import { Grid, Typography } from "@material-ui/core";
import { VFC } from "react";
import { StatusItem } from "../../../../components/Primitives/status-item.component";
import { Colors } from "../../../../style/Colors";
import { Badge } from "../../../components/badge/badge.component";

interface IProductTagFilter {
  label: string;
  onClick: VoidFunction;
  isSelected: boolean;
  count?: number;
}

export const ProductTagFilter: VFC<IProductTagFilter> = (props) => {
  const { label, onClick, count, isSelected } = props;

  return (
    <StatusItem isActive={isSelected} onClick={onClick}>
      <Grid container spacing={1} alignItems="center" wrap={"nowrap"}>
        <Grid item>
          <Typography variant="body1">{label}</Typography>
        </Grid>
        {count && (
          <Grid item>
            <Badge count={count} backgroundColor={Colors.red} textColor={Colors.white} />
          </Grid>
        )}
      </Grid>
    </StatusItem>
  );
};
