import { darken, makeStyles } from "@material-ui/core";
import { Colors, Shades } from "./Colors";

export const useButtonStyles = makeStyles((theme) => ({
  filterDone: {
    border: `2px solid ${Colors.greenLight}`,
    color: Colors.greenLight,
  },
  filterActive: {
    backgroundColor: Colors.greenDark,
    border: `2px solid ${Colors.gray}`,
    color: Colors.grayLight,
    borderRadius: "0.3rem !important",
    "&:hover": {
      backgroundColor: Colors.green,
    },
  },
  filterTodo: {
    border: `2px solid ${Colors.gray}`,
    color: Colors.gray,
  },
  danger: {
    backgroundColor: Colors.red,
    color: "white",
    "&:hover": {
      backgroundColor: darken(Colors.red, 0.05),
    },
  },
  dangerOutlined: {
    color: Colors.red,
    border: `2px solid ${Colors.red}`,
    "&:hover": { border: `2px solid ${Colors.red}` },
  },
  secondaryButton: {
    backgroundColor: Colors.white,
    color: Colors.greenLight,
    border: "2px solid",
    boxShadow: "none",
    "&:hover": { backgroundColor: `${Colors.white} !important`, boxShadow: "none !important" },
  },
  secondaryDangerButton: {
    backgroundColor: Colors.white,
    color: Colors.red,
    border: "2px solid",
    boxShadow: "none",
    "&:hover": { backgroundColor: `${Colors.white} !important`, boxShadow: "none !important" },
    "&:disabled": {
      backgroundColor: `${Colors.white} !important`,
      border: `2px solid ${Shades.gray30}`,
    },
  },
}));
