import { Grid, InputAdornment, makeStyles, Theme, Typography } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import _ from "lodash";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmResponseAssignmentX } from "../../../../collect-from-market/domain/assignment/cfm-response-assignment-x";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import { useGetResponseAssignmentsXQuery } from "../../../../collect-from-market/repositories/queries/response-assignments/get-response-assignments-x.query";
import { AutoCompleteNoForm } from "../../../../components/Autocomplete/AutoCompleteNoForm";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { Colors } from "../../../../style/Colors";
import { IAutocompleteOption } from "../../../../utils/AutocompleteHelper";
import { removeNullAndUndefined } from "../../../../utils/filter.util";
import {
  IResponseAssignmentRowValue,
  useProductResponseAssignmentContext,
} from "../../../pages/product-response/product-response-assignment.provider";
import { getProductArticleNumberAndName } from "../../product/product.utils";
import { mapToResponseFieldData } from "../../response-field/response-field.util";
import { ProductResponseAssignmentRow } from "../product-response-assignment-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    marginBottom: 20,
  },
}));

interface ICopyResponseAssignmentDialogProps {
  open: boolean;
  onCancel: VoidFunction;
  onCopyAssignment: (values: IResponseAssignmentRowValue[]) => void;
}

export const CopyResponseAssignmentDialog: VFC<ICopyResponseAssignmentDialogProps> = (props) => {
  const { open, onCancel, onCopyAssignment } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeStatus, setActiveStatus } = useProductResponseAssignmentContext();
  const [selectedProductOption, setSelectedProductOption] = useState<IAutocompleteOption | undefined>();

  const { isLoading, data: responseAssignments } = useGetResponseAssignmentsXQuery();

  const productsOfAssignments: ICfmProduct[] = useMemo(() => {
    if (!responseAssignments) return [];
    return _.uniqBy(
      responseAssignments.flatMap((ass) => ass.product).filter(removeNullAndUndefined),
      (product) => product.id,
    );
  }, [responseAssignments]);

  const onChange = (option: IAutocompleteOption | null) => {
    setSelectedProductOption(option ?? undefined);
  };

  const handleCancel = () => {
    setSelectedProductOption(undefined);
    onCancel();
  };

  const handleCopyAssignment = () => {
    if (!selectedProductOption) return;
    const assignmentsToUse = (responseAssignments ?? []).filter(
      (assignment) => assignment.product && assignment.product.id === Number(selectedProductOption.id),
    );

    const initialValues: IResponseAssignmentRowValue[] = assignmentsToUse.map((assignment) => {
      return {
        status: assignment.status,
        responseFieldData: assignment.responseFields
          ? assignment.responseFields.map(mapToResponseFieldData)
          : undefined,
        serviceDates: assignment.serviceDates,
      };
    });
    onCopyAssignment(initialValues);
  };

  const getAutocompleteOptions = (): IAutocompleteOption[] => {
    const options = productsOfAssignments.map((product) => {
      return {
        id: product.id.toString(),
        name: getProductArticleNumberAndName(product),
      };
    });

    return options;
  };

  const assignmentToInitialValues = (assignment: ICfmResponseAssignmentX): IResponseAssignmentRowValue => {
    return {
      status: assignment.status,
      responseFieldData: assignment.responseFields ? assignment.responseFields.map(mapToResponseFieldData) : undefined,
      serviceDates: assignment.serviceDates,
    };
  };

  return (
    <AppDialog
      open={open}
      title={t("configuration.responseAssignments.create.copyConfig")}
      onCancelClick={handleCancel}
      onAcceptClick={handleCopyAssignment}
      acceptTextOverride={t("configuration.responseAssignments.create.useConfig")}
      isLoading={isLoading}
      alignButtons="space-between"
      acceptDisabled={!selectedProductOption}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body1">{t("configuration.responseAssignments.create.chooseConfig")}</Typography>
        </Grid>
        <Grid item className={classes.autocomplete}>
          <AutoCompleteNoForm
            disabled={false}
            onChange={(option) => onChange(option as IAutocompleteOption)}
            value={selectedProductOption}
            label={""}
            options={getAutocompleteOptions()}
            multiple={false}
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            }
          />
        </Grid>
        {selectedProductOption &&
          responseAssignments &&
          responseAssignments
            .filter((assignment) => assignment.product && assignment.product.id === Number(selectedProductOption.id))
            .map((assignment, index) => {
              return (
                <Grid item>
                  <ProductResponseAssignmentRow
                    initValues={assignmentToInitialValues(assignment)}
                    index={index}
                    backgroundColor={Colors.white}
                    activeStatus={activeStatus}
                    setActiveStatus={setActiveStatus}
                  />
                </Grid>
              );
            })}
      </Grid>
    </AppDialog>
  );
};
