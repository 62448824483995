import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { translateCountryByCode } from "../../../../../utils/address.util";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderDetailInfoBlock } from "../card/order-details-info-block.component";

export const OrderXDetailsLocation: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const customerAddress = useMemo(() => {
    return order.customerAddress;
  }, [order.customerAddress]);

  const addressText = useMemo(() => {
    const textData: string[] = [`(${customerAddress?.id ?? ""}) ${customerAddress?.name ?? ""}`];

    if (customerAddress?.address && customerAddress.buildingNumber) {
      textData.push(`${customerAddress.address} ${customerAddress.buildingNumber}`);
    }

    if (customerAddress?.postal && customerAddress.city) {
      textData.push(`${customerAddress.postal} ${customerAddress.city}`);
    }

    textData.push(customerAddress?.country ? translateCountryByCode(customerAddress.country, t) : "");
    return textData.filter((text) => text.length > 0).join(", ");
  }, [
    customerAddress?.id,
    customerAddress?.name,
    customerAddress?.address,
    customerAddress?.postal,
    customerAddress?.city,
    customerAddress?.buildingNumber,
    customerAddress?.country,
    t,
  ]);

  const contactDataText = useMemo(() => {
    const data = [order.contactPersonName ?? "", order.contactPersonEmail ?? "", order.contactPersonTelephone ?? ""];

    return data.filter((text) => text.length > 0).join(", ");
  }, [order.contactPersonName, order.contactPersonEmail, order.contactPersonTelephone]);

  return (
    <OrderDetailInfoBlock title={t("order.details.wasteProducerAddress")}>
      <Grid container direction="column">
        {customerAddress && (
          <Grid item>
            <Typography variant="body1">{addressText}</Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">{contactDataText}</Typography>
        </Grid>
        {order.openingHours && (
          <Grid item>
            <Typography variant="body1">
              {t("order.details.openingHours", { openingHours: order.openingHours })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </OrderDetailInfoBlock>
  );
};
