import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAutocomplete } from "../../../../shared/components/form/fields/form-autocomplete.component";
import { IAutocompleteOption } from "../../../../utils/AutocompleteHelper";
import { ICreateProductRoutingFormInputs } from "../create-product-routing-content.component";
import { emptyOption } from "./product-routing-addresses-form-content.component";

interface IProductRoutingAddressesFormAdditionalAddressesProps {
  index: number;
  level: string;
  groupId: number | undefined;
  targetAddressOptions: IAutocompleteOption[];
  optionalTargetAddressIds: number[];
}

export const ProductRoutingAddressesFormAdditionalAddresses: VFC<
  IProductRoutingAddressesFormAdditionalAddressesProps
> = (props) => {
  const { index, level, groupId, targetAddressOptions, optionalTargetAddressIds } = props;

  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext<ICreateProductRoutingFormInputs>();

  type ObjectKey = keyof typeof errors;

  const optionalTargetAddressesProperty = `optional${
    level[0].toUpperCase() + level.substring(1)
  }AddressIds` as ObjectKey;

  const property = level === "primary" ? "optionalPrimaryAddressIds" : "optionalSecondaryAddressIds";

  const { remove } = useFieldArray({ name: property });

  const onUpdate = (value: number | number[] | null) => {
    if (index === 0 && value === null) {
      return Number(emptyOption.id);
    }
    if (value === null) {
      remove(index);
      return;
    }
    return value;
  };

  return (
    <Grid item key={index}>
      <FormAutocomplete<number>
        required={false}
        disabled={groupId === undefined}
        rules={{ required: false }}
        control={control}
        label={t("configuration.assignments.create.additionalEndAddresses")}
        options={targetAddressOptions
          .filter((option) => !optionalTargetAddressIds.includes(Number(option.id)))
          .map((option) => Number(option.id))}
        multiple={false}
        error={Boolean(errors[optionalTargetAddressesProperty])}
        transformValueOnChange={onUpdate}
        getOptionLabel={(value) => {
          return targetAddressOptions ? targetAddressOptions.find((option) => Number(option.id) === value)?.name : "";
        }}
        name={`${property}.${index}`}
      />
    </Grid>
  );
};
