export enum PomAnnouncementPeriod {
  Yearly = "yearly",
  Monthly = "monthly",
}

export function getTranslationKeyOfPeriod(period: PomAnnouncementPeriod): string {
  switch (period) {
    case PomAnnouncementPeriod.Monthly:
      return "pom.announcements.period.monthly";
    case PomAnnouncementPeriod.Yearly:
      return "pom.announcements.period.yearly";
  }
}
