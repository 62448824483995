import React from "react";
import { CalendarIcon } from "../../../components/Primitives";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";
import { getPomMainContactNavItems } from "../../../put-on-market/pom-navigation-items";
import { AvailablePomRoutes } from "../../../utils/constants";
import { isAdmin, isPomAgency, isPomMainContact } from "../user/user";
import { MenuListItem } from "./menu-list-item.component";
import { SidebarMenuItem } from "./sidebar-menu-item.component";

export const SidebarPomMenu: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { activePlatform } = usePlatformContext();

  const isPomPlatform = () => activePlatform === AvailablePlatform.Pom;
  const pomNavItems = isPomMainContact(internalUser) ? getPomMainContactNavItems(internalUser?.group?.id) : [];

  if (isPomPlatform() && !isAdmin(internalUser) && !isPomAgency(internalUser)) {
    return (
      <>
        {isPomMainContact(internalUser) && (
          <MenuListItem
            path={AvailablePomRoutes.Announcement}
            MenuIcon={CalendarIcon}
            title={"pom.announcements.create.text"}
          />
        )}
        {pomNavItems
          .filter((item) => item.visible && item.content)
          .map((item, index) => {
            return <SidebarMenuItem key={`pom-${item.to}-${index}`} name="pom" navItem={item} menuItem={item.label} />;
          })}
      </>
    );
  }
  return null;
};
