import { Badge, ListItemIcon } from "@material-ui/core";
import React from "react";
import { ShoppingCardIcon } from "../../../components/Primitives";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { useCart } from "../../../providers/Cart/cart-context";
import { AvailableCfmRoutes } from "../../../utils/constants";
import getCfmNavItems from "../../../collect-from-market/domain/navigation/cfm-navigation-items";
import { isAdmin, isCfmCustomer } from "../user/user";
import { MenuListItem } from "./menu-list-item.component";
import { SidebarMenuItem } from "./sidebar-menu-item.component";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";

export const SidebarCfmMenu: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { activePlatform } = usePlatformContext();
  const { cartCountOrderX } = useCart();

  const isCfmPlatform = () => activePlatform === AvailablePlatform.Cfm;
  const cfmNavItems = getCfmNavItems(internalUser, internalUser?.group?.id);

  if (isCfmPlatform() && !isAdmin(internalUser)) {
    return (
      <>
        {isCfmCustomer(internalUser) && (
          <MenuListItem path={AvailableCfmRoutes.CartX} MenuIcon={ShoppingCardIcon} title={"navigation.cart.label"}>
            <ListItemIcon>
              <Badge className="bg-orange" color="secondary" badgeContent={cartCountOrderX} showZero />
            </ListItemIcon>
          </MenuListItem>
        )}
        {cfmNavItems
          .filter((item) => item.visible && item.content)
          .map((item, index) => {
            return <SidebarMenuItem key={`cfm-${item.to}-${index}`} name="cfm" navItem={item} menuItem={item.label} />;
          })}
      </>
    );
  }
  return null;
};
