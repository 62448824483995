import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { CancelButton, CfmPlatformLogo } from "../../../components/Primitives";

const useStyles = makeStyles((theme: Theme) => ({
  alignCenter: {
    textAlign: "center",
  },
  resetButton: {
    border: "none",
  },
  contentContainer: {
    marginBottom: 20,
  },
}));

interface IEmptyProductViewProps {
  resetFilter: VoidFunction;
}

export const EmptyProductView: VFC<IEmptyProductViewProps> = (props) => {
  const { resetFilter } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.contentContainer}>
      <Grid container direction="row" justifyContent="center">
        <Grid item className={classes.alignCenter}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CfmPlatformLogo />
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>{t("configuration.overview.noData")}</Typography>
            </Grid>
            <Grid item>
              <CancelButton className={classes.resetButton} onClick={resetFilter}>
                {t("configuration.overview.reset")}
              </CancelButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
