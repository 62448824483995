import {
  BattGBrandStatus,
  IBattGBrand,
  IBattGBrandList,
  IBattGGrsBatteryType,
} from "../../../models/batt-g/batt-g-brand";
import { IBattGBrandFormInputs } from "../../../pages/batt-g/registration/brand/brand-dialog/batt-g-brand.dialog";
import {
  BattGBrandStatusModel,
  IBattGBrandListModel,
  IBattGBrandModel,
  IBattGGrsBatteryTypeModel,
} from "../../../repositories/models/batt-g/batt-g-brand.model";
import { IBattGCreateOrUpdateBrandRequest } from "../../../repositories/requests/batt-g/batt-g-create-or-update-brand.request";
import { formatDateYearMonthDay } from "../../../util/date.util";
import { PaginationMetaConverter } from "../pagination-meta.converter";

export class BattGBrandConverter {
  public static listToDomain(model: IBattGBrandListModel): IBattGBrandList {
    return {
      items: model.items.map(this.toDomain),
      meta: PaginationMetaConverter.toDomain(model.meta),
    };
  }

  public static toDomain(model: IBattGBrandModel): IBattGBrand {
    return {
      id: model.id,
      name: model.name,
      status: BattGBrandConverter.statusToDomain(model.status),
      startDate: model.startDate,
      endDate: model.endDate,
      trademarkRegisterNumber: model.trademarkRegisterNumber,
      grsBatteryType: BattGBrandConverter.batteryTypeToDomain(model.grsBatteryType),
      takeBackViaGrs: model.takeBackViaGrs,
      takeBackCompanyName: model.takeBackCompanyName,
      takeBackContactPersonEmail: model.takeBackContactPersonEmail,
      takeBackContactPersonName: model.takeBackContactPersonName,
      takeBackContactPersonTelephone: model.takeBackContactPersonTelephone,
      takeBackDescription: model.takeBackDescription,
    };
  }

  public static statusToDomain(model: BattGBrandStatusModel): BattGBrandStatus {
    switch (model) {
      case BattGBrandStatusModel.Draft:
        return BattGBrandStatus.Draft;
      case BattGBrandStatusModel.Published:
        return BattGBrandStatus.Published;
    }
  }

  public static statusToModel(domain: BattGBrandStatus): BattGBrandStatusModel {
    switch (domain) {
      case BattGBrandStatus.Draft:
        return BattGBrandStatusModel.Draft;
      case BattGBrandStatus.Published:
        return BattGBrandStatusModel.Published;
    }
  }

  public static batteryTypeToDomain(model: IBattGGrsBatteryTypeModel): IBattGGrsBatteryType {
    return {
      id: model.id,
      name: model.name,
      description: model.description,
    };
  }

  public static formInputsToCreateRequest(
    inputs: IBattGBrandFormInputs,
    status: BattGBrandStatus,
  ): IBattGCreateOrUpdateBrandRequest {
    return {
      grsBatteryTypeId: inputs.batteryType.id,
      name: inputs.name,
      startDate: formatDateYearMonthDay(inputs.startDate),
      endDate: inputs.endDate ? formatDateYearMonthDay(inputs.endDate) : null,
      status: BattGBrandConverter.statusToModel(status),
      takeBackCompanyName: inputs.takeBackCompanyName,
      takeBackContactPersonEmail: inputs.takeBackContactPersonEmail,
      takeBackContactPersonName: inputs.takeBackContactPersonName,
      takeBackContactPersonTelephone: inputs.takeBackContactPersonTelephone,
      takeBackDescription: inputs.takeBackDescription,
      takeBackViaGrs: inputs.takeBackViaGrs,
      trademarkRegisterNumber: inputs.trademarkRegisterNumber,
    };
  }
}
