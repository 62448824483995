import { IBAN } from "ibankit";

export const getFormattedIban = (iban: string): string => {
  const cleanedIban = cleanString(iban);
  return IBAN.printFormat(cleanedIban, " ");
};

export const getFormattedBic = (bic: string): string => {
  return cleanString(bic);
};

// Remove non-alpha-numeric and to upper case.
const cleanString = (str: string) => str.replaceAll(/[^a-z0-9]/gi, "").toUpperCase();
