import { LinearProgress } from "@material-ui/core";
import React from "react";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform } from "../../../providers/Auth/platform.provider";
import { CreateUserForm } from "../../components/user/create-user-form/create-user-form.component";
import { CreateUserFormProvider } from "../../components/user/create-user-form/create-user-form.provider";
import { AddressConverter } from "../../domain/converter/address.converter";
import { isCfmWasteProducer } from "../../domain/user/user";
import { AddressType } from "../../models/address/address.model";
import { useGetUserAddresses } from "../../repositories/queries/address/get-user-addresses-for-id.query";

interface ICreateUserPageProps {
  platformType: AvailablePlatform;
}

export const CreateUserPage = ({ platformType }: ICreateUserPageProps) => {
  const { internalUser } = useAuthContext();
  const { isLoading, data: userAddresses } = useGetUserAddresses(
    internalUser?.id,
    [AddressType.CustomerLocation],
    undefined,
    isCfmWasteProducer(internalUser) && internalUser?.id !== undefined,
  );

  const leanUserAddresses = userAddresses
    ? userAddresses.addresses.map(AddressConverter.domainToLeanAddressDomain)
    : [];

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <CreateUserFormProvider user={internalUser} userAddresses={leanUserAddresses} platformType={platformType}>
          <CreateUserForm userAddresses={leanUserAddresses} />
        </CreateUserFormProvider>
      )}
    </>
  );
};
