import { makeStyles } from "@material-ui/core";
import { DRAWER_OPEN } from "../../../../../shared/domain/sidebar/sidebar";

export const useOrderFooterStyle = makeStyles((theme) => ({
  footerStyle: {
    bottom: 0,
    left: 0,
    position: "fixed",
    zIndex: 600,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  footerWidthSidebarOpen: {
    width: `calc(100% - ${DRAWER_OPEN}px)`,
  },
  footerWidthSidebarClosed: {
    width: "100%",
  },
}));
