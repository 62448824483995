import React, { createContext, useContext } from "react";
import { IGroup } from "../../domain/group/group";

interface IGroupDetailProvider {
  group: IGroup;
}

export const GroupDetailContext = createContext<IGroupDetailProvider>({} as IGroupDetailProvider);

export const useGroupDetailContext = () => {
  return useContext(GroupDetailContext);
};

const useGroupDetailProvider = (props: IGroupDetailProviderProps): IGroupDetailProvider => {
  const { group } = props;
  return { group: group };
};

interface IGroupDetailProviderProps {
  group: IGroup;
}

export const GroupDetailProvider: React.FC<IGroupDetailProviderProps> = (props) => {
  const value = useGroupDetailProvider(props);
  return <GroupDetailContext.Provider value={value}>{props.children}</GroupDetailContext.Provider>;
};
