import { useQuery } from "react-query";
import { RestEndpoints } from "../../../api/endpoints";
import { ReactQueryConfig } from "../../../config/react-query-config";
import getInstance from "../../../utils/rest";
import { IPomAgencyGroupListModel } from "../models/agencies/pom-agency-groups.model";
import { PomQueryKeys } from "../pom-query-keys";

const getAgencyCompanies = async (
  page: number,
  pageSize: number,
  searchQuery?: string,
): Promise<IPomAgencyGroupListModel> => {
  const { data } = await getInstance().get<IPomAgencyGroupListModel>(RestEndpoints.PomAgencyGroups, {
    params: {
      page: page,
      pageSize: pageSize,
      query: searchQuery,
    },
  });

  return data;
};

export const useGetAgencyCompaniesQuery = (page: number, pageSize: number, searchQuery?: string) => {
  return useQuery(
    [PomQueryKeys.GetAgencyCompanies, page, pageSize, searchQuery],
    () => getAgencyCompanies(page, pageSize, searchQuery),
    {
      staleTime: ReactQueryConfig.staleTime,
    },
  );
};
