export enum CfmServiceDateModel {
  Pickup = "pickup",
  Delivery = "delivery",
  Treatment = "treatment",
  Recycling = "recycling",
}

export interface ICfmServiceDateListModel {
  items: CfmServiceDateModel[];
}
