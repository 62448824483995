import { GetNextPageParamFunction, useInfiniteQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import { IProductOverviewFilter } from "../../../../../configurator/pages/product/components/product-overview.utils";
import getInstance from "../../../../../utils/rest";
import { IProductsResponseModel } from "../../../models/product/cfm-product.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getInfiniteProducts = async (
  filter: IProductOverviewFilter,
  groupId: number | undefined,
  pageParam: number | undefined,
): Promise<IProductsResponseModel> => {
  const page = pageParam ? pageParam - 1 : 0;
  const mappedFilter = {
    generalFilter: filter.general,
    articleType: filter.productConfig.articleType,
    categoryIds: filter.productConfig.categories ? filter.productConfig.categories.map((cat) => cat.id) : undefined,
    fractionIds: filter.productConfig.fractions
      ? filter.productConfig.fractions.map((fraction) => fraction.id)
      : undefined,
    stateIds: filter.productConfig.states ? filter.productConfig.states.map((state) => state.id) : undefined,
    typeIds: filter.productConfig.types ? filter.productConfig.types.map((type) => type.id) : undefined,
    orderStatus:
      filter.productConfig.orderStatus && filter.productConfig.orderStatus.length > 0
        ? filter.productConfig.orderStatus
        : undefined,
    searchText: filter.searchText,
    sorting: filter.sorting,
    routingRequired: filter.routingRequired,
    pagination: { page, pageSize: 30 },
    groupId,
  };

  const { data } = await getInstance().post<IProductsResponseModel>(RestEndpoints.AllProducts, mappedFilter);
  return data;
};

export const useGetProductsInfiniteQuery = (
  filter: IProductOverviewFilter,
  getNextPageParam: GetNextPageParamFunction<IProductsResponseModel>,
  groupId?: number,
) => {
  return useInfiniteQuery(
    [CfmQueryKeys.GetAllProductsInfinite, filter, groupId],
    ({ pageParam }) => getInfiniteProducts(filter, groupId, pageParam),
    {
      getNextPageParam,
      staleTime: ReactQueryConfig.staleTime,
    },
  );
};
