import { Box, TextField } from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isArray } from "lodash";
import React, { ForwardedRef, ReactElement, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { useTranslation } from "react-i18next";
import { useShrinkLabel } from "../../../../hooks/useShrinkLabel";
import { useAutocompleteStyles } from "../../../../style/autocomplete.style";

interface IFormAutocomplete<T> {
  getOptionLabel: (option: T) => string | undefined;
  rules?: RegisterOptions<any>;
  transformValueOnChange?: (value: T | T[] | null) => any;
  getOptionSelected?: (option: T, value: T) => boolean;
  multiple?: boolean | undefined;
  name: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  disableClearable?: boolean;
  label: string | undefined;
  options: T[];
  control: Control<any, object>;
  renderOption?: (option: any) => ReactNode;
  listboxProps?: object;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[];
  onSearchChange?: (text: string | undefined) => void;
  onTextfieldBlur?: VoidFunction;
}

export const FormAutocomplete = React.forwardRef(
  <T extends {}>(
    {
      error,
      helperText,
      control,
      options,
      getOptionLabel,
      label,
      name,
      rules,
      multiple,
      transformValueOnChange,
      required = true,
      getOptionSelected,
      disabled,
      disableClearable,
      renderOption,
      isLoading,
      listboxProps,
      filterOptions,
      onSearchChange,
      onTextfieldBlur,
    }: IFormAutocomplete<T>,
    ref: ForwardedRef<any>,
  ) => {
    const classes = useAutocompleteStyles();
    const { shrinkStyle } = useShrinkLabel(label ?? "");
    const { t } = useTranslation();

    const getKeyForValue = (value: T): number | T | undefined => {
      if (isArray(value)) {
        return value.length;
      }
      return value;
    };

    const getLabelElement = () => {
      if (!required) return label;

      return <b>{label}</b>;
    };

    return (
      <Box flexDirection={"column"}>
        <Controller
          render={({ field }) => {
            return (
              <Autocomplete
                // Workaround for Autocomplete, because it won't rerender if
                // we use the "select all entries" option :(
                key={`autocomplete-${field.name}-${getKeyForValue(field.value)}`}
                {...field}
                ref={ref}
                ListboxProps={listboxProps}
                options={options}
                noOptionsText={t("general.autocomplete.noOptions")}
                multiple={multiple}
                classes={{
                  endAdornment: classes.adornment,
                }}
                getOptionSelected={getOptionSelected}
                fullWidth={true}
                loading={isLoading}
                loadingText={t("general.loading")}
                getOptionLabel={(option) => getOptionLabel(option) ?? ""}
                disabled={disabled}
                disableClearable={disableClearable}
                onChange={(event, value) => {
                  let changedValue = value;
                  if (transformValueOnChange) {
                    changedValue = transformValueOnChange(value);
                  }
                  field.onChange(changedValue);
                }}
                renderOption={renderOption}
                filterOptions={filterOptions}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      required={required}
                      label={label ? getLabelElement() : undefined}
                      InputLabelProps={{ shrink: true, style: shrinkStyle }}
                      error={error}
                      helperText={helperText}
                      className={classes.input}
                      onChange={onSearchChange ? (event) => onSearchChange(event.target.value) : undefined}
                      onBlur={onTextfieldBlur}
                    />
                  );
                }}
              />
            );
          }}
          defaultValue={multiple ? [] : null}
          name={name}
          rules={rules}
          control={control}
        />
      </Box>
    );
  },
) as <T extends {}>(props: IFormAutocomplete<T>, ref: any) => ReactElement;
