import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    border: "1px solid",
    borderRadius: "4px",
    borderColor: Colors.red,
    backgroundColor: Colors.red,
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionText: {
    color: Shades.white,
    fontWeight: "bold",
    padding: "3px 6px 1px 6px",
  },
  label: {
    textAlign: "end",
    marginTop: 2,
  },
  labelContent: {
    color: PrimaryColors.base,
  },
  iconStyle: {
    color: Shades.white,
  },
  iconContainer: {
    height: "100%",
  },
}));

interface IResetCreateProductFilterProps {
  onReset: VoidFunction;
  productCount: number;
  displayProductCountText: boolean;
  emptyLabelMinWidth?: number;
}

export const ResetCreateProductFilterButton: VFC<IResetCreateProductFilterProps> = (props) => {
  const { onReset, productCount, displayProductCountText, emptyLabelMinWidth = 130 } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item className={classes.label}>
        <Typography variant="body1" style={{ minWidth: emptyLabelMinWidth }} className={classes.labelContent}>
          {productCount > 0 && displayProductCountText ? t("configuration.productCount", { count: productCount }) : ""}
        </Typography>
      </Grid>
      <Grid item>
        <Box onClick={onReset} className={classes.option}>
          <Grid container direction="row">
            <Grid item>
              <Typography variant="body2" className={classes.optionText}>
                {t("configuration.resetConfig")}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column" justifyContent="center" className={classes.iconContainer}>
                <Grid item>
                  <Clear className={classes.iconStyle} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
