import { Box, Button, makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { CSSProperties, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { CalendarIcon, CsvExportIcon, SaveButton, SearchIcon, StepperItem } from "../../../components/Primitives";
import { useUnsavedDataContext } from "../../../providers/App/unsaved-data-context";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { hasMultiplePomContracts } from "../../../shared/components/group/group-form/group-form.util";
import { IPomGroupContract } from "../../../shared/domain/group/group-contract";
import { formatDateMonthYear, formatDateShort, formatYearShort } from "../../../shared/util/date.util";
import { useDetectClickOutside } from "../../../shared/util/detect-click-outside.util";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { usePomAnnouncementContext } from "../../pages/announcement/pom-announcement.provider";
import { IPomAnnouncementExcelDataModel } from "../../repositories/models/announcements/pom-announcement-excel-data.model";
import { PomAnnouncementPeriod } from "../../repositories/models/announcements/pom-announcement.period";
import { PomAnnouncementStatusModel } from "../../repositories/models/announcements/pom-announcements.model";
import { PomAnnouncementDatePicker } from "../announcement-date-picker/pom-announcement-date-picker.component";
import { PomAnnouncementDialog } from "../announcement-dialog/pom-announcement.dialog";
import { PomAnnouncementExcelExportDialog } from "../announcement-excel-export-dialog/pom-announcement-excel-export.dialog";
import { PomAnnouncementExcelUploadDialog } from "../announcement-excel-upload-dialog/pom-announcement-excel-upload.dialog";
import { PomAnnouncementExcelUploadProvider } from "../announcement-excel-upload-dialog/pom-announcement-excel-upload.provider";
import { usePomProductSearchFieldContext } from "../product-table/pom-product-search-field.provider";

const useStyle = makeStyles((_) => ({
  headerContainer: {
    marginBottom: "10px",
  },
  header: {
    color: PrimaryColors.base,
  },
  announcementDate: {
    color: PrimaryColors.base,
  },
  headerButton: {
    height: "32px",
  },
  icon: {
    padding: "2px",
  },
  datePickerContainer: {
    position: "absolute",
    left: 0,
    top: "40px",
    right: 0,
    maxWidth: "300px",
    zIndex: 20,
  },
  relative: {
    position: "relative",
  },
  whiteIcon: {
    color: Colors.white,
  },
}));

interface IPomAnnouncementHeaderComponent {
  className?: string;
  period: PomAnnouncementPeriod;
  contract: IPomGroupContract | undefined;
  openTakeBackSystemDialog: VoidFunction;
}

export const PomAnnouncementHeaderComponent: FunctionComponent<IPomAnnouncementHeaderComponent> = (props) => {
  const { t } = useTranslation();
  const { period, openTakeBackSystemDialog, contract } = props;
  const { focusAndScrollTo } = usePomProductSearchFieldContext();
  const {
    curAnnouncementDate,
    curAnnouncementStatus,
    purchaseOrderNumber,
    setPurchaseOrderNumber,
    onCreateAnnouncement,
    onPatchAnnouncement,
    validateAnnouncement,
    isLoading,
    announcementCreatedAt,
    announcementId,
    products,
    changedProducts,
    prefillChangedProducts,
  } = usePomAnnouncementContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyle();
  const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);
  const [showExcelUploadDialog, setShowExcelUploadDialog] = useState<boolean>(false);
  const [showExcelExportDialog, setShowExcelExportDialog] = useState<boolean>(false);
  const [showDatepicker, setShowDatepicker] = useState<boolean>(false);
  const { nodeRef } = useDetectClickOutside(() => {
    setShowDatepicker(false);
  });
  const { hasUnsavedData } = useUnsavedDataContext();
  const { internalUser } = useAuthContext();

  // if the product list is defined it is an update
  const isUpdate: boolean = !!products;

  const stepperStyle: CSSProperties = {
    display: "flex",
    padding: "16px",
    height: "30px",
  };

  const getAnnouncementDate = (): string => {
    if (!announcementCreatedAt || curAnnouncementStatus === PomAnnouncementStatusModel.Draft) {
      // show empty content to keep size of the field
      return "\u00a0";
    }

    return `${t("pom.announcements.announcement_date")} ${formatDateShort(announcementCreatedAt)}`;
  };

  const getAnnouncementId = (): string => {
    if (!announcementId) {
      return "\u00a0";
    }

    return `${t("pom.announcements.announcement_id")} ${announcementId}`;
  };

  const getAnnouncementPoNumber = (): string => {
    return `${t("purchase_order_number")}: ${purchaseOrderNumber}`;
  };

  const showSaveConfirmationDialog = (): void => {
    if (validateAnnouncement(true)) {
      setShowSubmitDialog(true);
    }
  };

  const handleSaveAnnouncement = (isDraft: boolean): void => {
    if (isUpdate) {
      void onPatchAnnouncement(isDraft);
    } else {
      void onCreateAnnouncement(isDraft);
    }
    setShowSubmitDialog(false);
  };

  const handleDateSelected = (_: Date): void => {
    setShowDatepicker(false);
  };

  const getCurrentAnnouncementDate = (): string => {
    if (curAnnouncementDate) {
      return props.period === PomAnnouncementPeriod.Monthly
        ? formatDateMonthYear(curAnnouncementDate)
        : formatYearShort(curAnnouncementDate);
    } else {
      return "";
    }
  };

  const prefillExtractedExcelData = (data: IPomAnnouncementExcelDataModel): void => {
    setShowExcelUploadDialog(false);
    prefillChangedProducts(data);
  };

  const showPoNumberInfo = (): boolean => {
    return announcementId != null && !!purchaseOrderNumber.length;
  };

  return (
    <>
      <PomAnnouncementDialog
        type={isUpdate && curAnnouncementStatus === PomAnnouncementStatusModel.Published ? "update" : "create"}
        purchaseOrderNumber={purchaseOrderNumber}
        setPurchaseOrderNumber={setPurchaseOrderNumber}
        newItems={changedProducts}
        existingItems={products}
        onOk={() => handleSaveAnnouncement(false)}
        onCancel={() => setShowSubmitDialog(false)}
        open={showSubmitDialog}
      />
      <PomAnnouncementExcelUploadProvider>
        <PomAnnouncementExcelUploadDialog
          onOk={prefillExtractedExcelData}
          onCancel={() => setShowExcelUploadDialog(false)}
          open={showExcelUploadDialog}
        />
      </PomAnnouncementExcelUploadProvider>
      <PomAnnouncementExcelExportDialog
        onCancel={() => setShowExcelExportDialog(false)}
        open={showExcelExportDialog}
        currentAnnouncementDate={curAnnouncementDate}
        announcementPeriod={period}
        contract={contract}
      />
      <div className={props.className}>
        <Box
          className={classes.headerContainer}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="end"
        >
          <h2 className={classes.header}>
            {getCurrentAnnouncementDate()} - {contract?.contractField.takeBackSystem.name} - {contract?.contractNumber}
          </h2>
          <Box display="flex" flexDirection="column" alignItems={isMobile ? "start" : "end"}>
            <div className={classes.announcementDate}>{getAnnouncementDate()}</div>
            <h2 className={classes.header + " mb-0"}>{getAnnouncementId()}</h2>
            {showPoNumberInfo() && <div className={classes.announcementDate}>{getAnnouncementPoNumber()}</div>}
          </Box>
        </Box>

        <Box className={classes.relative} display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box display={isMobile ? "block" : "flex"} className="mb-2">
            {hasMultiplePomContracts(internalUser?.group) && (
              <StepperItem
                isActive={false}
                style={stepperStyle}
                onClick={() => {
                  openTakeBackSystemDialog();
                }}
              >
                <Box>{t("pom.announcements.change_takebacksystem")}</Box>
              </StepperItem>
            )}
            <StepperItem
              isActive={false}
              style={stepperStyle}
              onClick={() => {
                setShowDatepicker(!showDatepicker);
              }}
            >
              <Box display="flex">
                <span className="mr-3">{t("pom.announcements.date.change")}</span>
                <CalendarIcon className={classes.icon} />
              </Box>
            </StepperItem>

            {showDatepicker && (
              <div ref={nodeRef} className={classes.datePickerContainer}>
                <PomAnnouncementDatePicker onDateSelected={handleDateSelected} />
              </div>
            )}

            <StepperItem isActive={false} style={stepperStyle} onClick={focusAndScrollTo}>
              <Box display="flex">
                <span className="mr-3"> {t("pom.announcements.to.product.search")} </span>
                <SearchIcon className={classes.icon} />
              </Box>
            </StepperItem>
          </Box>

          <Box display="flex" flexWrap="wrap">
            {!announcementId && (
              <Button
                endIcon={<CsvExportIcon className={classes.whiteIcon} />}
                color="primary"
                variant="contained"
                className={classes.headerButton + " mb-2 mr-2"}
                disabled={isLoading || !contract?.active}
                onClick={() => {
                  setShowExcelUploadDialog(true);
                }}
              >
                {t("pom.announcements.template.upload.title")}
              </Button>
            )}
            <Button
              endIcon={<CsvExportIcon className={classes.whiteIcon} />}
              color="primary"
              variant="contained"
              className={classes.headerButton + " mb-2 mr-2"}
              disabled={
                (curAnnouncementStatus === PomAnnouncementStatusModel.Published &&
                  changedProducts &&
                  changedProducts.length > 0) ||
                (curAnnouncementStatus === PomAnnouncementStatusModel.Draft && hasUnsavedData)
              }
              onClick={() => setShowExcelExportDialog(true)}
            >
              {t("pom.announcements.export.text")}
            </Button>
            {!announcementId && (
              <SaveButton
                className={classes.headerButton + " mb-2 mr-2"}
                onClick={() => handleSaveAnnouncement(true)}
                disabled={changedProducts?.length === 0 || isLoading || !contract?.active}
              >
                {t("pom.announcements.create_draft.text")}
              </SaveButton>
            )}
            <SaveButton
              className={classes.headerButton}
              onClick={showSaveConfirmationDialog}
              disabled={changedProducts?.length === 0 || isLoading || !contract?.active}
            >
              {t("pom.announcements.create.text")}
            </SaveButton>
          </Box>
        </Box>
      </div>
    </>
  );
};
