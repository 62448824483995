import { DividerClassKey, StyleRules } from "@material-ui/core";
import { Shades } from "../../../style/Colors";

export const MuiDivider: Partial<StyleRules<DividerClassKey, {}>> = {
  root: {
    marginTop: 15,
    marginBottom: 15,
    height: 2,
    color: Shades.gray40,
    backgroundColor: Shades.gray40,
  },
};
