import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { checkDate, formatDateShort } from "../../../../../shared/util/date.util";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";

const useStyles = makeStyles((theme: Theme) => ({
  purchaseOrderNumberContainer: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

interface IOrderCardInformationDetailsProps {
  order: ICfmOrderX;
}

export const OrderCardInformationDetails: VFC<IOrderCardInformationDetailsProps> = (props) => {
  const { order } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const purchaseOrderNumber = useMemo(() => {
    return order.purchaseOrderNumber && order.purchaseOrderNumber.length > 0 ? order.purchaseOrderNumber : "-";
  }, [order.purchaseOrderNumber]);

  const orderComment = useMemo(() => {
    return order.orderComment && order.orderComment.length > 0 ? order.orderComment : "-";
  }, [order.orderComment]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t("order.card.requested_pickup_date")}:{" "}
          <strong>
            {order.orderDate && checkDate(order.orderDate) !== null ? formatDateShort(order.orderDate) : ""}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2"></Typography>
        <Typography variant="body2" className={classes.purchaseOrderNumberContainer}>
          {t("purchase_order_number")}: <strong>{purchaseOrderNumber}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.purchaseOrderNumberContainer}>
          {t("order.column_name.orderComment")}: <strong>{orderComment}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};
