import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { PomQueryKeys } from "../../pom-query-keys";
import { downloadFile } from "../../../../shared/util/download.util";
import { IPomAnnouncementExcelResult } from "../../models/announcements/pom-announcement-excel-data.model";

const getAnnouncementTemplate = async (contractId: number | undefined, lang: string | null): Promise<IPomAnnouncementExcelResult | undefined> => {
  if (!contractId || !lang) return undefined;
  const url = RestEndpoints.PomAnnouncementExcel.replace(":contractId", contractId.toString());
  const { data } = await getInstance().get(url, { responseType: "blob", params: { lang } });
  const filename = lang === "de" ? "Meldebogen.xlsx" : "Reporting_Form.xlsx";
  downloadFile(data, filename);
  return { lang: lang, data: data };
};

export const useGetAnnouncementTemplateQuery = (contractId: number | undefined, language: string | null) => {
  return useQuery([PomQueryKeys.GetAnnouncementTemplate, contractId, language], () => getAnnouncementTemplate(contractId, language), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(contractId) && language !== null
  });
};
