import { InputBaseClassKey, StyleRules } from "@material-ui/core";
import { Shades } from "../../../style/Colors";

export const MuiInputBase: Partial<StyleRules<InputBaseClassKey, {}>> = {
  input: {
    "&$disabled": {
      color: `${Shades.gray60} !important`,
      backgroundColor: Shades.gray10,
      outline: `${Shades.gray30} solid 2px`,
      opacity: 0.9,
    },
  },
};
