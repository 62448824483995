import { makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { Colors, Shades } from "../../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    color: Colors.white,
    fontWeight: "bold",
    backgroundColor: Shades.gray40,
    borderRadius: "4px",
    padding: "0px 4px 0px 4px",
  },
}));

interface IResponseFieldIdBoxProps {
  fieldId: number;
}

export const ResponseFieldIdBox: VFC<IResponseFieldIdBoxProps> = (props) => {
  const { fieldId } = props;
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.status}>
      {fieldId}
    </Typography>
  );
};
