import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmResponseField } from "../../../collect-from-market/domain/routing-assignment/cfm-response-field";
import { EditIconNoBorderButton } from "../../../components/Primitives";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { useProductResponseFieldOverviewContext } from "../../pages/product-response-field/product-response-field-overview.provider";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import { ResponseFieldActiveBadge } from "./response-field-active-badge.component";
import { ResponseFieldRowHeader } from "./response-field-row-header.component";
import { getResponseFieldFieldTypeLabel, IResponseFieldRowHeader } from "./response-field.util";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0px 12px 0px 12px",
  },
  centerContainer: {
    height: "100%",
  },
  numberColumn: {
    width: "3%",
  },
  internalNumberColumn: {
    width: "10%",
  },
  typeColumn: {
    width: "10%",
  },
  unitColumn: {
    width: "10%",
  },
  nameColumn: {
    width: "38%",
  },
  statusHeader: {
    marginRight: "40px",
  },
  header: {
    backgroundColor: PrimaryColors.base,
    color: Colors.white,
  },
  inactiveText: {
    color: Colors.red,
  },
  badgeEditContainer: {
    flexGrow: 1,
  },
}));

interface IResponseFieldRowProps {
  responseField: ICfmResponseField | IResponseFieldRowHeader;
  isHeader?: boolean;
}
export const ResponseFieldRow: VFC<IResponseFieldRowProps> = (props) => {
  const { responseField, isHeader } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { setResponseField } = useProductResponseFieldOverviewContext();

  const isActive = (active: Date | string | null): boolean => {
    return active ? true : false;
  };

  if (isHeader) {
    return (
      <ResponseFieldRowHeader>
        <Grid container direction="row" justifyContent="flex-start" alignContent="center" className={classes.container}>
          <Grid item className={classes.numberColumn}>
            <Box display="flex" alignItems="center" className={classes.centerContainer}>
              <Typography>{responseField.id}</Typography>
            </Box>
          </Grid>
          <Grid item className={classes.internalNumberColumn}>
            <Box display="flex" alignItems="center" className={classes.centerContainer}>
              <Typography>{responseField.internalNumber}</Typography>
            </Box>
          </Grid>
          <Grid item className={classes.typeColumn}>
            <Box display="flex" alignItems="center" className={classes.centerContainer}>
              <Typography>{responseField.type}</Typography>
            </Box>
          </Grid>
          <Grid item className={classes.nameColumn}>
            <Box display="flex" alignItems="center" className={classes.centerContainer}>
              <Typography>{responseField.name}</Typography>
            </Box>
          </Grid>
          <Grid item className={classes.unitColumn}>
            <Box display="flex" alignItems="center" className={classes.centerContainer}>
              <Typography>{responseField.unit ?? "-"}</Typography>
            </Box>
          </Grid>
          <Grid item className={classNames(classes.badgeEditContainer, classes.statusHeader)}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" className={classes.centerContainer}>
              <Typography variant="body1">{responseField.active}</Typography>
            </Box>
          </Grid>
        </Grid>
      </ResponseFieldRowHeader>
    );
  }

  return (
    <BoxedRowGrid justifyContent="space-between">
      <Grid container direction="row" justifyContent="flex-start" alignContent="center" className={classes.container}>
        <Grid item className={classes.numberColumn}>
          <Box display="flex" alignItems="center" className={classes.centerContainer}>
            <Typography
              className={classNames({
                [classes.inactiveText]: !isActive(responseField.active),
              })}
              noWrap
            >
              {responseField.id}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.internalNumberColumn}>
          <Box display="flex" alignItems="center" className={classes.centerContainer}>
            <Typography
              className={classNames({
                [classes.inactiveText]: !isActive(responseField.active),
              })}
              noWrap
            >
              {responseField.internalNumber}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.typeColumn}>
          <Box display="flex" alignItems="center" className={classes.centerContainer}>
            <Typography
              className={classNames({
                [classes.inactiveText]: !isActive(responseField.active),
              })}
              noWrap
            >
              {getResponseFieldFieldTypeLabel((responseField as ICfmResponseField).type, t)}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.nameColumn}>
          <Box display="flex" alignItems="center" className={classes.centerContainer}>
            <Typography
              className={classNames({
                [classes.inactiveText]: !isActive(responseField.active),
              })}
              noWrap
            >
              {responseField.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.unitColumn}>
          <Box display="flex" alignItems="center" className={classes.centerContainer}>
            <Typography
              className={classNames({
                [classes.inactiveText]: !isActive(responseField.active),
              })}
              noWrap
            >
              {responseField.unit ?? "-"}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.badgeEditContainer}>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} alignContent="center">
            <Grid item>
              <Box display="flex" alignItems="center" className={classes.centerContainer}>
                <ResponseFieldActiveBadge isActive={isActive(responseField.active)} />
              </Box>
            </Grid>
            <Grid item>
              <EditIconNoBorderButton onClick={() => setResponseField(responseField as ICfmResponseField)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BoxedRowGrid>
  );
};
