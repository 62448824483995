import { DeepPartial, DefaultValues } from "react-hook-form";
import config from "../../../../config/config";
import { Environment } from "../../../../config/environment.util";
import { Countries, getDefaultCountry } from "../../../../utils/Countries";
import { IAddressLean } from "../../../domain/address/address-lean";
import { AddressType } from "../../../models/address/address.model";
import { TranslateFunc } from "../../../../utils/translation.utils";
import { IAddressFormInputs } from "./address-form.component";

export const ALL_ADDRESSES_OPTION = -1;

export const getSelectAllAddressesOption = (t: TranslateFunc) => {
  const selectAllAddressesOption: IAddressLean = {
    id: ALL_ADDRESSES_OPTION,
    groupId: -1,
    name: t("basedata.users.edit.option.all"),
    formattedAddress: t("basedata.users.edit.option.all"),
    type: AddressType.Invoice,
    openingHours: "",
    name2: "",
    address: "",
    addressText: "",
    buildingNumber: "",
    city: "",
    postal: "",
    country: "",
    state: "",
    lat: undefined,
    long: undefined,
    contactPersonEmail: "",
    contactPersonTelephone: "",
    contactPersonName: "",
    active: undefined,
  };

  return selectAllAddressesOption;
};

const defaultValues: DefaultValues<IAddressFormInputs> = {
  country: getDefaultCountry(),
};

const prefilledDevelopmentValues: DefaultValues<IAddressFormInputs> = {
  country: getDefaultCountry(),
  name: "Development",
  lat: -1,
  lng: -1,
  city: "Dev City",
  postal: "0000",
  name2: "Code Review",
  buildingNumber: "47",
  active: true,
  address: "Devstraße",
  contactPersonMail: "dev-address@wastebox.biz",
  contactPersonName: "Developer",
  contactPersonPhone: "+43 12341234",
  state: "Developia",
  addressText: "Dev Addresstext 1",
};

export const addressToInitialValues = (
  address: DeepPartial<IAddressLean> | undefined,
  addressType: AddressType | undefined,
): IAddressFormInputs | DefaultValues<IAddressFormInputs> | undefined => {
  if (!address) {
    if (config.environment === Environment.DevelopmentLocal && config.prefillForms === "YES") {
      const defaultValues = prefilledDevelopmentValues;
      defaultValues.type = addressType;
      return defaultValues;
    }

    const defaultValue = defaultValues;
    defaultValue.type = addressType;
    defaultValue.active = true;
    return defaultValue;
  }
  return {
    country: Countries.find((country) => country.code === address.country?.toUpperCase()) ?? getDefaultCountry(),
    addressText: address.addressText ?? "",
    address: address.address ?? "",
    active: !!address.active,
    contactPersonPhone: address.contactPersonTelephone ?? "",
    lng: address.long ?? -1,
    lat: address.lat ?? -1,
    contactPersonName: address.contactPersonName ?? "",
    contactPersonMail: address.contactPersonEmail ?? "",
    city: address.city ?? "",
    postal: address.postal ?? "",
    buildingNumber: address.buildingNumber ?? "",
    type: address.type ?? addressType,
    name2: address.name2 ?? "",
    name: address.name ?? "",
    state: address.state ?? "",
    openingHours: address.openingHours ?? "",
  };
};
