import { Box, Typography } from "@material-ui/core";
import config from "config/config";
import { useTranslation } from "react-i18next";
import DefaultLayout from "shared/layout/default.layout";

export const MaintenanceModePage = () => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <Box height="100%" display="flex" flexDirection="column" alignItems="center">
        <h1>{t("maintainance.title")}</h1>
        <Typography>
          <p>Sehr geehrte Nutzerinnen und Nutzer des GRS-Portals,</p>
          <p>wir möchten Sie darüber informieren, dass aufgrund von <b>planmäßigen Wartungsarbeiten</b> das GRS-Portal voraussichtlich bis <b>{config.maintenanceModeDescription}</b> nicht verfügbar sein wird.</p>
          <p>Für dringende Anliegen bitten wir Sie, unser Service-Center zu kontaktieren:
            <ul>
              <li>
                <b>E-Mail</b>: portal@grs-batterien.de
              </li>
              <li>
                <b>Service Center Telefonnummer</b>: +49 1806 80 50 30*
              </li>
              <li>
                <b>Servicezeiten</b>: Montag bis Donnerstag von 9:00 bis 12:00 Uhr und von 13:00 bis 16:00 Uhr, sowie Freitag von 9:00 bis 13:00 Uhr.
              </li>
            </ul>
          </p>
          <p>
            <i>
              *Festnetzpreis: 20 Cent pro Anruf; Mobilfunkpreise: maximal 60 Cent pro Anruf.
            </i>
          </p>
          <p>Wir danken Ihnen für Ihr Verständnis und stehen Ihnen bei weiteren Fragen gerne zur Verfügung.</p>
          <p>Mit freundlichen Grüßen,<br></br>Ihr GRS-Team</p>
        </Typography>
      </Box>
    </DefaultLayout>
  );
};
