import { useQuery } from "react-query";
import { IPaginationInputMeta } from "shared/domain/pagination/pagination-meta";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { MailConverter } from "../../../domain/converter/mail.converter";
import { PaginationMetaConverter } from "../../../domain/converter/pagination-meta.converter";
import { IApiMailResult } from "../../../domain/mail/mail";
import { IApiMailResultModel } from "../../../models/mails/mail.model";
import { SharedQueryKeys } from "../shared-query-keys";

export const getGroupMails = async (
  id: number,
  searchText: string | undefined,
  paginationInputMeta: IPaginationInputMeta,
): Promise<IApiMailResult> => {
  const url = replaceUriParameter(RestEndpoints.MailsForGroup, "groupId", id);
  const response = await getInstance().get<IApiMailResultModel>(url, {
    params: {
      query: searchText,
      ...paginationInputMeta,
    },
  });

  const data: IApiMailResult = {
    mails: response.data.mails.map(MailConverter.toDomain),
    meta: response.data.meta ? PaginationMetaConverter.toDomain(response.data.meta) : response.data.meta,
  };

  return data;
};

export const useGetGroupMails = (
  id: number,
  searchText: string | undefined,
  paginationInputMeta: IPaginationInputMeta,
) => {
  return useQuery(
    [SharedQueryKeys.GetGroupMails, id, searchText],
    () => getGroupMails(id, searchText, paginationInputMeta),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: Number.isFinite(id),
    },
  );
};
