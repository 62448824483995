import { InputLabel, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Control, Controller } from "react-hook-form";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import de from "react-phone-number-input/locale/de.json";
import "react-phone-number-input/style.css";
import { Colors, Shades } from "../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: "15px",
    borderRadius: "3rem",
    height: "40px",
    border: "none",
    boxShadow: `0px 0px 0px 2px ${Shades.gray50}`,
    backgroundColor: Colors.grayLight,
    "&.PhoneInput--disabled": {
      color: Colors.gray,
      opacity: "0.9",
      boxShadow: `0px 0px 0px 2px ${Shades.gray30}`,
      backgroundColor: Shades.gray10,
      "& .PhoneInputInput": {
        color: Colors.gray,
        opacity: 0.9,
      },
      "& .PhoneInputCountryIcon": {
        opacity: 0.5,
      },
    },
    "& .PhoneInputInput": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  invalid: {
    boxShadow: `0px 0px 0px 2px ${Colors.red}`,
  },
  noMargin: {
    margin: 0,
  },
}));

interface IFormPhoneCountryField {
  name: string;
  control: Control<any>;
  required: boolean;
  label: string;
  hasError: boolean;
  disabled?: boolean | undefined;
}

export const FormPhoneCountryField = ({
  name,
  control,
  required,
  label,
  hasError,
  disabled = false,
}: IFormPhoneCountryField) => {
  const classes = useStyles();

  const getClassNames = (): string => {
    return hasError ? [classes.root, classes.invalid].join(" ") : classes.root;
  };

  const getLabelClassNames = (): string => {
    return [classes.noMargin, "MuiInputLabel-shrink"].join(" ");
  };

  const getLabelElement = () => {
    if (!required) return label;

    return <b>{label}</b>;
  };

  return (
    <>
      <InputLabel
        disabled={disabled}
        required={required}
        className={getLabelClassNames()}
        margin={"dense"}
        error={hasError}
      >
        {getLabelElement()}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <PhoneInput
            international
            value={field.value}
            countryCallingCodeEditable={false}
            onChange={(value) => {
              if (value !== undefined) {
                field.onChange(value);
              }
            }}
            disabled={disabled}
            className={getClassNames()}
            defaultCountry="DE"
            labels={de}
          />
        )}
        name={name}
        control={control}
        defaultValue={""}
        rules={{ required: true, validate: isPossiblePhoneNumber }}
      />
    </>
  );
};
