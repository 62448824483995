import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { ICreateFreshdeskTicketRequest } from "../../../domain/requests/create-freshdesk-ticket.request";
import { SharedQueryKeys } from "../shared-query-keys";

const createFreshdeskTicket = async (createData: ICreateFreshdeskTicketRequest): Promise<void> => {
  const { data } = await getInstance().post<void>(RestEndpoints.Freshdesk, createData);
  return data;
};

export const useCreateFreshdeskTicketQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    SharedQueryKeys.CreateFreshdeskTicket,
    ({ createData }: { createData: ICreateFreshdeskTicketRequest }) => createFreshdeskTicket(createData),
    {
      onSuccess: () => {
        enqueueSnackbar(t("freshdesk.createTicketDialog.success"), { variant: "success" });
      },
    },
  );
};
