import { IPaginationMetaModel } from "../../../models/util/pagination-meta.model";

export enum BattGBrandStatusModel {
  Draft = "draft",
  Published = "published",
}

export interface IBattGBrandListModel {
  items: IBattGBrandModel[];
  meta: IPaginationMetaModel;
}

export interface IBattGBrandModel {
  id: number;
  status: BattGBrandStatusModel;
  name: string;
  trademarkRegisterNumber: string | null;
  takeBackViaGrs: boolean;
  startDate: Date;
  endDate: Date | null;
  takeBackDescription: string | null;
  takeBackCompanyName: string | null;
  takeBackContactPersonName: string | null;
  takeBackContactPersonTelephone: string | null;
  takeBackContactPersonEmail: string | null;
  grsBatteryType: IBattGGrsBatteryTypeModel;
}

export interface IBattGGrsBatteryTypeListModel {
  items: IBattGGrsBatteryTypeModel[];
}

export interface IBattGGrsBatteryTypeModel {
  id: number;
  name: string;
  description: string | null;
}
