import { StyleRules, TypographyClassKey } from "@material-ui/core";
import { PrimaryColors, Shades } from "style/Colors";
import {
  FONT_BODY1,
  FONT_BODY2,
  FONT_BUTTON,
  FONT_FAMILY,
  FONT_H1,
  FONT_H2,
  FONT_H3,
  FONT_H4,
  FONT_H5,
  FONT_H6,
} from "./constant";

export const MuiTypography: Partial<StyleRules<TypographyClassKey, {}>> = {
  root: {
    fontFamily: FONT_FAMILY,
  },
  h1: {
    color: PrimaryColors.base,
    fontSize: FONT_H1,
  },
  h2: {
    color: PrimaryColors.base,
    fontSize: FONT_H2,
  },
  h3: {
    color: PrimaryColors.base,
    fontSize: FONT_H3,
  },
  h4: {
    color: PrimaryColors.base,
    fontSize: FONT_H4,
  },
  h5: {
    color: PrimaryColors.base,
    fontSize: FONT_H5,
    fontWeight: "bold",
  },
  h6: {
    color: PrimaryColors.base,
    fontSize: FONT_H6,
  },
  body1: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_BODY1,
  },
  body2: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_BODY2,
  },
  button: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_BUTTON,
    textTransform: "none",
    fontWeight: "normal",
    color: Shades.white,
  },
};
