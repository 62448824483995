import { Grid, makeStyles, Typography } from "@material-ui/core";
import { VFC } from "react";
import { CfmPlatformLogo } from "../../../../../../components/Primitives/Icons";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
  resetButton: {
    border: "none",
  },
});

interface IEmptyWarehouseProps {
  text: string;
}

export const EmptyWarehouse: VFC<IEmptyWarehouseProps> = (props) => {
  const { text } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justifyContent="center">
        <Grid item className={classes.alignCenter}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CfmPlatformLogo />
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>{text}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
