import { formatDateYearMonthDay } from "../../../shared/util/date.util";
import {
  IPomAddAnnouncementModel,
  IPomAddAnnouncementProductModel,
} from "../../repositories/models/announcements/pom-add-announcement.model";
import {
  IPomAnnouncementInvalidProductExcelDataModel,
  IPomAnnouncementProductExcelDataModel,
} from "../../repositories/models/announcements/pom-announcement-excel-data.model";
import { PomAnnouncementStatusModel } from "../../repositories/models/announcements/pom-announcements.model";
import {
  IPomPatchAnnouncementModel,
  IPomPatchAnnouncementProductModel,
} from "../../repositories/models/announcements/pom-patch-announcement.model";
import { AnnouncementUtil } from "../../utils/announcement.util";
import { IPomProductTableData } from "../product-table/pom-product-table-data";
import { IPomAnnouncementTableData } from "./pom-announcement-table-data";

export class PomAnnouncementTableDataConverter {
  public static toDomain(data: IPomProductTableData): IPomAnnouncementTableData {
    const convertedObject: IPomAnnouncementTableData = {
      id: data.id,
      articleName: data.articleName,
      articleNumber: data.articleNumber,
      type: data.type,
      chemistry: data.chemistry,
      weightClass: data.weightClass,
      itemPrice: data.itemPrice,
      takeBackSystem: data.takeBackSystem,
      unit: data.unit,
      lowerWeightLimitInGram: data.lowerWeightLimitInGram,
      upperWeightLimitInGram: data.upperWeightLimitInGram,
    };

    return convertedObject;
  }

  public static toAddModel(
    announcementDate: Date,
    data: IPomAnnouncementTableData[],
    isDraft: boolean,
    purchaseOrderNumber: string,
    contractId: number,
  ): IPomAddAnnouncementModel {
    // always use the first of the month and strip the timezone
    const sanitizedDate = new Date(announcementDate);
    sanitizedDate.setDate(1);

    const productModels = data.map<IPomAddAnnouncementProductModel>((p) => ({
      productId: p.id,
      count: p.count!,
      weight: p.totalWeight!,
    }));

    const convertedObject: IPomAddAnnouncementModel = {
      announcementDate: formatDateYearMonthDay(sanitizedDate),
      status: isDraft ? PomAnnouncementStatusModel.Draft : PomAnnouncementStatusModel.Published,
      products: productModels,
      purchaseOrderNumber,
      contractId,
    };

    return convertedObject;
  }

  public static toPatchPublishedModel(
    announcementId: number | undefined,
    data: IPomAnnouncementTableData[],
    purchaseOrderNumber: string,
    contractId: number,
  ): IPomPatchAnnouncementModel {
    const productModels = data.map<IPomPatchAnnouncementProductModel>((p) => ({
      productId: p.id,
      count: p.count!,
      weight: p.totalWeight!,
    }));

    const convertedObject: IPomPatchAnnouncementModel = {
      announcementId: announcementId,
      status: PomAnnouncementStatusModel.Published,
      products: productModels,
      purchaseOrderNumber,
      contractId,
    };

    return convertedObject;
  }

  public static toPatchDraftModel(
    data: IPomAnnouncementTableData[],
    purchaseOrderNumber: string,
    contractId: number,
  ): IPomPatchAnnouncementModel {
    const productModels = data.map<IPomPatchAnnouncementProductModel>((p) => ({
      productId: p.id,
      count: p.count!,
      weight: p.totalWeight!,
    }));

    const convertedObject: IPomPatchAnnouncementModel = {
      announcementId: undefined,
      status: PomAnnouncementStatusModel.Draft,
      products: productModels,
      purchaseOrderNumber,
      contractId,
    };

    return convertedObject;
  }

  public static fromExcelDataModel(data: IPomAnnouncementProductExcelDataModel): IPomAnnouncementTableData {
    return {
      id: data.productData.id,
      articleName: data.productData.system.name,
      type: data.productData.type.name,
      articleNumber: data.productData.articleNumber,
      chemistry: data.productData.chemistry.name,
      takeBackSystem: data.productData.takeBackSystem.name,
      unit: data.productData.unit,
      lowerWeightLimitInGram:
        data.productData.lowerWeightLimitInGramOverride ?? data.productData.weightClass.lowerWeightLimitInGram,
      upperWeightLimitInGram:
        data.productData.upperWeightLimitInGramOverride ?? data.productData.weightClass.upperWeightLimitInGram,
      weightClass: data.productData.weightClass.name,
      itemPrice: data.productData.netPrice,
      count: data.count,
      totalWeight: data.weight,
      totalPrice: AnnouncementUtil.calculatePrice(
        data.productData.unit,
        data.productData.netPrice,
        data.count,
        data.weight,
      ),
    };
  }

  // Invalid items should be displayed with the article number, but should result in an error
  public static fromInvalidExcelDataModel(
    data: IPomAnnouncementInvalidProductExcelDataModel,
  ): IPomAnnouncementTableData {
    return {
      id: data.rowNumber * -1, // take the negative rowNumber as ID to not have conflicts with real IDs
      articleName: "-",
      type: "-",
      articleNumber: data.articleNumber,
      chemistry: "-",
      takeBackSystem: "-",
      unit: "STK",
      lowerWeightLimitInGram: null,
      upperWeightLimitInGram: null,
      weightClass: "-",
      itemPrice: 0,
      count: data.count,
      totalWeight: data.weight,
      isInvalid: true,
    };
  }
}
