import { GetNextPageParamFunction, useInfiniteQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { IResponseFieldFilter } from "../../../../configurator/components/response-field/response-field.util";
import getInstance from "../../../../utils/rest";
import { IResponseFieldsResponseModel } from "../../models/response-field/cfm-response-field.model";
import { CfmQueryKeys } from "../cfm-query-keys";

const getInfiniteResponseFields = async (
  filter: IResponseFieldFilter,
  pageParam: number | undefined,
): Promise<IResponseFieldsResponseModel> => {
  const page = pageParam ? pageParam - 1 : 0;
  const mappedFilter = {
    type: filter.config.type?.length ? filter.config.type : undefined,
    status: filter.config.status,
    searchText: filter.searchText,
    pagination: { page, pageSize: 5 },
  };

  const { data } = await getInstance().post<IResponseFieldsResponseModel>(
    RestEndpoints.AllResponseFields,
    mappedFilter,
  );
  return data;
};

export const useGetResponseFieldsInfiniteQuery = (
  filter: IResponseFieldFilter,
  getNextPageParam: GetNextPageParamFunction<IResponseFieldsResponseModel>,
) => {
  return useInfiniteQuery(
    [CfmQueryKeys.GetAllResponseFieldsInfinite, filter],
    ({ pageParam }) => getInfiniteResponseFields(filter, pageParam),
    {
      getNextPageParam,
      staleTime: ReactQueryConfig.staleTime,
    },
  );
};
