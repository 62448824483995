import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useAsyncEffect from "../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../shared/domain/scroll/scrolled-to-bottom-context";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { usePackageOverviewContext } from "../../pages/package/package-overview.provider";
import { useProductOverviewContext } from "../../pages/product/product-overview.provider";
import { ArticleConfigFooter } from "../article-config-footer.component";
import { EmptyProductView } from "../empty-container/empty-product-view.component";
import { ProductResponseRow } from "./product-response-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
  emptyContainer: {
    marginBottom: 100,
  },
}));

export const ProductResponseList: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const {
    hasNextPage,
    productsLoading: isLoading,
    fetchNextPage,
    products,
    isFetching,
    isFetchingNextPage,
    resetFilter,
  } = useProductOverviewContext();
  const { selectedProductIds, setSelectedProductIds } = usePackageOverviewContext();

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  const onContinue = () => {
    const url = `${AvailableConfiguratorRoutes.CreateResponseConfig}?ids=${selectedProductIds.join(",")}`;
    history.push(url);
  };

  const areAllSelected = () => {
    const ids = products.map((product) => product.id);
    return selectedProductIds.length > 0 && ids.every((productId) => selectedProductIds.includes(productId));
  };

  const onSelectAllClick = (checked: boolean) => {
    const ids = checked ? products.map((product) => product.id) : [];
    setSelectedProductIds(ids);
  };

  const onProductCheckboxClick = (productId: number, checked: boolean) => {
    if (checked) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(selectedProductIds.filter((selectedProductId) => selectedProductId !== productId));
    }
  };

  const isProductSelected = (productId: number) => {
    return selectedProductIds.find((selectedProductId) => selectedProductId === productId) !== undefined;
  };

  return (
    <>
      <Grid container direction="column" className={classes.emptyContainer}>
        {!isLoading &&
          products &&
          products.map((product, index) => {
            return (
              <Grid item key={index}>
                <ProductResponseRow
                  product={product}
                  assignments={product.responseAssignments}
                  isSelected={isProductSelected(product.id)}
                  onProductCheckboxClick={onProductCheckboxClick}
                />
              </Grid>
            );
          })}
        {products.length === 0 && !isLoading && !isFetching && !isFetchingNextPage && (
          <Grid item>
            <EmptyProductView resetFilter={resetFilter} />
          </Grid>
        )}
        {(isLoading || isFetching || isFetchingNextPage) && (
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={12} className={classes.alignSpinner}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ArticleConfigFooter
        disabled={selectedProductIds.length === 0}
        onButtonClick={onContinue}
        buttonText={t("configuration.toResponseConfig")}
        checkboxLabel={areAllSelected() ? t("general.deselectAll") : t("general.selectAll")}
        onCheckboxChange={onSelectAllClick}
        checked={areAllSelected()}
        displayCheckbox={products.length > 0}
      />
    </>
  );
};
