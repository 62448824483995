import { createContext, FunctionComponent, useContext, useMemo, useState } from "react";
import { useGetBrandsQuery } from "../../../../../put-on-market/repositories/queries/batt-g/get-brands.query";
import { BattGBrandStatusFilter, IBattGBrand } from "../../../../models/batt-g/batt-g-brand";

interface IBattGBrandProvider {
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  filters: BattGBrandStatusFilter[];
  setFilters: (status: BattGBrandStatusFilter[]) => void;
  setNewPageSize: (page: number) => void;
  itemCount: number;
  brands: IBattGBrand[];
  fetchBrands: () => Promise<void>;
}

export const BattGBrandContext = createContext<IBattGBrandProvider>({} as IBattGBrandProvider);

export const useBattGBrandContext = () => {
  return useContext(BattGBrandContext);
};

const useBattGBrandProvider = (): IBattGBrandProvider => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [itemCount, setItemCount] = useState<number>(0);
  const [filters, setFilters] = useState<BattGBrandStatusFilter[]>([]);
  const { data: brandsResult, isLoading, refetch: refetchBrands } = useGetBrandsQuery(page, pageSize, filters);

  const brands = useMemo(() => {
    setItemCount(brandsResult?.meta.numberOfRecords ?? 0);
    return brandsResult?.items ?? [];
  }, [brandsResult]);

  const fetchBrands = async () => {
    refetchBrands();
  };

  return {
    isLoading,
    page,
    pageSize,
    itemCount,
    setNewPageSize: setPageSize,
    setPage,
    brands,
    fetchBrands,
    filters,
    setFilters,
  };
};

export const BattGBrandProvider: FunctionComponent = (props) => {
  const value = useBattGBrandProvider();
  return <BattGBrandContext.Provider value={value}>{props.children}</BattGBrandContext.Provider>;
};
