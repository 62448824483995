import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";
import { IConfigurationOption } from "../product/product-configuration.component";

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    border: "1px solid",
    borderRadius: "4px",
  },
  optionText: {
    fontWeight: "bold",
    padding: "3px 6px 1px 6px",
  },
  optionTextNotSelected: {
    color: PrimaryColors.base,
  },
  optionTextSelected: {
    color: Shades.white,
  },
  optionTextDisabled: {
    color: Colors.gray,
  },
  optionSelected: {
    backgroundColor: PrimaryColors.base,
  },
  optionNotSelected: {
    backgroundColor: Shades.white,
  },
  optionNotDisabled: {
    borderColor: PrimaryColors.base,
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionDisabled: {
    borderColor: Colors.gray,
    "&:hover": {
      cursor: "default",
    },
  },
  label: {
    textAlign: "end",
    marginTop: 2,
  },
  labelContent: {
    minWidth: 60,
  },
}));

interface IResponseFieldConfigurationProps {
  selectedOptions: IConfigurationOption[] | undefined;
  options: IConfigurationOption[];
  onSelect: (value: IConfigurationOption | IConfigurationOption[] | undefined) => void;
  label: string;
  multiSelect: boolean;
  disabled: boolean;
}

export const ResponseFieldFilterConfiguration: VFC<IResponseFieldConfigurationProps> = (props) => {
  const { options, label, selectedOptions, multiSelect, onSelect, disabled } = props;
  const classes = useStyles();

  const handleClick = (option: IConfigurationOption) => {
    onSelect(option);
  };

  const handleClickMultiple = (option: IConfigurationOption) => {
    const alreadyExisting = (selectedOptions ?? []).find((selectedOption) => selectedOption.value === option.value);
    let options: IConfigurationOption[] | undefined;
    if (alreadyExisting) {
      options = (selectedOptions ?? []).filter((selectedOption) => selectedOption.value !== option.value);
    } else {
      options = (selectedOptions ?? []).concat(option);
    }
    onSelect(options);
  };

  const isSelected = (option: IConfigurationOption) => {
    return (selectedOptions ?? []).some((selectedOption) => selectedOption.value === option.value);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item className={classes.label}>
        <Typography variant="body1" className={classes.labelContent}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" spacing={1}>
          {options.map((option) => {
            return (
              <Grid item key={option.value}>
                <Box
                  id={option.value.toString()}
                  onClick={() => (multiSelect ? handleClickMultiple(option) : handleClick(option))}
                  className={classNames(classes.option, {
                    [classes.optionSelected]: isSelected(option),
                    [classes.optionNotSelected]: !isSelected(option),
                    [classes.optionNotDisabled]: !disabled,
                    [classes.optionDisabled]: disabled,
                  })}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classNames(classes.optionText, {
                      [classes.optionTextSelected]: isSelected(option),
                      [classes.optionTextNotSelected]: !isSelected(option),
                      [classes.optionTextDisabled]: disabled,
                    })}
                  >
                    {option.label}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
