import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { DangerousDeleteIconNoBorderButton } from "../../../components/Primitives";
import { Colors, Shades } from "../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../utils/constants";
import { ICfmOrderX } from "../../domain/order-x/cfm-order-x";
import { getPackageQuantityInformation } from "../../pages/order-x/utils/order-x-card.util";
import { TruckloadOrderInfo } from "./truckload-order-info.component";
import { TruckloadProductAndWeightInfoProps } from "./truckload-product-and-weight-info.component";

const useStyles = makeStyles((theme: Theme) => ({
  orderContainer: {
    backgroundColor: Colors.grayLight,
    padding: 10,
  },
  container: {
    height: "100%",
  },
  iconDimensions: {
    height: 40,
    width: 40,
  },
  dateText: {
    color: Shades.gray60,
  },
  orderTitle: {
    paddingBottom: 8,
  },
}));

interface IOrderOfTruckloadRowProps {
  order: ICfmOrderX;
  onRemoveClick: VoidFunction;
  isRemoveEnabled: boolean;
}

export const OrderOfTruckloadRow: VFC<IOrderOfTruckloadRowProps> = (props) => {
  const { order, onRemoveClick, isRemoveEnabled } = props;
  const classes = useStyles();
  const packageItem = useMemo(() => order.items.find((item) => item.package) ?? undefined, [order]);
  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="space-between" className={classes.orderContainer}>
      <Grid item xs={11}>
        <Grid container direction="column" spacing={0}>
          <Grid item className={classes.orderTitle}>
            <TruckloadOrderInfo orderId={order.id} date={order.orderDate} address={order.customerAddress} />
          </Grid>
          <Grid item>
            <TruckloadProductAndWeightInfoProps
              productItem={order.items.find((item) => item.product)}
              weight={order.warehouseWeight}
            />
          </Grid>
          <Grid item container direction="row">
            <Grid item>
              <Typography variant="body2">{packageItem?.package?.name ?? "-"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {TEXT_SEPARATOR} {getPackageQuantityInformation(t, packageItem?.quantity ?? 0)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isRemoveEnabled && (
        <Grid item xs={1}>
          <Box display={"flex"} alignItems={"center"} justifyContent="flex-end" className={classes.container}>
            <DangerousDeleteIconNoBorderButton className={classes.iconDimensions} onClick={onRemoveClick} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
