import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { ICfmProductResponseAssignment } from "../../../collect-from-market/domain/routing-assignment/cfm-product-response-assignment";
import { OrderCheckbox } from "../../../components/checkbox/order-checkbox.component";
import { ResponseConfigSmallIcon } from "../../../components/Primitives/Icons";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { Badge } from "../badge/badge.component";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import { getRedirectToProductResponseEditConfigUrl } from "../../pages/product/components/product-overview.utils";
import { ArticleNumberBox } from "../product/article-number-box.component";
import { ProductName } from "../product/product-name.component";
import { ProductResponseDialog } from "./dialog/response-assignment-detail-dialog.component";

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
  },
  boldText: {
    fontWeight: "bold",
    marginTop: 3,
  },

  container: { height: "100%" },
  routingIcon: {
    marginRight: 20,
  },
  noRoutingIcon: {
    color: Colors.red,
  },
  hasRoutingIcon: {
    color: PrimaryColors.base,
  },
  badgeContainer: { marginRight: 40, marginLeft: 10 },
  checkboxContainer: {
    marginLeft: 15,
  },
}));

interface IProductResponseRowProps {
  product: ICfmProduct;
  assignments: ICfmProductResponseAssignment[] | undefined;
  onProductCheckboxClick: (productId: number, checked: boolean) => void;
  isSelected: boolean;
}

export const ProductResponseRow: VFC<IProductResponseRowProps> = (props) => {
  const { product, assignments, onProductCheckboxClick, isSelected } = props;
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isResponseDialogOpen, setIsResponseDialogOpen] = useState(false);

  const responses = useMemo(() => product.responseAssignments ?? [], [product]);
  const onCheckboxChange = (checked: boolean) => {
    onProductCheckboxClick(product.id, checked);
  };

  const redirectToProductResponseEditConfig = () => {
    const assignmentIds = (product.responseAssignments ?? []).map((assignment) => assignment.id);
    if (!assignmentIds) {
      enqueueSnackbar(
        t("general.error_occurred", { errorCode: 500, errorMsg: "missing response assignment relation" }),
        { variant: "error" },
      );
      return;
    }

    history.push(getRedirectToProductResponseEditConfigUrl(product.id, assignmentIds));
  };

  return (
    <>
      {isResponseDialogOpen && (
        <ProductResponseDialog
          open={isResponseDialogOpen}
          product={product}
          onCancel={() => setIsResponseDialogOpen(false)}
          onEdit={redirectToProductResponseEditConfig}
        />
      )}
      <BoxedRowGrid justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" justifyContent="center" className={classes.container}>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item className={classes.checkboxContainer}>
                  <OrderCheckbox onChange={onCheckboxChange} checked={isSelected} size="large" />
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="center" className={classes.container}>
                    <Grid item>
                      <ArticleNumberBox articleNumber={product.articleNumber} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="center" className={classes.container}>
                    <Grid item>
                      <ProductName name={product.name} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {responses.length > 0 && (
          <Grid item>
            <Grid container direction="column" justifyContent="center" className={classes.container}>
              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    <ResponseConfigSmallIcon
                      viewBox="0 0 25 25"
                      className={classNames(classes.iconStyle, {
                        [classes.noRoutingIcon]: !assignments,
                        [classes.hasRoutingIcon]: assignments,
                      })}
                      onClick={() => setIsResponseDialogOpen(true)}
                    />
                  </Grid>
                  <Grid item className={classes.badgeContainer}>
                    <Badge count={responses.length} backgroundColor={PrimaryColors.base} textColor={Colors.white} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </BoxedRowGrid>
    </>
  );
};
