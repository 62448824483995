import { useMediaQuery } from "@material-ui/core";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AvailableMenus, AvailableMenuValue } from "../../util/sidebar.utils";

interface IUsersContextType {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  isFixed: boolean;
  activeMenu: AvailableMenuValue;
  setActiveMenu: (menu: AvailableMenuValue) => void;
}

interface ISidebarProps {
  children: any;
}

const SidebarContext = createContext<IUsersContextType>({} as IUsersContextType);

export const SidebarProvider = (props: ISidebarProps) => {
  const value = useSidebarProvider(props);
  return <SidebarContext.Provider value={value}>{props.children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => {
  return useContext(SidebarContext);
};

const useSidebarProvider = (props: ISidebarProps): IUsersContextType => {
  const fullScreen = useMediaQuery("(min-width:1190px)");
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<AvailableMenuValue>(AvailableMenus.None);

  useEffect(() => {
    setIsFixed(fullScreen);
    fullScreen && setSidebarOpen(true);
  }, [fullScreen]);

  return {
    sidebarOpen,
    setSidebarOpen,
    isFixed,
    activeMenu,
    setActiveMenu,
  };
};
