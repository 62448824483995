import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getOrderDeliveryReport = async (orderIds: number[]): Promise<string> => {
  const { data } = await getInstance().post<string>(RestEndpoints.CfmOrderXDeliveryReport, {
    ids: orderIds,
  });

  return data;
};

export const useGetOrderXDeliveryReport = (orderIds: number[]) => {
  return useMutation([CfmQueryKeys.GetOrderXDeliveryReport, orderIds], () => getOrderDeliveryReport(orderIds), {});
};
