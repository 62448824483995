import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  textAlignCenter: {
    textAlign: "center",
  },
  fullHeight: {
    height: "100%",
  },
}));

export const SummaryLoadingSpinner: FC = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.textAlignCenter}>
      <Grid container direction="column" justifyContent="center" className={classes.fullHeight}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Grid>
  );
};
