import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { ApiErrorCodes } from "../../../../api/api-error-codes";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { EnqueueSnackbarFunc } from "../../../../collect-from-market/repositories/queries/response-field/update-response-field.query";
import getInstance from "../../../../utils/rest";
import { TranslateFunc } from "../../../../utils/translation.utils";
import { ICreateGroupContractRequest, IMutateGroupContractRequest } from "../../../domain/group/group-contract";
import { SharedQueryKeys } from "../shared-query-keys";

const createGroupContract = async (groupId: number, data: ICreateGroupContractRequest): Promise<void> => {
  const url = replaceUriParameter(RestEndpoints.CreateGroupContract, "id", groupId);
  await getInstance().post(url, data);
};

const handleError = (error: unknown, enqueueFunc: EnqueueSnackbarFunc, t: TranslateFunc) => {
  const errorCode = getErrorCode(error);
  const errorStatusCode = getErrorStatusCode(error);

  if (errorCode === ApiErrorCodes.GroupContractNumberNotAvailable) {
    enqueueFunc(t("basedata.groups.groupContracts.contractNumberNotAvailable"), { variant: "error" });
  } else if (errorCode === ApiErrorCodes.PomContractAlreadyExists) {
    enqueueFunc(t("basedata.groups.groupContracts.pomContractAlreadyExists"), {
      variant: "error",
      preventDuplicate: true,
    });
  } else if (errorCode === ApiErrorCodes.CfmContractAlreadyExists) {
    enqueueFunc(t("basedata.groups.groupContracts.cfmContractAlreadyExists"), {
      variant: "error",
      preventDuplicate: true,
    });
  } else {
    enqueueFunc(
      t("general.error_occurred", {
        errorCode: errorStatusCode,
        errorMsg: errorCode,
      }),
      { variant: "error" },
    );
  }
};

export const useCreateGroupContract = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation(
    SharedQueryKeys.CreateGroupContracts,
    ({ groupId, data }: { groupId: number; data: ICreateGroupContractRequest }) => createGroupContract(groupId, data),
    {
      onSuccess: () => {
        enqueueSnackbar(t("general.successfull_saved"), { variant: "success" });
      },
      onError: (error) => {
        handleError(error, enqueueSnackbar, t);
      },
    },
  );
};

const updateGroupContract = async (
  groupId: number,
  contractId: number,
  data: IMutateGroupContractRequest,
): Promise<void> => {
  let url = replaceUriParameter(RestEndpoints.UpdateGroupContract, "contractId", contractId);
  url = replaceUriParameter(url, "groupId", groupId);
  await getInstance().post(url, data);
};

export const useUpdateGroupContract = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation(
    SharedQueryKeys.UpdateGroupContracts,
    ({ groupId, contractId, data }: { groupId: number; contractId: number; data: IMutateGroupContractRequest }) =>
      updateGroupContract(groupId, contractId, data),
    {
      onSuccess: () => {
        enqueueSnackbar(t("general.successfull_saved"), { variant: "success" });
      },
      onError: (error) => {
        handleError(error, enqueueSnackbar, t);
      },
    },
  );
};
