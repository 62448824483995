import lodash from "lodash";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { AvailablePomRoutes, AvailableSharedRoutes } from "../../../../utils/constants";
import { IGroupOrManufacturer } from "../../../domain/group/group-or-manufacturer";
import { IPaginationMeta } from "../../../domain/pagination/pagination-meta";
import { IGetGroupsFilter } from "../../../repositories/models/group/get-group.model";
import { useGroupAndManufacturerContext } from "./group-and-manufacturer-context";

interface IGroupAndManufacturerTableContext {
  groups: IGroupOrManufacturer[];
  isLoading: boolean;
  entryCount: number;
  toggleGroupStatus: (group: IGroupOrManufacturer) => Promise<void>;
  tablePagination: IPaginationMeta;
  setTablePagination: (pagination: IPaginationMeta) => void;
  filter: IGetGroupsFilter;
  setFilter: (filters: IGetGroupsFilter) => void;
  onAddGroup: () => void;
  onEditManufacturer: (id: number) => void;
  onShowManufacturerDetail: (id: number) => void;
  onShowGroupDetail: (id: number) => void;
  isFilterDirty: boolean;
  resetFilter: () => void;
}

export const GroupAndManufacturerTableContext = createContext<IGroupAndManufacturerTableContext>(
  {} as IGroupAndManufacturerTableContext,
);

export const useGroupAndManufacturerTableContext = () => {
  return useContext(GroupAndManufacturerTableContext);
};

const useGroupAndManufacturerTableProvider = (): IGroupAndManufacturerTableContext => {
  const {
    isLoading,
    tablePagination,
    setTablePagination,
    filter,
    setFilter,
    groups,
    refetchGroups,
    updateGroupActive,
  } = useGroupAndManufacturerContext();
  const [entryCount, setEntryCount] = useState<number>(50);
  const history = useHistory();

  useEffect(() => {
    setEntryCount(groups && groups.length > 50 ? 50 : groups.length);
  }, [groups]);

  const toggleGroupStatus = async (group: IGroupOrManufacturer) => {
    if (!group.id) {
      return;
    }

    await updateGroupActive(group.id, !group.active);
    await refetchGroups();
  };

  const onAddGroup = () => {
    history.push(AvailableSharedRoutes.GroupNew);
  };

  const onShowGroupDetail = (id: number) => {
    history.push(AvailableSharedRoutes.GroupDetail.replace(":id", id.toString()));
  };

  const onShowManufacturerDetail = (id: number) => {
    history.push(AvailablePomRoutes.ManufacturerDetail.replace(":id", id.toString()));
  };

  const onEditManufacturer = (id: number) => {
    history.push(AvailablePomRoutes.ManufacturerEdit.replace(":id", id.toString()));
  };

  const isFilterDirty = useMemo(
    () =>
      !lodash.isEmpty(filter?.active) ||
      !lodash.isEmpty(filter?.searchQuery) ||
      !lodash.isEmpty(filter?.contractTypes || !lodash.isEmpty(filter?.groupTypes)),
    [filter],
  );

  const resetFilter = () =>
    setFilter({
      active: undefined,
      searchQuery: undefined,
      contractTypes: undefined,
      groupTypes: [],
    });

  return {
    groups,
    setTablePagination,
    filter,
    setFilter,
    tablePagination,
    entryCount,
    toggleGroupStatus,
    isLoading,
    onShowGroupDetail,
    onAddGroup,
    isFilterDirty,
    resetFilter,
    onShowManufacturerDetail,
    onEditManufacturer,
  };
};

export const GroupAndManufacturerTableProvider: React.FC = (props) => {
  const value = useGroupAndManufacturerTableProvider();
  return (
    <GroupAndManufacturerTableContext.Provider value={value}>
      {props.children}
    </GroupAndManufacturerTableContext.Provider>
  );
};
