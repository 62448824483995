import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IIsPasswordResetRequiredResultModel } from "../../../models/user/user.model";
import { SharedQueryKeys } from "../shared-query-keys";

const isPasswordResetRequired = async (): Promise<boolean> => {
  const { data } = await getInstance().get<IIsPasswordResetRequiredResultModel>(RestEndpoints.IsPasswordResetRequired);
  return data.isResetRequired;
};

export const useIsPasswordResetRequiredQuery = (enabled?: boolean) => {
  return useQuery([SharedQueryKeys.IsPasswordResetRequired], () => isPasswordResetRequired(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
