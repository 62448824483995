export enum GroupHistoryGroupGroupTypeInfoColumn {
  GroupId = "groupId",
  TypeId = "typeId",
}

export enum GroupHistoryGroupInfoColumn {
  Id = "id",
  Uuid = "uuid",
  Name = "name",
  Name2 = "name2",
  Description = "description",
  ErpNumber = "erpNumber",
  Active = "active",
  ExternalId = "externalId",
  ContractStartDate = "contractStartDate",
  ContractEndDate = "contractEndDate",
  ContractNumberCfm = "contractNumberCfm",
  ContractNumberPom = "contractNumberPom",
  PomAnnouncementPeriod = "pomAnnouncementPeriod",
  EarNumber = "earNumber",
  EarTemporaryNumber = "earTemporaryNumber",
  VatId = "vatId",
  DefaultPriceGroup = "defaultPriceGroup",
  WebAddress = "webAddress",
  TradeRegisterNumber = "tradeRegisterNumber",
  GrsManagedEar = "grsManagedEar",
  HasAcceptedSepaMandate = "hasAcceptedSepaMandate",
  GroupBranchId = "groupBranchId",
}

export enum GroupHistoryAddressInfoColumn {
  Id = "id",
  Uuid = "uuid",
  Type = "type",
  Name = "name",
  Name2 = "Name2",
  Address = "address",
  AddressText = "addressText",
  BuildingNumber = "buildingNumber",
  City = "city",
  Postal = "postal",
  State = "state",
  Country = "country",
  Lat = "lat",
  Long = "long",
  ContactPersonEmail = "contactPersonEmail",
  ContactPersonName = "contactPersonName",
  ContactPersonTelephone = "contactPersonTelephone",
  ExternalId = "externalId",
  Active = "active",
}

export enum GroupHistoryUserInfoColumn {
  Id = "id",
  Uuid = "uuid",
  GroupId = "group_id",
  Title = "title",
  FirstName = "first_name",
  LastName = "last_name",
  DeliveryMail = "delivery_email",
  Email = "email",
  Telephone = "telephone",
  Language = "language",
  JobTitle = "jobtitle",
  UserTypeLevel = "user_type_level",
  MustResetPassword = "must_reset_password",
  Locked = "locked",
  RolePom = "role_pom",
  ExternalId = "external_id",
  Active = "active",
}

export enum GroupHistoryPaymentInfoColumn {
  PaymentInfoId = "paymentInfoId",
  Name = "name",
  Iban = "iban",
  BicOrSwift = "bicOrSwift",
}

export enum GroupHistoryGroupContractInfoColumn {
  Id = "id",
  ContractId = "contractId",
  GroupId = "groupId",
  ContractNumber = "contractNumber",
  ContractField = "contractField",
  DefaultPriceGroup = "defaultPriceGroup",
  Type = "type",
  StartDate = "startDate",
  EndDate = "endDate",
  Active = "active",
  PomAnnouncementPeriod = "pomAnnouncementPeriod",
}
