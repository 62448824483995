// regex https://github.com/validatorjs/validator.js/blob/8c4b3b35c3bd36dc3983aadb045422847154451b/src/lib/isStrongPassword.js#L85
/* eslint-disable */
const upperCaseRegex = /^[A-Z]$/;
const lowerCaseRegex = /^[a-z]$/;
const numberRegex = /^[0-9]$/;
const symbolRegex = /^[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]$/;
/* eslint-enable */

export interface IAnalysisResult {
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
  isLength: boolean;
  isValid?: boolean;
}

export const passwordAnalyser = (value: string): IAnalysisResult => {
  let hasUpperCase = false;
  let hasLowerCase = false;
  let hasNumber = false;
  let hasSymbol = false;

  if (value) {
    Object.keys(value).forEach((index) => {
      const char = value[+index];
      if (!hasUpperCase && upperCaseRegex.test(char)) {
        hasUpperCase = true;
      }
      if (!hasLowerCase && lowerCaseRegex.test(char)) {
        hasLowerCase = true;
      }
      if (!hasNumber && numberRegex.test(char)) {
        hasNumber = true;
      }
      if (!hasSymbol && symbolRegex.test(char)) {
        hasSymbol = true;
      }
    });
  }

  const isLength = value && value.length >= 10 ? true : false;

  return {
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSymbol,
    isLength,
    isValid: hasUpperCase && hasLowerCase && hasNumber && hasSymbol && isLength ? true : false,
  };
};
