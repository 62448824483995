import { Box, Grid, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { IAddress } from "../../../../shared/domain/address/address";
import { useProductPackageDataDisplayStyle } from "../../../../style/product-package-data-display.style";
import { TruckloadInfoBoxContent } from "./truckload-info-box-content.component";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";

interface ITruckloadInfoBoxViewProps {
  truckload: ICfmTruckloadWithOrders;
  targetAddress: IAddress | undefined;
  plannedDeliveryDate: Date | undefined;
  onEditClick?: VoidFunction;
}

export const TruckloadInfoBoxView: VFC<ITruckloadInfoBoxViewProps> = (props) => {
  const { truckload, onEditClick, targetAddress, plannedDeliveryDate } = props;
  const { t } = useTranslation();
  const classes = useProductPackageDataDisplayStyle();

  return (
    <Grid container direction="column">
      <Grid item>
        <Box className={classes.labelContainer}>
          <Typography variant="body1" className={classes.label}>
            {t("orders.truckload.details")}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.dataContainer}>
        <TruckloadInfoBoxContent
          truckload={truckload}
          targetAddress={targetAddress}
          plannedDeliveryDate={plannedDeliveryDate}
          onEditClick={onEditClick}
          contentPadding={true}
        />
      </Grid>
    </Grid>
  );
};
