import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Colors, PrimaryColors } from "../../../style/Colors";

const badgePadding = "0px 8px 0px 8px";

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    borderStyle: "solid",
    borderWidth: "2px",
    padding: badgePadding,
    borderRadius: "4px",
  },
  activeBadge: {
    border: PrimaryColors.base,
    borderStyle: "solid",
    borderWidth: "2px",
    padding: badgePadding,
    borderRadius: "4px",
  },
  inactiveBadge: {
    border: Colors.red,
    borderStyle: "solid",
    borderWidth: "2px",
    padding: badgePadding,
    borderRadius: "4px",
  },
  activeText: {
    color: PrimaryColors.base,
  },
  inactiveText: {
    color: Colors.red,
  },
}));

interface IResponseFieldActiveBadgeProps {
  isActive: boolean;
}

export const ResponseFieldActiveBadge: VFC<IResponseFieldActiveBadgeProps> = (props) => {
  const { isActive } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(classes.badge, {
        [classes.activeBadge]: isActive,
        [classes.inactiveBadge]: !isActive,
      })}
    >
      <Typography
        variant="body2"
        className={classNames(classes.activeText, {
          [classes.activeText]: isActive,
          [classes.inactiveText]: !isActive,
        })}
      >
        <strong>
          {isActive ? t("configuration.responseField.badge.active") : t("configuration.responseField.badge.inactive")}
        </strong>
      </Typography>
    </Box>
  );
};
