import { Grid, LinearProgress } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetProductCategoriesQuery } from "../../../../collect-from-market/repositories/queries/product/query/get-product-categories.query";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormField } from "../../form/form-field.component";
import { FormHeader } from "../../form/form-header.component";
import { GroupContractFormMode, IGroupContractFormInputs } from "./group-contract-form.component";

interface ICfmGroupContractFormProps {
  mode: GroupContractFormMode;
}

export const CfmGroupContractForm: FC<ICfmGroupContractFormProps> = (props) => {
  const { mode } = props;
  const { t } = useTranslation();
  const readOnly = mode === GroupContractFormMode.Detail;

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<IGroupContractFormInputs>();

  const productCategoryId = watch("productCategoryId");

  const { isLoading, data: productCategories } = useGetProductCategoriesQuery();

  useEffect(() => {
    if (!productCategories || productCategories.length === 0 || !productCategoryId) return;

    setValue("productCategoryId", productCategoryId);
  }, [productCategories, productCategoryId, setValue]);

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <>
          <Grid item className={"px-2"} xs={12} md={12}>
            <FormHeader>{t("basedata.group.cfm_heading")}</FormHeader>
          </Grid>
          <FormField md={12}>
            <FormAutocomplete<number>
              name="productCategoryId"
              required
              rules={{ required: true }}
              control={control}
              disableClearable
              disabled={readOnly}
              label={t("basedata.groups.groupContracts.productCategory")}
              error={Boolean(errors?.productCategoryId)}
              options={productCategories?.map((category) => category.id) ?? []}
              getOptionLabel={(value) => {
                const category = (productCategories ?? []).find((category) => category.id === value);
                return category?.name ?? "";
              }}
            />
          </FormField>
        </>
      )}
    </>
  );
};
