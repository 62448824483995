import { IAddressFormInputs } from "../../components/address/address-form/address-form.component";
import { IAddressLeanModel } from "../../models/address/address-lean.model";
import { ICreateAddressModel } from "../../models/address/create-address.model";
import { IAddressLean } from "../address/address-lean";
import { IAddressLeanFormInput } from "../address/address-lean-form-input";

export class AddressLeanConverter {
  public static toModel(address: IAddressLean): IAddressLeanModel {
    return {
      id: address.id,
      type: address.type,
      name: address.name,
      name2: address.name2,
      address: address.address,
      formattedAddress: address.formattedAddress,
      addressText: address.addressText,
      buildingNumber: address.buildingNumber,
      city: address.city,
      postal: address.postal,
      state: address.state,
      country: address.country,
      lat: address.lat,
      long: address.long,
      contactPersonEmail: address.contactPersonEmail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonTelephone,
      active: address.active,
      groupId: address.groupId,
      openingHours: address.openingHours,
    };
  }

  public static toDomain(address: IAddressLeanModel): IAddressLean {
    return {
      id: address.id ?? undefined,
      type: address.type,
      name: address.name,
      name2: address.name2,
      address: address.address,
      addressText: address.addressText,
      buildingNumber: address.buildingNumber,
      city: address.city,
      postal: address.postal,
      state: address.state ?? null,
      country: address.country,
      lat: address.lat,
      long: address.long,
      contactPersonEmail: address.contactPersonEmail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonTelephone,
      active: address.active,
      groupId: address.groupId,
      openingHours: address.openingHours,
      formattedAddress: address.formattedAddress,
    };
  }

  public static formInputsToModel(address: IAddressLeanFormInput): IAddressLeanModel {
    if (!address.id) {
      throw Error("Could not convert address lean form input to model, address id not set");
    }
    return {
      type: address.type,
      name: address.name,
      name2: address.name2,
      address: address.address,
      addressText: address.addressText,
      buildingNumber: address.buildingNumber,
      city: address.city,
      postal: address.postal,
      state: address.state ?? null,
      country: address.country,
      lat: address.lat,
      long: address.long,
      contactPersonEmail: address.contactPersonEmail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonTelephone,
      groupId: address.groupId,
      openingHours: address.openingHours ?? undefined,
      active: address.active ?? undefined,
      id: address.id,
    };
  }

  public static formInputsToCreateRequest(address: IAddressLeanFormInput): ICreateAddressModel {
    return {
      type: address.type,
      name: address.name,
      name2: address.name2,
      address: address.address,
      addressText: address.addressText,
      buildingNumber: address.buildingNumber,
      city: address.city,
      postal: address.postal,
      state: address.state ?? null,
      country: address.country,
      lat: address.lat,
      long: address.long,
      contactPersonEmail: address.contactPersonEmail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonTelephone,
      groupId: address.groupId,
      openingHours: address.openingHours,
      active: address.active ?? null,
    };
  }

  public static formInputsToDomain(address: IAddressFormInputs, groupId: number): IAddressLean {
    return {
      id: -1,
      type: address.type,
      name: address.name,
      name2: address.name2,
      address: address.address,
      addressText: address.addressText,
      buildingNumber: address.buildingNumber,
      city: address.city,
      postal: address.postal,
      state: address.state ?? null,
      country: address.country.code,
      lat: address.lat,
      long: address.lng,
      contactPersonEmail: address.contactPersonMail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonPhone,
      active: address.active ? new Date() : undefined,
      groupId: groupId,
      openingHours: address.openingHours ?? undefined,
    };
  }
}
