import { IApiMailModel } from "../../models/mails/mail.model";
import { IApiMail } from "../mail/mail";

export class MailConverter {
  static toDomain(mail: IApiMailModel): IApiMail {
    return {
      id: mail.id,
      messageTypeId: mail.messageTypeId,
      createdAt: mail.createdAt,
      updatedAt: mail.updatedAt,
      errorShort: mail.errorShort,
      errorLong: mail.errorLong,
      protocol: mail.protocol,
      mandateUuid: mail.mandateUuid,
      system: mail.system,
      subSystem: mail.subSystem,
      environment: mail.environment,
      from: mail.from,
      fromName: mail.fromName,
      to: mail.to,
      cc: mail.cc,
      bcc: mail.bcc,
      subject: mail.subject,
      content: mail.content,
      html: mail.html,
      attachments: mail.attachments,
      type: mail.type,
      userId: mail.userId,
      groupId: mail.groupId,
      mailTemplateType: mail.mailTemplateType,
    };
  }
}
