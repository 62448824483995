import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CfmServiceDate } from "../../../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { removeNullAndUndefined } from "../../../../../utils/filter.util";
import { useProductResponseAssignmentContext } from "../../../../pages/product-response/product-response-assignment.provider";
import { getResponseFieldServiceDates } from "../../../response-field/response-field.util";
import { IResponseAssignmentFormInputs } from "../../dialog/assign-response-fields-dialog.component";
import { ProductResponseServiceDateFilter } from "../../product-response-service-date-filter.component";

const useStyles = makeStyles((theme: Theme) => ({
  fieldTitle: {
    fontWeight: "bold",
  },
}));

export const ServiceDateForm: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<IResponseAssignmentFormInputs>();
  const { activeStatus, selectedResponseAssignmentRowValues } = useProductResponseAssignmentContext();

  const serviceDates: CfmServiceDate[] = watch("serviceDates");
  const isServiceDateSelected = (serviceDate: CfmServiceDate) => {
    return serviceDates && serviceDates.find((date) => date === serviceDate) !== undefined;
  };

  const isServiceDateDisabled = (cfmServiceDate: CfmServiceDate) => {
    const serviceDatesInUse = selectedResponseAssignmentRowValues
      .filter((selectedVal) => selectedVal.status !== activeStatus)
      .flatMap((selectedVal) => selectedVal.serviceDates)
      .filter(removeNullAndUndefined);

    return serviceDatesInUse.includes(cfmServiceDate);
  };

  const onSelectServiceDate = (serviceDate: CfmServiceDate) => {
    if (isServiceDateDisabled(serviceDate)) return;

    if (isServiceDateSelected(serviceDate)) {
      setValue(
        "serviceDates",
        serviceDates.filter((date) => date !== serviceDate),
      );
    } else {
      setValue("serviceDates", [...(serviceDates ?? []), serviceDate]);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body1" className={classes.fieldTitle}>
          {t("configuration.responseAssignments.dates.plural")}
        </Typography>
      </Grid>
      <Grid item container direction="row" spacing={2} justifyContent="flex-start" alignContent="center">
        {getResponseFieldServiceDates().map((cfmServiceDate) => {
          return (
            <Grid item>
              <ProductResponseServiceDateFilter
                cfmServiceDate={cfmServiceDate}
                isActive={isServiceDateSelected(cfmServiceDate)}
                onClick={() => onSelectServiceDate(cfmServiceDate)}
                disabled={isServiceDateDisabled(cfmServiceDate)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
