import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { Theme, withTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PrimaryColors, Shades } from "style/Colors";
import { tableIcons } from "../../utils/material-table.utilts";

interface ICustomTableProps<RowData extends object> extends MaterialTableProps<RowData> {
  theme: Theme;
}

const CustomTable = <RowData extends object>(props: ICustomTableProps<RowData>) => {
  const { t } = useTranslation();

  const { ...materialTableProps }: MaterialTableProps<RowData> = props;

  // align columns center by default
  const columns = materialTableProps.columns.map((col) => {
    col.align = "center";
    return col;
  });

  return (
    <MaterialTable
      {...materialTableProps}
      columns={columns}
      icons={tableIcons}
      style={{ width: "100%", boxShadow: "none" }}
      localization={{
        header: {
          actions: "",
        },
        toolbar: {
          nRowsSelected: t("table.nRowsSelected"),
          searchTooltip: t("table.toolbar.searchTooltip"),
          searchPlaceholder: t("table.toolbar.searchPlaceholder"),
        },
        body: {
          emptyDataSourceMessage: t("table.body.emptyDataSourceMessage"),
          editTooltip: t("table.editTooltip"),
          deleteTooltip: t("table.deleteTooltip"),
          editRow: {
            deleteText: t("table.editRow.deleteText"),
            cancelTooltip: t("table.editRow.cancelTooltip"),
            saveTooltip: t("table.editRow.saveTooltip"),
          },
        },
        pagination: {
          labelDisplayedRows: t("table.labelDisplayedRowsCustomTable"),
          labelRowsSelect: t("table.labelRowsSelect"),
          labelRowsPerPage: t("table.labelRowsPerPage"),
          firstTooltip: t("table.firstTooltip"),
          previousTooltip: t("table.previousTooltip"),
          nextTooltip: t("table.nextTooltip"),
          lastTooltip: t("table.lastTooltip"),
        },
        ...materialTableProps.localization,
      }}
      options={{
        headerStyle: {
          backgroundColor: PrimaryColors.base,
          color: Shades.white,
          fontWeight: "bold",
          fontSize: 16,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 5,
          paddingRight: 5,
          height: 60,
        },
        rowStyle: {
          fontSize: 16,
        },
        tableLayout: "auto",
        draggable: false,
        showTitle: false,
        ...materialTableProps.options,
      }}
    />
  );
};

export default withTheme(CustomTable);
