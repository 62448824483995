import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { BattGBrandConverter } from "../../../../shared/domain/converter/batt-g/batt-g-brand.converter";
import { IBattGGrsBatteryType } from "../../../../shared/models/batt-g/batt-g-brand";
import { IBattGGrsBatteryTypeListModel } from "../../../../shared/repositories/models/batt-g/batt-g-brand.model";
import getInstance from "../../../../utils/rest";
import { PomQueryKeys } from "../../pom-query-keys";

const getGrsBatteryTypes = async (): Promise<IBattGGrsBatteryType[]> => {
  const { data } = await getInstance().get<IBattGGrsBatteryTypeListModel>(RestEndpoints.BattGGrsBatteryTypes);
  return data.items.map((item) => BattGBrandConverter.batteryTypeToDomain(item));
};

export const useGetGrsBatteryTypesQuery = () => {
  return useQuery([PomQueryKeys.GetGrsBatteryTypes], () => getGrsBatteryTypes(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
