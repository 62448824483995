import { Box, FormControl, Grid, InputProps, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { ForwardedRef } from "react";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { Colors } from "../../../../style/Colors";
import { NewOrderToolTip } from "../../tooltips/order-new-tooltip.component";
import { Control, Controller, UseControllerProps } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
  selector: {
    backgroundColor: Colors.greenLight,
    borderColor: Colors.greenLight,
    border: "1px solid",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.greenDark,
      borderColor: Colors.greenDark,
    },
    height: "38px",
    minWidth: "38px",
    borderRadius: "100%",
  },
  selectorText: {
    color: "white",
    textAlign: "center",
    lineHeight: "38px",
  },
  selectorContainer: {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      "& .MuiGrid-item": {
        padding: 2,
      },
    },
  },
  columnContainer: {
    height: "100%",
  },
  required: {
    fontWeight: "bold",
  },
  helperIcon: {
    cursor: "help",
  },
  helperIconContainerColor: {
    paddingLeft: 2,
    color: Colors.greenLight,
    "&:hover": {
      color: Colors.greenDark,
    },
  },
  error: {
    color: "#c8364c",
  },
}));

interface IFormNumberSelectorFieldProps extends UseControllerProps<any> {
  hasError?: boolean;
  errorMessage?: string;
  required?: boolean;
  inputProps?: InputProps;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  numberSelectorValues?: number[];
  onSelectorClick: (value: number) => void;
  onChangedValue?: (value: number) => void;
  label: string;
  key: string;
  helperTooltip?: string;
  control: Control<any>;
}

export const FormNumberSelector = React.forwardRef(
  (
    {
      hasError,
      errorMessage,
      required = true,
      inputProps,
      onFocus,
      label,
      numberSelectorValues,
      onSelectorClick,
      key,
      helperTooltip,
      onChangedValue,
      control,
      name,
      rules,
    }: IFormNumberSelectorFieldProps,
    ref: ForwardedRef<any>,
  ) => {
    const classes = useStyles();
    const { isMobile, isDownTablet } = useScreenSize();
    const selectorValues = numberSelectorValues ?? [1, 2, 3, 4, 5, 6];

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={classNames({ [classes.required]: required, [classes.error]: hasError })}
                    >
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" className={classNames({ [classes.error]: hasError })}>
                      {required ? " *" : ""}
                    </Typography>
                  </Grid>
                  {helperTooltip && (
                    <Grid item className={classes.helperIconContainerColor}>
                      <NewOrderToolTip title={helperTooltip} placement="bottom">
                        <HelpOutlineOutlined color="inherit" className={classes.helperIcon} fontSize="small" />
                      </NewOrderToolTip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={isMobile ? 1 : 2} className={classes.selectorContainer}>
                  {selectorValues.map((num, index) => (
                    <Grid item key={`${num}-${index}`}>
                      <Box className={classes.selector} onClick={() => onSelectorClick(num)} key={`${num}-${index}`}>
                        <Typography variant="body1" className={classes.selectorText}>
                          {num}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                  <Grid item xs={12} md={2} lg={2} className={classNames({ ["pt-3"]: isDownTablet })}>
                    <FormControl fullWidth={true}>
                      <TextField
                        {...field}
                        key={key}
                        InputLabelProps={{ shrink: true }}
                        InputProps={inputProps}
                        label=""
                        type={"number"}
                        error={hasError}
                        helperText={errorMessage}
                        onFocus={onFocus}
                        onChange={(e) => {
                          field.onChange(e);
                          onChangedValue?.(Number(e.target.value));
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      />
    );
  },
);
