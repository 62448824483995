import { VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { GroupMutationConverter } from "../../../../domain/converter/group-mutation.converter";
import { IGroup } from "../../../../domain/group/group";
import { pomGroupTypes } from "../../../../domain/group/group-type";
import { useUpdateGroupPaymentInfo } from "../../../../repositories/queries/group/update-group-payment-info.query";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useCustomForm } from "../../../../util/form.util";
import { AppDialog } from "../../../dialog/app-dialog.component";
import {
  GroupFormPaymentSection,
  IGroupBaseDataPaymentInfoFormInputs,
} from "../../group-form/group-form-payment-info-section.component";
import { paymentInfoToInitValuesForEdit } from "../../group-form/group-form.util";

interface IEditGroupPaymentInfoDialogProps {
  open: boolean;
  group: IGroup;
  onCancel: VoidFunction;
  onSaved: VoidFunction;
}

export const EditGroupPaymentInfoDialog: VFC<IEditGroupPaymentInfoDialogProps> = (props) => {
  const { open, group, onCancel, onSaved } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formMethods = useCustomForm<IGroupBaseDataPaymentInfoFormInputs>({
    defaultValues: paymentInfoToInitValuesForEdit(
      group.invoiceAddress,
      group.paymentInfo,
      group.hasAcceptedSepaMandate,
    ),
    mode: "all",
  });
  const { handleSubmit: onSubmit } = formMethods;
  const { isLoading: isUpdateLoading, mutateAsync: updateGroupPaymentInfo } = useUpdateGroupPaymentInfo();

  const handleSubmit: SubmitHandler<IGroupBaseDataPaymentInfoFormInputs> = async (inputs) => {
    const convertedData = GroupMutationConverter.toUpdateGroupPaymentInfoModel(inputs);
    await updateGroupPaymentInfo({ id: group.id, updateData: convertedData });
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupForDetails);
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    onSaved();
  };

  return (
    <AppDialog
      title={t("basedata.payment_info.heading")}
      open={open}
      isLoading={isUpdateLoading}
      onCancelClick={onCancel}
      onAcceptClick={() => {
        onSubmit(handleSubmit)();
      }}
      acceptTextOverride={t("general.save.text")}
      alignButtons="space-between"
    >
      <FormProvider {...formMethods}>
        <GroupFormPaymentSection isPomGroup={pomGroupTypes.includes(group.type)} />
      </FormProvider>
    </AppDialog>
  );
};
