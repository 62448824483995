import { ICfmItemX } from "../../../../domain/order-x/cfm-item-x";
import { ICfmItemXModel } from "../../order-x/cfm-item-x.model";
import { CfmActionConverter } from "../cfm-action.converter";
import { CfmPackageConverter } from "../cfm-package.converter";
import { CfmProductConverter } from "../cfm-product.converter";

export class CfmItemXConverter {
  public static toDomain(model: ICfmItemXModel): ICfmItemX {
    return {
      action: model.action ? CfmActionConverter.toDomain(model.action) : null,
      customHeight: model.customHeight,
      customLength: model.customLength,
      customWeight: model.customWeight,
      customWidth: model.customWidth,
      id: model.id,
      package: model.package ? CfmPackageConverter.toDomain(model.package) : null,
      product: model.product ? CfmProductConverter.toDomain(model.product) : null,
      quantity: model.quantity,
      targetGrossWeightKg: model.targetGrossWeightKg,
      targetNetWeightKg: model.targetNetWeightKg,
    };
  }

  public static toDomainList(modelList: ICfmItemXModel[]): ICfmItemX[] {
    return modelList.map(CfmItemXConverter.toDomain);
  }
}
