import { TextField as TextInputField } from "@material-ui/core";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { makeStyles } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";

export interface INumberInputField {
  style?: React.CSSProperties;
  className?: string;
  error?: boolean;
  onChange: StandardInputProps["onChange"];
  onInput?: StandardInputProps["onInput"];
  value: number | undefined;
  minValue?: number;
  step?: number;
  fractionDigits?: number;
  disabled?: boolean;
}

const useStyles = makeStyles({
  formControl: {
    "& input": {
      textAlign: "end",
    },
  },
});

/**
 * A custom text input field that only allows positive numbers as input.
 * Use this component if you are managing the inputs manually
 */
export const NumberInputField: FunctionComponent<INumberInputField> = (props) => {
  const classes = useStyles();

  return (
    <TextInputField
      value={props.value ?? ""}
      type="number"
      onInput={props.onInput}
      style={props.style}
      error={props.error}
      disabled={props.disabled}
      className={props.className + " " + classes.formControl}
      onChange={(event) => {
        if (props.fractionDigits) {
          const value = event.target.value.match(`\\d*(\\.\\d{0,${props.fractionDigits}})?`)?.[0];
          if (value) {
            event.target.value = value;
          }
        }

        props.onChange?.(event);
      }}
      InputProps={{
        inputProps: { min: props.minValue ?? 0, step: props.step },
      }}
      onKeyPress={(event: any) => {
        if (event?.key === "-" || event?.key === "+") {
          event.preventDefault();
        }
      }}
    />
  );
};
