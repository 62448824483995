import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { useCallback, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { useCreateProductPackageConfig } from "../../../collect-from-market/repositories/queries/package/create-product-package-config.query";
import { useGetProductsByIdsQuery } from "../../../collect-from-market/repositories/queries/product/query/get-products-by-ids.query";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { ConfigurationCreatedDialog } from "../dialog/configuration-created-dialog.component";
import { ProductDataDisplay } from "../product/product-data-display.component";
import { FinishProductPackageConfig } from "./finish-product-package-config.component";
import { PackageDataDisplay } from "./package-data-display.component";
import { SelectPackagesForConfig } from "./select-package-for-config.component";

const useStyles = makeStyles((theme: Theme) => ({
  finishProductPackageConfigContainer: {
    marginTop: 20,
  },
}));

export interface ICfmProductPackageConfig {
  product: ICfmProduct;
  newPackages: ICfmPackage[];
  existingPackages: ICfmPackage[];
}
interface ICreateProductPackageConfigContentProps {
  productIds: number[];
}

export const CreateProductPackageConfigContent: VFC<ICreateProductPackageConfigContentProps> = (props) => {
  const { productIds } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [hasPackagesSelected, setHasPackagesSelected] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState<ICfmPackage[]>([]);
  const [selectedProductPackageConfigs, setSelectedProductPackageConfigs] = useState<ICfmProductPackageConfig[]>([]);
  const [isSubmitSuccessDialogOpen, setIsSubmitSuccessDialogOpen] = useState(false);

  const { isLoading, data: products } = useGetProductsByIdsQuery(productIds);
  const { mutateAsync: createConfig } = useCreateProductPackageConfig();

  const initProductPackageConfig = useCallback(() => {
    if (!products) return;
    const configs: ICfmProductPackageConfig[] = [];

    for (const product of products) {
      const existingPackageIds = (product.packages ?? []).map((pkg) => pkg.id);
      const config: ICfmProductPackageConfig = {
        product,
        newPackages: selectedPackages.filter((selectedPackage) => !existingPackageIds.includes(selectedPackage.id)),
        existingPackages: product.packages ?? [],
      };

      configs.push(config);
    }

    setSelectedProductPackageConfigs(configs);
  }, [products, selectedPackages]);

  useEffect(() => {
    if (!hasPackagesSelected) {
      setSelectedProductPackageConfigs([]);
    } else {
      initProductPackageConfig();
    }
  }, [hasPackagesSelected, setSelectedProductPackageConfigs, initProductPackageConfig]);

  const onNextClick = () => {
    setHasPackagesSelected(true);
  };

  const onCancel = () => {
    setIsSubmitSuccessDialogOpen(false);
    history.push(AvailableConfiguratorRoutes.ProductOverview);
  };

  const onAccept = () => {
    setIsSubmitSuccessDialogOpen(false);
    history.push(AvailableConfiguratorRoutes.ProductsForRouting);
  };

  const onSubmit = async () => {
    await createConfig({ config: selectedProductPackageConfigs });
    setIsSubmitSuccessDialogOpen(true);
  };

  return (
    <>
      <ConfigurationCreatedDialog
        open={isSubmitSuccessDialogOpen}
        title={t("configuration.productPackageConfig.success")}
        acceptText={t("basedata.general.yes.text")}
        cancelText={t("basedata.general.no.text")}
        contentText={t("configuration.productPackageConfig.toRoutingQuestion")}
        onCancel={onCancel}
        onAccept={onAccept}
        hasPackages
        hasRouting={false}
        maxWidth="xs"
      />

      <Grid container direction="column" spacing={2}>
        {isLoading && (
          <Grid item>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <ProductDataDisplay
            products={products ?? []}
            onEditClick={() => {
              history.push(AvailableConfiguratorRoutes.PackageOverview);
            }}
          />
        </Grid>
        {!hasPackagesSelected && (
          <Grid item>
            <SelectPackagesForConfig
              onNextClick={onNextClick}
              selectedPackages={selectedPackages}
              setSelectedPackages={setSelectedPackages}
            />
          </Grid>
        )}
        {hasPackagesSelected && (
          <>
            <Grid item className={classes.finishProductPackageConfigContainer}>
              <PackageDataDisplay packages={selectedPackages} onEditClick={() => setHasPackagesSelected(false)} />
            </Grid>
            <Grid item>
              <FinishProductPackageConfig
                selectedProductPackageConfigs={selectedProductPackageConfigs}
                setSelectedProductPackageConfigs={setSelectedProductPackageConfigs}
                onSubmit={onSubmit}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
