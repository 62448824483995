import { IPomProductTakeBackSystemModel } from "../../repositories/models/products/pom-product-take-back-system.model";
import { IPomProductTakeBackSystem } from "../models/takeback-system";

export class PomTakeBackSystemConverter {
  public static toDomain(model: IPomProductTakeBackSystemModel): IPomProductTakeBackSystem {
    return {
      id: model.id,
      name: model.name,
      internalNumber: model.internalNumber,
    };
  }

  public static toModel(domain: IPomProductTakeBackSystem): IPomProductTakeBackSystemModel {
    return {
      id: domain.id,
      name: domain.name,
      internalNumber: domain.internalNumber,
    };
  }
}
