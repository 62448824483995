import { Box, Container, Dialog, Grid, Tooltip, Typography } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import { BasicButton } from "components/Primitives";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoginStyle } from "style/login.style";
import { AvailableSharedRoutes } from "utils/constants";
import { mailValidation } from "utils/validationHelper";
import config from "../../../config/config";
import { FormTextField } from "../../components/form/fields/form-text.field";
import { ResetPasswordStatus } from "../../domain/user/user";
import DefaultLayout from "../../layout/default.layout";
import { useForgotPasswordQuery } from "../../repositories/queries/auth/mutation/forgot-password.query";
import { useCustomForm } from "../../util/form.util";

interface ILockedPasswordFormInputs {
  email: string;
}

export const LockedPage: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const classes = useLoginStyle();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { mutateAsync: forgotPassword } = useForgotPasswordQuery();
  const [reCaptchaResponseToken, setReCaptchaResponseToken] = useState<string | null>(null);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useCustomForm<ILockedPasswordFormInputs>();

  const reCaptchaSiteKey: string | undefined = config.reCaptchaSiteKey;
  const onPasswordReset: SubmitHandler<ILockedPasswordFormInputs> = async (inputs): Promise<void> => {
    try {
      const status = await forgotPassword({
        email: inputs.email,
        reCaptchaResponseToken,
      });
      if (status === ResetPasswordStatus.CaptchaFailed) {
        handleCaptchaFailed();
        return;
      }
      if (status === ResetPasswordStatus.Successful) {
        setModal(true);
      }
    } catch (error) {
      enqueueSnackbar(t("api.error.unknown_error"), {
        variant: "error",
      });
    } finally {
      captchaRef.current?.reset();
    }
  };

  const handleCaptchaFailed = () => {
    enqueueSnackbar(t("reCaptcha.captcha_failed"), {
      variant: "error",
    });
    setReCaptchaResponseToken(null);
    setModal(false);
  };

  const onCaptchaComplete = (token: string | null) => {
    setReCaptchaResponseToken(token);
  };

  return (
    <DefaultLayout>
      <Helmet>
        <title>{t("app_name")}</title>
      </Helmet>
      <Dialog open={modal} onClose={() => setModal(false)}>
        <Box m={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <p>{t("locked.password.reset.success.feedback")}</p>
              <p>{t("locked.password.reset.support", { email: "portal@grs-batterien.de" })}</p>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <div className={classes.root}>
        <Container style={{ paddingRight: 15, paddingLeft: 15 }}>
          <Grid
            container
            className="justify-content-center row"
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Grid item md={8} sm={10}>
              <div className="card-group">
                <div className={["p-4 card", classes.card, classes.cardRightRadius].join(" ")}>
                  <div className="card-body">
                    <Typography variant="h1">{t("locked.headline")}</Typography>
                    <Typography className={classes.lockedTextSpacer} variant="body1">
                      {t("locked.text")}
                    </Typography>

                    <form onSubmit={handleSubmit(onPasswordReset)}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item style={{ width: "95%" }}>
                          <FormTextField
                            hasError={Boolean(errors?.email)}
                            type="email"
                            label={t("locked.email.input_title")}
                            control={control}
                            name={"email"}
                            rules={{
                              required: true,
                              validate: (value) => mailValidation(value, t("general.email.required.error")),
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip title={t("locked.email.tooltip")} placement="right">
                            <HelpIcon style={{ marginTop: "12px", cursor: "help" }} fontSize="small" color="action" />
                          </Tooltip>
                        </Grid>
                      </Grid>

                      {reCaptchaSiteKey && (
                        <Box className={classes.captchaBox}>
                          <ReCAPTCHA ref={captchaRef} sitekey={reCaptchaSiteKey} onChange={onCaptchaComplete} />
                        </Box>
                      )}

                      <div className="mt-5 mb-0">
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <a href={AvailableSharedRoutes.Login} style={{ display: "flex" }}>
                              <ArrowBackIos />
                              <Typography variant="body1">{t("button.back.to.login")}</Typography>
                            </a>
                          </Grid>
                          <Grid item>
                            <BasicButton type="submit" disabled={isSubmitting}>
                              <Typography variant="body1">{t("locked.reset.password.button")}</Typography>
                            </BasicButton>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </DefaultLayout>
  );
};
