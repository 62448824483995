/* eslint-disable @typescript-eslint/no-unused-vars */

import { Grid, Step, StepConnector, Stepper } from "@material-ui/core";
import { KeyboardBackspaceOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  StatusAcceptedIcon,
  StatusCollectedIcon,
  StatusCompletedIcon,
  StatusOrderedIcon,
  StatusWeightedIcon,
} from "../../../../../components/Primitives";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { ICfmOrderFullReport } from "../../../../domain/order-x/cfm-order-report";
import { getCurrentStatusName } from "../../utils/order-x-card.util";
import {
  IOrderStatusStepItem,
  OrderXStatusStepContent,
} from "../card-status-step/order-x-card-status-step-content.component";
import { useOrderCardStatusIndicatorStyles } from "./order-x-card-status-indicator.styles";

interface IOrderCardStatusIndicatorProps {
  order: ICfmOrderX;
  report: ICfmOrderFullReport | undefined;
}

export const OrderXCardStatusIndicator: React.VFC<IOrderCardStatusIndicatorProps> = (props) => {
  const { order } = props;
  const { internalUser } = useAuthContext();
  const classes = useOrderCardStatusIndicatorStyles();
  const { isMobile } = useScreenSize();

  const { t } = useTranslation();

  // TODO: BAT-1859 Add Popup text
  const availableStatusItems: IOrderStatusStepItem[] = [
    {
      index: 0,
      status: CfmOrderXApiStatus.Ordered,
      icon: StatusOrderedIcon,
      popup: [],
    },
    {
      index: 1,
      status: CfmOrderXApiStatus.LogisticAccepted,
      icon: StatusAcceptedIcon,
      popup: [],
    },
    {
      index: 2,
      status: CfmOrderXApiStatus.LogisticPerformed,
      icon: StatusCollectedIcon,
      popup: [],
    },
    {
      index: 3,
      status: CfmOrderXApiStatus.RecyclerIncomingWarehouse,
      icon: StatusWeightedIcon,
      popup: [],
    },
    {
      index: 4,
      status: CfmOrderXApiStatus.RecyclerOutgoingWarehouse,
      icon: StatusWeightedIcon,
      popup: [],
    },
    {
      index: 5,
      status: CfmOrderXApiStatus.Confirmed,
      icon: StatusCompletedIcon,
      popup: [],
    },
  ];

  const getActiveStep = () => {
    const active = availableStatusItems.find((item) => item.status === order.status);
    return active?.index ?? 0;
  };

  return (
    <></>
    // <Grid container>
    //   <Grid item xs={12}>
    //     <Grid container direction="row" justifyContent="space-between">
    //       <Grid item xs={10} sm={11} lg={12} className={classNames({ [classes.stepperContainer]: isMobile })}>
    //         <Stepper
    //           activeStep={getActiveStep()}
    //           className={classes.stepper}
    //           connector={<StepConnector className={classes.connector} />}
    //         >
    //           {availableStatusItems.map((item, index) => {
    //             return (
    //               <Step key={index} className={classes.label}>
    //                 <OrderXStatusStepContent
    //                   key={index}
    //                   item={item}
    //                   index={index}
    //                   count={availableStatusItems.length}
    //                   statusName={getCurrentStatusName(order, internalUser, t)}
    //                   activeStepIndex={getActiveStep()}
    //                 />
    //               </Step>
    //             );
    //           })}
    //         </Stepper>
    //       </Grid>
    //       {isMobile && (
    //         <Grid item>
    //           <Grid container direction="column" justifyContent="center" className={classes.forwardIconContainer}>
    //             <Grid item>
    //               <KeyboardBackspaceOutlined fontSize="large" className={classes.forwardIcon} />
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       )}
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};
