import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { SimplePageTitle } from "../../../layout-components/SimplePageTitle";

const useStyle = makeStyles((theme) => ({
  subheading: {
    color: theme.palette.primary.main,
    fontWeight: "unset",
  },
}));

interface IDataPrivacyProps {}

export const DataPrivacy: React.FunctionComponent<IDataPrivacyProps> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const textParagraphMessageKeys = [
    "dataSecurity",
    "ipaddress",
    "storagePeriod",
    "affectedPerson",
    "article15",
    "article16",
    "article17",
    "article18",
    "article20",
    "article7",
    "article21",
    "article77",
    "enforcement",
  ];
  return (
    <Fragment>
      <SimplePageTitle titleMessageKey={"dataPrivacy.title"} />
      <Grid container direction="column" spacing={2} style={{ marginTop: 20 }}>
        <Grid item>
          <Typography variant="body1">{t("dataPrivacy.text.firstParagraph")}</Typography>
        </Grid>

        {textParagraphMessageKeys.map((value) => {
          return (
            <Grid item>
              <Typography className={classes.subheading} variant="h5">
                {t("dataPrivacy.text." + value + "_subheading")}
              </Typography>
              <Typography variant="body1">{t("dataPrivacy.text." + value)}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};
