import { useQuery } from "react-query";
import { IAddressResultModel } from "shared/models/address/address.model";
import { RestEndpoints, replaceUriParameter } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { IAddressResult } from "../../../domain/address/address";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { PaginationMetaConverter } from "../../../domain/converter/pagination-meta.converter";
import { AddressType } from "../../../models/address/address.model";
import { SharedQueryKeys } from "../shared-query-keys";

export const getUserAddressesForId = async (
  id: number | undefined,
  types: AddressType[],
  searchText: string | undefined,
  page?: number,
  pageSize?: number,
  includeDeleted?: boolean,
): Promise<IAddressResult | undefined> => {
  if (!id) return undefined;
  const url = replaceUriParameter(RestEndpoints.UserAddresses, "id", id);
  const response = await getInstance().get<IAddressResultModel>(url, {
    params: {
      types,
      query: searchText,
      page,
      pageSize,
      includeDeleted,
    },
  });

  return {
    addresses: response.data.addresses.map(AddressConverter.toDomain),
    meta: PaginationMetaConverter.toDomain(response.data.meta),
  };
};

export const useGetUserAddresses = (
  id: number | undefined,
  types: AddressType[],
  searchText: string | undefined,
  enabled: boolean,
  page?: number,
  pageSize?: number,
  includeDeleted?: boolean,
) => {
  return useQuery(
    [SharedQueryKeys.UserAddressesForId, id, types, searchText, enabled],
    () => getUserAddressesForId(id, types, searchText, page, pageSize, includeDeleted),
    {
      enabled: enabled && Number.isFinite(id),
    },
  );
};
