import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "../../../../../components/Primitives";
import { GroupDetailStatusTag } from "../../../../../shared/pages/groups/components/group-detail-status-tag.component";
import { useOrderNewInfoBoxStyle } from "../../../../../style/order-new-info-box.style";
import { OrderNewInfoBoxDesktop } from "./order-new-info-box-desktop.component";
import { OrderNewInfoBoxMobile } from "./order-new-info-box-mobile.component";

interface IOrderNewInfoBoxProps {
  iconComponent: JSX.Element;
  labelComponent: JSX.Element;
  onClick: VoidFunction;
  isNew?: boolean;
}

export const OrderNewInfoBox: React.FC<IOrderNewInfoBoxProps> = (props) => {
  const { iconComponent, labelComponent, onClick, isNew } = props;
  const { t } = useTranslation();
  const classes = useOrderNewInfoBoxStyle();
  const theme = useTheme();
  const isDownTablet = useMediaQuery(theme.breakpoints.down(900), { noSsr: true });

  const renderNextArrow = useCallback(() => {
    return (
      <Grid container direction="column" justifyContent="center">
        <Grid item className={classes.arrowPadding}>
          <ArrowRightIcon svgColor="green" />
        </Grid>
      </Grid>
    );
  }, [classes.arrowPadding]);

  const renderIsNew = useCallback(() => {
    if (!isNew) return null;
    return (
      <Grid container direction="column" justifyContent="center">
        <Grid item>
          <GroupDetailStatusTag label={t("general.new")} type={"success"} />
        </Grid>
      </Grid>
    );
  }, [isNew, t]);

  const component = useMemo(() => {
    const props = {
      iconComponent,
      labelComponent,
      onClick,
      renderNextArrow,
      renderIsNew,
    };
    return isDownTablet ? <OrderNewInfoBoxMobile {...props} /> : <OrderNewInfoBoxDesktop {...props} />;
  }, [isDownTablet, iconComponent, labelComponent, onClick, renderIsNew, renderNextArrow]);
  return <>{component}</>;
};
