import lodash from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { useGroupsFilterContext } from "../../../../providers/groups/groups-filter-provider";
import { IGroupOrManufacturer } from "../../../domain/group/group-or-manufacturer";
import { IPaginationMeta } from "../../../domain/pagination/pagination-meta";
import { IPaginationMetaModel } from "../../../models/util/pagination-meta.model";
import { IGetGroupsFilter } from "../../../repositories/models/group/get-group.model";
import { useGetGroupsAndManufacturersWithFiltersQuery } from "../../../repositories/queries/group/get-groups-and-manufacturers.query";
import { useUpdateGroupActive } from "../../../repositories/queries/group/update-group-active.query";

interface IGroupAndManufacturerContextType {
  isLoading: boolean;
  tablePagination: IPaginationMetaModel;
  setTablePagination: (pagination: IPaginationMetaModel) => void;
  filter: IGetGroupsFilter;
  setFilter: (filters: IGetGroupsFilter) => void;
  groups: IGroupOrManufacturer[];
  refetchGroups: () => Promise<void>;
  updateGroupActive: (groupId: number, active: boolean) => Promise<void>;
  isFilterDirty: boolean;
  resetFilter: () => void;
}

const GroupAndManufacturerContext = createContext<IGroupAndManufacturerContextType>(
  {} as IGroupAndManufacturerContextType,
);

export const GroupAndManufacturerProvider: React.FC = (props) => {
  const value = useGroupAndManufacturerProvider();
  return <GroupAndManufacturerContext.Provider value={value}>{props.children}</GroupAndManufacturerContext.Provider>;
};

export const useGroupAndManufacturerContext = () => {
  return useContext(GroupAndManufacturerContext);
};

const useGroupAndManufacturerProvider = (): IGroupAndManufacturerContextType => {
  const { filter, setFilter } = useGroupsFilterContext();

  const [tablePagination, setTablePagination] = useState<IPaginationMeta>({
    numberOfRecords: 0,
    pageSize: 50,
    page: 0,
    numberOfPages: 0,
  });
  const { isLoading: areGroupsLoading, refetch } = useGetGroupsAndManufacturersWithFiltersQuery(
    tablePagination,
    filter,
  );
  const { isLoading: isUpdateGroupActiveLoading, mutateAsync: updateGroupActiveQuery } = useUpdateGroupActive();
  const [groups, setGroups] = useState<IGroupOrManufacturer[]>([]);

  const refetchGroups = async () => {
    const groupsResult = await refetch();
    if (groupsResult.data) {
      setGroups(groupsResult.data.items);
      setTablePagination({ ...tablePagination, numberOfRecords: groupsResult.data.meta.numberOfRecords });
    }
  };

  const updateGroupActive = async (groupId: number, active: boolean): Promise<void> => {
    await updateGroupActiveQuery({
      id: groupId,
      isActive: active,
    });
  };

  const isLoading = areGroupsLoading || isUpdateGroupActiveLoading;

  useAsyncEffect(
    async (isActive) => {
      if (!isActive()) return;
      await refetchGroups();
    },
    [
      tablePagination.pageSize,
      tablePagination.page,
      filter.active,
      filter.searchQuery,
      filter.groupTypes,
      filter.contractTypes,
    ],
  );

  const isFilterDirty = useMemo(
    () =>
      !lodash.isEmpty(filter.active) ||
      !lodash.isEmpty(filter.groupTypes) ||
      !lodash.isEmpty(filter.searchQuery) ||
      !lodash.isEmpty(filter.contractTypes),
    [filter],
  );

  const resetFilter = () =>
    setFilter({
      active: undefined,
      searchQuery: undefined,
      groupTypes: [],
      contractTypes: undefined,
    });

  return {
    isLoading,
    tablePagination,
    setTablePagination,
    filter,
    setFilter,
    groups,
    refetchGroups,
    isFilterDirty,
    resetFilter,
    updateGroupActive,
  };
};
