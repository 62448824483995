import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import qs from "qs";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { CreatePackageConfigIcon } from "../../../components/Primitives/Icons";
import { Shades } from "../../../style/Colors";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { CreateProductPackageConfigContent } from "../../components/package/create-product-package-config-content.component";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 25,
    height: 25,
    color: Shades.gray50,
  },
  heading: {
    color: Shades.gray50,
  },
  headingContainer: {
    marginTop: 25,
  },
}));

export const CreateProductPackageConfigPage: VFC = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const idString = qs.parse(location.search, { ignoreQueryPrefix: true }).ids as string | undefined;

  if (!idString) {
    history.push(AvailableConfiguratorRoutes.PackageOverview);
    return null;
  }

  const selectedProductIds: number[] = idString.split(",").map((id) => Number(id));

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item className={classes.headingContainer}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <CreatePackageConfigIcon viewBox="0 0 25 25" className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h4" className={classes.heading}>
              {t("configuration.configPackage")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CreateProductPackageConfigContent productIds={selectedProductIds} />
      </Grid>
    </Grid>
  );
};
