import { makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { PackageConfigIconSmall } from "../../../../../../components/Primitives/Icons";
import { Colors, PrimaryColors } from "../../../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontWeight: "bold",
    color: PrimaryColors.base,
    marginLeft: 10,
    marginRight: 10,
  },
  packageIcon: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
  },
  notifiedQuantity: {
    fontWeight: "bold",
    color: Colors.orange,
  },
  notifiedText: {
    color: Colors.orange,
  },
  notifiedPackageIcon: {
    color: Colors.orange,
    height: 25,
    width: 25,
  },
}));

interface IWarehouseProductQuantityProps {
  quantity: number;
  notifiedWeight?: number | undefined;
  unit?: string;
}

export const WarehouseProductQuantity: VFC<IWarehouseProductQuantityProps> = (props) => {
  const { quantity, notifiedWeight, unit } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getPackageIcon = () => {
    let iconClass = classes.packageIcon;
    if (notifiedWeight && notifiedWeight > 0) {
      iconClass = classes.notifiedPackageIcon;
    }

    return <PackageConfigIconSmall className={iconClass} viewBox="0 0 25 25" />;
  };

  return (
    <>
      {getPackageIcon()}
      <Typography className={classes.text} variant="body1" color={notifiedWeight ? "secondary" : "primary"}>
        {unit && `${quantity.toLocaleString()} ${unit}`}
        {!unit && t("orders.destinationReceivedWarehouse.quantity", { quantity: quantity.toLocaleString() })}
      </Typography>
      {!!notifiedWeight && notifiedWeight > 0 && (
        <Typography className={classes.notifiedText} variant="body1" color="secondary">
          {t("orders.recyclerOutgoingWarehouse.notifiedQuantity", { quantity: notifiedWeight.toLocaleString() })}
        </Typography>
      )}
    </>
  );
};
