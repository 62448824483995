import { ICfmTruckload } from "../../../domain/truckload/cfm-truckload";
import { TranslateFunc } from "../../../../utils/translation.utils";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";
import { TruckloadState } from "../../../domain/truckload/truckload-state";
import { AddressType, recyclerAddressTypes } from "../../../../shared/models/address/address.model";

export const getWeight = (orders: ICfmOrderX[]) => {
  return orders.reduce((prev, cur) => prev + (cur.warehouseWeight ?? 0), 0);
};

export const getTruckloadNumber = (id: number) => {
  return `TL-${id.toString().padStart(4, "0")}`;
};

export const getTranslatedStatus = (truckload: ICfmTruckloadWithOrders | ICfmTruckload, t: TranslateFunc) => {
  if (truckload.doneAt) return t(`orders.truckloadAnnounced.${TruckloadState.Confirmed}`);
  if (truckload.submittedAt) return t(`orders.truckloadAnnounced.${TruckloadState.Submitted}`);
  return t(`orders.truckloadAnnounced.${TruckloadState.Draft}`);
};

const getTruckloadProduct = (truckload: ICfmTruckloadWithOrders) => {
  const orders = truckload.orders;
  if (!orders.length) return undefined;
  return orders[0].items.find((item) => item.product)?.product;
};

export const getTruckloadOrderFraction = (truckload: ICfmTruckloadWithOrders) => {
  return getTruckloadProduct(truckload)?.fraction;
};

export const getTruckloadOrderType = (truckload: ICfmTruckloadWithOrders) => {
  return getTruckloadProduct(truckload)?.type;
};

export const getTruckloadOrderState = (truckload: ICfmTruckloadWithOrders) => {
  return getTruckloadProduct(truckload)?.state;
};

export const truckloadValidAddressTypes = [
  AddressType.CustomerLocation,
  AddressType.LogisticLocation,
  ...recyclerAddressTypes,
];
