import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AvailablePlatform } from "../../../../providers/Auth/platform.provider";
import { IAddressLean } from "../../../domain/address/address-lean";
import { UserConverter } from "../../../domain/converter/user.converter";
import { IGroup } from "../../../domain/group/group";
import { cfmGroupTypes, GroupType, pomGroupTypes } from "../../../domain/group/group-type";
import { RolePom } from "../../../domain/user/role-pom";
import { isAdmin, isCfmCustomer, isCfmWasteProducer, isPomMainContact, IUser } from "../../../domain/user/user";
import { UserTypeLevel } from "../../../domain/user/user-type-level";
import { AddressType, recyclerAddressTypes } from "../../../models/address/address.model";
import { useGetGroupAddresses } from "../../../repositories/queries/address/get-group-addresses.query";
import { useGetGroupsWithFiltersQuery } from "../../../repositories/queries/group/get-groups.query";
import { useCreateUserQuery } from "../../../repositories/queries/user/mutation/create-user.query";
import { getSelectAllAddressesOption } from "../../address/address-form/address-form.util";
import { IMutateUserFormInputs } from "./create-user-form.component";

interface ICreateUserFormContext {
  groups: IGroup[];
  userGroupId?: number | undefined;
  userAddresses?: IAddressLean[];
  isCfmCustomerWasteProducer: boolean;
  isPomMainContact: boolean;
  groupAddresses: IAddressLean[];
  isAdmin: boolean;
  isLoading: boolean;
  handleFormSubmit: (inputs: IMutateUserFormInputs) => Promise<boolean>;
}

export const CreateUserFormContext = createContext<ICreateUserFormContext>({} as ICreateUserFormContext);

export const useCreateUserFormContext = () => {
  return useContext(CreateUserFormContext);
};

const useCreateUserFormProvider = ({
  user,
  platformType,
  userAddresses,
}: ICreateUserFormProps): ICreateUserFormContext => {
  const { t } = useTranslation();

  const getGroupTypesToFetch = (): GroupType[] => {
    if (platformType === AvailablePlatform.Cfm) {
      return cfmGroupTypes;
    } else if (platformType === AvailablePlatform.Pom) {
      return pomGroupTypes;
    }
    return [];
  };

  const { isLoading: areAddressesLoading, data: addressesResult } = useGetGroupAddresses(
    user?.group?.id,
    undefined,
    [AddressType.CustomerLocation, AddressType.LogisticLocation, ...recyclerAddressTypes],
    !!user?.group?.id && !isAdmin(user),
    undefined,
    true,
  );

  const { data: groups, isLoading: areGroupsLoading } = useGetGroupsWithFiltersQuery(
    { filter: { groupTypes: getGroupTypesToFetch() } },
    isAdmin(user),
  );

  const { mutateAsync: createUser, isLoading: isCreateUserLoading } = useCreateUserQuery();

  const isLoading = areAddressesLoading || areGroupsLoading || isCreateUserLoading;

  const isUserCfmCustomerWasteProducer: boolean =
    (isCfmCustomer(user) && isCfmWasteProducer(user) && platformType === AvailablePlatform.Cfm) ?? false;
  const isUserPomMainContact: boolean = (isPomMainContact(user) && platformType === AvailablePlatform.Pom) ?? false;
  const isUserAdmin: boolean = isAdmin(user);

  const handleFormSubmit = async (inputs: IMutateUserFormInputs): Promise<boolean> => {
    // remove the all addresses option from input
    const filteredInputs = { ...inputs };
    filteredInputs.userAddresses = inputs.userAddresses?.filter((a) => a.id !== getSelectAllAddressesOption(t).id);

    let groupIdForUser = filteredInputs.group.id;
    if (user && !isAdmin(user) && user.group) {
      groupIdForUser = user.group.id;
    }

    // If an admin creates an user with that form on Cfm, the user has automatically the CorporateLevel
    if (platformType === AvailablePlatform.Cfm) {
      if (isUserAdmin) {
        filteredInputs.userTypeLevel = UserTypeLevel.Corporate;
      }
    }

    // If an admin creates an user with that form on Pom, the user has automatically the role MainContact
    if (platformType === AvailablePlatform.Pom) {
      if (isUserAdmin) {
        filteredInputs.rolePom = RolePom.MainContact;
      } else {
        filteredInputs.rolePom = filteredInputs.rolePom ?? RolePom.MainContact;
      }
    }

    const request = UserConverter.toMutationRequest(filteredInputs, groupIdForUser);
    const response = await createUser({ createData: request });
    return !!response;
  };

  const getAddressesForGroup = () => {
    // If userAddresses are set, only allow those for selections
    if (userAddresses && userAddresses?.length > 0) {
      return userAddresses;
    }

    // Otherwise use all addresses for group
    const addresses = addressesResult?.addresses;

    if (addresses) {
      // Add all addresses option
      addresses.push(getSelectAllAddressesOption(t));
    }
    return addresses;
  };

  return {
    groups: groups?.groups ?? [],
    isLoading,
    handleFormSubmit,
    isCfmCustomerWasteProducer: isUserCfmCustomerWasteProducer,
    isPomMainContact: isUserPomMainContact,
    isAdmin: isUserAdmin,
    groupAddresses: getAddressesForGroup() ?? [],
  };
};

interface ICreateUserFormProps {
  user?: IUser | undefined;
  userAddresses?: IAddressLean[];
  platformType: AvailablePlatform;
}

export const CreateUserFormProvider: React.FC<ICreateUserFormProps> = (props) => {
  const value = useCreateUserFormProvider(props);
  return <CreateUserFormContext.Provider value={value}>{props.children}</CreateUserFormContext.Provider>;
};
