const getUsersPreferredLanguages = () => {
  if (navigator.languages !== undefined) {
    return navigator.languages;
  } else if (navigator.language !== undefined) {
    return [navigator.language];
  } else {
    return undefined;
  }
};

export const parseLanguages = (acceptedLangs: string[], defaultLang: string): string | undefined => {
  const userPref = getUsersPreferredLanguages();

  const match = userPref ? userPref.find((lang) => acceptedLangs.includes(lang)) : undefined;

  if (!match) {
    return defaultLang;
  }

  return match;
};
