import { useEffect, VFC } from "react";
import { formatDateYearMonthDay } from "../../../shared/util/date.util";
import { downloadFile } from "../../../shared/util/download.util";
import { useGetOrdersExportByIdsQuery } from "../../repositories/queries/order-x/queries/get-orders-export-by-ids.query";
import { ExcelButton } from "../../../shared/components/button/excel-button.component";

interface IExportTruckloadButtonProps {
  orderIds: number[];
}

export const ExportTruckloadButton: VFC<IExportTruckloadButtonProps> = (props) => {
  const { orderIds } = props;
  const {
    refetch: exportOrders,
    data: orderExportData,
    isLoading: isOrderExportLoading,
    isRefetching: isOrderExportRefetching,
  } = useGetOrdersExportByIdsQuery(orderIds, false);

  useEffect(() => {
    if (orderExportData) {
      const filename = `truckload-export-${formatDateYearMonthDay(new Date())}.xlsx`;
      downloadFile(orderExportData, filename);
    }
  }, [orderExportData]);

  const handleExportOrdersClick = async () => {
    await exportOrders();
  };

  return <ExcelButton onClick={handleExportOrdersClick} disabled={isOrderExportLoading || isOrderExportRefetching} />;
};
