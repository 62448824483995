import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { ArrowRightBig } from "../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  greenIcon: {
    color: PrimaryColors.base,
    height: 30,
    width: 40,
  },
}));

interface IConfigHeaderProps {
  label: string;
}

export const ConfigHeader: VFC<IConfigHeaderProps> = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <ArrowRightBig className={classes.greenIcon} />
      </Grid>
      <Grid item>
        <Typography variant="h4">{label}</Typography>
      </Grid>
    </Grid>
  );
};
