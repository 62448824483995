import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { CfmTruckloadConverter } from "../../../models/converter/truckload/cfm-truckload.converter";
import { ICfmTruckloadWithOrdersModel } from "../../../models/truckload/cfm-truckload-with-orders.model";
import { ICfmTruckloadWithOrders } from "../../../../domain/truckload/cfm-truckload-with-orders";

export const getTruckload = async (id: number | undefined): Promise<ICfmTruckloadWithOrders | undefined> => {
  if (!id) return undefined;
  const url = replaceUriParameter(RestEndpoints.CfmTruckload, "id", id);
  const response = await getInstance().get<ICfmTruckloadWithOrdersModel>(url);
  return CfmTruckloadConverter.withOrdersToDomain(response.data);
};

export const useGetTruckloadQuery = (id: number | undefined) => {
  return useQuery([CfmQueryKeys.Truckload, id], () => getTruckload(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(id),
  });
};
