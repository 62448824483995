import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import qs from "qs";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { ResponseConfigSmallIcon } from "../../../components/Primitives";
import { Shades } from "../../../style/Colors";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { CreateProductResponseContent } from "../../components/product-response/create-product-response-content.component";
import { ProductResponseFieldOverviewProvider } from "../product-response-field/product-response-field-overview.provider";
import { ProductResponseAssignmentProvider } from "./product-response-assignment.provider";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 25,
    height: 25,
    color: Shades.gray50,
  },
  heading: {
    color: Shades.gray50,
  },
  headingContainer: {
    marginTop: 25,
  },
}));

export const CreateProductResponsePage: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const idString = qs.parse(location.search, { ignoreQueryPrefix: true }).ids as string | undefined;

  if (!idString) {
    history.push(AvailableConfiguratorRoutes.ProductResponseOverview);
    return null;
  }

  const selectedProductIds: number[] = idString.split(",").map((id) => Number(id));
  const assignmentIdString = qs.parse(location.search, { ignoreQueryPrefix: true }).assignmentIds as string | undefined;
  const selectedAssignmentIds: number[] | undefined = assignmentIdString
    ? assignmentIdString.split(",").map((id) => Number(id))
    : undefined;

  return (
    <ProductResponseAssignmentProvider>
      <ProductResponseFieldOverviewProvider>
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.headingContainer}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <ResponseConfigSmallIcon viewBox="0 0 25 25" className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography variant="h4" className={classes.heading}>
                  {t("configuration.createNewResponse")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CreateProductResponseContent productIds={selectedProductIds} assignmentIds={selectedAssignmentIds} />
          </Grid>
        </Grid>
      </ProductResponseFieldOverviewProvider>
    </ProductResponseAssignmentProvider>
  );
};
