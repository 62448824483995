import { parseLanguages } from "../utils/locale";
import locales from "./locales";

const getEnvVar = (key: string) => {
  // @ts-ignore _env_ is used as a workaround to store the environments inside our docker image
  return window._env_?.[key] || process.env[key];
};

// bool values are provided via "yes" and "no" (like in core)
const getBoolEnvVar = (key: string): boolean => {
  return getEnvVar(key)?.toLowerCase() === "yes";
};

const config = {
  api: {
    baseURL: getEnvVar("REACT_APP_API_BASE_URL"),
  },
  build: {
    version: getEnvVar("BUILD_VERSION"),
    commit: getEnvVar("BUILD_COMMIT"),
  },
  appBaseUrl: window.location.host,
  maintenanceMode: getBoolEnvVar("REACT_APP_MAINTENANCE_MODE"),
  maintenanceModeDescription: getEnvVar("REACT_APP_MAINTENANCE_MODE_DESCRIPTION"),
  environment: getEnvVar("REACT_APP_ENVIRONMENT"),
  prefillForms: getEnvVar("REACT_APP_PREFILL_FORMS"),
  applicationInsightsConnectionString: getEnvVar("REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING"),
  enableNavision: getBoolEnvVar("REACT_APP_ENABLE_NAVISION"),
  reCaptchaSiteKey: getEnvVar("REACT_APP_RECAPTCHA_SITE_KEY"),
  enableOrderSplitting: getBoolEnvVar("REACT_APP_ENABLE_ORDER_SPLITTING"),
  packageDataSheetUrl: getEnvVar("REACT_APP_PACKAGE_DATA_SHEET_URL"),
  auth: {
    expirationKey: "wbt:expiresAt",
    tokenKey: "wbt:token",
    rolesKey: "wbt:user_roles",
    persistKey: "persist:auth",
    loginRedirect: "/",
    expirationNumber: 30,
    expirationUnit: "days",
  },
  initial_state: {
    themeSource: "default",
    locale: "en",
  },
  featureToggles: {
    logisticSetServicePickupDate: getBoolEnvVar("REACT_APP_FEATURE_ENABLE_LOGISTIC_SET_SERVICE_PICKUP_DATE"),
    articleConfigurator: getBoolEnvVar("REACT_APP_FEATURE_ENABLE_ARTICLE_CONFIGURATOR"),
  },
  locale: {
    defaultLocale: parseLanguages(["de"], "de") || "de",
    locales,
    persistKey: "locale",
    onError: (e: any) => {
      // Autodetect untranslated Text
      // For Performance reasons disable this most of the time
      // enable it only when you wan't to check for untranslated text

      const enabled = false;

      if (enabled) {
        const { id, defaultMessage } = e.descriptor;
        const missingTranslations = JSON.parse(localStorage.getItem("missing_translations") ?? "");
        localStorage.setItem(
          "missing_translations",
          JSON.stringify({
            ...missingTranslations,
            [id]: defaultMessage ? defaultMessage : id,
          }),
        );
      }
      //console.log("e", e.descriptor);
      //Uncomment this to show react-intl missing translation warnings
      //console.warn(e)
      return;
    },
  },
};

export default config;
