import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Shades } from "../../../../style/Colors";
import { IGroup } from "../../../domain/group/group";
import { DetailValueDisplay } from "../../../components/value-display/detail-value-display.component";
import { EditGroupPaymentInfoDialog } from "../../../components/group/edit-group/dialog/edit-group-payment-info-dialog.component";
import { GroupDetailPaymentInfoName } from "./group-detail-payment-info-name.component";

const useStyles = makeStyles((theme: Theme) => ({
  dataContainer: {
    marginTop: 15,
  },
  divider: {
    backgroundColor: Shades.gray20,
    marginBottom: 0,
  },
}));

interface IGroupDetailPaymentInfoProps {
  group: IGroup;
}

export const GroupDetailPaymentInfo: VFC<IGroupDetailPaymentInfoProps> = (props) => {
  const { group } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const paymentInfo = useMemo(() => {
    return group.paymentInfo;
  }, [group]);
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <EditGroupPaymentInfoDialog
        open={isEditOpen}
        group={group}
        onCancel={() => setIsEditOpen(false)}
        onSaved={() => setIsEditOpen(false)}
      />

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <GroupDetailPaymentInfoName
              hasAcceptedSepaMandate={group.hasAcceptedSepaMandate}
              onEditClicked={() => setIsEditOpen(true)}
            />
          </Grid>
          <Grid item className={classes.dataContainer}>
            <Grid container direction="row">
              {!paymentInfo && (
                <Grid item xs={12}>
                  <Typography variant="body1">{t("basedata.payment_info.noData")}</Typography>
                </Grid>
              )}
              {paymentInfo && (
                <>
                  <Grid item xs={12} md={6}>
                    <DetailValueDisplay label={t("basedata.payment_info.name")} value={paymentInfo.name} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DetailValueDisplay label={t("basedata.payment_info.iban")} value={paymentInfo.iban} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DetailValueDisplay
                      label={t("basedata.group.payment_info.bic_or_swift")}
                      value={paymentInfo.bicOrSwift}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
