import React, { createContext, useContext, useState } from "react";
import { useUploadFilesQuery } from "../../../../../../shared/repositories/queries/storage/mutations/upload-file.query";

interface ISelectedFilesProvider {
  selectedFileList: ISelectedFilesList[];
  updateFileList: (index: number, responseAssignmentId: number, files: File[]) => void;
  uploadFiles(): Promise<ISelectedFilesList[] | undefined>;
  isLoading: boolean;
  reset: VoidFunction;
}

export interface ISelectedFilesList {
  index: number;
  responseAssignmentId: number;
  s3Paths?: string[];
  files: File[];
}

export const SelectedFilesContext = createContext<ISelectedFilesProvider>({} as ISelectedFilesProvider);

export const useSelectedFilesContext = () => {
  return useContext(SelectedFilesContext);
};

const useSelectedFilesProvider = (): ISelectedFilesProvider => {
  const [selectedFileList, setSelectedFileList] = useState<ISelectedFilesList[]>([]);
  const { isLoading, mutateAsync: uploadFilesQuery } = useUploadFilesQuery();

  const uploadFiles = async (): Promise<ISelectedFilesList[]> => {
    if (!selectedFileList.length) {
      return [];
    }

    const newSelectedFileList = [...selectedFileList];

    for (const fileList of newSelectedFileList) {
      const uploadResult = await uploadFilesQuery(fileList.files);
      fileList.s3Paths = uploadResult.fileUrls;
    }

    setSelectedFileList(newSelectedFileList);
    return newSelectedFileList;
  };

  const updateFileList = (index: number, responseAssignmentId: number, files: File[]): void => {
    const fileList = [...selectedFileList];
    const newFileList = fileList?.find((f) => f.index === index);
    if (newFileList) {
      newFileList.files = files;
    } else {
      fileList.push({ index, responseAssignmentId, files });
    }

    setSelectedFileList(fileList);
  };

  const reset = (): void => {
    setSelectedFileList([]);
  };

  return {
    selectedFileList,
    updateFileList,
    isLoading,
    uploadFiles,
    reset,
  };
};

export const SelectedFilesProvider: React.FC = (props) => {
  const value = useSelectedFilesProvider();
  return <SelectedFilesContext.Provider value={value}>{props.children}</SelectedFilesContext.Provider>;
};
