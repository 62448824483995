import { makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { GroupIcon } from "../../../../../components/Primitives";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { IGroup } from "../../../../../shared/domain/group/group";
import { Colors, Shades } from "../../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { getDescriptionTextForTooltip } from "../../../../../utils/group.helper";
import { OrderNewInfoBox } from "./order-new-info-box.component";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    minHeight: 70,
    backgroundColor: Colors.grayLight,
    "&:hover": {
      backgroundColor: Shades.gray50,
    },
  },
  columnContainer: {
    height: "100%",
  },
  iconPadding: {
    paddingLeft: 15,
    paddingRight: 25,
  },
  icon: {
    height: 40,
    width: 35,
  },
}));

interface IGroupInfoBoxProps {
  group: IGroup;
  onClick: (id: number) => void;
}

export const GroupInfoBox: FunctionComponent<IGroupInfoBoxProps> = (props) => {
  const { group, onClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getLabelText = (group: IGroup) => {
    const erpText = group.erpNumber ? `${TEXT_SEPARATOR} ${group.erpNumber}` : "";
    return `${group.name}${erpText}`;
  };

  const getLabel = () => {
    return (
      <NewOrderToolTip title={getDescriptionTextForTooltip(group.name ?? undefined, t)}>
        <Typography variant="body1">{`${group.id}${TEXT_SEPARATOR} ${getLabelText(group)}`}</Typography>
      </NewOrderToolTip>
    );
  };

  return (
    <OrderNewInfoBox
      iconComponent={<GroupIcon classes={{ root: classes.icon }} />}
      labelComponent={getLabel()}
      onClick={() => onClick(group.id)}
    />
  );
};
