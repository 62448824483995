import { ICfmResponseAssignmentX } from "../../../domain/assignment/cfm-response-assignment-x";
import { ICfmResponseAssignmentXModel } from "../response-assignment/cfm-response-assignment-x.model";
import { CfmProductConverter } from "./cfm-product.converter";
import { CfmResponseFieldXConverter } from "./cfm-response-field-x.converter";
import { CfmOrderXConverter } from "./order-x/cfm-order-x.converter";
import { CfmServiceDateConverter } from "./order-x/cfm-service-date.converter";

export class CfmResponseAssignmentXConverter {
  public static toDomain(assignment: ICfmResponseAssignmentXModel): ICfmResponseAssignmentX {
    return {
      id: assignment.id,
      status: CfmOrderXConverter.statusToDomain(assignment.status),
      product: assignment.product ? CfmProductConverter.toDomain(assignment.product) : undefined,
      responseFields: assignment.responseFields
        ? assignment.responseFields.map(CfmResponseFieldXConverter.toDomain)
        : [],
      serviceDates: assignment.serviceDates ? assignment.serviceDates.map(CfmServiceDateConverter.toDomain) : [],
    };
  }
}
