import { Dialog, DialogProps, Grid, LinearProgress, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { AutofillCatcher } from "../../../../../../components/AutofillHelper/autofill-catcher";
import { HEADER_MARGIN_BOTTOM, HEADER_MARGIN_TOP } from "../../../../../../config/theme/overrides/constant";
import { useAuthContext } from "../../../../../../providers/Auth/auth.provider";
import { useCustomForm } from "../../../../../../shared/util/form.util";
import { BattGFormButtonRowComponent } from "../../../../../components/batt-g/batt-g-form-button-row.component";
import { FormAutocomplete } from "../../../../../components/form/fields/form-autocomplete.component";
import { FormCheckbox } from "../../../../../components/form/fields/form-checkbox.field";
import { FormDatepickerField } from "../../../../../components/form/fields/form-datepicker.field";
import { FormPhoneCountryField } from "../../../../../components/form/fields/form-phone-country.field";
import { FormTextField } from "../../../../../components/form/fields/form-text.field";
import { BattGBrandStatus, IBattGGrsBatteryType } from "../../../../../models/batt-g/batt-g-brand";
import { BattGBrandUtil } from "../batt-g-brand.util";
import { useBattGBrandDialogContext } from "./batt-g-brand-dialog.provider";

const useStyles = makeStyles({
  headerStyle: {
    marginTop: HEADER_MARGIN_TOP,
    marginBottom: HEADER_MARGIN_BOTTOM,
  },
});

interface IBattGBrandDialog extends DialogProps {
  onOk: () => void;
  onCancel: () => void;
}

export interface IBattGBrandFormInputs {
  name: string;
  trademarkRegisterNumber: string | null;
  batteryType: IBattGGrsBatteryType;
  takeBackViaGrs: boolean;
  startDate: Date;
  endDate: Date | null;
  takeBackDescription: string | null;
  takeBackCompanyName: string | null;
  takeBackContactPersonName: string | null;
  takeBackContactPersonTelephone: string | null;
  takeBackContactPersonEmail: string | null;
}

export const BattGBrandDialog: React.FC<IBattGBrandDialog> = (props) => {
  const { open, onOk, onCancel, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { saveBrand, isLoading, brand, batteryTypes, allowTakeBackViaGrs } = useBattGBrandDialogContext();
  const { internalUser } = useAuthContext();
  const isManagedByGrs: boolean = internalUser?.group?.grsManagedEar ?? false;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useCustomForm<IBattGBrandFormInputs>({ defaultValues: BattGBrandUtil.brandDialogInitialValues(brand) });

  const selectedBatteryType: IBattGGrsBatteryType = watch("batteryType");
  const isTakeBackViaGrsSelected: boolean = watch("takeBackViaGrs");

  const onSubmit = async (values: IBattGBrandFormInputs): Promise<void> => {
    const result = await saveBrand(values, BattGBrandStatus.Draft);
    if (result) {
      reset();
      onOk();
    }
  };

  const onSubmitWithFee = async (values: IBattGBrandFormInputs): Promise<void> => {
    const result = await saveBrand(values, BattGBrandStatus.Published);
    if (result) {
      reset();
      onOk();
    }
  };

  const getTitle = (): string => {
    if (brand) {
      return t("batt_g.brand.update");
    } else {
      return t("batt_g.brand.add");
    }
  };

  const handleTakeBackViaGrsChanged = (): void => {
    if (allowTakeBackViaGrs && isManagedByGrs) {
      setValue("takeBackViaGrs", !isTakeBackViaGrsSelected);
    } else if (!isManagedByGrs) {
      enqueueSnackbar(t("batt_g.brand.warning.not_represented_via_grs"), {
        variant: "warning",
        preventDuplicate: true,
      });
    } else if (!allowTakeBackViaGrs) {
      enqueueSnackbar(t("batt_g.brand.warning.no_registration"), {
        variant: "warning",
        preventDuplicate: true,
      });
    }
  };

  const takeBackInfo = () => {
    return (
      <Grid container spacing={4} className={"mt-4"}>
        <Grid item md={12} xs={12}>
          <FormTextField
            label={t("batt_g.brand.dialog.take_back_explanation")}
            hasError={Boolean(errors?.takeBackDescription)}
            required={true}
            control={control}
            name={"takeBackDescription"}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <p className={"m-0"}>{t("batt_g.brand.dialog.take_back_explanation_contact_data")}</p>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormTextField
            label={t("general.company.name.text")}
            hasError={Boolean(errors?.takeBackCompanyName)}
            required={true}
            control={control}
            name={"takeBackCompanyName"}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormTextField
            label={t("general.contact_person_name.text")}
            hasError={Boolean(errors?.takeBackContactPersonName)}
            required={true}
            control={control}
            name={"takeBackContactPersonName"}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <FormPhoneCountryField
            name="takeBackContactPersonTelephone"
            label={t("basedata.users.edit.phone")}
            control={control}
            hasError={Boolean(errors?.takeBackContactPersonTelephone)}
            required={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormTextField
            hasError={Boolean(errors?.takeBackContactPersonEmail)}
            errorMessage={errors?.takeBackContactPersonEmail?.message}
            label={t("general.email_contact_person.text")}
            control={control}
            name={"takeBackContactPersonEmail"}
            rules={{
              required: true,
              validate: async (value) => {
                if (!value || !isEmail(value)) {
                  return t("general.email.required.error");
                }
              },
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        } else {
          onCancel();
        }
      }}
      fullWidth={true}
      maxWidth="md"
      {...rest}
    >
      <div className="p-5">
        <Typography variant="h1" className={classes.headerStyle}>
          {getTitle()}
        </Typography>

        {isLoading && <LinearProgress />}

        {!isLoading && (
          <form>
            <AutofillCatcher />
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <FormTextField
                  label={t("batt_g.brand.dialog.name")}
                  hasError={Boolean(errors?.name)}
                  required={true}
                  control={control}
                  name={"name"}
                  rules={{
                    required: true,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormTextField
                  label={t("batt_g.brand.dialog.trademark")}
                  required={false}
                  control={control}
                  name={"trademarkRegisterNumber"}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormAutocomplete<IBattGGrsBatteryType>
                  getOptionLabel={(option) => option.name}
                  name={"batteryType"}
                  label={t("batt_g.brand.dialog.battery_type")}
                  options={batteryTypes ?? []}
                  disabled={!batteryTypes}
                  getOptionSelected={(option, value) => option.id === value.id}
                  required={true}
                  rules={{ required: true }}
                  error={Boolean(errors?.batteryType)}
                  control={control}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/*just an empty space*/}
              </Grid>

              <Grid item xs={12} md={6}>
                <FormDatepickerField
                  control={control}
                  name={"startDate"}
                  hasError={Boolean(errors?.startDate)}
                  required={true}
                  label={t("batt_g.brand.dialog.start_date")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormDatepickerField
                  control={control}
                  name={"endDate"}
                  hasError={Boolean(errors?.endDate)}
                  required={false}
                  label={t("batt_g.brand.dialog.end_date")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormCheckbox
                  name={"takeBackViaGrs"}
                  control={control}
                  label={t("batt_g.brand.dialog.take_back_via_grs")}
                  onValueChange={handleTakeBackViaGrsChanged}
                  noMargin
                />
              </Grid>
            </Grid>

            {selectedBatteryType && isTakeBackViaGrsSelected && (
              <div className="mt-4">{selectedBatteryType.description}</div>
            )}

            {!isTakeBackViaGrsSelected && takeBackInfo()}
          </form>
        )}
        <BattGFormButtonRowComponent
          onCancelButtonClicked={() => {
            reset();
            onCancel();
          }}
          hasErrors={async () => !(await trigger())}
          onSaveButtonClicked={brand?.status !== BattGBrandStatus.Published ? handleSubmit(onSubmit) : undefined}
          onSubmitWithFeeButtonClicked={handleSubmit(onSubmitWithFee)}
          isForBrands={true}
        />
      </div>
    </Dialog>
  );
};
