import { IUserLeanModel } from "../../models/user/user-lean.model";
import { IUserLean, IUserLeanList } from "../user/user-lean";

export class UserLeanConverter {
  public static toDomain(model: IUserLeanModel): IUserLean {
    return {
      active: model.active,
      userTypeLevel: model.userTypeLevel,
      id: model.id,
      deleted: model.deleted,
      deliveryEmail: model.deliveryEmail,
      email: model.email,
      externalId: model.externalId,
      rolePom: model.rolePom,
      groupId: model.groupId,
      firstName: model.firstName,
      jobTitle: model.jobTitle,
      uuid: model.uuid,
      language: model.language,
      lastName: model.lastName,
      mustResetPassword: model.mustResetPassword,
      telephone: model.telephone,
      title: model.title,
    };
  }

  public static listToDomain(model: IUserLeanModel[]): IUserLeanList {
    return {
      items: model.map(this.toDomain),
    };
  }
}
