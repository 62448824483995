import { Box, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollToTopIcon } from "../../../components/Primitives";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { Colors } from "../../../style/Colors";
import { SCROLL_TO_TOP_BUTTON_HEIGHT, SCROLL_TO_TOP_BUTTON_MIN_WIDTH } from "../../../utils/constants";
import { DRAWER_CLOSED, DRAWER_OPEN } from "../../domain/sidebar/sidebar";
import { useSidebarContext } from "../../domain/sidebar/sidebar-context";
import { NewOrderToolTip } from "../tooltips/order-new-tooltip.component";

const useStyles = makeStyles((theme: Theme) => ({
  sidebarOpenSpacing: {
    right: DRAWER_OPEN + 10,
  },
  sidebarClosedSpacing: {
    right: DRAWER_CLOSED + 10,
  },
  scrollToTopBase: {
    backgroundColor: Colors.greenLight,
    borderColor: Colors.greenLight,
    border: "1px solid",
    height: SCROLL_TO_TOP_BUTTON_HEIGHT,
    position: "fixed",
    zIndex: 500,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.greenDark,
      borderColor: Colors.greenDark,
    },
  },
  circle: {
    minWidth: SCROLL_TO_TOP_BUTTON_MIN_WIDTH,
    borderRadius: "100%",
    marginTop: 5,
    marginRight: 20,
    bottom: 15,
  },
  circleTablet: {
    right: 12,
  },
  mobile: {
    bottom: 0,
    width: "100%",
  },
  circleText: {
    color: Colors.white,
    textAlign: "center",
    lineHeight: `${SCROLL_TO_TOP_BUTTON_HEIGHT}px`,
  },
  icon: {
    height: 30,
    width: 30,
  },
  baseHeight: {
    height: SCROLL_TO_TOP_BUTTON_HEIGHT,
  },
  hidden: {
    display: "none",
  },
}));

export const ScrollToTopButton: FC = (props) => {
  const classes = useStyles();
  const { sidebarOpen } = useSidebarContext();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useScreenSize();
  const [scrolledToBottom, setHasScrolledToBottom] = useState(true);
  const handleScrollToTopClicked = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Use interval instead of scroll because if the window height is too big, the scroll to bottom event cannot be triggered
    // and the hasScrolled variable will not be updated to false if the user stops scrolling
    const interval = setInterval(() => {
      setHasScrolledToBottom(window.scrollY > window.innerHeight);
    }, 75);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box className={classes.baseHeight}>
      <NewOrderToolTip title={t("general.scrollToTop")}>
        <Box
          className={classNames(classes.scrollToTopBase, classes.circleText, {
            [classes.circle]: !isMobile,
            [classes.circleTablet]: !isMobile && isTablet,
            [classes.mobile]: isMobile,
            [classes.sidebarOpenSpacing]: sidebarOpen && !isMobile,
            [classes.sidebarClosedSpacing]: !sidebarOpen && !isMobile,
            [classes.hidden]: !scrolledToBottom,
          })}
          onClick={handleScrollToTopClicked}
        >
          <ScrollToTopIcon classes={{ root: classes.icon }} />
        </Box>
      </NewOrderToolTip>
    </Box>
  );
};
