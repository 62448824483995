import { AvailableConfiguratorRoutes, GERMANY_FALLBACK_ZIP, POSTAL_LENGTH } from "../../utils/constants";

export const getFormattedPostal = (postal: string, padCharacter?: string) => {
  if (postal.toUpperCase() === GERMANY_FALLBACK_ZIP || postal.length >= POSTAL_LENGTH) return postal;

  return postal.padEnd(POSTAL_LENGTH, padCharacter ?? "x");
};

export const getRedirectToCreateRoutingUrl = (productIds: number[]): string => {
  return `${AvailableConfiguratorRoutes.CreateProductRouting}?ids=${productIds.join(",")}`;
};

export const getRedirectToEditRoutingUrl = (productId: number, assignmentId: number): string => {
  return `${AvailableConfiguratorRoutes.EditProductRouting}?productId=${productId}&assignmentId=${assignmentId}`;
};
