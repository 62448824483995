import { IGroupBranchModel } from "../../models/group/group-branch.model";
import { IGroupBranch } from "../group/group-branch";

export class GroupBranchConverter {
  public static toModel(groupBranch: IGroupBranch): IGroupBranchModel {
    return {
      id: groupBranch.id,
      name: groupBranch.name,
    };
  }

  static toDomain(groupBranch: IGroupBranchModel): IGroupBranch {
    return {
      id: groupBranch.id,
      name: groupBranch.name ?? "",
    };
  }
}
