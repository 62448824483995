import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AvailableSharedRoutes } from "utils/constants";
import { useUnsavedDataContext } from "../../providers/App/unsaved-data-context";
import { QuestionDialog } from "../Dialogs/question-dialog";

const urlsToIgnore = [AvailableSharedRoutes.Login];

// https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b
export const UnsavedDataPrompt: FunctionComponent = () => {
  const { hasUnsavedData: when } = useUnsavedDataContext();
  const { t } = useTranslation();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        if (urlsToIgnore.includes(prompt.pathname)) {
          return;
        }

        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOk = useCallback(async () => {
    const onOkay = () => true;
    const canRoute = onOkay();
    if (canRoute) {
      history.block(() => {});
      history.push(currentPath);
    }
    setShowPrompt(false);
  }, [currentPath, history]);

  const handleCancel = useCallback(() => {
    const onCancel = () => false;
    const canRoute = onCancel();
    if (canRoute) {
      history.block(() => {});
      history.push(currentPath);
    }
    setShowPrompt(false);
  }, [currentPath, history]);

  return (
    <>
      {showPrompt && (
        <QuestionDialog
          isOpen={showPrompt}
          onAccept={handleOk}
          onClose={handleCancel}
          acceptText={t("general.leave")}
          displayStyle={"warning"}
          title={t("general.warning")}
          message={t("general.unsavedData")}
        />
      )}
    </>
  );
};
