import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ResetPasswordStatus } from "../../../../domain/user/user";
import { IForgotPasswordResponseModel } from "../../../../models/user/user.model";
import { SharedQueryKeys } from "../../shared-query-keys";

const forgotPassword = async (email: string, reCaptchaResponseToken: string | null): Promise<ResetPasswordStatus> => {
  const { data } = await getInstance().put<IForgotPasswordResponseModel>(RestEndpoints.UserForgotPassword, {
    email,
    reCaptchaResponseToken,
  });

  return data.status;
};

export const useForgotPasswordQuery = () => {
  return useMutation(
    SharedQueryKeys.ForgotPassword,
    ({ email, reCaptchaResponseToken }: { email: string; reCaptchaResponseToken: string | null }) =>
      forgotPassword(email, reCaptchaResponseToken),
  );
};
