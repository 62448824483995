import { Contact } from "shared/pages/contact/contact";
import { DataPrivacy } from "shared/pages/data-privacy/data-privacy";
import React, { VFC } from "react";
import { Redirect, Switch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuthContext } from "../../providers/Auth/auth.provider";
import { AuthenticatedRoute } from "../../utils/AuthenticatedRoute";
import { AvailableSharedRoutes } from "../../utils/constants";
import {
  isAdmin,
  isCfmCorporate,
  isCfmWasteProducer,
  isPomAgency,
  isPomMainContact,
  isPomMainContactOrCfmCorporate,
  IUser,
} from "../domain/user/user";
import { BattGRoutes } from "./batt-g/batt-g.routes";
import { GroupRoutes } from "./groups/group.routes";
import { UsersRoutes } from "./users/users.routes";

export const SharedRoutes: VFC = () => {
  const { internalUser } = useAuthContext();

  const canSeeBaseDataRoutes = (user: IUser | undefined) => {
    return (
      isAdmin(user) || isPomMainContact(user) || isPomAgency(user) || isCfmCorporate(user) || isCfmWasteProducer(user)
    );
  };

  return (
    <Switch>
      {/* contact & Data-Privacy */}
      <AuthenticatedRoute path={AvailableSharedRoutes.Contact} component={() => <Contact key={uuidv4()} />} />
      <AuthenticatedRoute path={AvailableSharedRoutes.DataPrivacy} component={() => <DataPrivacy key={uuidv4()} />} />

      {/*Batt-G*/}
      <AuthenticatedRoute
        path={AvailableSharedRoutes.BattG}
        allowedConditionCallback={isPomMainContactOrCfmCorporate}
        component={BattGRoutes}
      />

      {canSeeBaseDataRoutes(internalUser) && (
        <>
          <AuthenticatedRoute
            path={AvailableSharedRoutes.Group}
            allowedConditionCallback={isPomMainContact || isCfmCorporate}
          >
            <GroupRoutes />
          </AuthenticatedRoute>

          <AuthenticatedRoute path={AvailableSharedRoutes.UserList}>
            <UsersRoutes />
          </AuthenticatedRoute>
        </>
      )}

      <Redirect path={"*"} to={AvailableSharedRoutes.Portal} push />
    </Switch>
  );
};
