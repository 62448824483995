import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import { useState, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { WizardStepHeader } from "../../../../collect-from-market/pages/order-new/components/wizard-step-heading.component";
import { usePostalsForCreateAssignmentsTemplateQuery } from "../../../../collect-from-market/repositories/queries/routing-assignment/postals-for-create-assignments-template.query";
import { CsvExportIcon } from "../../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../../style/Colors";
import { ICreateProductRoutingFormInputs } from "../create-product-routing-content.component";
import { UploadPostalsDialog } from "../dialog/upload-postals-dialog.component";
import { ProductRoutingPostalSummary } from "../product-routing-postal-summary.component";
import { ProductRoutingPostalFormContent } from "./product-routing-postal-form-content.component";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: PrimaryColors.base,
  },
  buttonContainer: {
    marginTop: 3,
  },
}));

interface IProductRoutingPostalFormProps {
  isSummary: boolean;
  onEditClick: VoidFunction;
  canEdit?: boolean;
}

export const ProductRoutingPostalForm: VFC<IProductRoutingPostalFormProps> = (props) => {
  const { isSummary, onEditClick, canEdit } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const { setValue, watch } = useFormContext<ICreateProductRoutingFormInputs>();

  const { isLoading, refetch: downloadTemplate } = usePostalsForCreateAssignmentsTemplateQuery(false);

  const postals: string[] = watch("postals");

  const onExcelUploadAccepted = (result: string[]) => {
    setIsUploadDialogOpen(false);
    setValue("postals", result);
  };

  const downloadExcelTemplate = async () => {
    const downloadResult = await downloadTemplate();
    if (!downloadResult.data) return;
    const filename = "PLZ-Import.xlsx";
    const url = window.URL.createObjectURL(new Blob([downloadResult.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <UploadPostalsDialog
        open={isUploadDialogOpen}
        onCancel={() => setIsUploadDialogOpen(false)}
        onAccept={onExcelUploadAccepted}
      />
      <Grid container direction="column" spacing={2}>
        {!isSummary && (
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <WizardStepHeader text={t("configuration.assignments.create.postalHeading")} />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Button
                      endIcon={<CsvExportIcon className={classes.icon} />}
                      color="primary"
                      variant="outlined"
                      onClick={() => downloadExcelTemplate()}
                      disabled={isLoading}
                    >
                      {t("configuration.assignments.create.downloadTemplate")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      endIcon={<CsvExportIcon className={classes.icon} />}
                      color="primary"
                      variant="outlined"
                      onClick={() => setIsUploadDialogOpen(true)}
                    >
                      {t("configuration.assignments.create.uploadPostal")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isSummary && (
          <Grid item>
            <ProductRoutingPostalFormContent />
          </Grid>
        )}
        {isSummary && (
          <Grid item>
            <ProductRoutingPostalSummary postals={postals} canEdit={canEdit} onEditClick={onEditClick} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
