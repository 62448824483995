import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { formatDateYearMonth } from "../../../../shared/util/date.util";
import getInstance from "../../../../utils/rest";
import { PomQueryKeys } from "../../pom-query-keys";

const exportAnnouncement = async (
  date: Date | undefined,
  contractId: number | undefined,
): Promise<string | undefined> => {
  if (!date || !contractId) return undefined;
  const { data } = await getInstance().get(`${RestEndpoints.PomAnnouncements}/${formatDateYearMonth(date)}/export`, {
    params: {
      contractId,
    },
  });
  return data;
};

export const useExportAnnouncementQuery = (
  date: Date | undefined,
  contractId: number | undefined,
  enabled?: boolean,
) => {
  return useQuery([PomQueryKeys.ExportAnnouncement], () => exportAnnouncement(date, contractId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
