import { ICfmResponseValueReport } from "../../../../domain/report/cfm-response-value-report";
import { ICfmResponseValueReportModel } from "../../order-x/report/cfm-response-value-report.model";
import { CfmOrderXStatusConverter } from "./cfm-order-x-status.converter";

export class CfmOrderXResponseReportConverter {
  public static toDomain(model: ICfmResponseValueReportModel): ICfmResponseValueReport {
    return {
      responseValues: model.responseValues?.map((value) => ({
        fieldName: value.fieldName,
        status: CfmOrderXStatusConverter.toDomain(value.status),
        id: value.id,
        itemId: value.itemId,
        number: value.number,
        date: value.date,
        text: value.text,
        file: value.file,
        userId: value.userId,
        floatingPoint: value.floatingPoint,
        unit: value.unit,
        internalNumber: value.internalNumber,
      })),
      responseDates: model.responseDates?.map((responseDate) => ({
        fieldName: responseDate.fieldName,
        status: CfmOrderXStatusConverter.toDomain(responseDate.status),
        value: responseDate.value,
      })),
    };
  }
}
