import qs from "qs";
import { VFC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { EditProductRoutingContent } from "../../components/product-routing/edit-product-routing-content.component";

export const EditProductRoutingPage: VFC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const productIdString = qs.parse(location.search, { ignoreQueryPrefix: true }).productId as string | undefined;
  const assignmentIdString = qs.parse(location.search, { ignoreQueryPrefix: true }).assignmentId as string | undefined;

  if (!productIdString || !assignmentIdString) {
    history.push(AvailableConfiguratorRoutes.ProductsForRouting);
    return null;
  }

  const productId = Number(productIdString);
  const assignmentId = Number(assignmentIdString);

  return <EditProductRoutingContent productId={productId} assignmentId={assignmentId} />;
};
