import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getWarehouseTotalWeight = async (warehouseId: number | undefined): Promise<number | undefined> => {
  if (!warehouseId) return undefined;
  const { data } = await getInstance().get<{ weight: number }>(
    RestEndpoints.WarehouseTotalWeight.replace(":id", warehouseId.toString()),
  );
  return data.weight;
};

export const useGetWarehouseTotalWeight = (warehouseId: number | undefined) => {
  return useQuery([CfmQueryKeys.GetWarehouseTotalWeight, warehouseId], () => getWarehouseTotalWeight(warehouseId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(warehouseId),
  });
};
