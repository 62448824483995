import { ICfmAction } from "../../../domain/action/cfm-action";
import { ICfmActionModel } from "../action/cfm-action.model";

export class CfmActionConverter {
  public static toModel(action: ICfmAction): ICfmActionModel {
    return {
      id: action.id,
      name: action.name,
    };
  }

  public static toDomain(action: ICfmActionModel): ICfmAction {
    return {
      id: action.id,
      name: action.name,
    };
  }
}
