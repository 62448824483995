import { CfmServiceDate } from "../order-x/actions/cfm-service-date";
import { ICfmResponseFieldAssignment } from "../assignment/cfm-response-field-assignment";

export interface ICfmTransferOrderAvailableAction {
  type: CfmTransferOrderActionType;
  responseFieldAssignments?: ICfmResponseFieldAssignment[];
  serviceDates?: CfmServiceDate[];
}

export enum CfmTransferOrderActionType {
  Sorted = "Sorted",
  TruckloadCreated = "TruckloadCreated",
  TruckloadConfirmed = "TruckloadConfirmed",
  Recycled = "Recycled",
}
