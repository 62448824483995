import { FC } from "react";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { CartContent } from "./components/cart-content.component";

export const CartPage: FC = (props) => {
  return (
    <>
      <PageTitle pageTitleBreadcrumb />
      <CartContent />
    </>
  );
};
