import { FunctionComponent } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ICfmOrderFreshdeskRequest } from "../../../../domain/order-x/cfm-order-freshdesk-request";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { getStatusXName } from "../status-filter/status-x-util";
import { FreshdeskForm, IOrderXFreshdeskFormInputs } from "./freshdesk-form.component";
import { useAddOrderFreshdeskRequestQuery } from "../../../../repositories/queries/order-x/mutations/create-order-freshdesk-request.query";

interface IOrderXFreshDeskFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const OrderXFreshDeskForm: FunctionComponent<IOrderXFreshDeskFormProps> = (props) => {
  const { isOpen, setIsOpen } = props;
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const { mutateAsync: addOrderFreshdeskRequest } = useAddOrderFreshdeskRequestQuery();

  const getSubjectText = () => {
    return t("order_view.freshdesk_support", {
      orderId: order.id,
      translatedStatus: getStatusXName(order.status, t),
    });
  };

  const onSubmit: SubmitHandler<IOrderXFreshdeskFormInputs> = async (inputs): Promise<boolean> => {
    const requestData: ICfmOrderFreshdeskRequest = {
      contact: inputs.contactMail,
      text: inputs.text,
    };

    return await addOrderFreshdeskRequest({ orderId: order.id, requestData });
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return <FreshdeskForm isOpen={isOpen} subject={getSubjectText()} onCancel={onCancel} onSubmit={onSubmit} />;
};
