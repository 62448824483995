import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { CfmServiceDate } from "../../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { CalendarIcon } from "../../../../components/Primitives/Icons";
import { NewOrderToolTip } from "../../../../shared/components/tooltips/order-new-tooltip.component";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginTop: 2.5,
  },
}));

interface IProductResponseAssignmentServiceDateDetailProps {
  serviceDates: CfmServiceDate[];
}

export const ProductResponseAssignmentServiceDateDetail: VFC<IProductResponseAssignmentServiceDateDetailProps> = (
  props,
) => {
  const { serviceDates } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const translateServiceDate = (serviceDate: CfmServiceDate) => {
    return t(`configuration.responseAssignments.dates.${serviceDate}`);
  };

  const getDateLabel = () => {
    if (serviceDates.length === 1) {
      return (
        <Typography variant="body1" className={classes.text}>
          {translateServiceDate(serviceDates[0])}
        </Typography>
      );
    } else {
      const dateTranslations = serviceDates.map((date) => translateServiceDate(date)).join(", ");
      return (
        <NewOrderToolTip title={dateTranslations} placement="top">
          <Typography variant="body1" className={classes.text}>
            {t("configuration.responseAssignments.dates.plural")}
          </Typography>
        </NewOrderToolTip>
      );
    }
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <CalendarIcon svgColor="green" />
      </Grid>
      <Grid item>{getDateLabel()}</Grid>
    </Grid>
  );
};
