import { RestEndpoints } from "api/endpoints";
import { ICfmTransferOrderAvailableActionsModel } from "collect-from-market/repositories/models/transfer-order/cfm-transfer-order-available-action.model";
import { useQuery } from "react-query";
import getInstance from "utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmTransferOrderAvailableAction } from "../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { CfmTransferOrderAvailableActionConverter } from "../../../models/converter/transfer-order/cfm-transfer-order-available-action.converter";

const getTransferOrderActions = async (transferOrderId: number): Promise<ICfmTransferOrderAvailableAction[]> => {
  const { data } = await getInstance().get<ICfmTransferOrderAvailableActionsModel>(
    RestEndpoints.TransferOrderActions.replace(":id", transferOrderId.toString()),
  );

  return data.items.map((item) => CfmTransferOrderAvailableActionConverter.toDomain(item));
};

export const useGetTransferOrderActions = (transferOrderId: number) => {
  return useQuery([CfmQueryKeys.GetTransferOrderActions, transferOrderId], () =>
    getTransferOrderActions(transferOrderId),
  );
};
