import { Grid, makeStyles, Theme } from "@material-ui/core";
import { useState, VFC } from "react";
import { IGroup } from "../../../domain/group/group";
import { EditGroupTypeInfoDialog } from "../../../components/group/edit-group/dialog/edit-group-type-dialog.component";
import { GroupDetailPomInfo } from "./group-detail-pom-info.component";
import { GroupDetailTypeInfoName } from "./group-detail-type-info-name.component";

const useStyles = makeStyles((theme: Theme) => ({
  dataContainer: {
    marginTop: 15,
  },
}));

interface IGroupDetailTypeInfoProps {
  group: IGroup;
}

export const GroupDetailTypeInfo: VFC<IGroupDetailTypeInfoProps> = (props) => {
  const { group } = props;
  const classes = useStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <>
      <EditGroupTypeInfoDialog
        open={isEditOpen}
        group={group}
        onCancel={() => setIsEditOpen(false)}
        onSaved={() => setIsEditOpen(false)}
      />

      <Grid container direction="column">
        <Grid item>
          <GroupDetailTypeInfoName type={group.type} onEditClicked={() => setIsEditOpen(true)} />
        </Grid>
        <Grid item className={classes.dataContainer}>
          <Grid container direction="column">
            <Grid item>
              <GroupDetailPomInfo group={group} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
