import { VFC } from "react";
import { FieldPath, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDatepickerField } from "../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { OrderActionServiceDate } from "../../../../domain/order-x/actions/cfm-order-action";
import { CfmServiceDate } from "../../../../domain/order-x/actions/cfm-service-date";

interface ICfmServiceDatesInputProps {
  serviceDates: CfmServiceDate[];
}

interface ICfmServiceDatesInputFormFields {
  serviceDates?: OrderActionServiceDate;
}

export const CfmServiceDatesInput: VFC<ICfmServiceDatesInputProps> = (props) => {
  const { serviceDates } = props;
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext<ICfmServiceDatesInputFormFields>();
  const { t } = useTranslation();

  const getServiceDateForAction = (
    action: CfmServiceDate,
  ): { registerKey: FieldPath<ICfmServiceDatesInputFormFields>; hasError?: boolean; label: string } => {
    let registerKey: FieldPath<ICfmServiceDatesInputFormFields> | undefined = undefined;
    let hasError = undefined;
    let label = "";

    switch (action) {
      case CfmServiceDate.Delivery:
        label = t(`serviceDates.delivery`);
        registerKey = "serviceDates.delivery";
        hasError = Boolean(errors?.serviceDates?.delivery);
        break;
      case CfmServiceDate.Pickup:
        label = t(`serviceDates.pickup`);
        registerKey = "serviceDates.pickup";
        hasError = Boolean(errors?.serviceDates?.pickup);
        break;
      case CfmServiceDate.Recycling:
        label = t(`serviceDates.recycling`);
        registerKey = "serviceDates.recycling";
        hasError = Boolean(errors?.serviceDates?.recycling);
        break;
      case CfmServiceDate.Treatment:
        label = t(`serviceDates.treatment`);
        registerKey = "serviceDates.treatment";
        hasError = Boolean(errors?.serviceDates?.treatment);
        break;
    }

    return {
      label,
      registerKey,
      hasError,
    };
  };

  return (
    <>
      {serviceDates.map((action, index) => {
        const serviceDate = getServiceDateForAction(action);

        if (!serviceDate.registerKey) {
          return <></>;
        }

        return (
          <FormField key={`${action}-${index}`} md={12} className={"pb-4"}>
            <FormDatepickerField
              label={serviceDate.label}
              hasError={serviceDate.hasError}
              control={control}
              required={true}
              {...register(serviceDate.registerKey, {
                required: true,
                valueAsDate: true,
              })}
            />
          </FormField>
        );
      })}
    </>
  );
};
