import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AddButton } from "../../../components/Primitives";
import SearchField from "../../../components/SearchField";
import { PrimaryColors } from "../../../style/Colors";
import { IOrdersOfTruckloadFilter } from "./edit-truckload-content.component";
import { OrderOfTruckloadSorting } from "../../pages/order-x/order-truckload.provider";
import { ISortComponentOption, SortComponent } from "../../../shared/components/filter/sort.component";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  icon: {
    color: PrimaryColors.base,
  },
  searchFieldContainer: {
    paddingBottom: 24,
  },
}));

interface IOrdersOfTruckloadFilterProps {
  filter: IOrdersOfTruckloadFilter;
  setFilter: (data: IOrdersOfTruckloadFilter) => void;
  orderCount: number;
  onAddOrder: VoidFunction;
  showAddButton: boolean;
}

export const OrdersOfTruckloadFilter: VFC<IOrdersOfTruckloadFilterProps> = (props) => {
  const { filter, setFilter, orderCount, onAddOrder, showAddButton } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const displaySearchField = useMemo(() => orderCount > 5 || filter.query, [orderCount, filter]);

  const handleSortingChanged = (checked: boolean, sorting: OrderOfTruckloadSorting) => {
    if (checked) {
      setFilter({ ...filter, sorting });
    }
  };

  const sortOptions: ISortComponentOption[] = [
    {
      label: t(`orders.truckload.sorting.${OrderOfTruckloadSorting.WarehouseEntryDate}`),
      checked: filter.sorting === OrderOfTruckloadSorting.WarehouseEntryDate,
      value: "sortingTruckloadDate",
      onChange: (checked) => handleSortingChanged(checked, OrderOfTruckloadSorting.WarehouseEntryDate),
    },
    {
      label: t(`orders.truckload.sorting.${OrderOfTruckloadSorting.OrderNumber}`),
      checked: filter.sorting === OrderOfTruckloadSorting.OrderNumber,
      value: "sortingOrderNumber",
      onChange: (checked) => handleSortingChanged(checked, OrderOfTruckloadSorting.OrderNumber),
    },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row-reverse" justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md="auto">
            {showAddButton && (
              <AddButton onClick={onAddOrder}>
                <Typography variant="body1">{t("general.add.text")}</Typography>
              </AddButton>
            )}
          </Grid>
          <Grid item xs={12} md="auto">
            <SortComponent options={sortOptions} />
          </Grid>
        </Grid>
      </Grid>
      {displaySearchField && (
        <Grid item xs={12} className={classes.searchFieldContainer}>
          <SearchField
            size="large"
            tooltip={t("orders.truckload.ordersOfTruckload.searchToolTip")}
            onSearchSubmit={(value: string) => {
              setFilter({ ...filter, query: value });
            }}
            placeholder={t("order_filter_search_placeholder")}
            autoFocus={false}
          />
        </Grid>
      )}
    </Grid>
  );
};
