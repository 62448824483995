import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { UserConverter } from "../../../domain/converter/user.converter";
import { IUser } from "../../../domain/user/user";
import { SharedQueryKeys } from "../shared-query-keys";
import { ILoggedInUserModel } from "./../../../models/user/user.model";

const getLoggedInUserInfo = async (): Promise<{
  user: IUser;
  originalUser: IUser | null;
  status: number;
}> => {
  const { data, status } = await getInstance().get<ILoggedInUserModel>(RestEndpoints.GetUserInfo);
  return {
    user: UserConverter.toDomain(data.user),
    originalUser: data.originalUser ? UserConverter.toDomain(data.originalUser) : null,
    status,
  };
};

export const useGetLoggedInUserInfoQuery = (enabled: boolean = true) => {
  return useQuery([SharedQueryKeys.GetLoggedInUserInfo], () => getLoggedInUserInfo(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
