import { LinearProgress } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useHistory, useParams } from "react-router";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { AvailableSharedRoutes } from "../../../utils/constants";
import {
  IUserDetailParams,
  UserDetailContext,
  UserDetailProvider,
} from "../../components/user/user-form/user-detail.provider";
import { UserForm, UserFormMode } from "../../components/user/user-form/user-form.component";
import { UserFormProvider } from "../../components/user/user-form/user-form.provider";

export interface IUserDetailPageProps {
  mode: UserFormMode.Edit | UserFormMode.Detail;
}

export const UserDetailPage: FunctionComponent<IUserDetailPageProps> = ({ mode }) => {
  const { id } = useParams<IUserDetailParams>();
  const history = useHistory();

  const onSuccess = () => {
    history.push(AvailableSharedRoutes.UserList);
  };

  return (
    <UserDetailProvider userId={Number(id)}>
      <UserDetailContext.Consumer>
        {({ isLoading, user }) => (
          <UserFormProvider user={user} group={user?.group}>
            <PageTitle id={AvailableSharedRoutes.UserDetail} />
            {isLoading && <LinearProgress />}
            {!isLoading && user && <UserForm mode={mode} user={user} onSuccessCallback={onSuccess} />}
          </UserFormProvider>
        )}
      </UserDetailContext.Consumer>
    </UserDetailProvider>
  );
};
