import { Grid } from "@material-ui/core";
import lodash from "lodash";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { CfmResponseFieldType } from "../../../collect-from-market/domain/response-values-x/cfm-response-field-type";
import { CfmQueryKeys } from "../../../collect-from-market/repositories/queries/cfm-query-keys";
import SearchField from "../../../components/SearchField";
import { useProductResponseFieldOverviewContext } from "../../pages/product-response-field/product-response-field-overview.provider";
import { IConfigurationOption } from "../product/product-configuration.component";
import { ResetCreateProductFilterButton } from "../product/reset-create-product-filter-button";
import { ResponseFieldFilterConfiguration } from "./response-field-configuration.component";
import {
  getResponseFieldFieldTypeFilterOptions,
  getResponseFieldFilterInitialValue,
  getResponseFieldStatusFilterOptions,
  IResponseFieldConfigFilter,
  ResponseFieldStatus,
} from "./response-field.util";

export const ResponseFieldListFilter: VFC = (props) => {
  const { t } = useTranslation();

  const { filter, setFilter } = useProductResponseFieldOverviewContext();
  const queryClient = useQueryClient();

  const setConfigFilter = (config: IResponseFieldConfigFilter) => {
    setFilter({ ...filter, config });
  };

  const configFilter = useMemo(() => {
    return filter.config;
  }, [filter]);

  const onSearchSelect = (value: string) => {
    setFilter({ ...filter, searchText: value });
  };
  const onFieldTypeSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const options = value as IConfigurationOption[] | undefined;
    const fieldTypes: CfmResponseFieldType[] = options
      ? options.map((option) => option.value as CfmResponseFieldType)
      : [];
    const selectedTypes: CfmResponseFieldType[] = Object.values(CfmResponseFieldType).filter(
      (fieldType) => typeof fieldType !== "number" && fieldTypes.includes(fieldType),
    ) as CfmResponseFieldType[];
    setConfigFilter({ ...configFilter, type: selectedTypes });
  };

  const onStatusSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const option = value ? (value as IConfigurationOption) : undefined;
    setConfigFilter({ ...configFilter, status: option?.value as ResponseFieldStatus });
  };

  const isDirty = useMemo(
    () => !lodash.isEmpty(filter.config.status) || !lodash.isEmpty(filter.config.type),
    [filter.config.status, filter.config.type],
  );

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SearchField
          size="large"
          tooltip={t("configuration.search.responseFieldToolTip")}
          onSearchSubmit={onSearchSelect}
          placeholder={t("configuration.search.responseFields")}
          autoFocus={false}
        />
      </Grid>
      <Grid item>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={12}>
            <ResponseFieldFilterConfiguration
              selectedOptions={getResponseFieldFieldTypeFilterOptions(configFilter.type, t)}
              options={getResponseFieldFieldTypeFilterOptions(Object.values(CfmResponseFieldType), t)}
              onSelect={onFieldTypeSelect}
              label={t("configuration.responseField.filter.type")}
              multiSelect={true}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ResponseFieldFilterConfiguration
              selectedOptions={getResponseFieldStatusFilterOptions(configFilter.status, t)}
              options={getResponseFieldStatusFilterOptions(Object.values(ResponseFieldStatus), t)}
              onSelect={onStatusSelect}
              label={t("configuration.responseField.filter.status")}
              multiSelect={false}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
      {isDirty && (
        <Grid item xs={12}>
          <ResetCreateProductFilterButton
            onReset={() => {
              const initValues = getResponseFieldFilterInitialValue();
              setConfigFilter({ ...filter, status: initValues.config.status, type: initValues.config.type });
              queryClient.invalidateQueries(CfmQueryKeys.GetAllProducts);
            }}
            productCount={0}
            displayProductCountText={false}
            emptyLabelMinWidth={60}
          />
        </Grid>
      )}
    </Grid>
  );
};
