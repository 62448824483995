import React, { createContext, useContext, useState } from "react";
import { CfmServiceDate } from "../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { CfmOrderXApiStatus } from "../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { CfmResponseFieldType } from "../../../collect-from-market/domain/response-values-x/cfm-response-field-type";
import { CfmResponseFieldBillingType } from "../../domain/response-field-billing-type";

export enum ProductResponseAssignmentStep {
  ORDER_STATUS = "ORDER_STATUS",
  RESPONSE_FIELDS = "RESPONSE_FIELDS",
}

export interface IResponseFieldData {
  id: number;
  name: string;
  type: CfmResponseFieldType;
  serviceDate: CfmServiceDate | null;
  warehouseArticleId: number | null;
  billingType: CfmResponseFieldBillingType | null;
  internalNumber: number | null;
  unit: string | null;
}

export interface IResponseAssignmentRowValue {
  status: CfmOrderXApiStatus;
  responseFieldData?: IResponseFieldData[];
  serviceDates?: CfmServiceDate[];
}

interface IProductResponseAssignmentContextType {
  activeStep: ProductResponseAssignmentStep;
  setActiveStep: (activeStep: ProductResponseAssignmentStep) => void;
  selectedResponseAssignmentRowValues: IResponseAssignmentRowValue[];
  setSelectedResponseAssignmentRowValues: (values: IResponseAssignmentRowValue[]) => void;
  activeStatus: CfmOrderXApiStatus | undefined;
  setActiveStatus: (activeStatus: CfmOrderXApiStatus | undefined) => void;
  getActiveStatusIndex: () => CfmOrderXApiStatus;
}

interface IProductResponseAssignmentProviderProps {
  children: any;
}

const ProductResponseAssignmentContext = createContext<IProductResponseAssignmentContextType>(
  {} as IProductResponseAssignmentContextType,
);

export const ProductResponseAssignmentProvider = (props: IProductResponseAssignmentProviderProps) => {
  const value = useProductResponseAssignmentProvider(props);
  return (
    <ProductResponseAssignmentContext.Provider value={value}>
      {props.children}
    </ProductResponseAssignmentContext.Provider>
  );
};

export const useProductResponseAssignmentContext = () => {
  return useContext(ProductResponseAssignmentContext);
};

const useProductResponseAssignmentProvider = (
  props: IProductResponseAssignmentProviderProps,
): IProductResponseAssignmentContextType => {
  const confirmedStep: IResponseAssignmentRowValue = {
    status: CfmOrderXApiStatus.Confirmed,
  };
  const [activeStep, setActiveStep] = useState<ProductResponseAssignmentStep>(
    ProductResponseAssignmentStep.ORDER_STATUS,
  );
  const [selectedResponseAssignmentRowValues, setSelectedResponseAssignmentRowValues] = useState<
    IResponseAssignmentRowValue[]
  >([confirmedStep]);
  const [activeStatus, setActiveStatus] = useState<CfmOrderXApiStatus | undefined>(undefined);

  const getActiveStatusIndex = () => {
    return activeStatus ? selectedResponseAssignmentRowValues.findIndex((values) => values.status === activeStatus) : 0;
  };

  return {
    activeStep,
    setActiveStep,
    selectedResponseAssignmentRowValues,
    setSelectedResponseAssignmentRowValues,
    activeStatus,
    setActiveStatus,
    getActiveStatusIndex,
  };
};
