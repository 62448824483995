import React, { createContext, useContext, useEffect, useState } from "react";

interface IScrolledToBottomContextType {
  hasScrolledToBottom: boolean;
}

const ScrolledToBottomContext = createContext<IScrolledToBottomContextType>({} as IScrolledToBottomContextType);

export const ScrolledToBottomProvider = (props: any) => {
  const value = useScrolledToBottomProvider();
  return <ScrolledToBottomContext.Provider value={value}>{props.children}</ScrolledToBottomContext.Provider>;
};

export const useScrolledToBottomContext = () => {
  return useContext(ScrolledToBottomContext);
};

const useScrolledToBottomProvider = (): IScrolledToBottomContextType => {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  // set has scrolled to bottom if body height changes
  const resizeObserver = new ResizeObserver(() => {
    setHasScrolledToBottom(window.innerHeight > document.body.clientHeight);
  });

  // start observing body
  resizeObserver.observe(document.body);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const documentHeight = document.body.scrollHeight;
      const currentScroll = window.scrollY + window.innerHeight;
      // When the user is [modifier]px from the bottom, fire the event.
      const modifier = 200;
      if (currentScroll + modifier > documentHeight) {
        setHasScrolledToBottom(true);
      } else {
        setHasScrolledToBottom(false);
      }
    });
  }, []);

  return {
    hasScrolledToBottom,
  };
};
