import { Box, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../info-box/info-box";
import SearchField from "../../../components/SearchField";
import { IPaginationInputMeta } from "../../domain/pagination/pagination-meta";
import { AppTablePagination } from "./app-table-pagination.component";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  alignSpinner: {
    textAlign: "center",
  },
  rowsContainer: {
    marginTop: 2,
    marginBottom: 2,
  },
  searchFieldContainer: {
    marginBottom: 25,
  },
  paginationContainer: {
    marginTop: 25,
  },
}));

export interface ITableRow {
  id: string | number;
  labelComponent: JSX.Element;
  iconComponent: JSX.Element;
  isActive: boolean | undefined; // display active only if it is not undefined
}

export interface ISearchFieldProps {
  tooltip: string | undefined;
  placeholder: string | undefined;
  onSearchSubmit: (text: string | undefined) => void;
}

interface IAppTableProps {
  entries: ITableRow[];
  searchFieldProps?: ISearchFieldProps;
  paginationInputMeta: IPaginationInputMeta;
  setPaginationInputMeta: (inputMeta: IPaginationInputMeta) => void;
  numberOfRecords: number;
  onRowClicked: (tableRow: ITableRow) => void;
  isLoading?: boolean;
}

export const AppTable: VFC<IAppTableProps> = (props) => {
  const {
    searchFieldProps,
    numberOfRecords,
    isLoading,
    onRowClicked,
    entries,
    setPaginationInputMeta,
    paginationInputMeta,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();

  const displaySearchField = useMemo(() => {
    return numberOfRecords > 5 || searchText;
  }, [numberOfRecords, searchText]);

  return (
    <Grid container direction="column" spacing={1} className={classes.tableContainer}>
      {displaySearchField && searchFieldProps && (
        <Grid item xs={12} className={classes.searchFieldContainer}>
          <SearchField
            size="large"
            onSearchSubmit={(value: string) => {
              setSearchText(value);
              searchFieldProps.onSearchSubmit(value);
            }}
            placeholder={searchFieldProps.placeholder}
            tooltip={searchFieldProps.tooltip}
            autoFocus={false}
          />
        </Grid>
      )}
      {isLoading && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item md={12} className={classes.alignSpinner}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isLoading && entries.length === 0 && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item md={12} className={classes.alignSpinner}>
              <Typography variant="body1">{t("table.body.emptyDataSourceMessage")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {entries.map((entry) => {
        return (
          <Box key={entry.id} className={classes.rowsContainer}>
            <InfoBox
              iconComponent={entry.iconComponent}
              labelComponent={entry.labelComponent}
              onClick={() => {
                onRowClicked(entry);
              }}
              isActive={entry.isActive}
            />
          </Box>
        );
      })}
      <Grid item xs={12} className={classes.paginationContainer}>
        <AppTablePagination
          page={paginationInputMeta.page}
          setPage={(page: number) => setPaginationInputMeta({ page, pageSize: paginationInputMeta.pageSize })}
          numberOfRecords={numberOfRecords}
        />
      </Grid>
    </Grid>
  );
};
