import React, { FC } from "react";
import { Switch } from "react-router-dom";
import { UsersFilterProvider } from "../../../providers/Users/users-filter-provider";
import { AuthenticatedRoute } from "../../../utils/AuthenticatedRoute";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { UserFormMode } from "../../components/user/user-form/user-form.component";
import { GroupType } from "../../domain/group/group-type";
import { UserAddPage } from "../../pages/users/user-add.page";
import { UserDetailPage } from "../../pages/users/user-detail.page";
import { UserListPage } from "../../pages/users/users-list.page";

export const UsersRoutes: FC = () => {
  return (
    <UsersFilterProvider>
      <Switch>
        <AuthenticatedRoute
          path={AvailableSharedRoutes.UserCreate}
          component={UserAddPage}
          allowedRoles={[GroupType.Admin]}
        />

        <AuthenticatedRoute
          path={AvailableSharedRoutes.UserEdit}
          component={() => <UserDetailPage mode={UserFormMode.Edit} />}
          allowedRoles={[GroupType.Admin]}
        />

        <AuthenticatedRoute
          path={AvailableSharedRoutes.UserDetail}
          component={() => <UserDetailPage mode={UserFormMode.Detail} />}
          allowedRoles={[GroupType.Admin]}
        />

        <AuthenticatedRoute
          path={AvailableSharedRoutes.UserList}
          component={UserListPage}
          allowedRoles={[GroupType.Admin]}
        />
      </Switch>
    </UsersFilterProvider>
  );
};
