import { Grid } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "../../../../../../shared/components/loading-spinner.component";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { RespondDestinationArrivedRecyclerDialog } from "./dialog/respond-destination-arrived-recycler-dialog.component";
import { DestinationArrivedRecyclerWarehouseRow } from "./destination-arrived-recycler-warehouse-list.component";
import { ICfmTransferOrder } from "../../../../../domain/transfer-order/cfm-transfer-order";
import { useGetTransferOrders } from "../../../../../repositories/queries/transfer-order/queries/get-transfer-orders.query";
import { SelectedFilesProvider } from "../../actions/providers/selected-files.provider";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";
import { ICfmTransferOrderAvailableAction } from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { ListFooterComponent } from "../../../../../../shared/components/list/list-footer.component";
import { AddressFilter } from "../../../../../../shared/components/address/address-filter.component";
import { AddressType } from "../../../../../../shared/models/address/address.model";
import { CfmTransferOrderStatusModel } from "../../../../../repositories/models/transfer-order/cfm-transfer-order-status.model";

export const DestinationArrivedRecyclerWarehouseView: VFC = () => {
  const { t } = useTranslation();
  const [selectedWarehouse, setSelectedWarehouse] = useState<IAddressLean | undefined>();
  const [selectedTransferOrder, setSelectedTransferOrder] = useState<
    { order: ICfmTransferOrder; action: ICfmTransferOrderAvailableAction } | undefined
  >();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleWarehouseChanged = (warehouse: IAddressLean | null) => {
    setSelectedWarehouse(warehouse ?? undefined);
  };

  const onRespondClicked = (transferOrder: ICfmTransferOrder, action: ICfmTransferOrderAvailableAction) => {
    setSelectedTransferOrder({ order: transferOrder, action: action });
    setIsOpen(true);
  };

  const { isLoading, data: transferOrders } = useGetTransferOrders(
    {
      status: CfmTransferOrderStatusModel.Pending,
      targetAddressId: selectedWarehouse?.id,
    },
    !!selectedWarehouse?.id,
  );

  const hasOrders = useMemo(() => {
    return transferOrders && transferOrders.length > 0;
  }, [transferOrders]);

  const onCancel = () => {
    setIsOpen(false);
  };

  const onSubmitDialog = () => {
    setSelectedTransferOrder(undefined);
    setIsOpen(false);
  };

  return (
    <SelectedFilesProvider>
      {selectedWarehouse && selectedTransferOrder && isOpen && (
        <RespondDestinationArrivedRecyclerDialog
          transferOrder={selectedTransferOrder.order}
          availableAction={selectedTransferOrder.action}
          isOpen={isOpen}
          onSubmitted={onSubmitDialog}
          onCancel={onCancel}
        />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12} sm={4}>
          <AddressFilter handleAddressChanged={handleWarehouseChanged} addressTypes={[AddressType.RecyclerLocation]} />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoadingSpinner isLoading={isLoading} />
          </Grid>
        )}
        {!selectedWarehouse && !isLoading && (
          <Grid item>
            <EmptyWarehouse text={t("orders.destinationArrived.selectWarehouse")} />
          </Grid>
        )}
        {selectedWarehouse && !hasOrders && !isLoading && (
          <Grid item>
            <EmptyWarehouse text={t("orders.destinationArrived.noOrders")} />
          </Grid>
        )}

        <Grid item container direction="row" spacing={1}>
          {(transferOrders ?? []).map((order) => {
            return (
              <Grid item xs={12} key={order.id}>
                <DestinationArrivedRecyclerWarehouseRow
                  key={order.id}
                  transferOrder={order}
                  onRespondClicked={onRespondClicked}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <ListFooterComponent />
    </SelectedFilesProvider>
  );
};
