import { ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSidebarStyle } from "../../../style/sidebar.style";

interface ISubListItemProps {
  onClick: () => void;
  label: string;
  isActive?: boolean;
}

export const SubMenuItem: React.VFC<ISubListItemProps> = (props) => {
  const classes = useSidebarStyle();
  const { t } = useTranslation();
  const { onClick, label, isActive } = props;

  return (
    <ListItem dense key={label} button className={classes.subListItem} onClick={onClick}>
      <ListItemText primary={t(label)} className={isActive ? classes.activeListTitle : classes.listTitle} />
    </ListItem>
  );
};
