export enum UserTypeLevel {
  Corporate = "corporate",
  WasteProducer = "waste_producer",
}

export const getUserTypeLevelTranslation = (type: UserTypeLevel): string => {
  switch (type) {
    case UserTypeLevel.Corporate:
      return "create.user.type.corporate";
    case UserTypeLevel.WasteProducer:
      return "create.user.type.waste_producer";
  }
};
