import { makeStyles } from "@material-ui/core";
import { PADDING_STATUS } from "../config/theme/overrides/constant";
import { Colors, PrimaryColors, Shades } from "./Colors";

export const useStatusStyle = makeStyles(() => ({
  root: {
    height: "40px",
    color: Colors.greenLight,
    border: `2px solid ${Colors.greenLight}`,
    padding: PADDING_STATUS,
    alignItems: "center",
    margin: "0 1px",
    backgroundColor: Shades.white,
    borderRadius: 4,
    "& .MuiTypography-root": {
      color: Colors.greenLight,
    },
  },
  hover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      border: `2px solid ${PrimaryColors.hover}`,
      "& .MuiTypography-root": {
        color: Shades.white,
      },
    },
  },
  mobileRoot: {
    height: "40px",
    color: Colors.greenLight,
    border: `2px solid ${Colors.greenLight}`,
    padding: PADDING_STATUS,
    alignItems: "center",
    margin: "0 1px 10px",
    backgroundColor: Shades.white,
    borderRadius: 4,
    "& .MuiTypography-root": {
      color: Colors.greenLight,
    },
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      cursor: "pointer",
      "& .MuiTypography-root": {
        color: Shades.white,
      },
      border: `2px solid ${PrimaryColors.hover}`,
    },
  },
  active: {
    backgroundColor: PrimaryColors.active,
    "& .MuiTypography-root": {
      color: Shades.white,
    },
    border: `2px solid ${PrimaryColors.active}`,
  },
}));
