import { Box, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { EditIconNoBorderButton } from "../../../components/Primitives/Buttons";
import { useProductPackageDataDisplayStyle } from "../../../style/product-package-data-display.style";

interface IPackageDataDisplayProps {
  packages: ICfmPackage[];
  onEditClick: VoidFunction;
}

export const PackageDataDisplay: VFC<IPackageDataDisplayProps> = (props) => {
  const { packages, onEditClick } = props;
  const classes = useProductPackageDataDisplayStyle();
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Grid item>
        <Box className={classes.labelContainer}>
          <Typography variant="body1" className={classes.label}>
            {t("configuration.packages")}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.dataContainer}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="column" className={classes.productOrPackageContainer} spacing={1}>
              {packages
                .sort((p1, p2) => (p1.name ?? "").localeCompare(p2.name ?? ""))
                .map((pkg) => {
                  return (
                    <Grid item key={pkg.id}>
                      <Grid container direction="row">
                        <Grid item>
                          <Box display={"flex"} alignItems={"center"} className={classes.container}>
                            <Typography variant="body1" className={classNames(classes.name, classes.packageName)}>
                              {pkg.name}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" className={classes.container} justifyContent="center">
              <Grid item>
                <EditIconNoBorderButton className={classes.iconDimensions} onClick={onEditClick} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
