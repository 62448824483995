import { Grid, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useScreenSize } from "../../hooks/use-screen-size";
import { useStatusStyle } from "../../style/status.style";

const useStyles = makeStyles({
  itemContainer: {
    display: "flex",
  },
});

interface IStatusItemProps {
  isActive: boolean;
  onClick?: () => void;
}

export const StatusItem: FC<IStatusItemProps> = (props) => {
  const { children, onClick, isActive } = props;
  const { root, active, mobileRoot, hover } = useStatusStyle();
  const { isLaptop, isDesktop } = useScreenSize();
  const [joinedClasses, setJoinedClasses] = useState<string>([root].join(" "));
  const classes = useStyles();

  useEffect(() => {
    const classes = isLaptop || isDesktop ? [root] : [mobileRoot];

    if (isActive) {
      classes.push(active);
    }

    if (onClick) {
      classes.push(hover);
    }

    setJoinedClasses(classes.join(" "));
  }, [active, isLaptop, isDesktop, isActive, mobileRoot, root, hover, onClick]);

  return (
    <Grid
      container
      onClick={onClick}
      className={[classes.itemContainer, joinedClasses].join(" ")}
      alignContent="center"
    >
      {children}
    </Grid>
  );
};
