import { PropsWithChildren } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useAutocompleteStyles } from "../../../style/autocomplete.style";
import { useTranslation } from "react-i18next";

export interface ICompleteFilter<T> {
  handleFilterChanged: (filter: T | null) => void;
  getOptionLabel: (option: T) => string;
  placeholder: string;
  isLoading: boolean;
  options: T[];
}

export function AutocompleteFilter<T>(props: PropsWithChildren<ICompleteFilter<T>>) {
  const { options, getOptionLabel, placeholder, isLoading, handleFilterChanged } = props;
  const autoCompleteClasses = useAutocompleteStyles();
  const { t } = useTranslation();

  return (
    <Autocomplete
      options={options}
      classes={{
        endAdornment: autoCompleteClasses.adornment,
      }}
      renderInput={(params) => (
        <TextField className={autoCompleteClasses.input} placeholder={placeholder} {...params} />
      )}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      onChange={(event, value) => handleFilterChanged(value)}
      loadingText={t("general.loading")}
      noOptionsText={t("general.autocomplete.noOptions")}
    />
  );
}
