import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FunctionComponent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getErrorCode, getErrorStatusCode } from "../../../api/api-response";
import { AddIconButton, CfmPlatformLogo, PomPlatformLogo } from "../../../components/Primitives";
import useAsyncEffect from "../../../hooks/use-async-effect";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { removeNullAndUndefined } from "../../../utils/filter.util";
import { ICfmGroupContract, IPomGroupContract } from "../../domain/group/group-contract";
import { IPaginationInputMeta, IPaginationMeta } from "../../domain/pagination/pagination-meta";
import { isAdmin } from "../../domain/user/user";
import { useGroupDetailContext } from "../../pages/groups/group-detail.provider";
import {
  IGroupContractsResponse,
  useGetGroupContracts,
} from "../../repositories/queries/group/get-group-contracts.query";
import { getFormattedContractSubtitle, getFormattedContractTitle } from "../../util/contract.util";
import { GroupContractFormMode } from "../group/group-contract-form/group-contract-form.component";
import { GroupContractFormModal } from "../group/group-contract-modal/group-contract-form-modal.component";
import { GroupDetailHeader } from "../../pages/groups/components/group-detail-header.component";
import { AppTable, ITableRow } from "../tables/app-table.component";

const useStyles = makeStyles((theme: Theme) => ({
  contractIcon: {
    height: 40,
    width: 50,
  },
}));

interface IGroupContractsTable {
  readOnly: boolean;
}

interface IGroupContractsTableData {
  cfmContract?: ICfmGroupContract;
  pomContract?: IPomGroupContract;
}

export const GroupContractsTable: FunctionComponent<IGroupContractsTable> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { internalUser } = useAuthContext();
  const { readOnly } = props;
  const { group } = useGroupDetailContext();
  const [groupContracts, setGroupContracts] = useState<IGroupContractsTableData[] | undefined>();
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });
  const [pagination, setPagination] = useState<IPaginationMeta>({
    page: 0,
    pageSize: 5,
    numberOfPages: 0,
    numberOfRecords: 0,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContract, setSelectedContract] = useState<IGroupContractsTableData | undefined>();
  const contractFormMode = useRef<GroupContractFormMode>(GroupContractFormMode.Create);

  const handleOnLoadSuccess = (data: IGroupContractsResponse) => {
    const contracts: IGroupContractsTableData[] = [];
    data.cfmContracts.forEach((contract) => contracts.push({ cfmContract: contract }));
    data.pomContracts.forEach((contract) => contracts.push({ pomContract: contract }));
    setGroupContracts(contracts);
    setPagination(data.meta);
  };

  const handleOnLoadError = (err: any) => {
    const errorMsg = getErrorCode(err);
    const errorCode = getErrorStatusCode(err);
    enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
  };

  const { isLoading, refetch } = useGetGroupContracts(
    group.id,
    paginationInputMeta,
    handleOnLoadSuccess,
    handleOnLoadError,
  );

  useAsyncEffect(
    async (isActive) => {
      if (!isActive()) return;
      await refetch();
    },
    [paginationInputMeta],
  );

  const getContractLabelComponent = (contract: ICfmGroupContract | IPomGroupContract) => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body1">{getFormattedContractTitle(contract)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{getFormattedContractSubtitle(contract, t)}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getMappedEntries = (): ITableRow[] => {
    if (!groupContracts) return [];

    return groupContracts
      .map((contract) => {
        let entry: ITableRow | undefined = undefined;

        if (contract.pomContract) {
          entry = {
            id: contract.pomContract.id,
            labelComponent: getContractLabelComponent(contract.pomContract),
            isActive: dayjs().isBetween(contract.pomContract.startDate, contract.pomContract.endDate ?? "2999-01-01"),
            iconComponent: <PomPlatformLogo className={classes.contractIcon} />,
          };
        }

        if (contract.cfmContract) {
          entry = {
            id: contract.cfmContract.id,
            labelComponent: getContractLabelComponent(contract.cfmContract),
            isActive: dayjs().isBetween(contract.cfmContract.startDate, contract.cfmContract.endDate ?? "2999-01-01"),
            iconComponent: <CfmPlatformLogo className={classes.contractIcon} />,
          };
        }

        return entry;
      })
      .filter(removeNullAndUndefined);
  };

  const handleSaved = async () => {
    setSelectedContract(undefined);
    setIsModalOpen(false);
    await refetch();
  };

  return (
    <Box>
      {isModalOpen && (
        <GroupContractFormModal
          key={`${selectedContract?.cfmContract?.id ?? selectedContract?.pomContract?.id}-${contractFormMode.current}`}
          open={isModalOpen}
          onCancel={() => {
            setSelectedContract(undefined);
            setIsModalOpen(false);
          }}
          onSaved={handleSaved}
          initialMode={contractFormMode.current}
          baseContractInitialValues={selectedContract?.cfmContract ?? selectedContract?.pomContract}
          pomContractInitialValues={selectedContract?.pomContract?.contractField}
          cfmContractInitialValues={selectedContract?.cfmContract?.contractField}
          groupType={group.type}
          groupId={group.id}
          readOnly={readOnly}
        />
      )}

      <GroupDetailHeader>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">{t("basedata.groups.contractsTable.contracts")}</Typography>
          </Grid>
          {isAdmin(internalUser) && (
            <Grid item>
              <AddIconButton
                onClick={() => {
                  contractFormMode.current = GroupContractFormMode.Create;
                  setSelectedContract(undefined);
                  setIsModalOpen(true);
                }}
              />
            </Grid>
          )}
        </Grid>
      </GroupDetailHeader>

      <AppTable
        entries={getMappedEntries()}
        paginationInputMeta={paginationInputMeta}
        setPaginationInputMeta={setPaginationInputMeta}
        numberOfRecords={pagination.numberOfRecords ?? 0}
        onRowClicked={(tableRow) => {
          contractFormMode.current = GroupContractFormMode.Detail;
          const selectedPomContract = groupContracts?.find((c) => c.pomContract?.id === tableRow.id);
          const selectedCfmContract = groupContracts?.find((c) => c.cfmContract?.id === tableRow.id);

          setSelectedContract(selectedPomContract ?? selectedCfmContract);
          setIsModalOpen(true);
        }}
        isLoading={isLoading}
      ></AppTable>
    </Box>
  );
};
