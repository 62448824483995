import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";
import { ReactComponent as Paloxe600 } from "../../assets/icons/600er_Paloxe_H40px.svg";
import { ReactComponent as CancelGreen } from "../../assets/icons/Abbrechen_22px_gruen.svg";
import { ReactComponent as CancelWhite } from "../../assets/icons/Abbrechen_22px_weiss.svg";
import { ReactComponent as GroupAddressMarkerIcon } from "../../assets/icons/address-marker.svg";
import { ReactComponent as ArrowRightBigSvg } from "../../assets/icons/arrow-right-big.svg";
import { ReactComponent as OrdersGreen } from "../../assets/icons/Auftraege_25px_gruen.svg";
import { ReactComponent as OrdersWhite } from "../../assets/icons/Auftraege_25px_weiss.svg";
import { ReactComponent as OrderNewGreen } from "../../assets/icons/AuftragNeu_25px_gruen.svg";
import { ReactComponent as OrderNewWhite } from "../../assets/icons/AuftragNeu_25px_weiss.svg";
import { ReactComponent as Product } from "../../assets/icons/Batterieprodukte_BIG_H40px.svg";
import { ReactComponent as ProductMobile } from "../../assets/icons/Batterieprodukte_Mobile_B30px.svg";
import { ReactComponent as EditGreen } from "../../assets/icons/Bearbeiten_20px_gruen.svg";
import { ReactComponent as EditWhite } from "../../assets/icons/Bearbeiten_20px_weiss.svg";
import { ReactComponent as UserGreen } from "../../assets/icons/Benutzer_25px_gruen.svg";
import { ReactComponent as UserWhite } from "../../assets/icons/Benutzer_25px_weiss.svg";
import { ReactComponent as CheckboxApproveGreen } from "../../assets/icons/Bestaetigen_Haken_22px_gruen.svg";
import { ReactComponent as CheckboxApproveWhite } from "../../assets/icons/Bestaetigen_Haken_22px_weiss.svg";
import { ReactComponent as BigBag } from "../../assets/icons/Big_Bag_H40px.svg";
import { ReactComponent as CsvExportWhite } from "../../assets/icons/csv_export_22px.weiss.svg";
import { ReactComponent as AddEntryWhite } from "../../assets/icons/Eintrag_hinzufügen_25px.svg";
import { ReactComponent as AddEntryGreen } from "../../assets/icons/Eintrag_hinzufügen_25px_gruen.svg";
import { ReactComponent as RepeatOrderGreen } from "../../assets/icons/Erneut_bestellen_25px_gruen.svg";
import { ReactComponent as RepeatOrderWhite } from "../../assets/icons/Erneut_bestellen_25px_weiss.svg";
import { ReactComponent as FilterGreen } from "../../assets/icons/Filtern_20px_gruen.svg";
import { ReactComponent as FilterWhite } from "../../assets/icons/Filtern_20px_weiss.svg";
import { ReactComponent as AddWhite } from "../../assets/icons/Hinzufuegen_15px_weiss.svg";
import { ReactComponent as EuroPallet } from "../../assets/icons/ICN_Europalette.svg";
import { ReactComponent as ConfigGreen } from "../../assets/icons/ICN_Konfigurator_gruen.svg";
import { ReactComponent as ProductConfig } from "../../assets/icons/ICN_Produktkonfig_H25px_gruen.svg";
import { ReactComponent as RoutingConfigSmall } from "../../assets/icons/ICN_Routingkonfig_H25px_gruen.svg";
import { ReactComponent as RoutingConfig } from "../../assets/icons/ICN_Routingkonfig_H40px_rot.svg";
import { ReactComponent as Cardboard } from "../../assets/icons/ICN_Sammelkarton_H40px.svg";
import { ReactComponent as ConfigDone } from "../../assets/icons/ICN_Status_erledigt_16px_gruen.svg";
import { ReactComponent as ConfigMissing } from "../../assets/icons/ICN_Status_offen_16px_rot.svg";
import { ReactComponent as CreatePackageConfig } from "../../assets/icons/ICN_Verpackkonfig_H25px_gruen.svg";
import { ReactComponent as PackageConfigSmall } from "../../assets/icons/ICN_Verpackungen_H25px_rot.svg";
import { ReactComponent as PackageConfig } from "../../assets/icons/ICN_Verpackungen_H40px_rot.svg";
import { ReactComponent as InvoiceAddressMarkerIcon } from "../../assets/icons/invoice-address-marker.svg";
import { ReactComponent as CalendarGreen } from "../../assets/icons/Kalender_25px_gruen.svg";
import { ReactComponent as CalendarBlack } from "../../assets/icons/Kalender_25px_schwarz.svg";
import { ReactComponent as CalendarWhite } from "../../assets/icons/Kalender_25px_weiss.svg";
import { ReactComponent as CustomerContainer } from "../../assets/icons/Kundeneigener_Behaelter_H40px.svg";
import { ReactComponent as LoadCarrierXL } from "../../assets/icons/Ladungstraeger_L_XL_H40px.svg";
import { ReactComponent as AddressMarker } from "../../assets/icons/Leistungsort_H40px.svg";
import { ReactComponent as Delete } from "../../assets/icons/Loeschen_20px.svg";
import { ReactComponent as DeleteWhite } from "../../assets/icons/Loeschen_20px_weiss.svg";
import { ReactComponent as LoginAsUserIcon } from "../../assets/icons/login-as-user.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as MenuBurgerGreen } from "../../assets/icons/Menu_Burger_30px_gruen.svg";
import { ReactComponent as MenuBurgerWhite } from "../../assets/icons/Menu_Burger_30px_weiss.svg";
import { ReactComponent as MenuOpenGreen } from "../../assets/icons/Menu_einklappen_30px_gruen.svg";
import { ReactComponent as MenuOpenWhite } from "../../assets/icons/Menu_einklappen_30px_weiss.svg";
import { ReactComponent as NavigationLeftLastIcon } from "../../assets/icons/navigation-last-left.svg";
import { ReactComponent as NavigationLeftIcon } from "../../assets/icons/navigation-left.svg";
import { ReactComponent as NavigationRightLastIcon } from "../../assets/icons/navigation-right-last.svg";
import { ReactComponent as NavigationRightIcon } from "../../assets/icons/navigation-right.svg";
import { ReactComponent as ArrowUpGreen } from "../../assets/icons/Pfeil_hoch_22px_gruen.svg";
import { ReactComponent as ArrowUpWhite } from "../../assets/icons/Pfeil_hoch_22px_weiss.svg";
import { ReactComponent as ArrowLeftGreen } from "../../assets/icons/Pfeil_links_22px_gruen.svg";
import { ReactComponent as ArrowLeftWhite } from "../../assets/icons/Pfeil_links_22px_weiss.svg";
import { ReactComponent as ArrowRightGreen } from "../../assets/icons/Pfeil_rechts_22px_gruen.svg";
import { ReactComponent as ArrowRightWhite } from "../../assets/icons/Pfeil_rechts_22px_weiss.svg";
import { ReactComponent as ResponseFieldBillingRelevant } from "../../assets/icons/response_field_billing_relevant_icon.svg";
import { ReactComponent as ResponseAssignmentRoleCustomer } from "../../assets/icons/response_assignment_role_customer.svg";
import { ReactComponent as ResponseAssignmentRoleLogistic } from "../../assets/icons/response_assignment_role_logistic.svg";
import { ReactComponent as ResponseAssignmentRoleRecycler } from "../../assets/icons/response_assignment_role_recycler.svg";
import { ReactComponent as ResponseFieldCalendar } from "../../assets/icons/response_field_date_icon.svg";
import { ReactComponent as ResponseFieldFile } from "../../assets/icons/response_field_file_icon.svg";
import { ReactComponent as ResponseFieldFloatingPoint } from "../../assets/icons/response_field_floating_point_icon.svg";
import { ReactComponent as ResponseFieldNumber } from "../../assets/icons/response_field_number_icon.svg";
import { ReactComponent as ResponseFieldTextArea } from "../../assets/icons/response_field_text_area_icon.svg";
import { ReactComponent as ResponseFieldText } from "../../assets/icons/response_field_text_icon.svg";
import { ReactComponent as ResponseFieldWarehouseArticle } from "../../assets/icons/response_field_warehouse_article_icon.svg";
import { ReactComponent as CloseGreen } from "../../assets/icons/Schliessen_15px_gruen.svg";
import { ReactComponent as CloseWhite } from "../../assets/icons/Schliessen_15px_weiss.svg";
import { ReactComponent as ScrollToTop } from "../../assets/icons/Scroll-to-top_H30px_weiss.svg";
import { ReactComponent as ShowHistoryGreen } from "../../assets/icons/show_history_20px_gruen.svg";
import { ReactComponent as ShowHistoryWhite } from "../../assets/icons/show_history_20px_weiss.svg";
import { ReactComponent as SafetyContainerXL } from "../../assets/icons/Sicherheitsbehaelter_L_XL_H40px.svg";
import { ReactComponent as SaveGreen } from "../../assets/icons/Speichern_22px_gruen.svg";
import { ReactComponent as SaveWhite } from "../../assets/icons/Speichern_22px_weiss.svg";
import { ReactComponent as BaseDataGreen } from "../../assets/icons/Stammdaten_25px_gruen.svg";
import { ReactComponent as BaseDataWhite } from "../../assets/icons/Stammdaten_25px_weiss.svg";
import { ReactComponent as StatusCollected } from "../../assets/icons/Status_Abgeholt_H25px_grau.svg";
import { ReactComponent as StatusCompleted } from "../../assets/icons/Status_Abgeschlossen_H25px_grau.svg";
import { ReactComponent as StatusAccepted } from "../../assets/icons/Status_Auftrag_angenommen_H25px_grau.svg";
import { ReactComponent as StatusWeigthed } from "../../assets/icons/Status_Beim_Recycler_H25px_grau.svg";
import { ReactComponent as StatusOrdered } from "../../assets/icons/Status_Bestellt_H25px_grau.svg";
import { ReactComponent as SearchGreen } from "../../assets/icons/Suchen_25px_gruen.svg";
import { ReactComponent as SearchBlack } from "../../assets/icons/Suchen_25px_schwarz.svg";
import { ReactComponent as SearchWhite } from "../../assets/icons/Suchen_25px_weiss.svg";
import { ReactComponent as TruckloadSortingDesc } from "../../assets/icons/truckload_sorting_desc.svg";
import { ReactComponent as TruckloadSortingAsc } from "../../assets/icons/truckload_sorting_asc.svg";
import { ReactComponent as SupportGreen } from "../../assets/icons/Support_Hilfe_25px_gruen.svg";
import { ReactComponent as SupportWhite } from "../../assets/icons/Support_Hilfe_25px_weiss.svg";
import { ReactComponent as FreshdeskTicketsGreen } from "../../assets/icons/Freshdesk_Tickets_25px_gruen.svg";
import { ReactComponent as TransportBarrel } from "../../assets/icons/Transportfass_H40px.svg";
import { ReactComponent as TransportBarrelSlim } from "../../assets/icons/transport_barrel.svg";
import { ReactComponent as UserTableIcon } from "../../assets/icons/user-table-icon.svg";
import { ReactComponent as ShoppingCardBlackSvg } from "../../assets/icons/Warenkorb_25px_black.svg";
import { ReactComponent as ShoppingCardGreenSvg } from "../../assets/icons/Warenkorb_25px_gruen.svg";
import { ReactComponent as ShoppingCardWhiteSvg } from "../../assets/icons/Warenkorb_25px_weiss.svg";
import { ReactComponent as Expand } from "../../assets/icons/expand_icon.svg";
import { ReactComponent as Addon } from "../../assets/icons/Zusatzleistungen_B40px.svg";
import { ReactComponent as GRSNameLogo } from "../../assets/images/logos/grs_logo.svg";
import { ReactComponent as GRSNameLogoDev } from "../../assets/images/logos/grs_logo_testsystem.svg";
import { ReactComponent as ConfigPlatform } from "../../assets/images/logos/ICN_Konfigurator_H60px.svg";
import { ReactComponent as CfmPlatform } from "../../assets/images/logos/Verteiler_Abholung.svg";
import { ReactComponent as PomPlatform } from "../../assets/images/logos/Verteiler_Meldung.svg";
import { ReactComponent as ResponseConfig } from "../../assets/icons/ICN_Rueckmeldekonfig_H25px_gruen.svg";
import { ReactComponent as Group } from "../../assets/icons/group_icon.svg";
import { Colors } from "../../style/Colors";

export type IconColor = "green" | "darkGreen" | "white" | "black" | "disabled" | "hover" | "red";

export interface IIconsBaseProps extends SvgIconProps {
  svgColor?: IconColor;
}

export const TransportBarrelSlimIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 26 40" component={TransportBarrelSlim} />;
};

export const GroupIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 35 40" component={Group} />;
};

export const ExpandIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 20 20" component={Expand} />;
};

export const TruckloadSortingAscIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16.449 20" component={TruckloadSortingAsc} />;
};

export const TruckloadSortingDescIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16.449 20" component={TruckloadSortingDesc} />;
};

export const ResponseAssignmentRoleCustomerIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 13.5 16" component={ResponseAssignmentRoleCustomer} />;
};

export const ResponseAssignmentRoleLogisticIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 23 16" component={ResponseAssignmentRoleLogistic} />;
};

export const ResponseAssignmentRoleRecyclerIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 15.628 16" component={ResponseAssignmentRoleRecycler} />;
};

export const ResponseFieldBillingRelevantIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldBillingRelevant} />;
};

export const ResponseFieldWarehouseArticleIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldWarehouseArticle} />;
};

export const ResponseFieldFileIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldFile} />;
};

export const ResponseFieldCalendarIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldCalendar} />;
};

export const ResponseFieldTextAreaIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldTextArea} />;
};

export const ResponseFieldTextIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 19 16" component={ResponseFieldText} />;
};

export const ResponseFieldFloatingPointIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldFloatingPoint} />;
};

export const ResponseFieldNumberIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 16 16" component={ResponseFieldNumber} />;
};

export const LogoutSidebarIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={Logout} />;
};

export const ConfigIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 25 25" component={ConfigGreen} />;
};

export const PackageConfigIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={PackageConfig} />;
};

export const PackageConfigIconSmall: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={PackageConfigSmall} />;
};

export const CreatePackageConfigIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={CreatePackageConfig} />;
};

export const ConfigMissingIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={ConfigMissing} />;
};

export const ConfigDoneIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={ConfigDone} />;
};

export const ProductConfigIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={ProductConfig} />;
};

export const RoutingConfigSmallIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 25 25" component={RoutingConfigSmall} />;
};

export const RoutingConfigIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 41 40" component={RoutingConfig} />;
};

export const ResponseConfigSmallIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 25 25" component={ResponseConfig} />;
};

export const UserTableOutlined: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={UserTableIcon} />;
};

export const LoginAsIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={LoginAsUserIcon} />;
};

export const NavigationRightLast: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={NavigationRightLastIcon} />;
};

export const NavigationRight: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={NavigationRightIcon} />;
};

export const NavigationLeft: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={NavigationLeftIcon} />;
};

export const NavigationLeftLast: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={NavigationLeftLastIcon} />;
};

export const GroupAddressMarker: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={GroupAddressMarkerIcon} />;
};

export const InvoiceAddressMarker: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={InvoiceAddressMarkerIcon} />;
};

export const ArrowRightBig: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={ArrowRightBigSvg} viewBox="0 0 40 30" />;
};

export const StatusOrderedIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={StatusOrdered} />;
};

export const StatusAcceptedIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={StatusAccepted} />;
};

export const StatusCollectedIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={StatusCollected} />;
};

export const StatusWeightedIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={StatusWeigthed} />;
};

export const StatusCompletedIcon: React.FC<IIconsBaseProps> = ({ svgColor = "disabled", ...props }) => {
  return <SvgIcon {...props} component={StatusCompleted} />;
};

export const ScrollToTopIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 30 30" classes={classes} component={ScrollToTop} />;
};

export const BigBagIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={BigBag} viewBox="0 0 40 48" />;
};

export const EuroPalletIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={EuroPallet} viewBox="0 0 40 40" />;
};

export const TransportBarrelIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={TransportBarrel} viewBox="0 0 40 40" />;
};

export const CardboardIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={Cardboard} viewBox="0 0 40 43" />;
};

export const SafetyContainerXLIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={SafetyContainerXL} viewBox="0 0 40 34" />;
};

export const LoadCarrierXLIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={LoadCarrierXL} viewBox="0 0 40 40" />;
};

export const CustomerContainerIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={CustomerContainer} viewBox="0 0 40 39" />;
};

export const Paloxe600Icon: React.FC<IIconsBaseProps> = ({ svgColor = "white", classes, ...props }) => {
  return <SvgIcon {...props} classes={classes} component={Paloxe600} viewBox="0 0 40 43" />;
};

export const SupportIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon viewBox="0 0 25 25" {...props} component={SupportGreen} />;
    case "white":
    default:
      return <SvgIcon viewBox="0 0 25 25" {...props} component={SupportWhite} />;
  }
};

export const FreshdeskTicketsIcon: React.FC<IIconsBaseProps> = ({ ...props }) => {
  return <SvgIcon viewBox="0 0 32 32" {...props} component={FreshdeskTicketsGreen} />;
};

export const OrdersIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "white":
      return <SvgIcon viewBox="0 0 25 25" {...props} component={OrdersWhite} />;
    case "green":
      return <SvgIcon viewBox="0 0 25 25" {...props} component={OrdersGreen} />;
    default:
      return <SvgIcon viewBox="0 0 25 25" {...props} component={OrdersWhite} />;
  }
};

export const RepeatOrderIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "white":
      return <SvgIcon {...props} viewBox="0 0 25 25" component={RepeatOrderWhite} />;
    case "green":
      return <SvgIcon {...props} viewBox="0 0 25 25" component={RepeatOrderGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 25 25" component={RepeatOrderWhite} />;
  }
};

export const UserIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "white":
      return <SvgIcon {...props} component={UserWhite} />;
    case "green":
      return <SvgIcon {...props} component={UserGreen} />;
    default:
      return <SvgIcon {...props} component={UserGreen} />;
  }
};

export const ShoppingCardIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", ...props }) => {
  switch (svgColor) {
    case "white":
      return <SvgIcon {...props} component={ShoppingCardWhiteSvg} />;
    case "green":
      return <SvgIcon {...props} component={ShoppingCardGreenSvg} />;
    default:
      return <SvgIcon {...props} component={ShoppingCardBlackSvg} />;
  }
};

export const AddressMarkerIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", classes, ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 29 40" classes={classes} component={AddressMarker} />;
};

export const ProductIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", classes, ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 54 40" classes={classes} component={Product} />;
};

export const ProductMobileIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", classes, ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 39 30" classes={classes} component={ProductMobile} />;
};

export const SingleOrderIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", classes, ...props }) => {
  return <SvgIcon {...props} viewBox="0 0 40 32" classes={classes} component={Addon} />;
};

export const SearchIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", ...props }) => {
  switch (svgColor) {
    case "white":
      return <SvgIcon {...props} component={SearchWhite} />;
    case "green":
      return <SvgIcon {...props} component={SearchGreen} />;
    default:
      return <SvgIcon {...props} component={SearchBlack} />;
  }
};

export const BaseDataIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon viewBox="0 0 25 25" {...props} component={BaseDataGreen} />;
    default:
      return <SvgIcon viewBox="0 0 25 25" {...props} component={BaseDataWhite} />;
  }
};

export const SaveIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} component={SaveGreen} />;
    default:
      return <SvgIcon {...props} component={SaveWhite} />;
  }
};

export const CloseIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 15 14.999" component={CloseGreen} />;
    case "disabled":
      return <SvgIcon {...props} viewBox="0 0 15 14.999" component={CloseWhite} htmlColor={Colors.grayDark} />;
    case "hover":
      return <SvgIcon {...props} viewBox="0 0 15 14.999" component={CloseWhite} htmlColor={Colors.white} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 15 14.999" component={CloseWhite} />;
  }
};

export const ArrowRightIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 22 15.846" component={ArrowRightGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 22 15.846" component={ArrowRightWhite} />;
  }
};

export const ArrowLeftIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 22 15.846" component={ArrowLeftGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 22 15.846" component={ArrowLeftWhite} />;
  }
};

export const ArrowUpIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} component={ArrowUpGreen} />;
    default:
      return <SvgIcon {...props} component={ArrowUpWhite} />;
  }
};

export const CancelIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
    case "red":
      return <SvgIcon {...props} viewBox="0 0 22 22" component={CancelGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 22 22" component={CancelWhite} />;
  }
};

export const OrderNewIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon viewBox="0 0 25 25" {...props} component={OrderNewGreen} />;
    default:
      return <SvgIcon viewBox="0 0 25 25" {...props} component={OrderNewWhite} />;
  }
};

export const EditIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon viewBox="0 0 20 20" {...props} component={EditGreen} />;
    default:
      return <SvgIcon viewBox="0 0 20 20" {...props} component={EditWhite} />;
  }
};

export const FilterIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 20.001 20.003" component={FilterGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 20.001 20.003" component={FilterWhite} />;
  }
};

export const CheckboxApproveIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 22 19.229" component={CheckboxApproveGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 22 19.229" component={CheckboxApproveWhite} />;
  }
};

export const AddIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  return <AddWhite fill={svgColor} viewBox="0 0 14.42 15" {...props} />;
};

export const CalendarIcon: React.FC<IIconsBaseProps> = ({ svgColor = "black", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 25 25" component={CalendarGreen} />;
    case "black":
      return <SvgIcon {...props} viewBox="0 0 25 25" component={CalendarBlack} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 25 25" component={CalendarWhite} />;
  }
};

export const DeleteIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon viewBox="0 0 20 20" {...props} component={Delete} />;
    case "red":
      return <SvgIcon viewBox="0 0 20 20" {...props} component={Delete} color="error" />;
    case "disabled":
      return <SvgIcon viewBox="0 0 20 20" {...props} component={Delete} color="disabled" />;
    default:
      return <SvgIcon viewBox="0 0 20 20" {...props} component={DeleteWhite} />;
  }
};

export const MenuDrawerIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 30 30" component={MenuOpenGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 30 30" component={MenuOpenWhite} />;
  }
};

export const MenuBurgerIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 30 30" component={MenuBurgerGreen} />;
    default:
      return <SvgIcon {...props} viewBox="0 0 30 30" component={MenuBurgerWhite} />;
  }
};

export const AddEntryIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return <SvgIcon {...props} viewBox="0 0 25 25" component={AddEntryGreen} />;
    case "white":
    default:
      return <SvgIcon {...props} viewBox="0 0 25 25" component={AddEntryWhite} />;
  }
};

export const ShowHistoryIcon: React.FC<IIconsBaseProps> = ({ svgColor = "white", ...props }) => {
  switch (svgColor) {
    case "green":
      return (
        <SvgIcon
          {...props}
          viewBox="0 0 24 21.001"
          style={{ flex: 1, paddingRight: "2px" }}
          component={ShowHistoryGreen}
        />
      );
    default:
      return (
        <SvgIcon
          {...props}
          viewBox="0 0 24 21.001"
          style={{ flex: 1, paddingRight: "2px" }}
          component={ShowHistoryWhite}
        />
      );
  }
};

export const CsvExportIcon: React.FC<IIconsBaseProps> = (props) => {
  return <SvgIcon {...props} viewBox="0 0 22 22" component={CsvExportWhite} />;
};

export const GRSLogo: React.FC<IIconsBaseProps> = (props) => <GRSNameLogo {...props} />;
export const GRSLogoDev: React.FC<IIconsBaseProps> = (props) => <GRSNameLogoDev {...props} />;

export const CfmPlatformLogo: React.FC<IIconsBaseProps> = (props) => <CfmPlatform {...props} />;
export const PomPlatformLogo: React.FC<IIconsBaseProps> = (props) => <PomPlatform {...props} />;
export const ConfigPlatformLogo: React.FC<IIconsBaseProps> = (props) => <ConfigPlatform {...props} />;
