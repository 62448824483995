import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { IdBox } from "../../../../../configurator/components/product-response/id-box.component";

const useStyles = makeStyles((theme: Theme) => ({
  responseLabel: {
    fontWeight: "bold",
  },
  fitContent: {
    width: "fit-content",
  },
}));

interface IResponseValueForRow {
  name: string;
  value: string | number | JSX.Element | undefined;
  internalNumber: number | null;
  unit: string | null;
}

interface IResponseValueRowProps {
  value: IResponseValueForRow;
}

export const ResponseValueRow: React.FC<IResponseValueRowProps> = (props) => {
  const { value } = props;
  const classes = useStyles();

  const getResponseValueName = (name: string, unit: string | null) => {
    return unit ? `${name} [${unit}]:` : `${name}:`;
  };

  const internalNumberSpace = value.internalNumber ? 34 : 0;

  return (
    <Grid container direction="row">
      {value.internalNumber && (
        <Grid item style={{ width: internalNumberSpace }}>
          <Box className={classes.fitContent}>
            <IdBox id={value.internalNumber} />
          </Box>
        </Grid>
      )}
      <Grid item style={{ width: `calc(100% - ${internalNumberSpace}px)` }}>
        <Typography className={classes.responseLabel}>
          {getResponseValueName(value.name, value.unit)} {value.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
