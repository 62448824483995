import { DeepPartial, DefaultValues } from "react-hook-form";
import config from "../../../../config/config";
import { Environment } from "../../../../config/environment.util";
import { getDefaultCountry } from "../../../../utils/Countries";
import { IAddressLean } from "../../../domain/address/address-lean";
import { GroupType } from "../../../domain/group/group-type";
import { AddressType } from "../../../models/address/address.model";
import { ICreateGroupFormInputs } from "../create-group/create-group-form.component";

export const prefilledDevelopmentAddressValue: DeepPartial<IAddressLean> = {
  country: getDefaultCountry().code,
  type: AddressType.GroupLocation,
  name: "Development",
  lat: -1,
  long: -1,
  city: "Dev City",
  postal: "0000",
  name2: "Code Review",
  buildingNumber: "47",
  address: "Devstraße",
  contactPersonEmail: "dev-address@wastebox.biz",
  contactPersonName: "Developer",
  contactPersonTelephone: "+43 12341234",
  state: "Developia",
  addressText: "Dev Addresstext",
};

export const prefillDevelopmentValues = (defaultValues: DefaultValues<ICreateGroupFormInputs>) => {
  if (config.environment === Environment.DevelopmentLocal && config.prefillForms === "YES") {
    defaultValues.name = "DEV GROUP";
    defaultValues.type = GroupType.Customer;
    defaultValues.vatId = "DEV VAT ID";
    defaultValues.groupAddress = prefilledDevelopmentAddressValue;
  }
};
