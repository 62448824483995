import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetGroupQuery } from "../../../../../shared/repositories/queries/group/get-group.query";
import { translateCountryByCode } from "../../../../../utils/address.util";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderDetailInfoBlock } from "../card/order-details-info-block.component";
import { Loading } from "../../../../../shared/components/loading/loading.component";

export const OrderXDetailsInvoiceAddress: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const invoiceAddress = useMemo(() => {
    return order.customerAddress;
  }, [order.customerAddress]);

  const { data, isLoading } = useGetGroupQuery(order.customerAddress?.groupId);

  const addressText = useMemo(() => {
    const textData: string[] = [`(${invoiceAddress?.id ?? ""}) ${invoiceAddress?.name ?? ""}`];

    if (invoiceAddress?.address && invoiceAddress.buildingNumber) {
      textData.push(`${invoiceAddress.address} ${invoiceAddress.buildingNumber}`);
    }

    if (invoiceAddress?.postal && invoiceAddress.city) {
      textData.push(`${invoiceAddress.postal} ${invoiceAddress.city}`);
    }

    textData.push(invoiceAddress?.country ? translateCountryByCode(invoiceAddress.country, t) : "");
    return textData.filter((text) => text.length > 0).join(", ");
  }, [
    invoiceAddress?.id,
    invoiceAddress?.name,
    invoiceAddress?.address,
    invoiceAddress?.postal,
    invoiceAddress?.city,
    invoiceAddress?.buildingNumber,
    invoiceAddress?.country,
    t,
  ]);

  return (
    <OrderDetailInfoBlock title={t("order.details.invoiceAddress")}>
      <Loading isLoading={isLoading}>
        <Grid container direction="column">
          {invoiceAddress && (
            <Grid item>
              <Typography variant="body1">{addressText}</Typography>
            </Grid>
          )}
          {data?.erpNumber && (
            <Grid item>
              <Typography variant="body1">{t("order.details.erp", { erpNumber: data.erpNumber })}</Typography>
            </Grid>
          )}
        </Grid>
      </Loading>
    </OrderDetailInfoBlock>
  );
};
