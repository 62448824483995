import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ManualUnsavedDataPrompt } from "../../../components/Routes/manual-unsaved-data-prompt";
import { useUnsavedDataContext } from "../../../providers/App/unsaved-data-context";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { isCfmLogistics, isCfmRecycler } from "../../../shared/domain/user/user";
import { formatDateYearMonthDay } from "../../../shared/util/date.util";
import { Colors, Shades } from "../../../style/Colors";
import { AvailableCfmRoutes } from "../../../utils/constants";
import { ICfmOrderX } from "../../domain/order-x/cfm-order-x";
import { ICfmCreateTruckload } from "../../domain/truckload/cfm-create-truckload";
import { ICfmTruckloadWithOrders } from "../../domain/truckload/cfm-truckload-with-orders";
import { TruckloadState } from "../../domain/truckload/truckload-state";
import { useTruckloadAnnouncedRowStyle } from "../../pages/order-x/components/order-list-view/logistic-truckload-announced/truckload-announced.style";
import { OrderOfTruckloadSorting } from "../../pages/order-x/order-truckload.provider";
import { getTranslatedStatus, getTruckloadNumber, getWeight } from "../../pages/order-x/utils/truckload.utils";
import { CfmTruckloadConverter } from "../../repositories/models/converter/truckload/cfm-truckload.converter";
import { CfmTruckloadActionTypeModel } from "../../repositories/models/truckload/action/cfm-truckload-action.model";
import { IUpdateTruckloadInfoAndOrdersModel } from "../../repositories/models/truckload/cfm-update-truckload.model";
import { useCancelTruckloadQuery } from "../../repositories/queries/truckload/mutations/cancel-truckload.query";
import { useUpdateTruckloadInfoAndOrdersQuery } from "../../repositories/queries/truckload/mutations/update-truckload-info-and-orders.query";
import { EditTruckloadDialogs } from "./dialog/edit-truckload-dialogs.component";
import { ITruckloadPerformedInputs } from "./dialog/truckload-performed-dialog.component";
import { ExportTruckloadButton } from "./export-truckload-button.component";
import { EditTruckloadFooter } from "./footer/edit-truckload-footer.component";
import { TruckloadInfoBox } from "./info-box/truckload-info-box.component";
import { OrdersOfTruckloadFilter } from "./orders-of-truckload-filter.component";
import { OrdersOfTruckloadList } from "./orders-of-truckload-list.component";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 30,
  },
  content: {
    marginBottom: 120,
  },
  boxBorder: {
    border: `2px solid ${Shades.gray20}`,
  },
  idBorderBox: {
    border: `2px solid ${Colors.grayLight}`,
    backgroundColor: Colors.grayLight,
  },
  container: {
    height: "100%",
  },
}));

export interface ITruckloadInfo {
  targetAddressId: number | undefined;
  plannedDeliveryDate: Date | undefined;
}

export interface IOrdersOfTruckloadFilter {
  sorting: OrderOfTruckloadSorting;
  query: string | undefined;
}

interface IEditTruckloadContentProps {
  truckload: ICfmTruckloadWithOrders;
}

export const EditTruckloadContent: VFC<IEditTruckloadContentProps> = (props) => {
  const { truckload } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { setHasUnsavedData, hasUnsavedData: unsavedDataOfContext } = useUnsavedDataContext();
  const { internalUser } = useAuthContext();
  const truckloadClasses = useTruckloadAnnouncedRowStyle();
  const [truckloadFilter, setTruckloadFilter] = useState<IOrdersOfTruckloadFilter>({
    query: undefined,
    sorting: OrderOfTruckloadSorting.WarehouseEntryDate,
  });
  const [truckloadOrders, setTruckloadOrders] = useState<ICfmOrderX[]>(truckload.orders);
  const [truckloadInfo, setTruckloadInfo] = useState<ITruckloadInfo>({
    targetAddressId: truckload.targetAddress?.id,
    plannedDeliveryDate: truckload.plannedDeliveryDate,
  });
  const [isAddOrderDialogOpen, setIsAddOrderDialogOpen] = useState<boolean>(false);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState<boolean>(false);
  const [isEditTruckloadInfoDialogOpen, setIsEditTruckloadInfoDialogOpen] = useState<boolean>(false);
  const [isTruckloadPerformedDialogOpen, setIsTruckloadPerformedDialogOpen] = useState<boolean>(false);
  const [isSubmitTruckloadOpen, setIsSubmitTruckloadOpen] = useState<boolean>(false);
  const [isManualUnsavedDataDialogOpen, setIsManualUnsavedDataDialogOpen] = useState<boolean>(false);

  const { isLoading: isCancelTruckloadLoading, mutateAsync: cancelTruckload } = useCancelTruckloadQuery();
  const { isLoading: isUpdateTruckloadLoading, mutateAsync: updateTruckloadInfoAndOrders } =
    useUpdateTruckloadInfoAndOrdersQuery();

  const hasUnsavedData = useMemo(() => {
    const hasDifferentTruckloadInfo =
      truckload.targetAddress?.id !== truckloadInfo.targetAddressId ||
      truckload.plannedDeliveryDate !== truckloadInfo.plannedDeliveryDate;
    const originalOrderIds = truckload.orders.map((o) => o.id);
    const currentOrderIds = truckloadOrders.map((o) => o.id);

    const hasDifferentOrders = _.difference(originalOrderIds, currentOrderIds).length > 0;
    const hasDifferentInfo =
      truckload.targetAddress?.id !== truckloadInfo.targetAddressId ||
      (truckload.plannedDeliveryDate &&
        !dayjs(truckload.plannedDeliveryDate).isSame(dayjs(truckloadInfo.plannedDeliveryDate)));
    return hasDifferentInfo || hasDifferentOrders || hasDifferentTruckloadInfo;
  }, [truckload, truckloadOrders, truckloadInfo]);

  useEffect(() => {
    if (!hasUnsavedData) return;
    setHasUnsavedData(true);
  }, [hasUnsavedData, setHasUnsavedData]);

  const onCancelClicked = () => {
    history.push(AvailableCfmRoutes.OrderXOverview);
  };

  const onEditTruckload = () => {
    setIsEditTruckloadInfoDialogOpen(true);
  };

  const onRemove = (orderId: number) => {
    setTruckloadOrders(truckloadOrders.filter((order) => order.id !== orderId));
  };

  const onAddOrdersToTruckload = (orders: ICfmOrderX[]) => {
    const orderIds = orders.map((order) => order.id);
    setTruckloadOrders([...truckloadOrders.filter((existingOrder) => !orderIds.includes(existingOrder.id)), ...orders]);
    setIsAddOrderDialogOpen(false);
  };

  const onCancelTruckload = async () => {
    await cancelTruckload(truckload.id);
    history.push(AvailableCfmRoutes.OrderXOverview);
  };

  const onUpdateTruckloadInfoAndOrders = async () => {
    setHasUnsavedData(false);
    const plannedDeliveryDate = truckloadInfo.plannedDeliveryDate ?? truckload.plannedDeliveryDate;
    const requestData: IUpdateTruckloadInfoAndOrdersModel = {
      orderIds: truckloadOrders.map((order) => order.id),
      targetAddressId: truckloadInfo.targetAddressId,
      plannedDeliveryDate: plannedDeliveryDate ? formatDateYearMonthDay(plannedDeliveryDate) : undefined,
      type: CfmTruckloadActionTypeModel.TruckloadUpdate,
      truckloadId: truckload.id,
    };
    await updateTruckloadInfoAndOrders(requestData);
    history.push(AvailableCfmRoutes.OrderXOverview);
  };

  const onUpdateTruckloadInfo = async (data: ICfmCreateTruckload) => {
    if (!data.targetAddressId || !data.plannedDeliveryDate) return;
    setTruckloadInfo({ targetAddressId: data.targetAddressId, plannedDeliveryDate: data.plannedDeliveryDate });
    setIsEditTruckloadInfoDialogOpen(false);
  };

  const onRespondTruckload = async (data: ITruckloadPerformedInputs) => {
    const requestData = CfmTruckloadConverter.toConfirmedUpdatedRequest(data, truckload.id);
    await updateTruckloadInfoAndOrders(requestData);
    setIsTruckloadPerformedDialogOpen(false);
    history.push(AvailableCfmRoutes.OrderXOverview);
  };

  const onSubmitTruckload = async (data: ICfmCreateTruckload) => {
    const plannedDeliveryDate = data.plannedDeliveryDate ?? truckload.plannedDeliveryDate;
    // Logistic changes truckload from draft to submitted
    setHasUnsavedData(false);
    const requestData: IUpdateTruckloadInfoAndOrdersModel = {
      truckloadId: truckload.id,
      orderIds: truckloadOrders.map((order) => order.id),
      targetAddressId: data.targetAddressId ?? truckload.targetAddress?.id,
      plannedDeliveryDate: plannedDeliveryDate ? formatDateYearMonthDay(plannedDeliveryDate) : undefined,
      status: TruckloadState.Submitted,
      serviceDatesForOrders: undefined,
      type: CfmTruckloadActionTypeModel.TruckloadSubmitted,
    };
    await updateTruckloadInfoAndOrders(requestData);
    setIsSubmitTruckloadOpen(false);
    history.push(AvailableCfmRoutes.OrderXOverview);
  };

  const openRespondDialog = () => {
    isCfmRecycler(internalUser) ? setIsTruckloadPerformedDialogOpen(true) : setIsSubmitTruckloadOpen(true);
  };

  return (
    <>
      <ManualUnsavedDataPrompt
        open={isManualUnsavedDataDialogOpen}
        onOK={() => {
          setHasUnsavedData(false);
          setIsManualUnsavedDataDialogOpen(false);
          openRespondDialog();
          return true;
        }}
        onCancel={() => {
          setIsManualUnsavedDataDialogOpen(false);
          return false;
        }}
        acceptText={t("general.next.text")}
        text={t("general.unsavedDataLocalPage")}
      />
      <EditTruckloadDialogs
        isLoading={isUpdateTruckloadLoading || isCancelTruckloadLoading}
        truckload={truckload}
        truckloadOrders={truckloadOrders}
        isTruckloadPerformedDialogOpen={isTruckloadPerformedDialogOpen}
        isEditTruckloadInfoDialogOpen={isEditTruckloadInfoDialogOpen}
        isAddOrderDialogOpen={isAddOrderDialogOpen}
        isConfirmCancelOpen={isConfirmCancelOpen}
        isSubmitTruckloadOpen={isSubmitTruckloadOpen}
        setIsAddOrderDialogOpen={setIsAddOrderDialogOpen}
        setIsConfirmCancelOpen={setIsConfirmCancelOpen}
        setIsEditTruckloadInfoDialogOpen={setIsEditTruckloadInfoDialogOpen}
        setIsTruckloadPerformedDialogOpen={setIsTruckloadPerformedDialogOpen}
        setIsSubmitTruckloadOpen={setIsSubmitTruckloadOpen}
        onRespondTruckload={onRespondTruckload}
        onUpdateTruckloadInfo={onUpdateTruckloadInfo}
        onAddOrdersToTruckload={onAddOrdersToTruckload}
        onCancelTruckload={onCancelTruckload}
        onSubmitTruckload={onSubmitTruckload}
      />
      <Grid container direction="column" spacing={4} className={classes.content}>
        <Grid item xs={12} className={classes.heading}>
          <Grid container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm="auto">
                  <Typography variant="h4">{t("orders.truckloadAnnounced.edit")}</Typography>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems={"center"} className={classes.container}>
                    <Box className={classNames(truckloadClasses.box, classes.idBorderBox)}>
                      <Typography variant="body1" className={truckloadClasses.boxTextColor}>
                        {getTruckloadNumber(truckload.id)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems={"center"} className={classes.container}>
                    <Box className={classNames(truckloadClasses.box, truckloadClasses.statusBox, classes.boxBorder)}>
                      <Typography variant="body1" className={truckloadClasses.boxTextColor}>
                        {getTranslatedStatus(truckload, t)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ExportTruckloadButton orderIds={truckloadOrders.map((order) => order.id)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TruckloadInfoBox
            targetAddressId={truckloadInfo.targetAddressId}
            plannedDeliveryDate={truckloadInfo.plannedDeliveryDate}
            truckload={truckload}
            onEditClick={truckload.status !== TruckloadState.Confirmed ? onEditTruckload : undefined}
          />
        </Grid>
        <Grid item>
          <OrdersOfTruckloadFilter
            showAddButton={truckload.status !== TruckloadState.Confirmed && isCfmLogistics(internalUser)}
            filter={truckloadFilter}
            setFilter={setTruckloadFilter}
            onAddOrder={() => setIsAddOrderDialogOpen(true)}
            orderCount={truckloadOrders.length}
          />
        </Grid>
        <Grid item>
          <OrdersOfTruckloadList
            truckloadOrders={truckloadOrders}
            filter={truckloadFilter}
            onRemoveClick={onRemove}
            isRemoveEnabled={truckload.status !== TruckloadState.Confirmed && isCfmLogistics(internalUser)}
          />
        </Grid>
      </Grid>
      <EditTruckloadFooter
        continueActionsDisabled={truckloadOrders.length === 0 || isCancelTruckloadLoading || isUpdateTruckloadLoading}
        weight={getWeight(truckloadOrders)}
        truckloadState={truckload.status}
        onCancel={onCancelClicked}
        onCancelTruckload={() => setIsConfirmCancelOpen(true)}
        onSave={onUpdateTruckloadInfoAndOrders}
        onRespondTruckload={() => {
          if (unsavedDataOfContext) {
            setIsManualUnsavedDataDialogOpen(true);
            return;
          }
          openRespondDialog();
        }}
      />
    </>
  );
};
