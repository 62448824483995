import { Card, CardHeader, Grid } from "@material-ui/core";
import classNames from "classnames";
import React, { ReactNode, useMemo, useState } from "react";
import { Shades } from "style/Colors";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { PulseAnimation } from "../../../../../shared/components/pulse-animation/pulse-animation";
import { isAdmin } from "../../../../../shared/domain/user/user";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXStatusConverter } from "../../../../repositories/models/converter/order-x/cfm-order-x-status.converter";
import { useUpdateOrderXReadStatusQuery } from "../../../../repositories/queries/order-x/mutations/update-order-x-read-status.query";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { OrderXSelectedProvider } from "../../order-x-selected.provider";
import { OrderXOverviewCardContent } from "../card-content/order-x-overview-card-content.component";
import { OrderXCardResponse } from "../card-response/order-x-card-response.component";
import { OrderXCardStatusIndicator } from "../card-status-indicator/order-x-card-status-indicator.component";
import { OrderXCardDetails } from "../order-details/order-x-card-details.component";
import { OrderXCardActions } from "./order-x-card-actions.component";
import { OrderXCardHistory } from "./order-x-card-history.component";
import { OrderXCardTickets } from "./order-x-card-tickets.component";
import { OrderXCardTitle } from "./order-x-card-title.component";
import { useOrderXCardStyle } from "./order-x-card.styles";

interface IOrderXCardProps {
  order: ICfmOrderX;
}

export const OrderXCard: React.FC<IOrderXCardProps> = (props) => {
  const { order } = props;
  const { internalUser } = useAuthContext();
  const classes = useOrderXCardStyle();
  const { selectedOrderId, setSelectedOrderId } = useOrderXOverviewContext();
  const { isMobile } = useScreenSize();
  const [isOrderNew, setIsOrderNew] = useState<boolean>(order.isNew);

  const isSelected = useMemo(() => {
    return selectedOrderId !== undefined && order.id === selectedOrderId;
  }, [selectedOrderId, order]);

  const { mutateAsync: updateOrderRead } = useUpdateOrderXReadStatusQuery(
    order.id,
    CfmOrderXStatusConverter.toModel(order.status),
  );

  const getTitleEndAdornment = (): ReactNode => {
    if (isOrderNew) {
      return <PulseAnimation />;
    }
    return <></>;
  };

  return (
    <Card
      onClick={() => {
        if (!isAdmin(internalUser) && order.isNew) {
          void updateOrderRead();
          setIsOrderNew(false);
        }
        setSelectedOrderId(order.id);
      }}
      elevation={4}
      className={classes.root}
      style={{
        backgroundColor: Shades.gray10,
      }}
    >
      <CardHeader
        title={<OrderXCardTitle order={order} hasStatus={true} endAdornment={getTitleEndAdornment()} />}
        className={classNames(classes.defaultCardHeaderPadding, {
          [classes.cardHeaderActive]: isSelected,
          [classes.cardHeaderActiveMobile]: isSelected && isMobile,
          [classes.cardHeader]: !isSelected,
        })}
      />
      {order && (
        <OrderXOverviewCardContent order={order} isLoading={false} isSelected={isSelected}>
          {order && (
            <Grid container className={classes.cardOrderInformationStatus} hidden={!isSelected}>
              <Grid item className={classes.cardOrderInformationPlaceholder} />
              <Grid item xs={12} sm={10} md={11}>
                <OrderXCardStatusIndicator order={order} report={undefined} />
              </Grid>
            </Grid>
          )}
          {order && isSelected && (
            <OrderXSelectedProvider order={order}>
              <Grid container className={classes.cardOrderInformationDetails} hidden={!isSelected} spacing={2}>
                <Grid item xs={12}>
                  <OrderXCardActions />
                </Grid>
                <Grid item xs={12} className={"pt-4"}>
                  <OrderXCardResponse />
                </Grid>
                <Grid item xs={12}>
                  <OrderXCardDetails />
                </Grid>
                {isAdmin(internalUser) && (
                  <Grid item xs={12}>
                    <OrderXCardHistory />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <OrderXCardTickets />
                </Grid>
              </Grid>
            </OrderXSelectedProvider>
          )}
        </OrderXOverviewCardContent>
      )}
    </Card>
  );
};
