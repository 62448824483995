import { Box, Dialog, DialogProps, FormControl, FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import { usePasswordAnalyser } from "../../hooks/usePasswordAnalyser";
import { useUnsavedDataContext } from "../../providers/App/unsaved-data-context";
import { usePasswordResetDialogContext } from "./password-reset-dialog.provider";
import { useValidation } from "../../providers/Validation/validation-context";
import { ResetPasswordStatus } from "../../shared/domain/user/user";
import { useResetPasswordQuery } from "../../shared/repositories/queries/auth/mutation/reset-password.query";
import { useFormStyle } from "../../style/form.style";
import { cleanPassword, IPasswordResetData } from "../../utils/PasswordHelper";
import { STRONG_PASSWORD_OPTIONS } from "../../utils/constants";
import { AutofillCatcher } from "../AutofillHelper/autofill-catcher";
import { CancelButton, SaveButton } from "../Primitives/Buttons";
import { ManualUnsavedDataPrompt } from "../Routes/manual-unsaved-data-prompt";
import { CreateStrongPasswordHelper } from "./create-strong-password-helper";

interface IPasswordResetProps extends DialogProps {}

export const PasswordResetDialog: FC<IPasswordResetProps> = (props) => {
  const { ...rest } = props;
  const { open, setIsOpen } = usePasswordResetDialogContext();
  const classes = useFormStyle();
  const { t } = useTranslation();
  const { requiredValidation } = useValidation();
  const [password, setPassword] = useState<IPasswordResetData>({
    oldPassword: "",
    normalPassword: "",
    validationPassword: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [normalPasswordFocused, setIsNormalPasswordFocused] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { analyser, analysis } = usePasswordAnalyser();
  const { hasUnsavedData, setHasUnsavedData } = useUnsavedDataContext();
  const [unsavedDataModalOpen, setUnsavedDataModalOpen] = useState<boolean>(false);

  const { mutateAsync: resetPassword } = useResetPasswordQuery();

  const validateFields = useCallback(() => {
    const err = {} as any;
    if (password.oldPassword === "" || password.oldPassword === undefined) {
      err["old_password"] = requiredValidation(password.oldPassword);
    }

    if (
      password.normalPassword !== password.validationPassword ||
      !validator.isStrongPassword(password.normalPassword, STRONG_PASSWORD_OPTIONS)
    ) {
      err["new_password"] = t("basedata.users.edit.password.error");
      err["new_password_reenter"] = t("basedata.users.edit.password.error");
    }
    analyser(password.normalPassword);
    setErrors(err);
    return err;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password.oldPassword, password.validationPassword, password.normalPassword, t, requiredValidation]);

  useEffect(() => {
    if (valuesChanged) {
      validateFields();
      setValuesChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesChanged]);

  const submitNewPasswort = (e: any) => {
    e.preventDefault();
    const err = validateFields();
    if (Object.keys(err).length > 0) {
      if (err["new_password"]) {
        showMessage(t("user.reset.notStrongPassword"), "error");
      }

      return;
    }

    handlePasswordReset();
  };

  const resetForm = () => {
    setErrors({});
    setPassword({
      oldPassword: "",
      normalPassword: "",
      validationPassword: "",
    });
  };

  const showMessage = (message: string, variant: "error" | "success") => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handlePasswordReset = async () => {
    setIsSubmitting(true);
    const response = await resetPassword({ data: password, oldPasswordRequired: true });

    switch (response.status) {
      case ResetPasswordStatus.NoUser:
        showMessage(t("user.reset.noUser"), "error");
        break;
      case ResetPasswordStatus.WrongOldPass:
        showMessage(t("user.reset.oldPasswortIncorrect"), "error");
        break;
      case ResetPasswordStatus.Successful:
        showMessage(t("user.reset.SuccessfullyReset"), "success");
        setIsOpen(false);
        resetForm();
        break;
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <ManualUnsavedDataPrompt
        open={unsavedDataModalOpen}
        onOK={() => {
          setHasUnsavedData(false);
          setUnsavedDataModalOpen(false);
          resetForm();
          setIsOpen(false);
          return true;
        }}
        onCancel={() => {
          setUnsavedDataModalOpen(false);
          return false;
        }}
      />
      <Dialog {...rest} open={open}>
        <AutofillCatcher />
        <Box m={3}>
          <Typography variant="h1" style={{ marginBottom: 20 }}>
            {t("user.reset.password")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl className={classes.form} required>
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  label={t("user.reset.oldPassword")}
                  value={password?.oldPassword}
                  onChange={(event: any) => {
                    setHasUnsavedData(true);
                    const pwd = {
                      oldPassword: event.target.value,
                      normalPassword: password?.normalPassword,
                      validationPassword: password?.validationPassword,
                    };
                    const cleanedPwd = cleanPassword(pwd);
                    setPassword(cleanedPwd);
                    setValuesChanged(true);
                  }}
                  error={errors["old_password"]}
                />
                {errors[`old_password`] && <FormHelperText>{errors[`old_password`]}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.form} required>
                <TextField
                  onFocus={() => {
                    setIsNormalPasswordFocused(true);
                  }}
                  onBlur={() => {
                    setIsNormalPasswordFocused(false);
                  }}
                  required
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  label={t("user.reset.newPassword.text")}
                  value={password?.normalPassword}
                  onChange={(event: any) => {
                    setHasUnsavedData(true);
                    const pwd = {
                      oldPassword: password?.oldPassword,
                      normalPassword: event.target.value,
                      validationPassword: password?.validationPassword,
                    };
                    const cleanedPwd = cleanPassword(pwd);
                    setPassword(cleanedPwd);
                    setValuesChanged(true);
                  }}
                  error={errors["new_password"]}
                />
                {normalPasswordFocused && <CreateStrongPasswordHelper analysis={analysis} />}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.form} required>
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  label={t("user.reset.newPassword.reenter")}
                  value={password?.validationPassword}
                  onChange={(event: any) => {
                    setHasUnsavedData(true);
                    const pwd = {
                      oldPassword: password?.oldPassword,
                      normalPassword: password?.normalPassword,
                      validationPassword: event.target.value,
                    };
                    const cleanedPwd = cleanPassword(pwd);
                    setPassword(cleanedPwd);
                    setValuesChanged(true);
                  }}
                  error={errors["new_password_reenter"]}
                />
                {errors[`new_password_reenter`] && <FormHelperText>{errors[`new_password_reenter`]}</FormHelperText>}
              </FormControl>
            </Grid>
            {/* Buttons */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CancelButton
                  disabled={isSubmitting}
                  onClick={() => {
                    if (hasUnsavedData) {
                      setUnsavedDataModalOpen(true);
                    } else {
                      resetForm();
                      setIsOpen(false);
                    }
                  }}
                >
                  <Typography variant="body1">{t("general.cancel.text")}</Typography>
                </CancelButton>
                <Box mx={1}></Box>
                <SaveButton
                  disabled={isSubmitting}
                  onClick={(e: any) => {
                    submitNewPasswort(e);
                  }}
                >
                  <Typography variant="body1">{t("general.save.text")}</Typography>
                </SaveButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};
