import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProduct } from "../../../../domain/products/cfm-product";
import { CfmProductConverter } from "../../../models/converter/cfm-product.converter";
import { IProductsResponseModel } from "../../../models/product/cfm-product.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getProductsByIds = async (ids: number[]): Promise<ICfmProduct[]> => {
  const { data } = await getInstance().post<IProductsResponseModel>(RestEndpoints.ProductsByIds, { ids });
  return data.products.map(CfmProductConverter.toDomain);
};

export const useGetProductsByIdsQuery = (ids: number[]) => {
  return useQuery([CfmQueryKeys.GetProductsByIds, ids], () => getProductsByIds(ids), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
