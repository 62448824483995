import { Grid } from "@material-ui/core";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppDialog } from "../../../shared/components/dialog/app-dialog.component";
import { FormDatepickerField } from "../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../shared/components/form/form-field.component";
import { IPomGroupContract } from "../../../shared/domain/group/group-contract";
import { formatDateYearMonthDay } from "../../../shared/util/date.util";
import { useCustomForm } from "../../../shared/util/form.util";
import { IPomAnnouncementExcelExport } from "../../domain/announcements/pom-announcement-export";
import { PomAnnouncementPeriod } from "../../repositories/models/announcements/pom-announcement.period";
import { useExportExcelAnnouncements } from "../../repositories/queries/export-excel-announcements.query";
import { useGetPomFirstAnnouncementQuery } from "../../repositories/queries/get-pom-first-announcement.query";

interface IPomAnnouncementExcelExportDialog {
  open: boolean;
  onCancel: () => void;
  currentAnnouncementDate: Date | undefined;
  announcementPeriod: PomAnnouncementPeriod;
  contract: IPomGroupContract | undefined;
}

interface IPomAnnouncementExportFormInputs {
  announcementsFromDate: Date;
  announcementsToDate: Date;
}

export const PomAnnouncementExcelExportDialog: React.VFC<IPomAnnouncementExcelExportDialog> = (props) => {
  const { open, onCancel, currentAnnouncementDate, announcementPeriod, contract } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useCustomForm<IPomAnnouncementExportFormInputs>({
    defaultValues: {
      announcementsFromDate: currentAnnouncementDate ?? undefined,
      announcementsToDate: new Date(),
    },
  });
  const isMonthly = () => {
    return announcementPeriod === PomAnnouncementPeriod.Monthly;
  };
  const dateView = isMonthly() ? "month" : "year";
  const dateFormat = isMonthly() ? "MM.YYYY" : "YYYY";
  const dateEmptyLabel = isMonthly() ? "MM.JJJJ" : "JJJJ";
  const fileFormat = isMonthly() ? "MM.YYYY" : "YYYY";
  const { mutateAsync: exportExcelAnnouncements, isLoading } = useExportExcelAnnouncements();
  const { data: firstAnnouncement } = useGetPomFirstAnnouncementQuery(contract?.id);

  useEffect(() => {
    if (firstAnnouncement) {
      setValue("announcementsFromDate", firstAnnouncement.announcementDate);
    }
  }, [firstAnnouncement, setValue]);

  const onSubmit = async (inputs: IPomAnnouncementExportFormInputs): Promise<void> => {
    if (!contract) return;
    const fromDate = new Date(inputs.announcementsFromDate);
    const toDate = new Date(inputs.announcementsToDate);
    fromDate.setDate(1);
    toDate.setDate(31);
    if (!isMonthly()) {
      fromDate.setMonth(0);
      toDate.setMonth(11);
    }
    const exportData: IPomAnnouncementExcelExport = {
      announcementsFromDate: formatDateYearMonthDay(fromDate),
      announcementsToDate: formatDateYearMonthDay(toDate),
      contractId: contract.id,
    };
    try {
      const result = await exportExcelAnnouncements(exportData);
      const filename = `${contract.contractField.takeBackSystem.name} - ${
        contract.contractNumber
      } - Eingebrachte Meldungen ${dayjs(fromDate).format(fileFormat)} bis ${dayjs(toDate).format(fileFormat)}.csv`;
      const url = window.URL.createObjectURL(new Blob(["\ufeff", result]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      enqueueSnackbar(t("pom.announcements.excel_export.error"));
    }

    onCancel();
  };

  return (
    <AppDialog
      open={open}
      headerLabelComponent={t("pom.announcements.excel_export.title")}
      onCancelClick={onCancel}
      onAcceptClick={handleSubmit(onSubmit)}
      acceptTextOverride={t("pom.announcements.excel_export.export")}
      fullWidth={false}
      maxWidth="sm"
      isLoading={isLoading}
    >
      <form>
        <Grid container spacing={2}>
          <FormField md={6}>
            <FormDatepickerField
              {...register("announcementsFromDate", { required: true, valueAsDate: true })}
              label={t("pom.announcements.excel_export.from_date")}
              hasError={Boolean(errors.announcementsFromDate)}
              control={control}
              name={"announcementsFromDate"}
              format={dateFormat}
              emptyLabel={dateEmptyLabel}
              views={[dateView]}
              minDate={firstAnnouncement?.announcementDate}
            />
          </FormField>
          <FormField md={6}>
            <FormDatepickerField
              {...register("announcementsToDate", { required: true, valueAsDate: true })}
              label={t("pom.announcements.excel_export.to_date")}
              hasError={Boolean(errors.announcementsToDate)}
              control={control}
              name={"announcementsToDate"}
              format={dateFormat}
              emptyLabel={dateEmptyLabel}
              views={[dateView]}
              minDate={firstAnnouncement?.announcementDate}
            />
          </FormField>
        </Grid>
      </form>
    </AppDialog>
  );
};
