import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmTransferOrder } from "../../../../domain/transfer-order/cfm-transfer-order";
import { CfmTransferOrderConverter } from "../../../models/converter/transfer-order/cfm-transfer-order.converter";
import { ICfmTransferOrderListModel } from "../../../models/transfer-order/cfm-transfer-order.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmTransferOrderFilterModel } from "../../../models/transfer-order/cfm-transfer-order-filter.model";

const getTransferOrders = async (filter?: ICfmTransferOrderFilterModel): Promise<ICfmTransferOrder[] | undefined> => {
  const { data } = await getInstance().get<ICfmTransferOrderListModel>(RestEndpoints.TransferOrder, {
    params: {
      status: filter?.status,
      targetAddressId: filter?.targetAddressId,
      startAddressId: filter?.startAddressId,
      postingType: filter?.postingType,
    },
  });
  return data.items.map(CfmTransferOrderConverter.toDomain);
};

export const useGetTransferOrders = (filter?: ICfmTransferOrderFilterModel, enabled?: boolean) => {
  return useQuery([CfmQueryKeys.GetTransferOrders, filter], () => getTransferOrders(filter), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: enabled,
  });
};
