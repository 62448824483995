import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { ICfmPackage } from "../../../domain/packages/cfm-package";
import { CfmPackageConverter } from "../../models/converter/cfm-package.converter";
import { ICfmPackageModel } from "../../models/package/cfm-package.model";
import { CfmQueryKeys } from "../cfm-query-keys";

export const getPackages = async (): Promise<ICfmPackage[]> => {
  const response = await getInstance().get<ICfmPackageModel[]>(RestEndpoints.GetPackages);
  return response.data.map(CfmPackageConverter.toDomain);
};

export const useGetPackages = () => {
  return useQuery([CfmQueryKeys.GetPackages], () => getPackages(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
