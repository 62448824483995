import { IPaginationMeta } from "../../domain/pagination/pagination-meta";

export enum BattGBrandStatus {
  Draft = "draft",
  Published = "published",
}

export enum BattGBrandStatusFilter {
  Draft = "draft",
  Published = "published",
  Deactivated = "deactivated", // if endDate is set
}

export interface IBattGBrandList {
  items: IBattGBrand[];
  meta: IPaginationMeta;
}

export interface IBattGBrand {
  id: number;
  status: BattGBrandStatus;
  name: string;
  trademarkRegisterNumber: string | null;
  takeBackViaGrs: boolean;
  startDate: Date;
  endDate: Date | null;
  takeBackDescription: string | null;
  takeBackCompanyName: string | null;
  takeBackContactPersonName: string | null;
  takeBackContactPersonTelephone: string | null;
  takeBackContactPersonEmail: string | null;
  grsBatteryType: IBattGGrsBatteryType;
}

export interface IBattGGrsBatteryType {
  id: number;
  name: string;
  description: string | null;
}
