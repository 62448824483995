import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { ICfmProductRoutingAssignment } from "../../../domain/routing-assignment/cfm-product-routing-assignment";
import { CfmProductRoutingAssignmentConverter } from "../../models/converter/cfm-product-routing-assignment.converter";
import { ICfmProductRoutingAssignmentModel } from "../../models/routing-assignment/cfm-product-routing-assignment.model";
import { CfmQueryKeys } from "../cfm-query-keys";

const getProductRoutingAssignment = async (
  id: number | undefined,
): Promise<ICfmProductRoutingAssignment | undefined> => {
  if (!id) return undefined;

  const result = await getInstance().get<ICfmProductRoutingAssignmentModel>(
    RestEndpoints.GetProductRoutingAssignment.replace(":id", id.toString()),
  );
  return CfmProductRoutingAssignmentConverter.toDomain(result.data);
};

export const useGetProductRoutingAssignmentQuery = (id: number | undefined) => {
  return useQuery([CfmQueryKeys.GetProductRoutingAssignment], () => getProductRoutingAssignment(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(id),
    cacheTime: 0,
  });
};
