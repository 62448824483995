import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterButton } from "../Primitives/Buttons";
import Scrollbar from "../Scrollbar";
import SearchField from "../SearchField";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    minHeight: 300,
    minWidth: 300,
    display: "flex",
    flexDirection: "column",
  },
}));

export interface IOption {
  value: any;
  displayValue: string;
}

interface IChipFilterProps {
  possibleOptions: IOption[];
  label: string;
  filter: any | any[];
  setFilter: (x: any) => void;
  onClose?: () => void;
  onChipClick?: (x: any) => void;
  variant?: "default" | "outlined";
  returnFullOption?: boolean;
  multiple?: boolean;
  hideCheckbox?: boolean;
}

export const ChipFilter: FunctionComponent<IChipFilterProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { possibleOptions, label, filter, setFilter, onClose, onChipClick, returnFullOption, multiple, hideCheckbox } =
    props;

  const handleClick = (event: any) => {
    setIsActive(true);
    setAnchorEl(event.currentTarget);
    if (onChipClick) {
      onChipClick(event);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
    if (onClose) {
      onClose();
    }
  };

  const isChecked = (option: any) => {
    if (!filter) {
      return false;
    }
    if (returnFullOption === true) {
      const checked = multiple ? filter.includes(option.value) : option.value === (filter as string);
      return checked;
    } else {
      return multiple ? filter.includes(option.value) : option.value === (filter as string);
    }
  };

  const filterOptions = (options: IOption[]) => {
    let filteredOptions = options
      .filter((option) => option.value && option.displayValue)
      .sort((a, b) => (a.value === filter ? -1 : 1));

    if (searchTerm) {
      filteredOptions = filteredOptions.filter((option) =>
        t(option.displayValue ?? "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );
    }
    return filteredOptions;
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <FilterButton
        isActive={isActive}
        isFilterActive={filterOptions(possibleOptions).some((option) => isChecked(option))}
        onClick={handleClick}
      >
        {label}
      </FilterButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.container}>
          {
            <div>
              <SearchField
                onSearchSubmit={(value: any) => {
                  setSearchTerm(value);
                }}
                placeholder={t("general.search")}
                initialValue={searchTerm}
              />

              <Scrollbar style={{ minHeight: 250, marginTop: 18, marginBottom: 18 }}>
                <List>
                  {filterOptions(possibleOptions).map((option: IOption, index: number) => {
                    return (
                      <div key={index}>
                        <ListItem
                          dense
                          button
                          onClick={() => {
                            if (filter === option.value) {
                              setFilter(undefined);
                            } else {
                              setFilter(returnFullOption === true ? option : option.value);
                            }
                            if (!multiple) {
                              handleClose();
                            }
                          }}
                        >
                          {!hideCheckbox && (
                            <ListItemIcon>
                              <Checkbox edge="start" checked={isChecked(option)} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                          )}
                          <ListItemText primary={t(option.displayValue)} />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  })}
                </List>
              </Scrollbar>
            </div>
          }

          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Button onClick={handleClose} variant="contained" fullWidth color="secondary">
              <Typography>{t("general.close")}</Typography>
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};
