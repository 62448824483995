import React, { createContext, useContext, useState } from "react";

interface IPackageOverviewContextType {
  selectedProductIds: number[];
  setSelectedProductIds: (ids: number[]) => void;
}

const PackageOverviewContext = createContext<IPackageOverviewContextType>({} as IPackageOverviewContextType);

export const PackageOverviewProvider = (props: any) => {
  const value = usePackageOverviewProvider();
  return <PackageOverviewContext.Provider value={value}>{props.children}</PackageOverviewContext.Provider>;
};

export const usePackageOverviewContext = () => {
  return useContext(PackageOverviewContext);
};

const usePackageOverviewProvider = (): IPackageOverviewContextType => {
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  return { selectedProductIds, setSelectedProductIds };
};
