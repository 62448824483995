import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ArticleNumberBox } from "../../../../../../../configurator/components/product/article-number-box.component";
import { useAuthContext } from "../../../../../../../providers/Auth/auth.provider";
import { AppDialog } from "../../../../../../../shared/components/dialog/app-dialog.component";
import { FormAutocomplete } from "../../../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "../../../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../../../shared/components/form/form-field.component";
import { IAddressLean } from "../../../../../../../shared/domain/address/address-lean";
import { useCustomForm } from "../../../../../../../shared/util/form.util";
import { Shades } from "../../../../../../../style/Colors";
import { getAddressIds, getFormattedDomainAddress } from "../../../../../../../utils/address.util";
import { ICfmCreateResponseValueX } from "../../../../../../domain/order-x/actions/cfm-create-response-value-x";
import { ICfmWarehouseProduct } from "../../../../../../domain/products/cfm-warehouse-product";
import { CfmTransferOrderStatus } from "../../../../../../domain/transfer-order/cfm-transfer-order-status";
import {
  IResponseFieldValuesFormInputs,
  IResponseValueServiceDateFormInputs,
} from "../../../actions/order-x-action.component";
import { useSelectedFilesContext } from "../../../actions/providers/selected-files.provider";
import { ResponseXInput } from "../../../actions/response-x-input.component";
import { CfmServiceDatesInput } from "../../../actions/service-date-input.component";
import { CfmResponseFieldXConverter } from "../../../../../../repositories/models/converter/cfm-response-field-x.converter";
import { CfmTransferOrderConverter } from "../../../../../../repositories/models/converter/transfer-order/cfm-transfer-order.converter";
import { useCreateTransferOrderQuery } from "../../../../../../repositories/queries/transfer-order/mutation/create-transfer-order.query";
import { ICfmTransferOrderAvailableAction } from "../../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { ICfmCreateTransferOrder } from "../../../../../../domain/transfer-order/cfm-create-transfer-order";
import { useGetGroupAddresses } from "../../../../../../../shared/repositories/queries/address/get-group-addresses.query";
import { AddressType } from "../../../../../../../shared/models/address/address.model";

const useStyles = makeStyles((theme: Theme) => ({
  weightBox: {
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: 5,
    padding: "5px 20px 5px 20px",
  },
}));

export interface IRespondOutgoingWarehouseProductInputs
  extends IResponseValueServiceDateFormInputs,
    IResponseFieldValuesFormInputs {
  targetAddressId: number;
  plannedDeliveryDate: Date;
}

interface IRespondOutgoingWarehouseProductDialogProps {
  warehouseProduct: ICfmWarehouseProduct;
  availableAction: ICfmTransferOrderAvailableAction;
  startAddress: IAddressLean;
  isOpen: boolean;
  onSubmitted: VoidFunction;
  onCancel: VoidFunction;
}

export const RespondOutgoingWarehouseProductDialog: VFC<IRespondOutgoingWarehouseProductDialogProps> = (props) => {
  const { isOpen, onSubmitted, onCancel, startAddress, warehouseProduct, availableAction } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { uploadFiles, isLoading: isUploadFilesLoading, reset: resetFileUpload } = useSelectedFilesContext();
  const { internalUser } = useAuthContext();
  const { isLoading: areAddressesLoading, data: addressesResult } = useGetGroupAddresses(
    internalUser?.group?.id,
    undefined,
    [AddressType.RecyclerLocation],
    internalUser?.group?.id !== undefined,
    undefined,
    true,
  );
  const { isLoading: isCreateLoading, mutateAsync: createTransferOrder } = useCreateTransferOrderQuery();

  const getDefaultValues = (): ICfmCreateResponseValueX[] => {
    if (!internalUser) {
      return [];
    }

    return (
      availableAction.responseFieldAssignments?.map((assignment) =>
        CfmResponseFieldXConverter.assignmentToCreateResponseValueX(assignment, -1, internalUser.id),
      ) ?? []
    );
  };
  const formMethods = useCustomForm<IRespondOutgoingWarehouseProductInputs>({
    defaultValues: {
      responseFields: getDefaultValues(),
    },
  });
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = formMethods;

  const addresses = useMemo(() => addressesResult?.addresses ?? [], [addressesResult]);

  const onSubmit: SubmitHandler<IRespondOutgoingWarehouseProductInputs> = async (inputs) => {
    const filesList = (await uploadFiles()) ?? [];
    const requestData: ICfmCreateTransferOrder = CfmTransferOrderConverter.formToCreateDomain(
      inputs,
      startAddress.id,
      warehouseProduct.product.id,
      warehouseProduct.warehouseArticleNumber,
      filesList,
      CfmTransferOrderStatus.Pending,
      inputs.plannedDeliveryDate,
    );

    await createTransferOrder(requestData);
    resetFileUpload();
    onSubmitted();
  };

  const handleCancel = () => {
    resetFileUpload();
    onCancel();
  };

  return (
    <AppDialog
      open={isOpen}
      title={t("orders.recyclerOutgoingWarehouse.dialog.title")}
      onCancelClick={handleCancel}
      onAcceptClick={() => handleSubmit(onSubmit)()}
      acceptTextOverride={t("orders.recyclerOutgoingWarehouse.dialog.submit")}
      maxWidth="sm"
      isLoading={areAddressesLoading || isUploadFilesLoading || isCreateLoading}
    >
      <FormProvider {...formMethods}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} className={classes.weightBox}>
              <Grid item>
                <ArticleNumberBox articleNumber={warehouseProduct.product.articleNumber} />
              </Grid>
              <Grid item>
                <Typography>
                  {t("orders.recyclerOutgoingWarehouse.dialog.weightInfo", {
                    productName: warehouseProduct.product.name,
                    quantity: warehouseProduct.quantity,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <FormField md={12}>
            <FormAutocomplete<number>
              name={"targetAddressId"}
              control={control}
              label={t("orders.recyclerOutgoingWarehouse.dialog.targetAddress")}
              options={getAddressIds(addresses)}
              getOptionLabel={(value) => {
                const address = addresses.find((address) => address.id === value);
                if (address) {
                  return getFormattedDomainAddress(address, t);
                }
              }}
              error={Boolean(errors?.targetAddressId)}
              rules={{ required: true }}
            />
          </FormField>
          <Grid item>
            <FormDatepickerField
              label={t("orders.recyclerOutgoingWarehouse.dialog.plannedDeliveryDate")}
              hasError={Boolean(errors.plannedDeliveryDate)}
              control={control}
              required
              name={"plannedDeliveryDate"}
              disablePast
            />
          </Grid>
          {availableAction.responseFieldAssignments?.map((field, index) => {
            const responseField = availableAction.responseFieldAssignments?.[index];
            if (!responseField) {
              return <></>;
            }
            return (
              <Grid item>
                <ResponseXInput
                  responseIndex={index}
                  responseFieldId={responseField.responseAssignmentResponseFieldId}
                  fieldType={responseField.type}
                  label={responseField.name}
                  unit={responseField.unit}
                  required={false}
                />
              </Grid>
            );
          })}
          {availableAction.serviceDates && (
            <Grid item>
              <CfmServiceDatesInput serviceDates={availableAction.serviceDates} />
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </AppDialog>
  );
};
