import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderXStatusCount } from "../../../../domain/order-x/order-x-status-count";
import { CfmOrderXStatusFilterModel } from "../../../models/order-x/cfm-order-x-filter.model";
import { ICfmOrderXStatusCountModel } from "../../../models/order-x/cfm-order-x-status-count.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getOrderXStatusCount = async (
  orderStatus: CfmOrderXStatusFilterModel,
  hasPlannedDeliveryDate?: boolean,
): Promise<ICfmOrderXStatusCount> => {
  const { data } = await getInstance().get<ICfmOrderXStatusCountModel>(
    RestEndpoints.CfmOrderXStatusCount.replace(":status", orderStatus.toString()),
    {
      params: {
        hasPlannedDeliveryDate,
      },
    },
  );
  return data;
};

export const useGetOrderXStatusCountQuery = (
  orderStatus: CfmOrderXStatusFilterModel,
  enabled: boolean = true,
  hasPlannedDeliveryDate?: boolean,
) => {
  return useQuery(
    [CfmQueryKeys.GetOrderXStatusCount, orderStatus, hasPlannedDeliveryDate],
    () => getOrderXStatusCount(orderStatus, hasPlannedDeliveryDate),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled,
    },
  );
};
