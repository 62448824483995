import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProductFractionModel } from "../../../models/product/cfm-product-fraction.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmProductFraction } from "../../../../domain/products/cfm-product-fraction";
import { CfmProductFractionConverter } from "../../../models/converter/cfm-product-fraction.converter";

const getProductFractions = async (): Promise<ICfmProductFraction[]> => {
  const { data } = await getInstance().get<ICfmProductFractionModel[]>(RestEndpoints.AllProductFractions);
  return data.map(CfmProductFractionConverter.toDomain);
};

export const useGetProductFractionsQuery = () => {
  return useQuery([CfmQueryKeys.GetProductFractions], () => getProductFractions(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
