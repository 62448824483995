import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthContext } from "../../../providers/Auth/auth.provider";

export enum DashboardAction {
  SELECT_TAKE_BACK_SYSTEM = "SELECT_TAKE_BACK_SYSTEM",
}

interface IDashboardActionContextType {
  isOpenAction: boolean;
  setIsOpenAction: (open: boolean) => void;
  action: DashboardAction | undefined;
  setAction: (action: DashboardAction | undefined) => void;
  contractId: number | undefined;
  setContractId: (contractId: number | undefined) => void;
}

interface IDashboardActionContextProps {
  children: any;
}

const DashboardActionContext = createContext<IDashboardActionContextType>({} as IDashboardActionContextType);

export const DashboardActionProvider = (props: IDashboardActionContextProps) => {
  const value = useDashboardActionProvider(props);
  return <DashboardActionContext.Provider value={value}>{props.children}</DashboardActionContext.Provider>;
};

export const useDashboardActionContext = () => {
  return useContext(DashboardActionContext);
};

const useDashboardActionProvider = (props: IDashboardActionContextProps): IDashboardActionContextType => {
  const [action, setAction] = useState<DashboardAction | undefined>(undefined);
  const [isActionDialogOpen, setIsActionDialogOpen] = useState<boolean>(false);
  const [contractId, setContractId] = useState<number | undefined>(undefined);

  const { internalUser } = useAuthContext();

  useEffect(() => {
    if (internalUser?.group?.pomContracts?.length && internalUser?.group.pomContracts.length === 1) {
      setContractId(internalUser.group.pomContracts[0].id);
    }
  }, [internalUser]);

  return {
    isOpenAction: isActionDialogOpen,
    setIsOpenAction: setIsActionDialogOpen,
    action,
    setAction,
    contractId,
    setContractId,
  };
};
