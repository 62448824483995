import {
  GroupType,
  isAdminGroup,
  isAgencyGroup,
  isCreditorGroup,
  isDebtorGroup,
  isRepresentativeGroup,
} from "../domain/group/group-type";

export enum ErpNumberPrefix {
  Admin = "ADM",
  Agency = "BA",
  Debtor = "BD",
  Creditor = "BK",
  Representative = "BB",
  None = "",
}

export const getErpNumberOnly = (str: string): string => {
  const regEx = new RegExp("[0-9]*$");
  const matchValue = str?.match(regEx);
  return matchValue ? matchValue[0] : "";
};

export const getErpNumberPrefix = (types: GroupType[]): ErpNumberPrefix => {
  if (isAdminGroup(types)) return ErpNumberPrefix.Admin;
  if (isAgencyGroup(types)) return ErpNumberPrefix.Agency;
  if (isDebtorGroup(types)) return ErpNumberPrefix.Debtor;
  if (isCreditorGroup(types)) return ErpNumberPrefix.Creditor;
  if (isRepresentativeGroup(types)) return ErpNumberPrefix.Representative;
  return ErpNumberPrefix.None;
};
