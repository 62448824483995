import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { CfmOrderXApiStatus } from "../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { getOrderStatusOption } from "../../../collect-from-market/domain/order-x/order-x.util";
import { OrderCheckbox } from "../../../components/checkbox/order-checkbox.component";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import { IdBox } from "./id-box.component";

const useStyles = makeStyles((theme: Theme) => ({
  pkgName: {
    fontWeight: "bold",
  },
  container: {
    height: "100%",
  },
  checkBoxContainer: { marginLeft: 15 },
  statusBox: {
    marginLeft: 12,
  },
}));

interface IProductResponseOrderStatusConfigRowProps {
  status: CfmOrderXApiStatus;
  onSelect: (status: CfmOrderXApiStatus, checked: boolean) => void;
  isSelected: boolean;
}

export const ProductResponseOrderStatusConfigRow: VFC<IProductResponseOrderStatusConfigRowProps> = (props) => {
  const { status, onSelect, isSelected } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BoxedRowGrid>
      <Grid item>
        <Grid container direction="column" justifyContent="center" className={classes.container}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item className={classes.checkBoxContainer}>
                <OrderCheckbox onChange={(checked) => onSelect(status, checked)} checked={isSelected} size="large" />
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center" className={classes.container}>
                  <Typography variant="body1">{getOrderStatusOption(status, t).label}</Typography>
                  <Box className={classes.statusBox}>
                    <IdBox id={status} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BoxedRowGrid>
  );
};
