import { Box, Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmProductRoutingAssignment } from "../../../collect-from-market/domain/routing-assignment/cfm-product-routing-assignment";
import { ProductRoutingAddressField } from "./product-routing-address-field.component";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  divider: {
    width: "100%",
  },
}));

interface IProductRoutingAssignmentSummaryProps {
  assignment: ICfmProductRoutingAssignment;
}

export const ProductRoutingAssignmentSummary: VFC<IProductRoutingAssignmentSummaryProps> = (props) => {
  const { assignment } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="space-evenly">
      {assignment.primaryStartAddress && assignment.primaryTargetAddress && (
        <Grid item>
          <ProductRoutingAddressField
            heading={t("configuration.assignments.main")}
            startAddress={assignment.primaryStartAddress}
            targetAddress={assignment.primaryTargetAddress}
            optionalAddresses={assignment.optionalPrimaryAddresses ?? []}
            level="primary"
          />
        </Grid>
      )}
      {assignment.secondaryStartAddress && assignment.secondaryTargetAddress && (
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={10}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <Divider className={classes.divider} />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}></Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      {assignment.secondaryStartAddress && assignment.secondaryTargetAddress && (
        <Grid item>
          <ProductRoutingAddressField
            heading={t("configuration.assignments.secondary")}
            startAddress={assignment.secondaryStartAddress}
            targetAddress={assignment.secondaryTargetAddress}
            optionalAddresses={assignment.optionalSecondaryAddresses ?? []}
            level="secondary"
          />
        </Grid>
      )}
    </Grid>
  );
};
