import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { SystemCloningStatusConverter } from "../../../domain/converter/system-cloning-status.converter";
import { ISystemCloningStatus } from "../../../domain/system-cloning-status/system-cloning-status";
import { ISystemCloningStatusModel } from "../../models/system-cloning-status/system-cloning-status.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getLatestCloningStatus = async (): Promise<ISystemCloningStatus> => {
  const { data } = await getInstance().get<ISystemCloningStatusModel>(RestEndpoints.SystemClone);
  return SystemCloningStatusConverter.toDomain(data);
};

export const useGetLatestCloningStatusQuery = () => {
  return useQuery(SharedQueryKeys.GetLatestCloningStatus, () => getLatestCloningStatus(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
