import { Box, Checkbox, FormControlLabel, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { PrimaryColors } from "../../../../style/Colors";

const useStyles = makeStyles((theme) => ({
  noMargin: {
    margin: 0,
  },
  margin: {},
  checkboxContainer: {
    height: "100%",
  },
  checkboxIcon: {
    // the checkbox is an svg icon, therefore we need to use transform
    transform: "scale(1.042)",
  },
  color: {
    color: PrimaryColors.base,
  },
}));

interface IFormCheckbox {
  label: string;
  disabled?: boolean;
  control: Control<any, object>;
  name: string;
  noMargin?: boolean;
  onValueChange?: (...event: any[]) => void;
  tooltip?: string;
  dataTestId?: string;
}

export const FormCheckbox = ({
  disabled = false,
  label,
  control,
  name,
  noMargin = false,
  onValueChange,
  tooltip,
  dataTestId,
}: IFormCheckbox) => {
  const classes = useStyles();

  return (
    //empty tooltip will not be displayed
    <Tooltip title={tooltip ?? ""}>
      <Box className={classes.checkboxContainer} alignItems={"center"}>
        <FormControlLabel
          className={noMargin ? classes.noMargin : classes.margin}
          disabled={disabled}
          label={<span>{label}</span>}
          control={
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => (onValueChange ? onValueChange(e) : field.onChange(e))}
                    disableRipple
                    disabled={disabled}
                    className={classes.color}
                    classes={{ root: classes.checkboxIcon }}
                    data-testid={dataTestId}
                  />
                );
              }}
            />
          }
        />
      </Box>
    </Tooltip>
  );
};
