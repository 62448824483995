import { IGroupHistoryModel, IGroupHistoryResultModel } from "../../models/group/group-history.model";
import { IFormattedGroupHistory, IGroupHistoryResult } from "../group/group-history";
import { PaginationMetaConverter } from "./pagination-meta.converter";
import { UserConverter } from "./user.converter";

export class GroupHistoryConverter {
  static toListDomain(historyResult: IGroupHistoryResultModel): IGroupHistoryResult {
    return {
      entries: historyResult.entries.map(GroupHistoryConverter.oDomain),
      meta: PaginationMetaConverter.toDomain(historyResult.meta),
    };
  }

  static oDomain(history: IGroupHistoryModel): IFormattedGroupHistory {
    return {
      id: history.id,
      changedTableEntryId: history.changedTableEntryId,
      columnName: history.columnName,
      oldValue: history.oldValue,
      newValue: history.newValue,
      informationType: history.informationType,
      updatedAt: history.updatedAt,
      updatedBy: history.updatedBy ? UserConverter.toDomain(history.updatedBy) : history.updatedBy,
    };
  }
}
