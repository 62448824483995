import { useMutation } from "react-query";
import { RestEndpoints, replaceUriParameter } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderFreshdeskRequest } from "../../../../domain/order-x/cfm-order-freshdesk-request";
import { CfmOrderFreshdeskRequestConverter } from "../../../models/converter/cfm-order-freshdesk-request.converter";
import { CfmQueryKeys } from "../../cfm-query-keys";

const addOrderFreshdeskRequest = async (orderId: number, requestData: ICfmOrderFreshdeskRequest): Promise<boolean> => {
  const url = replaceUriParameter(RestEndpoints.CfmOrderFreshdeskRequest, "orderId", orderId);
  const { data } = await getInstance().post(url, CfmOrderFreshdeskRequestConverter.toModel(requestData));

  return data;
};

export const useAddOrderFreshdeskRequestQuery = () => {
  return useMutation(
    [CfmQueryKeys.AddOrderFreshdeskRequest],
    ({ orderId, requestData }: { orderId: number; requestData: ICfmOrderFreshdeskRequest }) =>
      addOrderFreshdeskRequest(orderId, requestData),
  );
};
