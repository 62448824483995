import { ICfmProductPackageConfig } from "../../../../configurator/components/package/create-product-package-config-content.component";
import { ICreateCfmProductPackageConfigModel } from "../product/cfm-create-product-package-config.model";

export class CfmCreateProductPackageConfigConverter {
  public static dataToModel(config: ICfmProductPackageConfig): ICreateCfmProductPackageConfigModel {
    return {
      productId: config.product.id,
      packageIds: [...config.existingPackages, ...config.newPackages].map((pkg) => pkg.id),
    };
  }
}
