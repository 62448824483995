import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IConfig } from "../../../domain/config/config";
import { ConfigConverter } from "../../../domain/converter/config.converter";
import { IConfigModel } from "../../models/config/config.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getConfig = async (): Promise<IConfig> => {
  const response = await getInstance().get<IConfigModel>(RestEndpoints.Config);
  return ConfigConverter.toDomain(response.data);
};

export const useGetConfig = () => {
  return useQuery([SharedQueryKeys.Config], () => getConfig(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
