import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { KeyboardBackspaceOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  BigBagIcon,
  CardboardIcon,
  CustomerContainerIcon,
  EuroPalletIcon,
  LoadCarrierXLIcon,
  Paloxe600Icon,
  SafetyContainerXLIcon,
  TransportBarrelIcon,
  TransportBarrelSlimIcon,
} from "../../../components/Primitives/Icons";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { usePackageIconStyle } from "../../../style/package-icon.style";
import { ICfmPackage } from "../packages/cfm-package";

const useStyles = makeStyles((theme: Theme) => ({
  mobileAlign: {
    paddingTop: 5,
  },
  measurements: {
    paddingTop: 3.5,
    paddingLeft: 10,
  },
  rotation: {
    transform: "rotate(180deg)",
  },
  arrowPadding: {
    paddingRight: 7,
  },
}));

export const usePackageIconAndLabel = () => {
  const iconClasses = usePackageIconStyle();
  const classes = useStyles();
  const { isDesktop } = useScreenSize();
  const { t } = useTranslation();

  const includesName = (name: string | null, checkValue: string) => {
    if (!name) return false;

    return name.toLowerCase().includes(checkValue.toLowerCase());
  };

  const getIcon = (productPackage: ICfmPackage, slimVersion?: boolean) => {
    const includes = (checkValue: string) => includesName(productPackage.name, checkValue);
    if (includes("Big Bag")) {
      return <BigBagIcon classes={{ root: iconClasses.bigBagIconSize }} />;
    }
    if (includes("Europalette")) {
      return <EuroPalletIcon classes={{ root: iconClasses.euroPaletteIconSize }} />;
    }
    if (includes("Transportfass")) {
      return slimVersion ? (
        <TransportBarrelSlimIcon classes={{ root: iconClasses.transportBarrelSlimIconSize }} />
      ) : (
        <TransportBarrelIcon classes={{ root: iconClasses.transportBarrelIconSize }} />
      );
    }
    if (includes("Sammelkarton")) {
      return <CardboardIcon classes={{ root: iconClasses.cardBoardIconSize }} />;
    }
    if (includes("Sicherheitsbehälter")) {
      return <SafetyContainerXLIcon classes={{ root: iconClasses.safetyContainerIconSize }} />;
    }
    if (includes("Ladungsträger")) {
      return <LoadCarrierXLIcon classes={{ root: iconClasses.loadCarrierIconSize }} />;
    }
    if (includes("Kundeneigener Behälter")) {
      return <CustomerContainerIcon classes={{ root: iconClasses.customerContainerIconSize }} />;
    }
    if (includes("Paloxe")) {
      return <Paloxe600Icon classes={{ root: iconClasses.paloxeIconSize }} />;
    }
    return <CustomerContainerIcon classes={{ root: iconClasses.customerContainerIconSize }} />;
  };

  const getLabel = (
    productPackage: ICfmPackage,
    quantity?: number,
    width?: number,
    height?: number,
    weight?: number,
    length?: number,
    descriptive?: boolean,
  ) => {
    const measurementsRequired = productPackage.measurementsRequired;
    const customMeasurementsAvailable =
      height !== undefined && width !== undefined && weight !== undefined && length !== undefined;
    const measurementsAvailable =
      productPackage.width !== undefined &&
      productPackage.height !== undefined &&
      productPackage.length !== undefined &&
      productPackage.tareWeightKg !== undefined;
    const packageWidth = customMeasurementsAvailable ? width : productPackage.width;
    const packageHeight = customMeasurementsAvailable ? height : productPackage.height;
    const packageLength = customMeasurementsAvailable ? length : productPackage.length;
    const packageWeight = customMeasurementsAvailable ? weight : productPackage.tareWeightKg;
    const packageQuantity = customMeasurementsAvailable ? quantity : undefined;

    const measurementsText = measurementsAvailable
      ? packageQuantity
        ? t("orders.new.wizard.package.measurementsWithQuantity", {
            quantity: packageQuantity,
            width: packageWidth,
            height: packageHeight,
            weight: packageWeight,
            length: packageLength,
          })
        : t("orders.new.wizard.package.measurements", {
            width: packageWidth,
            height: packageHeight,
            weight: packageWeight,
            length: packageLength,
          })
      : "";

    const displayMeasurements =
      (customMeasurementsAvailable && measurementsRequired) || (measurementsAvailable && !measurementsRequired);

    return (
      <Grid container direction={descriptive ? "column" : "row"} spacing={isDesktop && !descriptive ? 4 : 0}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl="auto"
          direction={isDesktop ? "row" : "column"}
          wrap={"wrap"}
        >
          <Typography className={classNames({ [classes.mobileAlign]: !isDesktop })} variant="body1">
            {descriptive ? `${t("orders.new.wizard.package.name")} ` : ""}
            <b>{productPackage.name}</b>
          </Typography>
          {displayMeasurements && <Typography variant="body2">{measurementsText}</Typography>}
        </Grid>
      </Grid>
    );
  };

  const getLabelWithMeasuredContainerData = (
    productPackage: ICfmPackage,
    quantity?: number,
    width?: number,
    height?: number,
    weight?: number,
    length?: number,
    descriptive?: boolean,
  ) => {
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          {getLabel(productPackage, undefined, width, height, weight, length, descriptive)}
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent={"flex-start"} spacing={0}>
            <Grid item className={classes.arrowPadding}>
              <KeyboardBackspaceOutlined />
            </Grid>
            <Grid item>
              {t("orders.new.wizard.package.summaryContainersToCollect", {
                containersToCollect: quantity,
                unit: productPackage.unit ?? "Stk.",
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getLabelWithContainerData = (
    productPackage: ICfmPackage,
    containersToCollect: number,
    containersToDeliver: number | undefined,
    descriptive?: boolean,
  ) => {
    const displayContainersToDeliver = (containersToDeliver ?? 0) > 0;
    return (
      <Grid container direction="column" spacing={0}>
        <Grid item xs={12}>
          {getLabel(productPackage, undefined, undefined, undefined, undefined, undefined, descriptive)}
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent={"flex-start"} spacing={0}>
            <Grid item className={classes.arrowPadding}>
              <KeyboardBackspaceOutlined />
            </Grid>
            <Grid item>
              {t("orders.new.wizard.package.summaryContainersToCollect", {
                containersToCollect,
                unit: productPackage.unit ?? "Stk.",
              })}
            </Grid>
          </Grid>
        </Grid>
        {displayContainersToDeliver && (
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent={"flex-start"} spacing={0}>
              <Grid item className={classes.arrowPadding}>
                <KeyboardBackspaceOutlined classes={{ root: classes.rotation }} />
              </Grid>
              <Grid item>
                {t("orders.new.wizard.package.summaryContainersToDeliver", {
                  containersToDeliver,
                  unit: productPackage.unit ?? "Stk.",
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return {
    getIcon,
    getLabel,
    getLabelWithContainerData,
    getLabelWithMeasuredContainerData,
  };
};
