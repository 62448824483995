import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { IPasswordResetData } from "../../../../../utils/PasswordHelper";
import getInstance from "../../../../../utils/rest";
import { IResetPasswordResultModel } from "../../../../models/user/user.model";
import { SharedQueryKeys } from "../../shared-query-keys";
import { IResetPasswordResponse } from "../../../../domain/user/user";

const unlock = async (passwordData: IPasswordResetData, token: string): Promise<IResetPasswordResponse> => {
  const { data } = await getInstance().put<IResetPasswordResultModel>(
    RestEndpoints.Unlock,
    {
      normalPassword: passwordData.normalPassword,
      oldPassword: passwordData.oldPassword,
      oldPasswordRequired: false,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  const result = {
    message: data.message,
    status: data.status,
  };
  return result;
};

export const useUnlockQuery = () => {
  return useMutation(SharedQueryKeys.Unlock, ({ data, token }: { data: IPasswordResetData; token: string }) =>
    unlock(data, token),
  );
};
