import { Box, CircularProgress, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ForwardButton } from "../../../../../../components/Primitives";
import { BoxedRowGrid } from "../../../../../../configurator/components/grid/boxed-row-grid.component";
import { ArticleNumberBox } from "../../../../../../configurator/components/product/article-number-box.component";
import { ICfmWarehouseProduct } from "../../../../../domain/products/cfm-warehouse-product";
import { WarehouseProductQuantity } from "./warehouse-product-weight.component";
import {
  CfmTransferOrderActionType,
  ICfmTransferOrderAvailableAction,
} from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { useGetTransferOrderActionsForProduct } from "../../../../../repositories/queries/transfer-order/queries/get-transfer-order-actions-for-product.query";
import { CfmOrderXStatus } from "../../../../../domain/order-x/cfm-order-x.status";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { useGetTransferOrdersNotifiedWeightQuery } from "../../../../../repositories/queries/order-x/queries/get-transfer-orders-notified-weight.query";
import { CfmTransferOrderStatus } from "../../../../../domain/transfer-order/cfm-transfer-order-status";

interface IWarehouseProductRowProps {
  warehouseProduct: ICfmWarehouseProduct;
  selectedWarehouse: IAddressLean;
  status: CfmOrderXStatus;
  onRespondClicked: (warehouseProduct: ICfmWarehouseProduct, action: ICfmTransferOrderAvailableAction) => void;
  shouldShowNotifiedWeight?: boolean;
}

export const WarehouseProductRow: VFC<IWarehouseProductRowProps> = (props) => {
  const { warehouseProduct, onRespondClicked, selectedWarehouse, shouldShowNotifiedWeight, status } = props;
  const { t } = useTranslation();
  const { data: availableActions, isLoading: isAvailableActionsLoading } = useGetTransferOrderActionsForProduct(
    warehouseProduct.product.id,
    status,
  );
  const { isLoading: isNotifiedWeightLoading, data: notifiedWeight } = useGetTransferOrdersNotifiedWeightQuery(
    warehouseProduct.product.id,
    selectedWarehouse?.id,
    CfmTransferOrderStatus.Pending,
    shouldShowNotifiedWeight,
  );

  const getButtonText = (type: CfmTransferOrderActionType): string => {
    switch (type) {
      case CfmTransferOrderActionType.Recycled:
        return t("orders.destinationReceivedWarehouse.respond");
      case CfmTransferOrderActionType.Sorted:
        return t("orders.recyclerIncomingWarehouse.respond");
      case CfmTransferOrderActionType.TruckloadCreated:
        return t("orders.recyclerOutgoingWarehouse.respond");
      default:
        return "";
    }
  };

  const isLoading = isNotifiedWeightLoading || isAvailableActionsLoading;

  return (
    <BoxedRowGrid justifyContent={"space-between"}>
      <Box display={"flex"} flexDirection="row" alignItems={"center"}>
        <ArticleNumberBox articleNumber={warehouseProduct.product.articleNumber} />
        <div className={"pl-3"}>{warehouseProduct.product.name}</div>
      </Box>
      <Box display={"flex"} flexDirection="row" alignItems={"center"} gridGap={12} flexWrap={"wrap"}>
        <Box display={"flex"} flexDirection={"row"}>
          <WarehouseProductQuantity
            quantity={warehouseProduct.quantity}
            unit={warehouseProduct.unit}
            notifiedWeight={notifiedWeight?.weight}
          />
        </Box>
        {isLoading && (
          <div className="mx-2">
            <CircularProgress size={30} />
          </div>
        )}
        {!isAvailableActionsLoading &&
          availableActions &&
          availableActions.map((action, index) => {
            return (
              <ForwardButton
                key={`${action.type}-${index}`}
                variant="outlined"
                onClick={() => onRespondClicked(warehouseProduct, action)}
              >
                <Typography variant="body1">{getButtonText(action.type)}</Typography>
              </ForwardButton>
            );
          })}
      </Box>
    </BoxedRowGrid>
  );
};
