import { Grid, TablePagination } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ArrowRightIconButton } from "../../../../components/Primitives";
import SearchField from "../../../../components/SearchField";
import CustomTable from "../../../../components/Tables/custom-table";
import { IGroup } from "../../../../shared/domain/group/group";
import { useBaseDataGridStyles } from "../../../../style/baseDataGrid.style";
import { AvailableSharedRoutes } from "../../../../utils/constants";
import { usePomAgencyContext } from "../pom-agency.provider";

export const PomAgencyGroupsTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useBaseDataGridStyles();
  const history = useHistory();
  const {
    agencyGroups,
    isLoading,
    setNewSearchQuery,
    curSearchQuery,
    pageSize,
    setNewPage,
    setNewPageSize,
    itemCount,
    page,
  } = usePomAgencyContext();

  const actions = [
    (rowData: object) => {
      const group = rowData as IGroup;

      return {
        icon: () => <ArrowRightIconButton />,
        tooltip: t("basedata.users.table.action.detail"),
        onClick: () => {
          if (!group.id) {
            return;
          }
          history.push(AvailableSharedRoutes.GroupDetail.replace(":id", group.id.toString()));
        },
      };
    },
  ];

  return (
    <CustomTable
      options={{
        pageSize: pageSize,
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
      }}
      columns={[
        {
          title: t("basedata.groups.table.groupId"),
          field: "id",
        },
        {
          title: t("basedata.groups.table.name"),
          field: "name",
        },
      ]}
      actions={actions}
      data={agencyGroups}
      isLoading={isLoading}
      components={{
        Toolbar: () => (
          <Grid direction="row" container className={classes.search}>
            <Grid className={classes.searchGrid} container spacing={4}>
              <Grid className={classes.searchGrid} item>
                <SearchField
                  tooltip={t("basedata.groups.table.search.agency")}
                  submitSearch={true}
                  inputTimeout={0}
                  initialValue={curSearchQuery}
                  onSearchSubmit={(value: string) => {
                    setNewSearchQuery(value);
                  }}
                  placeholder={t("basedata.groups.table.search.agency")}
                />
              </Grid>
            </Grid>
          </Grid>
        ),
        Pagination: (props) => (
          <TablePagination
            {...props}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={pageSize}
            count={itemCount}
            page={page}
            onPageChange={(event: any, newPage: number) => {
              props.onPageChange(event, newPage);
              setNewPage(newPage);
            }}
            onRowsPerPageChange={(event: any) => {
              props.onRowsPerPageChange(event);
              setNewPageSize(event.target.value);
            }}
          />
        ),
      }}
    />
  );
};
