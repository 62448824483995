import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { InfoBox } from "../../../components/info-box/info-box";
import { GroupAddressMarker, InvoiceAddressMarker } from "../../../../components/Primitives/Icons";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { useAddressIconStyle } from "../../../../style/address-icon.style";
import { getAddressBaseTextParts } from "../../../../utils/address.util";
import { TEXT_SEPARATOR } from "../../../../utils/constants";
import { EditGroupBaseDataDialog } from "../../../components/group/edit-group/dialog/edit-group-basedata-dialog.component";
import { DetailValueDisplay } from "../../../components/value-display/detail-value-display.component";
import { IAddress } from "../../../domain/address/address";
import { IAddressLean } from "../../../domain/address/address-lean";
import { IGroup } from "../../../domain/group/group";
import { AddressType } from "../../../models/address/address.model";
import { GroupDetailName } from "./group-detail-name.component";

const useStyles = makeStyles((theme: Theme) => ({
  dataContainer: {
    marginTop: 15,
  },
  invoiceAddressContainer: {
    marginTop: 5,
  },
  groupAddressContainer: {
    marginTop: 15,
  },
  boldText: {
    fontWeight: "bold",
  },
  textAlign: {
    textAlign: "center",
  },
}));

interface IGroupDetailBaseInfoProps {
  group: IGroup;
  headerOnly?: boolean;
}

export const GroupDetailBaseInfo: VFC<IGroupDetailBaseInfoProps> = (props) => {
  const { group, headerOnly } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const addressIconClasses = useAddressIconStyle();
  const [isEditOpen, setIsEditOpen] = useState(false);

  const getAddressText = (address: IAddressLean | IAddress) => {
    let textParts: string[] = [];
    if (address.name && address.id) {
      textParts.push(`(${address.id}) ${address.name}`);
    }
    textParts = textParts.concat(getAddressBaseTextParts(address, t));
    return textParts.join(`${TEXT_SEPARATOR} `);
  };

  const getAddressTextComponent = (address: IAddressLean | IAddress) => {
    const description =
      address.type === AddressType.Invoice
        ? t("basedata.addresses.type.invoice")
        : t("basedata.addresses.type.group_location");
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography
            variant="body1"
            className={classNames(classes.boldText, {
              [classes.textAlign]: isMobile,
            })}
          >
            {description}:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className={classNames({
              [classes.textAlign]: isMobile,
            })}
          >
            {getAddressText(address)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleEditClicked = () => {
    setIsEditOpen(true);
  };

  return (
    <>
      <EditGroupBaseDataDialog
        open={isEditOpen}
        group={group}
        onCancel={() => setIsEditOpen(false)}
        onSaved={() => setIsEditOpen(false)}
      />

      <Grid container direction="column">
        <Grid item>
          <GroupDetailName
            name={group.name}
            active={group.active ?? null}
            grsManagedEar={group.grsManagedEar}
            onEditClicked={!headerOnly ? handleEditClicked : undefined}
          />
        </Grid>
        {!headerOnly && (
          <Grid item className={classes.dataContainer}>
            <Grid container direction="row">
              <Grid item xs={12} md={6}>
                <DetailValueDisplay label={t("basedata.groups.edit.erpNumber")} value={group.erpNumber ?? "-"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DetailValueDisplay label={t("basedata.groups.edit.vatId")} value={group.vatId ?? "-"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DetailValueDisplay
                  label={t("basedata.groups.edit.tradeRegisterNumber")}
                  value={group.tradeRegisterNumber ?? "-"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DetailValueDisplay
                  label={t("basedata.groups.edit.groupBranch")}
                  value={group.groupBranch?.name ?? "-"}
                />
              </Grid>
              {group.groupAddress && (
                <Grid item xs={12} className={classes.groupAddressContainer}>
                  <InfoBox
                    iconComponent={
                      <GroupAddressMarker
                        className={addressIconClasses.groupOrInvoiceAddressIcon}
                        viewBox="0 0 28 40"
                      />
                    }
                    labelComponent={getAddressTextComponent(group.groupAddress)}
                    onClick={() => {}}
                    noHover
                  />
                </Grid>
              )}
              {group.invoiceAddress && (
                <Grid item xs={12} className={classes.invoiceAddressContainer}>
                  <InfoBox
                    iconComponent={
                      <InvoiceAddressMarker
                        className={addressIconClasses.groupOrInvoiceAddressIcon}
                        viewBox="0 0 28 40"
                      />
                    }
                    labelComponent={getAddressTextComponent(group.invoiceAddress)}
                    onClick={() => {}}
                    noHover
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
