import { createTheme, DialogClassKey, StyleRules } from "@material-ui/core";

// use intermediate theme to be able to use breakpoints
const theme = createTheme();

export const MuiDialog: Partial<StyleRules<DialogClassKey, {}>> = {
  scrollPaper: {
    backgroundColor: "rgb(0, 97, 72, 0.70)",
  },
  paper: {
    [theme.breakpoints.down("xs")]: {
      margin: 15,
      "& >div:nth-child(2)": {
        padding: 15,
      },
    },
  },
};
