import { StatusCodes } from "http-status-codes";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroup } from "../../../domain/group/group";
import { IUpdateGroupPaymentInfoModel } from "../../../models/group/group-update.model";
import { IGroupModel } from "../../../models/group/group.model";
import { SharedQueryKeys } from "../shared-query-keys";

const updateGroupPaymentInfo = async (id: number, updateData: IUpdateGroupPaymentInfoModel): Promise<IGroup> => {
  const url = replaceUriParameter(RestEndpoints.UpdateGroupPaymentInfo, "id", id);
  const { data } = await getInstance().put<IGroupModel>(url, updateData);
  return GroupConverter.toDomain(data);
};

export const useUpdateGroupPaymentInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation(
    SharedQueryKeys.UpdateGroupPaymentInfo,
    ({ id, updateData }: { id: number; updateData: IUpdateGroupPaymentInfoModel }) =>
      updateGroupPaymentInfo(id, updateData),
    {
      onSuccess: () => {
        enqueueSnackbar(t("general.successfull_saved"), { variant: "success" });
      },
      onError: (error) => {
        const errorMsg = getErrorCode(error);
        const errorCode = getErrorStatusCode(error);
        if (errorCode !== StatusCodes.INTERNAL_SERVER_ERROR) return;
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
