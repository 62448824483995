import { IPomAnnouncementListItem } from "../../../components/announcement-date-picker/pom-announcement-list";
import { IPomAnnouncementListModel } from "../announcements/pom-announcement-list.model";

export class PomAnnouncementListConverter {
  public static toDomain(data: IPomAnnouncementListModel): IPomAnnouncementListItem[] {
    const convertedItems: IPomAnnouncementListItem[] = data.items.map<IPomAnnouncementListItem>((item) => ({
      announcementId: item.announcementId,
      announcementPeriod: item.announcementPeriod,
      status: item.status,
      announcementDate: new Date(item.announcementDate),
    }));

    return convertedItems;
  }
}
