import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import lodash from "lodash";
import { useSnackbar } from "notistack";
import React, { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AppDialog } from "../../../shared/components/dialog/app-dialog.component";
import { useDashboardActionContext } from "../../../shared/pages/dashboard/dashboard-action.provider";
import { useGetGroupContracts } from "../../../shared/repositories/queries/group/get-group-contracts.query";
import { Colors } from "../../../style/Colors";
import { AvailablePomRoutes } from "../../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: Colors.grayDark,
    paddingLeft: 4,
  },
  select: {
    "& .MuiSelect-select:focus": {
      borderRadius: 20,
      backgroundColor: "transparent",
    },
  },
}));
interface IPomTakeBackSystemDialogProps {
  open: boolean;
  onAccept: () => void;
  onCancel: VoidFunction;
  groupId: number;
}

export const PomTakeBackSystemDialog: VFC<IPomTakeBackSystemDialogProps> = (props) => {
  const { open, onAccept, onCancel, groupId } = props;
  const { t } = useTranslation();
  const { contractId, setContractId } = useDashboardActionContext();
  const [systemId, setSystemId] = useState<number | undefined>(contractId);
  const [error, setError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const handleAccept = () => {
    if (systemId === undefined) {
      setError(true);
      return;
    }
    setError(false);
    setContractId(systemId);
    history.push(`${AvailablePomRoutes.Announcement}`);
    onAccept();
  };

  const handleOnLoadError = () => {
    enqueueSnackbar(t("pom.announcements.dialog.takebacksystem.error"), { variant: "error" });
  };

  const handleCancel = () => {
    setSystemId(undefined);
    setError(false);
    onCancel();
  };

  const { isLoading, data: contracts } = useGetGroupContracts(
    groupId,
    { page: 0, pageSize: 1000 },
    () => {},
    handleOnLoadError,
  );

  const takebackSystems = useMemo(() => {
    if (contracts) {
      const systems = contracts.pomContracts.map((contract) => {
        const system = {
          id: contract.id,
          contractNumber: contract.contractNumber,
          active: contract.active,
          takeBackSystemId: contract.contractField.takeBackSystem.id,
          takeBackSystemName: contract.contractField.takeBackSystem.name,
        };
        return system;
      });
      const uniqueSystems = lodash.uniqBy(systems, function (system) {
        return system.id;
      });
      return uniqueSystems;
    }
    return [];
  }, [contracts]);

  return (
    <AppDialog
      open={open}
      title={t("pom.announcements.dialog.title.takebacksystem")}
      onAcceptClick={handleAccept}
      acceptTextOverride={t("pom.announcements.dialog.takebacksystem.ok")}
      onCancelClick={handleCancel}
      maxWidth="sm"
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Grid container>
          <FormControl variant="outlined" fullWidth>
            {!systemId && (
              <InputLabel id="takebacksystem-selection-label" focused={false} shrink={false} className={classes.label}>
                {t("pom.announcements.dialog.placeholder")}
              </InputLabel>
            )}
            <Select
              id="takebacksystem-selection"
              value={systemId ?? ""}
              required={true}
              error={error}
              labelId="takebacksystem-selection-label"
              onChange={(event) => {
                setSystemId(event.target.value as number);
                setError(false);
              }}
              className={classes.select}
            >
              {takebackSystems.map((system) => {
                return (
                  <MenuItem key={system.id} value={system.id}>
                    <Typography>
                      {system.takeBackSystemName} - {system.contractNumber}{" "}
                      {system.active ? t("pom.announcements.dialog.takebacksystem.active") : ""}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
    </AppDialog>
  );
};
