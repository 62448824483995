import { Grid } from "@material-ui/core";
import { useEffect, useMemo, useState, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { WizardStepHeader } from "../../../../collect-from-market/pages/order-new/components/wizard-step-heading.component";
import { AddressType, recyclerAddressTypes } from "../../../../shared/models/address/address.model";
import { getFormattedRoutingAddress } from "../../../../utils/address.util";
import { IAutocompleteOption } from "../../../../utils/AutocompleteHelper";
import { ICreateProductRoutingFormInputs } from "../create-product-routing-content.component";
import { ProductRoutingAddressesSummary } from "../product-routing-addresses-summary.component";
import { emptyOption, ProductRoutingAddressesFormContent } from "./product-routing-addresses-form-content.component";
import { useGetAllAddressesOfType } from "../../../../shared/repositories/queries/address/get-all-addresses-of-type.query";

interface IProductRoutingAddressesFormProps {
  isSummary: boolean;
  onEditClick: VoidFunction;
}

export const ProductRoutingAddressesForm: VFC<IProductRoutingAddressesFormProps> = (props) => {
  const { isSummary, onEditClick } = props;
  const { t } = useTranslation();

  const { isLoading: areLogisticAddressesLoading, data: logisticAddresses } = useGetAllAddressesOfType(
    [AddressType.LogisticLocation],
    {
      page: 0,
      pageSize: 100,
    },
    true,
    true,
  );
  const { isLoading: areRecyclerAddressesLoading, data: recyclerAddresses } = useGetAllAddressesOfType(
    [...recyclerAddressTypes, AddressType.LogisticLocation],
    {
      page: 0,
      pageSize: 100,
    },
    true,
    true,
  );
  const isLoading = useMemo(
    () => areRecyclerAddressesLoading || areLogisticAddressesLoading,
    [areLogisticAddressesLoading, areRecyclerAddressesLoading],
  );

  const [startAddressOptions, setStartAddressOptions] = useState<IAutocompleteOption[]>([]);

  const { watch } = useFormContext<ICreateProductRoutingFormInputs>();

  const primaryStartAddressId: number = watch("primaryStartAddressId");

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined);

  const generateStartAddressOptions = () => {
    if (!logisticAddresses) {
      return [emptyOption];
    }

    const options: IAutocompleteOption[] = [emptyOption];
    const mappedOptions: IAutocompleteOption[] = logisticAddresses
      .filter((a) => a.active)
      .map((address) => {
        return {
          id: address.id.toString(),
          name: getFormattedRoutingAddress(AddressType.LogisticLocation, address, t),
        } as IAutocompleteOption;
      });

    return options.concat(mappedOptions);
  };

  useEffect(() => {
    if (logisticAddresses) {
      const startOptions = generateStartAddressOptions();
      setStartAddressOptions(startOptions);
    }
  }, [logisticAddresses]);

  useEffect(() => {
    if (primaryStartAddressId && logisticAddresses) {
      const primaryAddress = logisticAddresses.find((address) => address.id === primaryStartAddressId);
      if (primaryAddress) {
        setSelectedGroupId(primaryAddress.groupId ?? undefined);
      }
    }
  }, [primaryStartAddressId, logisticAddresses]);

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {!isSummary && (
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <WizardStepHeader text={t("configuration.assignments.create.addressHeading")} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isSummary && !isLoading && logisticAddresses && recyclerAddresses && (
          <Grid item>
            <ProductRoutingAddressesFormContent
              logisticAddresses={logisticAddresses.filter((a) => a.active)}
              recyclerAddresses={recyclerAddresses.filter((a) => a.active)}
              startAddressOptions={startAddressOptions}
              selectedGroupId={selectedGroupId}
              setSelectedGroupId={setSelectedGroupId}
            />
          </Grid>
        )}
        {isSummary && !isLoading && logisticAddresses && recyclerAddresses && (
          <Grid item>
            <ProductRoutingAddressesSummary
              onEditClick={onEditClick}
              startAddresses={logisticAddresses}
              targetAddresses={recyclerAddresses}
              groupId={selectedGroupId}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
