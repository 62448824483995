import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmTruckloadActionType } from "../../../models/truckload/action/truckload-action.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { CfmTruckloadConverter } from "./../../../models/converter/truckload/cfm-truckload.converter";
import { ICfmTruckload } from "../../../../domain/truckload/cfm-truckload";
import { ICfmTruckloadModel } from "../../../models/truckload/cfm-truckload.model";

const cancelTruckload = async (truckloadId: number): Promise<ICfmTruckload> => {
  const action = { type: CfmTruckloadActionType.TruckloadCanceled };
  const { data } = await getInstance().patch<ICfmTruckloadModel>(
    RestEndpoints.UpdateCfmTruckload.replace(":id", truckloadId.toString()),
    action,
  );
  return CfmTruckloadConverter.toDomain(data);
};

export const useCancelTruckloadQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation([CfmQueryKeys.CancelTruckload], cancelTruckload, {
    onSuccess: () => {
      enqueueSnackbar(t("notifications.order_saved_success.message"), {
        variant: "success",
      });
      queryClient.invalidateQueries([CfmQueryKeys.GetOrdersXWithFilterInfinite]);
      queryClient.invalidateQueries([CfmQueryKeys.Truckloads]);
    },
  });
};
