import { makeStyles } from "@material-ui/core";
import { Colors, Shades } from "./Colors";

export const accordionMinHeight = 50;

export const useOrderCardStyle = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: Shades.gray50,
  },
  divider: {
    backgroundColor: Shades.gray20,
  },
  cardHeaderTextExpanded: {
    fontWeight: "bold",
    color: Colors.white,
  },
  expandIcon: {
    color: Colors.greenLight,
    height: 30,
    width: 30,
  },
  expandedIcon: {
    transform: "rotate(90deg)",
    color: Colors.white,
    height: 30,
    width: 30,
  },
  accordionSummaryHeightOnExpand: {
    "&.Mui-expanded": {
      minHeight: accordionMinHeight,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "auto",
    },
  },
  editButtonContainer: {
    height: "100%",
  },
}));
