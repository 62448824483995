import { ComponentType, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IRouterState } from "./router-state";

const ScrollToTop: ComponentType<RouteComponentProps> = ({ children, location: { pathname, state } }): any => {
  useEffect(() => {
    const routerState: IRouterState = state as IRouterState;

    if (routerState?.scrollToTop === false) {
      return;
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
