import { makeStyles } from "@material-ui/core";

export const useAutocompleteStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "6px !important",
  },
  adornment: {
    top: "unset",
  },
  input: {
    "& input": {
      maxWidth: "calc(100% - 55px)",
    },
  },
}));
