import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroup } from "../../../domain/group/group";
import { SharedQueryKeys } from "../shared-query-keys";
import { IGroupModel } from "./../../../models/group/group.model";

const getGroupForDetails = async (id: number): Promise<IGroup> => {
  const url = replaceUriParameter(RestEndpoints.GroupForDetailView, "groupId", id);
  const response = await getInstance().get<IGroupModel>(url);
  return GroupConverter.toDomain(response.data);
};

export const useGetGroupForDetailsQuery = (id: number) => {
  return useQuery([SharedQueryKeys.GetGroupForDetails, id], () => getGroupForDetails(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(id),
  });
};
