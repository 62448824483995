import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors } from "./Colors";

export const useTypographyPillStyles = makeStyles((theme) => ({
  whitePill: {
    backgroundColor: "white",
    color: PrimaryColors.base,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
  grayPill: {
    backgroundColor: Colors.gray,
    color: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
  greenPill: {
    backgroundColor: PrimaryColors.base,
    color: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
  orangePill: {
    backgroundColor: Colors.orange,
    color: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
  redPill: {
    backgroundColor: Colors.red,
    color: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
  accordionExpandedWhitePill: {
    backgroundColor: "white",
    color: `${PrimaryColors.base} !important`,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
  accordionWhitePill: {
    backgroundColor: `${PrimaryColors.base} !important`,
    color: `white !important`,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: "5px",
    fontWeight: "bold",
  },
}));
