import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { usePackageIconAndLabel } from "../../../../collect-from-market/domain/hooks/use-package-icon-and-label";
import { ICfmPackage } from "../../../../collect-from-market/domain/packages/cfm-package";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import { DangerousDeleteIconNoBorderButton } from "../../../../components/Primitives/Buttons";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { Shades } from "../../../../style/Colors";
import { getProductArticleNumberAndName } from "../../product/product.utils";

const useStyles = makeStyles((theme: Theme) => ({
  packageBox: {
    border: "2px solid",
    borderColor: Shades.gray40,
    borderRadius: 5,
    marginBottom: 10,
    padding: 15,
  },
  packageName: {
    fontWeight: "bold",
    marginTop: 2,
  },
  nameContainer: {
    height: "100%",
  },
  iconDimensions: {
    height: 40,
    width: 40,
  },
}));

interface IPackageDialogProps {
  product: ICfmProduct;
  packages: ICfmPackage[];
  open: boolean;
  onCancel: VoidFunction;
  onEdit: VoidFunction;
  onRemove: (pkg: ICfmPackage) => void;
}

export const PackageDialog: VFC<IPackageDialogProps> = (props) => {
  const { product, packages, open, onCancel, onEdit, onRemove } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { getIcon } = usePackageIconAndLabel();

  return (
    <AppDialog
      title={t("configuration.currentPackage")}
      subtitle={getProductArticleNumberAndName(product)}
      onCancelClick={onCancel}
      onAcceptClick={onEdit}
      acceptTextOverride={t("general.edit.text")}
      open={open}
      maxWidth="sm"
    >
      <Grid container direction="column">
        {packages
          .sort((p1, p2) => (p1.name ?? "").localeCompare(p2.name ?? ""))
          .map((pkg) => {
            return (
              <Grid item key={pkg.id} className={classes.packageBox}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>{getIcon(pkg)}</Grid>
                      <Grid item>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.nameContainer}
                        >
                          <Typography variant="body1" className={classes.packageName}>
                            {pkg.name ?? ""}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="center" alignItems="center" className={classes.nameContainer}>
                      <DangerousDeleteIconNoBorderButton
                        className={classes.iconDimensions}
                        onClick={() => onRemove(pkg)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </AppDialog>
  );
};
