import { Grid, GridItemsAlignment, GridJustification, GridSpacing } from "@material-ui/core";
import classNames from "classnames";
import { FC } from "react";
import { useProductRowStyle } from "../../../style/product-row.style";

interface IBoxedRowGrid {
  disabled?: boolean;
  justifyContent?: GridJustification;
  alignItems?: GridItemsAlignment;
  spacing?: GridSpacing;
}

export const BoxedRowGrid: FC<IBoxedRowGrid> = (props) => {
  const { disabled, justifyContent, alignItems, children, spacing } = props;
  const classes = useProductRowStyle();

  return (
    <Grid
      container
      direction="row"
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={classNames(classes.rowContainer, {
        [classes.rowContainerDefault]: !disabled,
        [classes.rowContainerExistingProduct]: disabled,
      })}
      spacing={spacing}
    >
      {children}
    </Grid>
  );
};
