import { Grid } from "@material-ui/core";
import { useState, VFC } from "react";
import { ProductConfigurationFilter } from "./product-config-filter.component";
import { CreatableProductsList } from "./product-list/creatable-products-list.component";
import { EMPTY_PRODUCT_CONFIG_FILTER, IProductConfigFilter } from "./product.utils";

export const CreateProduct: VFC = () => {
  const [createProductFilter, setCreateProductFilter] = useState<IProductConfigFilter>(EMPTY_PRODUCT_CONFIG_FILTER);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <ProductConfigurationFilter
          productConfigFilter={createProductFilter}
          setProductConfigFilter={setCreateProductFilter}
          displayProductCountText
        />
      </Grid>
      <Grid item xs={12}>
        <CreatableProductsList
          createProductFilter={createProductFilter}
          setCreateProductFilter={setCreateProductFilter}
        />
      </Grid>
    </Grid>
  );
};
