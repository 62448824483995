import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmCancelationReason } from "../../../../domain/order-x/cfm-cancelation-reason";
import { ICfmCancelationReasonModel } from "../../../models/order-x/cfm-cancelation-reason.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getCancelationReasons = async (): Promise<ICfmCancelationReason[]> => {
  const { data } = await getInstance().get<ICfmCancelationReasonModel[]>(RestEndpoints.CfmCancelationReasons);
  return data;
};

export const useGetCancelationReasonsQuery = () => {
  return useQuery([CfmQueryKeys.GetCancelationReasons], () => getCancelationReasons(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
