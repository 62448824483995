import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CfmProductArticleType } from "../../../../../../configurator/components/product/product.utils";
import { CfmOrderActionType } from "../../../../../domain/order-x/actions/cfm-order-action-type";
import { ICfmOrderAvailableAction } from "../../../../../domain/order-x/actions/cfm-order-available-action";
import { ICfmOrderBulkAction } from "../../../../../domain/order-x/actions/cfm-order-bulk-action";
import {
  isLogisticBulkDeliveryReportStatus,
  isLogisticBulkUpdateStatus,
} from "../../../../../domain/order-x/cfm-order-x.status";
import { useGetOrderXBulkActions } from "../../../../../repositories/queries/order-x/queries/get-order-x-bulk-actions.query";
import { useGetOrderXDeliveryReport } from "../../../../../repositories/queries/order-x/queries/get-order-x-delivery-report.query";
import { ORDER_OVERVIEW_DEFAULT_PAGE_SIZE, useOrderXOverviewContext } from "../../../order-x-overview.provider";
import { getTitleForAction } from "../../actions/order-x-action.util";
import { getLogisticBulkActionForActionType, getLogisticBulkActionTypes } from "../order-bulk-action.util";

export enum LogisticBulkAction {
  DeliveryReport = "DeliveryReport",
  LogisticAccepted = "LogisticAccepted",
  ChangedPlannedPickup = "ChangedPlannedPickup",
  LogisticAnnounced = "LogisticAnnounced",
  LogisticPerformed = "LogisticPerformed",
  LogisticMistrip = "LogisticMistrip",
  LogisticAnnouncedSingleOrder = "LogisticAnnouncedSingleOrder",
}

export interface IBulkAction {
  action: LogisticBulkAction;
  label: string;
  disabled: boolean;
  isLoading: boolean;
  tooltip?: string;
}

interface IOrderBulkActionProvider {
  selectedOrderIds: number[];
  setSelectedOrderIds: (ids: number[]) => void;
  handleSelectOrder: (checked: boolean, id: number) => void;
  selectAllOrders: (checked: boolean) => void;
  isSelectAll: boolean;
  resetSelectedOrders: VoidFunction;
  selectedActionIndex: number | undefined;
  setSelectedActionIndex: (index: number | undefined) => void;
  resetBulkActions: VoidFunction;
  pdfContent: string | undefined;
  cfmOrderBulkActions: ICfmOrderBulkAction | undefined;
  bulkActions: IBulkAction[];
  isLoading: boolean;
  getDeliveryReport: () => Promise<string>;
  validateAvailableOrderActions: () => Promise<void>;
  currentBulkAction: ICfmOrderAvailableAction | undefined;
  setCurrentBulkAction: (bulkAction: ICfmOrderAvailableAction | undefined) => void;
}

export const OrderBulkActionContext = createContext<IOrderBulkActionProvider>({} as IOrderBulkActionProvider);

export const useOrderBulkActionContext = () => {
  return useContext(OrderBulkActionContext);
};

const useOrderBulkActionProvider = (): IOrderBulkActionProvider => {
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectedActionIndex, setSelectedActionIndex] = useState<number | undefined>(undefined);
  const [currentBulkAction, setCurrentBulkAction] = useState<ICfmOrderAvailableAction | undefined>(undefined);
  const shouldRefetchBulkValidation = useRef<boolean>(true);

  const { t } = useTranslation();

  const { orders, setPageSize, pageSize, curFilter, hasNextPage, fetchNextPage } = useOrderXOverviewContext();

  const {
    mutateAsync: getDeliveryReport,
    data: pdfContent,
    isLoading: pdfLoading,
  } = useGetOrderXDeliveryReport(selectedOrderIds);

  const {
    data: cfmOrderBulkActions,
    isLoading: bulkActionLoading,
    refetch: refetchBulkActions,
  } = useGetOrderXBulkActions(selectedOrderIds, false);

  const isLoading = pdfLoading || bulkActionLoading;

  const hasSingleOrders = useMemo(() => {
    const allItems = orders.filter((order) => selectedOrderIds.includes(order.id)).flatMap((o) => o.items);
    return allItems.some(
      (item) => item.product && item.product.articleType === CfmProductArticleType.SingleOrderProduct,
    );
  }, [orders, selectedOrderIds]);

  const hasDefaultOrders = useMemo(() => {
    const allItems = orders.filter((order) => selectedOrderIds.includes(order.id)).flatMap((o) => o.items);
    return allItems.some((item) => item.product && item.product.articleType === CfmProductArticleType.Product);
  }, [orders, selectedOrderIds]);

  // Reset of order selection on order status filter change
  useEffect(() => {
    selectAllOrders(false);
    setPageSize(ORDER_OVERVIEW_DEFAULT_PAGE_SIZE);
  }, [curFilter]);

  // Order refetch with max orders on select all for bulk actions
  useEffect(() => {
    // Load max 100 orders for bulk
    if (isSelectAll && orders.length < 100 && pageSize && hasNextPage) {
      fetchNextPage();
    }
  }, [isSelectAll, pageSize]);

  // Reset action or refetch data on order selection and deselection
  useEffect(() => {
    if (selectedOrderIds.length === 0) {
      setSelectedActionIndex(undefined);
      return;
    }
    shouldRefetchBulkValidation.current = true;
  }, [selectedOrderIds]);

  const isUpdatePossible = (type: CfmOrderActionType) => {
    // single orders and default orders can never be mixed for bulk actions
    if (hasSingleOrders && hasDefaultOrders) {
      return false;
    }

    return (
      cfmOrderBulkActions?.availableOrderBulks[0]?.availableActions.find((action) => action.type === type) !== undefined
    );
  };

  // Get title for bulkupdate to display bulkupdate option disabled in ui
  const isBulkUpdate = useMemo(() => {
    return cfmOrderBulkActions && isLogisticBulkUpdateStatus(curFilter.statusFilter);
  }, [cfmOrderBulkActions, curFilter]);

  const bulkActions: IBulkAction[] = useMemo(() => {
    const actions = [];
    if (isLogisticBulkDeliveryReportStatus(curFilter.statusFilter)) {
      actions.push({
        action: LogisticBulkAction.DeliveryReport,
        label: t("order.print_delivery_note.button"),
        disabled: selectedOrderIds.length === 0,
        isLoading,
      });
    }
    if (isBulkUpdate) {
      const bulkActions = getLogisticBulkActionTypes(curFilter.statusFilter, hasSingleOrders);
      for (const action of bulkActions) {
        const actionType = getLogisticBulkActionForActionType(action);
        if (!actionType) continue;

        actions.push({
          action: actionType,
          label: getTitleForAction(action, t),
          disabled:
            bulkActionLoading || (!isUpdatePossible(action) && actionType !== LogisticBulkAction.LogisticAccepted),
          isLoading,
          tooltip: t("order.bulk_update.tooltip"),
        });
      }
    }
    return actions;
  }, [t, selectedOrderIds, curFilter, isLoading, cfmOrderBulkActions, currentBulkAction]);

  const handleSelectOrder = (checked: boolean, id: number) => {
    if (checked) {
      setSelectedOrderIds(selectedOrderIds.concat([id]));
    } else {
      setSelectedOrderIds(selectedOrderIds.filter((orderId) => orderId !== id));
    }
  };

  const selectAllOrders = (checked: boolean) => {
    if (!checked) {
      setIsSelectAll(false);
      setSelectedOrderIds([]);
      setPageSize(ORDER_OVERVIEW_DEFAULT_PAGE_SIZE);
      return;
    }
    // Set pageSize to max order bulkoperation size to avoid multiple api calls
    setPageSize(100);
    setIsSelectAll(true);
  };

  const resetSelectedOrders = () => {
    setSelectedOrderIds([]);
  };

  const resetBulkActions = () => {
    resetSelectedOrders();
    setSelectedActionIndex(undefined);
  };

  const validateAvailableOrderActions = async () => {
    if (shouldRefetchBulkValidation) {
      refetchBulkActions();
      shouldRefetchBulkValidation.current = false;
    }
  };

  return {
    selectedOrderIds,
    setSelectedOrderIds,
    handleSelectOrder,
    selectAllOrders,
    isSelectAll,
    resetSelectedOrders,
    selectedActionIndex,
    setSelectedActionIndex,
    resetBulkActions,
    pdfContent,
    cfmOrderBulkActions,
    currentBulkAction,
    setCurrentBulkAction,
    bulkActions,
    isLoading,
    getDeliveryReport,
    validateAvailableOrderActions,
  };
};

export const OrderBulkActionProvider: React.FC = (props) => {
  const value = useOrderBulkActionProvider();
  return <OrderBulkActionContext.Provider value={value}>{props.children}</OrderBulkActionContext.Provider>;
};
