import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState, VFC } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { FormTextField } from "../../../../../shared/components/form/fields/form-text.field";
import { useCustomForm } from "../../../../../shared/util/form.util";

export interface IOrderXFreshdeskFormInputs {
  contactMail: string;
  text: string;
}

interface IFreshdeskFormProps {
  subject: string;
  isOpen: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<IOrderXFreshdeskFormInputs>;
  disableSuccessNotification?: boolean;
}

export const FreshdeskForm: VFC<IFreshdeskFormProps> = (props) => {
  const { isOpen, subject, onCancel, onSubmit, disableSuccessNotification } = props;
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const initValues = {
    contactMail: internalUser?.deliveryEmail,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
  } = useCustomForm<IOrderXFreshdeskFormInputs>({ defaultValues: initValues });

  // @ts-ignore
  const contactMail: string | undefined = watch("contactMail");
  const text: string | undefined = watch("text");

  useEffect(() => {
    if (!isOpen || !submitClicked) return;

    if (contactMail) {
      clearErrors("contactMail");
    } else {
      setError("contactMail", { message: "Contact mail must not be undefined", type: "validate" });
    }
  }, [contactMail, setError, clearErrors, isOpen, submitClicked]);

  useEffect(() => {
    if (!isOpen || !submitClicked) return;

    if (text) {
      clearErrors("text");
    } else {
      setError("text", { message: "Text must not be undefined", type: "validate" });
    }
  }, [text, setError, clearErrors, isOpen, submitClicked]);

  const handleClose = () => {
    setSubmitClicked(false);
    reset();
    onCancel();
  };

  const handleOkClick = async () => {
    if (errors.contactMail || errors.text || !contactMail || !text) return;
    try {
      setIsSubmitting(true);
      await handleSubmit(onSubmit)();
      if (!disableSuccessNotification) {
        enqueueSnackbar(t("order_view.freshdesk_support_successful"), { variant: "success" });
      }
      handleClose();
    } catch (error) {
      enqueueSnackbar(t("order_view.freshdesk_support_error"), { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppDialog
      title={t("general.grs_support")}
      subtitle={subject}
      onCancelClick={handleClose}
      onAcceptClick={() => {
        setSubmitClicked(true);
        handleOkClick();
      }}
      open={isOpen}
      isLoading={isSubmitting}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.contactMail)}
            label={t("order_view.freshdesk_support_contact")}
            required={true}
            control={control}
            name={"contactMail"}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.text)}
            label={t("order_view.freshdesk_support_message")}
            required={true}
            multiline
            minRows={10}
            control={control}
            name={"text"}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
    </AppDialog>
  );
};
