import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ReactNode, FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CancelButton, CfmPlatformLogo } from "../../../../components/Primitives";
import { useOrderXOverviewContext } from "../order-x-overview.provider";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
  resetButton: {
    border: "none",
  },
});

export const OrderOverviewEmptyList: FC = () => {
  const classes = useStyles();

  const { curFilter, resetFilter } = useOrderXOverviewContext();
  const { t } = useTranslation();

  const getEmptyText = (): string | ReactNode => {
    if (curFilter?.filter?.query) {
      // Trans is used because it can interpret the <strong> tag in the translation
      return <Trans i18nKey="order.noDataInStatusWithSearchResult">{{ search: curFilter.filter.query }}</Trans>;
    } else {
      return t("order.noDataInStatus");
    }
  };

  const classNames = [classes.resetButton, "mt-3"].join(" ");

  return (
    <Grid item xs={12}>
      <Grid container direction="row" justifyContent="center">
        <Grid item className={classes.alignCenter}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CfmPlatformLogo />
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>{getEmptyText()}</Typography>
              {curFilter?.filter?.query && (
                <CancelButton className={classNames} onClick={() => resetFilter()}>
                  {t("order.resetSearchResult")}
                </CancelButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
