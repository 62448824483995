import { Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { PomPlatformLogo } from "../../../../components/Primitives/Icons";
import { usePlatformIconStyle } from "../../../../style/platform-icon.style";
import { IGroup } from "../../../domain/group/group";
import { GroupType } from "../../../domain/group/group-type";
import { IconFormHeader } from "../../../components/form/icon-form-header.component";
import { DetailValueDisplay } from "../../../components/value-display/detail-value-display.component";

const useStyles = makeStyles((theme: Theme) => ({
  dataContainer: {
    marginTop: 15,
  },
  headerMargin: {
    marginBottom: 20,
  },
}));

interface IGroupDetailPomInfoProps {
  group: IGroup;
}

export const GroupDetailPomInfo: VFC<IGroupDetailPomInfoProps> = (props) => {
  const { group } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const platformIconClasses = usePlatformIconStyle();

  return (
    <Grid container direction="column" className={classes.dataContainer}>
      <Grid item className={classes.headerMargin}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <IconFormHeader
              text={t("basedata.group.pom_heading")}
              icon={<PomPlatformLogo className={platformIconClasses.pomPlatformDetailsIcon} />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            <DetailValueDisplay label={t("basedata.groups.edit.agencyGroup")} value={group.agencyGroup?.name ?? "-"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailValueDisplay
              label={t("basedata.groups.edit.EARNumber")}
              value={group.earNumber ? group.earNumber : "-"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailValueDisplay
              label={t("basedata.groups.edit.EARTemporaryNumber")}
              value={group.earTemporaryNumber ? group.earTemporaryNumber : "-"}
            />
          </Grid>
          {group.type === GroupType.PomAgency && (
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.edit.isPomAgencyAnnouncer")}
                value={group.isPomAgencyAnnouncer ? t("basedata.general.yes.text") : t("basedata.general.no.text")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
