import { Box, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { GRSHeaderLogo } from "../../shared/components/grs-header-logo";

interface IHeaderProps {}

export const Header: FunctionComponent<IHeaderProps> = (props) => {
  return (
    <header style={{ position: "sticky", top: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid style={{ display: "flex" }} item xs={6}>
              <Box py={2}>
                <GRSHeaderLogo />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </header>
  );
};
