import { Box, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import classNames from "classnames";
import { useMemo, VFC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OrderCheckbox } from "../../../../../../components/checkbox/order-checkbox.component";
import { ArticleNumberBox } from "../../../../../../configurator/components/product/article-number-box.component";
import { formatDateShort } from "../../../../../../shared/util/date.util";
import { Colors, Shades } from "../../../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../../../utils/constants";
import { ICfmOrderX } from "../../../../../domain/order-x/cfm-order-x";
import { getPackageQuantityInformation, getPackagesFromItems } from "../../../utils/order-x-card.util";

const useStyles = makeStyles((theme: Theme) => ({
  orderContainer: {
    backgroundColor: Colors.grayLight,
    padding: 10,
    "&:hover": {
      backgroundColor: Shades.gray20,
      cursor: "pointer",
    },
  },
  orderContainerDark: {
    backgroundColor: Shades.gray20,
    padding: 10,
    "&:hover": {
      backgroundColor: Shades.gray30,
      cursor: "pointer",
    },
  },
  container: {
    height: "100%",
  },
  dateText: {
    color: Shades.gray60,
  },
  checkBox: {
    minWidth: 60,
  },
  mobileProductNameSpacing: {
    [theme.breakpoints.down(910)]: {
      marginLeft: 7,
    },
  },
  mobileAddressNameSpacing: {
    [theme.breakpoints.down(682)]: {
      marginLeft: 7,
    },
  },
}));

interface ILogisticWarehouseOrderRowProps {
  order: ICfmOrderX;
  disableSelection?: boolean;
  disabledSelectionHint?: string;
  onCheckboxChange: (orderId: number, checked: boolean) => void;
  isSelected: (orderId: number) => boolean;
  darkTheme?: boolean;
}

export const LogisticWarehouseOrderRow: VFC<ILogisticWarehouseOrderRowProps> = (props) => {
  const { order, onCheckboxChange, isSelected, darkTheme, disableSelection = false, disabledSelectionHint } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const displayCheckboxSpacing = useMediaQuery(theme.breakpoints.down(910));

  const productItem = useMemo(() => order.items.find((item) => item.product), [order]);
  const packageItem = useMemo(() => getPackagesFromItems(order.items)[0], [order]);
  const customerAddress = useMemo(() => order.customerAddress, [order]);

  const getAddressText = () => {
    if (!customerAddress) return "";
    return `${customerAddress.id} ${customerAddress.name}, ${customerAddress.postal}, ${customerAddress.city}`;
  };

  return (
    <Box className={classNames({ [classes.orderContainer]: !darkTheme, [classes.orderContainerDark]: darkTheme })}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item className={classes.checkBox}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <OrderCheckbox
                  disabled={disableSelection}
                  disabledHint={disabledSelectionHint}
                  onChange={(checked) => {
                    onCheckboxChange(order.id, checked);
                  }}
                  size={"large"}
                  checked={isSelected(order.id)}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <ArticleNumberBox articleNumber={t("order.orderId", { id: order.id })} />
              </Box>
            </Grid>
            <Grid item className={classes.mobileAddressNameSpacing}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <Typography variant="body1" className={classes.dateText}>
                  {order.actualDeliveryDate ? formatDateShort(order.actualDeliveryDate) : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item className={classes.mobileAddressNameSpacing}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <Typography variant="body1">{getAddressText()}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.mobileProductNameSpacing}>
          <Grid container direction="row" spacing={2}>
            {!displayCheckboxSpacing && <Grid item className={classes.checkBox} />}
            <Grid item>
              <Typography variant="body1">{productItem?.product?.name ?? "-"}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Trans i18nKey="order.weight">{{ weight: order.warehouseWeight ?? "0" }}</Trans>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row">
          {!displayCheckboxSpacing && <Grid item className={classes.checkBox} />}
          <Grid item>
            <Typography variant="body2">{packageItem?.packageName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {TEXT_SEPARATOR} {getPackageQuantityInformation(t, packageItem?.quantityToCollect ?? undefined)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
