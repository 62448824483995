import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getRedirectToCreateRoutingUrl } from "../../../components/assignment-routing/assignment-products.util";
import { RoutingConfigSmallIcon } from "../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../style/Colors";
import { ProductOverviewGeneralFilter } from "../product/components/product-overview.utils";
import { ProductListFilter, ProductListFilterKey } from "../../components/product/product-list-filter.component";
import { DefaultProductList } from "../../components/product/product-list/default-product-list.component";
import { PackageOverviewProvider } from "../package/package-overview.provider";
import { ProductOverviewProvider } from "../product/product-overview.provider";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 20,
    marginBottom: 30,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
    marginTop: 5,
  },
}));

export const ProductsForRoutingPage: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const onContinue = (selectedProductIds: number[]) => {
    const url = getRedirectToCreateRoutingUrl(selectedProductIds);
    history.push(url);
  };

  return (
    <PackageOverviewProvider>
      <ProductOverviewProvider>
        <Grid container direction="column">
          <Grid item className={classes.heading}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <RoutingConfigSmallIcon viewBox="0 0 25 25" className={classes.iconStyle} />
              </Grid>
              <Grid item>
                <Typography variant="h4">{t("configuration.createNewRouting")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ProductListFilter
              withoutRoutingTextOverride={t(
                `configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutRouting}.routingOverview`,
              )}
              hidden={[
                ProductListFilterKey.TagWithoutPackage,
                ProductListFilterKey.TagInactiveProducts,
                ProductListFilterKey.SortingDate,
                ProductListFilterKey.SortingArticleNumber,
                ProductListFilterKey.SortingRegion,
              ]}
            />
          </Grid>
          <Grid item>
            <DefaultProductList onContinue={onContinue} onContinueText={t("configuration.toCreateRoutingConfig")} />
          </Grid>
        </Grid>
      </ProductOverviewProvider>
    </PackageOverviewProvider>
  );
};
