import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { ICfmCreateResponseField } from "../../../domain/routing-assignment/cfm-response-field";
import { CfmQueryKeys } from "../cfm-query-keys";
import { handleError } from "./update-response-field.query";

const createNewResponseField = async (responseField: ICfmCreateResponseField): Promise<void> => {
  await getInstance().post(RestEndpoints.ResponseFields, { ...responseField });
};

export const useCreateNewResponseFieldQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    CfmQueryKeys.CreateNewResponseField,
    (responseField: ICfmCreateResponseField) => createNewResponseField(responseField),
    {
      onError: (e) => {
        handleError(e, enqueueSnackbar, t);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(CfmQueryKeys.GetAllResponseFieldsInfinite);
      },
    },
  );
};
