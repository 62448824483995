import { PAGE_NOT_FOUND } from "utils/constants";
import { INavigationItem } from "./navigation-item";

export default function getBreadcrumb(path: string, items: INavigationItem[] = []): INavigationItem[] {
  for (const { content: outerContent = [] } of items) {
    if (outerContent.length) {
      for (const {
        label = "",
        to = "",
        description = "",
        content: innerContent = [],
        visible = false,
      } of outerContent) {
        if (innerContent.length) {
          const tempCrumb = getBreadcrumb(path, [{ content: [...innerContent], label: "", to: "/", visible: false }]);
          if (tempCrumb.find((x) => x.label !== PAGE_NOT_FOUND)) {
            return [{ label, to, description, visible }, ...tempCrumb];
          }
        }
        if (to === path) {
          return [{ label, to, description, visible }];
        }
      }
    }
  }

  return [{ label: PAGE_NOT_FOUND, to: "/", visible: false }];
}
