import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { IAddressLean } from "../../domain/address/address-lean";
import { AddressType } from "../../models/address/address.model";
import { useGetGroupAddresses } from "../../repositories/queries/address/get-group-addresses.query";
import { getFormattedDomainAddress } from "../../../utils/address.util";
import { AutocompleteFilter } from "../filter/autocomplete-filter.component";

interface IAddressFilterProps {
  handleAddressChanged: (address: IAddressLean | null) => void;
  addressTypes: AddressType[];
}

export const AddressFilter: VFC<IAddressFilterProps> = (props) => {
  const { handleAddressChanged, addressTypes } = props;
  const { internalUser } = useAuthContext();
  const { t } = useTranslation();
  const { isLoading: areAddressesLoading, data: addressesResult } = useGetGroupAddresses(
    internalUser?.group?.id,
    undefined,
    addressTypes,
    internalUser?.group?.id !== undefined,
  );

  const getAddressOptionLabel = (option: IAddressLean) => {
    return getFormattedDomainAddress(option, t);
  };

  return (
    <AutocompleteFilter
      handleFilterChanged={handleAddressChanged}
      getOptionLabel={getAddressOptionLabel}
      placeholder={t("orders.destinationReceivedWarehouse.filter.logisticAddress")}
      options={addressesResult?.addresses ?? []}
      isLoading={areAddressesLoading}
    />
  );
};
