import { AddressConverter } from "../../../../../shared/domain/converter/address.converter";
import { ITruckloadPerformedInputs } from "../../../../components/truckload/dialog/truckload-performed-dialog.component";
import { ICfmCreateTruckload } from "../../../../domain/truckload/cfm-create-truckload";
import { ICfmTruckload } from "../../../../domain/truckload/cfm-truckload";
import { ICreateTruckloadInputs } from "../../../../pages/order-x/components/dialog/logistic-warehouse-dialog.component";
import { CfmTruckloadActionTypeModel } from "../../truckload/action/cfm-truckload-action.model";
import { ICfmCreateTruckloadModel } from "../../truckload/cfm-create-truckload.model";
import { ICfmTruckloadModel } from "../../truckload/cfm-truckload.model";
import { IUpdateTruckloadInfoAndOrdersModel } from "../../truckload/cfm-update-truckload.model";
import { CfmOrderXActionConverter } from "../order-x/cfm-order-x-action.converter";
import { CfmOrderXConverter } from "./../order-x/cfm-order-x.converter";
import { formatDateYearMonthDay } from "../../../../../shared/util/date.util";
import { ICfmTruckloadWithOrdersModel } from "../../truckload/cfm-truckload-with-orders.model";
import { ICfmTruckloadWithOrders } from "../../../../domain/truckload/cfm-truckload-with-orders";
import { TruckloadState } from "../../../../domain/truckload/truckload-state";

export class CfmTruckloadConverter {
  public static toDomain(model: ICfmTruckloadModel): ICfmTruckload {
    return {
      id: model.id,
      startAddress: AddressConverter.toDomain(model.startAddress),
      plannedDeliveryDate: model.plannedDeliveryDate,
      targetAddress: model.targetAddress ? AddressConverter.toDomain(model.targetAddress) : undefined,
      actualDeliveryDate: model.actualDeliveryDate,
      orderCount: model.orderCount,
      status: model.status,
      calculatedWeight: model.calculatedWeight,
      actualWeight: model.actualWeight,
      draftCreatedAt: model.draftCreatedAt,
      submittedAt: model.submittedAt,
      doneAt: model.doneAt,
      updatedAt: model.updatedAt,
    };
  }

  public static withOrdersToDomain(model: ICfmTruckloadWithOrdersModel): ICfmTruckloadWithOrders {
    return {
      id: model.id,
      startAddress: AddressConverter.toDomain(model.startAddress),
      plannedDeliveryDate: model.plannedDeliveryDate,
      targetAddress: model.targetAddress ? AddressConverter.toDomain(model.targetAddress) : undefined,
      actualDeliveryDate: model.actualDeliveryDate,
      orders: model.orders.map(CfmOrderXConverter.toDomain),
      status: model.status,
      calculatedWeight: model.calculatedWeight,
      actualWeight: model.actualWeight,
      draftCreatedAt: model.draftCreatedAt,
      submittedAt: model.submittedAt,
      doneAt: model.doneAt,
      updatedAt: model.updatedAt,
    };
  }

  public static toCreateDomain(
    startAddressId: number,
    status: TruckloadState,
    orderIds: number[],
    inputs: ICreateTruckloadInputs,
  ): ICfmCreateTruckload {
    const serviceDates = CfmOrderXActionConverter.actionServiceDatesToModel(inputs.serviceDatesForOrders);

    return {
      startAddressId: startAddressId,
      targetAddressId: inputs.targetRecyclerAddressId,
      plannedDeliveryDate: inputs.plannedDeliveryDate,
      status,
      orderIds,
      serviceDatesForOrders: serviceDates,
    };
  }

  public static toConfirmedUpdatedRequest(
    inputs: ITruckloadPerformedInputs,
    truckloadId: number,
  ): IUpdateTruckloadInfoAndOrdersModel {
    const serviceDates = CfmOrderXActionConverter.actionServiceDatesToModel(inputs.serviceDates);
    return {
      actualWeight: inputs.actualWeight,
      actualDeliveryDate: inputs.actualDeliveryDate ? formatDateYearMonthDay(inputs.actualDeliveryDate) : undefined,
      type: CfmTruckloadActionTypeModel.TruckloadConfirmed,
      truckloadId,
      serviceDatesForOrders: serviceDates,
    };
  }

  public static createDataToModel(data: ICfmCreateTruckload): ICfmCreateTruckloadModel {
    return {
      startAddressId: data.startAddressId,
      targetAddressId: data.targetAddressId,
      plannedDeliveryDate: data.plannedDeliveryDate,
      status: data.status,
      orderIds: data.orderIds,
      serviceDatesForOrders: data.serviceDatesForOrders,
    };
  }
}
