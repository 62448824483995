import { TranslateFunc } from "../../../../utils/translation.utils";
import { IUser } from "../../../../shared/domain/user/user";
import { ICfmItemX } from "../../../domain/order-x/cfm-item-x";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { CfmActions } from "../components/card-information/order-x-card-information.component";
import { IOrderXCardPackageInformation } from "../components/package-information/order-x-card-package-information.component";
import { getStatusXName } from "../components/status-filter/status-x-util";

export const ORDER_STATUS_ALL = "all";

export const getCurrentStatusName = (order: ICfmOrderX, internalUser: IUser | undefined, t: TranslateFunc): string => {
  if (order === undefined || !internalUser) return "";
  return getStatusXName(order.status, t, internalUser.group?.type, undefined, order.truckloadId !== null);
};

export const getPackagesFromItems = (items: ICfmItemX[]): IOrderXCardPackageInformation[] => {
  // Currently each action for a package is it`s own package, but we want to display
  // only one with the delivery/collection quantities
  const packageItems: IOrderXCardPackageInformation[] = [];
  items
    .filter((item) => item.package)
    .forEach((item) => {
      const index = packageItems.findIndex((p) => p.id === item.package?.id);

      if (index === -1) {
        packageItems.push({
          id: item.package?.id,
          packageName: item.package?.name,
          quantityToDeliver: item.action?.id === CfmActions.Delivery ? item.quantity : undefined,
          quantityToCollect: item.action?.id === CfmActions.Pickup ? item.quantity : undefined,
        });
        return;
      }

      const packageItem = packageItems[index];
      if (item.action?.id === CfmActions.Delivery) {
        if (!packageItem.quantityToDeliver) packageItem.quantityToDeliver = 0;
        packageItem.quantityToDeliver += item.quantity;
      }

      if (item.action?.id === CfmActions.Pickup) {
        if (!packageItem.quantityToCollect) packageItem.quantityToCollect = 0;
        packageItem.quantityToCollect += item.quantity;
      }
    });

  return packageItems;
};

export const getPackageQuantityInformation = (
  t: TranslateFunc,
  quantityToCollect?: number,
  quantityToDeliver?: number,
): string | undefined => {
  if (quantityToCollect !== undefined && quantityToDeliver !== undefined) {
    return t("orders.new.wizard.submit.toCollectAndToDeliver", {
      collect: quantityToCollect,
      deliver: quantityToDeliver,
    });
  }
  if (quantityToCollect !== undefined) {
    return t("orders.new.wizard.submit.toCollect", {
      collect: quantityToCollect,
    });
  }
  if (quantityToDeliver !== undefined) {
    return t("orders.new.wizard.submit.toDeliver", {
      deliver: quantityToDeliver,
    });
  }
};

// Moved from useTranslation
export enum DateResponseFields {
  OrderDate = "order_date",
  RequestedPickupDate = "requested_pickup_date",
  ServicePickupDate = "service_pickup_date",
  PlannedDeliveryDate = "planned_delivery_date",
  ServiceDeliveryDate = "service_delivery_date",
  ServiceRecyclingDate = "service_recycling_date",
  PlannedPickupDate = "planned_pickup_date",
  RequestedDeliveryDate = "requested_delivery_date",
  CanceledAtDate = "cancelled_at",
}

export const getDateFieldHeader = (fieldName: string, t: TranslateFunc) => {
  switch (fieldName) {
    case DateResponseFields.OrderDate:
      return t("order.column_name.order_date.text");
    case DateResponseFields.RequestedPickupDate:
      return t("order.column_name.requested_pickup_date.text");
    case DateResponseFields.ServicePickupDate:
      return t("order.column_name.service_pickup_date.text");
    case DateResponseFields.PlannedDeliveryDate:
      return t("order.column_name.planned_delivery_date.text");
    case DateResponseFields.ServiceDeliveryDate:
      return t("order.column_name.service_delivery_date.text");
    case DateResponseFields.ServiceRecyclingDate:
      return t("order.column_name.service_recycling_date.text");
    case DateResponseFields.PlannedPickupDate:
      return t("order.column_name.planned_pickup_date.text");
    case DateResponseFields.RequestedDeliveryDate:
      return t("order.column_name.requested_delivery_date.text");
    case DateResponseFields.CanceledAtDate:
      return t("order.column_name.cancelled_at.text");
    default:
      return "";
  }
};
