import { Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { PrimaryColors } from "../../../../style/Colors";
import { BoxedRowGrid } from "../../grid/boxed-row-grid.component";
import { ArticleNumberBox } from "../article-number-box.component";
import { ProductName } from "../product-name.component";

const useStyles = makeStyles((theme: Theme) => ({
  packageIcon: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
  },
  container: { height: "100%" },
  badgeContainer: { marginRight: 40, marginLeft: 10 },
  checkboxContainer: {
    marginLeft: 15,
  },
}));

interface IDefaultProductRowProps {
  product: ICfmProduct;
  onProductCheckboxClick: (productId: number, checked: boolean) => void;
  isSelected: boolean;
}

export const DefaultProductRow: VFC<IDefaultProductRowProps> = (props) => {
  const { product, onProductCheckboxClick, isSelected } = props;
  const classes = useStyles();

  const onCheckboxChange = (checked: boolean) => {
    onProductCheckboxClick(product.id, checked);
  };

  return (
    <BoxedRowGrid justifyContent="space-between">
      <Grid item>
        <Grid container direction="column" justifyContent="center" className={classes.container}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item className={classes.checkboxContainer}>
                <OrderCheckbox onChange={onCheckboxChange} checked={isSelected} size="large" />
              </Grid>
              <Grid item>
                <Grid container direction="column" justifyContent="center" className={classes.container}>
                  <Grid item>
                    <ArticleNumberBox articleNumber={product.articleNumber} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" justifyContent="center" className={classes.container}>
                  <Grid item>
                    <ProductName name={product.name} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BoxedRowGrid>
  );
};
