import { IMutateUserFormInputs } from "../../components/user/create-user-form/create-user-form.component";
import { IUserModel } from "../../models/user/user.model";
import { PhoneNumberUtil } from "../../util/phone-number.util";
import { IMutateUserRequest, UserUpdateRequest } from "../requests/user-create.request";
import { IUser, IUserLoginAs } from "../user/user";
import { UserTypeLevel } from "../user/user-type-level";
import { AddressLeanConverter } from "./address-lean.converter";
import { AddressConverter } from "./address.converter";
import { GroupConverter } from "./group.converter";

export class UserConverter {
  public static toModel(user: IUser): IUserModel {
    return {
      id: user.id,
      uuid: user.uuid,
      userTypeLevel: user.userTypeLevel,
      deliveryEmail: user.deliveryEmail,
      active: user.active ?? null,
      firstName: user.firstName,
      fullName: user.fullName,
      lastName: user.lastName,
      group: user.group ? GroupConverter.toModel(user.group) : undefined,
      email: user.email,
      language: user.language,
      mustResetPassword: user.mustResetPassword ?? false,
      telephone: PhoneNumberUtil.transformPhoneNumber(user.telephone),
      title: user.title,
      externalId: user.externalId ?? "-",
      jobTitle: user.jobTitle ?? "-",
      notify: user.notify,
      rolePom: user.rolePom,
      addresses: user.addresses ? user.addresses.map(AddressConverter.toModel) : user.addresses,
      createdAt: user.createdAt,
      lastLogin: user.lastLogin,
    };
  }

  public static toDomain(user: IUserModel): IUser {
    return {
      id: user.id,
      uuid: user.uuid,
      userTypeLevel: user.userTypeLevel,
      deliveryEmail: user.deliveryEmail,
      active: user.active ?? null,
      firstName: user.firstName,
      fullName: user.fullName,
      lastName: user.lastName,
      group: user.group ? GroupConverter.toDomain(user.group) : undefined,
      email: user.email,
      language: user.language,
      mustResetPassword: user.mustResetPassword ?? false,
      telephone: PhoneNumberUtil.transformPhoneNumber(user.telephone),
      title: user.title,
      externalId: user.externalId ?? "-",
      jobTitle: user.jobTitle ?? "-",
      notify: user.notify,
      rolePom: user.rolePom ?? undefined,
      addresses: user.addresses ? user.addresses.map(AddressConverter.toDomain) : user.addresses,
      createdAt: user.createdAt,
      lastLogin: user.lastLogin,
    };
  }

  public static toDomainList(users: IUserModel[] | undefined): IUser[] {
    if (!users) return [];
    return users.map((user) => this.toDomain(user));
  }

  public static toMutationRequest(inputs: IMutateUserFormInputs, groupId?: number): IMutateUserRequest {
    if (inputs.userTypeLevel === UserTypeLevel.Corporate) {
      inputs.userAddresses = [];
    }

    return {
      active: inputs.active ? new Date() : null,
      addresses: inputs.userAddresses ? inputs.userAddresses?.map(AddressLeanConverter.toModel) : [],
      userTypeLevel: inputs.userTypeLevel,
      deliveryEmail: inputs.deliveryEmail,
      email: inputs.email,
      firstName: inputs.firstName,
      groupId: groupId ?? inputs.group?.id,
      lastName: inputs.lastName,
      notify: inputs.notify,
      rolePom: inputs.rolePom,
      telephone: inputs.telephone,
      title: inputs.title,
      // only set password if user actually set new password
      password: inputs.password && inputs.password.length > 0 ? inputs.password : undefined,
      jobTitle: inputs.jobTitle,
      externalId: inputs.externalId,
    };
  }

  public static toUpdateRequest(user: IUser): UserUpdateRequest {
    return {
      active: user.active ? new Date() : null,
    };
  }

  public static toLoginAsUserDomain(user: IUser): IUserLoginAs {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
    };
  }
}
