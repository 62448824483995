import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors, Shades } from "./Colors";

const boxPadding = "2px 6px 2px 6px";
const rowMarginTop = 8;
export const PRODUCT_ROW_HEIGHT = 70 + rowMarginTop;

export const useProductRowStyle = makeStyles((theme) => ({
  rowContainer: {
    minHeight: "70px",
    borderRadius: "4px",
    marginTop: rowMarginTop,
    padding: "12px 12px",
  },
  rowContainerDefault: {
    backgroundColor: Colors.grayLight,
    "&:hover": {
      backgroundColor: Shades.gray20,
      cursor: "pointer",
    },
  },
  rowContainerExistingProduct: {
    backgroundColor: Shades.white,
    borderColor: Shades.gray40,
    border: "2px solid",
  },
  container: {
    height: "100%",
  },
  iconDimensions: {
    height: 40,
    width: 40,
  },
  textContainer: {
    marginTop: 8,
  },
  articleNumberContainer: {
    borderRadius: "4px",
    maxHeight: 24,
  },
  articleNumberMargin: {
    marginTop: 8,
    marginRight: 16,
  },
  articleNumberContainerDefault: {
    backgroundColor: Shades.gray20,
  },
  articleNumberContainerExistingProduct: {
    backgroundColor: Shades.gray20,
  },
  articleNumberDefault: {
    fontWeight: "bold",
  },
  articleNumberText: {
    color: PrimaryColors.base,
    padding: boxPadding,
  },
  articleNumberTextExistingProduct: {
    color: Shades.gray60,
    padding: boxPadding,
  },
  alreadyExistingContainer: {
    borderColor: Shades.gray60,
    border: "3px solid",
    borderRadius: "4px",
    padding: "1px 4px 1px 4px",
    marginRight: 15,
  },
  deleteButtonContainer: {
    marginRight: 15,
  },
  alreadyExistingText: {
    color: Shades.gray60,
  },
  checkBoxContainer: {
    minWidth: 60,
    minHeight: 40,
  },
  inactiveProduct: {
    color: Colors.red,
  },
}));
