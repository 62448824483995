import { ICfmProductResponseAssignment } from "../../../domain/routing-assignment/cfm-product-response-assignment";
import { ICfmProductResponseAssignmentModel } from "../response-assignment/cfm-product-response-assignment.model";
import { CfmProductConverter } from "./cfm-product.converter";
import { CfmResponseFieldXConverter } from "./cfm-response-field-x.converter";
import { CfmOrderXStatusConverter } from "./order-x/cfm-order-x-status.converter";
import { CfmServiceDateConverter } from "./order-x/cfm-service-date.converter";

export class CfmProductResponseAssignmentConverter {
  public static toModel(domain: ICfmProductResponseAssignment): ICfmProductResponseAssignmentModel {
    return {
      id: domain.id,
      product: domain.product ? CfmProductConverter.toModel(domain.product) : undefined,
      status: CfmOrderXStatusConverter.toModel(domain.status),
      serviceDates: domain.serviceDates ? domain.serviceDates.map(CfmServiceDateConverter.toModel) : null,
      responseFields: domain.responseFields ? domain.responseFields.map(CfmResponseFieldXConverter.toModel) : null,
    };
  }

  public static toDomain(model: ICfmProductResponseAssignmentModel): ICfmProductResponseAssignment {
    return {
      id: model.id,
      product: model.product ? CfmProductConverter.toDomain(model.product) : undefined,
      status: CfmOrderXStatusConverter.toDomain(model.status),
      serviceDates: model.serviceDates ? model.serviceDates.map(CfmServiceDateConverter.toDomain) : null,
      responseFields: model.responseFields ? model.responseFields.map(CfmResponseFieldXConverter.toDomain) : null,
    };
  }
}
