import { IconButtonClassKey, StyleRules } from "@material-ui/core";
import { Colors } from "style/Colors";

export const MuiIconButton: Partial<StyleRules<IconButtonClassKey, {}>> = {
  root: {
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "transparent",
    },

    "&.small": {
      padding: "6px",
    },
    "&.active": {
      backgroundColor: Colors.greenLight,
      color: Colors.grayLight,
      "&:hover": {
        backgroundColor: Colors.green,
      },
    },
  },
};
