import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AddButton } from "../../../components/Primitives/Buttons";
import { RoutingConfigSmallIcon } from "../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../style/Colors";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { ProductOverviewGeneralFilter } from "../product/components/product-overview.utils";
import { ProductRoutingList } from "../../components/product-routing/product-routing-list.component";
import { ProductListFilter, ProductListFilterKey } from "../../components/product/product-list-filter.component";
import { PackageOverviewProvider } from "../package/package-overview.provider";
import { ProductOverviewProvider } from "../product/product-overview.provider";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 20,
    marginBottom: 30,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
    marginTop: 5,
  },
}));

export const ExistingProductRoutingPage: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PackageOverviewProvider>
      <ProductOverviewProvider>
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.heading}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <RoutingConfigSmallIcon viewBox="0 0 25 25" className={classes.iconStyle} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">{t("configuration.configRouting")}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <AddButton onClick={() => history.push(AvailableConfiguratorRoutes.ProductsForRouting)}>
                  <Typography variant="body1">{t("configuration.newRouting")}</Typography>
                </AddButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ProductListFilter
              hidden={[
                ProductListFilterKey.TagWithoutPackage,
                ProductListFilterKey.TagWithoutResponseAssignments,
                ProductListFilterKey.TagInactiveProducts,
              ]}
              withoutRoutingTextOverride={t(
                `configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutRouting}.routingOverview`,
              )}
            />
          </Grid>
          <Grid item>
            <ProductRoutingList />
          </Grid>
        </Grid>
      </ProductOverviewProvider>
    </PackageOverviewProvider>
  );
};
