import { CfmOrderXApiStatus, CfmOrderXStatus, CfmOrderXUiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { CfmOrderXStatusModel } from "../../order-x/cfm-order-x.status.model";

export class CfmOrderXStatusConverter {
  public static toDomain(model: CfmOrderXStatusModel): CfmOrderXApiStatus {
    switch (model) {
      case CfmOrderXStatusModel.ShoppingCart:
        return CfmOrderXApiStatus.ShoppingCart;
      case CfmOrderXStatusModel.Ordered:
        return CfmOrderXApiStatus.Ordered;
      case CfmOrderXStatusModel.LogisticAccepted:
        return CfmOrderXApiStatus.LogisticAccepted;
      case CfmOrderXStatusModel.LogisticAnnounced:
        return CfmOrderXApiStatus.LogisticAnnounced;
      case CfmOrderXStatusModel.LogisticPerformed:
        return CfmOrderXApiStatus.LogisticPerformed;
      case CfmOrderXStatusModel.LogisticWarehouse:
        return CfmOrderXApiStatus.LogisticWarehouse;
      case CfmOrderXStatusModel.LogisticTruckLoadAnnounced:
        return CfmOrderXApiStatus.LogisticTruckLoadAnnounced;
      case CfmOrderXStatusModel.RecyclerIncomingWarehouse:
        return CfmOrderXApiStatus.RecyclerIncomingWarehouse;
      case CfmOrderXStatusModel.RecyclerOutgoingWarehouse:
        return CfmOrderXApiStatus.RecyclerOutgoingWarehouse;
      case CfmOrderXStatusModel.DestinationArrived:
        return CfmOrderXApiStatus.DestinationArrived;
      case CfmOrderXStatusModel.DestinationReceived:
        return CfmOrderXApiStatus.DestinationReceived;
      case CfmOrderXStatusModel.Confirmed:
        return CfmOrderXApiStatus.Confirmed;
      case CfmOrderXStatusModel.Canceled:
        return CfmOrderXApiStatus.Canceled;
      case CfmOrderXStatusModel.Unsupported:
        return CfmOrderXApiStatus.Unsupported;
    }
  }

  public static statusWithAllToModel(domain: CfmOrderXStatus | "all"): CfmOrderXStatusModel | "all" {
    if (domain === "all") return "all";
    switch (domain) {
      case CfmOrderXApiStatus.ShoppingCart:
        return CfmOrderXStatusModel.ShoppingCart;
      case CfmOrderXApiStatus.Ordered:
        return CfmOrderXStatusModel.Ordered;
      case CfmOrderXApiStatus.LogisticAccepted:
        return CfmOrderXStatusModel.LogisticAccepted;
      case CfmOrderXApiStatus.LogisticAnnounced:
      case CfmOrderXUiStatus.LogisticAnnouncedWithPlannedDeliveryDate:
      case CfmOrderXUiStatus.LogisticAnnouncedWithoutPlannedDeliveryDate:
        return CfmOrderXStatusModel.LogisticAnnounced;
      case CfmOrderXApiStatus.LogisticPerformed:
        return CfmOrderXStatusModel.LogisticPerformed;
      case CfmOrderXApiStatus.LogisticWarehouse:
        return CfmOrderXStatusModel.LogisticWarehouse;
      case CfmOrderXApiStatus.LogisticTruckLoadAnnounced:
        return CfmOrderXStatusModel.LogisticTruckLoadAnnounced;
      case CfmOrderXApiStatus.RecyclerIncomingWarehouse:
      case CfmOrderXUiStatus.RecyclerIncomingWarehouseWithoutTruckload:
      case CfmOrderXUiStatus.SorterWarehouse:
        return CfmOrderXStatusModel.RecyclerIncomingWarehouse;
      case CfmOrderXApiStatus.RecyclerOutgoingWarehouse:
        return CfmOrderXStatusModel.RecyclerOutgoingWarehouse;
      case CfmOrderXApiStatus.DestinationArrived:
        return CfmOrderXStatusModel.DestinationArrived;
      case CfmOrderXApiStatus.DestinationReceived:
        return CfmOrderXStatusModel.DestinationReceived;
      case CfmOrderXApiStatus.Confirmed:
        return CfmOrderXStatusModel.Confirmed;
      case CfmOrderXApiStatus.Canceled:
        return CfmOrderXStatusModel.Canceled;
      case CfmOrderXApiStatus.Unsupported:
        return CfmOrderXStatusModel.Unsupported;
    }
  }

  public static toModel(domain: CfmOrderXApiStatus): CfmOrderXStatusModel {
    switch (domain) {
      case CfmOrderXApiStatus.ShoppingCart:
        return CfmOrderXStatusModel.ShoppingCart;
      case CfmOrderXApiStatus.Ordered:
        return CfmOrderXStatusModel.Ordered;
      case CfmOrderXApiStatus.LogisticAccepted:
        return CfmOrderXStatusModel.LogisticAccepted;
      case CfmOrderXApiStatus.LogisticAnnounced:
        return CfmOrderXStatusModel.LogisticAnnounced;
      case CfmOrderXApiStatus.LogisticPerformed:
        return CfmOrderXStatusModel.LogisticPerformed;
      case CfmOrderXApiStatus.LogisticWarehouse:
        return CfmOrderXStatusModel.LogisticWarehouse;
      case CfmOrderXApiStatus.LogisticTruckLoadAnnounced:
        return CfmOrderXStatusModel.LogisticTruckLoadAnnounced;
      case CfmOrderXApiStatus.RecyclerIncomingWarehouse:
        return CfmOrderXStatusModel.RecyclerIncomingWarehouse;
      case CfmOrderXApiStatus.RecyclerOutgoingWarehouse:
        return CfmOrderXStatusModel.RecyclerOutgoingWarehouse;
      case CfmOrderXApiStatus.DestinationArrived:
        return CfmOrderXStatusModel.DestinationArrived;
      case CfmOrderXApiStatus.DestinationReceived:
        return CfmOrderXStatusModel.DestinationReceived;
      case CfmOrderXApiStatus.Confirmed:
        return CfmOrderXStatusModel.Confirmed;
      case CfmOrderXApiStatus.Canceled:
        return CfmOrderXStatusModel.Canceled;
      case CfmOrderXApiStatus.Unsupported:
        return CfmOrderXStatusModel.Unsupported;
    }
  }
}
