import React, { createContext, useContext, useState } from "react";

interface IPasswordResetDialogContextType {
  open: boolean;
  setIsOpen: (open: boolean) => void;
}

interface IPasswordResetDialogContextProps {
  children: any;
}

const PasswordResetDialogContext = createContext<IPasswordResetDialogContextType>(
  {} as IPasswordResetDialogContextType,
);

export const PasswordResetDialogProvider = (props: IPasswordResetDialogContextProps) => {
  const value = usePasswordResetDialogProvider(props);
  return <PasswordResetDialogContext.Provider value={value}>{props.children}</PasswordResetDialogContext.Provider>;
};

export const usePasswordResetDialogContext = () => {
  return useContext(PasswordResetDialogContext);
};

const usePasswordResetDialogProvider = (props: IPasswordResetDialogContextProps): IPasswordResetDialogContextType => {
  const [passwordResetOpen, setPasswordResetOpen] = useState<boolean>(false);

  return {
    open: passwordResetOpen,
    setIsOpen: setPasswordResetOpen,
  };
};
