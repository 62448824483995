import {
  IResponseAssignmentRowValue,
  IResponseFieldData,
} from "../../../../configurator/pages/product-response/product-response-assignment.provider";
import { ICfmMutateResponseAssignmentXModel } from "../response-assignment/cfm-mutate-response-assignment-x.model";
import { ICfmResponseFieldMutateAssignmentModel } from "../response-assignment/cfm-response-field-mutate-assignment.model";
import { CfmOrderXConverter } from "./order-x/cfm-order-x.converter";
import { CfmServiceDateConverter } from "./order-x/cfm-service-date.converter";

export class CfmResponseAssignmentXMutationConverter {
  public static toModel(assignment: IResponseAssignmentRowValue): ICfmMutateResponseAssignmentXModel {
    return {
      status: CfmOrderXConverter.statusToModel(assignment.status),
      responseFields: assignment.responseFieldData
        ? assignment.responseFieldData.map(
            CfmResponseAssignmentXMutationConverter.fieldDataToResponseFieldMutationModel,
          )
        : [],
      serviceDates: assignment.serviceDates ? assignment.serviceDates.map(CfmServiceDateConverter.toModel) : [],
    };
  }

  private static fieldDataToResponseFieldMutationModel(
    data: IResponseFieldData,
  ): ICfmResponseFieldMutateAssignmentModel {
    return {
      fieldId: data.id,
      serviceDate: data.serviceDate ? CfmServiceDateConverter.toModel(data.serviceDate) : null,
      warehouseArticleId: data.warehouseArticleId,
      billingType: data.billingType,
    };
  }
}
