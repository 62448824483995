import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ArticleNumberBox } from "../../../../../../configurator/components/product/article-number-box.component";
import { useAuthContext } from "../../../../../../providers/Auth/auth.provider";
import { AppDialog } from "../../../../../../shared/components/dialog/app-dialog.component";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { useCustomForm } from "../../../../../../shared/util/form.util";
import { Shades } from "../../../../../../style/Colors";
import { ICfmCreateResponseValueX } from "../../../../../domain/order-x/actions/cfm-create-response-value-x";
import { ICfmWarehouseProduct } from "../../../../../domain/products/cfm-warehouse-product";
import { CfmTransferOrderStatus } from "../../../../../domain/transfer-order/cfm-transfer-order-status";
import {
  IResponseFieldValuesFormInputs,
  IResponseValueServiceDateFormInputs,
} from "../../actions/order-x-action.component";
import { useSelectedFilesContext } from "../../actions/providers/selected-files.provider";
import { ResponseXInput } from "../../actions/response-x-input.component";
import { CfmServiceDatesInput } from "../../actions/service-date-input.component";
import { CfmResponseFieldXConverter } from "../../../../../repositories/models/converter/cfm-response-field-x.converter";
import { CfmTransferOrderConverter } from "../../../../../repositories/models/converter/transfer-order/cfm-transfer-order.converter";
import { useCreateTransferOrderQuery } from "../../../../../repositories/queries/transfer-order/mutation/create-transfer-order.query";
import { ICfmTransferOrderAvailableAction } from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { ICfmCreateTransferOrder } from "../../../../../domain/transfer-order/cfm-create-transfer-order";

const useStyles = makeStyles((theme: Theme) => ({
  weightBox: {
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: 5,
    padding: "5px 20px 5px 20px",
  },
}));

export interface IRespondDestinationReceivedRecyclerWarehouseProductInputs
  extends IResponseValueServiceDateFormInputs,
    IResponseFieldValuesFormInputs {
  targetAddressId: number;
}

interface IRespondDestinationReceivedRecyclerWarehouseProductDialogProps {
  warehouseProduct: ICfmWarehouseProduct;
  availableAction: ICfmTransferOrderAvailableAction;
  startAddress: IAddressLean;
  isOpen: boolean;
  onSubmitted: VoidFunction;
  onCancel: VoidFunction;
}

export const RespondDestinationReceivedRecyclerWarehouseProductDialog: VFC<
  IRespondDestinationReceivedRecyclerWarehouseProductDialogProps
> = (props) => {
  const { isOpen, onSubmitted, onCancel, startAddress, warehouseProduct, availableAction } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { uploadFiles, isLoading: isUploadFilesLoading, reset: resetFileUpload } = useSelectedFilesContext();
  const { internalUser } = useAuthContext();
  const { isLoading: isCreateLoading, mutateAsync: createTransferOrder } = useCreateTransferOrderQuery();
  const getDefaultValues = (): ICfmCreateResponseValueX[] => {
    if (!internalUser) {
      return [];
    }

    return (
      availableAction.responseFieldAssignments?.map((assignment) =>
        CfmResponseFieldXConverter.assignmentToCreateResponseValueX(assignment, -1, internalUser.id),
      ) ?? []
    );
  };
  const formMethods = useCustomForm<IRespondDestinationReceivedRecyclerWarehouseProductInputs>({
    defaultValues: {
      responseFields: getDefaultValues(),
    },
  });
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<IRespondDestinationReceivedRecyclerWarehouseProductInputs> = async (inputs) => {
    const filesList = (await uploadFiles()) ?? [];
    const requestData: ICfmCreateTransferOrder = CfmTransferOrderConverter.formToCreateDomain(
      inputs,
      startAddress.id,
      warehouseProduct.product.id,
      warehouseProduct.warehouseArticleNumber,
      filesList,
      CfmTransferOrderStatus.Confirmed,
    );

    await createTransferOrder(requestData);
    resetFileUpload();
    onSubmitted();
  };

  const handleCancel = () => {
    resetFileUpload();
    onCancel();
  };

  return (
    <AppDialog
      open={isOpen}
      title={t("orders.destinationReceivedWarehouse.dialog.title")}
      onCancelClick={handleCancel}
      onAcceptClick={() => handleSubmit(onSubmit)()}
      acceptTextOverride={t("orders.destinationReceivedWarehouse.dialog.submit")}
      maxWidth="sm"
      isLoading={isUploadFilesLoading || isCreateLoading}
    >
      <FormProvider {...formMethods}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} className={classes.weightBox}>
              <Grid item>
                <ArticleNumberBox articleNumber={warehouseProduct.product.articleNumber} />
              </Grid>
              <Grid item>
                <Typography>
                  {t("orders.destinationReceivedWarehouse.dialog.weightInfo", {
                    productName: warehouseProduct.product.name,
                    quantity: warehouseProduct.quantity,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {availableAction.responseFieldAssignments?.map((field, index) => {
            const responseField = availableAction.responseFieldAssignments?.[index];
            if (!responseField) {
              return <></>;
            }
            return (
              <Grid item>
                <ResponseXInput
                  responseIndex={index}
                  responseFieldId={responseField.responseAssignmentResponseFieldId}
                  fieldType={responseField.type}
                  label={responseField.name}
                  unit={responseField.unit}
                  required={false}
                />
              </Grid>
            );
          })}
          {availableAction.serviceDates && (
            <Grid item>
              <CfmServiceDatesInput serviceDates={availableAction.serviceDates} />
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </AppDialog>
  );
};
