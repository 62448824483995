import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailableCfmRoutes } from "../../../utils/constants";
import { ICfmOrderX } from "../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../repositories/models/converter/order-x/cfm-order-x.converter";
import { useGetOrderXQuery } from "../../repositories/queries/order-x/queries/get-order-x.query";
import {
  IOrderNewWizardFormInputs,
  OrderNewWizard,
  WizardMode,
} from "../order-new/components/order-new-wizard.component";
import { OrderNewProvider, WizardStep } from "../order-new/order-new.provider";
import qs from "qs";

interface IOrderXNewRepeatParams {
  id: string | undefined;
}

export const OrderXNewRepeatPage: FC = (props) => {
  const { internalUser } = useAuthContext();
  const { id } = useParams<IOrderXNewRepeatParams>();
  const { data: order, isLoading } = useGetOrderXQuery(Number(id));
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const prefillAllOrderInfoString = qs.parse(location.search, { ignoreQueryPrefix: true }).prefillAll as
    | string
    | undefined;
  const prefillAllOrderInfo = prefillAllOrderInfoString === "true";

  const getInitialValues = (order: ICfmOrderX) => {
    const initialValues = CfmOrderXConverter.toWizardInputs(order);
    if (!initialValues) {
      enqueueSnackbar(
        t("general.error_occurred", { errorCode: 500, errorMsg: "Error loading initial values for repeating order!" }),
        { variant: "error" },
      );
      history.push(AvailableCfmRoutes.OrderXRepeatOverview);
      return;
    }

    if (!prefillAllOrderInfo) {
      initialValues.productOrders.map((p) => ({
        ...p,
        requestedPickupDate: undefined,
        orderNumber: undefined,
        orderComment: undefined,
      }));
    }

    const initValues: Partial<IOrderNewWizardFormInputs> = {
      ...initialValues,
      customerGroupId: initialValues.customerGroupId ? initialValues.customerGroupId : internalUser?.group?.id,
    };
    return initValues;
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && order && (
        <OrderNewProvider initialStep={WizardStep.SubmitPage} wizardMode={WizardMode.Repeat}>
          <OrderNewWizard initialValues={getInitialValues(order)} mode={WizardMode.Repeat} />
        </OrderNewProvider>
      )}
    </>
  );
};
