import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from "../providers/Auth/auth.provider";
import { IUser } from "../shared/domain/user/user";
import { isUserLoggedIn } from "./auth";
import { AvailableCfmRoutes, AvailableSharedRoutes } from "./constants";

interface IPasswordResetRouteProps {
  component: React.FunctionComponent<any>;
  path: string;
}

export const PasswordResetRoute: FunctionComponent<IPasswordResetRouteProps> = (props) => {
  const { getUser } = useAuthContext();
  const { ...rest } = props;
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    const loadAndSetUser = async () => {
      const loaded = await getUser();
      setUser(loaded);
    };

    loadAndSetUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notAllowed = () => {
    return !isUserLoggedIn() || !user || user.mustResetPassword === false;
  };

  const mustReset = () => {
    return user !== undefined && user.mustResetPassword === true;
  };

  return (
    <>
      {user !== undefined && (
        <>
          {mustReset() && <Redirect to={{ pathname: AvailableSharedRoutes.ResetPassword }} />}
          {notAllowed() && <Redirect to={{ pathname: AvailableCfmRoutes.OrderXOverview }} />}

          <Route {...rest} />
        </>
      )}
    </>
  );
};
