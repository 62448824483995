import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAutocomplete } from "../../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "../../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { recyclerAddressTypes } from "../../../../../../shared/models/address/address.model";
import { getAddressIds, getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { useGetAddressesForAccepting } from "../../../../../repositories/queries/address/get-addresses-for-accepting.query";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";
import { Loading } from "../../../../../../shared/components/loading/loading.component";

export interface IOrderXDestinationAnnouncedAction {
  plannedDeliveryDate: Date;
  destinationAddressId: number;
}

export const OrderXDestinationAnnouncedAction: VFC = () => {
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext<ICfmResponseValueFormInputs>();
  const { t } = useTranslation();

  const { data: destinationAddressesForAccepting, isLoading: areLogisticsAddressesLoading } =
    useGetAddressesForAccepting(true, recyclerAddressTypes);

  return (
    <Loading isLoading={areLogisticsAddressesLoading}>
      <FormField md={12} className={"pb-4"}>
        <FormAutocomplete<number>
          name={"destinationAnnounced.destinationAddressId"}
          control={control}
          label={t("basedata.addresses.type.recycling_location")}
          options={getAddressIds(destinationAddressesForAccepting ?? [])}
          getOptionLabel={(value) => {
            const address = (destinationAddressesForAccepting ?? []).find((address) => address.id === value);
            if (address) {
              return getFormattedDomainAddress(address, t);
            }
          }}
          error={Boolean(errors?.logisticAnnounced?.logisticAddressId)}
          rules={{ required: true }}
        />
      </FormField>

      <FormField md={12} className={"pb-4"}>
        <FormDatepickerField
          label={t("orders.accept_form.planned_delivery_date.label")}
          hasError={Boolean(errors?.destinationAnnounced?.plannedDeliveryDate)}
          control={control}
          required={true}
          {...register(`destinationAnnounced.plannedDeliveryDate`, {
            required: true,
            valueAsDate: true,
          })}
        />
      </FormField>
    </Loading>
  );
};
