import { useEffect, useState, VFC } from "react";
import { useQueryClient } from "react-query";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { EditGroupUserDialog } from "../../../../components/group/edit-group/dialog/edit-group-user-dialog.component";
import { UserFormModal } from "../../../../components/user/user-form-modal/user-form-modal.component";
import { UserFormMode } from "../../../../components/user/user-form/user-form.component";
import { IGroup } from "../../../../domain/group/group";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { IUser } from "../../../../domain/user/user";
import { UserTypeLevel } from "../../../../domain/user/user-type-level";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useGetRelatedWasteProducerUsers } from "../../../../repositories/queries/user/get-related-waste-producer-users.query";
import { UserDetailDialog } from "../dialogues/user-detail-dialog.component";
import { GroupDetailsUsersBaseTable } from "./group-detail-users-base-table.component";

interface IGroupDetailsWasteProducerUsersTableProps {
  group: IGroup;
}

export const GroupDetailsWasteProducerUsersTable: VFC<IGroupDetailsWasteProducerUsersTableProps> = (props) => {
  const { group } = props;
  const { internalUser } = useAuthContext();

  const queryClient = useQueryClient();
  const [openNewUser, setOpenNewUser] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const {
    isLoading,
    data: userResult,
    refetch,
  } = useGetRelatedWasteProducerUsers(internalUser?.id, searchText, paginationInputMeta);

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const onSavedCallback = () => {
    refetch();
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    setOpenNewUser(false);
  };

  const handleRowClicked = (user: IUser | undefined) => {
    setSelectedUser(user);
    setIsDetailOpen(true);
  };

  const handleOnAddNewClicked = () => {
    setOpenNewUser(true);
  };

  return (
    <>
      <UserFormModal
        savedCallback={onSavedCallback}
        onCancel={() => {
          setOpenNewUser(false);
        }}
        open={openNewUser}
        group={group}
        mode={UserFormMode.Create}
        initialValues={{ userTypeLevel: UserTypeLevel.WasteProducer }}
      />

      <UserDetailDialog
        open={isDetailOpen}
        user={selectedUser}
        onClose={() => {
          setIsDetailOpen(false);
          setSelectedUser(undefined);
        }}
        onEditClick={() => {
          setIsEditOpen(true);
          setIsDetailOpen(false);
        }}
      />

      <EditGroupUserDialog
        open={isEditOpen}
        group={group}
        user={selectedUser}
        onSaved={() => {
          setIsEditOpen(false);
          setIsDetailOpen(false);
          setSelectedUser(undefined);
          queryClient.invalidateQueries(SharedQueryKeys.GetRelatedWasteProducerUsers);
        }}
        onCancel={() => {
          setIsDetailOpen(false);
          setIsEditOpen(false);
        }}
      />

      <GroupDetailsUsersBaseTable
        userResult={userResult}
        onAddClick={handleOnAddNewClicked}
        onRowClicked={handleRowClicked}
        isLoading={isLoading}
        setSearchText={setSearchText}
        paginationInputMeta={paginationInputMeta}
        setPaginationInputMeta={setPaginationInputMeta}
      />
    </>
  );
};
