import lodash from "lodash";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPaginationMeta } from "../../shared/domain/pagination/pagination-meta";
import { IUser } from "../../shared/domain/user/user";
import { IUsersFilter } from "../../shared/repositories/queries/user/get-users.query";
import { getGroupTypeTranslation } from "../../utils/group.helper";
import { useUsersContext } from "./users-context";

interface IUserTableProps {
  children: React.ReactNode;
}

interface IUserTableContext {
  users: IUser[];
  isLoading: boolean;
  getGroupType: (user: IUser) => string;
  entryCount: number;
  toggleUserStatus: (user: IUser) => Promise<void>;
  tablePagination: IPaginationMeta;
  setTablePagination: (pagination: IPaginationMeta) => void;
  filters?: IUsersFilter;
  setFilters: (filters: IUsersFilter) => void;
  isFilterDirty: boolean;
  resetFilter: () => void;
  getErpNumber: (user: IUser) => string;
}

export const UserTableContext = createContext<IUserTableContext>({} as IUserTableContext);

export const useUserTableContext = () => {
  return useContext(UserTableContext);
};

const useUserTableProvider = (props: IUserTableProps): IUserTableContext => {
  const { t } = useTranslation();
  const { users, filters, isLoading, setFilters, tablePagination, setTablePagination, toggleUserStatus } =
    useUsersContext();
  const [entryCount, setEntryCount] = useState<number>(50);

  useEffect(() => {
    setEntryCount(users && users.length > 50 ? 50 : users.length);
  }, [users]);

  const getGroupType = (user: IUser): string => {
    if (!user.group) return "";
    const uiGroup = user.group;
    if (!uiGroup) return "";
    return getGroupTypeTranslation(uiGroup.type, t);
  };

  const isFilterDirty = useMemo(
    () => !lodash.isEmpty(filters?.active) || !lodash.isEmpty(filters?.searchText),
    [filters],
  );

  const resetFilter = () => setFilters({ active: undefined, searchText: undefined, groupTypes: undefined });

  const getErpNumber = (user: IUser): string => {
    return `${user.group?.erpNumber ?? ""}`;
  };

  return {
    getErpNumber,
    getGroupType,
    entryCount,
    toggleUserStatus,
    filters,
    setFilters,
    tablePagination,
    setTablePagination,
    isLoading,
    users,
    isFilterDirty,
    resetFilter,
  };
};

export const UserTableProvider = (props: IUserTableProps) => {
  const value = useUserTableProvider(props);
  return <UserTableContext.Provider value={value}>{props.children}</UserTableContext.Provider>;
};
