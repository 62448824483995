import { Box, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { ProductIcon, ProductMobileIcon, SingleOrderIcon } from "../../../../../components/Primitives";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { OrderNewInfoBox } from "./order-new-info-box.component";

const useStyles = makeStyles((theme: Theme) => ({
  iconSizeProduct: {
    height: 40,
    width: 54,
  },
  iconSizeProductMobile: {
    height: 30,
    width: 39,
  },
  iconSizeSingleOrder: {
    height: 32,
    width: 40,
  },
}));

export interface IInfoBoxDisplayData {
  id: number;
  label: JSX.Element;
}

interface IProductInfoBoxProps {
  displayData: IInfoBoxDisplayData;
  isSingleOrder: boolean;
  onClick: (id: number) => void;
}

export const ProductInfoBox: FunctionComponent<IProductInfoBoxProps> = (props) => {
  const { displayData, onClick, isSingleOrder } = props;
  const classes = useStyles();
  const { isUpTablet } = useScreenSize();

  const getSingleOrderIcon = () => {
    return (
      <Box>
        <SingleOrderIcon classes={{ root: classes.iconSizeSingleOrder }} />
      </Box>
    );
  };
  const getProductIcon = () => {
    return isUpTablet ? (
      <ProductIcon classes={{ root: classes.iconSizeProduct }} />
    ) : (
      <ProductMobileIcon classes={{ root: classes.iconSizeProductMobile }} />
    );
  };

  return (
    <OrderNewInfoBox
      iconComponent={isSingleOrder ? getSingleOrderIcon() : getProductIcon()}
      labelComponent={displayData.label}
      onClick={() => {
        onClick(displayData.id);
      }}
    />
  );
};
