import { Grid } from "@material-ui/core";
import React, { ReactNode, useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "../../../../../../shared/components/loading-spinner.component";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { AddressFilter } from "../../../../../../shared/components/address/address-filter.component";
import { RespondDestinationReceivedRecyclerWarehouseProductDialog } from "./destination-received-recycler-warehouse-product-dialog.component";
import { CfmOrderXApiStatus } from "../../../../../domain/order-x/cfm-order-x.status";
import { ICfmWarehouseProduct } from "../../../../../domain/products/cfm-warehouse-product";
import { ICfmOrderXOverviewFilter, useOrderXOverviewContext } from "../../../order-x-overview.provider";
import { SelectedFilesProvider } from "../../actions/providers/selected-files.provider";
import { ICfmTransferOrderAvailableAction } from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import {
  ISegmentedItem,
  SegmentedControl,
} from "../../../../../../shared/components/segmented-control/segmented-control.component";
import { useGetTransferOrders } from "../../../../../repositories/queries/transfer-order/queries/get-transfer-orders.query";
import { WarehouseProductsListComponent } from "./warehouse-products-list.component";
import { TransferOrdersListComponent } from "./transfer-orders-list.component";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";
import { ListFooterComponent } from "../../../../../../shared/components/list/list-footer.component";
import { AddressType } from "../../../../../../shared/models/address/address.model";
import { ProductFilterComponent } from "../destination-arrived-recycler-warehouse/product-filter.component";
import { removeNullAndUndefined } from "../../../../../../utils/filter.util";
import { useGetWarehouseItems } from "../../../../../repositories/queries/product/query/get-warehouse-items.query";
import { CfmTransferOrderStatusModel } from "../../../../../repositories/models/transfer-order/cfm-transfer-order-status.model";
import { CfmTransferOrderPostingTypeModel } from "../../../../../repositories/models/transfer-order/cfm-transfer-order-posting-type.model";
import { ExportTransferOrdersButton } from "./export-transfer-orders-button.component";
import { ICfmProduct } from "../../../../../domain/products/cfm-product";

enum ViewMode {
  RECYCLING_VIEW = "Recycling",
  HISTORY_VIEW = "History"
}

export const DestinationReceivedRecyclerWarehouseView: VFC = (props) => {
  const { t } = useTranslation();
  const { setFilter, curFilter } = useOrderXOverviewContext();
  const [selectedWarehouse, setSelectedWarehouse] = useState<IAddressLean | undefined>();
  const [selectedProductFilter, setSelectedProductFilter] = useState<ICfmProduct | undefined>();
  const [selectedWarehouseProduct, setSelectedWarehouseProduct] = useState<
    { product: ICfmWarehouseProduct; action: ICfmTransferOrderAvailableAction } | undefined
  >();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedViewMode, setSelectedViewMode] = useState<ViewMode>(ViewMode.RECYCLING_VIEW);

  const handleWarehouseChanged = (warehouse: IAddressLean | null) => {
    setSelectedWarehouse(warehouse ?? undefined);
  };

  const handleProductFilterChanged = (product: ICfmProduct | null) => {
    setSelectedProductFilter(product ?? undefined);
  };

  const transferOrderFilter = useMemo(
    () => ({
      startAddressId: selectedWarehouse?.id,
      status: CfmTransferOrderStatusModel.Confirmed,
      postingType: CfmTransferOrderPostingTypeModel.Recycled,
    }),
    [selectedWarehouse],
  );

  const warehouseSelected = Number.isFinite(selectedWarehouse?.id);

  const { isLoading: areProductsLoading, data: products } =
    useGetWarehouseItems(selectedWarehouse?.id, warehouseSelected && selectedViewMode === ViewMode.RECYCLING_VIEW);
  const {
    isLoading: isHistoryLoading,
    data: historyTransferOrders,
    refetch: refetchHistory,
  } = useGetTransferOrders(transferOrderFilter, warehouseSelected && selectedViewMode === ViewMode.HISTORY_VIEW);

  const isLoading = areProductsLoading || isHistoryLoading;

  const onRespondClicked = (warehouseProduct: ICfmWarehouseProduct, action: ICfmTransferOrderAvailableAction) => {
    setSelectedWarehouseProduct({ product: warehouseProduct, action: action });
    setIsOpen(true);
  };

  const onSubmittedWarehouseProduct = () => {
    const filterCopy: ICfmOrderXOverviewFilter = {
      statusFilter: CfmOrderXApiStatus.DestinationReceived,
      filter: { ...curFilter.filter, status: CfmOrderXApiStatus.DestinationReceived },
    };
    setFilter(filterCopy);
    closeSubmitDialog();
  };

  const closeSubmitDialog = () => {
    setSelectedWarehouseProduct(undefined);
    setIsOpen(false);
  };

  const segmentedControlItems: ISegmentedItem[] = [
    {
      label: t("orders.destinationReceivedWarehouse.segmentedControl.recycling"),
      onClick: () => {
        setSelectedViewMode(ViewMode.RECYCLING_VIEW);
      },
    },
    {
      label: t("orders.destinationReceivedWarehouse.segmentedControl.history"),
      onClick: () => {
        void refetchHistory();
        setSelectedViewMode(ViewMode.HISTORY_VIEW);
      },
    },
  ];

  const getListContent = useMemo((): ReactNode => {
    if (!selectedWarehouse) return;

    let productsToShow = products;

    if (selectedProductFilter) {
      productsToShow = products?.filter((p) => p.product?.id === selectedProductFilter?.id);
    }

    switch (selectedViewMode) {
      case "Recycling":
        return (
          <WarehouseProductsListComponent
            warehouseProducts={productsToShow}
            onRespondClicked={onRespondClicked}
            selectedWarehouse={selectedWarehouse}
            orderStatus={CfmOrderXApiStatus.DestinationArrived}
          />
        );
      case "History":
        return <TransferOrdersListComponent transferOrders={historyTransferOrders} />;
    }
  }, [products, historyTransferOrders, selectedViewMode, selectedWarehouse, selectedProductFilter]);

  return (
    <SelectedFilesProvider>
      {selectedWarehouse && selectedWarehouseProduct && isOpen && (
        <RespondDestinationReceivedRecyclerWarehouseProductDialog
          warehouseProduct={selectedWarehouseProduct.product}
          availableAction={selectedWarehouseProduct.action}
          startAddress={selectedWarehouse}
          isOpen={isOpen}
          onSubmitted={onSubmittedWarehouseProduct}
          onCancel={closeSubmitDialog}
        />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item container direction={"row"} spacing={2}>
          <Grid item xs={12} sm={4}>
            <AddressFilter
              handleAddressChanged={handleWarehouseChanged}
              addressTypes={[AddressType.RecyclerLocation]}
            />
          </Grid>
          {!isLoading && selectedWarehouse && selectedViewMode === "Recycling" && (
            <Grid item xs={12} sm={4}>
              <ProductFilterComponent
                products={products?.map((o) => o.product).filter(removeNullAndUndefined) ?? []}
                handleProductChanged={handleProductFilterChanged}
              />
            </Grid>
          )}
          {selectedWarehouse && selectedWarehouse && selectedViewMode === "History" && (
            <Grid item xs={12} sm={4}>
              <ExportTransferOrdersButton transferOrderFilter={transferOrderFilter}></ExportTransferOrdersButton>
            </Grid>
          )}
        </Grid>

        {selectedWarehouse && (
          <Grid item xs={12} sm={4}>
            <SegmentedControl items={segmentedControlItems} />
          </Grid>
        )}

        {isLoading && (
          <Grid item>
            <LoadingSpinner isLoading={isLoading} />
          </Grid>
        )}
        {!selectedWarehouse && (
          <Grid item>
            <EmptyWarehouse text={t("orders.destinationReceivedWarehouse.selectWarehouse")} />
          </Grid>
        )}
        {selectedWarehouse && getListContent}
      </Grid>
      <ListFooterComponent />
    </SelectedFilesProvider>
  );
};
