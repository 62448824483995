import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo, VFC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AddButton } from "../../../../components/Primitives";
import { FormAutocomplete } from "../../../../shared/components/form/fields/form-autocomplete.component";
import { IAddress } from "../../../../shared/domain/address/address";
import { getFormattedRoutingAddress } from "../../../../utils/address.util";
import { IAutocompleteOption } from "../../../../utils/AutocompleteHelper";
import { removeNullAndUndefined } from "../../../../utils/filter.util";
import { ICreateProductRoutingFormInputs } from "../create-product-routing-content.component";
import { ProductRoutingAddressesFormAdditionalAddresses } from "./product-routing-addresses-form-additional-addresses.component";
import { emptyOption } from "./product-routing-addresses-form-content.component";

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: "bold",
  },
}));

interface IProductRoutingAddressesFormAddressSectionProps {
  heading: string;
  logisticAddresses: IAddress[];
  recyclerAddresses: IAddress[];
  setGroupId: (groupId: number | undefined) => void;
  groupId: number | undefined;
  startAddresses: IAutocompleteOption[];
  required: boolean;
  level: string;
}

export const ProductRoutingAddressesFormAddressSection: VFC<IProductRoutingAddressesFormAddressSectionProps> = (
  props,
) => {
  const { heading, logisticAddresses, recyclerAddresses, groupId, startAddresses, required, level } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext<ICreateProductRoutingFormInputs>();

  type ObjectKey = keyof typeof errors;

  const startAddressProperty = `${level}StartAddressId` as ObjectKey;
  const targetAddressProperty = `${level}TargetAddressId` as ObjectKey;
  const optionalAddressIdsKey = level === "primary" ? "optionalPrimaryAddressIds" : "optionalSecondaryAddressIds";
  const startAddressIdKey = level === "primary" ? "primaryStartAddressId" : "secondaryStartAddressId";
  const targetAddressIdKey = level === "primary" ? "primaryTargetAddressId" : "secondaryTargetAddressId";

  const optionalAddressIds: number[] | undefined = watch(optionalAddressIdsKey);
  const startAddressId = watch(startAddressIdKey);
  const targetAddressId = watch(targetAddressIdKey);

  const { update } = useFieldArray({ name: optionalAddressIdsKey });

  const targetAddressOptions = useMemo(() => {
    const mappedLogisticTargetOptions: IAutocompleteOption[] = logisticAddresses
      .filter((address) => address.groupId === groupId)
      .map((address) => {
        if (!address.id) return undefined;
        return {
          id: address.id.toString(),
          name: getFormattedRoutingAddress(address.type, address, t),
        } as IAutocompleteOption;
      })
      .filter(removeNullAndUndefined);

    const mappedRecyclerTargetOptions: IAutocompleteOption[] = recyclerAddresses
      .map((address) => {
        if (!address.id) return undefined;
        return {
          id: address.id.toString(),
          name: getFormattedRoutingAddress(address.type, address, t),
        } as IAutocompleteOption;
      })
      .filter(removeNullAndUndefined);
    const options: IAutocompleteOption[] = [];
    return options.concat(mappedLogisticTargetOptions).concat(mappedRecyclerTargetOptions);
  }, [groupId, logisticAddresses, recyclerAddresses, t]);

  const primaryTargetAddressOptions = useMemo(() => {
    return targetAddressOptions
      .filter((option) => !(optionalAddressIds ?? []).includes(Number(option.id)))
      .filter((value, index, self) => self.map((v) => v.id).indexOf(value.id) === index);
  }, [optionalAddressIds, targetAddressOptions]);

  const optionalPrimaryTargetAddressOptions = useMemo(() => {
    return targetAddressOptions
      .filter((option) => Number(option.id) !== targetAddressId)
      .filter((value, index, self) => self.map((v) => v.id).indexOf(value.id) === index);
  }, [targetAddressId, targetAddressOptions]);

  const addAdditionalTargetAddress = useCallback(() => {
    const index = optionalAddressIds?.length ?? 0;
    update(index, Number(emptyOption.id));
    trigger(`${optionalAddressIdsKey}.${index}`);
  }, [optionalAddressIds?.length, optionalAddressIdsKey, trigger, update]);

  useEffect(() => {
    if (optionalAddressIds) return;
    addAdditionalTargetAddress();
  }, [optionalAddressIds, addAdditionalTargetAddress]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h6" className={classes.heading}>
          {heading}
        </Typography>
      </Grid>
      <Grid item>
        {startAddresses.length > 0 && (
          <FormAutocomplete<number>
            required={required || (targetAddressId !== undefined && targetAddressId !== null)}
            control={control}
            disabled={required ? false : groupId === undefined}
            label={t("configuration.assignments.create.startAddress")}
            options={startAddresses.map((option) => Number(option.id))}
            multiple={false}
            error={Boolean(errors[startAddressProperty])}
            getOptionLabel={(value) => {
              return startAddresses.find((option) => Number(option.id) === value)?.name ?? "";
            }}
            name={startAddressIdKey}
            rules={{ required: required || (targetAddressId !== undefined && targetAddressId !== null) }}
          />
        )}
      </Grid>
      <Grid item>
        <FormAutocomplete<number>
          required={required || (startAddressId !== undefined && startAddressId !== null)}
          control={control}
          disabled={groupId === undefined}
          label={t("configuration.assignments.create.targetAddress")}
          options={primaryTargetAddressOptions.map((option) => Number(option.id))}
          multiple={false}
          error={Boolean(errors[targetAddressProperty])}
          getOptionLabel={(value) => {
            return primaryTargetAddressOptions.find((option) => Number(option.id) === value)?.name ?? "";
          }}
          name={targetAddressProperty}
          rules={{ required: required || (startAddressId !== undefined && startAddressId !== null) }}
        />
      </Grid>
      {optionalAddressIds &&
        optionalAddressIds.map((_address, index) => {
          return (
            <ProductRoutingAddressesFormAdditionalAddresses
              key={index}
              index={index}
              level={level}
              groupId={groupId}
              targetAddressOptions={optionalPrimaryTargetAddressOptions}
              optionalTargetAddressIds={optionalAddressIds}
            />
          );
        })}
      <Grid item>
        <Box display="flex" justifyContent="flex-end">
          <AddButton onClick={() => addAdditionalTargetAddress()} variant="outlined" iconSvgColor="green">
            <Typography variant="body1">{t("configuration.assignments.create.newAddress")}</Typography>
          </AddButton>
        </Box>
      </Grid>
    </Grid>
  );
};
