import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors } from "../../../../../../style/Colors";

export const useTruckloadAnnouncedRowStyle = makeStyles((theme) => ({
  header: {
    backgroundColor: PrimaryColors.base,
  },
  content: {
    backgroundColor: Colors.grayLight,
  },
  innerHeadingContent: {
    padding: 24,
  },
  innerContent: {
    padding: "24px 24px 0px 24px",
  },
  container: {
    height: "100%",
  },
  box: {
    padding: "2px 10px 2px 10px",
    borderRadius: "4px",
  },
  statusBox: {
    backgroundColor: Colors.white,
  },
  idBox: {
    backgroundColor: Colors.grayLight,
    border: `2px solid Colors.grayLight`,
  },
  addressText: {
    color: Colors.white,
  },
  boxTextColor: {
    color: PrimaryColors.base,
    fontWeight: "bold",
  },
  buttonMobile: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      width: "100%",
    },
  },
  freshdeskButton: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  editButton: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 24,
    },
  },
}));
