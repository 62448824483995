import { useEffect, useMemo, useRef, useState, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { FormCheckbox } from "../../../../../../shared/components/form/fields/form-checkbox.field";
import { FormDatepickerField } from "../../../../../../shared/components/form/fields/form-datepicker.field";
import { FormInfiniteAutocomplete } from "../../../../../../shared/components/form/fields/form-infinite-autocomplete.component";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { CfmOrderActionType } from "../../../../../domain/order-x/actions/cfm-order-action-type";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";
import { useOrderActionChangeLogisticContext } from "../providers/order-action-change-logistic.provider";
import { useOrderXSelectedContext } from "../../../order-x-selected.provider";
import { useGetAssignableStatusForOrder } from "../../../../../repositories/queries/order-x/queries/get-order-x-assignable-status.query";
import { CfmOrderXApiStatus } from "../../../../../domain/order-x/cfm-order-x.status";

export interface IOrderChangeLogisticAction {
  groupId: number;
  changeLogisticAddressId: number;
  changeLogisticDestinationAddressId: number;
  changeLogisticRequestedPickupDate: Date;
  changeLogisticIsCustomerNotificationDisabled: boolean;
  changeLogisticIsRecyclerOrLogisticNotificationDisabled: boolean;
  changeLogisticStatus: number;
}

export const OrderChangeLogisticAction: VFC = () => {
  const { t } = useTranslation();
  const {
    setSelectedGroupId,
    filter,
    setFilter,
    groups,
    fetchNextGroupsPage,
    destinationAddresses,
    fetchNextDestinationAddressesPage,
    addresses,
    fetchNextAddressesPage,
    alternativeLogisticAddress,
  } = useOrderActionChangeLogisticContext();
  const {
    formState: { errors },
    watch,
    control,
    setValue
  } = useFormContext<ICfmResponseValueFormInputs>();
  const { order } = useOrderXSelectedContext();
  const groupId: number | undefined = watch("logisticChanged.groupId");
  const logisticLocation = watch('logisticChanged.changeLogisticAddressId');
  const currentLogisticLocation = useRef(order.logisticAddress?.id);
  const [initialLogisticLocation] = useState(order.logisticAddress?.id);
  const [statusDisabled, setStatusDisabled] = useState(false)
  const isAval = groups.find((g) => g.id === groupId)?.isAvalPartner;

  const { data: { status: statusConfiguration = []} = {}} = useGetAssignableStatusForOrder(order.id, true);

  useEffect(() => {
    setSelectedGroupId(groupId);
  }, [groupId, setSelectedGroupId]);

  useEffect(() => {
    if (groups.length > 0 && order.logisticAddress?.groupId) {
      setValue("logisticChanged.groupId", order.logisticAddress?.groupId );
    }
  }, [groups, setValue, order.logisticAddress?.groupId]);

  useEffect(() => {
    // reset address if group changed, as selected address is not in new group
    if (addresses.length !== 0 && currentLogisticLocation.current) {
      const hasLocation = addresses.find(({id}) => currentLogisticLocation.current === id);
      const locationId = hasLocation ? currentLogisticLocation.current : 0;
      setValue('logisticChanged.changeLogisticAddressId', locationId);
    }
  }, [groupId, addresses, setValue, currentLogisticLocation]);

  useEffect(() => {
    if (logisticLocation) {
      currentLogisticLocation.current = logisticLocation;
    }
    if (initialLogisticLocation !== currentLogisticLocation.current && isAval) {
      // GRS-298: new Aval transaction can only be in state ordered
      setValue('logisticChanged.changeLogisticStatus', CfmOrderXApiStatus.Ordered);
      // disable status selection if logistic location is changed to Aval partner
      setStatusDisabled(true);
    } else {
      // logistic location set to initial value, reset status to initial value
      setValue('logisticChanged.changeLogisticStatus', order.status)
      setStatusDisabled(false);
    }
  }, [setValue, logisticLocation, initialLogisticLocation, isAval, order.status]);

  useEffect(() => {
    if (destinationAddresses.length > 0 && order.destinationAddress?.id) {
      setValue('logisticChanged.changeLogisticDestinationAddressId', order.destinationAddress?.id);
    }
  }, [destinationAddresses, setValue, order.destinationAddress?.id]);

  const onTextChangeDebounced = useDebouncedCallback(
    (text: string | undefined, type: "group" | "logisticAddress" | "destinationAddress") => {
      switch (type) {
        case "group":
          setFilter({ ...filter, groupFilter: { search: text } });
          break;
        case "logisticAddress":
          setFilter({ ...filter, logisticAddressFilter: { search: text } });
          break;
        case "destinationAddress":
          setFilter({ ...filter, destinationAddressFilter: { search: text } });
          break;
      }
    },
    200,
  );

  const resetSearchFilter = (type: "group" | "logisticAddress" | "destinationAddress") => {
    switch (type) {
      case "group":
        setFilter({ ...filter, groupFilter: { search: undefined } });
        break;
      case "logisticAddress":
        setFilter({ ...filter, logisticAddressFilter: { search: undefined } });
        break;
      case "destinationAddress":
        setFilter({ ...filter, destinationAddressFilter: { search: undefined } });
        break;
    }
  };

  const groupsForSelect = useMemo(() => {
    const alternativeGroup = alternativeLogisticAddress?.group;
    if (!alternativeLogisticAddress || !alternativeGroup) return groups;

    const filteredGroups = groups.filter((g) => g.id !== alternativeGroup.id);
    filteredGroups.unshift(alternativeGroup);
    return filteredGroups;
  }, [alternativeLogisticAddress, groups]);

  return (
    <>
      <FormField md={12} className={"pb-4"}>
        <FormInfiniteAutocomplete<number>
          name={"logisticChanged.groupId"}
          control={control}
          label={t("general.text.logistic")}
          options={groupsForSelect.map((og) => og.id)}
          getOptionLabel={(value) => {
            const group = groupsForSelect.find((og) => og.id === value);
            if (group) {
              if (group.isAvalPartner) {
                return `(${group.id}) ${group.name} - (AvaL)`;
              } else {
                return `(${group.id}) ${group.name}`;
              }
            }
          }}
          error={Boolean(errors?.logisticChanged?.groupId)}
          rules={{ required: true }}
          fetchNextPage={() => {
            fetchNextGroupsPage();
          }}
          onSearchChange={(text) => onTextChangeDebounced(text, "group")}
          onTextfieldBlur={() => resetSearchFilter("group")}
        />
      </FormField>
      <FormField md={12} className={"pb-4"}>
        <FormInfiniteAutocomplete<number>
          name={"logisticChanged.changeLogisticAddressId"}
          control={control}
          label={t("basedata.addresses.type.logistic_location")}
          options={addresses.map((a) => a.id)}
          getOptionLabel={(value) => {
            const address = addresses.find((address) => address.id === value);
            return address ? getFormattedDomainAddress(address, t) : '';
          }}
          disabled={!groupId}
          error={Boolean(errors?.logisticChanged?.changeLogisticAddressId)}
          rules={{ required: true }}
          fetchNextPage={() => {
            fetchNextAddressesPage();
          }}
          onSearchChange={(text) => onTextChangeDebounced(text, "logisticAddress")}
          onTextfieldBlur={() => resetSearchFilter("logisticAddress")}
        />
      </FormField>
      <FormField md={12} className={"pb-4"}>
        <FormInfiniteAutocomplete<number>
          name={"logisticChanged.changeLogisticStatus"}
          control={control}
          label={t("orders.statusLabel")}
          error={Boolean(errors?.logisticChanged?.changeLogisticDestinationAddressId)}
          options={ isAval?  [CfmOrderXApiStatus.Ordered] : statusConfiguration}
          getOptionLabel={(value) => t(`orders.status.${CfmOrderXApiStatus[value]}`)}
          fetchNextPage={() => {}}
          rules={{ required: true }}
          disabled={ statusDisabled }
          onSearchChange={() => {}} onTextfieldBlur={() => {}}/>
      </FormField>

      <FormField md={12} className={"pb-4"}>
        <FormInfiniteAutocomplete<number>
          name={"logisticChanged.changeLogisticDestinationAddressId"}
          control={control}
          label={t("orders.truckload.form.targetAddress")}
          options={destinationAddresses.map((a) => a.id)}
          getOptionLabel={(value) => {
            const address = destinationAddresses.find((address) => address.id === value);
            if (address) {
              return getFormattedDomainAddress(address, t);
            }
          }}
          error={Boolean(errors?.logisticChanged?.changeLogisticDestinationAddressId)}
          rules={{ required: true }}
          fetchNextPage={async () => await fetchNextDestinationAddressesPage()}
          onSearchChange={(text) => onTextChangeDebounced(text, "destinationAddress")}
          onTextfieldBlur={() => resetSearchFilter("destinationAddress")}
        />
      </FormField>
      <FormField md={12} className={"pb-4"}>
        <FormDatepickerField
          label={t("orders.new.wizard.submit.requestedPickupDate")}
          hasError={Boolean(errors?.logisticChanged?.changeLogisticRequestedPickupDate)}
          control={control}
          name={"logisticChanged.changeLogisticRequestedPickupDate"}
          disabled={ isAval === true }
        />
      </FormField>
      <FormField md={12}>
        <FormCheckbox
          name={"logisticChanged.changeLogisticIsCustomerNotificationDisabled"}
          control={control}
          label={t(`orders.responseDialog.${CfmOrderActionType.ChangeLogistic}.isCustomerNotificationDisabled`)}
          noMargin
        />
      </FormField>
      <FormField md={12}>
        <FormCheckbox
          name={"logisticChanged.changeLogisticIsRecyclerOrLogisticNotificationDisabled"}
          control={control}
          label={t(
            `orders.responseDialog.${CfmOrderActionType.ChangeLogistic}.isRecyclerOrLogisticNotificationDisabled`,
          )}
          noMargin
        />
      </FormField>
    </>
  );
};
