import { IPomManufacturer } from "put-on-market/domain/models/manufacturer/manufacturer";
import React, { createContext, useContext } from "react";
import useAsyncEffect from "use-async-effect";
import { useGetManufacturerQuery } from "../../repositories/queries/get-manufacturers.query";
import { ManufacturerFormMode } from "./manufacturer-form.component";

export interface IManufacturerDetailParams {
  id: string | undefined;
}

interface IManufacturerDetailProvider {
  isLoading: boolean;
  manufacturer: IPomManufacturer | undefined;
}

export interface IManufacturerDetailProps {
  mode: ManufacturerFormMode.Edit | ManufacturerFormMode.Detail;
}

export const ManufacturerDetailContext = createContext<IManufacturerDetailProvider>({} as IManufacturerDetailProvider);

export const useManufacturerDetailContext = () => {
  return useContext(ManufacturerDetailContext);
};

const useManufacturerDetailProvider = ({
  manufacturerId,
}: IManufacturerDetailProviderProps): IManufacturerDetailProvider => {
  const { data: manufacturer, isLoading: loading, refetch: getManufacturer } = useGetManufacturerQuery(manufacturerId);

  useAsyncEffect(async () => {
    if (!manufacturerId) return;
    await getManufacturer();
  }, [manufacturerId]);

  return {
    isLoading: loading,
    manufacturer,
  };
};

interface IManufacturerDetailProviderProps {
  manufacturerId: number | undefined;
}

export const ManufacturerDetailProvider: React.FC<IManufacturerDetailProviderProps> = (props) => {
  const value = useManufacturerDetailProvider(props);
  return <ManufacturerDetailContext.Provider value={value}>{props.children}</ManufacturerDetailContext.Provider>;
};
