import { IBattGContactFormInputs } from "../../../components/batt-g/batt-g-contact-form.component";
import { IBattGRegistrationContactPerson } from "../../../models/batt-g/batt-g-registration";
import { IBattGRegistrationContactPersonModel } from "../../../repositories/models/batt-g/batt-g-registration.model";
import { IBattGContactPersonRequestData } from "../../../repositories/requests/batt-g/batt-g-registration.request";

export class BattGContactPersonConverter {
  public static toDomain(model: IBattGRegistrationContactPersonModel): IBattGRegistrationContactPerson {
    return {
      email: model.email,
      firstName: model.firstName,
      jobTitle: model.jobTitle,
      lastName: model.lastName,
      title: model.title,
      telephone: model.telephone,
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
    };
  }

  public static formInputToRequest(inputs: IBattGContactFormInputs): IBattGContactPersonRequestData {
    return {
      email: inputs.email,
      firstName: inputs.firstName,
      jobTitle: inputs.jobTitle,
      lastName: inputs.lastName,
      telephone: inputs.telephone,
      title: inputs.title,
    };
  }

  public static toFormInput(domain: IBattGRegistrationContactPerson): IBattGContactFormInputs {
    return {
      email: domain.email,
      firstName: domain.firstName,
      jobTitle: domain.jobTitle,
      lastName: domain.lastName,
      title: domain.title,
      telephone: domain.telephone,
    };
  }
}
