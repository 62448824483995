import lodash from "lodash";
import moment from "moment";
import config from "../config/config";
import { IUser } from "../shared/domain/user/user";

export interface IAuthConfig {
  expirationKey: string;
  tokenKey: string;
  rolesKey: string;
  expirationNumber: any;
}

export enum LoginOperationResult {
  Success = 200,
  WrongLoginData = 401,
  NoContracts = 403,
  UserLocked = 423,
  TooManyRequest = 429,
  UnexpectedError = 500,
}

export interface ILoginResult {
  result: LoginOperationResult;
  token?: string;
  user?: IUser;
}

const { auth } = config;
const { expirationKey, tokenKey, rolesKey } = auth as IAuthConfig;

export enum StoreKeys {
  TOKEN = "wbt:token",
}

export const isAuthExpired = () => {
  if (typeof Storage !== "undefined") {
    try {
      const timeStamp = localStorage.getItem(expirationKey);
      const expiresAt = moment(timeStamp, "x");
      if (!timeStamp) {
        clearLogin();
        return true;
      }

      const isExpired = moment().isAfter(expiresAt);
      if (isExpired) {
        clearLogin();
        return true;
      }
      return false;
    } catch (ex) {
      return true;
    }
  } else {
    return false;
  }
};

export const clearLogin = () => {
  localStorage.removeItem(expirationKey);
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(rolesKey);
  localStorage.removeItem("paths");
  localStorage.removeItem("auth");
  localStorage.removeItem("simple_values");
  localStorage.removeItem("locale");
};

export const isUserLoggedIn = () => {
  return !lodash.isNil(localStorage.getItem(StoreKeys.TOKEN)) && !isAuthExpired();
};
