import { makeStyles } from "@material-ui/core";

export const useGroupHeaderStyle = makeStyles((theme) => ({
  createHeader: {
    marginTop: 20,
  },
  detailHeader: {
    marginTop: 15,
  },
}));
