import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ChipFilter, IOption } from "../../../../../components/Filters/ChipFilter";
import { BattGBrandStatus, BattGBrandStatusFilter } from "../../../../models/batt-g/batt-g-brand";
import { useBattGBrandContext } from "./batt-g-brand.provider";
import { BattGBrandUtil } from "./batt-g-brand.util";

export const BattGBrandFilter: FunctionComponent = () => {
  const { filters, setFilters, setPage } = useBattGBrandContext();
  const { t } = useTranslation();
  const options: IOption[] = [
    {
      displayValue: t(BattGBrandUtil.getBrandStatusTranslation(BattGBrandStatus.Published)),
      value: BattGBrandStatusFilter.Published,
    },
    {
      displayValue: t(BattGBrandUtil.getBrandStatusTranslation(BattGBrandStatus.Draft)),
      value: BattGBrandStatusFilter.Draft,
    },
    {
      displayValue: t("batt_g.brand.status.deactivated"),
      value: BattGBrandStatusFilter.Deactivated,
    },
  ];

  return (
    <ChipFilter
      possibleOptions={options}
      label={t("basedata.users.table.filter.status.text")}
      multiple={true}
      filter={filters}
      setFilter={(filter: BattGBrandStatusFilter) => {
        const newFilters = [...filters];

        const indexOfActiveFilter = filters.indexOf(filter, 0);
        if (indexOfActiveFilter !== -1) {
          newFilters.splice(indexOfActiveFilter, 1);
        } else {
          newFilters.push(filter);
        }

        setFilters(newFilters);
        setPage(0);
      }}
    />
  );
};
