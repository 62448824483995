import { Box, Card, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useMemo, useState, VFC } from "react";
import { SubmitHandler } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ForwardButton, MailIconButton } from "../../../../../../components/Primitives/Buttons";
import { Badge } from "../../../../../../configurator/components/badge/badge.component";
import { useAuthContext } from "../../../../../../providers/Auth/auth.provider";
import { ICreateFreshdeskTicketRequest } from "../../../../../../shared/domain/requests/create-freshdesk-ticket.request";
import { isCfmRecycler } from "../../../../../../shared/domain/user/user";
import { useCreateFreshdeskTicketQuery } from "../../../../../../shared/repositories/queries/freshdesk/create-freshdesk-ticket.query";
import { formatDateShort } from "../../../../../../shared/util/date.util";
import { Colors, PrimaryColors } from "../../../../../../style/Colors";
import { getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { AvailableCfmRoutes } from "../../../../../../utils/constants";
import { ICfmTruckload } from "../../../../../domain/truckload/cfm-truckload";
import { getTranslatedStatus, getTruckloadNumber } from "../../../utils/truckload.utils";
import { FreshdeskForm, IOrderXFreshdeskFormInputs } from "../../order-freshdesk-form/freshdesk-form.component";
import { useTruckloadAnnouncedRowStyle } from "./truckload-announced.style";
import { IdBox } from "../../id-box.component";

interface ITruckloadAnnouncedRowProps {
  truckload: ICfmTruckload;
}

export const TruckloadAnnouncedRow: VFC<ITruckloadAnnouncedRowProps> = (props) => {
  const { truckload } = props;
  const classes = useTruckloadAnnouncedRowStyle();
  const history = useHistory();
  const { t } = useTranslation();
  const [isOrderFreshdeskFormOpen, setIsOrderFreshdeskFormOpen] = useState(false);
  const { internalUser } = useAuthContext();
  const { mutateAsync: createFreshdeskTicket } = useCreateFreshdeskTicketQuery();

  const plannedDeliveryDate = useMemo(() => {
    return truckload.plannedDeliveryDate ? formatDateShort(truckload.plannedDeliveryDate) : "-";
  }, [truckload]);

  const getSubjectText = () => {
    return t("orders.truckloadAnnounced.freshdeskSupport", {
      truckloadId: truckload.id,
      status: getTranslatedStatus(truckload, t),
    });
  };

  const onFreshdeskSupportSubmit: SubmitHandler<IOrderXFreshdeskFormInputs> = async (inputs): Promise<boolean> => {
    const requestData: ICreateFreshdeskTicketRequest = {
      subject: getSubjectText(),
      description: `${inputs.text} \n\n ${t("order_view.contact", { mail: inputs.contactMail })}`,
    };

    await createFreshdeskTicket({ createData: requestData });
    return true;
  };

  const onCancel = () => {
    setIsOrderFreshdeskFormOpen(false);
  };

  return (
    <>
      <FreshdeskForm
        isOpen={isOrderFreshdeskFormOpen}
        subject={getSubjectText()}
        onCancel={onCancel}
        onSubmit={onFreshdeskSupportSubmit}
        disableSuccessNotification
      />
      <Card elevation={0}>
        <Grid container direction="column">
          <Grid item className={classes.header}>
            <Grid container direction="row" justifyContent="space-between" className={classes.innerHeadingContent}>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Box className={classNames(classes.box, classes.statusBox)}>
                      <Typography variant="body1" className={classes.boxTextColor}>
                        {getTranslatedStatus(truckload, t)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <IdBox id={getTruckloadNumber(truckload.id)} />
                  </Grid>
                  <Grid item>
                    <Box display={"flex"} alignItems={"center"} className={classes.container}>
                      <Typography variant="body1" className={classes.addressText}>
                        {t("orders.truckloadAnnounced.targetAddress", {
                          addressText: truckload.targetAddress
                            ? getFormattedDomainAddress(truckload.targetAddress, t)
                            : "-",
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box display={"flex"} alignItems={"center"} className={classes.container}>
                  <Badge count={truckload.orderCount} backgroundColor={Colors.white} textColor={PrimaryColors.base} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            <Grid container direction="row" className={classes.innerContent}>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="body1">
                      {t("orders.truckloadAnnounced.announcedAt", { date: plannedDeliveryDate })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      <Trans i18nKey="orders.truckload.truckloadTransportWeight">
                        {{ weight: truckload.calculatedWeight?.toLocaleString() ?? 0 }}
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignContent="center"
                  className={classes.container}
                >
                  <Grid item xs={12} md="auto" className={classes.freshdeskButton}>
                    <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
                      <MailIconButton
                        variant="outlined"
                        onClick={() => setIsOrderFreshdeskFormOpen(true)}
                        className={classes.buttonMobile}
                      >
                        <Typography>{t("general.contact_grs")}</Typography>
                      </MailIconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md="auto" className={classes.editButton}>
                    <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
                      <ForwardButton
                        variant="outlined"
                        onClick={() => {
                          history.push(AvailableCfmRoutes.TruckloadEdit.replace(":id", truckload.id.toString()));
                        }}
                        className={classes.buttonMobile}
                      >
                        <Typography variant="body1">
                          {isCfmRecycler(internalUser)
                            ? t("orders.truckloadAnnounced.editOrRespond")
                            : t("orders.truckloadAnnounced.edit")}
                        </Typography>
                      </ForwardButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
