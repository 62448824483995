import dayjs from "dayjs";
import { IAddress } from "../../../../shared/domain/address/address";
import { IGroup } from "../../../../shared/domain/group/group";
import { IPaginationMeta } from "../../../../shared/domain/pagination/pagination-meta";

export enum PomManufacturerType {
  RepresentedCompany = "represented_company",
}

export interface IPomManufacturerList {
  items: IPomManufacturer[];
  meta: IPaginationMeta;
}

export interface IPomManufacturer {
  id: number;
  uuid: string;
  type: PomManufacturerType;
  groupId: number | undefined;
  group: IGroup | undefined;
  name: string;
  addressId: number | undefined;
  address: IAddress | undefined;
  externalId: string | null;
  battregNumber: string | null;
  contractStartsAt: Date;
  contractEndsAt: Date | undefined;
}

export const isActiveManufacturer = (manufacturer: IPomManufacturer): boolean => {
  const isActive =
    (manufacturer.contractStartsAt &&
      manufacturer.contractEndsAt &&
      dayjs(manufacturer.contractStartsAt).isBefore(new Date()) &&
      dayjs(manufacturer.contractEndsAt).isAfter(new Date())) ||
    (manufacturer.contractStartsAt &&
      dayjs(manufacturer.contractStartsAt).isBefore(new Date()) &&
      !manufacturer.contractEndsAt);

  return isActive;
};
