import { ThemeProvider } from "@material-ui/styles";
import { PlatformProvider } from "providers/Auth/platform.provider";
import { Redirect, Route, Switch } from "react-router-dom";
import { UnlockPage } from "shared/pages/password-reset/unlock.page";
import { AvailableSharedRoutes } from "utils/constants";
import MuiTheme from "./config/theme";
import { Portal } from "./portal";
import { LoginAsProvider } from "./providers/Auth/login-as-context";
import { ValidationProvider } from "./providers/Validation/validation-context";
import { ScrollToTopProvider } from "./shared/domain/scroll/scroll-to-top-context";
import { LockedPage as LockedOutPage } from "./shared/pages/locked-out/locked-out.page";
import { Login } from "./shared/pages/login/login.page";

const Routes = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <ValidationProvider>
        <ScrollToTopProvider>
          <LoginAsProvider>
            <PlatformProvider>
              <Switch>
                <Route path={AvailableSharedRoutes.Portal} component={Portal} />
                <Route path={AvailableSharedRoutes.LockedOut} exact component={LockedOutPage} />
                <Route path={AvailableSharedRoutes.Unlock} exact component={UnlockPage} />
                <Route path={["/", AvailableSharedRoutes.Login]} exact component={Login} />
                <Redirect path="*" to={AvailableSharedRoutes.Login} push />
              </Switch>
            </PlatformProvider>
          </LoginAsProvider>
        </ScrollToTopProvider>
      </ValidationProvider>
    </ThemeProvider>
  );
};

export default Routes;
