import { Grid, IconButton, Tooltip } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../../components/Primitives";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { isAdmin } from "../../../domain/user/user";
import { useGroupDetailContext } from "../../../pages/groups/group-detail.provider";
import { useGetGroupNextContractNumberQuery } from "../../../repositories/queries/group/get-group-next-contract-number.query";
import { FormDatepickerField } from "../../form/fields/form-datepicker.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { CreateFreshdeskTicketDialog } from "../../freshdesk/create-freshdesk-ticket-dialog.component";
import { InputLoadingSpinner } from "../../loading/input-loading-spinner.component";
import { GroupContractFormMode, IGroupContractFormInputs } from "./group-contract-form.component";

interface IGroupContractBaseFormProps {
  mode: GroupContractFormMode;
  contractNumberError?: boolean;
}

export const GroupContractBaseForm: FC<IGroupContractBaseFormProps> = (props) => {
  const { mode, contractNumberError } = props;
  const { t } = useTranslation();
  const { refetch: getNextContractNumber, isLoading: isNextContractNumberLoading } = useGetGroupNextContractNumberQuery(
    { enabled: false },
  );
  const { internalUser } = useAuthContext();
  const readOnly = mode === GroupContractFormMode.Detail;
  const [hasContractNumberChanged, setHasContractNumberChanged] = useState(false);
  const [isFreshdeskDialogOpen, setIsFreshdeskDialogOpen] = useState<boolean>(false);
  const { group } = useGroupDetailContext();
  const {
    formState: { errors },
    control,
    register,
    setValue,
    watch,
  } = useFormContext<IGroupContractFormInputs>();

  const contractNumber: string | undefined = watch("contractNumber");

  const isContractNumberDisabled = useMemo(() => {
    return readOnly || isNextContractNumberLoading || mode === GroupContractFormMode.Edit;
  }, [readOnly, isNextContractNumberLoading, mode]);

  const fetchAndSetNextContractNumber = useCallback(async () => {
    const contractNumber = await getNextContractNumber();
    setValue("contractNumber", `BV${contractNumber.data}`);
    setHasContractNumberChanged(true);
  }, [getNextContractNumber, setValue]);

  const getRefreshIcon = (): ReactNode => {
    return (
      <Tooltip title={t("basedata.groups.groupContracts.refreshContractNumber")}>
        <IconButton onClick={fetchAndSetNextContractNumber}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const getUpdateIcon = (): ReactNode => {
    return (
      <Tooltip title={t("basedata.groups.edit_erp_number")}>
        <IconButton
          onClick={() => {
            setIsFreshdeskDialogOpen(true);
          }}
        >
          <EditIcon svgColor={"green"} />
        </IconButton>
      </Tooltip>
    );
  };

  const getContractNumberEndAdornment = (): ReactNode => {
    if (isNextContractNumberLoading) {
      return <InputLoadingSpinner />;
    }
    if (mode === GroupContractFormMode.Edit && isAdmin(internalUser)) {
      return getUpdateIcon();
    }

    if (mode === GroupContractFormMode.Create) {
      return getRefreshIcon();
    }
  };

  return (
    <>
      <CreateFreshdeskTicketDialog
        handleClose={() => {
          setIsFreshdeskDialogOpen(false);
        }}
        isOpen={isFreshdeskDialogOpen}
        groupName={group.name}
        currentFieldValue={`${t("freshdesk.createTicketDialog.currentContractNumber")}: ${contractNumber}`}
        newFieldLabel={t("freshdesk.createTicketDialog.newContractNumber")}
        subject={t("freshdesk.createTicketDialog.titleContractNumber")}
        groupId={group.id}
      />
      <FormField>
        <FormTextField
          required={true}
          hasError={Boolean(errors?.contractNumber) || (contractNumberError && !hasContractNumberChanged)}
          label={t("basedata.groups.groupContracts.contractNumber")}
          disabled={isContractNumberDisabled}
          maxLength={20}
          inputProps={{
            endAdornment: getContractNumberEndAdornment(),
          }}
          control={control}
          name={"contractNumber"}
          rules={{
            required: true,
            onChange: () => setHasContractNumberChanged(true),
          }}
        />
      </FormField>
      <Grid item md={6} />
      <FormField>
        <FormDatepickerField
          control={control}
          label={t("basedata.groups.groupContracts.startDate")}
          disabled={readOnly}
          hasError={Boolean(errors?.startDate)}
          required={true}
          {...register("startDate", {
            required: true,
          })}
        />
      </FormField>
      <FormField>
        <FormDatepickerField
          control={control}
          label={t("basedata.groups.groupContracts.endDate")}
          disabled={readOnly}
          hasError={Boolean(errors?.endDate)}
          required={false}
          {...register("endDate", {
            required: false,
          })}
        />
      </FormField>
    </>
  );
};
