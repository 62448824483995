import { createContext, useContext } from "react";
import { useGetConfig } from "../../repositories/queries/group/get-config.query";
import { IConfig } from "./config";

interface IConfigContextType {
  appConfig: IConfig | undefined;
}

const ConfigContext = createContext<IConfigContextType>({} as IConfigContextType);

export const ConfigProvider = (props: any) => {
  const value = useConfigProvider();
  return <ConfigContext.Provider value={value}>{props.children}</ConfigContext.Provider>;
};

export const useConfigContext = () => {
  return useContext(ConfigContext);
};

const useConfigProvider = (): IConfigContextType => {
  const { data: appConfig } = useGetConfig();
  return { appConfig };
};
