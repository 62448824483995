import { VFC } from "react";
import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";
import { OrderChangeLogisticAction } from "./order-state-actions/order-x-change-logistic-action.component";
import { OrderXChangePlannedPickupAction } from "./order-state-actions/order-x-change-planned-pickup.component";
import { OrderXDestinationAnnouncedAction } from "./order-state-actions/order-x-destination-announced-action.component";
import { OrderXLogisticAnnouncedAction } from "./order-state-actions/order-x-logistic-announced-action.component";
import { OrderXLogisticAnnouncedSingleOrderAction } from "./order-state-actions/order-x-logistic-announced-single-order-action.component";
import { OrderLogisticMistripAction } from "./order-state-actions/order-logistic-mistrip-action.component";
import { OrderXLogisticPerformedOrderAction } from "./order-state-actions/order-x-logistic-performed-action.component";
import { OrderXLogisticPerformedWarehouseAction } from "./order-state-actions/order-x-logistic-warehouse-action.component";
import { OrderActionChangeLogisticProvider } from "./providers/order-action-change-logistic.provider";

interface IOrderXActionTypeInputProps {
  actionType: CfmOrderActionType;
}

export const OrderXActionTypeInput: VFC<IOrderXActionTypeInputProps> = (props) => {
  const { actionType } = props;

  switch (actionType) {
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.DestinationDeclined:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.Confirmed:
    case CfmOrderActionType.Canceled:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
      break;
    case CfmOrderActionType.LogisticAnnounced:
      return <OrderXLogisticAnnouncedAction />;
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
      return <OrderXLogisticAnnouncedSingleOrderAction />;
    case CfmOrderActionType.LogisticPerformed:
      return <OrderXLogisticPerformedOrderAction />;
    case CfmOrderActionType.DestinationAnnounced:
      return <OrderXDestinationAnnouncedAction />;
    case CfmOrderActionType.ChangeLogistic:
      return (
        <OrderActionChangeLogisticProvider>
          <OrderChangeLogisticAction />
        </OrderActionChangeLogisticProvider>
      );
    case CfmOrderActionType.LogisticWarehouse:
      return <OrderXLogisticPerformedWarehouseAction />;
    case CfmOrderActionType.ChangePlannedPickup:
      return <OrderXChangePlannedPickupAction />;
    case CfmOrderActionType.LogisticMistrip:
      return <OrderLogisticMistripAction />;
  }
  return <></>;
};
