import { CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { ForwardButton } from "../../../../components/Primitives/Buttons";
import useAsyncEffect from "../../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { usePackageOverviewContext } from "../../../pages/package/package-overview.provider";
import { useProductOverviewContext } from "../../../pages/product/product-overview.provider";
import { DefaultProductRow } from "./default-product-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
  buttonRow: {
    marginTop: 30,
    marginBottom: 180,
  },
  checkboxContainer: {
    marginLeft: 22,
  },
  container: {
    marginTop: 20,
  },
}));

interface IDefaultProductListProps {
  onContinue: (selectedProductIds: number[]) => void;
  onContinueText: string;
}

export const DefaultProductList: VFC<IDefaultProductListProps> = (props) => {
  const { onContinue, onContinueText } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const {
    hasNextPage,
    productsLoading: isLoading,
    fetchNextPage,
    products,
    isFetching,
    isFetchingNextPage,
  } = useProductOverviewContext();
  const { selectedProductIds, setSelectedProductIds } = usePackageOverviewContext();

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  const areAllSelected = () => {
    const ids = products.map((product) => product.id);
    return selectedProductIds.length > 0 && ids.every((productId) => selectedProductIds.includes(productId));
  };

  const onSelectAllClick = (checked: boolean) => {
    const ids = checked ? products.map((product) => product.id) : [];
    setSelectedProductIds(ids);
  };

  const onProductCheckboxClick = (productId: number, checked: boolean) => {
    if (checked) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(selectedProductIds.filter((selectedProductId) => selectedProductId !== productId));
    }
  };

  const isProductSelected = (productId: number) => {
    return selectedProductIds.find((selectedProductId) => selectedProductId === productId) !== undefined;
  };

  return (
    <Grid container direction="column" className={classes.container}>
      {!isLoading &&
        products &&
        products.map((product, index) => {
          return (
            <Grid item key={index}>
              <DefaultProductRow
                product={product}
                isSelected={isProductSelected(product.id)}
                onProductCheckboxClick={onProductCheckboxClick}
              />
            </Grid>
          );
        })}
      {(isLoading || isFetching || isFetchingNextPage) && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.alignSpinner}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item className={classes.buttonRow}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.checkboxContainer}>
            <OrderCheckbox
              label={areAllSelected() ? t("general.deselectAll") : t("general.selectAll")}
              onChange={onSelectAllClick}
              checked={areAllSelected()}
              size="large"
            />
          </Grid>
          <Grid item>
            <ForwardButton onClick={() => onContinue(selectedProductIds)} disabled={selectedProductIds.length === 0}>
              <Typography variant="body1">{onContinueText}</Typography>
            </ForwardButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
