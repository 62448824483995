import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AddIconButton, AddressMarkerIcon } from "../../../../../components/Primitives";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { Colors } from "../../../../../style/Colors";
import {
  getAddressBaseTextParts,
  getOpeningHoursTextForTooltip,
  trimOpeningHoursTextIfRequired,
} from "../../../../../utils/address.util";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { removeNullAndUndefined } from "../../../../../utils/filter.util";
import { AppTable } from "../../../../components/tables/app-table.component";
import { NewOrderToolTip } from "../../../../components/tooltips/order-new-tooltip.component";
import { IAddressLean, IAddressLeanResult } from "../../../../domain/address/address-lean";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { GroupDetailHeader } from "../group-detail-header.component";

const useStyles = makeStyles((theme: Theme) => ({
  disabledAddressText: {
    color: Colors.gray,
  },
  textAlign: {
    textAlign: "center",
  },
  addressIcon: {
    height: 40,
    width: 29,
  },
}));

interface IGroupDetailsAddressesBaseTableProps {
  addressesResult: IAddressLeanResult | undefined;
  onAddClick?: VoidFunction;
  onRowClicked: (user: IAddressLean | undefined) => void;
  isLoading: boolean;
  setSearchText: (text: string | undefined) => void;
  paginationInputMeta: IPaginationInputMeta;
  setPaginationInputMeta: (inputMeta: IPaginationInputMeta) => void;
}

export const GroupDetailsAddressesBaseTable: VFC<IGroupDetailsAddressesBaseTableProps> = (props) => {
  const {
    addressesResult,
    onAddClick,
    onRowClicked,
    isLoading,
    setSearchText,
    paginationInputMeta,
    setPaginationInputMeta,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { isMobile, isTablet } = useScreenSize();

  const onSearchSubmit = (value: string | undefined) => {
    setSearchText(value);
  };

  const searchFieldProps = {
    tooltip: t("basedata.groups.detail.searchWasteProducerAddress.tooltip"),
    placeholder: t("basedata.groups.detail.searchWasteProducerAddress.text"),
    onSearchSubmit,
  };

  const getAddressInfoText = useCallback(
    (address: IAddressLean) => {
      return getAddressBaseTextParts(address, t).join(`${TEXT_SEPARATOR} `);
    },
    [t],
  );

  const getAddressContactText = (address: IAddressLean) => {
    const textParts: string[] = [];

    if (address.contactPersonName) {
      textParts.push(address.contactPersonName);
    }
    if (address.contactPersonEmail) {
      textParts.push(address.contactPersonEmail);
    }
    if (address.contactPersonTelephone) {
      textParts.push(address.contactPersonTelephone);
    }
    if (address.openingHours) {
      textParts.push(trimOpeningHoursTextIfRequired(address.openingHours, 50));
    }

    return textParts.join(`${TEXT_SEPARATOR} `);
  };

  const getAddressLabelComponent = useCallback(
    (address: IAddressLean) => {
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography
              variant="body1"
              className={classNames({
                [classes.disabledAddressText]: address.active == null,
                [classes.textAlign]: isMobile,
              })}
            >
              <b>{`(${address.id}) ${address.name ?? "-"}`}</b>
              {TEXT_SEPARATOR} {getAddressInfoText(address)}
            </Typography>
          </Grid>
          <Grid item>
            <NewOrderToolTip title={getOpeningHoursTextForTooltip(address.openingHours ?? undefined, t, 50)}>
              <Typography
                variant="body1"
                className={classNames({
                  [classes.disabledAddressText]: address.active == null,
                  [classes.textAlign]: isMobile,
                })}
              >
                {getAddressContactText(address)}
              </Typography>
            </NewOrderToolTip>
          </Grid>
        </Grid>
      );
    },
    [classes.disabledAddressText, classes.textAlign, getAddressInfoText, isMobile, isTablet, t],
  );

  const mappedEntries = useMemo(() => {
    const addresses = addressesResult?.addresses;
    if (!addresses) return [];

    return addresses
      .filter((address) => address.id)
      .map((address) => {
        if (!address.id) return undefined;

        return {
          id: address.id,
          labelComponent: getAddressLabelComponent(address),
          iconComponent: <AddressMarkerIcon className={classes.addressIcon} />,
          isActive: address.active != null ? true : false,
        };
      })
      .filter(removeNullAndUndefined);
  }, [addressesResult?.addresses, getAddressLabelComponent, classes.addressIcon]);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <GroupDetailHeader>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h1">{t("basedata.addresses.groupDetailAddress.header")}</Typography>
              </Grid>
              {onAddClick && (
                <Grid item>
                  <AddIconButton onClick={onAddClick} />
                </Grid>
              )}
            </Grid>
          </GroupDetailHeader>
        </Grid>
        <Grid item>
          <AppTable
            entries={mappedEntries}
            searchFieldProps={searchFieldProps}
            paginationInputMeta={paginationInputMeta}
            setPaginationInputMeta={setPaginationInputMeta}
            numberOfRecords={addressesResult?.meta?.numberOfRecords ?? 0}
            onRowClicked={(tableRow) => {
              const detail = (addressesResult?.addresses ?? []).find(
                (address) => address.id && address.id === tableRow.id,
              );
              onRowClicked(detail);
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
