import { Grid, makeStyles, Switch, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { BORDER_RADIUS } from "../../../config/theme/overrides/constant";
import { Colors, PrimaryColors } from "../../../style/Colors";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  switchRootBase: {
    borderRadius: BORDER_RADIUS,
    width: 52,
    height: 32,
  },
  switchRootActive: {
    border: `2px solid ${PrimaryColors.base}`,
    backgroundColor: Colors.white,
  },
  switchRootDisabled: {
    border: `2px solid ${Colors.disabled}`,
  },
  switchRootInactive: {
    border: `2px solid ${Colors.gray}`,
    backgroundColor: Colors.white,
  },
  switchBase: {
    width: 30,
    paddingBottom: 22,
    paddingLeft: 0,
    paddingRight: 0,
    "&.Mui-checked": {
      transform: "translateX(18px)",
    },
  },
  switchTrack: {
    backgroundColor: Colors.white,
  },
  switchPrimaryColorBase: {
    color: Colors.gray,
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: Colors.white,
    },
    "&.Mui-checked": {
      color: PrimaryColors.base,
    },
  },
  switchPrimaryColorDisabled: {
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: Colors.disabled,
    },
  },
  switchChecked: {
    "&.MuiSwitch-track": {
      backgroundColor: Colors.white,
    },
  },
  switchThumb: {
    height: 20,
    width: 20,
  },
  label: {
    fontWeight: "bold",
    lineHeight: 2.3,
  },
}));

interface ICustomSwitchProps {
  value: boolean | undefined;
  onChange: (...event: any[]) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export const CustomSwitch: VFC<ICustomSwitchProps> = (props) => {
  const { label, disabled, required, value, onChange } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Switch
          size="medium"
          color="primary"
          disabled={disabled}
          classes={{
            root: classNames(classes.switchRootBase, {
              [classes.switchRootActive]: value === true && disabled === false,
              [classes.switchRootInactive]: value === false && disabled === false,
              [classes.switchRootDisabled]: disabled === true,
            }),
            switchBase: classes.switchBase,
            track: classes.switchTrack,
            checked: classes.switchChecked,
            thumb: classes.switchThumb,
            colorPrimary: classNames({
              [classes.switchPrimaryColorBase]: !disabled,
              [classes.switchPrimaryColorDisabled]: disabled === true,
            }),
          }}
          checked={value}
          onChange={onChange}
          disableRipple
          required={required}
        />
      </Grid>
      {label && (
        <Grid item>
          <Typography variant="body1" className={classes.label}>
            {label}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
