import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import lodash from "lodash";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { CfmOrderXApiStatus, getOrderStatusKeys } from "../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { getOrderStatusOption } from "../../../collect-from-market/domain/order-x/order-x.util";
import { CfmQueryKeys } from "../../../collect-from-market/repositories/queries/cfm-query-keys";
import { useGetProductCategoriesQuery } from "../../../collect-from-market/repositories/queries/product/query/get-product-categories.query";
import { useGetProductFractionsQuery } from "../../../collect-from-market/repositories/queries/product/query/get-product-fractions.query";
import { useGetProductStatesQuery } from "../../../collect-from-market/repositories/queries/product/query/get-product-states.query";
import { useGetProductTypesQuery } from "../../../collect-from-market/repositories/queries/product/query/get-product-types.query";
import { AvailablePlatform } from "../../../providers/Auth/platform.provider";
import { IConfigurationOption, ProductConfiguration } from "./product-configuration.component";
import {
  CfmProductArticleType,
  EMPTY_PRODUCT_CONFIG_FILTER,
  generateProductData,
  getPlatformFilterOptions,
  getProductArticleTypeFilterOptions,
  getProductConfigFilterOptions,
  IProductConfigFilter,
} from "./product.utils";
import { ResetCreateProductFilterButton } from "./reset-create-product-filter-button";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
}));

interface IProductConfigurationFilter {
  productConfigFilter: IProductConfigFilter;
  setProductConfigFilter: (data: IProductConfigFilter) => void;
  displayProductCountText: boolean;
  displayOrderStatus?: boolean;
}

export const ProductConfigurationFilter: VFC<IProductConfigurationFilter> = (props) => {
  const { productConfigFilter, setProductConfigFilter, displayProductCountText, displayOrderStatus } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { isLoading: categoriesLoading, data: productCategories } = useGetProductCategoriesQuery();
  const { isLoading: fractionsLoading, data: fractions } = useGetProductFractionsQuery();
  const { isLoading: statesLoading, data: states } = useGetProductStatesQuery();
  const { isLoading: typesLoading, data: types } = useGetProductTypesQuery();
  const queryClient = useQueryClient();

  const isLoading = useMemo(() => {
    return categoriesLoading || fractionsLoading || statesLoading || typesLoading;
  }, [categoriesLoading, fractionsLoading, statesLoading, typesLoading]);

  const isFractionsDisabled = useMemo(() => {
    return productConfigFilter.articleType === CfmProductArticleType.SingleOrderProduct;
  }, [productConfigFilter]);

  const productCount = useMemo(() => {
    return generateProductData(productConfigFilter).length;
  }, [productConfigFilter]);

  const isDirty = useMemo(
    () =>
      !lodash.isNil(productConfigFilter.articleType) ||
      !lodash.isEmpty(productConfigFilter.categories) ||
      (!isFractionsDisabled && !lodash.isEmpty(productConfigFilter.fractions)) ||
      !lodash.isEmpty(productConfigFilter.types) ||
      !lodash.isEmpty(productConfigFilter.states) ||
      !lodash.isEmpty(productConfigFilter.orderStatus),

    [productConfigFilter, isFractionsDisabled],
  );

  const onPlatformSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const option = value as IConfigurationOption | undefined;
    const platform = option ? (option.value as AvailablePlatform.Pom | AvailablePlatform.Cfm) : undefined;
    setProductConfigFilter({ ...productConfigFilter, platform });
  };

  const onArticleTypeSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const option = value as IConfigurationOption | undefined;
    const articleType = option ? (option.value as CfmProductArticleType) : undefined;
    if (articleType === CfmProductArticleType.SingleOrderProduct) {
      delete productConfigFilter.fractions;
    }
    setProductConfigFilter({ ...productConfigFilter, articleType });
  };

  const onCategorySelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const options = value as IConfigurationOption[] | undefined;
    const categoryIds = options ? options.map((option) => Number(option.value)) : [];
    const selectedCategories = (productCategories ?? []).filter((cat) => categoryIds.includes(cat.id));
    setProductConfigFilter({ ...productConfigFilter, categories: selectedCategories });
  };

  const onFractionSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const options = value as IConfigurationOption[] | undefined;
    const fractionIds = options ? options.map((option) => Number(option.value)) : [];
    const selectedFractions = (fractions ?? []).filter((fraction) => fractionIds.includes(fraction.id));
    setProductConfigFilter({ ...productConfigFilter, fractions: selectedFractions });
  };

  const onTypeSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const options = value as IConfigurationOption[] | undefined;
    const typeIds = options ? options.map((option) => Number(option.value)) : [];
    const selectedTypes = (types ?? []).filter((type) => typeIds.includes(type.id));
    setProductConfigFilter({ ...productConfigFilter, types: selectedTypes });
  };

  const onStateSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const options = value as IConfigurationOption[] | undefined;
    const stateIds = options ? options.map((option) => Number(option.value)) : [];
    const selectedStates = (states ?? []).filter((state) => stateIds.includes(state.id));
    setProductConfigFilter({ ...productConfigFilter, states: selectedStates });
  };

  const onOrderStatusSelect = (value: IConfigurationOption | IConfigurationOption[] | undefined) => {
    const options = value as IConfigurationOption[] | undefined;
    const orderStatus: CfmOrderXApiStatus[] = options
      ? options.map((option) => option.value as CfmOrderXApiStatus)
      : [];
    const selectedOrderStatus: CfmOrderXApiStatus[] = Object.values(CfmOrderXApiStatus).filter(
      (status) => typeof status !== "string" && orderStatus.includes(status),
    ) as CfmOrderXApiStatus[];
    setProductConfigFilter({ ...productConfigFilter, orderStatus: selectedOrderStatus });
  };

  return (
    <Grid container direction="row" spacing={2}>
      {isLoading && (
        <Grid item xs={12} className={classes.alignSpinner}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <>
          <Grid item xs={12}>
            <ProductConfiguration
              label={t("configuration.platforms")}
              selectedOptions={
                productConfigFilter.platform
                  ? getPlatformFilterOptions([productConfigFilter.platform as AvailablePlatform], t, false)
                  : undefined
              }
              options={getPlatformFilterOptions([AvailablePlatform.Cfm, AvailablePlatform.Pom], t, false)}
              onSelect={onPlatformSelect}
              disabled={false}
              multiSelect={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductConfiguration
              label={t("configuration.articleTypes")}
              selectedOptions={
                productConfigFilter.articleType
                  ? getProductArticleTypeFilterOptions([productConfigFilter.articleType as CfmProductArticleType], t)
                  : undefined
              }
              options={getProductArticleTypeFilterOptions(
                [CfmProductArticleType.Product, CfmProductArticleType.SingleOrderProduct],
                t,
              )}
              onSelect={onArticleTypeSelect}
              disabled={false}
              multiSelect={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductConfiguration
              label={t("configuration.categories")}
              selectedOptions={getProductConfigFilterOptions(productConfigFilter.categories)}
              options={getProductConfigFilterOptions(productCategories)}
              onSelect={onCategorySelect}
              disabled={false}
              multiSelect
            />
          </Grid>
          <Grid item xs={12}>
            <ProductConfiguration
              label={t("configuration.fractions")}
              selectedOptions={getProductConfigFilterOptions(productConfigFilter.fractions)}
              options={getProductConfigFilterOptions(fractions)}
              onSelect={onFractionSelect}
              disabled={isFractionsDisabled}
              multiSelect
            />
          </Grid>
          <Grid item xs={12}>
            <ProductConfiguration
              label={t("configuration.types")}
              selectedOptions={getProductConfigFilterOptions(productConfigFilter.types)}
              options={getProductConfigFilterOptions(types)}
              onSelect={onTypeSelect}
              disabled={false}
              multiSelect
            />
          </Grid>
          <Grid item xs={12}>
            <ProductConfiguration
              label={t("configuration.states")}
              selectedOptions={getProductConfigFilterOptions(productConfigFilter.states)}
              options={getProductConfigFilterOptions(states)}
              onSelect={onStateSelect}
              disabled={false}
              multiSelect
            />
          </Grid>
          {displayOrderStatus && (
            <Grid item xs={12}>
              <ProductConfiguration
                label={t("configuration.orderStatus")}
                selectedOptions={productConfigFilter.orderStatus?.map((status) => getOrderStatusOption(status, t))}
                options={getOrderStatusKeys(false).map((status) => getOrderStatusOption(status, t))}
                onSelect={onOrderStatusSelect}
                disabled={false}
                multiSelect
              />
            </Grid>
          )}
          {isDirty && (
            <Grid item xs={12}>
              <ResetCreateProductFilterButton
                onReset={() => {
                  setProductConfigFilter(EMPTY_PRODUCT_CONFIG_FILTER);
                  queryClient.invalidateQueries(CfmQueryKeys.GetAllProducts);
                }}
                productCount={productCount}
                displayProductCountText={displayProductCountText}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
