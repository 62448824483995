import { VFC } from "react";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { FormDatepickerField } from "../../../../../../shared/components/form/fields/form-datepicker.field";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";
import { FormTextField } from "../../../../../../shared/components/form/fields/form-text.field";
import { FormCheckbox } from "../../../../../../shared/components/form/fields/form-checkbox.field";
import { CfmOrderActionType } from "../../../../../domain/order-x/actions/cfm-order-action-type";
import { isAdmin } from "../../../../../../shared/domain/user/user";
import { useAuthContext } from "../../../../../../providers/Auth/auth.provider";

export interface IOrderXChangePlannedPickupAction {
  plannedPickupDate: Date;
  comment: string;
}

export const OrderXChangePlannedPickupAction: VFC = () => {
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const {
    formState: { errors },
    control,
  } = useFormContext<ICfmResponseValueFormInputs>();

  return (
    <>
      <FormField md={12} className={"pb-4"}>
        <FormDatepickerField
          label={t(`orders.accept_form.planned_pickup_date.label`)}
          name={"plannedPickupChanged.plannedPickupDate"}
          hasError={Boolean(errors?.plannedPickupChanged?.plannedPickupDate)}
          control={control}
          required={true}
        />
      </FormField>
      <FormField md={12} className={"pb-4"}>
        <FormTextField
          required
          multiline
          hasError={Boolean(errors.plannedPickupChanged?.comment)}
          minRows={3}
          maxLength={120}
          label={t("orders.new.wizard.submit.orderComment")}
          name={"plannedPickupChanged.comment"}
          control={control}
          rules={{ required: true }}
        />
      </FormField>
      {isAdmin(internalUser) && (
        <FormField md={12}>
          <FormCheckbox
            name={"isNotificationDisabled"}
            control={control}
            label={t(`orders.responseDialog.${CfmOrderActionType.ChangePlannedPickup}.isNotificationDisabled`)}
            noMargin
            dataTestId="change-planned-pickup-do-not-notify-checkbox"
          />
        </FormField>
      )}
    </>
  );
};
