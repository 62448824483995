import { CfmServiceDate } from "../../../../domain/order-x/actions/cfm-service-date";
import { CfmServiceDateModel } from "../../order-x/action/cfm-service-date.model";

export class CfmServiceDateConverter {
  public static toModel(serviceDateEntity: CfmServiceDate): CfmServiceDateModel {
    switch (serviceDateEntity) {
      case CfmServiceDate.Pickup:
        return CfmServiceDateModel.Pickup;
      case CfmServiceDate.Delivery:
        return CfmServiceDateModel.Delivery;
      case CfmServiceDate.Treatment:
        return CfmServiceDateModel.Treatment;
      case CfmServiceDate.Recycling:
        return CfmServiceDateModel.Recycling;
    }
  }

  public static toDomain(serviceDate: CfmServiceDateModel): CfmServiceDate {
    switch (serviceDate) {
      case CfmServiceDateModel.Pickup:
        return CfmServiceDate.Pickup;
      case CfmServiceDateModel.Delivery:
        return CfmServiceDate.Delivery;
      case CfmServiceDateModel.Treatment:
        return CfmServiceDate.Treatment;
      case CfmServiceDateModel.Recycling:
        return CfmServiceDate.Recycling;
    }
  }
}
