import { Grid, makeStyles, Theme } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { CfmQueryKeys } from "../../../collect-from-market/repositories/queries/cfm-query-keys";
import { useCreateProductPackageConfig } from "../../../collect-from-market/repositories/queries/package/create-product-package-config.query";
import { OrderCheckbox } from "../../../components/checkbox/order-checkbox.component";
import { PackageConfigIconSmall } from "../../../components/Primitives/Icons";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { Badge } from "../badge/badge.component";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import { ArticleNumberBox } from "../product/article-number-box.component";
import { ProductName } from "../product/product-name.component";
import { ICfmProductPackageConfig } from "./create-product-package-config-content.component";
import { PackageDialog } from "./dialog/package-dialog.component";
import { RemovePackageConfigDialog } from "./dialog/remove-package-config-dialog.component";

const useStyles = makeStyles((theme: Theme) => ({
  packageIcon: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
  },
  container: { height: "100%" },
  badgeContainer: { marginRight: 40, marginLeft: 10 },
  checkboxContainer: {
    marginLeft: 15,
  },
}));

interface IPackageConfigProductRowProps {
  product: ICfmProduct;
  onProductCheckboxClick: (productId: number, checked: boolean) => void;
  isSelected: boolean;
}

export const PackageConfigProductRow: VFC<IPackageConfigProductRowProps> = (props) => {
  const { product, onProductCheckboxClick, isSelected } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();
  const packages = useMemo(() => product.packages ?? [], [product]);
  const [isPackageDialogOpen, setIsPackageDialogOpen] = useState(false);
  const [packageToRemove, setPackageToRemove] = useState<ICfmPackage | undefined>();

  const { isLoading: isRemovePackageLoading, mutateAsync: saveProductPackageConfig } = useCreateProductPackageConfig();

  const onCheckboxChange = (checked: boolean) => {
    onProductCheckboxClick(product.id, checked);
  };

  const onEditPackageConfig = () => {
    const url = `${AvailableConfiguratorRoutes.CreateProductPackageConfig}?ids=${product.id}`;
    history.push(url);
  };

  const removePackage = async () => {
    if (!packageToRemove) return;

    // remove package from config by saving product and only wanted packages
    const wantedPackages = (product.packages ?? []).filter((existingPkg) => existingPkg.id !== packageToRemove.id);
    const data: ICfmProductPackageConfig = {
      product: product,
      existingPackages: wantedPackages,
      newPackages: [],
    };
    await saveProductPackageConfig({ config: [data] });

    setPackageToRemove(undefined);
    setIsPackageDialogOpen(wantedPackages.length > 0);
    queryClient.invalidateQueries(CfmQueryKeys.GetAllProductsInfinite);
  };

  return (
    <>
      <PackageDialog
        open={isPackageDialogOpen}
        product={product}
        packages={product.packages ?? []}
        onCancel={() => setIsPackageDialogOpen(false)}
        onEdit={onEditPackageConfig}
        onRemove={(pkg) => {
          setIsPackageDialogOpen(false);
          setPackageToRemove(pkg);
        }}
      />
      <RemovePackageConfigDialog
        open={packageToRemove !== undefined}
        packageName={packageToRemove?.name ?? ""}
        onCancel={() => {
          setPackageToRemove(undefined);
          setIsPackageDialogOpen(true);
        }}
        onAccept={removePackage}
        isLoading={isRemovePackageLoading}
      />
      <BoxedRowGrid justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" justifyContent="center" className={classes.container}>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item className={classes.checkboxContainer}>
                  <OrderCheckbox onChange={onCheckboxChange} checked={isSelected} size="large" />
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="center" className={classes.container}>
                    <Grid item>
                      <ArticleNumberBox articleNumber={product.articleNumber} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="center" className={classes.container}>
                    <Grid item>
                      <ProductName name={product.name} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {packages.length > 0 && (
          <Grid item>
            <Grid container direction="column" justifyContent="center" className={classes.container}>
              <Grid item>
                <Grid container direction="row">
                  <Grid item>
                    <PackageConfigIconSmall
                      className={classes.packageIcon}
                      viewBox="0 0 25 25"
                      onClick={() => setIsPackageDialogOpen(true)}
                    />
                  </Grid>
                  <Grid item className={classes.badgeContainer}>
                    <Badge count={packages.length} backgroundColor={PrimaryColors.base} textColor={Colors.white} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </BoxedRowGrid>
    </>
  );
};
