import { makeStyles, Theme } from "@material-ui/core";
import { WarningColors } from "./Colors";

export const useBoxStyle = makeStyles((theme: Theme) => ({
  error: {
    fontWeight: "bold",
    color: WarningColors.base,
    padding: 4,
  },
}));
