import { FC, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { EditGroupAddressDialog } from "../../../../components/group/edit-group/dialog/edit-group-address-dialog.component";
import { IAddressLean } from "../../../../domain/address/address-lean";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { AddressType, recyclerAddressTypes } from "../../../../models/address/address.model";
import { useGroupDetailContext } from "../../group-detail.provider";
import { AddressDetailDialog } from "../dialogues/address-detail-dialog.component";
import { GroupDetailsAddressesBaseTable } from "./group-detail-address-base-table.component";
import { useGetUserAddresses } from "../../../../repositories/queries/address/get-user-addresses-for-id.query";
import { AddressConverter } from "../../../../domain/converter/address.converter";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useQueryClient } from "react-query";

export const GroupDetailsWasteProducerAddressesTable: FC = (props) => {
  const { internalUser } = useAuthContext();
  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailAddress, setDetailAddress] = useState<IAddressLean | undefined>();
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });
  const { group } = useGroupDetailContext();
  const {
    isLoading,
    data: addressesResult,
    refetch,
  } = useGetUserAddresses(
    internalUser?.id,
    [
      AddressType.CustomerLocation,
      AddressType.LogisticLocation,
      AddressType.ManufacturerLocation,
      ...recyclerAddressTypes,
    ],
    searchText,
    true,
    paginationInputMeta.page,
    paginationInputMeta.pageSize,
    true,
  );

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const leanAddressResult = useMemo(() => {
    if (!addressesResult) return undefined;
    return {
      addresses: addressesResult.addresses.map(AddressConverter.domainToLeanAddressDomain),
      meta: addressesResult.meta,
    };
  }, [addressesResult]);

  const handleRowClicked = (address: IAddressLean | undefined) => {
    setDetailAddress(address);
    setIsDetailOpen(true);
  };

  return (
    <>
      <AddressDetailDialog
        open={isDetailOpen}
        address={detailAddress}
        onClose={() => {
          setDetailAddress(undefined);
          setIsDetailOpen(false);
        }}
        onEditClick={() => {
          setIsEditOpen(true);
          setIsDetailOpen(false);
        }}
      />

      <EditGroupAddressDialog
        open={isEditOpen}
        groupId={group.id}
        address={detailAddress}
        onCancel={() => {
          setIsDetailOpen(false);
          setIsEditOpen(false);
        }}
        onSaved={() => {
          setIsEditOpen(false);
          setIsDetailOpen(false);
          setDetailAddress(undefined);
          queryClient.invalidateQueries(SharedQueryKeys.UserAddressesForId);
        }}
        hide={["type"]}
        canDeactivate={false}
      />

      <GroupDetailsAddressesBaseTable
        addressesResult={leanAddressResult}
        onRowClicked={handleRowClicked}
        isLoading={isLoading}
        setSearchText={setSearchText}
        paginationInputMeta={paginationInputMeta}
        setPaginationInputMeta={setPaginationInputMeta}
      />
    </>
  );
};
