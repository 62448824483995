import { ICfmProductFraction } from "../../../domain/products/cfm-product-fraction";
import { ICfmProductFractionModel } from "../product/cfm-product-fraction.model";

export class CfmProductFractionConverter {
  public static toModel(fraction: ICfmProductFraction): ICfmProductFractionModel {
    return {
      id: fraction.id,
      name: fraction.name ?? "",
      avvCode: fraction.avvCode,
      active: fraction.active,
      internalNumber: fraction.internalNumber,
    };
  }

  public static toDomain(fraction: ICfmProductFractionModel): ICfmProductFraction {
    return {
      id: fraction.id,
      name: fraction.name,
      avvCode: fraction.avvCode,
      active: fraction.active,
      internalNumber: fraction.internalNumber,
    };
  }
}
