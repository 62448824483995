import { IPaymentInfoModel } from "../../models/payment-info/payment-info.model";
import { getFormattedBic, getFormattedIban } from "../../util/payment-info.util";
import { IAddress } from "../address/address";
import { IAddressLean } from "../address/address-lean";
import { IPaymentInfo } from "../payment-info/payment-info";
import { IPaymentInfoFormInput } from "../payment-info/payment-info-form-input";
import { IPaymentInfoCreateOrUpdate } from "../requests/payment-info-create.request";

export class PaymentInfoConverter {
  public static toDomain(paymentInfo: IPaymentInfoModel): IPaymentInfo {
    return {
      id: paymentInfo.id,
      bicOrSwift: paymentInfo.bicOrSwift,
      iban: paymentInfo.iban,
      name: paymentInfo.name,
    };
  }

  public static toModel(paymentInfo: IPaymentInfo): IPaymentInfoModel {
    return {
      id: paymentInfo.id,
      bicOrSwift: paymentInfo.bicOrSwift,
      iban: paymentInfo.iban,
      name: paymentInfo.name,
    };
  }

  public static toFormInput(paymentInfo: IPaymentInfo): IPaymentInfoFormInput {
    return {
      bicOrSwift: getFormattedBic(paymentInfo.bicOrSwift),
      iban: getFormattedIban(paymentInfo.iban),
      name: paymentInfo.name,
    };
  }

  public static toFormInputFromAddress(address: IAddressLean | IAddress): IPaymentInfoFormInput {
    return {
      bicOrSwift: "",
      iban: "",
      name: address.name ?? "",
    };
  }

  public static toCreateOrUpdateRequest(paymentInfoFormInput: IPaymentInfoFormInput): IPaymentInfoCreateOrUpdate {
    return {
      bicOrSwift: paymentInfoFormInput.bicOrSwift,
      iban: paymentInfoFormInput.iban?.replaceAll(" ", ""),
      name: paymentInfoFormInput.name,
    };
  }
}
