import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useMemo, useState, VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { useCustomForm } from "../../../../shared/util/form.util";
import { PrimaryColors } from "../../../../style/Colors";
import { useProductRowStyle } from "../../../../style/product-row.style";
import { BoxedRowGrid } from "../../grid/boxed-row-grid.component";
import { ProductNameForm } from "../../product-form/product-name-form.component";
import { IProductNameInputs } from "../../../pages/product/components/product-overview-row.component";
import { ProductConfirmButtons } from "../../../pages/product/components/product-update-confirm-buttons.component";
import { ArticleNumberBox } from "../article-number-box.component";
import { ProductName } from "../product-name.component";
import { getProductArticleNumberAndNameFromProductData } from "../product.utils";
import { IProductData } from "./creatable-products-list.component";

const useStyles = makeStyles(() => ({
  editIconColor: {
    color: PrimaryColors.base,
  },
  editIcon: {
    marginTop: 3,
    marginLeft: 2,
  },
  formContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttonContainer: {
    marginTop: 5,
    marginRight: 20,
  },
}));

interface ICreateProductRow {
  productData: IProductData;
  onCheckboxClick: (productData: IProductData, checked: boolean) => void;
  isChecked: boolean;
  isExistingProduct: boolean;
  setProductName: (name: string) => void;
  editEnabled: boolean;
}

export const CreateProductRow: VFC<ICreateProductRow> = (props) => {
  const { productData, onCheckboxClick, isChecked, isExistingProduct, setProductName, editEnabled } = props;
  const productRowClasses = useProductRowStyle();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const getDefaultName = () => {
    const name = [
      productData.category.name ?? "",
      productData.fraction?.name ?? undefined,
      productData.type.name ?? "",
      productData.state.name ?? "",
    ];

    return name.filter((data) => data !== undefined).join(" | ");
  };

  const getDefaultValues = () => {
    if (productData.name) return { name: productData.name };
    return { name: getDefaultName() };
  };

  const formMethods = useCustomForm<IProductNameInputs>({ defaultValues: getDefaultValues() });

  const articleNumber = useMemo(() => {
    return `${productData.category.internalNumber ?? "00"}${productData.fraction?.internalNumber ?? "00"}${
      productData.type.internalNumber ?? "00"
    }${productData.state.internalNumber ?? "00"}`;
  }, [
    productData.category.internalNumber,
    productData.fraction?.internalNumber,
    productData.state.internalNumber,
    productData.type.internalNumber,
  ]);

  const onChangeName: SubmitHandler<IProductNameInputs> = async (inputs): Promise<void> => {
    setProductName(inputs.name);
    setIsEditing(false);
  };

  const getProductName = () => {
    return productData.name ? productData.name : getProductArticleNumberAndNameFromProductData(productData);
  };

  return (
    <BoxedRowGrid disabled={isExistingProduct} justifyContent="space-between">
      <Grid item>
        <Grid container direction="column" justifyContent="center" className={productRowClasses.container}>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item className={productRowClasses.checkBoxContainer}>
                {!isExistingProduct && (
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <OrderCheckbox
                        onChange={(checked) => {
                          onCheckboxClick(productData, checked);
                        }}
                        checked={isChecked}
                        size="large"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item className="mr-3">
                <ArticleNumberBox articleNumber={articleNumber} disabled={isExistingProduct} />
              </Grid>
              {!isEditing && (
                <>
                  <Grid item>
                    <ProductName name={getProductName()} disabled={isExistingProduct} />
                  </Grid>
                  {!isExistingProduct && editEnabled && (
                    <Grid item className={classes.editIcon}>
                      <Edit className={classes.editIconColor} onClick={() => setIsEditing(true)} />
                    </Grid>
                  )}
                </>
              )}
              {isEditing && !productData.exists && (
                <Grid item className={classes.formContainer}>
                  <FormProvider {...formMethods}>
                    <ProductNameForm />
                  </FormProvider>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" justifyContent="center" className={productRowClasses.container}>
          {isExistingProduct && (
            <Box className={productRowClasses.alreadyExistingContainer}>
              <Typography variant="body2" className={productRowClasses.alreadyExistingText}>
                {t("configuration.alreadyExisting")}
              </Typography>
            </Box>
          )}
          {isEditing && (
            <Grid item className={classes.buttonContainer}>
              <ProductConfirmButtons
                onConfirm={() => formMethods.handleSubmit(onChangeName)()}
                onCancel={() => {
                  formMethods.reset(getDefaultValues());
                  setIsEditing(false);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </BoxedRowGrid>
  );
};
