import { useQuery } from "react-query";
import { RestEndpoints } from "../../../api/endpoints";
import { ReactQueryConfig } from "../../../config/react-query-config";
import { UserLeanConverter } from "../../../shared/domain/converter/user-lean.converter";
import { IUserLeanListModel } from "../../../shared/models/user/user-lean.model";
import { SharedQueryKeys } from "../../../shared/repositories/queries/shared-query-keys";
import getInstance from "../../../utils/rest";
import { IUserLeanList } from "../../../shared/domain/user/user-lean";

const getCfmCorporateUsersOfCurrentGroup = async (): Promise<IUserLeanList> => {
  const result = await getInstance().get<IUserLeanListModel>(RestEndpoints.CfmCorporateUsersOfCurrentGroup);
  return UserLeanConverter.listToDomain(result.data.items);
};

export const useGetCfmCorporateUsersOfCurrentGroupQuery = (enabled: boolean) => {
  return useQuery([SharedQueryKeys.GetCfmCorporateUsersOfCurrentGroup], () => getCfmCorporateUsersOfCurrentGroup(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
