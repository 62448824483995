import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useCreateResponseAssignmentsForProducts } from "../../../../collect-from-market/repositories/queries/response-assignments/create-product-package-config.query";
import { ForwardButton } from "../../../../components/Primitives";
import { Colors } from "../../../../style/Colors";
import { AvailableConfiguratorRoutes } from "../../../../utils/constants";
import {
  IResponseAssignmentRowValue,
  useProductResponseAssignmentContext,
} from "../../../pages/product-response/product-response-assignment.provider";
import { AssignResponseFieldsDialog } from "../dialog/assign-response-fields-dialog.component";
import { ProductResponseAssignmentRow } from "../product-response-assignment-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  checkBox: {
    marginLeft: 22,
  },
  bottomRowContainer: {
    marginBottom: 30,
    marginTop: 20,
  },
  headingContainer: {
    marginTop: 20,
  },
}));

interface IProductResponseAssignmentFormContentProps {
  productIds: number[];
}

export const ProductResponseAssignmentFormContent: VFC<IProductResponseAssignmentFormContentProps> = (props) => {
  const { productIds } = props;
  const { selectedResponseAssignmentRowValues, setSelectedResponseAssignmentRowValues, activeStatus, setActiveStatus } =
    useProductResponseAssignmentContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoading, mutateAsync: createResponseAssignmentsForProducts } = useCreateResponseAssignmentsForProducts();

  const [isOpen, setIsOpen] = useState(false);

  const onComplete = (assignedValues: IResponseAssignmentRowValue) => {
    const oldValues = selectedResponseAssignmentRowValues.filter(
      (oldValue) => assignedValues.status !== oldValue.status,
    );
    setSelectedResponseAssignmentRowValues([...oldValues, assignedValues]);
    setIsOpen(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  const onSelectStatus = () => {
    setIsOpen(true);
  };

  const handleCreateConfig = async () => {
    await createResponseAssignmentsForProducts({
      productIds,
      responseAssignments: selectedResponseAssignmentRowValues,
    });
    history.push(AvailableConfiguratorRoutes.ProductOverview);
  };

  return (
    <Grid container direction="column" spacing={2}>
      {isOpen && <AssignResponseFieldsDialog open={isOpen} onAccept={onComplete} onCancel={onCancel} />}
      <Grid item>
        <Grid container direction="column" spacing={1}>
          {selectedResponseAssignmentRowValues
            .sort((a1, a2) => a1.status - a2.status)
            .map((values, index) => {
              return (
                <Grid item key={index}>
                  <ProductResponseAssignmentRow
                    initValues={values}
                    index={index}
                    onEditStatus={onSelectStatus}
                    backgroundColor={Colors.grayLight}
                    activeStatus={activeStatus}
                    setActiveStatus={setActiveStatus}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item className={classes.bottomRowContainer}>
        <Grid container direction="row-reverse">
          <Grid item>
            <ForwardButton onClick={handleCreateConfig} disabled={isLoading}>
              <Typography variant="body1">{t("configuration.responseAssignments.create.submit")}</Typography>
            </ForwardButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
