import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateShort } from "../../../../../shared/util/date.util";
import { Colors, PrimaryColors, Shades } from "../../../../../style/Colors";
import { useTypographyPillStyles } from "../../../../../style/typography-pill.style";
import { getOrderStatusKeys } from "../../../../domain/order-x/cfm-order-x.status";
import { CfmResponseValueReportValue } from "../../../../domain/report/cfm-response-value-report-value";
import { useGetCfmOrderResponseReportQuery } from "../../../../repositories/queries/order-x/queries/get-response-report.query";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { getDateFieldHeader } from "../../utils/order-x-card.util";
import { getStatusXName } from "../status-filter/status-x-util";
import { ResponseValueRow } from "./response-value-row.component";
import { useOrderCardStyle } from "../../../../../style/order-card.style";

const accordionMinHeight = 50;
const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    backgroundColor: Colors.white,
    "&>div>div>div>div:first-child": {
      paddingRight: 10,
    },
  },
  link: {
    color: `${PrimaryColors.base} `,
    fontWeight: "bold",
    "&:active": {
      textDecoration: "underline",
    },
    "&:hover": {
      color: `${Shades.black} `,
      textDecoration: "underline",
      textDecorationColor: `${PrimaryColors.base} `,
    },
  },
  fitContent: {
    width: "fit-content",
  },
}));

export const OrderXCardResponse: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const orderCardClasses = useOrderCardStyle();
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, isLoading } = useGetCfmOrderResponseReportQuery(order.id);
  const typographyPillClasses = useTypographyPillStyles();

  const AccordionSummaryNoExpandIconRotate = withStyles({
    root: {
      minHeight: accordionMinHeight,
      backgroundColor: Colors.white,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        backgroundColor: Shades.gray50,
      },
    },
    expandIcon: {
      transform: "none !important",
    },
  })(AccordionSummary);

  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const formatFieldValue = (field: CfmResponseValueReportValue) => {
    if (field.date) {
      return formatDateShort(field.date);
    }
    if (field.file) {
      const links: string[] = (Array.isArray(field.file) ? field.file : [field.file]) as string[];
      return (
        <>
          {links.map((link) => (
            <a key={link} href={link} target={"_blank"} rel="noreferrer" className={classes.link}>
              Link
            </a>
          ))}
        </>
      );
    }
    if (field.floatingPoint) {
      return field.floatingPoint;
    }
    if (field.number) {
      return field.number;
    }
    if (field.text) {
      return field.text;
    }
  };

  const orderResponseDetails = useMemo(() => {
    if (!data) return [];
    const orderStatusKeys = getOrderStatusKeys(false);
    const responses = [];
    for (const status of orderStatusKeys) {
      const responseValues = data.responseValues
        .filter((value) => value.status === status)
        .map((response) => {
          return {
            name: response.fieldName,
            value: formatFieldValue(response),
            internalNumber: response.internalNumber,
            unit: response.unit,
          };
        });
      const responseDates = data.responseDates
        .filter((response) => response.status === status && response.value !== null)
        .map((value) => {
          return {
            name: getDateFieldHeader(value.fieldName, t),
            value: formatDateShort(value.value as Date),
            internalNumber: null,
            unit: null,
          };
        });
      if (responseValues.length > 0 || responseDates.length > 0) {
        responses.push({
          statusName: getStatusXName(status, t),
          values: responseValues.concat(responseDates),
        });
      }
    }
    return responses;
  }, [data]);

  return (
    <Accordion expanded={isExpanded} onChange={handleExpandChange} elevation={0} square>
      <AccordionSummaryNoExpandIconRotate
        className={orderCardClasses.accordionSummaryHeightOnExpand}
        expandIcon={
          <ArrowForwardIosOutlined
            classes={{ root: isExpanded ? orderCardClasses.expandedIcon : orderCardClasses.expandIcon }}
          />
        }
      >
        <Typography variant="body1" className={classNames({ [orderCardClasses.cardHeaderTextExpanded]: isExpanded })}>
          {t("order.response.cardLabel")}
        </Typography>
      </AccordionSummaryNoExpandIconRotate>
      <AccordionDetails classes={{ root: classes.detailsContainer }}>
        {isLoading && (
          <Grid item>
            <CircularProgress size="large" />
          </Grid>
        )}
        {!isLoading && (
          <Grid container direction="column" className={"w-100"} spacing={2}>
            {orderResponseDetails.map((response, index) => {
              return (
                <>
                  <Grid item container direction="row" spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.fitContent}>
                        <Typography variant="body2" className={typographyPillClasses.greenPill}>
                          {response.statusName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {response.values.map((value) => {
                        return <ResponseValueRow value={value} />;
                      })}
                    </Grid>
                  </Grid>
                  {index + 1 < orderResponseDetails.length && <Divider />}
                </>
              );
            })}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
