import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChipFilter, IOption } from "../../../../../components/Filters/ChipFilter";
import { useUsersContext } from "../../../../../providers/Users/users-context";

export const UsersActiveFilter: FC = (props) => {
  const { filters, setFilters, tablePagination, setTablePagination } = useUsersContext();
  const { t } = useTranslation();
  const options: IOption[] = [
    {
      value: "true",
      displayValue: t("basedata.users.table.filter.status.active"),
    },
    {
      value: "false",
      displayValue: t("basedata.users.table.filter.status.inactive"),
    },
  ];

  return (
    <ChipFilter
      possibleOptions={options}
      label={t("basedata.users.table.filter.status.text")}
      filter={filters?.active}
      setFilter={(x: boolean) => {
        const newFilter = { ...filters };
        newFilter.active = x;
        setFilters(newFilter);
        setTablePagination({ ...tablePagination, page: 0 });
      }}
    />
  );
};
