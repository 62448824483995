import { Grid } from "@material-ui/core";
import { isArray } from "lodash";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getFormattedDomainAddress } from "../../../../utils/address.util";
import { IAddressLean } from "../../../domain/address/address-lean";
import { getUserTypeLevelTranslation, UserTypeLevel } from "../../../domain/user/user-type-level";
import { ALL_ADDRESSES_OPTION } from "../../address/address-form/address-form.util";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { IMutateUserFormInputs } from "./create-user-form.component";

interface ICreateUserCfmSectionProps {
  userTypeLevelSelectOptions: UserTypeLevel[];
  isCfmCustomerWasteProducer: boolean;
  groupHasSelectableAddresses: boolean;
  groupAddresses: IAddressLean[];
}

export const CreateUserCfmSection: VFC<ICreateUserCfmSectionProps> = (props) => {
  const { userTypeLevelSelectOptions, isCfmCustomerWasteProducer, groupHasSelectableAddresses, groupAddresses } = props;
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IMutateUserFormInputs>();

  // @ts-ignore
  const userTypeLevel = watch("userTypeLevel");

  return (
    <Grid container spacing={2}>
      {!isCfmCustomerWasteProducer && (
        <Grid item xs={12} md={6}>
          <FormAutocomplete<UserTypeLevel>
            name={"userTypeLevel"}
            control={control}
            label={t("general.user.type")}
            options={userTypeLevelSelectOptions}
            error={Boolean(errors?.userTypeLevel)}
            helperText={errors?.userTypeLevel?.message}
            rules={{ required: true }}
            getOptionLabel={(userTypeLevel) => t(getUserTypeLevelTranslation(userTypeLevel))}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        {userTypeLevel === UserTypeLevel.WasteProducer && groupHasSelectableAddresses && (
          <FormAutocomplete<IAddressLean>
            name={"userAddresses"}
            multiple={true}
            getOptionLabel={(option) => {
              if (option.id === ALL_ADDRESSES_OPTION) {
                return option.formattedAddress ?? "-";
              }
              return getFormattedDomainAddress(option, t);
            }}
            transformValueOnChange={(value) => {
              if (isArray(value) && value.some((v) => v.id === ALL_ADDRESSES_OPTION)) {
                return groupAddresses.filter((a) => a.id !== ALL_ADDRESSES_OPTION);
              }
              return value;
            }}
            rules={{ required: true }}
            label={t("create.user.all.addresses")}
            options={groupAddresses}
            control={control}
          />
        )}
      </Grid>
      {isCfmCustomerWasteProducer && <Grid item xs={12} md={6} />}
    </Grid>
  );
};
