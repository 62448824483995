import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ISystemCloningStatus, SystemCloningStatus } from "shared/domain/system-cloning-status/system-cloning-status";
import { formatDateLong } from "shared/util/date.util";

interface ISystemCloningStatusProps {
  status: ISystemCloningStatus;
}

export const SystemCloningStatusPreview: FC<ISystemCloningStatusProps> = (props) => {
  const { status: cloningStatus } = props;
  const { startedBy, startedAt, finishedAt, status } = cloningStatus;
  const { t } = useTranslation();

  const translateStatus = (status: SystemCloningStatus) => {
    const errors = [
      SystemCloningStatus.OtherFailed,
      SystemCloningStatus.CloningFailed,
      SystemCloningStatus.MigrationFailed,
      SystemCloningStatus.PostprocessingFailed,
    ];
    if (errors.includes(status)) return t("system_cloning.error");
    if (status === SystemCloningStatus.Success) return t("system_cloning.success");
    return "-";
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography style={{ fontWeight: "bold" }}>{t("system_cloning.started_by")}</Typography>
        <Typography>{startedBy}</Typography>
      </Grid>

      <Grid item>
        <Typography style={{ fontWeight: "bold" }}>{t("system_cloning.started_at")}</Typography>
        <Typography>{formatDateLong(startedAt)}</Typography>
      </Grid>

      <Grid item>
        <Typography style={{ fontWeight: "bold" }}>{t("system_cloning.finished_at")}</Typography>
        <Typography>{finishedAt ? formatDateLong(finishedAt) : "-"}</Typography>
      </Grid>

      <Grid item>
        <Typography style={{ fontWeight: "bold" }}>{t("system_cloning.status")}</Typography>
        <Typography>{translateStatus(status)}</Typography>
        <Typography>{status}</Typography>
      </Grid>
    </Grid>
  );
};
