import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  IProductStateWithoutCategory,
  productsToStateWithoutCategory,
} from "../../../../../../configurator/components/product/product.utils";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { useAutocompleteStyles } from "../../../../../../style/autocomplete.style";
import { getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { ARTICLE_SEPARATOR } from "../../../../../../utils/constants";
import { ICfmPackage } from "../../../../../domain/packages/cfm-package";
import { useOrderTruckloadContext } from "../../../order-truckload.provider";
import { useGetWarehouseProducts } from "../../../../../repositories/queries/product/query/get-warehouse-products.query";
import { CfmOrderXApiStatus } from "collect-from-market/domain/order-x/cfm-order-x.status";

export const LogisticWarehouseSelectFilter: VFC = () => {
  const { t } = useTranslation();
  const autoCompleteClasses = useAutocompleteStyles();
  const { truckloadFilter, setTruckloadFilter, areAddressesLoading, arePackagesLoading, addresses, packages } =
    useOrderTruckloadContext();

  const { isLoading: areProductsLoading, data: products } = useGetWarehouseProducts(
    truckloadFilter.destinationAddressId,
    [CfmOrderXApiStatus.LogisticWarehouse],
  );

  const productsWithoutCategory: IProductStateWithoutCategory[] = useMemo(() => {
    return productsToStateWithoutCategory(products ?? []);
  }, [products]);

  const getProductOptionLabel = (option: IProductStateWithoutCategory) => {
    return `${option.fraction.name} ${ARTICLE_SEPARATOR} ${option.type.name} ${ARTICLE_SEPARATOR} ${option.state.name}`;
  };

  const getPackageOptionLabel = (option: ICfmPackage) => {
    return `${option.name}`;
  };

  const handleProductChange = (value: IProductStateWithoutCategory | null) => {
    setTruckloadFilter({
      ...truckloadFilter,
      fractionId: value?.fraction.id ?? undefined,
      typeId: value?.type.id ?? undefined,
      stateId: value?.state.id ?? undefined,
      productId: value?.id,
      packageId: undefined,
    });
  };

  const handlePackageChange = (value: ICfmPackage | null) => {
    setTruckloadFilter({
      ...truckloadFilter,
      packageId: value?.id ?? undefined,
    });
  };

  const getAddressOptionLabel = (option: IAddressLean) => {
    return getFormattedDomainAddress(option, t);
  };

  const handleAddressChange = (value: IAddressLean | null) => {
    setTruckloadFilter({ ...truckloadFilter, destinationAddressId: value?.id, packageId: undefined });
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} md={4}>
        <Autocomplete
          options={addresses}
          classes={{
            endAdornment: autoCompleteClasses.adornment,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={autoCompleteClasses.input}
              placeholder={t("orders.truckload.filter.chooseAddress")}
            />
          )}
          loading={areAddressesLoading}
          loadingText={t("general.loading")}
          noOptionsText={t("general.autocomplete.noOptions")}
          getOptionLabel={getAddressOptionLabel}
          onChange={(event, value) => handleAddressChange(value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          classes={{
            endAdornment: autoCompleteClasses.adornment,
          }}
          options={productsWithoutCategory ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              className={autoCompleteClasses.input}
              placeholder={t("orders.truckload.filter.chooseProduct")}
            />
          )}
          loading={areProductsLoading}
          loadingText={t("general.loading")}
          noOptionsText={t("general.autocomplete.noOptions")}
          getOptionLabel={getProductOptionLabel}
          onChange={(event, value) => handleProductChange(value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          classes={{ endAdornment: autoCompleteClasses.adornment }}
          options={packages ?? []}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className={autoCompleteClasses.input}
                placeholder={t("orders.truckload.filter.choosePackage")}
              />
            );
          }}
          loading={arePackagesLoading}
          loadingText={t("general.loading")}
          noOptionsText={t("general.autocomplete.noOptions")}
          getOptionLabel={getPackageOptionLabel}
          value={
            truckloadFilter.packageId
              ? packages.find((packageItem) => packageItem.id === truckloadFilter.packageId)
              : null
          }
          onChange={(event, value) => handlePackageChange(value)}
        />
      </Grid>
    </Grid>
  );
};
