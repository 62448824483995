import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ResponseConfigSmallIcon } from "../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../style/Colors";
import { ProductOverviewGeneralFilter } from "../product/components/product-overview.utils";
import { ProductResponseList } from "../../components/product-response/product-response-list.component";
import { ProductListFilter, ProductListFilterKey } from "../../components/product/product-list-filter.component";
import { PackageOverviewProvider } from "../package/package-overview.provider";
import { ProductResponseFieldOverviewProvider } from "../product-response-field/product-response-field-overview.provider";
import { ProductOverviewProvider } from "../product/product-overview.provider";
import { ProductResponseAssignmentProvider } from "./product-response-assignment.provider";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 20,
    marginBottom: 30,
    minHeight: 40,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
    marginTop: 5,
  },
}));

export const ExistingProductResponsePage: VFC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PackageOverviewProvider>
      <ProductOverviewProvider>
        <ProductResponseAssignmentProvider>
          <ProductResponseFieldOverviewProvider>
            <Grid container direction="column" spacing={2}>
              <Grid item className={classes.heading}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <ResponseConfigSmallIcon viewBox="0 0 25 25" className={classes.iconStyle} />
                      </Grid>
                      <Grid item>
                        <Typography variant="h4">{t("configuration.configResponse")}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <ProductListFilter
                  hidden={[
                    ProductListFilterKey.TagWithoutPackage,
                    ProductListFilterKey.TagWithoutRouting,
                    ProductListFilterKey.TagInactiveProducts,
                    ProductListFilterKey.SortingRegion,
                  ]}
                  withoutResponseTextOverride={t(
                    `configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutResponseAssignments}.responseOverview`,
                  )}
                  displayResponseFilters={true}
                />
              </Grid>
              <Grid item>
                <ProductResponseList />
              </Grid>
            </Grid>
          </ProductResponseFieldOverviewProvider>
        </ProductResponseAssignmentProvider>
      </ProductOverviewProvider>
    </PackageOverviewProvider>
  );
};
