import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmTruckloadAvailableStatusModel } from "collect-from-market/repositories/models/truckload/cfm-truckload-available-actions.model";
import { ICfmTruckloadAvailableStatus } from "collect-from-market/domain/truckload/cfm-truckload-available-actions";

export const getTruckloadAvailableStatus = async (
  startAddressId: number | undefined,
  fractionId: number | undefined,
  stateId: number | undefined,
  typeId: number | undefined,
): Promise<ICfmTruckloadAvailableStatus | undefined> => {
  const response = await getInstance().get<ICfmTruckloadAvailableStatusModel>(
    RestEndpoints.CfmTruckloadAvailableStatus,
    {
      params: {
        startAddressId,
        fractionId,
        stateId,
        typeId,
      },
    },
  );
  return response.data;
};

export const useGetTruckloadAvailableStatusQuery = (filter: {
  startAddressId?: number;
  fractionId?: number;
  stateId?: number;
  typeId?: number;
}) => {
  return useQuery(
    [CfmQueryKeys.TruckloadAvailableStatus, filter],
    () => getTruckloadAvailableStatus(filter.startAddressId, filter.fractionId, filter.stateId, filter.typeId),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled:
        Number.isFinite(filter.startAddressId) ||
        Number.isFinite(filter.fractionId) ||
        Number.isFinite(filter.stateId) ||
        Number.isFinite(filter.typeId),
    },
  );
};
