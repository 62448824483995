import { i18n } from "../config/locales/i18n";

// http://www.teamarbyte.de/mysql-laender.html
export enum CountriesEnum {
  Andorra = "AD",
  Kosovo = "XK",
  Czechia = "CZ",
  UnitedArabEmirates = "AE",
  Afghanistan = "AF",
  AntiguaandBarbuda = "AG",
  Anguilla = "AI",
  Albania = "AL",
  Armenia = "AM",
  NetherlandsAntilles = "AN",
  Angola = "AO",
  Antarctica = "AQ",
  Argentina = "AR",
  AmericanSamoa = "AS",
  Austria = "AT",
  Australia = "AU",
  Aruba = "AW",
  AlandIslands = "AX",
  Azerbaijan = "AZ",
  BosniaandHerzegovina = "BA",
  Barbados = "BB",
  Bangladesh = "BD",
  Belgium = "BE",
  BurkinaFaso = "BF",
  Bulgaria = "BG",
  Bahrain = "BH",
  Burundi = "BI",
  Benin = "BJ",
  Bermuda = "BM",
  Brunei = "BN",
  Bolivia = "BO",
  Brazil = "BR",
  Bahamas = "BS",
  Bhutan = "BT",
  BouvetIsland = "BV",
  Botswana = "BW",
  Belarus = "BY",
  Belize = "BZ",
  Canada = "CA",
  CocosIslands = "CC",
  Congo = "CD",
  CentralAfricanRepublic = "CF",
  Switzerland = "CH",
  IvoryCoast = "CI",
  CookIslands = "CK",
  Chile = "CL",
  Cameroon = "CM",
  Colombia = "CO",
  CostaRica = "CR",
  Cuba = "CU",
  CapeVerde = "CV",
  ChristmasIsland = "CX",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Germany = "DE",
  Djibouti = "DJ",
  Denmark = "DK",
  Dominica = "DM",
  DominicanRepublic = "DO",
  Algeria = "DZ",
  Ecuador = "EC",
  Estonia = "EE",
  Egypt = "EG",
  WesternSahara = "EH",
  Eritrea = "ER",
  Spain = "ES",
  Ethiopia = "ET",
  Finland = "FI",
  Fiji = "FJ",
  FalklandIslands = "FK",
  Micronesia = "FM",
  FaroeIslands = "FO",
  France = "FR",
  Gabon = "GA",
  UnitedKingdom = "GB",
  Grenada = "GD",
  Georgia = "GE",
  FrenchGuiana = "GF",
  Guernsey = "GG",
  Ghana = "GH",
  Gibraltar = "GI",
  Greenland = "GL",
  Gambia = "GM",
  Guinea = "GN",
  Guadeloupe = "GP",
  EquatorialGuinea = "GQ",
  Greece = "GR",
  SouthGeorgiaandtheSouthSandwichIslands = "GS",
  Guatemala = "GT",
  Guam = "GU",
  GuineaBissau = "GW",
  Guyana = "GY",
  HongKong = "HK",
  HeardIslandandMcDonaldIslands = "HM",
  Honduras = "HN",
  Croatia = "HR",
  Haiti = "HT",
  Hungary = "HU",
  Indonesia = "ID",
  Ireland = "IE",
  Israel = "IL",
  IsleofMan = "IM",
  India = "IN",
  BritishIndianOceanTerritory = "IO",
  Iraq = "IQ",
  Iran = "IR",
  Iceland = "IS",
  Italy = "IT",
  Jersey = "JE",
  Jamaica = "JM",
  Jordan = "JO",
  Japan = "JP",
  Kenya = "KE",
  Kyrgyzstan = "KG",
  Cambodia = "KH",
  Kiribati = "KI",
  Comoros = "KM",
  SaintKittsandNevis = "KN",
  NorthKorea = "KP",
  SouthKorea = "KR",
  Kuwait = "KW",
  CaymanIslands = "KY",
  Kazakhstan = "KZ",
  Laos = "LA",
  Lebanon = "LB",
  SaintLucia = "LC",
  Liechtenstein = "LI",
  SriLanka = "LK",
  Liberia = "LR",
  Lesotho = "LS",
  Lithuania = "LT",
  Luxembourg = "LU",
  Latvia = "LV",
  Libya = "LY",
  Morocco = "MA",
  Monaco = "MC",
  Moldova = "MD",
  Madagascar = "MG",
  MarshallIslands = "MH",
  Macedonia = "MK",
  Mali = "ML",
  Myanmar = "MM",
  Mongolia = "MN",
  China = "CN",
  NorthernMarianaIslands = "MP",
  Martinique = "MQ",
  Mauritania = "MR",
  Montserrat = "MS",
  Malta = "MT",
  Mauritius = "MU",
  Maldives = "MV",
  Malawi = "MW",
  Mexico = "MX",
  Malaysia = "MY",
  Mozambique = "MZ",
  Namibia = "NA",
  NewCaledonia = "NC",
  Niger = "NE",
  NorfolkIsland = "NF",
  Nigeria = "NG",
  Nicaragua = "NI",
  Netherlands = "NL",
  Norway = "NO",
  Niue = "NU",
  NewZealand = "NZ",
  Oman = "OM",
  Panama = "PA",
  Peru = "PE",
  FrenchPolynesia = "PF",
  PapuaNewGuinea = "PG",
  Philippines = "PH",
  Pakistan = "PK",
  Poland = "PL",
  SaintPierreandMiquelon = "PM",
  Pitcairn = "PN",
  PuertoRico = "PR",
  Palestine = "PS",
  Portugal = "PT",
  Palau = "PW",
  Paraguay = "PY",
  Qatar = "QA",
  Reunion = "RE",
  Romania = "RO",
  Rwanda = "RW",
  SaudiArabia = "SA",
  SolomonIslands = "SB",
  Seychelles = "SC",
  Sudan = "SD",
  Sweden = "SE",
  Singapore = "SG",
  SaintHelena = "SH",
  Slovenia = "SI",
  SvalbardandJanMayen = "SJ",
  Slovakia = "SK",
  SierraLeone = "SL",
  SanMarino = "SM",
  Senegal = "SN",
  Somalia = "SO",
  Suriname = "SR",
  SaoTomeandPrincipe = "ST",
  ElSalvador = "SV",
  Syria = "SY",
  Swaziland = "SZ",
  TurksandCaicosIslands = "TC",
  Chad = "TD",
  FrenchSouthernTerritories = "TF",
  Togo = "TG",
  Thailand = "TH",
  Tajikistan = "TJ",
  Tokelau = "TK",
  EastTimor = "TL",
  Turkmenistan = "TM",
  Tunisia = "TN",
  Tonga = "TO",
  Turkey = "TR",
  TrinidadandTobago = "TT",
  Tuvalu = "TV",
  Taiwan = "TW",
  Tanzania = "TZ",
  Ukraine = "UA",
  Uganda = "UG",
  UnitedStatesMinorOutlyingIslands = "UM",
  UnitedStates = "US",
  Uruguay = "UY",
  Uzbekistan = "UZ",
  Vatican = "VA",
  SaintVincentandtheGrenadines = "VC",
  Venezuela = "VE",
  BritishVirginIslands = "VG",
  VirginIslands = "VI",
  Vietnam = "VN",
  Vanuatu = "VU",
  WallisandFutuna = "WF",
  Samoa = "WS",
  Yemen = "YE",
  Mayotte = "YT",
  SouthAfrica = "ZA",
  Zambia = "ZM",
  Zimbabwe = "ZW",
  Serbia = "RS",
  Montenegro = "ME",
  SaintBarthelemy = "BL",
  SintEustatiusandSaba = "BQ",
  Curacao = "CW",
  SaintMartin = "MF",
  SintMaarten = "SX",
  SouthSudan = "SS",
  Nepal = "NP",
  Nauru = "NR",
}

export interface ICountry {
  name: string;
  translationKey: string;
  code: string;
}

const mapCountries = (): ICountry[] => {
  const mapped = (Object.keys(CountriesEnum) as Array<keyof typeof CountriesEnum>).map((key) => {
    const value: CountriesEnum = CountriesEnum[key];
    return {
      name: key,
      code: value,
      translationKey: `country.${value.toLowerCase()}`,
    };
  });

  return mapped;
};

export const Countries: ICountry[] = mapCountries().sort((c1, c2) =>
  i18n.t(c1.translationKey).localeCompare(i18n.t(c2.translationKey)),
);

export const getDefaultCountry = (): ICountry => {
  return Countries.find((country) => country.code === "DE")!;
};

export const getCountryByCode = (countryCode: string): ICountry | undefined => {
  if (!countryCode) return undefined;
  return Countries.find((country) => country.code === countryCode);
};
