import { VFC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Loading } from "./loading.component";

export const useStyles = makeStyles((theme) => ({
  inputLoadingContainer: {
    width: 30,
    height: 20,
    position: "relative",
  },
}));

export const InputLoadingSpinner: VFC = () => {
  const classes = useStyles();
  return (
    <Box justifyContent={"flex-end"} className={classes.inputLoadingContainer}>
      <Loading size={16} isLoading={true}></Loading>
    </Box>
  );
};
