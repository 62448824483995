import { VFC } from "react";
import { IconFormHeader } from "../../form/icon-form-header.component";
import { CreateGroupHeader } from "./create-group-header.component";

export interface ICreateGroupIconHeaderProps {
  text: string;
  icon: JSX.Element;
}

export const CreateGroupIconHeader: VFC<ICreateGroupIconHeaderProps> = (props) => {
  const { text, icon } = props;

  return (
    <CreateGroupHeader>
      <IconFormHeader text={text} icon={icon} />
    </CreateGroupHeader>
  );
};
