import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { QuestionDialog } from "../../../components/Dialogs/question-dialog";

interface IConfirmDialogProps {
  open: boolean;
  onCancel: () => Promise<void>;
  onConfirm: () => Promise<void>;
  title?: string;
  message?: string;
  actionText?: string;
  displayStyle?: "success" | "warning";
}

export const ConfirmDialog: VFC<IConfirmDialogProps> = (props) => {
  const { open, onCancel, onConfirm, title, actionText, message, displayStyle = "warning" } = props;
  const { t } = useTranslation();

  const getTitle = (): string => title ?? t("general.cancel_dialog.title");
  const getMessage = (): string => message ?? t("general.cancel_dialog.message");
  const getActionText = (): string => actionText ?? t("general.cancel_dialog.action");

  return (
    <QuestionDialog
      isOpen={open}
      displayStyle={displayStyle}
      onClose={onCancel}
      onAccept={onConfirm}
      title={getTitle()}
      message={getMessage()}
      acceptText={getActionText()}
    />
  );
};
