import { useQuery } from "react-query";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderStatusConfiguration } from "../../../../domain/order-x/order-x-status-configuration";
import { replaceUriParameter } from "../../../../../api/endpoints";
import { RestEndpoints } from "../../../../../api/endpoints";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ReactQueryConfig } from "../../../../../config/react-query-config";

const getAssignableStatusForOrder = async (orderId: number): Promise<ICfmOrderStatusConfiguration | undefined> => {
  if (!orderId) return undefined;
  const url = replaceUriParameter(RestEndpoints.CfmOrderAssignableStatus, "id", orderId);
  const {data} = await getInstance().get<ICfmOrderStatusConfiguration>(url);
  return data;
}

export const useGetAssignableStatusForOrder = (orderId: number, enabled?: boolean) => {
  return useQuery([CfmQueryKeys.GetOrderXStatusConfiguration, orderId],
    () => getAssignableStatusForOrder(orderId),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled ?? false
    }
  );
}
