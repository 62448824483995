import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiErrorCodes } from "../../../../api/api-error-codes";
import { PomAnnouncementPeriod } from "../../../../put-on-market/repositories/models/announcements/pom-announcement.period";
import { GroupType } from "../../../domain/group/group-type";
import { GroupContractType } from "../../../models/group/group-contract.model";
import { DateWithoutTime } from "../../../util/date.util";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormField } from "../../form/form-field.component";
import { CfmGroupContractForm } from "./cfm-group-contract-form.component";
import { GroupContractBaseForm } from "./group-contract-base-form.component";
import { PomGroupContractForm } from "./pom-group-contract-form.component";

export enum GroupContractFormMode {
  Create,
  Edit,
  Detail,
}

export interface IGroupContractFormInputs {
  id: number | undefined;
  groupId: number;
  contractNumber: string;
  startDate: DateWithoutTime;
  endDate: DateWithoutTime | null;
  type: GroupContractType;
  productCategoryId: number | undefined;
  takeBackSystemId: number | undefined;
  defaultPriceGroup: boolean | undefined;
  announcementPeriod: PomAnnouncementPeriod;
}

interface IGroupContractFormProps {
  mode: GroupContractFormMode;
  groupType: GroupType;
  apiErrorCode: string | undefined;
}

export const GroupContractForm: FunctionComponent<IGroupContractFormProps> = (props) => {
  const { mode, groupType, apiErrorCode } = props;
  const { t } = useTranslation();

  const formMethods = useFormContext<IGroupContractFormInputs>();
  const {
    control,
    watch,
    formState: { errors },
  } = formMethods;
  const contractType = watch("type");

  const contractTypeOptions = [GroupContractType.Cfm, GroupContractType.Pom];

  return (
    <>
      <form>
        <Grid container spacing={2}>
          {mode === GroupContractFormMode.Create && groupType === GroupType.Customer && (
            <FormField md={12}>
              <FormAutocomplete<GroupContractType>
                name="type"
                required
                rules={{ required: true }}
                control={control}
                label={t("basedata.groups.groupContracts.contractType")}
                error={Boolean(errors?.type)}
                options={contractTypeOptions}
                getOptionLabel={(value) => {
                  return t(`basedata.group.${value}_heading`);
                }}
              />
            </FormField>
          )}
          <GroupContractBaseForm
            mode={mode}
            contractNumberError={apiErrorCode === ApiErrorCodes.GroupContractNumberNotAvailable}
          />
          {contractType === GroupContractType.Cfm && <CfmGroupContractForm mode={mode} />}
          {contractType === GroupContractType.Pom && <PomGroupContractForm mode={mode} />}
        </Grid>
      </form>
    </>
  );
};
