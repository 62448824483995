import { Divider, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState, VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SaveButton } from "../../../../components/Primitives";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { AvailablePlatform } from "../../../../providers/Auth/platform.provider";
import { Shades } from "../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../utils/constants";
import { IAddressLean } from "../../../domain/address/address-lean";
import { IAddressLeanFormInput } from "../../../domain/address/address-lean-form-input";
import { IGroupExistingDebitorData } from "../../../domain/group/group-existing-debitor-data";
import { GroupType, pomGroupTypes } from "../../../domain/group/group-type";
import { AddressType } from "../../../models/address/address.model";
import { useGetGroupExistingDebitors } from "../../../repositories/queries/group/get-group-existing-debitors.query";
import { useCustomForm } from "../../../util/form.util";
import { AddressNewOptionId } from "../../../util/group-data-form.util";
import { AddressFormModal } from "../../address/address-form-modal/address-form-modal.component";
import { AddressFormMode } from "../../address/address-form/address-form.component";
import { AppDialog } from "../../dialog/app-dialog.component";
import { FormHeader } from "../../form/form-header.component";
import { prefillDevelopmentValues } from "../group-form/development-create-group-form.util";
import { GroupFormAddressSection } from "../group-form/group-form-address-section.component";
import { GroupFormBaseDataSection, IGroupBaseDataFormInputs } from "../group-form/group-form-base-data-section";
import {
  GroupFormGroupGroupTypeSection,
  IGroupBaseDataTypeFormInputs,
} from "../group-form/group-form-group-type-section.component";
import {
  GroupFormPaymentSection,
  IGroupBaseDataPaymentInfoFormInputs,
} from "../group-form/group-form-payment-info-section.component";
import { GroupFormPomSection, IGroupBaseDataPomFormInputs } from "../group-form/group-form-pom-section.component";
import { GroupFormRadioButtons } from "../group-form/group-form-radio-buttons-section.component";
import { defaultValues, getDetailUrl, showPomSection } from "../group-form/group-form.util";
import { useCreateGroupFormContext } from "./create-group-form.provider";

export const useCreateGroupFormStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: Shades.gray20,
    marginTop: 0,
    marginBottom: 0,
  },
  lastDivider: {
    backgroundColor: Shades.gray20,
    marginTop: 25,
  },
  saveButtonCOntainer: {
    marginTop: 25,
    marginBottom: 200,
  },
}));

export interface ICreateGroupFormInputs
  extends IGroupBaseDataFormInputs,
    IGroupBaseDataPaymentInfoFormInputs,
    IGroupBaseDataTypeFormInputs,
    IGroupBaseDataPomFormInputs {
  id?: number;
  uuid?: string;
  groupBranchId: number | null;
}

interface ICreateGroupFormProps {
  platform: AvailablePlatform;
}

export const CreateGroupForm: VFC<ICreateGroupFormProps> = (props) => {
  const { platform } = props;
  const { isLoading, handleFormSubmit } = useCreateGroupFormContext();
  const history = useHistory();
  const { setHasUnsavedData } = useUnsavedDataContext();
  const { t } = useTranslation();
  const classes = useCreateGroupFormStyles();

  const initValues = defaultValues;
  prefillDevelopmentValues(initValues);
  const initialGroupType: GroupType | undefined = initValues.type;
  const formMethods = useCustomForm<ICreateGroupFormInputs>({ defaultValues: initValues, mode: "all" });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
    getFieldState,
  } = formMethods;

  // @ts-ignore
  const invoiceAddress: IAddressLeanFormInput | undefined = watch("invoiceAddress");
  const groupAddress: IAddressLeanFormInput | undefined = watch("groupAddress");
  const groupType: GroupType = watch("type");
  const groupName: string = watch("name");

  const [isExistingDebitorOpen, setIsExistingDebitorOpen] = useState<boolean>(false);
  const [existingDebitor, setExistingDebitor] = useState<IGroupExistingDebitorData | null>(null);

  const { mutateAsync: getExistingDebitors } = useGetGroupExistingDebitors();

  useEffect(() => {
    setHasUnsavedData(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  // Prefill PaymentInfo if it is not set.
  useEffect(() => {
    // never overwrite existing data
    if (initValues.paymentInfo != null) return;
    const field = getFieldState("paymentInfo");
    if (field.isDirty) return;

    setValue("paymentInfo.name", groupName ?? "");
  }, [getFieldState, groupName, initValues.paymentInfo, setValue]);

  const onValidateBaseDataForSubmit: SubmitHandler<ICreateGroupFormInputs> = async (inputs) => {
    const existingDebitorResult = await getExistingDebitors({
      name: inputs.name,
      vatId: inputs.vatId ?? null,
      tradeRegisterNumber: inputs.tradeRegisterNumber ?? null,
    });
    if (existingDebitorResult) {
      if (Object.values(existingDebitorResult).some((value) => value === true)) {
        setExistingDebitor(existingDebitorResult);
        setIsExistingDebitorOpen(true);
        return;
      }
      setExistingDebitor(null);
    }
    onSubmit(inputs);
  };

  const onSubmit: SubmitHandler<ICreateGroupFormInputs> = async (inputs) => {
    setHasUnsavedData(false);
    const response = await handleFormSubmit(inputs);
    if (response.isSuccess && response.group && response.group?.id) {
      history.push(getDetailUrl(response.group.id, response.contactPersonEmailExists));
      return;
    }
  };

  const handleSaveClick = (): void => {
    handleSubmit(onValidateBaseDataForSubmit)();
  };

  const isAddressDialogOpen = (): boolean => {
    return invoiceAddress?.id === AddressNewOptionId || groupAddress?.id === AddressNewOptionId;
  };

  const handleAddressDialogCancel = (): void => {
    if (invoiceAddress?.id === AddressNewOptionId) {
      // @ts-ignore
      setValue("invoiceAddress", null);
    }

    if (groupAddress?.id === AddressNewOptionId) {
      // @ts-ignore
      setValue("groupAddress", null);
    }
  };

  const handleNewAddress = (address: IAddressLean | undefined): void => {
    if (invoiceAddress?.id === AddressNewOptionId) {
      setValue("invoiceAddress", address);
    } else if (groupAddress?.id === AddressNewOptionId) {
      setValue("groupAddress", address);
    }
  };

  const getModalAddressType = (): AddressType => {
    return invoiceAddress?.id === AddressNewOptionId ? AddressType.Invoice : AddressType.GroupLocation;
  };

  const getFieldsFromExistingDebitor = (debitor: IGroupExistingDebitorData) => {
    const fields = [];
    for (const [key, value] of Object.entries(debitor)) {
      if (value === true) {
        fields.push(t(`basedata.groups.existingDebitor.fields.${key}`));
      }
    }
    return fields;
  };

  return (
    <FormProvider {...formMethods}>
      <AppDialog
        title={t("basedata.groups.existingDebitor.heading")}
        onCancelClick={() => {
          setIsExistingDebitorOpen(false);
        }}
        onAcceptClick={handleSubmit(onSubmit)}
        open={isExistingDebitorOpen}
        acceptTextOverride={t("basedata.general.yes.text")}
        cancelTextOverride={t("basedata.general.no.text")}
        fullWidth={false}
        maxWidth="sm"
      >
        <Typography>
          {existingDebitor &&
            t("basedata.groups.existingDebitor.existingInfo", {
              fields: getFieldsFromExistingDebitor(existingDebitor).join(`${TEXT_SEPARATOR} `),
            })}
        </Typography>
      </AppDialog>
      <form>
        {/* do not remove, only reliable way to prevent chrome from pre filling fields */}

        <AddressFormModal
          platform={platform}
          open={isAddressDialogOpen()}
          addressTypes={[getModalAddressType()]}
          mode={AddressFormMode.Create}
          onCancel={() => handleAddressDialogCancel()}
          onChangedAddress={(newAddress) => handleNewAddress(newAddress?.address)}
          canDeactivate={false}
        />

        <FormHeader>{t("basedata.groups.create")}</FormHeader>

        {isLoading && <LinearProgress />}

        {!isLoading && (
          <>
            <GroupFormRadioButtons />
            <GroupFormGroupGroupTypeSection hideHeading />
            <GroupFormBaseDataSection initialGroupType={initialGroupType} type="create" />
            <GroupFormAddressSection />
            <GroupFormPaymentSection isPomGroup={pomGroupTypes.includes(groupType)} />
            {showPomSection(groupType) && <GroupFormPomSection groupType={groupType} />}
          </>
        )}
      </form>

      {!isLoading && (
        <>
          {showPomSection(groupType) && <Divider className={classes.lastDivider} />}
          <Grid container direction="row-reverse" className={classes.saveButtonCOntainer}>
            <Grid item>
              <SaveButton onClick={handleSaveClick}>
                <Typography variant="body1">{t("general.save.text")}</Typography>
              </SaveButton>
            </Grid>
          </Grid>
        </>
      )}
    </FormProvider>
  );
};
