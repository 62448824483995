import { CfmProductCategoryConverter } from "../../../collect-from-market/repositories/models/converter/cfm-product-category.converter";
import { PomTakeBackSystemConverter } from "../../../put-on-market/domain/converter/pom-takeback-system.converter";
import { IGroupContractFormInputs } from "../../components/group/group-contract-form/group-contract-form.component";
import {
  GroupContractType,
  ICfmGroupContractFieldModel,
  ICfmGroupContractModel,
  IPomGroupContractFieldModel,
  IPomGroupContractModel,
} from "../../models/group/group-contract.model";
import { formatDateYearMonthDay } from "../../util/date.util";
import {
  ICfmGroupContract,
  ICfmGroupContractField,
  ICreateGroupContractRequest,
  IMutateGroupContractRequest,
  IPomGroupContract,
  IPomGroupContractField,
} from "../group/group-contract";

export class GroupContractConverter {
  public static inputToCreateRequest(input: IGroupContractFormInputs): ICreateGroupContractRequest {
    if (input.type === GroupContractType.Cfm) {
      return {
        contractNumber: input.contractNumber,
        startDate: formatDateYearMonthDay(input.startDate),
        endDate: input.endDate ? formatDateYearMonthDay(input.endDate) : input.endDate,
        type: input.type,
        productCategoryId: input.productCategoryId,
        announcementPeriod: null,
      };
    } else {
      return {
        contractNumber: input.contractNumber,
        startDate: formatDateYearMonthDay(input.startDate),
        endDate: input.endDate ? formatDateYearMonthDay(input.endDate) : input.endDate,
        type: input.type,
        takeBackSystemId: input.takeBackSystemId,
        defaultPriceGroup: input.defaultPriceGroup,
        announcementPeriod: input.announcementPeriod,
      };
    }
  }

  public static inputToMutateRequest(input: IGroupContractFormInputs): IMutateGroupContractRequest {
    if (input.type === GroupContractType.Cfm) {
      return {
        startDate: formatDateYearMonthDay(input.startDate),
        endDate: input.endDate ? formatDateYearMonthDay(input.endDate) : input.endDate,
        type: input.type,
        productCategoryId: input.productCategoryId,
        announcementPeriod: null,
      };
    } else {
      return {
        startDate: formatDateYearMonthDay(input.startDate),
        endDate: input.endDate ? formatDateYearMonthDay(input.endDate) : input.endDate,
        type: input.type,
        takeBackSystemId: input.takeBackSystemId,
        defaultPriceGroup: input.defaultPriceGroup,
        announcementPeriod: input.announcementPeriod,
      };
    }
  }

  public static cfmContractToDomain(contract: ICfmGroupContractModel): ICfmGroupContract {
    return {
      id: contract.id,
      groupId: contract.groupId,
      contractNumber: contract.contractNumber,
      startDate: contract.startDate,
      endDate: contract.endDate,
      type: contract.type,
      active: contract.active,
      contractField: GroupContractConverter.cfmContractFieldToDomain(contract.contractField),
    };
  }

  public static pomContractToDomain(contract: IPomGroupContractModel): IPomGroupContract {
    return {
      id: contract.id,
      groupId: contract.groupId,
      contractNumber: contract.contractNumber,
      startDate: contract.startDate,
      endDate: contract.endDate,
      type: contract.type,
      active: contract.active,
      contractField: GroupContractConverter.pomContractFieldToDomain(contract.contractField),
    };
  }

  private static pomContractFieldToDomain(field: IPomGroupContractFieldModel): IPomGroupContractField {
    return {
      id: field.id,
      takeBackSystem: PomTakeBackSystemConverter.toDomain(field.takeBackSystem),
      defaultPriceGroup: field.defaultPriceGroup,
      pomAnnouncementPeriod: field.pomAnnouncementPeriod,
    };
  }

  private static cfmContractFieldToDomain(field: ICfmGroupContractFieldModel): ICfmGroupContractField {
    return {
      id: field.id,
      productCategory: CfmProductCategoryConverter.toDomain(field.productCategory),
    };
  }

  public static cfmContractToModel(contract: ICfmGroupContract): ICfmGroupContractModel {
    return {
      id: contract.id,
      groupId: contract.groupId,
      contractNumber: contract.contractNumber,
      startDate: contract.startDate,
      endDate: contract.endDate,
      type: contract.type,
      active: contract.active,
      contractField: GroupContractConverter.cfmContractFieldToModel(contract.contractField),
    };
  }

  public static pomContractToModel(contract: IPomGroupContract): IPomGroupContractModel {
    return {
      id: contract.id,
      groupId: contract.groupId,
      contractNumber: contract.contractNumber,
      startDate: contract.startDate,
      endDate: contract.endDate,
      type: contract.type,
      active: contract.active,
      contractField: GroupContractConverter.pomContractFieldToModel(contract.contractField),
    };
  }

  private static pomContractFieldToModel(field: IPomGroupContractField): IPomGroupContractFieldModel {
    return {
      id: field.id,
      takeBackSystem: PomTakeBackSystemConverter.toModel(field.takeBackSystem),
      defaultPriceGroup: field.defaultPriceGroup,
    };
  }

  private static cfmContractFieldToModel(field: ICfmGroupContractField): ICfmGroupContractFieldModel {
    return {
      id: field.id,
      productCategory: CfmProductCategoryConverter.toModel(field.productCategory),
    };
  }
}
