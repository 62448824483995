import React, { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AvailableSharedRoutes } from "../../../../utils/constants";
import { IAddressLean } from "../../../domain/address/address-lean";
import { UserConverter } from "../../../domain/converter/user.converter";
import { IGroup } from "../../../domain/group/group";
import { cfmGroupTypes, pomGroupTypes } from "../../../domain/group/group-type";
import { AddressType, recyclerAddressTypes } from "../../../models/address/address.model";
import { isAdmin, isCfmWasteProducer, IUser } from "../../../domain/user/user";
import { useGetGroupAddresses } from "../../../repositories/queries/address/get-group-addresses.query";
import { useGetGroupsWithFiltersQuery } from "../../../repositories/queries/group/get-groups.query";
import { useCreateUserQuery } from "../../../repositories/queries/user/mutation/create-user.query";
import { useUpdateUserQuery } from "../../../repositories/queries/user/mutation/update-user.query";
import { getSelectAllAddressesOption } from "../../address/address-form/address-form.util";
import { IMutateUserFormInputs } from "../create-user-form/create-user-form.component";
import { UserFormMode } from "./user-form.component";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { useGetUserAddresses } from "../../../repositories/queries/address/get-user-addresses-for-id.query";
import { AddressConverter } from "../../../domain/converter/address.converter";

interface IUserFormContext {
  groups: IGroup[];
  userGroupId?: number | undefined;
  isLoading: boolean;
  handleFormSubmit: (
    inputs: IMutateUserFormInputs,
    mode: UserFormMode,
    shouldOpenDetail?: boolean | undefined,
  ) => Promise<IUser | undefined>;
  groupAddresses: IAddressLean[];
  wasteProducerAddresses: IAddressLean[];
}

export const UserFormContext = createContext<IUserFormContext>({} as IUserFormContext);

export const useUserFormContext = () => {
  return useContext(UserFormContext);
};

const useUserFormProvider = ({ user, group }: IUserFormProps): IUserFormContext => {
  const { t } = useTranslation();
  const history = useHistory();
  const { internalUser } = useAuthContext();

  const { data: groups, isLoading: areGroupsLoading } = useGetGroupsWithFiltersQuery(
    {
      filter: { groupTypes: [...cfmGroupTypes, ...pomGroupTypes] },
    },
    group === undefined && isAdmin(internalUser),
  );
  const { data: groupAddresses, isLoading: areGroupAddressesLoading } = useGetGroupAddresses(
    group?.id,
    undefined,
    [AddressType.CustomerLocation, ...recyclerAddressTypes, AddressType.LogisticLocation],
    group !== undefined,
    undefined,
    true,
  );

  const { isLoading: areWasteProducerAddressesLoading, data: wasteProducerAddressesResult } = useGetUserAddresses(
    internalUser?.id,
    [
      AddressType.CustomerLocation,
      AddressType.LogisticLocation,
      AddressType.ManufacturerLocation,
      ...recyclerAddressTypes,
    ],
    undefined,
    isCfmWasteProducer(internalUser) && !isAdmin(internalUser),
  );

  const { mutateAsync: createUser, isLoading: isCreateUserLoading } = useCreateUserQuery();
  const { mutateAsync: updateUser, isLoading: isUpdateUserLoading } = useUpdateUserQuery();

  const wasteProducerAddresses = useMemo(() => {
    if (!wasteProducerAddressesResult) return [];

    return wasteProducerAddressesResult.addresses.map(AddressConverter.domainToLeanAddressDomain);
  }, [wasteProducerAddressesResult]);

  const isLoading = useMemo(() => {
    return (
      areGroupsLoading ||
      areGroupAddressesLoading ||
      isCreateUserLoading ||
      isUpdateUserLoading ||
      areWasteProducerAddressesLoading
    );
  }, [
    areGroupsLoading,
    areGroupAddressesLoading,
    isCreateUserLoading,
    isUpdateUserLoading,
    areWasteProducerAddressesLoading,
  ]);

  const handleFormSubmit = async (
    inputs: IMutateUserFormInputs,
    mode: UserFormMode,
    shouldOpenDetail: boolean | undefined = true,
  ): Promise<IUser | undefined> => {
    let userResponse: IUser | undefined = undefined;
    // remove the all addresses option from input
    const filteredInputs = { ...inputs };
    filteredInputs.userAddresses = inputs.userAddresses?.filter((a) => a.id !== getSelectAllAddressesOption(t).id);

    if (mode === UserFormMode.Create) {
      userResponse = await handleCreate(filteredInputs);
    } else if (mode === UserFormMode.Edit && user?.id !== undefined) {
      userResponse = await handleUpdate(filteredInputs, user.id);
    }

    if (userResponse?.id && shouldOpenDetail) {
      history.push(AvailableSharedRoutes.UserDetail.replace(":id", userResponse.id.toString()));
    }

    return userResponse;
  };

  const handleCreate = async (filteredInputs: IMutateUserFormInputs): Promise<IUser> => {
    const request = UserConverter.toMutationRequest(filteredInputs, group?.id);
    return await createUser({ createData: request });
  };

  const handleUpdate = async (filteredInputs: IMutateUserFormInputs, userId: number): Promise<IUser> => {
    const request = UserConverter.toMutationRequest(filteredInputs);

    // don't send email if it matches the initial values
    if (request.email === user?.email) {
      request.email = undefined;
    }

    return await updateUser({ updateData: request, userId: userId });
  };

  return {
    groups: groups?.groups ?? [],
    isLoading,
    handleFormSubmit,
    groupAddresses: groupAddresses?.addresses ?? [],
    wasteProducerAddresses,
  };
};

interface IUserFormProps {
  user?: IUser | undefined;
  group?: IGroup;
}

export const UserFormProvider: React.FC<IUserFormProps> = (props) => {
  const value = useUserFormProvider(props);
  return <UserFormContext.Provider value={value}>{props.children}</UserFormContext.Provider>;
};
