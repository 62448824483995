import { Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import React, { useEffect, useState, VFC } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { AddIconButton } from "../../../../components/Primitives";
import { DangerousDeleteIconNoBorderButton } from "../../../../components/Primitives/Buttons";
import { GERMANY_FALLBACK_ZIP } from "../../../../utils/constants";
import { ICreateProductRoutingFormInputs } from "../create-product-routing-content.component";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginTop: 3,
  },
}));

const MaskedInput = (props: any) => {
  const { inputRef, ref, value, onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={"00000"}
      value={value}
      placeholderChar="x"
      lazy={false}
      ref={ref}
      inputRef={inputRef}
      onAccept={(value, mask) => {
        const data = {
          target: {
            value: mask.unmaskedValue,
          },
        };
        onChange(data);
      }}
    />
  );
};

export const ProductRoutingPostalFormContent: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
    watch,
    control,
  } = useFormContext<ICreateProductRoutingFormInputs>();
  const { fields, remove } = useFieldArray({ name: "postals" });
  const [hasSelectedFallback, setHasSelectedFallback] = useState(false);
  const [oldSelectedPostals, setOldSelectedPostals] = useState<string[]>();

  const postals: string[] | undefined = watch("postals");

  useEffect(() => {
    // if germany fallback postal has been selected, we need to set the checkbox
    if (postals && postals.length === 1 && postals[0] === GERMANY_FALLBACK_ZIP) {
      setHasSelectedFallback(true);
      return;
    }

    // do nothing if multiple postals are selected
    if (postals && postals.length > 0) return;

    // field array method cannot append empty, therefore we need to use setValue
    setValue("postals", [""]);
  }, [postals, setValue]);

  const onCheckboxChecked = (checked: boolean) => {
    if (checked) {
      setOldSelectedPostals(postals);
      setValue("postals", ["DE"]);
    } else {
      setValue("postals", oldSelectedPostals ?? [""]);
    }
    setHasSelectedFallback(checked);
  };

  const onRemovePostal = (index: number) => {
    remove(index);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <OrderCheckbox
          label={t("configuration.assignments.create.fallbackPostal")}
          onChange={onCheckboxChecked}
          checked={hasSelectedFallback}
          size="large"
        />
      </Grid>
      {!hasSelectedFallback && (
        <Grid item>
          <Grid container direction="row" spacing={2}>
            {fields.map((field, index) => (
              <Grid item key={field.id}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <Controller
                      name={`postals.${index}`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          error={Boolean(errors?.postals?.[index])}
                          InputLabelProps={{ shrink: true }}
                          required
                          value={value}
                          onChange={onChange}
                          InputProps={{ inputComponent: MaskedInput }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className={classes.buttonContainer}>
                    <DangerousDeleteIconNoBorderButton onClick={() => onRemovePostal(index)} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <AddIconButton
                onClick={() => {
                  // field array method cannot append empty, therefore we need to use setValue
                  setValue("postals", [...(postals ?? []), ""]);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
