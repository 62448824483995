import React, { FC } from "react";
import { Switch } from "react-router-dom";
import { IUser, isCfmCorporate, isCfmWasteProducer, isPomMainContact } from "shared/domain/user/user";
import { GroupAddPage } from "shared/pages/groups/group-add.page";
import { GroupDetailPage } from "shared/pages/groups/group-detail.page";
import { GroupOverviewPage } from "shared/pages/groups/group-overview.page";
import { AvailableSharedRoutes } from "utils/constants";
import { GroupsFilterProvider } from "../../../providers/groups/groups-filter-provider";
import { AuthenticatedRoute } from "../../../utils/AuthenticatedRoute";
import { GroupType } from "../../domain/group/group-type";

export const GroupRoutes: FC = () => {
  const groupDetailConditionCallback = (user: IUser | undefined) => {
    return isPomMainContact(user) || isCfmCorporate(user) || isCfmWasteProducer(user);
  };

  return (
    <GroupsFilterProvider>
      <Switch>
        <AuthenticatedRoute path={AvailableSharedRoutes.GroupNew} exact allowedRoles={[GroupType.Admin]}>
          <GroupAddPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute path={AvailableSharedRoutes.GroupList} exact allowedRoles={[GroupType.Admin]}>
          <GroupOverviewPage />
        </AuthenticatedRoute>

        <AuthenticatedRoute
          path={AvailableSharedRoutes.GroupDetail}
          exact
          allowedConditionCallback={groupDetailConditionCallback}
        >
          <GroupDetailPage />
        </AuthenticatedRoute>
      </Switch>
    </GroupsFilterProvider>
  );
};
