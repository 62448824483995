import { FormControl, FormHelperText, Grid, Typography } from "@material-ui/core";
import { CreateStrongPasswordHelper } from "components/Password/create-strong-password-helper";
import { SaveButton } from "components/Primitives";
import { usePasswordAnalyser } from "hooks/usePasswordAnalyser";
import React, { useState, VFC } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextField } from "shared/components/form/fields/form-text.field";
import { useCustomForm } from "shared/util/form.util";

export interface IPasswordResetFormInputs {
  password: string;
  verificationPassword: string;
}

interface IPasswordResetFormProps {
  onSubmit: SubmitHandler<IPasswordResetFormInputs>;
}

export const PasswordResetForm: VFC<IPasswordResetFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    control,
  } = useCustomForm<IPasswordResetFormInputs>({ mode: "all" });

  const { analyserSync, analysis } = usePasswordAnalyser();
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);

  const validatePassword = (password: string): boolean => {
    const analysis = analyserSync(password);
    return !!analysis.isValid;
  };

  const validateVerificationPassword = (password: string): boolean => {
    return password === getValues("password");
  };

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <Grid container item direction="column" className="mt-3">
        <Grid item>
          <FormControl className="mb-4 w-100">
            <FormTextField
              hasError={Boolean(errors["password"])}
              type="password"
              onFocus={() => setIsPasswordFieldFocused(true)}
              label={t("user.reset.newPassword.text")}
              control={control}
              name={"password"}
              rules={{
                required: false, // If true: Password is not validated when cleared.
                validate: validatePassword,
                onBlur: () => setIsPasswordFieldFocused(false),
              }}
            />
            {isPasswordFieldFocused && <CreateStrongPasswordHelper analysis={analysis} />}
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className="mb-4 w-100">
            <FormTextField
              hasError={Boolean(errors["verificationPassword"])}
              type="password"
              label={t("user.reset.newPassword.reenter")}
              control={control}
              name={"verificationPassword"}
              rules={{
                required: true,
                validate: validateVerificationPassword,
              }}
            />
            {errors["verificationPassword"] && (
              <FormHelperText className="mt-2">{t("basedata.users.edit.password.error")}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <SaveButton disabled={isSubmitting} type="submit">
            <Typography variant="body1">{t("general.save.text")}</Typography>
          </SaveButton>
        </Grid>
      </Grid>
    </form>
  );
};
