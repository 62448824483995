import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useCallback, useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AddIconButton } from "../../../../../components/Primitives/Buttons";
import { UserTableOutlined } from "../../../../../components/Primitives/Icons";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { Colors } from "../../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { AppTable } from "../../../../components/tables/app-table.component";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { RolePom } from "../../../../domain/user/role-pom";
import { IUser, IUserResult } from "../../../../domain/user/user";
import { getUserTypeLevelTranslation } from "../../../../domain/user/user-type-level";
import { formatDateLong } from "../../../../util/date.util";
import { GroupDetailHeader } from "../group-detail-header.component";

const useStyles = makeStyles((theme: Theme) => ({
  disabledAddressText: {
    color: Colors.gray,
  },
  icon: {
    width: 35,
    height: 40,
  },
  textAlign: {
    textAlign: "center",
  },
}));

interface IGroupDetailsUsersBaseTableProps {
  userResult: IUserResult | undefined;
  onAddClick?: VoidFunction;
  onRowClicked: (user: IUser | undefined) => void;
  isLoading: boolean;
  setSearchText: (text: string | undefined) => void;
  paginationInputMeta: IPaginationInputMeta;
  setPaginationInputMeta: (inputMeta: IPaginationInputMeta) => void;
}

export const GroupDetailsUsersBaseTable: VFC<IGroupDetailsUsersBaseTableProps> = (props) => {
  const {
    userResult,
    onAddClick,
    onRowClicked,
    isLoading,
    setSearchText,
    paginationInputMeta,
    setPaginationInputMeta,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const onSearchSubmit = (value: string | undefined) => {
    setSearchText(value);
  };

  const searchFieldProps = {
    tooltip: t("basedata.users.table.search"),
    placeholder: t("basedata.groups.detail.searchUsers"),
    onSearchSubmit,
  };

  const getUserInfoText = useCallback(
    (user: IUser) => {
      const textParts: string[] = [];

      if (user.rolePom != null) {
        const text =
          user.rolePom === RolePom.Announcer
            ? t("basedata.users.edit.pom_role.announcer")
            : t("basedata.users.edit.pom_role.main_contact");
        textParts.push(text);
      }

      if (user.userTypeLevel != null) {
        textParts.push(t(getUserTypeLevelTranslation(user.userTypeLevel)));
      }

      if (user.addresses && user.addresses.length > 0) {
        textParts.push(`${t("basedata.users.edit.locations")}: ${user.addresses.length}`);
      }

      return textParts.join(`${TEXT_SEPARATOR} `);
    },
    [t],
  );

  const getUserMiscText = useCallback(
    (user: IUser) => {
      const textParts: string[] = [];

      if (user.email) {
        textParts.push(user.email);
      }
      if (user.telephone) {
        textParts.push(user.telephone);
      }
      if (user.lastLogin) {
        textParts.push(`${t("basedata.users.table.last_login")}: ${formatDateLong(user.lastLogin)}`);
      }

      return textParts.join(`${TEXT_SEPARATOR} `);
    },
    [t],
  );

  const getUserLabelComponent = useCallback(
    (user: IUser) => {
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography
              variant="body1"
              className={classNames({
                [classes.disabledAddressText]: user.active == null,
                [classes.textAlign]: isMobile,
              })}
            >
              <b>
                {user.firstName ?? "-"} {user.lastName ?? "-"}{" "}
              </b>
              {getUserInfoText(user)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              className={classNames({
                [classes.disabledAddressText]: user.active == null,
                [classes.textAlign]: isMobile,
              })}
            >
              {getUserMiscText(user)}
            </Typography>
          </Grid>
        </Grid>
      );
    },
    [classes.disabledAddressText, classes.textAlign, getUserInfoText, getUserMiscText, isMobile],
  );

  const mappedEntries = useMemo(() => {
    const users = userResult?.users;
    if (!users) return [];

    return users.map((user) => {
      return {
        id: user.id,
        labelComponent: getUserLabelComponent(user),
        iconComponent: <UserTableOutlined className={classes.icon} viewBox="0 0 34.933 40" />,
        isActive: user.active != null ? true : false,
      };
    });
  }, [classes.icon, getUserLabelComponent, userResult?.users]);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <GroupDetailHeader>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h1">{t("basedata.users.heading")}</Typography>
              </Grid>
              {onAddClick && (
                <Grid item>
                  <AddIconButton onClick={onAddClick} />
                </Grid>
              )}
            </Grid>
          </GroupDetailHeader>
        </Grid>
        <Grid item>
          <AppTable
            entries={mappedEntries}
            searchFieldProps={searchFieldProps}
            paginationInputMeta={paginationInputMeta}
            setPaginationInputMeta={setPaginationInputMeta}
            numberOfRecords={userResult?.meta?.numberOfRecords ?? 0}
            onRowClicked={(tableRow) => {
              const user = userResult?.users.find((user) => user.id === tableRow.id);
              onRowClicked(user);
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
