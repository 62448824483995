import { useMutation } from "react-query";
import { ILoginAsResponse } from "../../../models/auth/login-as.response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { SharedQueryKeys } from "../../shared-query-keys";
import { ILoginResultLeanModel } from "../../../models/auth/login.response";

const login = async (email: string, password: string): Promise<{ result: ILoginResultLeanModel; status: number }> => {
  const url = RestEndpoints.Login;
  const { data, status } = await getInstance().post<ILoginAsResponse>(
    url,
    { email, password },
    { withCredentials: true },
  );
  return { result: data, status };
};

export const useLoginQuery = () => {
  return useMutation(SharedQueryKeys.Login, ({ email, password }: { email: string; password: string }) =>
    login(email, password),
  );
};
