import { IPomManufacturerListModel } from "put-on-market/repositories/models/manufacturer/manufacturer.model";
import { PaginationMetaConverter } from "shared/domain/converter/pagination-meta.converter";
import { IPomManufacturerList } from "../../models/manufacturer/manufacturer";
import { ManufacturerConverter } from "./manufacturer.converter";

export class ManufacturerListConverter {
  public static toDomain(list: IPomManufacturerListModel): IPomManufacturerList {
    return {
      items: ManufacturerConverter.toDomainList(list.items),
      meta: PaginationMetaConverter.toDomain(list.meta),
    };
  }

  public static toModel(list: IPomManufacturerList): IPomManufacturerListModel {
    return {
      items: ManufacturerConverter.toModelList(list.items),
      meta: PaginationMetaConverter.toModel(list.meta),
    };
  }
}
