import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProductCategory } from "../../../../domain/products/cfm-product-category";
import { CfmProductCategoryConverter } from "../../../models/converter/cfm-product-category.converter";
import { ICfmProductCategoryModel } from "../../../models/product/cfm-product-category.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getProductCategories = async (): Promise<ICfmProductCategory[]> => {
  const { data } = await getInstance().get<ICfmProductCategoryModel[]>(RestEndpoints.AllProductCategories);
  return data.map(CfmProductCategoryConverter.toDomain);
};

export const useGetProductCategoriesQuery = () => {
  return useQuery([CfmQueryKeys.GetProductCategories], () => getProductCategories(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
