import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { WizardStepHeader } from "../../../../collect-from-market/pages/order-new/components/wizard-step-heading.component";
import { ProductResponseAssignmentFormContent } from "./product-response-assignment-form-content.component";

interface IProductResponseAssignmentFormProps {
  productIds: number[];
}

export const ProductResponseAssignmentForm: VFC<IProductResponseAssignmentFormProps> = (props) => {
  const { productIds } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <WizardStepHeader text={t("configuration.responseAssignments.create.responseFieldsHeading")} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ProductResponseAssignmentFormContent productIds={productIds} />
        </Grid>
      </Grid>
    </>
  );
};
