import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { FieldErrorsImpl, FormProvider } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { FormAutocomplete } from "../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { AddressType } from "../../../../../shared/models/address/address.model";
import { useCustomForm } from "../../../../../shared/util/form.util";
import { Shades } from "../../../../../style/Colors";
import { getFormattedDomainAddress } from "../../../../../utils/address.util";
import { OrderActionServiceDate } from "../../../../domain/order-x/actions/cfm-order-action";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { ICfmCreateTruckload } from "../../../../domain/truckload/cfm-create-truckload";
import { CfmTruckloadConverter } from "../../../../repositories/models/converter/truckload/cfm-truckload.converter";
import { OrderServiceDateForm } from "./order-service-dates-form.component";
import { isCfmRecycler } from "../../../../../shared/domain/user/user";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { TruckloadState } from "../../../../domain/truckload/truckload-state";
import { useGetAllAddressesOfType } from "../../../../../shared/repositories/queries/address/get-all-addresses-of-type.query";

const useStyles = makeStyles((theme: Theme) => ({
  weightBox: {
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: 5,
    padding: "5px 20px 5px 20px",
  },
}));

export interface ICreateTruckloadInputs {
  targetRecyclerAddressId?: number;
  plannedDeliveryDate?: Date;
  serviceDatesForOrders: OrderActionServiceDate;
}

interface ILogisticWarehouseDialogProps {
  startAddressId: number | undefined;
  open: boolean;
  onCancel: VoidFunction;
  onSubmit: (data: ICfmCreateTruckload) => Promise<void>;
  weight: number;
  selectedOrders: ICfmOrderX[];
  serviceDateEditEnabled: boolean;
  initialValues?: ICreateTruckloadInputs;
  acceptTextOverride?: string;
  truckloadState?: TruckloadState;
  isDraftEnabled: boolean;
  isLoading: boolean;
}

export const LogisticWarehouseDialog: VFC<ILogisticWarehouseDialogProps> = (props) => {
  const {
    startAddressId,
    open,
    onCancel,
    onSubmit,
    weight,
    selectedOrders,
    serviceDateEditEnabled,
    initialValues,
    acceptTextOverride,
    isDraftEnabled,
    isLoading,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const formMethods = useCustomForm<ICreateTruckloadInputs>({ mode: "all", defaultValues: initialValues });
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = formMethods;

  const { isLoading: areAddressesLoading, data: recyclerAddresses } = useGetAllAddressesOfType(
    [AddressType.SorterLocation],
    {
      page: 0,
      pageSize: 100,
    },
    true,
    true,
  );

  const availableAddresses = useMemo(() => {
    if (isCfmRecycler(internalUser)) {
      return recyclerAddresses?.filter((a) => a.groupId === internalUser?.group?.id) ?? [];
    }

    return recyclerAddresses ?? [];
  }, [internalUser, recyclerAddresses]);

  const prepareAndSubmit = async (inputs: ICreateTruckloadInputs, status: TruckloadState): Promise<void> => {
    if (!startAddressId) return;

    const orderIds = selectedOrders.map((order) => order.id);
    const data = CfmTruckloadConverter.toCreateDomain(startAddressId, status, orderIds, inputs);
    onSubmit(data);
  };

  const handleTruckloadSubmit = (inputs: ICreateTruckloadInputs) => {
    prepareAndSubmit(inputs, TruckloadState.Submitted);
  };

  const handleTruckloadDraft = (inputs: ICreateTruckloadInputs) => {
    prepareAndSubmit(inputs, TruckloadState.Draft);
  };

  const handleTruckloadDraftError = (errors: Partial<FieldErrorsImpl>) => {
    // fields are by default required BUT for creating a draft they are optional
    // therefore saving draft with validation errors is be possible
    const values = getValues();
    const cleanedValues: ICreateTruckloadInputs = {
      ...values,
    };
    handleTruckloadDraft(cleanedValues);
  };

  return (
    <AppDialog
      open={open}
      title={t("orders.truckload.heading")}
      onCancelClick={onCancel}
      onAcceptClick={() => handleSubmit(handleTruckloadDraft, handleTruckloadDraftError)()}
      onCompleteClick={() => handleSubmit(handleTruckloadSubmit)()}
      acceptTextOverride={t("orders.truckload.draftSave")}
      completeTextOverride={acceptTextOverride ?? t("orders.truckload.submit")}
      isCompleteVisible
      isAcceptVisible={isDraftEnabled}
      fullWidth
      maxWidth="sm"
      isLoading={isLoading || areAddressesLoading}
    >
      <FormProvider {...formMethods}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Box className={classes.weightBox}>
              <Trans i18nKey="orders.truckload.truckloadTransportWeight">
                {{ weight: weight.toLocaleString() ?? 0 }}
              </Trans>
            </Box>
          </Grid>
          <FormField md={12}>
            <FormAutocomplete<number>
              name={"targetRecyclerAddressId"}
              control={control}
              label={t("orders.truckload.form.targetAddress")}
              options={availableAddresses.map((address) => address.id)}
              getOptionLabel={(value) => {
                const address = availableAddresses.find((address) => address.id === value);
                if (address) {
                  return getFormattedDomainAddress(address, t);
                }
              }}
              error={Boolean(errors?.targetRecyclerAddressId)}
              rules={{ required: true }}
            />
          </FormField>
          <Grid item>
            <FormDatepickerField
              label={t("orders.truckload.form.plannedDeliveryDate")}
              hasError={Boolean(errors.plannedDeliveryDate)}
              control={control}
              required
              name={"plannedDeliveryDate"}
            />
          </Grid>
          {serviceDateEditEnabled && (
            <Grid item>
              <OrderServiceDateForm
                orders={selectedOrders}
                orderStatus={CfmOrderXApiStatus.LogisticTruckLoadAnnounced}
              />
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </AppDialog>
  );
};
