import { formatDateYearMonthDay } from "../../../../../shared/util/date.util";
import { ICfmCreateResponseValueX } from "../../../../domain/order-x/actions/cfm-create-response-value-x";
import { ICfmOrderAvailableAction } from "../../../../domain/order-x/actions/cfm-order-available-action";
import { ICfmOrderAvailableActionsModel } from "../../order-x/action/cfm-order-x-available-action.model";
import { ICfmCreateResponseValueXModel } from "../../order-x/action/create-response-value-x.model";
import { CfmResponseFieldXConverter } from "../cfm-response-field-x.converter";
import { CfmOrderXActionConverter } from "./cfm-order-x-action.converter";
import { CfmServiceDateConverter } from "./cfm-service-date.converter";
import { ISelectedFilesList } from "../../../../pages/order-x/components/actions/providers/selected-files.provider";

export class CfmOrderXResponseValueConverter {
  public static createToModel(
    domain: ICfmCreateResponseValueX,
    files?: ISelectedFilesList,
  ): ICfmCreateResponseValueXModel {
    return {
      text: domain.text,
      itemId: domain.itemId,
      number: domain.number ? Number(domain.number) : null,
      files: files?.s3Paths ?? [],
      date: domain.date ? formatDateYearMonthDay(domain.date) : null,
      floatingPoint: domain.floatingPoint ? Number(domain.floatingPoint) : null,
      responseAssignmentResponseFieldId: domain.responseAssignmentResponseFieldId,
    };
  }

  public static availableToDomain(model: ICfmOrderAvailableActionsModel): ICfmOrderAvailableAction[] {
    return model.items?.map((i) => {
      return {
        type: CfmOrderXActionConverter.actionToDomain(i.type),
        serviceDates: i.serviceDates?.map(CfmServiceDateConverter.toDomain),
        responseFieldAssignments: i.responseFieldAssignments?.map(CfmResponseFieldXConverter.toDomain),
      };
    });
  }
}
