import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { UserIcon } from "../../../components/Primitives";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";
import { usePasswordResetDialogContext } from "../../../components/Password/password-reset-dialog.provider";
import { useSidebarStyle } from "../../../style/sidebar.style";
import { AvailableCfmRoutes, AvailablePomRoutes } from "../../../utils/constants";
import { AvailableMenus } from "../../util/sidebar.utils";
import { useSidebarContext } from "./sidebar-context";
import { SubMenuItem } from "./submenu-item.component";

const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    backgroundColor: "transparent",
    paddingTop: 0,
    margin: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
}));

export const SidebarUserMenu: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { activePlatform } = usePlatformContext();
  const { open, setIsOpen } = usePasswordResetDialogContext();
  const { setSidebarOpen, isFixed, activeMenu, setActiveMenu } = useSidebarContext();
  const classes = useSidebarStyle();
  const containerClasses = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleResetPassword = () => {
    setIsOpen(true);
    !isFixed && setSidebarOpen(false);
  };

  const handleNewUser = () => {
    if (activePlatform === AvailablePlatform.Pom) {
      history.push(AvailablePomRoutes.UserNew);
    } else {
      history.push(AvailableCfmRoutes.UserNew);
    }
    !isFixed && setSidebarOpen(false);
  };

  const isActiveMenu = () => {
    return open || pathname === AvailableCfmRoutes.UserNew || pathname === AvailablePomRoutes.UserNew ? true : false;
  };

  const isActive = useMemo(() => {
    return activeMenu === AvailableMenus.User;
  }, [activeMenu]);

  const [activeProfile, setActiveProfile] = useState<boolean>(isActive);

  const handleAccordion = () => {
    if (activeProfile) {
      setActiveMenu(AvailableMenus.None);
    } else {
      setActiveMenu(AvailableMenus.User);
    }
    setActiveProfile(!activeProfile);
  };

  useEffect(() => {
    setActiveProfile(isActive);
  }, [activeMenu]);

  return (
    <>
      <Accordion
        expanded={activeProfile}
        onChange={handleAccordion}
        className={`${classes.expandListItem} ${classes.expandUserMenuListItem}`}
        id="user-menu"
        key="user-menu"
      >
        <AccordionSummary
          key="profile-summary"
          expandIcon={<ArrowDropDown color="primary" className={classes.expandIcon} />}
          aria-controls="profile-content"
          id="profile-header"
          className={classes.summary}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <UserIcon svgColor="green" />
          </ListItemIcon>
          <ListItemText
            primary={internalUser?.fullName}
            className={isActiveMenu() ? classes.activeListTitle : classes.listTitle}
          />
        </AccordionSummary>
        <AccordionDetails key="profile-details" className={containerClasses.detailsContainer}>
          <List className={classes.list}>
            <SubMenuItem onClick={handleResetPassword} label={"user.reset.password"} isActive={open} />
            <SubMenuItem
              onClick={handleNewUser}
              label={"navigation.setting.content.user"}
              isActive={pathname === AvailableCfmRoutes.UserNew || pathname === AvailablePomRoutes.UserNew}
            />
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
