import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { CfmProductConverter } from "../../../models/converter/cfm-product.converter";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmProductModel } from "../../../models/product/cfm-product.model";
import { ICfmProduct } from "../../../../domain/products/cfm-product";
import { CfmOrderXApiStatus } from "collect-from-market/domain/order-x/cfm-order-x.status";

const getWarehouseProducts = async (
  warehouseId: number | undefined,
  status: CfmOrderXApiStatus[],
): Promise<ICfmProduct[] | undefined> => {
  if (!warehouseId) return undefined;
  const { data } = await getInstance().get<{ items: ICfmProductModel[] }>(
    RestEndpoints.WarehouseProducts.replace(":id", warehouseId.toString()),
    {
      params: {
        status: status,
      },
    },
  );
  return data.items.map(CfmProductConverter.toDomain);
};

export const useGetWarehouseProducts = (warehouseId: number | undefined, status: CfmOrderXApiStatus[]) => {
  return useQuery(
    [CfmQueryKeys.GetWarehouseProducts, warehouseId, ...status],
    () => getWarehouseProducts(warehouseId, status),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: Number.isFinite(warehouseId),
    },
  );
};
