import { Box, Card, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useMemo, useState, VFC } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  CancelButton,
  CancelButtonOutlined,
  ExpandIconButton,
  FilledCloseIconButton,
  ForwardButton,
  SaveButton,
} from "../../../../components/Primitives";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { DRAWER_OPEN } from "../../../../shared/domain/sidebar/sidebar";
import { useSidebarContext } from "../../../../shared/domain/sidebar/sidebar-context";
import { isCfmLogistics, isCfmRecycler } from "../../../../shared/domain/user/user";
import { PrimaryColors } from "../../../../style/Colors";
import { OrderScrollToTopButton } from "../../../pages/order-x/components/order-scroll-to-top-button.component";
import { TruckloadState } from "../../../domain/truckload/truckload-state";

const useStyles = makeStyles((theme: Theme) => ({
  footerStyle: {
    bottom: 0,
    left: 0,
    position: "fixed",
    zIndex: 600,
    minHeight: 100,
  },
  footerWidthSidebarOpen: {
    width: `calc(100% - ${DRAWER_OPEN}px)`,
  },
  footerWidthSidebarClosed: {
    width: "100%",
  },
  containerPadding: {
    padding: "20px 20px 20px 20px",
  },
  weightText: {
    color: PrimaryColors.base,
  },
  mobileButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  container: {
    height: "100%",
  },
}));

interface IEditTruckloadFooterMobileViewProps {
  continueActionsDisabled: boolean;
  onCancel: VoidFunction;
  onCancelTruckload: VoidFunction;
  onSave: VoidFunction;
  onRespondTruckload: VoidFunction;
  weight: number;
  truckloadState: TruckloadState;
}

export const EditTruckloadFooterMobileView: VFC<IEditTruckloadFooterMobileViewProps> = (props) => {
  const { onCancel, onCancelTruckload, onSave, onRespondTruckload, continueActionsDisabled, weight, truckloadState } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const { sidebarOpen } = useSidebarContext();
  const [isExpanded, setIsExpanded] = useState(false);

  const isTruckloadCompleted = truckloadState === TruckloadState.Confirmed;
  const displayRespondOrSubmitButton = useMemo(() => {
    return isCfmRecycler(internalUser) || (isCfmLogistics(internalUser) && truckloadState === TruckloadState.Draft);
  }, [internalUser, truckloadState]);

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.footerStyle, {
        [classes.footerWidthSidebarOpen]: sidebarOpen,
        [classes.footerWidthSidebarClosed]: !sidebarOpen,
      })}
    >
      <Grid item>
        <OrderScrollToTopButton />
      </Grid>
      <Grid item>
        <Card raised elevation={20}>
          <Grid container direction="column" className={classes.containerPadding} spacing={1}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Box display="flex" alignItems="center" className={classes.container}>
                    <Typography variant="body1" className={classes.weightText}>
                      <Trans i18nKey="orders.truckload.truckloadWeight">{{ weight }}</Trans>
                    </Typography>
                  </Box>
                </Grid>
                {!isExpanded && (
                  <Grid item>
                    <ExpandIconButton onClick={() => setIsExpanded(true)} />
                  </Grid>
                )}
                {isExpanded && (
                  <Grid item>
                    <FilledCloseIconButton onClick={() => setIsExpanded(false)} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isExpanded && (
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  {displayRespondOrSubmitButton && (
                    <Grid item xs={12} sm={3}>
                      <ForwardButton
                        onClick={onRespondTruckload}
                        disabled={continueActionsDisabled}
                        className={classes.mobileButton}
                      >
                        <Typography>
                          {isCfmRecycler(internalUser) ? t("orders.truckload.respond") : t("orders.truckload.submit")}
                        </Typography>
                      </ForwardButton>
                    </Grid>
                  )}
                  {!isTruckloadCompleted && isCfmLogistics(internalUser) && (
                    <Grid item xs={12} sm={3}>
                      <CancelButton onClick={onCancelTruckload} className={classes.mobileButton}>
                        <Typography>{t("cancel")}</Typography>
                      </CancelButton>
                    </Grid>
                  )}
                  {!isTruckloadCompleted && (
                    <Grid item xs={12} sm={3}>
                      <SaveButton onClick={onSave} disabled={continueActionsDisabled} className={classes.mobileButton}>
                        <Typography>{t("general.change")}</Typography>
                      </SaveButton>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={3}>
                    <CancelButtonOutlined onClick={onCancel} variant="outlined" className={classes.mobileButton}>
                      <Typography>{t("general.cancel.text")}</Typography>
                    </CancelButtonOutlined>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
