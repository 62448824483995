import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AddButton } from "../../../components/Primitives";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { ProductOverviewList } from "./components/product-overview-list.component";
import { ProductListFilter, ProductListFilterKey } from "../../components/product/product-list-filter.component";
import { PackageOverviewProvider } from "../package/package-overview.provider";
import { ProductOverviewProvider } from "./product-overview.provider";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 30,
    marginBottom: 10,
  },
}));

export const ProductOverviewPage: VFC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <PackageOverviewProvider>
      <ProductOverviewProvider>
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.heading}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h4">{t("configuration.overview.heading")}</Typography>
              </Grid>
              <Grid item>
                <AddButton onClick={() => history.push(AvailableConfiguratorRoutes.CreateProductConfig)}>
                  <Typography variant="body1">{t("configuration.newProduct")}</Typography>
                </AddButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ProductListFilter hidden={[ProductListFilterKey.SortingRegion]} />
          </Grid>
          <Grid item>
            <ProductOverviewList />
          </Grid>
        </Grid>
      </ProductOverviewProvider>
    </PackageOverviewProvider>
  );
};
