import { ICfmResponseField } from "../../../domain/routing-assignment/cfm-response-field";
import { ICfmResponseFieldModel } from "../response-field/cfm-response-field.model";

export class CfmResponseFieldConverter {
  public static toModel(responseField: ICfmResponseField): ICfmResponseFieldModel {
    return {
      id: responseField.id,
      internalNumber: responseField.internalNumber,
      type: responseField.type,
      name: responseField.name,
      unit: responseField.unit,
      active: responseField.active,
      inUse: responseField.inUse,
    };
  }

  public static toDomain(responseField: ICfmResponseFieldModel): ICfmResponseField {
    return {
      id: responseField.id,
      internalNumber: responseField.internalNumber,
      type: responseField.type,
      name: responseField.name,
      unit: responseField.unit,
      active: responseField.active,
      inUse: responseField.inUse,
    };
  }
}
