import { IConfigurationOption } from "../../../configurator/components/product/product-configuration.component";
import { TranslateFunc } from "../../../utils/translation.utils";
import { CfmOrderXApiStatus } from "../order-x/cfm-order-x.status";

export const getOrderStatusOption = (status: CfmOrderXApiStatus, t: TranslateFunc): IConfigurationOption => {
  return {
    value: status,
    label: t(`order.cfm_status_x.${status}.name`),
  };
};
