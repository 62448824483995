import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AddressConverter } from "shared/domain/converter/address.converter";
import { BasicButton } from "../../../../components/Primitives";
import { AvailablePlatform } from "../../../../providers/Auth/platform.provider";
import { ICreateAddressResult } from "../../../domain/address/address";
import { IAddressLean } from "../../../domain/address/address-lean";
import { IGroup } from "../../../domain/group/group";
import { AddressType } from "../../../models/address/address.model";
import { AppDialog } from "../../dialog/app-dialog.component";
import { AddressForm, AddressFormKey, AddressFormMode } from "../address-form/address-form.component";
import { AddressFormProvider } from "../address-form/address-form.provider";

interface IAddressFormModalProps {
  open: boolean;
  onCancel: () => void;
  // We have two use cases:
  // 1 - we are in group detail and create an address for a group
  // 2 - we are in create order and get a group id to create an address for
  group?: IGroup;
  groupId?: number;
  platform: AvailablePlatform;
  onChangedAddress: (result: ICreateAddressResult | undefined) => void;
  addressTypes: AddressType[];
  shouldPersistAddress?: boolean;
  mode: AddressFormMode;
  hide?: AddressFormKey[];
  canDeactivate?: boolean;
  headerText?: string;
}

export const AddressFormModal: React.FunctionComponent<IAddressFormModalProps> = (props) => {
  const {
    open,
    onCancel,
    onChangedAddress,
    mode,
    addressTypes,
    group,
    groupId,
    platform,
    shouldPersistAddress = false,
    hide,
    canDeactivate = true,
    headerText,
  } = props;

  const [addressToFill, setAddressToFill] = useState<DeepPartial<IAddressLean> | undefined>(undefined);

  const { t } = useTranslation();
  const getHeadingText = (): string => {
    if (headerText) return headerText;

    switch (mode) {
      case AddressFormMode.Edit:
        return t("basedata.addresses.edit.text");
      case AddressFormMode.Detail:
        return t("basedata.addresses.detail");
      case AddressFormMode.Create:
        return t("basedata.addresses.create");
    }
  };

  const handleFillAddressClick = () => {
    const address = group?.groupAddress ? AddressConverter.domainToLeanAddressDomain(group.groupAddress) : undefined;
    setAddressToFill({
      address: address?.address,
      addressText: address?.addressText,
      city: address?.city,
      groupId: address?.groupId,
      formattedAddress: address?.formattedAddress,
      buildingNumber: address?.buildingNumber,
      country: address?.country,
      postal: address?.postal,
      name: address?.name,
      state: address?.state,
      name2: address?.name2,
      contactPersonEmail: address?.contactPersonEmail,
      contactPersonName: address?.contactPersonName,
      contactPersonTelephone: address?.contactPersonTelephone,
      openingHours: address?.openingHours,
      active: address?.active,
    });
  };

  return (
    <AppDialog open={open} title={getHeadingText()}>
      {group && (
        <BasicButton
          onClick={() => {
            handleFillAddressClick();
          }}
          className={"mb-3"}
        >
          <Typography variant={"body1"}>{t("basedata.address.prefillAddress")}</Typography>
        </BasicButton>
      )}
      <div key={addressToFill?.name}>
        <AddressFormProvider
          onSuccessCallback={(addressResult) => {
            onChangedAddress(addressResult);
            if (addressToFill) {
              setAddressToFill(undefined);
            }
          }}
          shouldPersistAddress={shouldPersistAddress}
          groupId={group?.id ?? groupId}
          address={addressToFill}
        >
          <AddressForm
            mode={mode}
            displayHeading={false}
            addressTypes={addressTypes}
            shownAsModal={true}
            onCancelCallback={() => {
              setAddressToFill(undefined);
              onCancel();
            }}
            platform={platform}
            hide={hide}
            canDeactivate={canDeactivate}
          />
        </AddressFormProvider>
      </div>
    </AppDialog>
  );
};
