import { Grid, makeStyles, Typography } from "@material-ui/core";
import { VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { ArticleNumberBox } from "../../../../../../../configurator/components/product/article-number-box.component";
import { useAuthContext } from "../../../../../../../providers/Auth/auth.provider";
import { AppDialog } from "../../../../../../../shared/components/dialog/app-dialog.component";
import { FormDatepickerField } from "../../../../../../../shared/components/form/fields/form-datepicker.field";
import { useCustomForm } from "../../../../../../../shared/util/form.util";
import { Shades } from "../../../../../../../style/Colors";
import { useTypographyPillStyles } from "../../../../../../../style/typography-pill.style";
import { ICfmCreateResponseValueX } from "../../../../../../domain/order-x/actions/cfm-create-response-value-x";
import { ICfmTransferOrder } from "../../../../../../domain/transfer-order/cfm-transfer-order";
import { CfmTransferOrderStatus } from "../../../../../../domain/transfer-order/cfm-transfer-order-status";
import {
  IResponseFieldValuesFormInputs,
  IResponseValueServiceDateFormInputs,
} from "../../../actions/order-x-action.component";
import { useSelectedFilesContext } from "../../../actions/providers/selected-files.provider";
import { ResponseXInput } from "../../../actions/response-x-input.component";
import { CfmServiceDatesInput } from "../../../actions/service-date-input.component";
import { CfmResponseFieldXConverter } from "../../../../../../repositories/models/converter/cfm-response-field-x.converter";
import { CfmTransferOrderConverter } from "../../../../../../repositories/models/converter/transfer-order/cfm-transfer-order.converter";
import { useUpdateTransferOrderQuery } from "../../../../../../repositories/queries/transfer-order/mutation/update-transfer-order.query";
import { ICfmTransferOrderAvailableAction } from "../../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { ICfmUpdateTransferOrder } from "../../../../../../domain/transfer-order/cfm-update-transfer-order";

const useStyles = makeStyles(() => ({
  weightBox: {
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: 5,
    padding: "5px 20px 5px 20px",
  },
  weightInfo: {
    padding: "5px 0px 5px 0px",
  },
  dialogTitlePill: {
    position: "absolute",
    right: 32,
  },
}));

export interface IRespondDesinationArrivedRecyclerInputs
  extends IResponseValueServiceDateFormInputs,
    IResponseFieldValuesFormInputs {
  targetAddressId: number;
  actualDeliveryDate: Date;
}

interface IRespondDestinationArrivedRecyclerDialogProps {
  transferOrder: ICfmTransferOrder;
  availableAction: ICfmTransferOrderAvailableAction;
  isOpen: boolean;
  onSubmitted: VoidFunction;
  onCancel: VoidFunction;
}

export const RespondDestinationArrivedRecyclerDialog: VFC<IRespondDestinationArrivedRecyclerDialogProps> = (props) => {
  const { isOpen, onSubmitted, onCancel, transferOrder, availableAction } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const typographyPillClasses = useTypographyPillStyles();
  const { uploadFiles, isLoading: isUploadFilesLoading, reset: resetFileUpload } = useSelectedFilesContext();
  const { internalUser } = useAuthContext();
  const { isLoading: isUpdateLoading, mutateAsync: updateTransferOrder } = useUpdateTransferOrderQuery();

  const getDefaultValues = (): ICfmCreateResponseValueX[] => {
    if (!internalUser) {
      return [];
    }

    return (
      availableAction.responseFieldAssignments?.map((assignment) =>
        CfmResponseFieldXConverter.assignmentToCreateResponseValueX(assignment, -1, internalUser.id),
      ) ?? []
    );
  };
  const formMethods = useCustomForm<IRespondDesinationArrivedRecyclerInputs>({
    defaultValues: {
      responseFields: getDefaultValues(),
    },
  });
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = formMethods;

  const onSubmit: SubmitHandler<IRespondDesinationArrivedRecyclerInputs> = async (inputs) => {
    const filesList = (await uploadFiles()) ?? [];
    const requestData: ICfmUpdateTransferOrder = CfmTransferOrderConverter.formToUpdateDomain(
      inputs,
      CfmTransferOrderStatus.Confirmed,
      transferOrder.product.id,
      filesList,
      undefined,
      inputs.actualDeliveryDate,
    );

    await updateTransferOrder({
      transferOrderId: transferOrder.id,
      requestData,
    });
    resetFileUpload();
    onSubmitted();
  };

  const handleCancel = () => {
    resetFileUpload();
    onCancel();
  };

  return (
    <AppDialog
      open={isOpen}
      title={t("orders.destinationArrived.dialog.title")}
      onCancelClick={handleCancel}
      onAcceptClick={() => handleSubmit(onSubmit)()}
      acceptTextOverride={t("orders.destinationArrived.dialog.submit")}
      maxWidth="sm"
      isLoading={isUploadFilesLoading || isUpdateLoading}
      headerLabelComponent={
        <div className={classes.dialogTitlePill}>
          <Typography variant="body2" className={typographyPillClasses.whitePill}>
            {transferOrder.id}
          </Typography>
        </div>
      }
    >
      <FormProvider {...formMethods}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} className={classes.weightBox}>
              <Grid item>
                <ArticleNumberBox articleNumber={transferOrder.product.articleNumber} />
              </Grid>
              <Grid item>
                <Typography>{transferOrder.product.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} className={classes.weightBox}>
              <Typography className={classes.weightInfo}>
                <Trans i18nKey="orders.destinationArrived.dialog.weightInfo">
                  {{ weight: transferOrder.weight?.toLocaleString() }}
                </Trans>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <FormDatepickerField
              label={t("orders.destinationArrived.dialog.actualDeliveryDate")}
              hasError={Boolean(errors.actualDeliveryDate)}
              control={control}
              required
              name={"actualDeliveryDate"}
              disablePast
            />
          </Grid>
          {availableAction.responseFieldAssignments?.map((field, index) => {
            const responseField = availableAction.responseFieldAssignments?.[index];
            if (!responseField) {
              return <></>;
            }
            return (
              <Grid item>
                <ResponseXInput
                  responseIndex={index}
                  responseFieldId={responseField.responseAssignmentResponseFieldId}
                  fieldType={responseField.type}
                  label={responseField.name}
                  unit={responseField.unit}
                  required={false}
                />
              </Grid>
            );
          })}
          {availableAction.serviceDates && (
            <Grid item>
              <CfmServiceDatesInput serviceDates={availableAction.serviceDates} />
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </AppDialog>
  );
};
