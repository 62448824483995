import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { BattGBrandConverter } from "../../../../../shared/domain/converter/batt-g/batt-g-brand.converter";
import { IBattGBrand } from "../../../../../shared/models/batt-g/batt-g-brand";
import { IBattGBrandModel } from "../../../../../shared/repositories/models/batt-g/batt-g-brand.model";
import { IBattGCreateOrUpdateBrandRequest } from "../../../../../shared/repositories/requests/batt-g/batt-g-create-or-update-brand.request";
import getInstance from "../../../../../utils/rest";
import { PomQueryKeys } from "../../../pom-query-keys";

const updateBrand = async (id: number, request: IBattGCreateOrUpdateBrandRequest): Promise<IBattGBrand> => {
  const { data } = await getInstance().patch<IBattGBrandModel>(`${RestEndpoints.BattGBrands}/${id}`, request);
  return BattGBrandConverter.toDomain(data);
};

export const useUpdateBrandQuery = () => {
  return useMutation(
    [PomQueryKeys.UpdateBrand],
    ({ id, request }: { id: number; request: IBattGCreateOrUpdateBrandRequest }) => updateBrand(id, request),
  );
};
