import { useQuery } from "react-query";
import { RestEndpoints } from "../../../api/endpoints";
import { ReactQueryConfig } from "../../../config/react-query-config";
import getInstance from "../../../utils/rest";
import { IPomProductListModel } from "../models/products/pom-products.model";
import { PomQueryKeys } from "../pom-query-keys";

const getProducts = async (
  page: number,
  pageSize: number,
  searchQuery?: string,
  productTypeIds?: number[],
  takeBackSystemId?: number,
): Promise<IPomProductListModel> => {
  const { data } = await getInstance().get<IPomProductListModel>(RestEndpoints.PomProducts, {
    params: {
      page: page,
      pageSize: pageSize,
      query: searchQuery,
      productTypeIds,
      takeBackSystemId,
    },
  });

  return data;
};

export const useGetProductsQuery = (
  page: number,
  pageSize: number,
  searchQuery?: string,
  productTypeIds?: number[],
  takeBackSystemId?: number,
) => {
  return useQuery(
    [PomQueryKeys.GetProducts, page, pageSize, searchQuery, productTypeIds, takeBackSystemId],
    () => getProducts(page, pageSize, searchQuery, productTypeIds, takeBackSystemId),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: Number.isFinite(takeBackSystemId),
    },
  );
};
