import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IGroupBranchModel } from "../../../models/group/group-branch.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getGroupBranches = async (): Promise<IGroupBranchModel[]> => {
  const { data } = await getInstance().get<IGroupBranchModel[]>(RestEndpoints.GroupBranches);
  return data;
};

export const useGetGroupBranchesQuery = () => {
  return useQuery([SharedQueryKeys.GetGroupBranches], () => getGroupBranches(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: true,
  });
};
