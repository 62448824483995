import { AvailableConfiguratorRoutes } from "../../../../utils/constants";
import { IProductConfigFilter } from "../../../components/product/product.utils";
import { EMPTY_PRODUCT_CONFIG_FILTER } from "../../../components/product/product.utils";

export enum ProductOverviewSortingFilter {
  Date = "Date",
  ArticleNumber = "ArticleNumber",
  Region = "Region",
}

export enum ProductOverviewGeneralFilter {
  All = "All",
  WithoutPackages = "WithoutPackages",
  WithoutRouting = "WithoutRouting",
  WithoutResponseAssignments = "WithoutResponseAssignments",
  WithValidConfiguration = "WithValidConfiguration",
  Inactive = "Inactive",
  Active = "Active",
}

export interface IProductOverviewFilter {
  general: ProductOverviewGeneralFilter[];
  productConfig: IProductConfigFilter;
  searchText: string | undefined;
  sorting: ProductOverviewSortingFilter;
  routingRequired: boolean | undefined;
}

export const getProductFilterInitialValue = (): IProductOverviewFilter => {
  return {
    general: [ProductOverviewGeneralFilter.All],
    productConfig: EMPTY_PRODUCT_CONFIG_FILTER,
    searchText: undefined,
    sorting: ProductOverviewSortingFilter.Date,
    routingRequired: false,
  };
};

export const getRedirectToProductResponseEditConfigUrl = (productId: number, assignmentIds: number[]): string => {
  const url = `${AvailableConfiguratorRoutes.CreateResponseConfig}?ids=${productId}&assignmentIds=${assignmentIds.join(
    ",",
  )}`;

  return url;
};
