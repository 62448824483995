import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { VFC } from "react";
import { FreshdeskTicketsIcon } from "../../../../../components/Primitives";
import { Shades } from "../../../../../style/Colors";
import { ICfmOrderXTicketsModel } from "../../../../repositories/models/order-x/cfm-order-x-tickets.model";
import { OrderTicketStatusBox } from "./order-x-tickets-status-box";
import { formatDateShort } from "../../../../../shared/util/date.util";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  detailText: {
    color: Shades.gray50,
  },
  fitContent: {
    width: "fit-content",
  },

  gridContainer: {
    padding: 2,
  },

  ticketId: {
    fontWeight: "bold",
  },
}));

interface IOrderTicketsSubColumnProps {
  ticket: ICfmOrderXTicketsModel;
}

export const OrderXTicketsRow: VFC<IOrderTicketsSubColumnProps> = (props) => {
  const { ticket } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      {ticket.status && (
        <Grid item xs={2}>
          <Box className={classes.fitContent}>
            <OrderTicketStatusBox status={ticket.status} />
          </Box>
        </Grid>
      )}
      <Grid item xs={2}>
        <Typography className={classes.ticketId}>
          {t("order.tickets.idLabel")}:&nbsp;#{ticket.id}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography>{formatDateShort(new Date(ticket.createdAt))}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{ticket.subject}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography align={"right"}>
          <a href={`https://service.grs-batterien.de/support/tickets/${ticket.id}`}>
            <FreshdeskTicketsIcon />
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};
