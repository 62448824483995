import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroup } from "../../../domain/group/group";
import { IGroupModel } from "../../../models/group/group.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getGroup = async (id: number | undefined): Promise<IGroup> => {
  const url = replaceUriParameter(RestEndpoints.GroupById, "id", id ?? "");
  const response = await getInstance().get<IGroupModel>(url);
  return GroupConverter.toDomain(response.data);
};

// for conditional loading the id can be undefined
export const useGetGroupQuery = (id: number | undefined, enabled: boolean = true) => {
  return useQuery([SharedQueryKeys.GetGroupById, id, enabled], () => getGroup(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: enabled,
  });
};
