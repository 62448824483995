import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { getErrorCode, getErrorStatusCode } from "../../api/api-response";
import config from "../../config/config";
import { UserConverter } from "../../shared/domain/converter/user.converter";
import { IUser } from "../../shared/domain/user/user";
import { useGetLoggedInUserInfoQuery } from "../../shared/repositories/queries/user/get-users-info.query";
import { useIsPasswordResetRequiredQuery } from "../../shared/repositories/queries/auth/is-password-reset-required.query";
import { useLoginAsQuery } from "../../shared/repositories/queries/auth/mutation/login-as.query";
import { useLogoutAsQuery } from "../../shared/repositories/queries/auth/mutation/logout-as.query";
import { useLogoutQuery } from "../../shared/repositories/queries/auth/mutation/logout.query";
import { AuthUtil } from "../../shared/util/auth.util";
import { clearLogin, isUserLoggedIn } from "../../utils/auth";
import { AvailableSharedRoutes } from "../../utils/constants";
import { useLoginAs } from "./login-as-context";

export interface IAuthProviderContextType {
  internalUser: IUser | undefined;
  userInitialRoute: string;
  getUser: () => Promise<IUser | undefined>;
  logout: () => Promise<void>;
  setIsPasswordResetRequired: (user: IUser) => Promise<void>;
  loginAs: (id: number) => Promise<void>;
  logoutAs: () => Promise<void>;
}

export const AuthProviderContext = createContext<IAuthProviderContextType>({} as IAuthProviderContextType);

export const AuthProvider = (props: any) => {
  const value = useAuthProvider();
  return <AuthProviderContext.Provider value={value}>{props.children}</AuthProviderContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthProviderContext);
};

const useAuthProvider = (): IAuthProviderContextType => {
  const [internalUser, setInternalUser] = useState<IUser | undefined>();
  const history = useHistory();
  const { setLoginAsTypes, setLoggedAs } = useLoginAs();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { refetch: refetchUserInfo } = useGetLoggedInUserInfoQuery(false);
  const { mutateAsync: apiLogout } = useLogoutQuery();
  const { mutateAsync: loginAsUser } = useLoginAsQuery();
  const { mutateAsync: logoutAsUser } = useLogoutAsQuery();
  const { refetch: isPasswordResetRequired } = useIsPasswordResetRequiredQuery(false);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userInitialRoute = useMemo(() => {
    return internalUser ? AvailableSharedRoutes.Portal : AvailableSharedRoutes.Login;
  }, [internalUser]);

  const getUser = async (): Promise<IUser | undefined> => {
    if (!isUserLoggedIn()) {
      return undefined;
    }

    if (internalUser) {
      return internalUser;
    }

    try {
      const refetchedUserInfoResult = await refetchUserInfo();
      const userData = refetchedUserInfoResult.data;
      const user = userData?.user;
      const originalUser = userData?.originalUser;
      setInternalUser(user);

      if (originalUser) {
        setLoggedAs(true);
        const types = originalUser.group?.type ? [originalUser.group?.type] : undefined;
        setLoginAsTypes(types);
      }
      return user;
    } catch (error) {
      const errorMsg = getErrorCode(error);
      const errorCode = getErrorStatusCode(error);
      enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
    }
  };

  const logout = async () => {
    // api call required to block the current jwt token
    await apiLogout();
    setInternalUser(undefined);
    clearLogin();
    history.replace(AvailableSharedRoutes.Login);
  };

  const setIsPasswordResetRequired = async (user: IUser): Promise<void> => {
    const isResetRequiredData = await isPasswordResetRequired();
    const isResetRequired = isResetRequiredData.data ?? false;
    setInternalUser({
      ...user,
      mustResetPassword: isResetRequired,
    });
  };

  const loginAs = async (id: number): Promise<void> => {
    const data = await loginAsUser({ id });
    if (!data) {
      return;
    }

    const { token, user } = data;
    localStorage.setItem(config.auth.tokenKey, token);
    const types = internalUser?.group?.type ? [internalUser.group.type] : [];
    const convertedUser = UserConverter.toDomain(user);

    setInternalUser({ ...convertedUser, mustResetPassword: false });
    setLoginAsTypes(types);
    setLoggedAs(true);

    // invalidate all queries
    queueMicrotask(async () => {
      await queryClient.invalidateQueries();
      history.replace(AuthUtil.getRedirectUrl());
    });
  };

  const logoutAs = async (): Promise<void> => {
    const data = await logoutAsUser();
    const { token, user } = data;
    localStorage.setItem(config.auth.tokenKey, token);

    const convertedUser = UserConverter.toDomain(user);
    setInternalUser(convertedUser);
    setLoggedAs(false);
    setLoginAsTypes(undefined);

    // invalidate all queries
    queueMicrotask(async () => {
      await queryClient.invalidateQueries();
      history.replace(AvailableSharedRoutes.Portal);
    });
  };

  return {
    internalUser,
    userInitialRoute,
    getUser,
    logout,
    setIsPasswordResetRequired,
    loginAs,
    logoutAs,
  };
};
