import dayjs from "dayjs";
import { ICreateGroupFormInputs } from "../../components/group/create-group/create-group-form.component";
import { IEditGroupTypeFormInputs } from "../../components/group/edit-group/dialog/edit-group-type-dialog.component";
import { IGroupBaseDataFormInputs } from "../../components/group/group-form/group-form-base-data-section";
import { IGroupBaseDataPaymentInfoFormInputs } from "../../components/group/group-form/group-form-payment-info-section.component";
import { ICreateAddressModel } from "../../models/address/create-address.model";
import {
  IUpdateGroupBaseDataModel,
  IUpdateGroupPaymentInfoModel,
  IUpdateGroupTypeInfoModel,
} from "../../models/group/group-update.model";
import { GroupType } from "../group/group-type";
import { IGroupCreateRequest } from "../requests/group-create-or-edit.request";
import { AddressLeanConverter } from "./address-lean.converter";
import { PaymentInfoConverter } from "./payment-info.converter";

export class GroupMutationConverter {
  public static toUpdateGroupBaseDataModel(
    inputs: IGroupBaseDataFormInputs,
    originalActiveState: Date | null | undefined,
  ): IUpdateGroupBaseDataModel {
    // do not always send a new active date, to prevent history entry
    const active = inputs.active ? originalActiveState ?? new Date() : null;

    return {
      active,
      grsManagedEar: inputs.grsManagedEar,
      name: inputs.name,
      invoiceAddress: inputs.invoiceAddress
        ? AddressLeanConverter.formInputsToModel(inputs.invoiceAddress)
        : inputs.invoiceAddress,
      groupAddress: inputs.groupAddress
        ? AddressLeanConverter.formInputsToModel(inputs.groupAddress)
        : inputs.groupAddress,
      vatId: inputs.vatId,
      tradeRegisterNumber: inputs.tradeRegisterNumber,
      differentInvoiceAddress: inputs.differentInvoiceAddress,
      groupBranchId: inputs.groupBranchId,
    };
  }

  public static toUpdateGroupPaymentInfoModel(
    inputs: IGroupBaseDataPaymentInfoFormInputs,
  ): IUpdateGroupPaymentInfoModel {
    return {
      hasAcceptedSepaMandate: inputs.hasAcceptedSepaMandate,
      paymentInfo: inputs.paymentInfo
        ? PaymentInfoConverter.toCreateOrUpdateRequest(inputs.paymentInfo)
        : inputs.paymentInfo,
    };
  }

  public static toUpdateGroupTypeInfoModel(inputs: IEditGroupTypeFormInputs): IUpdateGroupTypeInfoModel {
    const agencyGroupId = inputs.agencyGroup !== null ? inputs.agencyGroup?.id : null;
    return {
      type: [inputs.type],
      agencyGroupId,
      earNumber: inputs.earNumber,
      earTemporaryNumber: inputs.earTemporaryNumber,
      isPomAgencyAnnouncer: inputs.type === GroupType.PomAgency ? inputs.isPomAgencyAnnouncer ?? false : undefined,
    };
  }

  public static toFormCreateRequest(inputs: ICreateGroupFormInputs): IGroupCreateRequest {
    // PaymentInfo must either be fully filled out or null.
    // If an IBAN is set, we assert that the rest of the fields are also set.
    const paymentInfo = inputs.paymentInfo
      ? PaymentInfoConverter.toCreateOrUpdateRequest(inputs.paymentInfo)
      : undefined;

    return {
      name: inputs.name,
      erpNumber: inputs.erpNumber,
      groupTypes: [inputs.type],
      active: inputs.active ? dayjs().toDate() : undefined,
      addresses: this.getAddresses(inputs),
      agencyGroupId: inputs.agencyGroup?.id,
      differentInvoiceAddress: inputs.differentInvoiceAddress,
      hasAcceptedSepaMandate: inputs.hasAcceptedSepaMandate,
      paymentInfo,
      tradeRegisterNumber: inputs.tradeRegisterNumber,
      vatId: inputs.vatId,
      earNumber: inputs.earNumber,
      earTemporaryNumber: inputs.earTemporaryNumber,
      grsManagedEar: inputs.grsManagedEar,
      groupBranchId: inputs.groupBranchId,
      isPomAgencyAnnouncer: inputs.type === GroupType.PomAgency ? inputs.isPomAgencyAnnouncer ?? false : undefined,
    };
  }

  private static getAddresses(inputs: ICreateGroupFormInputs): ICreateAddressModel[] {
    const addresses: ICreateAddressModel[] = [];
    if (inputs.invoiceAddress && inputs.differentInvoiceAddress) {
      addresses.push(AddressLeanConverter.formInputsToCreateRequest(inputs.invoiceAddress));
    }
    if (inputs.groupAddress) {
      addresses.push(AddressLeanConverter.formInputsToCreateRequest(inputs.groupAddress));
    }
    return addresses;
  }
}
