import { Grid, Typography } from "@material-ui/core";
import React, { VFC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { getPackageQuantityInformation } from "../../utils/order-x-card.util";

export interface IOrderXCardPackageInformation {
  id?: number;
  packageName?: string | null;
  quantityToCollect?: number;
  quantityToDeliver?: number;
}

export const OrderXCardPackageInformation: VFC<IOrderXCardPackageInformation> = (props) => {
  const { t } = useTranslation();
  const { packageName, quantityToDeliver, quantityToCollect } = props;

  const text = useMemo(() => {
    const packageQuantityInfo = getPackageQuantityInformation(t, quantityToCollect, quantityToDeliver);
    const defaultText = [packageName, packageQuantityInfo].filter((s) => s !== undefined).join(`${TEXT_SEPARATOR} `);
    return defaultText;
  }, [packageName, quantityToCollect, quantityToDeliver, t]);

  return (
    <Grid container direction="row">
      <Grid item>
        <Typography variant="body2">{text}</Typography>
      </Grid>
    </Grid>
  );
};
