import { PrimaryColors } from "../../../style/Colors";

/**
 *  BUTTON DEFINITIONS
 */

export const BUTTON_OUTLINE_BORDER = `2px solid ${PrimaryColors.base}`;
export const BUTTON_OUTLINE_BORDER_TRANSPARENT = `2px solid transparent`;

export const PADDING = "14px";
export const BORDER_RADIUS = "3rem";
export const PADDING_BASE = "20px";
export const PADDING_STATUS = "10px";

/**
 *  INPUT DEFINITIONS
 */

export const INPUT_HEIGHT = "40px";

/**
 *  SEARCH INPUT HEIGHT
 */

export const SEARCH_INPUT_NORMAL = "40px";
export const SEARCH_INPUT_LARGE = "60px";

/**
 *  FONT
 */

export const FONT_FAMILY = '"IBM Plex Sans", "Helvetica", "Arial", sans-serif';

/**
 * FONT SIZES
 */

export const FONT_H1 = "32px"; // adjusted
export const FONT_H2 = "53px";
export const FONT_H3 = "42px";
export const FONT_H4 = "29px";
export const FONT_H5 = "22px"; // adjusted
export const FONT_H6 = "18px";
export const FONT_SUBTITLE1 = "32px";
export const FONT_SUBTITLE2 = "24px";
export const FONT_BODY1 = "16px";
export const FONT_BODY2 = "14px";
export const FONT_BUTTON = "16px";
export const FONT_LABEL = "16px";
export const FONT_BADGE_SIZE = "12px";

export const HEADER_MARGIN_TOP = 25;
export const HEADER_MARGIN_BOTTOM = 25;

/**
 * COLORS
 * Mirror of /src/assets/core/_variables-mui.scss
 * SEE BATTBOX-397
 * https://github.com/saubermacherag/battbox-ui/pull/156
 */

export const PRIMARY = "#65B22E";
export const PRIMARY_LIGHT = "#96C33C";
export const PRIMARY_DARK = "#006148";
export const SECONDARY = "#008A32";
export const INHERIT_DEFAULT1 = "#fefefe";
export const INHERIT_DEFAULT2 = "#f8f9ff";
export const BACKGROUND = "#ebedef";
export const SECOND = "#070919";
export const INDIGO = "#7420ff";
export const PURPLE = "#797EB0";
export const PINK = "#fc26a4";
export const RED = "#C01640";
export const ORANGE = "#EF7E3C";
export const YELLOW = "#F2B700";
export const GREEN = "#1bc943";
export const TEAL = "#0CB4E5";
export const CYAN = "#3FB498";
