import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { IGroup } from "../../../../domain/group/group";
import { IUser } from "../../../../domain/user/user";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { AppDialog } from "../../../dialog/app-dialog.component";
import { UserForm, UserFormMode } from "../../../user/user-form/user-form.component";
import { UserFormProvider } from "../../../user/user-form/user-form.provider";

interface IEditGroupUserDialogProps {
  open: boolean;
  user: IUser | undefined;
  group: IGroup;
  onSaved: VoidFunction;
  onCancel: VoidFunction;
}

export const EditGroupUserDialog: VFC<IEditGroupUserDialogProps> = (props) => {
  const { open, user, onSaved, onCancel, group } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const saveClicked = () => {
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupUsers);
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    onSaved();
  };

  return (
    <AppDialog title={t("basedata.users.edit.text")} open={open}>
      <UserFormProvider group={group} user={user}>
        <UserForm
          user={user ? user : undefined}
          userGroup={group}
          mode={UserFormMode.Edit}
          shownAsModal={true}
          onCancelCallback={onCancel}
          onSuccessCallback={saveClicked}
          displayHeading={false}
          jobTitleAndExternalIdHidden
        />
      </UserFormProvider>
    </AppDialog>
  );
};
