import { Grid, LinearProgress } from "@material-ui/core";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { AutofillCatcher } from "../../../../components/AutofillHelper/autofill-catcher";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { AvailablePlatform } from "../../../../providers/Auth/platform.provider";
import { getTranslatedAddressType } from "../../../../utils/address.util";
import { Countries, ICountry } from "../../../../utils/Countries";
import { AddressType, recyclerAddressTypes } from "../../../models/address/address.model";
import { useCustomForm } from "../../../util/form.util";
import { AppDialogButtonRow } from "../../dialog/app-dialog-button-row.component";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormPhoneCountryField } from "../../form/fields/form-phone-country.field";
import { FormSwitch } from "../../form/fields/form-switch.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { FormHeader } from "../../form/form-header.component";
import { useAddressFormContext } from "./address-form.provider";
import { addressToInitialValues } from "./address-form.util";

export interface IAddressFormInputs {
  type: AddressType;
  name: string;
  address: string;
  name2: string;
  buildingNumber: string;
  postal: string;
  city: string;
  country: ICountry;
  state: string;
  lat: number;
  lng: number;
  contactPersonMail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  addressText: string;
  active: boolean;
  openingHours?: string;
}

export type AddressFormKey = keyof IAddressFormInputs;

export enum AddressFormMode {
  Detail,
  Edit,
  Create,
}

interface IAddressFormProps {
  addressTypes?: AddressType[] | undefined;
  onCancelCallback?: () => void;
  shownAsModal?: boolean;
  platform: AvailablePlatform;
  mode: AddressFormMode;
  hide?: AddressFormKey[];
  canDeactivate?: boolean;
  headerText?: string;
  displayHeading?: boolean;
}

export const AddressForm: FC<IAddressFormProps> = ({
  mode,
  addressTypes,
  onCancelCallback,
  shownAsModal = false,
  platform,
  hide,
  canDeactivate = true,
  displayHeading = true,
  headerText,
}) => {
  const { isLoading, handleFormSubmit, address } = useAddressFormContext();
  const { setHasUnsavedData } = useUnsavedDataContext();
  // If multiple addressTypes are passed down, user needs to select one
  const initialAddressType: AddressType | undefined = addressTypes?.length === 1 ? addressTypes[0] : undefined;
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useCustomForm<IAddressFormInputs>({
    defaultValues: addressToInitialValues(address, initialAddressType),
  });

  const { t } = useTranslation();
  const inputsDisabled = mode === AddressFormMode.Detail;
  const selectedAddressType = watch("type");
  const fromAddressActive = watch("active");

  useEffect(() => {
    setHasUnsavedData(isDirty);
  }, [isDirty, setHasUnsavedData]);

  const onSubmit: SubmitHandler<IAddressFormInputs> = async (inputs) => {
    setHasUnsavedData(false);
    const isSuccess = await handleFormSubmit(inputs, mode);
    if (isSuccess) {
      reset();
    }
  };

  const getHeadingText = (): string => {
    if (headerText) return headerText;

    switch (mode) {
      case AddressFormMode.Edit:
        return t("basedata.addresses.edit.text");
      case AddressFormMode.Detail:
        return t("basedata.addresses.detail");
      case AddressFormMode.Create:
        return t("basedata.addresses.create");
    }
  };

  const handleOkClick = (): void => {
    if (shownAsModal && mode === AddressFormMode.Detail && platform === AvailablePlatform.Pom) {
      onCancelCallback?.();
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const getOkText = (): string => {
    if (mode === AddressFormMode.Detail && platform === AvailablePlatform.Cfm) {
      return t("general.edit.text");
    } else if (mode === AddressFormMode.Detail && platform === AvailablePlatform.Pom) {
      return t("general.back.text");
    } else {
      return t("general.save.text");
    }
  };

  const shouldHide = useCallback(
    (key: AddressFormKey) => {
      if (hide == null) return false;
      return hide.includes(key);
    },
    [hide],
  );

  const isCancelButtonVisible = (): boolean => {
    if (platform === AvailablePlatform.Pom) {
      return mode !== AddressFormMode.Detail && shownAsModal;
    } else {
      return true;
    }
  };

  const shouldHideOpeningHours = useMemo(() => {
    const addressesWithoutOpeningHours = [
      AddressType.GroupLocation,
      AddressType.Invoice,
      AddressType.ManufacturerLocation,
    ];
    return shouldHide("openingHours") || addressesWithoutOpeningHours.includes(selectedAddressType);
  }, [selectedAddressType, shouldHide]);

  const getAddressOptions = (): AddressType[] => {
    if (addressTypes && addressTypes.length > 1) {
      return addressTypes;
    } else {
      return [AddressType.CustomerLocation, AddressType.LogisticLocation, ...recyclerAddressTypes];
    }
  };

  const isActiveDeactivated = useMemo(() => {
    return fromAddressActive && !canDeactivate;
  }, [fromAddressActive, canDeactivate]);

  return (
    <form>
      {/* do not remove, only reliable way to prevent chrome from pre filling fields */}
      <AutofillCatcher />
      {displayHeading && <FormHeader>{getHeadingText()}</FormHeader>}
      {isLoading && <LinearProgress />}

      {!isLoading && (
        <Grid container direction="row" spacing={4}>
          <FormField md={12} hide={shouldHide("active")}>
            <FormSwitch
              name="active"
              disabled={inputsDisabled || isActiveDeactivated}
              control={control}
              label={t("basedata.users.edit.active")}
            />
          </FormField>

          <FormField hide={shouldHide("type")}>
            <FormAutocomplete<AddressType>
              disabled={inputsDisabled || (addressTypes !== undefined && addressTypes.length <= 1)}
              control={control}
              required={true}
              label={t("basedata.addresses.edit.addressType.type")}
              options={getAddressOptions()}
              error={Boolean(errors?.type)}
              helperText={errors?.type?.message}
              rules={{ required: !shouldHide("type") }}
              getOptionLabel={(type) => getTranslatedAddressType(type, t)}
              {...register("type", { required: !shouldHide("type") })}
            />
          </FormField>
          {!shouldHide("type") && <Grid item md={6} />}

          <FormField hide={shouldHide("name")}>
            <FormTextField
              disabled={inputsDisabled}
              hasError={Boolean(errors?.name)}
              maxLength={50}
              label={t("basedata.addresses.edit.name")}
              control={control}
              name={"name"}
              required={!shouldHide("name")}
              rules={{ required: !shouldHide("name") }}
            />
          </FormField>

          <FormField hide={shouldHide("name2")}>
            <FormTextField
              required={false}
              disabled={inputsDisabled}
              hasError={Boolean(errors?.name2)}
              maxLength={50}
              label={t("basedata.addresses.edit.name2")}
              name={"name2"}
              control={control}
            />
          </FormField>

          <FormField md={6} hide={shouldHide("country")}>
            <FormAutocomplete<ICountry>
              disabled={inputsDisabled}
              control={control}
              required={true}
              label={t("basedata.addresses.edit.country")}
              options={Countries}
              error={Boolean(errors?.country)}
              rules={{ required: !shouldHide("country") }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(country) => t(country.translationKey)}
              {...register("country")}
            />
          </FormField>

          <FormField hide={shouldHide("state")}>
            <FormTextField
              hasError={Boolean(errors?.state)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.state")}
              control={control}
              name={"state"}
              required={!shouldHide("state")}
              rules={{ required: !shouldHide("state") }}
            />
          </FormField>

          <FormField hide={shouldHide("address")}>
            <FormTextField
              hasError={Boolean(errors?.address)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.address")}
              control={control}
              name={"address"}
              required={!shouldHide("address")}
              rules={{
                required: !shouldHide("address"),
              }}
            />
          </FormField>

          <FormField hide={shouldHide("buildingNumber")}>
            <FormTextField
              hasError={Boolean(errors?.buildingNumber)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.buildingNumber")}
              control={control}
              name={"buildingNumber"}
              required={!shouldHide("buildingNumber")}
              rules={{
                required: !shouldHide("buildingNumber"),
              }}
            />
          </FormField>

          <FormField hide={shouldHide("postal")}>
            <FormTextField
              hasError={Boolean(errors?.postal)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.postal")}
              control={control}
              name={"postal"}
              required={!shouldHide("postal")}
              rules={{
                required: !shouldHide("postal"),
              }}
            />
          </FormField>

          <FormField hide={shouldHide("city")}>
            <FormTextField
              hasError={Boolean(errors?.city)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.city")}
              control={control}
              name={"city"}
              required={!shouldHide("city")}
              rules={{
                required: !shouldHide("city"),
              }}
            />
          </FormField>

          <FormField hide={shouldHide("addressText")}>
            <FormTextField
              required={false}
              hasError={Boolean(errors?.addressText)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.addressText")}
              control={control}
              name={"addressText"}
            />
          </FormField>

          <FormField hide={shouldHide("contactPersonName")}>
            <FormTextField
              hasError={Boolean(errors?.contactPersonName)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.contactPersonName")}
              control={control}
              name={"contactPersonName"}
              required={!shouldHide("contactPersonName")}
              rules={{ required: !shouldHide("contactPersonName") }}
            />
          </FormField>

          <FormField hide={shouldHide("contactPersonMail")}>
            <FormTextField
              hasError={Boolean(errors?.contactPersonMail)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.contactPersonEmail")}
              control={control}
              name={"contactPersonMail"}
              required={!shouldHide("contactPersonMail")}
              rules={{
                validate: async (value) => {
                  if (!shouldHide("contactPersonMail") && (!value || !isEmail(value))) {
                    return t("general.email.required.error");
                  }
                },
              }}
            />
          </FormField>

          <FormField hide={shouldHide("contactPersonPhone")}>
            <FormPhoneCountryField
              name="contactPersonPhone"
              disabled={inputsDisabled}
              label={t("basedata.users.edit.phone")}
              control={control}
              hasError={Boolean(errors?.contactPersonPhone)}
              required={true}
            />
          </FormField>
          <FormField hide={shouldHideOpeningHours}>
            <FormTextField
              required={false}
              hasError={Boolean(errors?.openingHours)}
              disabled={inputsDisabled}
              label={t("basedata.addresses.edit.opening_hours")}
              control={control}
              name={"openingHours"}
              dataTestId="address-form-opening-hours-text-field"
            />
          </FormField>
        </Grid>
      )}

      <AppDialogButtonRow
        isLoading={isLoading}
        acceptTextOverride={getOkText()}
        isCancelVisible={isCancelButtonVisible()}
        onCancelClick={onCancelCallback}
        onAcceptClick={handleOkClick}
        type="button"
        alignButtons="space-between"
      />
    </form>
  );
};
