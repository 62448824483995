import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "../../../components/Primitives";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { GroupDetailStatusTag } from "../../pages/groups/components/group-detail-status-tag.component";
import { Colors, Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    minHeight: 70,
    borderRadius: 4,
    backgroundColor: Colors.grayLight,
  },
  gridWrap: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
    },
  },
  gridContainerHover: {
    "&:hover": {
      backgroundColor: Shades.gray50,
      cursor: "pointer",
    },
  },
  arrowContainer: {
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 55,
      marginTop: "unset",
      marginBottom: "unset",
    },
  },
  columnContainer: {
    height: "100%",
  },
  iconPadding: {
    paddingLeft: 15,
    paddingRight: 25,
  },
  labelContainer: {
    [theme.breakpoints.down("md")]: {
      paddingBottom: 10,
      paddingTop: 10,
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        textAlign: "left",
        paddingLeft: 5,
      },
    },
  },
  mobileAlign: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  paddingBottomAndTop: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  paddingLeftAndRight: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  innerContainer: {
    height: "100%",
  },
}));

interface IInfoBoxProps {
  iconComponent: JSX.Element;
  labelComponent: JSX.Element;
  onClick: VoidFunction;
  noHover?: boolean;
  isActive?: boolean;
  displayNextArrow?: boolean;
  isNew?: boolean;
}

export const InfoBox: VFC<IInfoBoxProps> = (props) => {
  const { iconComponent, labelComponent, onClick, noHover, isActive, displayNextArrow, isNew } = props;
  const classes = useStyles();
  const { isDesktop, isMobile, isTablet, isLaptop } = useScreenSize();
  const { t } = useTranslation();

  const getActiveStatusLabel = (isActive: boolean) => {
    return isActive ? t("general.active") : t("general.inactive");
  };

  const renderNextArrow = () => {
    if (!displayNextArrow) return null;
    return (
      <Grid container direction="column" justifyContent="center">
        <Grid item className={classes.iconPadding}>
          <ArrowRightIcon svgColor="green" />
        </Grid>
      </Grid>
    );
  };

  const renderIsNew = () => {
    if (!isNew) return null;
    return (
      <Grid container direction="column" justifyContent="center">
        <Grid item>
          <GroupDetailStatusTag label={t("general.new")} type={"success"} />
        </Grid>
      </Grid>
    );
  };

  const isNormalView = useMemo(() => {
    return isDesktop || isTablet || isLaptop;
  }, [isDesktop, isTablet, isLaptop]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classNames(classes.gridContainer, {
        [classes.paddingBottomAndTop]: isMobile,
        [classes.gridContainerHover]: !noHover,
        [classes.gridWrap]: isActive === undefined,
      })}
      onClick={onClick}
    >
      <Grid item xs={isNormalView ? "auto" : 12} className={classNames({ [classes.mobileAlign]: !isNormalView })}>
        <Grid container direction="row" className={classes.innerContainer}>
          <Grid item xs={isNormalView ? "auto" : 2} className={classNames({ [classes.mobileAlign]: !isNormalView })}>
            <Grid container direction="column" justifyContent="center" className={classes.columnContainer}>
              <Grid item className={classes.iconPadding}>
                {iconComponent}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={isNormalView ? "auto" : 10}
            className={classNames({ [classes.paddingLeftAndRight]: isMobile })}
          >
            <Grid container direction="column" justifyContent="center" className={classes.columnContainer}>
              <Grid item className={classes.labelContainer}>
                {labelComponent}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isActive !== undefined && (
        <Grid item xs={isNormalView ? "auto" : 12}>
          <Grid container direction="column" justifyContent="center">
            <Grid item>
              <Box style={{ margin: 18 }}>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <GroupDetailStatusTag
                      label={getActiveStatusLabel(isActive)}
                      type={isActive === true ? "success" : "error"}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      {(displayNextArrow || isNew) && (
        <Grid
          item
          xs={isNormalView ? "auto" : 12}
          className={classNames(classes.arrowContainer, { [classes.mobileAlign]: !isNormalView })}
        >
          <Grid container direction="row">
            <Grid item>{renderIsNew()}</Grid>
            <Grid item>{renderNextArrow()}</Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
