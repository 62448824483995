import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { CfmOrderXStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { OrderXFilterButton } from "../status-filter-button/order-x-filter-button";
import { getStatusX, ICfmOrderXFilterStatus } from "./role-x-based-status";
import { ICfmOrderXFilter } from "../../../../domain/order-x/cfm-order-x-filter";

const useStyles = makeStyles({
  boxContainer: {
    overflowY: "hidden",
    overflowX: "hidden",
    display: "flex",
  },
  boxContainerFlow: {
    overflowY: "hidden",
    overflowX: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  scrollContainer: {
    overflowX: "auto",
  },
  statusFilterContainer: {
    flexShrink: 0,
  },
});

export const OrderXStatusFilter: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const { isMobile, isTablet } = useScreenSize();
  const statuses: ICfmOrderXFilterStatus[] = getStatusX(t, internalUser);
  const { setFilter, curFilter, setSelectedOrderId } = useOrderXOverviewContext();

  const handleButtonClick = (status: CfmOrderXStatus | "all", newFilter: ICfmOrderXFilter | undefined) => {
    if (curFilter?.statusFilter === status) {
      return;
    }

    setFilter({ filter: newFilter, statusFilter: status });
    setSelectedOrderId(undefined);
  };

  if (statuses.length === 0) {
    return null;
  }

  return (
    <Box
      className={classNames({
        [classes.boxContainer]: !isMobile && !isTablet,
        [classes.boxContainerFlow]: isMobile || isTablet,
      })}
    >
      <Grid container spacing={1} wrap={"nowrap"} className={classes.scrollContainer}>
        {statuses.map((statusType, index) => {
          const isSelected = curFilter?.statusFilter === statusType.status;
          return (
            <Grid item key={statusType.status} className={classes.statusFilterContainer}>
              <OrderXFilterButton
                key={`${statusType.status}-${index}`}
                isSelected={isSelected}
                orderStatus={statusType.status}
                filter={statusType.filter}
                onClick={() => handleButtonClick(statusType.status, statusType.filter)}
              >
                <Typography variant="button">{statusType.label}</Typography>
              </OrderXFilterButton>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
