import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { DeleteIconButton, EditIconButton } from "../../../components/Primitives";

interface IProductRoutingDialogActionButtonProps {
  onDelete: VoidFunction;
  onEdit: VoidFunction;
}

export const ProductRoutingDialogActionButtons: VFC<IProductRoutingDialogActionButtonProps> = (props) => {
  const { onDelete, onEdit } = props;

  return (
    <Grid container direction="column" justifyContent="space-evenly" style={{ height: "100%" }}>
      <Grid item>
        <EditIconButton onClick={onEdit} />
      </Grid>
      <Grid item>
        <DeleteIconButton onClick={onDelete} />
      </Grid>
    </Grid>
  );
};
