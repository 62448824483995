import { CfmOrderXStatusFilter } from "./cfm-order-x-filter";

export type CfmOrderXStatus = CfmOrderXApiStatus | CfmOrderXUiStatus;

export enum CfmOrderXApiStatus {
  ShoppingCart = 10,
  Ordered = 20,
  LogisticAccepted = 30,
  LogisticAnnounced = 40,
  LogisticPerformed = 50,
  LogisticWarehouse = 60,
  LogisticTruckLoadAnnounced = 70,
  RecyclerIncomingWarehouse = 90,
  RecyclerOutgoingWarehouse = 100,
  DestinationArrived = 110,
  DestinationReceived = 120,
  Confirmed = 600,
  Canceled = 900,
  Unsupported = -1,
}

export enum CfmOrderXUiStatus {
  LogisticAnnouncedWithoutPlannedDeliveryDate = 41,
  LogisticAnnouncedWithPlannedDeliveryDate = 42,
  RecyclerIncomingWarehouseWithoutTruckload = 91,
  SorterWarehouse = 92, // sum type for AP9 recycler incoming warehouse and destination arrived
}

export const getOrderStatusKeys = (includeUnsupportedStatus: boolean) => {
  const statusKeys: number[] = [];
  for (const status in CfmOrderXApiStatus) {
    if (!isNaN(Number(status))) {
      statusKeys.push(Number(status));
    }
  }

  if (!includeUnsupportedStatus) {
    return statusKeys.filter((key) => key !== CfmOrderXApiStatus.Unsupported);
  }

  return statusKeys;
};

export const isLogisticBulkStatus = (status: CfmOrderXStatusFilter) => {
  switch (status) {
    case CfmOrderXApiStatus.Ordered:
    case CfmOrderXApiStatus.LogisticAccepted:
    case CfmOrderXApiStatus.LogisticAnnounced:
      return true;
    default:
      return false;
  }
};

export const isLogisticBulkUpdateStatus = (status: CfmOrderXStatusFilter) => {
  switch (status) {
    case CfmOrderXApiStatus.Ordered:
    case CfmOrderXApiStatus.LogisticAccepted:
    case CfmOrderXApiStatus.LogisticAnnounced:
      return true;
    default:
      return false;
  }
};

export const isLogisticBulkDeliveryReportStatus = (status: CfmOrderXStatusFilter) => {
  switch (status) {
    case CfmOrderXApiStatus.LogisticAccepted:
    case CfmOrderXApiStatus.LogisticAnnounced:
      return true;
    default:
      return false;
  }
};

export const isTruckloadStatus = (status: CfmOrderXStatus) => {
  switch (status) {
    case CfmOrderXApiStatus.LogisticWarehouse:
    case CfmOrderXApiStatus.LogisticTruckLoadAnnounced:
    case CfmOrderXApiStatus.RecyclerIncomingWarehouse:
    case CfmOrderXApiStatus.RecyclerOutgoingWarehouse:
    case CfmOrderXApiStatus.DestinationArrived:
    case CfmOrderXApiStatus.DestinationReceived:
      return true;
    default:
      return false;
  }
};
