import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmResponseValueReport } from "../../../../domain/report/cfm-response-value-report";
import { CfmOrderXResponseReportConverter } from "../../../models/converter/order-x/cfm-order-x-response-report.converter";
import { ICfmResponseValueReportModel } from "../../../models/order-x/report/cfm-response-value-report.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getCfmOrderResponseReport = async (orderId: number): Promise<ICfmResponseValueReport> => {
  const { data } = await getInstance().get<ICfmResponseValueReportModel>(
    RestEndpoints.CfmOrderXResponseReport.replace(":id", orderId.toString()),
  );
  return CfmOrderXResponseReportConverter.toDomain(data);
};

export const useGetCfmOrderResponseReportQuery = (orderId: number) => {
  return useQuery([CfmQueryKeys.GetCfmOrderResponseReport, orderId], () => getCfmOrderResponseReport(orderId), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
