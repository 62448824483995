import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProductStateModel } from "../../../models/product/cfm-product-state.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmProductState } from "../../../../domain/products/cfm-product-state";
import { CfmProductStateConverter } from "../../../models/converter/cfm-product-state.converter";

const getProductStates = async (): Promise<ICfmProductState[]> => {
  const { data } = await getInstance().get<ICfmProductStateModel[]>(RestEndpoints.AllProductStates);
  return data.map(CfmProductStateConverter.toDomain);
};

export const useGetProductStatesQuery = () => {
  return useQuery([CfmQueryKeys.GetProductStates], () => getProductStates(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
