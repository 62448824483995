import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { CfmQueryKeys } from "../../../collect-from-market/repositories/queries/cfm-query-keys";
import { useGetProductCountQuery } from "../../../collect-from-market/repositories/queries/product/query/get-product-count.query";
import SearchField from "../../../components/SearchField";
import { CustomRadio } from "../../../shared/components/radio/custom-radio.component";
import { usePackageOverviewContext } from "../../pages/package/package-overview.provider";
import { useProductOverviewContext } from "../../pages/product/product-overview.provider";
import { HidableGridItem } from "../grid/hidable-grid-item.component";
import {
  ProductOverviewGeneralFilter,
  ProductOverviewSortingFilter,
} from "../../pages/product/components/product-overview.utils";
import { ProductTagFilter } from "../../pages/product/components/product-tag-filter.component";
import { ProductConfigurationFilter } from "./product-config-filter.component";
import { IProductConfigFilter } from "./product.utils";

const useStyles = makeStyles((theme: Theme) => ({
  radioBox: {
    height: "100%",
  },
  label: {
    textAlign: "end",
    marginTop: 2,
  },
  labelContent: {
    minWidth: 130,
  },
}));

export enum ProductListFilterKey {
  TagAll,
  TagWithoutPackage,
  TagWithoutRouting,
  TagWithoutResponseAssignments,
  TagInactiveProducts,
  Search,
  ProductFilter,
  SortingDate,
  SortingArticleNumber,
  SortingRegion,
}

interface IProductListFilterProps {
  hidden?: ProductListFilterKey[];
  withoutPackageTextOverride?: string;
  withoutRoutingTextOverride?: string;
  withoutResponseTextOverride?: string;
  inactiveTextOverride?: string;
  displayResponseFilters?: boolean;
}

export const ProductListFilter: VFC<IProductListFilterProps> = (props) => {
  const {
    hidden,
    withoutPackageTextOverride,
    withoutRoutingTextOverride,
    withoutResponseTextOverride,
    inactiveTextOverride,
    displayResponseFilters,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { filter, setFilter } = useProductOverviewContext();
  const { setSelectedProductIds } = usePackageOverviewContext();

  const { data: withoutPackageCount } = useGetProductCountQuery(ProductOverviewGeneralFilter.WithoutPackages);
  const { data: withoutRoutingCount } = useGetProductCountQuery(ProductOverviewGeneralFilter.WithoutRouting);
  const { data: withoutResponseCount } = useGetProductCountQuery(
    ProductOverviewGeneralFilter.WithoutResponseAssignments,
  );
  const { data: inactiveCount } = useGetProductCountQuery(ProductOverviewGeneralFilter.Inactive);

  useEffect(() => {
    // ensure query is in initial state when component is mounted (e.g. in case of redirect)
    queryClient.resetQueries(CfmQueryKeys.GetAllProductsInfinite);
  }, [queryClient]);

  useEffect(() => {
    setSelectedProductIds([]);
  }, [filter, setSelectedProductIds]);

  const setProductConfigFilter = (config: IProductConfigFilter) => {
    setFilter({ ...filter, productConfig: config });
  };

  const setGeneralFilter = (type: ProductOverviewGeneralFilter) => {
    setFilter({ ...filter, general: [type] });
  };

  const setSearchFilter = (text: string) => {
    setFilter({ ...filter, searchText: text });
  };

  const setSorting = (type: ProductOverviewSortingFilter) => {
    setFilter({ ...filter, sorting: type });
  };

  const isHidden = (filterKey: ProductListFilterKey) => {
    return (hidden ?? []).includes(filterKey);
  };

  const isSelected = (filterType: ProductOverviewGeneralFilter): boolean => {
    const allowedActiveTagFilterCount = 1;
    return filter.general.length === allowedActiveTagFilterCount && filter.general.includes(filterType);
  };

  const getCount = (count: number | undefined) => {
    return count && count > 0 ? count : undefined;
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.TagAll)}>
            <ProductTagFilter
              label={t(`configuration.overview.filter.${ProductOverviewGeneralFilter.All}`)}
              onClick={() => setGeneralFilter(ProductOverviewGeneralFilter.All)}
              isSelected={isSelected(ProductOverviewGeneralFilter.All)}
            />
          </HidableGridItem>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.TagWithoutPackage)}>
            <ProductTagFilter
              label={
                withoutPackageTextOverride ??
                t(`configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutPackages}.default`)
              }
              onClick={() => setGeneralFilter(ProductOverviewGeneralFilter.WithoutPackages)}
              isSelected={isSelected(ProductOverviewGeneralFilter.WithoutPackages)}
              count={getCount(withoutPackageCount)}
            />
          </HidableGridItem>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.TagWithoutRouting)}>
            <ProductTagFilter
              label={
                withoutRoutingTextOverride ??
                t(`configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutRouting}.default`)
              }
              onClick={() => setGeneralFilter(ProductOverviewGeneralFilter.WithoutRouting)}
              isSelected={isSelected(ProductOverviewGeneralFilter.WithoutRouting)}
              count={getCount(withoutRoutingCount)}
            />
          </HidableGridItem>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.TagWithoutResponseAssignments)}>
            <ProductTagFilter
              label={
                withoutResponseTextOverride ??
                t(`configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutResponseAssignments}.default`)
              }
              onClick={() => setGeneralFilter(ProductOverviewGeneralFilter.WithoutResponseAssignments)}
              isSelected={isSelected(ProductOverviewGeneralFilter.WithoutResponseAssignments)}
              count={getCount(withoutResponseCount)}
            />
          </HidableGridItem>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.TagInactiveProducts)}>
            <ProductTagFilter
              label={
                inactiveTextOverride ??
                t(`configuration.overview.filter.${ProductOverviewGeneralFilter.Inactive}.default`)
              }
              onClick={() => setGeneralFilter(ProductOverviewGeneralFilter.Inactive)}
              isSelected={isSelected(ProductOverviewGeneralFilter.Inactive)}
              count={getCount(inactiveCount)}
            />
          </HidableGridItem>
        </Grid>
      </Grid>
      <HidableGridItem hidden={isHidden(ProductListFilterKey.Search)}>
        <SearchField
          initialValue={filter.searchText}
          size="large"
          tooltip={t("configuration.search.productsToolTip")}
          onSearchSubmit={(value: string) => {
            setSearchFilter(value);
          }}
          placeholder={t("configuration.search.products")}
          autoFocus={false}
        />
      </HidableGridItem>
      <HidableGridItem hidden={isHidden(ProductListFilterKey.ProductFilter)}>
        <ProductConfigurationFilter
          productConfigFilter={filter.productConfig}
          setProductConfigFilter={setProductConfigFilter}
          displayProductCountText={false}
          displayOrderStatus={displayResponseFilters}
        />
      </HidableGridItem>
      <HidableGridItem
        hidden={isHidden(ProductListFilterKey.SortingDate) && isHidden(ProductListFilterKey.SortingArticleNumber)}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item className={classes.label}>
            <Box display={"flex"} alignItems={"center"} className={classes.radioBox}>
              <Typography variant="body1" className={classes.labelContent}>
                {t("configuration.sortBy")}
              </Typography>
            </Box>
          </Grid>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.SortingDate)}>
            <Box display={"flex"} alignItems={"center"} className={classes.radioBox}>
              <CustomRadio
                label={t("configuration.createDate")}
                checked={filter.sorting === ProductOverviewSortingFilter.Date}
                onChange={() => setSorting(ProductOverviewSortingFilter.Date)}
                value="date"
              />
            </Box>
          </HidableGridItem>
          <HidableGridItem hidden={isHidden(ProductListFilterKey.SortingArticleNumber)}>
            <Box display={"flex"} alignItems={"center"} className={classes.radioBox}>
              <CustomRadio
                label={t("configuration.articleNumber")}
                checked={filter.sorting === ProductOverviewSortingFilter.ArticleNumber}
                onChange={() => setSorting(ProductOverviewSortingFilter.ArticleNumber)}
                value="articleNumber"
              />
            </Box>
          </HidableGridItem>

          <HidableGridItem hidden={isHidden(ProductListFilterKey.SortingRegion)}>
            <Box display={"flex"} alignItems={"center"} className={classes.radioBox}>
              <CustomRadio
                label={t("configuration.region")}
                checked={filter.sorting === ProductOverviewSortingFilter.Region}
                onChange={() => setSorting(ProductOverviewSortingFilter.Region)}
                value="region"
              />
            </Box>
          </HidableGridItem>
        </Grid>
      </HidableGridItem>
    </Grid>
  );
};
