import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ChipFilter, IOption } from "../../../../components/Filters/ChipFilter";

export enum GroupTypeFilterValues {
  Admin = 1,
  Customer = 2,
  CfmCustomer = 3,
  CfmLogistics = 4,
  CfmRecycler = 5,
  Pom = 6,
  PomAndCfmCustomer = 7,
  PomAgency = 8,
  PomRepresentative = 9,
}

export const CFM_GROUP_TYPE_FILTER_VALUES = [
  GroupTypeFilterValues.Admin,
  GroupTypeFilterValues.CfmCustomer,
  GroupTypeFilterValues.CfmRecycler,
  GroupTypeFilterValues.CfmLogistics,
];
export const POM_GROUP_TYPE_FILTER_VALUES = [
  GroupTypeFilterValues.Pom,
  GroupTypeFilterValues.PomAgency,
  GroupTypeFilterValues.PomRepresentative,
];

interface IGroupTypeFilterProps {
  value: GroupTypeFilterValues | undefined;
  onChange: (newGroupTypes: GroupTypeFilterValues | undefined) => void;
}

export const GroupTypeFilter: VFC<IGroupTypeFilterProps> = ({ value: selected, onChange }) => {
  const { t } = useTranslation();

  const options = [
    GroupTypeFilterValues.Admin,
    GroupTypeFilterValues.Customer,
    GroupTypeFilterValues.CfmCustomer,
    GroupTypeFilterValues.CfmLogistics,
    GroupTypeFilterValues.CfmRecycler,
    GroupTypeFilterValues.Pom,
    GroupTypeFilterValues.PomAndCfmCustomer,
    GroupTypeFilterValues.PomAgency,
    GroupTypeFilterValues.PomRepresentative,
  ];

  const getGroupTypeFilterTranslation = (type: GroupTypeFilterValues) => {
    const groupTypeKey = GroupTypeFilterValues[type]?.replace(/([a-z])([A-Z])/g, "$1_$2");
    const id = `basedata.users.table.group.${groupTypeKey}`.toLowerCase();
    return t(id);
  };

  const possibleOptions: IOption[] = options.map((type) => {
    return {
      value: type,
      displayValue: getGroupTypeFilterTranslation(type),
    };
  });

  return (
    <ChipFilter
      possibleOptions={possibleOptions}
      label={t("basedata.groups.table.type")}
      filter={selected}
      setFilter={onChange}
    />
  );
};
