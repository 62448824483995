import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { IPomAgencyGroupListModel } from "../../../../put-on-market/repositories/models/agencies/pom-agency-groups.model";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroup } from "../../../domain/group/group";
import { SharedQueryKeys } from "../shared-query-keys";

const getAllAgencies = async (): Promise<IGroup[]> => {
  const { data } = await getInstance().get<IPomAgencyGroupListModel>(RestEndpoints.PomAgencies);
  return data.items?.map(GroupConverter.toDomain);
};

export const useGetAllAgenciesQuery = (enabled?: boolean) => {
  return useQuery([SharedQueryKeys.GetAllAgencies], () => getAllAgencies(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: enabled ?? true,
  });
};
