import { formatPhoneNumberIntl } from "react-phone-number-input";

export class PhoneNumberUtil {
  public static transformPhoneNumber(value: string): string {
    // Needed for invalid phone numbers that existed before the validation logic
    // This can be removed if no old phone numbers without the country code in it exist anymore
    if (value?.startsWith("00")) {
      return formatPhoneNumberIntl(`+${value.slice(2)}`);
    }
    if (value?.startsWith("0")) {
      return formatPhoneNumberIntl(`+${value.slice(1)}`);
    }

    return value;
  }
}
