import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AvailableCfmRoutes, AvailableSharedRoutes } from "../../../utils/constants";

interface IScrollToTopContextType {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const IScrollToTopContext = createContext<IScrollToTopContextType>({} as IScrollToTopContextType);

export const ScrollToTopProvider = (props: any) => {
  const value = useScrollToTopProvider();
  return <IScrollToTopContext.Provider value={value}>{props.children}</IScrollToTopContext.Provider>;
};

export const useScrollToTopContext = () => {
  return useContext(IScrollToTopContext);
};

const pathsWithScrollToTopButton = [
  AvailableSharedRoutes.GroupNew,
  AvailableSharedRoutes.GroupDetail.replace(":id", "[0-9]+"),
  AvailableCfmRoutes.CartX,
  AvailableCfmRoutes.OrderXOverview,
];

const useScrollToTopProvider = (): IScrollToTopContextType => {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();

  const isButtonEnabled = useMemo(() => {
    return pathsWithScrollToTopButton.some((path) => new RegExp(path).test(pathname));
  }, [pathname]);

  useEffect(() => {
    setIsVisible(isButtonEnabled);
  }, [isButtonEnabled]);

  return {
    isVisible,
    setIsVisible,
  };
};
