import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CfmServiceDate } from "../../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { CfmOrderXApiStatus } from "../../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { getOrderStatusOption } from "../../../../collect-from-market/domain/order-x/order-x.util";
import { useGetResponseFieldsQuery } from "../../../../collect-from-market/repositories/queries/response-field/get-response-fields.query";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { useCustomForm } from "../../../../shared/util/form.util";
import { removeNullAndUndefined } from "../../../../utils/filter.util";
import {
  IResponseAssignmentRowValue,
  IResponseFieldData,
  useProductResponseAssignmentContext,
} from "../../../pages/product-response/product-response-assignment.provider";
import { AssignResponseFieldsFormContent } from "../form/assign-fields-dialog/response-fields-form-content.component";
import { ServiceDateForm } from "../form/assign-fields-dialog/service-date-form.component";
import { CfmResponseFieldBillingType } from "../../../domain/response-field-billing-type";
import { useGetAllProductsQuery } from "../../../../collect-from-market/repositories/queries/product/query/get-products.query";

export interface IResponseFieldFormInputs {
  fieldId: number;
  isBillingRelevant: boolean | null;
  hasWarehouseArticle: boolean;
  serviceDate: CfmServiceDate | null;
  warehouseArticleId: number | null;
  isCredit: boolean | null;
}

export interface IResponseAssignmentFormInputs {
  responseFieldsInputs: IResponseFieldFormInputs[];
  status: CfmOrderXApiStatus;
  serviceDates: CfmServiceDate[];
}

const statusWithServiceDatesOnly = [CfmOrderXApiStatus.LogisticTruckLoadAnnounced];

interface IAssignResponseFieldsDialogProps {
  open: boolean;
  onAccept: (values: IResponseAssignmentRowValue) => void;
  onCancel: VoidFunction;
}

export const AssignResponseFieldsDialog: VFC<IAssignResponseFieldsDialogProps> = (props) => {
  const { open, onAccept, onCancel } = props;
  const { activeStatus, getActiveStatusIndex, selectedResponseAssignmentRowValues } =
    useProductResponseAssignmentContext();
  const { t } = useTranslation();
  const getInitialValues = () => {
    const initValuesForStatus = selectedResponseAssignmentRowValues.find((values) => values.status === activeStatus);
    if (!initValuesForStatus) return { status: activeStatus, responseFields: [{}] };

    let responseFieldsInput: IResponseFieldFormInputs[] = [];

    if (initValuesForStatus.responseFieldData) {
      responseFieldsInput = initValuesForStatus.responseFieldData.map((field) => {
        return {
          fieldId: field.id,
          isBillingRelevant: field.serviceDate != null,
          serviceDate: field.serviceDate,
          hasWarehouseArticle: field.warehouseArticleId != null,
          warehouseArticleId: field.warehouseArticleId ?? null,
          isCredit: field.billingType === CfmResponseFieldBillingType.Credit,
        };
      });
    }

    const initValues: IResponseAssignmentFormInputs = {
      status: initValuesForStatus.status,
      serviceDates: initValuesForStatus.serviceDates ?? [],
      responseFieldsInputs: responseFieldsInput,
    };
    return initValues;
  };

  const formMethods = useCustomForm<IResponseAssignmentFormInputs>({ defaultValues: getInitialValues(), mode: "all" });
  const { handleSubmit } = formMethods;

  const { data: responseFields, isLoading } = useGetResponseFieldsQuery();
  const { isLoading: areProductsLoading } = useGetAllProductsQuery(false);
  const canAssignFields = useMemo(
    () => activeStatus && !statusWithServiceDatesOnly.includes(activeStatus),
    [activeStatus],
  );

  const getTitle = () => {
    if (!activeStatus) return "";
    const labelText = t(`Status: ${getOrderStatusOption(activeStatus, t).label}`);
    const indexText = `[${getActiveStatusIndex() + 1}]`;
    return `${labelText} ${indexText}`;
  };

  const mapInputToResponseFieldData = (input: IResponseFieldFormInputs): IResponseFieldData | undefined => {
    const field = (responseFields ?? []).find((field) => field.id === input.fieldId);
    if (!field) return undefined;

    let billingType: CfmResponseFieldBillingType | null = null;
    if (input.isBillingRelevant) {
      billingType = input.isCredit ? CfmResponseFieldBillingType.Credit : CfmResponseFieldBillingType.Invoice;
    }

    const responseFieldData: IResponseFieldData = {
      id: field.id,
      name: field.name,
      type: field.type,
      serviceDate: input.isBillingRelevant && input.serviceDate ? input.serviceDate : null,
      warehouseArticleId: input.hasWarehouseArticle && input.warehouseArticleId ? input.warehouseArticleId : null,
      billingType: billingType,
      internalNumber: field.internalNumber,
      unit: field.unit,
    };

    return responseFieldData;
  };

  const onSubmit: SubmitHandler<IResponseAssignmentFormInputs> = async (inputs): Promise<void> => {
    const existingValues = selectedResponseAssignmentRowValues.find((value) => value.status === inputs.status);
    if (!existingValues) return;

    const responseFieldData = inputs.responseFieldsInputs
      .map((inputField) => mapInputToResponseFieldData(inputField))
      .filter(removeNullAndUndefined);
    const newAssignment: IResponseAssignmentRowValue = {
      status: inputs.status,
      serviceDates: inputs.serviceDates,
      responseFieldData,
    };

    onAccept(newAssignment);
  };

  return (
    <AppDialog
      open={open}
      title={getTitle()}
      onAcceptClick={() => {
        handleSubmit(onSubmit)();
      }}
      onCancelClick={onCancel}
      isLoading={isLoading || areProductsLoading}
      acceptTextOverride={t("save")}
      alignButtons="space-between"
    >
      {isLoading && (
        <Grid item>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <FormProvider {...formMethods}>
          <form>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <ServiceDateForm />
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              {canAssignFields && (
                <Grid item>
                  <AssignResponseFieldsFormContent responseFields={responseFields ?? []} />
                </Grid>
              )}
            </Grid>
          </form>
        </FormProvider>
      )}
    </AppDialog>
  );
};
