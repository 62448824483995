import React, { ChangeEvent, FunctionComponent } from "react";

interface IAutofillCatcherProps {}

export const AutofillCatcher: FunctionComponent<IAutofillCatcherProps> = (props) => {
  const onAutoComplete: EventListener = (e: any) => {
    const event = e as ChangeEvent;
    // Exclude Phone Number Input, otherwise the component would throw an error
    if (event?.target?.className === "PhoneInputCountrySelect") {
      return;
    }
    event.preventDefault(); // prevent autocomplete
  };

  document.addEventListener("onautocomplete", onAutoComplete);

  return (
    <>
      <input
        type="text"
        style={{
          opacity: 0,
          position: "absolute",
          width: 0,
        }}
      />
      <input
        type="password"
        style={{
          opacity: 0,
          position: "absolute",
          width: 0,
        }}
      />
    </>
  );
};
