import { makeStyles, Theme } from "@material-ui/core";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CfmProductArticleType } from "../../../../../configurator/components/product/product.utils";
import { FormNumberSelector } from "../../../../../shared/components/form/fields/form-number-selector.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { positiveNumberOnly } from "../../../../../utils/validationHelper";
import { ICfmCreatePackage } from "../../../../domain/packages/cfm-package";
import { useGetProductQuery } from "../../../../repositories/queries/product/query/get-product.query";
import { IOrderNewWizardFormInputs, IOrderNewWizardFormProductInput } from "../order-new-wizard.component";
import config from "../../../../../config/config";

const useStyles = makeStyles((theme: Theme) => ({
  formSpacing: {
    marginTop: theme.spacing(4),
  },
}));

interface IPackageFormDialogContentProps {
  productOrderIndex: number;
}

export const PackageFormDialogContent: FC<IPackageFormDialogContentProps> = (props) => {
  const { productOrderIndex } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    setValue,
    trigger,
    getValues,
    watch,
    control,
  } = useFormContext<IOrderNewWizardFormInputs>();
  const productOrder: IOrderNewWizardFormProductInput = getValues(`productOrders.${productOrderIndex}`);
  const containersToBeCollectedError = t("orders.new.wizard.package.error.containersToBeCollected");
  const containersToBeDeliveredError = t("orders.new.wizard.package.error.containersToBeDelivered");
  const productId: number | undefined = watch("productId");
  const { data: product } = useGetProductQuery(productId);

  const calculateNetAndGrossWeight = (
    productPackage: ICfmCreatePackage | undefined,
    containersToBeCollected: number,
  ): { grossWeight: number; netWeight: number } | undefined => {
    if (!productPackage || !containersToBeCollected) {
      return;
    }

    const packageHasWeights =
      productPackage.measurementsRequired === false
        ? productPackage.averageGrossWeightKg && productPackage.tareWeightKg
        : undefined;

    if (!packageHasWeights || productPackage.measurementsRequired === true) return undefined;

    const packageWeight = containersToBeCollected * (productPackage.tareWeightKg ?? 0);
    const grossWeight = containersToBeCollected * (productPackage.averageGrossWeightKg ?? 0);

    const netWeight = grossWeight - packageWeight;

    if (netWeight === 0) return undefined;

    return {
      grossWeight,
      netWeight,
    };
  };

  const onSelectorContainerToBeCollectedClick = (value: number) => {
    const fieldPackage: ICfmCreatePackage | undefined = productOrder.package;
    if (!fieldPackage) {
      return;
    }
    const weights = calculateNetAndGrossWeight(fieldPackage, value);

    setValue(`productOrders.${productOrderIndex}`, {
      ...productOrder,
      productGrossWeight: weights?.grossWeight ?? productOrder.productGrossWeight,
      productNetWeight: weights?.netWeight ?? productOrder.productNetWeight,
      package: {
        ...fieldPackage,
        containersToBeCollected: value,
      },
    });
  };

  const onContainerToBeCollectedValueChanged = (value: number) => {
    const fieldPackage: ICfmCreatePackage | undefined = productOrder.package;
    if (!fieldPackage) {
      return;
    }
    const weights = calculateNetAndGrossWeight(fieldPackage, value);
    setValue(
      `productOrders.${productOrderIndex}.productGrossWeight`,
      weights?.grossWeight ?? productOrder.productGrossWeight,
    );
    setValue(
      `productOrders.${productOrderIndex}.productNetWeight`,
      weights?.netWeight ?? productOrder.productNetWeight,
    );
  };

  const onSelectorContainerToBeDeliveredClick = (value: number) => {
    const fieldPackage: ICfmCreatePackage | undefined = productOrder.package;
    if (!fieldPackage) {
      return;
    }

    setValue(`productOrders.${productOrderIndex}`, {
      ...productOrder,
      package: {
        ...fieldPackage,
        containersToBeDelivered: value,
      },
    });
  };

  const positiveNumberOnlyValidation = (value: number | undefined, errorMessage: string, allowZero: boolean) => {
    if (!value) return errorMessage;
    return positiveNumberOnly(value, errorMessage, allowZero);
  };

  const positiveOrUndefinedNumberValidation = (value: number | undefined, errorMessage: string) => {
    if (!value) return true;
    return positiveNumberOnly(value, errorMessage, true);
  };

  const onSoQuantityChange = (value: number) => {
    setValue("singleOrderQuantity", value);
    trigger("singleOrderQuantity");
  };

  const isSingleOrderProduct = useMemo(() => {
    return product?.articleType === CfmProductArticleType.SingleOrderProduct;
  }, [product]);

  const withDelivery = useMemo(() => {
    return !config.enableOrderSplitting || productOrder.package?.withPackageDelivery
  }, [config, productOrder]);

  return (
    <>
      {!isSingleOrderProduct && (
        <>
          <FormField md={12}>
            <FormNumberSelector
              control={control}
              key={"container-to-be-collected-key"}
              inputProps={{ inputProps: { min: 0 } }}
              hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.containersToBeCollected)}
              label={t("orders.new.wizard.package.containersToBeCollected")}
              required={true}
              onSelectorClick={onSelectorContainerToBeCollectedClick}
              onChangedValue={onContainerToBeCollectedValueChanged}
              {...register(`productOrders.${productOrderIndex}.package.containersToBeCollected` as const, {
                required: true,
                validate: (value) => positiveNumberOnlyValidation(value, containersToBeCollectedError, false),
              })}
            />
          </FormField>
          {withDelivery && (
            <FormField md={12} className={classes.formSpacing}>
              <FormNumberSelector
                control={control}
                key={"container-to-be-delivered-key"}
                inputProps={{ inputProps: { min: 0 } }}
                hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.containersToBeDelivered)}
                label={t("orders.new.wizard.package.containersToBeDelivered")}
                onSelectorClick={onSelectorContainerToBeDeliveredClick}
                helperTooltip={t("orders.new.wizard.package.containersToBeDeliveredTooltip")}
                required={false}
                {...register(`productOrders.${productOrderIndex}.package.containersToBeDelivered` as const, {
                  required: false,
                  validate: (value) => positiveOrUndefinedNumberValidation(value, containersToBeDeliveredError),
                })}
              />
            </FormField>
          )}
        </>
      )}
      {isSingleOrderProduct && (
        <FormField md={12}>
          <FormNumberSelector
            control={control}
            key={"single-order-quantity"}
            hasError={Boolean(errors.singleOrderQuantity)}
            label={t("orders.new.wizard.submit.soAddonQuantity")}
            onSelectorClick={onSoQuantityChange}
            {...register(`singleOrderQuantity` as const, {
              required: true,
              validate: (value) =>
                positiveNumberOnlyValidation(value, t("orders.new.wizard.submit.error.soAddonQuantity"), true),
            })}
          />
        </FormField>
      )}
    </>
  );
};
