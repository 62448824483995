import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AddButton } from "../../../components/Primitives";
import { PrimaryColors } from "../../../style/Colors";
import { ConfigurationAddResponseFieldDialog } from "../../components/product-response/dialog/add-response-field-dialog.component";
import { ResponseFieldListFilter } from "../../components/response-field/response-field-list-filter.component";
import { ResponseFieldList } from "../../components/response-field/response-field-list.component";
import { PackageOverviewProvider } from "../package/package-overview.provider";
import { ProductOverviewProvider } from "../product/product-overview.provider";
import { ProductResponseFieldOverviewProvider } from "./product-response-field-overview.provider";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 20,
    marginBottom: 30,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
    marginTop: 5,
  },
  listContainer: {
    marginTop: 16,
  },
}));

export const ProductResponseFieldOverview: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isAddResponseFieldDialogOpen, setIsAddResponseFieldDialogOpen] = useState(false);

  const onAddResponseField = () => {
    setIsAddResponseFieldDialogOpen(true);
  };

  const onCloseResponseFieldDialog = () => {
    setIsAddResponseFieldDialogOpen(false);
  };

  return (
    <PackageOverviewProvider>
      <ProductOverviewProvider>
        <ProductResponseFieldOverviewProvider>
          {isAddResponseFieldDialogOpen && (
            <ConfigurationAddResponseFieldDialog
              open={isAddResponseFieldDialogOpen}
              onCancel={onCloseResponseFieldDialog}
              onAccept={onCloseResponseFieldDialog}
              acceptText={t("save")}
              maxWidth="sm"
            />
          )}
          <Grid container direction="column">
            <Grid item className={classes.heading}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">{t("configuration.responseField.heading")}</Typography>
                </Grid>
                <Grid item>
                  <AddButton onClick={onAddResponseField}>
                    <Typography variant="body1">{t("configuration.newResponseField")}</Typography>
                  </AddButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ResponseFieldListFilter />
            </Grid>
            <Grid item className={classes.listContainer}>
              <ResponseFieldList openDialog={onAddResponseField} />
            </Grid>
          </Grid>
        </ProductResponseFieldOverviewProvider>
      </ProductOverviewProvider>
    </PackageOverviewProvider>
  );
};
