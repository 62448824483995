import { createContext, FunctionComponent, useContext, useState } from "react";
import { ICfmOrderX } from "../../domain/order-x/cfm-order-x";

interface IOrderXSelectedProvider {
  order: ICfmOrderX;
}

export const OrderXSelectedContext = createContext<IOrderXSelectedProvider>({} as IOrderXSelectedProvider);

export const useOrderXSelectedContext = () => {
  return useContext(OrderXSelectedContext);
};

const useOrderXSelectedProvider = (order: ICfmOrderX): IOrderXSelectedProvider => {
  const [selectedOrder] = useState<ICfmOrderX>(order);
  return {
    order: selectedOrder,
  };
};

export const OrderXSelectedProvider: FunctionComponent<{ order: ICfmOrderX }> = (props) => {
  const value = useOrderXSelectedProvider(props.order);
  return <OrderXSelectedContext.Provider value={value}>{props.children}</OrderXSelectedContext.Provider>;
};
