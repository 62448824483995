import { PaperClassKey, StyleRules } from "@material-ui/core";

export const MuiPaper: Partial<StyleRules<PaperClassKey, {}>> = {
  rounded: {
    borderRadius: "4px",
  },
  elevation4: {
    boxShadow: "none",
  },
};
