import { FunctionComponent } from "react";
import { NumberInputField } from "../../../../../components/Form/Fields/NumberField";
import { useInputStyles } from "../../../../../style/input.style";
import { formatNumber } from "../../../../../utils/number.helper";
import { usePomAnnouncementContext } from "../../../../pages/announcement/pom-announcement.provider";

interface IPomAnnouncementTableCountInputColProps {
  id: number;
}

export const restrictNumberInputZeroDigits = (event: any) => {
  const value = event.target.value;
  event.target.value = value.split(".")[0];
};

export const PomAnnouncementTableCountInputCol: FunctionComponent<IPomAnnouncementTableCountInputColProps> = (
  props,
) => {
  const classes = useInputStyles();
  const { id } = props;
  const { products, changedProducts, onCountChange, showValidationErrors, validationErrors, validationWarnings } =
    usePomAnnouncementContext();

  const product = products?.find((value) => value.id === id);
  const changedProduct = changedProducts?.find((value) => value.id === id);

  const count = formatNumber(product?.count, 0) ?? "-";
  const changedCount = changedProduct?.count;

  const hasError = showValidationErrors && Array.from(validationErrors).find((e) => e.productId === id)?.countError;
  const hasWarning = Array.from(validationWarnings).some((productId) => productId === id);

  return (
    <>
      {changedProduct && (
        <NumberInputField
          key={`${product?.id}-count`}
          minValue={0}
          disabled={changedProduct.isInvalid === true}
          error={hasError || changedProduct.isInvalid === true}
          className={hasWarning ? classes.warning : undefined}
          onInput={restrictNumberInputZeroDigits}
          onChange={(event) => onCountChange(changedProduct!, event.target.value)}
          value={changedCount}
        />
      )}
      {product && !changedProduct && <div>{count}</div>}
    </>
  );
};
