import { Box, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useCallback } from "react";
import { Colors } from "../../../style/Colors";
import { IPomAnnouncementDateItem } from "./pom-announcement-date-list";
import { PomAnnouncementDatePickerEntry } from "./pom-announcement-date-picker-entry.component";
import { PomAnnouncementDatePickerHeader } from "./pom-announcement-date-picker-header.component";
import { usePomAnnouncementDatePickerContext } from "./pom-announcement-date-picker.provider";
import { formatDateYearMonth } from "../../../shared/util/date.util";

const useStyles = makeStyles({
  datePickerContainer: {
    background: Colors.greenDark,
    borderRadius: "8px",
    padding: "25px 28px 32px 28px",
  },
  loadingContainer: {
    height: "240px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface IPomAnnouncementDatePickerProps {
  onDateSelected: (date: Date) => void;
}

export const PomAnnouncementDatePicker: React.FC<IPomAnnouncementDatePickerProps> = (props) => {
  const classes = useStyles();
  const {
    isLoading,
    handleAnnouncementDateChanged,
    period,
    announcementList,
    datePickerStartDate,
    generatePickerFields,
  } = usePomAnnouncementDatePickerContext();
  const { onDateSelected } = props;

  const handleDateSelected = (date: Date): void => {
    handleAnnouncementDateChanged(date);
    onDateSelected(date);
  };

  const isDateActive = useCallback(
    (entry: IPomAnnouncementDateItem) => {
      return datePickerStartDate ? formatDateYearMonth(entry.date) === formatDateYearMonth(datePickerStartDate) : false;
    },
    [datePickerStartDate]
  );

  return (
    <div className={classes.datePickerContainer}>
      {datePickerStartDate && (
        <PomAnnouncementDatePickerHeader
          startAtDate={datePickerStartDate}
          onDateChanged={(date) => {
            const newDate = new Date(datePickerStartDate);
            newDate.setFullYear(date.getFullYear());
            generatePickerFields(newDate);
          }}
        />
      )}
      {isLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={50} />
        </div>
      )}
      {!isLoading && announcementList.length && (
        <Box marginTop={"20px"} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"} gridGap={"20px"}>
          {announcementList &&
            announcementList.map((entry) => {
              return (
                <PomAnnouncementDatePickerEntry
                  period={period}
                  key={`date-${entry.date}`}
                  date={entry.date}
                  isActive={isDateActive(entry)}
                  status={entry.status}
                  onEntryClicked={handleDateSelected}
                />
              );
            })}
        </Box>
      )}
    </div>
  );
};
