import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    border: "2px solid #c8364c",
    marginTop: 20,
  },
  errorText: {
    color: "#c8364c",
  },
}));

interface IErrorInfoBoxProps {
  errors: string[];
}

export const ErrorInfoBox: FunctionComponent<IErrorInfoBoxProps> = (props) => {
  const { errors } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.errorContainer} spacing={2}>
      {errors.map((error, index) => (
        <Grid item key={`${error}-${index}`} lg={12}>
          <Typography variant="body1" className={classes.errorText}>
            {error}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
