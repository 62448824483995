import { Action, Column, MTableBody, MTableBodyRow, Options } from "@material-table/core";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import React, { CSSProperties, ReactNode, useCallback } from "react";
import CustomTable from "../../../../components/Tables/custom-table";
import { TranslateFunc } from "../../../../utils/translation.utils";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { Colors } from "../../../../style/Colors";
import { IPomAnnouncementTableData } from "../pom-announcement-table-data";
import { PomAnnouncementTableCountInputCol } from "./columns/pom-announcement-table-count-input-column.component";
import { PomAnnouncementTablePriceCol } from "./columns/pom-announcement-table-price-column.component";
import { PomAnnouncementTableWeightInputCol } from "./columns/pom-announcement-table-weight-input-column.component";
import { PomAnnouncementTableHistoryRow } from "./pom-announcement-table-history-row.component";
import { PomAnnouncementTableSumRow } from "./pom-announcement-table-sum-row.component";
import { useDashboardActionContext } from "../../../../shared/pages/dashboard/dashboard-action.provider";

interface IPomAnnouncementTableProps {
  loading: boolean;
  options: Options<any>;
  actions: ((rowData: object) => Action<object>)[];
  translate: TranslateFunc;
  data: IPomAnnouncementTableData[];
  expandedRows: number[];
  changedProductIds: number[];
  emptyDataSourceMessage: string;
}

const useStyles = makeStyles({
  tableRowBase: {
    "& button:not(:last-child)": {
      marginRight: "10px",
    },
  },
  tableRowBorder: {
    "& td": {
      borderBottom: `2px solid ${Colors.white}`,
    },
    "& td:last-child": {
      "& >div": {
        justifyContent: "end",
      },
    },
  },
  tableRowNoBorder: {
    "& td": {
      borderBottom: "none",
    },
  },
});

type RenderColumn = (rowData: any) => ReactNode;

function AnnouncementTable(props: IPomAnnouncementTableProps) {
  const classes = useStyles();
  const { internalUser } = useAuthContext();
  const { contractId } = useDashboardActionContext();

  const renderWeightInput: RenderColumn = useCallback(
    (rowData: IPomAnnouncementTableData) => <PomAnnouncementTableWeightInputCol id={rowData.id} />,
    [],
  );

  const renderCountInput: RenderColumn = useCallback(
    (rowData: IPomAnnouncementTableData) => <PomAnnouncementTableCountInputCol id={rowData.id} />,
    [],
  );

  const renderPriceColumn: RenderColumn = useCallback(
    (rowData: IPomAnnouncementTableData) => <PomAnnouncementTablePriceCol id={rowData.id} />,
    [],
  );

  const currentPomContract = internalUser?.group?.pomContracts?.find((contract) => contract.id === contractId);

  return (
    <CustomTable
      isLoading={props.loading}
      options={props.options}
      actions={props.actions}
      columns={getColumns(
        props.translate,
        renderCountInput,
        renderWeightInput,
        renderPriceColumn,
        currentPomContract
          ? currentPomContract.contractField.defaultPriceGroup
          : internalUser?.group?.pomContracts?.[0]?.contractField?.defaultPriceGroup ?? false,
      )}
      data={props.data}
      localization={{ body: { emptyDataSourceMessage: props.emptyDataSourceMessage } }}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />
            <PomAnnouncementTableSumRow />
          </>
        ),
        Row: (rowProps) => {
          const product = rowProps.data as IPomAnnouncementTableData;
          const isHistoryVisible = props.expandedRows.some((id) => id === product.id);
          const classNames = [classes.tableRowBase];
          if (isHistoryVisible) {
            classNames.push(classes.tableRowNoBorder);
          } else {
            classNames.push(classes.tableRowBorder);
          }

          return (
            <>
              <MTableBodyRow {...rowProps} className={classNames.join(" ")} />
              {isHistoryVisible && <PomAnnouncementTableHistoryRow product={product} />}
            </>
          );
        },
      }}
    />
  );
}

const getColumns = (
  translate: TranslateFunc,
  renderCountInput: RenderColumn,
  renderWeightInput: RenderColumn,
  renderPriceColumn: RenderColumn,
  allowPrices: boolean,
): Column<object>[] => {
  const headerStyleText: CSSProperties = {
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "start",
  };

  const headerStyleNumber: CSSProperties = {
    paddingLeft: 16,
    textAlign: "end",
  };

  const priceCol: Column<object> = {
    title: translate("pom.announcements.table.price"),
    render: renderPriceColumn,
    headerStyle: headerStyleNumber,
    cellStyle: {
      width: "10%",
      textAlign: "end",
    },
  };

  const tableCols: Column<object>[] = [
    {
      title: translate("pom.announcements.product.table.article_number"),
      field: "articleNumber",
      headerStyle: headerStyleText,
      cellStyle: {
        width: "1%",
        textAlign: "start",
      },
    },
    {
      title: translate("pom.announcements.product.table.take_back_system"),
      tooltip: translate("pom.announcements.product.table.take_back_system_tooltip"),
      field: "takeBackSystem",
      headerStyle: headerStyleText,
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      title: translate("pom.announcements.product.table.article"),
      field: "articleName",
      headerStyle: headerStyleText,
      cellStyle: {
        width: "25%",
        textAlign: "start",
      },
    },
    {
      title: translate("pom.announcements.product.table.type"),
      field: "type",
      headerStyle: headerStyleText,
      cellStyle: {
        width: "1%",
        textAlign: "start",
      },
    },
    {
      title: translate("pom.announcements.product.table.weight_class"),
      field: "weightClass",
      headerStyle: headerStyleText,
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      title: translate("pom.announcements.product.table.chemistry"),
      field: "chemistry",
      headerStyle: headerStyleText,
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      title: translate("pom.announcements.table.count"),
      render: renderCountInput,
      field: "count",
      headerStyle: headerStyleNumber,
      cellStyle: {
        textAlign: "end",
      },
    },
    {
      title: translate("pom.announcements.table.weight"),
      render: renderWeightInput,
      field: "totalWeight",
      headerStyle: headerStyleNumber,
      cellStyle: {
        textAlign: "end",
      },
    },
    {
      title: translate("pom.announcements.product.table.unit"),
      tooltip: translate("pom.announcements.product.table.unit_tooltip"),
      field: "unit",
      headerStyle: headerStyleText,
      cellStyle: {
        textAlign: "start",
      },
    },
  ];

  if (allowPrices) {
    tableCols.push(priceCol);
  }

  return tableCols;
};

// Memo is used to render the table only when new columns are added or removed or the loading state changes
// https://reactjs.org/docs/react-api.html#reactmemo
export const AnnouncementTableMemo = React.memo(
  AnnouncementTable,
  (prevProps, nextProps) =>
    nextProps.loading === prevProps.loading &&
    nextProps.data.length === prevProps.data.length &&
    nextProps.expandedRows.length === prevProps.expandedRows.length &&
    _.isEqual(prevProps.changedProductIds, nextProps.changedProductIds),
);
