import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { IGroupExistingDebitorData } from "../../../domain/group/group-existing-debitor-data";
import { SharedQueryKeys } from "../shared-query-keys";

const getGroupExistingDebitors = async (
  name: string,
  vatId: string | null,
  tradeRegisterNumber: string | null,
): Promise<IGroupExistingDebitorData> => {
  const { data } = await getInstance().get<IGroupExistingDebitorData>(RestEndpoints.GroupExistingDebitors, {
    params: {
      name,
      vatId: vatId ?? null,
      tradeRegisterNumber: tradeRegisterNumber ?? null,
    },
  });
  return data;
};

export const useGetGroupExistingDebitors = () => {
  return useMutation(
    SharedQueryKeys.GetExistingGroupDebitors,
    ({
      name,
      vatId,
      tradeRegisterNumber,
    }: {
      name: string;
      vatId: string | null;
      tradeRegisterNumber: string | null;
    }) => getGroupExistingDebitors(name, vatId, tradeRegisterNumber),
    {},
  );
};
