import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { EmailOutlined } from "@material-ui/icons";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { IApiMail } from "../../../../domain/mail/mail";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { useGetGroupMails } from "../../../../repositories/queries/group/get-group-mails.query";
import { formatDateShort } from "../../../../util/date.util";
import { AppTable, ITableRow } from "../../../../components/tables/app-table.component";
import { MailDetailDialog } from "../dialogues/mail-detail-dialog.component";
import { GroupDetailHeader } from "../group-detail-header.component";

const useStyles = makeStyles((theme: Theme) => ({
  textAlign: {
    textAlign: "center",
  },
}));

interface IGroupDetailsMailsTableProps {
  groupId: number;
}

export const GroupDetailsMailsTable: VFC<IGroupDetailsMailsTableProps> = (props) => {
  const { groupId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { isMobile, isTablet } = useScreenSize();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedMail, setSelectedMail] = useState<IApiMail | undefined>();
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });

  const { isLoading, data: mailResult, refetch } = useGetGroupMails(groupId, searchText, paginationInputMeta);

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const onSearchSubmit = (value: string | undefined) => {
    setSearchText(value);
  };

  const searchFieldProps = {
    tooltip: t("mail.search"),
    placeholder: t("mail.searchPlaceholder"),
    onSearchSubmit,
  };

  const getMailLabelComponent = (mail: IApiMail) => {
    return (
      <Typography
        variant="body1"
        className={classNames({
          [classes.textAlign]: isMobile || isTablet,
        })}
      >
        <b>{mail.subject}</b>
        {TEXT_SEPARATOR} {mail.to}
        {TEXT_SEPARATOR} {formatDateShort(mail.createdAt)}
      </Typography>
    );
  };

  const getMappedEntries = (mails: IApiMail[] | undefined): ITableRow[] => {
    if (!mails) return [];

    const groupedMails = _(mails)
      .groupBy((mail) => formatDateShort(mail.createdAt))
      .map((mail) => mail)
      .value();

    return groupedMails.map((mail) => {
      return {
        id: mail[0].id,
        labelComponent: getMailLabelComponent(mail[0]),
        iconComponent: <EmailOutlined fontSize="large" />,
        isActive: undefined,
      };
    });
  };

  return (
    <>
      <MailDetailDialog mail={selectedMail} onClose={() => setSelectedMail(undefined)} />
      <Grid container direction="column">
        <Grid item>
          <GroupDetailHeader>
            <Typography variant="h1">{t("mail.general")}</Typography>
          </GroupDetailHeader>
        </Grid>
        <Grid item>
          <AppTable
            entries={getMappedEntries(mailResult?.mails)}
            searchFieldProps={searchFieldProps}
            paginationInputMeta={paginationInputMeta}
            setPaginationInputMeta={setPaginationInputMeta}
            numberOfRecords={mailResult?.meta?.numberOfRecords ?? 0}
            onRowClicked={(tableRow) => {
              const mail = (mailResult?.mails ?? []).find((mail) => mail.id === tableRow.id);
              setSelectedMail(mail);
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
