import { InputLabelClassKey, StyleRules } from "@material-ui/core";
import { Colors, Shades } from "style/Colors";
import { FONT_FAMILY, FONT_LABEL } from "./constant";

export const MuiInputLabel: Partial<StyleRules<InputLabelClassKey, {}>> = {
  root: {
    zIndex: 999,
    marginLeft: 10,
    marginTop: "5px",
    marginBottom: "5px",
    fontFamily: FONT_FAMILY,
    fontSize: FONT_LABEL,
    color: Shades.black,
    "&$disabled": {
      color: Colors.disabled,
    },
  },
  shrink: {
    marginLeft: 0,
    marginTop: 0,
    transform: "translate(0, -5px)",
  },
};
