import { Action } from "@material-table/core";
import { Grid, TablePagination, Typography } from "@material-ui/core";
import {
  AddButton,
  ApproveIconButton,
  ArrowRightIconButton,
  CloseIconButton,
  EditIconButton,
} from "components/Primitives";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { GroupErpNumber } from "shared/components/group/groups-erp-number";
import { ClearFilterButton } from "../../../../components/Buttons/clear-filter-button";
import SearchField from "../../../../components/SearchField";
import CustomTable from "../../../../components/Tables/custom-table";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { useBaseDataGridStyles } from "../../../../style/baseDataGrid.style";
import { getGroupTypeTranslation } from "../../../../utils/group.helper";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroupOrManufacturer } from "../../../domain/group/group-or-manufacturer";
import { GroupType } from "../../../domain/group/group-type";
import { isPomMainContact } from "../../../domain/user/user";
import { GroupContractType } from "../../../models/group/group-contract.model";
import { GroupTypeFilter, GroupTypeFilterValues } from "../group-filter/group-type-filter.component";
import { GroupAndManufacturerActiveFilter } from "./group-and-manufacturer-active-filter.component";
import { useGroupAndManufacturerTableContext } from "./group-and-manufacturer-table.provider";

export const GroupAndManufacturerTable: VFC = () => {
  const { t } = useTranslation();
  const classes = useBaseDataGridStyles();
  const { internalUser } = useAuthContext();
  const {
    toggleGroupStatus,
    groups,
    isLoading,
    tablePagination,
    setTablePagination,
    setFilter,
    filter,
    entryCount,
    onShowGroupDetail,
    onAddGroup,
    isFilterDirty,
    resetFilter,
    onShowManufacturerDetail,
    onEditManufacturer,
  } = useGroupAndManufacturerTableContext();

  const getTooltip = (group: IGroupOrManufacturer): string => {
    return group.active ? t("basedata.users.table.action.deactivate") : t("");
  };

  const actions: ((rowData: object) => Action<object>)[] = [
    (rowData: object) => {
      const group = rowData as IGroupOrManufacturer;

      return {
        icon: () => (group.active ? <CloseIconButton /> : <ApproveIconButton />),
        tooltip: getTooltip(group),
        onClick: async () => {
          await toggleGroupStatus(group);
        },
        hidden: group.id == null,
      };
    },
    (rowData: object) => {
      const group = rowData as IGroupOrManufacturer;

      return {
        icon: () => <EditIconButton />,
        tooltip: t("basedata.users.table.action.edit"),
        onClick: () => {
          if (group.manufacturerId) {
            onEditManufacturer(group.manufacturerId);
            return;
          }
        },
        hidden: !group.manufacturerId,
      };
    },
    (rowData: object) => {
      const group = rowData as IGroupOrManufacturer;

      return {
        icon: () => <ArrowRightIconButton />,
        tooltip: t("basedata.users.table.action.detail"),
        onClick: () => {
          if (group.manufacturerId) {
            onShowManufacturerDetail(group.manufacturerId);
            return;
          }
          if (group.id) {
            onShowGroupDetail(group.id);
            return;
          }
        },
      };
    },
  ];

  const isManufacturer = (entry: IGroupOrManufacturer) => {
    return entry.manufacturerId !== undefined;
  };

  const isDeleted = (entry: IGroupOrManufacturer) => {
    const hasId = isManufacturer(entry) ? entry.manufacturerId !== undefined : entry.id !== undefined;
    return hasId && entry.active === null;
  };

  const getGroupTypeFilterValue = (): GroupTypeFilterValues | undefined => {
    return GroupConverter.groupTypesToGroupTypeFilterValues(filter.groupTypes?.[0], filter.contractTypes);
  };

  const setGroupTypeFilter = (value: GroupTypeFilterValues | undefined) => {
    const groupTypes: GroupType | undefined = GroupConverter.groupTypeFilterValuesToGroupTypes(value) ?? undefined;
    const contractTypes: GroupContractType[] = GroupConverter.groupTypeFilterValuesToContractTypes(value) ?? [];
    setFilter({
      ...filter,
      groupTypes: groupTypes ? [groupTypes] : [],
      contractTypes,
    });
  };

  return (
    <CustomTable
      options={{
        pageSize: entryCount,
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        rowStyle: (data: IGroupOrManufacturer) => {
          return {
            color: isDeleted(data) ? "red" : undefined,
          };
        },
      }}
      columns={[
        {
          title: t("basedata.groups.table.groupId"),
          field: "id",
        },
        {
          title: t("basedata.groups.table.name"),
          field: "name",
        },
        {
          title: t("basedata.group.agency_or_manufacturer_title"),
          field: "representative",
        },
        {
          title: t("basedata.groups.edit.erpNumber"),
          field: "erpNumber",
          render: (rowData) => <GroupErpNumber erpNumber={(rowData as IGroupOrManufacturer).erpNumber} />,
        },
        {
          title: t("basedata.groups.edit.address"),
          field: "displayAddress",
        },
        {
          title: t("basedata.groups.table.type"),
          render: (rowData) => {
            const groupType = (rowData as IGroupOrManufacturer).groupType;
            if (groupType !== null) {
              return getGroupTypeTranslation(groupType, t);
            }
          },
          field: "groupType",
        },
      ]}
      actions={actions}
      data={groups}
      isLoading={isLoading}
      components={{
        Toolbar: (props) => (
          <Grid direction="row" container className={classes.search}>
            <Grid className={classes.searchGrid} container spacing={4}>
              <Grid item>
                <GroupTypeFilter value={getGroupTypeFilterValue()} onChange={setGroupTypeFilter} />
              </Grid>
              <Grid item>
                <GroupAndManufacturerActiveFilter />
              </Grid>
              {isFilterDirty && (
                <Grid item>
                  <ClearFilterButton onClick={resetFilter} />
                </Grid>
              )}
              <Grid className={classes.searchGrid} item>
                <SearchField
                  tooltip={t("basedata.groups.table.search.pom")}
                  submitSearch={true}
                  inputTimeout={0}
                  initialValue={filter?.searchQuery}
                  onSearchSubmit={(value: string) => {
                    setTablePagination({ ...tablePagination, page: 0 });
                    setFilter({
                      ...filter,
                      searchQuery: value,
                    });
                  }}
                  placeholder={t("basedata.groups.table.search.pom")}
                />
              </Grid>
            </Grid>
            {!isPomMainContact(internalUser) && (
              <Grid className={classes.addGrid} item>
                <AddButton onClick={onAddGroup}>
                  <Typography variant="body1">{t("response_field_config.response.table.add_group")}</Typography>
                </AddButton>
              </Grid>
            )}
          </Grid>
        ),
        Pagination: (props) => (
          <TablePagination
            {...props}
            rowsPerPageOptions={[50, 100]}
            rowsPerPage={tablePagination.pageSize}
            count={tablePagination.numberOfRecords}
            page={tablePagination.page}
            onPageChange={(event: any, newPage: number) => {
              setTablePagination({
                ...tablePagination,
                page: newPage,
              });
              props.onPageChange(event, newPage);
            }}
            onRowsPerPageChange={(event: any) => {
              setTablePagination({
                ...tablePagination,
                pageSize: event.target.value,
                page: 0,
              });
              props.onRowsPerPageChange(event);
            }}
          />
        ),
      }}
    />
  );
};
