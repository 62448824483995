import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import sidebar_logo from "../../assets/images/logos/sidebar_logo.png";
import { GRSLogo, GRSLogoDev } from "../../components/Primitives";
import config from "../../config/config";
import { Environment } from "../../config/environment.util";
import { useAuthContext } from "../../providers/Auth/auth.provider";

const useStyles = makeStyles({
  logo: {
    cursor: "pointer",
  },
});

export const GRSHeaderLogo: React.FC = () => {
  const classes = useStyles();
  const fullScreen = useMediaQuery("(min-width:950px)");
  const { userInitialRoute } = useAuthContext();
  const history = useHistory();

  const navigateHome = useCallback((): void => {
    history.replace(userInitialRoute);
  }, [userInitialRoute, history]);

  const mobileLogo = (
    <img src={sidebar_logo} height={40} width={40} onClick={navigateHome} className={classes.logo} alt="logo" />
  );

  if (
    config.environment === Environment.DevelopmentLocal ||
    config.environment === Environment.Development ||
    config.environment === Environment.Staging
  ) {
    return fullScreen ? (
      <GRSLogoDev className={classes.logo} onClick={navigateHome} height={73} width={220} />
    ) : (
      mobileLogo
    );
  }

  return fullScreen ? <GRSLogo className={classes.logo} onClick={navigateHome} height={73} width={220} /> : mobileLogo;
};
