import { Grid } from "@material-ui/core";
import React from "react";
import { CfmOrderXApiStatus, CfmOrderXUiStatus } from "../../../domain/order-x/cfm-order-x.status";
import { OrderTruckloadAnnouncedProvider } from "../order-truckload-announced.provider";
import { OrderTruckloadProvider } from "../order-truckload.provider";
import { useOrderXOverviewContext } from "../order-x-overview.provider";
import { DestinationArrivedRecyclerWarehouseView } from "./order-list-view/destination-arrived-recycler-warehouse/destination-arrived-recycler-warehouse-view.component";
import { DestinationReceivedRecyclerWarehouseView } from "./order-list-view/destination-received-recycler-warehouse/destination-received-recycler-warehouse-view.component";
import { OrderListTruckloadAnnouncedView } from "./order-list-view/logistic-truckload-announced/order-list-truckload-announced-view.component";
import { OrderListLogisticWarehouseView } from "./order-list-view/logistic-warehouse/order-list-logistic-warehouse-view.component";
import { OrderListDefaultView } from "./order-list-view/order-list-default-view.component";
import { SorterWarehouseView } from "./order-list-view/sorter-warehouse/sorter-warehouse-view.component";

export const OrderXList: React.VFC = () => {
  const { curFilter } = useOrderXOverviewContext();

  const getListView = () => {
    switch (curFilter.statusFilter) {
      case CfmOrderXApiStatus.LogisticWarehouse:
        return (
          <OrderTruckloadProvider>
            <OrderListLogisticWarehouseView />
          </OrderTruckloadProvider>
        );
      case CfmOrderXApiStatus.LogisticTruckLoadAnnounced:
        return (
          <OrderTruckloadAnnouncedProvider>
            <OrderListTruckloadAnnouncedView />
          </OrderTruckloadAnnouncedProvider>
        );
      case CfmOrderXUiStatus.SorterWarehouse:
        return <SorterWarehouseView />;
      case CfmOrderXApiStatus.DestinationArrived:
        return <DestinationArrivedRecyclerWarehouseView />;
      case CfmOrderXApiStatus.DestinationReceived:
        return <DestinationReceivedRecyclerWarehouseView />;
      default:
        return <OrderListDefaultView />;
    }
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        {getListView()}
      </Grid>
    </Grid>
  );
};
