import { Grid, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AddButton, CancelButton, ForwardButton } from "../../../../components/Primitives/Buttons";
import { CfmProductArticleType } from "../../../../configurator/components/product/product.utils";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { Loading } from "../../../../shared/components/loading/loading.component";
import { isAdmin, isCfmCustomer } from "../../../../shared/domain/user/user";
import { useCustomForm } from "../../../../shared/util/form.util";
import { useButtonStyles } from "../../../../style/buttons.style";
import { Colors } from "../../../../style/Colors";
import { AvailableCfmRoutes } from "../../../../utils/constants";
import { CfmOrderActionType } from "../../../domain/order-x/actions/cfm-order-action-type";
import { ICfmCreatePackage } from "../../../domain/packages/cfm-package";
import { CfmOrderXActionConverter } from "../../../repositories/models/converter/order-x/cfm-order-x-action.converter";
import { CfmOrderXConverter } from "../../../repositories/models/converter/order-x/cfm-order-x.converter";
import { useCreateOrderXQuery } from "../../../repositories/queries/order-x/mutations/create-order-x.query";
import { useUpdateOrderXQuery } from "../../../repositories/queries/order-x/mutations/update-order-x.query";
import { useGetProductQuery } from "../../../repositories/queries/product/query/get-product.query";
import { useOrderNewContext, WizardStep } from "../order-new.provider";
import { OrderNewPackageDialog } from "./dialog/order-new-package-dialog.component";
import { OrderNewAddressList } from "./form/order-new-address-list.component";
import { OrderNewGroupList } from "./form/order-new-group-list.component";
import { OrderNewPackageList } from "./form/order-new-package-list.component";
import { OrderNewProductList } from "./form/order-new-product-list.component";
import { OrderWizardSummary } from "./summary/order-wizard-summary.component";

const useStyles = makeStyles((theme: Theme) => ({
  grayText: {
    color: Colors.gray,
  },
  mobileButton: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export enum WizardMode {
  Create,
  Edit,
  Repeat,
}

export interface IOrderNewWizardStep {
  component: JSX.Element;
  wizardStep: WizardStep;
}

export interface IOrderNewWizardFormInputs {
  orderId: number | undefined;
  customerGroupId: number;
  customerAddressId: number;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  openingHours: string;
  productId: number | undefined;
  singleOrderQuantity: number | undefined;
  productOrders: IOrderNewWizardFormProductInput[];
  orderNow: boolean | undefined;
}

export interface IOrderNewWizardFormProductInput {
  productGrossWeight: number | undefined;
  productNetWeight: number | undefined;
  package: ICfmCreatePackage | undefined;
  requestedPickupDate: Date | undefined;
  orderNumber: string | undefined;
  orderComment: string | undefined;
}

interface IOrderNewWizardProps {
  initialValues?: Partial<IOrderNewWizardFormInputs>;
  mode: WizardMode;
}

export const OrderNewWizard: FunctionComponent<IOrderNewWizardProps> = (props) => {
  const { initialValues, mode } = props;
  const { t } = useTranslation();
  const { activeStep, setActiveStep, editInfo } = useOrderNewContext();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useCustomForm<IOrderNewWizardFormInputs>({ defaultValues: initialValues, mode: "all" });
  const { handleSubmit, setValue, watch } = formMethods;
  const { internalUser } = useAuthContext();
  const classes = useStyles();
  const [doDisplayAddPackage, setDoDisplayAddPackage] = useState(false);
  const [doDisplaySubmitDialog, setDoDisplaySubmitDialog] = useState(false);
  const productId = watch("productId");
  const productOrders = watch("productOrders");
  const orderNow = watch("orderNow");
  const { data: product } = useGetProductQuery(productId);
  const doDisplayAddButtons =
    product?.articleType === CfmProductArticleType.Product &&
    !doDisplayAddPackage &&
    activeStep === WizardStep.SubmitPage;
  const buttonClasses = useButtonStyles();
  const history = useHistory();
  const { setHasUnsavedData } = useUnsavedDataContext();
  const showAddPackageInEdit = mode === WizardMode.Edit ? productOrders.length === 0 : true;

  const onMutationSuccess = () => {
    setHasUnsavedData(false);
    enqueueSnackbar(t("notifications.order_saved_success.message"), {
      variant: "success",
    });

    if (orderNow) {
      history.push(AvailableCfmRoutes.OrderXOverview);
    }
    isCfmCustomer(internalUser) && !orderNow
      ? history.push(AvailableCfmRoutes.CartX)
      : history.push(AvailableCfmRoutes.OrderXOverview);
  };

  const { mutateAsync: createOrderX, isLoading: isCreateOrderXLoading } = useCreateOrderXQuery(onMutationSuccess);
  const { mutateAsync: updateOrderX, isLoading: isUpdateOrderXLoading } = useUpdateOrderXQuery();
  const isMutationLoading = isCreateOrderXLoading || isUpdateOrderXLoading;

  useEffect(() => {
    // always hide adding addon or adding package when step changed from some other step to submit page
    // so user always has a clean submit page
    if (activeStep !== WizardStep.SubmitPage) return;
    setDoDisplayAddPackage(false);
  }, [activeStep]);

  useEffect(() => {
    // only packages can be edited, addons can only be deleted
    if (editInfo.editClicked) setDoDisplayAddPackage(true);
  }, [editInfo.editClicked]);

  const handlePackageAccept = useCallback(() => {
    setActiveStep(WizardStep.SubmitPage);
  }, [setActiveStep]);

  const steps: IOrderNewWizardStep[] = useMemo(() => {
    return [
      { component: <OrderNewGroupList />, wizardStep: WizardStep.CustomerGroup },
      { component: <OrderNewAddressList />, wizardStep: WizardStep.CustomerAddress },
      { component: <OrderNewProductList />, wizardStep: WizardStep.Product },
      {
        component: <OrderNewPackageList handleAccept={handlePackageAccept} productOrderIndex={0} />,
        wizardStep: WizardStep.Package,
      },
      { component: <></>, wizardStep: WizardStep.SubmitPage },
    ];
  }, [handlePackageAccept]);

  const showError = useCallback(
    (message: string) => {
      enqueueSnackbar(t("general.error_occurred", { errorCode: 404, errorMsg: message }), {
        variant: "error",
      });
    },
    [t, enqueueSnackbar],
  );

  const getCurrentStepComponent = useCallback(() => {
    const step = steps.find((step) => step.wizardStep === activeStep);
    if (!step) {
      showError("Wizard Step not found");
      return <></>;
    }

    return <Grid item>{step.component}</Grid>;
  }, [showError, activeStep, steps]);

  const doDisplaySummary = () => {
    const minStep = isAdmin(internalUser) ? WizardStep.CustomerGroup : WizardStep.CustomerAddress;
    return activeStep > minStep;
  };

  const onSubmit = async (inputs: IOrderNewWizardFormInputs): Promise<void> => {
    await handleOrderXSubmit(inputs);
  };

  const handleOrderXSubmit = async (inputs: IOrderNewWizardFormInputs) => {
    setHasUnsavedData(false);
    if (!inputs.productId) {
      showError("productId not set!");
      return;
    }

    if (mode === WizardMode.Create || mode === WizardMode.Repeat) {
      const createDataOrdersX = CfmOrderXConverter.toCreateOrderModel(inputs, inputs.productId);
      await createOrderX({
        items: createDataOrdersX,
        orderNow: inputs.orderNow,
      });
    } else if (inputs.orderId) {
      if (inputs.productOrders && inputs.productOrders.length > 1) {
        showError("multiple product ids found!");
        return;
      }

      const productOrder = inputs.productOrders?.[0];
      if (!productOrder) {
        showError("product order not set!");
        return;
      }

      if (!productOrder.requestedPickupDate) {
        showError("requested pickup date missing!");
        return;
      }

      const updateDataOrderX = CfmOrderXActionConverter.toEditInCartUpdateRequest(
        CfmOrderActionType.EditInShoppingCart,
        inputs,
        inputs.productId,
        productOrder,
        productOrder.requestedPickupDate,
      );

      await updateOrderX({ orderId: inputs.orderId, action: updateDataOrderX });
      history.push(AvailableCfmRoutes.CartX);
    }
  };

  const handleOrderSubmit = (orderNow: boolean) => {
    setValue("orderNow", orderNow);
    if (product?.articleType === CfmProductArticleType.SingleOrderProduct) {
      setDoDisplaySubmitDialog(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Loading isLoading={isMutationLoading}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography className={classes.grayText} variant="h1">
              {mode === WizardMode.Create
                ? t("orders.new.wizard.headerCreate")
                : mode === WizardMode.Repeat
                ? t("orders.new.wizard.headerRepeat")
                : t("orders.new.wizard.headerEdit")}
            </Typography>
          </Grid>
          {doDisplaySummary() && (
            <Grid item>
              <OrderWizardSummary />
            </Grid>
          )}
          {getCurrentStepComponent()}
          {doDisplayAddPackage && activeStep === WizardStep.SubmitPage && (
            <Grid item>
              <OrderNewPackageList
                handleAccept={() => setDoDisplayAddPackage(false)}
                handleCancel={() => setDoDisplayAddPackage(false)}
              />
            </Grid>
          )}

          {doDisplaySubmitDialog && (
            <OrderNewPackageDialog
              open={doDisplaySubmitDialog}
              productOrderIndex={0}
              productPackage={product?.packages?.[0]}
              onCancel={() => {
                setDoDisplaySubmitDialog(false);
              }}
              onAccept={() => {
                setDoDisplaySubmitDialog(false);
                handleSubmit(onSubmit)();
              }}
            />
          )}

          <Grid item>
            <Grid container direction="row-reverse" spacing={2} justifyContent="space-between">
              {!doDisplayAddPackage && mode !== WizardMode.Edit && (
                <Grid item xs={12} md="auto">
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md="auto">
                      <ForwardButton
                        disabled={activeStep !== WizardStep.SubmitPage || isMutationLoading}
                        onClick={() => handleOrderSubmit(true)}
                        className={classNames(classes.mobileButton)}
                      >
                        <Typography variant="body1">{t("orders.new.wizard.orderNow")}</Typography>
                      </ForwardButton>
                    </Grid>
                    {isCfmCustomer(internalUser) && (
                      <Grid item xs={12} md="auto">
                        <Tooltip title={t("orders.new.wizard.submit.saveCreateDeactivated")}>
                          <span>
                            <ForwardButton
                              //disabled={activeStep !== WizardStep.SubmitPage || isMutationLoading}
                              disabled={true}
                              title={t("orders.new.wizard.submit.saveCreateDeactivated")}
                              onClick={() => handleOrderSubmit(false)}
                              className={classes.mobileButton}
                            >
                              <Typography variant="body1">{t("orders.new.wizard.submit.saveCreate")}</Typography>
                            </ForwardButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {isCfmCustomer(internalUser) && mode === WizardMode.Edit && (
                <ForwardButton
                  disabled={activeStep !== WizardStep.SubmitPage || isMutationLoading}
                  onClick={() => handleOrderSubmit(false)}
                  className={classes.mobileButton}
                >
                  <Typography variant="body1">{t("orders.new.wizard.submit.saveEdit")}</Typography>
                </ForwardButton>
              )}
              {doDisplayAddButtons && showAddPackageInEdit && (
                <Grid item xs={12} md="auto">
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md="auto">
                      <AddButton
                        className={classNames(buttonClasses.secondaryButton, classes.mobileButton)}
                        iconSvgColor={"green"}
                        onClick={() => {
                          setDoDisplayAddPackage(true);
                        }}
                      >
                        <Typography variant="body1">{t("orders.new.wizard.addPackage")}</Typography>
                      </AddButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {doDisplayAddPackage && activeStep === WizardStep.SubmitPage && (
                <Grid item xs={12}>
                  <CancelButton
                    className={classNames(buttonClasses.secondaryDangerButton, classes.mobileButton)}
                    iconSvgColor={"red"}
                    onClick={() => {
                      setDoDisplayAddPackage(false);
                    }}
                  >
                    <Typography variant="body1">
                      {doDisplayAddPackage && t("orders.new.wizard.cancelAddPackage")}
                    </Typography>
                  </CancelButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Loading>
    </FormProvider>
  );
};
