import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { ICreateGroupResult } from "../../../domain/group/group";
import { IGroupCreateRequest } from "../../../domain/requests/group-create-or-edit.request";
import { ICreateGroupResultModel } from "../../../models/group/group.model";
import { SharedQueryKeys } from "./../shared-query-keys";

const createGroup = async (createData: IGroupCreateRequest): Promise<ICreateGroupResult> => {
  const { data } = await getInstance().post<ICreateGroupResultModel>(RestEndpoints.CreateGroup, createData);
  return { group: GroupConverter.toDomain(data.group), contactPersonEmailExists: data.contactPersonEmailExists };
};

export const useCreateGroupQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    SharedQueryKeys.CreateGroup,
    ({ createData }: { createData: IGroupCreateRequest }) => createGroup(createData),
    {
      onSuccess: () => {
        enqueueSnackbar(t("general.group.create_success"), { variant: "success" });
      },
    },
  );
};
