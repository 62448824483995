import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderAvailableAction } from "../../../../domain/order-x/actions/cfm-order-available-action";
import { CfmOrderXResponseValueConverter } from "../../../models/converter/order-x/cfm-order-x-response-value.converter";
import { ICfmOrderAvailableActionsModel } from "../../../models/order-x/action/cfm-order-x-available-action.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

export const getOrderXActionsQuery = async (orderId: number): Promise<ICfmOrderAvailableAction[]> => {
  const { data } = await getInstance().get<ICfmOrderAvailableActionsModel>(
    RestEndpoints.CfmOrderXActions.replace(":id", orderId.toString()),
  );
  return CfmOrderXResponseValueConverter.availableToDomain(data);
};

export const useGetOrderXActionsQuery = (orderId: number, enabled: boolean) => {
  return useQuery([CfmQueryKeys.GetOrderXActions, orderId], () => getOrderXActionsQuery(orderId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
