import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import getBreadcrumb from "shared/domain/navigation/breadcrumbs";
import getCfmNavItems from "collect-from-market/domain/navigation/cfm-navigation-items";
import { PAGE_NOT_FOUND } from "../../utils/constants";
import { ILooseObject } from "../../utils/rest";
import { useAuthContext } from "../../providers/Auth/auth.provider";

export const PageTitle: React.FunctionComponent<ILooseObject> = ({
  displayAddButton = false,
  displayCardCount = true,
  id = "",
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getUser } = useAuthContext();
  const [pageDetails, setPageDetails] = useState<any>();
  const [loading, setIsLoading] = useState<boolean>(true);

  // OnMount
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const loaded = await getUser();
      const navigationItems = getCfmNavItems(loaded);
      const breadCrumb = getBreadcrumb(id !== "" ? id : location.pathname, navigationItems);
      const pageDetails = breadCrumb ? breadCrumb[breadCrumb.length - 1] : { label: "Page not Found", description: "" };
      setPageDetails(pageDetails);
      setIsLoading(false);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Helmet>
      <title>
        {t("app_name")}
        {!loading && pageDetails.label && pageDetails.label !== PAGE_NOT_FOUND ? ` - ${t(pageDetails.label)}` : ""}
      </title>
    </Helmet>
  );
};
