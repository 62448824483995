import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useGetProductRoutingAssignmentQuery } from "../../../collect-from-market/repositories/queries/routing-assignment/get-routing-assignment.query";
import { RoutingConfigSmallIcon } from "../../../components/Primitives";
import { LoadingSpinner } from "../../../shared/components/loading-spinner.component";
import { Shades } from "../../../style/Colors";
import {
  CreateProductRoutingContent,
  ICreateProductRoutingFormInputs,
} from "./create-product-routing-content.component";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 25,
    height: 25,
    color: Shades.gray50,
  },
  heading: {
    color: Shades.gray50,
  },
  headingContainer: {
    marginTop: 25,
  },
}));

interface IEditProductRoutingContentProps {
  productId: number;
  assignmentId: number;
}

export const EditProductRoutingContent: VFC<IEditProductRoutingContentProps> = (props) => {
  const { productId, assignmentId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { isLoading, data: assignment } = useGetProductRoutingAssignmentQuery(assignmentId);

  const initialValues: Partial<ICreateProductRoutingFormInputs> | undefined = useMemo(() => {
    if (!assignment || isLoading) return undefined;

    const primaryStartAddressId = assignment.primaryStartAddress?.id;
    const primaryTargetAddressId = assignment.primaryTargetAddress?.id;
    const secondaryStartAddressId = assignment.secondaryStartAddress?.id;
    const secondaryTargetAddressId = assignment.secondaryTargetAddress?.id;
    const optionalPrimaryAddressIds = assignment.optionalPrimaryAddresses?.map((a) => a.id);
    const optionalSecondaryAddressIds = assignment.optionalSecondaryAddresses?.map((a) => a.id);

    const hasInvalidValues = primaryStartAddressId === undefined || primaryTargetAddressId === undefined;

    if (hasInvalidValues) return undefined;

    return {
      postals: [assignment.postal],
      primaryStartAddressId,
      primaryTargetAddressId,
      secondaryStartAddressId,
      secondaryTargetAddressId,
      optionalPrimaryAddressIds,
      optionalSecondaryAddressIds,
    };
  }, [isLoading, assignment]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item className={classes.headingContainer}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <RoutingConfigSmallIcon viewBox="0 0 25 25" className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h4" className={classes.heading}>
              {t("configuration.editRouting")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && (
        <Grid item>
          <LoadingSpinner isLoading={isLoading} />
        </Grid>
      )}
      {!isLoading && initialValues && (
        <Grid item>
          <CreateProductRoutingContent productIds={[productId]} initialValues={initialValues} />
        </Grid>
      )}
    </Grid>
  );
};
