import { useEffect, useState, VFC } from "react";
import { useQueryClient } from "react-query";
import { usePlatformContext } from "../../../../../providers/Auth/platform.provider";
import { AddressFormModal } from "../../../../components/address/address-form-modal/address-form-modal.component";
import { AddressFormMode } from "../../../../components/address/address-form/address-form.component";
import { EditGroupAddressDialog } from "../../../../components/group/edit-group/dialog/edit-group-address-dialog.component";
import { ICreateAddressResult } from "../../../../domain/address/address";
import { IAddressLean } from "../../../../domain/address/address-lean";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { AddressType, recyclerAddressTypes } from "../../../../models/address/address.model";
import { useGetGroupAddresses } from "../../../../repositories/queries/address/get-group-addresses.query";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useGroupDetailContext } from "../../group-detail.provider";
import { AddressDetailDialog } from "../dialogues/address-detail-dialog.component";
import { GroupDetailsAddressesBaseTable } from "./group-detail-address-base-table.component";
import { getAddressTypesFromGroupType } from "../../../../../utils/group.helper";

interface IGroupDetailsAddressesTableProps {
  onAddressCreated: (result: ICreateAddressResult | undefined) => void;
}

export const GroupDetailsAddressesTable: VFC<IGroupDetailsAddressesTableProps> = (props) => {
  const { onAddressCreated } = props;
  const queryClient = useQueryClient();
  const { activePlatform } = usePlatformContext();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailAddress, setDetailAddress] = useState<IAddressLean | undefined>();
  const [openNewAddress, setOpenNewAddress] = useState(false);
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });
  const { group } = useGroupDetailContext();
  const {
    isLoading,
    data: addressesResult,
    refetch,
  } = useGetGroupAddresses(
    group.id,
    searchText,
    [
      AddressType.CustomerLocation,
      AddressType.LogisticLocation,
      AddressType.ManufacturerLocation,
      ...recyclerAddressTypes,
    ],
    true,
    paginationInputMeta,
  );

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const handleSave = (result: ICreateAddressResult | undefined) => {
    refetch();
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    setOpenNewAddress(false);
    onAddressCreated(result);
  };

  const handleRowClicked = (address: IAddressLean | undefined) => {
    setDetailAddress(address);
    setIsDetailOpen(true);
  };

  const handleOnAddNewClicked = () => {
    setOpenNewAddress(true);
  };

  return (
    <>
      <AddressFormModal
        open={openNewAddress}
        platform={activePlatform}
        shouldPersistAddress={true}
        addressTypes={getAddressTypesFromGroupType(group.type)}
        mode={AddressFormMode.Create}
        group={group}
        onCancel={() => setOpenNewAddress(false)}
        onChangedAddress={handleSave}
        canDeactivate={false}
      />

      <AddressDetailDialog
        open={isDetailOpen}
        address={detailAddress}
        onClose={() => {
          setDetailAddress(undefined);
          setIsDetailOpen(false);
        }}
        onEditClick={() => {
          setIsEditOpen(true);
          setIsDetailOpen(false);
        }}
      />

      <EditGroupAddressDialog
        open={isEditOpen}
        groupId={group.id}
        address={detailAddress}
        onCancel={() => {
          setIsDetailOpen(false);
          setIsEditOpen(false);
        }}
        onSaved={() => {
          setIsEditOpen(false);
          setIsDetailOpen(false);
          setDetailAddress(undefined);
        }}
      />

      <GroupDetailsAddressesBaseTable
        addressesResult={addressesResult}
        onRowClicked={handleRowClicked}
        isLoading={isLoading}
        setSearchText={setSearchText}
        paginationInputMeta={paginationInputMeta}
        setPaginationInputMeta={setPaginationInputMeta}
        onAddClick={handleOnAddNewClicked}
      />
    </>
  );
};
