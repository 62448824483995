import { IAddress } from "../../shared/domain/address/address";
import { IAddressLean } from "../../shared/domain/address/address-lean";
import { translateCountryByCode } from "../../utils/address.util";
import { TEXT_SEPARATOR } from "../../utils/constants";
import { TranslateFunc } from "../../utils/translation.utils";
import { ICfmProduct } from "../domain/products/cfm-product";

export const getProductLabelString = (product: ICfmProduct): string => {
  return `${product.articleNumber} | ${product.name}`;
};

export const getFormattedCustomerAddress = (
  address: IAddress | IAddressLean | undefined | null,
  t: TranslateFunc,
): string => {
  if (!address) {
    return "";
  }
  return `(${address?.id ?? ""}) ${address?.name ?? ""}${TEXT_SEPARATOR} ${address?.postal ?? ""} ${
    address?.city ?? ""
  }${address.country ? `${TEXT_SEPARATOR} ` + translateCountryByCode(address.country, t) : ""}`;
};
