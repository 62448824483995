import { CfmServiceDateModel } from "../../order-x/action/cfm-service-date.model";

export enum CfmTruckloadActionTypeModel {
  TruckloadCanceled = "TruckloadCanceled",
  TruckloadSubmitted = "TruckloadSubmitted",
  TruckloadConfirmed = "TruckloadConfirmed",
  TruckloadUpdate = "TruckloadUpdate",
}

export interface ICfmTruckloadActionModel {
  type: CfmTruckloadActionTypeModel;
  serviceDates?: {
    [key in CfmServiceDateModel]?: Date;
  };
}
