export enum SharedQueryKeys {
  // Clone System
  CloneSystem = "CloneSystem",
  GetLatestCloningStatus = "GetLatestCloningStatus",

  // Group
  GetGroupById = "GetGroupById",
  GetGroupHistory = "GetGroupHistory",
  GetGroupForDetails = "GetGroupForDetails",
  GetGroupContracts = "GetGroupContracts",
  GetGroupBranches = "GetGroupBranches",
  CreateGroupContracts = "CreateGroupContracts",
  UpdateGroupContracts = "UpdateGroupContracts",
  GetGroupsWithFilters = "GetGroupsWithFilters",
  GetAllGroups = "GetAllGroups",
  GetInfiniteGroups = "GetInfiniteGroups",
  GetGroupsAndManufacturersWithFilters = "GetGroupsWithFilters",
  GetAllAgencies = "GetAllAgencies",
  GetGroupNextContractNumber = "GetGroupNextContractNumber",
  GetGroupMails = "GetGroupMails",
  GetGroupManufacturers = "GetGroupManufacturers",
  GetNextErpNumber = "GetNextErpNumber",
  CreateGroup = "CreateGroup",
  UpdateGroupBaseData = "UpdateGroupBaseData",
  UpdateGroupPaymentInfo = "UpdateGroupPaymentInfo",
  UpdateGroupTypeInfo = "UpdateGroupTypeInfo",
  UpdateGroupActive = "UpdateGroupActive",
  GetExistingGroupDebitors = "GetExistingGroupDebitors",
  GetCfmCorporateUsersOfCurrentGroup = "GetCfmCorporateUsersOfCurrentGroup",

  // User
  CreateUser = "CreateUser",
  Logout = "Logout",
  UpdateUser = "UpdateUser",
  GetGroupUsers = "GetGroupUsers",
  GetRelatedWasteProducerUsers = "GetRelatedWasteProducerUsers",
  GetUsersWithFilter = "GetUsersWithFilters",
  GetLoggedInUserInfo = "GetLoggedInUserInfo",
  GetUser = "GetUser",
  LoginAsUser = "LoginAsUser",
  Login = "Login",
  LogoutAsUser = "LogoutAsUser",
  IsPasswordResetRequired = "isPasswordResetRequired",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
  Unlock = "Unlock",

  // Address
  UserAddressesForId = "UserAddressesForId",
  GetGroupAddressesForUuid = "GetGroupAddressesForUuid",
  GetInfiniteGroupAddresses = "GetInfiniteGroupAddresses",
  GetAddress = "GetAddress",
  GetAddressesOfType = "GetAddressesOfType",
  GetInfiniteAddressesOfType = "GetInfiniteAddressesOfType",
  ResolveAddress = "ResolveAddress",
  CreateAddress = "CreateAddress",
  UpdateAddress = "UpdateAddress",

  // Freshdesk
  CreateFreshdeskTicket = "CreateFreshdeskTicket",

  // Storage
  UploadFile = "UploadFile",

  Config = "Config",
}
