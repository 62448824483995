import { OutlinedInputClassKey, StyleRules } from "@material-ui/core";
import { Colors, Shades } from "style/Colors";
import { BORDER_RADIUS } from "style/Constant";

export const MuiOutlinedInput: Partial<StyleRules<OutlinedInputClassKey, {}>> = {
  root: {
    backgroundColor: Colors.grayLight,
    borderRadius: BORDER_RADIUS,
    boxShadow: `0px 0px 3px ${Colors.gray}`,
    border: `1px solid ${Colors.gray}`,
    fontFamily: "arial",

    "& .--normal": {
      height: "60px !important",
    },

    "& .--small": {
      height: "40px !important",
    },

    "&$disabled": {
      color: Colors.gray,
      backgroundColor: Shades.gray10,
      opacity: 0.9,
    },

    "&.MuiAutocomplete-inputRoot": {
      padding: "5px 10px !important",
    },

    "&$focused": {
      border: `1px solid ${Colors.accentGreenLight}`,

      "&::before": {
        border: "none",
      },

      "&::after": {
        border: "none",
      },
    },
    "&$error": {
      border: `1px solid ${Colors.red} !important`,
    },
  },
  multiline: {
    borderRadius: ".5rem",
  },
};
