import { Theme, Tooltip, TooltipProps, withStyles } from "@material-ui/core";
import { FC } from "react";
import { FONT_BADGE_SIZE } from "../../../config/theme/overrides/constant";
import { Colors } from "../../../style/Colors";

const StyledOrderToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: FONT_BADGE_SIZE,
    border: `2px solid ${Colors.greenDark}`,
    borderRadius: 4,
    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
  },
}))(Tooltip);

export const NewOrderToolTip: FC<TooltipProps> = (props) => {
  return <StyledOrderToolTip {...props} />;
};
