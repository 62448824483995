import { Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clearLogin } from "utils/auth";
import { AvailableSharedRoutes } from "utils/constants";
import { useStartCloningSystemQuery } from "../../repositories/queries/system-cloning/mutation/clone-system.query";
import { SystemCloningDialog } from "./components/system-cloning-dialog.component";
import { SystemCloningStatusPreview } from "./components/system-cloning-status.component";
import { useGetLatestCloningStatusQuery } from "../../repositories/queries/system-cloning/get-latest-cloning-status.query";

export const SystemCloningPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnComplete = (_: unknown) => {
    closeDialog();
    enqueueSnackbar(t("system_cloning.start.success"), {
      variant: "success",
    });
    setTimeout(() => {
      clearLogin();
      window.location.pathname = AvailableSharedRoutes.Login;
    }, 10000);
  };

  const handleOnError = (_: unknown) => {
    enqueueSnackbar(t("system_cloning.start.error"), {
      variant: "error",
    });
  };

  const { mutateAsync: startCloningSystem } = useStartCloningSystemQuery(handleOnError, handleOnComplete);
  const { data: latestStatus, refetch } = useGetLatestCloningStatusQuery();

  useEffect(() => {
    refetch();
    // on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleAccept = async () => {
    startCloningSystem();
  };

  const handleCancel = async () => {
    setIsDialogOpen(false);
  };

  return (
    <Grid container style={{ padding: 10 }}>
      <SystemCloningDialog isOpen={isDialogOpen} onAccept={handleAccept} onCancel={handleCancel} />
      <Button variant="contained" onClick={openDialog}>
        {t("system_cloning.start_cloning")}
      </Button>
      {latestStatus && <SystemCloningStatusPreview status={latestStatus} />}
    </Grid>
  );
};
