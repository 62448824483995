import { useQuery } from "react-query";
import { UserConverter } from "shared/domain/converter/user.converter";
import { IPaginationInputMeta } from "shared/domain/pagination/pagination-meta";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { PaginationMetaConverter } from "../../../domain/converter/pagination-meta.converter";
import { IUserResult } from "../../../domain/user/user";
import { IUserResultModel } from "../../../models/user/user.model";
import { SharedQueryKeys } from "../shared-query-keys";

export const getGroupUsers = async (
  id: number,
  searchText: string | undefined,
  paginationInputMeta?: IPaginationInputMeta,
): Promise<IUserResult> => {
  const url = replaceUriParameter(RestEndpoints.UsersForGroup, "groupId", id);
  const response = await getInstance().get<IUserResultModel>(url, {
    params: {
      query: searchText,
      ...paginationInputMeta,
    },
  });

  const data: IUserResult = {
    users: response.data.users.map(UserConverter.toDomain),
    meta: response.data.meta ? PaginationMetaConverter.toDomain(response.data.meta) : response.data.meta,
  };

  return data;
};

export const useGetGroupUsers = (
  id: number,
  searchText: string | undefined,
  paginationInputMeta?: IPaginationInputMeta,
) => {
  return useQuery(
    [SharedQueryKeys.GetGroupUsers, id, searchText],
    () => getGroupUsers(id, searchText, paginationInputMeta),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: Number.isFinite(id),
    },
  );
};
