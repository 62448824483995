import { AccordionDetailsClassKey, StyleRules } from "@material-ui/core";
import { Shades } from "style/Colors";
import { PADDING_BASE } from "./constant";

export const MuiAccordionDetails: Partial<StyleRules<AccordionDetailsClassKey, {}>> = {
  root: {
    backgroundColor: Shades.gray10,
    padding: PADDING_BASE,
  },
};
