import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useLoginStyle } from "style/login.style";

interface IPasswordResetBodyProps {}

export const PasswordResetBody: FC<IPasswordResetBodyProps> = ({ children }) => {
  const classes = useLoginStyle();

  return (
    <div className={classes.root}>
      <Grid container className="justify-content-center row">
        <Grid item md={8} sm={10}>
          <div className="card-group">
            <div className={["p-4 card", classes.card, classes.cardRightRadius].join(" ")}>
              <div className="card-body">{children}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
