export enum BattGRegistrationStatus {
  Draft = "draft",
  Published = "published",
}

export interface IBattGRegistration {
  id: number;
  status: BattGRegistrationStatus;
  manufacturer: IBattGRegistrationManufacturer;
  mainContactPerson: IBattGRegistrationContactPerson;
  representativeContactPerson: IBattGRegistrationContactPerson;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBattGRegistrationManufacturer {
  name: string;
  address: string;
  buildingNumber: string;
  postal: string;
  city: string;
  state: string;
  country: string;
  webAddress: string | null;
  tradeRegisterNumber: string | null;
  startDate: Date;
  endDate: Date | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBattGRegistrationContactPerson {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  jobTitle: string | null;
  createdAt: Date;
  updatedAt: Date;
}
