import { createContext, FC, useContext, useMemo } from "react";
import { useGetProductCountQuery } from "../../../collect-from-market/repositories/queries/product/query/get-product-count.query";
import config from "../../../config/config";
import { ProductOverviewGeneralFilter } from "../../../configurator/pages/product/components/product-overview.utils";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { ShortcutType } from "../../../utils/shortcuts";
import { isAdmin } from "../../domain/user/user";

interface IDashboardCountProvider {
  isWithoutPackageCountLoading: boolean;
  isWithoutRoutingCountLoading: boolean;
  isWithoutResponseCountLoading: boolean;
  withoutPackageCount: number | undefined;
  withoutRoutingCount: number | undefined;
  withoutResponseCount: number | undefined;
}

export const ShortcutTypesWithCount = [
  ShortcutType.Cart,
  ShortcutType.PackageConfig,
  ShortcutType.RoutingConfig,
  ShortcutType.ResponseConfig,
];

export const DashboardCountContext = createContext<IDashboardCountProvider>({} as IDashboardCountProvider);

export const useDashboardCountContext = () => {
  return useContext(DashboardCountContext);
};

const useDashboardCountProvider = (): IDashboardCountProvider => {
  const { internalUser } = useAuthContext();
  const isEnabled = useMemo(() => {
    return isAdmin(internalUser) && config.featureToggles.articleConfigurator;
  }, [internalUser]);

  const { isLoading: isWithoutPackageCountLoading, data: withoutPackageCount } = useGetProductCountQuery(
    ProductOverviewGeneralFilter.WithoutPackages,
    isEnabled,
  );
  const { isLoading: isWithoutRoutingCountLoading, data: withoutRoutingCount } = useGetProductCountQuery(
    ProductOverviewGeneralFilter.WithoutRouting,
    isEnabled,
  );
  const { isLoading: isWithoutResponseCountLoading, data: withoutResponseCount } = useGetProductCountQuery(
    ProductOverviewGeneralFilter.WithoutResponseAssignments,
    isEnabled,
  );

  return {
    isWithoutPackageCountLoading,
    isWithoutRoutingCountLoading,
    isWithoutResponseCountLoading,
    withoutPackageCount,
    withoutRoutingCount,
    withoutResponseCount,
  };
};

export const DashboardCountProvider: FC = (props) => {
  const value = useDashboardCountProvider();
  return <DashboardCountContext.Provider value={value}>{props.children}</DashboardCountContext.Provider>;
};
