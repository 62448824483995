import React, { createContext, useContext, useState } from "react";

interface IUnsavedDataContextType {
  hasUnsavedData: boolean;
  setHasUnsavedData: (hasUnsavedData: boolean) => void;
}

interface IUnsavedDataprops {
  children: any;
}

const UnsavedDataContext = createContext<IUnsavedDataContextType>({} as IUnsavedDataContextType);

export const UnsavedDataProvider = (props: IUnsavedDataprops) => {
  const value = useUnsavedDataProvider(props);
  return <UnsavedDataContext.Provider value={value}>{props.children}</UnsavedDataContext.Provider>;
};

export const useUnsavedDataContext = () => {
  return useContext(UnsavedDataContext);
};

const useUnsavedDataProvider = (props: IUnsavedDataprops): IUnsavedDataContextType => {
  const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false);

  return {
    hasUnsavedData,
    setHasUnsavedData,
  };
};
