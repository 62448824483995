import { UnsavedDataPrompt } from "components/Routes/unsaved-data-prompt";
import React, { Suspense, VFC } from "react";
import { Redirect, Switch } from "react-router-dom";
import { GroupType } from "shared/domain/group/group-type";
import { CreateUserPage } from "shared/pages/create-user/create-user.page";
import { AuthenticatedRoute } from "../../utils/AuthenticatedRoute";
import { AvailableCfmRoutes } from "../../utils/constants";
import { OrderXNewRepeatPage } from "../pages/order-new-repeat/order-x-new-repeat.page";
import { OrderXNewPage } from "../pages/order-new/order-x-new.page";
import { OrderXOverviewPage } from "../pages/order-x/order-x-overview.page";
import { OrderXRepeatOverview } from "../pages/order-x-repeat-overview/order-x-repeat-overview.page";
import { OrderXEditPendingPage } from "collect-from-market/pages/order-edit-pending/order-edit-x-pending.page";
import { CartPage } from "../pages/cart/cart.page";
import { Loading } from "../../shared/components/loading/loading.component";
import { EditTruckloadPage } from "../pages/truckload/edit-truckload.page";
import { AvailablePlatform } from "../../providers/Auth/platform.provider";

export const CfmRoutes: VFC = () => {
  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <UnsavedDataPrompt />
      <Switch>
        <Redirect path={AvailableCfmRoutes.Portal} exact to={AvailableCfmRoutes.OrderXOverview} />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.UserNew}
          exact
          component={() => <CreateUserPage platformType={AvailablePlatform.Cfm} />}
        />

        {/*Order X*/}
        <AuthenticatedRoute
          path={[AvailableCfmRoutes.OrderXOverview, AvailableCfmRoutes.OrderXDetail, AvailableCfmRoutes.OrderQrView]}
          exact
          component={OrderXOverviewPage}
        />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.OrderXNew}
          exact
          component={() => <OrderXNewPage />}
          allowedRoles={[GroupType.Customer]}
        />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.OrderXAdminNew}
          exact
          component={() => <OrderXNewPage />}
          allowedRoles={[GroupType.Admin]}
        />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.OrderXNewRepeat}
          exact
          component={() => <OrderXNewRepeatPage />}
          allowedRoles={[GroupType.Customer, GroupType.Admin]}
        />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.OrderXRepeatOverview}
          exact
          component={() => <OrderXRepeatOverview />}
        />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.OrderXEditPending}
          exact
          component={() => <OrderXEditPendingPage />}
          allowedRoles={[GroupType.Customer]}
        />
        <AuthenticatedRoute
          path={AvailableCfmRoutes.CartX}
          exact
          component={() => <CartPage />}
          allowedRoles={[GroupType.Customer]}
        />

        <AuthenticatedRoute
          path={AvailableCfmRoutes.TruckloadEdit}
          exact
          component={() => <EditTruckloadPage />}
          allowedRoles={[GroupType.CfmLogistics, GroupType.CfmRecycler]}
        />

        <Redirect path="*" to={AvailableCfmRoutes.Portal} push />
      </Switch>
    </Suspense>
  );
};
