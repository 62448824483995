import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { formatDateYearMonthDay } from "../../../../shared/util/date.util";
import getInstance from "../../../../utils/rest";
import { IPomAnnouncementListModel } from "../../models/announcements/pom-announcement-list.model";
import { PomQueryKeys } from "../../pom-query-keys";
import { ReactQueryConfig } from "../../../../config/react-query-config";

const getAnnouncements = async (from?: Date, to?: Date, contractId?: number): Promise<IPomAnnouncementListModel> => {
  const { data } = await getInstance().get<IPomAnnouncementListModel>(RestEndpoints.PomAnnouncements, {
    params: {
      from: from && formatDateYearMonthDay(from),
      to: to && formatDateYearMonthDay(to),
      contractId,
    },
  });

  return data;
};

export const useGetAnnouncementsQuery = (from?: Date, to?: Date, contractId?: number) => {
  return useQuery([PomQueryKeys.GetAnnouncements, from, to, contractId], () => getAnnouncements(from, to, contractId), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
