import { Box, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useEffect, useState, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditIconNoBorderButton } from "../../../components/Primitives";
import { IAddress } from "../../../shared/domain/address/address";
import { Colors, Shades } from "../../../style/Colors";
import { ICreateProductRoutingFormInputs } from "./create-product-routing-content.component";
import { ProductRoutingAddressField } from "./product-routing-address-field.component";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    border: "2px solid",
    borderColor: Shades.gray40,
    borderRadius: "0px 5px 5px 5px",
    padding: "15px 20px 15px 20px",
  },
  boxHeader: {
    backgroundColor: Shades.gray50,
    width: "fit-content",
    borderRadius: "5px 5px 0px 0px",
    padding: "7px 15px 3px 15px",
  },
  boxHeadingText: {
    color: Colors.white,
  },
  container: {
    height: "100%",
  },
}));

interface IProductRoutingAddressesSummaryProps {
  onEditClick: VoidFunction;
  startAddresses: IAddress[];
  targetAddresses: IAddress[];
  groupId: number | undefined;
}

export const ProductRoutingAddressesSummary: VFC<IProductRoutingAddressesSummaryProps> = (props) => {
  const { onEditClick, startAddresses, targetAddresses, groupId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [primaryStartAddress, setPrimaryStartAddress] = useState<IAddress | undefined>(undefined);
  const [primaryTargetAddress, setPrimaryTargetAddress] = useState<IAddress | undefined>(undefined);
  const [optionalPrimaryAddresses, setOptionalPrimaryAddresses] = useState<IAddress[]>([]);
  const [secondaryStartAddress, setSecondaryStartAddress] = useState<IAddress | undefined>(undefined);
  const [secondaryTargetAddress, setSecondaryTargetAddress] = useState<IAddress | undefined>(undefined);
  const [optionalSecondaryAddresses, setOptionalSecondaryAddresses] = useState<IAddress[]>([]);

  const { watch } = useFormContext<ICreateProductRoutingFormInputs>();

  const primaryStartAddressId: number = watch("primaryStartAddressId");
  const primaryTargetAddressId: number = watch("primaryTargetAddressId");
  const optionalPrimaryAddressIds: number[] | undefined = watch("optionalPrimaryAddressIds");
  const secondaryStartAddressId: number = watch("secondaryStartAddressId");
  const secondaryTargetAddressId: number = watch("secondaryTargetAddressId");
  const optionalSecondaryAddressIds: number[] | undefined = watch("optionalSecondaryAddressIds");

  useEffect(() => {
    const allAddresses = [...startAddresses.filter((address) => address.groupId === groupId), ...targetAddresses];
    setPrimaryStartAddress(startAddresses.find((address) => address.id === primaryStartAddressId));
    setPrimaryTargetAddress(allAddresses.find((address) => address.id === primaryTargetAddressId));
    const optionalPrimary = allAddresses.filter(
      (address) => address.id && optionalPrimaryAddressIds && optionalPrimaryAddressIds.includes(address.id),
    );
    setOptionalPrimaryAddresses(optionalPrimary);
    setSecondaryStartAddress(startAddresses.find((address) => address.id === secondaryStartAddressId));
    setSecondaryTargetAddress(allAddresses.find((address) => address.id === secondaryTargetAddressId));
    const optionalSecondary = allAddresses.filter(
      (address) => address.id && optionalSecondaryAddressIds && optionalSecondaryAddressIds.includes(address.id),
    );
    setOptionalSecondaryAddresses(optionalSecondary);
  }, [
    primaryStartAddressId,
    primaryTargetAddressId,
    optionalPrimaryAddressIds,
    secondaryStartAddressId,
    secondaryTargetAddressId,
    optionalSecondaryAddressIds,
    startAddresses,
    targetAddresses,
  ]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="column">
          <Grid item className={classes.boxHeader}>
            <Typography variant="body1" className={classes.boxHeadingText}>
              {t("configuration.addresses")}
            </Typography>
          </Grid>
          <Grid item className={classes.box}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.container}>
                  <Grid container direction="column">
                    {primaryStartAddress && primaryTargetAddress && (
                      <ProductRoutingAddressField
                        heading={t("configuration.assignments.main")}
                        startAddress={primaryStartAddress}
                        targetAddress={primaryTargetAddress}
                        optionalAddresses={optionalPrimaryAddresses}
                        level="primary"
                      />
                    )}
                    {secondaryStartAddress && secondaryTargetAddress && (
                      <Grid item style={{ paddingTop: 4 }}>
                        <Divider />
                      </Grid>
                    )}
                    {secondaryStartAddress && secondaryTargetAddress && (
                      <ProductRoutingAddressField
                        heading={t("configuration.assignments.secondary")}
                        startAddress={secondaryStartAddress}
                        targetAddress={secondaryTargetAddress}
                        optionalAddresses={optionalSecondaryAddresses}
                        level="secondary"
                      />
                    )}
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.container}>
                  <EditIconNoBorderButton onClick={onEditClick} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
