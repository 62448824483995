import { useMutation } from "react-query";
import { RestEndpoints, replaceUriParameter } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { ManufacturerConverter } from "../../../domain/converter/manufacturer/manufacturer.converter";
import { IPomManufacturer } from "../../../domain/models/manufacturer/manufacturer";
import { IPomManufacturerModel } from "../../models/manufacturer/manufacturer.model";
import { PomQueryKeys } from "../../pom-query-keys";
import { IUpdatePomManufacturerRequest } from "../../requests/manufacturer/manufacturer-update.request";

type QueryErrorHandler =
  | ((
      error: unknown,
      variables: {
        manufacturerId: number;
        manufacturerInput: IUpdatePomManufacturerRequest;
      },
      context: unknown,
    ) => void | Promise<unknown>)
  | undefined;
type QuerySuccessHandler =
  | ((
      data: IPomManufacturer,
      variables: {
        manufacturerId: number;
        manufacturerInput: IUpdatePomManufacturerRequest;
      },
      context: unknown,
    ) => void | Promise<unknown>)
  | undefined;

const updateManufacturer = async (
  manufacturerId: number,
  manufacturerInput: IUpdatePomManufacturerRequest,
): Promise<IPomManufacturer> => {
  const url = replaceUriParameter(RestEndpoints.EditPomManufacturer, "id", manufacturerId);
  const { data } = await getInstance().put<IPomManufacturerModel>(url, manufacturerInput);

  return ManufacturerConverter.toDomain(data);
};

export const useUpdateManufacturerQuery = (handleError: QueryErrorHandler, handleSuccess: QuerySuccessHandler) => {
  return useMutation(
    [PomQueryKeys.UpdateManufacturer],
    ({
      manufacturerId,
      manufacturerInput,
    }: {
      manufacturerId: number;
      manufacturerInput: IUpdatePomManufacturerRequest;
    }) => updateManufacturer(manufacturerId, manufacturerInput),
    {
      onError: handleError,
      onSuccess: handleSuccess,
    },
  );
};
