import { ICfmPackageModel } from "collect-from-market/repositories/models/package/cfm-package.model";
import { ICfmCreatePackage, ICfmPackage } from "../../../domain/packages/cfm-package";
import { ICfmItemX } from "../../../domain/order-x/cfm-item-x";
import { CfmActions } from "../../../pages/order-x/components/card-information/order-x-card-information.component";
import { ICfmCreateOrderXPackageModel } from "../order-x/create/cfm-create-order-x-package.model";

export class CfmPackageConverter {
  public static toDomain(pkg: ICfmPackageModel): ICfmPackage {
    return {
      id: pkg.id,
      uuid: pkg.uuid,
      name: pkg.name,
      length: pkg.length,
      width: pkg.width,
      height: pkg.height,
      unit: pkg.unit,
      tareWeightKg: pkg.tareWeightKg,
      averageGrossWeightKg: pkg.averageGrossWeightKg,
      active: pkg.active,
      measurementsRequired: pkg.measurementsRequired,
      imageUrl: pkg.imageUrl,
      withPackageDelivery: pkg.withPackageDelivery,
    };
  }

  public static createPackageToCreateXPackageModel(pckg: ICfmCreatePackage): ICfmCreateOrderXPackageModel {
    const quantityToDeliver =
      !pckg.containersToBeDelivered || isNaN(pckg.containersToBeDelivered) ? 0 : Number(pckg.containersToBeDelivered);

    return {
      id: pckg.packageId,
      height: pckg.measurementsRequired && pckg.height ? Number(pckg.height) : undefined,
      width: pckg.measurementsRequired && pckg.width ? Number(pckg.width) : undefined,
      length: pckg.measurementsRequired && pckg.length ? Number(pckg.length) : undefined,
      weight: pckg.measurementsRequired && pckg.weight ? Number(pckg.weight) : undefined,
      quantityToCollect: Number(pckg.containersToBeCollected),
      quantityToDeliver,
    };
  }

  public static toCreatePackageXList(items: ICfmItemX[] | undefined): ICfmCreatePackage[] | undefined {
    if (!items) return undefined;

    // Currently each action for a package is it`s own package, but we want to display
    // only one with the delivery/collection quantities
    const packageItems: ICfmCreatePackage[] = [];
    items
      .filter((item) => item.package)
      .forEach((item) => {
        const index = packageItems.findIndex((p) => p.packageId === item.package?.id);

        if (index === -1 && item.package?.id) {
          packageItems.push({
            packageId: item.package?.id,
            containersToBeDelivered: item.action?.id === CfmActions.Delivery ? item.quantity : undefined,
            containersToBeCollected: item.action?.id === CfmActions.Pickup ? item.quantity : undefined,
            height: item.package?.height ?? undefined,
            length: item.package?.length ?? undefined,
            weight: item.package?.tareWeightKg ?? undefined,
            width: item.package?.width ?? undefined,
            measurementsRequired: item.package?.measurementsRequired ?? false,
            tareWeightKg: item.package?.tareWeightKg ?? null,
            averageGrossWeightKg: item.package?.averageGrossWeightKg ?? null,
          });
          return;
        }

        const packageItem = packageItems[index];
        if (item.action?.id === CfmActions.Delivery) {
          if (!packageItem.containersToBeDelivered) packageItem.containersToBeDelivered = 0;
          packageItem.containersToBeDelivered += item.quantity;
        }

        if (item.action?.id === CfmActions.Pickup) {
          if (!packageItem.containersToBeCollected) packageItem.containersToBeCollected = 0;
          packageItem.containersToBeCollected += item.quantity;
        }
      });

    return packageItems;
  }
}
