import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import { ICreateProductRoutingFormInputs } from "../../../../configurator/components/product-routing/create-product-routing-content.component";
import getInstance from "../../../../utils/rest";
import { CfmCreateProductRoutingAssignmentConverter } from "../../models/converter/cfm-create-product-routing-assignment.converter";
import { CfmQueryKeys } from "../cfm-query-keys";

const createProductRoutingAssignments = async (
  productIds: number[],
  createData: ICreateProductRoutingFormInputs,
): Promise<void> => {
  const convertedConfig = CfmCreateProductRoutingAssignmentConverter.createDataToModel(createData);
  await getInstance().post(RestEndpoints.CreateProductRoutingAssignments, { productIds, ...convertedConfig });
};

export const useCreateProductRoutingAssignmentsQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    CfmQueryKeys.CreateProductRoutingAssignments,
    ({ productIds, createData }: { productIds: number[]; createData: ICreateProductRoutingFormInputs }) =>
      createProductRoutingAssignments(productIds, createData),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
