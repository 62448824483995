import { BaseDataIcon, OrdersIcon } from "../../../components/Primitives";
import { INavigationItem, escapeParam } from "../../../shared/domain/navigation/navigation-item";
import { IUser, isCfmCustomer } from "../../../shared/domain/user/user";
import { AvailableCfmRoutes, AvailableSharedRoutes } from "../../../utils/constants";

export default function getCfmNavItems(user?: IUser, groupId?: number): INavigationItem[] {
  return [
    {
      label: "navigation.orders.label",
      icon: OrdersIcon,
      to: AvailableCfmRoutes.BaseOrderUrl,
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.orders.overview.label",
          description: "navigation.orders.overview.description",
          to: AvailableCfmRoutes.OrderXOverview,
          visible: true,
          invisibleChildrenUrls: [
            escapeParam(AvailableCfmRoutes.OrderXDetail, ":orderId"),
            escapeParam(AvailableCfmRoutes.OrderQrView, ":orderId"),
            escapeParam(AvailableCfmRoutes.TruckloadEdit, ":id"),
          ],
        },
        {
          label: "navigation.orders.new.label",
          description: "navigation.orders.new.label",
          to: AvailableCfmRoutes.OrderXNew,
          visible: isCfmCustomer(user),
        },
        {
          label: "navigation.orders.repeatOverview.label",
          description: "navigation.orders.repeatOverview.description",
          to: AvailableCfmRoutes.OrderXRepeatOverview,
          visible: isCfmCustomer(user),
        },
        {
          label: "",
          to: AvailableCfmRoutes.OrderXDetail,
          visible: false,
        },
        {
          label: "",
          to: AvailableCfmRoutes.OrderQrView,
          visible: false,
        },
        {
          label: "",
          to: AvailableCfmRoutes.TruckloadEdit,
          visible: false,
          escapedUrl: escapeParam(AvailableCfmRoutes.TruckloadEdit, ":id"),
        },
      ],
    },
    {
      label: "navigation.basedata.label",
      icon: BaseDataIcon,
      to: "/portal/groups",
      visible: user !== undefined,
      isParent: true,
      content: [
        {
          label: "navigation.basedata.groups.account",
          to: AvailableSharedRoutes.GroupDetail.replace(":id", groupId?.toString() ?? ""),
          visible: true,
        },
      ],
    },
  ];
}
