import { Box } from "@material-ui/core";
import { FC } from "react";
import { useGroupHeaderStyle } from "../../../../style/group-header.style";

export const GroupDetailHeader: FC = (props) => {
  const { children } = props;
  const headerStyle = useGroupHeaderStyle();

  return <Box className={headerStyle.detailHeader}>{children}</Box>;
};
