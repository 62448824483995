import { Grid, GridProps } from "@material-ui/core";
import { VFC } from "react";

interface IHidableGridProps extends GridProps {
  hidden?: boolean;
}

export const HidableGridItem: VFC<IHidableGridProps> = (props) => {
  const { hidden, children, ...rest } = props;
  if (hidden) return null;
  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
};
