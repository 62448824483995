import { TEXT_SEPARATOR } from "../../utils/constants";
import { TranslateFunc } from "../../utils/translation.utils";
import { ICfmGroupContract, ICfmGroupContractField, IPomGroupContract } from "../domain/group/group-contract";
import { formatDateShort } from "./date.util";

export const getFormattedContractTitle = (contract: ICfmGroupContract | IPomGroupContract): string => {
  const endDate = contract.endDate ? ` - ${formatDateShort(contract.endDate)}` : ``;
  return `${contract.contractNumber}${TEXT_SEPARATOR} ${formatDateShort(contract.startDate)}${endDate}`;
};

export const getFormattedContractSubtitle = (
  contract: ICfmGroupContract | IPomGroupContract,
  t: TranslateFunc,
): string => {
  if (instanceOfCfmGroupContract(contract.contractField)) {
    return `${contract.contractField.productCategory?.name}`;
  } else {
    return `${contract.contractField.takeBackSystem?.name}${TEXT_SEPARATOR} ${
      contract.contractField?.defaultPriceGroup
        ? t("basedata.groups.contractsTable.default_price_group")
        : t("basedata.groups.contractsTable.not_default_price_group")
    }`;
  }
};

function instanceOfCfmGroupContract(object: any): object is ICfmGroupContractField {
  return "productCategory" in object;
}
