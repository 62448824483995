import { FC } from "react";
import { useTranslation } from "react-i18next";
import { QuestionDialog } from "../../../../components/Dialogs/question-dialog";

interface ICartDeleteOrdersDialogProps {
  onAccept: () => Promise<void>;
  onClose: () => Promise<void>;
  isOpen: boolean;
  isProcessing: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const CartDeleteOrdersDialog: FC<ICartDeleteOrdersDialogProps> = (props) => {
  const { onAccept, onClose, isOpen, isProcessing } = props;
  const { t } = useTranslation();

  return (
    <QuestionDialog
      title={t("dialog.multiselect_order_delete_title.text")}
      message={t("dialog.multiselect_order_delete_message.text")}
      acceptText={t("dialog.multiselect_order_delete_button_action.text")}
      onAccept={onAccept}
      onClose={onClose}
      isOpen={isOpen}
      displayStyle={"warning"}
      isProcessing={isProcessing}
    />
  );
};
