import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { CfmQueryKeys } from "../cfm-query-keys";

const deleteProductRoutingAssignment = async (id: number): Promise<void> => {
  await getInstance().delete(RestEndpoints.DeleteProductRoutingAssignment.replace(":id", id.toString()));
};

export const useDeleteProductRoutingAssignmentQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    CfmQueryKeys.DeleteProductRoutingAssignments,
    ({ id }: { id: number }) => deleteProductRoutingAssignment(id),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CfmQueryKeys.GetAllProducts);
        queryClient.invalidateQueries(CfmQueryKeys.GetAllProductsInfinite);
        queryClient.invalidateQueries(CfmQueryKeys.GetProductCount);
      },
    },
  );
};
