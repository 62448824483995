import React, { FunctionComponent } from "react";
import { NumberInputField } from "../../../../../components/Form/Fields/NumberField";
import { useInputStyles } from "../../../../../style/input.style";
import { formatNumber } from "../../../../../utils/number.helper";
import { usePomAnnouncementContext } from "../../../../pages/announcement/pom-announcement.provider";

interface IPomAnnouncementTableWeightInputColProps {
  id: number;
}

export const PomAnnouncementTableWeightInputCol: FunctionComponent<IPomAnnouncementTableWeightInputColProps> = (
  props,
) => {
  const classes = useInputStyles();
  const { id } = props;
  const { products, changedProducts, onWeightChange, showValidationErrors, validationErrors, validationWarnings } =
    usePomAnnouncementContext();

  const product = products?.find((value) => value.id === id);
  const changedProduct = changedProducts?.find((value) => value.id === id);

  const weight = formatNumber(product?.totalWeight, 3) ?? "-";
  const changedWeight = changedProduct?.totalWeight;

  const hasError = showValidationErrors && Array.from(validationErrors).find((e) => e.productId === id)?.weightError;
  const hasWarning = Array.from(validationWarnings).some((productId) => productId === id);

  return (
    <>
      {changedProduct && (
        <NumberInputField
          key={`${product?.id}-weight`}
          minValue={0}
          disabled={changedProduct.isInvalid === true}
          error={hasError || changedProduct.isInvalid === true}
          className={hasWarning ? classes.warning : undefined}
          onChange={(event) => onWeightChange(changedProduct!, event.target.value)}
          value={changedWeight}
          fractionDigits={3}
          step={0.001}
        />
      )}
      {product && !changedProduct && <div>{weight} </div>}
    </>
  );
};
