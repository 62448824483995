import { StatusCodes } from "http-status-codes";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance, { instanceOfAxiosError } from "../../../../../utils/rest";
import { UserConverter } from "../../../../domain/converter/user.converter";
import { UserUpdateRequest } from "../../../../domain/requests/user-create.request";
import { IUser } from "../../../../domain/user/user";
import { IUserModel } from "../../../../models/user/user.model";
import { SharedQueryKeys } from "../../shared-query-keys";

const updateUser = async (createData: UserUpdateRequest, userId: number): Promise<IUser> => {
  const { data } = await getInstance().put<IUserModel>(RestEndpoints.EditUser.replace(":id", `${userId}`), createData);
  return UserConverter.toDomain(data);
};

export const useUpdateUserQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    SharedQueryKeys.UpdateUser,
    ({ updateData, userId }: { updateData: UserUpdateRequest; userId: number }) => updateUser(updateData, userId),
    {
      onError: (error) => {
        if (instanceOfAxiosError(error) && error.response?.status !== StatusCodes.INTERNAL_SERVER_ERROR) {
          const errorMsg = getErrorCode(error);
          const errorCode = getErrorStatusCode(error);
          enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
        }
      },
      onSuccess: () => {
        enqueueSnackbar(t("basedata.general.user.update_success"), { variant: "success" });
        void queryClient.invalidateQueries(SharedQueryKeys.GetUser);
      },
    },
  );
};
