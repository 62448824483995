import { Box, CircularProgress, Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../../providers/Auth/platform.provider";
import { Shades } from "../../../../style/Colors";
import { getNamePartsFromContactPersonName } from "../../../../utils/address.util";
import { SCROLL_TO_TOP_BUTTON_HEIGHT } from "../../../../utils/constants";
import { FormHeader } from "../../../components/form/form-header.component";
import { GroupContractsTable } from "../../../components/table/group-contracts-table";
import { UserFormModal } from "../../../components/user/user-form-modal/user-form-modal.component";
import { IUserInitialValues, UserFormMode } from "../../../components/user/user-form/user-form.component";
import { ICreateAddressResult } from "../../../domain/address/address";
import { IAddressLean } from "../../../domain/address/address-lean";
import { useConfigContext } from "../../../domain/config/config.provider";
import { hasGroupPomContracts, hasGroupPomOrCfmContracts } from "../../../domain/group/group";
import { GroupType, isCfmGroup, isPomGroup } from "../../../domain/group/group-type";
import { RolePom } from "../../../domain/user/role-pom";
import {
  isAdmin,
  isCfmCorporate,
  isCfmWasteProducer,
  isPomAgency,
  isPomMainContact,
  isPomRepresentative,
} from "../../../domain/user/user";
import { UserTypeLevel } from "../../../domain/user/user-type-level";
import { useGetGroupForDetailsQuery } from "../../../repositories/queries/group/get-group-for-details.query";
import { SharedQueryKeys } from "../../../repositories/queries/shared-query-keys";
import { GroupDetailProvider } from "../group-detail.provider";
import { GroupDetailBaseInfo } from "./group-detail-base-info.component";
import { GroupDetailPaymentInfo } from "./group-detail-payment-info.component";
import { GroupDetailTypeInfo } from "./group-detail-type-info.component";
import { GroupDetailsAddressesTable } from "./tables/group-detail-address-table.component";
import { GroupDetailsGroupHistoriesTable } from "./tables/group-detail-group-history-table.component";
import { GroupDetailsMailsTable } from "./tables/group-detail-mail-table.component";
import { GroupDetailsManufacturersTable } from "./tables/group-detail-manufacturers-table.component";
import { GroupDetailsUsersTable } from "./tables/group-detail-users-table.component";
import { GroupDetailsWasteProducerAddressesTable } from "./tables/group-detail-waste-producer-address-table.component";
import { GroupDetailsWasteProducerUsersTable } from "./tables/group-detail-waste-producer-users-table.componen";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
  divider: {
    backgroundColor: Shades.gray20,
    marginTop: 0,
    marginBottom: 0,
  },
  spacingBottom: {
    marginBottom: SCROLL_TO_TOP_BUTTON_HEIGHT,
  },
}));

enum CreateUserSender {
  CreatedGroup,
  CreatedAddress,
}

interface ICreateUserDialogInfo {
  isOpen: boolean;
  sender: CreateUserSender;
}

interface IGroupDetailContentProps {
  groupId: number;
  platform: AvailablePlatform;
  createUserOnLoad: boolean;
}

export const GroupDetailContent: VFC<IGroupDetailContentProps> = (props) => {
  const { groupId, platform, createUserOnLoad } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const { appConfig } = useConfigContext();
  const { setHasUnsavedData } = useUnsavedDataContext();
  const queryClient = useQueryClient();
  const { activePlatform } = usePlatformContext();

  const [createUserDialogInfo, setCreateUserDialogInfo] = useState<ICreateUserDialogInfo>(
    createUserOnLoad
      ? { isOpen: createUserOnLoad, sender: CreateUserSender.CreatedGroup }
      : { isOpen: false, sender: CreateUserSender.CreatedAddress },
  );
  const [addressForCreateUser, setAddressForCreateUser] = useState<IAddressLean | undefined>();

  const { isLoading, data: group } = useGetGroupForDetailsQuery(groupId);

  const isPomMainContactOrCfmCorporate =
    (isPomMainContact(internalUser) && activePlatform === AvailablePlatform.Pom) ||
    (isCfmCorporate(internalUser) && activePlatform === AvailablePlatform.Cfm);
  const isWasteProducer = useMemo(() => isCfmWasteProducer(internalUser) && !isAdmin(internalUser), [internalUser]);

  const displayContracts = isAdmin(internalUser) || isPomMainContactOrCfmCorporate;
  const displayGroupAddresses = isAdmin(internalUser) || isCfmCorporate(internalUser);
  const displayGroupDetailType = isAdmin(internalUser);

  const displayPaymentInfo =
    isAdmin(internalUser) ||
    isPomAgency(internalUser) ||
    isPomRepresentative(internalUser) ||
    (internalUser?.group && hasGroupPomOrCfmContracts(internalUser?.group) && isPomMainContactOrCfmCorporate);

  const isCreateUserDialogOpen = useMemo(() => {
    return group && createUserDialogInfo.isOpen;
  }, [group, createUserDialogInfo]);

  const displayMailsTable = useMemo(() => {
    return (
      platform === AvailablePlatform.Pom &&
      group?.type !== GroupType.PomAgency &&
      hasGroupPomContracts(group) &&
      (isAdmin(internalUser) || isPomMainContact(internalUser))
    );
  }, [platform, group, internalUser]);

  const displayManufacturersTable = useMemo(() => {
    return platform === AvailablePlatform.Pom && group?.type === GroupType.PomRepresentative;
  }, [platform, group]);

  const displayGroupHistoriesTable = useMemo(() => {
    return isAdmin(internalUser);
  }, [internalUser]);

  const initialValuesForCreateUser: IUserInitialValues | undefined = useMemo(() => {
    const address =
      createUserDialogInfo.sender === CreateUserSender.CreatedGroup ? group?.groupAddress : addressForCreateUser;

    if (!address) return undefined;
    const { firstName, lastName } = getNamePartsFromContactPersonName(address.contactPersonName);

    const values = {
      firstName,
      lastName,
      email: address.contactPersonEmail ?? undefined,
      notify: true,
      title: t("basedata.users.title.diverse"),
      userTypeLevel: isCfmGroup(group?.type) ? UserTypeLevel.Corporate : undefined,
      rolePom: isPomGroup(group?.type) ? RolePom.MainContact : undefined,
      password: appConfig?.userDefaultPw ?? "",
      confirmPassword: appConfig?.userDefaultPw ?? "",
      telephone: address.contactPersonTelephone ?? undefined,
    };
    return values;
  }, [createUserDialogInfo, addressForCreateUser, group, t, appConfig]);

  const onUserCreated = () => {
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupUsers);
    onUserModalClose();
  };

  const onUserModalClose = () => {
    setHasUnsavedData(false);
    setAddressForCreateUser(undefined);
    setCreateUserDialogInfo({ isOpen: false, sender: CreateUserSender.CreatedAddress });
  };

  const onAddressCreated = (result: ICreateAddressResult | undefined) => {
    if (result?.contactPersonEmailExists === true || result?.address === undefined) return;
    setAddressForCreateUser(result.address);
    setCreateUserDialogInfo({ isOpen: true, sender: CreateUserSender.CreatedAddress });
  };

  return (
    <>
      {isCreateUserDialogOpen && (
        <UserFormModal
          savedCallback={onUserCreated}
          onCancel={onUserModalClose}
          open={isCreateUserDialogOpen}
          group={group}
          initialValues={initialValuesForCreateUser}
          mode={UserFormMode.Create}
        />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormHeader>{t("basedata.groups.detail.text", { id: groupId })}</FormHeader>
        </Grid>
        {isLoading && (
          <Grid item>
            <Grid container direction="row">
              <Grid item md={12} className={classes.alignSpinner}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isLoading && group && (
          <GroupDetailProvider group={group}>
            <Grid item>
              <GroupDetailBaseInfo group={group} headerOnly={isWasteProducer} />
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
            </Grid>
            {displayPaymentInfo && (
              <>
                <Grid item>
                  <GroupDetailPaymentInfo group={group} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {displayGroupDetailType && (
              <>
                <Grid item>
                  <GroupDetailTypeInfo group={group} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}

            {/* tables */}
            {displayContracts && (
              <>
                <Grid item>
                  <GroupContractsTable readOnly={!isAdmin(internalUser)} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {displayGroupAddresses && (
              <>
                <Grid item>
                  <GroupDetailsAddressesTable onAddressCreated={onAddressCreated} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {!isWasteProducer && (
              <>
                <Grid item>
                  <GroupDetailsUsersTable group={group} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {displayMailsTable && (
              <>
                <Grid item>
                  <GroupDetailsMailsTable groupId={group.id} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {displayManufacturersTable && (
              <>
                <Grid item>
                  <GroupDetailsManufacturersTable group={group} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {displayGroupHistoriesTable && <GroupDetailsGroupHistoriesTable groupId={group.id} />}
            {isWasteProducer && (
              <>
                <Grid item>
                  <GroupDetailsWasteProducerUsersTable group={group} />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
            {isWasteProducer && (
              <>
                <Grid item>
                  <GroupDetailsWasteProducerAddressesTable />
                </Grid>
                <Grid item>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}
          </GroupDetailProvider>
        )}
        <Box className={classes.spacingBottom}></Box>
      </Grid>
    </>
  );
};
