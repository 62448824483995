import lodash from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { GroupTypeFilterValues } from "../../shared/components/group/group-filter/group-type-filter.component";
import { UserConverter } from "../../shared/domain/converter/user.converter";
import { IPaginationMeta } from "../../shared/domain/pagination/pagination-meta";
import { IUser, IUserResult } from "../../shared/domain/user/user";
import { IUsersFilter, useGetUsersWithFiltersQuery } from "../../shared/repositories/queries/user/get-users.query";
import { useUpdateUserQuery } from "../../shared/repositories/queries/user/mutation/update-user.query";
import { useUsersFilterContext } from "./users-filter-provider";
import dayjs from "dayjs";

interface IUsersContextType {
  isLoading: boolean;
  tablePagination: IPaginationMeta;
  setTablePagination: (pagination: IPaginationMeta) => void;
  toggleUserStatus: (user: IUser) => Promise<void>;
  users: IUser[];
  filters?: IUsersFilter;
  setFilters: (filters: IUsersFilter) => void;
  isFilterDirty: boolean;
  resetFilter: () => void;
}

interface IUsersProps {
  children: any;
  groupTypes?: GroupTypeFilterValues[];
}

const UsersContext = createContext<IUsersContextType>({} as IUsersContextType);

export const UsersProvider = (props: IUsersProps) => {
  const value = useUsersProvider(props);
  return <UsersContext.Provider value={value}>{props.children}</UsersContext.Provider>;
};

export const useUsersContext = () => {
  return useContext(UsersContext);
};

const useUsersProvider = (props: IUsersProps): IUsersContextType => {
  const { filters, setFilters } = useUsersFilterContext();
  const [tablePagination, setTablePagination] = useState<IPaginationMeta>({
    numberOfRecords: 0,
    pageSize: 50,
    page: 0,
    numberOfPages: 0,
  });

  const onSuccess = (data: IUserResult) => {
    setTablePagination({ ...tablePagination, numberOfRecords: data?.meta?.numberOfRecords ?? 0 });
  };

  const {
    data: users,
    isLoading: areUsersLoading,
    refetch,
  } = useGetUsersWithFiltersQuery(filters, true, tablePagination, onSuccess);
  const { mutateAsync: updateUser, isLoading: isUpdateUserLoading } = useUpdateUserQuery();

  const isLoading = areUsersLoading || isUpdateUserLoading;

  const toggleUserStatus = async (user: IUser): Promise<void> => {
    const userToSave = UserConverter.toUpdateRequest(user);
    userToSave.active = userToSave.active === null ? dayjs().toDate() : null;

    await updateUser({ userId: user.id, updateData: userToSave });
    await refetch();
  };

  const isFilterDirty = useMemo(
    () => !lodash.isEmpty(filters.active) || !lodash.isEmpty(filters.searchText),
    [filters],
  );

  const resetFilter = () =>
    setFilters({
      active: undefined,
      searchText: undefined,
      groupTypes: props.groupTypes,
    });

  return {
    isLoading,
    tablePagination,
    setTablePagination,
    users: users?.users ?? [],
    filters,
    setFilters,
    isFilterDirty,
    resetFilter,
    toggleUserStatus,
  };
};
