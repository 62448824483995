import { Action, Column, MTableCell, Options } from "@material-table/core";
import { Grid, makeStyles, TablePagination } from "@material-ui/core";
import React, { CSSProperties, FunctionComponent, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { ClearFilterButton } from "../../../components/Buttons/clear-filter-button";
import SearchField from "../../../components/SearchField";
import CustomTable from "../../../components/Tables/custom-table";
import { AddItemTableIcon } from "../../../components/Tables/table-action-icons/add-item-table-icon.component";
import { Shades } from "../../../style/Colors";
import { IPomAnnouncementTableData } from "../announcement-table/pom-announcement-table-data";
import { usePomProductSearchFieldContext } from "./pom-product-search-field.provider";
import { IPomProductTableData } from "./pom-product-table-data";
import { usePomProductTableContext } from "./pom-product-table.provider";
import { PomProductTypeFilter } from "./pom-product-type-filter";

const useStyle = makeStyles((_) => ({
  tablePagination: {
    backgroundColor: Shades.gray30,
    display: "flex",
    flexDirection: "row-reverse",
  },
  toolbarItem: {
    padding: "32px 32px 32px 0px",
  },
}));

export interface IPomProductTable {
  onProductAdded: (product: IPomProductTableData) => void;
}

export const PomProductTable: FunctionComponent<IPomProductTable> = (props) => {
  const { t } = useTranslation();
  const { ref } = usePomProductSearchFieldContext();
  const classes = useStyle();
  const { onProductAdded } = props;
  const {
    products,
    onSearchChanged,
    isLoading,
    itemCount,
    page,
    pageSize,
    setNewPage,
    setNewPageSize,
    isProductDisabled,
    productTypes,
    productTypeFilter,
    setProductTypeFilter,
    onFilterChanged,
  } = usePomProductTableContext();

  const tableOptions: Options<any> = {
    toolbar: false,
    actionsColumnIndex: -1,
    pageSize: pageSize,
    emptyRowsWhenPaging: false,
    headerStyle: {
      backgroundColor: Shades.gray50,
      color: Shades.white,
      fontWeight: "bold",
    },
    rowStyle: (rowData: IPomAnnouncementTableData) => ({
      color: Shades.gray70,
      backgroundColor: isProductDisabled(rowData) ? Shades.gray20 : "",
    }),
    actionsCellStyle: {
      width: "1%",
      paddingRight: "15px",
      borderBottom: "2px solid white",
    },
  };

  const tableActions: ((rowData: object) => Action<object>)[] = [
    (rowData: object) => {
      const product = rowData as IPomProductTableData;
      return {
        icon: () => <AddItemTableIcon isDisabled={isProductDisabled(product)} />,
        disabled: isProductDisabled(product),
        onClick: () => {
          onProductAdded(product);
        },
      };
    },
  ];

  const isFilterDirty = useMemo(
    (): boolean => productTypeFilter !== undefined && productTypeFilter.length > 0,
    [productTypeFilter],
  );

  return (
    <Grid container>
      <Grid direction="row" container>
        <Grid item className={classes.toolbarItem}>
          <PomProductTypeFilter
            typeOptions={productTypes}
            selectedOptions={productTypeFilter ?? []}
            setSelectedOptions={setProductTypeFilter}
            onFilterClosed={onFilterChanged}
          />
        </Grid>
        {isFilterDirty && (
          <Grid item className={classes.toolbarItem}>
            <ClearFilterButton
              onClick={() => {
                setProductTypeFilter(undefined);
              }}
            />
          </Grid>
        )}
        <Grid item className={classes.toolbarItem}>
          <SearchField
            tooltip={t("pom.announcements.product.search")}
            ref={ref}
            onSearchSubmit={onSearchChanged}
            placeholder={t("pom.announcements.product.search")}
          />
        </Grid>
      </Grid>
      <CustomTable
        isLoading={isLoading}
        options={tableOptions}
        columns={getColumns(t)}
        actions={tableActions}
        data={products}
        components={{
          Cell: (props) => (
            <MTableCell
              {...props}
              style={{
                borderBottom: "2px solid white",
              }}
            />
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              className={classes.tablePagination}
              rowsPerPageOptions={[10, 20, 50, 100]}
              rowsPerPage={pageSize}
              count={itemCount}
              page={page}
              onPageChange={(event: any, newPage: number) => {
                props.onPageChange(event, newPage);
                setNewPage(newPage);
              }}
              onRowsPerPageChange={(event: any) => {
                props.onRowsPerPageChange(event);
                setNewPageSize(event.target.value);
              }}
            />
          ),
        }}
      />
    </Grid>
  );
};

const getColumns = (t: TFunction<"translation">): Column<object>[] => {
  const headerStyle: CSSProperties = {
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "start",
  };

  const cellStyle: CSSProperties = {
    textAlign: "start",
  };

  return [
    {
      title: t("pom.announcements.product.table.article_number"),
      field: "articleNumber",
      headerStyle: {
        width: "1%",
        maxWidth: "1%",
      },
      cellStyle: {
        width: "1%",
        maxWidth: "1%",
      },
    },
    {
      title: t("pom.announcements.product.table.take_back_system"),
      field: "takeBackSystem",
      tooltip: t("pom.announcements.product.table.take_back_system_tooltip"),
      headerStyle,
      cellStyle,
    },
    {
      title: t("pom.announcements.product.table.article"),
      field: "articleName",
      headerStyle,
      cellStyle,
    },
    {
      title: t("pom.announcements.product.table.type"),
      field: "type",
      headerStyle: {
        ...headerStyle,
        width: "1%",
        maxWidth: "1%",
      },
      cellStyle: {
        ...cellStyle,
        width: "1%",
        maxWidth: "1%",
      },
    },
    {
      title: t("pom.announcements.product.table.weight_class"),
      field: "weightClass",
      headerStyle,
      cellStyle,
    },
    {
      title: t("pom.announcements.product.table.chemistry"),
      field: "chemistry",
      headerStyle: {
        ...headerStyle,
        width: "1%",
        maxWidth: "1%",
      },
      cellStyle: {
        ...cellStyle,
        width: "1%",
        maxWidth: "1%",
      },
    },
    {
      title: t("pom.announcements.product.table.unit"),
      tooltip: t("pom.announcements.product.table.unit_tooltip"),
      field: "unit",
      headerStyle,
      cellStyle,
    },
  ];
};
