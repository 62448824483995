import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmOrderXStatusModel } from "../../../models/order-x/cfm-order-x.status.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const updateOrderXReadStatus = async (orderId: number): Promise<void> => {
  await getInstance().put(RestEndpoints.CfmUpdateOrderXReadStatus.replace(":id", orderId.toString()));
  return;
};

export const useUpdateOrderXReadStatusQuery = (orderId: number, orderStatus: CfmOrderXStatusModel) => {
  const queryClient = useQueryClient();
  return useMutation([CfmQueryKeys.UpdateOrderXReadStatus, orderId], () => updateOrderXReadStatus(orderId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([CfmQueryKeys.GetOrderXStatusCount, orderStatus]);
    },
  });
};
