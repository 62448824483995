import {
  IPomAnnouncement,
  IPomAnnouncementTableData,
} from "../../../components/announcement-table/pom-announcement-table-data";
import { IPomAnnouncementModel, IPomAnnouncementProductModel } from "../announcements/pom-announcements.model";

export class PomAnnouncementsConverter {
  public static toDomain(data: IPomAnnouncementModel): IPomAnnouncement {
    const convertedObject: IPomAnnouncement = {
      id: data.id,
      announcementDate: new Date(data.announcementDate),
      createdAt: data.createdAt,
      announcementId: data.announcementId,
      announcementPeriod: data.announcementPeriod,
      status: data.status,
      products: this.convertProductModelToAnnouncementProduct(data.products),
      purchaseOrderNumber: data.purchaseOrderNumber,
    };

    return convertedObject;
  }

  public static toPrefilledProducts(data: IPomAnnouncementModel): IPomAnnouncementTableData[] {
    return data.products.map<IPomAnnouncementTableData>((p) => {
      return {
        id: p.productData.id,
        unit: p.productData.unit,
        itemPrice: Number(p.productData.netPrice),
        type: p.productData.type.name,
        weightClass: p.productData.weightClass.name,
        articleNumber: p.productData.articleNumber,
        articleName: p.productData.system.name,
        chemistry: p.productData.chemistry.name,
        takeBackSystem: p.productData.takeBackSystem.name,
        totalPrice: undefined,
        lowerWeightLimitInGram:
          p.productData.lowerWeightLimitInGramOverride ?? p.productData.weightClass.lowerWeightLimitInGram,
        upperWeightLimitInGram:
          p.productData.upperWeightLimitInGramOverride ?? p.productData.weightClass.upperWeightLimitInGram,
      };
    });
  }

  private static convertProductModelToAnnouncementProduct(
    products: IPomAnnouncementProductModel[],
  ): IPomAnnouncementTableData[] {
    return products.map<IPomAnnouncementTableData>((p) => {
      return {
        id: p.productData.id,
        unit: p.productData.unit,
        totalWeight: Number(p.weight),
        itemPrice: Number(p.productData.netPrice),
        count: Number(p.count),
        type: p.productData.type.name,
        weightClass: p.productData.weightClass.name,
        articleNumber: p.productData.articleNumber,
        articleName: p.productData.system.name,
        totalPrice: this.getTotalPriceOfProduct(p),
        history: this.convertProductModelToAnnouncementProduct(p.history),
        chemistry: p.productData.chemistry.name,
        takeBackSystem: p.productData.takeBackSystem.name,
        createdByAdminName: p.createdByAdminName,
        createdByUserName: p.createdByUserName,
        createdAt: new Date(p.createdAt),
        lowerWeightLimitInGram:
          p.productData.lowerWeightLimitInGramOverride ?? p.productData.weightClass.lowerWeightLimitInGram,
        upperWeightLimitInGram:
          p.productData.upperWeightLimitInGramOverride ?? p.productData.weightClass.upperWeightLimitInGram,
      };
    });
  }

  private static getTotalPriceOfProduct(product: IPomAnnouncementProductModel): number {
    if (product.productData.unit === "kg") {
      return product.weight * product.productData.netPrice;
    } else {
      return product.count * product.productData.netPrice;
    }
  }
}
