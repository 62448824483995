import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import config from "./config/config";

const reactPlugin: ReactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights | null = null;

export const telemetryService = () => {
  const initialize = () => {
    const browserHistory = createBrowserHistory({ basename: "" });
    if (config.applicationInsightsConnectionString) {
      appInsights = new ApplicationInsights({
        config: {
          connectionString: config.applicationInsightsConnectionString,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
          autoTrackPageVisitTime: true,
          enableAutoRouteTracking: true,
        },
      });
      appInsights.loadAppInsights();
    }
  };
  return { appInsights, initialize };
};

export const getReactPlugin = () => reactPlugin;
export const getAppInsights = () => appInsights;
