import { Box, Button, makeStyles } from "@material-ui/core";
import { PlayCircleOutline } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Colors } from "../../../style/Colors";

interface IPomAnnouncementDatePickerHeader {
  startAtDate: Date;
  onDateChanged: (date: Date) => void;
}

const useStyles = makeStyles({
  datePickerTitle: {
    color: Colors.white,
    margin: 0,
    fontWeight: "bold",
    lineHeight: 1,
  },
  datePickerButton: {
    alignItems: "start",
    padding: 0,
    height: "24px",
  },
  end: {
    justifyContent: "end",
  },
  start: {
    justifyContent: "start",
  },
  rightArrowIcon: {
    color: Colors.white,
  },
  leftArrowIcon: {
    color: Colors.white,
    transform: "rotate(180deg)",
  },
});

export const PomAnnouncementDatePickerHeader = (props: IPomAnnouncementDatePickerHeader) => {
  const { startAtDate, onDateChanged } = props;
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(startAtDate));

  useEffect(() => {
    setSelectedDate(new Date(startAtDate));
  }, [startAtDate]);

  const handleNextYearButtonClicked = (): void => {
    const newDate = new Date(selectedDate.setFullYear(selectedDate.getFullYear() + 1));
    setSelectedDate(newDate);
    onDateChanged(newDate);
  };

  const handlePrevYearButtonClicked = (): void => {
    const newDate = new Date(selectedDate.setFullYear(selectedDate.getFullYear() - 1));
    setSelectedDate(newDate);
    onDateChanged(newDate);
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Button className={[classes.datePickerButton, classes.start].join(" ")} onClick={handlePrevYearButtonClicked}>
        <PlayCircleOutline className={classes.leftArrowIcon} />
      </Button>
      <Box display={"flex"} flexDirection={"column"} textAlign={"center"} alignSelf={"center"}>
        <h5 className={classes.datePickerTitle}>{selectedDate.getFullYear()}</h5>
      </Box>
      <Button className={[classes.datePickerButton, classes.end].join(" ")} onClick={handleNextYearButtonClicked}>
        <PlayCircleOutline className={classes.rightArrowIcon} />
      </Button>
    </Box>
  );
};
