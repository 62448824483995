import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { IOrderNewWizardFormInputs } from "../../../../pages/order-new/components/order-new-wizard.component";
import { ICfmOrderXModel } from "../../order-x/cfm-order-x.model";
import { ICfmCreateOrderXModel } from "../../order-x/create/cfm-create-order-x.model";
import { CfmPackageConverter } from "../cfm-package.converter";
import { CfmItemXConverter } from "../item-x/cfm-item-x.converter";
import { CfmOrderXStatusModel } from "./../../order-x/cfm-order-x.status.model";
import { CfmOrderXStatusConverter } from "./cfm-order-x-status.converter";

export class CfmOrderXConverter {
  public static toCreateOrderModel(inputs: IOrderNewWizardFormInputs, productId: number): ICfmCreateOrderXModel[] {
    return inputs.productOrders.map((p) => {
      const productPackage = p.package;

      return {
        customerAddressId: inputs.customerAddressId,
        requestedPickupDate: p.requestedPickupDate ?? new Date(),
        productId,
        // only singleOrderProducts can have a quantity > 1, normal products always have quantity 1
        quantity: inputs.singleOrderQuantity ? Number(inputs.singleOrderQuantity) : 1,
        grossWeightKg: p.productGrossWeight ? Number(p.productGrossWeight) : null,
        netWeightKg: p.productNetWeight ? Number(p.productNetWeight) : null,
        purchaseOrderNumber: p.orderNumber,
        contactPersonName: inputs.contactPersonName,
        contactPersonTelephone: inputs.contactPersonPhone,
        contactPersonEmail: inputs.contactPersonEmail,
        openingHours: inputs.openingHours,
        orderComment: p.orderComment,
        package: productPackage ? CfmPackageConverter.createPackageToCreateXPackageModel(productPackage) : undefined,
      };
    });
  }

  public static toDomain(model: ICfmOrderXModel): ICfmOrderX {
    return {
      routingPostal: model.routingPostal,
      actualDeliveryDate: model.actualDeliveryDate,
      truckloadId: model.truckloadId,
      actualPickupDate: model.actualPickupDate,
      canceledDate: model.canceledDate,
      confirmedDate: model.confirmedDate,
      contactPersonEmail: model.contactPersonEmail,
      contactPersonName: model.contactPersonName,
      contactPersonTelephone: model.contactPersonTelephone,
      customerAddress: model.customerAddress,
      destinationAddress: model.destinationAddress,
      destinationArrivedDate: model.destinationArrivedDate,
      destinationReceivedDate: model.destinationReceivedDate,
      id: model.id,
      items: CfmItemXConverter.toDomainList(model.items),
      logisticAcceptedDate: model.logisticAcceptedDate,
      logisticAddress: model.logisticAddress,
      logisticAnnouncedDate: model.logisticAnnouncedDate,
      logisticPerformedDate: model.logisticPerformedDate,
      logisticTruckLoadAnnouncedDate: model.logisticTruckLoadAnnouncedDate,
      logisticTruckLoadPerformedDate: model.logisticTruckLoadPerformedDate,
      logisticWarehouseDate: model.logisticWarehouseDate,
      openingHours: model.openingHours,
      orderComment: model.orderComment,
      orderDate: model.orderDate,
      plannedDeliveryDate: model.plannedDeliveryDate,
      plannedPickupDate: model.plannedPickupDate,
      purchaseOrderNumber: model.purchaseOrderNumber,
      isNew: model.isNew,
      recyclerIncomingWarehouseDate: model.recyclerIncomingWarehouseDate,
      recyclerOutgoingWarehouseDate: model.recyclerOutgoingWarehouseDate,
      requestedDeliveryDate: model.requestedDeliveryDate,
      requestedPickupDate: model.requestedPickupDate,
      serviceDeliveryDate: model.serviceDeliveryDate,
      servicePickupDate: model.servicePickupDate,
      serviceRecyclingDate: model.serviceRecyclingDate,
      serviceTreatmentDate: model.serviceTreatmentDate,
      shoppingCartDate: model.shoppingCartDate,
      status: CfmOrderXStatusConverter.toDomain(model.status),
      warehouseWeight: model.warehouseWeight,
    };
  }

  public static toWizardInputs(order: ICfmOrderX): IOrderNewWizardFormInputs | undefined {
    if (!order.customerAddress?.id) {
      // order needs relations for wizard
      return undefined;
    }

    const productItem = (order.items ?? []).find((item) => item.product);

    const packages = CfmPackageConverter.toCreatePackageXList(order.items?.filter((item) => item.package)) ?? [];

    return {
      orderId: order.id,
      customerGroupId: order.customerAddress.groupId,
      customerAddressId: order.customerAddress?.id,
      contactPersonName: order.contactPersonName ?? "",
      contactPersonEmail: order.contactPersonEmail ?? "",
      contactPersonPhone: order.contactPersonTelephone ?? "",
      openingHours: order.openingHours ?? "",
      productId: productItem?.product?.id,
      productOrders: [
        {
          productGrossWeight: productItem?.targetGrossWeightKg ?? undefined,
          productNetWeight: productItem?.targetNetWeightKg ?? undefined,
          package: packages[0],
          requestedPickupDate: order.requestedPickupDate ?? undefined,
          orderNumber: order.purchaseOrderNumber ?? "",
          orderComment: order.orderComment ?? "",
        },
      ],
      orderNow: undefined,
      singleOrderQuantity: undefined,
    };
  }

  public static statusToModel(domain: CfmOrderXApiStatus): CfmOrderXStatusModel {
    switch (domain) {
      case CfmOrderXApiStatus.ShoppingCart:
        return CfmOrderXStatusModel.ShoppingCart;
      case CfmOrderXApiStatus.Ordered:
        return CfmOrderXStatusModel.Ordered;
      case CfmOrderXApiStatus.LogisticAccepted:
        return CfmOrderXStatusModel.LogisticAccepted;
      case CfmOrderXApiStatus.LogisticAnnounced:
        return CfmOrderXStatusModel.LogisticAnnounced;
      case CfmOrderXApiStatus.LogisticPerformed:
        return CfmOrderXStatusModel.LogisticPerformed;
      case CfmOrderXApiStatus.LogisticWarehouse:
        return CfmOrderXStatusModel.LogisticWarehouse;
      case CfmOrderXApiStatus.LogisticTruckLoadAnnounced:
        return CfmOrderXStatusModel.LogisticTruckLoadAnnounced;
      case CfmOrderXApiStatus.RecyclerIncomingWarehouse:
        return CfmOrderXStatusModel.RecyclerIncomingWarehouse;
      case CfmOrderXApiStatus.RecyclerOutgoingWarehouse:
        return CfmOrderXStatusModel.RecyclerOutgoingWarehouse;
      case CfmOrderXApiStatus.DestinationArrived:
        return CfmOrderXStatusModel.DestinationArrived;
      case CfmOrderXApiStatus.DestinationReceived:
        return CfmOrderXStatusModel.DestinationReceived;
      case CfmOrderXApiStatus.Confirmed:
        return CfmOrderXStatusModel.Confirmed;
      case CfmOrderXApiStatus.Canceled:
        return CfmOrderXStatusModel.Canceled;
      case CfmOrderXApiStatus.Unsupported:
        return CfmOrderXStatusModel.Unsupported;
    }
  }

  public static statusToDomain(model: CfmOrderXStatusModel): CfmOrderXApiStatus {
    switch (model) {
      case CfmOrderXStatusModel.ShoppingCart:
        return CfmOrderXApiStatus.ShoppingCart;
      case CfmOrderXStatusModel.Ordered:
        return CfmOrderXApiStatus.Ordered;
      case CfmOrderXStatusModel.LogisticAccepted:
        return CfmOrderXApiStatus.LogisticAccepted;
      case CfmOrderXStatusModel.LogisticAnnounced:
        return CfmOrderXApiStatus.LogisticAnnounced;
      case CfmOrderXStatusModel.LogisticPerformed:
        return CfmOrderXApiStatus.LogisticPerformed;
      case CfmOrderXStatusModel.LogisticWarehouse:
        return CfmOrderXApiStatus.LogisticWarehouse;
      case CfmOrderXStatusModel.LogisticTruckLoadAnnounced:
        return CfmOrderXApiStatus.LogisticTruckLoadAnnounced;
      case CfmOrderXStatusModel.RecyclerIncomingWarehouse:
        return CfmOrderXApiStatus.RecyclerIncomingWarehouse;
      case CfmOrderXStatusModel.RecyclerOutgoingWarehouse:
        return CfmOrderXApiStatus.RecyclerOutgoingWarehouse;
      case CfmOrderXStatusModel.DestinationArrived:
        return CfmOrderXApiStatus.DestinationArrived;
      case CfmOrderXStatusModel.DestinationReceived:
        return CfmOrderXApiStatus.DestinationReceived;
      case CfmOrderXStatusModel.Confirmed:
        return CfmOrderXApiStatus.Confirmed;
      case CfmOrderXStatusModel.Canceled:
        return CfmOrderXApiStatus.Canceled;
      case CfmOrderXStatusModel.Unsupported:
        return CfmOrderXApiStatus.Unsupported;
    }
  }
}
