import { Box, makeStyles, Typography } from "@material-ui/core";
import { VFC } from "react";
import { ICfmOrderXHistoryModel } from "../../../../repositories/models/order-x/cfm-order-x-history.model";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Shades } from "../../../../../style/Colors";
import { formatDateLong } from "../../../../../shared/util/date.util";

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: "bold",
  },
  oldValue: {
    textDecoration: "line-through",
  },
  detailText: {
    color: Shades.gray50,
  },
}));

interface IOrderHistorySubColumnProps {
  entry: ICfmOrderXHistoryModel;
}

export const OrderXHistoryRow: VFC<IOrderHistorySubColumnProps> = (props) => {
  const { t } = useTranslation();
  const { entry } = props;
  const classes = useStyles();
  const isDateValue = entry.columnName.includes("Date");

  const getHistoryValue = (value: string | null): string => {
    if (value === undefined || value === null) {
      return "";
    }

    if (isDateValue && dayjs(value).isValid()) {
      return formatDateLong(new Date(value));
    }

    return value;
  };

  const getChangedBy = (): string => {
    if (entry.actorEdit) {
      return t("order.history.changed_by.adminEdit", {
        admin: entry.actorEdit,
        user: entry.changedBy,
      });
    }

    return t("order.history.changed_by.text", {
      user: entry.changedBy,
    });
  };

  const getColumnName = (): string => {
    return t(`order.column_name.${entry.columnName}`, { defaultValue: entry.columnName });
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" className={classes.heading}>
          {getColumnName()}
        </Typography>
        <Typography variant="body1" className={classes.heading}>
          {getHistoryValue(entry.newValue)}
        </Typography>
        <Typography variant="body2" className={classes.oldValue}>
          {getHistoryValue(entry.originalValue)}
        </Typography>
        <Typography variant="body2" className={classes.detailText}>
          {getChangedBy()}
        </Typography>
        <Typography variant="body2" className={classes.detailText}>
          {entry.updateComment}
        </Typography>
        <Typography variant="body2" className={classes.detailText}>
          {formatDateLong(new Date(entry.createdAt))}
        </Typography>
      </Box>
    </Box>
  );
};
