import useAsyncEffect from "hooks/use-async-effect";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { createContext, FunctionComponent, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnsavedDataContext } from "../../../providers/App/unsaved-data-context";
import { useDashboardActionContext } from "../../../shared/pages/dashboard/dashboard-action.provider";
import { isNullValue } from "../../../utils/validationHelper";
import {
  IPomAnnouncement,
  IPomAnnouncementTableData,
} from "../../components/announcement-table/pom-announcement-table-data";
import { PomAnnouncementTableDataConverter } from "../../components/announcement-table/pom-announcement-table-data.converter";
import { IPomProductTableData } from "../../components/product-table/pom-product-table-data";
import { IPomAnnouncementExcelDataModel } from "../../repositories/models/announcements/pom-announcement-excel-data.model";
import { PomAnnouncementStatusModel } from "../../repositories/models/announcements/pom-announcements.model";
import { IPomPatchAnnouncementModel } from "../../repositories/models/announcements/pom-patch-announcement.model";
import { PomAnnouncementsConverter } from "../../repositories/models/converter/pom-announcements.converter";
import { useExportAnnouncementQuery } from "../../repositories/queries/announcements/export-announcement.query";
import { getAnnouncement } from "../../repositories/queries/announcements/get-announcement.query";
import { useGetLatestAnnouncementQuery } from "../../repositories/queries/announcements/get-latest-announcement.query";
import { useCreateAnnouncementQuery } from "../../repositories/queries/mutations/announcements/create-announcement.query";
import { usePatchAnnouncementQuery } from "../../repositories/queries/mutations/announcements/patch-announcement.query";
import { AnnouncementUtil } from "../../utils/announcement.util";
import { IPomAnnouncementValidationError } from "./pom-announcement-validation-error";

type PomAnnouncementSubscription = (announcement: IPomAnnouncement) => void;

type ProductId = number;

interface IPomAnnouncementProvider {
  curAnnouncementDate: Date | undefined;
  curAnnouncementStatus: PomAnnouncementStatusModel | undefined;
  products?: IPomAnnouncementTableData[];
  changedProducts?: IPomAnnouncementTableData[];
  expandedProductIds: number[];
  announcementDate?: Date;
  announcementCreatedAt?: Date;
  announcementId?: number;
  purchaseOrderNumber: string;
  onProductAdded: (product: IPomProductTableData) => void;
  onProductChanged: (product: IPomAnnouncementTableData) => void;
  onProductRemoved: (product: IPomAnnouncementTableData) => void;
  onToggleHistory: (product: IPomAnnouncementTableData) => void;
  onCreateAnnouncement: (isDraft: boolean) => Promise<void>;
  onPatchAnnouncement: (isDraft: boolean) => Promise<void>;
  onExportAnnouncement: () => Promise<void>;
  onWeightChange: (product: IPomAnnouncementTableData, weight: string) => void;
  onCountChange: (product: IPomAnnouncementTableData, count: string) => void;
  setPurchaseOrderNumber: (purchaseOrderNumber: string) => void;
  handleAnnouncementDateChanged: (date: Date) => void;
  isLoading: boolean;
  validateAnnouncement: (showErrorInSnackbar?: boolean) => boolean;
  showValidationErrors: boolean;
  validationErrors: Set<IPomAnnouncementValidationError>;
  validationWarnings: Set<ProductId>;
  subscribeToAnnouncementCreated: (subscriber: PomAnnouncementSubscription) => void;
  prefillChangedProducts: (data: IPomAnnouncementExcelDataModel) => void;
  latestAnnouncementDate: Date | undefined;
}

export const PomAnnouncementContext = createContext<IPomAnnouncementProvider>({} as IPomAnnouncementProvider);

export const usePomAnnouncementContext = () => {
  return useContext(PomAnnouncementContext);
};

const usePomAnnouncementProvider = (): IPomAnnouncementProvider => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [expandedProductIds, setExpandedProductIds] = useState<number[]>([]);
  const [changedProducts, setChangedProducts] = useState<IPomAnnouncementTableData[]>([]);
  const [announcement, setAnnouncement] = useState<IPomAnnouncement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Set<IPomAnnouncementValidationError>>(new Set());
  const [validationWarnings, setValidationWarnings] = useState<Set<ProductId>>(new Set());
  const [curAnnouncementDate, setCurrentAnnouncementDate] = useState<Date | undefined>();
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");
  const createdAnnouncementSubscriptions = useRef<PomAnnouncementSubscription[]>([]);
  const { contractId } = useDashboardActionContext();
  const [latestAnnouncementDate, setLatestAnnouncementDate] = useState<Date | undefined>(undefined);
  const { setHasUnsavedData } = useUnsavedDataContext();

  const { data: latestAnnouncementResult, refetch: getLatestAnnouncement } = useGetLatestAnnouncementQuery(
    contractId,
    false,
  );
  const { mutateAsync: patchAnnouncement } = usePatchAnnouncementQuery();
  const { mutateAsync: createAnnouncement } = useCreateAnnouncementQuery();
  const { refetch: exportAnnouncement, data: exportedAnnouncement } = useExportAnnouncementQuery(
    curAnnouncementDate,
    contractId,
    false,
  );

  useAsyncEffect(
    async (isActive) => {
      // Use existing purchase order number.
      if (announcement?.status === PomAnnouncementStatusModel.Published) {
        return setPurchaseOrderNumber(announcement.purchaseOrderNumber);
      }

      if (!contractId) return;
      // Fetch latest announcement and use its purchase order number if it exists.
      const reloadedLatestAnnouncementApiResult = await getLatestAnnouncement();
      const reloadedLatestAnnouncementResult = reloadedLatestAnnouncementApiResult.data;
      if (!reloadedLatestAnnouncementResult?.announcement) {
        // 404 is expected when there is no announcement.
        if (reloadedLatestAnnouncementResult?.status === 404) {
          if (isActive()) setPurchaseOrderNumber("");
        } else {
          enqueueSnackbar(
            t("general.error_occurred", {
              errorCode: latestAnnouncementResult?.status,
              errorMsg: "An error occurred loading latest announcement",
            }),
            { variant: "error" },
          );
        }
        setIsLoading(false);
        return;
      }

      if (isActive()) {
        setLatestAnnouncementDate(reloadedLatestAnnouncementResult.announcement.announcementDate);
        setPurchaseOrderNumber(reloadedLatestAnnouncementResult.announcement.purchaseOrderNumber);
      }
    },
    [announcement, setPurchaseOrderNumber, contractId],
  );

  const onProductAdded = (product: IPomProductTableData): void => {
    const announcementProduct = PomAnnouncementTableDataConverter.toDomain(product);

    // Reset Validation if new items are added
    setValidationErrors(new Set());
    setShowValidationErrors(false);
    setHasUnsavedData(true);

    setChangedProducts([...changedProducts, announcementProduct]);
  };

  const getLatestAnnouncementDate = async (): Promise<Date | undefined> => {
    if (!contractId) return undefined;

    await getLatestAnnouncement();
    if (!latestAnnouncementResult?.announcement) {
      return undefined;
    } else {
      return latestAnnouncementResult?.announcement?.announcementDate;
    }
  };

  const onProductChanged = (product: IPomAnnouncementTableData): void => {
    if (!changedProducts.includes(product)) {
      setHasUnsavedData(true);
      setChangedProducts([...changedProducts, cloneDeep(product)]);
    }
  };

  const onProductRemoved = (product: IPomAnnouncementTableData): void => {
    const index = changedProducts.findIndex((p) => p.id === product.id);

    if (index === undefined) {
      return;
    }

    const newProducts = [...changedProducts];
    newProducts.splice(index, 1);
    setChangedProducts([...newProducts]);

    // If it is a draft, also remove the item from the announcement
    if (announcement?.status === PomAnnouncementStatusModel.Draft) {
      announcement.products = announcement.products.filter(
        (announcementProduct) => announcementProduct.id !== product.id,
      );
    }
  };

  const validateAnnouncement = (showErrorInSnackbar?: boolean): boolean => {
    const errors = new Set<IPomAnnouncementValidationError>();
    const warnings = new Set<ProductId>();
    let hasInvalidInputs = false;
    let hasInvalidReduction = false;
    let hasInvalidInputCombination = false;
    let hasUnknownProduct = false;

    if (showErrorInSnackbar) {
      setShowValidationErrors(true);
    }

    for (const product of changedProducts) {
      if (product.isInvalid) {
        hasUnknownProduct ||= true;
        continue;
      }

      if (AnnouncementUtil.isAnnouncementTableDataWeightInvalid(product)) {
        warnings.add(product.id);
      }

      const error: IPomAnnouncementValidationError = { productId: product.id };

      if (isNullValue(product.count) || isNullValue(product.totalWeight)) {
        error.countError ||= isNullValue(product.count);
        error.weightError ||= isNullValue(product.totalWeight);
        hasInvalidInputs ||= true;
      }

      // check if announcement is allowed to be reduced (BAT-605)
      if (!AnnouncementUtil.isAnnouncementDecreaseAllowedForYear(announcement)) {
        const existingProduct = announcement?.products.find((p) => p.id === product.id);
        const productCount = product.count ?? 0;
        const productWeight = product.totalWeight ?? 0;
        const existingProductCount = existingProduct?.count ?? 0;
        const existingProductWeight = existingProduct?.totalWeight ?? 0;

        if (existingProduct && (productCount < existingProductCount || productWeight < existingProductWeight)) {
          error.countError ||= productCount < existingProductCount;
          error.weightError ||= productWeight < existingProductWeight;
          hasInvalidReduction ||= true;
        }
      }

      if (AnnouncementUtil.isAnnouncementTableDataInvalid(product)) {
        error.countError ||= true;
        error.weightError ||= true;
        hasInvalidInputCombination = true;
      }

      if (error.countError || error.weightError) {
        errors.add(error);
      }
    }

    if (hasInvalidInputs && showErrorInSnackbar) {
      enqueueSnackbar(t("pom.announcements.create.error.invalid_input"), { variant: "error" });
    }

    if (hasInvalidReduction && showErrorInSnackbar) {
      enqueueSnackbar(t("pom.announcements.invalid_reduction_error"), { variant: "error" });
    }

    if (hasInvalidInputCombination && showErrorInSnackbar) {
      enqueueSnackbar(t("pom.announcements.invalid_input_combination"), { variant: "error" });
    }

    if (hasUnknownProduct && showErrorInSnackbar) {
      enqueueSnackbar(t("pom.announcements.unknown_product"), { variant: "error" });
    }

    if (warnings.size > 0) {
      enqueueSnackbar(t("pom.announcements.invalid_weight"), {
        variant: "warning",
        preventDuplicate: true,
      });
    }

    setValidationErrors(errors);
    setValidationWarnings(warnings);
    return errors.size === 0 && !hasUnknownProduct;
  };

  const onCreateAnnouncement = async (isDraft: boolean): Promise<void> => {
    const isValid = validateAnnouncement(true);
    setShowValidationErrors(true);
    if (!isValid || !curAnnouncementDate || !contractId) {
      return;
    }

    setIsLoading(true);
    const request = PomAnnouncementTableDataConverter.toAddModel(
      curAnnouncementDate,
      changedProducts,
      isDraft,
      purchaseOrderNumber,
      contractId,
    );
    const result = await createAnnouncement({ request });

    if (result.announcement) {
      const newAnnouncement = PomAnnouncementsConverter.toDomain(result.announcement);
      setHasUnsavedData(false);
      setAnnouncement(newAnnouncement);

      if (newAnnouncement.status === PomAnnouncementStatusModel.Draft) {
        setChangedProducts(newAnnouncement.products);
      } else {
        setChangedProducts([]);
      }

      createdAnnouncementSubscriptions.current.forEach((notify) => notify(newAnnouncement));
      enqueueSnackbar(t(isDraft ? "pom.announcements.create_draft.success" : "pom.announcements.create.success"), {
        variant: "success",
      });
    } else {
      if (result.status === 403) {
        enqueueSnackbar(t("pom.announcements.create.error.insufficient_contract"), { variant: "error" });
      } else {
        enqueueSnackbar(
          t(isDraft ? "pom.announcements.create_draft.error" : "pom.announcements.create.error.undefined"),
          {
            variant: "error",
          },
        );
      }
    }
    setIsLoading(false);
  };

  const onPatchAnnouncement = async (isDraft: boolean): Promise<void> => {
    const prePatchStatus = announcement?.status;
    const isValid = validateAnnouncement(true);
    setShowValidationErrors(true);
    if (!isValid || !curAnnouncementDate || !contractId) {
      return;
    }

    let request: IPomPatchAnnouncementModel;
    if (isDraft) {
      request = PomAnnouncementTableDataConverter.toPatchDraftModel(changedProducts, purchaseOrderNumber, contractId);
    } else {
      request = PomAnnouncementTableDataConverter.toPatchPublishedModel(
        announcement?.announcementId,
        changedProducts,
        purchaseOrderNumber,
        contractId,
      );
    }

    setIsLoading(true);
    const result = await patchAnnouncement({ date: curAnnouncementDate, request });
    if (result.announcement) {
      const updatedAnnouncement = PomAnnouncementsConverter.toDomain(result.announcement);
      setHasUnsavedData(false);
      setAnnouncement(updatedAnnouncement);

      if (updatedAnnouncement.status === PomAnnouncementStatusModel.Draft) {
        setChangedProducts(updatedAnnouncement.products);
      } else {
        setChangedProducts([]);
      }

      // If the status transitioned to published, notify subscribers
      if (
        prePatchStatus === PomAnnouncementStatusModel.Draft &&
        updatedAnnouncement.status === PomAnnouncementStatusModel.Published
      ) {
        createdAnnouncementSubscriptions.current.forEach((notify) => notify(updatedAnnouncement));
      }

      enqueueSnackbar(t(isDraft ? "pom.announcements.create_draft.success" : "pom.announcements.create.success"), {
        variant: "success",
      });
      setIsLoading(false);
    } else {
      if (result.status === 409) {
        enqueueSnackbar(t("pom.announcements.patch.error_conflict"), { variant: "error" });
      } else {
        enqueueSnackbar(
          t(isDraft ? "pom.announcements.create_draft.error" : "pom.announcements.create.error.undefined"),
          {
            variant: "error",
          },
        );
      }
    }
    setIsLoading(false);
  };

  const onExportAnnouncement = async (): Promise<void> => {
    if (!curAnnouncementDate || !contractId) {
      return;
    }

    await exportAnnouncement();
    if (!exportedAnnouncement) {
      enqueueSnackbar(t("pom.announcements.export.error"), { variant: "error" });
      return;
    }
    const filename = `${moment(curAnnouncementDate).format("MM")}_${announcement?.announcementId ?? "draft"}.csv`;
    const url = window.URL.createObjectURL(new Blob(["\ufeff", exportedAnnouncement]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const onWeightChange = (product: IPomAnnouncementTableData, weight: string) => {
    const productToChange = changedProducts?.find((p) => p.id === product.id);

    if (!productToChange) {
      return;
    }
    let newWeight = Number(weight);
    setHasUnsavedData(true);

    if (isNaN(newWeight)) {
      newWeight = 0.0;
    }

    productToChange.totalWeight = newWeight;
    if (productToChange.unit === "kg") {
      productToChange.totalPrice = newWeight * productToChange.itemPrice;
    }
    setChangedProducts([...changedProducts]);
    validateAnnouncement();
  };

  const onCountChange = (product: IPomAnnouncementTableData, count: string) => {
    const productToChange = changedProducts.find((p) => p.id === product.id);

    if (!productToChange) {
      return;
    }

    let newCount = Number(parseFloat(count).toFixed(2));
    setHasUnsavedData(true);

    if (isNaN(newCount)) {
      newCount = 0;
    }

    productToChange.count = newCount;
    if (productToChange.unit === "STK") {
      productToChange.totalPrice = newCount * productToChange.itemPrice;
    }

    setChangedProducts([...changedProducts]);
    validateAnnouncement();
  };

  const onToggleHistory = (product: IPomAnnouncementTableData): void => {
    const newExpandedProductIds = [...expandedProductIds];

    if (newExpandedProductIds.includes(product.id)) {
      const index = newExpandedProductIds.findIndex((p) => p === product.id);
      newExpandedProductIds.splice(index, 1);
    } else {
      newExpandedProductIds.push(product.id);
    }

    setExpandedProductIds([...newExpandedProductIds]);
  };

  const handleAnnouncementDateChanged = async (date: Date): Promise<void> => {
    if (!contractId) return;
    setCurrentAnnouncementDate(date);
    setIsLoading(true);

    let changedProducts: IPomAnnouncementTableData[] = [];
    try {
      const result = await getAnnouncement(date, contractId);
      const announcement = PomAnnouncementsConverter.toDomain(result);
      setAnnouncement(announcement);

      if (announcement.status === PomAnnouncementStatusModel.Draft) {
        changedProducts = announcement.products;
      }
    } catch (error) {
      const latestDate = await getLatestAnnouncementDate();
      if (latestDate) {
        changedProducts = await fetchProductsOfLatestAnnouncement(latestDate);
      }
      setAnnouncement(null);
    }

    resetValidation();
    setChangedProducts(changedProducts);
    setIsLoading(false);
  };

  const subscribeToAnnouncementCreated = (subscriber: PomAnnouncementSubscription): void => {
    createdAnnouncementSubscriptions.current.push(subscriber);
  };

  const fetchProductsOfLatestAnnouncement = async (date: Date): Promise<IPomAnnouncementTableData[]> => {
    if (!contractId) return [];
    try {
      const result = await getAnnouncement(date, contractId);
      return PomAnnouncementsConverter.toPrefilledProducts(result);
    } catch (error) {
      return [];
    }
  };

  const prefillChangedProducts = (data: IPomAnnouncementExcelDataModel): void => {
    // if announcement already exists (only draft possible), we need to clear all existing products,
    // because both the products and the changed products are displayed in the table
    const clearedAnnouncement = announcement;
    if (clearedAnnouncement) {
      clearedAnnouncement.products = [];
      setAnnouncement(clearedAnnouncement);
    }
    resetValidation();
    setHasUnsavedData(true);

    const prefillData: IPomAnnouncementTableData[] = data.items.map(
      PomAnnouncementTableDataConverter.fromExcelDataModel,
    );

    const unknownData: IPomAnnouncementTableData[] = data.unknownItems.map(
      PomAnnouncementTableDataConverter.fromInvalidExcelDataModel,
    );

    setChangedProducts([...unknownData, ...prefillData]);
  };

  const resetValidation = (): void => {
    setExpandedProductIds([]);
    setValidationErrors(new Set());
    setValidationWarnings(new Set());
    setShowValidationErrors(false);
  };

  return {
    products: announcement?.products,
    changedProducts,
    expandedProductIds,
    isLoading,
    validationErrors,
    validationWarnings,
    showValidationErrors,
    announcementDate: announcement?.announcementDate,
    announcementCreatedAt: announcement?.createdAt,
    announcementId: announcement?.announcementId,
    curAnnouncementDate,
    curAnnouncementStatus: announcement?.status,
    purchaseOrderNumber,
    setPurchaseOrderNumber,
    handleAnnouncementDateChanged,
    validateAnnouncement,
    onCreateAnnouncement,
    onPatchAnnouncement,
    onExportAnnouncement,
    onProductAdded,
    onProductChanged,
    onProductRemoved,
    onCountChange,
    onWeightChange,
    onToggleHistory,
    subscribeToAnnouncementCreated,
    prefillChangedProducts,
    latestAnnouncementDate,
  };
};

export const PomAnnouncementProvider: FunctionComponent = (props) => {
  const value = usePomAnnouncementProvider();
  return <PomAnnouncementContext.Provider value={value}>{props.children}</PomAnnouncementContext.Provider>;
};
