import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

interface IPasswordResetHeadProps {
  title: string;
}

export const PasswordResetHead: FC<IPasswordResetHeadProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
