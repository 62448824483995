import { useSnackbar } from "notistack";
import React, { createContext, useContext, useState } from "react";
import { DeepPartial } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ICreateAddressResult } from "../../../domain/address/address";
import { IAddressLean } from "../../../domain/address/address-lean";
import { AddressLeanConverter } from "../../../domain/converter/address-lean.converter";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { AddressFormMode, IAddressFormInputs } from "./address-form.component";
import { useCreateAddressQuery } from "../../../repositories/queries/address/create-address.query";
import { useUpdateAddressQuery } from "../../../repositories/queries/address/update-address.query";

interface IAddressFormContext {
  isLoading: boolean;
  address?: DeepPartial<IAddressLean> | undefined;
  handleFormSubmit: (
    inputs: IAddressFormInputs,
    mode: AddressFormMode,
    shouldOpenDetail?: boolean | undefined,
  ) => Promise<ICreateAddressResult | undefined>;
}

export const AddressFormContext = createContext<IAddressFormContext>({} as IAddressFormContext);

export const useAddressFormContext = () => {
  return useContext(AddressFormContext);
};

const useAddressFormProvider = ({
  address,
  shouldPersistAddress = true,
  onSuccessCallback,
  groupId,
}: IAddressFormProps): IAddressFormContext => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { mutateAsync: createAddress } = useCreateAddressQuery();
  const { mutateAsync: updateAddress } = useUpdateAddressQuery();

  const handleFormSubmit = async (
    inputs: IAddressFormInputs,
    mode: AddressFormMode,
  ): Promise<ICreateAddressResult | undefined> => {
    setIsLoading(true);
    let result: ICreateAddressResult | undefined = undefined;
    // There are use cases where the address should not be persisted in the backend, just filled for another form.
    // In this case, just the address should be returned.
    if (shouldPersistAddress === false) {
      if (!groupId) {
        return;
      }
      const leanAddress = AddressLeanConverter.formInputsToDomain(inputs, groupId);
      const addressResult = { address: leanAddress, contactPersonEmailExists: false };
      onSuccessCallback?.(addressResult);
      return;
    }

    try {
      if (mode === AddressFormMode.Create) {
        result = await handleCreate(inputs);
      }
      if (mode === AddressFormMode.Edit) {
        const address = await handleEdit(inputs);
        result = address ? { address, contactPersonEmailExists: false } : undefined;
      }
      if (result && onSuccessCallback) {
        onSuccessCallback(result);
      }
    } catch (error) {
      enqueueSnackbar(t("basedata.general.address.create_error"), { variant: "error" });
    }

    setIsLoading(false);
    return result;
  };

  const handleCreate = async (inputs: IAddressFormInputs): Promise<ICreateAddressResult | undefined> => {
    if (!groupId) {
      return;
    }
    const request = AddressConverter.toFormCreateRequest(inputs, groupId);
    const address = await createAddress({ createData: request });
    enqueueSnackbar(t("basedata.general.address.create_success"), { variant: "success" });
    return address;
  };

  const handleEdit = async (inputs: IAddressFormInputs): Promise<IAddressLean | undefined> => {
    if (!address?.id) {
      return;
    }
    const request = AddressConverter.toFormEditRequest(inputs, address.id);
    const updatedAddress = await updateAddress({ id: address.id, updateData: request });
    enqueueSnackbar(t("basedata.general.address.update_success"), { variant: "success" });
    return AddressConverter.domainToLeanAddressDomain(updatedAddress);
  };


  return {
    isLoading,
    handleFormSubmit,
    address,
  };
};

interface IAddressFormProps {
  address?: DeepPartial<IAddressLean> | undefined;
  shouldPersistAddress?: boolean | undefined;
  groupId?: number | undefined;
  onSuccessCallback?: (address: ICreateAddressResult | undefined) => void;
}

export const AddressFormProvider: React.FC<IAddressFormProps> = (props) => {
  const value = useAddressFormProvider(props);
  return <AddressFormContext.Provider value={value}>{props.children}</AddressFormContext.Provider>;
};
