import { Grid, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useState, VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ICfmPackage } from "../../../../collect-from-market/domain/packages/cfm-package";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import { CfmQueryKeys } from "../../../../collect-from-market/repositories/queries/cfm-query-keys";
import { useCreateProductPackageConfig } from "../../../../collect-from-market/repositories/queries/package/create-product-package-config.query";
import { useUpdateCfmProductNameQuery } from "../../../../collect-from-market/repositories/queries/product/mutation/update-product-name.query";
import { getRedirectToCreateRoutingUrl } from "../../../../components/assignment-routing/assignment-products.util";
import { formatDateShort } from "../../../../shared/util/date.util";
import { useCustomForm } from "../../../../shared/util/form.util";
import { useProductOverviewRowStyle } from "../../../../style/product-overview-row.style";
import { AvailableConfiguratorRoutes } from "../../../../utils/constants";
import { BoxedRowGrid } from "../../../components/grid/boxed-row-grid.component";
import { ICfmProductPackageConfig } from "../../../components/package/create-product-package-config-content.component";
import { PackageDialog } from "../../../components/package/dialog/package-dialog.component";
import { RemovePackageConfigDialog } from "../../../components/package/dialog/remove-package-config-dialog.component";
import { ProductNameForm } from "../../../components/product-form/product-name-form.component";
import { ProductResponseDialog } from "../../../components/product-response/dialog/response-assignment-detail-dialog.component";
import { ProductRoutingDialog } from "../../../components/product-routing/product-routing-dialog.component";
import { ArticleNumberBox } from "../../../components/product/article-number-box.component";
import { ProductName } from "../../../components/product/product-name.component";
import {
  getProductArticleNumberAndName,
  hasPackages,
  hasResponse,
  hasRouting,
} from "../../../components/product/product.utils";
import { ProductActionIcons } from "./product-action-icons.component";
import { ProductActiveToggle } from "./product-active-toggle.component";
import { getRedirectToProductResponseEditConfigUrl } from "./product-overview.utils";
import { ProductConfirmButtons } from "./product-update-confirm-buttons.component";

export interface IProductNameInputs {
  name: string;
}

interface IProductOverviewRowProps {
  product: ICfmProduct;
}

export const ProductOverviewRow: VFC<IProductOverviewRowProps> = (props) => {
  const { product } = props;
  const classes = useProductOverviewRowStyle();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(product.active != null);
  const [isEditing, setIsEditing] = useState(false);
  const [isPackageDialogOpen, setIsPackageDialogOpen] = useState(false);
  const [packageToRemove, setPackageToRemove] = useState<ICfmPackage | undefined>();
  const [isRoutingDialogOpen, setIsRoutingDialogOpen] = useState(false);
  const [isResponseDialogOpen, setIsResponseDialogOpen] = useState(false);

  const { mutateAsync: updateProductName } = useUpdateCfmProductNameQuery();
  const { isLoading: isRemovePackageLoading, mutateAsync: saveProductPackageConfig } = useCreateProductPackageConfig();

  const defaultValues = { name: product.name };
  const formMethods = useCustomForm<IProductNameInputs>({ defaultValues });

  const onPackageIconClick = () => {
    if (isEditing) return;

    if (hasPackages(product)) {
      setIsPackageDialogOpen(true);
    } else {
      redirectToEditConfig();
    }
  };

  const onRoutingIconClick = () => {
    if (isEditing) return;

    if (hasRouting(product)) {
      setIsRoutingDialogOpen(true);
    } else {
      const url = getRedirectToCreateRoutingUrl([product.id]);
      history.push(url);
    }
  };

  const redirectToResponseConfig = () => {
    const url = `${AvailableConfiguratorRoutes.CreateResponseConfig}?ids=${product.id}`;
    history.push(url);
  };

  const onResponseIconClick = () => {
    if (isEditing) return;

    if (hasResponse(product)) {
      setIsResponseDialogOpen(true);
    } else {
      redirectToResponseConfig();
    }
  };

  const onUpdateProductName = () => {
    formMethods.handleSubmit(onSubmit)();
  };

  const onSubmit: SubmitHandler<IProductNameInputs> = async (inputs): Promise<void> => {
    await updateProductName({ productId: product.id, data: inputs });
    setIsEditing(false);
    queryClient.invalidateQueries(CfmQueryKeys.GetAllProductsInfinite);
  };

  const redirectToEditConfig = () => {
    const url = `${AvailableConfiguratorRoutes.CreateProductPackageConfig}?ids=${product.id}`;
    history.push(url);
  };

  const redirectToProductResponseEditConfig = () => {
    const assignmentIds = (product.responseAssignments ?? []).map((assignment) => assignment.id);
    if (!assignmentIds) {
      enqueueSnackbar(
        t("general.error_occurred", { errorCode: 500, errorMsg: "missing response assignment relation" }),
        { variant: "error" },
      );
      return;
    }
    history.push(getRedirectToProductResponseEditConfigUrl(product.id, assignmentIds));
  };

  const removePackage = async () => {
    if (!packageToRemove) return;

    // remove package from config by saving product and only wanted packages
    const wantedPackages = (product.packages ?? []).filter((existingPkg) => existingPkg.id !== packageToRemove.id);
    const data: ICfmProductPackageConfig = {
      product: product,
      existingPackages: wantedPackages,
      newPackages: [],
    };
    await saveProductPackageConfig({ config: [data] });

    setPackageToRemove(undefined);
    setIsPackageDialogOpen(wantedPackages.length > 0);
    queryClient.invalidateQueries(CfmQueryKeys.GetAllProductsInfinite);
  };

  const onEditRoutingClicked = () => {
    const url = getRedirectToCreateRoutingUrl([product.id]);
    history.push(url);
  };

  return (
    <>
      <ProductRoutingDialog
        open={isRoutingDialogOpen}
        productName={getProductArticleNumberAndName(product)}
        productId={product.id}
        assignments={product.assignments ?? []}
        onClose={() => setIsRoutingDialogOpen(false)}
        onEdit={onEditRoutingClicked}
      />
      <PackageDialog
        open={isPackageDialogOpen}
        product={product}
        packages={product.packages ?? []}
        onCancel={() => setIsPackageDialogOpen(false)}
        onEdit={redirectToEditConfig}
        onRemove={(pkg) => {
          setIsPackageDialogOpen(false);
          setPackageToRemove(pkg);
        }}
      />
      <RemovePackageConfigDialog
        open={packageToRemove !== undefined}
        packageName={packageToRemove?.name ?? ""}
        onCancel={() => {
          setPackageToRemove(undefined);
          setIsPackageDialogOpen(true);
        }}
        onAccept={removePackage}
        isLoading={isRemovePackageLoading}
      />
      {isResponseDialogOpen && (
        <ProductResponseDialog
          open={isResponseDialogOpen}
          product={product}
          onCancel={() => setIsResponseDialogOpen(false)}
          onEdit={redirectToProductResponseEditConfig}
        />
      )}
      <BoxedRowGrid justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" justifyContent="center" className={classes.container}>
            <Grid item>
              <Grid container direction="row">
                <Grid
                  item
                  className={classNames(classes.articleNumberBox, { [classes.articleNumberIsEditing]: isEditing })}
                >
                  <ArticleNumberBox articleNumber={product.articleNumber} />
                </Grid>
                {!isEditing && (
                  <>
                    <Grid item className={classes.text}>
                      <ProductName name={product.name} isActive={isActive} noWrap />
                    </Grid>
                    <Grid item className={classes.editIcon}>
                      <Edit className={classes.editIconColor} onClick={() => setIsEditing(true)} />
                    </Grid>
                  </>
                )}
                {isEditing && (
                  <Grid item className={classes.formContainer}>
                    <FormProvider {...formMethods}>
                      <ProductNameForm />
                    </FormProvider>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.iconAndButtonContainer}>
          <Grid container direction="row" spacing={2}>
            {!isEditing && (
              <Grid item className={classes.createdAtText}>
                <Typography variant="body1">{formatDateShort(product.createdAt)}</Typography>
              </Grid>
            )}
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  className={classNames({
                    [classes.productIconContainer]: !isEditing,
                    [classes.productIconContainerEditing]: isEditing,
                  })}
                >
                  <ProductActionIcons
                    product={product}
                    onPackageIconClick={onPackageIconClick}
                    onRoutingIconClick={onRoutingIconClick}
                    onResponseIconClick={onResponseIconClick}
                    additionalActions={[
                      <ProductActiveToggle productId={product.id} isActive={isActive} setIsActive={setIsActive} />,
                    ]}
                  />
                </Grid>
                {isEditing && (
                  <Grid item className={[classes.buttonContainer, "mb-4"].join(" ")}>
                    <ProductConfirmButtons
                      onConfirm={onUpdateProductName}
                      onCancel={() => {
                        formMethods.reset(defaultValues);
                        setIsEditing(false);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BoxedRowGrid>
    </>
  );
};
