import { useEffect, useState, VFC } from "react";
import { useQueryClient } from "react-query";
import { LoginAsDialog } from "../../../../components/auth/login-as-dialog.component";
import { EditGroupUserDialog } from "../../../../components/group/edit-group/dialog/edit-group-user-dialog.component";
import { UserFormModal } from "../../../../components/user/user-form-modal/user-form-modal.component";
import { UserFormMode } from "../../../../components/user/user-form/user-form.component";
import { UserConverter } from "../../../../domain/converter/user.converter";
import { IGroup } from "../../../../domain/group/group";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { IUser } from "../../../../domain/user/user";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useGetGroupUsers } from "../../../../repositories/queries/user/get-group-users.query";
import { UserDetailDialog } from "../dialogues/user-detail-dialog.component";
import { GroupDetailsUsersBaseTable } from "./group-detail-users-base-table.component";

interface IGroupDetailsUsersTableProps {
  group: IGroup;
}

export const GroupDetailsUsersTable: VFC<IGroupDetailsUsersTableProps> = (props) => {
  const { group } = props;
  const queryClient = useQueryClient();
  const [openNewUser, setOpenNewUser] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isLoginAsOpen, setIsLoginAsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { isLoading, data: userResult, refetch } = useGetGroupUsers(group.id, searchText, paginationInputMeta);

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const onSavedCallback = () => {
    refetch();
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    setOpenNewUser(false);
  };

  const handleRowClicked = (user: IUser | undefined) => {
    setSelectedUser(user);
    setIsDetailOpen(true);
  };

  const handleOnAddNewClicked = () => {
    setOpenNewUser(true);
  };

  return (
    <>
      <LoginAsDialog
        user={selectedUser ? UserConverter.toLoginAsUserDomain(selectedUser) : selectedUser}
        open={isLoginAsOpen}
        onCancel={() => {
          setIsDetailOpen(false);
          setIsLoginAsOpen(false);
        }}
      />

      <UserFormModal
        savedCallback={onSavedCallback}
        onCancel={() => {
          setOpenNewUser(false);
        }}
        open={openNewUser}
        group={group}
        mode={UserFormMode.Create}
      />

      <UserDetailDialog
        open={isDetailOpen}
        user={selectedUser}
        onClose={() => {
          setIsDetailOpen(false);
          setSelectedUser(undefined);
        }}
        onLoginAsClick={() => {
          setIsLoginAsOpen(true);
          setIsDetailOpen(false);
        }}
        onEditClick={() => {
          setIsEditOpen(true);
          setIsDetailOpen(false);
        }}
      />

      <EditGroupUserDialog
        open={isEditOpen}
        group={group}
        user={selectedUser}
        onSaved={() => {
          setIsEditOpen(false);
          setIsDetailOpen(false);
          setSelectedUser(undefined);
        }}
        onCancel={() => {
          setIsDetailOpen(false);
          setIsEditOpen(false);
        }}
      />

      <GroupDetailsUsersBaseTable
        userResult={userResult}
        onAddClick={handleOnAddNewClicked}
        onRowClicked={handleRowClicked}
        isLoading={isLoading}
        setSearchText={setSearchText}
        paginationInputMeta={paginationInputMeta}
        setPaginationInputMeta={setPaginationInputMeta}
      />
    </>
  );
};
