import { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { getAnnouncementPeriodOfGroup } from "../../../shared/components/group/group-form/group-form.util";
import { IPomGroupContract } from "../../../shared/domain/group/group-contract";
import { useDashboardActionContext } from "../../../shared/pages/dashboard/dashboard-action.provider";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { PomTakeBackSystemDialog } from "../../components/announcement-dialog/pom-take-back-system.dialog";
import { PomProductSearchFieldProvider } from "../../components/product-table/pom-product-search-field.provider";
import { PomAnnouncementPeriod } from "../../repositories/models/announcements/pom-announcement.period";
import { PomAnnouncementContent } from "./components/pom-announcement-content.component";
import { PomAnnouncementProvider } from "./pom-announcement.provider";

export const PomAnnouncementPage: FC = () => {
  const { internalUser } = useAuthContext();
  const { contractId } = useDashboardActionContext();
  const [isTakeBackSystemDialogOpen, setIsTakeBackSystemDialogOpen] = useState<boolean>(false);
  const history = useHistory();

  const period: PomAnnouncementPeriod | undefined = useMemo(() => {
    if (contractId === undefined) return undefined;
    const period = getAnnouncementPeriodOfGroup(internalUser?.group, contractId);
    return period;
  }, [contractId, internalUser?.group]);

  const contract: IPomGroupContract | undefined = useMemo(() => {
    const filteredContract = internalUser?.group?.pomContracts?.find((contract) => contract.id === contractId);
    return filteredContract;
  }, [contractId, internalUser]);

  const closeActionDialog = () => {
    setIsTakeBackSystemDialogOpen(false);
  };

  const openTakeBackSystemDialog = () => {
    setIsTakeBackSystemDialogOpen(true);
  };

  useEffect(() => {
    if (contractId === undefined && internalUser?.group) {
      setIsTakeBackSystemDialogOpen(true);
    }
  }, [contractId, internalUser]);

  if (!internalUser?.group) {
    history.push(AvailableSharedRoutes.Portal);
    return null;
  }

  return (
    <>
      <PomTakeBackSystemDialog
        open={isTakeBackSystemDialogOpen}
        onAccept={closeActionDialog}
        onCancel={closeActionDialog}
        groupId={internalUser.group.id}
      />
      <PomAnnouncementProvider>
        <PomProductSearchFieldProvider>
          <PomAnnouncementContent
            period={period}
            contract={contract}
            handleOpenTakeBackSystemDialog={openTakeBackSystemDialog}
          />
        </PomProductSearchFieldProvider>
      </PomAnnouncementProvider>
    </>
  );
};
