import { Typography } from "@material-ui/core";
import { useQuery } from "hooks/useQuery";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IPasswordResetData } from "utils/PasswordHelper";
import { AvailableSharedRoutes } from "utils/constants";
import { ResetPasswordStatus } from "../../domain/user/user";
import DefaultLayout from "../../layout/default.layout";
import { useUnlockQuery } from "../../repositories/queries/auth/mutation/unlock.query";
import { PasswordResetBody } from "./components/password-reset-body.component";
import { IPasswordResetFormInputs, PasswordResetForm } from "./components/password-reset-form.component";
import { PasswordResetHead } from "./components/password-reset-head.component";

export const UnlockPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { query } = useQuery();
  const { mutateAsync: unlock } = useUnlockQuery();

  // Included in the 'password forgotten' email.
  const token = query.get("token");

  const { enqueueSnackbar } = useSnackbar();

  if (!token) {
    history.push(AvailableSharedRoutes.Login);
    return null;
  }

  const handlePasswordReset: SubmitHandler<IPasswordResetFormInputs> = async ({ password, verificationPassword }) => {
    const request: IPasswordResetData = {
      normalPassword: password,
      validationPassword: verificationPassword,
      oldPassword: "",
    };
    const response = await unlock({ data: request, token });
    switch (response.status) {
      case ResetPasswordStatus.NoUser:
        enqueueSnackbar(t("user.reset.noUser"), { variant: "error" });
        break;
      case ResetPasswordStatus.Successful:
        enqueueSnackbar(t("user.reset.SuccessfullyReset"), { variant: "success" });
        history.push(AvailableSharedRoutes.Login);
        break;
    }
  };

  return (
    <DefaultLayout>
      <PasswordResetHead title={t("user.reset.password")} />
      <PasswordResetBody>
        <Typography variant="h1">{t("user.reset.password")}</Typography>
        <Typography variant="body1">{t("user.reset.required")}</Typography>
        <PasswordResetForm onSubmit={handlePasswordReset} />
      </PasswordResetBody>
    </DefaultLayout>
  );
};
