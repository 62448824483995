import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroupOrManufacturerList } from "../../../domain/group/group-or-manufacturer";
import { IPaginationMeta } from "../../../domain/pagination/pagination-meta";
import { IGroupOrManufacturerListModel } from "../../../models/group/group-or-manufacturer.model";
import { IGetGroupsFilter } from "../../models/group/get-group.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getGroupsAndManufacturersWithFilters = async (
  paginationStatus: IPaginationMeta,
  filter: IGetGroupsFilter,
): Promise<IGroupOrManufacturerList> => {
  const { active, groupTypes, searchQuery, contractTypes } = filter;
  const { page, pageSize } = paginationStatus;
  const { data } = await getInstance().get<IGroupOrManufacturerListModel>(RestEndpoints.GroupsAndManufacturers, {
    params: { active, groupTypes, contractTypes, query: searchQuery, page, pageSize },
  });
  return GroupConverter.groupOrManufacturerListToDomain(data);
};

export const useGetGroupsAndManufacturersWithFiltersQuery = (
  paginationStatus: IPaginationMeta,
  filter: IGetGroupsFilter,
) => {
  return useQuery(
    [SharedQueryKeys.GetGroupsAndManufacturersWithFilters, paginationStatus, filter],
    () => getGroupsAndManufacturersWithFilters(paginationStatus, filter),
    {
      staleTime: ReactQueryConfig.staleTime,
    },
  );
};
