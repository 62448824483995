import { makeStyles } from "@material-ui/core";
import { Colors } from "./Colors";

export const useLoginStyle = makeStyles((theme) => ({
  root: {
    height: "75vh",
    display: "flex",
    alignItems: "center",
  },
  cardLeftRadius: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    [theme.breakpoints.down("md")]: {
      borderRadius: ".25rem",
    },
  },
  cardRightRadius: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      borderRadius: ".25rem",
    },
  },
  card: {
    borderRadius: ".25rem",
    backgroundColor: "#eff5f7",
  },
  cardNoShadow: {
    boxShadow: "none",
  },
  containerLogo: {
    width: "44%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.grayLight,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  image: {
    width: "600px",
    [theme.breakpoints.up("lg")]: {
      width: "700px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "700px",
    },
  },
  link: {
    color: theme.palette.primary.main + "!important",
    "&:hover": {
      textDecoration: "underline !important",
    },
    fontSize: 15.5,
  },
  description: {
    color: "#768192 !important",
    fontSize: ".875rem",
  },
  logoHover: {
    cursor: "pointer",
  },
  loginContainer: {
    margin: "auto",
    marginTop: "120px",
    marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
  versionContainer: {
    display: "flex",
    justifyContent: "center",
    "& span": {
      fontSize: 12,
      color: Colors.gray,
    },
  },
  captchaBox: {
    marginTop: "20px",
  },
  lockedTextSpacer: {
    marginBottom: 16,
  },
  mobileWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
