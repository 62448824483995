import { Grid, Typography } from "@material-ui/core";
import { VFC } from "react";
import { Trans } from "react-i18next";
import { ICfmItemX } from "../../domain/order-x/cfm-item-x";

interface ITruckloadProductAndWeightInfoProps {
  productItem: ICfmItemX | undefined;
  weight: number | null;
}

export const TruckloadProductAndWeightInfoProps: VFC<ITruckloadProductAndWeightInfoProps> = (props) => {
  const { productItem, weight } = props;
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Typography variant="body1">{productItem?.product?.name ?? "-"}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <Trans i18nKey="order.weight">{{ weight: weight?.toLocaleString() ?? "-" }}</Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};
