import _ from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { CfmProductConverter } from "../../../collect-from-market/repositories/models/converter/cfm-product.converter";
import { IProductsResponseModel } from "../../../collect-from-market/repositories/models/product/cfm-product.model";
import { useGetProductsInfiniteQuery } from "../../../collect-from-market/repositories/queries/product/query/get-infinite-products.query";
import { getProductFilterInitialValue, IProductOverviewFilter } from "./components/product-overview.utils";
import { getNextProductPageParam } from "../../../shared/util/infinite-query-product.util";

interface IProductOverviewContextType {
  filter: IProductOverviewFilter;
  setFilter: (filter: IProductOverviewFilter) => void;
  resetFilter: VoidFunction;
  productsLoading: boolean;
  products: ICfmProduct[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<IProductsResponseModel>>;
}

interface IProductOverviewProviderProps {
  children: any;
}

const ProductOverviewContext = createContext<IProductOverviewContextType>({} as IProductOverviewContextType);

export const ProductOverviewProvider = (props: IProductOverviewProviderProps) => {
  const value = useProductOverviewProvider(props);
  return <ProductOverviewContext.Provider value={value}>{props.children}</ProductOverviewContext.Provider>;
};

export const useProductOverviewContext = () => {
  return useContext(ProductOverviewContext);
};

const useProductOverviewProvider = (props: IProductOverviewProviderProps): IProductOverviewContextType => {
  const [filter, setFilter] = useState<IProductOverviewFilter>(getProductFilterInitialValue());

  const { data, isLoading, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useGetProductsInfiniteQuery(
    filter,
    getNextProductPageParam,
  );

  const products = useMemo(() => {
    const pages = data?.pages ?? [];
    return _.flatMap(pages, (page) => page.products).map((product) => CfmProductConverter.toDomain(product));
  }, [data?.pages]);

  const resetFilter = () => {
    setFilter(getProductFilterInitialValue());
  };

  return {
    filter,
    setFilter,
    resetFilter,
    products,
    productsLoading: isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};
