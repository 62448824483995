import React, { ForwardedRef, ReactElement, ReactNode } from "react";
import { Control, RegisterOptions } from "react-hook-form";
import { FormAutocomplete } from "./form-autocomplete.component";

interface IFormInfiniteAutocompleteProps<T> {
  label: string | undefined;
  name: string;
  options: T[];
  control: Control<any, object>;
  getOptionLabel: (option: T) => string | undefined;
  fetchNextPage: () => void;
  onSearchChange: (text: string | undefined) => void;
  onTextfieldBlur: VoidFunction;
  renderOption?: (option: any) => ReactNode;
  rules?: RegisterOptions<any>;
  multiple?: boolean | undefined;
  error?: boolean;
  disabled?: boolean;
}

export const FormInfiniteAutocomplete = React.forwardRef(
  <T extends {}>(props: IFormInfiniteAutocompleteProps<T>, ref: ForwardedRef<any>) => {
    const {
      label,
      name,
      options,
      control,
      getOptionLabel,
      renderOption,
      rules,
      multiple,
      error,
      fetchNextPage,
      onSearchChange,
      onTextfieldBlur,
      disabled,
    } = props;

    const onLoadNext = () => {
      fetchNextPage();
    };

    return (
      <FormAutocomplete<T>
        name={name}
        control={control}
        label={label}
        options={options}
        getOptionLabel={getOptionLabel}
        error={error}
        rules={rules}
        multiple={multiple}
        renderOption={renderOption}
        // override to stop local filtering by search text
        filterOptions={(x) => x}
        onSearchChange={onSearchChange}
        onTextfieldBlur={onTextfieldBlur}
        disabled={disabled}
        listboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              onLoadNext();
            }
          },
        }}
      />
    );
  },
) as <T extends {}>(props: IFormInfiniteAutocompleteProps<T>, ref: any) => ReactElement;
