import React, { createContext } from "react";
import { IUser } from "../../../domain/user/user";
import { useGetUserQuery } from "../../../repositories/queries/user/get-user.query";

export interface IUserDetailParams {
  id: string | undefined;
}

interface IUserDetailProvider {
  isLoading: boolean;
  user: IUser | undefined;
}

export const UserDetailContext = createContext<IUserDetailProvider>({} as IUserDetailProvider);

const useUserDetailProvider = ({ userId }: IUserDetailProviderProps): IUserDetailProvider => {
  const { data, isLoading } = useGetUserQuery(userId);

  return {
    isLoading,
    user: data,
  };
};

interface IUserDetailProviderProps {
  userId: number;
}

export const UserDetailProvider: React.FC<IUserDetailProviderProps> = (props) => {
  const value = useUserDetailProvider(props);
  return <UserDetailContext.Provider value={value}>{props.children}</UserDetailContext.Provider>;
};
