import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { ChangeEvent, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { isAdmin, isCfmCustomer, isCfmLogistics, isCfmRecycler } from "../../../../../shared/domain/user/user";
import { Colors, Shades } from "../../../../../style/Colors";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderXDetailsInvoiceAddress } from "./order-x-details-invoice-address.component";
import { OrderXDetailsLocation } from "./order-x-details-location.component";
import { OrderXDetailsLogistics } from "./order-x-details-logistics.component";
import { OrderXDetailsDestination } from "./order-x-details-destination.component";
import { accordionMinHeight, useOrderCardStyle } from "../../../../../style/order-card.style";

const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    backgroundColor: Colors.white,
    "&>div>div>div>div:first-child": {
      paddingRight: 10,
    },
  },
}));

export const OrderXCardDetails: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const orderCardClasses = useOrderCardStyle();
  const { curFilter } = useOrderXOverviewContext();
  const activeStatus = curFilter?.statusFilter;
  const [isExpanded, setIsExpanded] = useState(false);
  const { internalUser } = useAuthContext();

  const AccordionSummaryNoExpandIconRotate = withStyles({
    root: {
      minHeight: accordionMinHeight,
      backgroundColor: Colors.white,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        backgroundColor: Shades.gray50,
      },
    },
    expandIcon: {
      transform: "none !important",
    },
  })(AccordionSummary);

  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const isPermittedForLocationEdit = useMemo(() => {
    return isAdmin(internalUser) || (isCfmCustomer(internalUser) && activeStatus === CfmOrderXApiStatus.Ordered);
  }, [internalUser, activeStatus]);

  const doDisplayInvoiceAddress = useMemo(() => {
    return order.customerAddress != null && isAdmin(internalUser);
  }, [order, internalUser]);

  const doDisplayWasteProducerAddress = useMemo(() => {
    return order.customerAddress != null; // display wasteproducer for all roles in all states if address is available
  }, [order]);

  const doDisplayLogisticAddress = useMemo(() => {
    let displayAddress = false;
    if (isAdmin(internalUser) || isCfmCustomer(internalUser)) {
      displayAddress = true; // always display address for admin or customer when available
    }
    if (isCfmLogistics(internalUser)) {
      // do not display own address for logistic
      displayAddress = false;
    }
    if (isCfmRecycler(internalUser)) {
      displayAddress =
        order.status != null &&
        order.status > CfmOrderXApiStatus.Ordered &&
        order.status < CfmOrderXApiStatus.Confirmed;
    }
    return order.logisticAddress != null && displayAddress;
  }, [order, internalUser]);

  const doDisplayRecyclerAddress = useMemo(() => {
    let displayAddress = false;
    if (isAdmin(internalUser)) {
      displayAddress = order.status != null && order.status > CfmOrderXApiStatus.ShoppingCart;
    }
    if (isCfmCustomer(internalUser)) {
      displayAddress =
        order.status != null &&
        order.status > CfmOrderXApiStatus.ShoppingCart &&
        order.items?.find((item) => item.product) !== undefined;
    }
    if (isCfmLogistics(internalUser)) {
      // do not display own address for logistic
      displayAddress = order.status != null && order.status > CfmOrderXApiStatus.ShoppingCart;
    }
    if (isCfmRecycler(internalUser)) {
      displayAddress = order.status != null && order.status === CfmOrderXApiStatus.Confirmed;
    }
    return order.destinationAddress != null && displayAddress;
  }, [order, internalUser]);

  const getOrderDetailRow = (detailEntry: ReactNode, isEditAllowed: boolean, hasDivider?: boolean): ReactNode => {
    return (
      <>
        {hasDivider && (
          <Grid item>
            <Divider className={orderCardClasses.divider} />
          </Grid>
        )}

        <Grid item>
          <Grid container direction="row" justifyContent="space-between" wrap={"nowrap"}>
            <Grid item md="auto">
              {detailEntry}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Accordion expanded={isExpanded} onChange={handleExpandChange} elevation={0} square>
        <AccordionSummaryNoExpandIconRotate
          className={orderCardClasses.accordionSummaryHeightOnExpand}
          expandIcon={
            <ArrowForwardIosOutlined
              classes={{ root: isExpanded ? orderCardClasses.expandedIcon : orderCardClasses.expandIcon }}
            />
          }
        >
          <Typography variant="body1" className={classNames({ [orderCardClasses.cardHeaderTextExpanded]: isExpanded })}>
            {t("order.details.cardLabel")}
          </Typography>
        </AccordionSummaryNoExpandIconRotate>
        <AccordionDetails classes={{ root: classes.detailsContainer }}>
          <Grid container direction="column" className={"w-100"}>
            {doDisplayWasteProducerAddress &&
              getOrderDetailRow(
                <OrderXDetailsLocation />,
                activeStatus !== CfmOrderXApiStatus.Canceled && isPermittedForLocationEdit,
                false,
              )}
            {doDisplayInvoiceAddress &&
              getOrderDetailRow(
                <OrderXDetailsInvoiceAddress />,
                activeStatus !== CfmOrderXApiStatus.Canceled && isAdmin(internalUser),
                doDisplayWasteProducerAddress,
              )}
            {doDisplayLogisticAddress &&
              getOrderDetailRow(
                <OrderXDetailsLogistics />,
                activeStatus !== CfmOrderXApiStatus.Canceled && isAdmin(internalUser),
                doDisplayInvoiceAddress || doDisplayWasteProducerAddress,
              )}
            {doDisplayRecyclerAddress &&
              getOrderDetailRow(
                <OrderXDetailsDestination />,
                activeStatus !== CfmOrderXApiStatus.Canceled && isAdmin(internalUser),
                doDisplayLogisticAddress || doDisplayInvoiceAddress || doDisplayWasteProducerAddress,
              )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
