import { Grid } from "@material-ui/core";
import { LoginForm } from "components/Form/LoginForm";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLoginStyle } from "style/login.style";
import DefaultLayout from "../../layout/default.layout";
import config from "../../../config/config";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const classes = useLoginStyle();

  return (
    <>
      <Helmet>
        <title>{t("app_name")}</title>
      </Helmet>
      <DefaultLayout>
        <Grid item md={6} sm={8} xs={11} className={classes.loginContainer}>
          <div className="card-group">
            <div className={["p-4 card", classes.card, classes.cardRightRadius].join(" ")}>
              <div className="card-body">
                <LoginForm />
              </div>
            </div>
          </div>
        </Grid>
      </DefaultLayout>
      <div className={classes.versionContainer}>
        <span>{config.build.version}</span>
      </div>
    </>
  );
};
