import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";
import { CfmOrderXStatusFilter } from "../../../../domain/order-x/cfm-order-x-filter";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { LogisticBulkAction } from "./providers/order-bulk-action.provider";

export const getLogisticBulkActionTypes = (
  status: CfmOrderXStatusFilter,
  hasSingleOrders: boolean,
): CfmOrderActionType[] => {
  if (status === CfmOrderXApiStatus.Ordered) {
    const logisticAnnouncedAction = hasSingleOrders
      ? CfmOrderActionType.LogisticAnnouncedSingleOrder
      : CfmOrderActionType.LogisticAnnounced;
    return [CfmOrderActionType.LogisticAccepted, logisticAnnouncedAction];
  }

  if (status === CfmOrderXApiStatus.LogisticAccepted) {
    return hasSingleOrders ? [CfmOrderActionType.LogisticAnnouncedSingleOrder] : [CfmOrderActionType.LogisticAnnounced];
  }

  if (status === CfmOrderXApiStatus.LogisticAnnounced) {
    return [
      CfmOrderActionType.ChangePlannedPickup,
      CfmOrderActionType.LogisticMistrip,
      CfmOrderActionType.LogisticPerformed,
    ];
  }

  return [];
};

export const getLogisticBulkActionForActionType = (actionType: CfmOrderActionType): LogisticBulkAction | undefined => {
  switch (actionType) {
    case CfmOrderActionType.LogisticAccepted:
      return LogisticBulkAction.LogisticAccepted;
    case CfmOrderActionType.ChangePlannedPickup:
      return LogisticBulkAction.ChangedPlannedPickup;
    case CfmOrderActionType.LogisticAnnounced:
      return LogisticBulkAction.LogisticAnnounced;
    case CfmOrderActionType.LogisticPerformed:
      return LogisticBulkAction.LogisticPerformed;
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
      return LogisticBulkAction.LogisticAnnouncedSingleOrder;
    case CfmOrderActionType.LogisticMistrip:
      return LogisticBulkAction.LogisticMistrip;
    default:
      return undefined;
  }
};

export const getActionTypeForBulkAction = (type: LogisticBulkAction): CfmOrderActionType | undefined => {
  switch (type) {
    case LogisticBulkAction.LogisticAccepted:
      return CfmOrderActionType.LogisticAccepted;
    case LogisticBulkAction.ChangedPlannedPickup:
      return CfmOrderActionType.ChangePlannedPickup;
    case LogisticBulkAction.LogisticAnnounced:
      return CfmOrderActionType.LogisticAnnounced;
    case LogisticBulkAction.LogisticPerformed:
      return CfmOrderActionType.LogisticPerformed;
    case LogisticBulkAction.LogisticAnnouncedSingleOrder:
      return CfmOrderActionType.LogisticAnnouncedSingleOrder;
    case LogisticBulkAction.LogisticMistrip:
      return CfmOrderActionType.LogisticMistrip;
    default:
      return undefined;
  }
};
