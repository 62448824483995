import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChipFilter, IOption } from "../../../components/Filters/ChipFilter";
import { IPomProductType } from "./pom-product-table-data";

interface IPomProductTypeFilter {
  typeOptions: IPomProductType[];
  selectedOptions: number[];
  setSelectedOptions: (data: number[] | undefined) => void;
  onFilterClosed: () => void;
}

export const PomProductTypeFilter: FC<IPomProductTypeFilter> = (props) => {
  const { typeOptions, selectedOptions, setSelectedOptions, onFilterClosed } = props;
  const { t } = useTranslation();
  const options: IOption[] = typeOptions.map((type) => {
    return {
      value: type.id,
      displayValue: type.name,
    };
  });

  const handleSetFilter = (id: number) => {
    if (selectedOptions.find((option) => option === id) !== undefined) {
      setSelectedOptions(selectedOptions.filter((option) => option !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }
  };

  return (
    <ChipFilter
      multiple={true}
      possibleOptions={options}
      label={t("pom.announcements.product.table.type")}
      filter={selectedOptions}
      setFilter={(id: number) => {
        handleSetFilter(id);
      }}
      onClose={onFilterClosed}
    />
  );
};
