import { Grid, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { useEffect, useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmCreateResponseField } from "../../../../collect-from-market/domain/routing-assignment/cfm-response-field";
import { useCreateNewResponseFieldQuery } from "../../../../collect-from-market/repositories/queries/response-field/create-new-response-field.query";
import { useUpdateResponseFieldQuery } from "../../../../collect-from-market/repositories/queries/response-field/update-response-field.query";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { FormAutocomplete } from "../../../../shared/components/form/fields/form-autocomplete.component";
import { FormSwitch } from "../../../../shared/components/form/fields/form-switch.field";
import { FormTextField } from "../../../../shared/components/form/fields/form-text.field";
import { FormField } from "../../../../shared/components/form/form-field.component";
import { useCustomForm } from "../../../../shared/util/form.util";
import { useProductResponseFieldOverviewContext } from "../../../pages/product-response-field/product-response-field-overview.provider";
import { getResponseFieldFieldTypeLabel } from "../../response-field/response-field.util";
import { CfmResponseFieldType } from "../../../../collect-from-market/domain/response-values-x/cfm-response-field-type";

const useStyles = makeStyles((theme: Theme) => ({
  activeSwitch: {
    float: "right",
    paddingTop: 16,
  },
}));

interface IResponseFieldFormInputs {
  id: number | null;
  type: CfmResponseFieldType;
  name: string;
  unit: string;
  internalNumber: number;
  active: boolean;
}

interface IConfigurationAddResponseFieldDialog {
  open: boolean;
  onAccept: VoidFunction;
  onCancel: VoidFunction;
  acceptText?: string;
  cancelText?: string;
  fullWidth?: boolean;
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
}

export const ConfigurationAddResponseFieldDialog: VFC<IConfigurationAddResponseFieldDialog> = (props) => {
  const { open, onAccept, onCancel, acceptText, cancelText, fullWidth = true, maxWidth } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { mutateAsync: addResponseField, isLoading: responseFieldCreationLoading } = useCreateNewResponseFieldQuery();
  const { mutateAsync: updateResponseField, isLoading: responseFieldUpdateLoading } = useUpdateResponseFieldQuery();
  const { responseField, setResponseField } = useProductResponseFieldOverviewContext();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useCustomForm<IResponseFieldFormInputs>({ defaultValues: { active: true } });

  useEffect(() => {
    if (!responseField) return;
    const values = {
      id: responseField?.id ?? undefined,
      type: responseField?.type ?? undefined,
      active: responseField ? (responseField.active ? true : false) : true,
      unit: responseField?.unit ?? undefined,
      internalNumber: responseField?.internalNumber ?? undefined,
      name: responseField?.name ?? undefined,
    };

    for (const kvp of Object.entries(values)) {
      const key = kvp[0];
      const value = kvp[1];
      setValue(key as any, value);
    }
  }, [responseField, setValue]);

  const onSubmit = async (inputs: IResponseFieldFormInputs): Promise<void> => {
    const newResponseField: ICfmCreateResponseField = {
      type: inputs.type,
      unit: inputs.unit ? inputs.unit : null,
      internalNumber: inputs.internalNumber ? Number(inputs.internalNumber) : null,
      name: inputs.name,
      active: inputs.active ? new Date() : null,
    };
    if (responseField?.id) {
      const updateParams: { id: number; responseField: ICfmCreateResponseField } = {
        id: responseField.id,
        responseField: newResponseField,
      };
      await updateResponseField(updateParams);
    } else {
      await addResponseField(newResponseField);
    }
    setResponseField(undefined);
    onAccept();
  };

  const onCancelClick = () => {
    setResponseField(undefined);
    onCancel();
  };

  const title = useMemo(() => {
    if (responseField?.id) {
      return t("configuration.responseField.dialog.updateTitle");
    }
    return t("configuration.responseField.dialog.createTitle");
  }, [responseField, t]);

  const validateInternalNumber = (value: number | undefined): undefined | string => {
    if (!value || value < 10 || value > 99) {
      return t("response_field_config.response.internal_number_error");
    }
  };

  return (
    <AppDialog
      open={open}
      title={title}
      onAcceptClick={handleSubmit(onSubmit)}
      onCancelClick={onCancelClick}
      acceptTextOverride={acceptText}
      cancelTextOverride={cancelText}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      isLoading={responseFieldCreationLoading || responseFieldUpdateLoading}
    >
      <form>
        <Grid container spacing={4}>
          <FormField md={6}>
            <FormAutocomplete<CfmResponseFieldType>
              control={control}
              label={t("configuration.responseField.dialog.form.type")}
              options={Object.values(CfmResponseFieldType)}
              getOptionLabel={(value) => {
                return getResponseFieldFieldTypeLabel(value, t);
              }}
              {...register("type", { required: true })}
            />
          </FormField>
          <FormField md={6}>
            <Tooltip
              title={
                responseField?.inUse === true && responseField.active != null
                  ? t("configuration.responseField.inUse")
                  : ""
              }
            >
              <div className={classes.activeSwitch}>
                <FormSwitch
                  name="active"
                  disabled={responseField?.inUse === true && responseField.active != null}
                  control={control}
                  label={t("configuration.responseField.dialog.form.active")}
                  required
                />
              </div>
            </Tooltip>
          </FormField>
          <FormField md={12}>
            <FormTextField
              hasError={Boolean(errors.name)}
              label={t("configuration.responseField.dialog.form.name")}
              type="text"
              required={true}
              control={control}
              name={"name"}
              rules={{
                required: true,
              }}
            />
          </FormField>
          <FormField md={6}>
            <FormTextField
              hasError={Boolean(errors.unit)}
              label={t("configuration.responseField.dialog.form.unit")}
              type="text"
              required={false}
              control={control}
              name={"unit"}
              rules={{ required: false }}
            />
          </FormField>
          <FormField md={6}>
            <FormTextField
              hasError={Boolean(errors.internalNumber)}
              label={t("configuration.responseField.dialog.form.internalNumber")}
              type="number"
              required={true}
              errorMessage={errors.internalNumber?.message}
              control={control}
              name={"internalNumber"}
              rules={{
                required: true,
                validate: validateInternalNumber,
              }}
            />
          </FormField>
        </Grid>
      </form>
    </AppDialog>
  );
};
