import { makeStyles } from "@material-ui/core";
import { PrimaryColors, Shades } from "../../../../../style/Colors";

const ORDER_CARD_PADDING_LEFT_SELECTED_MOBILE = 20;
const SELECTED_PADDING_LEFT = 58;
const SELECTED_PADDING_RIGHT = 48;

export const useOrderXCardStyle = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Shades.grayLight,
    },
    marginBottom: 24,
  },
  cardHeader: {
    backgroundColor: PrimaryColors.base,
  },
  cardHeaderActive: {
    backgroundColor: PrimaryColors.active,
  },
  cardHeaderActiveMobile: {
    paddingLeft: SELECTED_PADDING_LEFT,
    paddingRight: SELECTED_PADDING_RIGHT,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: ORDER_CARD_PADDING_LEFT_SELECTED_MOBILE,
      paddingRight: ORDER_CARD_PADDING_LEFT_SELECTED_MOBILE,
    },
  },
  cardOrderInformationPlaceholder: {
    width: 70,
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  cardOrderInformationStatus: {
    paddingTop: 16,
  },
  cardOrderInformationDetails: {
    paddingTop: 16,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 32,
    },
  },
  defaultCardHeaderPadding: {
    padding: "15px 30px",
    [theme.breakpoints.down("xs")]: {
      padding: 15,
    },
  },
}));
