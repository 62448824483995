import { BaseDataIcon, OrdersIcon } from "../../../components/Primitives/Icons";
import { escapeParam, INavigationItem } from "../../../shared/domain/navigation/navigation-item";
import { AvailableCfmRoutes, AvailableSharedRoutes } from "../../../utils/constants";

export default function getCfmAdminNavItems(): INavigationItem[] {
  return [
    {
      label: "navigation.orders.label",
      icon: OrdersIcon,
      to: AvailableCfmRoutes.BaseOrderUrl,
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.orders.overview.label",
          description: "navigation.orders.overview.description",
          to: AvailableCfmRoutes.OrderXOverview,
          visible: true,
          invisibleChildrenUrls: [
            escapeParam(AvailableCfmRoutes.OrderXDetail, ":orderId"),
            escapeParam(AvailableCfmRoutes.OrderQrView, ":orderId"),
          ],
        },
        {
          label: "navigation.orders.new.label",
          description: "navigation.orders.new.description",
          to: AvailableCfmRoutes.OrderXAdminNew,
          visible: true,
        },
        {
          label: "navigation.orders.repeatOverview.label",
          description: "navigation.orders.repeatOverview.description",
          to: AvailableCfmRoutes.OrderXRepeatOverview,
          visible: true,
        },
        {
          label: "",
          to: AvailableCfmRoutes.OrderXDetail,
          visible: false,
        },
        {
          label: "",
          to: AvailableCfmRoutes.OrderQrView,
          visible: false,
        },
      ],
    },
    {
      label: "navigation.basedata.label",
      icon: BaseDataIcon,
      to: "/portal/",
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.basedata.groups.label",
          to: AvailableSharedRoutes.GroupList,
          visible: true,
          invisibleChildrenUrls: [
            AvailableSharedRoutes.GroupNew,
            escapeParam(AvailableSharedRoutes.GroupDetail, ":id"),
          ],
        },
        {
          label: "basedata.groups.create",
          to: AvailableSharedRoutes.GroupNew,
          visible: false,
        },
        {
          label: "basedata.groups.detail.text",
          to: AvailableSharedRoutes.GroupDetail,
          visible: false,
        },
        {
          label: "basedata.groups.edit",
          to: AvailableSharedRoutes.GroupDetail,
          visible: false,
        },
        {
          label: "navigation.basedata.users.label",
          to: AvailableSharedRoutes.UserList,
          visible: true,
          invisibleChildrenUrls: [
            AvailableSharedRoutes.UserCreate,
            escapeParam(AvailableSharedRoutes.UserDetail, ":id"),
            escapeParam(AvailableSharedRoutes.UserEdit, ":id"),
          ],
        },
        {
          label: "basedata.users.create",
          to: AvailableSharedRoutes.UserCreate,
          visible: false,
        },
        {
          label: "basedata.users.edit.text",
          to: AvailableSharedRoutes.UserEdit,
          visible: false,
        },
        {
          label: "basedata.users.detail",
          to: AvailableSharedRoutes.UserDetail,
          visible: false,
        },
      ],
    },
  ];
}
