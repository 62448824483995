import _ from "lodash";
import { createContext, FunctionComponent, useContext, useMemo, useState } from "react";
import useAsyncEffect from "../../../hooks/use-async-effect";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { useDashboardActionContext } from "../../../shared/pages/dashboard/dashboard-action.provider";
import { useGetPomProductTypesQuery } from "../../repositories/queries/get-pom-product-types.query";
import { useGetProductsQuery } from "../../repositories/queries/get-products";
import { IPomAnnouncementTableData } from "../announcement-table/pom-announcement-table-data";
import { IPomProductTableData, IPomProductType } from "./pom-product-table-data";
import { PomProductTableDataConverter } from "./pom-product-table-data.converter";

interface IPomProductTableProvider {
  products: IPomProductTableData[];
  isLoading: boolean;
  page: number;
  setNewPage: (page: number) => void;
  pageSize: number;
  setNewPageSize: (page: number) => void;
  itemCount: number;
  isProductDisabled: (product: IPomProductTableData) => boolean;
  productTypes: IPomProductType[];
  productTypeFilter: number[] | undefined;
  setProductTypeFilter: (ids: number[] | undefined) => void;
  onFilterChanged: VoidFunction;
  onSearchChanged: (newValue: string) => void;
}

const PomProductTableContext = createContext<IPomProductTableProvider>({} as IPomProductTableProvider);

export const usePomProductTableContext = () => {
  return useContext(PomProductTableContext);
};

const usePomProductTableProvider = (selectedProducts?: IPomAnnouncementTableData[]): IPomProductTableProvider => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [itemCount, setItemCount] = useState<number>(0);
  const [curSearchQuery, setCurSearchQuery] = useState<string | undefined>();
  const [productTypeFilter, setProductTypeFilter] = useState<number[] | undefined>();
  const [curProductTypeFilter, setCurProductTypeFilter] = useState<number[] | undefined>();
  const { contractId } = useDashboardActionContext();
  const { internalUser } = useAuthContext();

  const takeBackSystemId = useMemo(
    () =>
      internalUser?.group?.pomContracts?.find((contract) => contract.id === contractId)?.contractField.takeBackSystem
        .id,
    [contractId, internalUser],
  );

  const {
    data: pomProductsResult,
    refetch: fetchProducts,
    isLoading: arePomProductsLoading,
  } = useGetProductsQuery(page, pageSize, curSearchQuery, productTypeFilter, takeBackSystemId);
  const {
    data: productTypesResult,
    refetch: fetchProductTypes,
    isLoading: areProductTypesLoading,
  } = useGetPomProductTypesQuery();

  const isLoading = useMemo(
    () => arePomProductsLoading || areProductTypesLoading,
    [arePomProductsLoading, areProductTypesLoading],
  );

  const products = useMemo(() => {
    setItemCount(pomProductsResult?.meta.numberOfRecords ?? 0);
    return pomProductsResult ? PomProductTableDataConverter.toDomain(pomProductsResult) : [];
  }, [pomProductsResult]);

  const productTypes = useMemo(() => {
    return (productTypesResult ?? []).map(PomProductTableDataConverter.typeToDomain);
  }, [productTypesResult]);

  useAsyncEffect(async () => {
    if (contractId === undefined) return;
    await fetchProducts();
    await fetchProductTypes();
  }, [contractId]);

  useAsyncEffect(
    async (isActive) => {
      if (!isActive() || productTypeFilter !== undefined) return;
      await fetchProducts();
    },
    [productTypeFilter],
  );

  const areProductTypeIdArraysSameValue = useMemo(() => {
    const difference = _.xor(productTypeFilter, curProductTypeFilter);
    return difference.length === 0;
  }, [productTypeFilter, curProductTypeFilter]);

  const setNewPageSize = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const isProductDisabled = (product: IPomProductTableData | IPomAnnouncementTableData): boolean => {
    if (!selectedProducts) {
      return false;
    }

    return selectedProducts.some((selectedProduct) => selectedProduct.id === product.id);
  };

  const onFilterChanged = () => {
    // if filter changes, reset page
    if (!areProductTypeIdArraysSameValue) {
      setPage(0);
      setCurProductTypeFilter(productTypeFilter);
    }
  };

  const onSearchChanged = (newValue: string) => {
    setCurSearchQuery(newValue);
    setPage(0);
  };

  return {
    products: products,
    isLoading: isLoading,
    page: page,
    setNewPage: setPage,
    pageSize: pageSize,
    setNewPageSize: setNewPageSize,
    itemCount: itemCount,
    isProductDisabled,
    productTypes,
    productTypeFilter,
    setProductTypeFilter,
    onFilterChanged,
    onSearchChanged,
  };
};

interface IPomProductTableProviderProps {
  selectedProducts?: IPomAnnouncementTableData[];
}

export const PomProductTableProvider: FunctionComponent<IPomProductTableProviderProps> = (props) => {
  const value = usePomProductTableProvider(props.selectedProducts);
  return <PomProductTableContext.Provider value={value}>{props.children}</PomProductTableContext.Provider>;
};
