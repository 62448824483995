import React, { FC, useState } from "react";

export enum AvailablePlatform {
  Cfm = "Cfm",
  Pom = "Pom",
  Config = "Config",
}

const STORAGE_KEY = "platform";
const STORAGE_CFM = "cfm";
const STORAGE_POM = "pom";
const STORAGE_CONFIG = "config";
const STORAGE: Storage = sessionStorage;

interface IPlatformContext {
  activePlatform: AvailablePlatform;
  setActivePlatform(newPlatform: AvailablePlatform): void;
}

interface IPlatformProps {}

const getDefaultPlatform = (value: string | null) => {
  switch (value) {
    case STORAGE_POM:
      return AvailablePlatform.Pom;
    case STORAGE_CFM:
      return AvailablePlatform.Cfm;
    case STORAGE_CONFIG:
      return AvailablePlatform.Config;
    default:
      return AvailablePlatform.Cfm;
  }
};

const usePlatformProvider = (props: IPlatformProps): IPlatformContext => {
  // Keep platform on refresh. Default to CFM.
  const value = STORAGE.getItem(STORAGE_KEY);
  const defaultPlatform = getDefaultPlatform(value);
  const [activePlatform, setActivePlatform] = useState<AvailablePlatform>(defaultPlatform);

  const setPlatform = (newPlatform: AvailablePlatform) => {
    const value =
      newPlatform === AvailablePlatform.Pom
        ? STORAGE_POM
        : newPlatform === AvailablePlatform.Cfm
        ? STORAGE_CFM
        : STORAGE_CONFIG;
    STORAGE.setItem(STORAGE_KEY, value);
    setActivePlatform(newPlatform);
  };

  return {
    activePlatform,
    setActivePlatform: setPlatform,
  };
};

const PlatformContext = React.createContext<IPlatformContext>({} as IPlatformContext);

export const PlatformProvider: FC<IPlatformProps> = (props) => {
  const platform = usePlatformProvider(props);

  return <PlatformContext.Provider value={platform}>{props.children}</PlatformContext.Provider>;
};

export const usePlatformContext = () => React.useContext(PlatformContext);
