import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Colors, PrimaryColors } from "../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: 28,
    padding: "2px 10px 2px 10px",
    borderRadius: "4px",
  },
  idBox: {
    backgroundColor: Colors.grayLight,
    border: `2px solid Colors.grayLight`,
  },
  boxTextColor: {
    color: PrimaryColors.base,
    fontWeight: "bold",
  },
}));

interface IIdBoxProps {
  id: number | string;
}

export const IdBox: React.FC<IIdBoxProps> = (props) => {
  const { id } = props;
  const classes = useStyles();

  return (
    <Box className={classNames(classes.box, classes.idBox)}>
      <Typography variant="body1" className={classes.boxTextColor}>
        {id}
      </Typography>
    </Box>
  );
};
