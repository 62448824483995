import { CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { useGetPackages } from "../../../collect-from-market/repositories/queries/package/get-packages.query";
import { OrderCheckbox } from "../../../components/checkbox/order-checkbox.component";
import { ForwardButton } from "../../../components/Primitives/Buttons";
import { ConfigHeader } from "../header/config-header.component";
import { PackageConfigRow } from "./package-config-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  checkBox: {
    marginLeft: 22,
  },
  bottomRowContainer: {
    marginBottom: 30,
    marginTop: 20,
  },
  headingContainer: {
    marginTop: 20,
  },
}));

interface ISelectPackagesForConfigProps {
  selectedPackages: ICfmPackage[];
  setSelectedPackages: (packages: ICfmPackage[]) => void;
  onNextClick: VoidFunction;
}

export const SelectPackagesForConfig: VFC<ISelectPackagesForConfigProps> = (props) => {
  const { selectedPackages, setSelectedPackages, onNextClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading, data: packages } = useGetPackages();

  const areAllSelected = () => {
    const selectedIds = selectedPackages.map((pkg) => pkg.id);
    const ids = (packages ?? []).map((pkg) => pkg.id);
    return ids.length > 0 && ids.every((id) => selectedIds.includes(id));
  };

  const onSelectAllClick = (checked: boolean) => {
    const newSelectedPackages = checked && packages ? packages : [];
    setSelectedPackages(newSelectedPackages);
  };

  const isPackageSelected = (pkg: ICfmPackage) => {
    return selectedPackages.find((selectedPackage) => selectedPackage.id === pkg.id) !== undefined;
  };

  const onPackageSelect = (pkg: ICfmPackage, checked: boolean) => {
    if (checked) {
      setSelectedPackages([...selectedPackages, pkg]);
    } else {
      setSelectedPackages(selectedPackages.filter((selectedPackage) => selectedPackage.id !== pkg.id));
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      {isLoading && (
        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item className={classes.headingContainer}>
        <ConfigHeader label={t("configuration.choosePackages")} />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          {(packages ?? [])
            .sort((p1, p2) => (p1.name ?? "").localeCompare(p2.name ?? ""))
            .map((pkg) => {
              return (
                <Grid item key={pkg.id}>
                  <PackageConfigRow pkg={pkg} isSelected={isPackageSelected(pkg)} onSelect={onPackageSelect} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item className={classes.bottomRowContainer}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.checkBox}>
            <OrderCheckbox
              label={areAllSelected() ? t("general.deselectAll") : t("general.selectAll")}
              onChange={onSelectAllClick}
              checked={areAllSelected()}
              size="large"
            />
          </Grid>
          <Grid item>
            <ForwardButton onClick={onNextClick} disabled={selectedPackages.length === 0}>
              <Typography variant="body1">
                {t("configuration.createProductPackageConfig.showPackageConfigs")}
              </Typography>
            </ForwardButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
