import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { useQuery } from "react-query";
import { CfmOrderXStatusFilterModel } from "../../../models/order-x/cfm-order-x-filter.model";

const getOrdersExportByFilter = async (statusFilter: CfmOrderXStatusFilterModel): Promise<string> => {
  const { data } = await getInstance().get(RestEndpoints.CfmOrdersXExportByFilter, {
    params: {
      status: statusFilter,
    },
    responseType: "blob",
  });

  return data;
};

export const useGetOrdersExportByFilterQuery = (statusFilter: CfmOrderXStatusFilterModel, enabled: boolean = true) => {
  return useQuery([CfmQueryKeys.GetOrdersXExportByFilter], () => getOrdersExportByFilter(statusFilter), {
    enabled,
    // disable cache time, generate always new
    cacheTime: 0,
  });
};
