import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { replaceUriParameter, RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmTransferOrder } from "../../../../domain/transfer-order/cfm-transfer-order";
import { CfmTransferOrderConverter } from "../../../models/converter/transfer-order/cfm-transfer-order.converter";
import { ICfmTransferOrderModel } from "../../../models/transfer-order/cfm-transfer-order.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmUpdateTransferOrder } from "../../../../domain/transfer-order/cfm-update-transfer-order";

interface IUpdateTransferOrderRequestData {
  transferOrderId: number;
  requestData: ICfmUpdateTransferOrder;
}

const updateTransferOrderQuery = async ({
  transferOrderId,
  requestData,
}: IUpdateTransferOrderRequestData): Promise<ICfmTransferOrder | undefined> => {
  const url = replaceUriParameter(RestEndpoints.UpdateTransferOrder, "id", transferOrderId);
  const { data } = await getInstance().patch<ICfmTransferOrderModel | undefined>(
    url,
    CfmTransferOrderConverter.updateToModel(requestData),
  );
  return data ? CfmTransferOrderConverter.toDomain(data) : undefined;
};

export const useUpdateTransferOrderQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation([CfmQueryKeys.UpdateTransferOrder], updateTransferOrderQuery, {
    onError: () => {
      enqueueSnackbar(t("notifications.order_saved_error.message"), {
        variant: "error",
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t("notifications.order_saved_success.message"), {
        variant: "success",
      });
      void queryClient.invalidateQueries(CfmQueryKeys.GetTransferOrders);
    },
  });
};
