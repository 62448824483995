import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  NavigationLeft,
  NavigationLeftLast,
  NavigationRight,
  NavigationRightLast,
} from "../../../components/Primitives/Icons";
import { PrimaryColors, Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 25,
    width: 26.58,
  },
  infoText: {
    color: PrimaryColors.base,
  },
  disabledIcon: {
    color: Shades.gray20,
  },
  enabledIcon: {
    color: PrimaryColors.base,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const rowsPerPage = 5;

interface IAppTablePaginationProps {
  page: number;
  setPage: (value: number) => void;
  numberOfRecords: number;
}

export const AppTablePagination: VFC<IAppTablePaginationProps> = (props) => {
  const { page, setPage, numberOfRecords } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const infoText = useMemo(() => {
    const calculatedFrom = page === 0 ? 1 : rowsPerPage * page;
    const from = numberOfRecords === 0 ? 0 : calculatedFrom;
    const calculatedTo = page === 0 ? rowsPerPage : rowsPerPage * page + rowsPerPage;
    const to = calculatedTo > numberOfRecords ? numberOfRecords : calculatedTo;

    return t("table.labelDisplayedRows", { from, to, count: numberOfRecords });
  }, [numberOfRecords, page, t]);

  const maxPage = useMemo(() => {
    const maxPage = Math.ceil(numberOfRecords / rowsPerPage);
    return maxPage > 0 ? maxPage - 1 : maxPage; // page has zero based index
  }, [numberOfRecords]);

  const hasNextPage = useMemo(() => {
    return page < maxPage;
  }, [page, maxPage]);

  const hasPreviousPage = useMemo(() => {
    return page > 0;
  }, [page]);

  const isLastPage = useMemo(() => {
    return page === maxPage;
  }, [page, maxPage]);

  const isFirstPage = useMemo(() => {
    return page === 0;
  }, [page]);

  const onFirstPageClick = () => {
    if (isFirstPage) return;
    setPage(0);
  };

  const onLastPageClick = () => {
    if (isLastPage) return;
    setPage(maxPage);
  };

  const onNextPageClick = () => {
    if (!hasNextPage) return;
    setPage(page + 1);
  };

  const onPreviousPageClick = () => {
    if (!hasPreviousPage) return;
    setPage(page - 1);
  };

  return (
    <Grid container direction="row" justifyContent="flex-end">
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <NavigationLeftLast
              className={classNames(classes.icon, {
                [classes.enabledIcon]: !isFirstPage,
                [classes.disabledIcon]: isFirstPage,
              })}
              viewBox="0 0 25 26.58"
              onClick={onFirstPageClick}
            />
          </Grid>
          <Grid item>
            <NavigationLeft
              className={classNames(classes.icon, {
                [classes.enabledIcon]: hasPreviousPage,
                [classes.disabledIcon]: !hasPreviousPage,
              })}
              viewBox="0 0 25 26.58"
              onClick={onPreviousPageClick}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.infoText}>
              {infoText}
            </Typography>
          </Grid>
          <Grid item>
            <NavigationRight
              className={classNames(classes.icon, {
                [classes.enabledIcon]: hasNextPage,
                [classes.disabledIcon]: !hasNextPage,
              })}
              viewBox="0 0 25 26.58"
              onClick={onNextPageClick}
            />
          </Grid>
          <Grid item>
            <NavigationRightLast
              className={classNames(classes.icon, {
                [classes.enabledIcon]: !isLastPage,
                [classes.disabledIcon]: isLastPage,
              })}
              viewBox="0 0 25 26.58"
              onClick={onLastPageClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
