import { makeStyles, Theme, Typography } from "@material-ui/core";
import { isArray } from "lodash";
import { ReactNode, useCallback, useMemo, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { WarningColors } from "../../../../style/Colors";
import { getFormattedDomainAddress } from "../../../../utils/address.util";
import { IAddressLean } from "../../../domain/address/address-lean";
import { hasGroupCfmContracts, hasGroupPomAndCfmContracts, hasGroupPomContracts } from "../../../domain/group/group";
import { getRolePomTranslation, RolePom } from "../../../domain/user/role-pom";
import { isAdmin } from "../../../domain/user/user";
import { getUserTypeLevelTranslation, UserTypeLevel } from "../../../domain/user/user-type-level";
import { ALL_ADDRESSES_OPTION } from "../../address/address-form/address-form.util";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormField } from "../../form/form-field.component";
import { IMutateUserFormInputs } from "../create-user-form/create-user-form.component";
import { useUserFormContext } from "./user-form.provider";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: WarningColors.base,
  },
}));

interface IUserFormRoleSelectsProps {
  inputsDisabled: boolean;
  jobTitleAndExternalIdHidden: boolean;
  userTypeLevelSelectOptions: UserTypeLevel[];
}

export const UserFormRoleSelects: VFC<IUserFormRoleSelectsProps> = (props) => {
  const { inputsDisabled, jobTitleAndExternalIdHidden, userTypeLevelSelectOptions } = props;
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, submitCount },
    watch,
  } = useFormContext<IMutateUserFormInputs>();
  const classes = useStyles();
  const { groupAddresses, wasteProducerAddresses } = useUserFormContext();
  const { internalUser } = useAuthContext();

  // @ts-ignore - fix circular dependency
  const group = watch("group");
  const isPom = hasGroupPomContracts(group);
  const isCfm = hasGroupCfmContracts(group);
  const userTypeLevel = watch("userTypeLevel");
  const rolePom = watch("rolePom");

  const hasPomAndCfmContracts = useMemo(() => {
    if (!group) {
      return false;
    }
    return hasGroupPomAndCfmContracts(group);
  }, [group]);

  const isCfmWasteProducer = useMemo(() => internalUser?.userTypeLevel === UserTypeLevel.WasteProducer, [internalUser]);
  const isCfmWasteProducerAndNotAdmin = useMemo(
    () => internalUser?.userTypeLevel === UserTypeLevel.WasteProducer && !isAdmin(internalUser),
    [internalUser],
  );
  const isCfmCorporate = useMemo(() => internalUser?.userTypeLevel === UserTypeLevel.Corporate, [internalUser]);
  const displayCfmSelect = useMemo(
    () => (isCfm || hasPomAndCfmContracts) && (isAdmin(internalUser) || isCfmCorporate || isCfmWasteProducer),
    [isCfm, hasPomAndCfmContracts, internalUser, isCfmWasteProducer, isCfmCorporate],
  );
  const displayPomSelect = useMemo(
    () => (isPom || hasPomAndCfmContracts) && (isAdmin(internalUser) || internalUser?.rolePom === RolePom.MainContact),
    [isPom, hasPomAndCfmContracts, internalUser],
  );
  // if only one of the two selects is displayed it has to be required
  const isRequired = useMemo(
    () => displayCfmSelect === false || displayPomSelect === false,
    [displayCfmSelect, displayPomSelect],
  );

  const displayErrorInfo = useMemo(() => {
    return !isRequired && submitCount > 0 && !userTypeLevel && !rolePom;
  }, [isRequired, submitCount, userTypeLevel, rolePom]);

  const getGroupAddressesAutocomplete = (): ReactNode => {
    const addressOptions = isCfmWasteProducerAndNotAdmin ? wasteProducerAddresses : groupAddresses;
    return (
      <FormAutocomplete<IAddressLean>
        name={"userAddresses"}
        multiple={true}
        getOptionLabel={(option) => {
          if (option.id === ALL_ADDRESSES_OPTION) {
            return option.formattedAddress ?? "-";
          }
          return getFormattedDomainAddress(option, t);
        }}
        transformValueOnChange={(value) => {
          if (isArray(value) && value.some((v) => v.id === ALL_ADDRESSES_OPTION)) {
            return addressOptions.filter((a) => a.id !== ALL_ADDRESSES_OPTION);
          }
          return value;
        }}
        rules={{ required: true }}
        label={t("create.user.all.addresses")}
        options={addressOptions}
        control={control}
        disabled={inputsDisabled}
        error={Boolean(errors?.userAddresses)}
        getOptionSelected={(option: IAddressLean, value: IAddressLean) => option.id === value.id}
      />
    );
  };

  const groupHasSelectableAddresses = useCallback(() => {
    return groupAddresses.some((g) => g.id !== ALL_ADDRESSES_OPTION);
  }, [groupAddresses]);

  return (
    <>
      {displayCfmSelect && (
        <>
          {!jobTitleAndExternalIdHidden && !inputsDisabled && <FormField />}
          <FormField>
            <FormAutocomplete<UserTypeLevel>
              name={"userTypeLevel"}
              control={control}
              label={t("general.user.type")}
              options={userTypeLevelSelectOptions}
              error={Boolean(errors?.userTypeLevel) || displayErrorInfo}
              helperText={errors?.userTypeLevel?.message}
              rules={{ required: isRequired }}
              getOptionLabel={(userTypeLevel) => t(getUserTypeLevelTranslation(userTypeLevel))}
              disabled={inputsDisabled || isCfmWasteProducerAndNotAdmin}
            />
          </FormField>
          <FormField>
            {userTypeLevel === UserTypeLevel.WasteProducer &&
              groupHasSelectableAddresses() &&
              getGroupAddressesAutocomplete()}
          </FormField>
        </>
      )}

      {displayPomSelect && (
        <>
          <FormField>
            <FormAutocomplete<RolePom>
              name={"rolePom"}
              control={control}
              label={t("basedata.users.edit.pom_type")}
              options={[RolePom.Announcer, RolePom.MainContact]}
              error={Boolean(errors?.rolePom) || displayErrorInfo}
              helperText={errors?.rolePom?.message}
              rules={{
                required: isRequired,
              }}
              getOptionLabel={(rolePom) => t(getRolePomTranslation(rolePom))}
              disabled={inputsDisabled}
            />
          </FormField>
        </>
      )}
      {displayErrorInfo && (
        <FormField md={12}>
          <Typography variant="body1" className={classes.error}>
            {t("create.user.roleOrTypeRequired")}
          </Typography>
        </FormField>
      )}
    </>
  );
};
