import React from "react";
import { useTranslation } from "react-i18next";
import { IGroup } from "../../../domain/group/group";
import { IUser } from "../../../domain/user/user";
import { AppDialog } from "../../dialog/app-dialog.component";
import { IUserInitialValues, UserForm, UserFormMode } from "../user-form/user-form.component";
import { UserFormProvider } from "../user-form/user-form.provider";

interface INewUserModalProps {
  open: boolean;
  group?: IGroup;
  onCancel: () => void;
  savedCallback: (user?: IUser) => void;
  initialValues?: IUserInitialValues;
  mode: UserFormMode;
}

export const UserFormModal: React.FunctionComponent<INewUserModalProps> = (props) => {
  const { group, open, onCancel, savedCallback, initialValues, mode } = props;
  const { t } = useTranslation();

  const getHeadingText = (): string => {
    switch (mode) {
      case UserFormMode.Edit:
        return t("basedata.users.edit.text");
      case UserFormMode.Detail:
        return `${t("basedata.users.detail")}`;
      case UserFormMode.Create:
        return t("general.user.data");
    }
  };

  return (
    <AppDialog open={open} title={getHeadingText()}>
      <UserFormProvider group={group}>
        <UserForm
          user={initialValues}
          userGroup={group}
          mode={mode}
          onCancelCallback={onCancel}
          onSuccessCallback={savedCallback}
          displayHeading={false}
          jobTitleAndExternalIdHidden
        />
      </UserFormProvider>
    </AppDialog>
  );
};
