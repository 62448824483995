import { makeStyles } from "@material-ui/core";

export const useAdvancePasswordFieldStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  popup: {
    width: "100%",
    position: "absolute",
    top: "4.2em",
    left: 0,
    zIndex: 9998,
    backgroundColor: theme.palette.background.paper,
    padding: "1em",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: theme.shadows[2],
  },
  list: {
    margin: 0,
    padding: 0,
    "& li": {
      display: "flex",
      fontSize: "0.95em",
    },
  },
  icon: {
    margin: "0px 5px",
  },
}));
