import { Grid, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CfmServiceDate } from "../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { PADDING_STATUS } from "../../../config/theme/overrides/constant";
import { useScreenSize } from "../../../hooks/use-screen-size";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";

const useStyles = makeStyles({
  itemContainer: {
    display: "flex",
  },
  root: {
    height: "40px",
    border: `2px solid ${Colors.greenLight}`,
    padding: PADDING_STATUS,
    alignItems: "center",
    margin: "0 0 0",
    backgroundColor: Shades.white,
    borderRadius: 4,
  },
  hover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      border: `2px solid ${PrimaryColors.hover}`,
      "& .MuiTypography-root": {
        color: Shades.white,
      },
    },
  },
  mobileRoot: {
    height: "40px",
    color: Colors.greenLight,
    border: `2px solid ${Colors.greenLight}`,
    padding: PADDING_STATUS,
    alignItems: "center",
    margin: "0 0px 0px",
    backgroundColor: Shades.white,
    borderRadius: 4,
    "& .MuiTypography-root": {
      color: Colors.greenLight,
    },
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      cursor: "pointer",
      "& .MuiTypography-root": {
        color: Shades.white,
      },
      border: `2px solid ${PrimaryColors.hover}`,
    },
  },
  active: {
    backgroundColor: PrimaryColors.active,
    "& .MuiTypography-root": {
      color: Shades.white,
    },
    border: `2px solid ${PrimaryColors.active}`,
  },
  disabled: {
    border: `2px solid ${Colors.grayDark}`,
    color: Colors.red,
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  text: {
    color: Colors.greenLight,
  },
  disabledText: {
    color: Colors.grayDark,
  },
});

interface IProductResponseServiceDateFilterProps {
  cfmServiceDate: CfmServiceDate;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const ProductResponseServiceDateFilter: FC<IProductResponseServiceDateFilterProps> = (props) => {
  const { cfmServiceDate, onClick, isActive, disabled } = props;
  const { isLaptop, isDesktop } = useScreenSize();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
      onClick={!disabled && onClick ? onClick : undefined}
      className={classNames({
        [classes.root]: isLaptop || isDesktop,
        [classes.mobileRoot]: !isLaptop && !isDesktop,
        [classes.active]: isActive,
        [classes.hover]: onClick && !disabled,
        [classes.disabled]: disabled,
      })}
      alignContent="center"
    >
      <Typography
        variant="body1"
        className={classNames({
          [classes.text]: !disabled,
          [classes.disabledText]: disabled,
        })}
      >
        {t(`configuration.responseAssignments.dates.${cfmServiceDate}`)}
      </Typography>
    </Grid>
  );
};
