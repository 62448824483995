import { UserTypeLevel } from "./../domain/user/user-type-level";
import { getTranslationKeyOfPeriod } from "../../put-on-market/repositories/models/announcements/pom-announcement.period";
import { translateCountryByCode } from "../../utils/address.util";
import { getGroupContractTypeTranslation, getGroupTypeTranslation } from "../../utils/group.helper";
import { IFormattedGroupHistory } from "../domain/group/group-history";
import {
  GroupHistoryAddressInfoColumn,
  GroupHistoryGroupContractInfoColumn,
  GroupHistoryGroupGroupTypeInfoColumn,
  GroupHistoryGroupInfoColumn,
  GroupHistoryPaymentInfoColumn,
  GroupHistoryUserInfoColumn,
} from "../domain/group/group-history-info-column";
import { GroupHistoryInformationType } from "../domain/group/group-history-information-type";
import { GroupType } from "../domain/group/group-type";
import { getRolePomTranslation } from "../domain/user/role-pom";
import { GroupContractType } from "../models/group/group-contract.model";
import { TranslateFunc } from "../../utils/translation.utils";
import { formatDateLong, formatDateShort } from "./date.util";

export const translateColumnName = (columName: string, type: GroupHistoryInformationType, t: TranslateFunc) => {
  switch (type) {
    case GroupHistoryInformationType.AddressInfo:
      return t(`basedata.groups.historyTable.address.fields.${columName}`);
    case GroupHistoryInformationType.GroupInfo:
      return t(`basedata.groups.historyTable.group.fields.${columName}`);
    case GroupHistoryInformationType.UserInfo:
      return t(`basedata.groups.historyTable.user.fields.${columName}`);
    case GroupHistoryInformationType.PaymentInfo:
      return t(`basedata.groups.historyTable.paymentInfo.fields.${columName}`);
    case GroupHistoryInformationType.GroupGroupTypeInfo:
      return t(`basedata.groups.historyTable.groupGroupType.fields.${columName}`);
    case GroupHistoryInformationType.GroupContractInfo:
      return t(`basedata.groups.historyTable.contract.fields.${columName}`);
  }
};

const translateAnnouncementPeriod = (value: any, t: TranslateFunc) => {
  return t(getTranslationKeyOfPeriod(value));
};

const translatePomRole = (value: any, t: TranslateFunc) => {
  return t(getRolePomTranslation(value));
};

const translateUserTypeLevel = (value: any, t: TranslateFunc) => {
  if (value === UserTypeLevel.Corporate) {
    return t("create.user.type.corporate");
  } else if (value === UserTypeLevel.WasteProducer) {
    return t("create.user.type.waste_producer");
  }
  return value;
};

const translateCountry = (value: any, t: TranslateFunc) => {
  return translateCountryByCode(value, t);
};

const translateBoolean = (value: any, t: TranslateFunc) => {
  if (!value) return "-";

  return value === "true" || value === "1" ? t("basedata.general.yes.text") : t("basedata.general.no.text");
};

const translateGroupType = (value: any, t: TranslateFunc) => {
  if (!value) return "-";
  return getGroupTypeTranslation(value as GroupType, t);
};

const translateGroupContractType = (value: any, t: TranslateFunc) => {
  if (!value) return "-";
  return getGroupContractTypeTranslation(value as GroupContractType, t);
};

const translateGroupContractDate = (value: any, _t: TranslateFunc) => {
  return formatDateShort(value);
};

type FormattingFunction = (value: any, t: TranslateFunc) => string;

interface IFormattingRule {
  columnName:
    | GroupHistoryGroupInfoColumn
    | GroupHistoryAddressInfoColumn
    | GroupHistoryUserInfoColumn
    | GroupHistoryPaymentInfoColumn
    | GroupHistoryGroupGroupTypeInfoColumn
    | GroupHistoryGroupContractInfoColumn;
  formattingFunc: FormattingFunction;
}

const groupInfoFormattingRules: IFormattingRule[] = [
  {
    columnName: GroupHistoryGroupInfoColumn.Active,
    formattingFunc: formatDateLong,
  },
  {
    columnName: GroupHistoryGroupInfoColumn.ContractStartDate,
    formattingFunc: formatDateShort,
  },
  {
    columnName: GroupHistoryGroupInfoColumn.ContractEndDate,
    formattingFunc: formatDateShort,
  },
  {
    columnName: GroupHistoryGroupInfoColumn.PomAnnouncementPeriod,
    formattingFunc: translateAnnouncementPeriod,
  },
  {
    columnName: GroupHistoryGroupInfoColumn.HasAcceptedSepaMandate,
    formattingFunc: translateBoolean,
  },
  {
    columnName: GroupHistoryGroupInfoColumn.GrsManagedEar,
    formattingFunc: translateBoolean,
  },
  {
    columnName: GroupHistoryGroupInfoColumn.DefaultPriceGroup,
    formattingFunc: translateBoolean,
  },
];
const addressInfoFormattingRules: IFormattingRule[] = [
  {
    columnName: GroupHistoryAddressInfoColumn.Country,
    formattingFunc: translateCountry,
  },
  {
    columnName: GroupHistoryAddressInfoColumn.Active,
    formattingFunc: formatDateLong,
  },
];
const userInfoFormattingRules: IFormattingRule[] = [
  {
    columnName: GroupHistoryUserInfoColumn.RolePom,
    formattingFunc: translatePomRole,
  },
  {
    columnName: GroupHistoryUserInfoColumn.UserTypeLevel,
    formattingFunc: translateUserTypeLevel,
  },
  {
    columnName: GroupHistoryUserInfoColumn.MustResetPassword,
    formattingFunc: translateBoolean,
  },
  {
    columnName: GroupHistoryUserInfoColumn.Active,
    formattingFunc: formatDateLong,
  },
];
const paymentInfoFormattingRules: IFormattingRule[] = [];
const groupGroupTypeInfoFormattingRules: IFormattingRule[] = [
  {
    columnName: GroupHistoryGroupGroupTypeInfoColumn.TypeId,
    formattingFunc: translateGroupType,
  },
];
const groupContractInfoFormattingRules: IFormattingRule[] = [
  {
    columnName: GroupHistoryGroupContractInfoColumn.Type,
    formattingFunc: translateGroupContractType,
  },
  {
    columnName: GroupHistoryGroupContractInfoColumn.StartDate,
    formattingFunc: translateGroupContractDate,
  },
  {
    columnName: GroupHistoryGroupContractInfoColumn.EndDate,
    formattingFunc: translateGroupContractDate,
  },
  {
    columnName: GroupHistoryGroupContractInfoColumn.DefaultPriceGroup,
    formattingFunc: translateBoolean,
  },
  {
    columnName: GroupHistoryGroupContractInfoColumn.PomAnnouncementPeriod,
    formattingFunc: translateAnnouncementPeriod,
  },
];

export const formatValue = (columnName: string, value: any, type: GroupHistoryInformationType, t: TranslateFunc) => {
  let formattingRule: IFormattingRule | undefined;
  switch (type) {
    case GroupHistoryInformationType.AddressInfo:
      formattingRule = addressInfoFormattingRules.find((rule) => rule.columnName === columnName);
      break;
    case GroupHistoryInformationType.GroupInfo:
      formattingRule = groupInfoFormattingRules.find((rule) => rule.columnName === columnName);
      break;
    case GroupHistoryInformationType.UserInfo:
      formattingRule = userInfoFormattingRules.find((rule) => rule.columnName === columnName);
      break;
    case GroupHistoryInformationType.PaymentInfo:
      formattingRule = paymentInfoFormattingRules.find((rule) => rule.columnName === columnName);
      break;
    case GroupHistoryInformationType.GroupGroupTypeInfo:
      formattingRule = groupGroupTypeInfoFormattingRules.find((rule) => rule.columnName === columnName);
      break;
    case GroupHistoryInformationType.GroupContractInfo:
      formattingRule = groupContractInfoFormattingRules.find((rule) => rule.columnName === columnName);
  }

  if (formattingRule && value) {
    return formattingRule.formattingFunc(value, t);
  }

  return value?.length ? value : "-";
};

export const getTranslatedInfoType = (history: IFormattedGroupHistory, t: TranslateFunc) => {
  return t(`basedata.groups.historyTable.infoType.${history.informationType}`);
};

export const getUpdatedBy = (history: IFormattedGroupHistory, t: TranslateFunc) => {
  if (!history.updatedBy || !history.updatedBy.group) {
    return t("basedata.groups.historyTable.noUpdatedByInfo");
  }

  return `${history.updatedBy.group.name} (${history.updatedBy.fullName})`;
};
