import { Grid, Typography } from "@material-ui/core";
import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { OrderNewFreshDeskForm } from "../form/order-new-freshdesk-form.component";

interface IProductSelectionHintProps {
  open: boolean;
  onClose: () => void;
}

export const ProductSelectionHint: VFC<IProductSelectionHintProps> = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const [contactFormOpen, setContactFormOpen] = useState<boolean>(false);
  const chemistries = [
    "lfp",
    "nmc",
  ]
  const conditions = [
    "securedForTransport",
    "damagedSecuredForTransport",
    "crititallyDamaged",
  ]

  return (
    <AppDialog
      open={open}
      title={t("orders.new.wizard.product.hint.title")}
      onAcceptClick={onClose}
      fullWidth={true}
      acceptTextOverride={t("general.close")}
      onContactClick={() => setContactFormOpen(true)}
      isContactVisible={true}
    >
      <Grid container xs={12} justifyContent="center" alignItems="flex-start" spacing={2} direction="column">
        <Grid item>
          <Typography variant="h5">{t("orders.new.wizard.product.hint.deviceBatteries.title")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {t("orders.new.wizard.product.hint.deviceBatteries.description")}
            <a href={t("orders.new.wizard.product.hint.deviceBatteries.link")} target="_blank" rel="noreferrer">
              <b>{t("orders.new.wizard.product.hint.deviceBatteries.link")}</b>
            </a>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">{t("orders.new.wizard.product.hint.industrialBatteries.title")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{t("orders.new.wizard.product.hint.industrialBatteries.description")}</Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6"><b>{t("orders.new.wizard.product.hint.chemistryTitle")}</b></Typography>
        </Grid>

        <Grid item>
          <ul>
            {chemistries.map((value) => {
              return (
                <li>
                  <Typography variant="body1">
                    <b>{t("orders.new.wizard.product.hint.chemistry." + value + ".title")}</b>
                     - {t("orders.new.wizard.product.hint.chemistry." + value + ".description")}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </Grid>

        <Grid item>
          <Typography variant="h6"><b>{t("orders.new.wizard.product.hint.structure.title")}</b></Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{t("orders.new.wizard.product.hint.structure.description")}</Typography>
        </Grid>

        <Grid item>
          <Typography variant="h6"><b>{t("orders.new.wizard.product.hint.conditionTitle")}</b></Typography>
          <Typography variant="body1"><b>{t("orders.new.wizard.product.hint.conditionHint.title")}</b></Typography>
          <Typography variant="body1">{t("orders.new.wizard.product.hint.conditionHint.description")}</Typography>
        </Grid>

        {conditions.map((value) => {
          return (
            <Grid item>
              <Typography variant="body1"><b>{t("orders.new.wizard.product.hint.condition." + value + ".title")}</b></Typography>
              <Typography variant="body1">{t("orders.new.wizard.product.hint.condition." + value + ".description")}</Typography>
            </Grid>
          );
        })}
      </Grid>
      <OrderNewFreshDeskForm isOpen={contactFormOpen} setIsOpen={setContactFormOpen}/>
    </AppDialog>
  );
};
