import { Grid } from "@material-ui/core";
import dayjs from "dayjs";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { getFormattedDomainAddress } from "../../../utils/address.util";
import { ICfmOrderX } from "../../domain/order-x/cfm-order-x";
import { OrderOfTruckloadSorting } from "../../pages/order-x/order-truckload.provider";
import { IOrdersOfTruckloadFilter } from "./edit-truckload-content.component";
import { OrderOfTruckloadRow } from "./order-of-truckload-row.component";

interface IOrdersOfTruckloadListProps {
  truckloadOrders: ICfmOrderX[];
  filter: IOrdersOfTruckloadFilter;
  onRemoveClick: (orderId: number) => void;
  isRemoveEnabled: boolean;
}

export const OrdersOfTruckloadList: VFC<IOrdersOfTruckloadListProps> = (props) => {
  const { truckloadOrders, onRemoveClick, filter, isRemoveEnabled } = props;
  const { t } = useTranslation();

  const orders = useMemo(() => {
    let ordersToUse = truckloadOrders;

    if (filter.query !== undefined) {
      ordersToUse = ordersToUse.filter((order) => {
        const customerAddress = order.customerAddress ? getFormattedDomainAddress(order.customerAddress, t) : undefined;
        const query = filter.query?.toLowerCase();
        if (!query) return true;

        return (
          query.includes(order.id.toString()) ||
          (order.purchaseOrderNumber && order.purchaseOrderNumber.toLowerCase().includes(query)) ||
          (order.contactPersonName && order.contactPersonName.toLowerCase().includes(query)) ||
          (customerAddress && customerAddress.toLowerCase().includes(query))
        );
      });
    }

    if (filter.sorting === OrderOfTruckloadSorting.OrderNumber) {
      ordersToUse = ordersToUse.sort((o1, o2) => o2.id - o1.id);
    }

    if (filter.sorting === OrderOfTruckloadSorting.WarehouseEntryDate) {
      ordersToUse = ordersToUse.sort((o1, o2) => {
        if (!o2.logisticWarehouseDate || !o1.logisticWarehouseDate) return 1;
        return dayjs(o2.logisticWarehouseDate).isBefore(dayjs(o1.logisticWarehouseDate)) ? -1 : 1;
      });
    }
    return ordersToUse;
  }, [truckloadOrders, filter.query, filter.sorting, t]);

  return (
    <Grid container spacing={1}>
      {orders &&
        orders.map((order) => {
          return (
            <Grid item xs={12} key={order.id}>
              <OrderOfTruckloadRow
                order={order}
                onRemoveClick={() => onRemoveClick(order.id)}
                isRemoveEnabled={isRemoveEnabled}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};
