import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAutocomplete } from "../../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { useGetCancelationReasonsQuery } from "../../../../../repositories/queries/order-x/queries/get-cancelation-reasons.query";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";

export interface IOrderLogisticMistripAction {
  cancelationReasonId: number;
  reOrder: boolean;
}

export const OrderLogisticMistripAction: VFC = () => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    control,
  } = useFormContext<ICfmResponseValueFormInputs>();

  const { isLoading, data: reasons } = useGetCancelationReasonsQuery();

  return (
    <>
      <FormField md={12} className={"pb-4"}>
        <FormAutocomplete<number>
          name={"logisticMistrip.cancelationReasonId"}
          control={control}
          label={t("general.reason")}
          options={(reasons ?? []).map((r) => r.id)}
          isLoading={isLoading}
          getOptionLabel={(value) => {
            const reason = (reasons ?? []).find((r) => r.id === value);
            return reason?.text ?? "";
          }}
          error={Boolean(errors?.logisticMistrip?.cancelationReasonId)}
          rules={{ required: true }}
        />
      </FormField>
    </>
  );
};
