import { Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { HistoryOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { Shades } from "../../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { IFormattedGroupHistory } from "../../../../domain/group/group-history";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { useGetGroupHistoryQuery } from "../../../../repositories/queries/group/get-group-history.query";
import { formatDateLong } from "../../../../util/date.util";
import {
  formatValue,
  getTranslatedInfoType,
  getUpdatedBy,
  translateColumnName,
} from "../../../../util/group-history.util";
import { AppTable, ITableRow } from "../../../../components/tables/app-table.component";
import { GroupHistoryDetailDialog } from "../dialogues/group-history-detail-dialog.component";
import { GroupDetailHeader } from "../group-detail-header.component";

const useStyles = makeStyles((theme: Theme) => ({
  textAlign: {
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  divider: {
    backgroundColor: Shades.gray20,
    marginTop: 0,
  },
}));

interface IGroupDetailsGroupHistoriesTableProps {
  groupId: number;
}

export const GroupDetailsGroupHistoriesTable: VFC<IGroupDetailsGroupHistoriesTableProps> = (props) => {
  const { groupId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedHistory, setSelectedHistory] = useState<IFormattedGroupHistory | undefined>();
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });

  const {
    isLoading,
    data: groupHistoryResult,
    refetch,
  } = useGetGroupHistoryQuery(groupId, searchText, paginationInputMeta);

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const onSearchSubmit = (value: string | undefined) => {
    setPaginationInputMeta({ ...paginationInputMeta, page: 0 });
    setSearchText(value);
  };

  const searchFieldProps = {
    tooltip: t("basedata.groups.historyTable.search"),
    placeholder: t("basedata.groups.historyTable.searchPlacehoder"),
    onSearchSubmit,
  };

  const getGroupHistoryLabelComponent = (history: IFormattedGroupHistory) => {
    const textParts: string[] = [];
    textParts.push(getTranslatedInfoType(history, t));
    textParts.push(history.changedTableEntryId.toString());
    textParts.push(translateColumnName(history.columnName, history.informationType, t));
    textParts.push(formatValue(history.columnName, history.oldValue, history.informationType, t) ?? "-");
    textParts.push(formatValue(history.columnName, history.newValue, history.informationType, t));
    textParts.push(formatDateLong(history.updatedAt));
    textParts.push(getUpdatedBy(history, t));

    return (
      <Grid container direction="column">
        <Grid item>
          <Typography
            variant="body1"
            className={classNames(classes.bold, {
              [classes.textAlign]: isMobile,
            })}
          >
            {t("basedata.groups.historyTable.description")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            className={classNames({
              [classes.textAlign]: isMobile,
            })}
          >
            {textParts.join(`${TEXT_SEPARATOR} `)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getMappedEntries = (histories: IFormattedGroupHistory[] | undefined): ITableRow[] => {
    if (!histories) return [];

    return histories.map((history) => {
      return {
        id: `${history.id}-${history.columnName}`,
        labelComponent: getGroupHistoryLabelComponent(history),
        iconComponent: <HistoryOutlined fontSize="large" />,
        isActive: undefined,
      };
    });
  };

  if ((groupHistoryResult?.entries ?? []).length === 0 && searchText === undefined) return null;

  return (
    <>
      <GroupHistoryDetailDialog history={selectedHistory} onClose={() => setSelectedHistory(undefined)} />

      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <GroupDetailHeader>
              <Typography variant="h1">{t("basedata.groups.historyTable.heading")}</Typography>
            </GroupDetailHeader>
          </Grid>
          <Grid item>
            <AppTable
              entries={getMappedEntries(groupHistoryResult?.entries)}
              searchFieldProps={searchFieldProps}
              paginationInputMeta={paginationInputMeta}
              setPaginationInputMeta={setPaginationInputMeta}
              numberOfRecords={groupHistoryResult?.meta?.numberOfRecords ?? 0}
              onRowClicked={(tableRow) => {
                const history = (groupHistoryResult?.entries ?? []).find(
                  (history) => `${history.id}-${history.columnName}` === (tableRow.id as string),
                );
                setSelectedHistory(history);
              }}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider className={classes.divider} />
      </Grid>
    </>
  );
};
