import React, { FC, useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { PomAnnouncementPeriod } from "../../../../put-on-market/repositories/models/announcements/pom-announcement.period";
import { instanceOfAxiosError } from "../../../../utils/rest";
import { GroupContractConverter } from "../../../domain/converter/group-contract.converter";
import {
  ICfmGroupContractField,
  ICreateGroupContractRequest,
  IGroupContract,
  IMutateGroupContractRequest,
  IPomGroupContractField,
} from "../../../domain/group/group-contract";
import { cfmGroupTypes, GroupType } from "../../../domain/group/group-type";
import { GroupContractType } from "../../../models/group/group-contract.model";
import {
  useCreateGroupContract,
  useUpdateGroupContract,
} from "../../../repositories/queries/group/group-contract-mutation.query";
import { SharedQueryKeys } from "../../../repositories/queries/shared-query-keys";
import { useCustomForm } from "../../../util/form.util";
import { AppDialog } from "../../dialog/app-dialog.component";
import { GroupContractFormDetailComponent } from "../group-contract-form/group-contract-form-detail.component";
import {
  GroupContractForm,
  GroupContractFormMode,
  IGroupContractFormInputs,
} from "../group-contract-form/group-contract-form.component";

interface IGroupContractFormModalProps {
  open: boolean;
  onCancel: () => void;
  onSaved: () => void;
  baseContractInitialValues?: IGroupContract;
  cfmContractInitialValues?: ICfmGroupContractField;
  pomContractInitialValues?: IPomGroupContractField;
  initialMode: GroupContractFormMode;
  groupId: number;
  groupType: GroupType;
  readOnly?: boolean;
}

export const GroupContractFormModal: FC<IGroupContractFormModalProps> = (props) => {
  const {
    open,
    onCancel,
    onSaved,
    baseContractInitialValues,
    cfmContractInitialValues,
    pomContractInitialValues,
    initialMode,
    groupId,
    groupType,
    readOnly,
  } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const getInitialType = (): GroupContractType | undefined => {
    // If groupType is customer and contract type is not set, the form will handle it via the dropdown
    if (baseContractInitialValues?.type || groupType === GroupType.Customer) {
      return baseContractInitialValues?.type;
    }

    if (cfmGroupTypes.includes(groupType)) {
      return GroupContractType.Cfm;
    } else {
      return GroupContractType.Pom;
    }
  };

  const defaultValues: Partial<IGroupContractFormInputs> = {
    id: baseContractInitialValues?.id,
    productCategoryId: cfmContractInitialValues?.productCategory.id,
    groupId: groupId,
    contractNumber: baseContractInitialValues?.contractNumber,
    startDate: baseContractInitialValues?.startDate,
    endDate: baseContractInitialValues?.endDate,
    defaultPriceGroup: pomContractInitialValues?.defaultPriceGroup,
    takeBackSystemId: pomContractInitialValues?.takeBackSystem.id,
    type: getInitialType(),
    announcementPeriod: pomContractInitialValues?.pomAnnouncementPeriod ?? PomAnnouncementPeriod.Monthly,
  };
  const formMethods = useCustomForm<IGroupContractFormInputs>({ defaultValues });
  const { handleSubmit, reset } = formMethods;

  const { isLoading: isCreateGroupContractLoading, mutateAsync: createGroupContract } = useCreateGroupContract();
  const { isLoading: isUpdateGroupContractLoading, mutateAsync: updateGroupContract } = useUpdateGroupContract();
  const isLoading = isCreateGroupContractLoading || isUpdateGroupContractLoading;

  const [apiErrorCode, setApiErrorCode] = useState<string | undefined>();
  const [formMode, setFormMode] = useState<GroupContractFormMode>(initialMode);

  const onSubmit: SubmitHandler<IGroupContractFormInputs> = async (inputs) => {
    if (formMode === GroupContractFormMode.Create) {
      try {
        const requestData: ICreateGroupContractRequest = GroupContractConverter.inputToCreateRequest(inputs);
        await createGroupContract({ groupId: inputs.groupId, data: requestData });
      } catch (error) {
        const errorCode = instanceOfAxiosError(error) ? error.response?.data.errorCode : undefined;
        setApiErrorCode(errorCode);
        return;
      }
    } else if (formMode === GroupContractFormMode.Edit && baseContractInitialValues?.id) {
      const requestData: IMutateGroupContractRequest = GroupContractConverter.inputToMutateRequest(inputs);
      await updateGroupContract({
        groupId: inputs.groupId,
        contractId: baseContractInitialValues?.id,
        data: requestData,
      });
    }
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    reset();
    onSaved();
  };

  const handleAcceptButtonClick = (): void => {
    if (formMode === GroupContractFormMode.Detail) {
      setFormMode(GroupContractFormMode.Edit);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleCancelButtonClick = (): void => {
    reset();
    setFormMode(initialMode);
    onCancel();
  };

  const getAcceptText = (): string => {
    if (formMode === GroupContractFormMode.Detail) {
      return t("general.edit.text");
    } else {
      return t("general.save.text");
    }
  };

  return (
    <AppDialog
      open={open}
      title={t(`basedata.groups.groupContracts.${formMode}`)}
      alignButtons={"space-between"}
      onCancelClick={handleCancelButtonClick}
      onAcceptClick={readOnly ? undefined : handleAcceptButtonClick}
      acceptTextOverride={getAcceptText()}
      isLoading={isLoading}
    >
      {open && formMode !== GroupContractFormMode.Detail && (
        <FormProvider {...formMethods}>
          <GroupContractForm groupType={groupType} mode={formMode} apiErrorCode={apiErrorCode} />
        </FormProvider>
      )}
      {open && formMode === GroupContractFormMode.Detail && baseContractInitialValues && (
        <GroupContractFormDetailComponent
          baseContractValues={baseContractInitialValues}
          cfmContractValues={cfmContractInitialValues}
          pomContractValues={pomContractInitialValues}
        />
      )}
    </AppDialog>
  );
};
