import { createContext, useContext, useMemo, useState } from "react";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { IAddressLean } from "../../../shared/domain/address/address-lean";
import { AddressConverter } from "../../../shared/domain/converter/address.converter";
import { UserTypeLevel } from "../../../shared/domain/user/user-type-level";
import { useGetGroupAddresses } from "../../../shared/repositories/queries/address/get-group-addresses.query";
import { useGetUserAddresses } from "../../../shared/repositories/queries/address/get-user-addresses-for-id.query";
import { ICfmPackage } from "../../domain/packages/cfm-package";
import { ICfmProduct } from "../../domain/products/cfm-product";
import { useGetPackagesForProduct } from "../../repositories/queries/package/get-packages-for-product.query";
import { useGetAllProductsQuery } from "../../repositories/queries/product/query/get-products.query";
import { ITruckloadLeanFilter } from "./components/order-list-view/logistic-warehouse/logistic-warehouse-order-filter.component";
import { truckloadValidAddressTypes } from "./utils/truckload.utils";

interface IOrderTruckloadContextType {
  truckloadFilter: ITruckloadFilter;
  setTruckloadFilter: (filter: ITruckloadFilter) => void;
  isSelectDataSet: boolean;
  areProductsLoading: boolean;
  areAddressesLoading: boolean;
  arePackagesLoading: boolean;
  products: ICfmProduct[];
  addresses: IAddressLean[];
  packages: ICfmPackage[];
}

export enum OrderOfTruckloadSorting {
  OrderNumber = "OrderNumber",
  WarehouseEntryDate = "WarehouseEntryDate",
}

export interface ITruckloadFilter extends ITruckloadLeanFilter {
  stateId: number | undefined;
  fractionId: number | undefined;
  typeId: number | undefined;
  productId: number | undefined;
  packageId: number | undefined;
  logisticAddressId: number | undefined;
  destinationAddressId: number | undefined;
}

const OrderTruckloadContext = createContext<IOrderTruckloadContextType>({} as IOrderTruckloadContextType);

export const OrderTruckloadProvider = (props: any) => {
  const value = useOrderTruckloadProvider();
  return <OrderTruckloadContext.Provider value={value}>{props.children}</OrderTruckloadContext.Provider>;
};

export const useOrderTruckloadContext = () => {
  return useContext(OrderTruckloadContext);
};

const emptyTruckloadFilter: ITruckloadFilter = {
  stateId: undefined,
  fractionId: undefined,
  typeId: undefined,
  productId: undefined,
  packageId: undefined,
  logisticAddressId: undefined,
  destinationAddressId: undefined,
  sorting: OrderOfTruckloadSorting.WarehouseEntryDate,
  query: undefined,
  sortingMode: "DESC",
};

const useOrderTruckloadProvider = (): IOrderTruckloadContextType => {
  const { internalUser } = useAuthContext();
  const [truckloadFilter, setTruckloadFilter] = useState<ITruckloadFilter>(emptyTruckloadFilter);

  const isSelectDataSet = useMemo(
    () =>
      truckloadFilter.stateId !== undefined &&
      truckloadFilter.typeId !== undefined &&
      truckloadFilter.fractionId !== undefined &&
      truckloadFilter.productId !== undefined &&
      (truckloadFilter.logisticAddressId !== undefined || truckloadFilter.destinationAddressId !== undefined),
    [
      truckloadFilter.stateId,
      truckloadFilter.typeId,
      truckloadFilter.fractionId,
      truckloadFilter.productId,
      truckloadFilter.packageId,
      truckloadFilter.logisticAddressId,
      truckloadFilter.destinationAddressId,
    ],
  );

  const { isLoading: areProductsLoading, data: products } = useGetAllProductsQuery();
  const { data: groupAddressesResult, isLoading: groupAddressesLoading } = useGetGroupAddresses(
    internalUser?.group?.id,
    undefined,
    truckloadValidAddressTypes,
    internalUser?.group?.id !== undefined && internalUser.userTypeLevel === UserTypeLevel.Corporate,
    {
      page: 0,
      pageSize: 100,
    },
    true,
  );

  const { isLoading: arePackagesLoading, data: packages } = useGetPackagesForProduct(
    truckloadFilter.productId,
    undefined,
  );

  const { data: userAddressesResult, isLoading: userAddressesLoading } = useGetUserAddresses(
    internalUser?.id,
    truckloadValidAddressTypes,
    undefined,
    internalUser?.userTypeLevel === UserTypeLevel.WasteProducer,
  );

  const addresses = useMemo(() => {
    return internalUser?.userTypeLevel === UserTypeLevel.Corporate
      ? groupAddressesResult?.addresses ?? []
      : (userAddressesResult?.addresses ?? []).map(AddressConverter.domainToLeanAddressDomain);
  }, [groupAddressesResult, userAddressesResult, internalUser]);

  const areAddressesLoading = useMemo(
    () => userAddressesLoading || groupAddressesLoading,
    [userAddressesLoading, groupAddressesLoading],
  );

  return {
    truckloadFilter,
    setTruckloadFilter,
    isSelectDataSet,
    areProductsLoading,
    areAddressesLoading,
    arePackagesLoading,
    products: products ?? [],
    packages: packages ?? [],
    addresses,
  };
};
