import { Box, Dialog, Grid } from "@material-ui/core";
import { DialogHeader } from "components/Primitives";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ModalDefaultButtons } from "../Modals/modal-default-buttons";

interface IManualUnsavedDataPrompt {
  onOK: () => boolean;
  onCancel: () => boolean;
  open: boolean;
  text?: string;
  acceptText?: string;
  cancelText?: string;
}

export const ManualUnsavedDataPrompt: FC<IManualUnsavedDataPrompt> = (props) => {
  const { t } = useTranslation();
  const { onOK, onCancel, open, acceptText, cancelText, text } = props;

  return (
    <>
      {open && (
        <Dialog open={open}>
          <DialogHeader isSuccess={false}>{t("general.warning")}</DialogHeader>
          <Box p={4}>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                {text ?? t("general.unsavedData")}
              </Grid>
              <Grid item xs={12}>
                <ModalDefaultButtons
                  onComplete={onOK}
                  onCancel={onCancel}
                  okText={acceptText ?? t("general.leave")}
                  cancelText={cancelText ?? t("general.cancel.text")}
                />
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </>
  );
};
