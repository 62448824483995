import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getRolePomTranslation, RolePom } from "../../../domain/user/role-pom";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { IMutateUserFormInputs } from "./create-user-form.component";

interface ICreateUserPomSectionProps {
  isPomMainContact: boolean;
}

export const CreateUserPomCfmSection: VFC<ICreateUserPomSectionProps> = (props) => {
  const { isPomMainContact } = props;
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<IMutateUserFormInputs>();

  if (!isPomMainContact) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormAutocomplete<RolePom>
          name={"rolePom"}
          control={control}
          label={t("basedata.users.edit.pom_type")}
          options={[RolePom.Announcer, RolePom.MainContact]}
          error={Boolean(errors?.rolePom)}
          helperText={errors?.rolePom?.message}
          rules={{
            required: true,
          }}
          getOptionLabel={(rolePom) => t(getRolePomTranslation(rolePom))}
        />
      </Grid>
      <Grid item xs="auto" md={6} />
    </Grid>
  );
};
