import getInstance from "../../../../../utils/rest";
import { SharedQueryKeys } from "../../shared-query-keys";
import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { IFileDataResponseModel } from "../../../models/storage/file-data.model";

const uploadFile = async (files: File[]): Promise<IFileDataResponseModel> => {
  const formData = new FormData();

  for (const file of files) {
    formData.append("files", file);
  }

  const { data } = await getInstance().post<IFileDataResponseModel>(RestEndpoints.Storage, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export const useUploadFilesQuery = () => {
  return useMutation([SharedQueryKeys.UploadFile], (files: File[]) => uploadFile(files), {});
};
