export enum SystemCloningStatus {
  Starting = "started",
  Cloning = "cloning",
  Migrating = "migrating",
  Postprocessing = "post_processing",
  Success = "success",
  OtherFailed = "other_failed",
  CloningFailed = "cloning_failed",
  MigrationFailed = "migration_failed",
  PostprocessingFailed = "postprocessing_failed",
}

export interface ISystemCloningStatus {
  id: number;
  startedAt: Date;
  finishedAt: Date | null;
  startedBy: string;
  status: SystemCloningStatus;
}
