import { FormControlLabel, makeStyles, Radio, Theme, Typography } from "@material-ui/core";
import { FiberManualRecord as Circle } from "@material-ui/icons";
import { ChangeEvent, VFC } from "react";
import { PrimaryColors, Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  controlLabelRoot: {
    margin: 0,
  },
  radioRoot: {
    height: 30,
    width: 30,
    marginRight: 8,
    backgroundColor: Shades.gray20,
    color: Shades.gray20,
    "&:hover": {
      backgroundColor: Shades.gray20,
    },
  },
  radioChecked: {
    "&.Mui-checked": {
      backgroundColor: Shades.gray20,
      color: PrimaryColors.base,
      "&:hover": {
        backgroundColor: Shades.gray20,
      },
    },
  },
}));

interface ICustomRadioProps {
  label: string;
  checked: boolean;
  value: string;
  onChange: (checked: boolean) => void;
}

export const CustomRadio: VFC<ICustomRadioProps> = (props) => {
  const classes = useStyles();
  const { label, checked, value, onChange } = props;

  return (
    <FormControlLabel
      classes={{ root: classes.controlLabelRoot }}
      label={<Typography variant="body1">{label}</Typography>}
      control={
        <Radio
          checked={checked}
          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked)}
          value={value}
          classes={{ root: classes.radioRoot, checked: classes.radioChecked }}
          disableRipple
          checkedIcon={<Circle fontSize="medium" />}
        />
      }
    />
  );
};
