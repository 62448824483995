import { Box, Chip, FormControl, FormHelperText, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { ForwardedRef, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorBox } from "../../../../components/Box";
import { UploadButton } from "../../../../components/Primitives";
import { Colors } from "../../../../style/Colors";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  fileInput: {
    display: "none",
  },
  fileInputLabel: {
    width: "fit-content",
  },
  error: {
    color: Colors.red,
    borderColor: Colors.red,
  },
}));

interface IFormFileUploadFieldProps {
  name: string;
  label: string;
  initialFiles?: File[];
  onFileUpload: (files: File[]) => void;
  onFileDelete: (files: File[]) => void;
  control: Control<any, object>;
  required?: boolean;
  error: boolean;
  fileUploadError?: boolean;
  variant?: "text" | "outlined" | "contained";
}

export const FormFileUpload = React.forwardRef(
  (
    {
      name,
      onFileUpload,
      onFileDelete,
      label,
      error,
      control,
      initialFiles,
      required = true,
      fileUploadError,
      variant = "contained",
    }: IFormFileUploadFieldProps,
    ref: ForwardedRef<any>,
  ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [files, setFiles] = useState<File[]>(initialFiles ?? []);

    const onUpload = (files: File[]) => {
      setFiles(files);
    };

    const onDelete = (file: File) => {
      const newFiles = files.filter((f) => f.name !== file.name);
      setFiles(newFiles);
      onFileDelete(newFiles);
    };

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <Grid container direction="column">
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <Box>
                  <input
                    className={classes.fileInput}
                    style={{ display: "none" }}
                    ref={ref}
                    id={`${name}-input-file-field`}
                    type="file"
                    multiple
                    onChange={(event) => {
                      const files = Array.from(event.target.files || []);
                      onUpload(files);
                      onChange(event);
                      onFileUpload(files);
                    }}
                  />
                  <label htmlFor={`${name}-input-file-field`}>
                    <UploadButton
                      className={classNames({ [classes.error]: error })}
                      variant={variant}
                      iconSvgColor={error ? "red" : "green"}
                    >
                      {label}
                    </UploadButton>
                  </label>
                  {error && (
                    <FormHelperText className={classes.error}>{t("general.upload.requiredError")}</FormHelperText>
                  )}
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {files.map((file) => (
                  <Grid item xs={12}>
                    <Box my={1}>
                      <Chip color="primary" label={file.name} onDelete={() => onDelete(file)} />
                    </Box>
                  </Grid>
                ))}
                {fileUploadError && (
                  <ErrorBox>
                    <Typography>
                      <strong>*{t("file.upload.error")}</strong>
                    </Typography>
                  </ErrorBox>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    );
  },
);
