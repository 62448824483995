import { Grid } from "@material-ui/core";
import _ from "lodash";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { getGroupTypeTranslation } from "../../../../utils/group.helper";
import { cfmGroupTypes, GroupType, pomGroupTypes } from "../../../domain/group/group-type";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormField } from "../../form/form-field.component";
import { FormHeader } from "../../form/form-header.component";
import { isAgencyOrMainContact } from "./group-form.util";

export interface IGroupBaseDataTypeFormInputs {
  type: GroupType;
}

interface IGroupFormGroupTypeSectionProps {
  hideHeading?: boolean;
}

export const GroupFormGroupGroupTypeSection: VFC<IGroupFormGroupTypeSectionProps> = (props) => {
  const { hideHeading = false } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext<IGroupBaseDataTypeFormInputs>();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();

  return (
    <Grid container spacing={4}>
      {!hideHeading && (
        <Grid item xs={12}>
          <Grid container className={"px-2"}>
            <FormHeader size="small">{t("basedata.group.groupType")}</FormHeader>
          </Grid>
        </Grid>
      )}

      <FormField xs={12} md={12}>
        <FormAutocomplete<GroupType>
          name={"type"}
          control={control}
          label={t("basedata.groups.edit.groupType")}
          options={_.uniq([...pomGroupTypes, ...cfmGroupTypes])}
          error={Boolean(errors?.type)}
          rules={{ required: true }}
          getOptionLabel={(type) => getGroupTypeTranslation(type, t)}
          disabled={isAgencyOrMainContact(internalUser)}
        />
      </FormField>
    </Grid>
  );
};
