import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { PrimaryColors, Shades } from "../../../../style/Colors";
import { formatNumber } from "../../../../utils/number.helper";
import { usePomAnnouncementContext } from "../../../pages/announcement/pom-announcement.provider";

const useStyle = makeStyles((_) => ({
  cellStyle: {
    color: Shades.white,
    fontWeight: 600,
    textAlign: "end",
    padding: "16px",
  },
  cellStyleStart: {
    color: Shades.white,
    fontWeight: 600,
    textAlign: "start",
    padding: "16px",
  },
}));

interface IPomAnnouncementTableSumRowProps {}

export const PomAnnouncementTableSumRow: FC<IPomAnnouncementTableSumRowProps> = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { products, changedProducts } = usePomAnnouncementContext();
  const { internalUser } = useAuthContext();

  const changedProductIds = changedProducts?.map((product) => product.id) ?? [];
  const unchangedProducts = products?.filter((product) => !changedProductIds.some((p) => p === product.id)) ?? [];
  const curProducts = [...unchangedProducts, ...(changedProducts ?? [])];

  const count = curProducts?.reduce((sum, cur) => sum + (cur.count ?? 0), 0);
  const weight = curProducts?.reduce((sum, cur) => sum + (cur.totalWeight ?? 0), 0);
  const price = curProducts?.reduce((sum, cur) => sum + (cur.totalPrice ?? 0), 0);

  if (curProducts.length === 0) {
    return <></>;
  }

  return (
    <tfoot style={{ backgroundColor: PrimaryColors.base, height: "60px" }}>
      <tr>
        <td colSpan={5} />
        <td className={classes.cellStyleStart}>{t("general.sum")}</td>
        <td className={classes.cellStyle}>{formatNumber(count, 0)}</td>
        <td className={classes.cellStyle}>{formatNumber(weight, 3)}</td>
        <td />
        {internalUser?.group?.pomContracts?.[0]?.contractField?.defaultPriceGroup && (
          <td className={classes.cellStyle}>{`${formatNumber(price, 2)} €`}</td>
        )}
        <td />
      </tr>
    </tfoot>
  );
};
