import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ArticleNumberBox } from "../../../configurator/components/product/article-number-box.component";
import { IAddressLean } from "../../../shared/domain/address/address-lean";
import { formatDateShort } from "../../../shared/util/date.util";
import { Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  dateText: {
    color: Shades.gray60,
  },
  container: {
    height: "100%",
  },
}));

interface ITruckloadOrderInfoProps {
  address: IAddressLean | null;
  orderId: number;
  date: Date | null;
}

export const TruckloadOrderInfo: VFC<ITruckloadOrderInfoProps> = (props) => {
  const { address, orderId, date } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getAddressText = (address: IAddressLean | null) => {
    if (!address) return "";
    return `${address.id} ${address.name}, ${address.postal}, ${address.city}`;
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <ArticleNumberBox articleNumber={t("order.orderId", { id: orderId })} />
      </Grid>
      <Grid item>
        <Box display={"flex"} alignItems={"center"} className={classes.container}>
          <Typography variant="body1" className={classes.dateText}>
            {date ? formatDateShort(date) : ""}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display={"flex"} alignItems={"center"} className={classes.container}>
          <Typography variant="body1">{getAddressText(address)}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
