import { Grid, LinearProgress, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchField from "../../../../../components/SearchField";
import { IGroup } from "../../../../../shared/domain/group/group";
import { GroupType } from "../../../../../shared/domain/group/group-type";
import { GroupContractType } from "../../../../../shared/models/group/group-contract.model";
import { useGetGroupsWithFiltersQuery } from "../../../../../shared/repositories/queries/group/get-groups.query";
import { MIN_COUNT_FOR_SEARCH_FIELD } from "../../../../../utils/constants";
import { useOrderNewContext } from "../../order-new.provider";
import { GroupInfoBox } from "../info-box/group-info-box.component";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { WizardStepHeader } from "../wizard-step-heading.component";

const useStyles = makeStyles((theme: Theme) => ({
  groupBoxFirst: {
    marginTop: 40,
  },
  groupBox: {
    marginTop: 5,
  },
  searchContainer: {
    marginTop: 25,
  },
}));

export const OrderNewGroupList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();
  const { activeStep, setActiveStep } = useOrderNewContext();
  const { setValue } = useFormContext<IOrderNewWizardFormInputs>();

  const { data, isLoading, refetch } = useGetGroupsWithFiltersQuery({
    filter: {
      searchQuery: searchText,
      groupTypes: [GroupType.Customer],
      contractTypes: [GroupContractType.Cfm],
      active: true,
    },
  });

  useEffect(() => {
    refetch();
  }, [searchText, refetch]);

  const onGroupClicked = (id: number) => {
    setValue("customerGroupId", id);
    setActiveStep(activeStep + 1);
  };

  const doDisplaySearchField = () => {
    return (data?.groups?.length ?? 0) > MIN_COUNT_FOR_SEARCH_FIELD || searchText;
  };

  const hasNoData = () => {
    return !isLoading && (data?.groups?.length ?? 0) === 0;
  };

  const sortGroupsByName = (a: IGroup, b: IGroup) => {
    return a.name.localeCompare(b.name);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <WizardStepHeader text={t("orders.new.wizard.group.select")} />
      </Grid>
      {isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {doDisplaySearchField() && (
        <Grid item className={classes.searchContainer}>
          <SearchField
            size="large"
            tooltip={t("orders.new.wizard.search.group")}
            onSearchSubmit={(value: string) => {
              setSearchText(value);
            }}
            placeholder={t("orders.new.wizard.search.group")}
            autoFocus={false}
          />
        </Grid>
      )}
      {!isLoading &&
        data &&
        data.groups.sort(sortGroupsByName).map((group, index) => {
          return (
            <Grid key={index} item className={index === 0 ? classes.groupBoxFirst : classes.groupBox}>
              <GroupInfoBox group={group} onClick={onGroupClicked} />
            </Grid>
          );
        })}
      {hasNoData() && (
        <Grid item>
          <Typography variant="body1">{t("orders.new.wizard.noData")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
