import { CfmServiceDateModel } from "../order-x/action/cfm-service-date.model";
import { ICfmResponseFieldAssignmentModel } from "../response-assignment/cfm-response-field-assignment.model";

export interface ICfmTransferOrderAvailableActionsModel {
  items: ICfmTransferOrderAvailableActionModel[];
}

export interface ICfmTransferOrderAvailableActionModel {
  type: CfmTransferOrderActionTypeModel;
  responseFieldAssignments?: ICfmResponseFieldAssignmentModel[];
  serviceDates?: CfmServiceDateModel[];
}

export enum CfmTransferOrderActionTypeModel {
  Sorted = "Sorted",
  TruckloadCreated = "TruckloadCreated",
  TruckloadConfirmed = "TruckloadConfirmed",
  Recycled = "Recycled",
}
