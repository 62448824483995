import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { IAddress } from "../../../../shared/domain/address/address";
import { AddressConverter } from "../../../../shared/domain/converter/address.converter";
import { AddressType, IAddressModel } from "../../../../shared/models/address/address.model";
import getInstance from "../../../../utils/rest";
import { CfmQueryKeys } from "../cfm-query-keys";

const getAddressesForAccepting = async (addressTypes: AddressType[]): Promise<IAddress[]> => {
  const response = await getInstance().get<IAddressModel[]>(RestEndpoints.AddressesForUser, {
    params: { addressTypes },
  });
  return response.data.map(AddressConverter.toDomain);
};

export const useGetAddressesForAccepting = (enabled: boolean = true, addressTypes: AddressType[]) => {
  return useQuery(
    [CfmQueryKeys.GetLogisticsAddressForAccepting, addressTypes],
    () => getAddressesForAccepting(addressTypes),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled,
    },
  );
};
