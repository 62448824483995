import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  IPomManufacturer,
  isActiveManufacturer,
} from "../../../../../put-on-market/domain/models/manufacturer/manufacturer";
import { translateCountryByCode } from "../../../../../utils/address.util";
import { formatDateShort } from "../../../../util/date.util";
import { AppDialog } from "../../../../components/dialog/app-dialog.component";
import { GroupDetailStatusTag } from "../group-detail-status-tag.component";
import { DetailValueDisplay } from "../../../../components/value-display/detail-value-display.component";

interface IManufacturerDetailDialogProps {
  open: boolean;
  manufacturer: IPomManufacturer | undefined;
  onClose: VoidFunction;
  onEditClick: VoidFunction;
}

export const ManufacturerDetailDialog: VFC<IManufacturerDetailDialogProps> = (props) => {
  const { open, manufacturer, onClose, onEditClick } = props;
  const { t } = useTranslation();

  return (
    <AppDialog
      title={t("basedata.manufacturer.heading")}
      maxWidth="md"
      open={open}
      onCancelClick={onClose}
      onAcceptClick={onEditClick}
      acceptTextOverride={t("general.edit.text")}
      alignButtons="space-between"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" spacing={1}>
            <Grid item>
              <GroupDetailStatusTag
                label={manufacturer && isActiveManufacturer(manufacturer) ? t("general.active") : t("general.inactive")}
                type={manufacturer && isActiveManufacturer(manufacturer) ? "success" : "error"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.manufacturer.name")} value={manufacturer?.name ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.users.edit.externalId")}
                value={manufacturer?.externalId?.length ? manufacturer.externalId : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.country")}
                value={manufacturer?.address?.country ? translateCountryByCode(manufacturer?.address?.country, t) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.table.addressAndBuilding")}
                value={
                  manufacturer?.address?.address && manufacturer?.address?.buildingNumber
                    ? `${manufacturer?.address?.address} ${manufacturer?.address?.buildingNumber}`
                    : "-"
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.state")}
                value={manufacturer?.address?.state ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.postal")}
                value={manufacturer?.address?.postal ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.city")}
                value={manufacturer?.address?.city ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.manufacturer.battreg_number")}
                value={manufacturer?.battregNumber ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.manufacturer.contract_starts_at")}
                value={manufacturer?.contractStartsAt ? formatDateShort(manufacturer.contractStartsAt) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.manufacturer.contract_ends_at")}
                value={manufacturer?.contractEndsAt ? formatDateShort(manufacturer.contractEndsAt) : "-"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
