import { useMutation } from "react-query";
import { getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import { formatDateYearMonth } from "../../../../../shared/util/date.util";
import getInstance from "../../../../../utils/rest";
import { IPomAnnouncementModel } from "../../../models/announcements/pom-announcements.model";
import { IPomPatchAnnouncementModel } from "../../../models/announcements/pom-patch-announcement.model";
import { PomQueryKeys } from "../../../pom-query-keys";

export interface IPatchAnnouncementResult {
  announcement: IPomAnnouncementModel | undefined;
  status: number;
}

const patchAnnouncement = async (
  date: Date,
  request: IPomPatchAnnouncementModel,
): Promise<IPatchAnnouncementResult> => {
  try {
    const result = await getInstance().patch<IPomAnnouncementModel>(
      `${RestEndpoints.PomAnnouncements}/${formatDateYearMonth(date)}`,
      request,
    );
    return { announcement: result.data, status: result.status };
  } catch (error: any) {
    const code = getErrorStatusCode(error);
    return { announcement: undefined, status: code };
  }
};

export const usePatchAnnouncementQuery = () => {
  return useMutation(
    [PomQueryKeys.PatchAnnouncement],
    ({ date, request }: { date: Date; request: IPomPatchAnnouncementModel }) => patchAnnouncement(date, request),
  );
};
