import { Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CfmServiceDate } from "../../../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { ICfmResponseField } from "../../../../../collect-from-market/domain/routing-assignment/cfm-response-field";
import { useGetAllProductsQuery } from "../../../../../collect-from-market/repositories/queries/product/query/get-products.query";
import { AddButton, DangerousDeleteIconNoBorderButton } from "../../../../../components/Primitives/Buttons";
import {
  ResponseFieldBillingRelevantIcon,
  ResponseFieldWarehouseArticleIcon,
} from "../../../../../components/Primitives/Icons";
import { FormAutocomplete } from "../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormCheckbox } from "../../../../../shared/components/form/fields/form-checkbox.field";
import { PrimaryColors } from "../../../../../style/Colors";
import { ArticleNumberBox } from "../../../product/article-number-box.component";
import {
  IResponseAssignmentFormInputs,
  IResponseFieldFormInputs,
} from "../../dialog/assign-response-fields-dialog.component";
import { ResponseFieldIdBox } from "../response-field/response-field-id-box.component";
import { ResponseFieldTypeIcon } from "../response-field/response-field-type-icon.component";
import { FormSwitch } from "../../../../../shared/components/form/fields/form-switch.field";
import { CfmOrderXApiStatus } from "../../../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { CfmResponseFieldType } from "../../../../../collect-from-market/domain/response-values-x/cfm-response-field-type";

const useStyles = makeStyles((theme: Theme) => ({
  fieldTitle: {
    fontWeight: "bold",
  },
  iconDimensions: {
    height: 40,
    width: 40,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 16,
    width: 16,
  },
  container: {
    height: "100%",
  },
  responseFieldInput: {
    width: "93%",
  },
  deleteContainer: {
    width: "7%",
  },
  // We need to set a certain width, otherwise the row grows in height with the usage of xs={10}
  autocompleteContainer: {
    width: 600,
  },
}));

interface IAssignResponseFieldsFormContentProps {
  responseFields: ICfmResponseField[];
}

export const AssignResponseFieldsFormContent: VFC<IAssignResponseFieldsFormContentProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { responseFields } = props;
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<IResponseAssignmentFormInputs>();
  const { fields, append, remove, update: updateFieldArray } = useFieldArray({ control, name: "responseFieldsInputs" });
  const status = watch("status");
  const selectedResponseFields = watch("responseFieldsInputs");
  const { isLoading: areProductsLoading, data: products } = useGetAllProductsQuery();

  const getFieldUnit = (field: ICfmResponseField): string | undefined => {
    return field.unit ? ` [${field.unit}]` : ` [${t("configuration.responseField.noUnit")}]`;
  };

  const getFieldName = (field: ICfmResponseField): string => {
    return `${field.name}${getFieldUnit(field)}`;
  };

  const getFieldOptionLabel = (responseFieldId: number) => {
    const field = responseFields.find((field) => field.id === responseFieldId);
    if (!field) return "";
    return getFieldName(field);
  };

  const getProductOptionLabel = (productId: number) => {
    const product = (products ?? []).find((product) => product.id === productId);
    if (!product) return "";
    return `${product.articleNumber} | ${product.name}`;
  };

  const renderFieldOption = (responseFieldId: number) => {
    const field = responseFields.find((field) => field.id === responseFieldId);
    if (!field) return <></>;
    return (
      <Grid container direction="row" spacing={1}>
        {field.internalNumber && (
          <Grid item>
            <ResponseFieldIdBox fieldId={field.internalNumber} />
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">{getFieldName(field)}</Typography>
        </Grid>
        <Grid item>
          <ResponseFieldTypeIcon type={field.type} />
        </Grid>
      </Grid>
    );
  };

  const renderProductOption = (productId: number) => {
    const product = (products ?? []).find((product) => product.id === productId);
    if (!product) return <></>;
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <div>
            <ArticleNumberBox articleNumber={product.articleNumber} />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1">{product.name}</Typography>
        </Grid>
      </Grid>
    );
  };

  const validateFieldId = (value: number | undefined) => {
    if (!value || value === -1) return "error";

    return undefined;
  };

  const handleAddResponseFieldButtonClick = () => {
    const newResponseField: IResponseFieldFormInputs = {
      fieldId: -1,
      isBillingRelevant: null,
      serviceDate: null,
      hasWarehouseArticle: false,
      warehouseArticleId: null,
      isCredit: null,
    };
    append(newResponseField);
  };

  // Only allow multiple warehouse items in specific status
  const isWarehouseArticleAlreadyAssigned = selectedResponseFields.some((rf) => rf.hasWarehouseArticle);
  const isMultipleWarehouseArticlesDisabled =
    status === CfmOrderXApiStatus.LogisticWarehouse && isWarehouseArticleAlreadyAssigned;

  const isBillingCheckboxDisabled = (index: number) => {
    const selectedResponseField = responseFields.find((r) => r.id === selectedResponseFields[index].fieldId);
    return (
      selectedResponseField === undefined ||
      (selectedResponseField?.type !== CfmResponseFieldType.FloatingPoint &&
        selectedResponseField?.type !== CfmResponseFieldType.Number)
    );
  };

  const getBillingCheckboxTooltip = (index: number): string | undefined => {
    if (isBillingCheckboxDisabled(index)) {
      return t("configuration.responseAssignments.create.error.billableResponseFieldNumberOnly");
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1" className={classes.fieldTitle}>
          {t("configuration.responseAssignments.create.responseFields")}
        </Typography>
      </Grid>

      {!areProductsLoading &&
        fields.map((field, index) => {
          const isBillingRelevant = selectedResponseFields[index]?.isBillingRelevant;
          const hasWarehouseArticle = selectedResponseFields[index]?.hasWarehouseArticle;

          return (
            <>
              <Grid item xs={12} key={`${field.id}-${index}-1`}>
                <Grid container direction="row">
                  <Grid item className={classes.responseFieldInput}>
                    <FormAutocomplete<number>
                      label={undefined}
                      name={`responseFieldsInputs.${index}.fieldId`}
                      options={responseFields.filter((field) => field.active).map((field) => field.id)}
                      error={Boolean(errors?.responseFieldsInputs?.[index]?.fieldId)}
                      getOptionLabel={getFieldOptionLabel}
                      control={control}
                      rules={{
                        required: true,
                        validate: validateFieldId,
                        onChange: () => {
                          updateFieldArray(index, {
                            ...selectedResponseFields[index],
                            isBillingRelevant: null,
                            serviceDate: null,
                          });
                        },
                      }}
                      renderOption={renderFieldOption}
                    />
                  </Grid>
                  <Grid item className={classes.deleteContainer}>
                    <Grid container direction="row" justifyContent="center">
                      <Grid item>
                        <DangerousDeleteIconNoBorderButton
                          className={classes.iconDimensions}
                          onClick={() => {
                            remove(index);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item key={`${field.id}-${index}-2`}>
                <Grid container direction="row" spacing={2} alignItems={"center"} key={`${field.id}-${index}-2`}>
                  <Grid item xs={"auto"}>
                    <Grid container alignItems={"center"}>
                      <FormCheckbox
                        name={`responseFieldsInputs.${index}.isBillingRelevant`}
                        control={control}
                        disabled={isBillingCheckboxDisabled(index)}
                        tooltip={getBillingCheckboxTooltip(index)}
                        label={""}
                        noMargin
                      />
                      <ResponseFieldBillingRelevantIcon className={classes.iconStyle} />
                      {!isBillingRelevant && (
                        <Typography variant="body1" className={"ml-2"}>
                          {t("configuration.responseAssignments.billingRelevant")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {isBillingRelevant && (
                    <>
                      <Grid item className={classes.autocompleteContainer}>
                        <FormAutocomplete<CfmServiceDate>
                          label={undefined}
                          name={`responseFieldsInputs.${index}.serviceDate`}
                          options={Object.values(CfmServiceDate)}
                          error={Boolean(errors?.responseFieldsInputs?.[index]?.serviceDate)}
                          getOptionLabel={(value) => {
                            return t(`configuration.responseAssignments.dates.${value}`);
                          }}
                          control={control}
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={"auto"}>
                        <FormSwitch
                          label={t("configuration.responseAssignments.credit")}
                          name={`responseFieldsInputs.${index}.isCredit`}
                          control={control}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item key={`${field.id}-${index}-3`}>
                <Grid container direction="row" spacing={2} alignItems={"center"}>
                  <Grid item xs={"auto"}>
                    <Grid container alignItems={"center"}>
                      <FormCheckbox
                        tooltip={
                          isMultipleWarehouseArticlesDisabled && !hasWarehouseArticle
                            ? t("configuration.responseAssignments.warehouseArticleAlreadyAssigned")
                            : undefined
                        }
                        name={`responseFieldsInputs.${index}.hasWarehouseArticle`}
                        control={control}
                        disabled={isMultipleWarehouseArticlesDisabled && !hasWarehouseArticle}
                        label={""}
                        noMargin
                      />
                      <ResponseFieldWarehouseArticleIcon className={classes.iconStyle} />
                      {!hasWarehouseArticle && (
                        <Typography variant="body1" className={"ml-2"}>
                          {t("configuration.responseAssignments.warehouseArticle")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {hasWarehouseArticle && (
                    <Grid item className={classes.autocompleteContainer}>
                      <FormAutocomplete<number>
                        label={undefined}
                        name={`responseFieldsInputs.${index}.warehouseArticleId`}
                        options={(products ?? []).map((product) => product.id)}
                        error={Boolean(errors?.responseFieldsInputs?.[index]?.warehouseArticleId)}
                        getOptionLabel={getProductOptionLabel}
                        control={control}
                        renderOption={renderProductOption}
                        rules={{ required: true }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item key={`${field.id}-${index}-4`}>
                <Divider />
              </Grid>
            </>
          );
        })}

      {!areProductsLoading && (
        <>
          <Grid item>
            <AddButton onClick={handleAddResponseFieldButtonClick} variant="outlined" iconSvgColor="green">
              <Typography variant="body1">{t("configuration.newResponseField")}</Typography>
            </AddButton>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </>
      )}
    </Grid>
  );
};
