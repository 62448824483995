import { Grid, makeStyles, Theme } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import SearchField from "../../../../../../components/SearchField";
import useAsyncEffect from "../../../../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { useOrderTruckloadAnnouncedContext } from "../../../order-truckload-announced.provider";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";
import { TruckloadAnnouncedRow } from "./truckload-announced-row.component";
import { TruckloadAnnouncedSelectFilter } from "./truckload-announced-select-filter.component";
import { ListFooterComponent } from "../../../../../../shared/components/list/list-footer.component";
import {
  CfmTruckloadSortByOptionModel,
  SortingMode,
} from "../../../../../repositories/models/truckload/cfm-truckload-sort.model";
import { ISortComponentOption, SortComponent } from "../../../../../../shared/components/filter/sort.component";

const useStyles = makeStyles((theme: Theme) => ({
  searchFieldContainer: {
    paddingBottom: 24,
  },
}));

export const OrderListTruckloadAnnouncedView: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    truckloadAnnouncedFilter,
    setTruckloadAnnouncedFilter,
    setTruckloadSort,
    truckloadSort,
    truckloads,
    fetchNextPage,
    isLoading: areTruckloadsLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  } = useOrderTruckloadAnnouncedContext();
  const { hasScrolledToBottom } = useScrolledToBottomContext();

  const isLoading = useMemo(
    () => areTruckloadsLoading || isFetching || isFetchingNextPage,
    [areTruckloadsLoading, isFetching, isFetchingNextPage],
  );

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  const sortOptions: ISortComponentOption[] = [
    {
      label: t("orders.truckloadAnnounced.sort.plannedDeliveryDate"),
      checked: truckloadSort.sortBy === CfmTruckloadSortByOptionModel.PlannedDeliveryDate,
      value: CfmTruckloadSortByOptionModel.PlannedDeliveryDate,
      onChange: () => {
        setTruckloadSort({
          ...truckloadSort,
          sortBy: CfmTruckloadSortByOptionModel.PlannedDeliveryDate,
        });
      },
    },
    {
      label: t("orders.truckloadAnnounced.sort.truckloadNumber"),
      checked: truckloadSort.sortBy === CfmTruckloadSortByOptionModel.TruckloadId,
      value: CfmTruckloadSortByOptionModel.TruckloadId,
      onChange: () => {
        setTruckloadSort({
          ...truckloadSort,
          sortBy: CfmTruckloadSortByOptionModel.TruckloadId,
        });
      },
    },
  ];

  const getNewSortingMode: SortingMode = useMemo(() => {
    return truckloadSort.sortingMode === "ASC" ? "DESC" : "ASC";
  }, [truckloadSort]);

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <TruckloadAnnouncedSelectFilter />
        </Grid>
        <Grid item xs={12} className={classes.searchFieldContainer}>
          <SearchField
            initialValue={truckloadAnnouncedFilter.query}
            autoFocus={false}
            size="large"
            onSearchSubmit={(value) => {
              setTruckloadAnnouncedFilter({ ...truckloadAnnouncedFilter, query: value });
            }}
            placeholder={t("orders.truckloadAnnounced.search.text")}
            tooltip={t("orders.truckloadAnnounced.search.toolTip")}
          />
        </Grid>

        <Grid item xs={12}>
          <SortComponent
            options={sortOptions}
            onSortIconClick={() => {
              setTruckloadSort({ ...truckloadSort, sortingMode: getNewSortingMode });
            }}
          ></SortComponent>
        </Grid>

        {truckloads.map((truckload) => (
          <Grid item xs={12} key={truckload.id}>
            <TruckloadAnnouncedRow truckload={truckload} />
          </Grid>
        ))}
        {(!truckloads || truckloads.length === 0) && !isLoading && !isFetching && !isFetchingNextPage && (
          <EmptyWarehouse text={t("orders.truckloadAnnounced.noData")} />
        )}
      </Grid>
      <ListFooterComponent />
    </>
  );
};
