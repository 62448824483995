import { ActiveFilter } from "components/Filters/active-filter";
import { VFC } from "react";
import { useGroupAndManufacturerContext } from "./group-and-manufacturer-context";

export const GroupAndManufacturerActiveFilter: VFC = () => {
  const { filter, setFilter, tablePagination, setTablePagination } = useGroupAndManufacturerContext();

  const handleChange = (newValue: boolean | undefined) => {
    setFilter({ ...filter, active: newValue });
    setTablePagination({ ...tablePagination, page: 0 });
  };

  return <ActiveFilter isActive={filter?.active} setIsActive={handleChange} />;
};
