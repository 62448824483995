import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import useAsyncEffect from "../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../shared/domain/scroll/scrolled-to-bottom-context";
import { useProductOverviewContext } from "../../pages/product/product-overview.provider";
import { EmptyProductView } from "../empty-container/empty-product-view.component";
import { ProductRoutingRow } from "./product-routing-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
  last: {
    "&:last-child": {
      marginBottom: 20,
    },
  },
}));

export const ProductRoutingList: VFC = () => {
  const classes = useStyles();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const {
    hasNextPage,
    productsLoading: isLoading,
    fetchNextPage,
    products,
    isFetching,
    isFetchingNextPage,
    resetFilter,
  } = useProductOverviewContext();

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  const getProductRoutingRows = (product: ICfmProduct) => {
    // if no assignments are given, display normal product row
    if (!product.assignments || product.assignments.length === 0) {
      return (
        <Grid item className={classes.last} key={product.id}>
          <ProductRoutingRow product={product} assignment={undefined} />
        </Grid>
      );
    }

    return product.assignments.map((assignment) => {
      return (
        <Grid item key={assignment.id} className={classes.last}>
          <ProductRoutingRow product={product} assignment={assignment} />
        </Grid>
      );
    });
  };

  return (
    <Grid container direction="column">
      {!isLoading && products && products.map((product) => getProductRoutingRows(product))}
      {products.length === 0 && !isLoading && !isFetching && !isFetchingNextPage && (
        <Grid item>
          <EmptyProductView resetFilter={resetFilter} />
        </Grid>
      )}
      {(isLoading || isFetching || isFetchingNextPage) && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.alignSpinner}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
