import { useState } from "react";
import { IAnalysisResult, passwordAnalyser } from "utils/passwordAnalyser";

export const usePasswordAnalyser = () => {
  const [analysis, setAnalyser] = useState<IAnalysisResult>({
    hasLowerCase: false,
    hasNumber: false,
    hasSymbol: false,
    hasUpperCase: false,
    isLength: false,
  });

  const analyser = (value: string) => Promise.resolve(setAnalyser(passwordAnalyser(value)));
  const analyserSync = (value: string) => {
    const result = passwordAnalyser(value);
    setAnalyser(result);
    return result;
  };

  return { analysis, analyser, analyserSync };
};
