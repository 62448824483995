import { createTheme, DialogContentClassKey, StyleRules } from "@material-ui/core";

// use intermediate theme to be able to use breakpoints
const theme = createTheme();

export const MuiDialogContent: Partial<StyleRules<DialogContentClassKey, {}>> = {
  root: {
    padding: 30,
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
};
