import React, { FC } from "react";
import { Box, Grid } from "@material-ui/core";
import { BoxedRowGrid } from "../../../../../../configurator/components/grid/boxed-row-grid.component";
import { ArticleNumberBox } from "../../../../../../configurator/components/product/article-number-box.component";
import { ICfmTransferOrder } from "../../../../../domain/transfer-order/cfm-transfer-order";
import { useTranslation } from "react-i18next";
import { WarehouseProductQuantity } from "../warehouse/warehouse-product-weight.component";
import { formatDateLong, formatDateShort } from "../../../../../../shared/util/date.util";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";

interface ITransferOrdersListProps {
  transferOrders: ICfmTransferOrder[] | undefined;
}

export const TransferOrdersListComponent: FC<ITransferOrdersListProps> = (props) => {
  const { transferOrders } = props;
  const { t } = useTranslation();

  const getTransferOrderString = (order: ICfmTransferOrder): string => {
    return `${order.product.name} - ${
      order.serviceRecyclingDate ? formatDateShort(order.serviceRecyclingDate) : ""
    } - ${t("transferOrder.updatedBy")} ${order.updatedBy} [${formatDateLong(order.updatedAt)}]`;
  };

  const hasItems = transferOrders && transferOrders.length > 0;

  return (
    <>
      {!hasItems && (
        <Grid item>
          <EmptyWarehouse text={t("transferOrder.noTransferOrders")} />
        </Grid>
      )}

      <Grid item container direction="column">
        {(transferOrders ?? []).map((h) => {
          return (
            <Grid item key={h.id}>
              <BoxedRowGrid alignItems={"center"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"}>
                  <ArticleNumberBox articleNumber={h.product.articleNumber} />
                  <div className={"pl-3"}>{getTransferOrderString(h)}</div>
                </Box>
                <Box display={"flex"} flexDirection={"row"}>
                  <WarehouseProductQuantity quantity={h.weight ?? 0} />
                </Box>
              </BoxedRowGrid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
