import { useSnackbar } from "notistack";
import React, { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetGrsBatteryTypesQuery } from "../../../../../../put-on-market/repositories/queries/batt-g/get-grs-battery-types.query";
import { useGetRegistrationOfCurrentGroupQuery } from "../../../../../../put-on-market/repositories/queries/batt-g/get-registration-of-current-group.query";
import { useCreateBrandQuery } from "../../../../../../put-on-market/repositories/queries/mutations/batt-g/create-brand.query";
import { useUpdateBrandQuery } from "../../../../../../put-on-market/repositories/queries/mutations/batt-g/update-brand.query";
import { BattGBrandConverter } from "../../../../../domain/converter/batt-g/batt-g-brand.converter";
import { BattGBrandStatus, IBattGBrand, IBattGGrsBatteryType } from "../../../../../models/batt-g/batt-g-brand";
import { BattGRegistrationStatus } from "../../../../../models/batt-g/batt-g-registration";
import { IBattGBrandFormInputs } from "./batt-g-brand.dialog";

interface IBattGBrandDialogProvider {
  saveBrand: (inputs: IBattGBrandFormInputs, status: BattGBrandStatus) => Promise<IBattGBrand | undefined>;
  isLoading: boolean;
  brand: IBattGBrand | undefined;
  batteryTypes: IBattGGrsBatteryType[];
  allowTakeBackViaGrs: boolean;
}

export const BattGBrandDialogContext = createContext<IBattGBrandDialogProvider>({} as IBattGBrandDialogProvider);

export const useBattGBrandDialogContext = () => {
  return useContext(BattGBrandDialogContext);
};

const useBattGBrandDialogProvider = ({ brand }: IBattGBrandDialogProviderProps): IBattGBrandDialogProvider => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutateAsync: updateBrand, isLoading: isUpdateBrandLoading } = useUpdateBrandQuery();
  const { mutateAsync: createBrand, isLoading: isCreateBrandLoading } = useCreateBrandQuery();
  const { data: registrationOfCurrentGroupResult } = useGetRegistrationOfCurrentGroupQuery();
  const { data: grsBatteryTypesResult } = useGetGrsBatteryTypesQuery();

  const isLoading = useMemo(
    () => isUpdateBrandLoading || isCreateBrandLoading,
    [isUpdateBrandLoading, isCreateBrandLoading],
  );

  const batteryTypes = useMemo(() => grsBatteryTypesResult ?? [], [grsBatteryTypesResult]);
  const allowTakeBackViaGrs = useMemo(
    () => registrationOfCurrentGroupResult?.status === BattGRegistrationStatus.Published,
    [registrationOfCurrentGroupResult],
  );

  const getSuccessTranslation = (status: BattGBrandStatus): string => {
    return status === BattGBrandStatus.Draft ? "batt_g.brand.save_success" : "batt_g.brand.submit_success";
  };

  const saveBrand = async (
    inputs: IBattGBrandFormInputs,
    status: BattGBrandStatus,
  ): Promise<IBattGBrand | undefined> => {
    const request = BattGBrandConverter.formInputsToCreateRequest(inputs, status);
    let result: IBattGBrand;

    if (brand) {
      result = await updateBrand({ request, id: brand.id });
    } else {
      result = await createBrand({ request });
    }

    enqueueSnackbar(t(getSuccessTranslation(status)), { variant: "success" });
    return result;
  };

  return {
    saveBrand,
    isLoading,
    brand,
    batteryTypes,
    allowTakeBackViaGrs,
  };
};

interface IBattGBrandDialogProviderProps {
  brand: IBattGBrand | undefined;
}

export const BattGBrandDialogProvider: React.FC<IBattGBrandDialogProviderProps> = (props) => {
  const value = useBattGBrandDialogProvider(props);
  return <BattGBrandDialogContext.Provider value={value}>{props.children}</BattGBrandDialogContext.Provider>;
};
