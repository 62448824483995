import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: "bold",
  },
}));

interface IOrderDetailInfoBlockProps {
  title: string;
}

export const OrderDetailInfoBlock: FC<IOrderDetailInfoBlockProps> = (props) => {
  const { title, children } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
