import { Box, Dialog, DialogProps, makeStyles, TextField, Typography } from "@material-ui/core";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { CancelButton, SaveButton } from "../../../components/Primitives";
import { PrimaryColors, Shades } from "../../../style/Colors";
import { IPomAnnouncementTableData } from "../announcement-table/pom-announcement-table-data";
import { PomAnnouncementDialogTable } from "./pom-announcement-dialog-table.component";

export type IPomAnnouncementDialogType = "create" | "update";

interface IPomAnnouncementDialog extends DialogProps {
  type: IPomAnnouncementDialogType;
  purchaseOrderNumber: string;
  setPurchaseOrderNumber: (purchaseOrderNumber: string) => void;
  newItems?: IPomAnnouncementTableData[];
  existingItems?: IPomAnnouncementTableData[];
  onOk: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  dialogContainer: {
    backgroundColor: Shades.gray10,
  },
  header: {
    textAlign: "center",
    backgroundColor: PrimaryColors.base,
    color: Shades.white,
    height: "60px",
  },
});

export const PomAnnouncementDialog = (props: IPomAnnouncementDialog) => {
  const { type, open, newItems, onOk, onCancel, existingItems, purchaseOrderNumber, setPurchaseOrderNumber, ...rest } =
    props;
  const { t } = useTranslation();
  const classes = useStyles();

  const titleText = (): string => {
    if (type === "create") {
      return t("pom.announcements.dialog.title.create");
    } else {
      return t("pom.announcements.dialog.title.update");
    }
  };

  const okButtonText = (): string => {
    if (type === "create") {
      return t("pom.announcements.dialog.ok");
    } else {
      return t("pom.announcements.dialog.ok_update");
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth={false} {...rest}>
      <div className={classes.dialogContainer}>
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.header}>
          <Typography variant="body1" className="mx-4">
            <strong>{titleText()}</strong>
          </Typography>
        </Box>
        <PomAnnouncementDialogTable type={type} newItems={newItems} existingItems={existingItems} />

        <Box display="flex" className="my-4" justifyContent="center" alignItems="center">
          <TextField
            InputLabelProps={{ shrink: true }}
            label={t("purchase_order_number")}
            value={purchaseOrderNumber}
            onChange={(e) => {
              setPurchaseOrderNumber(e.target.value);
            }}
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" className="my-2">
          <CancelButton variant="contained" onClick={onCancel} color="primary" className="delete">
            <Typography variant="body1">{t("general.cancel.text")}</Typography>
          </CancelButton>
          <Box mx={1} />
          <SaveButton variant="contained" onClick={onOk} color="primary">
            <Typography variant="body1">{okButtonText()}</Typography>
          </SaveButton>
        </Box>
      </div>
    </Dialog>
  );
};
