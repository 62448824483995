import { Grid, LinearProgress } from "@material-ui/core";
import _ from "lodash";
import { useMemo, VFC } from "react";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { useGetResponseAssignmentsForOrdersQuery } from "../../../../repositories/queries/order-x/queries/get-response-assignments-for-orders.query";
import { CfmServiceDatesInput } from "../actions/service-date-input.component";

interface IOrderServiceDatesFormProps {
  orders: ICfmOrderX[];
  orderStatus: CfmOrderXApiStatus;
}

export const OrderServiceDateForm: VFC<IOrderServiceDatesFormProps> = (props) => {
  const { orders, orderStatus } = props;
  const { isLoading, data: responseAssignments } = useGetResponseAssignmentsForOrdersQuery(
    orders.map((order) => order.id),
  );
  const serviceDates = useMemo(() => {
    return _.uniq(
      (responseAssignments ?? [])
        .filter((assignment) => assignment.status === orderStatus)
        .flatMap((ass) => ass.serviceDates),
    );
  }, [responseAssignments, orderStatus]);

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Grid container direction="column" spacing={4}>
          <CfmServiceDatesInput serviceDates={serviceDates}></CfmServiceDatesInput>
        </Grid>
      )}
    </>
  );
};
