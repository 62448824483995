import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { QuestionDialog } from "../../../components/Dialogs/question-dialog";

interface IManufacturerConfirmDialogProps {
  open: boolean;
  onCancel: () => Promise<void>;
  onConfirm: () => Promise<void>;
}

export const ManufacturerConfirmDialog: VFC<IManufacturerConfirmDialogProps> = (props) => {
  const { open, onCancel, onConfirm } = props;
  const { t } = useTranslation();

  return (
    <QuestionDialog
      isOpen={open}
      displayStyle={"warning"}
      onClose={onCancel}
      onAccept={onConfirm}
      title={t("pom.manufacturers.create.duplicate.title")}
      message={t("pom.manufacturers.create.duplicate.message")}
      acceptText={t("pom.manufacturers.create.duplicate.action")}
    />
  );
};
