import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { BattGRegistrationConverter } from "../../../../shared/domain/converter/batt-g/batt-g-registration.converter";
import { IBattGRegistration } from "../../../../shared/models/batt-g/batt-g-registration";
import { IBattGRegistrationModel } from "../../../../shared/repositories/models/batt-g/batt-g-registration.model";
import getInstance from "../../../../utils/rest";
import { PomQueryKeys } from "../../pom-query-keys";

const getRegistrationOfCurrentGroup = async (): Promise<IBattGRegistration> => {
  const { data } = await getInstance().get<IBattGRegistrationModel>(RestEndpoints.BattGRegistrationOfGroup);
  return BattGRegistrationConverter.toDomain(data);
};

export const useGetRegistrationOfCurrentGroupQuery = () => {
  return useQuery([PomQueryKeys.GetRegistrationOfCurrentGroup], () => getRegistrationOfCurrentGroup(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
