import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import { ICfmOrderBulkActionModel } from "../../../models/order-x/action/cfm-order-bulk-action.model";
import { CfmOrderBulkActionConverter } from "../../../models/converter/order-x/cfm-order-x-bulk-action.converter";
import { ICfmOrderBulkAction } from "../../../../domain/order-x/actions/cfm-order-bulk-action";

const getOrderBulkActions = async (orderIds: number[]): Promise<ICfmOrderBulkAction> => {
  const { data } = await getInstance().post<ICfmOrderBulkActionModel>(RestEndpoints.CfmOrderXBulkActions, {
    ids: orderIds,
  });

  return CfmOrderBulkActionConverter.toDomain(data);
};

export const useGetOrderXBulkActions = (orderIds: number[], enabled: boolean) => {
  return useQuery([CfmQueryKeys.GetOrderXBulkActions], () => getOrderBulkActions(orderIds), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
