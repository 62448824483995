import { ICfmProductState } from "../../../domain/products/cfm-product-state";
import { ICfmProductStateModel } from "../product/cfm-product-state.model";

export class CfmProductStateConverter {
  public static toModel(state: ICfmProductState): ICfmProductStateModel {
    return {
      id: state.id,
      name: state.name,
      active: state.active,
      internalNumber: state.internalNumber,
    };
  }

  public static toDomain(state: ICfmProductStateModel): ICfmProductState {
    return {
      id: state.id,
      name: state.name,
      active: state.active,
      internalNumber: state.internalNumber,
    };
  }
}
