import { Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { SimplePageTitle } from "../../../layout-components/SimplePageTitle";

interface IContactProps {}

export const Contact: React.FunctionComponent<IContactProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <SimplePageTitle titleMessageKey={"contact.title"} />

      <Grid container direction="column" spacing={1} style={{ marginTop: 20 }}>
        <Grid item>
          <Typography variant="body1">{t("contact.text.greeting")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {t("contact.text.body", {
              mail: t("contact.text.mail"),
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{t("contact.text.parting")}</Typography>
        </Grid>
        <Grid item>
          <Typography>{t("contact.text.team")}</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};
