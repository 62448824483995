import { Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { AcceptButton, CancelButtonOutlined } from "../../../../components/Primitives/Buttons";
import { CancelIcon, SaveIcon } from "../../../../components/Primitives/Icons";
import { Colors } from "../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  saveIcon: {
    marginTop: 10,
  },
  cancelIcon: {
    color: Colors.red,
    marginTop: 10,
  },
}));

interface IProductConfirmButtonsProps {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

export const ProductConfirmButtons: VFC<IProductConfirmButtonsProps> = (props) => {
  const { onConfirm, onCancel } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const displayFullButtons = useMediaQuery(theme.breakpoints.up(1200), { noSsr: true });

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        {displayFullButtons && (
          <CancelButtonOutlined onClick={onCancel} variant="outlined">
            <Typography>{t("general.cancel.text")}</Typography>
          </CancelButtonOutlined>
        )}
        {!displayFullButtons && <CancelIcon onClick={onCancel} svgColor="red" className={classes.cancelIcon} />}
      </Grid>
      <Grid item>
        {displayFullButtons && (
          <AcceptButton onClick={onConfirm} variant="outlined">
            <Typography>{t("configuration.updateProductName")}</Typography>
          </AcceptButton>
        )}
        {!displayFullButtons && <SaveIcon onClick={onConfirm} svgColor="green" className={classes.saveIcon} />}
      </Grid>
    </Grid>
  );
};
