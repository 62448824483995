import React, { createContext, FC, useContext, useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useUnsavedDataContext } from "../../../providers/App/unsaved-data-context";
import { IOrderNewWizardFormInputs, WizardMode } from "./components/order-new-wizard.component";

// additional non wizard forms add additional package, add additional addon
export enum WizardStep {
  CustomerGroup = 0,
  CustomerAddress = 1,
  Product = 2,
  Package = 3,
  SubmitPage = 4,
}

interface IEditInfo {
  editClicked: boolean;
  stepBeforeEditClicked: WizardStep | undefined;
  formArrayIndex?: number;
}

interface IOrderNewContextType {
  activeStep: WizardStep;
  setActiveStep(step: WizardStep): void;
  isCustomerGroupInfoAvailable: boolean;
  isCustomerAddressInfoAvailable: boolean;
  isProductInfoAvailable: boolean;
  isPackageInfoAvailable: boolean;
  resetProduct(setValue: UseFormSetValue<IOrderNewWizardFormInputs>): void;
  editInfo: IEditInfo;
  setEditInfo: (info: IEditInfo) => void;
  wizardMode: WizardMode;
}

const OrderNewContext = createContext<IOrderNewContextType>({} as IOrderNewContextType);

interface IOrderNewProviderProps {
  initialStep: WizardStep;
  wizardMode: WizardMode;
}

export const OrderNewProvider: FC<IOrderNewProviderProps> = (props) => {
  const value = useOrderNewProvider(props);
  return <OrderNewContext.Provider value={value}>{props.children}</OrderNewContext.Provider>;
};

export const useOrderNewContext = () => {
  return useContext(OrderNewContext);
};

const useOrderNewProvider = (props: IOrderNewProviderProps): IOrderNewContextType => {
  const [activeStep, setActiveStep] = useState(props.initialStep);
  const [editInfo, setEditInfo] = useState<IEditInfo>({
    editClicked: false,
    stepBeforeEditClicked: undefined,
    formArrayIndex: undefined,
  });
  const { setHasUnsavedData } = useUnsavedDataContext();

  const isCustomerGroupInfoAvailable = useMemo(() => activeStep > WizardStep.CustomerGroup, [activeStep]);
  const isCustomerAddressInfoAvailable = useMemo(() => activeStep > WizardStep.CustomerAddress, [activeStep]);
  const isProductInfoAvailable = useMemo(() => activeStep > WizardStep.Product, [activeStep]);
  const isPackageInfoAvailable = useMemo(() => activeStep > WizardStep.Package, [activeStep]);

  const resetProduct = (setValue: UseFormSetValue<IOrderNewWizardFormInputs>) => {
    setValue("productId", undefined);
    setValue("productOrders", []);
  };

  const handleActiveStep = (step: WizardStep) => {
    window.scrollTo(0, 0);
    setHasUnsavedData(true);
    setActiveStep(step);
  };

  return {
    activeStep,
    setActiveStep: handleActiveStep,
    isCustomerGroupInfoAvailable,
    isCustomerAddressInfoAvailable,
    isProductInfoAvailable,
    isPackageInfoAvailable,
    resetProduct,
    editInfo,
    setEditInfo,
    wizardMode: props.wizardMode,
  };
};
