import { Grid, LinearProgress } from "@material-ui/core";
import { replaceUriParameter } from "api/endpoints";
import axios from "axios";
import { AutofillCatcher } from "components/AutofillHelper/autofill-catcher";
import { StatusCodes } from "http-status-codes";
import { useSnackbar } from "notistack";
import { ManufacturerConverter } from "put-on-market/domain/converter/manufacturer/manufacturer.converter";
import { IPomManufacturer } from "put-on-market/domain/models/manufacturer/manufacturer";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppDialogButtonRow } from "shared/components/dialog/app-dialog-button-row.component";
import { FormAutocomplete } from "shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "shared/components/form/fields/form-datepicker.field";
import { FormTextField } from "shared/components/form/fields/form-text.field";
import { FormField } from "shared/components/form/form-field.component";
import { IGroup } from "shared/domain/group/group";
import { AvailablePomRoutes } from "utils/constants";
import { Countries, ICountry, getDefaultCountry } from "../../../utils/Countries";
import { useCreateManufacturerQuery } from "../../repositories/queries/mutations/create-manufacturers.query";
import { useUpdateManufacturerQuery } from "../../repositories/queries/mutations/update-manufacturers.query";
import { ManufacturerConfirmDialog } from "./manufacturer-confirm-dialog.component";
import { useQueryClient } from "react-query";
import { PomQueryKeys } from "../../repositories/pom-query-keys";

export interface IPomManufacturerFormInputs {
  group: IGroup | undefined;
  name: string | undefined;
  addressName2: string | undefined;
  country: ICountry | undefined;
  addressLocation: string | undefined;
  buildingNumber: string | undefined;
  state: string | undefined;
  postal: string | undefined;
  city: string | undefined;
  externalId: string | undefined;
  battregNumber: string | undefined;
  contractStartsAt: Date;
  contractEndsAt: Date | undefined;
  addressId: number | undefined;
}

export enum ManufacturerFormMode {
  Detail,
  Edit,
  Create,
}

interface IManufacturerFormProps {
  mode: ManufacturerFormMode;
  manufacturer?: IPomManufacturer;
  mainGroup?: IGroup;
  onCancelCallback?: () => void;
  onSuccessCallback?: (manufacturer: IPomManufacturer | undefined) => void;
}

export const ManufacturerForm: FC<IManufacturerFormProps> = (props) => {
  const { manufacturer, mainGroup, mode, onCancelCallback, onSuccessCallback } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const {
    register,
    handleSubmit: onSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IPomManufacturerFormInputs>({
    defaultValues: manufacturer ? ManufacturerConverter.toForm(manufacturer) : { country: getDefaultCountry() },
  });
  const queryClient = useQueryClient();

  const handleUpdateError = () => {
    enqueueSnackbar(t("pom.manufacturers.update.error"), { variant: "error" });
  };
  const handleUpdateSuccess = (manufacturer: IPomManufacturer | undefined) => {
    queryClient.invalidateQueries(PomQueryKeys.GetManufacturer);
    if (!manufacturer) return;
    if (onSuccessCallback) onSuccessCallback(manufacturer);
    reset();
  };
  const { isLoading: updateLoading, mutateAsync: updateManufacturer } = useUpdateManufacturerQuery(
    handleUpdateError,
    handleUpdateSuccess,
  );

  const handleCreateSuccess = (manufacturer: IPomManufacturer | undefined) => {
    if (!manufacturer) return;
    if (onSuccessCallback) onSuccessCallback(manufacturer);
    reset();
  };
  const handleCreateError = (error: unknown) => {
    if (axios.isAxiosError(error) && error?.response?.status === StatusCodes.CONFLICT) {
      setIsConfirmDialogOpen(true);
      return;
    }
    enqueueSnackbar(t("pom.manufacturers.create.error.text"), { variant: "error" });
  };
  const { isLoading: createLoading, mutateAsync: createManufacturer } = useCreateManufacturerQuery(
    handleCreateError,
    handleCreateSuccess,
  );

  // @ts-ignore - fix circular dependency
  const contractStartsAt = watch("contractStartsAt");
  const contractEndsAt = watch("contractEndsAt");

  const handleCreate = async (inputs: IPomManufacturerFormInputs, ignoreDuplicateName: boolean) => {
    if (!mainGroup) {
      return;
    }

    const request = ManufacturerConverter.toFormCreateRequest(inputs);
    request.groupId = mainGroup.id;

    await createManufacturer({ manufacturerInput: request, ignoreDuplicateName });
  };

  const handleUpdate = async (inputs: IPomManufacturerFormInputs) => {
    if (!manufacturer?.id) return;
    const request = ManufacturerConverter.toFormUpdateRequest(inputs);
    await updateManufacturer({ manufacturerId: manufacturer.id, manufacturerInput: request });
  };

  const handleSubmit: SubmitHandler<IPomManufacturerFormInputs> = (inputs) => async (ignoreDuplicateName: boolean) => {
    switch (mode) {
      case ManufacturerFormMode.Create:
        return await handleCreate(inputs, ignoreDuplicateName);
      case ManufacturerFormMode.Edit:
        return await handleUpdate(inputs);
    }
  };

  const handleOkClick = () => {
    if (manufacturer?.id == null) return;
    const routeToPush = replaceUriParameter(AvailablePomRoutes.ManufacturerEdit, "id", manufacturer.id);
    history.push(routeToPush);
  };

  const inputsDisabled = mode === ManufacturerFormMode.Detail;
  const loading = createLoading || updateLoading;

  return (
    <>
      <ManufacturerConfirmDialog
        open={isConfirmDialogOpen}
        onCancel={async () => setIsConfirmDialogOpen(false)}
        onConfirm={async () => {
          setIsConfirmDialogOpen(false);
          handleSubmit(getValues())(true);
        }}
      />

      <form onSubmit={onSubmit((inputs) => handleSubmit(inputs)(false))}>
        {/* do not remove, only reliable way to prevent chrome from pre filling fields */}
        <AutofillCatcher />

        {loading && <LinearProgress />}
        {!loading && (
          <Grid container spacing={4}>
            <FormField>
              <FormTextField
                hasError={Boolean(errors["name"])}
                label={t("basedata.manufacturer.name")}
                maxLength={50}
                errorMessage={errors["name"]?.message}
                disabled={inputsDisabled}
                control={control}
                name={"name"}
                rules={{
                  required: true,
                }}
              />
            </FormField>
            <FormField>
              <FormTextField
                hasError={Boolean(errors["addressName2"])}
                label={t("basedata.manufacturer.name2")}
                errorMessage={errors["addressName2"]?.message}
                disabled={inputsDisabled}
                maxLength={50}
                control={control}
                name={"addressName2"}
              />
            </FormField>
            <FormField>
              <FormAutocomplete<ICountry>
                disabled={inputsDisabled}
                control={control}
                required={true}
                label={t("basedata.addresses.edit.country")}
                options={Countries}
                error={Boolean(errors?.country)}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(country) => t(country.translationKey)}
                rules={{ required: true }}
                {...register("country")}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["state"])}
                label={t("basedata.addresses.edit.state")}
                disabled={inputsDisabled}
                control={control}
                name={"state"}
                rules={{
                  required: true,
                }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["addressLocation"])}
                label={t("basedata.addresses.edit.address")}
                disabled={inputsDisabled}
                control={control}
                name={"addressLocation"}
                rules={{
                  required: true,
                }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["buildingNumber"])}
                label={t("basedata.addresses.edit.buildingNumber")}
                disabled={inputsDisabled}
                control={control}
                name={"buildingNumber"}
                rules={{
                  required: true,
                }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["postal"])}
                label={t("basedata.addresses.edit.postal")}
                disabled={inputsDisabled}
                maxLength={30}
                control={control}
                name={"postal"}
                rules={{
                  required: true,
                }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["city"])}
                label={t("basedata.addresses.edit.city")}
                disabled={inputsDisabled}
                control={control}
                name={"city"}
                rules={{
                  required: true,
                }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["battregNumber"])}
                label={t("basedata.manufacturer.battreg_number")}
                disabled={inputsDisabled}
                required={false}
                control={control}
                name={"battregNumber"}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors["externalId"])}
                label={t("basedata.users.edit.externalId")}
                disabled={inputsDisabled}
                required={false}
                control={control}
                name={"externalId"}
              />
            </FormField>

            <FormField>
              <FormDatepickerField
                hasError={Boolean(errors["contractStartsAt"])}
                control={control}
                label={t("basedata.manufacturer.contract_starts_at")}
                disabled={inputsDisabled}
                maxDate={contractEndsAt}
                required={true}
                {...register("contractStartsAt", {
                  required: true,
                })}
              />
            </FormField>

            <FormField>
              <FormDatepickerField
                control={control}
                label={t("basedata.manufacturer.contract_ends_at")}
                disabled={inputsDisabled}
                required={false}
                minDate={contractStartsAt}
                {...register("contractEndsAt", {
                  required: false,
                })}
              />
            </FormField>
          </Grid>
        )}
        <AppDialogButtonRow
          isLoading={loading}
          acceptTextOverride={mode === ManufacturerFormMode.Detail ? t("general.edit.text") : t("general.save.text")}
          onCancelClick={onCancelCallback ? onCancelCallback : undefined}
          onAcceptClick={mode === ManufacturerFormMode.Detail ? handleOkClick : () => {}}
          type="submit"
          alignButtons="space-between"
        />
      </form>
    </>
  );
};
