import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { ICreateAddressResult } from "../../../domain/address/address";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { ICreateAddressResultModel } from "../../../models/address/address.model";
import { ICreateAddressModel } from "../../../models/address/create-address.model";
import { SharedQueryKeys } from "../shared-query-keys";

const createAddress = async (address: ICreateAddressModel): Promise<ICreateAddressResult> => {
  const { data } = await getInstance().post<ICreateAddressResultModel>(RestEndpoints.CreateAddress, address);
  return {
    address: AddressConverter.toLeanAddress(data.address),
    contactPersonEmailExists: data.contactPersonEmailExists,
  };
};

export const useCreateAddressQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    SharedQueryKeys.CreateAddress,
    ({ createData }: { createData: ICreateAddressModel }) => createAddress(createData),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
