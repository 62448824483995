import { formatDateYearMonthDay } from "../../../../../shared/util/date.util";
import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";
import {
  IOrderNewWizardFormInputs,
  IOrderNewWizardFormProductInput,
} from "../../../../pages/order-new/components/order-new-wizard.component";
import { ICfmResponseValueFormInputs } from "../../../../pages/order-x/components/actions/order-x-action.component";
import { CfmOrderActionTypeModel } from "../../order-x/action/cfm-order-action-type.model";
import {
  ICfmOrderActionEditInShoppingCartModel,
  ICfmOrderActionModel,
  OrderActionServiceDateModel,
} from "../../order-x/action/cfm-order-x-action.model";
import { CfmServiceDateModel } from "../../order-x/action/cfm-service-date.model";
import { CfmPackageConverter } from "../cfm-package.converter";
import { CfmOrderXResponseValueConverter } from "./cfm-order-x-response-value.converter";
import { ISelectedFilesList } from "../../../../pages/order-x/components/actions/providers/selected-files.provider";
import { OrderActionServiceDate } from "../../../../domain/order-x/actions/cfm-order-action";

export class CfmOrderXActionConverter {
  public static toUpdateRequest(
    actionType: CfmOrderActionType,
    inputs: ICfmResponseValueFormInputs,
    files: ISelectedFilesList[],
  ): ICfmOrderActionModel {
    const plannedPickupDate =
      inputs.logisticAnnounced?.plannedPickupDate ?? inputs.plannedPickupChanged?.plannedPickupDate;
    return {
      type: CfmOrderXActionConverter.actionToModel(actionType),
      responseFields: inputs.responseFields.map((field) =>
        CfmOrderXResponseValueConverter.createToModel(
          field,
          files.find((file) => file.responseAssignmentId === field.responseAssignmentResponseFieldId),
        ),
      ),
      serviceDates: CfmOrderXActionConverter.actionServiceDatesToModel(inputs.serviceDates),
      logisticAddressId: inputs.logisticAnnounced?.logisticAddressId,
      plannedPickupDate: plannedPickupDate ? formatDateYearMonthDay(plannedPickupDate) : undefined,
      isNotificationDisabled: inputs.isNotificationDisabled ?? false,
      actualPickupDate: inputs.logisticPerformed?.actualPickupDate
        ? formatDateYearMonthDay(inputs.logisticPerformed?.actualPickupDate)
        : undefined,
      plannedDeliveryDate: inputs.destinationAnnounced?.plannedDeliveryDate
        ? formatDateYearMonthDay(inputs.destinationAnnounced?.plannedDeliveryDate)
        : undefined,
      actualDeliveryDate: inputs.logisticWarehouse?.actualDeliveryDate
        ? formatDateYearMonthDay(inputs.logisticWarehouse?.actualDeliveryDate)
        : undefined,
      destinationAddressId:
        inputs.logisticAnnounced?.destinationAddressId ?? inputs.destinationAnnounced?.destinationAddressId,
      changeLogisticAddressId: inputs.logisticChanged?.changeLogisticAddressId,
      changeLogisticDestinationAddressId: inputs.logisticChanged?.changeLogisticDestinationAddressId,
      changeLogisticRequestedPickupDate: inputs.logisticChanged?.changeLogisticRequestedPickupDate
        ? formatDateYearMonthDay(inputs.logisticChanged?.changeLogisticRequestedPickupDate)
        : undefined,
      changeLogisticIsCustomerNotificationDisabled:
        inputs.logisticChanged?.changeLogisticIsCustomerNotificationDisabled ?? false,
      changeLogisticIsRecyclerOrLogisticNotificationDisabled:
        inputs.logisticChanged?.changeLogisticIsRecyclerOrLogisticNotificationDisabled ?? false,
      changeLogisticStatus: inputs.logisticChanged?.changeLogisticStatus,
      comment: inputs.plannedPickupChanged?.comment,
      reOrder: inputs.logisticMistrip?.reOrder,
      cancelationReasonId: inputs.logisticMistrip?.cancelationReasonId,
    };
  }

  public static actionServiceDatesToModel(serviceDates?: OrderActionServiceDate): OrderActionServiceDateModel {
    return {
      [CfmServiceDateModel.Delivery]: serviceDates?.delivery
        ? formatDateYearMonthDay(serviceDates.delivery)
        : undefined,
      [CfmServiceDateModel.Pickup]: serviceDates?.pickup ? formatDateYearMonthDay(serviceDates.pickup) : undefined,
      [CfmServiceDateModel.Recycling]: serviceDates?.recycling
        ? formatDateYearMonthDay(serviceDates.recycling)
        : undefined,
      [CfmServiceDateModel.Treatment]: serviceDates?.treatment
        ? formatDateYearMonthDay(serviceDates.treatment)
        : undefined,
    };
  }

  public static toEditInCartUpdateRequest(
    actionType: CfmOrderActionType,
    inputs: IOrderNewWizardFormInputs,
    productId: number,
    productOrder: IOrderNewWizardFormProductInput,
    requestedPickupDate: Date,
  ): ICfmOrderActionEditInShoppingCartModel {
    return {
      type: CfmOrderXActionConverter.actionToModel(actionType),
      purchaseOrderNumber: productOrder.orderNumber,
      customerAddressId: inputs.customerAddressId,
      contactPersonName: inputs.contactPersonName,
      contactPersonTelephone: inputs.contactPersonPhone,
      contactPersonEmail: inputs.contactPersonEmail,
      openingHours: inputs.openingHours,
      orderComment: productOrder.orderComment,
      quantity: inputs.singleOrderQuantity ? inputs.singleOrderQuantity : 1,
      requestedPickupDate: requestedPickupDate,
      productId: productId,
      package: productOrder.package
        ? CfmPackageConverter.createPackageToCreateXPackageModel(productOrder.package)
        : undefined,
      grossWeightKg: productOrder.productGrossWeight,
      netWeightKg: productOrder.productNetWeight,
    };
  }

  public static actionToDomain(model: CfmOrderActionTypeModel): CfmOrderActionType {
    switch (model) {
      case CfmOrderActionTypeModel.ShoppingCart:
        return CfmOrderActionType.ShoppingCart;
      case CfmOrderActionTypeModel.Ordered:
        return CfmOrderActionType.Ordered;
      case CfmOrderActionTypeModel.LogisticAccepted:
        return CfmOrderActionType.LogisticAccepted;
      case CfmOrderActionTypeModel.LogisticDeclined:
        return CfmOrderActionType.LogisticDeclined;
      case CfmOrderActionTypeModel.LogisticAnnounced:
        return CfmOrderActionType.LogisticAnnounced;
      case CfmOrderActionTypeModel.LogisticAnnouncedSingleOrder:
        return CfmOrderActionType.LogisticAnnouncedSingleOrder;
      case CfmOrderActionTypeModel.LogisticPerformed:
        return CfmOrderActionType.LogisticPerformed;
      case CfmOrderActionTypeModel.LogisticWarehouse:
        return CfmOrderActionType.LogisticWarehouse;
      case CfmOrderActionTypeModel.LogisticTruckLoad:
        return CfmOrderActionType.LogisticTruckLoad;
      case CfmOrderActionTypeModel.RecyclerIncomingWarehouse:
        return CfmOrderActionType.RecyclerIncomingWarehouse;
      case CfmOrderActionTypeModel.RecyclerOutgoingWarehouse:
        return CfmOrderActionType.RecyclerOutgoingWarehouse;
      case CfmOrderActionTypeModel.DestinationAnnounced:
        return CfmOrderActionType.DestinationAnnounced;
      case CfmOrderActionTypeModel.DestinationDeclined:
        return CfmOrderActionType.DestinationDeclined;
      case CfmOrderActionTypeModel.DestinationArrived:
        return CfmOrderActionType.DestinationArrived;
      case CfmOrderActionTypeModel.DestinationReceived:
        return CfmOrderActionType.DestinationReceived;
      case CfmOrderActionTypeModel.Confirmed:
        return CfmOrderActionType.Confirmed;
      case CfmOrderActionTypeModel.Canceled:
        return CfmOrderActionType.Canceled;
      case CfmOrderActionTypeModel.EditInShoppingCart:
        return CfmOrderActionType.EditInShoppingCart;
      case CfmOrderActionTypeModel.ChangeLogistic:
        return CfmOrderActionType.ChangeLogistic;
      case CfmOrderActionTypeModel.ChangePlannedPickup:
        return CfmOrderActionType.ChangePlannedPickup;
      case CfmOrderActionTypeModel.LogisticMistrip:
        return CfmOrderActionType.LogisticMistrip;
    }
  }

  public static actionToModel(domain: CfmOrderActionType): CfmOrderActionTypeModel {
    switch (domain) {
      case CfmOrderActionType.ShoppingCart:
        return CfmOrderActionTypeModel.ShoppingCart;
      case CfmOrderActionType.Ordered:
        return CfmOrderActionTypeModel.Ordered;
      case CfmOrderActionType.LogisticAccepted:
        return CfmOrderActionTypeModel.LogisticAccepted;
      case CfmOrderActionType.LogisticDeclined:
        return CfmOrderActionTypeModel.LogisticDeclined;
      case CfmOrderActionType.LogisticAnnounced:
        return CfmOrderActionTypeModel.LogisticAnnounced;
      case CfmOrderActionType.LogisticAnnouncedSingleOrder:
        return CfmOrderActionTypeModel.LogisticAnnouncedSingleOrder;
      case CfmOrderActionType.LogisticPerformed:
        return CfmOrderActionTypeModel.LogisticPerformed;
      case CfmOrderActionType.LogisticWarehouse:
        return CfmOrderActionTypeModel.LogisticWarehouse;
      case CfmOrderActionType.LogisticTruckLoad:
        return CfmOrderActionTypeModel.LogisticTruckLoad;
      case CfmOrderActionType.RecyclerIncomingWarehouse:
        return CfmOrderActionTypeModel.RecyclerIncomingWarehouse;
      case CfmOrderActionType.RecyclerOutgoingWarehouse:
        return CfmOrderActionTypeModel.RecyclerOutgoingWarehouse;
      case CfmOrderActionType.DestinationAnnounced:
        return CfmOrderActionTypeModel.DestinationAnnounced;
      case CfmOrderActionType.DestinationDeclined:
        return CfmOrderActionTypeModel.DestinationDeclined;
      case CfmOrderActionType.DestinationArrived:
        return CfmOrderActionTypeModel.DestinationArrived;
      case CfmOrderActionType.DestinationReceived:
        return CfmOrderActionTypeModel.DestinationReceived;
      case CfmOrderActionType.Confirmed:
        return CfmOrderActionTypeModel.Confirmed;
      case CfmOrderActionType.Canceled:
        return CfmOrderActionTypeModel.Canceled;
      case CfmOrderActionType.EditInShoppingCart:
        return CfmOrderActionTypeModel.EditInShoppingCart;
      case CfmOrderActionType.ChangeLogistic:
        return CfmOrderActionTypeModel.ChangeLogistic;
      case CfmOrderActionType.ChangePlannedPickup:
        return CfmOrderActionTypeModel.ChangePlannedPickup;
      case CfmOrderActionType.LogisticMistrip:
        return CfmOrderActionTypeModel.LogisticMistrip;
    }
  }
}
