import { DefaultValues } from "react-hook-form";
import { IGroup } from "../../../domain/group/group";
import { Language } from "../../../domain/user/language";
import { IMutateUserFormInputs } from "../create-user-form/create-user-form.component";
import { AddressConverter } from "./../../../domain/converter/address.converter";
import { IUserInitialValues } from "./user-form.component";

export const defaultValues: DefaultValues<IMutateUserFormInputs> = {
  notify: false,
  active: true,
  // If default value is not null, reset would not work :(
  // @ts-ignore
  title: null,
  // @ts-ignore
  group: null,
  // @ts-ignore
  rolePom: null,
};

export const userToInitialValues = (
  user: IUserInitialValues | undefined,
  userGroup: IGroup | undefined,
): IMutateUserFormInputs | DefaultValues<IMutateUserFormInputs> | undefined => {
  if (!user) {
    const values = defaultValues;
    values.group = userGroup;
    return values;
  }

  if (!user) {
    const defaultValuesWithGroup = defaultValues;
    defaultValuesWithGroup.group = userGroup;
    return defaultValuesWithGroup;
  }

  return {
    active: user.active !== null,
    userTypeLevel: user.userTypeLevel ? user.userTypeLevel : undefined,
    deliveryEmail: user.deliveryEmail,
    email: user.email,
    externalId: user.externalId,
    firstName: user.firstName,
    lastName: user.lastName,
    notify: user.notify ?? false,
    password: user.password ?? "",
    passwordConfirm: user.confirmPassword ?? "",
    rolePom: user.rolePom,
    telephone: user.telephone,
    title: user.title,
    userAddresses: user.addresses ? user.addresses.map(AddressConverter.domainToLeanAddressDomain) : [],
    group: userGroup ?? user.group,
    jobTitle: user.jobTitle,
    language: Language.de,
  };
};
