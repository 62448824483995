import { IPaginationMetaModel } from "shared/models/util/pagination-meta.model";
import { IGroupModel } from "../group/group.model";
import { IUserModel } from "../user/user.model";

export enum AddressType {
  Invoice = "invoice",
  SorterLocation = "sorter_location",
  RecyclerLocation = "recycler_location",
  CustomerLocation = "customer_location",
  LogisticLocation = "logistic_location",
  GroupLocation = "group_location",
  ManufacturerLocation = "manufacturer_location",
}

export const recyclerAddressTypes = [AddressType.SorterLocation, AddressType.RecyclerLocation];

export interface IAddressModel {
  id: number;
  type: AddressType;
  name: string | null;
  name2: string | null;
  address: string | null;
  formattedAddress: string | null;
  addressText: string | null;
  buildingNumber: string | null;
  city: string | null;
  postal: string | null;
  state: string | null;
  country: string | null;
  lat: number | null;
  long: number | null;
  contactPersonEmail: string | null;
  contactPersonName: string | null;
  contactPersonTelephone: string | null;
  externalId: string | null;
  active: Date | null;
  users?: IUserModel[];
  groupId?: number | null;
  group?: IGroupModel | null;
  openingHours: string | null;
}

export interface IAddressResultModel {
  addresses: IAddressModel[];
  meta: IPaginationMetaModel;
}

export interface ICreateAddressResultModel {
  address: IAddressModel;
  contactPersonEmailExists: boolean;
}
