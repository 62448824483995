import _ from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { ICfmResponseField } from "../../../collect-from-market/domain/routing-assignment/cfm-response-field";
import { CfmResponseFieldConverter } from "../../../collect-from-market/repositories/models/converter/cfm-response-field.converter";
import { IResponseFieldsResponseModel } from "../../../collect-from-market/repositories/models/response-field/cfm-response-field.model";
import { useGetResponseFieldsInfiniteQuery } from "../../../collect-from-market/repositories/queries/response-field/get-infinite-response-fields.query";
import {
  getResponseFieldFilterInitialValue,
  IResponseFieldFilter,
} from "../../components/response-field/response-field.util";

interface IProductResponseFieldOverviewContextType {
  filter: IResponseFieldFilter;
  setFilter: (filter: IResponseFieldFilter) => void;
  responseFieldsLoading: boolean;
  responseFields: ICfmResponseField[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<IResponseFieldsResponseModel>>;
  responseField: ICfmResponseField | undefined;
  setResponseField: (responseField: ICfmResponseField | undefined) => void;
}

interface IProductResponseFieldOverviewProviderProps {
  children: any;
  routingRequired?: boolean;
}

const ProductResponseFieldOverviewContext = createContext<IProductResponseFieldOverviewContextType>(
  {} as IProductResponseFieldOverviewContextType,
);

export const ProductResponseFieldOverviewProvider = (props: IProductResponseFieldOverviewProviderProps) => {
  const value = useProductResponseFieldOverviewProvider(props);
  return (
    <ProductResponseFieldOverviewContext.Provider value={value}>
      {props.children}
    </ProductResponseFieldOverviewContext.Provider>
  );
};

export const useProductResponseFieldOverviewContext = () => {
  return useContext(ProductResponseFieldOverviewContext);
};

const useProductResponseFieldOverviewProvider = (
  props: IProductResponseFieldOverviewProviderProps,
): IProductResponseFieldOverviewContextType => {
  const [filter, setFilter] = useState<IResponseFieldFilter>(getResponseFieldFilterInitialValue());
  const [responseField, setResponseField] = useState<ICfmResponseField | undefined>(undefined);

  const getNextResponseFieldPageParam = (
    lastPage: IResponseFieldsResponseModel,
    pages: IResponseFieldsResponseModel[],
  ) => {
    const hasNextPage = pages.length < (lastPage.paginationMeta?.numberOfPages ?? 0);
    if (hasNextPage) {
      return pages.length + 1;
    }

    // Return undefined to indicate there is no next page available.
    return undefined;
  };

  const { data, isLoading, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } =
    useGetResponseFieldsInfiniteQuery(filter, getNextResponseFieldPageParam);

  const responseFields = useMemo(() => {
    const pages = data?.pages ?? [];
    return _.flatMap(pages, (page) => page.responseFields).map((responseField) =>
      CfmResponseFieldConverter.toDomain(responseField),
    );
  }, [data?.pages]);

  return {
    filter,
    setFilter,
    responseFields,
    responseFieldsLoading: isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    responseField,
    setResponseField,
  };
};
