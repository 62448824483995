import { FC } from "react";
import { AutocompleteFilter } from "../../../../../../shared/components/filter/autocomplete-filter.component";
import { useTranslation } from "react-i18next";
import { ICfmProduct } from "../../../../../domain/products/cfm-product";

interface IProductFilterComponent {
  products: ICfmProduct[];
  handleProductChanged: (address: ICfmProduct | null) => void;
}

export const ProductFilterComponent: FC<IProductFilterComponent> = (props) => {
  const { products, handleProductChanged } = props;
  const { t } = useTranslation();

  const getProductLabel = (option: ICfmProduct): string => {
    return option.name ?? "";
  };
  return (
    <AutocompleteFilter
      handleFilterChanged={handleProductChanged}
      getOptionLabel={getProductLabel}
      placeholder={t("orders.destinationReceivedWarehouse.filter.article")}
      isLoading={false}
      options={products}
    />
  );
};
