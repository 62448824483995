import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { CfmQueryKeys } from "../cfm-query-keys";

const getPostalsForCreateAssignmentsTemplateQuery = async (): Promise<string> => {
  const result = await getInstance().get(RestEndpoints.UploadPostalsForCreateAssignmentsTemplate, {
    responseType: "blob",
  });
  return result.data;
};

export const usePostalsForCreateAssignmentsTemplateQuery = (enabled: boolean) => {
  return useQuery(
    [CfmQueryKeys.UploadPostalsForCreateAssignmentsTemplate],
    () => getPostalsForCreateAssignmentsTemplateQuery(),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled,
    },
  );
};
