import { UnsavedDataPrompt } from "components/Routes/unsaved-data-prompt";
import { AvailablePlatform, usePlatformContext } from "providers/Auth/platform.provider";
import { FunctionComponent, useEffect } from "react";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { SystemCloningPage } from "shared/pages/clone-system/system-cloning.page";
import { PasswordResetPage } from "shared/pages/password-reset/must-reset.page";
import { SharedRoutes } from "shared/routes/shared.routes";
import { CfmRoutes } from "./collect-from-market/routes/cfm.routes";
import config from "./config/config";
import { ConfigurationRoutes } from "./configurator/configuration.routes";
import { CartProvider } from "./providers/Cart/cart-context";
import { DashboardActionProvider } from "./shared/pages/dashboard/dashboard-action.provider";
import { PasswordResetDialogProvider } from "./components/Password/password-reset-dialog.provider";
import { PomRoutes } from "./put-on-market/routes/pom.routes";
import { ScrollToTopButton } from "./shared/components/scroll-to-top/scroll-to-top-button.component";
import { cfmGroupTypes, GroupType, pomGroupTypes } from "./shared/domain/group/group-type";
import { useScrollToTopContext } from "./shared/domain/scroll/scroll-to-top-context";
import { isAdmin, isPomMainContact } from "./shared/domain/user/user";
import { SidebarLayout } from "./shared/layout/sidebar.layout";
import { DashboardCountProvider } from "./shared/pages/dashboard/dashboard-count.provider";
import { DashboardPage } from "./shared/pages/dashboard/dashboard.page";
import { isUserLoggedIn } from "./utils/auth";
import { AuthenticatedRoute } from "./utils/AuthenticatedRoute";
import {
  AvailableCfmRoutes,
  AvailableConfiguratorRoutes,
  AvailablePomRoutes,
  AvailableSharedRoutes,
  isCfmRoute,
  isConfigRoute,
  isPomRoute,
} from "./utils/constants";
import { PasswordResetRoute } from "./utils/PasswordResetRoute";
import { ConfigProvider } from "./shared/domain/config/config.provider";
import { AuthProvider, AuthProviderContext } from "./providers/Auth/auth.provider";
import { MaintenanceModePage } from "shared/pages/maintenance-mode/maintenance-mode.page";

export const Portal: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { setActivePlatform } = usePlatformContext();
  const { isVisible: isScrollToTopVisible } = useScrollToTopContext();

  useEffect(() => {
    if (isPomRoute(location.pathname)) {
      setActivePlatform(AvailablePlatform.Pom);
    } else if (isCfmRoute(location.pathname)) {
      setActivePlatform(AvailablePlatform.Cfm);
    } else if (isConfigRoute(location.pathname)) {
      setActivePlatform(AvailablePlatform.Config);
    }
  }, [location.pathname, setActivePlatform]);

  if (!isUserLoggedIn()) {
    history.push(AvailableSharedRoutes.Login, { next: location.pathname });
    return null;
  }

  return (
    <AuthProvider>
      <AuthProviderContext.Consumer>
        {({ internalUser }) => {
          if (!internalUser) {
            return <></>;
          }

          if (config.maintenanceMode && !isAdmin(internalUser)) {
            return <MaintenanceModePage />;
          }

          return (
            <CartProvider>
              <ConfigProvider>
                <DashboardCountProvider>
                  <UnsavedDataPrompt />
                  <Switch>
                    <AuthenticatedRoute
                      allowedRoles={[GroupType.Admin]}
                      path={AvailableSharedRoutes.SecretCloneSystem}
                      component={SystemCloningPage}
                    />
                    <PasswordResetRoute path={AvailableSharedRoutes.ResetPassword} component={PasswordResetPage} />
                    {/* CartProvider is needed for sidebar cart button */}
                    <PasswordResetDialogProvider>
                      <DashboardActionProvider>
                        <SidebarLayout>
                          <Switch>
                            {/* Dashboard for all user groups */}
                            <AuthenticatedRoute
                              allowedRoles={[GroupType.Admin, ...pomGroupTypes, ...cfmGroupTypes]}
                              exact
                              path={AvailableSharedRoutes.Portal}
                              component={DashboardPage}
                              allowedConditionCallback={isPomMainContact}
                            />

                            {/* CFM */}
                            <AuthenticatedRoute allowedRoles={cfmGroupTypes} path={AvailableCfmRoutes.Portal}>
                              <CfmRoutes />
                            </AuthenticatedRoute>

                            {/* POM */}
                            <AuthenticatedRoute
                              allowedRoles={pomGroupTypes}
                              path={AvailablePomRoutes.Portal}
                              allowedConditionCallback={isPomMainContact}
                            >
                              <PomRoutes />
                            </AuthenticatedRoute>

                            {/* ADMIN CONFIGURATOR */}
                            {config.featureToggles.articleConfigurator && (
                              <AuthenticatedRoute
                                path={AvailableConfiguratorRoutes.Portal}
                                allowedRoles={[GroupType.Admin]}
                              >
                                <ConfigurationRoutes />
                              </AuthenticatedRoute>
                            )}

                            {/* SHARED */}
                            <AuthenticatedRoute
                              path={AvailableSharedRoutes.Portal}
                              allowedConditionCallback={isPomMainContact}
                            >
                              <SharedRoutes />
                            </AuthenticatedRoute>
                          </Switch>
                        </SidebarLayout>
                      </DashboardActionProvider>
                    </PasswordResetDialogProvider>

                    <Redirect path="*" to={AvailableSharedRoutes.Portal} push />
                  </Switch>
                  {isScrollToTopVisible && <ScrollToTopButton />}
                </DashboardCountProvider>
              </ConfigProvider>
            </CartProvider>
          );
        }}
      </AuthProviderContext.Consumer>
    </AuthProvider>
  );
};
