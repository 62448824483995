import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { LoginAsButtonOutlined } from "../../../../../components/Primitives";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { isAgencyGroup } from "../../../../domain/group/group-type";
import { getRolePomTranslation } from "../../../../domain/user/role-pom";
import { isAdmin, isCfmCorporate, isCfmWasteProducer, isPomMainContact, IUser } from "../../../../domain/user/user";
import { getUserTypeLevelTranslation } from "../../../../domain/user/user-type-level";
import { getActiveStatusLabel } from "../../../../util/translation.util";
import { AppDialog } from "../../../../components/dialog/app-dialog.component";
import { DetailValueDisplay } from "../../../../components/value-display/detail-value-display.component";
import { GroupDetailStatusTag } from "../group-detail-status-tag.component";

interface IUserDetailDialogProps {
  open: boolean;
  user: IUser | undefined;
  onClose: VoidFunction;
  onEditClick: VoidFunction;
  onLoginAsClick?: VoidFunction;
}

export const UserDetailDialog: VFC<IUserDetailDialogProps> = (props) => {
  const { open, user, onClose, onEditClick, onLoginAsClick } = props;
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();

  const isLoginAsEnabled =
    (onLoginAsClick && isAdmin(internalUser)) ||
    isAgencyGroup(internalUser?.group?.type ? [internalUser.group.type] : []);

  const isEditAllowed =
    isAdmin(internalUser) ||
    isAgencyGroup(internalUser?.group?.type ? [internalUser.group.type] : []) ||
    isPomMainContact(internalUser) ||
    isCfmCorporate(internalUser) ||
    isCfmWasteProducer(internalUser);

  return (
    <AppDialog
      title={t("basedata.users.detail")}
      open={open}
      onCancelClick={onClose}
      onAcceptClick={onEditClick}
      acceptTextOverride={t("general.edit.text")}
      acceptDisabled={!isEditAllowed}
      alignButtons="space-between"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" spacing={1}>
            <Grid item>
              <GroupDetailStatusTag
                label={getActiveStatusLabel(user?.active, t)}
                type={user?.active === null ? "error" : "success"}
              />
            </Grid>
            {isLoginAsEnabled && (
              <Grid item>
                <LoginAsButtonOutlined variant="outlined" onClick={onLoginAsClick} disabled={!isLoginAsEnabled}>
                  {t("basedata.users.login.button", {
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                  })}
                </LoginAsButtonOutlined>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={12} md={12}>
              <DetailValueDisplay label={t("basedata.users.edit.title")} value={user?.title ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.users.edit.firstName")} value={user?.firstName ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.users.edit.lastName")} value={user?.lastName ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.users.edit.loginEmail")} value={user?.email ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.users.edit.deliveryEmail")} value={user?.deliveryEmail ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.users.edit.password.text")} value={"**********"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.users.edit.phone")} value={user?.telephone ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.users.edit.pom_type")}
                value={user?.rolePom ? t(getRolePomTranslation(user.rolePom)) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.users.edit.cfm_type")}
                value={user && user?.userTypeLevel !== null ? t(getUserTypeLevelTranslation(user.userTypeLevel)) : "-"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
