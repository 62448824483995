import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { useMutation, useQueryClient } from "react-query";
import qs from "qs";
import { CfmOrderXStatusModel } from "../../../models/order-x/cfm-order-x.status.model";

const deletePendingOrdersX = async (orderIds: number[]): Promise<void> => {
  await getInstance().delete(`${RestEndpoints.CfmOrdersX}`, {
    params: {
      orderIds: orderIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const useDeletePendingOrdersX = () => {
  const queryClient = useQueryClient();
  return useMutation(
    CfmQueryKeys.DeletePendingOrders,
    ({ orderIds }: { orderIds: number[] }) => deletePendingOrdersX(orderIds),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([CfmQueryKeys.GetOrderXStatusCount, CfmOrderXStatusModel.ShoppingCart]);
      },
    },
  );
};
