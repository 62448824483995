import { CSSProperties } from "react";

/** Chosen to fit a half-width input field into the order overview
 *  at the smallest possible desktop-breakpoint at `width=960px`.
 */
const LABEL_LENGTH_THRESHOLD = 30;
const SHRINK_LABEL_STYLE: CSSProperties = { fontSize: "12px" } as const;

interface IUseShrinkLabel {
  shrinkStyle: CSSProperties;
}

/**
 * Returns a style, which reduces the size of labels if they overstep a certain length.
 * Useful to avoid ugly text wraps in input fields.
 */
export const useShrinkLabel = (label: string): IUseShrinkLabel => {
  const isTooLong = label && label.length > LABEL_LENGTH_THRESHOLD;

  const shrinkStyle = isTooLong ? SHRINK_LABEL_STYLE : {};

  return { shrinkStyle };
};
