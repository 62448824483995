import { useQuery } from "react-query";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { CfmQueryKeys } from "../cfm-query-keys";
import { ICfmPackage } from "./../../../domain/packages/cfm-package";
import { CfmPackageConverter } from "./../../models/converter/cfm-package.converter";
import { ICfmPackageModel } from "./../../models/package/cfm-package.model";

export const getPackagesForProduct = async (
  productId: number | undefined,
  searchText: string | undefined,
): Promise<ICfmPackage[] | undefined> => {
  if (!productId) return undefined;
  const url = replaceUriParameter(RestEndpoints.PackagesForProduct, "id", productId);
  const response = await getInstance().get<ICfmPackageModel[]>(url, { params: { searchQuery: searchText } });
  return response.data.map(CfmPackageConverter.toDomain);
};

export const useGetPackagesForProduct = (productId: number | undefined, searchText: string | undefined) => {
  return useQuery([CfmQueryKeys.GetPackagesForProduct, productId], () => getPackagesForProduct(productId, searchText), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(productId),
  });
};
