import { TranslateFunc } from "./translation.utils";
import { GroupType } from "../shared/domain/group/group-type";
import { AddressType, recyclerAddressTypes } from "../shared/models/address/address.model";
import { GroupContractType } from "../shared/models/group/group-contract.model";

export const getGroupTypeTranslation = (type: GroupType, t: TranslateFunc) => {
  const groupTypeKey = GroupType[type]?.replace(/([a-z])([A-Z])/g, "$1_$2");
  const id = `basedata.users.table.group.${groupTypeKey}`.toLowerCase();
  return t(id);
};

export const getGroupContractTypeTranslation = (type: GroupContractType, t: TranslateFunc) => {
  const id = `basedata.groups.contractsTable.${type}`;
  return t(id);
};

export const getGroupTypeTranslationForLabel = (type: GroupType, t: TranslateFunc) => {
  const id = getGroupTypeTranslation(type, t);
  return `${t("basedata.group.groupType")}: ${t(id)}`;
};

const descriptionMaxLength = 100;
export const getDescriptionTextForTooltip = (description: string | undefined, t: TranslateFunc) => {
  return description && description.length > descriptionMaxLength
    ? `${t("orders.new.wizard.group.description")}: ${description}`
    : "";
};

export const getAddressTypesFromGroupType = (groupType: GroupType): AddressType[] => {
  switch (groupType) {
    case GroupType.Customer:
    case GroupType.PomRepresentative:
      return [AddressType.CustomerLocation];
    case GroupType.CfmRecycler:
      return recyclerAddressTypes;
    case GroupType.CfmLogistics:
      return [AddressType.LogisticLocation];
    default:
      return [AddressType.CustomerLocation];
  }
};
