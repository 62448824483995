import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDateShort } from "../../../../shared/util/date.util";
import { Colors, Shades } from "../../../../style/Colors";
import { formatNumber } from "../../../../utils/number.helper";
import { IPomAnnouncementTableData } from "../pom-announcement-table-data";

const useStyles = makeStyles((theme: Theme) => ({
  historyRow: {
    color: Shades.gray60,
    backgroundColor: Shades.gray20,
    "& td": {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "16px",
    },
  },
  valueCol: {
    textAlign: "end",
  },
  borderTop: {
    position: "relative",
    "& td": {
      height: "2px",
      backgroundColor: Shades.gray40,
      position: "absolute",
      left: "16px",
      width: "calc(100% - 32px)",
    },
  },
  borderBottom: {
    borderBottom: `2px solid ${Colors.white}`,
    height: "8px",
    backgroundColor: Shades.gray20,
  },
}));

export interface IPomAnnouncementTableHistoryRowProps {
  product: IPomAnnouncementTableData;
}

export const PomAnnouncementTableHistoryRow: React.FC<IPomAnnouncementTableHistoryRowProps> = ({ product }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getCreatedAtDate = (data: IPomAnnouncementTableData, isInitial: boolean): string => {
    const date = data.createdAt;
    if (!date) {
      return "-";
    }

    if (isInitial) {
      return `${t("pom.announcements.announcement_date")} ${formatDateShort(date)}`;
    }
    return `${t("pom.announcements.history_date")} ${formatDateShort(date)}`;
  };

  const getUsername = (data: IPomAnnouncementTableData): string => {
    const { createdByAdminName, createdByUserName } = data;

    if (createdByAdminName) {
      return t("pom.announcements.history_admin_user", {
        user: createdByUserName ?? "-",
        admin: createdByAdminName,
      });
    }
    if (createdByUserName) {
      return t("pom.announcements.history_user", { user: createdByUserName });
    }

    return "-";
  };

  const BorderTop = () => {
    return (
      <tr className={classes.borderTop}>
        <td colSpan={9} />
      </tr>
    );
  };

  const BorderBottom = () => {
    return (
      <>
        <tr className={classes.borderBottom}>
          <td colSpan={11} />
        </tr>
      </>
    );
  };

  return (
    <>
      <BorderTop />
      <PomAnnouncementTableHistoryRowEntry
        dateInfo={getCreatedAtDate(product, product.history?.length === 0)}
        userInfo={getUsername(product)}
        countInfo={formatNumber(product.count, 0)}
        weightInfo={formatNumber(product.totalWeight, 3)}
      />
      {product.history?.length === 0 && <BorderBottom />}
      {product.history?.map((historyProduct, index) => {
        const hasBottomBorder = product.history?.length === index + 1;
        return (
          <>
            <PomAnnouncementTableHistoryRowEntry
              key={`history-item-${historyProduct.createdAt}`}
              dateInfo={getCreatedAtDate(historyProduct, index + 1 === product.history?.length)}
              userInfo={getUsername(historyProduct)}
              countInfo={formatNumber(historyProduct.count, 0)}
              weightInfo={formatNumber(historyProduct.totalWeight, 3)}
            />
            {hasBottomBorder && <BorderBottom />}
          </>
        );
      })}
    </>
  );
};

const PomAnnouncementTableHistoryRowEntry: React.FC<{
  dateInfo: string;
  userInfo: string;
  countInfo?: string;
  weightInfo?: string;
}> = ({ dateInfo, userInfo, countInfo, weightInfo }) => {
  const classes = useStyles();

  return (
    <tr className={classes.historyRow}>
      <td colSpan={2}>{dateInfo}</td>
      <td colSpan={4}>{userInfo}</td>
      <td className={classes.valueCol}>{countInfo}</td>
      <td className={classes.valueCol}>{weightInfo}</td>
      {/*@ts-ignore colSpan 100% = fill available col's*/}
      <td colSpan={"100%"} />
    </tr>
  );
};
