import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { IIconsBaseProps, UserIcon } from "../../../components/Primitives";
import { useSidebarStyle } from "../../../style/sidebar.style";
import { AvailableMenus, isAvailableMenuValue } from "../../util/sidebar.utils";
import { INavigationItem } from "../navigation/navigation-item";
import { useSidebarContext } from "./sidebar-context";
import { SubMenuItem } from "./submenu-item.component";

const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    backgroundColor: "transparent",
    paddingTop: 0,
    margin: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
}));

interface ISidebarMenuItemProps {
  name: string;
  navItem: INavigationItem;
  menuItem: string; // label of navItem
}

export const SidebarMenuItem: React.VFC<ISidebarMenuItemProps> = (props) => {
  const classes = useSidebarStyle();
  const containerClasses = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { setSidebarOpen, isFixed, activeMenu, setActiveMenu } = useSidebarContext();
  const { name, navItem, menuItem } = props;
  const NavIcon = navItem.icon ? (navItem.icon as FunctionComponent<IIconsBaseProps>) : UserIcon;

  // describes if the menu is expanded if the user just landed on page
  const isMenuExpanded = useCallback(() => {
    if (!isAvailableMenuValue(menuItem)) return false;
    return activeMenu === menuItem;
  }, [activeMenu, menuItem]);

  // local state is needed to handle when user clicks on same accordion to close it
  const [isMenuExpandedLocal, setIsMenuExpandedLocal] = useState<boolean>(isMenuExpanded());

  //handle user was redirected to menu item, and did not click on sidebar item
  useEffect(() => {
    if (!navItem.isParent || !navItem.content || !isAvailableMenuValue(navItem.label)) return;
    const isChildSelected =
      (navItem.content ?? []).find((child) => new RegExp(child.escapedUrl ?? child.to).test(pathname)) !== undefined;

    if (isChildSelected) {
      setActiveMenu(navItem.label);
    }
    setIsMenuExpandedLocal(isChildSelected);
  }, [pathname]);

  const handleNavigationItemClick = (targetItem: INavigationItem): void => {
    history.push(targetItem.to);
    !isFixed && setSidebarOpen(false);
  };

  const isActiveSubItem = (item: INavigationItem) => {
    const defaultIsVisible = item.to === pathname;
    const invisibleChildActive = (item.invisibleChildrenUrls ?? []).some((childUrl) =>
      new RegExp(childUrl).test(pathname),
    );
    return defaultIsVisible || invisibleChildActive;
  };

  const isActiveMenu = () => {
    const paths = navItem.content?.filter((item) => new RegExp(item.to).test(pathname));
    return paths && paths.length > 0 ? true : false;
  };

  const handleAccordion = () => {
    if (isMenuExpandedLocal) {
      setActiveMenu(AvailableMenus.None);
      setIsMenuExpandedLocal(false);
    } else if (isAvailableMenuValue(menuItem)) {
      setActiveMenu(menuItem);
      setIsMenuExpandedLocal(true);
    }
  };

  return (
    <Accordion
      expanded={isMenuExpandedLocal}
      onChange={handleAccordion}
      className={classes.expandListItem}
      id={`${name}-menu`}
      key={`${name}-menu`}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        key={`${name}-summary`}
        expandIcon={<ArrowDropDown color="primary" className={classes.expandIcon} />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
        className={classes.summary}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <NavIcon svgColor="green" />
        </ListItemIcon>
        <ListItemText
          primary={t(navItem.label)}
          className={isActiveMenu() ? classes.activeListTitle : classes.listTitle}
        />
      </AccordionSummary>
      <AccordionDetails key={`${name}-details`} classes={{ root: containerClasses.detailsContainer }}>
        <List className={classes.list}>
          {(navItem.content ?? [])
            .filter((item) => item.visible)
            .map((item, index) => {
              return (
                <SubMenuItem
                  key={`${item.label}-${index}`}
                  onClick={() => handleNavigationItemClick(item)}
                  label={item.label}
                  isActive={isActiveSubItem(item)}
                />
              );
            })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
