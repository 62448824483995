export enum RestEndpoints {
  // Auth
  Login = "/v1/auth/login",
  LoginAsUser = "/v1/auth/login-as/:id",
  LogoutAsUser = "/v1/auth/logout-as",
  Logout = "/v1/auth/logout",

  // User
  AllUsers = "/v1/users",
  CreateUser = "/v1/users",
  EditUser = "/v1/users/:id",
  GetUser = "/v1/users/:id",
  Unlock = "/v1/users/unlock",
  ResetPassword = "/v1/users/reset-password",
  GetUserInfo = "/v1/users/me",
  IsPasswordResetRequired = "/v1/users/isPasswordResetRequired",
  UserForgotPassword = "/v1/users/forgot-password",

  // Shared endpoints
  ResolveAddressToLatLng = "/v1/addresses/resolve",
  NextErpNumber = "/v1/groups/next-erp-number",
  Groups = "/v1/groups",
  GroupBranches = "/v1/group-branches",
  GroupById = "/v1/groups/:id",
  UpdateGroupBaseData = "/v1/groups/:id/basedata",
  UpdateGroupPaymentInfo = "/v1/groups/:id/payment-info",
  UpdateGroupTypeInfo = "/v1/groups/:id/type-info",
  UpdateGroupActive = "/v1/groups/:id/active",
  GroupHistory = "/v1/groups/:id/history",
  GroupForDetailView = "/v1/groups/:groupId/details",
  CreateGroup = "/v1/groups",
  AddressesForGroup = "/v1/groups/:groupId/addresses-for-group",
  UsersForGroup = "/v1/groups/:groupId/users",
  SystemClone = "/v1/persistence/clone/",
  GroupsAndManufacturers = "/v1/groups-and-manufacturers",
  ContractsForGroup = "/v1/groups/:id/contracts",
  CreateGroupContract = "/v1/groups/:id/contract",
  UpdateGroupContract = "/v1/groups/:groupId/contract/:contractId",
  AddressesForUser = "/v1/users/addresses",
  AddressById = "/v1/addresses/:id",
  BattGRegistrations = "/v1/battg/registrations",
  BattGRegistrationOfGroup = "/v1/battg/registrations/current",
  BattGBrands = "/v1/battg/brands",
  BattGGrsBatteryTypes = "/v1/battg/grs-battery-types",
  NextContractNumber = "/v1/groups/next-contract-number",
  UserAddresses = "/v1/users/:id/addresses",
  UserRelatedWasteProducers = "/v1/users/:id/related-waste-producers",
  Addresses = "/v1/addresses",
  CreateAddress = "/v1/addresses",
  EditAddress = "/v1/addresses/:id",
  GroupExistingDebitors = "/v1/groups-existing-debitordata",
  Freshdesk = "/v1/freshdesk",
  Config = "/v1/config",

  // Storage
  Storage = "/v1/storage",

  // CFM endpoints
  ResponseFields = "/v1/cfm/response-fields",
  ResponseField = "/v1/cfm/response-fields/:id",
  AllResponseFields = "/v1/cfm/response-fields/search",
  CfmOrderFreshdeskRequest = "/v1/cfm/ordersX/:orderId/request-support",
  ProductById = "/v1/cfm/products/:id",
  ProductCount = "/v1/cfm/products-overview",
  CreateNewCfmProducts = "/v1/cfm/products",
  UpdateCfmProductName = "/v1/cfm/products/name",
  UpdateCfmProductActive = "/v1/cfm/products/:id/active",
  PackagesForProduct = "/v1/cfm/products/:id/packages",
  CfmCorporateUsersOfCurrentGroup = "/v1/cfm/groups/current/corporate-users",
  AllProductCategories = "/v1/cfm/products/categories",
  AllProductStates = "/v1/cfm/products/states",
  AllProductTypes = "/v1/cfm/products/types",
  AllProducts = "/v1/cfm/products/search",
  Warehouse = "/v1/cfm/warehouse/:id",
  WarehouseProducts = "/v1/cfm/warehouse/:id/products",
  WarehouseTotalWeight = "/v1/cfm/warehouse/:id/total-weight",
  AllProductFractions = "/v1/cfm/products/fractions",
  ProductAddresses = "/v1/cfm/products/:productId/addresses",
  ProductsByIds = "/v1/cfm/products/by-ids",
  GetPackages = "/v1/cfm/packages",
  CreateProductPackageConfigs = "/v1/cfm/products/package-configs",
  UploadPostalsForCreateAssignments = "/v1/cfm/routing/extract-postals",
  UploadPostalsForCreateAssignmentsTemplate = "/v1/cfm/routing/postal-template",
  GetProductRoutingAssignment = "/v1/cfm/routing/:id",
  CreateProductRoutingAssignments = "/v1/cfm/routing/",
  DeleteProductRoutingAssignment = "/v1/cfm/routing/:id",
  ResponseAssignmentsX = "/v1/cfm/response-assignments-x",

  // Order X
  CfmOrdersX = "/v1/cfm/ordersX",
  CfmOrderX = "/v1/cfm/ordersX/:id",
  CfmResponseAssignmentsForOrders = "/v1/cfm/ordersX/response-configs",
  CfmOrderXResponseReport = "/v1/cfm/ordersX/:id/response-report",
  CfmOrderXStatusCount = "/v1/cfm/ordersX-status-count/:status",
  CfmUpdateOrderXReadStatus = "/v1/cfm/ordersX/:id/read",
  CfmOrderXActions = "/v1/cfm/ordersX/:id/actions",
  CfmOrderXHistory = "/v1/cfm/ordersX/:id/history",
  CfmOrderXTickets = "/v1/cfm/ordersX/:id/tickets",
  CfmOrderXDeliveryReport = "/v1/cfm/ordersX/delivery-report",
  CfmOrderXBulkActions = "/v1/cfm/ordersX/bulk-actions",
  CfmOrdersXExportByFilter = "/v1/cfm/ordersX/export-by-filter",
  CfmOrdersXExportByIds = "/v1/cfm/ordersX/export-by-ids",
  CfmCancelationReasons = "/v1/cfm/ordersX/cancelation-reasons",
  CfmOrderLogisticRouting = "/v1/cfm/ordersX/:id/logistic-routing",
  CfmOrderAssignableStatus = "/v1/cfm/ordersX/:id/assignable-status",

  // TransferOrder
  TransferOrder = "/v1/cfm/transfer-orders",
  ExportTransferOrders = "/v1/cfm/transfer-orders/export",
  TransferOrderActions = "/v1/cfm/transfer-orders/:id/actions",
  TransferOrderProductActions = "/v1/cfm/transfer-orders/actions",
  UpdateTransferOrder = "/v1/cfm/transfer-orders/:id",
  TransferOrderNotifiedWeight = "/v1/cfm/transfer-orders/notified-weight",

  // Truckload
  CfmCreateTruckload = "/v1/cfm/truckloads",
  CfmTruckloads = "/v1/cfm/truckloads",
  CfmTruckload = "/v1/cfm/truckloads/:id",
  CfmTruckloadAvailableStatus = "/v1/cfm/truckloads/status",
  UpdateCfmTruckload = "/v1/cfm/truckloads/:id",

  // POM endpoints
  PomProducts = "/v1/pom/products",
  PomProductTakeBackSystems = "/v1/pom/products/take-back-systems",
  PomAnnouncements = "/v1/pom/announcements",
  PomAnnouncementExportExcel = "/v1/pom/announcements/export-excel",
  PomLatestAnnouncement = "/v1/pom/announcements/latest",
  PomFirstAnnouncement = "/v1/pom/announcements/first",
  MailsForGroup = "/v1/mails/mails-of-group/:groupId",
  PomAnnouncementExcel = "/v1/pom/announcement-excel/:contractId",
  PomAnnouncementExtractExcel = "/v1/pom/announcement-excel/extract/:contractId",
  PomAgencies = "/v1/pom/agencies",
  PomAgencyGroups = "/v1/pom/agencies/current/groups",
  PomManufacturer = "/v1/pom/manufacturers",
  GetPomManufacturer = "/v1/pom/manufacturers/:id",
  EditPomManufacturer = "/v1/pom/manufacturers/:id",
  PomMainContactsOfCurrentGroup = "/v1/pom/groups/current/main-contacts",
  PomProductTypes = "/v1/pom/products/types",
}

export const replaceUriParameter = (uri: string, key: string, value: string | number): string => {
  return uri.replace(`:${key}`, value.toString());
};
