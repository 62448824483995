import { useInfiniteQuery } from "react-query";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IAddressResult } from "../../../domain/address/address";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { PaginationMetaConverter } from "../../../domain/converter/pagination-meta.converter";
import { AddressType, IAddressResultModel } from "../../../models/address/address.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getInfiniteGroupAddresses = async (
  groupId: number | undefined,
  query: string | undefined,
  includeDeleted: boolean,
  types: AddressType[],
  pageParam: number = 0,
  pageSize: number = 5,
): Promise<IAddressResult> => {
  const url = replaceUriParameter(RestEndpoints.AddressesForGroup, "groupId", groupId ?? "");
  const response = await getInstance().get<IAddressResultModel>(url, {
    params: {
      addressTypes: types,
      query,
      active: includeDeleted ? undefined : true,
      page: pageParam,
      pageSize,
    },
  });

  return {
    addresses: response.data.addresses.map(AddressConverter.toDomain),
    meta: PaginationMetaConverter.toDomain(response.data.meta),
  };
};

export const useGetInfiniteGroupAddresses = (
  groupId: number | undefined,
  query: string | undefined,
  includeDeleted: boolean,
  addressTypes: AddressType[],
  pageSize?: number,
  enabled?: boolean,
) => {
  return useInfiniteQuery(
    [SharedQueryKeys.GetInfiniteGroupAddresses, pageSize, query, groupId],
    ({ pageParam }) => getInfiniteGroupAddresses(groupId, query, includeDeleted, addressTypes, pageParam, pageSize),
    {
      getNextPageParam: (lastPage) => {
        const hasNextPage = lastPage.meta.page + 1 < lastPage.meta.numberOfPages;
        if (hasNextPage) {
          return lastPage.meta.page + 1;
        }

        return undefined;
      },
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled !== undefined ? enabled : true,
    },
  );
};
