import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { isAdmin } from "../../../../../shared/domain/user/user";
import { Shades } from "../../../../../style/Colors";
import { useOrderNewContext } from "../../order-new.provider";
import { AddressSummary } from "./address-summary.component";
import { GroupSummary } from "./group-summary.component";
import { ProductSummary } from "./product-summary.component";
import { useFormContext } from "react-hook-form";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    marginTop: 25,
    marginBottom: 25,
  },
  myOrderContainer: {
    minHeight: 30,
    borderRadius: "6px 6px 0px 0px",
    backgroundColor: Shades.gray50,
    width: "fit-content",
  },
  text: {
    lineHeight: "30px",
    color: "white",
    marginLeft: 15,
    marginRight: 15,
  },
  summaryContainer: {
    marginTop: 10,
  },
}));

interface IOrderWizardSummaryProps {}

export const OrderWizardSummary: FunctionComponent<IOrderWizardSummaryProps> = (props) => {
  const classes = useStyles();
  const {
    isCustomerGroupInfoAvailable,
    isCustomerAddressInfoAvailable,
    isProductInfoAvailable,
    isPackageInfoAvailable,
  } = useOrderNewContext();
  const { internalUser } = useAuthContext();
  const { t } = useTranslation();
  const informationAvailable =
    isCustomerGroupInfoAvailable || isCustomerAddressInfoAvailable || isProductInfoAvailable || isPackageInfoAvailable;
  const { getValues } = useFormContext<IOrderNewWizardFormInputs>();
  const getProductOrders = getValues().productOrders;

  return (
    <Grid container direction="column" className={classes.gridContainer}>
      {informationAvailable && (
        <Grid item>
          <Box className={classes.myOrderContainer}>
            <Typography className={classes.text} variant="body1">
              {t("orders.new.wizard.myOrder")}
            </Typography>
          </Box>
        </Grid>
      )}
      {isCustomerGroupInfoAvailable && isAdmin(internalUser) && (
        <Grid item>
          <GroupSummary />
        </Grid>
      )}
      {isCustomerAddressInfoAvailable && (
        <Grid
          item
          className={classNames({
            [classes.summaryContainer]: isAdmin(internalUser),
          })}
        >
          <AddressSummary isFirstSummaryItem={!isAdmin(internalUser)} />
        </Grid>
      )}
      {isProductInfoAvailable && (
        <>
          {getProductOrders &&
            getProductOrders.map((p, index) => {
              return (
                <Grid key={p.package?.packageId ?? index} item className={classes.summaryContainer}>
                  <ProductSummary productOrderIndex={index} />
                </Grid>
              );
            })}
        </>
      )}
    </Grid>
  );
};
