import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../../models/converter/order-x/cfm-order-x.converter";
import { ICfmOrderXListWithoutPaginationModel } from "../../../models/order-x/cfm-order-x.model";
import { ICfmCreateOrderXListModel } from "../../../models/order-x/create/cfm-create-order-x.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { CfmOrderXStatusModel } from "../../../models/order-x/cfm-order-x.status.model";

const createOrderXQuery = async (createRequest: ICfmCreateOrderXListModel): Promise<ICfmOrderX[]> => {
  const { data } = await getInstance().post<ICfmOrderXListWithoutPaginationModel>(
    RestEndpoints.CfmOrdersX,
    createRequest,
  );
  return data.items.map(CfmOrderXConverter.toDomain);
};

export const useCreateOrderXQuery = (handleOnSuccess: VoidFunction) => {
  const queryClient = useQueryClient();
  return useMutation(
    [CfmQueryKeys.UpdateOrderX],
    (createRequest: ICfmCreateOrderXListModel) => createOrderXQuery(createRequest),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([CfmQueryKeys.GetOrderXStatusCount, CfmOrderXStatusModel.ShoppingCart]);
        handleOnSuccess();
      },
    },
  );
};
