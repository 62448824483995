import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmCreateTruckloadModel } from "../../../models/truckload/cfm-create-truckload.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const createTruckload = async (createRequest: ICfmCreateTruckloadModel): Promise<void> => {
  await getInstance().post(RestEndpoints.CfmCreateTruckload, createRequest);
};

export const useCreateTruckloadQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [CfmQueryKeys.CreateTruckload],
    (createRequest: ICfmCreateTruckloadModel) => createTruckload(createRequest),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(CfmQueryKeys.GetOrdersXWithFilter);
      },
    },
  );
};
