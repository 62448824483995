import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { CfmPlatformLogo, ConfigPlatformLogo, PomPlatformLogo } from "../../../components/Primitives/Icons";
import config from "../../../config/config";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform } from "../../../providers/Auth/platform.provider";
import { PomTakeBackSystemDialog } from "../../../put-on-market/components/announcement-dialog/pom-take-back-system.dialog";
import { getCfmShortcuts, getConfiguratorShortcuts, getPomShortcuts } from "../../../utils/shortcuts";
import { PlatformContainer } from "../../components/dashboard/platform-container.component";
import { PlatformCustomerInfoComponent } from "../../components/dashboard/platform-customer-info.component";
import { isAdmin, isCfm, isCfmCustomer, isPom, isPomAgency, isPomMainContact } from "../../domain/user/user";
import { DashboardAction, useDashboardActionContext } from "./dashboard-action.provider";
import { useDashboardCountContext } from "./dashboard-count.provider";

export const DashboardPage: React.VFC = () => {
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const {
    isWithoutPackageCountLoading,
    isWithoutRoutingCountLoading,
    isWithoutResponseCountLoading,
    withoutPackageCount,
    withoutRoutingCount,
    withoutResponseCount,
  } = useDashboardCountContext();
  const { action, isOpenAction, setIsOpenAction, setAction, contractId } = useDashboardActionContext();

  const isCountLoading = useMemo(() => {
    return isWithoutPackageCountLoading || isWithoutRoutingCountLoading || isWithoutResponseCountLoading;
  }, [isWithoutPackageCountLoading, isWithoutRoutingCountLoading, isWithoutResponseCountLoading]);

  const configCountSubTitle: string | undefined = useMemo(() => {
    if (isCountLoading || !isAdmin(internalUser)) return undefined;
    const count = (withoutPackageCount ?? 0) + (withoutRoutingCount ?? 0) + (withoutResponseCount ?? 0);
    return t("dashboard.statistic.missingConfigs", { count });
  }, [withoutPackageCount, withoutRoutingCount, withoutResponseCount, isCountLoading, internalUser, t]);

  const isPomPlatformAvailable = useMemo(() => {
    return isPom(internalUser) || isPomAgency(internalUser) || isPomMainContact(internalUser);
  }, [internalUser]);

  const userHasPomRole = useMemo(() => {
    return isAdmin(internalUser) || internalUser?.rolePom != null;
  }, [internalUser]);

  const isCfmPlatformAvailable = useMemo(() => {
    return isCfm(internalUser);
  }, [internalUser]);

  const userHasCfmLevel = useMemo(() => {
    return isAdmin(internalUser) || internalUser?.userTypeLevel != null;
  }, [internalUser]);

  const closeActionDialog = () => {
    setIsOpenAction(false);
    setAction(undefined);
  };

  const isOpen = useCallback(
    (selectedAction: DashboardAction) => {
      return action === selectedAction && isOpenAction;
    },
    [action, isOpenAction],
  );

  const items: JSX.Element[] = useMemo(() => {
    const conditionalItems = [];

    if (isPomPlatformAvailable && userHasPomRole) {
      conditionalItems.push(
        <PlatformContainer
          key={"pom"}
          title={t("dashboard.platform.pom")}
          Logo={PomPlatformLogo}
          platform={AvailablePlatform.Pom}
          shortcuts={getPomShortcuts(internalUser)}
        />,
      );
    }

    if (isCfmPlatformAvailable && userHasCfmLevel) {
      conditionalItems.push(
        <PlatformContainer
          key={"cfm"}
          title={t("dashboard.platform.cfm")}
          Logo={CfmPlatformLogo}
          platform={AvailablePlatform.Cfm}
          shortcuts={getCfmShortcuts(internalUser)}
        />,
      );
    }

    if (isAdmin(internalUser) && config.featureToggles.articleConfigurator) {
      conditionalItems.push(
        <PlatformContainer
          key={"configurator"}
          title={t("dashboard.platform.configurator")}
          Logo={ConfigPlatformLogo}
          platform={AvailablePlatform.Config}
          shortcuts={getConfiguratorShortcuts(internalUser)}
          subTitle={configCountSubTitle}
        />,
      );
    }

    if (isCfmCustomer(internalUser)) {
      conditionalItems.push(<PlatformCustomerInfoComponent key={"customerInfo"}/>);
    }
    return conditionalItems;
  }, [
    configCountSubTitle,
    t,
    internalUser,
    isCfmPlatformAvailable,
    userHasCfmLevel,
    isPomPlatformAvailable,
    userHasPomRole,
  ]);

  return (
    <>
      {internalUser && internalUser.group?.id && contractId === undefined && (
        <PomTakeBackSystemDialog
          open={isOpen(DashboardAction.SELECT_TAKE_BACK_SYSTEM)}
          onAccept={closeActionDialog}
          onCancel={closeActionDialog}
          groupId={internalUser.group.id}
        />
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 900: 2 }}>
        <Masonry columnsCount={2} gutter="20px">
          {items.map((item) => item)}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};
