import { IPaginationMetaModel } from "../../models/util/pagination-meta.model";
import { IPaginationMeta } from "../pagination/pagination-meta";

export class PaginationMetaConverter {
  public static toDomain(pagination: IPaginationMetaModel): IPaginationMeta {
    return {
      numberOfPages: pagination.numberOfPages,
      numberOfRecords: pagination.numberOfRecords,
      page: pagination.page,
      pageSize: pagination.pageSize,
    };
  }

  public static toModel(pagination: IPaginationMeta): IPaginationMetaModel {
    return {
      numberOfPages: pagination.numberOfPages,
      numberOfRecords: pagination.numberOfRecords,
      page: pagination.page,
      pageSize: pagination.pageSize,
    };
  }
}
