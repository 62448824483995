import { Box, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { AcceptButton, AddButton, DeleteButton } from "../../../../components/Primitives/Buttons";
import { useButtonStyles } from "../../../../style/buttons.style";
import { Colors, Shades } from "../../../../style/Colors";
import { AvailableCfmRoutes } from "../../../../utils/constants";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { CfmOrderXBulkUpdateConverter } from "../../../repositories/models/converter/order-x/cfm-order-x-bulk-update.converter";
import { CfmOrderXStatusModel } from "../../../repositories/models/order-x/cfm-order-x.status.model";
import { useBulkUpdateOrderXQuery } from "../../../repositories/queries/order-x/mutations/bulk-update-order-x.query";
import { useDeletePendingOrdersX } from "../../../repositories/queries/order-x/mutations/delete-order-x.query";
import { useGetOrdersQuery } from "../../../repositories/queries/order-x/queries/get-orders.query";
import { CartAddressWithOrders } from "./cart-address-with-orders.component";
import { CartDeleteOrdersDialog } from "./cart-delete-orders-dialog.component";

const useStyles = makeStyles((theme: Theme) => ({
  marginTop: {
    marginTop: 30,
  },
  disabledText: {
    color: Shades.gray30,
  },
  normalText: {
    color: Colors.red,
  },
  checkBoxMargin: {
    marginLeft: 30,
    "& p": {
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 15,
    },
  },
  newOrderButtonAlign: {
    textAlign: "end",
    [theme.breakpoints.down(746)]: {
      paddingTop: 15,
    },
  },
  buttonContainer: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
      flexDirection: "column-reverse",
      marginTop: 10,
      "& button": {
        width: "100%",
      },
    },
  },
  bottomSectionContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

export interface IOrdersXPerCustomerAddress {
  customerAddressId: number;
  orders: ICfmOrderX[];
}

export const CartContent: FC = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const buttonClasses = useButtonStyles();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    data: orderData,
    isLoading,
    refetch: refetchOrders,
  } = useGetOrdersQuery({ status: CfmOrderXStatusModel.ShoppingCart });
  const { mutateAsync: orderPendingOrders, isLoading: isOrderPendingOrdersLoading } = useBulkUpdateOrderXQuery();
  const { mutateAsync: deletePendingOrders, isLoading: isDeletePendingOrdersLoading } = useDeletePendingOrdersX();

  const orders = useMemo(() => {
    return orderData?.items ?? [];
  }, [orderData]);

  const areButtonsDisabled =
    isOrderPendingOrdersLoading || isDeletePendingOrdersLoading || isLoading || (orders?.length ?? 0) === 0;
  const ordersPerCustomerAddresses: IOrdersXPerCustomerAddress[] = useMemo(() => {
    if (!orders) return [];
    const ordersPerCustomerAddresses: IOrdersXPerCustomerAddress[] = _.chain(orders)
      .groupBy((order) => order.customerAddress?.id)
      .map((orders, customerAddressId) => ({
        customerAddressId: Number(customerAddressId),
        orders: orders,
      }))
      .value();
    return ordersPerCustomerAddresses;
  }, [orders]);

  const getAllOrderIds = () => {
    const orders = ordersPerCustomerAddresses.flatMap((ordersPerAddress) => ordersPerAddress.orders);
    return orders.map((order) => order.id);
  };

  const selectAllChanges = (checked: boolean) => {
    if (!checked) {
      setSelectedOrderIds([]);
      return;
    }

    setSelectedOrderIds(_.uniq(getAllOrderIds()));
  };

  const onSelect = (ids: number[]) => {
    setSelectedOrderIds(_.uniq(ids));
  };

  const areAllOrdersSelected = () => {
    return ordersPerCustomerAddresses.length > 0 && getAllOrderIds().every((id) => selectedOrderIds.includes(id));
  };

  const handleDeleteOrders = async () => {
    await deletePendingOrders({ orderIds: selectedOrderIds });
    setIsDeleteDialogOpen(false);
    setSelectedOrderIds([]);
    refetchOrders();
    enqueueSnackbar(t("notifications.multiselect_order_delete_success.message"), {
      variant: "success",
    });
  };

  const handleOrder = async () => {
    await orderPendingOrders(CfmOrderXBulkUpdateConverter.toModel(selectedOrderIds));
    history.push(AvailableCfmRoutes.OrderXOverview);
  };

  return (
    <Box className={classes.marginTop}>
      <CartDeleteOrdersDialog
        isOpen={isDeleteDialogOpen}
        setOpen={setIsDeleteDialogOpen}
        onAccept={handleDeleteOrders}
        isProcessing={isDeletePendingOrdersLoading}
        onClose={async () => {
          setIsDeleteDialogOpen(false);
        }}
      />
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md="auto">
              <Typography variant="h1">{t("cart.header")}</Typography>
            </Grid>
            <Grid item md="auto" className={classes.newOrderButtonAlign}>
              <AddButton
                iconSvgColor={"white"}
                onClick={() => {
                  history.push(AvailableCfmRoutes.OrderXNew);
                }}
              >
                <Typography variant="body1">{t("orders.new.text")}</Typography>
              </AddButton>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && (
          <Grid item container direction="row" justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && ordersPerCustomerAddresses.length === 0 && (
          <Grid item>
            <Typography variant="body1">{t("cart.noOrders")}</Typography>
          </Grid>
        )}
        {ordersPerCustomerAddresses.map((ordersPerCustomerAddress, index) => (
          <Grid item key={index}>
            <CartAddressWithOrders
              ordersPerCustomerAddress={ordersPerCustomerAddress}
              onSelect={onSelect}
              selectedOrderIds={selectedOrderIds}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Grid container className={classes.bottomSectionContainer}>
            <Grid item xs={12} sm={12} md={4}>
              <Box className={classes.checkBoxMargin}>
                <OrderCheckbox
                  label={areAllOrdersSelected() ? t("general.deselectAll") : t("general.selectAll")}
                  onChange={selectAllChanges}
                  checked={areAllOrdersSelected()}
                  size="medium"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container className={classes.buttonContainer} spacing={2}>
                <Grid item>
                  <DeleteButton
                    disabled={areButtonsDisabled || selectedOrderIds.length === 0}
                    className={buttonClasses.secondaryDangerButton}
                    iconSvgColor={areButtonsDisabled || selectedOrderIds.length === 0 ? "disabled" : "red"}
                    onClick={() => setIsDeleteDialogOpen(true)}
                  >
                    <Typography
                      variant="body1"
                      className={classNames({
                        [classes.normalText]: !areButtonsDisabled && selectedOrderIds.length > 0,
                        [classes.disabledText]: areButtonsDisabled || selectedOrderIds.length === 0,
                      })}
                    >
                      {t("cart.deleteSelected")}
                    </Typography>
                  </DeleteButton>
                </Grid>
                <Grid item>
                  <AcceptButton
                    disabled={areButtonsDisabled || selectedOrderIds.length === 0 || isOrderPendingOrdersLoading}
                    iconSvgColor={"white"}
                    onClick={handleOrder}
                  >
                    <Typography variant="body1">{t("cart.orderSelected")}</Typography>
                  </AcceptButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
