import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { EditIconButton } from "../../../../components/Primitives";
import { GroupDetailStatusTag } from "./group-detail-status-tag.component";

const useStyles = makeStyles((theme: Theme) => ({
  iconAlign: {
    textAlign: "right",
  },
}));

interface IGroupDetailPaymentInfoNameProps {
  hasAcceptedSepaMandate: boolean;
  onEditClicked: VoidFunction;
}

export const GroupDetailPaymentInfoName: VFC<IGroupDetailPaymentInfoNameProps> = (props) => {
  const { hasAcceptedSepaMandate, onEditClicked } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const getActiveStatusLabel = () => {
    return hasAcceptedSepaMandate ? t("basedata.payment_info.active") : t("basedata.payment_info.inactive");
  };

  return (
    <Grid container direction="row" justifyContent="space-between" spacing={1}>
      <Grid item xs={10} md="auto">
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="h1">{t("basedata.payment_info.heading")}</Typography>
          </Grid>
          <Grid item>
            <GroupDetailStatusTag label={getActiveStatusLabel()} type={hasAcceptedSepaMandate ? "success" : "error"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} md="auto" className={classes.iconAlign}>
        <EditIconButton onClick={onEditClicked} />
      </Grid>
    </Grid>
  );
};
