import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import { IProductData } from "../../../../../configurator/components/product/product-list/creatable-products-list.component";
import getInstance from "../../../../../utils/rest";
import { CfmCreateProductConverter } from "../../../models/converter/cfm-create-product.converter";
import { CfmQueryKeys } from "../../cfm-query-keys";

const createNewCfmProducts = async (config: IProductData[]): Promise<void> => {
  const convertedConfig = config.map(CfmCreateProductConverter.productDataToModel);
  await getInstance().post(RestEndpoints.CreateNewCfmProducts, { data: convertedConfig });
};

export const useCreateNewCfmProductsQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    CfmQueryKeys.CreateNewProducts,
    ({ config }: { config: IProductData[] }) => createNewCfmProducts(config),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
