import { Grid, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";

interface IRemovePackageConfigDialogProps {
  open: boolean;
  onCancel: VoidFunction;
  onAccept: VoidFunction;
  packageName: string;
  isLoading?: boolean;
}

export const RemovePackageConfigDialog: VFC<IRemovePackageConfigDialogProps> = (props) => {
  const { open, onCancel, onAccept, packageName, isLoading } = props;
  const { t } = useTranslation();

  return (
    <AppDialog
      title={t("configuration.removePackageConfig.header")}
      open={open}
      onCancelClick={onCancel}
      onAcceptClick={onAccept}
      acceptTextOverride={t("basedata.general.yes.text")}
      alignButtons="space-between"
      maxWidth="xs"
      isLoading={isLoading}
    >
      <Grid container>
        <Grid item>
          <Typography variant="body1">
            {t("configuration.removePackageConfig.text", { pkgName: packageName })}
          </Typography>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
