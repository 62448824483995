import { VFC } from "react";
import { Control, Controller } from "react-hook-form";
import { CustomSwitch } from "../../switch/custom-switch.component";

interface IFormSwitch {
  label: string;
  name: string;
  control: Control<any>;
  disabled?: boolean;
  required?: boolean;
}

export const FormSwitch: VFC<IFormSwitch> = (props) => {
  const { label, name, control, disabled, required } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <CustomSwitch
            label={label}
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
            required={required}
          />
        );
      }}
    />
  );
};
