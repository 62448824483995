import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useTypographyPillStyles } from "../../../../../style/typography-pill.style";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    minHeight: 28,
    padding: "2px 10px 2px 10px",
    borderRadius: "4px",
  },

  fitContent: {
    width: "fit-content",
  },
}));

interface IOrderTicketStatusBoxProps {
  status: number;
}

export const OrderTicketStatusBox: VFC<IOrderTicketStatusBoxProps> = (props) => {
  const { status } = props;
  const classes = useStyles();
  const typographyPillClasses = useTypographyPillStyles();
  const { t } = useTranslation();

  let pillClass = typographyPillClasses.grayPill;
  if (status === 2) {
    pillClass = typographyPillClasses.redPill;
  }

  if (status === 3) {
    pillClass = typographyPillClasses.orangePill;
  }

  if (status === 4) {
    pillClass = typographyPillClasses.greenPill;
  }

  return (
    <Box className={classNames(classes.box, classes.fitContent)}>
      <Typography variant="body2" className={pillClass}>
        {status === 2 && t("order.tickets.status.open")}
        {status === 3 && t("order.tickets.status.pending")}
        {status === 4 && t("order.tickets.status.resolved")}
        {status === 5 && t("order.tickets.status.closed")}
      </Typography>
    </Box>
  );
};
