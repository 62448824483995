import { FC } from "react";

export interface INavigationItem {
  label: string;
  to: string;
  visible: boolean;
  icon?: FC;
  description?: string;
  content?: INavigationItem[];
  isParent?: boolean;
  invisibleChildrenUrls?: string[];
  escapedUrl?: string;
}

export const escapeParam = (path: string, param: string | string[]) => {
  if (Array.isArray(param)) {
    let url = path;
    for (const value of param) {
      url = url.replace(value, "[0-9]+");
    }

    return url;
  } else {
    return path.replace(param as string, "[0-9]+");
  }
};
