import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProduct } from "../../../../domain/products/cfm-product";
import { CfmProductConverter } from "../../../models/converter/cfm-product.converter";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { IProductsResponseModel } from "../../../models/product/cfm-product.model";

const getAllProducts = async (): Promise<ICfmProduct[]> => {
  const { data } = await getInstance().post<IProductsResponseModel>(RestEndpoints.AllProducts);
  return data.products.map(CfmProductConverter.toDomain);
};

export const useGetAllProductsQuery = (enabled: boolean = true) => {
  return useQuery([CfmQueryKeys.GetAllProducts], () => getAllProducts(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
