import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React, { ReactNode } from "react";
import {
  DangerousDeleteIconNoBorderButton,
  EditIconNoBorderButton,
} from "../../../../../components/Primitives/Buttons";
import { useScreenSize } from "../../../../../hooks/use-screen-size";

const useStyles = makeStyles((theme: Theme) => ({
  iconPadding: {
    paddingRight: 25,
  },
  columnContainer: {
    height: "100%",
  },
  buttonsContainer: {
    width: 90,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      paddingTop: 10,
      justifyContent: "center",
    },
  },
  paddingBottom: {
    paddingBottom: 10,
  },
  paddingTopAndBottom: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  paddingLeftAndRight: {
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  iconDimensions: {
    height: 40,
    width: 40,
  },
  iconContainer: {
    width: 90,
  },
  summaryContainer: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    padding: "15px 30px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      padding: "15px",
    },
  },
  subSummaryContent: {
    marginLeft: 75,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  summaryContent: {
    width: "100%",
  },
  mobileIcon: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 5,
    },
  },
}));

interface ISummaryBoxProps {
  iconComponent: JSX.Element;
  labelComponent: JSX.Element;
  onEditClick?: VoidFunction;
  onDeleteClick?: VoidFunction;
  isSubSummaryBox?: boolean;
  children?: ReactNode;
}

export const SummaryBox: React.FC<ISummaryBoxProps> = (props) => {
  const { iconComponent, labelComponent, onEditClick, onDeleteClick, isSubSummaryBox, children } = props;
  const classes = useStyles();
  const { isDesktop, isTablet, isMobile } = useScreenSize();

  return (
    <div
      className={classNames(classes.summaryContainer, {
        [classes.subSummaryContent]: isSubSummaryBox,
      })}
    >
      <Grid
        item
        className={classNames(classes.iconContainer, {
          [classes.iconPadding]: isSubSummaryBox && isDesktop,
        })}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          className={classNames(classes.columnContainer, classes.mobileIcon, {
            [classes.iconPadding]: !isSubSummaryBox && !isTablet,
          })}
        >
          {iconComponent}
        </Grid>
      </Grid>
      <div className={classNames(classes.summaryContent)}>
        <Grid container direction="column" justifyContent="center" className={classes.columnContainer}>
          <Grid item>{labelComponent}</Grid>
          {children}
        </Grid>
      </div>

      <Grid container className={classNames(classes.buttonsContainer)}>
        {onEditClick && isMobile && <Grid item xs={4} md="auto" />}
        {onEditClick && (
          <EditIconNoBorderButton
            className={classes.iconDimensions}
            onClick={() => {
              onEditClick();
            }}
          />
        )}
        {onDeleteClick && (
          <DangerousDeleteIconNoBorderButton
            className={classes.iconDimensions}
            onClick={() => {
              onDeleteClick();
            }}
          />
        )}
        {onEditClick && isMobile && <Grid item xs={4} md="auto" />}
      </Grid>
    </div>
  );
};
