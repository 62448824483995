import { Box, Grid, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { EditIconNoBorderButton } from "../../../components/Primitives/Buttons";
import { useProductPackageDataDisplayStyle } from "../../../style/product-package-data-display.style";
import { ArticleNumberBox } from "./article-number-box.component";
import { ProductActiveBadge } from "./product-active-badge.component";

interface IProductDataDisplayProps {
  products: ICfmProduct[];
  onEditClick: VoidFunction;
  showActiveBadge?: boolean;
  canEdit?: boolean;
}

export const ProductDataDisplay: VFC<IProductDataDisplayProps> = (props) => {
  const { products, onEditClick, showActiveBadge, canEdit = true } = props;
  const classes = useProductPackageDataDisplayStyle();
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Grid item>
        <Box className={classes.labelContainer}>
          <Typography variant="body1" className={classes.label}>
            {t("configuration.article")}
          </Typography>
        </Box>
      </Grid>
      <Grid item className={classes.dataContainer}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="column" className={classes.productOrPackageContainer} spacing={1}>
              {products.map((product) => {
                const active = (product.responseAssignments?.length ?? 0) > 0 ? true : false;
                return (
                  <Grid item key={product.id}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <ArticleNumberBox articleNumber={product.articleNumber} />
                      </Grid>
                      <Grid item>
                        <Box display={"flex"} alignItems={"center"} className={classes.container}>
                          <Typography variant="body1" className={classes.name}>
                            {product.name}
                          </Typography>
                        </Box>
                      </Grid>
                      {showActiveBadge && (
                        <Grid item>
                          <ProductActiveBadge isActive={active} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {canEdit && (
            <Grid item>
              <Grid container direction="column" className={classes.container} justifyContent="center">
                <Grid item>
                  <EditIconNoBorderButton className={classes.iconDimensions} onClick={onEditClick} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
