import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IAddress } from "../../../../shared/domain/address/address";
import { IAutocompleteOption } from "../../../../utils/AutocompleteHelper";
import { NO_FILTER_SELECT_OPTION } from "../../../../utils/constants";
import { ProductRoutingAddressesFormAddressSection } from "./product-routing-addresses-form-address-section.component";

export const emptyOption = {
  id: NO_FILTER_SELECT_OPTION,
  name: "",
};

interface IProductRoutingAddressesFormContentProps {
  logisticAddresses: IAddress[];
  recyclerAddresses: IAddress[];
  startAddressOptions: IAutocompleteOption[];
  selectedGroupId: number | undefined;
  setSelectedGroupId: (groupId: number | undefined) => void;
}

export const ProductRoutingAddressesFormContent: React.FC<IProductRoutingAddressesFormContentProps> = (props) => {
  const { logisticAddresses, recyclerAddresses, startAddressOptions, selectedGroupId, setSelectedGroupId } = props;
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <ProductRoutingAddressesFormAddressSection
          key="primary"
          heading={`${t("configuration.assignments.main")} *`}
          logisticAddresses={logisticAddresses}
          recyclerAddresses={recyclerAddresses}
          setGroupId={setSelectedGroupId}
          groupId={selectedGroupId}
          startAddresses={startAddressOptions}
          required={true}
          level="primary"
        />
      </Grid>
      <Grid item>
        <ProductRoutingAddressesFormAddressSection
          key="secondary"
          heading={t("configuration.assignments.secondary")}
          logisticAddresses={logisticAddresses}
          recyclerAddresses={recyclerAddresses}
          setGroupId={setSelectedGroupId}
          groupId={selectedGroupId}
          startAddresses={startAddressOptions}
          required={false}
          level="secondary"
        />
      </Grid>
    </Grid>
  );
};
