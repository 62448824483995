import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import { PomTakeBackSystemConverter } from "../../../../put-on-market/domain/converter/pom-takeback-system.converter";
import { IPomProductTakeBackSystem } from "../../../../put-on-market/domain/models/takeback-system";
import { IPomProductTakeBackSystemListModel } from "../../../../put-on-market/repositories/models/products/pom-product-take-back-system.model";
import getInstance from "../../../../utils/rest";
import { CfmQueryKeys } from "../cfm-query-keys";

const getTakeBackSystems = async (): Promise<IPomProductTakeBackSystem[]> => {
  const response = await getInstance().get<IPomProductTakeBackSystemListModel>(RestEndpoints.PomProductTakeBackSystems);
  return response.data.items.map(PomTakeBackSystemConverter.toDomain);
};

export const useGetTakeBackSystemsQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useQuery([CfmQueryKeys.GetTakeBackSystems], () => getTakeBackSystems(), {
    onError(err) {
      const errorMsg = getErrorCode(err);
      const errorCode = getErrorStatusCode(err);
      enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
    },
  });
};
