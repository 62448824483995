import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { QuestionDialog } from "../../../../components/Dialogs/question-dialog";
import { StepperItem } from "../../../../components/Primitives";
import { HEADER_MARGIN_BOTTOM, HEADER_MARGIN_TOP } from "../../../../config/theme/overrides/constant";
import { PageTitle } from "../../../../layout-components/PageTitle/page-title";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { PrimaryColors } from "../../../../style/Colors";
import { AvailableSharedRoutes } from "../../../../utils/constants";
import { BattGContactForm } from "../../../components/batt-g/batt-g-contact-form.component";
import { BattGContactFormMode } from "../../../components/batt-g/batt-g-form.util";
import { BattGManufacturerBaseDataForm } from "../../../components/batt-g/batt-g-manufacturer-base-data-form.component";
import { BattGRegistrationStatus } from "../../../models/batt-g/batt-g-registration";
import { formatDateShort } from "../../../util/date.util";
import { BattGRegisterContext, BattGRegisterProvider } from "./batt-g-register.provider";
import { BattGRegistrationStep } from "./batt-g-registration-steps";
import { BattGBrandTableComponent } from "./brand/batt-g-brand-table.component";
import { BattGBrandProvider } from "./brand/batt-g-brand.provider";

const useStyles = makeStyles({
  headerStyle: {
    marginTop: HEADER_MARGIN_TOP,
    marginBottom: HEADER_MARGIN_BOTTOM,
  },
  stepperItemContainer: {
    "& div": {
      display: "flex",
    },
  },
  publishDate: {
    color: PrimaryColors.base,
  },
});

export const BattGRegisterPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const isManagedByGrs: boolean = internalUser?.group?.grsManagedEar ?? false;
  const history = useHistory();

  const openMailClient = (): void => {
    window.open("mailto:hersteller_ear@grs-batterien.de?subject=Anfrage für EAR-Verwaltung&body=Vertragsnummer:");
  };

  if (!isManagedByGrs) {
    return (
      <QuestionDialog
        onClose={() => {
          history.goBack();
        }}
        isOpen={true}
        onAccept={async () => openMailClient()}
        displayStyle={"warning"}
        title={t("batt_g.register.not_managed_by_grs_error_title")}
        message={t("batt_g.register.not_managed_by_grs_error_message")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        acceptText={t("batt_g.register.not_managed_by_grs_error_action")}
      />
    );
  }

  return (
    <BattGRegisterProvider>
      <BattGRegisterContext.Consumer>
        {({
          isLoading,
          groupAddress,
          activeStep,
          handleStepChanged,
          mainContact,
          manufacturerBaseData,
          registrationStatus,
          getLatestPublishedAt,
        }) => {
          const latestPublishedAt = getLatestPublishedAt();
          return (
            <>
              {isLoading && <LinearProgress />}
              {!isLoading && (
                <>
                  <PageTitle id={AvailableSharedRoutes.BattGMasterData} />

                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h1" className={classes.headerStyle}>
                      {t("navigation.pom.batt_g.register.master_data")}
                    </Typography>
                    {registrationStatus === BattGRegistrationStatus.Published && latestPublishedAt && (
                      <Typography className={[classes.headerStyle, classes.publishDate].join(" ")}>
                        {t("batt_g.register.published_at", {
                          date: formatDateShort(latestPublishedAt),
                        })}
                      </Typography>
                    )}
                  </Box>
                  <Grid container alignContent={"center"} className={classes.stepperItemContainer}>
                    <StepperItem
                      isActive={activeStep === BattGRegistrationStep.ManufacturerBaseData}
                      onClick={() => handleStepChanged(BattGRegistrationStep.ManufacturerBaseData)}
                    >
                      {t("batt_g.register.manufacturer_data")}
                    </StepperItem>
                    <StepperItem
                      isActive={activeStep === BattGRegistrationStep.MainContactData}
                      onClick={() => {
                        if (manufacturerBaseData) {
                          handleStepChanged(BattGRegistrationStep.MainContactData);
                        }
                      }}
                    >
                      {t("batt_g.register.main_contact_data")}
                    </StepperItem>
                    <StepperItem
                      isActive={activeStep === BattGRegistrationStep.RepresentativeData}
                      onClick={() => {
                        if (mainContact) {
                          handleStepChanged(BattGRegistrationStep.RepresentativeData);
                        }
                      }}
                    >
                      {t("batt_g.register.representative_data")}
                    </StepperItem>
                  </Grid>

                  <Box my={4}>
                    {activeStep === BattGRegistrationStep.ManufacturerBaseData && internalUser && (
                      <BattGManufacturerBaseDataForm user={internalUser} address={groupAddress} />
                    )}
                    {activeStep === BattGRegistrationStep.MainContactData && internalUser && (
                      <BattGContactForm user={internalUser} mode={BattGContactFormMode.MainContact} />
                    )}
                    {activeStep === BattGRegistrationStep.RepresentativeData && internalUser && (
                      <BattGContactForm user={internalUser} mode={BattGContactFormMode.Representative} />
                    )}
                  </Box>
                </>
              )}
              <BattGBrandProvider>
                {registrationStatus === BattGRegistrationStatus.Published && (
                  <div className="mb-4">
                    <Typography variant="h1" className={classes.headerStyle}>
                      {t("navigation.pom.batt_g.register.brands")}
                    </Typography>
                    <BattGBrandTableComponent />
                  </div>
                )}
              </BattGBrandProvider>
            </>
          );
        }}
      </BattGRegisterContext.Consumer>
    </BattGRegisterProvider>
  );
};
