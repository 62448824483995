import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmResponseAssignmentX } from "../../../../domain/assignment/cfm-response-assignment-x";
import { CfmResponseAssignmentXConverter } from "../../../models/converter/cfm-response-assignment-x.converter";
import { ICfmResponseAssignmentXModel } from "../../../models/response-assignment/cfm-response-assignment-x.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getResponseAssignmentsForOrders = async (orderIds: number[]): Promise<ICfmResponseAssignmentX[]> => {
  const response = await getInstance().get<{ items: ICfmResponseAssignmentXModel[] }>(
    RestEndpoints.CfmResponseAssignmentsForOrders,
    {
      params: { orderIds },
    },
  );
  return response.data.items.map(CfmResponseAssignmentXConverter.toDomain);
};

export const useGetResponseAssignmentsForOrdersQuery = (orderIds: number[]) => {
  return useQuery(
    [CfmQueryKeys.GetServiceDatesForTruckload, orderIds],
    () => getResponseAssignmentsForOrders(orderIds),
    {
      staleTime: ReactQueryConfig.staleTime,
    },
  );
};
