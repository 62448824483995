import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { usePlatformContext } from "../../../../../providers/Auth/platform.provider";
import { IAddressLean } from "../../../../domain/address/address-lean";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { AddressForm, AddressFormMode, IAddressFormInputs } from "../../../address/address-form/address-form.component";
import { AddressFormProvider } from "../../../address/address-form/address-form.provider";
import { AppDialog } from "../../../dialog/app-dialog.component";
import { useGroupDetailContext } from "../../../../pages/groups/group-detail.provider";
import { GroupType } from "../../../../domain/group/group-type";
import { AddressType } from "../../../../models/address/address.model";

interface IEditGroupAddressDialogProps {
  open: boolean;
  groupId: number;
  address: IAddressLean | undefined;
  onSaved: VoidFunction;
  onCancel: VoidFunction;
  hide?: (keyof IAddressFormInputs)[] | undefined;
  canDeactivate?: boolean;
}

export const EditGroupAddressDialog: VFC<IEditGroupAddressDialogProps> = (props) => {
  const { open, groupId, address, onSaved, onCancel, hide, canDeactivate = true } = props;
  const { t } = useTranslation();
  const { activePlatform } = usePlatformContext();
  const queryClient = useQueryClient();
  const { group } = useGroupDetailContext();

  const addressTypes =
    group.type === GroupType.CfmRecycler ? [AddressType.RecyclerLocation, AddressType.SorterLocation] : [];

  const saveClicked = () => {
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupAddressesForUuid);
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    onSaved();
  };

  return (
    <AppDialog
      title={t("basedata.addresses.groupDetailAddress.edit")}
      maxWidth="md"
      open={open}
      alignButtons="space-between"
      id={address?.id}
    >
      <AddressFormProvider shouldPersistAddress groupId={groupId} address={address} onSuccessCallback={saveClicked}>
        <AddressForm
          mode={AddressFormMode.Edit}
          shownAsModal={true}
          platform={activePlatform}
          canDeactivate={canDeactivate}
          hide={hide ?? ["type"]}
          onCancelCallback={onCancel}
          displayHeading={false}
          addressTypes={addressTypes}
        />
      </AddressFormProvider>
    </AppDialog>
  );
};
