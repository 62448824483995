import { Divider, Grid, makeStyles } from "@material-ui/core";
import { BIC, IBAN } from "ibankit";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { nonEmptyString } from "utils/validationHelper";
import { Shades } from "../../../../style/Colors";
import { IPaymentInfoFormInput } from "../../../domain/payment-info/payment-info-form-input";
import { getFormattedBic, getFormattedIban } from "../../../util/payment-info.util";
import { FormSwitch } from "../../form/fields/form-switch.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { FormHeader } from "../../form/form-header.component";

export interface IGroupBaseDataPaymentInfoFormInputs {
  hasAcceptedSepaMandate: boolean;
  paymentInfo?: IPaymentInfoFormInput;
}

export const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: Shades.gray20,
    marginTop: 0,
    marginBottom: 0,
  },
}));

interface IGroupFormPaymentSectionProps {
  isPomGroup: boolean;
  displayHeader?: boolean;
  displayDivider?: boolean;
}

export const GroupFormPaymentSection: VFC<IGroupFormPaymentSectionProps> = (props) => {
  const { isPomGroup, displayHeader = true, displayDivider = true } = props;
  const {
    control,
    formState: { errors },
    getFieldState,
    getValues,
    setValue,
    watch,
  } = useFormContext<IGroupBaseDataPaymentInfoFormInputs>();
  const { t } = useTranslation();
  const classes = useStyles();

  const hasAcceptedSepaMandate = watch("hasAcceptedSepaMandate");

  // Require fully filled out PaymentInfo if:
  // - SEPA mandate is checked
  // - Any field is touched and any field has a value
  // - Is active POM group
  const isRequired = (): boolean => {
    if (!hasAcceptedSepaMandate) {
      return false;
    }

    const fieldState = getFieldState("paymentInfo");
    const paymentInfo = getValues("paymentInfo");

    const hasBeenTouched = fieldState.isTouched;
    const hasAnyValue = !!paymentInfo && Object.values(paymentInfo).some((v) => !!v);
    return hasAcceptedSepaMandate || (hasBeenTouched && hasAnyValue) || isPomGroup;
  };

  // See <https://trello.com/c/Ss8ITlSI/83-react-hook-form-conditional-required-flags>
  // Manually enforce conditional required flag as dynamically updating it,
  // leads to wrong behavior.
  // e.g. Fields are not required that should be required.
  const isNotRequiredString = (value: string | undefined): boolean => {
    if (!isRequired()) return true;
    return !!value && nonEmptyString(value);
  };

  const setFormattedIban = (iban: string): void => {
    setValue("paymentInfo.iban", getFormattedIban(iban));
  };

  const setFormattedBic = (bic: string): void => {
    setValue("paymentInfo.bicOrSwift", getFormattedBic(bic));
  };

  return (
    <Grid container spacing={4}>
      {displayHeader && (
        <Grid item>
          <Grid container direction="row" className={"px-2"}>
            <FormHeader size="small">{t("basedata.payment_info.heading")}</FormHeader>
          </Grid>
        </Grid>
      )}

      <FormField md={12}>
        <FormSwitch name="hasAcceptedSepaMandate" control={control} label={t("basedata.payment_info.active")} />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(errors?.paymentInfo?.name)}
          label={t("basedata.payment_info.name")}
          required={isRequired()}
          control={control}
          name={"paymentInfo.name"}
          rules={{
            validate: isNotRequiredString,
            deps: "paymentInfo",
          }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(errors?.paymentInfo?.iban)}
          label={t("basedata.payment_info.iban")}
          required={isRequired()}
          maxLength={50}
          control={control}
          name={"paymentInfo.iban"}
          rules={{
            required: isRequired(),
            onChange: (e) => setFormattedIban(e.target.value),
            validate: (value) => {
              if (!isRequired() && value === "") return true;
              return value && IBAN.isValid(value);
            },
            deps: "paymentInfo",
          }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(errors?.paymentInfo?.bicOrSwift)}
          label={t("basedata.group.payment_info.bic_or_swift")}
          maxLength={20}
          required={isRequired()}
          control={control}
          name={"paymentInfo.bicOrSwift"}
          rules={{
            required: isRequired(),
            onChange: (e) => setFormattedBic(e.target.value),
            validate: (value) => {
              if (!isRequired() && value === "") return true;
              return value && BIC.isValid(value);
            },
            deps: "paymentInfo",
          }}
        />
      </FormField>

      {displayDivider && (
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};
