export enum CfmOrderXStatusModel {
  ShoppingCart = 10,
  Ordered = 20,
  LogisticAccepted = 30,
  LogisticAnnounced = 40,
  LogisticPerformed = 50,
  LogisticWarehouse = 60,
  LogisticTruckLoadAnnounced = 70,
  RecyclerIncomingWarehouse = 90,
  RecyclerOutgoingWarehouse = 100,
  DestinationArrived = 110,
  DestinationReceived = 120,
  Confirmed = 600,
  Canceled = 900,
  Unsupported = -1,
}
