import _ from "lodash";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { isAdmin, isCfmLogistics } from "../../../shared/domain/user/user";
import { IRouterState } from "../../../shared/util/router/router-state";
import { AvailableCfmRoutes } from "../../../utils/constants";
import { ICfmOrderX, ICfmOrderXList } from "../../domain/order-x/cfm-order-x";
import { CfmOrderXStatusFilter, ICfmOrderXFilter } from "../../domain/order-x/cfm-order-x-filter";
import { CfmOrderXApiStatus } from "../../domain/order-x/cfm-order-x.status";
import { CfmOrderXStatusConverter } from "../../repositories/models/converter/order-x/cfm-order-x-status.converter";
import { useGetInfiniteOrdersXQuery } from "../../repositories/queries/order-x/queries/get-orders-x-infinite.query";

export interface IOrderXOverviewContextType {
  orders: ICfmOrderX[];
  curFilter: ICfmOrderXOverviewFilter;
  setFilter: (filter: ICfmOrderXOverviewFilter) => void;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<ICfmOrderXList>>;
  displayExportOrderButton: () => boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  pageSize: number | undefined;
  setPageSize: (pageSize: number | undefined) => void;
  selectedOrderId: number | undefined;
  setSelectedOrderId: (selectedOrderId: number | undefined) => void;
  resetFilter: VoidFunction;
}

interface IOrderXOverviewProviderProps {
  orderIdForPreSelect?: number;
  children: any;
}

const OrderXOverviewContext = createContext<IOrderXOverviewContextType>({} as IOrderXOverviewContextType);

export const OrderXOverviewProvider = (props: IOrderXOverviewProviderProps) => {
  const value = useOrderXOverviewProvider(props);
  return <OrderXOverviewContext.Provider value={value}>{props.children}</OrderXOverviewContext.Provider>;
};

export const useOrderXOverviewContext = () => {
  return useContext(OrderXOverviewContext);
};

export interface ICfmOrderXOverviewFilter {
  statusFilter: CfmOrderXStatusFilter;
  filter?: ICfmOrderXFilter;
}

export const ORDER_OVERVIEW_DEFAULT_PAGE_SIZE = 30;

const getInitialState = (orderId?: number) => {
  const initialFilterState: ICfmOrderXOverviewFilter = {
    statusFilter: "all",
    filter: {
      status: "all",
      // if user used email deeplink, set orderId in url and also in search field so that only this one order is displayed
      orderId: orderId,
      query: orderId ? orderId.toString() : undefined,
    },
  };

  return initialFilterState;
};

const useOrderXOverviewProvider = (props: IOrderXOverviewProviderProps): IOrderXOverviewContextType => {
  const { orderIdForPreSelect } = props;
  const { internalUser } = useAuthContext();
  const history = useHistory();

  const [filter, setFilter] = useState<ICfmOrderXOverviewFilter>(getInitialState(orderIdForPreSelect));
  const [pageSize, setPageSize] = useState<number | undefined>(ORDER_OVERVIEW_DEFAULT_PAGE_SIZE);
  const [selectedOrderId, setSelectedOrderId] = useState<number | undefined>(orderIdForPreSelect);

  const isOrderQueryEnabled = useMemo(() => {
    const status = filter.statusFilter;
    const orderFilter = filter.filter;
    // do not enable for status truckload logistic announced as truckloads are no normal orders but a bulk list of orders
    if (status === CfmOrderXApiStatus.LogisticTruckLoadAnnounced) return false;
    // do not enable for status recycler incoming warehouse, recycler outgoing warehouse as products are displayed, not orders
    if (
      status === CfmOrderXApiStatus.RecyclerIncomingWarehouse ||
      status === CfmOrderXApiStatus.RecyclerOutgoingWarehouse
    ) {
      return false;
    }
    // do not enable for status destination arrived as transfer orders are displayed
    if (status === CfmOrderXApiStatus.DestinationArrived) return false;
    // enabled by default except for truckload
    if (status !== CfmOrderXApiStatus.LogisticWarehouse) return true;
    // only enable for truckload if address has been selected
    return orderFilter?.logisticAddressId !== undefined || orderFilter?.destinationAddressId !== undefined;
  }, [filter.statusFilter, filter.filter]);

  const { data, isLoading, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useGetInfiniteOrdersXQuery(
    {
      ...filter.filter,
      status: CfmOrderXStatusConverter.statusWithAllToModel(filter.filter?.status ?? filter.statusFilter),
    },
    pageSize,
    isOrderQueryEnabled,
  );

  const orders = useMemo(() => {
    const pages = data?.pages ?? [];
    return _.flatMap(pages, (page) => page.items);
  }, [data?.pages]);

  const resetFilter = (): void => {
    setPageSize(ORDER_OVERVIEW_DEFAULT_PAGE_SIZE);
    setFilter(getInitialState());
  };

  const displayExportOrderButton = (): boolean => {
    if (!internalUser || (!isAdmin(internalUser) && !isCfmLogistics(internalUser))) {
      return false;
    }

    return filter.statusFilter === CfmOrderXApiStatus.Ordered;
  };

  useEffect(() => {
    const routerState: IRouterState = {
      scrollToTop: false,
    };
    history.replace({
      pathname: `${AvailableCfmRoutes.OrderXDetail.replace(
        ":orderId",
        selectedOrderId ? selectedOrderId.toString() : "",
      )}`,
      state: routerState,
    });
  }, [selectedOrderId, history]);

  return {
    orders,
    curFilter: filter,
    setFilter,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage: hasNextPage ?? false,
    selectedOrderId,
    pageSize,
    setPageSize,
    setSelectedOrderId,
    resetFilter,
    displayExportOrderButton,
  };
};
