import { useQuery } from "react-query";
import { replaceUriParameter, RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { CfmTransferOrderStatus } from "../../../../domain/transfer-order/cfm-transfer-order-status";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmTransferOrderNotifiedWeightModel } from "../../../models/transfer-order/cfm-transfer-order-notified-weight.model";

const getTransferOrdersNotifiedWeight = async (
  warehouseProductId: number,
  startAddressId: number,
  status: CfmTransferOrderStatus,
): Promise<ICfmTransferOrderNotifiedWeightModel> => {
  const url = replaceUriParameter(RestEndpoints.TransferOrderNotifiedWeight, "warehouseProductId", warehouseProductId);
  const { data } = await getInstance().get<ICfmTransferOrderNotifiedWeightModel>(url, {
    params: {
      warehouseProductId,
      startAddressId,
      status,
    },
  });

  return data;
};

export const useGetTransferOrdersNotifiedWeightQuery = (
  warehouseProductId: number,
  startAddressId: number,
  status: CfmTransferOrderStatus,
  enabled: boolean = true,
) => {
  return useQuery(
    [CfmQueryKeys.GetTransferOrdersNotifiedWeight, warehouseProductId, startAddressId, status],
    () => getTransferOrdersNotifiedWeight(warehouseProductId, startAddressId, status),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled,
    },
  );
};
