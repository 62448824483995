import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { ManufacturerConverter } from "../../../domain/converter/manufacturer/manufacturer.converter";
import { IPomManufacturerModel } from "../../models/manufacturer/manufacturer.model";
import { ICreatePomManufacturerRequest } from "../../requests/manufacturer/manufacturer-create.request";
import { PomQueryKeys } from "../../pom-query-keys";
import { IPomManufacturer } from "../../../domain/models/manufacturer/manufacturer";

type QueryErrorHandler =
  | ((
      error: unknown,
      variables: {
        manufacturerInput: ICreatePomManufacturerRequest;
        ignoreDuplicateName: boolean;
      },
      context: unknown,
    ) => void | Promise<unknown>)
  | undefined;
type QuerySuccessHandler =
  | ((
      data: IPomManufacturer,
      variables: {
        manufacturerInput: ICreatePomManufacturerRequest;
        ignoreDuplicateName: boolean;
      },
      context: unknown,
    ) => void | Promise<unknown>)
  | undefined;

const createManufacturer = async (
  manufacturerInput: ICreatePomManufacturerRequest,
  ignoreDuplicateName: boolean,
): Promise<IPomManufacturer> => {
  const { data } = await getInstance().post<IPomManufacturerModel>(RestEndpoints.PomManufacturer, manufacturerInput, {
    params: { ignoreDuplicateName },
  });

  return ManufacturerConverter.toDomain(data);
};

export const useCreateManufacturerQuery = (handleError: QueryErrorHandler, handleSuccess: QuerySuccessHandler) => {
  return useMutation(
    [PomQueryKeys.CreateManufacturer],
    ({
      manufacturerInput,
      ignoreDuplicateName,
    }: {
      manufacturerInput: ICreatePomManufacturerRequest;
      ignoreDuplicateName: boolean;
    }) => createManufacturer(manufacturerInput, ignoreDuplicateName),
    {
      onError: handleError,
      onSuccess: handleSuccess,
    },
  );
};
