import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { IPasswordResetData } from "../../../../../utils/PasswordHelper";
import getInstance from "../../../../../utils/rest";
import { IResetPasswordResultModel } from "../../../../models/user/user.model";
import { SharedQueryKeys } from "../../shared-query-keys";
import { IResetPasswordResponse } from "../../../../domain/user/user";

const resetPassword = async (
  passwordData: IPasswordResetData,
  oldPasswordRequired: boolean,
): Promise<IResetPasswordResponse> => {
  const { data } = await getInstance().put<IResetPasswordResultModel>(RestEndpoints.ResetPassword, {
    normalPassword: passwordData.normalPassword,
    oldPassword: passwordData.oldPassword,
    oldPasswordRequired,
  });

  const result = {
    message: data.message,
    status: data.status,
  };
  return result;
};

export const useResetPasswordQuery = () => {
  return useMutation(
    SharedQueryKeys.ResetPassword,
    ({ data, oldPasswordRequired }: { data: IPasswordResetData; oldPasswordRequired: boolean }) =>
      resetPassword(data, oldPasswordRequired),
  );
};
