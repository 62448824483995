import { Cancel, CheckCircle } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAdvancePasswordFieldStyle } from "../../style/advancePasswordField.style";
import { IAnalysisResult } from "../../utils/passwordAnalyser";

interface ICreateStrongPasswordHelperProps {
  analysis: IAnalysisResult;
}

export const CreateStrongPasswordHelper = (props: ICreateStrongPasswordHelperProps) => {
  const { analysis } = props;
  const { t } = useTranslation();
  const classes = useAdvancePasswordFieldStyle();

  return (
    <div className={classes.popup}>
      <ul className={classes.list}>
        <li>
          {analysis.hasUpperCase ? (
            <CheckCircle className={classes.icon} color="primary" />
          ) : (
            <Cancel className={classes.icon} color="error" />
          )}
          {t("password.min.upper")}
        </li>
        <li>
          {analysis.hasLowerCase ? (
            <CheckCircle className={classes.icon} color="primary" />
          ) : (
            <Cancel className={classes.icon} color="error" />
          )}
          {t("password.min.lower")}
        </li>
        <li>
          {analysis.hasNumber ? (
            <CheckCircle className={classes.icon} color="primary" />
          ) : (
            <Cancel className={classes.icon} color="error" />
          )}
          {t("password.min.number")}
        </li>
        <li>
          {analysis.hasSymbol ? (
            <CheckCircle className={classes.icon} color="primary" />
          ) : (
            <Cancel className={classes.icon} color="error" />
          )}
          {t("password.min.symbol")}
        </li>
        <li>
          {analysis.isLength ? (
            <CheckCircle className={classes.icon} color="primary" />
          ) : (
            <Cancel className={classes.icon} color="error" />
          )}
          {t("password.min.length")}
        </li>
      </ul>
    </div>
  );
};
