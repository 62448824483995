import { useQuery } from "react-query";
import { PaginationMetaConverter } from "shared/domain/converter/pagination-meta.converter";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroup } from "../../../domain/group/group";
import { IPaginationMeta } from "../../../domain/pagination/pagination-meta";
import { IGetGroupsParams, IGroupListModel } from "../../models/group/get-group.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getGroupsWithFilters = async (
  params: IGetGroupsParams,
): Promise<{ groups: IGroup[]; paginationMeta: IPaginationMeta | undefined }> => {
  const { filter, pagination } = params;
  const { active, groupTypes, searchQuery, contractTypes } = filter;
  const { page, pageSize } = pagination ?? {};
  const { data } = await getInstance().get<IGroupListModel>(RestEndpoints.Groups, {
    params: { active, groupTypes, contractTypes, searchQuery, page, pageSize: pageSize ?? 50 },
  });
  return {
    groups: data.items.map(GroupConverter.toDomain),
    paginationMeta: data.meta ? PaginationMetaConverter.toDomain(data.meta) : undefined,
  };
};

export const useGetGroupsWithFiltersQuery = (params: IGetGroupsParams, enabled: boolean = true) => {
  return useQuery([SharedQueryKeys.GetGroupsWithFilters, params], () => getGroupsWithFilters(params), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
