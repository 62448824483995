import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useUploadPostalsForCreateAssignments } from "../../../../collect-from-market/repositories/queries/routing-assignment/upload-postals-for-create-assignments.query";
import { getFormattedPostal } from "../../../../components/assignment-routing/assignment-products.util";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { CustomDropzone } from "../../../../shared/components/dropzone/dropzone";
import { TEXT_SEPARATOR } from "../../../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  boldText: {
    fontWeight: "bold",
  },
}));

interface IUploadPostalsDialogProps {
  open: boolean;
  onCancel: VoidFunction;
  onAccept: (result: string[]) => void;
}

export const UploadPostalsDialog: VFC<IUploadPostalsDialogProps> = (props) => {
  const { open, onCancel, onAccept } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [result, setResult] = useState<string[] | undefined>();

  const { isLoading, mutateAsync: extractPostals } = useUploadPostalsForCreateAssignments();

  const onFileSelected = async (file: File | undefined): Promise<void> => {
    if (!file) {
      return;
    }
    const postals = await extractPostals({ file });
    setResult(postals);
  };

  const handleAccept = () => {
    if (!result) return;
    const localResult = result;
    setResult(undefined);
    onAccept(localResult);
  };

  const handleCancel = () => {
    setResult(undefined);
    onCancel();
  };

  return (
    <AppDialog
      open={open}
      onCancelClick={handleCancel}
      onAcceptClick={handleAccept}
      title={t("configuration.assignments.create.uploadPostal")}
      acceptTextOverride={t("configuration.assignments.create.confirmPostals")}
      cancelTextOverride={t("general.cancel.text")}
      isLoading={isLoading}
      maxWidth="sm"
    >
      <Grid container direction="column">
        {!result && (
          <Grid item>
            <CustomDropzone
              handleFilesChanged={(files: File[]) => {
                const file = files?.length ? files[0] : undefined;
                onFileSelected(file);
              }}
              maxFiles={1}
            />
          </Grid>
        )}
        {result && (
          <>
            <Grid item>
              <Typography variant="body1" className={classes.boldText}>
                {t("configuration.assignments.create.extractedPostals")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {result.map((postal) => getFormattedPostal(postal)).join(`${TEXT_SEPARATOR} `)}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </AppDialog>
  );
};
