import { ButtonBaseClassKey, StyleRules } from "@material-ui/core";
import { PrimaryColors, Shades } from "style/Colors";

export const MuiButtonBase: Partial<StyleRules<ButtonBaseClassKey, {}>> = {
  root: {
    "&.MuiPaginationItem-root": {
      backgroundColor: PrimaryColors.base,
      color: Shades.white,

      "&:hover": {
        backgroundColor: PrimaryColors.hover,
      },

      "&.Mui-selected": {
        backgroundColor: PrimaryColors.active,
        color: Shades.white,

        "&:hover": {
          backgroundColor: PrimaryColors.active,
        },
      },
    },
  },
};
