import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderXBulkUpdateListModel } from "../../../models/order-x/edit/cfm-order-x-bulk-update.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const bulkUpdateOrderXQuery = async (orderUpdates: ICfmOrderXBulkUpdateListModel): Promise<void> => {
  await getInstance().patch<void>(RestEndpoints.CfmOrdersX, orderUpdates);
  return undefined;
};

export const useBulkUpdateOrderXQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation(
    [CfmQueryKeys.BulkUpdateOrderX],
    (orderUpdates: ICfmOrderXBulkUpdateListModel) => bulkUpdateOrderXQuery(orderUpdates),
    {
      onError: (error) => {
        const errorMsg = getErrorCode(error);
        const errorCode = getErrorStatusCode(error);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar(t("notifications.order_saved_success.message"), {
          variant: "success",
        });
        void queryClient.invalidateQueries(CfmQueryKeys.GetOrdersXWithFilterInfinite);
        void queryClient.invalidateQueries(CfmQueryKeys.GetOrdersXWithFilter);
        void queryClient.invalidateQueries(CfmQueryKeys.GetOrderXStatusCount);
        void queryClient.invalidateQueries(CfmQueryKeys.GetOrderXBulkActions);
        void queryClient.invalidateQueries(CfmQueryKeys.GetOrderXDeliveryReport);
      },
    },
  );
};
