import { Grid, LinearProgress } from "@material-ui/core";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { DefaultValues, FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Language } from "shared/domain/user/language";
import useAsyncEffect from "use-async-effect";
import isEmail from "validator/lib/isEmail";
import { AutofillCatcher } from "../../../../components/AutofillHelper/autofill-catcher";
import { usePasswordAnalyser } from "../../../../hooks/usePasswordAnalyser";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { getTitles } from "../../../../utils/constants";
import { passwordAnalyser } from "../../../../utils/passwordAnalyser";
import { IAddressLean } from "../../../domain/address/address-lean";
import { IGroup } from "../../../domain/group/group";
import { RolePom } from "../../../domain/user/role-pom";
import { useCustomForm } from "../../../util/form.util";
import { ALL_ADDRESSES_OPTION } from "../../address/address-form/address-form.util";
import { AppDialogButtonRow } from "../../dialog/app-dialog-button-row.component";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormCheckbox } from "../../form/fields/form-checkbox.field";
import { FormPasswordField } from "../../form/fields/form-password.field";
import { FormPhoneCountryField } from "../../form/fields/form-phone-country.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormHeader } from "../../form/form-header.component";
import { CreateUserCfmSection } from "./create-user-cfm-section.component";
import { useCreateUserFormContext } from "./create-user-form.provider";
import { CreateUserPomCfmSection } from "./create-user-pom-section.component";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { UserTypeLevel } from "../../../domain/user/user-type-level";
import { AvailablePlatform, usePlatformContext } from "../../../../providers/Auth/platform.provider";

export interface IMutateUserFormInputs {
  group: IGroup;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  deliveryEmail: string;
  telephone: string;
  language: Language;
  externalId: string;
  userTypeLevel?: UserTypeLevel;
  rolePom?: RolePom;
  password: string;
  passwordConfirm: string;
  userAddresses: IAddressLean[];
  notify: boolean;
  active: boolean;
}

interface ICreateUserFormProps {
  userAddresses: IAddressLean[];
}

// With this form every user can create a new user with his access rights
export const CreateUserForm: React.FC<ICreateUserFormProps> = ({ userAddresses }) => {
  const { groups, isLoading, handleFormSubmit, groupAddresses, isCfmCustomerWasteProducer, isPomMainContact, isAdmin } =
    useCreateUserFormContext();
  const history = useHistory();
  const { activePlatform } = usePlatformContext();
  const { internalUser } = useAuthContext();

  const defaultValues: DefaultValues<IMutateUserFormInputs> = {
    language: Language.de,
    notify: true,
    active: true,
    // If default value is not null, reset would not work :(
    // @ts-ignore
    title: null,
    // @ts-ignore
    group: internalUser?.group,
    // @ts-ignore
    rolePom: null,
    // @ts-ignore
    userTypeLevel: isCfmCustomerWasteProducer ? UserTypeLevel.WasteProducer : null,
    // @ts-ignore
    telephone: null,
    userAddresses: userAddresses,
  };

  const formMethods = useCustomForm<IMutateUserFormInputs>({ defaultValues });
  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
  } = formMethods;

  const { t } = useTranslation();
  const { analyser, analysis } = usePasswordAnalyser();
  const { setHasUnsavedData } = useUnsavedDataContext();

  // @ts-ignore - fix circular dependency
  const password = watch("password");
  const group = watch("group");
  const [userTypeSelectSelectOptions, setUserTypeSelectSelectOptions] = useState<UserTypeLevel[]>([
    UserTypeLevel.Corporate,
  ]);

  const groupHasSelectableAddresses = useCallback(() => {
    return groupAddresses.some((g) => g.id !== ALL_ADDRESSES_OPTION);
  }, [groupAddresses]);

  const setCfmUserTypeLevelSelectOptions = useCallback(() => {
    const options = [UserTypeLevel.Corporate];

    if (groupHasSelectableAddresses()) {
      options.push(UserTypeLevel.WasteProducer);
    }

    setUserTypeSelectSelectOptions(options);
  }, [groupHasSelectableAddresses]);

  useAsyncEffect(async () => {
    if (group?.id && group?.uuid) {
      resetField("userAddresses");
    }
  }, [group]);

  useEffect(() => {
    setHasUnsavedData(isDirty);
  }, [isDirty]);

  useEffect(() => {
    setCfmUserTypeLevelSelectOptions();
    if (!groupHasSelectableAddresses()) {
      setValue("userTypeLevel", UserTypeLevel.Corporate);
    } else {
      setValue("userTypeLevel", defaultValues?.userTypeLevel);
    }
  }, [setValue, groupHasSelectableAddresses, setCfmUserTypeLevelSelectOptions, defaultValues?.userTypeLevel]);

  const onSubmit: SubmitHandler<IMutateUserFormInputs> = async (inputs) => {
    setHasUnsavedData(false);
    const isSuccess = await handleFormSubmit(inputs);
    if (isSuccess) {
      reset();
      history.goBack();
    }
  };

  const handleSaveClick = (): void => {
    handleSubmit(onSubmit)();
  };

  const getGroupAutocomplete = (): ReactNode | undefined => {
    if (isAdmin) {
      return (
        <FormAutocomplete<IGroup>
          name={"group"}
          control={control}
          label={t("basedata.users.edit.groupName")}
          getOptionSelected={(option, value) => option.id === value.id}
          options={groups}
          error={Boolean(errors?.group)}
          rules={{ required: true }}
          getOptionLabel={(group) => group?.name}
        />
      );
    }
  };

  return (
    <form>
      <AutofillCatcher />
      <FormHeader>{t("general.user.data")}</FormHeader>
      {isLoading && <LinearProgress />}

      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {getGroupAutocomplete()}
          </Grid>

          <Grid item xs={12} md={6} />

          <Grid item xs={12} md={6} direction={"column"}>
            <FormAutocomplete<string>
              name={"title"}
              control={control}
              required={true}
              label={t("basedata.users.edit.title")}
              options={getTitles(t)}
              error={Boolean(errors?.title)}
              helperText={errors?.title?.message}
              rules={{ required: true }}
              getOptionLabel={(title) => title}
            />
          </Grid>
          <Grid item xs={12} md={6} />

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.firstName)}
              label={t("basedata.users.edit.firstName")}
              control={control}
              name={"firstName"}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.lastName)}
              label={t("basedata.users.edit.lastName")}
              control={control}
              name={"lastName"}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.email)}
              errorMessage={errors?.email?.message}
              label={t("basedata.users.edit.loginEmail")}
              inputProps={{ autoComplete: "username", id: "email" }}
              type="email"
              control={control}
              name={"email"}
              rules={{
                required: true,
                validate: async (value) => {
                  if (!value || !isEmail(value)) {
                    return t("general.email.required.error");
                  }
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.deliveryEmail)}
              errorMessage={errors?.deliveryEmail?.message}
              label={t("basedata.users.edit.deliveryEmail")}
              type="email"
              control={control}
              name={"deliveryEmail"}
              rules={{
                required: true,
                validate: async (value) => {
                  if (!value || !isEmail(value)) {
                    return t("general.email.required.error");
                  }
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormPhoneCountryField
              name="telephone"
              label={t("basedata.users.edit.phone")}
              control={control}
              hasError={Boolean(errors?.telephone)}
              required={true}
            />
          </Grid>

          <Grid item xs={12} md={6} />

          <FormProvider {...formMethods}>
            <Grid item xs={12}>
              {activePlatform === AvailablePlatform.Cfm && (
                <CreateUserCfmSection
                  userTypeLevelSelectOptions={userTypeSelectSelectOptions}
                  isCfmCustomerWasteProducer={isCfmCustomerWasteProducer}
                  groupHasSelectableAddresses={groupHasSelectableAddresses()}
                  groupAddresses={groupAddresses}
                />
              )}

              {activePlatform === AvailablePlatform.Pom && (
                <CreateUserPomCfmSection isPomMainContact={isPomMainContact} />
              )}
            </Grid>
          </FormProvider>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormPasswordField
              hasError={Boolean(errors?.password)}
              label={t("basedata.users.edit.password.text")}
              analysis={analysis}
              {...register("password", {
                required: true,
                onChange: () => trigger("password"),
                validate: async (value) => {
                  if (!value) {
                    return false;
                  }
                  await analyser(value);
                  return passwordAnalyser(value).isValid;
                },
              })}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.passwordConfirm)}
              errorMessage={errors?.passwordConfirm?.message}
              type={"password"}
              label={t("basedata.users.edit.password.reenter")}
              control={control}
              name={"passwordConfirm"}
              rules={{
                required: true,
                onChange: () => trigger("passwordConfirm"),
                validate: (value) => {
                  const isValid = value && value === password && passwordAnalyser(value).isValid;
                  if (!isValid) {
                    return t("basedata.users.edit.password.error");
                  }
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormCheckbox name={"notify"} control={control} label={t("create.user.notify_on_create")} />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormCheckbox name={"active"} control={control} label={t("basedata.users.edit.active")} />
          </Grid>
        </Grid>
      )}

      <AppDialogButtonRow
        isLoading={isLoading}
        onAcceptClick={handleSaveClick}
        onCancelClick={history.goBack}
        type="button"
        alignButtons="space-between"
        acceptTextOverride={t("general.save.text")}
      />
    </form>
  );
};
