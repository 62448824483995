import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { Colors } from "../../style/Colors";

const titleMinHeight = 55;
const useStyles = makeStyles((theme: Theme) => ({
  lightboxContainer: {
    position: "fixed",
    top: "0",
    left: "0",
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0,0,0,0.7)",
    cursor: "pointer",
    zIndex: 1000,
    animation: "$fadeIn 0.3s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    minHeight: titleMinHeight,
    backgroundColor: "rgba(0,0,0,0.4)",
    position: "absolute",
    top: 0,
    width: "100%",
  },
  titleContainer: {
    minHeight: titleMinHeight,
    paddingLeft: 20,
  },
  titleText: {
    color: Colors.white,
  },
}));

interface ILightboxProps {
  title: string;
  src: string;
  onClose: VoidFunction;
}

export const Lightbox: VFC<ILightboxProps> = (props) => {
  const { onClose, src, title } = props;
  const classes = useStyles();

  return (
    <div onClick={onClose} className={classes.lightboxContainer}>
      <Grid container direction="column" justifyContent="center" style={{ height: "100%" }}>
        <Grid item className={classes.headerContainer}>
          <Box display="flex" alignItems={"center"} className={classes.titleContainer}>
            <Typography variant="h5" className={classes.titleText}>
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.imageContainer}>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <img src={src} alt={title} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
