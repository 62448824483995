export const Colors = {
  greenLight: "#65b22e",
  green: "#379e31",
  greenDark: "#006148",
  greenDark70: "rgba(0,97,72,0.7)",
  accentGreenLight: "#96c33c",
  accentGreenDark: "#008A32",
  gray: "#79969e",
  grayLight: "#eff5f7",
  grayDark: "#95999a",
  yellow: "#efd500",
  orange: "#EF7E3C",
  red: "#C8364C",
  purple: "#797EB0",
  blue: "#0DB4E6",
  turquoise: "#3fb498",
  white: "#fff",
  disabled: "rgba(0, 0, 0, 0.38)",
} as const;

type ColorKey = keyof typeof Colors;
export type ColorValue = typeof Colors[ColorKey];

export const PrimaryColors = {
  base: Colors.greenLight,
  hover: Colors.green,
  active: Colors.greenDark,
};

export const WarningColors = {
  base: "#C8364C",
  hover: "#AC2E41",
  active: "#952637",
};

export const Shades = {
  black: "#000",
  gray90: "#304b5380",
  gray80: "#47636b",
  gray70: "#607c84",
  gray60: "#79969e",
  gray50: "#93b0b9",
  gray40: "#bed6dd",
  gray30: "#cee0e6",
  gray20: "#dfebee",
  gray10: "#eff5f7",
  grayLight: "#f2f1ef",
  white: "#fff",
};
