import { Grid, makeStyles } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackButton,
  CancelButton,
  EditButton,
  ForwardButton,
  SaveButton,
  TransferDataButton,
} from "../../../components/Primitives";
import { ConfirmDialog } from "../../../shared/components/dialog/confirm-dialog.component";
import { useBattGRegisterContext } from "../../pages/batt-g/registration/batt-g-register.provider";

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: "40px",
    "& Button:not(:last-child)": {
      marginRight: "10px",
    },
  },
});

export interface IBattGFormButtonRowComponentProps {
  onCancelButtonClicked: () => void;
  onSaveButtonClicked?: () => void;
  onNextButtonClicked?: () => void;
  onBackButtonClicked?: () => void;
  onSubmitWithFeeButtonClicked?: () => void;
  hasErrors?: () => Promise<boolean>;
  isForBrands?: boolean;
}

export const BattGFormButtonRowComponent: FC<IBattGFormButtonRowComponentProps> = ({
  onCancelButtonClicked,
  onNextButtonClicked,
  onSaveButtonClicked,
  onBackButtonClicked,
  onSubmitWithFeeButtonClicked,
  hasErrors,
  isForBrands = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
  const [submitWithFeeDialogOpen, setSubmitWithFeeDialogOpen] = useState<boolean>(false);

  const { isReadOnly, setIsReadOnly } = useBattGRegisterContext();

  const handleCancel = (): void => setCancelDialogOpen(true);

  const handleDialogCancel = async (): Promise<void> => setCancelDialogOpen(false);

  const handleSubmitWithFee = async (): Promise<void> => {
    if (hasErrors && (await hasErrors())) return;
    setSubmitWithFeeDialogOpen(true);
  };

  const handleSubmitWithFeeDialogCancel = async (): Promise<void> => setSubmitWithFeeDialogOpen(false);

  const CancelDialog = () => {
    return (
      <ConfirmDialog
        open={cancelDialogOpen}
        onCancel={handleDialogCancel}
        onConfirm={async () => {
          handleDialogCancel();
          onCancelButtonClicked();
        }}
      />
    );
  };

  const SubmitWithFeeDialog = () => {
    if (!onSubmitWithFeeButtonClicked) {
      return <></>;
    }

    return (
      <ConfirmDialog
        open={submitWithFeeDialogOpen}
        onCancel={handleSubmitWithFeeDialogCancel}
        onConfirm={async () => {
          onSubmitWithFeeButtonClicked();
          setSubmitWithFeeDialogOpen(false);
        }}
        displayStyle={"success"}
        title={t("batt_g.register.dialog.submit_title")}
        message={t("batt_g.register.dialog.submit_message")}
        actionText={t("batt_g.register.dialog.submit_action")}
      />
    );
  };

  const areButtonsDisabled = isReadOnly && !isForBrands;

  return (
    <Grid container justifyContent={"center"} className={classes.buttonContainer}>
      <CancelDialog />
      <SubmitWithFeeDialog />
      <CancelButton disabled={areButtonsDisabled} onClick={handleCancel}>
        {t("general.cancel.text")}
      </CancelButton>
      {onBackButtonClicked && <BackButton onClick={onBackButtonClicked}>{t("general.back.text")}</BackButton>}
      {onNextButtonClicked && <ForwardButton onClick={onNextButtonClicked}>{t("general.next.text")}</ForwardButton>}
      {areButtonsDisabled && (
        <EditButton
          onClick={() => {
            setIsReadOnly(!isReadOnly);
          }}
        >
          {t("general.edit.text")}
        </EditButton>
      )}
      {onSaveButtonClicked && (
        <SaveButton disabled={areButtonsDisabled} onClick={onSaveButtonClicked}>
          {t("general.save.text")}
        </SaveButton>
      )}
      {onSubmitWithFeeButtonClicked && (
        <TransferDataButton disabled={areButtonsDisabled} onClick={handleSubmitWithFee}>
          {t("batt_g.register.submit_with_fee_button")}
        </TransferDataButton>
      )}
    </Grid>
  );
};
