import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupHistoryConverter } from "../../../domain/converter/group-history.converter";
import { IGroupHistoryResult } from "../../../domain/group/group-history";
import { IGroupHistoryResultModel } from "../../../models/group/group-history.model";
import { SharedQueryKeys } from "../shared-query-keys";
import { IPaginationInputMeta } from "./../../../domain/pagination/pagination-meta";

const getGroupHistory = async (
  id: number,
  searchText: string | undefined,
  pagination: IPaginationInputMeta,
): Promise<IGroupHistoryResult> => {
  const url = replaceUriParameter(RestEndpoints.GroupHistory, "id", id);
  const response = await getInstance().get<IGroupHistoryResultModel>(url, {
    params: { ...pagination, query: searchText },
  });
  return GroupHistoryConverter.toListDomain(response.data);
};

export const useGetGroupHistoryQuery = (
  id: number,
  searchText: string | undefined,
  pagination: IPaginationInputMeta,
) => {
  return useQuery(
    [SharedQueryKeys.GetGroupHistory, id, searchText, pagination],
    () => getGroupHistory(id, searchText, pagination),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: Number.isFinite(id),
    },
  );
};
