import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { BattGBrandConverter } from "../../../../shared/domain/converter/batt-g/batt-g-brand.converter";
import { BattGBrandStatusFilter, IBattGBrandList } from "../../../../shared/models/batt-g/batt-g-brand";
import { IBattGBrandListModel } from "../../../../shared/repositories/models/batt-g/batt-g-brand.model";
import getInstance from "../../../../utils/rest";
import { PomQueryKeys } from "../../pom-query-keys";

const getBrands = async (
  page: number,
  pageSize: number,
  filters: BattGBrandStatusFilter[],
): Promise<IBattGBrandList> => {
  const { data } = await getInstance().get<IBattGBrandListModel>(RestEndpoints.BattGBrands, {
    params: {
      page: page,
      pageSize: pageSize,
      status: filters,
    },
  });
  return BattGBrandConverter.listToDomain(data);
};

export const useGetBrandsQuery = (page: number, pageSize: number, filters: BattGBrandStatusFilter[]) => {
  return useQuery([PomQueryKeys.GetBrands, page, pageSize, filters], () => getBrands(page, pageSize, filters), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
