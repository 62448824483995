import { Grid } from "@material-ui/core";
import _ from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { UserTableProvider } from "../../../providers/Users/user-table.provider";
import { UsersProvider } from "../../../providers/Users/users-context";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { BaseDataPageHeader } from "../../components/basedata-page-header/basedata-page-header.component";
import {
  CFM_GROUP_TYPE_FILTER_VALUES,
  POM_GROUP_TYPE_FILTER_VALUES,
} from "../../components/group/group-filter/group-type-filter.component";
import { UsersTable } from "../../components/user/user-table/user-table.component";

export const UserListPage: FC = () => {
  const { t } = useTranslation();

  return (
    <UsersProvider groupTypes={_.uniq([...CFM_GROUP_TYPE_FILTER_VALUES, ...POM_GROUP_TYPE_FILTER_VALUES])}>
      <UserTableProvider>
        <PageTitle id={AvailableSharedRoutes.UserList} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <BaseDataPageHeader title={t("basedata.users.heading")} />
          </Grid>
          <Grid item xs={12} md={12}>
            <UsersTable />
          </Grid>
        </Grid>
      </UserTableProvider>
    </UsersProvider>
  );
};
