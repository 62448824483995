import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from "@material-ui/core";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Badge } from "../../../configurator/components/badge/badge.component";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";
import { useCart } from "../../../providers/Cart/cart-context";
import { Colors, PrimaryColors } from "../../../style/Colors";
import {
  AvailableCfmRoutes,
  AvailableConfiguratorRoutes,
  AvailablePomRoutes,
  AvailableSharedRoutes,
} from "../../../utils/constants";
import { IShortcut, ShortcutType } from "../../../utils/shortcuts";
import { isAdmin, isPomAgency, isPomMainContact } from "../../domain/user/user";
import { DashboardAction, useDashboardActionContext } from "../../pages/dashboard/dashboard-action.provider";
import { ShortcutTypesWithCount, useDashboardCountContext } from "../../pages/dashboard/dashboard-count.provider";
import { NewOrderToolTip } from "../tooltips/order-new-tooltip.component";
import { PlatformContainerTitle } from "./platform-container-title.component";

const defaultBorder = "1px solid #ffffff";

const useStyles = makeStyles((theme: Theme) => ({
  platformContainer: {
    background: Colors.grayLight,
    cursor: "pointer",
    padding: 20,
    minHeight: 253,
    borderRadius: 4,
    border: defaultBorder,
    display: "flex",
  },
  cardContentText: {
    minHeight: 95,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 32,
    },
  },
  cardContentImage: {
    minHeight: 95,
    alignSelf: "center",
  },
  shortcutContainer: {
    padding: 0,
  },
  listContainerGrid: {
    overflow: "hidden",
    borderRadius: 4,
    borderBottom: defaultBorder,
    [theme.breakpoints.down("sm")]: {
      minHeight: 63,
    },
  },
  listContainer: {
    padding: 0,
    margin: 0,
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      minHeight: 63,
    },
  },
  listItem: {
    height: 63,
    borderRadius: 4,
    background: Colors.grayLight,
    border: defaultBorder,
    "&:nth-child(4)": {
      borderTop: defaultBorder,
      borderLeft: defaultBorder,
      borderRight: defaultBorder,
      borderBottom: "none",
    },
  },
  listItemText: {
    "& .MuiTypography-root": {
      fontSize: 16,
    },
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
  },
}));

interface IPlatformContainerProps {
  title: string;
  Logo: FunctionComponent;
  platform: AvailablePlatform;
  shortcuts: IShortcut[];
  subTitle?: string;
}

export const PlatformContainer: React.VFC<IPlatformContainerProps> = (props) => {
  const classes = useStyles();
  const { title, Logo, platform, shortcuts, subTitle } = props;
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const history = useHistory();
  const { setActivePlatform } = usePlatformContext();
  const { cartCountOrderX } = useCart();
  const { withoutPackageCount, withoutResponseCount, withoutRoutingCount } = useDashboardCountContext();
  const { setAction, setIsOpenAction, contractId } = useDashboardActionContext();

  const handleListItemClick = (shortcut: IShortcut) => {
    setActivePlatform(platform);
    if (shortcut.action) {
      if (shortcut.action === DashboardAction.SELECT_TAKE_BACK_SYSTEM) {
        if (contractId !== undefined) {
          history.push(`${AvailablePomRoutes.Announcement}`);
        }
      }

      setAction(shortcut.action);
      setIsOpenAction(true);
      return;
    }

    const route =
      shortcut.name === ShortcutType.GroupOverview
        ? shortcut.route.replace(":id", internalUser?.group?.id?.toString() ?? "")
        : shortcut.route;

    history.push(route);
  };

  const allowedShortcuts = useMemo(() => {
    const allowedShortcuts = shortcuts.filter((shortcut) => {
      const allowedRole = shortcut.mandatoryPomMainContact;
      // Filter allowedRole first
      if (allowedRole && !isPomMainContact(internalUser)) {
        return false;
      }

      return shortcut.display;
    });
    return allowedShortcuts;
  }, [internalUser, shortcuts]);

  const handlePlatformSelection = (): void => {
    setActivePlatform(platform);
    if (platform === AvailablePlatform.Pom) {
      if (isAdmin(internalUser) || isPomAgency(internalUser)) {
        history.push(AvailableSharedRoutes.GroupList);
      }

      setAction(DashboardAction.SELECT_TAKE_BACK_SYSTEM);
      setIsOpenAction(true);
      return;
    } else if (platform === AvailablePlatform.Config) {
      history.push(AvailableConfiguratorRoutes.ProductOverview);
    } else {
      history.push(AvailableCfmRoutes.OrderXOverview);
    }
  };

  const getCountValue = (shortcutType: ShortcutType): number => {
    switch (shortcutType) {
      case ShortcutType.Cart:
        return cartCountOrderX;
      case ShortcutType.PackageConfig:
        return withoutPackageCount ?? 0;
      case ShortcutType.RoutingConfig ?? 0:
        return withoutRoutingCount ?? 0;
      case ShortcutType.ResponseConfig ?? 0:
        return withoutResponseCount ?? 0;
      default:
        return 0;
    }
  };

  const getCountTooltip = (shortcutType: ShortcutType): string => {
    return t(`dashboard.statistic.${shortcutType}`, { count: getCountValue(shortcutType) });
  };

  return (
    <Grid container id="platform-container">
      <Grid item xs={12}>
        <PlatformContainerTitle
          handleSelection={handlePlatformSelection}
          Logo={Logo}
          title={title}
          subTitle={subTitle}
        />
      </Grid>
      {allowedShortcuts.length > 0 && (
        <Grid item xs={12} id="platform-shortcuts" className={classes.listContainerGrid}>
          <List className={classes.listContainer}>
            {allowedShortcuts.map((shortcut: IShortcut, index: number) => {
              const ShortcutIcon = shortcut.icon;
              return (
                <ListItem
                  key={index}
                  dense
                  button
                  onClick={() => handleListItemClick(shortcut)}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <ShortcutIcon svgColor="green" className={classes.iconStyle} viewBox="0 0 25 25" />
                  </ListItemIcon>
                  <ListItemText primary={t(shortcut.translation)} className={classes.listItemText} />
                  {ShortcutTypesWithCount.includes(shortcut.name) && (
                    <ListItemIcon>
                      <NewOrderToolTip title={getCountTooltip(shortcut.name)}>
                        <Box>
                          <Badge
                            count={getCountValue(shortcut.name)}
                            textColor={Colors.white}
                            backgroundColor={shortcut.name === ShortcutType.Cart ? Colors.orange : Colors.red}
                          />
                        </Box>
                      </NewOrderToolTip>
                    </ListItemIcon>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}
    </Grid>
  );
};
