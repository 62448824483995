import { CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useEffect, useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import { useDebouncedCallback } from "use-debounce";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import { ICfmProductCategory } from "../../../../collect-from-market/domain/products/cfm-product-category";
import { ICfmProductFraction } from "../../../../collect-from-market/domain/products/cfm-product-fraction";
import { ICfmProductState } from "../../../../collect-from-market/domain/products/cfm-product-state";
import { ICfmProductType } from "../../../../collect-from-market/domain/products/cfm-product-type";
import { CfmQueryKeys } from "../../../../collect-from-market/repositories/queries/cfm-query-keys";
import { useCreateNewCfmProductsQuery } from "../../../../collect-from-market/repositories/queries/product/mutation/create-new-products.query";
import { useGetAllProductsQuery } from "../../../../collect-from-market/repositories/queries/product/query/get-products.query";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { ForwardButton } from "../../../../components/Primitives";
import { AvailablePlatform } from "../../../../providers/Auth/platform.provider";
import { PRODUCT_ROW_HEIGHT } from "../../../../style/product-row.style";
import { AvailableConfiguratorRoutes } from "../../../../utils/constants";
import { ConfigurationCreatedDialog } from "../../dialog/configuration-created-dialog.component";
import {
  CfmProductArticleType,
  EMPTY_PRODUCT_CONFIG_FILTER,
  generateProductData,
  getArticleNumber,
  IProductConfigFilter,
} from "../product.utils";
import { CreateProductRow } from "./create-product-row.component";

const listHeight = 600;
const maxProductRows = 200;

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
  bottomRow: {
    marginTop: 20,
  },
  checkboxAlignment: {
    marginLeft: 16,
  },
}));

export interface IProductData {
  platform: AvailablePlatform.Cfm | AvailablePlatform.Pom;
  articleType: CfmProductArticleType;
  category: ICfmProductCategory;
  fraction: ICfmProductFraction | undefined;
  state: ICfmProductState;
  type: ICfmProductType;
  exists: boolean;
  selected: boolean;
  name?: string;
}

interface ICreateProductConfiguration {
  createProductFilter: IProductConfigFilter;
  setCreateProductFilter: (data: IProductConfigFilter) => void;
}

export const CreatableProductsList: VFC<ICreateProductConfiguration> = (props) => {
  const { createProductFilter, setCreateProductFilter } = props;
  const classes = useStyles();
  const [products, setProducts] = useState<IProductData[]>([]);
  const [isSubmitSuccessDialogOpen, setIsSubmitSuccessDialogOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<IProductData[]>([]);
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isGenerationLoading, setIsGenerationLoading] = useState(false);

  const { isLoading: allProductsLoading, data: allExistingProducts } = useGetAllProductsQuery();
  const { isLoading: isMutationLoading, mutateAsync: createNewCfmProducts } = useCreateNewCfmProductsQuery();

  const calculatedListHeight = useMemo(() => {
    const productsHeight = products.length * PRODUCT_ROW_HEIGHT;
    return productsHeight > listHeight ? listHeight : productsHeight;
  }, [products.length]);

  const isLoading = useMemo(() => {
    return allProductsLoading || isMutationLoading || isGenerationLoading;
  }, [allProductsLoading, isMutationLoading, isGenerationLoading]);

  const isSingleOrder = useMemo(() => {
    return createProductFilter.articleType === CfmProductArticleType.SingleOrderProduct;
  }, [createProductFilter]);

  const displayProductList = useMemo(() => {
    return (
      createProductFilter.platform &&
      createProductFilter.articleType &&
      createProductFilter.categories &&
      createProductFilter.categories.length > 0 &&
      createProductFilter.types &&
      createProductFilter.types.length > 0 &&
      (!isSingleOrder ? createProductFilter.fractions && createProductFilter.fractions.length > 0 : true) &&
      createProductFilter.states &&
      createProductFilter.states.length > 0
    );
  }, [createProductFilter]);

  const handleGenerateProductData = useDebouncedCallback(async () => {
    const productData = generateProductData(createProductFilter);
    setProducts(productData);
    setSelectedProducts([]);
    // all products should be selected per default
    const selectableProducts = productData.filter((product) => !isExistingProduct(product));
    setSelectedProducts(selectableProducts);
    setIsGenerationLoading(false);
  }, 500);

  useEffect(() => {
    if (!displayProductList) return;
    setIsGenerationLoading(true);
    handleGenerateProductData();
  }, [displayProductList, handleGenerateProductData, createProductFilter]);

  const findProduct = (products: ICfmProduct[], productToRemove: IProductData) => {
    return products.find(
      (product) =>
        product.category?.id === productToRemove.category.id &&
        product.fraction?.id === productToRemove.fraction?.id &&
        product.state?.id === productToRemove.state.id &&
        product.type?.id === productToRemove.type.id,
    );
  };

  const handleSelect = (product: IProductData, checked: boolean) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      const filtered = getAllOtherProducts(product, selectedProducts);
      setSelectedProducts(filtered);
    }
  };

  const isSelected = (product: IProductData) => {
    return selectedProducts.some(
      (selectedProduct) =>
        selectedProduct.category.id === product.category.id &&
        selectedProduct.fraction?.id === product.fraction?.id &&
        selectedProduct.state.id === product.state.id &&
        selectedProduct.type.id === product.type.id,
    );
  };

  const isExistingProduct = (product: IProductData) => {
    return findProduct(allExistingProducts ?? [], product) !== undefined;
  };

  const onSelectCheckboxClick = (checked: boolean) => {
    if (checked) {
      const selectableProducts = products.filter((product) => !isExistingProduct(product));
      setSelectedProducts(selectableProducts);
    } else {
      setSelectedProducts([]);
    }
  };

  const areAllSelected = () => {
    const selectableProducts = products.filter((product) => !isExistingProduct(product));
    return selectableProducts.length > 0 && selectedProducts.length === selectableProducts.length;
  };

  const onOrderProductsClick = async () => {
    await createNewCfmProducts({ config: selectedProducts });
    setIsSubmitSuccessDialogOpen(true);
  };

  const getAllOtherProducts = (product: IProductData, products: IProductData[]) => {
    return products.filter(
      (other) =>
        other.category.id !== product.category.id ||
        other.fraction?.id !== product.fraction?.id ||
        other.state.id !== product.state.id ||
        other.type.id !== product.type.id,
    );
  };

  const getProduct = (product: IProductData): IProductData | undefined => {
    return products.find(
      (other) =>
        other.category.id === product.category.id &&
        other.fraction?.id === product.fraction?.id &&
        other.state.id === product.state.id &&
        other.type.id === product.type.id,
    );
  };

  const setProductDataName = (product: IProductData, name: string) => {
    const existingProduct = getProduct(product);
    if (!existingProduct) return;

    const updatedProduct = {
      ...existingProduct,
      name: name,
    };

    setProducts([...getAllOtherProducts(existingProduct, products), updatedProduct]);
    setSelectedProducts([...getAllOtherProducts(existingProduct, selectedProducts), updatedProduct]);
  };

  const getProductRow = ({ index, style }: ListChildComponentProps) => {
    const product = products[index];
    return (
      <div style={style}>
        <CreateProductRow
          productData={product}
          onCheckboxClick={handleSelect}
          isChecked={isSelected(product)}
          isExistingProduct={isExistingProduct(product)}
          setProductName={(name) => setProductDataName(product, name)}
          editEnabled={products.length < maxProductRows}
        />
      </div>
    );
  };

  if (!displayProductList) return null;

  return (
    <>
      <ConfigurationCreatedDialog
        open={isSubmitSuccessDialogOpen}
        title={t("configuration.successfullyCreated")}
        acceptText={t("basedata.general.yes.text")}
        cancelText={t("basedata.general.no.text")}
        contentText={t("configuration.redirectToPackageConfig")}
        onCancel={() => {
          setCreateProductFilter(EMPTY_PRODUCT_CONFIG_FILTER);
          queryClient.invalidateQueries(CfmQueryKeys.GetAllProducts);
          setIsSubmitSuccessDialogOpen(false);
        }}
        onAccept={() => history.push(AvailableConfiguratorRoutes.PackageOverview)}
        hasPackages={false}
        displayRoutingIcon={false}
        maxWidth="xs"
        hasRouting={false}
      />

      <Grid container direction="column" spacing={2}>
        {isLoading && (
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={12} className={classes.alignSpinner}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isGenerationLoading && products.length >= maxProductRows && (
          <Grid item>
            <List
              width={"inherit"}
              height={calculatedListHeight}
              itemCount={products.length}
              itemSize={PRODUCT_ROW_HEIGHT}
              style={{ overscrollBehavior: "contain" }}
            >
              {getProductRow}
            </List>
          </Grid>
        )}
        {!isGenerationLoading && products.length > 0 && products.length < maxProductRows && (
          <Grid item>
            {products
              .sort((a1, a2) => getArticleNumber(a1).localeCompare(getArticleNumber(a2)))
              .map((product, index) => {
                return (
                  <CreateProductRow
                    key={index}
                    productData={product}
                    onCheckboxClick={handleSelect}
                    isChecked={isSelected(product)}
                    isExistingProduct={isExistingProduct(product)}
                    editEnabled={products.length < maxProductRows}
                    setProductName={(name) => setProductDataName(product, name)}
                  />
                );
              })}
          </Grid>
        )}
        <Grid item className={classes.bottomRow} xs={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item className={classes.checkboxAlignment}>
              {products.filter((product) => !isExistingProduct(product)).length > 0 && (
                <OrderCheckbox
                  label={areAllSelected() ? t("general.deselectAll") : t("general.selectAll")}
                  onChange={onSelectCheckboxClick}
                  checked={areAllSelected()}
                  size="large"
                />
              )}
            </Grid>
            <Grid item>
              <ForwardButton disabled={selectedProducts.length === 0} onClick={onOrderProductsClick}>
                <Typography variant="body1">{t("configuration.createProducts")}</Typography>
              </ForwardButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
