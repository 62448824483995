export enum BattGRegistrationStatusModel {
  Draft = "draft",
  Published = "published",
}

export interface IBattGRegistrationModel {
  id: number;
  status: BattGRegistrationStatusModel;
  manufacturer: IBattGRegistrationManufacturerModel;
  mainContactPerson: IBattGRegistrationContactPersonModel;
  representativeContactPerson: IBattGRegistrationContactPersonModel;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBattGRegistrationManufacturerModel {
  name: string;
  address: string;
  buildingNumber: string;
  postal: string;
  city: string;
  state: string;
  country: string;
  webAddress: string | null;
  tradeRegisterNumber: string | null;
  startDate: Date;
  endDate: Date | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBattGRegistrationContactPersonModel {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  jobTitle: string | null;
  createdAt: Date;
  updatedAt: Date;
}
