import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmOrderXHistoryListModel, ICfmOrderXHistoryModel } from "../../../models/order-x/cfm-order-x-history.model";

const getOrderHistory = async (orderId: number): Promise<ICfmOrderXHistoryModel[]> => {
  const url = replaceUriParameter(RestEndpoints.CfmOrderXHistory, "id", orderId);
  const { data } = await getInstance().get<ICfmOrderXHistoryListModel>(url);

  return data.items;
};

export const useGetOrderXHistory = (orderId: number) => {
  return useQuery([CfmQueryKeys.GetOrderXHistory], () => getOrderHistory(orderId));
};
