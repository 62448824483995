import { Column, MTableBody, MTableBodyRow, Options } from "@material-table/core";
import { Box, makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../components/Tables/custom-table";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";
import { formatNumber } from "../../../utils/number.helper";
import {
  IPomAnnouncementTableData,
  sortPomAnnouncementTableData,
} from "../announcement-table/pom-announcement-table-data";
import { PomAnnouncementTableSumRow } from "../announcement-table/table/pom-announcement-table-sum-row.component";
import { IPomAnnouncementDialogType } from "./pom-announcement.dialog";

const useStyles = makeStyles({
  tableRow: {
    "& td": {
      borderBottom: `2px solid ${Colors.greenDark70}`,
    },
  },
  oldValue: {
    fontWeight: "normal",
    color: Shades.gray70,
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
});

interface IPomAnnouncementDialogTableProps {
  type: IPomAnnouncementDialogType;
  newItems?: IPomAnnouncementTableData[];
  existingItems?: IPomAnnouncementTableData[];
}

export const PomAnnouncementDialogTable: FC<IPomAnnouncementDialogTableProps> = (props) => {
  const { type, newItems = [], existingItems } = props;
  const classes = useStyles();
  const { internalUser } = useAuthContext();

  const tableOptions: Options<any> = {
    toolbar: false,
    paging: false,
    header: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "500px",
  };

  const cellStyleNumber: CSSProperties = {
    textAlign: "end",
    color: PrimaryColors.base,
    fontWeight: "bold",
    height: "80px",
  };

  const columns: Column<object>[] = [
    {
      field: "articleNumber",
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      field: "takeBackSystem",
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      field: "articleName",
      cellStyle: {
        textAlign: "start",
        whiteSpace: "nowrap",
      },
    },
    {
      field: "type",
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      field: "weightClass",
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      field: "chemistry",
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      field: "count",
      cellStyle: cellStyleNumber,
      render: (data) => {
        const id = (data as IPomAnnouncementTableData).id;
        const newCount = (data as IPomAnnouncementTableData).count;
        const oldCount = existingItems?.find((i) => i.id === id)?.count;

        return (
          <Box display="flex" flexDirection="column">
            <span className={classes.nowrap}>{formatNumber(newCount, 0)}</span>
            {type === "update" && oldCount !== undefined && <OldValueInfo value={formatNumber(oldCount, 0) ?? "-"} />}
          </Box>
        );
      },
    },
    {
      field: "totalWeight",
      cellStyle: cellStyleNumber,
      render: (data) => {
        const id = (data as IPomAnnouncementTableData).id;
        const newWeight = (data as IPomAnnouncementTableData).totalWeight;
        const oldWeight = existingItems?.find((i) => i.id === id)?.totalWeight;

        return (
          <Box display="flex" flexDirection="column">
            <span className={classes.nowrap}>{formatNumber(newWeight, 3)}</span>
            {type === "update" && oldWeight !== undefined && <OldValueInfo value={formatNumber(oldWeight, 3) ?? "-"} />}
          </Box>
        );
      },
    },
    {
      field: "unit",
      cellStyle: {
        textAlign: "start",
      },
    },
    {
      cellStyle: cellStyleNumber,
      render: (data: object) => {
        const id = (data as IPomAnnouncementTableData).id;
        const newPrice = (data as IPomAnnouncementTableData).totalPrice;
        const oldPrice = existingItems?.find((i) => i.id === id)?.totalPrice;
        if (internalUser?.group?.pomContracts?.[0]?.contractField?.defaultPriceGroup) {
          return (
            <Box display="flex" flexDirection="column">
              <span className={classes.nowrap}>{`${formatNumber(newPrice, 2)} €`}</span>
              {type === "update" && oldPrice !== undefined && (
                <OldValueInfo value={`${formatNumber(oldPrice, 2) ?? "-"} €`} />
              )}
            </Box>
          );
        }
      },
    },
  ];

  return (
    <CustomTable
      options={tableOptions}
      columns={columns}
      data={newItems.sort(sortPomAnnouncementTableData)}
      components={{
        Body: (props) => (
          <>
            <MTableBody {...props} />
            {type === "create" && <PomAnnouncementTableSumRow />}
          </>
        ),
        Row: (rowProps) => <MTableBodyRow {...rowProps} className={classes.tableRow} />,
      }}
    />
  );
};

const OldValueInfo: React.FC<{ value: string }> = ({ value }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <span className={[classes.oldValue, classes.nowrap].join(" ")}>
      {t("pom.announcements.dialog.old_value", { value: value })}
    </span>
  );
};
