import { useMediaQuery } from "@material-ui/core";
import React, { CSSProperties, FC, useEffect, useState } from "react";
import { useStepperStyle } from "../../style/stepper.style";

interface IStepperItemProps {
  isActive: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

export const StepperItem: FC<IStepperItemProps> = (props) => {
  const { children, onClick, isActive, style } = props;
  const { root, active, mobileRoot, hover } = useStepperStyle();
  const fullScreen = useMediaQuery("(min-width:1130px)");
  const [joinedClasses, setJoinedClasses] = useState<string>([root].join(" "));

  useEffect(() => {
    const classes = fullScreen ? [root] : [mobileRoot];

    if (isActive) {
      classes.push(active);
    }

    if (onClick) {
      classes.push(hover);
    }

    setJoinedClasses(classes.join(" "));
  }, [active, fullScreen, isActive, mobileRoot, root, hover, onClick]);

  return (
    <div style={style} onClick={onClick} className={joinedClasses}>
      {children}
    </div>
  );
};
