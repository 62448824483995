import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { formatDateYearMonth } from "../../../../shared/util/date.util";
import getInstance from "../../../../utils/rest";
import { IPomAnnouncementModel } from "../../models/announcements/pom-announcements.model";
import { PomQueryKeys } from "../../pom-query-keys";

export const getAnnouncement = async (date: Date, contractId: number): Promise<IPomAnnouncementModel> => {
  const { data } = await getInstance().get<IPomAnnouncementModel>(
    `${RestEndpoints.PomAnnouncements}/${formatDateYearMonth(date)}`,
    {
      params: {
        contractId,
      },
    },
  );

  return data;
};

export const useGetAnnouncementQuery = (date: Date, contractId: number) => {
  return useQuery([PomQueryKeys.GetAnnouncement, date, contractId], () => getAnnouncement(date, contractId), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
