import { IAddress } from "../shared/domain/address/address";
import { IAddressLean } from "../shared/domain/address/address-lean";
import { AddressType } from "../shared/models/address/address.model";
import { TEXT_SEPARATOR } from "./constants";
import { getCountryByCode } from "./Countries";
import { TranslateFunc } from "./translation.utils";

export const getFormattedDomainAddressForTitle = (
  { id, name, formattedAddress, address, city, postal, country }: Partial<IAddress>,
  countryTranslation: string,
): string => {
  const translatedCountry = country ? countryTranslation : "";
  if (formattedAddress) {
    return `(${id}) ${name} (${formattedAddress}${TEXT_SEPARATOR} ${translatedCountry})`;
  } else {
    return `(${id}) ${name} (${address} ${postal} ${city ?? ""}${TEXT_SEPARATOR} ${translatedCountry})`;
  }
};

export const getFormattedDomainAddress = (
  { id, name, formattedAddress, address, city, postal, country }: Partial<IAddress>,
  t: TranslateFunc,
): string => {
  const translatedCountry = country ? t(`country.${country.toLowerCase()}`) : "";
  if (formattedAddress) {
    return `(${id}) ${name} (${formattedAddress}, ${translatedCountry})`;
  } else {
    return `(${id}) ${name} (${address} ${postal} ${city ?? ""}, ${translatedCountry})`;
  }
};

export const getTranslatedAddressType = (type: AddressType | undefined, t: TranslateFunc) => {
  switch (type) {
    case AddressType.Invoice:
      return t("basedata.addresses.type.invoice");
    case AddressType.CustomerLocation:
      return t("basedata.addresses.type.customer_location");
    case AddressType.GroupLocation:
      return t("basedata.addresses.type.group_location");
    case AddressType.LogisticLocation:
      return t("basedata.addresses.type.logistic_location");
    case AddressType.RecyclerLocation:
      return t("basedata.addresses.type.recycling_location");
    case AddressType.SorterLocation:
      return t("basedata.addresses.type.sorter_location");
    case AddressType.ManufacturerLocation:
      return t("basedata.addresses.type.manufacturer_location");
    default:
      return t("basedata.addresses.table.addressType.cantResolve");
  }
};

export const getRoutingTranslatedAddressType = (type: AddressType | undefined, t: TranslateFunc) => {
  switch (type) {
    case AddressType.Invoice:
      return t("basedata.addresses.routinType.invoice");
    case AddressType.CustomerLocation:
      return t("basedata.addresses.routingType.customer_location");
    case AddressType.GroupLocation:
      return t("basedata.addresses.routingType.group_location");
    case AddressType.LogisticLocation:
      return t("basedata.addresses.routingType.logistic_location");
    case AddressType.SorterLocation:
      return t("basedata.addresses.routingType.sorter_location");
    case AddressType.RecyclerLocation:
      return t("basedata.addresses.routingType.recycling_location");
    case AddressType.ManufacturerLocation:
      return t("basedata.addresses.routingType.manufacturer_location");
    default:
      return t("basedata.addresses.table.addressType.cantResolve");
  }
};

export const translateCountryByCode = (countryCode: string, translate: TranslateFunc) => {
  const country = getCountryByCode(countryCode);
  if (!country) {
    return translate("basedata.addresses.table.noEntry");
  }
  return translate(country.translationKey);
};


const openingHoursMaxLength = 100;
export const getOpeningHoursTextForTooltip = (
  openingHours: string | undefined,
  t: TranslateFunc,
  maxLength?: number,
) => {
  const length = maxLength ?? openingHoursMaxLength;
  return openingHours && openingHours.length > length
    ? `${t("orders.new.wizard.wasteProducerAddress.openingHours")}: ${openingHours}`
    : "";
};

export const trimOpeningHoursTextIfRequired = (openingHours: string, maxLength?: number) => {
  const length = maxLength ?? openingHoursMaxLength;
  if (openingHours.length <= length) return openingHours;

  return `${openingHours.substring(0, length - 1)}...`;
};

export const getAddressBaseTextParts = (address: IAddressLean | IAddress, t: TranslateFunc): string[] => {
  const textParts: string[] = [];

  if (address.address && address.buildingNumber) {
    textParts.push(`${address.address} ${address.buildingNumber}`);
  }
  if (address.postal && address.city) {
    textParts.push(` ${address.postal} ${address.city}`);
  }
  if (address.country) {
    textParts.push(` ${translateCountryByCode(address.country, t)}`);
  }

  return textParts;
};

export const getFormattedRoutingAddress = (addressType: AddressType, address: IAddress, t: TranslateFunc): string => {
  const translatedCountry = address.country ? t(`country.${address.country.toLowerCase()}`) : "";
  return `${getRoutingTranslatedAddressType(addressType, t)}${TEXT_SEPARATOR} ${
    address.group?.erpNumber ?? "-"
  }${TEXT_SEPARATOR} (${address.id}) ${address.name} (${address.address} ${address.postal} ${
    address.city ?? ""
  }, ${translatedCountry})`;
};

export const getAddressIds = (addresses: Array<IAddress | IAddressLean> | undefined) => {
  if (!addresses) return [];
  return addresses.filter((address) => address.id).map((address) => address.id as number);
};

export const getNamePartsFromContactPersonName = (name: string | undefined | null) => {
  if (!name) return { firstName: undefined, lastName: undefined };
  // special unicode characters for umlauts
  // see https://stackoverflow.com/questions/22017723/regex-for-umlaut
  // Ä, ä -> \u00c4, \u00e4
  // Ö, ö -> \u00d6, \u00f6
  // Ü, ü -> \u00dc, \u00fc
  // ß    -> \u00df
  const re = new RegExp(/[A-z\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]+/, "g");
  const result = name.match(re);
  const resultLength = result?.length ?? 0;

  const firstName = result && resultLength > 0 ? result[0] : undefined;
  const lastName = result && resultLength > 1 ? result.slice(1).join("") : undefined;

  return { firstName, lastName };
};
