import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { AppDialog } from "../../shared/components/dialog/app-dialog.component";

interface IQuestionDialogProps {
  isProcessing?: boolean;
  isOpen: boolean;
  message?: string;
  title?: string;
  acceptText?: string;
  cancelText?: string;
  onAccept: () => Promise<void>;
  onClose: () => void;
  displayStyle?: "success" | "warning";
}

export const QuestionDialog: FC<IQuestionDialogProps> = (props) => {
  const { isProcessing, isOpen, message, title, acceptText, onAccept, onClose, displayStyle, cancelText } = props;

  return (
    <AppDialog
      open={isOpen}
      onCancelClick={onClose}
      onAcceptClick={onAccept}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title={title}
      isLoading={isProcessing}
      displayStyle={displayStyle}
      acceptTextOverride={acceptText}
      cancelTextOverride={cancelText}
      maxWidth="sm"
    >
      <Typography variant={"body1"}>{message}</Typography>
    </AppDialog>
  );
};
