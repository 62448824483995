import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import { useSnackbar } from "notistack";
import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { translateAppError } from "../../../../utils/translation.utils";
import { AppError } from "../../../../utils/AppError";
import { GroupMutationConverter } from "../../../domain/converter/group-mutation.converter";
import { IGroup } from "../../../domain/group/group";
import { useCreateGroupQuery } from "../../../repositories/queries/group/create-group.query";
import { ICreateGroupFormInputs } from "./create-group-form.component";

interface ICreateGroupFormContext {
  isLoading: boolean;
  handleFormSubmit: (inputs: ICreateGroupFormInputs) => Promise<ISubmitResult>;
}

interface ISubmitResult {
  isSuccess: boolean;
  group?: IGroup | undefined;
  contactPersonEmailExists: boolean | undefined;
}

export const CreateGroupFormContext = createContext<ICreateGroupFormContext>({} as ICreateGroupFormContext);

export const useCreateGroupFormContext = () => {
  return useContext(CreateGroupFormContext);
};

const useCreateGroupFormProvider = (): ICreateGroupFormContext => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: createGroup } = useCreateGroupQuery();

  const handleFormSubmit = async (inputs: ICreateGroupFormInputs): Promise<ISubmitResult> => {
    setIsLoading(true);
    try {
      const request = GroupMutationConverter.toFormCreateRequest(inputs);
      const newGroupResult = await createGroup({ createData: request });

      return {
        isSuccess: true,
        group: newGroupResult.group,
        contactPersonEmailExists: newGroupResult.contactPersonEmailExists,
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status === StatusCodes.CONFLICT) {
        enqueueSnackbar(t("basedata.group.error.erp_number_unavailable"), { variant: "error" });
      } else {
        enqueueSnackbar(translateAppError(error as AppError, t), { variant: "error" });
      }
      return { isSuccess: false, group: undefined, contactPersonEmailExists: undefined };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading: isLoading,
    handleFormSubmit,
  };
};

export const CreateGroupFormProvider: React.FC = (props) => {
  const value = useCreateGroupFormProvider();
  return <CreateGroupFormContext.Provider value={value}>{props.children}</CreateGroupFormContext.Provider>;
};
