import { Countries, getDefaultCountry } from "../../../../utils/Countries";
import { IBattGManufacturerBaseDataFormInputs } from "../../../components/batt-g/batt-g-manufacturer-base-data-form.component";
import { IBattGRegistrationManufacturer } from "../../../models/batt-g/batt-g-registration";
import { IBattGRegistrationManufacturerModel } from "../../../repositories/models/batt-g/batt-g-registration.model";
import { IBattGRegistrationManufacturerRequestData } from "../../../repositories/requests/batt-g/batt-g-registration.request";
import { formatDateYearMonthDay } from "../../../util/date.util";

export class BattGManufacturerConverter {
  public static toDomain(model: IBattGRegistrationManufacturerModel): IBattGRegistrationManufacturer {
    return {
      address: model.address,
      city: model.city,
      buildingNumber: model.buildingNumber,
      postal: model.postal,
      tradeRegisterNumber: model.tradeRegisterNumber,
      state: model.state,
      name: model.name,
      country: model.country,
      endDate: model.endDate,
      webAddress: model.webAddress,
      startDate: model.startDate,
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
    };
  }

  public static formInputToRequest(
    inputs: IBattGManufacturerBaseDataFormInputs,
  ): IBattGRegistrationManufacturerRequestData {
    return {
      address: inputs.address,
      city: inputs.city,
      buildingNumber: inputs.buildingNumber,
      postal: inputs.postal,
      tradeRegisterNumber: inputs.tradeRegisterNumber,
      state: inputs.state,
      webAddress: inputs.webAddress,
      name: inputs.groupName,
      country: inputs.country.code,
      endDate: inputs.endDate ? formatDateYearMonthDay(inputs.endDate) : null,
      startDate: formatDateYearMonthDay(inputs.startDate),
    };
  }

  public static toFormInput(domain: IBattGRegistrationManufacturer): IBattGManufacturerBaseDataFormInputs {
    return {
      webAddress: domain.webAddress ?? "",
      endDate: domain.endDate,
      postal: domain.postal,
      tradeRegisterNumber: domain.tradeRegisterNumber ?? "",
      state: domain.state,
      city: domain.city,
      buildingNumber: domain.buildingNumber,
      address: domain.address,
      country: Countries.find((c) => c.code === domain.country) ?? getDefaultCountry(),
      startDate: domain.startDate,
      groupName: domain.name,
    };
  }
}
