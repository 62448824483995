export const AvailableMenus = {
  None: "none",
  Order: "navigation.orders.label",
  BattData: "navigation.basedata.label",
  Logistic: "navigation.logistics.label",
  Recycling: "navigation.recycling.label",
  User: "user",
  Configurator: "navigation.configuration.configurator",
  BattGRegister: "navigation.pom.batt_g.register.text",
} as const;
type AvailableMenuKey = keyof typeof AvailableMenus;
export type AvailableMenuValue = typeof AvailableMenus[AvailableMenuKey];

export const isAvailableMenuValue = (x: any): x is AvailableMenuValue => Object.values(AvailableMenus).includes(x);
