import { VFC } from "react";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { isAdmin } from "../../../shared/domain/user/user";
import { IOrderNewWizardFormInputs, OrderNewWizard, WizardMode } from "./components/order-new-wizard.component";
import { OrderNewProvider, WizardStep } from "./order-new.provider";

export const OrderXNewPage: VFC = () => {
  const { internalUser } = useAuthContext();
  const getInitialValues = () => {
    const initValues: Partial<IOrderNewWizardFormInputs> = {};

    if (!isAdmin(internalUser)) {
      initValues.customerGroupId = internalUser?.group?.id;
    }

    return initValues;
  };

  return (
    <OrderNewProvider
      initialStep={isAdmin(internalUser) ? WizardStep.CustomerGroup : WizardStep.CustomerAddress}
      wizardMode={WizardMode.Create}
    >
      <OrderNewWizard initialValues={getInitialValues()} mode={WizardMode.Create} />
    </OrderNewProvider>
  );
};
