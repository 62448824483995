import React, { VFC } from "react";
import { useErpNumberFormatter } from "../../../hooks/use-erp-number-formatter";

interface IGroupErpNumberProps {
  erpNumber: string | undefined | null;
}

export const GroupErpNumber: VFC<IGroupErpNumberProps> = ({ erpNumber }) => {
  const { formattedErpNumber } = useErpNumberFormatter({ erpNumber });

  return <>{formattedErpNumber ?? ""}</>;
};
