import { IBattGContactFormInputs } from "../../../components/batt-g/batt-g-contact-form.component";
import { IBattGManufacturerBaseDataFormInputs } from "../../../components/batt-g/batt-g-manufacturer-base-data-form.component";
import { BattGRegistrationStatus, IBattGRegistration } from "../../../models/batt-g/batt-g-registration";
import {
  BattGRegistrationStatusModel,
  IBattGRegistrationModel,
} from "../../../repositories/models/batt-g/batt-g-registration.model";
import { IBattGRegistrationRequest } from "../../../repositories/requests/batt-g/batt-g-registration.request";
import { BattGContactPersonConverter } from "./batt-g-contact-person.converter";
import { BattGManufacturerConverter } from "./batt-g-manufacturer.converter";

export class BattGRegistrationConverter {
  public static toDomain(model: IBattGRegistrationModel): IBattGRegistration {
    return {
      id: model.id,
      status: this.statusToDomain(model.status),
      manufacturer: BattGManufacturerConverter.toDomain(model.manufacturer),
      mainContactPerson: BattGContactPersonConverter.toDomain(model.mainContactPerson),
      representativeContactPerson: BattGContactPersonConverter.toDomain(model.representativeContactPerson),
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
    };
  }

  public static registerFormInputsToRequest(
    manufacturer: IBattGManufacturerBaseDataFormInputs,
    mainContact: IBattGContactFormInputs,
    representative: IBattGContactFormInputs,
    status: BattGRegistrationStatusModel,
  ): IBattGRegistrationRequest {
    return {
      manufacturer: BattGManufacturerConverter.formInputToRequest(manufacturer),
      status: status,
      mainContactPerson: BattGContactPersonConverter.formInputToRequest(mainContact),
      representativeContactPerson: BattGContactPersonConverter.formInputToRequest(representative),
    };
  }

  public static toFormInputs(domain: IBattGRegistration): {
    manufacturer: IBattGManufacturerBaseDataFormInputs;
    mainContact: IBattGContactFormInputs;
    representative: IBattGContactFormInputs;
  } {
    return {
      manufacturer: BattGManufacturerConverter.toFormInput(domain.manufacturer),
      mainContact: BattGContactPersonConverter.toFormInput(domain.mainContactPerson),
      representative: BattGContactPersonConverter.toFormInput(domain.representativeContactPerson),
    };
  }

  public static statusToDomain(model: BattGRegistrationStatusModel): BattGRegistrationStatus {
    switch (model) {
      case BattGRegistrationStatusModel.Draft:
        return BattGRegistrationStatus.Draft;
      case BattGRegistrationStatusModel.Published:
        return BattGRegistrationStatus.Published;
    }
  }
}
