import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { CfmOrderXApiStatus } from "../../../collect-from-market/domain/order-x/cfm-order-x.status";
import {
  ResponseAssignmentRoleCustomerIcon,
  ResponseAssignmentRoleLogisticIcon,
  ResponseAssignmentRoleRecyclerIcon,
} from "../../../components/Primitives/Icons";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { IdBox } from "./id-box.component";

export const INDEX_BOX_HEIGHT_WIDTH = 27;
export const INDEX_BOX_MARGIN_RIGHT = 16;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  statusLabel: {
    fontWeight: "bold",
    marginTop: 2.5,
  },
  statusBox: {
    marginLeft: 16,
  },
  assignmentIndicatorBase: {
    width: INDEX_BOX_HEIGHT_WIDTH,
    height: INDEX_BOX_HEIGHT_WIDTH,
    borderRadius: 4,
    marginRight: INDEX_BOX_MARGIN_RIGHT,
  },
  activeAssignmentIndicator: {
    border: `2px solid ${PrimaryColors.base}`,
    backgroundColor: Colors.grayLight,
  },
  assignmentIndicator: {
    border: `2px solid ${PrimaryColors.base}`,
    backgroundColor: PrimaryColors.base,
  },
  indicatorText: {
    color: Colors.white,
    textAlign: "center",
    fontWeight: "bold",
  },
  activeIndicatorText: {
    color: PrimaryColors.base,
    textAlign: "center",
    fontWeight: "bold",
  },
  iconBase: {
    color: PrimaryColors.base,
  },
  roleIconCustomer: {
    height: 16,
    width: 16,
  },
  roleIconRecycler: {
    height: 16,
    width: 15.6,
  },
  roleIconLogistic: {
    height: 16,
    width: 23,
  },
}));

interface IProductResponseASsignmentRowTitleProps {
  index: number;
  title: string;
  status: CfmOrderXApiStatus;
  isActiveStatus: boolean;
}

export const ProductResponseAssignmentRowTitle: VFC<IProductResponseASsignmentRowTitleProps> = (props) => {
  const { index, title, status, isActiveStatus } = props;
  const classes = useStyles();

  const getRoleIcon = () => {
    switch (status) {
      // customer
      case CfmOrderXApiStatus.ShoppingCart:
        return (
          <ResponseAssignmentRoleCustomerIcon className={classNames(classes.iconBase, classes.roleIconCustomer)} />
        );
      // logistic
      case CfmOrderXApiStatus.Ordered:
      case CfmOrderXApiStatus.LogisticAccepted:
      case CfmOrderXApiStatus.LogisticAnnounced:
      case CfmOrderXApiStatus.LogisticPerformed:
      case CfmOrderXApiStatus.LogisticWarehouse:
      case CfmOrderXApiStatus.LogisticTruckLoadAnnounced:
        return (
          <ResponseAssignmentRoleLogisticIcon className={classNames(classes.iconBase, classes.roleIconLogistic)} />
        );
      // recycler
      case CfmOrderXApiStatus.RecyclerIncomingWarehouse:
      case CfmOrderXApiStatus.RecyclerOutgoingWarehouse:
      case CfmOrderXApiStatus.DestinationArrived:
      case CfmOrderXApiStatus.DestinationReceived:
        return (
          <ResponseAssignmentRoleRecyclerIcon className={classNames(classes.iconBase, classes.roleIconRecycler)} />
        );
    }
  };

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Box
            className={classNames(classes.assignmentIndicatorBase, {
              [classes.activeAssignmentIndicator]: isActiveStatus,
              [classes.assignmentIndicator]: !isActiveStatus,
            })}
          >
            <Typography
              variant="body1"
              className={classNames(classes.indicatorText, {
                [classes.activeIndicatorText]: isActiveStatus,
              })}
            >
              {index + 1}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Typography variant="body1" className={classes.statusLabel}>
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Box className={classes.statusBox}>
            <IdBox id={status} />
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Box className={classes.statusBox}>{getRoleIcon()}</Box>
        </Box>
      </Grid>
    </Grid>
  );
};
