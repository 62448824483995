import { Grid, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Cached } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../../components/Primitives";
import useAsyncEffect from "../../../../hooks/use-async-effect";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { IAddressLeanFormInput } from "../../../domain/address/address-lean-form-input";
import { GroupType } from "../../../domain/group/group-type";
import { isAdmin } from "../../../domain/user/user";
import { useGetGroupBranchesQuery } from "../../../repositories/queries/group/get-group-branches.query";
import { useGetNextErpNumber } from "../../../repositories/queries/group/get-next-erp-number.query";
import { getErpNumberOnly, getErpNumberPrefix } from "../../../util/erp-number.util";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { CreateFreshdeskTicketDialog } from "../../freshdesk/create-freshdesk-ticket-dialog.component";
import { InputLoadingSpinner } from "../../loading/input-loading-spinner.component";
import { Loading } from "../../loading/loading.component";
import { IGroupBaseDataTypeFormInputs } from "./group-form-group-type-section.component";

export interface IGroupBaseDataFormInputs {
  active: boolean;
  grsManagedEar?: boolean;
  name: string;
  erpNumber: string;
  invoiceAddress: IAddressLeanFormInput | undefined;
  groupAddress: IAddressLeanFormInput | undefined;
  vatId: string;
  tradeRegisterNumber: string | undefined;
  differentInvoiceAddress: boolean;
  groupBranchId: number | null;
}

interface IGroupFormBaseDataSection {
  initialGroupType: GroupType | undefined;
  type: "edit" | "create";
  groupId?: number | undefined;
}

export const GroupFormBaseDataSection: VFC<IGroupFormBaseDataSection> = (props) => {
  const { initialGroupType, type, groupId } = props;
  const {
    formState: { errors, dirtyFields },
    clearErrors,
    control,
    watch,
    setValue,
  } = useFormContext<IGroupBaseDataFormInputs>();

  const { watch: watchTypeForm } = useFormContext<IGroupBaseDataTypeFormInputs>();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading: isGetNextErpNumberLoading,
    data: nextGroupErpNumber,
    refetch: refetchNextErpNumber,
  } = useGetNextErpNumber(type === "create");
  const { data: groupBranches, isLoading } = useGetGroupBranchesQuery();
  const { internalUser } = useAuthContext();
  const [isFreshdeskDialogOpen, setIsFreshdeskDialogOpen] = useState<boolean>(false);

  const selectedType: GroupType | undefined = watchTypeForm("type");
  const erpNumber: string | undefined = watch("erpNumber");
  const groupName: string | undefined = watch("name");

  const branches = useMemo(() => {
    if (!groupBranches) return [];
    return groupBranches;
  }, [groupBranches]);

  const fetchAvailableErpNumberClick = async () => {
    const result = await fetchAvailableErpNumber();
    if (!result) return;
    setValue("erpNumber", result);
    clearErrors("erpNumber");
  };

  const fetchAvailableErpNumber = useCallback(async (): Promise<string | undefined> => {
    try {
      await refetchNextErpNumber();
      let erpNumberCustomPrefix = "";

      if (erpNumber) {
        const numberOnly = getErpNumberOnly(erpNumber);
        erpNumberCustomPrefix = erpNumber.replace(new RegExp(`${numberOnly}$`), "").toUpperCase();
      }

      if (!nextGroupErpNumber?.nextErpNumber) return undefined;

      return `${erpNumberCustomPrefix}${nextGroupErpNumber?.nextErpNumber}`;
    } catch (e) {
      enqueueSnackbar(t("basedata.group.error.get_next_erp_number"), { variant: "error" });
    }
  }, [enqueueSnackbar, erpNumber, t, nextGroupErpNumber?.nextErpNumber, refetchNextErpNumber]);

  useAsyncEffect(
    // initial value for erp number
    async (isActive) => {
      if (type === "edit") return;
      const result = await fetchAvailableErpNumber();
      if (!isActive() || !result || isGetNextErpNumberLoading) return;
      setValue("erpNumber", result);
    },
    [isGetNextErpNumberLoading, type],
  );

  useEffect(() => {
    // set prefix if type changed
    // Only prefill on create, never overwrite existing erp numbers
    if (type === "edit" || dirtyFields["erpNumber"]) return;
    const erpNumberOnly = erpNumber ? getErpNumberOnly(erpNumber) : "";
    const erpNumberAndText = `${getErpNumberPrefix([selectedType ?? initialGroupType])}${erpNumberOnly}`;
    setValue("erpNumber", erpNumberAndText);
  }, [dirtyFields, erpNumber, selectedType, initialGroupType, setValue, type]);

  const handleErpNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue("erpNumber", value.replaceAll(" ", ""));
  };

  const getRefreshIcon = (): ReactNode => {
    return (
      <Tooltip title={t("basedata.groups.refresh_erp_number")}>
        <IconButton onClick={fetchAvailableErpNumberClick}>
          <Cached />
        </IconButton>
      </Tooltip>
    );
  };

  const getUpdateIcon = (): ReactNode => {
    return (
      <Tooltip title={t("basedata.groups.edit_erp_number")}>
        <IconButton
          onClick={() => {
            setIsFreshdeskDialogOpen(true);
          }}
        >
          <EditIcon svgColor={"green"} />
        </IconButton>
      </Tooltip>
    );
  };

  const getErpFieldEndAdornment = (): ReactNode => {
    if (isGetNextErpNumberLoading) {
      return <InputLoadingSpinner />;
    }
    if (type === "edit" && isAdmin(internalUser)) {
      return getUpdateIcon();
    }

    if (type === "create") {
      return getRefreshIcon();
    }
  };

  return (
    <>
      <CreateFreshdeskTicketDialog
        handleClose={() => {
          setIsFreshdeskDialogOpen(false);
        }}
        isOpen={isFreshdeskDialogOpen}
        groupName={groupName}
        currentFieldValue={`${t("freshdesk.createTicketDialog.currentErpNumber")}: ${erpNumber}`}
        newFieldLabel={t("freshdesk.createTicketDialog.newErpNumber")}
        subject={t("freshdesk.createTicketDialog.titleErpNumber")}
        groupId={groupId}
      />
      <Loading isLoading={isLoading}>
        <Grid container spacing={4} className="mt-2">
          <FormField md={12}>
            <FormTextField
              hasError={Boolean(errors?.name)}
              label={t("basedata.groups.edit.name")}
              required={true}
              maxLength={50}
              control={control}
              name={"name"}
              rules={{
                required: true,
              }}
            />
          </FormField>

          <FormField>
            <FormTextField
              hasError={Boolean(errors?.erpNumber)}
              maxLength={20}
              label={t("basedata.groups.edit.erpNumber")}
              required={true}
              control={control}
              name={"erpNumber"}
              rules={{
                required: true,
                validate: () => !errors?.erpNumber,
                onChange: handleErpNumberChange,
              }}
              disabled={type === "edit" || isGetNextErpNumberLoading}
              inputProps={{ endAdornment: getErpFieldEndAdornment() }}
            />
          </FormField>

          <FormField>
            <FormTextField
              hasError={Boolean(errors?.vatId)}
              label={t("basedata.groups.edit.vatId")}
              maxLength={30}
              control={control}
              name={"vatId"}
              rules={{
                required: true,
              }}
            />
          </FormField>

          <FormField>
            <FormTextField
              hasError={Boolean(errors?.tradeRegisterNumber)}
              label={t("basedata.groups.edit.tradeRegisterNumber")}
              required={false}
              control={control}
              name={"tradeRegisterNumber"}
            />
          </FormField>

          <FormField>
            <FormAutocomplete<number>
              control={control}
              label={t("basedata.groups.edit.groupBranch")}
              options={branches.map((branch) => branch.id)}
              error={Boolean(errors?.groupBranchId)}
              required={true}
              getOptionLabel={(branchId) => {
                const branch = branches.find((branch) => branch.id === branchId);
                if (!branch) return undefined;
                return branch.name ?? undefined;
              }}
              name={"groupBranchId"}
              rules={{ required: true }}
            />
          </FormField>
        </Grid>
      </Loading>
    </>
  );
};
