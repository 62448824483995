import { useCallback, useMemo } from "react";
import { useForm, UseFormRegister, UseFormReturn } from "react-hook-form";
import { UseFormProps } from "react-hook-form/dist/types";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

const validateInputNotWhitespace = <T>(value: T): true | string =>
  typeof value === "string" ? value.trim().length > 0 || "input cannot be whitespace" : true;

/* this hook wraps the return value of react-hook-form's useForm to
consider inputs that only contain whitespaces as empty, in the case where
the input validation is set to "required".
https://github.com/react-hook-form/react-hook-form/issues/1650
 */
const useFormCustomWrapper = <T extends FieldValues>(formMethods: UseFormReturn<T>): UseFormReturn<T> => {
  const { register } = formMethods;
  const wrappedRegister: UseFormRegister<T> = useCallback(
    (name, options) => {
      // we only need to inject the whitespace validation if options.required = true
      if (!options || !options.required) return register(name, options);

      // inject whitespace validation into the rules
      const originalValidationRules =
        typeof options.validate === "function" ? { default: options.validate } : options.validate;

      const wrappedOptions: RegisterOptions = {
        ...options,
        setValueAs: (value) => {
          if (typeof value === "string") {
            return value.trim();
          }
          return value;
        },
        validate: {
          ...originalValidationRules,
          whitespaces: validateInputNotWhitespace,
        },
      };
      return register(name, wrappedOptions);
    },
    [register],
  );

  // register is inside control too, we need to add our wrapped version there in
  // order to make sure Controller and useController work correctly. Avoid to create
  // a new object every time by using useMemo
  const control = useMemo(
    () => ({
      ...formMethods.control,
      register: wrappedRegister,
    }),
    [formMethods.control, wrappedRegister],
  );

  return {
    ...formMethods,
    register: wrappedRegister,
    control,
  };
};

export const useCustomForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> => {
  const methods = useForm<TFieldValues, TContext>({
    ...props,
  });
  return useFormCustomWrapper(methods);
};
