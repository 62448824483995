import { Badge, Grid, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MailIconButton, PrintButton } from "../../../../../components/Primitives";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { isAdmin, isCfm, isCfmLogistics } from "../../../../../shared/domain/user/user";
import { downloadPdf } from "../../../../../shared/util/download.util";
import { CfmOrderXApiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { useGetOrderXDeliveryReport } from "../../../../repositories/queries/order-x/queries/get-order-x-delivery-report.query";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderXActions } from "../actions/order-x-actions.component";
import { OrderXFreshDeskForm } from "../order-freshdesk-form/order-x-freshdesk-form.component";
import { Colors } from "../../../../../style/Colors";
import { useGetOrderXTickets } from "../../../../repositories/queries/order-x/queries/get-order-x-tickets.query";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    [theme.breakpoints.up("xs")]: {
      "&>div>div:last-child": {
        paddingRight: 8,
      },
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 8,
    },
  },
  buttonContainerBottomRow: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 8,
    },
  },
  mobileButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  badgeContact: {
    backgroundColor: Colors.red,
    color: Colors.white,
    marginRight: 15,
  },
}));

export const OrderXCardActions: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { internalUser } = useAuthContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOrderFreshdeskFormOpen, setIsOrderFreshdeskFormOpen] = useState(false);
  const { data: tickets, isLoading } = useGetOrderXTickets(order.id);

  const displayFreshdeskButton = useMemo(() => {
    return !!internalUser && isCfm(internalUser);
  }, [internalUser]);

  const displayOrderReportButton = useMemo(() => {
    if (!internalUser || (!isAdmin(internalUser) && !isCfmLogistics(internalUser))) {
      return false;
    }

    return (
      order.status === CfmOrderXApiStatus.LogisticAccepted || order.status === CfmOrderXApiStatus.LogisticAnnounced
    );
  }, [internalUser, order]);

  const { data: pdf, mutateAsync: getDeliveryReport } = useGetOrderXDeliveryReport([order.id]);

  const onDownloadPdf = (): void => {
    getDeliveryReport();
  };

  useEffect(() => {
    if (!pdf) return;
    downloadPdf(pdf, "Lieferschein.pdf");
  }, [pdf]);

  return (
    <Grid container justifyContent="flex-start" spacing={1}>
      <Grid item className={classNames(classes.mobileButton)} xs={12} sm="auto">
        <OrderXActions />
      </Grid>
      <Grid item xs={12} sm="auto">
        <Grid container direction={"row"} justifyContent={"space-between"} spacing={2}>
          {displayFreshdeskButton && (
            <Grid item xs={12} sm="auto" className={classes.mobileButton}>
              <MailIconButton
                className={classes.mobileButton}
                variant="outlined"
                onClick={() => setIsOrderFreshdeskFormOpen(true)}
              >
                <Typography>
                  {!isLoading && tickets && tickets.length > 0 && (
                    <Badge className={classes.badgeContact} badgeContent={tickets.length} showZero={false} />
                  )}

                  {t("general.contact_grs")}
                </Typography>
              </MailIconButton>
            </Grid>
          )}
          {displayOrderReportButton && (
            <Grid item xs={12} sm="auto" className={classes.mobileButton}>
              <PrintButton className={classes.mobileButton} variant="outlined" onClick={onDownloadPdf}>
                <Typography>{t("order.print_delivery_note.button")}</Typography>
              </PrintButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <OrderXFreshDeskForm isOpen={isOrderFreshdeskFormOpen} setIsOpen={setIsOrderFreshdeskFormOpen} />
    </Grid>
  );
};
