import { Box, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";
import { getButtonForAction } from "./order-x-action.util";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

interface IOrderActionButtonProps {
  title: string;
  actionType: CfmOrderActionType;
  handleButtonClick: VoidFunction;
}

export const OrderActionButton: React.FC<IOrderActionButtonProps> = (props) => {
  const { title, actionType, handleButtonClick } = props;
  const classes = useStyles();

  return (
    <Box className={classNames(classes.container)}>{getButtonForAction(title, actionType, handleButtonClick)}</Box>
  );
};
