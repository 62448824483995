import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { getFormattedPostal } from "../../../components/assignment-routing/assignment-products.util";
import { EditIconNoBorderButton } from "../../../components/Primitives/Buttons";
import { Colors, Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    border: "2px solid",
    borderColor: Shades.gray40,
    borderRadius: "0px 5px 5px 5px",
    padding: "15px 20px 15px 20px",
  },
  boxHeader: {
    backgroundColor: Shades.gray50,
    width: "fit-content",
    borderRadius: "5px 5px 0px 0px",
    padding: "7px 15px 3px 15px",
  },
  boxHeadingText: {
    color: Colors.white,
  },
  container: {
    height: "100%",
  },
}));

interface IProductRoutingPostalSummaryProps {
  postals: string[];
  onEditClick: VoidFunction;
  canEdit?: boolean;
}

export const ProductRoutingPostalSummary: VFC<IProductRoutingPostalSummaryProps> = (props) => {
  const { postals, onEditClick, canEdit = true } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="column">
          <Grid item className={classes.boxHeader}>
            <Typography variant="body1" className={classes.boxHeadingText}>
              {t("configuration.postalOrRegion")}
            </Typography>
          </Grid>
          <Grid item className={classes.box}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.container}>
                  <Typography variant="body1">
                    {postals.map((postal) => getFormattedPostal(postal)).join(", ")}
                  </Typography>
                </Box>
              </Grid>
              {canEdit && (
                <Grid item>
                  <Box display="flex" justifyContent="center" alignItems="center" className={classes.container}>
                    <EditIconNoBorderButton onClick={onEditClick} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
