import { useQuery } from "react-query";
import { RestEndpoints } from "../../../api/endpoints";
import { ReactQueryConfig } from "../../../config/react-query-config";
import getInstance from "../../../utils/rest";
import { IPomProductTypeModel } from "../models/products/pom-product-type.model";
import { PomQueryKeys } from "../pom-query-keys";

const getProductTypes = async (): Promise<IPomProductTypeModel[]> => {
  const { data } = await getInstance().get<IPomProductTypeModel[]>(RestEndpoints.PomProductTypes);
  return data;
};

export const useGetPomProductTypesQuery = () => {
  return useQuery([PomQueryKeys.GetProductTypes], () => getProductTypes(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
