import { VFC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAutocomplete } from "../../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "../../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { AddressType } from "../../../../../../shared/models/address/address.model";
import { getAddressIds, getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { useGetAddressesForAccepting } from "../../../../../repositories/queries/address/get-addresses-for-accepting.query";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";
import { Loading } from "../../../../../../shared/components/loading/loading.component";
import { useOrderXSelectedContext } from "../../../order-x-selected.provider";

export const OrderXLogisticAnnouncedSingleOrderAction: VFC = () => {
  const {
    formState: { errors },
    control,
    register,
    getValues,
    setValue,
  } = useFormContext<ICfmResponseValueFormInputs>();
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();

  const { data: logisticsAddressesForAccepting, isLoading: areLogisticsAddressesLoading } = useGetAddressesForAccepting(
    true,
    [AddressType.LogisticLocation],
  );

  useEffect(() => {
    if (order.logisticAddress && !getValues("logisticAnnounced.logisticAddressId")) {
      setValue("logisticAnnounced.logisticAddressId", order.logisticAddress.id);
    }
  }, [order.logisticAddress, getValues, setValue]);

  return (
    <Loading isLoading={areLogisticsAddressesLoading}>
      <FormField md={12} className={"pb-4"}>
        <FormAutocomplete<number>
          name={"logisticAnnounced.logisticAddressId"}
          control={control}
          label={t("basedata.addresses.type.logistic_location")}
          options={getAddressIds(logisticsAddressesForAccepting ?? [])}
          getOptionLabel={(value) => {
            const address = (logisticsAddressesForAccepting ?? []).find((address) => address.id === value);
            if (address) {
              return getFormattedDomainAddress(address, t);
            }
          }}
          error={Boolean(errors?.logisticAnnounced?.logisticAddressId)}
          rules={{ required: true }}
        />
      </FormField>
      <FormField md={12} className={"pb-4"}>
        <FormDatepickerField
          label={t(`orders.accept_form.planned_pickup_date.label`)}
          hasError={Boolean(errors?.logisticAnnounced?.plannedPickupDate)}
          control={control}
          required={true}
          {...register(`logisticAnnounced.plannedPickupDate`, {
            required: true,
            valueAsDate: true,
          })}
        />
      </FormField>
    </Loading>
  );
};
