import { replaceUriParameter } from "api/endpoints";
import { useQuery, useQueryClient } from "react-query";
import { PaginationMetaConverter } from "shared/domain/converter/pagination-meta.converter";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupContractConverter } from "../../../domain/converter/group-contract.converter";
import { ICfmGroupContract, IPomGroupContract } from "../../../domain/group/group-contract";
import { IPaginationInputMeta, IPaginationMeta } from "../../../domain/pagination/pagination-meta";
import { IGroupContractListModel } from "../../../models/group/group-contract.model";
import { SharedQueryKeys } from "../shared-query-keys";

export interface IGroupContractsResponse {
  cfmContracts: ICfmGroupContract[];
  pomContracts: IPomGroupContract[];
  meta: IPaginationMeta;
}

const getGroupContracts = async (
  groupId: number,
  paginationMeta: IPaginationInputMeta,
): Promise<IGroupContractsResponse> => {
  const url = replaceUriParameter(RestEndpoints.ContractsForGroup, "id", groupId);
  const response = await getInstance().get<IGroupContractListModel>(url, {
    params: {
      page: paginationMeta.page,
      pageSize: paginationMeta.pageSize,
    },
  });
  const { cfmContracts, pomContracts, meta } = response.data;
  return {
    cfmContracts: cfmContracts.map(GroupContractConverter.cfmContractToDomain),
    pomContracts: pomContracts.map(GroupContractConverter.pomContractToDomain),
    meta: PaginationMetaConverter.toDomain(meta),
  };
};

export const useGetGroupContracts = (
  groupId: number,
  paginationInputMeta: IPaginationInputMeta,
  handleLoadSuccess: (data: IGroupContractsResponse) => void,
  handleLoadError: (err: any) => void,
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [SharedQueryKeys.GetGroupContracts, groupId, paginationInputMeta],
    () => getGroupContracts(groupId, paginationInputMeta),
    {
      staleTime: ReactQueryConfig.staleTime,
      onSuccess(data) {
        handleLoadSuccess(data);
        queryClient.invalidateQueries([SharedQueryKeys.GetGroupForDetails, groupId]);
      },
      onError(err) {
        handleLoadError(err);
      },
    },
  );
};
