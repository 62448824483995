import { FunctionComponent } from "react";
import { SubmitHandler } from "react-hook-form";
import { useCreateFreshdeskTicketQuery } from "../../../../../shared/repositories/queries/freshdesk/create-freshdesk-ticket.query";
import { ICreateFreshdeskTicketRequest } from "../../../../../shared/domain/requests/create-freshdesk-ticket.request";
import { GenericFreshdeskForm, IGenericFreshdeskFormInputs } from "./generic-freshdesk-form.component";
import { useTranslation } from "react-i18next";

interface IOrderNewFreshDeskFormProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const OrderNewFreshDeskForm: FunctionComponent<IOrderNewFreshDeskFormProps> = (props) => {
  const { isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const { mutateAsync: freshdeskQuery } = useCreateFreshdeskTicketQuery();

  const onSubmit: SubmitHandler<IGenericFreshdeskFormInputs> = async (inputs): Promise<void> => {
    const requestData: ICreateFreshdeskTicketRequest = {
      subject: t("orders.new.wizard.product.hint.ticketSubject"),
      description: inputs.description,
    };

    return await freshdeskQuery({ createData: requestData });
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return <GenericFreshdeskForm displaySubject={false} isOpen={isOpen} onCancel={onCancel} onSubmit={onSubmit} />;
};
