import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import { ProductOverviewGeneralFilter } from "../../../../../configurator/pages/product/components/product-overview.utils";
import { ICountDataModel } from "../../../../../shared/models/util/count-data.model";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";

export const getProductCount = async (type: ProductOverviewGeneralFilter): Promise<number> => {
  const response = await getInstance().get<ICountDataModel>(RestEndpoints.ProductCount, { params: { type } });
  return response.data.count;
};

export const useGetProductCountQuery = (type: ProductOverviewGeneralFilter, enabled?: boolean) => {
  return useQuery([CfmQueryKeys.GetProductCount, type], () => getProductCount(type), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: enabled ?? true,
  });
};
