import { InputClassKey, StyleRules } from "@material-ui/core";
import { Colors, PrimaryColors, Shades, WarningColors } from "style/Colors";
import { BORDER_RADIUS } from "style/Constant";
import { FONT_FAMILY, INPUT_HEIGHT } from "./constant";

export const MuiInput: Partial<StyleRules<InputClassKey, {}>> = {
  root: {
    backgroundColor: Shades.gray10,
    borderRadius: BORDER_RADIUS,
    padding: "5px 10px",
    boxShadow: `0px 0px 0px 2px ${Shades.gray50}`,
    border: `none`,
    height: INPUT_HEIGHT,
    "&$disabled": {
      color: Shades.gray60,
      backgroundColor: Shades.gray10,
      boxShadow: `0px 0px 0px 2px ${Shades.gray30}`,
      opacity: 0.9,
    },
    "&.MuiAutocomplete-inputRoot": {
      padding: "5px 10px !important",
      minHeight: INPUT_HEIGHT,
      height: "unset !important",
    },
    "&$focused": {
      boxShadow: `0px 0px 0px 2px ${PrimaryColors.base}`,

      "&::before": {
        border: "none",
      },

      "&::after": {
        border: "none",
      },
    },
    "&$error": {
      boxShadow: `0px 0px 0px 2px ${WarningColors.base}`,
    },
  },
  underline: {
    "&:hover": {
      backgroundColor: Colors.grayLight,
    },
    "&:hover:not(.Mui-disabled)::before": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
    "&$disabled": {
      "&::before": {
        border: "none",
      },
    },
    "&$error": {
      "&::after": {
        border: "none !important",
      },
    },
  },
  multiline: {
    height: "inherit",
    borderRadius: ".5rem",
  },
  input: {
    fontFamily: FONT_FAMILY,
  },
};
