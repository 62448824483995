import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  getAcceptTextForAction,
  getDescriptionForAction,
  getDisplayStyleForAction,
  getTitleForAction,
  getTitleForCompleteAction,
  isLogisticMistrip,
} from "../actions/order-x-action.util";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { OrderBulkActionTypeInput } from "./order-bulk-action-type.input.component";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useCustomForm } from "../../../../../shared/util/form.util";
import { ICfmResponseValueFormInputs } from "../actions/order-x-action.component";
import { Alert } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { ResponseXInput } from "../actions/response-x-input.component";
import { CfmServiceDatesInput } from "../actions/service-date-input.component";
import { useSelectedFilesContext } from "../actions/providers/selected-files.provider";
import { useBulkUpdateOrderXQuery } from "../../../../repositories/queries/order-x/mutations/bulk-update-order-x.query";
import { CfmOrderXBulkUpdateConverter } from "../../../../repositories/models/converter/order-x/cfm-order-x-bulk-update.converter";
import { CfmResponseFieldXConverter } from "../../../../repositories/models/converter/cfm-response-field-x.converter";
import { ICfmCreateResponseValueX } from "../../../../domain/order-x/actions/cfm-create-response-value-x";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { useOrderBulkActionContext } from "./providers/order-bulk-action.provider";
interface IOrderBulkActionDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  reset: VoidFunction;
}

export const OrderBulkActionDialog: React.VFC<IOrderBulkActionDialogProps> = (props) => {
  const { open, setOpen, reset } = props;
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const { uploadFiles, isLoading: isUploadFilesLoading, reset: resetFileUpload } = useSelectedFilesContext();
  const { orders } = useOrderXOverviewContext();
  const { isLoading: isUpdateLoading, mutateAsync: updateOrders } = useBulkUpdateOrderXQuery();
  const { currentBulkAction, selectedOrderIds, cfmOrderBulkActions: bulkActions } = useOrderBulkActionContext();

  const isLoading = isUpdateLoading || isUploadFilesLoading;

  const firstOrder = orders.find((order) => selectedOrderIds.includes(order.id));

  const getDefaultValues = (): ICfmCreateResponseValueX[] => {
    if (!internalUser || !currentBulkAction || !firstOrder) {
      return [];
    }
    const productItem = firstOrder.items.find((item) => item.product);
    if (!productItem) return [];

    return (
      currentBulkAction.responseFieldAssignments?.map((assignment) =>
        CfmResponseFieldXConverter.assignmentToCreateResponseValueX(assignment, productItem.id, internalUser.id),
      ) ?? []
    );
  };

  const formMethods = useCustomForm<ICfmResponseValueFormInputs>({
    defaultValues: {
      responseFields: getDefaultValues(),
    },
  });

  const onCloseBulkActionDialog = () => {
    resetFileUpload();
    setOpen(false);
  };

  const onSubmit: SubmitHandler<ICfmResponseValueFormInputs> = async (inputs): Promise<void> => {
    const availableBulks = bulkActions?.availableOrderBulks;
    if (!availableBulks || !currentBulkAction) return;
    const filesList = (await uploadFiles()) ?? [];
    const matchingOrders = orders.filter((order) => availableBulks.map((bulk) => bulk.orderId).includes(order.id));
    await updateOrders(
      CfmOrderXBulkUpdateConverter.toUpdateResponseFieldsModel(
        matchingOrders,
        availableBulks,
        currentBulkAction,
        inputs,
        filesList,
      ),
    );
    reset();
    resetFileUpload();
    setOpen(false);
    formMethods.reset();
  };

  const onAcceptBulkActionDialog = async () => {
    await formMethods.handleSubmit(onSubmit)();
  };

  const isCompleteActionVisible = useMemo(() => {
    return isLogisticMistrip(currentBulkAction?.type, internalUser);
  }, [currentBulkAction?.type, internalUser]);

  if (!currentBulkAction) {
    return null;
  }

  const getDescriptionWhenNoResponseFieldsArePresent = (): ReactNode | undefined => {
    if (currentBulkAction?.responseFieldAssignments?.length === 0 && currentBulkAction?.serviceDates?.length === 0) {
      return (
        <Alert severity={"info"} className={"mb-3"}>
          <Typography variant="body1">{t(`orders.status.responseDialogGenericMessage`)}</Typography>
        </Alert>
      );
    }
  };

  const handleCompleteButton = async () => {
    if (isLogisticMistrip(currentBulkAction.type, internalUser)) {
      formMethods.setValue("logisticMistrip.reOrder", true);
      onAcceptBulkActionDialog();
    }
  };

  return (
    <AppDialog
      displayStyle={getDisplayStyleForAction(currentBulkAction.type, t)}
      open={open}
      onCancelClick={onCloseBulkActionDialog}
      onAcceptClick={onAcceptBulkActionDialog}
      title={getTitleForAction(currentBulkAction.type, t)}
      acceptTextOverride={getAcceptTextForAction(currentBulkAction.type, t)}
      isLoading={isLoading}
      isCompleteVisible={isCompleteActionVisible}
      completeTextOverride={getTitleForCompleteAction(currentBulkAction.type, t)}
      onCompleteClick={handleCompleteButton}
    >
      {getDescriptionForAction(currentBulkAction.type, t) && (
        <Alert severity={getDescriptionForAction(currentBulkAction.type, t)?.severity}>
          <Typography variant="body1">{getDescriptionForAction(currentBulkAction.type, t)?.message}</Typography>
        </Alert>
      )}
      {getDescriptionWhenNoResponseFieldsArePresent()}
      <FormProvider {...formMethods}>
        {currentBulkAction.responseFieldAssignments?.map((field, index) => {
          const responseField = currentBulkAction.responseFieldAssignments?.[index];
          if (!responseField) {
            return <></>;
          }
          return (
            <div className={"pb-4"} key={index}>
              <ResponseXInput
                responseIndex={index}
                responseFieldId={responseField.responseAssignmentResponseFieldId}
                fieldType={responseField.type}
                label={responseField.name}
                unit={responseField.unit}
              />
            </div>
          );
        })}
        {currentBulkAction.serviceDates && <CfmServiceDatesInput serviceDates={currentBulkAction.serviceDates} />}
        <OrderBulkActionTypeInput actionType={currentBulkAction.type} />
      </FormProvider>
    </AppDialog>
  );
};
