import React from "react";
import { ShowHistoryIcon } from "../../Primitives";
import { useTableActionIconStyle } from "./table-action-icons.style";

export const ShowHistoryTableIcon: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { small, greenFill, animatedBorder, animatedBorderActive } = useTableActionIconStyle();
  const classNames = [small, greenFill, animatedBorder];

  if (isActive) {
    classNames.push(animatedBorderActive);
  }

  return (
    <div className={classNames.join(" ")}>
      <ShowHistoryIcon svgColor="white" />
    </div>
  );
};
