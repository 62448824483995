import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderXList } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../../models/converter/order-x/cfm-order-x.converter";
import { ICfmOrderXFilterModel } from "../../../models/order-x/cfm-order-x-filter.model";
import { ICfmOrderXListModel } from "../../../models/order-x/cfm-order-x.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getOrders = async (filter: ICfmOrderXFilterModel): Promise<ICfmOrderXList> => {
  const { query, status, hasPlannedDeliveryDate } = filter;

  const { data } = await getInstance().get<ICfmOrderXListModel>(RestEndpoints.CfmOrdersX, {
    params: {
      query: query === "" ? undefined : query,
      status,
      hasPlannedDeliveryDate,
      logisticAddressId: filter.logisticAddressId,
      destinationAddressId: filter.destinationAddressId,
      stateId: filter.stateId,
      typeId: filter.typeId,
      fractionId: filter.fractionId,
      orderOfTruckloadSorting: filter.orderOfTruckloadSorting,
      assignedToTruckload: filter.assignedToTruckload,
    },
  });

  return {
    items: data.items.map(CfmOrderXConverter.toDomain),
    meta: data.meta,
  };
};

export const useGetOrdersQuery = (filter: ICfmOrderXFilterModel) => {
  return useQuery(
    [
      CfmQueryKeys.GetOrdersXWithFilter,
      filter.status,
      filter.hasPlannedDeliveryDate,
      filter.query,
      filter.orderId,
      filter.fractionId,
      filter.typeId,
      filter.stateId,
      filter.logisticAddressId,
      filter.destinationAddressId,
      filter.orderOfTruckloadSorting,
    ],
    () => getOrders(filter),
    {
      staleTime: ReactQueryConfig.staleTime,
    },
  );
};
