import React, { createContext, FunctionComponent, useContext, useRef } from "react";

interface IPomProductSearchFieldProvider {
  ref: React.Ref<HTMLInputElement>;
  focusAndScrollTo: () => void;
}

const PomProductSearchFieldContext = createContext<IPomProductSearchFieldProvider>(
  {} as IPomProductSearchFieldProvider,
);

export const usePomProductSearchFieldContext = () => {
  return useContext(PomProductSearchFieldContext);
};

const usePomSearchFieldProvider = (): IPomProductSearchFieldProvider => {
  const ref = useRef<HTMLInputElement>(null);

  const focusAndScrollTo = (): void => {
    if (ref.current) {
      (ref.current.children.namedItem("searchInput") as HTMLInputElement)?.focus();
      ref.current.scrollIntoView();
    }
  };

  return {
    ref: ref,
    focusAndScrollTo: focusAndScrollTo,
  };
};

export const PomProductSearchFieldProvider: FunctionComponent = (props) => {
  const value = usePomSearchFieldProvider();
  return <PomProductSearchFieldContext.Provider value={value}>{props.children}</PomProductSearchFieldContext.Provider>;
};
