import { makeStyles } from "@material-ui/core";

export const usePlatformIconStyle = makeStyles((theme) => ({
  cfmPlatformDetailsIcon: {
    height: 30,
    width: 54,
  },
  pomPlatformDetailsIcon: {
    height: 30,
    width: 54,
  },
}));
