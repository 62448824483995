export enum CfmOrderActionType {
  ShoppingCart = "ShoppingCart",
  EditInShoppingCart = "EditInShoppingCart",
  ChangeLogistic = "ChangeLogistic",
  ChangePlannedPickup = "ChangePlannedPickup",
  Ordered = "Ordered",
  LogisticAccepted = "LogisticAccepted",
  LogisticDeclined = "LogisticDeclined",
  LogisticAnnounced = "LogisticAnnounced",
  LogisticAnnouncedSingleOrder = "LogisticAnnouncedSingleOrder",
  LogisticPerformed = "LogisticPerformed",
  LogisticWarehouse = "LogisticWarehouse",
  LogisticTruckLoad = "LogisticTruckLoad",
  RecyclerIncomingWarehouse = "RecyclerIncomingWarehouse",
  RecyclerOutgoingWarehouse = "RecyclerOutgoingWarehouse",
  DestinationAnnounced = "DestinationAnnounced",
  DestinationDeclined = "DestinationDeclined",
  DestinationArrived = "DestinationArrived",
  DestinationReceived = "DestinationReceived",
  Confirmed = "Confirmed",
  Canceled = "Canceled",
  LogisticMistrip = "LogisticMistrip",
}
