import { GroupType } from "../../../../../shared/domain/group/group-type";
import { isCfmCustomer, isCfmLogistics, isCfmRecycler, IUser } from "../../../../../shared/domain/user/user";
import { TranslateFunc } from "../../../../../utils/translation.utils";
import { ICfmOrderXFilter } from "../../../../domain/order-x/cfm-order-x-filter";
import { CfmOrderXApiStatus, CfmOrderXStatus, CfmOrderXUiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { ORDER_STATUS_ALL } from "../../utils/order-x-card.util";
import { getStatusXName } from "./status-x-util";
import config from "../../../../../config/config";

export interface ICfmOrderXFilterStatus {
  label: string;
  status: CfmOrderXStatus | "all";
  filter?: ICfmOrderXFilter;
}

export const getStatusX = (t: TranslateFunc, user: IUser | undefined): ICfmOrderXFilterStatus[] => {
  if (!user) {
    return [];
  }

  if (isCfmLogistics(user)) {
    return [
      {
        label: t(`order.status_all.name`),
        status: ORDER_STATUS_ALL,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Ordered, t, GroupType.CfmLogistics),
        status: CfmOrderXApiStatus.Ordered,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticAccepted, t),
        status: CfmOrderXApiStatus.LogisticAccepted,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticAnnounced, t),
        status: CfmOrderXApiStatus.LogisticAnnounced,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticPerformed, t),
        status: CfmOrderXApiStatus.LogisticPerformed,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticWarehouse, t),
        status: CfmOrderXApiStatus.LogisticWarehouse,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticTruckLoadAnnounced, t),
        status: CfmOrderXApiStatus.LogisticTruckLoadAnnounced,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Confirmed, t),
        status: CfmOrderXApiStatus.Confirmed,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Canceled, t),
        status: CfmOrderXApiStatus.Canceled,
      },
    ];
  } else if (isCfmRecycler(user)) {
    if (config.enableNavision) {
      return [
        {
          label: t(`order.status_all.name`),
          status: ORDER_STATUS_ALL,
        },
        {
          label: getStatusXName(
            CfmOrderXUiStatus.LogisticAnnouncedWithoutPlannedDeliveryDate,
            t,
            GroupType.CfmRecycler,
          ),
          status: CfmOrderXUiStatus.LogisticAnnouncedWithoutPlannedDeliveryDate,
          filter: {
            hasPlannedDeliveryDate: false,
          },
        },
        {
          label: getStatusXName(CfmOrderXUiStatus.LogisticAnnouncedWithPlannedDeliveryDate, t, GroupType.CfmRecycler),
          status: CfmOrderXUiStatus.LogisticAnnouncedWithPlannedDeliveryDate,
          filter: {
            hasPlannedDeliveryDate: true,
          },
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.LogisticPerformed, t),
          status: CfmOrderXApiStatus.LogisticPerformed,
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.LogisticTruckLoadAnnounced, t),
          status: CfmOrderXApiStatus.LogisticTruckLoadAnnounced,
        },
        {
          label: getStatusXName(CfmOrderXUiStatus.SorterWarehouse, t, GroupType.CfmRecycler),
          status: CfmOrderXUiStatus.SorterWarehouse,
        },
        {
          label: getStatusXName(
            CfmOrderXUiStatus.RecyclerIncomingWarehouseWithoutTruckload,
            t,
            GroupType.CfmRecycler,
            undefined,
            false,
          ),
          status: CfmOrderXUiStatus.RecyclerIncomingWarehouseWithoutTruckload,
          filter: {
            assignedToTruckload: false,
          },
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.DestinationArrived, t),
          status: CfmOrderXApiStatus.DestinationArrived,
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.DestinationReceived, t),
          status: CfmOrderXApiStatus.DestinationReceived,
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.Confirmed, t),
          status: CfmOrderXApiStatus.Confirmed,
        },
      ];
    } else {
      return [
        {
          label: t(`order.status_all.name`),
          status: ORDER_STATUS_ALL,
        },
        {
          label: getStatusXName(
            CfmOrderXUiStatus.LogisticAnnouncedWithoutPlannedDeliveryDate,
            t,
            GroupType.CfmRecycler,
          ),
          status: CfmOrderXUiStatus.LogisticAnnouncedWithoutPlannedDeliveryDate,
          filter: {
            hasPlannedDeliveryDate: false,
          },
        },
        {
          label: getStatusXName(CfmOrderXUiStatus.LogisticAnnouncedWithPlannedDeliveryDate, t, GroupType.CfmRecycler),
          status: CfmOrderXUiStatus.LogisticAnnouncedWithPlannedDeliveryDate,
          filter: {
            hasPlannedDeliveryDate: true,
          },
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.LogisticPerformed, t),
          status: CfmOrderXApiStatus.LogisticPerformed,
        },
        {
          label: getStatusXName(
            CfmOrderXApiStatus.LogisticTruckLoadAnnounced,
            t,
            GroupType.CfmRecycler,
            undefined,
            true,
          ),
          status: CfmOrderXApiStatus.LogisticTruckLoadAnnounced,
        },
        {
          label: getStatusXName(
            CfmOrderXUiStatus.RecyclerIncomingWarehouseWithoutTruckload,
            t,
            GroupType.CfmRecycler,
            undefined,
            false,
          ),
          status: CfmOrderXUiStatus.RecyclerIncomingWarehouseWithoutTruckload,
          filter: {
            assignedToTruckload: false,
          },
        },
        {
          label: getStatusXName(CfmOrderXApiStatus.Confirmed, t),
          status: CfmOrderXApiStatus.Confirmed,
        },
      ];
    }
  } else if (isCfmCustomer(user)) {
    return [
      {
        label: t(`order.status_all.name`),
        status: ORDER_STATUS_ALL,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Ordered, t),
        status: CfmOrderXApiStatus.Ordered,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticAccepted, t),
        status: CfmOrderXApiStatus.LogisticAccepted,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticAnnounced, t),
        status: CfmOrderXApiStatus.LogisticAnnounced,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticPerformed, t),
        status: CfmOrderXApiStatus.LogisticPerformed,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Confirmed, t),
        status: CfmOrderXApiStatus.Confirmed,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Canceled, t),
        status: CfmOrderXApiStatus.Canceled,
      },
    ];
  } else {
    return [
      {
        label: t(`order.status_all.name`),
        status: ORDER_STATUS_ALL,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.ShoppingCart, t),
        status: CfmOrderXApiStatus.ShoppingCart,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Ordered, t),
        status: CfmOrderXApiStatus.Ordered,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticAccepted, t),
        status: CfmOrderXApiStatus.LogisticAccepted,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticAnnounced, t),
        status: CfmOrderXApiStatus.LogisticAnnounced,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.LogisticPerformed, t),
        status: CfmOrderXApiStatus.LogisticPerformed,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Confirmed, t),
        status: CfmOrderXApiStatus.Confirmed,
      },
      {
        label: getStatusXName(CfmOrderXApiStatus.Canceled, t),
        status: CfmOrderXApiStatus.Canceled,
      },
    ];
  }
};
