import { Typography } from "@material-ui/core";
import { ReactNode } from "react";
import { BasicButton, CancelButton, RoutingIconButton } from "../../../../../components/Primitives";
import { isAdmin, isCfmLogistics, IUser } from "../../../../../shared/domain/user/user";
import { TranslateFunc } from "../../../../../utils/translation.utils";
import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";

export const getButtonForAction = (
  title: string,
  actionType: CfmOrderActionType,
  handleButtonClick: VoidFunction,
): ReactNode => {
  switch (actionType) {
    case CfmOrderActionType.LogisticAnnounced:
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.ChangePlannedPickup:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
    case CfmOrderActionType.LogisticPerformed:
    case CfmOrderActionType.LogisticWarehouse:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
    case CfmOrderActionType.EditInShoppingCart:
    case CfmOrderActionType.DestinationAnnounced:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.DestinationReceived:
    case CfmOrderActionType.Confirmed:
      return (
        <BasicButton dataTestId="order-action-button" onClick={handleButtonClick} style={{ width: "inherit" }}>
          <Typography variant="body1"> {title} </Typography>
        </BasicButton>
      );
    case CfmOrderActionType.Canceled:
    case CfmOrderActionType.DestinationDeclined:
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.LogisticMistrip:
      return (
        <CancelButton data-testid="order-action-cancel-button" onClick={handleButtonClick} style={{ width: "inherit" }}>
          <Typography variant="body1"> {title} </Typography>
        </CancelButton>
      );
    case CfmOrderActionType.ChangeLogistic:
      return (
        <RoutingIconButton onClick={handleButtonClick} style={{ width: "inherit" }}>
          <Typography variant="body1"> {title} </Typography>
        </RoutingIconButton>
      );
  }
};

export const getAcceptTextForAction = (actionType: CfmOrderActionType, t: TranslateFunc): string => {
  switch (actionType) {
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticAnnounced:
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
    case CfmOrderActionType.LogisticPerformed:
    case CfmOrderActionType.LogisticWarehouse:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
    case CfmOrderActionType.DestinationAnnounced:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.DestinationReceived:
    case CfmOrderActionType.EditInShoppingCart:
    case CfmOrderActionType.ChangeLogistic:
    case CfmOrderActionType.ChangePlannedPickup:
    case CfmOrderActionType.Confirmed:
      return t("general.confirm");
    case CfmOrderActionType.Canceled:
      return t("dialog.order_storno_button_action.text");
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.DestinationDeclined:
      return t("order.decline.button");
    case CfmOrderActionType.LogisticMistrip:
      return t("order.logisticMistrip.cancel");
  }
};

export const getTitleForAction = (actionType: CfmOrderActionType, t: TranslateFunc): string => {
  switch (actionType) {
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.LogisticAnnounced:
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
    case CfmOrderActionType.LogisticPerformed:
    case CfmOrderActionType.LogisticWarehouse:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
    case CfmOrderActionType.DestinationAnnounced:
    case CfmOrderActionType.DestinationDeclined:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.DestinationReceived:
    case CfmOrderActionType.EditInShoppingCart:
    case CfmOrderActionType.Confirmed:
    case CfmOrderActionType.LogisticMistrip:
      return t(`orders.responseDialog.${actionType}`);
    case CfmOrderActionType.Canceled:
      return t("dialog.order_storno_title.text");
    case CfmOrderActionType.ChangeLogistic:
      return t(`orders.responseDialog.${actionType}.header`);
    case CfmOrderActionType.ChangePlannedPickup:
      return t(`orders.responseDialog.${actionType}.header`);
  }
};

export const getTitleForCompleteAction = (actionType: CfmOrderActionType, t: TranslateFunc): string => {
  switch (actionType) {
    case CfmOrderActionType.Canceled:
      return t(`orders.responseDialog.completeAction.${actionType}`);
    case CfmOrderActionType.LogisticMistrip:
      return t(`order.logisticMistrip.cancelAndReorder`);
    default:
      return t(`orders.responseDialog.${actionType}`);
  }
};

export const getDescriptionForAction = (
  actionType: CfmOrderActionType,
  t: TranslateFunc,
):
  | {
      message: string;
      severity: "success" | "info" | "error" | "warning";
    }
  | undefined => {
  switch (actionType) {
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticAnnounced:
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
    case CfmOrderActionType.LogisticPerformed:
    case CfmOrderActionType.LogisticWarehouse:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
    case CfmOrderActionType.DestinationAnnounced:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.DestinationReceived:
    case CfmOrderActionType.EditInShoppingCart:
    case CfmOrderActionType.ChangePlannedPickup:
    case CfmOrderActionType.Confirmed:
      return;
    case CfmOrderActionType.Canceled:
      return {
        message: t("dialog.order_storno_message.text"),
        severity: "warning",
      };
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.DestinationDeclined:
      return {
        message: t("order.decline.warning.message"),
        severity: "warning",
      };
  }
};

export const getDisplayStyleForAction = (actionType: CfmOrderActionType, t: TranslateFunc): "warning" | "success" => {
  switch (actionType) {
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticAnnounced:
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
    case CfmOrderActionType.LogisticPerformed:
    case CfmOrderActionType.LogisticWarehouse:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
    case CfmOrderActionType.DestinationAnnounced:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.DestinationReceived:
    case CfmOrderActionType.EditInShoppingCart:
    case CfmOrderActionType.ChangeLogistic:
    case CfmOrderActionType.ChangePlannedPickup:
    case CfmOrderActionType.Confirmed:
      return "success";
    case CfmOrderActionType.Canceled:
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.DestinationDeclined:
    case CfmOrderActionType.LogisticMistrip:
      return "warning";
  }
};

export const isLogisticMistrip = (type: CfmOrderActionType | undefined, user: IUser | undefined) => {
  return type === CfmOrderActionType.LogisticMistrip && (isAdmin(user) || isCfmLogistics(user));
};

export const isAdminCancelAndReOrder = (type: CfmOrderActionType | undefined, user: IUser | undefined) => {
  return isAdmin(user) && type === CfmOrderActionType.Canceled;
};
