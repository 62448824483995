export enum GroupType {
  Admin = 1,
  Customer = 2,
  CfmLogistics = 3,
  CfmRecycler = 4,
  PomAgency = 7,
  PomRepresentative = 8,
}

export const pomGroupTypes = [GroupType.Admin, GroupType.Customer, GroupType.PomAgency, GroupType.PomRepresentative];
export const cfmGroupTypes = [GroupType.Admin, GroupType.Customer, GroupType.CfmLogistics, GroupType.CfmRecycler];

export const isAdminGroup = (types: GroupType[]): boolean => {
  return types.includes(GroupType.Admin);
};

export const isAgencyGroup = (types: GroupType[]): boolean => {
  return [GroupType.PomAgency].some((type) => types.includes(type));
};

export const isCreditorGroup = (types: GroupType[]): boolean => {
  return [GroupType.CfmLogistics, GroupType.CfmRecycler].some((type) => types.includes(type));
};

export const isRepresentativeGroup = (types: GroupType[]): boolean => {
  return [GroupType.PomRepresentative].some((type) => types.includes(type));
};

export const isDebtorGroup = (types: GroupType[]): boolean => {
  return [GroupType.Customer].some((type) => types.includes(type));
};

export const isCfmGroup = (type: GroupType | undefined) => {
  return type && cfmGroupTypes.includes(type);
};
export const isPomGroup = (type: GroupType | undefined) => {
  return type && pomGroupTypes.includes(type);
};
