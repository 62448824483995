import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAutocomplete } from "../../../../../../shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "../../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { Loading } from "../../../../../../shared/components/loading/loading.component";
import { IAddress } from "../../../../../../shared/domain/address/address";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { AddressType } from "../../../../../../shared/models/address/address.model";
import { getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { CfmProductAddressTypeModel } from "../../../../../repositories/models/product/cfm-product-address-type.model";
import { useGetAddressesForAccepting } from "../../../../../repositories/queries/address/get-addresses-for-accepting.query";
import { useGetAddressesForProductQuery } from "../../../../../repositories/queries/address/get-addresses-for-product.query";
import { useOrderXSelectedContext } from "../../../order-x-selected.provider";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";

export interface IOrderXLogisticAnnouncedAction {
  logisticAddressId: number;
  destinationAddressId: number;
  plannedPickupDate: Date;
}

export const OrderXLogisticAnnouncedAction: FC = () => {
  const { order } = useOrderXSelectedContext();
  const productItem = order.items.find((item) => item.product);
  const productId = productItem?.product?.id ?? 0;
  const {
    formState: { errors },
    control,
    register,
    setValue,
    getValues,
  } = useFormContext<ICfmResponseValueFormInputs>();
  const { t } = useTranslation();

  const { data: logisticsAddressesForAccepting, isLoading: logisticsAddressesLoading } = useGetAddressesForAccepting(
    true,
    [AddressType.LogisticLocation],
  );

  const { data: destinationAddresses, isLoading: destinationAddressesLoading } = useGetAddressesForProductQuery(
    productId,
    CfmProductAddressTypeModel.TargetAddresses,
    order.routingPostal ?? "DE",
    order.logisticAddress?.id ?? 0,
    true,
  );

  const getAddressIds = (addresses: Array<IAddress | IAddressLean> | undefined) => {
    if (!addresses) return [];
    return addresses.filter((address) => address.id).map((address) => address.id as number);
  };

  const addressOptions: Array<IAddress | IAddressLean> = useMemo(() => {
    const addresses: Array<IAddress | IAddressLean> = [];
    if (destinationAddresses?.address) {
      addresses.push(destinationAddresses.address);
    }

    if (destinationAddresses?.optionalAddresses.length) {
      addresses.push(
        ...destinationAddresses.optionalAddresses.filter((optionalAddress) =>
          addresses.some((a) => a.id !== optionalAddress.id),
        ),
      );
    }

    if (order.destinationAddress && !addresses.some((a) => a.id === order.destinationAddress?.id)) {
      addresses.push(order.destinationAddress);
    }

    return addresses;
  }, [destinationAddresses]);

  const isLoading = logisticsAddressesLoading || destinationAddressesLoading;

  useEffect(() => {
    // Prefill the form with the primary address
    if (destinationAddresses?.address && !getValues("logisticAnnounced.destinationAddressId")) {
      setValue("logisticAnnounced.destinationAddressId", destinationAddresses.address.id);
    }
    if (order.logisticAddress && !getValues("logisticAnnounced.logisticAddressId")) {
      setValue("logisticAnnounced.logisticAddressId", order.logisticAddress.id);
    }
  }, [destinationAddresses, order.logisticAddress, getValues, setValue]);

  return (
    <Loading isLoading={isLoading}>
      <FormField md={12} className={"pb-4"}>
        <FormAutocomplete<number>
          name={"logisticAnnounced.logisticAddressId"}
          control={control}
          label={t("basedata.addresses.type.logistic_location")}
          options={getAddressIds(logisticsAddressesForAccepting ?? [])}
          getOptionLabel={(value) => {
            const address = (logisticsAddressesForAccepting ?? []).find((address) => address.id === value);
            if (address) {
              return getFormattedDomainAddress(address, t);
            }
          }}
          error={Boolean(errors?.logisticAnnounced?.logisticAddressId)}
          rules={{ required: true }}
        />
      </FormField>

      <FormField md={12} className={"pb-4"}>
        <FormDatepickerField
          label={t(`orders.accept_form.planned_pickup_date.label`)}
          hasError={Boolean(errors?.logisticAnnounced?.plannedPickupDate)}
          control={control}
          required={true}
          {...register(`logisticAnnounced.plannedPickupDate`, {
            required: true,
            valueAsDate: true,
          })}
        />
      </FormField>

      <FormField md={12}>
        <FormAutocomplete<number>
          name={"logisticAnnounced.destinationAddressId"}
          control={control}
          label={t("order.column_name.destinationAddress")}
          options={getAddressIds(addressOptions)}
          getOptionLabel={(value) => {
            const address = addressOptions.find((address) => address.id === value);
            if (address) {
              return getFormattedDomainAddress(address, t);
            }
          }}
          error={Boolean(errors?.logisticAnnounced?.destinationAddressId)}
          rules={{ required: true }}
        />
      </FormField>
    </Loading>
  );
};
