import { Box, Card, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ForwardButton } from "../../../../../../components/Primitives/Buttons";
import { PrimaryColors } from "../../../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  truckloadWeightText: {
    color: PrimaryColors.base,
  },
  container: {
    height: "100%",
  },
  submitButton: {
    margin: "0px 20px 0px 20px",
  },
  containerPadding: {
    padding: "20px 0px 20px 0px",
  },
}));

interface ILogisticWarehouseFooterProps {
  selectedWeight: number;
  totalWarehouseWeight: number | undefined;
  submitDisabled: boolean;
  onCreateTruckloadClick: VoidFunction;
}

export const LogisticWarehouseFooter: VFC<ILogisticWarehouseFooterProps> = (props) => {
  const { submitDisabled, selectedWeight, totalWarehouseWeight, onCreateTruckloadClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card raised elevation={20}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignContent="center"
        className={classes.containerPadding}
      >
        <Grid item xs={12} md={"auto"} className="mr-4">
          <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
            <Typography variant="body1" className={classes.truckloadWeightText}>
              <Trans i18nKey="orders.truckload.selectedWeight">{{ weight: selectedWeight.toLocaleString() }}</Trans>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={"auto"}>
          <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
            <Typography variant="body1" className={classes.truckloadWeightText}>
              <Trans i18nKey="order.totalWeightInWarehouse">
                {{ weight: totalWarehouseWeight?.toLocaleString() ?? "-" }}
              </Trans>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={"auto"} alignContent="center" className={classes.submitButton}>
          <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
            <ForwardButton disabled={submitDisabled} onClick={onCreateTruckloadClick}>
              <Typography variant="body1">{t("orders.truckload.createTruckload")}</Typography>
            </ForwardButton>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
