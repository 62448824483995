import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { ICfmResponseAssignmentX } from "../../../domain/assignment/cfm-response-assignment-x";
import { CfmResponseAssignmentXConverter } from "../../models/converter/cfm-response-assignment-x.converter";
import { CfmQueryKeys } from "../cfm-query-keys";
import { ICfmResponseAssignmentXModel } from "./../../models/response-assignment/cfm-response-assignment-x.model";

export const getResponseAssignmentX = async (assignmentIds?: number[]): Promise<ICfmResponseAssignmentX[]> => {
  const response = await getInstance().get<{ items: ICfmResponseAssignmentXModel[] }>(
    RestEndpoints.ResponseAssignmentsX,
    {
      params: {
        assignmentIds,
      },
    },
  );
  return response.data.items.map(CfmResponseAssignmentXConverter.toDomain);
};

export const useGetResponseAssignmentsXQuery = () => {
  return useQuery([CfmQueryKeys.GetResponseAssignments], () => getResponseAssignmentX(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};

export const useGetResponseAssignmentByIdsXQuery = (assignmentIds: number[] | undefined, enabled: boolean) => {
  return useQuery(
    [CfmQueryKeys.GetResponseAssignmentByIds, assignmentIds],
    () => getResponseAssignmentX(assignmentIds),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled && (assignmentIds?.length || 0) > 0,
    },
  );
};
