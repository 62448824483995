import dayjs from "dayjs";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { QuestionDialog } from "../../../../components/Dialogs/question-dialog";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { ICfmCreateTruckload } from "../../../domain/truckload/cfm-create-truckload";
import { LogisticWarehouseDialog } from "../../../pages/order-x/components/dialog/logistic-warehouse-dialog.component";
import { getWeight } from "../../../pages/order-x/utils/truckload.utils";
import { AddOrderToTruckloadDialog } from "./add-order-to-truckload-dialog.component";
import { ITruckloadPerformedInputs, TruckloadPerformedDialog } from "./truckload-performed-dialog.component";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";

interface IEditTruckloadDialogsProps {
  truckload: ICfmTruckloadWithOrders;
  truckloadOrders: ICfmOrderX[];
  isTruckloadPerformedDialogOpen: boolean;
  isEditTruckloadInfoDialogOpen: boolean;
  isAddOrderDialogOpen: boolean;
  isConfirmCancelOpen: boolean;
  isSubmitTruckloadOpen: boolean;
  setIsAddOrderDialogOpen: (isOpen: boolean) => void;
  setIsConfirmCancelOpen: (isOpen: boolean) => void;
  setIsEditTruckloadInfoDialogOpen: (isOpen: boolean) => void;
  setIsTruckloadPerformedDialogOpen: (isOpen: boolean) => void;
  setIsSubmitTruckloadOpen: (isOpen: boolean) => void;
  onRespondTruckload: (data: ITruckloadPerformedInputs) => Promise<void>;
  onUpdateTruckloadInfo: (data: ICfmCreateTruckload) => Promise<void>;
  onAddOrdersToTruckload: (orders: ICfmOrderX[]) => void;
  onCancelTruckload: () => Promise<void>;
  onSubmitTruckload: (data: ICfmCreateTruckload) => Promise<void>;
  isLoading: boolean;
}

export const EditTruckloadDialogs: VFC<IEditTruckloadDialogsProps> = (props) => {
  const {
    truckload,
    truckloadOrders,
    isTruckloadPerformedDialogOpen,
    isEditTruckloadInfoDialogOpen,
    isAddOrderDialogOpen,
    isConfirmCancelOpen,
    isSubmitTruckloadOpen,
    setIsAddOrderDialogOpen,
    setIsEditTruckloadInfoDialogOpen,
    setIsTruckloadPerformedDialogOpen,
    setIsConfirmCancelOpen,
    setIsSubmitTruckloadOpen,
    onRespondTruckload,
    onUpdateTruckloadInfo,
    onAddOrdersToTruckload,
    onCancelTruckload,
    onSubmitTruckload,
    isLoading,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {isTruckloadPerformedDialogOpen && (
        <TruckloadPerformedDialog
          isLoading={isLoading}
          ordersInTruckload={truckloadOrders}
          isOpen={isTruckloadPerformedDialogOpen}
          onCancel={() => setIsTruckloadPerformedDialogOpen(false)}
          onSubmit={onRespondTruckload}
          calculatedWeight={getWeight(truckloadOrders)}
        />
      )}
      {isEditTruckloadInfoDialogOpen && (
        <LogisticWarehouseDialog
          isDraftEnabled={false}
          startAddressId={truckload.startAddress.id}
          initialValues={{
            targetRecyclerAddressId: truckload.targetAddress?.id,
            serviceDatesForOrders: {},
            plannedDeliveryDate: truckload.plannedDeliveryDate
              ? dayjs(truckload.plannedDeliveryDate).toDate()
              : undefined,
          }}
          open={isEditTruckloadInfoDialogOpen}
          onCancel={() => setIsEditTruckloadInfoDialogOpen(false)}
          onSubmit={onUpdateTruckloadInfo}
          weight={getWeight(truckloadOrders)}
          selectedOrders={truckloadOrders}
          serviceDateEditEnabled={false}
          acceptTextOverride={t("general.change")}
          isLoading={isLoading}
        />
      )}
      {isAddOrderDialogOpen && (
        <AddOrderToTruckloadDialog
          truckload={truckload}
          existingOrders={truckloadOrders}
          isOpen={isAddOrderDialogOpen}
          onCancel={() => setIsAddOrderDialogOpen(false)}
          onAddOrdersToTruckload={onAddOrdersToTruckload}
        />
      )}
      {isConfirmCancelOpen && (
        <QuestionDialog
          title={t("orders.truckload.cancel.dialogHeader")}
          message={t("orders.truckload.cancel.dialogContent")}
          acceptText={t("dialog.order_storno_button_action.text")}
          onAccept={onCancelTruckload}
          onClose={() => setIsConfirmCancelOpen(false)}
          isOpen={isConfirmCancelOpen}
          displayStyle={"warning"}
        />
      )}
      {isSubmitTruckloadOpen && (
        <LogisticWarehouseDialog
          startAddressId={truckload.startAddress.id}
          initialValues={{
            targetRecyclerAddressId: truckload.targetAddress?.id,
            plannedDeliveryDate: truckload.plannedDeliveryDate
              ? dayjs(truckload.plannedDeliveryDate).toDate()
              : undefined,
            serviceDatesForOrders: {},
          }}
          isDraftEnabled={false}
          open={isSubmitTruckloadOpen}
          onCancel={() => setIsSubmitTruckloadOpen(false)}
          onSubmit={onSubmitTruckload}
          weight={getWeight(truckloadOrders)}
          selectedOrders={truckloadOrders}
          serviceDateEditEnabled
          acceptTextOverride={t("orders.truckload.submit")}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
