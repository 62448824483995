import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { ILoginAsResponse } from "../../../models/auth/login-as.response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { SharedQueryKeys } from "../../shared-query-keys";

const loginAs = async (id: number): Promise<ILoginAsResponse> => {
  const url = RestEndpoints.LoginAsUser.replace(":id", id.toString());
  const { data } = await getInstance().post<ILoginAsResponse>(url);
  return data;
};

export const useLoginAsQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(SharedQueryKeys.LoginAsUser, ({ id }: { id: number }) => loginAs(id), {
    onError: (error) => {
      const errorMsg = getErrorCode(error);
      const errorCode = getErrorStatusCode(error);
      enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
    },
    onSuccess: () => {
      enqueueSnackbar(t("basedata.users.login.successLogin"), { variant: "success" });
      void queryClient.invalidateQueries(SharedQueryKeys.GetUser);
    },
  });
};
