import { TranslateFunc } from "utils/translation.utils";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { ApiErrorCodes } from "../../../../api/api-error-codes";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { ICfmCreateResponseField } from "../../../domain/routing-assignment/cfm-response-field";
import { CfmQueryKeys } from "../cfm-query-keys";

const updateResponseField = async (id: number, responseField: ICfmCreateResponseField): Promise<void> => {
  const url = replaceUriParameter(RestEndpoints.ResponseField, "id", id);
  await getInstance().put(url, { ...responseField });
};

export type EnqueueSnackbarFunc = (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey;
export const handleError = (e: unknown, enqueueSnackbar: EnqueueSnackbarFunc, t: TranslateFunc) => {
  const errorMsg = getErrorCode(e);
  const errorCode = getErrorStatusCode(e);
  if (errorMsg === ApiErrorCodes.CfmInternalNumberAlreadyExists.toString()) {
    enqueueSnackbar(t("responsefields.already_exists"), { variant: "error" });
  } else {
    enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
  }
};

export const useUpdateResponseFieldQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation(
    CfmQueryKeys.UpdateResponseField,
    ({ id, responseField }: { id: number; responseField: ICfmCreateResponseField }) =>
      updateResponseField(id, responseField),
    {
      onError: (e) => {
        handleError(e, enqueueSnackbar, t);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries(CfmQueryKeys.GetAllResponseFieldsInfinite);
      },
    },
  );
};
