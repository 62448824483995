import {
  CfmTransferOrderActionTypeModel,
  ICfmTransferOrderAvailableActionModel,
} from "../../transfer-order/cfm-transfer-order-available-action.model";
import {
  CfmTransferOrderActionType,
  ICfmTransferOrderAvailableAction,
} from "../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { CfmServiceDateConverter } from "../order-x/cfm-service-date.converter";
import { CfmResponseFieldXConverter } from "../cfm-response-field-x.converter";

export class CfmTransferOrderAvailableActionConverter {
  public static toDomain(model: ICfmTransferOrderAvailableActionModel): ICfmTransferOrderAvailableAction {
    return {
      type: this.actionToDomain(model.type),
      serviceDates: model.serviceDates?.map(CfmServiceDateConverter.toDomain),
      responseFieldAssignments: model.responseFieldAssignments?.map(CfmResponseFieldXConverter.toDomain),
    };
  }

  private static actionToDomain(model: CfmTransferOrderActionTypeModel): CfmTransferOrderActionType {
    switch (model) {
      case CfmTransferOrderActionTypeModel.Sorted:
        return CfmTransferOrderActionType.Sorted;
      case CfmTransferOrderActionTypeModel.TruckloadCreated:
        return CfmTransferOrderActionType.TruckloadCreated;
      case CfmTransferOrderActionTypeModel.TruckloadConfirmed:
        return CfmTransferOrderActionType.TruckloadConfirmed;
      case CfmTransferOrderActionTypeModel.Recycled:
        return CfmTransferOrderActionType.Recycled;
    }
  }
}
