import { Button, ButtonProps, IconButton, IconButtonProps } from "@material-ui/core";
import { Block, Check, Launch, MailOutline, Print } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import { useButtonStyles } from "../../style/buttons.style";
import { Colors, PrimaryColors, Shades } from "../../style/Colors";
import { useIconButtonsStyle } from "../../style/iconButtons.style";
import {
  AddIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  CancelIcon,
  CheckboxApproveIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  ExpandIcon,
  FilterIcon,
  IconColor,
  LoginAsIcon,
  RoutingConfigSmallIcon,
  SaveIcon,
  ShoppingCardIcon,
} from "./Icons";

interface IButtonsProps extends ButtonProps {
  isActive?: boolean;
  iconSvgColor?: IconColor;
  dataTestId?: string;
}

interface IFilterButtonProps extends IButtonsProps {
  isFilterActive?: boolean;
}

interface IIconButtonsProps extends IconButtonProps {
  isActive?: boolean;
  ref?: any; // should be done more research about ref type
}

/*
 --- BUTTONS
*/
export const LoginAsButtonOutlined: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "outlined",
  ...props
}) => {
  return (
    <Button
      endIcon={<LoginAsIcon />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const CancelButtonOutlined: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  className,
  ...props
}) => {
  const { dangerOutlined } = useButtonStyles();
  return (
    <Button
      endIcon={<Block />}
      color="secondary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${dangerOutlined}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export const UploadButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  variant = "contained",
  iconSvgColor = "white",
  ...props
}) => {
  return (
    <Button
      endIcon={<AddIcon svgColor={iconSvgColor} />}
      {...props}
      component="span"
      variant={variant}
      color="primary"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const LoginButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button color="primary" variant={variant} disabled={disabled} onClick={onClick} style={{ fontSize: 16 }} {...props}>
      {children}
    </Button>
  );
};

export const BasicButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  dataTestId,
  ...props
}) => {
  return (
    <Button data-testid={dataTestId} color="primary" variant={variant} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export const DeleteButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  className,
  iconSvgColor,
  ...props
}) => {
  const { danger } = useButtonStyles();
  return (
    <Button
      endIcon={<DeleteIcon svgColor={iconSvgColor ? iconSvgColor : disabled ? "black" : "white"} />}
      color="secondary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${danger}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export const CancelButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  className,
  iconSvgColor,
  ...props
}) => {
  const { danger } = useButtonStyles();
  return (
    <Button
      endIcon={<CancelIcon svgColor={iconSvgColor ? iconSvgColor : disabled ? "black" : "white"} />}
      color="secondary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${danger}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export const BackButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      startIcon={<ArrowLeftIcon />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const ForwardButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      endIcon={<ArrowRightIcon svgColor={variant === "outlined" ? "green" : "white"} />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const SmallShoppingCartButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      startIcon={<ShoppingCardIcon svgColor="white" />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const AddButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  iconSvgColor = "white",
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      endIcon={<AddIcon svgColor={disabled ? undefined : iconSvgColor} />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const FilterButton: React.FC<IFilterButtonProps> = ({
  children,
  onClick,
  disabled,
  variant = "outlined",
  isActive,
  isFilterActive,
  ...props
}) => {
  const [hasEntered, setHasEntered] = useState(false);
  return (
    <Button
      endIcon={<FilterIcon svgColor={hasEntered || isActive || isFilterActive ? "white" : "green"} />}
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={classNames({ "--active": isActive, "--filter-active": isFilterActive })}
      {...props}
    >
      {children}
    </Button>
  );
};

export const SaveButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button endIcon={<SaveIcon />} color="primary" variant={variant} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export const TransferDataButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      endIcon={<ArrowUpIcon />}
      color="primary"
      style={{ backgroundColor: Colors.blue }}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const PrintButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button endIcon={<Print />} color="primary" variant={variant} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export const AcceptButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button endIcon={<Check />} color="primary" variant={variant} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export const EditButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button endIcon={<EditIcon />} color="primary" variant={variant} disabled={disabled} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

/*
 --- ICON BUTTONS
*/

export const DeleteIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { red, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [red, normal];

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      style={{ height: 35, width: 35 }}
      onClick={onClick}
      {...props}
    >
      <DeleteIcon svgColor={hasEntered ? "white" : "red"} />
    </IconButton>
  );
};

export const ExpandIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { greenFill, expandIcon } = useIconButtonsStyle();
  const classes = [greenFill];

  return (
    <IconButton
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      style={{ height: 35, width: 35 }}
      onClick={onClick}
      {...props}
    >
      <ExpandIcon className={expandIcon} />
    </IconButton>
  );
};

export const FilledCloseIconButton: React.FC<IIconButtonsProps> = ({
  children,
  onClick,
  isActive,
  disabled,
  ...props
}) => {
  const { greenFill, expandIcon } = useIconButtonsStyle();
  const classes = [greenFill];

  return (
    <IconButton
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      style={{ height: 35, width: 35 }}
      onClick={onClick}
      {...props}
    >
      <CloseIcon className={expandIcon} />
    </IconButton>
  );
};

export const DangerousDeleteIconNoBorderButton: React.FC<IIconButtonsProps> = ({
  children,
  onClick,
  isActive,
  disabled,
  ...props
}) => {
  return (
    <IconButton color="primary" disabled={disabled} style={{ height: 35, width: 35 }} onClick={onClick} {...props}>
      <DeleteIcon svgColor={"red"} />
    </IconButton>
  );
};

export const AddIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { green, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [green, normal];

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <AddIcon svgColor={hasEntered ? "white" : "green"} />
    </IconButton>
  );
};

export const EditIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { green, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [green, normal];

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      style={{ height: 35, width: 35 }}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <EditIcon fontSize="small" svgColor={hasEntered ? "white" : "green"} />
    </IconButton>
  );
};

export const EditIconNoBorderButton: React.FC<IIconButtonsProps> = ({
  children,
  onClick,
  isActive,
  disabled,
  ...props
}) => {
  return (
    <IconButton color="primary" style={{ height: 35, width: 35 }} disabled={disabled} onClick={onClick} {...props}>
      <EditIcon fontSize="small" svgColor={"green"} />
    </IconButton>
  );
};

export const ArrowRightIconButton: React.FC<IIconButtonsProps> = ({
  children,
  onClick,
  isActive,
  disabled,
  ...props
}) => {
  const { green, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [green, normal];

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      style={{ height: 35, width: 35 }}
      {...props}
    >
      <ArrowRightIcon fontSize="small" svgColor={hasEntered ? "white" : "green"} />
    </IconButton>
  );
};

export const CloseIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { green, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [green, normal];

  const getSvgColor = () => {
    if (hasEntered) {
      return "hover";
    }

    if (disabled) {
      return "disabled";
    }

    return "green";
  };

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      style={{ height: 35, width: 35, borderColor: disabled ? Colors.disabled : Colors.greenLight }}
      onClick={onClick}
      {...props}
    >
      <CloseIcon fontSize="small" svgColor={getSvgColor()} />
    </IconButton>
  );
};

export const ApproveIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { green, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [green, normal];

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      style={{ height: 35, width: 35 }}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <CheckboxApproveIcon fontSize="small" svgColor={hasEntered ? "white" : "green"} />
    </IconButton>
  );
};

export const LoginAsIconButton: React.FC<IIconButtonsProps> = ({ children, onClick, isActive, disabled, ...props }) => {
  const { green, normal } = useIconButtonsStyle();
  const [hasEntered, setHasEntered] = useState(false);
  const classes = [green, normal];

  const getColor = () => {
    if (disabled) {
      return Colors.disabled;
    }
    if (hasEntered) {
      return Shades.white;
    }

    return PrimaryColors.base;
  };

  return (
    <IconButton
      onMouseEnter={() => setHasEntered(true)}
      onMouseLeave={() => setHasEntered(false)}
      className={classes.join(" ")}
      color="primary"
      disabled={disabled}
      style={{ height: 35, width: 35, borderColor: disabled ? Colors.disabled : Colors.greenLight }}
      onClick={onClick}
      {...props}
    >
      <Launch fontSize="small" style={{ fill: getColor() }} />
    </IconButton>
  );
};

export const MailIconButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      endIcon={<MailOutline />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};

export const RoutingIconButton: React.FC<IButtonsProps> = ({
  children,
  onClick,
  disabled,
  variant = "contained",
  ...props
}) => {
  return (
    <Button
      endIcon={<RoutingConfigSmallIcon />}
      color="primary"
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
};
