import { Box, makeStyles } from "@material-ui/core";
import { formatMonthShort, formatYearShort } from "../../../shared/util/date.util";
import { Colors } from "../../../style/Colors";
import { PomAnnouncementPeriod } from "../../repositories/models/announcements/pom-announcement.period";
import { PomAnnouncementStatusModel } from "../../repositories/models/announcements/pom-announcements.model";

interface IPomAnnouncementDatePickerMonth {
  date: Date;
  isActive: boolean;
  status: PomAnnouncementStatusModel | undefined;
  period: PomAnnouncementPeriod;
  onEntryClicked: (date: Date) => void;
}

const useStyles = makeStyles({
  monthContainer: {
    padding: "6px",
    textAlign: "center",
    color: Colors.white,
    cursor: "pointer",
    border: `2px solid transparent`,
    transition: "margin 400ms, border-radius 400ms",
    "&:hover": {
      background: Colors.greenLight,
      borderRadius: "6px",
      margin: "0 -5px",
    },
  },
  hasNoAnnouncement: {
    borderRadius: "22px",
    background: "transparent",
    borderColor: Colors.white,
  },
  hasAnnouncement: {
    borderRadius: "22px",
    background: Colors.greenLight,
  },
  hasDraft: {
    borderRadius: "22px",
    background: "transparent",
    borderColor: Colors.greenLight,
  },
  active: {
    background: Colors.white,
    borderRadius: "4px",
    color: Colors.greenDark,
    "&:hover": {
      color: Colors.white,
    },
  },
});

export const PomAnnouncementDatePickerEntry = (props: IPomAnnouncementDatePickerMonth) => {
  const classes = useStyles();

  const classNames = [classes.monthContainer];
  const { date, isActive, status, onEntryClicked, period } = props;

  if (isActive) {
    classNames.push(classes.active);
  } else {
    if (status && status === PomAnnouncementStatusModel.Published) {
      classNames.push(classes.hasAnnouncement);
    } else if (status && status === PomAnnouncementStatusModel.Draft) {
      classNames.push(classes.hasDraft);
    } else {
      classNames.push(classes.hasNoAnnouncement);
    }
  }

  return (
    <Box
      onClick={() => onEntryClicked(date)}
      className={classNames.join(" ")}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {period === PomAnnouncementPeriod.Monthly ? formatMonthShort(date) : formatYearShort(date)}
    </Box>
  );
};
