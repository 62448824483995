import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoginAs } from "../../../providers/Auth/login-as-context";
import { AppDialog } from "../dialog/app-dialog.component";
import { GroupType } from "../../domain/group/group-type";
import { useAuthContext } from "../../../providers/Auth/auth.provider";

interface ILogoutAsDialogProps {
  open: boolean;
  onCancel: () => void;
}

export const LogoutAsDialog = (props: ILogoutAsDialogProps) => {
  const { open, onCancel } = props;
  const { t } = useTranslation();
  const { loginAsTypes } = useLoginAs();
  const { logoutAs } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogoutAs = async () => {
    setIsLoading(true);
    await logoutAs();
    setIsLoading(false);
    onCancel();
  };

  const isPomAgency = () => {
    if (!loginAsTypes) {
      return false;
    }

    loginAsTypes.includes(GroupType.PomAgency);
  };

  const getDialogTextTranslation = (): string => {
    if (isPomAgency()) {
      return t("basedata.users.logout.agency_text");
    } else {
      return t("basedata.users.logout.text");
    }
  };

  const getLogoutTextTranslation = (): string => {
    if (isPomAgency()) {
      return t("basedata.users.logout.agency_action");
    } else {
      return t("basedata.users.table.action.logout");
    }
  };

  return (
    <AppDialog
      displayStyle="success"
      open={open}
      isLoading={isLoading}
      onCancelClick={onCancel}
      onAcceptClick={handleLogoutAs}
      acceptTextOverride={getLogoutTextTranslation()}
      title={t("basedata.users.logout.header")}
      maxWidth={"sm"}
      fullWidth={true}
      alignButtons="space-between"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">{getDialogTextTranslation()}</Typography>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
