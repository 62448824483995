import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDatepickerField } from "../../../../../../shared/components/form/fields/form-datepicker.field";
import { FormField } from "../../../../../../shared/components/form/form-field.component";
import { ICfmResponseValueFormInputs } from "../order-x-action.component";

export interface IOrderXLogisticPerformedAction {
  actualPickupDate: Date;
}

export const OrderXLogisticPerformedOrderAction: VFC = () => {
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext<ICfmResponseValueFormInputs>();
  const { t } = useTranslation();

  return (
    <FormField md={12} className={"pb-4"}>
      <FormDatepickerField
        label={t(`orders.accept_form.actual_pickup_date.label`)}
        hasError={Boolean(errors?.logisticPerformed?.actualPickupDate)}
        control={control}
        required={true}
        {...register(`logisticPerformed.actualPickupDate`, {
          required: true,
          valueAsDate: true,
        })}
      />
    </FormField>
  );
};
