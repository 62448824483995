import React, { Suspense, VFC } from "react";
import { Redirect, Switch } from "react-router-dom";
import { GroupType } from "../../shared/domain/group/group-type";
import { CreateUserPage } from "../../shared/pages/create-user/create-user.page";
import { AuthenticatedRoute } from "../../utils/AuthenticatedRoute";
import { AvailablePomRoutes } from "../../utils/constants";
import { ManufacturerFormMode } from "../components/manufacturer-form/manufacturer-form.component";
import { PomAgencyPage } from "../pages/agency/pom-agency.page";
import { PomAnnouncementPage } from "../pages/announcement/pom-announcement.page";
import { PomManufacturerAddPage } from "../pages/manufacturer/pom-manufacturer-add.page";
import { PomManufacturerDetailPage } from "../pages/manufacturer/pom-manufacturer-detail.page";
import { Loading } from "../../shared/components/loading/loading.component";
import { AvailablePlatform } from "../../providers/Auth/platform.provider";

export const PomRoutes: VFC = () => {
  return (
    <Suspense fallback={<Loading isLoading={true} />}>
      <Switch>
        <Redirect path={AvailablePomRoutes.Portal} exact to={AvailablePomRoutes.Announcement} />
        <AuthenticatedRoute
          allowedRoles={[GroupType.Customer, GroupType.PomRepresentative, GroupType.PomAgency]}
          path={AvailablePomRoutes.Announcement}
          exact
          component={PomAnnouncementPage}
        />

        {/*New User for non Admin*/}
        <AuthenticatedRoute
          path={AvailablePomRoutes.UserNew}
          exact
          component={() => <CreateUserPage platformType={AvailablePlatform.Pom} />}
        />

        {/*Agency*/}
        <AuthenticatedRoute
          allowedRoles={[GroupType.PomAgency]}
          path={AvailablePomRoutes.Agency}
          exact
          component={PomAgencyPage}
        />

        {/*Manufacturer*/}
        <AuthenticatedRoute
          allowedRoles={[GroupType.Admin, GroupType.PomRepresentative]}
          path={AvailablePomRoutes.ManufacturerCreate}
          exact
          component={PomManufacturerAddPage}
        />
        <AuthenticatedRoute
          allowedRoles={[GroupType.Admin, GroupType.PomRepresentative]}
          path={AvailablePomRoutes.ManufacturerDetail}
          exact
          component={() => <PomManufacturerDetailPage mode={ManufacturerFormMode.Detail} />}
        />
        <AuthenticatedRoute
          allowedRoles={[GroupType.Admin, GroupType.PomRepresentative]}
          path={AvailablePomRoutes.ManufacturerEdit}
          exact
          component={() => <PomManufacturerDetailPage mode={ManufacturerFormMode.Edit} />}
        />

        <Redirect path="*" to={AvailablePomRoutes.Portal} push />
      </Switch>
    </Suspense>
  );
};
