import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Colors } from "../../../style/Colors";

const logoWidth = 210;

const useStyles = makeStyles((theme: Theme) => ({
  platformContainer: {
    background: Colors.grayLight,
    cursor: "pointer",
    padding: 20,
    borderRadius: 4,
    border: "1px solid #ffffff",
  },
  textContainer: {
    width: `calc(100% - ${logoWidth}px)`,
  },
  subTitle: {
    color: Colors.red,
  },
  title: {},
  logo: {
    width: logoWidth,
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
  },
}));

interface IPlatformContainerTitleProps {
  handleSelection: () => void;
  Logo: FunctionComponent;
  title: string;
  subTitle?: string;
}

export const PlatformContainerTitle: React.VFC<IPlatformContainerTitleProps> = (props) => {
  const { handleSelection, Logo, title, subTitle } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.platformContainer} onClick={handleSelection}>
      <Grid item id="platform-logo" className={classes.logo}>
        <Logo />
      </Grid>
      <Grid
        xs={12}
        sm="auto"
        item
        id="platform-title"
        className={classes.textContainer}
        container
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
        </Grid>
        {subTitle && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.subTitle}>
              {subTitle}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
