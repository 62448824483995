import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ScrollToTopIcon } from "../../../../components/Primitives/Icons";
import { NewOrderToolTip } from "../../../../shared/components/tooltips/order-new-tooltip.component";
import { PrimaryColors } from "../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 20,
    width: 20,
  },
  iconContainer: {
    padding: 10,
    backgroundColor: PrimaryColors.base,
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      cursor: "pointer",
    },
    borderTopLeftRadius: "20px",
  },
}));

export const OrderScrollToTopButton: VFC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleScrollToTopClicked = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Grid container direction="row" justifyContent="flex-end">
      <Grid item>
        <NewOrderToolTip title={t("general.scrollToTop")}>
          <Box className={classes.iconContainer} onClick={handleScrollToTopClicked}>
            <ScrollToTopIcon classes={{ root: classes.icon }} />
          </Box>
        </NewOrderToolTip>
      </Grid>
    </Grid>
  );
};
