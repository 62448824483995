import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProductModel } from "../../../models/product/cfm-product.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmProduct } from "../../../../domain/products/cfm-product";
import { CfmProductConverter } from "../../../models/converter/cfm-product.converter";

export const getProduct = async (id: number | undefined): Promise<ICfmProduct | undefined> => {
  if (!id) return undefined;
  const url = replaceUriParameter(RestEndpoints.ProductById, "id", id);
  const response = await getInstance().get<ICfmProductModel>(url);
  return CfmProductConverter.toDomain(response.data);
};

export const useGetProductQuery = (id: number | undefined) => {
  return useQuery([CfmQueryKeys.GetProductById, id], () => getProduct(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(id),
  });
};
