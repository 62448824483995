import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { IFormattedGroupHistory } from "../../../../domain/group/group-history";
import { formatDateLong } from "../../../../util/date.util";
import {
  formatValue,
  getTranslatedInfoType,
  getUpdatedBy,
  translateColumnName,
} from "../../../../util/group-history.util";
import { AppDialog } from "../../../../components/dialog/app-dialog.component";
import { DetailValueDisplay } from "../../../../components/value-display/detail-value-display.component";

interface IGroupHistoryDetailDialogProps {
  history: IFormattedGroupHistory | undefined;
  onClose: VoidFunction;
}

export const GroupHistoryDetailDialog: VFC<IGroupHistoryDetailDialogProps> = (props) => {
  const { history, onClose } = props;
  const { t } = useTranslation();

  return (
    <AppDialog
      title={t("basedata.groups.historyTable.heading")}
      maxWidth="md"
      open={history !== undefined}
      onCancelClick={onClose}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={12} md={12}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.infoType.label")}
                value={history ? getTranslatedInfoType(history, t) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.id")}
                value={history?.changedTableEntryId?.toString() ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.columnName")}
                value={history?.columnName ? translateColumnName(history.columnName, history.informationType, t) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.oldValue")}
                value={
                  history?.columnName && history?.oldValue && history.informationType
                    ? formatValue(history.columnName, history.oldValue, history.informationType, t)
                    : "-"
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.newValue")}
                value={
                  history?.columnName && history?.newValue && history.informationType
                    ? formatValue(history.columnName, history.newValue, history.informationType, t)
                    : "-"
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.timeStamp")}
                value={history?.updatedAt ? formatDateLong(history.updatedAt) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.groups.historyTable.updatedBy")}
                value={history ? getUpdatedBy(history, t) : "-"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
