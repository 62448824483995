import { DefaultValues } from "react-hook-form";
import { replaceUriParameter } from "../../../../api/endpoints";
import { AvailableSharedRoutes } from "../../../../utils/constants";
import { IAddress } from "../../../domain/address/address";
import { IAddressLean } from "../../../domain/address/address-lean";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { PaymentInfoConverter } from "../../../domain/converter/payment-info.converter";
import { IGroup } from "../../../domain/group/group";
import { GroupType, pomGroupTypes } from "../../../domain/group/group-type";
import { IPaymentInfo } from "../../../domain/payment-info/payment-info";
import { ICreateGroupFormInputs } from "../create-group/create-group-form.component";
import { IEditGroupTypeFormInputs } from "../edit-group/dialog/edit-group-type-dialog.component";
import { isPomAgency, isPomMainContact, IUser } from "./../../../domain/user/user";
import { IGroupBaseDataFormInputs } from "./group-form-base-data-section";
import { IGroupBaseDataPaymentInfoFormInputs } from "./group-form-payment-info-section.component";

export const defaultValues: DefaultValues<ICreateGroupFormInputs> = {
  active: true,
  differentInvoiceAddress: false,
  hasAcceptedSepaMandate: false,
  grsManagedEar: false,
  isPomAgencyAnnouncer: true,
};

export const groupToInitValuesForEdit = (group: IGroup): IGroupBaseDataFormInputs => {
  const invoiceAddress = group.invoiceAddress;
  const groupAddress = group.groupAddress;
  const hasDifferentInvoice =
    invoiceAddress &&
    groupAddress &&
    (invoiceAddress.name !== groupAddress.name ||
      invoiceAddress.address !== groupAddress.address ||
      invoiceAddress.buildingNumber !== groupAddress.buildingNumber ||
      invoiceAddress.city !== groupAddress.city ||
      invoiceAddress.state !== groupAddress.state ||
      invoiceAddress.country !== groupAddress.country ||
      invoiceAddress.contactPersonName !== groupAddress.contactPersonName ||
      invoiceAddress.contactPersonEmail !== groupAddress.contactPersonEmail ||
      invoiceAddress.contactPersonTelephone !== groupAddress.contactPersonTelephone);
  return {
    active: group.active != null,
    grsManagedEar: group.grsManagedEar,
    name: group.name,
    erpNumber: group.erpNumber ?? "",
    vatId: group.vatId ?? "",
    tradeRegisterNumber: group.tradeRegisterNumber?.trim(),
    differentInvoiceAddress: hasDifferentInvoice ?? false,
    invoiceAddress: invoiceAddress ? AddressConverter.domainToLeanAddressDomain(invoiceAddress) : undefined,
    groupAddress: groupAddress ? AddressConverter.domainToLeanAddressDomain(groupAddress) : undefined,
    groupBranchId: group.groupBranch?.id ?? null,
  };
};

export const paymentInfoToInitValuesForEdit = (
  invoiceAddress: IAddressLean | IAddress | null,
  paymentInfo: IPaymentInfo | null | undefined,
  hasAcceptedSepaMandate: boolean,
): IGroupBaseDataPaymentInfoFormInputs => {
  let prefilledPaymentInfo = paymentInfo ? PaymentInfoConverter.toFormInput(paymentInfo) : undefined;

  if (prefilledPaymentInfo === undefined && invoiceAddress) {
    prefilledPaymentInfo = PaymentInfoConverter.toFormInputFromAddress(invoiceAddress);
  }
  return {
    hasAcceptedSepaMandate,
    paymentInfo: prefilledPaymentInfo,
  };
};

export const groupToGroupTypeDefaultValuesForEdit = (group: IGroup): IEditGroupTypeFormInputs => {
  return {
    type: group.type,
    agencyGroup: group.agencyGroup,
    earNumber: group.earNumber ?? "",
    earTemporaryNumber: group.earTemporaryNumber ?? "",
    isPomAgencyAnnouncer: group.isPomAgencyAnnouncer,
  };
};

export const showPomSection = (groupType: GroupType): boolean => {
  return pomGroupTypes.includes(groupType);
};

export const isAgencyOrMainContact = (internalUser: IUser | undefined) => {
  return (internalUser && isPomAgency(internalUser)) || isPomMainContact(internalUser);
};

export const getAnnouncementPeriodOfGroup = (group: IGroup | undefined, contractId: number | undefined) => {
  if (!group || !group.pomContracts || group.pomContracts.length === 0) return undefined;
  // Use contract for selected takebacksystem
  return (
    group.pomContracts.find((contract) => contract.id === contractId)?.contractField.pomAnnouncementPeriod ??
    group.pomContracts[0].contractField.pomAnnouncementPeriod
  );
};

export const hasMultiplePomContracts = (group: IGroup | undefined) => {
  if (!group || !group.pomContracts || group.pomContracts.length < 1) return false;
  if (group.pomContracts.length > 1) {
    const activePomContracts = group.pomContracts.filter((contract) => contract.active);
    return activePomContracts.length > 0;
  }
  return false;
};

export const getActivePomContractForSystem = (group: IGroup, contractId: number) => {
  const filteredContract = group.pomContracts?.find((contract) => contract.id === contractId && contract.active);
  return filteredContract;
};

export const getDetailUrl = (id: number, contactPersonEmailExists?: boolean) => {
  const url = replaceUriParameter(AvailableSharedRoutes.GroupDetail, "id", id);
  if (contactPersonEmailExists === undefined || contactPersonEmailExists === true) return url;

  return `${url}?createUserOnLoad=true`;
};
