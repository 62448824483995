import { AppError } from "./AppError";

export type TranslateFunc = (t: string, o?: any) => string;

export const translateAppError = (error: AppError, t: TranslateFunc, defaultMessage?: string, options?: any) => {
  switch (error) {
    case AppError.EMAIL_ALREADY_IN_USE:
      return t("api.error.email_already_in_use", options);
    case AppError.PRODUCT_ORDER_MISSING_BILLING_ADDRESS:
      return t("app.error.PRODUCT_ORDER_MISSING_WASTER_PRODUCER", options);
    case AppError.PRODUCT_ORDER_MISSING_PRODUCT:
      return t("app.error.PRODUCT_ORDER_MISSING_PRODUCT", options);
    case AppError.PRODUCT_ORDER_MISSING_WASTER_PRODUCER:
      return t("app.error.PRODUCT_ORDER_MISSING_WASTER_PRODUCER", options);
    case AppError.ROW_REFERENCED:
      return t("app.error.ROW_REFERENCED", options);
  }
};
