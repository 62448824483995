import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Countries } from "../../../../utils/Countries";
import { AddressType } from "../../../models/address/address.model";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormPhoneCountryField } from "../../form/fields/form-phone-country.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { IGroupBaseDataFormInputs } from "./group-form-base-data-section";

interface IAddressForGroupFormProps {
  addressType: AddressType.Invoice | AddressType.GroupLocation;
}

export const AddressForGroupForm: VFC<IAddressForGroupFormProps> = (props) => {
  const { addressType } = props;
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useFormContext<IGroupBaseDataFormInputs>();
  const baseName = useMemo(() => {
    return addressType === AddressType.Invoice ? "invoiceAddress" : "groupAddress";
  }, [addressType]);

  const addressErrors = useMemo(() => {
    return addressType === AddressType.Invoice ? errors.invoiceAddress : errors.groupAddress;
  }, [addressType, errors.invoiceAddress, errors.groupAddress]);

  useEffect(() => {
    //@ts-ignore
    setValue(`${baseName}.type`, addressType);
    setValue(`${baseName}.active`, new Date());
  }, [addressType, setValue, baseName]);

  return (
    <Grid container spacing={4}>
      <FormField>
        <FormTextField
          maxLength={50}
          hasError={Boolean(addressErrors?.name)}
          label={t("basedata.addresses.edit.name")}
          control={control}
          name={`${baseName}.name`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormTextField
          required={false}
          hasError={Boolean(addressErrors?.name2)}
          maxLength={50}
          label={t("basedata.addresses.edit.name2")}
          control={control}
          name={`${baseName}.name2`}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.address)}
          label={t("basedata.addresses.edit.address")}
          control={control}
          name={`${baseName}.address`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.buildingNumber)}
          label={t("basedata.addresses.edit.buildingNumber")}
          control={control}
          name={`${baseName}.buildingNumber`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.postal)}
          label={t("basedata.addresses.edit.postal")}
          control={control}
          name={`${baseName}.postal`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.city)}
          label={t("basedata.addresses.edit.city")}
          control={control}
          name={`${baseName}.city`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.state)}
          label={t("basedata.addresses.edit.state")}
          control={control}
          name={`${baseName}.state`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormAutocomplete<string>
          control={control}
          label={t("basedata.addresses.edit.country")}
          options={Countries.map((country) => country.code)}
          error={Boolean(addressErrors?.country)}
          rules={{ required: true }}
          getOptionLabel={(countryCode) => {
            const country = Countries.find((fullCountry) => fullCountry.code === countryCode);
            if (!country) return undefined;
            return t(country.translationKey);
          }}
          {...register(`${baseName}.country`, { required: true })}
        />
      </FormField>

      <FormField>
        <FormTextField
          required={false}
          hasError={Boolean(addressErrors?.addressText)}
          label={t("basedata.addresses.edit.addressText")}
          control={control}
          name={`${baseName}.addressText`}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.contactPersonName)}
          label={t("basedata.addresses.edit.contactPersonName")}
          control={control}
          name={`${baseName}.contactPersonName`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormTextField
          hasError={Boolean(addressErrors?.contactPersonEmail)}
          label={t("basedata.addresses.edit.contactPersonEmail")}
          control={control}
          name={`${baseName}.contactPersonEmail`}
          rules={{ required: true }}
        />
      </FormField>

      <FormField>
        <FormPhoneCountryField
          label={t("basedata.users.edit.phone")}
          control={control}
          hasError={Boolean(addressErrors?.contactPersonTelephone)}
          required={true}
          {...register(`${baseName}.contactPersonTelephone`, { required: true })}
        />
      </FormField>
    </Grid>
  );
};
