import { CfmProductConverter } from "./cfm-product.converter";
import { ICfmResponseFieldAssignment } from "../../../domain/assignment/cfm-response-field-assignment";
import { ICfmResponseFieldAssignmentModel } from "../response-assignment/cfm-response-field-assignment.model";
import { CfmServiceDateConverter } from "./order-x/cfm-service-date.converter";
import { ICfmCreateResponseValueX } from "../../../domain/order-x/actions/cfm-create-response-value-x";

export class CfmResponseFieldXConverter {
  public static toModel(responseField: ICfmResponseFieldAssignment): ICfmResponseFieldAssignmentModel {
    return {
      id: responseField.id,
      type: responseField.type,
      name: responseField.name,
      unit: responseField.unit,
      active: responseField.active,
      internalNumber: responseField.internalNumber,
      responseAssignmentResponseFieldId: responseField.responseAssignmentResponseFieldId,
      serviceDate: responseField.serviceDate ? CfmServiceDateConverter.toModel(responseField.serviceDate) : null,
      warehouseArticle: responseField.warehouseArticle
        ? CfmProductConverter.toModel(responseField.warehouseArticle)
        : null,
      billingType: responseField.billingType,
    };
  }

  public static toDomain(responseField: ICfmResponseFieldAssignmentModel): ICfmResponseFieldAssignment {
    return {
      id: responseField.id,
      type: responseField.type,
      name: responseField.name,
      unit: responseField.unit,
      active: responseField.active,
      internalNumber: responseField.internalNumber,
      responseAssignmentResponseFieldId: responseField.responseAssignmentResponseFieldId,
      serviceDate: responseField.serviceDate ? CfmServiceDateConverter.toDomain(responseField.serviceDate) : null,
      warehouseArticle: responseField.warehouseArticle
        ? CfmProductConverter.toDomain(responseField.warehouseArticle)
        : null,
      billingType: responseField.billingType,
    };
  }

  public static assignmentToCreateResponseValueX(
    assignment: ICfmResponseFieldAssignment,
    itemId: number,
    userId: number,
  ): ICfmCreateResponseValueX {
    return {
      responseAssignmentResponseFieldId: assignment.responseAssignmentResponseFieldId,
      itemId,
      userId,
      text: null,
      number: null,
      floatingPoint: null,
      date: null,
      files: null,
      warehouseProductId: assignment.warehouseArticle?.id ?? null,
    };
  }
}
