import { makeStyles } from "@material-ui/core";
import { Colors, Shades } from "./Colors";

export const useProductPackageDataDisplayStyle = makeStyles((theme) => ({
  iconDimensions: {
    height: 40,
    width: 40,
    marginRight: 20,
  },
  container: {
    height: "100%",
  },
  dataContainer: {
    border: "2px solid",
    borderColor: Shades.gray40,
    borderRadius: "0px 5px 5px 5px",
  },
  labelContainer: {
    backgroundColor: Shades.gray50,
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: "5px 5px 0px 0px",
    padding: "8px 20px 3px 20px",
    width: "fit-content",
  },
  label: {
    color: Colors.white,
  },
  name: {
    marginTop: 3,
  },
  packageName: {
    fontWeight: "bold",
  },
  productOrPackageContainer: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 25,
  },
}));
