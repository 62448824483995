import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Shades } from "../../../../../style/Colors";
import { useOrderCardStyle } from "../../../../../style/order-card.style";
import { OrderXTickets } from "./order-x-tickets";
import { useGetOrderXTickets } from "../../../../repositories/queries/order-x/queries/get-order-x-tickets.query";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";

const accordionMinHeight = 50;
const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    backgroundColor: Colors.white,
  },
}));

export const OrderXCardTickets: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const orderCardClasses = useOrderCardStyle();
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: tickets, isLoading: isTicketsLoading } = useGetOrderXTickets(order.id);
  const AccordionSummaryNoExpandIconRotate = withStyles({
    root: {
      minHeight: accordionMinHeight,
      backgroundColor: Colors.white,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        backgroundColor: Shades.gray50,
      },
    },
    expandIcon: {
      transform: "none !important",
    },
  })(AccordionSummary);

  // Underlying MUI component exposes this type
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  if (!isTicketsLoading && (!tickets || tickets.length < 1)) {
    return <></>;
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleExpandChange} elevation={0} square>
      <AccordionSummaryNoExpandIconRotate
        className={orderCardClasses.accordionSummaryHeightOnExpand}
        expandIcon={
          <ArrowForwardIosOutlined
            classes={{ root: isExpanded ? orderCardClasses.expandedIcon : orderCardClasses.expandIcon }}
          />
        }
      >
        <Typography variant="body1" className={classNames({ [orderCardClasses.cardHeaderTextExpanded]: isExpanded })}>
          {t("order.tickets.cardLabel")}
        </Typography>
      </AccordionSummaryNoExpandIconRotate>
      <AccordionDetails classes={{ root: classes.detailsContainer }}>
        {isTicketsLoading && <CircularProgress />}
        {!isTicketsLoading && tickets && <OrderXTickets tickets={tickets} />}
        {!isTicketsLoading && !tickets && <Typography>{t("order.noData")}</Typography>}
      </AccordionDetails>
    </Accordion>
  );
};
