import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { usePackageIconAndLabel } from "../../../collect-from-market/domain/hooks/use-package-icon-and-label";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { OrderCheckbox } from "../../../components/checkbox/order-checkbox.component";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  pkgName: {
    fontWeight: "bold",
  },
  container: {
    height: "100%",
  },
  checkBoxContainer: { marginLeft: 15 },
  iconWidth: {
    width: 40,
  },
}));

interface IPackageConfigRowProps {
  pkg: ICfmPackage;
  onSelect: (pkg: ICfmPackage, checked: boolean) => void;
  isSelected: boolean;
}

export const PackageConfigRow: VFC<IPackageConfigRowProps> = (props) => {
  const { pkg, onSelect, isSelected } = props;
  const classes = useStyles();
  const { getIcon } = usePackageIconAndLabel();

  return (
    <BoxedRowGrid>
      <Grid item>
        <Grid container direction="column" justifyContent="center" className={classes.container}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item className={classes.checkBoxContainer}>
                <OrderCheckbox onChange={(checked) => onSelect(pkg, checked)} checked={isSelected} size="large" />
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center" className={classNames(classes.container, classes.iconWidth)}>
                  {getIcon(pkg)}
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center" className={classes.container}>
                  <Typography variant="body1" className={classes.pkgName}>
                    {pkg.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BoxedRowGrid>
  );
};
