import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import classNames from "classnames";
import * as React from "react";
import { PrimaryColors, Shades, WarningColors } from "../../style/Colors";
import { CheckboxApproveIcon } from "./Icons";

const useStyles = makeStyles((theme: Theme) => ({
  sizeLarge: {
    padding: 25,
  },
  sizeMedium: {
    padding: 14,
  },
  textColor: {
    color: Shades.white,
  },
  backGroundColorSuccess: {
    backgroundColor: PrimaryColors.base,
  },
  backGroundColorError: {
    backgroundColor: WarningColors.base,
  },
}));

interface IDialogHeader {
  isSuccess: boolean;
  customIcon?: any;
  fontSize?: string;
  textAlign?: "center" | "start";
  size?: "large" | "medium";
  displayIcon?: boolean;
}

export const DialogHeader: React.FunctionComponent<IDialogHeader> = (props) => {
  const {
    children,
    isSuccess,
    customIcon,
    fontSize = "22px",
    textAlign = "center",
    size = "large",
    displayIcon = true,
  } = props;
  const classes = useStyles();

  return (
    <Box
      className={classNames(classes.textColor, {
        [classes.sizeLarge]: size === "large",
        [classes.sizeMedium]: size === "medium",
        [classes.backGroundColorSuccess]: isSuccess,
        [classes.backGroundColorError]: !isSuccess,
      })}
      style={{ backgroundColor: isSuccess ? PrimaryColors.base : WarningColors.base, color: Shades.white }}
    >
      <Grid container style={{ textAlign: textAlign }} spacing={2}>
        {displayIcon && (
          <Grid item xs={12}>
            {!isSuccess ? <Warning /> : customIcon ? customIcon : <CheckboxApproveIcon />}
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" style={{ fontSize: fontSize, fontWeight: "bold" }}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
