import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  getTranslatedAddressType,
  translateCountryByCode,
  trimOpeningHoursTextIfRequired,
} from "../../../../../utils/address.util";
import { IAddressLean } from "../../../../domain/address/address-lean";
import { getActiveStatusLabel } from "../../../../util/translation.util";
import { AppDialog } from "../../../../components/dialog/app-dialog.component";
import { GroupDetailStatusTag } from "../group-detail-status-tag.component";
import { DetailValueDisplay } from "../../../../components/value-display/detail-value-display.component";

interface IAddressDetailModalDialog {
  open: boolean;
  address: IAddressLean | undefined;
  onClose: VoidFunction;
  onEditClick: VoidFunction;
}

export const AddressDetailDialog: VFC<IAddressDetailModalDialog> = (props) => {
  const { open, address, onClose, onEditClick } = props;
  const { t } = useTranslation();

  return (
    <AppDialog
      title={t("basedata.addresses.groupDetailAddress.details")}
      maxWidth="md"
      open={open}
      onCancelClick={onClose}
      onAcceptClick={onEditClick}
      acceptTextOverride={t("general.edit.text")}
      alignButtons="space-between"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <GroupDetailStatusTag
                label={getActiveStatusLabel(address?.active, t)}
                type={address?.active == null ? "error" : "success"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.addresses.edit.id")} value={address?.id.toString() ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.addressType.type")}
                value={getTranslatedAddressType(address?.type, t)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.addresses.edit.name")} value={address?.name ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.name2")}
                value={address?.name2 ? address?.name2 : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.country")}
                value={address?.country ? translateCountryByCode(address.country, t) : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.addresses.edit.state")} value={address?.state ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.addresses.edit.address")} value={address?.address ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.buildingNumber")}
                value={address?.buildingNumber ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.addresses.edit.postal")} value={address?.postal ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay label={t("basedata.addresses.edit.city")} value={address?.city ?? "-"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.addressText")}
                value={address?.addressText ? address?.addressText : "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.contactPersonName")}
                value={address?.contactPersonName ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.contactPersonEmail")}
                value={address?.contactPersonEmail ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.contactPersonPhone")}
                value={address?.contactPersonTelephone ?? "-"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailValueDisplay
                label={t("basedata.addresses.edit.opening_hours")}
                value={address?.openingHours ? trimOpeningHoursTextIfRequired(address?.openingHours, 20) : "-"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
