import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AppDialog } from "../dialog/app-dialog.component";
import { IUserLoginAs } from "../../domain/user/user";

interface ILoginAsDialogProps {
  open: boolean;
  user: IUserLoginAs | undefined;
  onCancel: () => void;
}

export const LoginAsDialog = (props: ILoginAsDialogProps) => {
  const { open, onCancel, user } = props;
  const { t } = useTranslation();
  const { loginAs } = useAuthContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLoginAs = async () => {
    if (!user) return;
    setIsLoading(true);
    await loginAs(user.id);
    setIsLoading(false);
    onCancel();
  };

  return (
    <AppDialog
      displayStyle="success"
      open={open}
      isLoading={isLoading}
      onCancelClick={onCancel}
      onAcceptClick={handleLoginAs}
      acceptTextOverride={t("general.sign_in_to_account.text")}
      title={t("basedata.users.login.header")}
      maxWidth={"sm"}
      fullWidth={true}
      alignButtons="space-between"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">
              {t("basedata.users.login.text", {
                firstName: user?.firstName,
                lastName: user?.lastName,
              })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </AppDialog>
  );
};
