import { Divider, Grid, makeStyles } from "@material-ui/core";
import React, { VFC } from "react";
import { ICfmOrderXTicketsModel } from "../../../../repositories/models/order-x/cfm-order-x-tickets.model";
import { Shades } from "../../../../../style/Colors";
import { OrderXTicketsRow } from "./order-x-tickets-row";

const useStyles = makeStyles({
  divider: {
    marginTop: 7,
    backgroundColor: Shades.gray20,
  },
});

interface IOrderTicketsProps {
  tickets: ICfmOrderXTicketsModel[] | undefined;
}

export const OrderXTickets: VFC<IOrderTicketsProps> = (props) => {
  const { tickets } = props;
  const classes = useStyles();

  if (tickets && tickets.length < 1) {
    return <></>;
  }

  return (
    <Grid container className="pl-2 pr-2">
      {tickets &&
        tickets.map((entry, index) => (
          <Grid item xs={12} key={index}>
            <OrderXTicketsRow ticket={entry} />
            <Divider className={classes.divider} />
          </Grid>
        ))}
    </Grid>
  );
};
