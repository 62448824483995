import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateShort } from "../../../../../shared/util/date.util";
import { translateCountryByCode } from "../../../../../utils/address.util";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderDetailInfoBlock } from "../card/order-details-info-block.component";

export const OrderXDetailsLogistics: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const logisticAddress = useMemo(() => {
    return order.logisticAddress;
  }, [order.logisticAddress]);

  const addressText = useMemo(() => {
    const textData: string[] = [`(${logisticAddress?.id ?? ""}) ${logisticAddress?.name ?? ""}`];

    if (logisticAddress?.address && logisticAddress.buildingNumber) {
      textData.push(`${logisticAddress.address} ${logisticAddress.buildingNumber}`);
    }

    if (logisticAddress?.postal && logisticAddress.city) {
      textData.push(`${logisticAddress.postal} ${logisticAddress.city}`);
    }

    textData.push(logisticAddress?.country ? translateCountryByCode(logisticAddress.country, t) : "");
    return textData.filter((text) => text.length > 0).join(", ");
  }, [
    logisticAddress?.id,
    logisticAddress?.name,
    logisticAddress?.address,
    logisticAddress?.postal,
    logisticAddress?.city,
    logisticAddress?.buildingNumber,
    logisticAddress?.country,
    t,
  ]);

  const contactDataText = useMemo(() => {
    const data = [
      logisticAddress?.contactPersonName ?? "",
      logisticAddress?.contactPersonEmail ?? "",
      logisticAddress?.contactPersonTelephone ?? "",
    ];

    return data.filter((text) => text.length > 0).join(", ");
  }, [
    logisticAddress?.contactPersonName,
    logisticAddress?.contactPersonEmail,
    logisticAddress?.contactPersonTelephone,
  ]);

  return (
    <OrderDetailInfoBlock title={t("order.details.logistic.title")}>
      <Grid container direction="column">
        {logisticAddress && (
          <Grid item>
            <Typography variant="body1">{addressText}</Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">{contactDataText}</Typography>
        </Grid>
        {logisticAddress?.openingHours && (
          <Grid item>
            <Typography variant="body1">
              {t("order.details.openingHours", { openingHours: logisticAddress?.openingHours })}
            </Typography>
          </Grid>
        )}
        {order.plannedPickupDate && (
          <Grid item>
            <Typography variant="body1">
              {t("order.details.plannedPickupDate", { date: formatDateShort(order.plannedPickupDate) })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </OrderDetailInfoBlock>
  );
};
