import { Box, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { DangerousDeleteIconNoBorderButton } from "../../../components/Primitives/Buttons";
import { ArrowRightBig } from "../../../components/Primitives/Icons";

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: "bold",
  },
  forwardIcon: {
    transform: "scale(0.7)",
  },
  iconDimensions: {
    height: 40,
    width: 40,
  },
  label: {
    marginTop: 2,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  dividerContainer: {
    height: 22,
  },
  container: {
    height: "100%",
  },
}));

interface IPackageConfigDetailRowProps {
  label: string;
  onRemove: VoidFunction;
  bold: boolean;
  displayTopDivider: boolean;
  canRemove?: boolean;
}

export const PackageConfigDetailRow: VFC<IPackageConfigDetailRowProps> = (props) => {
  const { label, onRemove, bold, displayTopDivider, canRemove = true } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item className={classes.dividerContainer}>
        {displayTopDivider && <Divider className={classes.divider} />}
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Box display={"flex"} alignItems={"center"} className={classes.container}>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <ArrowRightBig className={classes.forwardIcon} />
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classNames(classes.label, { [classes.bold]: bold })}>
                    {label}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {canRemove && (
            <Grid item>
              <DangerousDeleteIconNoBorderButton className={classes.iconDimensions} onClick={onRemove} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
