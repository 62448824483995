import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "./Colors";

export const useInputStyles = makeStyles({
  warning: {
    "& .MuiInput-root": {
      "box-shadow": `0px 0px 0px 2px ${Colors.orange}`,
    },
  },
});
