import { makeStyles } from "@material-ui/core";
import { Colors } from "./Colors";

export const useAddressIconStyle = makeStyles((theme) => ({
  groupOrInvoiceAddressIcon: {
    height: 40,
    width: 28,
    color: Colors.gray,
  },
}));
