import { Button, ButtonGroup, makeStyles, Theme } from "@material-ui/core";
import React, { useState, VFC } from "react";
import classNames from "classnames";
import { Colors } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  segmentedButton: {
    height: 30,
    border: "2px solid",
    borderColor: Colors.greenLight,
    "&:hover": {
      backgroundColor: Colors.greenLight,
      color: Colors.white,
    },
  },
  active: {
    backgroundColor: Colors.greenDark,
    color: Colors.white,
    borderColor: Colors.greenDark,
    "&:hover": {
      borderColor: Colors.greenLight,
    },
  },
}));

export interface ISegmentedItem {
  label: string;
  onClick: VoidFunction;
}

interface ISegmentedControlProps {
  items: ISegmentedItem[];
}

export const SegmentedControl: VFC<ISegmentedControlProps> = (props) => {
  const classes = useStyles();
  const { items } = props;

  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0);

  const handleButtonClick = (index: number) => {
    setSelectedSegmentIndex(index);
    items[index].onClick();
  };

  return (
    <ButtonGroup disableElevation variant="contained">
      {items &&
        items.map((segment, index) => (
          <Button
            key={index}
            onClick={() => handleButtonClick(index)}
            className={classNames(classes.segmentedButton, {
              [classes.active]: selectedSegmentIndex === index,
            })}
          >
            {segment.label}
          </Button>
        ))}
    </ButtonGroup>
  );
};
