import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { ForwardButton } from "../../../components/Primitives/Buttons";
import { ConfigHeader } from "../header/config-header.component";
import { ICfmProductPackageConfig } from "./create-product-package-config-content.component";
import { CreateProductPackageConfigRow } from "./create-product-package-config-row.component";
import { RemovePackageConfigDialog } from "./dialog/remove-package-config-dialog.component";

const useStyles = makeStyles((theme: Theme) => ({
  headingContainer: {
    marginTop: 25,
    marginBottom: 10,
  },
  bottomRowContainer: {
    marginBottom: 30,
    marginTop: 20,
  },
}));

interface IRemoveExistingPackageData {
  product: ICfmProduct;
  package: ICfmPackage;
}

interface IFinishProductPackageConfigProps {
  selectedProductPackageConfigs: ICfmProductPackageConfig[];
  setSelectedProductPackageConfigs: (values: ICfmProductPackageConfig[]) => void;
  onSubmit: VoidFunction;
}

export const FinishProductPackageConfig: VFC<IFinishProductPackageConfigProps> = (props) => {
  const { selectedProductPackageConfigs, setSelectedProductPackageConfigs, onSubmit } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [removeExistingData, setRemoveExistingData] = useState<IRemoveExistingPackageData | undefined>();

  const onRemove = (product: ICfmProduct, pkg: ICfmPackage, isExistingPackage: boolean) => {
    if (isExistingPackage) {
      setRemoveExistingData({ product, package: pkg });
      return;
    } else {
      removePackage(product, pkg, false);
    }
  };

  const removePackage = (product: ICfmProduct, pkg: ICfmPackage, isExistingPackage: boolean) => {
    const untouchedConfigs = selectedProductPackageConfigs.filter((config) => config.product.id !== product.id);
    const existingConfig = selectedProductPackageConfigs.find((config) => config.product.id === product.id);
    if (!existingConfig) return;

    const newPackages = !isExistingPackage
      ? existingConfig.newPackages.filter((p) => p.id !== pkg.id)
      : existingConfig.newPackages;
    const existingPackages = isExistingPackage
      ? existingConfig.existingPackages.filter((p) => p.id !== pkg.id)
      : existingConfig.existingPackages;

    const newConfig: ICfmProductPackageConfig = {
      product,
      newPackages,
      existingPackages,
    };
    setSelectedProductPackageConfigs([...untouchedConfigs, newConfig]);
  };

  return (
    <>
      <RemovePackageConfigDialog
        open={removeExistingData !== undefined}
        onCancel={() => setRemoveExistingData(undefined)}
        onAccept={() => {
          if (!removeExistingData) return;
          removePackage(removeExistingData.product, removeExistingData.package, true);
          setRemoveExistingData(undefined);
        }}
        packageName={removeExistingData?.package.name ?? ""}
      />
      <Grid container direction="column">
        <Grid item className={classes.headingContainer}>
          <ConfigHeader label={t("configuration.finishPackageConfiguration")} />
        </Grid>
        {selectedProductPackageConfigs
          .sort((c1, c2) => c1.product.name.localeCompare(c2.product.name))
          .map((config) => {
            return <CreateProductPackageConfigRow config={config} onRemovePackage={onRemove} />;
          })}
        <Grid item className={classes.bottomRowContainer}>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <ForwardButton onClick={onSubmit}>
                <Typography variant="body1">{t("configuration.createProductPackageConfig.submit")}</Typography>
              </ForwardButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
