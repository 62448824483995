import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors, Shades } from "./Colors";
import { BORDER_RADIUS } from "./Constant";

export const useIconButtonsStyle = makeStyles((theme) => ({
  small: {
    padding: "4px",

    "&.Mui-disabled": {
      border: "none",
      background: Shades.gray30,
    },
  },
  normal: {
    padding: "8px",
  },
  green: {
    border: `2px solid ${PrimaryColors.base}`,

    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      border: `2px solid ${PrimaryColors.hover}`,

      "& .MuiSvgIcon-colorPrimary": {
        color: `${Shades.white} !important`,
      },
    },
  },
  red: {
    border: `2px solid ${Colors.red}`,

    "&:hover": {
      backgroundColor: Colors.red,
      border: `2px solid ${Colors.red}`,

      "& .MuiSvgIcon-colorPrimary": {
        color: `${Shades.white} !important`,
      },
    },
  },
  deleteIcon: {
    "& .MuiSvgIcon-root": {
      width: "unset",
      height: "unset",
    },
  },
  greenFill: {
    border: `2px solid transparent`,
    backgroundColor: PrimaryColors.base,
    "& .MuiSvgIcon-colorPrimary": {
      color: Shades.white,
    },
    "&:hover": {
      backgroundColor: PrimaryColors.base,
    },
  },
  white: {
    border: `2px solid ${Shades.white}`,

    "&:hover": {
      backgroundColor: Shades.white,

      "& .MuiSvgIcon-colorPrimary": {
        color: `${PrimaryColors.active} !important`,
      },
    },
  },
  expandIcon: {
    color: Colors.white,
    transform: "scale(0.7)",
  },
  active: {
    border: `2px solid ${PrimaryColors.active}`,
    backgroundColor: PrimaryColors.active,

    "&:hover": {
      border: `2px solid ${PrimaryColors.active}`,
      backgroundColor: PrimaryColors.active,
    },
  },
  animatedBorder: {
    borderRadius: BORDER_RADIUS,
    transition: "border-radius .5s",
    "&:hover": {
      borderRadius: "0.3rem",
    },
  },
  animatedBorderActive: {
    borderRadius: "0.3rem",
    backgroundColor: PrimaryColors.active,
    "&:hover": {
      backgroundColor: PrimaryColors.active,
    },
  },
}));
