import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState, VFC } from "react";
import { useHistory } from "react-router-dom";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { ICfmProductRoutingAssignment } from "../../../collect-from-market/domain/routing-assignment/cfm-product-routing-assignment";
import { getRedirectToCreateRoutingUrl } from "../../../components/assignment-routing/assignment-products.util";
import { RoutingConfigSmallIcon } from "../../../components/Primitives/Icons";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { useProductRowStyle } from "../../../style/product-row.style";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import { ArticleNumberBox } from "../product/article-number-box.component";
import { ProductName } from "../product/product-name.component";
import { getProductArticleNumberAndName } from "../product/product.utils";
import { ProductRoutingDialog } from "./product-routing-dialog.component";

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
    marginTop: 5,
  },
  boldText: {
    fontWeight: "bold",
    marginTop: 3,
  },
  routingIcon: {
    marginRight: 20,
  },
  noRoutingIcon: {
    color: Colors.red,
  },
  hasRoutingIcon: {
    color: PrimaryColors.base,
  },
  postalContainer: {
    minWidth: 85,
    minHeight: 40,
  },
}));

interface IProductRoutingRowProps {
  product: ICfmProduct;
  assignment: ICfmProductRoutingAssignment | undefined;
}

export const ProductRoutingRow: VFC<IProductRoutingRowProps> = (props) => {
  const { product, assignment } = props;
  const classes = useStyles();
  const history = useHistory();
  const productRowClasses = useProductRowStyle();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onEditClicked = () => {
    const url = getRedirectToCreateRoutingUrl([product.id]);
    history.push(url);
  };

  const onIconClick = () => {
    if (assignment) {
      setIsDialogOpen(true);
    } else {
      onEditClicked();
    }
  };

  return (
    <>
      <ProductRoutingDialog
        open={isDialogOpen}
        productName={getProductArticleNumberAndName(product)}
        productId={product.id}
        assignments={assignment ? [assignment] : []}
        onClose={() => setIsDialogOpen(false)}
        onEdit={onEditClicked}
      />
      <BoxedRowGrid justifyContent="space-between">
        <Grid item>
          <Grid container direction="column" justifyContent="center" className={productRowClasses.container}>
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.postalContainer}>
                  <Grid item className={productRowClasses.container}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      className={productRowClasses.container}
                    >
                      <Typography variant="body1" className={classes.boldText}>
                        {assignment?.postal ?? ""}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item>
                  <ArticleNumberBox articleNumber={product.articleNumber} />
                </Grid>
                <Grid item className={productRowClasses.textContainer}>
                  <ProductName name={product.name} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" justifyContent="center" className={productRowClasses.container}>
            <Grid item className={classes.routingIcon}>
              <RoutingConfigSmallIcon
                viewBox="0 0 25 25"
                className={classNames(classes.iconStyle, {
                  [classes.noRoutingIcon]: !assignment,
                  [classes.hasRoutingIcon]: assignment,
                })}
                onClick={onIconClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </BoxedRowGrid>
    </>
  );
};
