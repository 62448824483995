import { createTheme } from "@material-ui/core";
import { common } from "@material-ui/core/colors";
import { Colors, PrimaryColors } from "../../style/Colors";
import { BACKGROUND, FONT_FAMILY, INHERIT_DEFAULT1, INHERIT_DEFAULT2, SECOND, SECONDARY } from "./overrides/constant";
import { MuiAccordionDetails } from "./overrides/mui-accordion-details";
import { MuiAccordionSummary } from "./overrides/mui-accordion-summary";
import { MuiBadge } from "./overrides/mui-badge";
import { MuiButton } from "./overrides/mui-button";
import { MuiButtonBase } from "./overrides/mui-button-base";
import { MuiCardContent } from "./overrides/mui-card";
import { MuiChip } from "./overrides/mui-chip";
import { MuiDialog } from "./overrides/mui-dialog";
import { MuiDialogContent } from "./overrides/mui-dialog-content";
import { MuiDivider } from "./overrides/mui-divider";
import { MuiFormHelperText } from "./overrides/mui-form-helper-text";
import { MuiIconButton } from "./overrides/mui-icon-button";
import { MuiInput } from "./overrides/mui-input";
import { MuiInputBase } from "./overrides/mui-input-base";
import { MuiInputLabel } from "./overrides/mui-input-label";
import { MuiOutlinedInput } from "./overrides/mui-outlined-input";
import { MuiPaper } from "./overrides/mui-paper";
import { MuiStep } from "./overrides/mui-step";
import { MuiStepper } from "./overrides/mui-stepper";
import { MuiSvgIcon } from "./overrides/mui-svg-icon";
import { MuiTableCell } from "./overrides/mui-table-cell";
import { MuiTablePagination } from "./overrides/mui-table-pagination";
import { MuiTableRow } from "./overrides/mui-table-row";
import { MuiTypography } from "./overrides/mui-typography";

// convert to ts file

export const appBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const MuiTheme = createTheme({
  breakpoints: {
    values: appBreakpoints,
  },
  typography: {
    fontFamily: FONT_FAMILY,
  },
  palette: {
    background: {
      default: BACKGROUND,
      contrastText: common.black,
    },
    primary: {
      main: PrimaryColors.base,
      contrastText: common.white,
    },
    grey: {
      300: INHERIT_DEFAULT1,
      A100: INHERIT_DEFAULT2,
    },
    secondary: {
      main: SECONDARY,
      contrastText: common.white,
    },
    error: {
      main: Colors.red,
    },
    success: {
      main: Colors.accentGreenLight,
    },
    warning: {
      main: Colors.orange,
    },
    helpers: {
      primary: Colors.blue,
      main: "rgba(25, 46, 91, .035)",
    },
  },
  shape: {
    borderRadius: "0.5rem",
  },
  overrides: {
    MuiDivider: MuiDivider,
    MuiButton: MuiButton,
    MuiIconButton: MuiIconButton,
    MuiBadge: MuiBadge,
    MuiChip: MuiChip,
    MuiInput: MuiInput,
    MuiInputBase: MuiInputBase,
    MuiInputLabel: MuiInputLabel,
    MuiFormHelperText: MuiFormHelperText,
    MuiSvgIcon: MuiSvgIcon,
    MuiStep: MuiStep,
    MuiStepper: MuiStepper,
    MuiOutlinedInput: MuiOutlinedInput,
    MuiTypography: MuiTypography,
    MuiAccordionSummary: MuiAccordionSummary,
    MuiAccordionDetails: MuiAccordionDetails,
    MuiTableRow: MuiTableRow,
    MuiTableCell: MuiTableCell,
    MuiPaper: MuiPaper,
    MuiDialog: MuiDialog,
    MuiDialogContent: MuiDialogContent,
    MuiCardContent: MuiCardContent,
    MuiButtonBase: MuiButtonBase,
    MuiTablePagination: MuiTablePagination,
    MuiTooltip: {
      tooltip: {
        backgroundColor: SECOND,
        padding: "8px 16px",
        fontSize: "13px",
      },
      arrow: {
        color: SECOND,
      },
    },
    MuiFormControl: {
      root: {
        "&.square": {
          "& > .MuiInput-root": {
            borderRadius: ".25em",
          },
        },
      },
    },
  },
});

export default MuiTheme;
