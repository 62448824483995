import { Badge, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AddButton, CsvExportIcon, SmallShoppingCartButton } from "../../../../../components/Primitives";
import { BORDER_RADIUS } from "../../../../../config/theme/overrides/constant";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { useCart } from "../../../../../providers/Cart/cart-context";
import { isAdmin, isCfmLogistics, isCfmRecycler } from "../../../../../shared/domain/user/user";
import { formatDateYearMonthDay } from "../../../../../shared/util/date.util";
import { downloadFile } from "../../../../../shared/util/download.util";
import { Colors, PrimaryColors } from "../../../../../style/Colors";
import { AvailableCfmRoutes } from "../../../../../utils/constants";
import { CfmOrderXStatusConverter } from "../../../../repositories/models/converter/order-x/cfm-order-x-status.converter";
import { useGetOrdersExportByFilterQuery } from "../../../../repositories/queries/order-x/queries/get-orders-export-by-filter.query";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";

const useStyles = makeStyles((theme) => ({
  whiteIcon: {
    color: Colors.white,
  },
  buttonContainer: {
    paddingTop: 0,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  newOrderButton: {
    borderRadius: `${BORDER_RADIUS} 0rem 0rem ${BORDER_RADIUS}`,
    marginRight: 3,
  },
  exportButton: {
    width: 40,
    height: 40,
    backgroundColor: PrimaryColors.base,
    borderRadius: 0,
    marginRight: 3,
  },
  lastButtonRadius: {
    borderRadius: `0rem ${BORDER_RADIUS} ${BORDER_RADIUS} 0rem`,
    marginRight: 0,
  },
}));

export const OrderXOverviewHeaderButtons: React.FC = (props) => {
  const classes = useStyles();
  const { internalUser } = useAuthContext();
  const { isMobile, isTablet } = useScreenSize();
  const history = useHistory();
  const { t } = useTranslation();
  const { displayExportOrderButton, curFilter } = useOrderXOverviewContext();
  const { cartCountOrderX } = useCart();
  const {
    refetch: exportOrders,
    data: orderExportData,
    isLoading: isOrderExportLoading,
    isRefetching: isOrderExportRefetching,
  } = useGetOrdersExportByFilterQuery(CfmOrderXStatusConverter.statusWithAllToModel(curFilter.statusFilter), false);

  const exportDisabled = useMemo(
    () => isOrderExportLoading || isOrderExportRefetching,
    [isOrderExportLoading, isOrderExportRefetching],
  );

  const displayNewOrderButton = useMemo(
    () => !isAdmin(internalUser) && !isCfmRecycler(internalUser) && !isCfmLogistics(internalUser),
    [internalUser],
  );
  const displayExportButton = useMemo(() => displayExportOrderButton(), [displayExportOrderButton]);
  const displayShoppingCartButton = useMemo(
    () => (isMobile || isTablet) && displayNewOrderButton,
    [isMobile, isTablet, displayNewOrderButton],
  );
  const displayOnlyExportButton = useMemo(
    () => displayExportButton && !displayShoppingCartButton && !displayNewOrderButton,
    [displayExportButton, displayShoppingCartButton, displayNewOrderButton],
  );

  const handleExportOrdersClick = async () => {
    if (exportDisabled) return;
    await exportOrders();
  };

  const handleNewOrderClick = useCallback(async () => {
    const url = isAdmin(internalUser) ? AvailableCfmRoutes.OrderXAdminNew : AvailableCfmRoutes.OrderXNew;
    history.push(url);
  }, [history, internalUser]);

  useEffect(() => {
    if (orderExportData) {
      const filename = `order-export-${formatDateYearMonthDay(new Date())}.xlsx`;
      downloadFile(orderExportData, filename);
    }
  }, [orderExportData]);

  if (displayOnlyExportButton) {
    return (
      <Button color="primary" variant="contained" disabled={exportDisabled} onClick={() => handleExportOrdersClick()}>
        <CsvExportIcon className={classes.whiteIcon} />
      </Button>
    );
  }

  return (
    <Grid item className={classes.buttonContainer}>
      <Grid container direction="row">
        <Grid item>
          <AddButton
            onClick={handleNewOrderClick}
            className={classNames({ [classes.newOrderButton]: displayShoppingCartButton || displayExportButton })}
          >
            <Typography variant="body1">{t("orders.new.text")}</Typography>
          </AddButton>
        </Grid>
        {displayExportButton && (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={exportDisabled}
              onClick={() => handleExportOrdersClick()}
              className={classNames(classes.exportButton, { [classes.lastButtonRadius]: !displayShoppingCartButton })}
            >
              <CsvExportIcon className={classes.whiteIcon} />
            </Button>
          </Grid>
        )}
        {displayShoppingCartButton && (
          <Grid item>
            <SmallShoppingCartButton
              className={classes.lastButtonRadius}
              onClick={() => history.push(AvailableCfmRoutes.CartX)}
            >
              <Badge className="bg-orange" color="secondary" badgeContent={cartCountOrderX} showZero />
            </SmallShoppingCartButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
