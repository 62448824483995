import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";
import { BORDER_RADIUS } from "../../../style/Constant";

export const useTableActionIconStyle = makeStyles((theme) => ({
  small: {
    width: "40px",
    height: "40px",
    aspectRatio: "1",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    border: `2px solid transparent`,
  },
  disabled: {
    backgroundColor: Shades.gray30,
    "& .MuiSvgIcon-colorPrimary": {
      color: Colors.white,
    },
  },
  greenFill: {
    backgroundColor: PrimaryColors.base,
    "& .MuiSvgIcon-colorPrimary": {
      color: Shades.white,
    },
    "&:hover": {
      backgroundColor: PrimaryColors.base,
    },
  },
  animatedBorder: {
    borderRadius: BORDER_RADIUS,
    transition: "border-radius .5s",
    "&:hover": {
      borderRadius: "0.3rem",
    },
  },
  animatedBorderActive: {
    borderRadius: "0.3rem",
    backgroundColor: PrimaryColors.active,
    "&:hover": {
      backgroundColor: PrimaryColors.active,
    },
  },
}));
