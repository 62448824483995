import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  getProductNameWithoutCategory,
  IProductStateWithoutCategory,
  productsToStateWithoutCategory,
} from "../../../../../../configurator/components/product/product.utils";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { useAutocompleteStyles } from "../../../../../../style/autocomplete.style";
import { getFormattedDomainAddress } from "../../../../../../utils/address.util";
import { useOrderTruckloadAnnouncedContext } from "../../../order-truckload-announced.provider";
import { TruckloadState } from "../../../../../domain/truckload/truckload-state";
import { useGetWarehouseProducts } from "collect-from-market/repositories/queries/product/query/get-warehouse-products.query";
import { CfmOrderXApiStatus } from "collect-from-market/domain/order-x/cfm-order-x.status";
import { useGetTruckloadAvailableStatusQuery } from "collect-from-market/repositories/queries/truckload/queries/get-truckload-availalbe-status.query";
import { removeNullAndUndefined } from "utils/filter.util";

export const TruckloadAnnouncedSelectFilter: VFC = () => {
  const { t } = useTranslation();
  const autoCompleteClasses = useAutocompleteStyles();
  const { addresses, areAddressesLoading, truckloadAnnouncedFilter, setTruckloadAnnouncedFilter } =
    useOrderTruckloadAnnouncedContext();

  const { isLoading: areProductsLoading, data: products } = useGetWarehouseProducts(
    truckloadAnnouncedFilter.logisticAddressId,
    [CfmOrderXApiStatus.LogisticTruckLoadAnnounced],
  );

  const productsWithoutCategory: IProductStateWithoutCategory[] = useMemo(() => {
    return productsToStateWithoutCategory(products ?? []);
  }, [products]);

  const { isLoading: areAvailableStatusLoading, data: availableStatus } = useGetTruckloadAvailableStatusQuery({
    startAddressId: truckloadAnnouncedFilter.logisticAddressId,
    fractionId: truckloadAnnouncedFilter.fractionId,
    stateId: truckloadAnnouncedFilter.stateId,
    typeId: truckloadAnnouncedFilter.typeId,
  });

  const truckloadStateOptions: TruckloadState[] = useMemo(() => {
    return (
      availableStatus?.items
        .map<TruckloadState | undefined>((item) => {
          switch (item) {
            case "draft":
              return TruckloadState.Draft;
            case "submitted":
              return TruckloadState.Submitted;
            case "confirmed":
              return TruckloadState.Confirmed;
            default:
              return undefined;
          }
        })
        .filter(removeNullAndUndefined) ?? []
    );
  }, [availableStatus]);

  const getAddressOptionLabel = (option: IAddressLean) => {
    return getFormattedDomainAddress(option, t);
  };

  const getProductOptionLabel = (option: IProductStateWithoutCategory) => {
    return getProductNameWithoutCategory(option.fraction, option.type, option.state);
  };

  const getStatusLabel = (option: TruckloadState) => t(`orders.truckloadAnnounced.${option}`);

  const handleAddressChange = (address: IAddressLean | null) => {
    setTruckloadAnnouncedFilter({ ...truckloadAnnouncedFilter, logisticAddressId: address?.id });
  };

  const handleOptionChange = (value: IProductStateWithoutCategory | null) => {
    setTruckloadAnnouncedFilter({
      ...truckloadAnnouncedFilter,
      fractionId: value?.fraction.id ?? undefined,
      typeId: value?.type.id ?? undefined,
      stateId: value?.state.id ?? undefined,
    });
  };

  const handleStateChange = (state: TruckloadState | null) => {
    setTruckloadAnnouncedFilter({ ...truckloadAnnouncedFilter, state: state ?? undefined });
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} md={4}>
        <Autocomplete
          options={addresses}
          classes={{
            endAdornment: autoCompleteClasses.adornment,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={autoCompleteClasses.input}
              placeholder={t("orders.truckloadAnnounced.filter.logisticAddress")}
            />
          )}
          loading={areAddressesLoading}
          loadingText={t("general.loading")}
          noOptionsText={t("general.autocomplete.noOptions")}
          getOptionLabel={getAddressOptionLabel}
          onChange={(event, value) => handleAddressChange(value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          options={productsWithoutCategory}
          classes={{
            endAdornment: autoCompleteClasses.adornment,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={autoCompleteClasses.input}
              placeholder={t("orders.truckloadAnnounced.filter.product")}
            />
          )}
          loading={areProductsLoading}
          loadingText={t("general.loading")}
          noOptionsText={t("general.autocomplete.noOptions")}
          getOptionLabel={getProductOptionLabel}
          onChange={(event, value) => handleOptionChange(value)}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          options={truckloadStateOptions}
          classes={{
            endAdornment: autoCompleteClasses.adornment,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={autoCompleteClasses.input}
              placeholder={t("orders.truckloadAnnounced.filter.orderStatus")}
            />
          )}
          loading={areAvailableStatusLoading}
          loadingText={t("general.loading")}
          noOptionsText={t("general.autocomplete.noOptions")}
          getOptionLabel={getStatusLabel}
          value={truckloadAnnouncedFilter.state}
          onChange={(event, value) => handleStateChange(value)}
        />
      </Grid>
    </Grid>
  );
};
