import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { useProductRowStyle } from "../../../style/product-row.style";

interface IProductNameProps {
  name: string;
  disabled?: boolean;
  noWrap?: boolean;
  isActive?: boolean;
}

export const ProductName: VFC<IProductNameProps> = (props) => {
  const { name, disabled, noWrap, isActive = true } = props;
  const classes = useProductRowStyle();
  return (
    <Typography
      variant="body1"
      noWrap={noWrap}
      className={classNames({ [classes.alreadyExistingText]: disabled, [classes.inactiveProduct]: !isActive })}
    >
      {name}
    </Typography>
  );
};
