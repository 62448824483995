import { createContext, ReactNode, useContext, useState } from "react";
import { IGetGroupsFilter } from "../../shared/repositories/models/group/get-group.model";

interface IGroupsFilterProps {
  children: ReactNode;
}

interface IGroupsFilterContext {
  filter: IGetGroupsFilter;
  setFilter: (filters: IGetGroupsFilter) => void;
}

export const GroupsFilterContext = createContext<IGroupsFilterContext>({} as IGroupsFilterContext);

export const useGroupsFilterContext = () => {
  return useContext(GroupsFilterContext);
};

const useGroupsFilterProvider = (props: IGroupsFilterProps): IGroupsFilterContext => {
  const [filters, setFilters] = useState<IGetGroupsFilter>({
    active: undefined,
    groupTypes: [],
    contractTypes: undefined,
    searchQuery: undefined,
  });

  return {
    filter: filters,
    setFilter: setFilters,
  };
};

export const GroupsFilterProvider = (props: IGroupsFilterProps) => {
  const value = useGroupsFilterProvider(props);
  return <GroupsFilterContext.Provider value={value}>{props.children}</GroupsFilterContext.Provider>;
};
