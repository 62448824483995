import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { LoginButton } from "components/Primitives";
import { EMAIL, PASSWORD, useLogin } from "hooks/useLogin";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useLoginStyle } from "style/login.style";
import { API_ERROR } from "../../api/api-response";
import { IUser } from "../../shared/domain/user/user";
import { LoginOperationResult } from "../../utils/auth";
import { AvailableSharedRoutes } from "../../utils/constants";

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const classes = useLoginStyle();
  const [auth, setValue, handleLogin] = useLogin();
  const history = useHistory();
  const [error, setError] = useState<LoginOperationResult>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const location = useLocation();

  const login = async () => {
    setIsProcessing(true);
    const { result, user } = await handleLogin();

    if (result !== LoginOperationResult.Success && result !== LoginOperationResult.UserLocked) {
      setError(result);
      setIsProcessing(false);
      return;
    }

    if (result === LoginOperationResult.UserLocked) {
      history.push(`${AvailableSharedRoutes.LockedOut}?email=${auth.email}`);
      return;
    }
    if (user?.mustResetPassword) {
      history.push(AvailableSharedRoutes.ResetPassword);
      return;
    }

    handleRedirectForUser(user);
    setIsProcessing(false);
  };

  const handleRedirectForUser = (user: IUser | undefined) => {
    const state: any = location.state;
    const next = state?.next;

    if (!user) {
      history.push(AvailableSharedRoutes.Login, { next });
      return;
    }

    history.push(AvailableSharedRoutes.Portal);
  };

  const getErrorMessageOfLoginOperationResult = (type: LoginOperationResult) => {
    switch (type) {
      case LoginOperationResult.WrongLoginData:
        return t("error.login.wrong_credentials");
      case LoginOperationResult.NoContracts:
        return t("error.login.no_active_contracts");
      case LoginOperationResult.TooManyRequest:
        return t("error.login.too_many_requests");
      default:
        return t("general.error_occurred", { errorCode: API_ERROR, errorMsg: "Unknown error while trying to login" });
    }
  };

  const onEnter = (ev: any) => {
    if (ev.key === "Enter") {
      login();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography color="primary" variant="h1">
          {t("general.login_title.text")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Box my={2}>
              <TextField
                value={auth.email}
                onChange={(e) => setValue(e.currentTarget.value, EMAIL)}
                className="w-100"
                InputLabelProps={{ shrink: true }}
                label={t("general.email.text")}
                id={EMAIL}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <TextField
                value={auth.password}
                className="w-100"
                onChange={(e) => setValue(e.currentTarget.value, PASSWORD)}
                InputLabelProps={{ shrink: true }}
                label={t("general.password.text")}
                type="password"
                onKeyPress={onEnter}
                id={PASSWORD}
              />
            </Box>
          </Grid>
          {!!error && (
            <Typography variant="body1" color="error" id="loginError" style={{ marginTop: 3 }}>
              {getErrorMessageOfLoginOperationResult(error)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Grid container direction="row-reverse" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item className={classes.mobileWidth}>
              <LoginButton onClick={login} disabled={isProcessing} id="loginButton" className={classes.mobileWidth}>
                {t("general.login_button.text")}
              </LoginButton>
            </Grid>
            <Grid item className={classes.mobileWidth}>
              <Link className={classes.link} to={AvailableSharedRoutes.LockedOut}>
                {t("general.forgot_password.text")}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
