/**
 * Used to format a given number in the correct locale (. vs ,)
 * @param value the number that should be formatted
 * @param fractionDigits the amount of fractional digits
 */
export const formatNumber = (value: number | undefined, fractionDigits: number): string | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }

  if (isNaN(value)) {
    return undefined;
  }

  return Number(value).toLocaleString("de-DE", {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
};
