import { Grid, makeStyles, Typography } from "@material-ui/core";
import { CloudUploadOutlined } from "@material-ui/icons";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { PrimaryColors } from "../../../style/Colors";

const useStyles = makeStyles(() => ({
  dropzone: {
    width: "100%",
    minHeight: 120,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 1,
  borderColor: "black",
  borderStyle: "dashed",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
  height: "100%",
};

const acceptStyle = {
  borderColor: PrimaryColors.base,
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface ICustomDropzoneProps {
  handleFilesChanged: (files: File[]) => void;
  maxFiles: number;
}

export const CustomDropzone: FunctionComponent<ICustomDropzoneProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleFilesChanged, maxFiles } = props;
  const { getRootProps, getInputProps, acceptedFiles, isDragAccept, isDragReject } = useDropzone({
    accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] },
    maxFiles,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (acceptedFiles.length) {
      handleFilesChanged(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <div className={classes.dropzone}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Typography variant="body1">{t("pom.announcements.template.upload.drop_here")}</Typography>
          </Grid>
          <Grid item>
            <CloudUploadOutlined />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
