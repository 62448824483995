import { Box, CardContent, CircularProgress, Grid } from "@material-ui/core";
import classNames from "classnames";
import { FC, useMemo } from "react";
import { ProductIcon, ProductMobileIcon, SingleOrderIcon } from "../../../../../components/Primitives";
import { CfmProductArticleType } from "../../../../../configurator/components/product/product.utils";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { OrderXCardInformation } from "../card-information/order-x-card-information.component";
import { OrderCardInformationDetails } from "../card/order-card-information-details.component";
import { useOrderContentStyles } from "./order-x-overview-card-content.styles";

export interface IOrderXOverviewCardContentProps {
  isLoading: boolean;
  isSelected: boolean;
  order: ICfmOrderX;
}

export const OrderXOverviewCardContent: FC<IOrderXOverviewCardContentProps> = (props) => {
  const { isLoading, isSelected, order, children } = props;
  const classes = useOrderContentStyles();
  const { isMobile } = useScreenSize();

  const hasProduct = useMemo(() => {
    return order.items.find((item) => item.product)?.product?.articleType === CfmProductArticleType.Product;
  }, [order]);

  const productIcon = useMemo(() => {
    return !isMobile ? (
      <ProductIcon classes={{ root: classes.iconSizeProduct }} />
    ) : (
      <ProductMobileIcon classes={{ root: classes.iconSizeProductMobile }} />
    );
  }, [isMobile, classes.iconSizeProduct, classes.iconSizeProductMobile]);

  const singleOrderIcon = useMemo(() => {
    return <SingleOrderIcon className={classes.iconSizeAddon} />;
  }, [classes.iconSizeAddon]);

  const icon = useMemo(() => (hasProduct ? productIcon : singleOrderIcon), [productIcon, singleOrderIcon, hasProduct]);

  return (
    <CardContent
      classes={{ root: classes.cardContent }}
      className={classNames({
        [classes.cardContentSelected]: isSelected && isMobile,
        [classes.cardContentNotSelected]: !isSelected,
      })}
    >
      <Grid container direction="row" spacing={0}>
        <Grid
          item
          xs={isMobile ? 12 : undefined}
          className={classes.cardOrderInformationIcon}
          container
          justifyContent="center"
          direction="column"
        >
          <Grid item>{icon}</Grid>
        </Grid>
        <Grid item xs={isMobile ? 12 : 10}>
          <OrderXCardInformation items={order.items} isSelected={isSelected} />
          {order && isSelected && (
            <Box style={{ paddingTop: "4px" }}>
              <OrderCardInformationDetails order={order} />
            </Box>
          )}
        </Grid>
      </Grid>
      {!isLoading && children}
      {isLoading && (
        <Grid container direction="row">
          <Grid item xs={12} className={classes.rowCenter}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </CardContent>
  );
};
