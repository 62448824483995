import { DialogContent, LinearProgress } from "@material-ui/core";
import React from "react";

export const DialogLoadingContainer = () => {
  return (
    <DialogContent style={{ padding: "20px" }}>
      <LinearProgress />
    </DialogContent>
  );
};
