import axios, { AxiosResponse } from "axios";

export interface IApiResponseSuccess<T> {
  data: T;
}

export interface IApiResponseFailure {
  message: string;
  errorCode?: string;
  error?: any;
  errorData?: any;
  statusCode?: number;
}

const apiErrorNoResponseStatusCode = 503;
export const API_ERROR = 500;

export function isSuccess<T>(response: ApiResponse<T>): response is IApiResponseSuccess<T> {
  return "data" in response;
}

export function isFailure<T>(response: ApiResponse<T>): response is IApiResponseFailure {
  return "message" in response && "errorCode" in response;
}

export function success<T>(response: T): ApiResponse<T> {
  return {
    data: response,
  };
}

/**
 * Checks the status code of the axios response and converts it to the correct ApiResponse
 * @deprecated
 */
export function parseNetworkResponse<T>(response: AxiosResponse<T>): ApiResponse<T> {
  if (response.status >= 200 && response.status < 300) {
    return success(response.data);
  }

  return {
    message: `Response code ${response.status} does not indicate success`,
    statusCode: response.status,
  };
}

export function parseNetworkError(error: any): IApiResponseFailure {
  if (axios.isAxiosError(error)) {
    return {
      statusCode: error.response?.status,
      errorCode: error.response?.data.errorCode,
      message: error.response?.data.message,
      errorData: error.response?.data.errorData,
    };
  } else {
    return {
      message: "Something went wrong",
      error: error,
    };
  }
}

export function getErrorStatusCode(error: any): number {
  const parsedError = parseNetworkError(error);
  return parsedError.statusCode ?? apiErrorNoResponseStatusCode;
}

export function getErrorCode(error: any): string {
  const parsedError = parseNetworkError(error);
  // do not use real error message, because we often log parameters, which is a lot of text
  return parsedError.errorCode ?? "no error provided";
}

export type ApiResponse<T> = IApiResponseSuccess<T> | IApiResponseFailure;
