import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../../models/converter/order-x/cfm-order-x.converter";
import { ICfmOrderXModel } from "../../../models/order-x/cfm-order-x.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getOrderX = async (orderId: number | undefined): Promise<ICfmOrderX> => {
  const { data } = await getInstance().get<ICfmOrderXModel>(
    RestEndpoints.CfmOrderX.replace(":id", orderId ? orderId.toString() : ""),
  );
  return CfmOrderXConverter.toDomain(data);
};

export const useGetOrderXQuery = (orderId: number | undefined) => {
  return useQuery([CfmQueryKeys.GetOrderX, orderId], () => getOrderX(orderId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: !!orderId,
  });
};
