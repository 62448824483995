import _ from "lodash";
import { createContext, useContext, useMemo, useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { IAddressLean } from "../../../shared/domain/address/address-lean";
import { AddressConverter } from "../../../shared/domain/converter/address.converter";
import { isCfmRecycler } from "../../../shared/domain/user/user";
import { UserTypeLevel } from "../../../shared/domain/user/user-type-level";
import { AddressType } from "../../../shared/models/address/address.model";
import { useGetAllAddressesOfType } from "../../../shared/repositories/queries/address/get-all-addresses-of-type.query";
import { useGetGroupAddresses } from "../../../shared/repositories/queries/address/get-group-addresses.query";
import { useGetUserAddresses } from "../../../shared/repositories/queries/address/get-user-addresses-for-id.query";
import { ICfmProduct } from "../../domain/products/cfm-product";
import { ICfmTruckload, ICfmTruckloadList } from "../../domain/truckload/cfm-truckload";
import { useGetAllProductsQuery } from "../../repositories/queries/product/query/get-products.query";
import { useGetInfiniteTruckloadsQuery } from "../../repositories/queries/truckload/queries/get-truckloads.query";
import { truckloadValidAddressTypes } from "./utils/truckload.utils";
import { ICfmTruckloadFilterModel } from "../../repositories/models/truckload/cfm-truckload-filter.model";
import {
  CfmTruckloadSortByOptionModel,
  ICfmTruckloadSortModel,
} from "../../repositories/models/truckload/cfm-truckload-sort.model";

interface IOrderTruckloadAnnouncedContextType {
  addresses: IAddressLean[];
  areAddressesLoading: boolean;
  products: ICfmProduct[];
  areProductsLoading: boolean;
  truckloadAnnouncedFilter: ICfmTruckloadFilterModel;
  setTruckloadAnnouncedFilter: (filter: ICfmTruckloadFilterModel) => void;
  truckloadSort: ICfmTruckloadSortModel;
  setTruckloadSort: (filter: ICfmTruckloadSortModel) => void;
  truckloads: ICfmTruckload[];
  // infinite query props
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ICfmTruckloadList>>;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}

const OrderTruckloadAnnouncedContext = createContext<IOrderTruckloadAnnouncedContextType>(
  {} as IOrderTruckloadAnnouncedContextType,
);

export const OrderTruckloadAnnouncedProvider = (props: any) => {
  const value = useOrderTruckloadAnnouncedProvider();
  return (
    <OrderTruckloadAnnouncedContext.Provider value={value}>{props.children}</OrderTruckloadAnnouncedContext.Provider>
  );
};

export const useOrderTruckloadAnnouncedContext = () => {
  return useContext(OrderTruckloadAnnouncedContext);
};

const useOrderTruckloadAnnouncedProvider = (): IOrderTruckloadAnnouncedContextType => {
  const { internalUser } = useAuthContext();
  const [truckloadAnnouncedFilter, setTruckloadAnnouncedFilter] = useState<ICfmTruckloadFilterModel>({});
  const [truckloadSort, setTruckloadSort] = useState<ICfmTruckloadSortModel>({
    sortingMode: "DESC",
    sortBy: CfmTruckloadSortByOptionModel.TruckloadId,
  });

  const { data: groupAddressesResult, isLoading: groupAddressesLoading } = useGetGroupAddresses(
    internalUser?.group?.id,
    undefined,
    truckloadValidAddressTypes,
    internalUser?.group?.id !== undefined && internalUser.userTypeLevel === UserTypeLevel.Corporate,
    {
      page: 0,
      pageSize: 100,
    },
    true,
  );
  const { data: userAddressesResult, isLoading: userAddressesLoading } = useGetUserAddresses(
    internalUser?.id,
    truckloadValidAddressTypes,
    undefined,
    internalUser?.userTypeLevel === UserTypeLevel.WasteProducer,
  );
  const { data: logisticAddressesResult, isLoading: logisticAddressesLoading } = useGetAllAddressesOfType(
    [AddressType.LogisticLocation],
    {
      page: 0,
      pageSize: 100,
    },
    isCfmRecycler(internalUser),
    true,
  );
  const { isLoading: areProductsLoading, data: products } = useGetAllProductsQuery();

  const { data, isLoading, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useGetInfiniteTruckloadsQuery(
    truckloadAnnouncedFilter,
    truckloadSort,
  );

  const truckloads = useMemo(() => {
    const pages = data?.pages ?? [];
    return _.flatMap(pages, (page) => page.items);
  }, [data?.pages]);

  const addresses = useMemo(() => {
    // if user is recycler, load all logistic addresses for filter
    if (isCfmRecycler(internalUser)) {
      return (logisticAddressesResult ?? []).map(AddressConverter.domainToLeanAddressDomain);
    }

    if (internalUser?.userTypeLevel === UserTypeLevel.Corporate) {
      return groupAddressesResult?.addresses ?? [];
    }

    return (userAddressesResult?.addresses ?? []).map(AddressConverter.domainToLeanAddressDomain);
  }, [groupAddressesResult, userAddressesResult, logisticAddressesResult, internalUser]);

  const areAddressesLoading = useMemo(
    () => userAddressesLoading || groupAddressesLoading || logisticAddressesLoading,
    [userAddressesLoading, groupAddressesLoading, logisticAddressesLoading],
  );

  return {
    areAddressesLoading,
    addresses,
    areProductsLoading,
    products: products ?? [],
    truckloadAnnouncedFilter,
    setTruckloadAnnouncedFilter,
    truckloadSort,
    setTruckloadSort,
    truckloads,
    isLoading,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    hasNextPage: hasNextPage ?? false,
  };
};
