import { useInfiniteQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { PaginationMetaConverter } from "../../../domain/converter/pagination-meta.converter";
import { IGroupList } from "../../../domain/group/group";
import { GroupType } from "../../../domain/group/group-type";
import { IGroupListModel } from "../../models/group/get-group.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getInfiniteGroups = async (
  groupTypes: GroupType[],
  query: string | undefined,
  pageParam: number = 0,
  pageSize: number = 5,
): Promise<IGroupList> => {
  const { data } = await getInstance().get<IGroupListModel>(RestEndpoints.Groups, {
    params: { groupTypes, searchQuery: query, page: pageParam, pageSize },
  });
  return {
    items: data.items.map(GroupConverter.toDomain),
    meta: PaginationMetaConverter.toDomain(data.meta),
  };
};

export const useGetInfiniteGroupsQuery = (
  groupTypes: GroupType[],
  query: string | undefined,
  pageSize?: number,
  enabled?: boolean,
) => {
  return useInfiniteQuery(
    [SharedQueryKeys.GetInfiniteGroups, pageSize, query],
    ({ pageParam }) => getInfiniteGroups(groupTypes, query, pageParam, pageSize),
    {
      getNextPageParam: (lastPage) => {
        const hasNextPage = lastPage.meta.page + 1 < lastPage.meta.numberOfPages;
        if (hasNextPage) {
          return lastPage.meta.page + 1;
        }

        return undefined;
      },
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled ?? true,
    },
  );
};
