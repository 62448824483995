import { VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { GroupMutationConverter } from "../../../../domain/converter/group-mutation.converter";
import { IGroup } from "../../../../domain/group/group";
import { useUpdateGroupTypeInfo } from "../../../../repositories/queries/group/update-group-type-info.query";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useCustomForm } from "../../../../util/form.util";
import { AppDialog } from "../../../dialog/app-dialog.component";
import {
  GroupFormGroupGroupTypeSection,
  IGroupBaseDataTypeFormInputs,
} from "../../group-form/group-form-group-type-section.component";
import { GroupFormPomSection, IGroupBaseDataPomFormInputs } from "../../group-form/group-form-pom-section.component";
import { groupToGroupTypeDefaultValuesForEdit, showPomSection } from "../../group-form/group-form.util";

export interface IEditGroupTypeFormInputs extends IGroupBaseDataTypeFormInputs, IGroupBaseDataPomFormInputs {}

interface IEditGroupTypeDialogProps {
  open: boolean;
  group: IGroup;
  onCancel: VoidFunction;
  onSaved: VoidFunction;
}

export const EditGroupTypeInfoDialog: VFC<IEditGroupTypeDialogProps> = (props) => {
  const { open, group, onCancel, onSaved } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formMethods = useCustomForm<IEditGroupTypeFormInputs>({
    defaultValues: groupToGroupTypeDefaultValuesForEdit(group),
    mode: "all",
  });
  const { handleSubmit: onSubmit, watch } = formMethods;
  const { isLoading: isUpdateLoading, mutateAsync: updateGroupTypeInfo } = useUpdateGroupTypeInfo();

  // @ts-ignore
  const groupType: UIGroupType = watch("type");

  const handleSubmit: SubmitHandler<IEditGroupTypeFormInputs> = async (inputs) => {
    const convertedData = GroupMutationConverter.toUpdateGroupTypeInfoModel(inputs);
    await updateGroupTypeInfo({ id: group.id, updateData: convertedData });
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupForDetails);
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    onSaved();
  };

  return (
    <AppDialog
      title={t("basedata.groups.edit.groupTypeHeading")}
      open={open}
      isLoading={isUpdateLoading}
      onCancelClick={onCancel}
      onAcceptClick={() => {
        onSubmit(handleSubmit)();
      }}
      acceptTextOverride={t("general.save.text")}
      alignButtons="space-between"
    >
      <FormProvider {...formMethods}>
        <GroupFormGroupGroupTypeSection hideHeading />
        {showPomSection(groupType) && <GroupFormPomSection groupType={groupType} />}
      </FormProvider>
    </AppDialog>
  );
};
