import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { OrderScrollToTopButton } from "../../../collect-from-market/pages/order-x/components/order-scroll-to-top-button.component";
import { useOrderFooterStyle } from "../../../collect-from-market/pages/order-x/components/card/order-footer.style";
import { useSidebarContext } from "../../domain/sidebar/sidebar-context";

export const ListFooterComponent: FC = () => {
  const footerClasses = useOrderFooterStyle();
  const { sidebarOpen } = useSidebarContext();
  return (
    <Grid
      container
      direction="row"
      className={classNames(footerClasses.footerStyle, {
        [footerClasses.footerWidthSidebarOpen]: sidebarOpen,
        [footerClasses.footerWidthSidebarClosed]: !sidebarOpen,
      })}
    >
      <OrderScrollToTopButton />
    </Grid>
  );
};
