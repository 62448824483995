import { FC } from "react";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { UserForm, UserFormMode } from "../../components/user/user-form/user-form.component";
import { UserFormProvider } from "../../components/user/user-form/user-form.provider";

export const UserAddPage: FC = () => {
  return (
    <UserFormProvider>
      <PageTitle id={AvailableSharedRoutes.UserCreate} />
      <UserForm mode={UserFormMode.Create} />
    </UserFormProvider>
  );
};
