import { Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from "@material-ui/core";
import { HomeOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { LogoutAsDialog } from "../../components/auth/logout-as-dialog.component";
import { LogoutSidebarIcon, SupportIcon } from "../../../components/Primitives";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { useLoginAs } from "../../../providers/Auth/login-as-context";

import { Colors, Shades } from "../../../style/Colors";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { AvailableMenus } from "../../util/sidebar.utils";
import { MenuListItem } from "./menu-list-item.component";
import { SidebarAdminMenu } from "./sidebar-admin-menu.component";
import { SidebarCfmMenu } from "./sidebar-cfm-menu.component";
import { SidebarConfigMenu } from "./sidebar-config-menu.component";
import { useSidebarContext } from "./sidebar-context";
import { SidebarPomAgencyMenu } from "./sidebar-pom-agency-menu.component";
import { SidebarPomMenu } from "./sidebar-pom-menu.component";
import { SidebarUserMenu } from "./sidebar-user-menu.component";

export const DRAWER_OPEN = 290;
export const DRAWER_CLOSED = 0;

const useStyles = makeStyles((theme: Theme) => ({
  drawerOpen: {
    width: DRAWER_OPEN,
    "& .MuiDrawer-paperAnchorDockedRight": {
      borderLeft: "none",
    },
  },
  drawerOpenPaper: {
    width: DRAWER_OPEN,
    backgroundColor: Shades.gray20,
  },
  drawerClosed: {
    width: DRAWER_CLOSED,
  },
  menuContainer: {
    height: "100%",
  },
  menuTitle: {
    "& .MuiListItemText-primary": {
      fontSize: 16,
    },
  },
  activeMenuTitle: {
    "& .MuiListItemText-primary": {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  icon: {
    "&.MuiSvgIcon-root": {
      color: Colors.greenLight,
      height: 28,
      width: 28,
    },
  },
  logoutIcon: {
    height: 28,
    width: 28,
    fill: "none",
    stroke: Colors.greenLight,
  },
  startMenu: {
    padding: 0,
    "& .MuiList-padding": {
      padding: 0,
    },
    paddingTop: 20,
  },
  menuList: {
    padding: 0,
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  listItem: {
    borderBottom: `1px solid ${Shades.gray30}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listItemIcon: {
    height: 28,
    width: 36,
    minWidth: 36,
    marginLeft: 4,
  },
  paper: {
    zIndex: 999,
  },
}));

export const Sidebar: React.VFC = () => {
  const classes = useStyles();
  const { sidebarOpen, isFixed, setSidebarOpen, setActiveMenu } = useSidebarContext();
  const { logout } = useAuthContext();
  const { pathname } = useLocation();
  const { loggedAs } = useLoginAs();
  const { t } = useTranslation();
  const history = useHistory();

  const [logoutAsDialogOpen, setLogoutAsDialogOpen] = useState<boolean>(false);

  const isLoggedAs = () => {
    return loggedAs;
  };

  const onClickLogout = () => {
    if (isLoggedAs()) {
      setLogoutAsDialogOpen(true);
    } else {
      // Reset to base path so that deep linking will not trigger on next login
      history.location.pathname = AvailableSharedRoutes.Login;
      logout();
    }
  };

  const isActivePath = (path: string) => {
    return path === pathname;
  };

  const onHomeMenuClicked = () => {
    setActiveMenu(AvailableMenus.None);
    history.push(AvailableSharedRoutes.Portal);
    !isFixed && setSidebarOpen(false);
  };

  return (
    <Drawer
      open={sidebarOpen}
      anchor="right"
      variant={isFixed ? "permanent" : "temporary"}
      onClose={() => setSidebarOpen(false)}
      className={classNames({ [classes.drawerOpen]: sidebarOpen, [classes.drawerClosed]: !sidebarOpen })}
      classes={{
        paper: classNames(classes.paper, [
          {
            [classes.drawerOpenPaper]: sidebarOpen,
            [classes.drawerClosed]: !sidebarOpen,
          },
        ]),
      }}
    >
      <Grid container className={classes.menuContainer} alignContent="space-between" id="navigation-container">
        <Grid container item xs={12} id="top-nav-section" alignContent="flex-start">
          <Grid style={{ overflowX: "hidden" }}>
            <LogoutAsDialog open={logoutAsDialogOpen} onCancel={() => setLogoutAsDialogOpen(false)} />
          </Grid>
          <Grid item xs={12} id="nav-title">
            <List className={classes.startMenu}>
              <ListItem key="my-start" dense button onClick={onHomeMenuClicked} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <HomeOutlined color="primary" className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={t("dashboard.title")}
                  className={isActivePath(AvailableSharedRoutes.Portal) ? classes.activeMenuTitle : classes.menuTitle}
                />
              </ListItem>
            </List>
          </Grid>
          {pathname !== AvailableSharedRoutes.Portal && (
            <Grid item xs={12} style={{ flexGrow: 1 }} id="nav-items">
              <List className={classes.menuList}>
                <SidebarPomAgencyMenu />
                <SidebarAdminMenu />
                <SidebarCfmMenu />
                <SidebarPomMenu />
                <SidebarConfigMenu />
              </List>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} id="nav-footer">
          <List className={classes.menuList}>
            <SidebarUserMenu />
            <MenuListItem path={AvailableSharedRoutes.Contact} MenuIcon={SupportIcon} title={"general.support"} />
            <MenuListItem path={AvailableSharedRoutes.DataPrivacy} MenuIcon={SupportIcon} title={"dataPrivacy.link"} />
            <ListItem key="logout" dense button onClick={() => onClickLogout()} className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <LogoutSidebarIcon className={classes.logoutIcon} />
              </ListItemIcon>
              <ListItemText primary={t("general.logout")} className={classes.menuTitle} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};
