import { DefaultValues } from "react-hook-form";
import { Countries, getDefaultCountry } from "../../../utils/Countries";
import { IAddressLean } from "../../domain/address/address-lean";
import { IUser } from "../../domain/user/user";
import { IBattGContactFormInputs } from "./batt-g-contact-form.component";
import { IBattGManufacturerBaseDataFormInputs } from "./batt-g-manufacturer-base-data-form.component";

export enum BattGContactFormMode {
  MainContact,
  Representative,
}

export class BattGFormUtil {
  public static manufacturerBaseDataFormInitialValues(
    user: IUser,
    groupAddress: IAddressLean | undefined,
    inputs?: IBattGManufacturerBaseDataFormInputs,
  ): DefaultValues<IBattGManufacturerBaseDataFormInputs> {
    if (inputs) {
      return inputs;
    }

    return {
      address: groupAddress?.address ?? "",
      buildingNumber: groupAddress?.buildingNumber ?? "",
      city: groupAddress?.city ?? "",
      country: Countries.find((c) => c.code === groupAddress?.country) ?? getDefaultCountry(),
      groupName: user.group?.name ?? "",
      postal: groupAddress?.postal ?? "",
      state: groupAddress?.state ?? "",
      tradeRegisterNumber: user.group?.tradeRegisterNumber ?? "",
    };
  }

  public static contactFormInitialValues(inputs?: IBattGContactFormInputs): DefaultValues<IBattGContactFormInputs> {
    if (inputs) {
      return inputs;
    }

    return {};
  }
}
