import { ICfmProductCategoryModel } from "collect-from-market/repositories/models/product/cfm-product-category.model";
import { ICfmProductCategory } from "../../../domain/products/cfm-product-category";

export class CfmProductCategoryConverter {
  public static toModel(category: ICfmProductCategory): ICfmProductCategoryModel {
    return {
      id: category.id,
      name: category.name,
      type: category.type ?? undefined,
    };
  }

  public static toDomain(model: ICfmProductCategoryModel): ICfmProductCategory {
    return {
      id: model.id,
      name: model.name,
      type: model.type ?? null,
      internalNumber: model.internalNumber ?? null,
    };
  }
}
