import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { AddressMarkerIcon } from "../../../../../components/Primitives";
import { IAddressLean } from "../../../../../shared/domain/address/address-lean";
import { Colors, Shades } from "../../../../../style/Colors";
import { AddressInfoBoxText } from "./address-info-box-text.component";
import { OrderNewInfoBox } from "./order-new-info-box.component";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    minHeight: 70,
    backgroundColor: Colors.grayLight,
    "&:hover": {
      backgroundColor: Shades.gray50,
    },
  },
  columnContainer: {
    height: "100%",
  },
  iconPadding: {
    paddingLeft: 15,
    paddingRight: 25,
  },
  iconSize: {
    height: 40,
    width: 29,
  },
  mobileAlign: {
    textAlign: "center",
  },
}));

interface IAddressInfoBoxProps {
  address: IAddressLean;
  onClick: (address: IAddressLean) => void;
  isNew: boolean;
}

export const AddressInfoBox: React.FC<IAddressInfoBoxProps> = (props) => {
  const { address, onClick, isNew } = props;
  const classes = useStyles();

  const getLabel = () => {
    return <AddressInfoBoxText address={address} />;
  };

  return (
    <OrderNewInfoBox
      iconComponent={<AddressMarkerIcon classes={{ root: classes.iconSize }} />}
      labelComponent={getLabel()}
      onClick={() => onClick(address)}
      isNew={isNew}
    />
  );
};
