import { useQuery } from "react-query";
import { getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IPomAnnouncement } from "../../../components/announcement-table/pom-announcement-table-data";
import { IPomAnnouncementModel } from "../../models/announcements/pom-announcements.model";
import { PomAnnouncementsConverter } from "../../models/converter/pom-announcements.converter";
import { PomQueryKeys } from "../../pom-query-keys";

export interface ILatestAnnouncementResult {
  announcement: IPomAnnouncement | undefined;
  status: number;
}

const getLatestAnnouncement = async (
  contractId: number | undefined,
): Promise<ILatestAnnouncementResult | undefined> => {
  if (!contractId) return undefined;
  try {
    const result = await getInstance().get<IPomAnnouncementModel>(RestEndpoints.PomLatestAnnouncement, {
      params: {
        contractId,
      },
    });
    return { announcement: PomAnnouncementsConverter.toDomain(result.data), status: result.status };
  } catch (error: any) {
    const code = getErrorStatusCode(error);
    return { announcement: undefined, status: code };
  }
};

export const useGetLatestAnnouncementQuery = (contractId: number | undefined, enabled: boolean) => {
  return useQuery([PomQueryKeys.GetLatestAnnouncement, contractId], () => getLatestAnnouncement(contractId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
