import dayjs from "dayjs";

// Workaround to store a date without time, because JS dates always contain the time
export type DateWithoutTime = string;

export const checkDate = (dateToCheck: any): Date | null => {
  if (!dateToCheck || dateToCheck === "" || dayjs(dateToCheck).year() === 1970 || !dayjs(dateToCheck).isValid()) {
    return null;
  }

  return dateToCheck;
};

export const formatDateLong = (date: Date) => dayjs(date).format("DD.MM.YYYY HH:mm");

export const formatDateShort = (date: Date | string) => dayjs(date).format("DD.MM.YYYY");

export const formatDateYearMonth = (date: Date) => dayjs(date).format("YYYY-MM");

export const formatDateYearMonthDay = (date: Date | string) => dayjs(date).format("YYYY-MM-DD");

export const formatDateMonthYear = (date: Date) => dayjs(date).format("MMMM YYYY");

export const formatYearShort = (date: Date) => dayjs(date).format("YYYY");

export const formatMonthShort = (date: Date) => dayjs(date).format("MMM");
