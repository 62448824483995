import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDatepickerField } from "../../../../../shared/components/form/fields/form-datepicker.field";
import { FormFileUpload } from "../../../../../shared/components/form/fields/form-file-upload.field";
import { FormTextField } from "../../../../../shared/components/form/fields/form-text.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { positiveNumberOnly } from "../../../../../utils/validationHelper";
import { CfmResponseFieldType } from "../../../../domain/response-values-x/cfm-response-field-type";
import { IResponseFieldValuesFormInputs } from "./order-x-action.component";
import { useSelectedFilesContext } from "./providers/selected-files.provider";

interface IResponseXInput {
  responseIndex: number;
  responseFieldId: number;
  fieldType: CfmResponseFieldType;
  label: string;
  unit: string | null;
  required?: boolean;
}

export const ResponseXInput: VFC<IResponseXInput> = (props) => {
  const { label, fieldType, responseIndex, unit, responseFieldId, required = true } = props;
  const {
    formState: { errors },
    control,
    register,
    setValue,
  } = useFormContext<IResponseFieldValuesFormInputs>();
  const { updateFileList, selectedFileList } = useSelectedFilesContext();
  const { t } = useTranslation();

  const numberError = t("orders.response_values.error.number");
  const positiveNumberOnlyValidation = (value: number | undefined, errorMessage: string) => {
    if (value === undefined || value === null) return errorMessage;
    return positiveNumberOnly(value, errorMessage, true);
  };

  const onFileUploadChange = (files: File[]) => {
    updateFileList(responseIndex, responseFieldId, files);
  };

  const onFileDelete = (files: File[]) => {
    if (selectedFileList.find((file) => file.index === responseIndex)?.files.length === 1) {
      setValue(`responseFields.${responseIndex}.files`, null);
    }
    updateFileList(responseIndex, responseFieldId, files);
  };

  const responseValueError =
    errors.responseFields && errors.responseFields[responseIndex] ? errors.responseFields[responseIndex] : undefined;

  if (fieldType === CfmResponseFieldType.Date) {
    return (
      <FormField key={responseIndex} md={12}>
        <FormDatepickerField
          label={label}
          hasError={Boolean(responseValueError?.date)}
          control={control}
          required={required}
          {...register(`responseFields.${responseIndex}.date`, {
            required: required,
            valueAsDate: true,
          })}
        />
      </FormField>
    );
  }
  if (fieldType === CfmResponseFieldType.TextArea) {
    return (
      <FormField key={responseIndex} md={12}>
        <FormTextField
          hasError={Boolean(responseValueError?.text)}
          label={label}
          type="text"
          multiline={true}
          minRows={4}
          required={required}
          control={control}
          name={`responseFields.${responseIndex}.text`}
          rules={{ required: required }}
        />
      </FormField>
    );
  }
  if (fieldType === CfmResponseFieldType.Text) {
    return (
      <FormField key={responseIndex} md={12}>
        <FormTextField
          hasError={Boolean(responseValueError?.text)}
          label={label}
          type="text"
          required={required}
          control={control}
          name={`responseFields.${responseIndex}.text`}
          rules={{ required: required }}
        />
      </FormField>
    );
  }
  if (fieldType === CfmResponseFieldType.FloatingPoint) {
    return (
      <FormField key={responseIndex} md={12}>
        <FormTextField
          hasError={Boolean(responseValueError)}
          label={label}
          type="number"
          inputProps={{
            endAdornment: <>{unit ?? "kg"}</>,
          }}
          required={required}
          control={control}
          name={`responseFields.${responseIndex}.floatingPoint`}
          rules={{
            required: required,
            validate: (value) => positiveNumberOnlyValidation(Number(value), numberError),
          }}
        />
      </FormField>
    );
  }
  if (fieldType === CfmResponseFieldType.File) {
    return (
      <FormField key={responseIndex} md={12}>
        <FormFileUpload
          control={control}
          required={required}
          variant="outlined"
          onFileUpload={(files: File[]) => onFileUploadChange(files)}
          onFileDelete={(files: File[]) => onFileDelete(files)}
          name={`responseFields.${responseIndex}.files`}
          initialFiles={selectedFileList.find((file) => file.index === responseIndex)?.files}
          label={label}
          error={Boolean(responseValueError?.files)}
        />
      </FormField>
    );
  }
  return (
    <FormField key={responseIndex} md={12}>
      <FormTextField
        hasError={Boolean(responseValueError?.number)}
        label={label}
        type="number"
        control={control}
        inputProps={{
          endAdornment: <>{unit ?? "kg"}</>,
        }}
        required={required}
        name={`responseFields.${responseIndex}.number`}
        rules={{
          required: required,
          validate: (value) => positiveNumberOnlyValidation(Number(value), numberError),
        }}
      />
    </FormField>
  );
};
