import { Box, Button, CircularProgress, Dialog, DialogProps, Grid, makeStyles, Typography } from "@material-ui/core";
import { InfoOutlined, Warning } from "@material-ui/icons";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IApiResponseFailure, IApiResponseSuccess, isFailure, isSuccess } from "../../../api/api-response";
import { CancelButton, CsvExportIcon, SaveButton } from "../../../components/Primitives";
import { CustomDropzone } from "../../../shared/components/dropzone/dropzone";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";
import { IPomAnnouncementExcelDataModel } from "../../repositories/models/announcements/pom-announcement-excel-data.model";
import {
  PomAnnouncementExcelUploadDialogState,
  usePomAnnouncementExcelUploadContext,
} from "./pom-announcement-excel-upload.provider";

interface IPomAnnouncementExcelUploadDialog extends DialogProps {
  onOk: (data: IPomAnnouncementExcelDataModel) => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  dialogContainer: {
    backgroundColor: Shades.gray10,
  },
  header: {
    textAlign: "center",
    backgroundColor: PrimaryColors.base,
    color: Shades.white,
    height: "60px",
  },
  successText: {
    color: Colors.green,
    fontWeight: "bold",
  },
  warningText: {
    color: Colors.orange,
    fontWeight: "bold",
  },
  errorText: {
    color: Colors.red,
    fontWeight: "bold",
  },
  centerText: {
    textAlign: "center",
  },
  detailText: {
    fontSize: 12,
  },
  fullWidth: {
    width: "100%",
  },
  whiteIcon: {
    color: Colors.white,
  },
});

export const PomAnnouncementExcelUploadDialog = (props: IPomAnnouncementExcelUploadDialog) => {
  const { open, onOk, onCancel, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    dialogState,
    extractionResult,
    resetDialog,
    isExtractionValid,
    getErrorTextByErrorCode,
    onFileSelected,
    downloadAnnouncementTemplate,
  } = usePomAnnouncementExcelUploadContext();

  const onClose = (): void => {
    onCancel();
    resetDialog();
  };

  const uploadFileContent = (): ReactNode => {
    return (
      <CustomDropzone
        handleFilesChanged={(files: File[]) => {
          const file = files?.length ? files[0] : undefined;
          onFileSelected(file);
        }}
        maxFiles={1}
      />
    );
  };

  const successContent = (extractionResult: IApiResponseSuccess<IPomAnnouncementExcelDataModel>): ReactNode => {
    return (
      <Box display="flex" className="m-5" justifyContent="center" alignItems="center">
        <Grid container xs={12} justifyContent="center" alignItems="center" spacing={2} direction="column">
          <Grid item container xs={12} sm={8} justifyContent="space-between">
            <Grid item xs={10}>
              {t("pom.announcements.template.upload.dialog.success_items")}
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
              <span className={classes.successText}>{extractionResult.data.items.length}</span>
            </Grid>

            <Grid item className="mt-2" xs={10}>
              {t("pom.announcements.template.upload.dialog.unknown_items")}
            </Grid>
            <Grid item container className="mt-2" xs={2} justifyContent="flex-end">
              <span
                className={extractionResult.data.unknownItems.length > 0 ? classes.warningText : classes.successText}
              >
                {extractionResult.data.unknownItems.length}
              </span>
            </Grid>
            {extractionResult.data.unknownItems.map((item) => {
              return (
                <>
                  <Grid item xs={6} className={classes.detailText}>
                    {item.articleNumber}
                  </Grid>
                  <Grid item xs={6} className={classes.detailText}>
                    {t("pom.announcements.template.upload.dialog.rowNumber", {
                      value: item.rowNumber,
                    })}
                  </Grid>
                </>
              );
            })}

            {extractionResult.data.invalidItems.length > 0 && (
              <>
                <Grid item className="mt-2" xs={10}>
                  {t("pom.announcements.template.upload.dialog.invalid_items")}
                </Grid>
                <Grid item container className="mt-2" xs={2} justifyContent="flex-end">
                  <span className={classes.errorText}>{extractionResult.data.invalidItems.length}</span>
                </Grid>
              </>
            )}
            {extractionResult.data.invalidItems.map((item) => {
              return (
                <>
                  <Grid item xs={6} className={classes.detailText}>
                    {item.articleNumber}
                  </Grid>
                  <Grid item xs={6} className={classes.detailText}>
                    {t("pom.announcements.template.upload.dialog.rowNumber", {
                      value: item.rowNumber,
                    })}
                  </Grid>
                </>
              );
            })}
          </Grid>

          {extractionResult.data.invalidItems.length > 0 && (
            <span className={[classes.errorText, classes.centerText, "mt-4"].join(" ")}>
              {t("pom.announcements.template.upload.dialog.error.invalid_items")}
            </span>
          )}
        </Grid>
      </Box>
    );
  };

  const failureContent = (extractionResult: IApiResponseFailure): ReactNode => {
    return (
      <Box display="flex" className="m-5" justifyContent="center" alignItems="center" flexDirection="column">
        <Warning htmlColor={Colors.red} fontSize="large" className="mb-2" />
        <span className={[classes.errorText, classes.centerText].join(" ")}>
          {getErrorTextByErrorCode(extractionResult.errorCode)}
        </span>
        <Box className="mt-4" style={{ width: "100%" }}>
          {uploadFileContent()}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" {...rest}>
      <div className={classes.dialogContainer}>
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.header}>
          <Typography variant="body1" className="mx-4">
            <strong>{t("pom.announcements.template.upload.title")}</strong>
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" className="mx-4 mt-4">
          <InfoOutlined className="mr-3" fontSize="large" />
          <Typography>
            {t("pom.announcements.template.upload.dialog.how_to")}
            <br />
            {t("pom.announcements.template.upload.dialog.description")}
          </Typography>
        </Box>

        {dialogState === PomAnnouncementExcelUploadDialogState.Initial && (
          <Box display="flex" className="m-5" justifyContent="center">
            {uploadFileContent()}
          </Box>
        )}

        {dialogState === PomAnnouncementExcelUploadDialogState.Loading && (
          <Box display="flex" className="m-5" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        {dialogState === PomAnnouncementExcelUploadDialogState.ShowResults &&
          extractionResult &&
          isSuccess(extractionResult) &&
          successContent(extractionResult)}

        {dialogState === PomAnnouncementExcelUploadDialogState.ShowResults &&
          extractionResult &&
          isFailure(extractionResult) &&
          failureContent(extractionResult)}

        <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" className="my-4">
          <Grid
            container
            direction="row"
            spacing={2}
            className={classes.fullWidth}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item>
              <Button
                endIcon={<CsvExportIcon className={classes.whiteIcon} />}
                color="primary"
                variant="contained"
                onClick={() => downloadAnnouncementTemplate("de")}
              >
                {t("pom.announcements.template.download.text")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                endIcon={<CsvExportIcon className={classes.whiteIcon} />}
                color="primary"
                variant="contained"
                onClick={() => downloadAnnouncementTemplate("en")}
              >
                {t("pom.announcements.template.download.textEn")}
              </Button>
            </Grid>
            <Grid item>
              <CancelButton variant="contained" onClick={onClose} color="primary" className="delete">
                <Typography variant="body1">{t("general.cancel.text")}</Typography>
              </CancelButton>
            </Grid>
            <Grid item>
              <SaveButton
                variant="contained"
                onClick={() => {
                  if (extractionResult && isSuccess(extractionResult)) {
                    onOk(extractionResult.data);
                    resetDialog();
                  }
                }}
                color="primary"
                disabled={!isExtractionValid()}
              >
                <Typography variant="body1">{t("pom.announcements.template.upload.dialog.confirm")}</Typography>
              </SaveButton>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};
