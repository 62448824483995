import { useMutation } from "react-query";
import { ApiResponse, success } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import { getInstanceMultipartFormData } from "../../../../../utils/rest";
import { IPomAnnouncementExcelDataModel } from "../../../models/announcements/pom-announcement-excel-data.model";
import { PomQueryKeys } from "../../../pom-query-keys";

const extractAnnouncementTemplateData = async (
  file: File,
  contractId: number,
): Promise<ApiResponse<IPomAnnouncementExcelDataModel>> => {
  const form = new FormData();
  form.append("file", file);
  const url = RestEndpoints.PomAnnouncementExtractExcel.replace(":contractId", contractId.toString());
  const { data } = await getInstanceMultipartFormData().post(url, form);
  return success(data);
};

export const useExtractAnnouncementTemplateDataQuery = () => {
  return useMutation(
    [PomQueryKeys.ExtractAnnouncementTemplateData],
    ({ file, contractId }: { file: File; contractId: number }) => extractAnnouncementTemplateData(file, contractId),
  );
};
