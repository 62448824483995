import {
  ManufacturerForm,
  ManufacturerFormMode,
} from "put-on-market/components/manufacturer-form/manufacturer-form.component";
import { FunctionComponent } from "react";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { AvailableSharedRoutes } from "../../../utils/constants";

export const PomManufacturerAddPage: FunctionComponent = () => {
  return (
    <>
      <PageTitle id={AvailableSharedRoutes.UserCreate} />
      <ManufacturerForm mode={ManufacturerFormMode.Create} />
    </>
  );
};
