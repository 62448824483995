import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import { IdBox } from "../../../collect-from-market/pages/order-x/components/id-box.component";
import { Colors } from "../../../style/Colors";

export interface IAppDialogHeaderProps {
  title?: string;
  subtitle?: string | ReactNode;
  displayStyle?: "success" | "warning";
  id?: string | number;
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    color: Colors.white,
    backgroundColor: Colors.greenLight,
    padding: "20px 30px",
    borderBottom: `2px solid ${Colors.greenDark}`,
    [theme.breakpoints.down("md")]: {
      padding: 15,
    },
  },
  warningStyle: {
    backgroundColor: Colors.red,
  },
  textContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

export const AppDialogHeader: FC<IAppDialogHeaderProps> = (props) => {
  const { title, subtitle, children, displayStyle, id } = props;
  const classes = useStyles();

  const renderSubTitle = (): ReactNode => {
    if (typeof subtitle === "string") {
      <Typography variant="body2">{subtitle}</Typography>;
    }

    return subtitle;
  };

  return (
    <>
      <Grid
        container
        className={classNames(classes.headerContainer, { [classes.warningStyle]: displayStyle === "warning" })}
        direction={"column"}
      >
        <Grid item container direction="row" spacing={2}>
          {title && (
            <Grid item>
              <Box className={classes.textContainer}>
                <Typography variant="body1" className="font-weight-bold">
                  {title}
                </Typography>
              </Box>
            </Grid>
          )}
          {id && (
            <Grid item>
              <IdBox id={id} />
            </Grid>
          )}
        </Grid>

        {subtitle !== undefined && renderSubTitle()}
        {children && children}
      </Grid>
    </>
  );
};
