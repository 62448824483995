import { FunctionComponent } from "react";
import {
  BaseDataIcon,
  CalendarIcon,
  CreatePackageConfigIcon,
  IIconsBaseProps,
  OrderNewIcon,
  OrdersIcon,
  ProductConfigIcon,
  RoutingConfigSmallIcon,
  ShoppingCardIcon,
  UserIcon,
} from "../components/Primitives/Icons";
import {
  IUser,
  isAdmin,
  isCfmLogistics,
  isCfmRecycler,
  isCustomer,
  isPomAgency,
  isPomRepresentative,
} from "../shared/domain/user/user";
import { DashboardAction } from "../shared/pages/dashboard/dashboard-action.provider";
import {
  AvailableCfmRoutes,
  AvailableConfiguratorRoutes,
  AvailablePomRoutes,
  AvailableSharedRoutes,
} from "./constants";

export interface IShortcut {
  name: ShortcutType;
  display: boolean;
  mandatoryPomMainContact: boolean;
  translation: string;
  route: string;
  icon: FunctionComponent<IIconsBaseProps>;
  action?: DashboardAction;
}

export enum ShortcutType {
  NewAnnouncement = "NewAnnouncement",
  GroupOverview = "GroupOverview",
  ManufacturerOverview = "ManufacturerOverview",
  BaseData = "BaseData",
  NewOrder = "NewOrder",
  OrderOverview = "OrderOverview",
  Cart = "Cart",
  RepeatOrder = "RepeatOrder",
  ConfigOverview = "ConfigOverview",
  ProductConfig = "ProductConfig",
  PackageConfig = "PackageConfig",
  RoutingConfig = "RoutingConfig",
  ResponseConfig = "ResponseConfig",
}

// #region Shortcuts
export const getPomShortcuts = (user: IUser | undefined): IShortcut[] => {
  const displayNewAnnouncement =
    isCustomer(user) || isPomRepresentative(user) || (isPomAgency(user) && user?.group?.isPomAgencyAnnouncer === true);

  return [
    {
      name: ShortcutType.NewAnnouncement,
      display: displayNewAnnouncement,
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.pom.new_announcment",
      route: AvailablePomRoutes.Portal,
      icon: CalendarIcon,
      action: DashboardAction.SELECT_TAKE_BACK_SYSTEM,
    },
    {
      name: ShortcutType.GroupOverview,
      display: isCustomer(user) || isPomAgency(user) || isPomRepresentative(user),
      mandatoryPomMainContact: true,
      translation: "dashboard.platform.shortcuts.pom.group_overview",
      route: AvailableSharedRoutes.GroupDetail,
      icon: OrdersIcon,
    },
    {
      name: ShortcutType.ManufacturerOverview,
      display: isPomAgency(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.pom.manufactorer_overview",
      route: AvailablePomRoutes.Agency,
      icon: UserIcon,
    },
    {
      name: ShortcutType.BaseData,
      display: isCustomer(user) || isPomRepresentative(user),
      mandatoryPomMainContact: true,
      translation: "dashboard.platform.shortcuts.pom.base_data",
      route: AvailableSharedRoutes.BattGMasterData,
      icon: BaseDataIcon,
    },
  ];
};

export const getCfmShortcuts = (user: IUser | undefined): IShortcut[] => {
  return [
    {
      name: ShortcutType.NewOrder,
      display: isCustomer(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.cfm.new_order",
      route: AvailableCfmRoutes.OrderXNew,
      icon: OrderNewIcon,
    },
    {
      name: ShortcutType.OrderOverview,
      display: isCustomer(user) || isCfmLogistics(user) || isCfmRecycler(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.cfm.order_overview",
      route: AvailableCfmRoutes.OrderXOverview,
      icon: OrdersIcon,
    },
    {
      name: ShortcutType.GroupOverview,
      display: user !== undefined,
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.pom.group_overview",
      route: AvailableSharedRoutes.GroupDetail,
      icon: OrdersIcon,
    },
    {
      name: ShortcutType.Cart,
      display: isCustomer(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.cfm.cart",
      route: AvailableCfmRoutes.CartX,
      icon: ShoppingCardIcon,
    },
  ];
};

export const getConfiguratorShortcuts = (user: IUser | undefined): IShortcut[] => {
  return [
    {
      name: ShortcutType.ConfigOverview,
      display: isAdmin(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.config.overview",
      route: AvailableConfiguratorRoutes.ProductOverview,
      icon: OrdersIcon,
    },
    {
      name: ShortcutType.ProductConfig,
      display: isAdmin(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.config.product",
      route: AvailableConfiguratorRoutes.CreateProductConfig,
      icon: ProductConfigIcon,
    },
    {
      name: ShortcutType.PackageConfig,
      display: isAdmin(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.config.package",
      route: AvailableConfiguratorRoutes.PackageOverview,
      icon: CreatePackageConfigIcon,
    },
    {
      name: ShortcutType.RoutingConfig,
      display: isAdmin(user),
      mandatoryPomMainContact: false,
      translation: "dashboard.platform.shortcuts.config.routing",
      route: AvailableConfiguratorRoutes.ProductRoutingOverview,
      icon: RoutingConfigSmallIcon,
    },
    // {
    //   name: ShortcutType.RESPONSE_CONFIG,
    //   allowedGroups: isAdmin(user),
    //   mandatoryPomMainContact: false,
    //   translation: "dashboard.platform.shortcuts.config.response",
    //   route: AvailableConfiguratorRoutes.CreateResponseConfig,
    //   icon: ResponseConfigIcon
    // }
  ];
};
// #endregion
