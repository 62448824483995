import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import {
  PackageConfigIconSmall,
  ResponseConfigSmallIcon,
  RoutingConfigSmallIcon,
} from "../../../../components/Primitives/Icons";
import { useProductOverviewRowStyle } from "../../../../style/product-overview-row.style";
import { hasPackages, hasResponse, hasRouting } from "../../../components/product/product.utils";

interface IProductActionIconsProps {
  product: ICfmProduct;
  onPackageIconClick: VoidFunction;
  onRoutingIconClick: VoidFunction;
  onResponseIconClick: VoidFunction;
  additionalActions?: React.ReactNode[];
}

export const ProductActionIcons: VFC<IProductActionIconsProps> = (props) => {
  const { product, onPackageIconClick, onRoutingIconClick, onResponseIconClick, additionalActions } = props;
  const rowClasses = useProductOverviewRowStyle();

  return (
    <Grid container direction="row" spacing={2} justifyContent="flex-end">
      {(additionalActions ?? []).map((action) => {
        return <Grid item>{action}</Grid>;
      })}
      <Grid item>
        <PackageConfigIconSmall
          className={classNames(rowClasses.icons, {
            [rowClasses.iconData]: hasPackages(product),
            [rowClasses.iconNoData]: !hasPackages(product),
          })}
          viewBox="0 0 25 25"
          onClick={onPackageIconClick}
        />
      </Grid>
      <Grid item>
        <RoutingConfigSmallIcon
          className={classNames(rowClasses.icons, {
            [rowClasses.iconData]: hasRouting(product),
            [rowClasses.iconNoData]: !hasRouting(product),
          })}
          viewBox="0 0 25 25"
          onClick={onRoutingIconClick}
        />
      </Grid>
      <Grid item>
        <ResponseConfigSmallIcon
          className={classNames(rowClasses.icons, {
            [rowClasses.iconData]: hasResponse(product),
            [rowClasses.iconNoData]: !hasResponse(product),
          })}
          viewBox="0 0 25 25"
          onClick={onResponseIconClick}
        />
      </Grid>
    </Grid>
  );
};
