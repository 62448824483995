import dayjs from "dayjs";
import { IPomAnnouncementDateItem } from "../pom-announcement-date-list";
import { IPomAnnouncementListItem } from "../pom-announcement-list";

export class PomAnnouncementDateListConverter {
  public static toMonthDateList(
    announcementList?: IPomAnnouncementListItem[],
    startDate?: Date,
  ): IPomAnnouncementDateItem[] {
    const entries: IPomAnnouncementDateItem[] = [];
    const startYear = startDate?.getFullYear() ?? new Date().getFullYear();

    for (let i = 1; i <= 12; i++) {
      const dateString = `${startYear}-${i}-01`;
      const entry: IPomAnnouncementDateItem = {
        date: new Date(dateString),
        status: undefined,
        announcementId: undefined,
      };
      entries.push(entry);
    }

    PomAnnouncementDateListConverter.fillEntries(announcementList, entries);
    return entries;
  }

  public static toYearDateList(
    announcementList?: IPomAnnouncementListItem[],
    startDate?: Date,
  ): IPomAnnouncementDateItem[] {
    const entries: IPomAnnouncementDateItem[] = [{
      date: dayjs(startDate).toDate(),
      status: undefined,
      announcementId: undefined,
    }];
    PomAnnouncementDateListConverter.fillEntries(announcementList, entries);
    return entries;
  }

  public static fillEntries(items: IPomAnnouncementListItem[] | undefined, entries: IPomAnnouncementDateItem[]): void {
    return items?.forEach((item) => {
      const matchedEntry = entries.findIndex((e) => dayjs(e.date).isSame(item.announcementDate, "month"));
      if (matchedEntry !== -1) {
        entries[matchedEntry].announcementId = item.announcementId;
        entries[matchedEntry].status = item.status;
        entries[matchedEntry].date = item.announcementDate;
      }
    });
  }
}
