import { VFC } from "react";
import { CfmOrderActionType } from "../../../../domain/order-x/actions/cfm-order-action-type";
import { OrderXLogisticAnnouncedBulkAction } from "./order-state-actions/order-logistic-announced-bulk-action.component";
import { OrderXLogisticAnnouncedSingleOrderBulkAction } from "./order-state-actions/order-logistic-announced-single-order-bulk-action.component";
import { OrderXChangePlannedPickupAction } from "../actions/order-state-actions/order-x-change-planned-pickup.component";
import { OrderLogisticMistripAction } from "../actions/order-state-actions/order-logistic-mistrip-action.component";
import { OrderXLogisticPerformedBulkAction } from "./order-state-actions/order-logistic-performed-bulk-action.component";

interface IOrderBulkActionTypeInputProps {
  actionType: CfmOrderActionType;
}

export const OrderBulkActionTypeInput: VFC<IOrderBulkActionTypeInputProps> = (props) => {
  const { actionType } = props;

  switch (actionType) {
    case CfmOrderActionType.ShoppingCart:
    case CfmOrderActionType.Ordered:
    case CfmOrderActionType.LogisticAccepted:
    case CfmOrderActionType.LogisticDeclined:
    case CfmOrderActionType.LogisticWarehouse:
    case CfmOrderActionType.DestinationDeclined:
    case CfmOrderActionType.DestinationArrived:
    case CfmOrderActionType.Confirmed:
    case CfmOrderActionType.Canceled:
    case CfmOrderActionType.LogisticTruckLoad:
    case CfmOrderActionType.RecyclerIncomingWarehouse:
    case CfmOrderActionType.RecyclerOutgoingWarehouse:
      break;
    case CfmOrderActionType.LogisticPerformed:
      return <OrderXLogisticPerformedBulkAction />;
    case CfmOrderActionType.LogisticAnnounced:
      return <OrderXLogisticAnnouncedBulkAction />;
    case CfmOrderActionType.LogisticAnnouncedSingleOrder:
      return <OrderXLogisticAnnouncedSingleOrderBulkAction />;
    case CfmOrderActionType.ChangePlannedPickup:
      return <OrderXChangePlannedPickupAction />;
    case CfmOrderActionType.LogisticMistrip:
      return <OrderLogisticMistripAction />;
  }
  return <></>;
};
