import { Divider, Grid, makeStyles } from "@material-ui/core";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GroupAddressMarker } from "../../../../components/Primitives/Icons";
import { useAddressIconStyle } from "../../../../style/address-icon.style";
import { Shades } from "../../../../style/Colors";
import { AddressType } from "../../../models/address/address.model";
import { FormCheckbox } from "../../form/fields/form-checkbox.field";
import { FormField } from "../../form/form-field.component";
import { FormHeader } from "../../form/form-header.component";
import { CreateGroupHeader } from "../create-group/create-group-header.component";
import { AddressForGroupForm } from "./address-for-group-form.component";
import { IGroupBaseDataFormInputs } from "./group-form-base-data-section";

export const useStyles = makeStyles((theme) => ({
  invoiceAddressContainer: {
    marginTop: 20,
  },
  divider: {
    backgroundColor: Shades.gray20,
    marginTop: 0,
    marginBottom: 0,
  },
}));

export interface IGroupFormAddressSectionProps {
  displayDivider?: boolean;
}

export const GroupFormAddressSection: VFC<IGroupFormAddressSectionProps> = (props) => {
  const { displayDivider = true } = props;
  const { control, watch } = useFormContext<IGroupBaseDataFormInputs>();
  const { t } = useTranslation();
  const classes = useStyles();
  const addressIconClasses = useAddressIconStyle();

  // @ts-ignore
  const differentInvoiceAddress: boolean = watch("differentInvoiceAddress");

  return (
    <Grid container spacing={4}>
      <Grid item>
        <CreateGroupHeader>
          <Grid container direction="row" spacing={1} alignItems={"center"}>
            <Grid item xs={12} md="auto">
              <GroupAddressMarker className={addressIconClasses.groupOrInvoiceAddressIcon} viewBox="0 0 28 40" />
            </Grid>
            <Grid item xs={12} md="auto">
              <FormHeader doShrink size="small">
                {t("basedata.group.address_heading")}
              </FormHeader>
            </Grid>
          </Grid>
        </CreateGroupHeader>
      </Grid>

      <FormField md={12}>
        <AddressForGroupForm addressType={AddressType.GroupLocation} />
      </FormField>

      <FormField md={12}>
        <FormCheckbox
          name={"differentInvoiceAddress"}
          control={control}
          label={t("basedata.differentInvoice")}
          noMargin
        />
      </FormField>

      {differentInvoiceAddress && (
        <FormField md={12} className={classes.invoiceAddressContainer}>
          <AddressForGroupForm addressType={AddressType.Invoice} />
        </FormField>
      )}

      {displayDivider && (
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};
