import React from "react";
import { CalendarIcon, UserIcon } from "../../../components/Primitives";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";
import { getPomAgencyNavItems } from "../../../put-on-market/pom-navigation-items";
import { AvailablePomRoutes } from "../../../utils/constants";
import { isPomAgency, isPomMainContact } from "../user/user";
import { MenuListItem } from "./menu-list-item.component";
import { SidebarMenuItem } from "./sidebar-menu-item.component";

export const SidebarPomAgencyMenu: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { activePlatform } = usePlatformContext();

  const isPomPlatform = () => activePlatform === AvailablePlatform.Pom;
  const pomAgencyNavItems = isPomMainContact(internalUser) ? getPomAgencyNavItems(internalUser?.group?.id) : [];

  if (isPomPlatform() && isPomAgency(internalUser)) {
    return (
      <>
        {internalUser?.group?.isPomAgencyAnnouncer === true && (
          <MenuListItem
            path={AvailablePomRoutes.Announcement}
            MenuIcon={CalendarIcon}
            title={"pom.announcements.create.text"}
          />
        )}
        <MenuListItem path={AvailablePomRoutes.Agency} MenuIcon={UserIcon} title={"pom.agency.groups"} />
        {pomAgencyNavItems
          .filter((item) => item.visible && item.content)
          .map((item, index) => {
            return (
              <SidebarMenuItem
                key={`agency-${item.to}-${index}`}
                name="pom-agency"
                navItem={item}
                menuItem={item.label}
              />
            );
          })}
      </>
    );
  }
  return null;
};
