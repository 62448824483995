import { makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormNumberSelector } from "../../../../../shared/components/form/fields/form-number-selector.field";
import { FormTextField } from "../../../../../shared/components/form/fields/form-text.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { positiveNumberOnly } from "../../../../../utils/validationHelper";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";

const useStyles = makeStyles((theme: Theme) => ({
  formSpacing: {
    marginTop: theme.spacing(4),
  },
}));

interface ICustomPackageFormDialogContentProps {
  productOrderIndex: number;
}

export const CustomPackageFormDialogContent: FunctionComponent<ICustomPackageFormDialogContentProps> = (props) => {
  const { productOrderIndex } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    watch,
    setValue,
    control,
  } = useFormContext<IOrderNewWizardFormInputs>();
  const productOrders = watch(`productOrders`);

  const quantityError = t("orders.new.wizard.package.error.quantity");
  const lengthError = t("orders.new.wizard.package.error.length");
  const widthError = t("orders.new.wizard.package.error.width");

  const positiveNumberOnlyValidation = (value: number | undefined, errorMessage: string) => {
    if (!value) return errorMessage;
    return positiveNumberOnly(value, errorMessage, false);
  };

  const onSelectorQuantityClick = (value: number) => {
    const productOrder = productOrders?.[productOrderIndex];
    const productPackage = productOrder.package;
    if (productPackage) {
      setValue(`productOrders.${productOrderIndex}`, {
        ...productOrder,
        package: {
          ...productPackage,
          containersToBeCollected: value,
        },
      });
    }
  };

  return (
    <>
      <FormField md={12}>
        <FormNumberSelector
          key={"custom-package-containers-to-be-collected"}
          control={control}
          hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.containersToBeCollected)}
          label={t("orders.new.wizard.package.quantity")}
          onSelectorClick={onSelectorQuantityClick}
          {...register(`productOrders.${productOrderIndex}.package.containersToBeCollected` as const, {
            required: true,
            validate: (value) => positiveNumberOnlyValidation(value, quantityError),
          })}
        />
      </FormField>
      <FormField md={12} className={classes.formSpacing}>
        <FormTextField
          hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.length)}
          label={t("orders.new.wizard.package.length")}
          type="number"
          inputProps={{
            endAdornment: <>{"mm"}</>,
          }}
          control={control}
          required
          {...register(`productOrders.${productOrderIndex}.package.length` as const, {
            validate: (value) => positiveNumberOnlyValidation(value, lengthError),
          })}
        />
      </FormField>
      <FormField md={12} className={classes.formSpacing}>
        <FormTextField
          hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.width)}
          label={t("orders.new.wizard.package.width")}
          type="number"
          inputProps={{
            endAdornment: <>{"mm"}</>,
          }}
          required
          control={control}
          name={`productOrders.${productOrderIndex}.package.width`}
          rules={{
            validate: (value) => positiveNumberOnlyValidation(value, widthError),
          }}
        />
      </FormField>
      <FormField md={12} className={classes.formSpacing}>
        <FormTextField
          hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.height)}
          label={t("orders.new.wizard.package.height")}
          type="number"
          inputProps={{
            endAdornment: <>{"mm"}</>,
          }}
          required
          control={control}
          name={`productOrders.${productOrderIndex}.package.height`}
          rules={{
            validate: (value) => positiveNumberOnlyValidation(value, widthError),
          }}
        />
      </FormField>
      <FormField md={12} className={classes.formSpacing}>
        <FormTextField
          hasError={Boolean(errors?.productOrders?.[productOrderIndex]?.package?.weight)}
          label={t("orders.new.wizard.package.weight")}
          type="number"
          inputProps={{
            endAdornment: <>kg</>,
          }}
          required
          control={control}
          rules={{
            validate: (value) => positiveNumberOnlyValidation(value, widthError),
          }}
          name={`productOrders.${productOrderIndex}.package.weight`}
        />
      </FormField>
    </>
  );
};
