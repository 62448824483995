import { VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { GroupMutationConverter } from "../../../../domain/converter/group-mutation.converter";
import { IGroup } from "../../../../domain/group/group";
import { useUpdateGroupBaseData } from "../../../../repositories/queries/group/update-group-base-data.query";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { useCustomForm } from "../../../../util/form.util";
import { AppDialog } from "../../../dialog/app-dialog.component";
import { GroupFormAddressSection } from "../../group-form/group-form-address-section.component";
import { GroupFormBaseDataSection, IGroupBaseDataFormInputs } from "../../group-form/group-form-base-data-section";
import {
  GroupFormRadioButtons,
  IGroupBaseToggleFormInputs,
} from "../../group-form/group-form-radio-buttons-section.component";
import { groupToInitValuesForEdit } from "../../group-form/group-form.util";

interface ICompleteGroupBaseDataFormInputs extends IGroupBaseDataFormInputs, IGroupBaseToggleFormInputs {}

interface IEditGroupBaseDataDialogProps {
  open: boolean;
  group: IGroup;
  onCancel: VoidFunction;
  onSaved: VoidFunction;
}

export const EditGroupBaseDataDialog: VFC<IEditGroupBaseDataDialogProps> = (props) => {
  const { open, group, onCancel, onSaved } = props;
  const { t } = useTranslation();
  const formMethods = useCustomForm<ICompleteGroupBaseDataFormInputs>({
    defaultValues: groupToInitValuesForEdit(group),
    mode: "all",
  });
  const { handleSubmit: onSubmit } = formMethods;
  const queryClient = useQueryClient();
  const { isLoading: isUpdateLoading, mutateAsync: updateGroupBaseData } = useUpdateGroupBaseData();

  const handleSubmit: SubmitHandler<IGroupBaseDataFormInputs> = async (inputs) => {
    const convertedData = GroupMutationConverter.toUpdateGroupBaseDataModel(inputs, group.active);
    await updateGroupBaseData({ id: group.id, updateData: convertedData });
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupForDetails);
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    onSaved();
  };

  return (
    <AppDialog
      title={t("basedata.groups.edit.text")}
      open={open}
      isLoading={isUpdateLoading}
      onCancelClick={onCancel}
      onAcceptClick={() => {
        onSubmit(handleSubmit)();
      }}
      acceptTextOverride={t("general.save.text")}
      alignButtons="space-between"
    >
      <FormProvider {...formMethods}>
        <GroupFormRadioButtons />
        <GroupFormBaseDataSection initialGroupType={group.type} type="edit" groupId={group.id} />
        <GroupFormAddressSection displayDivider={false} />
      </FormProvider>
    </AppDialog>
  );
};
