import { makeStyles } from "@material-ui/core";
import { Colors, Shades } from "./Colors";

export const useOrderNewInfoBoxStyle = makeStyles((theme) => ({
  gridContainer: {
    minHeight: 70,
    borderRadius: 4,
    backgroundColor: Colors.grayLight,
  },
  arrowContainer: {
    height: "100%",
    marginTop: "auto",
    marginBottom: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 55,
      marginTop: "unset",
      marginBottom: "unset",
    },
  },
  columnContainer: {
    height: "100%",
  },

  paddingBottomAndTop: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  paddingLeftAndRight: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  innerContainer: {
    height: "100%",
  },
  iconPadding: {
    paddingLeft: 15,
    paddingRight: 25,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 15,
    },
  },
  gridContainerHover: {
    "&:hover": {
      backgroundColor: Shades.gray50,
      cursor: "pointer",
    },
  },
  arrowPadding: {
    paddingLeft: 15,
    paddingRight: 25,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingRight: 15,
    },
  },
  label: {
    flexGrow: 1,
    paddingTop: 16,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
}));
