import { makeStyles } from "@material-ui/core";

export const usePackageIconStyle = makeStyles((theme) => ({
  bigBagIconSize: {
    height: 48,
    width: 40,
  },
  euroPaletteIconSize: {
    height: 40,
    width: 40,
  },
  transportBarrelIconSize: {
    height: 40,
    width: 40,
  },
  transportBarrelSlimIconSize: {
    height: 40,
    width: 26,
  },
  cardBoardIconSize: {
    height: 43,
    width: 40,
  },
  safetyContainerIconSize: {
    height: 34,
    width: 40,
  },
  loadCarrierIconSize: {
    height: 40,
    width: 40,
  },
  customerContainerIconSize: {
    height: 39,
    width: 40,
  },
  paloxeIconSize: {
    height: 43,
    width: 43,
  },
}));
