import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getOrdersExportByIds = async (orderIds: number[]): Promise<string> => {
  const { data } = await getInstance().get(RestEndpoints.CfmOrdersXExportByIds, {
    params: {
      orderIds,
    },
    responseType: "blob",
  });

  return data;
};

export const useGetOrdersExportByIdsQuery = (orderIds: number[], enabled: boolean = true) => {
  return useQuery([CfmQueryKeys.GetOrdersXExportByIds], () => getOrdersExportByIds(orderIds), {
    enabled,
    // disable cache time, generate always new
    cacheTime: 0,
  });
};
