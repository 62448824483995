import { Divider, Grid, makeStyles } from "@material-ui/core";
import React, { VFC } from "react";
import { ICfmOrderXHistoryModel } from "../../../../repositories/models/order-x/cfm-order-x-history.model";
import { OrderXHistoryRow } from "./order-x-history-row";
import { Shades } from "../../../../../style/Colors";

const useStyles = makeStyles({
  divider: {
    marginTop: 7,
    backgroundColor: Shades.gray20,
  },
});

interface IOrderHistoryProps {
  history: ICfmOrderXHistoryModel[] | undefined;
}

export const OrderXHistory: VFC<IOrderHistoryProps> = (props) => {
  const { history } = props;
  const classes = useStyles();

  return (
    <Grid container className="pl-2 pr-2">
      {history &&
        history.map((entry, index) => (
          <Grid item xs={12} key={index}>
            <OrderXHistoryRow entry={entry} />
            <Divider className={classes.divider} />
          </Grid>
        ))}
    </Grid>
  );
};
