import { useQuery } from "react-query";
import { RestEndpoints } from "../../../api/endpoints";
import { ReactQueryConfig } from "../../../config/react-query-config";
import getInstance from "../../../utils/rest";
import { IPomAnnouncement } from "../../components/announcement-table/pom-announcement-table-data";
import { PomAnnouncementsConverter } from "../models/converter/pom-announcements.converter";
import { PomQueryKeys } from "../pom-query-keys";

const getPomFirstAnnouncement = async (contractId: number | undefined): Promise<IPomAnnouncement> => {
  const { data } = await getInstance().get(RestEndpoints.PomFirstAnnouncement, { params: { contractId } });
  return PomAnnouncementsConverter.toDomain(data);
};

export const useGetPomFirstAnnouncementQuery = (contractId: number | undefined) => {
  return useQuery([PomQueryKeys.GetFirstPomAnnouncement], () => getPomFirstAnnouncement(contractId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: contractId !== undefined,
  });
};
