import { useMutation } from "react-query";
import { ILogoutResponse } from "../../../models/auth/logout.response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { SharedQueryKeys } from "../../shared-query-keys";

const logout = async (): Promise<ILogoutResponse> => {
  const { data } = await getInstance().post<ILogoutResponse>(RestEndpoints.Logout);
  return data;
};

export const useLogoutQuery = () => {
  return useMutation(SharedQueryKeys.Logout, () => logout());
};
