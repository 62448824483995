import { Grid } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import {
  ITruckloadLeanFilter,
  LogisticWarehouseOrderFilter,
} from "../../../pages/order-x/components/order-list-view/logistic-warehouse/logistic-warehouse-order-filter.component";
import { LogisticWarehouseOrderRow } from "../../../pages/order-x/components/order-list-view/logistic-warehouse/logistic-warehouse-order-row.component";
import { EmptyWarehouse } from "../../../pages/order-x/components/order-list-view/warehouse/empty-warehouse.component";
import { OrderOfTruckloadSorting } from "../../../pages/order-x/order-truckload.provider";
import {
  getTruckloadOrderFraction,
  getTruckloadOrderState,
  getTruckloadOrderType,
} from "../../../pages/order-x/utils/truckload.utils";
import { CfmOrderXStatusModel } from "../../../repositories/models/order-x/cfm-order-x.status.model";
import { useGetOrdersQuery } from "../../../repositories/queries/order-x/queries/get-orders.query";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";

interface IAddOrderToTruckloadDialogProps {
  truckload: ICfmTruckloadWithOrders;
  existingOrders: ICfmOrderX[];
  isOpen: boolean;
  onCancel: VoidFunction;
  onAddOrdersToTruckload: (orders: ICfmOrderX[]) => void;
}

export const AddOrderToTruckloadDialog: VFC<IAddOrderToTruckloadDialogProps> = (props) => {
  const { isOpen, onCancel, onAddOrdersToTruckload, truckload, existingOrders } = props;
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ITruckloadLeanFilter>({ sorting: OrderOfTruckloadSorting.WarehouseEntryDate });
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);

  const queryFilter = useMemo(() => {
    return {
      status: CfmOrderXStatusModel.LogisticWarehouse,
      destinationAddressId: truckload.startAddress.id,
      stateId: getTruckloadOrderState(truckload)?.id,
      typeId: getTruckloadOrderType(truckload)?.id,
      fractionId: getTruckloadOrderFraction(truckload)?.id,
      orderOfTruckloadSorting: filter.sorting,
      query: filter.query,
    };
  }, [truckload, filter]);

  const { data: orderData, isLoading } = useGetOrdersQuery(queryFilter);
  const orders = useMemo(() => {
    const existingIds = existingOrders.map((o) => o.id);
    return orderData?.items?.filter((o) => !existingIds.includes(o.id)) ?? [];
  }, [orderData, existingOrders]);

  const resetSelectedOrderIds = () => setSelectedOrderIds([]);

  const onCheckboxChanged = (orderId: number, checked: boolean) => {
    if (checked) {
      setSelectedOrderIds([...selectedOrderIds, orderId]);
    } else {
      setSelectedOrderIds(selectedOrderIds.filter((id) => id !== orderId));
    }
  };

  const isOrderSelected = (orderId: number) => selectedOrderIds.includes(orderId);

  const addOrdersToTruckloadClicked = () => {
    onAddOrdersToTruckload(orders.filter((order) => selectedOrderIds.includes(order.id)));
  };

  return (
    <AppDialog
      open={isOpen}
      title={t("orders.truckload.addOrder")}
      onAcceptClick={addOrdersToTruckloadClicked}
      onCancelClick={onCancel}
      acceptTextOverride={t("orders.truckload.addOrder")}
      isLoading={isLoading}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <LogisticWarehouseOrderFilter
            truckloadFilter={filter}
            setTruckloadFilter={setFilter}
            orders={orders}
            onFilterChanged={resetSelectedOrderIds}
            hideFilter
          />
        </Grid>
        {orders.map((order, index) => (
          <Grid item key={index}>
            <LogisticWarehouseOrderRow
              order={order}
              onCheckboxChange={onCheckboxChanged}
              isSelected={isOrderSelected}
              darkTheme
            />
          </Grid>
        ))}
        {(!orders || !orders.length) && <EmptyWarehouse text={t("orders.truckload.addOrderEmpty")} />}
      </Grid>
    </AppDialog>
  );
};
