import { IConfigModel } from "../../repositories/models/config/config.model";
import { IConfig } from "../config/config";

export class ConfigConverter {
  static toDomain(model: IConfigModel): IConfig {
    return {
      userDefaultPw: model.userDefaultPw,
    };
  }
}
