import { usePlatformContext } from "providers/Auth/platform.provider";
import qs from "qs";
import { FC } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { GroupDetailContent } from "./components/group-detail-content.component";

export interface IGroupDetailParams {
  id: string | undefined;
}

export const GroupDetailPage: FC = () => {
  const { id } = useParams<IGroupDetailParams>();
  const location = useLocation();
  const { activePlatform } = usePlatformContext();

  const createUserOnLoadString = qs.parse(location.search, { ignoreQueryPrefix: true }).createUserOnLoad as
    | string
    | undefined;
  const createUserOnLoad = createUserOnLoadString === "true";

  if (!id) return null;

  return (
    <>
      <PageTitle id={AvailableSharedRoutes.GroupDetail} />
      <GroupDetailContent groupId={Number(id)} platform={activePlatform} createUserOnLoad={createUserOnLoad} />
    </>
  );
};
