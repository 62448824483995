import { Container, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Header } from "../../layout-components/Header/header-component";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    paddingLeft: "40px",
  },
}));

export const DefaultLayout: React.FunctionComponent = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.headerContainer}>
        <Header />
      </div>
      <Container>
        <>{props.children}</>
      </Container>
    </>
  );
};

export default DefaultLayout;
