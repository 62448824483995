import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ReactNode, useMemo, useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { useCustomForm } from "../../../../../shared/util/form.util";
import { AvailableCfmRoutes } from "../../../../../utils/constants";
import { ICfmCreateResponseValueX } from "../../../../domain/order-x/actions/cfm-create-response-value-x";
import { OrderActionServiceDate } from "../../../../domain/order-x/actions/cfm-order-action";
import { ICfmOrderAvailableAction } from "../../../../domain/order-x/actions/cfm-order-available-action";
import { CfmResponseFieldXConverter } from "../../../../repositories/models/converter/cfm-response-field-x.converter";
import { CfmOrderXActionConverter } from "../../../../repositories/models/converter/order-x/cfm-order-x-action.converter";
import { useUpdateOrderXQuery } from "../../../../repositories/queries/order-x/mutations/update-order-x.query";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderActionButton } from "./order-action-button.component";
import { IOrderLogisticMistripAction } from "./order-state-actions/order-logistic-mistrip-action.component";
import { IOrderChangeLogisticAction } from "./order-state-actions/order-x-change-logistic-action.component";
import { IOrderXChangePlannedPickupAction } from "./order-state-actions/order-x-change-planned-pickup.component";
import { IOrderXDestinationAnnouncedAction } from "./order-state-actions/order-x-destination-announced-action.component";
import { IOrderXLogisticAnnouncedAction } from "./order-state-actions/order-x-logistic-announced-action.component";
import { IOrderXLogisticPerformedAction } from "./order-state-actions/order-x-logistic-performed-action.component";
import { IOrderXLogisticWarehouseAction } from "./order-state-actions/order-x-logistic-warehouse-action.component";
import { OrderXActionTypeInput } from "./order-x-action-type.input";
import {
  getAcceptTextForAction,
  getDescriptionForAction,
  getDisplayStyleForAction,
  getTitleForAction,
  getTitleForCompleteAction,
  isAdminCancelAndReOrder,
  isLogisticMistrip,
} from "./order-x-action.util";
import { useSelectedFilesContext } from "./providers/selected-files.provider";
import { ResponseXInput } from "./response-x-input.component";
import { CfmServiceDatesInput } from "./service-date-input.component";

interface IOrderXActionProps {
  action: ICfmOrderAvailableAction;
}

export interface IResponseValueServiceDateFormInputs {
  serviceDates?: OrderActionServiceDate;
}

export interface IResponseFieldValuesFormInputs {
  responseFields: ICfmCreateResponseValueX[];
}

export interface ICfmResponseValueFormInputs
  extends IResponseValueServiceDateFormInputs,
    IResponseFieldValuesFormInputs {
  logisticAnnounced?: IOrderXLogisticAnnouncedAction;
  destinationAnnounced?: IOrderXDestinationAnnouncedAction;
  logisticPerformed?: IOrderXLogisticPerformedAction;
  logisticChanged?: IOrderChangeLogisticAction;
  logisticWarehouse?: IOrderXLogisticWarehouseAction;
  plannedPickupChanged?: IOrderXChangePlannedPickupAction;
  isNotificationDisabled?: boolean;
  logisticMistrip?: IOrderLogisticMistripAction;
}

export const OrderXAction: React.VFC<IOrderXActionProps> = (props) => {
  const { action } = props;
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const { uploadFiles, isLoading: isUploadFilesLoading, reset: resetFileUpload } = useSelectedFilesContext();
  const { isLoading: isUpdateOrderLoading, mutateAsync: updateOrderX } = useUpdateOrderXQuery();
  const history = useHistory();

  const isLoading = isUpdateOrderLoading || isUploadFilesLoading;
  const { order } = useOrderXSelectedContext();
  const productItem = order.items.find((item) => item.product);

  const getDefaultValues = (): ICfmCreateResponseValueX[] => {
    if (!internalUser || !productItem) {
      return [];
    }

    return (
      action.responseFieldAssignments?.map((assignment) =>
        CfmResponseFieldXConverter.assignmentToCreateResponseValueX(assignment, productItem.id, internalUser.id),
      ) ?? []
    );
  };

  const formMethods = useCustomForm<ICfmResponseValueFormInputs>({
    defaultValues: {
      responseFields: getDefaultValues(),
      plannedPickupChanged: {
        plannedPickupDate: order.plannedPickupDate ?? undefined,
      },
      isNotificationDisabled: false,
      logisticMistrip: {
        reOrder: false,
      },
      logisticChanged: {
        changeLogisticStatus: order.status,
        changeLogisticRequestedPickupDate: order.requestedPickupDate ?? undefined
      }
    },
  });

  const [actionDialogOpen, setActionDialogOpen] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ICfmResponseValueFormInputs> = async (inputs): Promise<void> => {
    const filesList = (await uploadFiles()) ?? [];
    await updateOrderX({
      action: CfmOrderXActionConverter.toUpdateRequest(action.type, inputs, filesList),
      orderId: order.id,
    });
    resetFileUpload();
    setActionDialogOpen(false);
    formMethods.reset();
  };

  const handleButtonClick = () => {
    setActionDialogOpen(true);
  };

  const handleDialogCancel = () => {
    resetFileUpload();
    setActionDialogOpen(false);
  };

  const handleSubmitResponseValues = async () => {
    await formMethods.handleSubmit(onSubmit)();
  };

  const getDescriptionWhenNoResponseFieldArePresent = (): ReactNode | undefined => {
    if (action.responseFieldAssignments?.length === 0 && action.serviceDates?.length === 0) {
      return (
        <Alert severity={"info"} className={"mb-3"}>
          <Typography variant="body1">{t(`orders.status.responseDialogGenericMessage`)}</Typography>
        </Alert>
      );
    }
  };

  const isCompleteActionVisible = useMemo(() => {
    return isAdminCancelAndReOrder(action.type, internalUser) || isLogisticMistrip(action.type, internalUser);
  }, [action.type, internalUser]);

  const handleCompleteButton = async () => {
    if (isAdminCancelAndReOrder(action.type, internalUser)) {
      await handleSubmitResponseValues();
      const url = `${AvailableCfmRoutes.OrderXNewRepeat.replace(":id", order.id.toString())}?prefillAll=true`;
      history.push(url);
    }

    if (isLogisticMistrip(action.type, internalUser)) {
      formMethods.setValue("logisticMistrip.reOrder", true);
      await handleSubmitResponseValues();
    }
  };

  return (
    <>
      {actionDialogOpen && (
        <AppDialog
          displayStyle={getDisplayStyleForAction(action.type, t)}
          open={actionDialogOpen}
          onCancelClick={handleDialogCancel}
          onAcceptClick={handleSubmitResponseValues}
          title={getTitleForAction(action.type, t)}
          acceptTextOverride={getAcceptTextForAction(action.type, t)}
          isLoading={isLoading}
          isCompleteVisible={isCompleteActionVisible}
          completeTextOverride={getTitleForCompleteAction(action.type, t)}
          onCompleteClick={handleCompleteButton}
        >
          {getDescriptionForAction(action.type, t) && (
            <Alert severity={getDescriptionForAction(action.type, t)?.severity}>
              <Typography variant="body1">{getDescriptionForAction(action.type, t)?.message}</Typography>
            </Alert>
          )}
          {getDescriptionWhenNoResponseFieldArePresent()}
          <FormProvider {...formMethods}>
            {action.responseFieldAssignments?.map((field, index) => {
              const responseField = action.responseFieldAssignments?.[index];
              if (!responseField) {
                return <></>;
              }
              return (
                <div className={"pb-4"}>
                  <ResponseXInput
                    responseIndex={index}
                    responseFieldId={responseField.responseAssignmentResponseFieldId}
                    fieldType={responseField.type}
                    label={responseField.name}
                    unit={responseField.unit}
                  />
                </div>
              );
            })}
            {action.serviceDates && <CfmServiceDatesInput serviceDates={action.serviceDates} />}
            <OrderXActionTypeInput actionType={action.type} />
          </FormProvider>
        </AppDialog>
      )}
      <OrderActionButton
        title={t(`orders.responseButton.${action.type}`)}
        actionType={action.type}
        handleButtonClick={handleButtonClick}
      />
    </>
  );
};
