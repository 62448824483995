import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AddressMarkerIcon } from "../../../../../components/Primitives";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { IAddress } from "../../../../../shared/domain/address/address";
import { useGetAddressQuery } from "../../../../../shared/repositories/queries/address/get-address.query";
import { Shades } from "../../../../../style/Colors";
import {
  getOpeningHoursTextForTooltip,
  translateCountryByCode,
  trimOpeningHoursTextIfRequired,
} from "../../../../../utils/address.util";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { useOrderNewContext, WizardStep } from "../../order-new.provider";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { SummaryBox } from "./summary-box.component";
import { SummaryLoadingSpinner } from "./summary-loading-spinner.component";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    minHeight: 70,
    border: "2px solid",
    borderColor: Shades.gray50,
  },
  gridContainerBorderRadiusFirstItem: {
    borderRadius: "0px 5px 5px 5px",
  },
  gridContainerBorderRadius: {
    borderRadius: "5px 5px 5px 5px",
  },
  iconSize: {
    height: 40,
    width: 29,
  },
  tooltipHover: {
    "&:hover": {
      cursor: "help",
    },
  },
}));

interface IAddressSummaryProps {
  isFirstSummaryItem: boolean;
}

export const AddressSummary: FunctionComponent<IAddressSummaryProps> = (props) => {
  const { isFirstSummaryItem } = props;
  const classes = useStyles();
  const { watch, setValue } = useFormContext<IOrderNewWizardFormInputs>();
  const addressId = watch("customerAddressId");
  const contactPersonName = watch("contactPersonName");
  const contactPersonEmail = watch("contactPersonEmail");
  const contactPersonPhone = watch("contactPersonPhone");
  const openingHours = watch("openingHours");

  const { data: address, isLoading } = useGetAddressQuery(addressId);
  const { activeStep, setActiveStep, resetProduct, setEditInfo } = useOrderNewContext();
  const { t } = useTranslation();

  const doAddHoverEffect = (openingHours: string | undefined) => {
    return getOpeningHoursTextForTooltip(openingHours, t).length > 0;
  };

  const getLabel = (address: IAddress) => {
    const openingHoursText = openingHours ? `${TEXT_SEPARATOR} ${trimOpeningHoursTextIfRequired(openingHours)}` : "";
    return (
      <>
        <Grid item>
          <Typography variant="body1">
            <b>{`(${address.id}) ${address.name}`}</b>
            {`${TEXT_SEPARATOR} ${address.address} ${address.buildingNumber}${TEXT_SEPARATOR} ${address.postal} ${
              address.city
            }${TEXT_SEPARATOR} ${address.country ? translateCountryByCode(address.country, t) : "-"}`}
          </Typography>
        </Grid>
        <Grid item>
          <NewOrderToolTip title={getOpeningHoursTextForTooltip(openingHours, t)}>
            <Typography
              variant="body1"
              className={classNames({ [classes.tooltipHover]: doAddHoverEffect(openingHours) })}
            >{`${contactPersonName ?? "-"}${TEXT_SEPARATOR} ${contactPersonEmail ?? "-"}${TEXT_SEPARATOR} ${
              contactPersonPhone ?? "-"
            }${openingHoursText}`}</Typography>
          </NewOrderToolTip>
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      direction="row"
      className={classNames(classes.gridContainer, {
        [classes.gridContainerBorderRadiusFirstItem]: isFirstSummaryItem,
        [classes.gridContainerBorderRadius]: !isFirstSummaryItem,
      })}
    >
      {isLoading && <SummaryLoadingSpinner />}
      {!isLoading && address && (
        <SummaryBox
          iconComponent={<AddressMarkerIcon classes={{ root: classes.iconSize }} />}
          labelComponent={getLabel(address)}
          onEditClick={() => {
            setEditInfo({ editClicked: true, stepBeforeEditClicked: activeStep });
            setActiveStep(WizardStep.CustomerAddress);
          }}
          onDeleteClick={() => {
            resetProduct(setValue);
            setActiveStep(WizardStep.CustomerAddress);
          }}
        />
      )}
    </Grid>
  );
};
