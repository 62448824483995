export interface IPassword {
  normalPassword: string;
  validationPassword: string;
}

export interface IPasswordResetData extends IPassword {
  oldPassword: string;
}

export const cleanPassword = (pwd: IPasswordResetData) => {
  const password = pwd;
  if (password.normalPassword) {
    password.normalPassword = password.normalPassword.trim();
  }
  if (password.validationPassword) {
    password.validationPassword = password.validationPassword.trim();
  }
  if (password.oldPassword) {
    password.oldPassword = password.oldPassword.trim();
  }
  return password;
};
