import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";

const updateCfmProductActive = async (productId: number, isActive: boolean): Promise<void> => {
  const url = RestEndpoints.UpdateCfmProductActive.replace(":id", productId.toString());
  await getInstance().patch(url, {
    isActive,
  });
};

export const useUpdateCfmProductActiveQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    CfmQueryKeys.UpdateProductName,
    ({ productId, isActive }: { productId: number; isActive: boolean }) => updateCfmProductActive(productId, isActive),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CfmQueryKeys.GetAllProductsInfinite);
        queryClient.invalidateQueries(CfmQueryKeys.GetProductCount);
        queryClient.invalidateQueries(CfmQueryKeys.GetProductStates);
        queryClient.invalidateQueries(CfmQueryKeys.GetProductFractions);
        queryClient.invalidateQueries(CfmQueryKeys.GetProductTypes);
        queryClient.invalidateQueries(CfmQueryKeys.GetProductCategories);
        enqueueSnackbar(t("general.successfull_saved"), { variant: "success" });
      },
    },
  );
};
