import { CfmResponseFieldXConverter } from "../cfm-response-field-x.converter";
import { CfmOrderXActionConverter } from "./cfm-order-x-action.converter";
import { CfmServiceDateConverter } from "./cfm-service-date.converter";
import { ICfmOrderBulkActionModel } from "../../order-x/action/cfm-order-bulk-action.model";
import { ICfmResponseValueFormInputs } from "../../../../pages/order-x/components/actions/order-x-action.component";
import { CfmOrderXResponseValueConverter } from "./cfm-order-x-response-value.converter";
import { CfmServiceDateModel } from "../../order-x/action/cfm-service-date.model";
import { formatDateYearMonthDay } from "../../../../../shared/util/date.util";
import { ICfmOrderActionModel } from "../../order-x/action/cfm-order-x-action.model";
import { ISelectedFilesList } from "../../../../pages/order-x/components/actions/providers/selected-files.provider";
import { ICfmOrderBulkAction } from "../../../../domain/order-x/actions/cfm-order-bulk-action";
import { ICfmOrderAvailableAction } from "../../../../domain/order-x/actions/cfm-order-available-action";
import { removeNullAndUndefined } from "../../../../../utils/filter.util";

export class CfmOrderBulkActionConverter {
  public static toUpdateRequest(
    bulk: ICfmOrderAvailableAction,
    inputs: ICfmResponseValueFormInputs,
    files: ISelectedFilesList[],
    itemId: number,
  ): ICfmOrderActionModel {
    // Override itemIds for bulk -> Inputs for bulk operations do not contain correct itemIds matched for order
    const responseFields = inputs.responseFields
      .map((responsefield, index) => ({
        ...responsefield,
        itemId,
        responseAssignmentResponseFieldId: bulk?.responseFieldAssignments?.[index]?.responseAssignmentResponseFieldId,
      }))
      .filter(removeNullAndUndefined);

    const plannedPickupDate =
      inputs.logisticAnnounced?.plannedPickupDate ?? inputs.plannedPickupChanged?.plannedPickupDate;

    return {
      type: CfmOrderXActionConverter.actionToModel(bulk.type),
      responseFields: responseFields
        .map((field) => {
          if (!field || field.responseAssignmentResponseFieldId === undefined) {
            return undefined;
          }
          return CfmOrderXResponseValueConverter.createToModel(
            //@ts-ignore
            field,
            files.find((file) => file.responseAssignmentId === field.responseAssignmentResponseFieldId),
          );
        })
        .filter(removeNullAndUndefined),
      serviceDates: {
        [CfmServiceDateModel.Delivery]: inputs.serviceDates?.delivery
          ? formatDateYearMonthDay(inputs.serviceDates.delivery)
          : undefined,
        [CfmServiceDateModel.Pickup]: inputs.serviceDates?.pickup
          ? formatDateYearMonthDay(inputs.serviceDates.pickup)
          : undefined,
        [CfmServiceDateModel.Recycling]: inputs.serviceDates?.recycling
          ? formatDateYearMonthDay(inputs.serviceDates.recycling)
          : undefined,
        [CfmServiceDateModel.Treatment]: inputs.serviceDates?.treatment
          ? formatDateYearMonthDay(inputs.serviceDates.treatment)
          : undefined,
      },
      plannedPickupDate: plannedPickupDate ? formatDateYearMonthDay(plannedPickupDate) : undefined,
      actualPickupDate: inputs.logisticPerformed?.actualPickupDate
        ? formatDateYearMonthDay(inputs.logisticPerformed?.actualPickupDate)
        : undefined,
      plannedDeliveryDate: inputs.destinationAnnounced?.plannedDeliveryDate
        ? formatDateYearMonthDay(inputs.destinationAnnounced?.plannedDeliveryDate)
        : undefined,
      comment: inputs.plannedPickupChanged?.comment,
      cancelationReasonId: inputs.logisticMistrip?.cancelationReasonId,
      reOrder: inputs.logisticMistrip?.reOrder,
    };
  }

  public static toDomain(model: ICfmOrderBulkActionModel): ICfmOrderBulkAction {
    return {
      availableOrderBulks: model.availableOrderBulks.map((bulks) => {
        return {
          orderId: bulks.orderId,
          availableActions: bulks.availableActions.map((action) => {
            return {
              type: CfmOrderXActionConverter.actionToDomain(action.type),
              serviceDates: action.serviceDates?.map(CfmServiceDateConverter.toDomain),
              responseFieldAssignments: action.responseFieldAssignments?.map(CfmResponseFieldXConverter.toDomain),
            };
          }),
        };
      }),
    };
  }
}
