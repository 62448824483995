import { StatusCodes } from "http-status-codes";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance, { instanceOfAxiosError } from "../../../../../utils/rest";
import { UserConverter } from "../../../../domain/converter/user.converter";
import { IMutateUserRequest } from "../../../../domain/requests/user-create.request";
import { IUser } from "../../../../domain/user/user";
import { IUserModel } from "../../../../models/user/user.model";
import { SharedQueryKeys } from "../../shared-query-keys";

const createUser = async (createData: IMutateUserRequest): Promise<IUser> => {
  const { data } = await getInstance().post<IUserModel>(RestEndpoints.CreateUser, createData);
  return UserConverter.toDomain(data);
};

export const useCreateUserQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    SharedQueryKeys.CreateUser,
    ({ createData }: { createData: IMutateUserRequest }) => createUser(createData),
    {
      onError: (error) => {
        if (instanceOfAxiosError(error) && error.response?.status !== StatusCodes.INTERNAL_SERVER_ERROR) {
          const errorMsg = getErrorCode(error);
          const errorCode = getErrorStatusCode(error);
          enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
        }
      },
      onSuccess: () => {
        enqueueSnackbar(t("general.user.create_success"), { variant: "success" });
        void queryClient.invalidateQueries(SharedQueryKeys.GetUser);
      },
    },
  );
};
