import { Box, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  ResponseFieldBillingRelevantIcon,
  ResponseFieldWarehouseArticleIcon,
} from "../../../../../components/Primitives/Icons";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { PrimaryColors } from "../../../../../style/Colors";
import { IResponseFieldData } from "../../../../pages/product-response/product-response-assignment.provider";
import { IdBox } from "../../id-box.component";
import { ResponseFieldTypeIcon } from "./response-field-type-icon.component";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginBottom: 10,
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  lastContainer: {
    "&:last-child": {
      marginBottom: 11,
    },
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 16,
    width: 16,
  },
  hoverToolTip: {
    "&:hover": {
      cursor: "help",
    },
  },
}));

interface IResponseFieldDetailLabelProps {
  field: IResponseFieldData;
  index: number;
}

export const ResponseFieldDetailLabel: VFC<IResponseFieldDetailLabelProps> = (props) => {
  const { field, index } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const fieldName = useMemo(() => {
    const unit = field.unit ?? t("configuration.responseField.noUnit");
    return `${field.name} [${unit}]`;
  }, [field, t]);

  return (
    <Grid container direction="column">
      <Grid item>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item className={classes.lastContainer}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="body1">{`${index + 1}.`}</Typography>
          </Grid>
          {field.internalNumber && (
            <Grid item>
              <IdBox id={field.internalNumber} />
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1">{fieldName}</Typography>
          </Grid>
          <Grid item>
            <ResponseFieldTypeIcon type={field.type} />
          </Grid>
          {field.serviceDate && (
            <Grid item>
              <NewOrderToolTip
                title={t(`configuration.responseAssignments.billingRelevant`)}
                className={classes.hoverToolTip}
                placement="top"
              >
                <Box>
                  <ResponseFieldBillingRelevantIcon className={classes.iconStyle} />
                </Box>
              </NewOrderToolTip>
            </Grid>
          )}
          {field.warehouseArticleId && (
            <Grid item>
              <NewOrderToolTip
                title={t(`configuration.responseAssignments.warehouseArticle`)}
                className={classes.hoverToolTip}
                placement="top"
              >
                <Box>
                  <ResponseFieldWarehouseArticleIcon className={classes.iconStyle} />
                </Box>
              </NewOrderToolTip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
