import { Grid } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useOrderNewInfoBoxStyle } from "../../../../../style/order-new-info-box.style";

interface IOrderNewInfoBoxDesktopProps {
  iconComponent: JSX.Element;
  labelComponent: JSX.Element;
  onClick: VoidFunction;
  renderNextArrow: () => JSX.Element;
  renderIsNew: () => JSX.Element | null;
}

export const OrderNewInfoBoxDesktop: React.FC<IOrderNewInfoBoxDesktopProps> = (props) => {
  const { iconComponent, labelComponent, onClick, renderNextArrow, renderIsNew } = props;
  const classes = useOrderNewInfoBoxStyle();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classNames(classes.gridContainer, classes.gridContainerHover)}
      onClick={() => {
        onClick();
      }}
    >
      <Grid item style={{ flexGrow: 1 }} xs>
        <Grid container direction="row" className={classes.innerContainer}>
          <Grid item xs="auto">
            <Grid
              container
              direction="column"
              justifyContent="center"
              className={classNames(classes.innerContainer, classes.iconPadding)}
            >
              {iconComponent}
            </Grid>
          </Grid>
          <Grid item xs={12} md className={classes.label}>
            <Grid container direction="column" justifyContent="center" className={classes.innerContainer}>
              {labelComponent}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs="auto" item className={classNames(classes.arrowContainer)}>
        <Grid container direction="row">
          <Grid item>{renderIsNew()}</Grid>
          <Grid item>{renderNextArrow()}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
