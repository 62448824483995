import { makeStyles } from "@material-ui/core";
import { Colors, Shades } from "../../../../../style/Colors";

export const useOrderCardStatusIndicatorStyles = makeStyles((theme) => ({
  label: {
    width: 24,
  },
  connector: {
    minWidth: 70,
    "& .MuiStepConnector-line": {
      borderWidth: 3,
      borderColor: Shades.gray30,
    },
    "&.MuiStepConnector-active": {
      "& .MuiStepConnector-line": {
        borderColor: `${Colors.greenLight}`,
      },
    },
    "&.MuiStepConnector-completed": {
      "& .MuiStepConnector-line": {
        borderColor: `${Colors.greenLight}`,
      },
    },
  },
  stepper: {
    backgroundColor: "transparent",
    height: 100,
    paddingLeft: 0,
    paddingRight: 0,
  },
  stepperContainer: {
    overflowX: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  forwardIconContainer: {
    height: "100%",
  },
  forwardIcon: {
    transform: "rotate(180deg)",
    color: Colors.greenLight,
    marginTop: 4,
  },
}));
