export enum RolePom {
  Announcer = "announcer",
  MainContact = "main_contact",
}

export const getRolePomTranslation = (role: RolePom): string => {
  switch (role) {
    case RolePom.Announcer:
      return "basedata.users.edit.pom_role.announcer";
    case RolePom.MainContact:
      return "basedata.users.edit.pom_role.main_contact";
  }
};
