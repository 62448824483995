import { AddEntryIcon, BaseDataIcon } from "../components/Primitives";
import { escapeParam, INavigationItem } from "../shared/domain/navigation/navigation-item";
import { AvailablePomRoutes, AvailableSharedRoutes } from "../utils/constants";

export function getPomAdminNavItems(): INavigationItem[] {
  return [
    {
      label: "navigation.basedata.label",
      icon: BaseDataIcon,
      to: "",
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.basedata.users.label",
          to: AvailableSharedRoutes.UserList,
          visible: true,
          invisibleChildrenUrls: [
            AvailableSharedRoutes.UserCreate,
            escapeParam(AvailableSharedRoutes.UserDetail, ":id"),
            escapeParam(AvailableSharedRoutes.UserEdit, ":id"),
          ],
        },
        {
          label: "basedata.users.create",
          to: AvailableSharedRoutes.UserCreate,
          visible: false,
        },
        {
          label: "basedata.users.edit.text",
          to: AvailableSharedRoutes.UserEdit,
          visible: false,
        },
        {
          label: "basedata.users.detail",
          to: AvailableSharedRoutes.UserDetail,
          visible: false,
        },
        {
          label: "navigation.basedata.groups.label",
          to: AvailableSharedRoutes.GroupList,
          visible: true,
          invisibleChildrenUrls: [
            AvailablePomRoutes.ManufacturerCreate,
            escapeParam(AvailablePomRoutes.ManufacturerDetail, ":id"),
            escapeParam(AvailablePomRoutes.ManufacturerEdit, ":id"),
          ],
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerCreate,
          visible: false,
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerDetail,
          visible: false,
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerEdit,
          visible: false,
        },
      ],
    },
  ];
}

export function getPomMainContactNavItems(groupId: number | undefined): INavigationItem[] {
  return [
    {
      label: "navigation.basedata.label",
      icon: BaseDataIcon,
      to: "/portal/groups",
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.basedata.groups.account",
          to: AvailableSharedRoutes.GroupDetail.replace(":id", groupId?.toString() ?? ""),
          visible: true,
          invisibleChildrenUrls: [
            AvailablePomRoutes.ManufacturerCreate,
            escapeParam(AvailablePomRoutes.ManufacturerDetail, ":id"),
            escapeParam(AvailablePomRoutes.ManufacturerEdit, ":id"),
          ],
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerCreate,
          visible: false,
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerDetail,
          visible: false,
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerEdit,
          visible: false,
        },
      ],
    },
    {
      label: "navigation.pom.batt_g.register.text",
      icon: AddEntryIcon,
      to: AvailableSharedRoutes.BattG,
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.pom.batt_g.register.master_data",
          to: AvailableSharedRoutes.BattGMasterData,
          visible: true,
        },
      ],
    },
  ];
}

export function getPomAgencyNavItems(groupId: number | undefined): INavigationItem[] {
  return [
    {
      label: "navigation.basedata.label",
      icon: BaseDataIcon,
      to: "/portal/pom/groups",
      visible: true,
      isParent: true,
      content: [
        {
          label: "navigation.basedata.groups.label",
          to: AvailableSharedRoutes.GroupDetail.replace(":id", groupId?.toString() ?? ""),
          visible: true,
          invisibleChildrenUrls: [
            AvailablePomRoutes.ManufacturerCreate,
            escapeParam(AvailablePomRoutes.ManufacturerDetail, ":id"),
            escapeParam(AvailablePomRoutes.ManufacturerEdit, ":id"),
          ],
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerCreate,
          visible: false,
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerDetail,
          visible: false,
        },
        {
          label: "",
          to: AvailablePomRoutes.ManufacturerEdit,
          visible: false,
        },
      ],
    },
  ];
}
