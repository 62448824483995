import { VFC } from "react";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { EditTruckloadFooterMobileView } from "./edit-truckload-footer-mobile-view.component";
import { EditTruckloadFooterView } from "./edit-truckload-footer-view.component";
import { TruckloadState } from "../../../domain/truckload/truckload-state";

interface IEditTruckloadFooterProps {
  continueActionsDisabled: boolean;
  onCancel: VoidFunction;
  onCancelTruckload: VoidFunction;
  onSave: VoidFunction;
  onRespondTruckload: VoidFunction;
  weight: number;
  truckloadState: TruckloadState;
}

export const EditTruckloadFooter: VFC<IEditTruckloadFooterProps> = (props) => {
  const { onCancel, onCancelTruckload, onSave, onRespondTruckload, continueActionsDisabled, weight, truckloadState } =
    props;
  const { isMobile } = useScreenSize();

  return (
    <>
      {!isMobile && (
        <EditTruckloadFooterView
          continueActionsDisabled={continueActionsDisabled}
          onCancel={onCancel}
          onCancelTruckload={onCancelTruckload}
          onSave={onSave}
          onRespondTruckload={onRespondTruckload}
          weight={weight}
          truckloadState={truckloadState}
        />
      )}
      {isMobile && (
        <EditTruckloadFooterMobileView
          continueActionsDisabled={continueActionsDisabled}
          onCancel={onCancel}
          onCancelTruckload={onCancelTruckload}
          onSave={onSave}
          onRespondTruckload={onRespondTruckload}
          weight={weight}
          truckloadState={truckloadState}
        />
      )}
    </>
  );
};
