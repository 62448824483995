import { Chip, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    textAlign: "center",
  },
}));

interface IBadgeProps {
  count: number;
  backgroundColor: string;
  textColor: string;
}

export const Badge: VFC<IBadgeProps> = (props) => {
  const { count, backgroundColor, textColor } = props;
  const classes = useStyles();

  return (
    <Chip
      label={
        <Typography variant="body2" className={classes.text} style={{ color: textColor }}>
          {count}
        </Typography>
      }
      size="small"
      style={{ backgroundColor }}
    />
  );
};
