import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { IIconsBaseProps } from "../../../components/Primitives/Icons";
import { useSidebarStyle } from "../../../style/sidebar.style";
import { useSidebarContext } from "./sidebar-context";

interface IMenuListItemProps {
  path: string;
  MenuIcon: FunctionComponent<IIconsBaseProps>;
  title: string;
}

export const MenuListItem: React.FC<IMenuListItemProps> = (props) => {
  const history = useHistory();
  const classes = useSidebarStyle();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setSidebarOpen, isFixed } = useSidebarContext();
  const { path, MenuIcon, title, children } = props;

  const handleNavigationItemClick = (path: string): void => {
    history.push(path);
    !isFixed && setSidebarOpen(false);
  };

  const isActivePath = (path: string) => {
    return path === pathname;
  };

  return (
    <ListItem button dense onClick={() => handleNavigationItemClick(path)} className={classes.listItem}>
      <ListItemIcon className={classes.menuListItemIcon}>
        <MenuIcon svgColor="green" />
      </ListItemIcon>
      <ListItemText primary={t(title)} className={isActivePath(path) ? classes.activeListTitle : classes.listTitle} />
      {children}
    </ListItem>
  );
};
