import { TranslateFunc } from "../../../../../utils/translation.utils";
import { CfmOrderXApiStatus, CfmOrderXStatus, CfmOrderXUiStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { GroupType } from "../../../../../shared/domain/group/group-type";

export const getStatusXName = (
  status: CfmOrderXStatus,
  t: TranslateFunc,
  groupType?: GroupType,
  hasPlannedDeliveryDate?: boolean,
  isTruckload?: boolean,
) => {
  let key = `orders.status.${CfmOrderXApiStatus[status]}`;

  if (groupType === GroupType.CfmLogistics) {
    key = `orders.status.logistic.${CfmOrderXApiStatus[status]}`;
  }

  if (groupType === GroupType.CfmRecycler) {
    if (status === CfmOrderXUiStatus.LogisticAnnouncedWithoutPlannedDeliveryDate) {
      key = `orders.status.recycler.LogisticAnnouncedWithoutPlannedDeliveryDate`;
    } else if (
      (status === CfmOrderXApiStatus.RecyclerIncomingWarehouse ||
        status === CfmOrderXUiStatus.RecyclerIncomingWarehouseWithoutTruckload) &&
      !isTruckload
    ) {
      key = `orders.status.recycler.RecyclerIncomingWarehouseWithoutTruckload`;
    } else if (status === CfmOrderXUiStatus.SorterWarehouse) {
      key = `orders.status.recycler.SorterWarehouse`;
    } else if (status === CfmOrderXUiStatus.LogisticAnnouncedWithPlannedDeliveryDate) {
      key = `orders.status.recycler.LogisticAnnouncedWithPlannedDeliveryDate`;
    } else {
      key = `orders.status.recycler.${CfmOrderXApiStatus[status]}`;
    }
  }

  return t(key);
};
