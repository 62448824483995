import { PomAnnouncementStatusModel } from "../../repositories/models/announcements/pom-announcements.model";
import { PomProductUnit } from "../product-table/pom-product-table-data";
import { PomAnnouncementPeriod } from "../../repositories/models/announcements/pom-announcement.period";

export interface IPomAnnouncement {
  id?: number;
  announcementId?: number;
  announcementDate: Date;
  status: PomAnnouncementStatusModel;
  products: IPomAnnouncementTableData[];
  announcementPeriod: PomAnnouncementPeriod;
  purchaseOrderNumber: string;
  createdAt: Date;
}

export interface IPomAnnouncementTableData {
  id: number;
  articleNumber: string;
  articleName: string;
  type: string;
  weightClass: string;
  itemPrice: number;
  unit: PomProductUnit;
  count?: number;
  totalWeight?: number;
  totalPrice?: number;
  chemistry: string;
  takeBackSystem: string;
  history?: IPomAnnouncementTableData[];
  createdByAdminName?: string | null;
  createdByUserName?: string;
  createdAt?: Date;
  lowerWeightLimitInGram: number | null;
  upperWeightLimitInGram: number | null;
  isInvalid?: boolean;
}

export const sortPomAnnouncementTableData = (a: IPomAnnouncementTableData, b: IPomAnnouncementTableData): number => {
  const createdAtTimeA = a.createdAt ? new Date(a.createdAt).getTime() : Number.MAX_SAFE_INTEGER;
  const createdAtTimeB = b.createdAt ? new Date(b.createdAt).getTime() : Number.MAX_SAFE_INTEGER;

  return createdAtTimeA - createdAtTimeB || a.articleNumber.localeCompare(b.articleNumber);
};
