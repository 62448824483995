import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IAddress } from "../../../domain/address/address";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { SharedQueryKeys } from "../shared-query-keys";
import { IAddressModel } from "./../../../models/address/address.model";

export const getAddress = async (id: number | undefined): Promise<IAddress | undefined> => {
  if (!id) return undefined;
  const url = replaceUriParameter(RestEndpoints.AddressById, "id", id);
  const response = await getInstance().get<IAddressModel>(url);
  return AddressConverter.toDomain(response.data);
};

export const useGetAddressQuery = (id: number | undefined) => {
  return useQuery([SharedQueryKeys.GetAddress, id], () => getAddress(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(id),
  });
};
