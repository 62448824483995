export interface ICfmPackage {
  id: number;
  uuid: string;
  name: string | null;
  length: number | null;
  width: number | null;
  height: number | null;
  unit: string | null;
  active: Date | null;
  measurementsRequired: boolean;
  tareWeightKg: number | null;
  averageGrossWeightKg: number | null;
  imageUrl: string | null;
  withPackageDelivery?: boolean;
}

export interface ICfmCreatePackage {
  packageId: number;
  length: number | undefined;
  height: number | undefined;
  width: number | undefined;
  weight: number | undefined;
  measurementsRequired: boolean;
  containersToBeDelivered: number | undefined;
  containersToBeCollected: number | undefined;
  tareWeightKg: number | null;
  averageGrossWeightKg: number | null;
  withPackageDelivery?: boolean;
}

export const sortPackages = (a: ICfmPackage, b: ICfmPackage) => {
  return (a.name ?? "").localeCompare(b.name ?? "");
};
