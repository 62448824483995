import { FormControl, Grid, LinearProgress, TextField } from "@material-ui/core";
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FormHeader } from "shared/components/form/form-header.component";
import useAsyncEffect from "use-async-effect";
import isEmail from "validator/lib/isEmail";
import { AutofillCatcher } from "../../../../components/AutofillHelper/autofill-catcher";
import { usePasswordAnalyser } from "../../../../hooks/usePasswordAnalyser";
import { useUnsavedDataContext } from "../../../../providers/App/unsaved-data-context";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { AvailableSharedRoutes, getTitles } from "../../../../utils/constants";
import { passwordAnalyser } from "../../../../utils/passwordAnalyser";
import { IGroup } from "../../../domain/group/group";
import { isAdmin, isPomMainContact, IUser } from "../../../domain/user/user";
import { UserTypeLevel } from "../../../domain/user/user-type-level";
import { useCustomForm } from "../../../util/form.util";
import { ALL_ADDRESSES_OPTION } from "../../address/address-form/address-form.util";
import { AppDialogButtonRow } from "../../dialog/app-dialog-button-row.component";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormCheckbox } from "../../form/fields/form-checkbox.field";
import { FormPasswordField } from "../../form/fields/form-password.field";
import { FormPhoneCountryField } from "../../form/fields/form-phone-country.field";
import { FormSwitch } from "../../form/fields/form-switch.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { IMutateUserFormInputs } from "../create-user-form/create-user-form.component";
import { UserFormRoleSelects } from "./user-form-role-selects.component";
import { useUserFormContext } from "./user-form.provider";
import { userToInitialValues } from "./user-form.util";
import { GroupType } from "../../../domain/group/group-type";

export enum UserFormMode {
  Detail,
  Edit,
  Create,
}

export interface IUserInitialValues extends Partial<IUser> {
  password?: string;
  confirmPassword?: string;
}

interface IUserFormProps {
  userGroup?: IGroup;
  user?: IUserInitialValues;
  shownAsModal?: boolean;
  onCancelCallback?: () => void | undefined;
  onSuccessCallback?: (user: IUser | undefined) => void | undefined;
  mode: UserFormMode;
  displayHeading?: boolean;
  jobTitleAndExternalIdHidden?: boolean;
}

export const UserForm: FC<IUserFormProps> = ({
  user,
  mode,
  userGroup,
  shownAsModal = false,
  displayHeading = true,
  onCancelCallback,
  onSuccessCallback,
  jobTitleAndExternalIdHidden = false,
}: IUserFormProps) => {
  const { groups, isLoading, handleFormSubmit, groupAddresses } = useUserFormContext();
  const defaultValues = useMemo(() => userToInitialValues(user, userGroup), [user, userGroup]);
  const formMethods = useCustomForm<IMutateUserFormInputs>({ defaultValues });
  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    reset,
    resetField,
    setValue,
    formState: { errors, isDirty },
  } = formMethods;

  const { t } = useTranslation();
  const { setHasUnsavedData } = useUnsavedDataContext();
  const { internalUser } = useAuthContext();
  const { analyser, analysis } = usePasswordAnalyser();
  const history = useHistory();
  const [userTypeLevelSelectOptions, setUserTypeLevelSelectOptions] = useState<UserTypeLevel[]>([
    UserTypeLevel.Corporate,
  ]);
  // @ts-ignore - fix circular dependency
  const password = watch("password");
  const group = watch("group");
  const userTypeLevel = watch("userTypeLevel");
  const rolePom = watch("rolePom");

  const inputsDisabled = mode === UserFormMode.Detail;

  useEffect(() => {
    setHasUnsavedData(isDirty);
  }, [setHasUnsavedData, isDirty]);

  useEffect(() => {
    if (mode !== UserFormMode.Detail) return;
    reset(userToInitialValues(user, userGroup));
  }, [mode, user, userGroup, reset]);

  const groupHasSelectableAddresses = useCallback(() => {
    return groupAddresses.some((g) => g.id !== ALL_ADDRESSES_OPTION);
  }, [groupAddresses]);

  const setCfmUserTypeLevelSelectOptions = useCallback(() => {
    const options = [UserTypeLevel.Corporate];

    if (groupHasSelectableAddresses()) {
      options.push(UserTypeLevel.WasteProducer);
    }

    setUserTypeLevelSelectOptions(options);
  }, [groupHasSelectableAddresses]);

  useAsyncEffect(async () => {
    if (group?.id && group?.uuid) {
      resetField("userAddresses");
    }
  }, [group]);

  useEffect(() => {
    setCfmUserTypeLevelSelectOptions();
    if (groupHasSelectableAddresses()) {
      setValue("userTypeLevel", defaultValues?.userTypeLevel);
    }
  }, [setValue, groupHasSelectableAddresses, setCfmUserTypeLevelSelectOptions, defaultValues?.userTypeLevel]);

  const onSubmit: SubmitHandler<IMutateUserFormInputs> = async (inputs) => {
    // ensure admin can create admin users for other admin groups or own group
    const isCreateUserForAdminGroup = userGroup && userGroup.type === GroupType.Admin && isAdmin(internalUser);
    if (!userTypeLevel && !rolePom && !isCreateUserForAdminGroup) {
      return;
    }

    setHasUnsavedData(false);
    const mutatedUser = await handleFormSubmit(inputs, mode, onSuccessCallback === undefined);
    if (mutatedUser) {
      if (onSuccessCallback) onSuccessCallback(mutatedUser);
      reset();
    }
  };

  const getGroupAutocomplete = (): ReactNode | undefined => {
    if (userGroup) {
      return;
    }

    return (
      <>
        <FormField>
          <FormAutocomplete<IGroup>
            name={"group"}
            control={control}
            label={t("basedata.users.edit.groupName")}
            getOptionSelected={(option, value) => option.id === value.id}
            options={groups}
            error={Boolean(errors?.group)}
            rules={{ required: true }}
            getOptionLabel={(group) => group?.name}
            disabled={inputsDisabled}
          />
        </FormField>
        <FormField />
      </>
    );
  };

  const getHeadingText = (): string => {
    switch (mode) {
      case UserFormMode.Edit:
        return t("basedata.users.edit.text");
      case UserFormMode.Detail:
        return `${t("basedata.users.detail")} - ID ${user?.id ?? ""}`;
      case UserFormMode.Create:
        return t("general.user.data");
    }
  };

  const handleOkClick = (): void => {
    if (mode === UserFormMode.Detail && user !== undefined && user.id && !isPomMainContact(internalUser)) {
      history.push(AvailableSharedRoutes.UserEdit.replace(":id", user.id.toString()));
    } else if (mode === UserFormMode.Detail && isPomMainContact(internalUser)) {
      onCancelCallback?.();
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const getDialogOkText = (): string => {
    if (mode === UserFormMode.Detail && !isPomMainContact(internalUser)) {
      return t("general.edit.text");
    } else if (mode === UserFormMode.Detail && isPomMainContact(internalUser)) {
      return t("general.back.text");
    } else {
      return t("general.save.text");
    }
  };

  return (
    <form>
      {/* do not remove, only reliable way to prevent chrome from pre filling fields */}
      <AutofillCatcher />
      {displayHeading && <FormHeader>{getHeadingText()}</FormHeader>}
      {isLoading && <LinearProgress />}

      {!isLoading && (
        <FormProvider {...formMethods}>
          <Grid container direction="row" spacing={4}>
            <FormField md={12}>
              <FormSwitch
                name="active"
                control={control}
                label={t("basedata.users.edit.active")}
                disabled={inputsDisabled}
              />
            </FormField>

            {getGroupAutocomplete()}

            <FormField>
              <FormAutocomplete<string>
                name={"title"}
                control={control}
                required={true}
                label={t("basedata.users.edit.title")}
                options={getTitles(t)}
                error={Boolean(errors?.title)}
                helperText={errors?.title?.message}
                rules={{ required: true }}
                getOptionLabel={(title) => title}
                disabled={inputsDisabled}
              />
            </FormField>

            {!jobTitleAndExternalIdHidden && (
              <FormField>
                <FormTextField
                  required={false}
                  hasError={Boolean(errors?.jobTitle)}
                  label={t("basedata.users.edit.jobtitle")}
                  disabled={inputsDisabled}
                  control={control}
                  name={"jobTitle"}
                />
              </FormField>
            )}
            {jobTitleAndExternalIdHidden && <FormField />}

            <FormField>
              <FormTextField
                hasError={Boolean(errors?.firstName)}
                label={t("basedata.users.edit.firstName")}
                disabled={inputsDisabled}
                control={control}
                name={"firstName"}
                rules={{ required: true }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors?.lastName)}
                label={t("basedata.users.edit.lastName")}
                disabled={inputsDisabled}
                control={control}
                name={"lastName"}
                rules={{ required: true }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors?.email)}
                errorMessage={errors?.email?.message}
                label={t("basedata.users.edit.loginEmail")}
                disabled={inputsDisabled}
                control={control}
                name={"email"}
                rules={{
                  required: true,
                  validate: async (value) => {
                    if (!value || !isEmail(value)) {
                      return t("general.email.required.error");
                    }
                  },
                }}
              />
            </FormField>

            <FormField>
              <FormTextField
                hasError={Boolean(errors?.deliveryEmail)}
                errorMessage={errors?.deliveryEmail?.message}
                label={t("basedata.users.edit.deliveryEmail")}
                disabled={inputsDisabled}
                control={control}
                name={"deliveryEmail"}
                rules={{
                  required: true,
                  validate: async (value) => {
                    if (!value || !isEmail(value)) {
                      return t("general.email.required.error");
                    }
                  },
                }}
              />
            </FormField>

            {!inputsDisabled && (
              <>
                <FormField>
                  <FormPasswordField
                    hasError={Boolean(errors?.password)}
                    label={t("basedata.users.edit.password.text")}
                    analysis={analysis}
                    {...register("password", {
                      required: mode === UserFormMode.Create,
                      onChange: () => trigger("password"),
                      validate: async (value) => {
                        if (!value && mode === UserFormMode.Edit) {
                          return true;
                        }
                        if (!value) {
                          return false;
                        }
                        await analyser(value);
                        return passwordAnalyser(value).isValid;
                      },
                    })}
                  />
                </FormField>
                <FormField>
                  <FormTextField
                    hasError={Boolean(errors?.passwordConfirm)}
                    errorMessage={errors?.passwordConfirm?.message}
                    type={"password"}
                    label={t("basedata.users.edit.password.reenter")}
                    control={control}
                    name={"passwordConfirm"}
                    rules={{
                      required: mode === UserFormMode.Create,
                      onChange: () => trigger("passwordConfirm"),
                      validate: (value) => {
                        if (!value && mode === UserFormMode.Edit) {
                          return true;
                        }
                        const isValid = value && value === password && passwordAnalyser(value).isValid;
                        if (!isValid) {
                          return t("basedata.users.edit.password.error");
                        }
                      },
                    }}
                  />
                </FormField>
                <FormField>
                  <FormCheckbox
                    name="notify"
                    control={control}
                    label={t("create.user.notify_on_create")}
                    disabled={inputsDisabled}
                  />
                </FormField>
              </>
            )}

            {!jobTitleAndExternalIdHidden && (
              <FormField>
                <FormTextField
                  required={false}
                  hasError={Boolean(errors?.externalId)}
                  label={t("basedata.users.edit.externalId")}
                  disabled={inputsDisabled}
                  control={control}
                  name={"externalId"}
                />
              </FormField>
            )}
            <FormField>
              <FormPhoneCountryField
                name="telephone"
                disabled={inputsDisabled}
                label={t("basedata.users.edit.phone")}
                control={control}
                hasError={Boolean(errors?.telephone)}
                required
              />
            </FormField>

            <UserFormRoleSelects
              inputsDisabled={inputsDisabled}
              jobTitleAndExternalIdHidden={jobTitleAndExternalIdHidden}
              userTypeLevelSelectOptions={userTypeLevelSelectOptions}
            />

            {shownAsModal && mode === UserFormMode.Detail && user && (
              <FormField>
                <FormControl fullWidth={true}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={true}
                    value={user.id}
                    label={t("basedata.users.edit.id")}
                  />
                </FormControl>
              </FormField>
            )}
          </Grid>
        </FormProvider>
      )}

      <AppDialogButtonRow
        isLoading={isLoading}
        acceptTextOverride={getDialogOkText()}
        onCancelClick={onCancelCallback ? onCancelCallback : undefined}
        onAcceptClick={handleOkClick}
        type="button"
        alignButtons="space-between"
      />
    </form>
  );
};
