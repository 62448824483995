import { FunctionComponent } from "react";
import { usePackageIconAndLabel } from "../../../../domain/hooks/use-package-icon-and-label";
import { ICfmPackage } from "../../../../domain/packages/cfm-package";
import { OrderNewInfoBox } from "./order-new-info-box.component";

interface IPackageInfoBoxProps {
  productPackage: ICfmPackage;
  onClick: (productPackage: ICfmPackage) => void;
  customIconComponent?: JSX.Element;
}

export const PackageInfoBox: FunctionComponent<IPackageInfoBoxProps> = (props) => {
  const { productPackage, onClick, customIconComponent } = props;
  const { getIcon, getLabel } = usePackageIconAndLabel();

  return (
    <OrderNewInfoBox
      iconComponent={customIconComponent ?? getIcon(productPackage)}
      labelComponent={getLabel(productPackage)}
      onClick={() => onClick(productPackage)}
    />
  );
};
