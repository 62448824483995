import { RefObject, useEffect, useRef } from "react";

export const useDetectClickOutside = (onClickOutside: () => void): { nodeRef: RefObject<any> } => {
  const nodeRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    //check if click is outside
    if (nodeRef.current && !nodeRef.current?.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return {
    nodeRef,
  };
};
