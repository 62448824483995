import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import useAsyncEffect from "../../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { useProductOverviewContext } from "../product-overview.provider";
import { EmptyProductView } from "../../../components/empty-container/empty-product-view.component";
import { ProductOverviewRow } from "./product-overview-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
}));

export const ProductOverviewList: VFC = () => {
  const classes = useStyles();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const {
    hasNextPage,
    productsLoading: isLoading,
    fetchNextPage,
    products,
    isFetching,
    isFetchingNextPage,
    resetFilter,
  } = useProductOverviewContext();

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  return (
    <Grid container direction="column">
      {!isLoading &&
        products &&
        products.map((product, index) => {
          return (
            <Grid item key={index}>
              <ProductOverviewRow product={product} />
            </Grid>
          );
        })}
      {products.length === 0 && !isLoading && !isFetching && !isFetchingNextPage && (
        <Grid item>
          <EmptyProductView resetFilter={resetFilter} />
        </Grid>
      )}
      {(isLoading || isFetching || isFetchingNextPage) && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.alignSpinner}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
