import { ICfmProductModel } from "collect-from-market/repositories/models/product/cfm-product.model";
import { ICfmProduct } from "../../../domain/products/cfm-product";
import { ICfmWarehouseProduct } from "../../../domain/products/cfm-warehouse-product";
import { ICfmWarehouseProductModel } from "../product/cfm-warehouse-product.model";
import { CfmPackageConverter } from "./cfm-package.converter";
import { CfmProductCategoryConverter } from "./cfm-product-category.converter";
import { CfmProductFractionConverter } from "./cfm-product-fraction.converter";
import { CfmProductResponseAssignmentConverter } from "./cfm-product-response-assignment.converter";
import { CfmProductRoutingAssignmentConverter } from "./cfm-product-routing-assignment.converter";
import { CfmProductStateConverter } from "./cfm-product-state.converter";
import { CfmProductTypeConverter } from "./cfm-product-type.converter";

export class CfmProductConverter {
  public static toModel(product: ICfmProduct): ICfmProductModel {
    return {
      id: product.id,
      articleNumber: product.articleNumber,
      name: product.name,
      isAval: product.isAval,
      category: product.category ? CfmProductCategoryConverter.toModel(product.category) : undefined,
      fraction: product.fraction ? CfmProductFractionConverter.toModel(product.fraction) : undefined,
      type: product.type ? CfmProductTypeConverter.toModel(product.type) : undefined,
      state: product.state ? CfmProductStateConverter.toModel(product.state) : undefined,
      articleType: product.articleType,
      active: product.active,
      createdAt: product.createdAt,
      updatedAt: product.updatedAt,
      assignments: product.assignments
        ? product.assignments.map(CfmProductRoutingAssignmentConverter.toModel)
        : undefined,
      responseAssignments: product.responseAssignments
        ? product.responseAssignments.map(CfmProductResponseAssignmentConverter.toModel)
        : undefined,
    };
  }

  public static toDomain(product: ICfmProductModel): ICfmProduct {
    return {
      id: product.id,
      articleNumber: product.articleNumber,
      name: product.name,
      isAval: product.isAval,
      category: product.category ? CfmProductCategoryConverter.toDomain(product.category) : undefined,
      fraction: product.fraction ? CfmProductFractionConverter.toDomain(product.fraction) : undefined,
      type: product.type ? CfmProductTypeConverter.toDomain(product.type) : undefined,
      state: product.state ? CfmProductStateConverter.toDomain(product.state) : undefined,
      articleType: product.articleType,
      active: product.active,
      createdAt: product.createdAt,
      updatedAt: product.updatedAt,
      packages: product.packages ? product.packages.map(CfmPackageConverter.toDomain) : undefined,
      assignments: product.assignments
        ? product.assignments.map(CfmProductRoutingAssignmentConverter.toDomain)
        : undefined,
      responseAssignments: product.responseAssignments
        ? product.responseAssignments.map(CfmProductResponseAssignmentConverter.toDomain)
        : undefined,
    };
  }

  public static warehouseToDomain(model: ICfmWarehouseProductModel): ICfmWarehouseProduct {
    return {
      product: CfmProductConverter.toDomain(model.product),
      warehouseArticleNumber: model.warehouseArticleNumber,
      quantity: model.quantity,
      unit: model.unit,
    };
  }
}
