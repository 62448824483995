import { RestEndpoints } from "api/endpoints";
import { CfmOrderXStatus } from "collect-from-market/domain/order-x/cfm-order-x.status";
import { CfmOrderXStatusConverter } from "collect-from-market/repositories/models/converter/order-x/cfm-order-x-status.converter";
import { ICfmTransferOrderAvailableActionsModel } from "collect-from-market/repositories/models/transfer-order/cfm-transfer-order-available-action.model";
import { useQuery } from "react-query";
import getInstance from "utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmTransferOrderAvailableAction } from "../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { CfmTransferOrderAvailableActionConverter } from "../../../models/converter/transfer-order/cfm-transfer-order-available-action.converter";

const getTransferOrderActionsForProduct = async (
  productId: number,
  status: CfmOrderXStatus,
): Promise<ICfmTransferOrderAvailableAction[]> => {
  const { data } = await getInstance().get<ICfmTransferOrderAvailableActionsModel>(
    RestEndpoints.TransferOrderProductActions,
    {
      params: {
        productId,
        status: CfmOrderXStatusConverter.statusWithAllToModel(status),
      },
    },
  );

  return data.items.map((item) => CfmTransferOrderAvailableActionConverter.toDomain(item));
};

export const useGetTransferOrderActionsForProduct = (productId: number, status: CfmOrderXStatus) => {
  return useQuery([CfmQueryKeys.GetTransferOrderActionsForProduct, productId, status], () =>
    getTransferOrderActionsForProduct(productId, status),
  );
};
