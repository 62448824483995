import uAE from "use-async-effect";

type MaybePromise = void | Promise<void>;

const useAsyncEffect = (callback: (isActive: () => boolean) => MaybePromise, watch: unknown[]) => {
  uAE(async (active) => {
    await callback(active);
  }, watch);
};

export default useAsyncEffect;
