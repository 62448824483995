import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../shared/domain/scroll/scrolled-to-bottom-context";
import { useProductResponseFieldOverviewContext } from "../../pages/product-response-field/product-response-field-overview.provider";
import { ResponseFieldRow } from "./response-field-row.component";
import { getResponseFieldRowHeader } from "./response-field.util";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
  },
  last: {
    "&:last-child": {
      marginBottom: 10,
    },
  },
}));

interface IResponseFieldListProps {
  openDialog: () => void;
}

export const ResponseFieldList: VFC<IResponseFieldListProps> = (props) => {
  const { openDialog } = props;
  const classes = useStyles();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const { t } = useTranslation();
  const { responseField } = useProductResponseFieldOverviewContext();

  useEffect(() => {
    if (!responseField) return;
    openDialog();
  }, [responseField]);

  const {
    hasNextPage,
    responseFieldsLoading: isLoading,
    fetchNextPage,
    responseFields,
    isFetching,
    isFetchingNextPage,
  } = useProductResponseFieldOverviewContext();

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  return (
    <Grid container direction="column">
      <Grid item>
        <ResponseFieldRow responseField={getResponseFieldRowHeader(t)} isHeader={true} />
      </Grid>
      {(isLoading || isFetching || isFetchingNextPage) && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.alignSpinner}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isLoading &&
        responseFields &&
        responseFields.map((responseField, index) => {
          return (
            <Grid item key={index} className={classes.last}>
              <ResponseFieldRow responseField={responseField} />
            </Grid>
          );
        })}
    </Grid>
  );
};
