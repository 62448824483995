import { Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { usePackageIconAndLabel } from "../../../../domain/hooks/use-package-icon-and-label";
import { ICfmCreatePackage, ICfmPackage } from "../../../../domain/packages/cfm-package";
import { SummaryBox } from "./summary-box.component";

const useStyles = makeStyles((theme: Theme) => ({
  entryContainerPackage: {
    minHeight: 70,
  },
  mobileAlign: {
    textAlign: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  dividerMargin: {
    marginLeft: 105,
    marginRight: 30,
    marginBottom: 5,
    marginTop: 5,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

interface IPackageSummaryRowProps {
  productPackage?: ICfmPackage;
  formPackage?: ICfmCreatePackage;
  onEditClick?: VoidFunction;
  onDeleteClick?: VoidFunction;
}

export const PackageSummaryRow: FunctionComponent<IPackageSummaryRowProps> = (props) => {
  const { productPackage, onDeleteClick, onEditClick, formPackage } = props;
  const classes = useStyles();
  const { getIcon, getLabelWithMeasuredContainerData, getLabelWithContainerData } = usePackageIconAndLabel();
  const { isDesktop } = useScreenSize();

  const getPackageSummaryBox = () => {
    if (!productPackage) return <></>;
    return (
      <SummaryBox
        iconComponent={getIcon(productPackage, true)}
        labelComponent={
          productPackage.measurementsRequired
            ? getLabelWithMeasuredContainerData(
                productPackage,
                formPackage?.containersToBeCollected,
                formPackage?.width,
                formPackage?.height,
                formPackage?.weight,
                formPackage?.length,
              )
            : getLabelWithContainerData(
                productPackage,
                formPackage?.containersToBeCollected ?? 0,
                formPackage?.containersToBeDelivered,
              )
        }
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        isSubSummaryBox
      />
    );
  };

  return (
    <>
      <Grid item>
        <Divider classes={{ root: classes.dividerMargin }} />
      </Grid>
      <Grid
        item
        className={classNames({
          [classes.entryContainerPackage]: isDesktop && productPackage,
        })}
      >
        <Grid container direction="row">
          <Grid item md={1} />
          {getPackageSummaryBox()}
        </Grid>
      </Grid>
    </>
  );
};
