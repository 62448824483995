import { removeNullAndUndefined } from "../../../../../utils/filter.util";
import { ICfmOrderAvailableAction } from "../../../../domain/order-x/actions/cfm-order-available-action";
import { ICfmOrderBulkAvailableAction } from "../../../../domain/order-x/actions/cfm-order-bulk-action";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { ICfmResponseValueFormInputs } from "../../../../pages/order-x/components/actions/order-x-action.component";
import { ISelectedFilesList } from "../../../../pages/order-x/components/actions/providers/selected-files.provider";
import { CfmOrderActionTypeModel } from "../../order-x/action/cfm-order-action-type.model";
import { ICfmOrderActionModel } from "../../order-x/action/cfm-order-x-action.model";
import {
  ICfmOrderXBulkUpdateListModel,
  ICfmOrderXBulkUpdateModel,
} from "../../order-x/edit/cfm-order-x-bulk-update.model";
import { CfmOrderBulkActionConverter } from "./cfm-order-x-bulk-action.converter";

export class CfmOrderXBulkUpdateConverter {
  public static toModel(orderIds: number[]): ICfmOrderXBulkUpdateListModel {
    const orderUpdates: ICfmOrderXBulkUpdateModel[] = orderIds.map((id) => ({
      orderId: id,
      action: {
        type: CfmOrderActionTypeModel.Ordered,
      },
    }));
    return { items: orderUpdates };
  }

  public static toActionModel(orderIds: number[], action: ICfmOrderActionModel): ICfmOrderXBulkUpdateListModel {
    const orderUpdates: ICfmOrderXBulkUpdateModel[] = orderIds.map((id) => ({
      orderId: id,
      action: action,
    }));
    return { items: orderUpdates };
  }

  public static toUpdateResponseFieldsModel(
    orders: ICfmOrderX[],
    bulkAction: ICfmOrderBulkAvailableAction[],
    currentBulkAction: ICfmOrderAvailableAction,
    inputs: ICfmResponseValueFormInputs,
    filesList: ISelectedFilesList[],
  ): ICfmOrderXBulkUpdateListModel {
    const orderUpdates = orders
      .map((order) => {
        const productItem = order.items.find((item) => item.product);
        const bulkItem = bulkAction.find((bulkOrder) => bulkOrder.orderId === order.id);
        const bulk = bulkItem?.availableActions.find((action) => action.type === currentBulkAction.type);
        if (!productItem || !bulk) return undefined;
        const updateAction = CfmOrderBulkActionConverter.toUpdateRequest(bulk, inputs, filesList, productItem.id);
        return {
          orderId: order.id,
          action: updateAction,
        };
      })
      .filter(removeNullAndUndefined);

    return { items: orderUpdates };
  }
}
