import { FormControl, Grid, InputProps, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";
import React from "react";
import { Colors } from "../../../../style/Colors";
import { NewOrderToolTip } from "../../tooltips/order-new-tooltip.component";
import { Control, Controller, UseControllerProps } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) => ({
  helperIcon: {
    cursor: "help",
  },
  helperIconContainer: {
    paddingLeft: 2,
    color: Colors.greenLight,
    "&:hover": {
      color: Colors.greenDark,
    },
  },
  labelSpacing: {
    top: -5,
  },
  bold: {
    fontWeight: "bold",
  },
}));

interface IFormTextField extends UseControllerProps<any> {
  hasError?: boolean;
  errorMessage?: string;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  label: string | undefined;
  inputProps?: InputProps;
  multiline?: boolean;
  minRows?: number;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  toolTipText?: string;
  maxLength?: number;
  disabled?: boolean;
  dataTestId?: string;
  control: Control<any>;
}

export const FormTextField = React.forwardRef(
  ({
    hasError,
    errorMessage,
    label,
    type = "text",
    required = true,
    inputProps,
    multiline,
    minRows,
    onFocus,
    toolTipText,
    maxLength,
    disabled,
    name,
    rules,
    control,
    dataTestId,
  }: IFormTextField) => {
    const classes = useStyles();

    const getLabelElement = () => {
      if (!required) return label;

      if (!toolTipText) {
        return (
          <>
            <b>{label}</b> *
          </>
        );
      }

      return (
        <Grid container direction="row">
          <Grid item>
            <Typography variant="body1" className={classes.bold}>
              {label} *
            </Typography>
          </Grid>
          <Grid item>
            <Grid item className={classes.helperIconContainer}>
              <NewOrderToolTip title={toolTipText} placement="bottom">
                <HelpOutlineOutlined color="inherit" className={classes.helperIcon} fontSize="small" />
              </NewOrderToolTip>
            </Grid>
          </Grid>
        </Grid>
      );
    };

    return (
      <FormControl fullWidth={true}>
        <Controller
          name={name}
          control={control}
          defaultValue={""}
          rules={rules}
          render={({ field }) => {
            return (
              <TextField
                InputLabelProps={{ shrink: true, className: classes.labelSpacing }}
                InputProps={inputProps}
                label={label ? getLabelElement() : undefined}
                inputProps={{
                  maxLength,
                  min: 0,
                }}
                type={type}
                error={hasError}
                helperText={errorMessage}
                multiline={multiline}
                minRows={minRows}
                onFocus={onFocus}
                disabled={disabled}
                data-testid={dataTestId}
                {...field}
              />
            );
          }}
        />
      </FormControl>
    );
  },
);
