import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import React, { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { CfmOrderXApiStatus } from "../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { getOrderStatusOption } from "../../../collect-from-market/domain/order-x/order-x.util";
import { EditIconNoBorderButton } from "../../../components/Primitives";
import { Colors, ColorValue } from "../../../style/Colors";
import { IResponseAssignmentRowValue } from "../../pages/product-response/product-response-assignment.provider";
import { ProductResponseAssignmentServiceDateDetail } from "./form/product-response-assignment-service-date-detail.component";
import { ResponseFieldDetailLabel } from "./form/response-field/response-field-detail-label.component";
import {
  INDEX_BOX_HEIGHT_WIDTH,
  INDEX_BOX_MARGIN_RIGHT,
  ProductResponseAssignmentRowTitle,
} from "./product-response-assignment-row-title.component";

const rowMarginTop = 8;
const accordionHeaderPadding = 16;
const accordionSummaryPadding = 20;
const accordionPaddingDifference = accordionSummaryPadding - accordionHeaderPadding;

const useStyles = makeStyles((theme: Theme) => ({
  pkgName: {
    fontWeight: "bold",
  },
  container: {
    height: "100%",
  },
  checkBoxContainer: {
    marginTop: 4,
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  rowContainer: {
    minHeight: "70px",
    borderRadius: "4px",
    marginTop: rowMarginTop,
  },
  rowContainerDefault: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  expandIcon: {
    color: Colors.greenLight,
    height: 30,
    width: 30,
  },
  expandedIcon: {
    transform: "rotate(90deg)",
    color: Colors.greenLight,
    height: 30,
    width: 30,
  },

  editStatusColumn: {
    textAlign: "right",
    flexGrow: 1,
  },
  accordionDetails: {
    padding: "0px 20px 0px 20px",
  },
  noDataContainer: {
    marginBottom: 15,
  },
  respFieldContainer: {
    marginLeft: INDEX_BOX_HEIGHT_WIDTH + INDEX_BOX_MARGIN_RIGHT - accordionPaddingDifference,
  },
}));

interface IProductResponseAssignmentRowProps {
  initValues: IResponseAssignmentRowValue;
  index: number;
  onEditStatus?: VoidFunction;
  backgroundColor: ColorValue;
  activeStatus: CfmOrderXApiStatus | undefined;
  setActiveStatus: (activeStatus: CfmOrderXApiStatus | undefined) => void;
}

export const ProductResponseAssignmentRow: VFC<IProductResponseAssignmentRowProps> = (props) => {
  const { initValues, index, onEditStatus, backgroundColor, activeStatus, setActiveStatus } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const onSelectStatus = () => {
    activeStatus === initValues.status ? setActiveStatus(undefined) : setActiveStatus(initValues.status);
  };

  const handleEditStatus = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!onEditStatus) return;
    setActiveStatus(initValues.status);
    onEditStatus();
  };

  const isActiveStatus = useMemo(() => {
    return activeStatus === initValues.status;
  }, [activeStatus, initValues.status]);

  return (
    <Accordion expanded={isActiveStatus} onChange={onSelectStatus} elevation={0} square>
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosOutlined classes={{ root: isActiveStatus ? classes.expandedIcon : classes.expandIcon }} />
        }
        className={classes.rowContainerDefault}
        style={{ backgroundColor }}
      >
        <Grid container direction="column" justifyContent="center" className={classes.container}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <ProductResponseAssignmentRowTitle
                  index={index}
                  title={getOrderStatusOption(initValues.status, t).label}
                  status={initValues.status}
                  isActiveStatus={isActiveStatus}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  {(initValues.serviceDates ?? []).length > 0 && (
                    <Grid item>
                      <Box display={"flex"} alignItems={"center"} className={classes.container}>
                        <ProductResponseAssignmentServiceDateDetail serviceDates={initValues.serviceDates ?? []} />
                      </Box>
                    </Grid>
                  )}
                  {onEditStatus && (
                    <Grid item>
                      <Grid item className={classes.editStatusColumn}>
                        <EditIconNoBorderButton onClick={(e) => handleEditStatus(e)} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor }} classes={{ root: classes.accordionDetails }}>
        <Grid container direction="column">
          {(initValues.responseFieldData ?? []).length === 0 && (
            <Grid item className={classes.noDataContainer}>
              <Typography variant="body1">{t("configuration.responseAssignments.create.noResponseFields")}</Typography>
            </Grid>
          )}
          {(initValues.responseFieldData ?? []).length > 0 &&
            (initValues.responseFieldData ?? []).map((respField, index) => {
              return (
                <Grid item key={`${index}-${respField.id}`} className={classes.respFieldContainer}>
                  <ResponseFieldDetailLabel field={respField} index={index} />
                </Grid>
              );
            })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
