import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import { ICfmProductPackageConfig } from "../../../../configurator/components/package/create-product-package-config-content.component";
import getInstance from "../../../../utils/rest";
import { CfmCreateProductPackageConfigConverter } from "../../models/converter/cfm-create-product-package-config.converter";
import { CfmQueryKeys } from "../cfm-query-keys";

const createProductPackageConfig = async (config: ICfmProductPackageConfig[]): Promise<void> => {
  const convertedConfig = config.map(CfmCreateProductPackageConfigConverter.dataToModel);
  await getInstance().post(RestEndpoints.CreateProductPackageConfigs, { data: convertedConfig });
};

export const useCreateProductPackageConfig = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    CfmQueryKeys.CreateProductPackageConfig,
    ({ config }: { config: ICfmProductPackageConfig[] }) => createProductPackageConfig(config),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
