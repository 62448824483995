import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { formatDateShort } from "../../../../shared/util/date.util";
import { TEXT_SEPARATOR } from "../../../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  mobileAlign: {
    textAlign: "left",
  },
}));

interface ICartOrderInfoRowProps {
  requestedPickupDate: Date | null;
  purchaseOrderNumber: string | null;
  orderComment: string | null;
}

export const CartOrderInfoRow: FC<ICartOrderInfoRowProps> = (props) => {
  const { requestedPickupDate, purchaseOrderNumber, orderComment } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { isDesktop } = useScreenSize();

  const date = requestedPickupDate
    ? t("cart.requestedPickupDate", { date: formatDateShort(requestedPickupDate) })
    : undefined;
  const orderNumber = purchaseOrderNumber
    ? `${TEXT_SEPARATOR} ${t("cart.orderNumber", { orderNumber: purchaseOrderNumber })}`
    : "";
  const comment = orderComment ? `${TEXT_SEPARATOR} ${t("cart.orderComment", { orderComment })}` : "";

  return (
    <Grid item>
      <Typography variant="body2" className={classNames({ [classes.mobileAlign]: !isDesktop })}>
        {date} {orderNumber} {comment}
      </Typography>
    </Grid>
  );
};
