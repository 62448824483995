import { useQuery } from "react-query";
import { IPaginationInputMeta } from "shared/domain/pagination/pagination-meta";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { ManufacturerListConverter } from "../../../../put-on-market/domain/converter/manufacturer/manufacturer-list.converter";
import { IPomManufacturerList } from "../../../../put-on-market/domain/models/manufacturer/manufacturer";
import { IPomManufacturerListModel } from "../../../../put-on-market/repositories/models/manufacturer/manufacturer.model";
import getInstance from "../../../../utils/rest";
import { SharedQueryKeys } from "../shared-query-keys";

export const getGroupManufacturers = async (
  id: number,
  searchText: string | undefined,
  paginationInputMeta: IPaginationInputMeta,
): Promise<IPomManufacturerList> => {
  const response = await getInstance().get<IPomManufacturerListModel>(RestEndpoints.PomManufacturer, {
    params: {
      groupId: id,
      query: searchText,
      ...paginationInputMeta,
    },
  });

  return ManufacturerListConverter.toDomain(response.data);
};

export const useGetGroupManufacturers = (
  id: number,
  searchText: string | undefined,
  paginationInputMeta: IPaginationInputMeta,
) => {
  return useQuery(
    [SharedQueryKeys.GetGroupManufacturers, id, searchText],
    () => getGroupManufacturers(id, searchText, paginationInputMeta),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: Number.isFinite(id),
    },
  );
};
