import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import config from "../../../config/config";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { ICreateFreshdeskTicketRequest } from "../../domain/requests/create-freshdesk-ticket.request";
import { useCreateFreshdeskTicketQuery } from "../../repositories/queries/freshdesk/create-freshdesk-ticket.query";
import { useCustomForm } from "../../util/form.util";
import { AppDialog } from "../dialog/app-dialog.component";
import { FormTextField } from "../form/fields/form-text.field";

export interface ICreateFreshdeskTicketDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  currentFieldValue: string;
  newFieldLabel: string;
  subject: string;
  groupName: string;
  groupId: number | undefined;
}

interface ICreateFreshdeskTicketInputs {
  subject: string;
  description: string;
  newFieldValue: string;
  reason: string;
}

export const CreateFreshdeskTicketDialog: VFC<ICreateFreshdeskTicketDialogProps> = (props) => {
  const { handleClose, isOpen, currentFieldValue, subject, newFieldLabel, groupId, groupName } = props;
  const { t } = useTranslation();
  const { isLoading, mutateAsync: createFreshdeskTicket } = useCreateFreshdeskTicketQuery();
  const { internalUser } = useAuthContext();

  const deepLinkGroup = `https://${config.appBaseUrl}/portal/groups/${groupId}`;
  const defaultValues: Partial<ICreateFreshdeskTicketInputs> = {
    subject: subject,
    description: `${currentFieldValue} | ${t("freshdesk.createTicketDialog.groupName")}: ${groupName} | ${t(
      "freshdesk.createTicketDialog.issuer",
    )}: ${internalUser?.fullName} |  ${t("freshdesk.createTicketDialog.deepLink")}: ${deepLinkGroup}`,
  };

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useCustomForm<ICreateFreshdeskTicketInputs>({ defaultValues });

  const onSubmit = async (inputs: ICreateFreshdeskTicketInputs) => {
    const request: ICreateFreshdeskTicketRequest = {
      subject: inputs.subject,
      description: `${newFieldLabel}: ${inputs.newFieldValue} | ${t("freshdesk.createTicketDialog.reason")}: ${
        inputs.reason
      } \n\n\n ${inputs.description}`,
    };
    await createFreshdeskTicket({ createData: request });
    handleClose();
  };

  return (
    <AppDialog
      title={t("general.grs_support")}
      subtitle={t("freshdesk.createTicketDialog.subtitle")}
      onCancelClick={handleClose}
      onAcceptClick={handleSubmit(onSubmit)}
      open={isOpen}
      isLoading={isLoading}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.subject)}
            label={t("freshdesk.createTicketDialog.subject")}
            required={true}
            control={control}
            name={"subject"}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.newFieldValue)}
            label={newFieldLabel}
            required={true}
            control={control}
            name={"newFieldValue"}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.subject)}
            label={t("freshdesk.createTicketDialog.reason")}
            required={true}
            control={control}
            name={"reason"}
            rules={{
              required: true,
            }}
          />
        </Grid>
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.description)}
            label={t("freshdesk.createTicketDialog.description")}
            required={true}
            multiline
            minRows={10}
            control={control}
            name={"description"}
            rules={{
              required: true,
            }}
          />
        </Grid>
      </Grid>
    </AppDialog>
  );
};
