import React from "react";
import getCfmAdminNavItems from "../../../collect-from-market/domain/navigation/cfm-admin-navigation-items";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";
import { getPomAdminNavItems } from "../../../put-on-market/pom-navigation-items";
import { isAdmin } from "../user/user";
import { SidebarMenuItem } from "./sidebar-menu-item.component";

export const SidebarAdminMenu: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { activePlatform } = usePlatformContext();

  const isPomPlatform = () => activePlatform === AvailablePlatform.Pom;
  const isConfiguratorPlatform = () => activePlatform === AvailablePlatform.Config;
  const adminNavItems = isPomPlatform() ? getPomAdminNavItems() : getCfmAdminNavItems();

  if (!isAdmin(internalUser) || isConfiguratorPlatform()) return null;

  return (
    <>
      {adminNavItems
        .filter((item) => item.visible && item.content)
        .map((item, index) => {
          return (
            <SidebarMenuItem key={`admin-${item.to}-${index}`} name="admin" navItem={item} menuItem={item.label} />
          );
        })}
    </>
  );
};
