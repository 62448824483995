import { VFC } from "react";
import { useQueryClient } from "react-query";
import {
  ManufacturerForm,
  ManufacturerFormMode,
} from "../../../../../put-on-market/components/manufacturer-form/manufacturer-form.component";
import { IPomManufacturer } from "../../../../../put-on-market/domain/models/manufacturer/manufacturer";
import { IGroup } from "../../../../domain/group/group";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { AppDialog } from "../../../dialog/app-dialog.component";

interface IEditGroupManufacturerDialogProps {
  open: boolean;
  group: IGroup;
  mode: ManufacturerFormMode;
  title: string;
  manufacturer?: IPomManufacturer;
  onSaved: VoidFunction;
  onCancel: VoidFunction;
}

export const MutateGroupManufacturerDialog: VFC<IEditGroupManufacturerDialogProps> = (props) => {
  const { open, group, manufacturer, onSaved, onCancel, title, mode } = props;
  const queryClient = useQueryClient();

  const saveClicked = () => {
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupManufacturers);
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    onSaved();
  };

  return (
    <AppDialog title={title} open={open}>
      <ManufacturerForm
        manufacturer={manufacturer}
        mainGroup={group}
        mode={mode}
        onCancelCallback={onCancel}
        onSuccessCallback={saveClicked}
      />
    </AppDialog>
  );
};
