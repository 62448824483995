import { IProductData } from "../../../../configurator/components/product/product-list/creatable-products-list.component";
import { AvailablePlatform } from "../../../../providers/Auth/platform.provider";
import { ARTICLE_SEPARATOR } from "../../../../utils/constants";
import { ICfmCreateProductDataModel } from "../product/cfm-create-products.model";

export class CfmCreateProductConverter {
  public static productDataToModel(productData: IProductData): ICfmCreateProductDataModel {
    const articleNumber = `${productData.category.internalNumber ?? "00"}${
      productData.fraction?.internalNumber ?? "00"
    }${productData.type.internalNumber ?? "00"}${productData.state.internalNumber ?? "00"}`;

    const name = [
      productData.category.name ?? "",
      productData.fraction?.name ?? undefined,
      productData.type.name ?? "",
      productData.state.name ?? "",
    ];

    const productName = productData.name
      ? productData.name
      : name.filter((data) => data !== undefined).join(` ${ARTICLE_SEPARATOR} `);

    return {
      articleNumber,
      name: productName,
      platform: productData.platform === AvailablePlatform.Cfm ? "cfm" : "pom",
      articleType: productData.articleType,
      categoryId: productData.category.id,
      fractionId: productData.fraction?.id ?? undefined,
      stateId: productData.state.id,
      typeId: productData.type.id,
    };
  }
}
