import { Grid, makeStyles } from "@material-ui/core";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { FormTextField } from "../../../shared/components/form/fields/form-text.field";
import { FormField } from "../../../shared/components/form/form-field.component";
import { IProductNameInputs } from "../../pages/product/components/product-overview-row.component";

const useStyle = makeStyles(() => ({
  container: {
    minWidth: 600,
  },
}));

export const ProductNameForm: VFC = (props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext<IProductNameInputs>();
  const classes = useStyle();

  return (
    <Grid container direction="column" spacing={2} className={classes.container}>
      <FormField xs={12} md={12}>
        <FormTextField
          required={true}
          hasError={Boolean(errors?.name)}
          label={undefined}
          name={"name"}
          control={control}
          rules={{ required: true }}
          maxLength={100}
        />
      </FormField>
    </Grid>
  );
};
