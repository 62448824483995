import { Box, Card, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { OrderScrollToTopButton } from "../../collect-from-market/pages/order-x/components/order-scroll-to-top-button.component";
import { OrderCheckbox } from "../../components/checkbox/order-checkbox.component";
import { ForwardButton } from "../../components/Primitives/Buttons";
import { DRAWER_OPEN } from "../../shared/domain/sidebar/sidebar";
import { useSidebarContext } from "../../shared/domain/sidebar/sidebar-context";
import { PrimaryColors } from "../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  truckloadWeightText: {
    color: PrimaryColors.base,
  },
  container: {
    height: "100%",
  },
  submitButton: {
    margin: "0px 20px 0px 20px",
  },
  containerPadding: {
    padding: "20px 0px 20px 0px",
  },
  footerStyle: {
    bottom: 0,
    left: 0,
    position: "fixed",
    zIndex: 600,
    minHeight: 80,
  },
  footerWidthSidebarOpen: {
    width: `calc(100% - ${DRAWER_OPEN}px)`,
  },
  footerWidthSidebarClosed: {
    width: "100%",
  },
  checkboxContainer: {
    marginLeft: 61,
  },
}));

interface IArticleConfigFooterProps {
  disabled: boolean;
  displayCheckbox: boolean;
  onButtonClick: VoidFunction;
  buttonText: string;
  checkboxLabel: string;
  onCheckboxChange: (checked: boolean) => void;
  checked: boolean;
}

export const ArticleConfigFooter: VFC<IArticleConfigFooterProps> = (props) => {
  const { disabled, onButtonClick, buttonText, checkboxLabel, onCheckboxChange, checked, displayCheckbox } = props;
  const classes = useStyles();
  const { sidebarOpen } = useSidebarContext();

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.footerStyle, {
        [classes.footerWidthSidebarOpen]: sidebarOpen,
        [classes.footerWidthSidebarClosed]: !sidebarOpen,
      })}
    >
      <Grid item>
        <OrderScrollToTopButton />
      </Grid>
      <Card raised elevation={20}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          className={classes.containerPadding}
        >
          <Grid item className={classes.checkboxContainer}>
            <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
              {displayCheckbox && (
                <OrderCheckbox label={checkboxLabel} onChange={onCheckboxChange} checked={checked} size="large" />
              )}
            </Box>
          </Grid>
          <Grid item alignContent="center" className={classes.submitButton}>
            <Box display={"flex"} alignItems={"center"} justifyContent="center" className={classes.container}>
              <ForwardButton disabled={disabled} onClick={onButtonClick}>
                <Typography variant="body1">{buttonText}</Typography>
              </ForwardButton>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
