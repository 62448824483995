import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { BuildOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { AddIconButton } from "../../../../../components/Primitives/Buttons";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { ManufacturerFormMode } from "../../../../../put-on-market/components/manufacturer-form/manufacturer-form.component";
import {
  IPomManufacturer,
  isActiveManufacturer,
} from "../../../../../put-on-market/domain/models/manufacturer/manufacturer";
import { Colors } from "../../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { IGroup } from "../../../../domain/group/group";
import { IPaginationInputMeta } from "../../../../domain/pagination/pagination-meta";
import { useGetGroupManufacturers } from "../../../../repositories/queries/group/get-group-manufacturers.query";
import { SharedQueryKeys } from "../../../../repositories/queries/shared-query-keys";
import { formatDateShort } from "../../../../util/date.util";
import { AppTable, ITableRow } from "../../../../components/tables/app-table.component";
import { MutateGroupManufacturerDialog } from "../../../../components/group/edit-group/dialog/mutate-group-manufacturer-dialog.component";
import { ManufacturerDetailDialog } from "../dialogues/manufacturer-detail-dialog.component";

const useStyles = makeStyles((theme: Theme) => ({
  disabledAddressText: {
    color: Colors.gray,
  },
  textAlign: {
    textAlign: "center",
  },
  headerContainer: {
    marginTop: 15,
  },
}));

interface IGroupDetailsManufacturersTableProps {
  group: IGroup;
}

export const GroupDetailsManufacturersTable: VFC<IGroupDetailsManufacturersTableProps> = (props) => {
  const { group } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isMobile, isTablet } = useScreenSize();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedManufacturer, setSelectedManufacturer] = useState<IPomManufacturer | undefined>();
  const [isNewManufacturerOpen, setIsNewManufacturerOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [paginationInputMeta, setPaginationInputMeta] = useState<IPaginationInputMeta>({
    page: 0,
    pageSize: 5,
  });

  const {
    isLoading,
    data: manufacturersResult,
    refetch,
  } = useGetGroupManufacturers(group.id, searchText, paginationInputMeta);

  useEffect(() => {
    refetch();
  }, [paginationInputMeta.page, searchText, refetch]);

  const onSearchSubmit = (value: string | undefined) => {
    setSearchText(value?.length ? value : undefined);
  };

  const searchFieldProps = {
    tooltip: t("basedata.manufacturer.table.search"),
    placeholder: t("basedata.manufacturer.table.searchPlaceholder"),
    onSearchSubmit,
  };

  const getManufacturerLabelComponent = (manufacturer: IPomManufacturer) => {
    const textParts: string[] = [];
    if (manufacturer.battregNumber) {
      textParts.push(manufacturer.battregNumber);
    }
    if (manufacturer.address?.name) {
      textParts.push(manufacturer.address.name);
    }
    textParts.push(
      `${t("basedata.manufacturer.contract_starts_at")}: ${formatDateShort(manufacturer.contractStartsAt)}`,
    );
    if (manufacturer.contractEndsAt) {
      textParts.push(`${t("basedata.manufacturer.contract_ends_at")}: ${formatDateShort(manufacturer.contractEndsAt)}`);
    }

    return (
      <Typography
        variant="body1"
        className={classNames({
          [classes.disabledAddressText]: !isActiveManufacturer(manufacturer),
          [classes.textAlign]: isMobile || isTablet,
        })}
      >
        <b>{manufacturer.name}</b>
        {TEXT_SEPARATOR} {textParts.join(`${TEXT_SEPARATOR} `)}
      </Typography>
    );
  };

  const getMappedEntries = (manufacturers: IPomManufacturer[] | undefined): ITableRow[] => {
    if (!manufacturers) return [];

    return manufacturers.map((manufacturer) => {
      return {
        id: manufacturer.id,
        labelComponent: getManufacturerLabelComponent(manufacturer),
        iconComponent: <BuildOutlined fontSize="large" />,
        isActive: isActiveManufacturer(manufacturer),
      };
    });
  };

  const onCreateSavedCallback = () => {
    refetch();
    queryClient.invalidateQueries(SharedQueryKeys.GetGroupHistory);
    setIsNewManufacturerOpen(false);
  };

  return (
    <>
      <MutateGroupManufacturerDialog
        group={group}
        open={isNewManufacturerOpen}
        mode={ManufacturerFormMode.Create}
        title={t("pom.manufacturers.create.title")}
        onSaved={onCreateSavedCallback}
        onCancel={() => {
          setIsNewManufacturerOpen(false);
        }}
      />

      <ManufacturerDetailDialog
        open={isDetailOpen}
        manufacturer={selectedManufacturer}
        onClose={() => {
          setSelectedManufacturer(undefined);
          setIsDetailOpen(false);
        }}
        onEditClick={() => {
          setIsDetailOpen(false);
          setIsEditOpen(true);
        }}
      />

      <MutateGroupManufacturerDialog
        open={isEditOpen}
        mode={ManufacturerFormMode.Edit}
        title={t("pom.manufacturers.edit.title")}
        group={group}
        manufacturer={selectedManufacturer}
        onSaved={() => {
          setIsEditOpen(false);
          setSelectedManufacturer(undefined);
        }}
        onCancel={() => {
          setIsDetailOpen(false);
          setIsEditOpen(false);
        }}
      />

      <Grid container direction="column">
        <Grid item className={classes.headerContainer}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h1">{t("basedata.manufacturer.heading")}</Typography>
            </Grid>
            <Grid item>
              <AddIconButton
                onClick={() => {
                  setIsNewManufacturerOpen(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <AppTable
            entries={getMappedEntries(manufacturersResult?.items)}
            searchFieldProps={searchFieldProps}
            paginationInputMeta={paginationInputMeta}
            setPaginationInputMeta={setPaginationInputMeta}
            numberOfRecords={manufacturersResult?.meta?.numberOfRecords ?? 0}
            onRowClicked={(tableRow) => {
              const manufacturer = (manufacturersResult?.items ?? []).find(
                (manufacturer) => manufacturer.id === tableRow.id,
              );
              setSelectedManufacturer(manufacturer);
              setIsDetailOpen(true);
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
