import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../providers/Auth/auth.provider";
import { useLoginAs } from "../providers/Auth/login-as-context";
import { GroupType } from "../shared/domain/group/group-type";
import { IUser } from "../shared/domain/user/user";
import { isUserLoggedIn } from "./auth";
import { AvailableSharedRoutes } from "./constants";

interface IAuthenticatedRouteProps extends RouteProps {
  redirectTo?: string;
  allowedRoles?: GroupType[];
  allowedConditionCallback?: (user: IUser | undefined) => boolean;
}

export const AuthenticatedRoute: FunctionComponent<IAuthenticatedRouteProps> = (props) => {
  const { internalUser } = useAuthContext();
  const { loggedAs } = useLoginAs();
  const { redirectTo = AvailableSharedRoutes.Login, allowedRoles, allowedConditionCallback, location, ...rest } = props;
  if (!isUserLoggedIn()) {
    return <Redirect to={{ pathname: redirectTo, state: { next: location!.pathname } }} />;
  }

  const mustReset = () => {
    return internalUser !== undefined && internalUser.mustResetPassword && !loggedAs;
  };

  const isAllowed = () => {
    const notAllowed = internalUser && allowedRoles && !allowedRoles.some((role) => internalUser?.group?.type === role);
    const isAllowedConditionally = allowedConditionCallback ? allowedConditionCallback(internalUser) : false;
    // if user is allowed through condition; override notAllowed by roles
    return isAllowedConditionally ? isAllowedConditionally : !notAllowed;
  };

  return (
    <>
      {internalUser && (
        <>
          {mustReset() && <Redirect to={{ pathname: AvailableSharedRoutes.ResetPassword }} />}
          {!isAllowed() && <Redirect to={{ pathname: AvailableSharedRoutes.Login }} />}
          <Route {...rest} />
        </>
      )}
    </>
  );
};
