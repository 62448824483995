import React from "react";
import { DeleteIcon } from "../../Primitives";
import { useTableActionIconStyle } from "./table-action-icons.style";

export const DeleteItemTableIcon: React.FC = () => {
  const { small, greenFill, animatedBorder } = useTableActionIconStyle();
  const classNames = [small, greenFill, animatedBorder];

  return (
    <div className={classNames.join(" ")}>
      <DeleteIcon svgColor="white" style={{ flex: 1, padding: "2px" }} />
    </div>
  );
};
