import { Box, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { replaceUriParameter } from "../../../../api/endpoints";
import SearchField from "../../../../components/SearchField";
import { useScrolledToBottomContext } from "../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { AvailableCfmRoutes } from "../../../../utils/constants";
import { useOrderXRepeatOverviewContext } from "../order-x-repeat-overview.provider";
import { OrderXRepeatInfo } from "./order-x-repeat-info.component";

const useStyles = makeStyles((theme: Theme) => ({
  alignCenter: {
    textAlign: "center",
    width: "100%",
  },
  dataContainer: {
    marginTop: 30,
  },
}));

export const OrderXRepeatList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { searchText, setSearchText, isLoading, isFetching, isFetchingNextPage, orders, hasNextPage, fetchNextPage } =
    useOrderXRepeatOverviewContext();
  const { hasScrolledToBottom } = useScrolledToBottomContext();

  useEffect(() => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading && !isFetching && !isFetchingNextPage;
    if (!shouldFetchNext) return;
    fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasNextPage, hasScrolledToBottom, isFetching, isFetchingNextPage, isLoading]);

  const hasNoData = useMemo(() => {
    return !isLoading && !isFetching && !isFetchingNextPage && orders.length === 0;
  }, [isLoading, isFetching, isFetchingNextPage, orders]);

  const doDisplaySearchField = useMemo(() => {
    return orders.length > 5 || searchText;
  }, [orders, searchText]);

  return (
    <Grid container direction="column">
      {doDisplaySearchField && (
        <Grid item>
          <SearchField
            size="large"
            tooltip={t("order_filter_search_placeholder")}
            onSearchSubmit={(value: string) => {
              setSearchText(value);
            }}
            placeholder={t("order_filter_search_placeholder")}
            autoFocus={false}
          />
        </Grid>
      )}
      <Box className={classes.dataContainer}>
        {orders.map((order, index) => (
          <Grid item key={index}>
            <OrderXRepeatInfo
              order={order}
              onRepeatClick={(order) => {
                history.push(replaceUriParameter(AvailableCfmRoutes.OrderXNewRepeat, "id", order.id));
              }}
            />
          </Grid>
        ))}
      </Box>
      {hasNoData && (
        <Grid item className={classes.dataContainer}>
          <Typography variant="body1">{t("orders.repeat.noData")}</Typography>
        </Grid>
      )}
      {(isLoading || isFetching || isFetchingNextPage) && (
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.alignCenter}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
