import { AddressConverter } from "../../../../../shared/domain/converter/address.converter";
import { IRespondWarehouseProductInputs } from "../../../../pages/order-x/components/order-list-view/sorter-warehouse/dialog/respond-incoming-warehouse-product-dialog.component";
import { CfmServiceDate } from "../../../../domain/order-x/actions/cfm-service-date";
import { ICfmTransferOrder } from "../../../../domain/transfer-order/cfm-transfer-order";
import { CfmTransferOrderStatus } from "../../../../domain/transfer-order/cfm-transfer-order-status";
import { ISelectedFilesList } from "../../../../pages/order-x/components/actions/providers/selected-files.provider";
import { CfmTransferOrderStatusModel } from "../../transfer-order/cfm-transfer-order-status.model";
import { ICfmTransferOrderModel } from "../../transfer-order/cfm-transfer-order.model";
import { CfmProductConverter } from "../cfm-product.converter";
import { CfmTransferOrderResponseFieldConverter } from "./cfm-transfer-order-response-field.converter";
import { formatDateYearMonthDay } from "../../../../../shared/util/date.util";
import { ICfmCreateTransferOrderModel } from "../../transfer-order/cfm-create-transfer-order.model";
import { ICfmUpdateTransferOrderModel } from "../../transfer-order/cfm-update-transfer-order.model";
import { ICfmUpdateTransferOrder } from "../../../../domain/transfer-order/cfm-update-transfer-order";
import { ICfmCreateTransferOrder } from "../../../../domain/transfer-order/cfm-create-transfer-order";
import { CfmOrderXActionConverter } from "../order-x/cfm-order-x-action.converter";

export class CfmTransferOrderConverter {
  public static toDomain(model: ICfmTransferOrderModel): ICfmTransferOrder {
    if (!model.product) {
      throw new Error("Error converting transfer order entity to domain. Missing relation product.");
    }
    if (!model.startAddress) {
      throw new Error("Error converting transfer order entity to domain. Missing relation start address.");
    }

    return {
      id: model.id,
      product: CfmProductConverter.toDomain(model.product),
      status: CfmTransferOrderConverter.statusToDomain(model.status),
      startAddress: AddressConverter.toDomain(model.startAddress),
      targetAddress: model.targetAddress ? AddressConverter.toDomain(model.targetAddress) : null,
      plannedDeliveryDate: model.plannedDeliveryDate,
      actualDeliveryDate: model.actualDeliveryDate,
      serviceDeliveryDate: model.serviceDeliveryDate,
      servicePickupDate: model.servicePickupDate,
      serviceRecyclingDate: model.serviceRecyclingDate,
      serviceTreatmentDate: model.serviceTreatmentDate,
      weight: model.weight ?? undefined,
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
      updatedBy: model.updatedBy,
    };
  }

  public static statusToModel(domain: CfmTransferOrderStatus): CfmTransferOrderStatusModel {
    switch (domain) {
      case CfmTransferOrderStatus.Pending:
        return CfmTransferOrderStatusModel.Pending;
      case CfmTransferOrderStatus.Confirmed:
        return CfmTransferOrderStatusModel.Confirmed;
    }
  }

  public static statusToDomain(model: CfmTransferOrderStatusModel): CfmTransferOrderStatus {
    switch (model) {
      case CfmTransferOrderStatusModel.Pending:
        return CfmTransferOrderStatus.Pending;
      case CfmTransferOrderStatusModel.Confirmed:
        return CfmTransferOrderStatus.Confirmed;
    }
  }

  public static createToModel(model: ICfmCreateTransferOrder): ICfmCreateTransferOrderModel {
    return {
      startAddressId: model.startAddressId,
      targetAddressId: model.targetAddressId,
      warehouseProductId: model.warehouseProductId,
      warehouseArticleNumber: model.warehouseArticleNumber,
      plannedDeliveryDate: formatDateYearMonthDay(model.plannedDeliveryDate),
      actualDeliveryDate: model.actualDeliveryDate ? formatDateYearMonthDay(model.actualDeliveryDate) : undefined,
      status: CfmTransferOrderConverter.statusToModel(model.status),
      serviceDates: CfmOrderXActionConverter.actionServiceDatesToModel(model.serviceDates),
      responseFields: model.responseFields
        ? model.responseFields.map(CfmTransferOrderResponseFieldConverter.createToModel)
        : undefined,
    };
  }

  public static updateToModel(model: ICfmUpdateTransferOrder): ICfmUpdateTransferOrderModel {
    return {
      status: CfmTransferOrderConverter.statusToModel(model.status),
      plannedDeliveryDate: model.plannedDeliveryDate ? formatDateYearMonthDay(model.plannedDeliveryDate) : undefined,
      actualDeliveryDate: model.actualDeliveryDate ? formatDateYearMonthDay(model.actualDeliveryDate) : undefined,
      serviceDates: CfmOrderXActionConverter.actionServiceDatesToModel(model.serviceDates),
      responseFields: model.responseFields
        ? model.responseFields.map(CfmTransferOrderResponseFieldConverter.createToModel)
        : undefined,
    };
  }

  public static formToCreateDomain(
    inputs: IRespondWarehouseProductInputs,
    startAddressId: number,
    warehouseProductId: number,
    warehouseArticleNumber: string,
    filesList?: ISelectedFilesList[],
    status?: CfmTransferOrderStatus,
    plannedDeliveryDate?: Date,
  ): ICfmCreateTransferOrder {
    return {
      startAddressId: startAddressId,
      targetAddressId: inputs.targetAddressId,
      warehouseProductId: warehouseProductId,
      warehouseArticleNumber: warehouseArticleNumber,
      plannedDeliveryDate: plannedDeliveryDate ?? new Date(),
      actualDeliveryDate: undefined,
      status: status ?? CfmTransferOrderStatus.Confirmed,
      serviceDates: {
        [CfmServiceDate.Pickup]: inputs.serviceDates?.pickup,
        [CfmServiceDate.Delivery]: inputs.serviceDates?.delivery,
        [CfmServiceDate.Recycling]: inputs.serviceDates?.recycling,
        [CfmServiceDate.Treatment]: inputs.serviceDates?.treatment,
      },
      responseFields: inputs.responseFields
        ? inputs.responseFields.map((field) =>
            CfmTransferOrderResponseFieldConverter.formToCreateDomain(
              field,
              filesList?.find((file) => file.responseAssignmentId === field.responseAssignmentResponseFieldId),
              warehouseProductId,
            ),
          )
        : undefined,
    };
  }

  public static formToUpdateDomain(
    inputs: IRespondWarehouseProductInputs,
    status: CfmTransferOrderStatus,
    warehouseProductId: number,
    filesList?: ISelectedFilesList[],
    plannedDeliveryDate?: Date,
    actualDeliveryDate?: Date,
  ): ICfmUpdateTransferOrder {
    return {
      status: status,
      plannedDeliveryDate: plannedDeliveryDate ?? undefined,
      actualDeliveryDate: actualDeliveryDate ?? undefined,
      serviceDates: {
        [CfmServiceDate.Pickup]: inputs.serviceDates?.pickup,
        [CfmServiceDate.Delivery]: inputs.serviceDates?.delivery,
        [CfmServiceDate.Recycling]: inputs.serviceDates?.recycling,
        [CfmServiceDate.Treatment]: inputs.serviceDates?.treatment,
      },
      responseFields: inputs.responseFields
        ? inputs.responseFields.map((field) =>
            CfmTransferOrderResponseFieldConverter.formToCreateDomain(
              field,
              filesList?.find((file) => file.responseAssignmentId === field.responseAssignmentResponseFieldId),
              warehouseProductId,
            ),
          )
        : undefined,
    };
  }
}
