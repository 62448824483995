import { makeStyles } from "@material-ui/core";
import { PADDING_BASE } from "../config/theme/overrides/constant";
import { PrimaryColors, Shades } from "./Colors";
import { BORDER_RADIUS } from "./Constant";

export const useStepperStyle = makeStyles((theme) => ({
  root: {
    height: "40px",
    color: Shades.white,
    padding: PADDING_BASE,
    alignItems: "center",
    margin: "0 1px",
    backgroundColor: PrimaryColors.base,
    "&:first-child": {
      borderTopLeftRadius: BORDER_RADIUS,
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    "&:last-child": {
      borderTopRightRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  },
  hover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
    },
  },
  mobileRoot: {
    height: "40px",
    color: Shades.white,
    padding: PADDING_BASE,
    alignItems: "center",
    margin: "0 1px 10px",
    backgroundColor: PrimaryColors.base,
    "&:hover": {
      backgroundColor: PrimaryColors.hover,
      cursor: "pointer",
    },
  },
  active: {
    backgroundColor: PrimaryColors.active,
  },
}));
