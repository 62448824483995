import { AccordionSummaryClassKey, StyleRules } from "@material-ui/core";

export const MuiAccordionSummary: Partial<StyleRules<AccordionSummaryClassKey, {}>> = {
  root: {
    borderRadius: 5,
    minHeight: 60,
    "&$expanded": {
      borderRadius: 0,
    },
  },
  content: {
    margin: 0,
  },
};
