import { createContext, FunctionComponent, useContext, useEffect, useState } from "react";
import { GroupConverter } from "../../../shared/domain/converter/group.converter";
import { IGroup } from "../../../shared/domain/group/group";
import { useGetAgencyCompaniesQuery } from "../../repositories/queries/get-agency-companies.query";

interface IPomAgencyProvider {
  agencyGroups: IGroup[];
  isLoading: boolean;
  setNewSearchQuery: (searchQuery: string) => void;
  page: number;
  setNewPage: (page: number) => void;
  pageSize: number;
  setNewPageSize: (page: number) => void;
  curSearchQuery: string | undefined;
  itemCount: number;
}

export const PomAgencyContext = createContext<IPomAgencyProvider>({} as IPomAgencyProvider);

export const usePomAgencyContext = () => {
  return useContext(PomAgencyContext);
};

const usePomAgencyProvider = (): IPomAgencyProvider => {
  const [agencyGroups, setAgencyGroups] = useState<IGroup[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [itemCount, setItemCount] = useState<number>(0);
  const [curSearchQuery, setCurSearchQuery] = useState<string | undefined>();
  const { data: agencyCompanies, isLoading } = useGetAgencyCompaniesQuery(page, pageSize, curSearchQuery);

  useEffect(() => {
    if (!agencyCompanies) return;
    const groups = agencyCompanies.items;
    const convertedGroups = groups.map((group) => GroupConverter.toDomain(group));
    setItemCount(agencyCompanies.meta.numberOfRecords);
    setAgencyGroups(convertedGroups);
  }, [agencyCompanies]);

  const setNewSearchQuery = (searchQuery: string): void => {
    // if search value changes, reset page
    if (searchQuery !== curSearchQuery) {
      setPage(0);
    }

    setCurSearchQuery(searchQuery);
  };

  const setNewPage = (newPage: number): void => {
    setPage(newPage);
  };

  const setNewPageSize = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setPage(0);
  };

  return {
    agencyGroups,
    isLoading,
    setNewSearchQuery,
    curSearchQuery,
    itemCount,
    page,
    pageSize,
    setNewPage,
    setNewPageSize,
  };
};

export const PomAgencyProvider: FunctionComponent = (props) => {
  const value = usePomAgencyProvider();
  return <PomAgencyContext.Provider value={value}>{props.children}</PomAgencyContext.Provider>;
};
