import { ButtonClassKey, StyleRules } from "@material-ui/core";
import { PrimaryColors, Shades, WarningColors } from "style/Colors";
import { Colors } from "./../../../style/Colors";
import { BUTTON_OUTLINE_BORDER, BUTTON_OUTLINE_BORDER_TRANSPARENT } from "./constant";

const PADDING = "14px";
const BORDER_RADIUS = "3rem";

export const MuiButton: Partial<StyleRules<ButtonClassKey, {}>> = {
  text: {
    paddingLeft: PADDING,
    paddingRight: PADDING,
  },
  containedSizeSmall: {
    paddingLeft: PADDING,
    paddingRight: PADDING,
  },
  root: {
    textTransform: "none",
    fontWeight: "normal",
    borderRadius: BORDER_RADIUS,
    padding: "5px 20px",
    transition: "border-radius .5s",
    minHeight: 40,
    height: "auto",

    "&:hover": {
      borderRadius: "0.3rem",
    },

    "&.MuiButton-containedPrimary:hover": {
      backgroundColor: PrimaryColors.hover,
    },

    "&:disabled": {
      backgroundColor: `${Shades.gray30} !important`,
      color: "white !important",
    },

    "&.delete": {
      backgroundColor: `${WarningColors.base} !important`,
      color: "white !important",
    },
  },

  outlined: {
    border: BUTTON_OUTLINE_BORDER,
  },

  outlinedPrimary: {
    border: BUTTON_OUTLINE_BORDER,

    "&:hover": {
      backgroundColor: "inherit",
      color: Colors.greenLight,
      border: BUTTON_OUTLINE_BORDER,
    },

    "&.--active": {
      backgroundColor: PrimaryColors.active,
      color: Shades.white,
      border: BUTTON_OUTLINE_BORDER_TRANSPARENT,
      borderRadius: "0.3rem",
    },

    "&.--filter-active": {
      backgroundColor: PrimaryColors.active,
      color: Shades.white,
      border: BUTTON_OUTLINE_BORDER_TRANSPARENT,
    },
  },

  endIcon: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: "16px",
  },

  startIcon: {
    marginLeft: 0,
    marginRight: 0,
    paddingRight: "16px",
  },
};
