import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { GroupOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { IGroup } from "../../../../../shared/domain/group/group";
import { useGetGroupQuery } from "../../../../../shared/repositories/queries/group/get-group.query";
import { Shades } from "../../../../../style/Colors";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";
import { getDescriptionTextForTooltip } from "../../../../../utils/group.helper";
import { useOrderNewContext, WizardStep } from "../../order-new.provider";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { SummaryBox } from "./summary-box.component";
import { SummaryLoadingSpinner } from "./summary-loading-spinner.component";

const useStyles = makeStyles((theme: Theme) => ({
  iconPadding: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  gridContainer: {
    minHeight: 70,
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: "0px 5px 5px 5px",
  },
  tooltipHover: {
    "&:hover": {
      cursor: "help",
    },
  },
}));

interface IGroupSummaryProps {}

export const GroupSummary: FunctionComponent<IGroupSummaryProps> = (props) => {
  const classes = useStyles();
  const { watch, setValue } = useFormContext<IOrderNewWizardFormInputs>();
  const groupId = watch("customerGroupId");
  const { data, isLoading } = useGetGroupQuery(groupId);
  const { setActiveStep, resetProduct } = useOrderNewContext();
  const { t } = useTranslation();

  const doAddHoverEffect = (description: string | undefined) => {
    return getDescriptionTextForTooltip(description, t).length > 0;
  };

  const getLabelText = (group: IGroup) => {
    const erpText = group.erpNumber ? `${TEXT_SEPARATOR} ${group.erpNumber}` : "";
    return `${group.name}${erpText}`;
  };

  const getLabel = (group: IGroup) => {
    return (
      <NewOrderToolTip title={getDescriptionTextForTooltip(group.name ?? undefined, t)}>
        <Typography
          className={classNames({ [classes.tooltipHover]: doAddHoverEffect(group.name) })}
          variant="body1"
        >{`${group.id}${TEXT_SEPARATOR} ${getLabelText(group)}`}</Typography>
      </NewOrderToolTip>
    );
  };

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      {isLoading && <SummaryLoadingSpinner />}
      {!isLoading && data && (
        <SummaryBox
          iconComponent={<GroupOutlined fontSize="large" />}
          labelComponent={getLabel(data)}
          onDeleteClick={() => {
            resetProduct(setValue);
            setActiveStep(WizardStep.CustomerGroup);
          }}
        />
      )}
    </Grid>
  );
};
