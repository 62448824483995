import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmTransferOrder } from "../../../../domain/transfer-order/cfm-transfer-order";
import { CfmTransferOrderConverter } from "../../../models/converter/transfer-order/cfm-transfer-order.converter";
import { ICfmTransferOrderModel } from "../../../models/transfer-order/cfm-transfer-order.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmCreateTransferOrder } from "../../../../domain/transfer-order/cfm-create-transfer-order";

const createTransferOrderQuery = async (
  requestData: ICfmCreateTransferOrder,
): Promise<ICfmTransferOrder | undefined> => {
  const { data } = await getInstance().post<ICfmTransferOrderModel | undefined>(
    RestEndpoints.TransferOrder,
    CfmTransferOrderConverter.createToModel(requestData),
  );
  return data ? CfmTransferOrderConverter.toDomain(data) : undefined;
};

export const useCreateTransferOrderQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation([CfmQueryKeys.TransferOrder], createTransferOrderQuery, {
    onError: () => {
      enqueueSnackbar(t("notifications.order_saved_error.message"), {
        variant: "error",
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t("notifications.order_saved_success.message"), {
        variant: "success",
      });
      void queryClient.invalidateQueries(CfmQueryKeys.GetWarehouseItems);
      void queryClient.invalidateQueries(CfmQueryKeys.GetTransferOrdersNotifiedWeight);
    },
  });
};
