import dayjs from "dayjs";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import useAsyncEffect from "../../../hooks/use-async-effect";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { useDashboardActionContext } from "../../../shared/pages/dashboard/dashboard-action.provider";
import { formatDateYearMonth } from "../../../shared/util/date.util";
import { usePomAnnouncementContext } from "../../pages/announcement/pom-announcement.provider";
import { PomAnnouncementPeriod } from "../../repositories/models/announcements/pom-announcement.period";
import { PomAnnouncementListConverter } from "../../repositories/models/converter/pom-announcement-list.converter";
import { useGetAnnouncementsQuery } from "../../repositories/queries/announcements/get-announcements.query";
import { PomAnnouncementDateListConverter } from "./converter/pom-announcement-date-list.converter";
import { IPomAnnouncementDateItem } from "./pom-announcement-date-list";

interface IPomAnnouncementDatePickerProvider {
  isLoading: boolean;
  announcementList: IPomAnnouncementDateItem[];
  datePickerStartDate: Date | undefined;
  generatePickerFields: (startDate: Date) => void;
  period: PomAnnouncementPeriod;
  handleAnnouncementDateChanged: (date: Date) => void;
}

export const PomAnnouncementDatePickerContext = createContext<IPomAnnouncementDatePickerProvider>(
  {} as IPomAnnouncementDatePickerProvider,
);

export const usePomAnnouncementDatePickerContext = () => {
  return useContext(PomAnnouncementDatePickerContext);
};

const usePomAnnouncementDatePickerProvider = (
  props: IPomAnnouncementDatePickerProviderProps,
): IPomAnnouncementDatePickerProvider => {
  const [announcementList, setAnnouncementList] = useState<IPomAnnouncementDateItem[]>([]);
  const [contractStartDate, setContractStartDate] = useState<Date | undefined>(undefined);
  const [contractEndDate, setContractEndDate] = useState<Date | undefined>(undefined);
  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState<Date | undefined>(undefined);
  const { getUser } = useAuthContext();
  const { onAnnouncementChanged } = props;
  const { subscribeToAnnouncementCreated, curAnnouncementDate } = usePomAnnouncementContext();
  const period = props.period;
  const [currentPeriod, setCurrentPeriod] = useState<PomAnnouncementPeriod>(period);
  const { contractId } = useDashboardActionContext();
  const {
    data: announcements,
    refetch: refetchAnnouncementList,
    isLoading,
  } = useGetAnnouncementsQuery(contractStartDate, contractEndDate, contractId);

  // Reload announcement list when announcement was created
  useEffect(() => {
    subscribeToAnnouncementCreated(async (announcement) => {
      if (!announcement.announcementDate) return;
      await refetchAnnouncementList();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDatePickerSelectedDate(curAnnouncementDate);
  }, [curAnnouncementDate]);

  const generatePickerFields = useCallback(
    (startDate: Date): void => {
      if (!announcements?.items) return;
      const convertedList = PomAnnouncementListConverter.toDomain(announcements);
      const currentYear = startDate.getFullYear();
      const selectedPeriod = announcements.items.find(({announcementDate}) => new Date(announcementDate).getFullYear() === currentYear)?.announcementPeriod ?? period;
      if (selectedPeriod === PomAnnouncementPeriod.Monthly) {
        setAnnouncementList(PomAnnouncementDateListConverter.toMonthDateList(convertedList, startDate));
      } else {
        setAnnouncementList(PomAnnouncementDateListConverter.toYearDateList(convertedList, startDate));
      }
      setCurrentPeriod(selectedPeriod);
    },
    [announcements, period]
  );

  useEffect(() => {
    if (!announcements?.items || !datePickerSelectedDate) return;
    generatePickerFields(datePickerSelectedDate);
  }, [announcements, datePickerSelectedDate, generatePickerFields]);

  useAsyncEffect(async () => {
    const user = await getUser();
    const contractStartDate = user?.group?.pomContracts?.find((contract) => contract.id === contractId)?.startDate;
    const contractEndDate = user?.group?.pomContracts?.find((contract) => contract.id === contractId)?.endDate;

    if (!contractStartDate) {
      return;
    }

    setContractStartDate(new Date(contractStartDate));
    setContractEndDate(contractEndDate ? new Date(contractEndDate) : dayjs().add(100, "years").toDate());
    const startDate = calculateStartDate(contractStartDate, contractEndDate);
    setDatePickerSelectedDate(startDate);
    handleAnnouncementDateChanged(startDate ?? new Date());
  }, [contractId]);

  const calculateStartDate = (contractStartDate: string, contractEndDate?: string | null): Date | undefined => {
    // set start date depending on contract defined in BAT-2313
    const currentDate = new Date();
    const formattedContractStartDate = contractStartDate ? formatDateYearMonth(new Date(contractStartDate)) : undefined;
    const formattedContractEndDate = contractEndDate ? formatDateYearMonth(new Date(contractEndDate)) : undefined;
    if (!formattedContractStartDate && !formattedContractEndDate) {
      // contract dates not given, use current date
      return currentDate;
    }

    const isInStartDate = formattedContractStartDate && formattedContractStartDate < formatDateYearMonth(currentDate);
    const isInEndDate = !formattedContractEndDate || formattedContractEndDate >= formatDateYearMonth(currentDate);
    if (isInStartDate && isInEndDate) {
      // current date is between contract start and end date, so use current date - 1 period (either month or year)
      const monthDate = dayjs(currentDate).subtract(1, "month").toDate();
      const yearDate = dayjs(currentDate).subtract(1, "year").toDate();
      return currentPeriod === PomAnnouncementPeriod.Monthly ? monthDate : yearDate;
    }

    const contractIsInFuture =
      formattedContractStartDate && formattedContractStartDate > formatDateYearMonth(currentDate);
    if (contractIsInFuture) {
      // contract is in the future, use first possible date (contract start date)
      return new Date(contractStartDate);
    }

    const contractIsInPast = formattedContractEndDate && formattedContractEndDate < formatDateYearMonth(currentDate);
    if (contractIsInPast) {
      // contract is in the past, use last possible date (contract end date)
      return new Date(formattedContractEndDate);
    }
  };

  const handleAnnouncementDateChanged = (date: Date): void => {
    const newDate =
      currentPeriod === PomAnnouncementPeriod.Yearly
        ? dayjs(date).startOf("year").toDate()
        : dayjs(date).startOf("month").toDate();
    onAnnouncementChanged(newDate);
  };

  return {
    isLoading,
    datePickerStartDate: datePickerSelectedDate,
    announcementList,
    generatePickerFields,
    handleAnnouncementDateChanged,
    period: currentPeriod
  };
};

interface IPomAnnouncementDatePickerProviderProps {
  onAnnouncementChanged: (date: Date) => void;
  period: PomAnnouncementPeriod;
}

export const PomAnnouncementDatePickerProvider: React.FC<IPomAnnouncementDatePickerProviderProps> = (props) => {
  const value = usePomAnnouncementDatePickerProvider(props);
  return (
    <PomAnnouncementDatePickerContext.Provider value={value}>
      {props.children}
    </PomAnnouncementDatePickerContext.Provider>
  );
};
