import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState, VFC } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { FormTextField } from "../../../../../shared/components/form/fields/form-text.field";
import { useCustomForm } from "../../../../../shared/util/form.util";

export interface IGenericFreshdeskFormInputs {
  subject: string;
  description: string;
}

interface IGenericFreshdeskFormProps {
  isOpen: boolean;
  displaySubject: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<IGenericFreshdeskFormInputs>;
  disableSuccessNotification?: boolean;
}

export const GenericFreshdeskForm: VFC<IGenericFreshdeskFormProps> = (props) => {
  const { isOpen, displaySubject, onCancel, onSubmit, disableSuccessNotification } = props;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
  } = useCustomForm<IGenericFreshdeskFormInputs>();

  const subject: string | undefined = watch("subject");
  const description: string | undefined = watch("description");

  useEffect(() => {
    if (!isOpen || !submitClicked || !displaySubject) return;

    if (subject) {
      clearErrors("subject");
    } else {
      setError("subject", { message: "Subject must not be undefined", type: "validate" });
    }
  }, [subject, setError, clearErrors, isOpen, submitClicked]);

  useEffect(() => {
    if (!isOpen || !submitClicked) return;

    if (description) {
      clearErrors("description");
    } else {
      setError("description", { message: "Description must not be undefined", type: "validate" });
    }
  }, [description, setError, clearErrors, isOpen, submitClicked]);

  const handleClose = () => {
    setSubmitClicked(false);
    reset();
    onCancel();
  };

  const handleOkClick = async () => {
    if (errors.subject || errors.description || (displaySubject && !subject) || !description) return;
    try {
      setIsSubmitting(true);
      await handleSubmit(onSubmit)();
      if (!disableSuccessNotification) {
        enqueueSnackbar(t("order_view.freshdesk_support_successful"), { variant: "success" });
      }
      handleClose();
    } catch (error) {
      enqueueSnackbar(t("order_view.freshdesk_support_error"), { variant: "error" });
    } finally {
      setIsSubmitting(false);
      setSubmitClicked(false);
    }
  };

  return (
    <AppDialog
      title={t("general.grs_support")}
      onCancelClick={handleClose}
      onAcceptClick={() => {
        setSubmitClicked(true);
        handleOkClick();
      }}
      open={isOpen}
      isLoading={isSubmitting}
    >
      <Grid container spacing={2} direction="column">
        {displaySubject && (
          <Grid item>
            <FormTextField
              hasError={Boolean(errors?.subject)}
              label={t("order_view.freshdesk_support_subject")}
              required={true}
              control={control}
              name={"subject"}
              rules={{ required: true }}
            />
          </Grid>
        )}
        <Grid item>
          <FormTextField
            hasError={Boolean(errors?.description)}
            label={t("order_view.freshdesk_support_message")}
            required={true}
            multiline
            minRows={10}
            control={control}
            name={"description"}
            rules={{ required: true }}
          />
        </Grid>
      </Grid>
    </AppDialog>
  );
};
