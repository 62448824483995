import { useInfiniteQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmTruckloadList } from "../../../../domain/truckload/cfm-truckload";
import { ICfmTruckloadListModel } from "../../../models/truckload/cfm-truckload.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { CfmTruckloadConverter } from "../../../models/converter/truckload/cfm-truckload.converter";
import { ICfmTruckloadFilterModel } from "../../../models/truckload/cfm-truckload-filter.model";
import { ICfmTruckloadSortModel } from "../../../models/truckload/cfm-truckload-sort.model";

const getInfiniteTruckloads = async (
  filter: ICfmTruckloadFilterModel,
  sort?: ICfmTruckloadSortModel,
  pageParam: number = 0,
): Promise<ICfmTruckloadList> => {
  const { query, logisticAddressId, state, fractionId, typeId, stateId } = filter;

  const { data } = await getInstance().get<ICfmTruckloadListModel>(RestEndpoints.CfmTruckloads, {
    params: {
      query: query === "" ? undefined : query,
      state,
      logisticAddressId,
      stateId,
      fractionId,
      typeId,
      page: pageParam,
      pageSize: 5,
      sortBy: sort?.sortBy,
      sortMode: sort?.sortingMode,
    },
  });

  return {
    items: data.items.map(CfmTruckloadConverter.toDomain),
    meta: data.meta,
  };
};

export const useGetInfiniteTruckloadsQuery = (
  filter: ICfmTruckloadFilterModel,
  sort?: ICfmTruckloadSortModel,
  enabled?: boolean,
) => {
  return useInfiniteQuery(
    [CfmQueryKeys.Truckloads, filter, sort],
    ({ pageParam }) => getInfiniteTruckloads(filter, sort, pageParam),
    {
      getNextPageParam: (lastPage) => {
        const hasNextPage = lastPage.meta.page < lastPage.meta.numberOfPages;
        if (hasNextPage) {
          return lastPage.meta.page + 1;
        }

        return undefined;
      },
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled ?? true,
    },
  );
};
