import { FunctionComponent } from "react";
import { PomAgencyGroupsTable } from "./components/pom-agency-groups-table.component";
import { PomAgencyProvider } from "./pom-agency.provider";

export const PomAgencyPage: FunctionComponent = () => {
  return (
    <PomAgencyProvider>
      <PomAgencyGroupsTable />
    </PomAgencyProvider>
  );
};
