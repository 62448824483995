import { Grid, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import isURL from "validator/lib/isURL";
import { AutofillCatcher } from "../../../components/AutofillHelper/autofill-catcher";
import { FormAutocomplete } from "../../../shared/components/form/fields/form-autocomplete.component";
import { FormDatepickerField } from "../../../shared/components/form/fields/form-datepicker.field";
import { FormTextField } from "../../../shared/components/form/fields/form-text.field";
import { useCustomForm } from "../../../shared/util/form.util";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { Countries, ICountry } from "../../../utils/Countries";
import { IAddressLean } from "../../domain/address/address-lean";
import { IUser } from "../../domain/user/user";
import { useBattGRegisterContext } from "../../pages/batt-g/registration/batt-g-register.provider";
import { BattGFormButtonRowComponent } from "./batt-g-form-button-row.component";
import { BattGFormUtil } from "./batt-g-form.util";

const useStyles = makeStyles({
  containerPadding: {
    padding: "8px",
    paddingRight: 0,
  },
  itemNoEndPadding: {
    "&.MuiGrid-item": {
      paddingRight: 0,
    },
  },
});

export interface IBattGManufacturerBaseDataFormInputs {
  groupName: string;
  country: ICountry;
  state: string;
  address: string;
  postal: string;
  city: string;
  buildingNumber: string;
  webAddress: string | null;
  tradeRegisterNumber: string | null;
  startDate: Date;
  endDate: Date | null;
}

export interface IBattGManufacturerBaseDataFormProps {
  user: IUser;
  address: IAddressLean | undefined;
}

export const BattGManufacturerBaseDataForm: FC<IBattGManufacturerBaseDataFormProps> = ({ user, address }) => {
  const { t } = useTranslation();
  const { handleManufacturerBaseDataFormSubmit, manufacturerBaseData, isReadOnly } = useBattGRegisterContext();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useCustomForm<IBattGManufacturerBaseDataFormInputs>({
    defaultValues: BattGFormUtil.manufacturerBaseDataFormInitialValues(user, address, manufacturerBaseData),
  });
  const history = useHistory();

  const handleCancelButtonClick = (): void => {
    history.push(AvailableSharedRoutes.GroupDetail.replace(":id", user.group?.id.toString() ?? ""));
  };

  const onSubmit = (values: IBattGManufacturerBaseDataFormInputs): void => {
    handleManufacturerBaseDataFormSubmit(values);
  };

  return (
    <>
      <form>
        <AutofillCatcher />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <FormTextField
              label={t("general.company.name.text")}
              disabled={true}
              required={true}
              control={control}
              name={"groupName"}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={6} xs={12}>
            <FormAutocomplete<ICountry>
              name={"country"}
              control={control}
              disabled={isReadOnly}
              required={true}
              label={t("basedata.addresses.edit.country")}
              options={Countries}
              error={Boolean(errors?.country)}
              rules={{ required: true }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(country) => t(country.translationKey)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              disabled={isReadOnly}
              hasError={Boolean(errors?.state)}
              label={t("basedata.addresses.edit.state")}
              control={control}
              name={"state"}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid className={classes.containerPadding} container md={12} spacing={2} direction={"row"}>
            <Grid item xs={10} md={5}>
              <FormTextField
                disabled={isReadOnly}
                hasError={Boolean(errors?.city)}
                label={t("basedata.addresses.edit.city")}
                control={control}
                name={"city"}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <FormTextField
                disabled={isReadOnly}
                hasError={Boolean(errors?.postal)}
                label={t("basedata.addresses.edit.postal")}
                control={control}
                name={"postal"}
                rules={{
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={10} md={5}>
              <FormTextField
                disabled={isReadOnly}
                hasError={Boolean(errors?.address)}
                label={t("basedata.addresses.edit.address")}
                control={control}
                name={"address"}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={2} md={1}>
              <FormTextField
                disabled={isReadOnly}
                hasError={Boolean(errors?.buildingNumber)}
                label={t("basedata.addresses.edit.buildingNumber")}
                control={control}
                name={"buildingNumber"}
                rules={{
                  required: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormTextField
              disabled={isReadOnly}
              hasError={Boolean(errors?.tradeRegisterNumber)}
              label={t("basedata.groups.edit.tradeRegisterNumber")}
              required={false}
              control={control}
              name={"tradeRegisterNumber"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              disabled={isReadOnly}
              hasError={Boolean(errors?.webAddress)}
              label={t("basedata.groups.edit.webAddress")}
              required={false}
              control={control}
              name={"webAddress"}
              rules={{
                validate: (value) => {
                  if (value && !isURL(value)) {
                    return t("general.url.invalid.error");
                  }
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDatepickerField
              disabled={isReadOnly}
              control={control}
              name={"startDate"}
              hasError={Boolean(errors?.startDate)}
              required={true}
              label={t("basedata.groups.edit.start_date")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDatepickerField
              disabled={isReadOnly}
              control={control}
              name={"endDate"}
              hasError={Boolean(errors?.endDate)}
              required={false}
              label={t("basedata.groups.edit.end_date")}
            />
          </Grid>
        </Grid>
      </form>
      <BattGFormButtonRowComponent
        onCancelButtonClicked={handleCancelButtonClick}
        onNextButtonClicked={handleSubmit(onSubmit)}
      />
    </>
  );
};
