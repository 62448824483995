import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { CancelButton, SaveButton } from "../Primitives";

const useStyles = makeStyles((theme: Theme) => ({
  centerAlign: {
    textAlign: "center",
  },
}));

interface IModalDefaultButtonProps {
  onCancel: () => void;
  onComplete: () => void;
  okText?: string;
  cancelText?: string;
}

export const ModalDefaultButtons: FunctionComponent<IModalDefaultButtonProps> = (props) => {
  const { onCancel, onComplete, okText, cancelText } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignContent="center" spacing={1}>
      <Grid item xs={12} sm={6} className={classes.centerAlign}>
        <CancelButton
          onClick={() => {
            onCancel();
          }}
        >
          <Typography variant="body1">{cancelText ?? t("general.cancel.text")}</Typography>
        </CancelButton>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centerAlign}>
        <SaveButton
          onClick={() => {
            onComplete();
          }}
        >
          <Typography variant="body1">{okText ?? t("general.complete")}</Typography>
        </SaveButton>
      </Grid>
    </Grid>
  );
};
