import { makeStyles, Typography } from "@material-ui/core";
import { Colors, PrimaryColors } from "../../../style/Colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    background: Colors.grayLight,
    padding: 20,
    borderRadius: 4,
    border: "1px solid #ffffff",
    display: "flex",
    flexDirection: "column",
    color: PrimaryColors.base,
    "& ul": {
      listStyleType: "'- '",
    },
  },
});

export const PlatformCustomerInfoComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography variant="h5" className="mb-3">
        {t("dashboard.customerInfo.title")}
      </Typography>

      <Typography className="mb-1">{t("dashboard.customerInfo.sectionGrsBasic.title")}</Typography>
      <ul>
        <li>{t("dashboard.customerInfo.sectionGrsBasic.grsBasic")}</li>
      </ul>

      <Typography className="mb-1">{t("dashboard.customerInfo.sectionGrsService.title")}</Typography>
      <ul>
        <li>{t("dashboard.customerInfo.sectionGrsService.consumer")}</li>
        <li>{t("dashboard.customerInfo.sectionGrsService.eMobility")}</li>
        <li>{t("dashboard.customerInfo.sectionGrsService.healthcare")}</li>
        <li>{t("dashboard.customerInfo.sectionGrsService.powertools")}</li>
      </ul>

      <Typography className="mb-1 font-weight-bold">{t("dashboard.customerInfo.sectionIndustry.title")}</Typography>
      <ul>
        <li>{t("dashboard.customerInfo.sectionIndustry.automation")}</li>
        <li>{t("dashboard.customerInfo.sectionIndustry.storage")}</li>
        <li>{t("dashboard.customerInfo.sectionIndustry.eMobility")}</li>
      </ul>
    </div>
  );
};
