import { Checkbox, FormControlLabel, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useOrderBulkActionContext } from "./providers/order-bulk-action.provider";

const useStyles = makeStyles((theme: Theme) => ({
  actionBarContainer: {
    paddingLeft: 0,
    paddingTop: 8,
    paddingBottom: 8,
    marginBottom: 8,
  },
  checkbox: {
    marginRight: 10,
  },
  label: {
    marginBottom: 0,
  },
}));

interface IOrderBulkActionBarProps {
  children?: ReactNode;
}

export const OrderBulkActionBar: React.VFC<IOrderBulkActionBarProps> = (props) => {
  const { children } = props;
  const { selectAllOrders, isSelectAll } = useOrderBulkActionContext();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item container xs={12} className={classes.actionBarContainer} alignItems="center">
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isSelectAll}
              className={classes.checkbox}
              onChange={(e) => selectAllOrders(e.target.checked)}
            />
          }
          className={classes.label}
          label={
            <Typography variant="body1">{isSelectAll ? t("general.deselectAll") : t("general.selectAll")}</Typography>
          }
        />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};
