import { IPomProductTypeModel } from "../../repositories/models/products/pom-product-type.model";
import { IPomProductListModel } from "../../repositories/models/products/pom-products.model";
import { IPomProductTableData, IPomProductType } from "./pom-product-table-data";

export class PomProductTableDataConverter {
  public static toDomain(data: IPomProductListModel): IPomProductTableData[] {
    return data.items.map((item) => {
      const convertedObject: IPomProductTableData = {
        id: item.id,
        articleName: item.system.name,
        articleNumber: item.articleNumber,
        type: item.type.name,
        weightClass: item.weightClass.name,
        itemPrice: item.netPrice,
        unit: item.unit,
        chemistry: item.chemistry.name,
        takeBackSystem: item.takeBackSystem.name,
        lowerWeightLimitInGram: item.lowerWeightLimitInGramOverride ?? item.weightClass.lowerWeightLimitInGram,
        upperWeightLimitInGram: item.upperWeightLimitInGramOverride ?? item.weightClass.upperWeightLimitInGram,
      };
      return convertedObject;
    });
  }

  public static typeToDomain(data: IPomProductTypeModel): IPomProductType {
    return {
      id: data.id,
      name: data.name,
      internalNumber: data.internalNumber,
    };
  }
}
