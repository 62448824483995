import { Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import isEmail from "validator/lib/isEmail";
import { AutofillCatcher } from "../../../components/AutofillHelper/autofill-catcher";
import { ChipFilter } from "../../../components/Filters/ChipFilter";
import { AvailableSharedRoutes, getTitles } from "../../../utils/constants";
import { IUser } from "../../domain/user/user";
import { BattGRegistrationStatus } from "../../models/batt-g/batt-g-registration";
import { useBattGRegisterContext } from "../../pages/batt-g/registration/batt-g-register.provider";
import { BattGRegistrationStep } from "../../pages/batt-g/registration/batt-g-registration-steps";
import { useCustomForm } from "../../util/form.util";
import { FormAutocomplete } from "../form/fields/form-autocomplete.component";
import { FormPhoneCountryField } from "../form/fields/form-phone-country.field";
import { FormTextField } from "../form/fields/form-text.field";
import { BattGFormButtonRowComponent } from "./batt-g-form-button-row.component";
import { BattGContactFormMode, BattGFormUtil } from "./batt-g-form.util";

const useStyles = makeStyles({
  containerPadding: {
    padding: "8px",
    paddingRight: 0,
  },
  itemNoEndPadding: {
    "&.MuiGrid-item": {
      paddingRight: 0,
    },
  },
  autocompleteContainer: {
    marginBottom: "7px",
  },
  alert: {
    marginBottom: "16px",
  },
});

export interface IBattGContactFormInputs {
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string | null;
  email: string;
  telephone: string;
}

export interface IBattGContactFormProps {
  user: IUser;
  mode: BattGContactFormMode;
}

export const BattGContactForm: FC<IBattGContactFormProps> = ({ user, mode }) => {
  const { t } = useTranslation();
  const {
    handleMainContactFormSubmit,
    mainContact,
    representative,
    handleStepChanged,
    handleRepresentativeFormSubmit,
    availableMainContacts,
    registrationStatus,
    isReadOnly,
  } = useBattGRegisterContext();
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useCustomForm<IBattGContactFormInputs>({
    defaultValues: BattGFormUtil.contactFormInitialValues(
      mode === BattGContactFormMode.Representative ? representative : mainContact,
    ),
  });

  const history = useHistory();
  const isSaveButtonVisible =
    mode === BattGContactFormMode.Representative && registrationStatus !== BattGRegistrationStatus.Published;

  const handleCancelButtonClick = (): void => {
    history.push(AvailableSharedRoutes.GroupDetail.replace(":id", user.group?.id.toString() ?? ""));
  };

  const onSubmit = (values: IBattGContactFormInputs): void => {
    if (mode === BattGContactFormMode.MainContact) {
      handleMainContactFormSubmit(values);
    }
    if (mode === BattGContactFormMode.Representative) {
      handleRepresentativeFormSubmit(values);
    }
  };

  const onSubmitWithFee = (values: IBattGContactFormInputs): void => {
    handleRepresentativeFormSubmit(values, true);
  };

  const handleBackButtonClicked = (): void => {
    if (mode === BattGContactFormMode.MainContact) {
      handleStepChanged(BattGRegistrationStep.ManufacturerBaseData);
    } else {
      handleStepChanged(BattGRegistrationStep.MainContactData);
    }
  };

  const getPrefillButtonTitle = (): string => {
    if (mode === BattGContactFormMode.MainContact) {
      return t("batt_g.register.prefill.main_contact");
    } else {
      return t("batt_g.register.prefill.representative");
    }
  };

  return (
    <>
      {mode === BattGContactFormMode.Representative && (
        <Alert severity={"info"} className={classes.alert}>
          {t("batt_g.register.representative_information")}
        </Alert>
      )}
      {!isReadOnly && (
        <Grid container className={classes.autocompleteContainer} spacing={2}>
          <Grid item xs={12} md={6} className="pb-4">
            <ChipFilter
              hideCheckbox={true}
              filter={undefined}
              possibleOptions={availableMainContacts.map((item) => {
                return {
                  value: item,
                  displayValue: `${item.firstName} ${item.lastName}`,
                };
              })}
              label={getPrefillButtonTitle()}
              setFilter={(value) => {
                setValue("firstName", value.firstName);
                setValue("lastName", value.lastName);
                setValue("title", value.title);
                setValue("email", value.email);
                setValue("telephone", value.telephone);
                setValue("jobTitle", value.jobTitle);
              }}
            />
          </Grid>
        </Grid>
      )}
      <form>
        <AutofillCatcher />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} direction={"column"}>
            <FormAutocomplete<string>
              disabled={isReadOnly}
              name={"title"}
              control={control}
              required={true}
              label={t("basedata.users.edit.title")}
              options={getTitles(t)}
              error={Boolean(errors?.title)}
              helperText={errors?.title?.message}
              rules={{ required: true }}
              getOptionLabel={(title) => title}
            />
          </Grid>
          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              disabled={isReadOnly}
              required={false}
              hasError={Boolean(errors?.jobTitle)}
              label={t("basedata.users.edit.jobtitle")}
              control={control}
              name={"jobTitle"}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.firstName)}
              disabled={isReadOnly}
              label={t("basedata.users.edit.firstName")}
              control={control}
              name={"firstName"}
              rules={{
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.lastName)}
              disabled={isReadOnly}
              label={t("basedata.users.edit.lastName")}
              control={control}
              name={"lastName"}
              rules={{
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} direction={"column"}>
            <FormTextField
              hasError={Boolean(errors?.email)}
              disabled={isReadOnly}
              errorMessage={errors?.email?.message}
              label={t("general.email_contact_person.text")}
              control={control}
              name={"email"}
              rules={{
                required: true,
                validate: async (value) => {
                  if (!value || !isEmail(value)) {
                    return t("general.email.required.error");
                  }
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} direction={"column"}>
            <FormPhoneCountryField
              name="telephone"
              label={t("basedata.users.edit.phone")}
              control={control}
              hasError={Boolean(errors?.telephone)}
              disabled={isReadOnly}
              required={true}
            />
          </Grid>
        </Grid>
      </form>

      <BattGFormButtonRowComponent
        onCancelButtonClicked={handleCancelButtonClick}
        onNextButtonClicked={mode === BattGContactFormMode.MainContact ? handleSubmit(onSubmit) : undefined}
        onSaveButtonClicked={isSaveButtonVisible ? handleSubmit(onSubmit) : undefined}
        onSubmitWithFeeButtonClicked={
          mode === BattGContactFormMode.Representative ? handleSubmit(onSubmitWithFee) : undefined
        }
        hasErrors={async () => !(await trigger())}
        onBackButtonClicked={handleBackButtonClicked}
      />
    </>
  );
};
