import { ClickAwayListener, MenuItem, MenuList, Tooltip } from "@material-ui/core";
import React, { Fragment } from "react";
import { useOrderBulkActionContext } from "./providers/order-bulk-action.provider";

interface IOrderBulkActionMenuListProps {
  handleMenuItemClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => void;
  handleClose: (event: React.MouseEvent<Document, MouseEvent>) => void;
}

export const OrderBulkActionMenuList: React.FC<IOrderBulkActionMenuListProps> = (props) => {
  const { handleMenuItemClick, handleClose } = props;
  const { bulkActions, selectedActionIndex } = useOrderBulkActionContext();

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <MenuList id="bulk-actions" autoFocusItem>
        {bulkActions.map((option, index) => (
          <Fragment key={index}>
            {option.tooltip && option.disabled ? (
              <Tooltip title={option.tooltip} placement="right">
                <span>
                  <MenuItem
                    key={index}
                    disabled={option.disabled}
                    selected={index === selectedActionIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option.label}
                  </MenuItem>
                </span>
              </Tooltip>
            ) : (
              <MenuItem
                key={index}
                disabled={option.disabled}
                selected={index === selectedActionIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option.label}
              </MenuItem>
            )}
          </Fragment>
        ))}
      </MenuList>
    </ClickAwayListener>
  );
};
