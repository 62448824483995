import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import { ICfmPackage } from "../../../collect-from-market/domain/packages/cfm-package";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { BoxedRowGrid } from "../grid/boxed-row-grid.component";
import { ArticleNumberBox } from "../product/article-number-box.component";
import { ICfmProductPackageConfig } from "./create-product-package-config-content.component";
import { PackageConfigDetailRow } from "./package-config-detail-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  pkgName: {
    fontWeight: "bold",
  },
  container: {
    height: "100%",
  },
  checkBoxContainer: { marginLeft: 15 },
  productContainer: {
    marginLeft: 20,
  },
  productName: {
    marginTop: 2,
  },
  packageContainer: {
    marginLeft: 20,
    marginRight: 20,
  },
  bottomPlaceholder: {
    height: 11,
  },
}));

interface ICreateProductPackageConfigRowProps {
  config: ICfmProductPackageConfig;
  onRemovePackage: (product: ICfmProduct, pkg: ICfmPackage, isExistingPackage: boolean) => void;
}

export const CreateProductPackageConfigRow: VFC<ICreateProductPackageConfigRowProps> = (props) => {
  const { config, onRemovePackage: onRemove } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const product = useMemo(() => config.product, [config]);
  const newPackages = useMemo(() => config.newPackages, [config]);
  const existingPackages = useMemo(() => config.existingPackages, [config]);

  return (
    <BoxedRowGrid>
      <Grid item xs={12}>
        <Grid container direction="column">
          <Grid item style={{ marginTop: 15 }}>
            <Grid container direction="row" spacing={2}>
              <Grid item className={classes.productContainer}>
                <ArticleNumberBox articleNumber={product.articleNumber} />
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.productName}>
                  {product.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {newPackages.length === 0 && existingPackages.length === 0 && (
          <Grid item className={classes.packageContainer}>
            <PackageConfigDetailRow
              label={t("configuration.noPackages")}
              onRemove={() => {}}
              bold={true}
              canRemove={false}
              displayTopDivider={false}
            />
          </Grid>
        )}
        {newPackages.map((pkg, index) => {
          return (
            <Box className={classes.packageContainer}>
              <Grid item>
                <PackageConfigDetailRow
                  label={pkg.name ?? ""}
                  onRemove={() => onRemove(product, pkg, false)}
                  bold={true}
                  displayTopDivider={index > 0}
                />
              </Grid>
            </Box>
          );
        })}
        {existingPackages.map((pkg, index) => {
          return (
            <Box className={classes.packageContainer}>
              <Grid item>
                <PackageConfigDetailRow
                  label={pkg.name ?? ""}
                  onRemove={() => onRemove(product, pkg, true)}
                  bold={false}
                  displayTopDivider={index > 0 || newPackages.length > 0}
                />
              </Grid>
            </Box>
          );
        })}
        <Grid item className={classes.bottomPlaceholder} />
      </Grid>
    </BoxedRowGrid>
  );
};
