import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BaseDataPageHeader } from "shared/components/basedata-page-header/basedata-page-header.component";
import { GroupAndManufacturerProvider } from "shared/components/group/group-and-manufacturer-table/group-and-manufacturer-context";
import { GroupAndManufacturerTable } from "shared/components/group/group-and-manufacturer-table/group-and-manufacturer-table.component";
import { GroupAndManufacturerTableProvider } from "shared/components/group/group-and-manufacturer-table/group-and-manufacturer-table.provider";

export const GroupOverviewPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <GroupAndManufacturerProvider>
      <GroupAndManufacturerTableProvider>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BaseDataPageHeader title={t("basedata.groups.heading")} />
          </Grid>
          <Grid item xs={12}>
            <GroupAndManufacturerTable />
          </Grid>
        </Grid>
      </GroupAndManufacturerTableProvider>
    </GroupAndManufacturerProvider>
  );
};
