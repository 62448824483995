import { Typography } from "@material-ui/core";
import { VFC } from "react";

interface IDetailValueDisplayProps {
  label: string;
  value: string;
}

export const DetailValueDisplay: VFC<IDetailValueDisplayProps> = (props) => {
  const { label, value } = props;

  return (
    <Typography data-testid="detail-value-display" variant="body1">
      <b>{label}: </b> {value}
    </Typography>
  );
};
