import { Grid, TextField } from "@material-ui/core";
import React, { ForwardedRef, useState } from "react";
import { CreateStrongPasswordHelper } from "../../../../components/Password/create-strong-password-helper";
import { IAnalysisResult } from "../../../../utils/passwordAnalyser";

interface IFormPasswordField {
  hasError?: boolean;
  errorMessage?: string;
  label: string;
  analysis?: IAnalysisResult;
}

export const FormPasswordField = React.forwardRef(
  ({ hasError, errorMessage, label, analysis, ...rest }: IFormPasswordField, ref: ForwardedRef<any>) => {
    const [show, setShow] = useState(false);

    return (
      <Grid style={{ position: "relative" }} container direction={"column"}>
        <TextField
          {...rest}
          type={"password"}
          error={hasError}
          helperText={errorMessage}
          label={
            <>
              <b>{label}</b> *
            </>
          }
          InputLabelProps={{ shrink: true }}
          ref={ref}
          onFocus={() => setShow(true)}
          onBlur={() => setShow(false)}
        />
        {analysis && show && <CreateStrongPasswordHelper analysis={analysis} />}
      </Grid>
    );
  },
);
