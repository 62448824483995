import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IAddress } from "../../../shared/domain/address/address";
import { Shades } from "../../../style/Colors";
import { getFormattedDomainAddress } from "../../../utils/address.util";

const useStyles = makeStyles((theme: Theme) => ({
  boldText: {
    fontWeight: "bold",
  },
  arrowIcon: {
    color: Shades.black,
    transform: "rotate(180deg)",
  },
  subHeadingText: {
    fontWeight: "bold",
    marginTop: 1,
  },
  addressSummary: {
    padding: "5px 0px 0px 5px",
  },
}));

interface IProductRoutingAddressFieldProps {
  heading: string;
  startAddress: IAddress;
  targetAddress: IAddress;
  optionalAddresses: IAddress[];
  level: string;
}

export const ProductRoutingAddressField: FC<IProductRoutingAddressFieldProps> = (props) => {
  const { heading, startAddress, targetAddress, optionalAddresses, level } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getAddressText = useCallback(
    (address: IAddress | undefined) => {
      return address ? getFormattedDomainAddress(address, t) : "-";
    },
    [t],
  );

  const addressLevel = t(`configuration.assignments.level.${level}`);

  return (
    <>
      <Typography variant="body1" className={classes.boldText}>
        {heading}
      </Typography>
      {startAddress && (
        <AddressSummaryField heading={t("configuration.assignments.startAddress", { level: addressLevel })}>
          <Grid item>
            <Typography variant="body1">{getAddressText(startAddress)}</Typography>
          </Grid>
        </AddressSummaryField>
      )}
      {targetAddress && (
        <AddressSummaryField heading={t("configuration.assignments.targetAddress", { level: addressLevel })}>
          <Grid>
            <Typography variant="body1">{getAddressText(targetAddress)}</Typography>
          </Grid>
        </AddressSummaryField>
      )}
      {optionalAddresses.length > 0 && (
        <AddressSummaryField heading={t("configuration.assignments.optionalAddresses")}>
          {optionalAddresses.map((address) => {
            return (
              <Grid item key={address.id}>
                <Typography variant="body1">{getAddressText(address)}</Typography>
              </Grid>
            );
          })}
        </AddressSummaryField>
      )}
    </>
  );
};

interface IAddressSummaryFieldProps {
  heading: string;
  children: ReactNode;
}

const AddressSummaryField: FC<IAddressSummaryFieldProps> = (props) => {
  const { heading, children } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.addressSummary}>
      <Grid item>
        <KeyboardBackspace className={classes.arrowIcon} fontSize="small" />
      </Grid>
      <Grid item xs={11}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1" className={classes.subHeadingText}>
              {heading}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={11} container direction="column">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
