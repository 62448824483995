import { Grid, Theme, makeStyles } from "@material-ui/core";
import { FunctionComponent, useCallback } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { ICfmPackage } from "../../../../domain/packages/cfm-package";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { CustomPackageFormDialogContent } from "./custom-package-form-dialog-content.component";
import { ErrorInfoBox } from "./error-info-box.component";
import { PackageFormDialogContent } from "./package-form-dialog-content.component";
import { OrderNewSubmitFormComponent } from "../form/order-new-submit-form.component";

const useStyles = makeStyles((theme: Theme) => ({
  fullContainer: {
    width: "100%",
  },
}));

interface IOrderNewPackageDialogProps {
  open: boolean;
  productPackage: ICfmPackage | undefined;
  onCancel: () => void;
  onAccept: () => void;
  productOrderIndex: number;
}

export const OrderNewPackageDialog: FunctionComponent<IOrderNewPackageDialogProps> = (props) => {
  const { open, productPackage, onCancel, onAccept, productOrderIndex } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    trigger,
    formState: { isValid, errors },
  } = useFormContext<IOrderNewWizardFormInputs>();
  const measurementsRequired = productPackage?.measurementsRequired ?? false;

  const getTitle = (): string => {
    if (!productPackage) return "";
    return `${productPackage.name}`;
  };

  const getSubtitle = (): string | undefined => {
    if (!productPackage?.measurementsRequired) {
      return t("orders.new.wizard.package.measurements", {
        width: productPackage?.width ?? "-",
        height: productPackage?.height ?? "-",
        weight: productPackage?.tareWeightKg ?? "-",
        length: productPackage?.length ?? "-",
      });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const onAcceptAndValidate = async () => {
    const result = await trigger();
    const canContinue = result && isValid;
    if (!canContinue) return;
    onAccept();
  };

  const getErrors = useCallback(() => {
    if (!productOrderIndex || errors?.productOrders?.[productOrderIndex]?.package === undefined) return [];
    const errorsOfCurrentPackage = errors.productOrders[productOrderIndex]?.package;
    if (!errorsOfCurrentPackage) return [];

    const errorMessages = Object.values(errorsOfCurrentPackage)
      .map((e) => (e as FieldError).message ?? "")
      .filter(Boolean);
    return errorMessages;
  }, [productOrderIndex, errors.productOrders]);

  return (
    <AppDialog
      open={open}
      title={getTitle()}
      subtitle={getSubtitle()}
      onCancelClick={handleCancel}
      onAcceptClick={onAcceptAndValidate}
      fullWidth={false}
      maxWidth="sm"
    >
      <Grid container>
        {measurementsRequired ? (
          <CustomPackageFormDialogContent productOrderIndex={productOrderIndex} />
        ) : (
          <PackageFormDialogContent productOrderIndex={productOrderIndex} />
        )}
        <div className={"mt-5"}>
          <OrderNewSubmitFormComponent productOrderIndex={productOrderIndex} />
        </div>
        {getErrors().length > 0 && (
          <Grid item lg={12} className={classes.fullContainer}>
            <ErrorInfoBox errors={getErrors()} />
          </Grid>
        )}
      </Grid>
    </AppDialog>
  );
};
