import { useLocation } from "react-router-dom";

export interface IQueryUrlParts {
  [queryPart: string]: string;
}

export const useQuery = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const addQueryPart = (key: string, values: string[] | undefined, existingPartObject: IQueryUrlParts) => {
    if (!values) {
      existingPartObject[key] = "";
      return;
    }
    existingPartObject[key] = `${key}=${values.join(",")}`;
  };

  const getUrlWithQueryParts = (baseUrl: string, parts: IQueryUrlParts) => {
    let queryOperatorAdded = false;
    let url = baseUrl;

    Object.keys(parts).forEach((key) => {
      const urlPart = parts[key];
      if (!urlPart) {
        return;
      }
      if (!queryOperatorAdded) {
        url += "?";
        queryOperatorAdded = true;
      } else {
        url += "&";
      }

      url += urlPart;
    });

    return url;
  };

  return { query, addQueryPart, getUrlWithQueryParts };
};
