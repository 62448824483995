import _ from "lodash";
import React, { createContext, useContext, useMemo, useState } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { ICfmOrderX, ICfmOrderXList } from "../../domain/order-x/cfm-order-x";
import { CfmOrderXStatusModel } from "../../repositories/models/order-x/cfm-order-x.status.model";
import { useGetInfiniteOrdersXQuery } from "../../repositories/queries/order-x/queries/get-orders-x-infinite.query";

interface IOrderXRepeatOverviewContextType {
  orders: ICfmOrderX[];
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<ICfmOrderXList>>;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  searchText: string | undefined;
  setSearchText: (searchText: string) => void;
}

interface IRepeatOrderProps {
  children: any;
}

const OrderXRepeatOverviewContext = createContext<IOrderXRepeatOverviewContextType>(
  {} as IOrderXRepeatOverviewContextType,
);

export const OrderXRepeatOverviewProvider = (props: IRepeatOrderProps) => {
  const value = useOrderXRepeatOverviewProvider();
  return <OrderXRepeatOverviewContext.Provider value={value}>{props.children}</OrderXRepeatOverviewContext.Provider>;
};

export const useOrderXRepeatOverviewContext = () => {
  return useContext(OrderXRepeatOverviewContext);
};

const useOrderXRepeatOverviewProvider = (): IOrderXRepeatOverviewContextType => {
  const [searchText, setSearchText] = useState<string | undefined>();

  const { data, isLoading, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useGetInfiniteOrdersXQuery({
    status: CfmOrderXStatusModel.Confirmed,
    query: searchText,
  });

  const orders = useMemo(() => {
    const pages = data?.pages ?? [];
    return _.flatMap(pages, (page) => page.items);
  }, [data?.pages]);

  return {
    orders,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage: hasNextPage ?? false,
    searchText,
    setSearchText,
  };
};
