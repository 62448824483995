import { Checkbox, FormControlLabel, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FC } from "react";
import { Colors } from "../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginTop: 5,
  },
  controlLabel: {
    margin: 0,
    "& .MuiIconButton-root": {
      paddingLeft: 0,
    },
  },
  sizeBig: {
    transform: "scale(1.5)", // checkbox is an svg icon, so use transform to increase size
  },
}));

interface IOrderCheckboxProps {
  label?: string;
  onChange: (checked: boolean) => void;
  checked?: boolean;
  color?: string;
  disabled?: boolean;
  disabledHint?: string;
  size: "medium" | "large";
}

export const OrderCheckbox: FC<IOrderCheckboxProps> = (props) => {
  const { label, onChange, color, checked, size, disabled, disabledHint } = props;
  const classes = useStyles();

  const getCheckboxColor = () => {
    if (disabled === true) return Colors.gray;
    return color ?? Colors.greenLight;
  };

  return (
    <>
      {label ? (
        <FormControlLabel
          classes={{ root: classes.controlLabel }}
          control={
            <Tooltip title={disabledHint ?? ""} disableHoverListener={!disabled || disabledHint === undefined}>
              {/*Span is needed so that tooltip works on disabled component*/}
              <span>
                <Checkbox
                  checked={checked}
                  disabled={disabled}
                  style={{ color: getCheckboxColor() }}
                  onChange={(e) => onChange(e.target.checked)}
                  className={classNames({ [classes.sizeBig]: size === "large" })}
                />
              </span>
            </Tooltip>
          }
          label={
            <Typography variant="body1" className={classes.text}>
              {label}
            </Typography>
          }
        />
      ) : (
        <Tooltip title={disabledHint ?? ""} disableHoverListener={!disabled || disabledHint === undefined}>
          {/*Span is needed so that tooltip works on disabled component*/}
          <span>
            <Checkbox
              checked={checked}
              disabled={disabled}
              style={{ color: getCheckboxColor() }}
              className={classNames({ [classes.sizeBig]: size === "large" })}
              onChange={(e) => onChange(e.target.checked)}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
};
