import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { isAdmin } from "../../../shared/domain/user/user";
import { AvailableCfmRoutes } from "../../../utils/constants";
import {
  IOrderNewWizardFormInputs,
  OrderNewWizard,
  WizardMode,
} from "../order-new/components/order-new-wizard.component";
import { OrderNewProvider, WizardStep } from "../order-new/order-new.provider";
import { useGetOrderXQuery } from "../../repositories/queries/order-x/queries/get-order-x.query";
import { ICfmOrderX } from "../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../repositories/models/converter/order-x/cfm-order-x.converter";

interface IOrderXEditPendingParams {
  id: string | undefined;
}

export const OrderXEditPendingPage: FC = (props) => {
  const { internalUser } = useAuthContext();
  const { id } = useParams<IOrderXEditPendingParams>();

  const { data: order, isLoading } = useGetOrderXQuery(id ? Number(id) : undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  const getInitialValues = (order: ICfmOrderX) => {
    if (isAdmin(internalUser)) return undefined;

    const initialValues = CfmOrderXConverter.toWizardInputs(order);
    if (!initialValues) {
      enqueueSnackbar(
        t("general.error_occurred", { errorCode: 500, errorMsg: "Error loading initial values for editing!" }),
        { variant: "error" },
      );
      history.push(AvailableCfmRoutes.OrderXNew);
      return;
    }
    const initValues: Partial<IOrderNewWizardFormInputs> = {
      ...initialValues,
      customerGroupId: internalUser?.group?.id,
    };
    return initValues;
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {!isLoading && order && (
        <OrderNewProvider initialStep={WizardStep.SubmitPage} wizardMode={WizardMode.Edit}>
          <OrderNewWizard initialValues={getInitialValues(order)} mode={WizardMode.Edit} />
        </OrderNewProvider>
      )}
    </>
  );
};
