import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { useEffect, useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "../../../../../../hooks/use-async-effect";
import { useScrolledToBottomContext } from "../../../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { DRAWER_OPEN } from "../../../../../../shared/domain/sidebar/sidebar";
import { useSidebarContext } from "../../../../../../shared/domain/sidebar/sidebar-context";
import { CfmOrderXApiStatus } from "../../../../../domain/order-x/cfm-order-x.status";
import { ICfmCreateTruckload } from "../../../../../domain/truckload/cfm-create-truckload";
import { CfmTruckloadConverter } from "../../../../../repositories/models/converter/truckload/cfm-truckload.converter";
import { useCreateTruckloadQuery } from "../../../../../repositories/queries/order-x/mutations/create-truckload.query";
import { useOrderTruckloadContext } from "../../../order-truckload.provider";
import { useOrderXOverviewContext } from "../../../order-x-overview.provider";
import { getWeight } from "../../../utils/truckload.utils";
import { LogisticWarehouseDialog } from "../../dialog/logistic-warehouse-dialog.component";
import { OrderScrollToTopButton } from "../../order-scroll-to-top-button.component";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";
import { LogisticWarehouseFooter } from "./logistic-warehouse-footer.component";
import { LogisticWarehouseOrderFilter } from "./logistic-warehouse-order-filter.component";
import { LogisticWarehouseOrderRow } from "./logistic-warehouse-order-row.component";
import { LogisticWarehouseSelectFilter } from "./logistic-warehouse-select-filter.component";
import { useGetWarehouseTotalWeight } from "../../../../../repositories/queries/product/query/get-warehouse-total-weight.query";
import { ICfmOrderXFilter } from "../../../../../domain/order-x/cfm-order-x-filter";

const useStyles = makeStyles((theme: Theme) => ({
  emptyTextContainer: {
    marginTop: 40,
  },
  orderFilterContainer: {
    marginTop: 20,
  },
  footerStyle: {
    bottom: 0,
    left: 0,
    position: "fixed",
    zIndex: 600,
    minHeight: 80,
  },
  footerWidthSidebarOpen: {
    width: `calc(100% - ${DRAWER_OPEN}px)`,
  },
  footerWidthSidebarClosed: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
  mainContent: {
    marginBottom: 40,
  },
}));

export const OrderListLogisticWarehouseView: VFC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isSelectDataSet, truckloadFilter, setTruckloadFilter } = useOrderTruckloadContext();
  const {
    orders,
    fetchNextPage,
    isLoading: areOrdersLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    setFilter: setOrderFilter,
    curFilter: orderFilter,
  } = useOrderXOverviewContext();
  const { sidebarOpen } = useSidebarContext();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const { isLoading: isCreateLoading, mutateAsync: createTruckload } = useCreateTruckloadQuery();

  const isLoading = useMemo(
    () => areOrdersLoading || isFetching || isFetchingNextPage || isCreateLoading,
    [areOrdersLoading, isFetching, isFetchingNextPage, isCreateLoading],
  );

  const hasOrders = useMemo(() => orders.length > 0 || orderFilter.filter?.query, [orders]);

  const { data: totalWarehouseWeight } = useGetWarehouseTotalWeight(truckloadFilter.destinationAddressId);
  const selectedWeight = useMemo(() => {
    const selectedOrders = orders.filter((order) => selectedOrderIds.includes(order.id));
    return getWeight(selectedOrders);
  }, [selectedOrderIds, orders]);

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  useEffect(() => {
    const filterObject: ICfmOrderXFilter = {
      ...orderFilter.filter,
      query: truckloadFilter.query,
      fractionId: truckloadFilter.fractionId,
      typeId: truckloadFilter.typeId,
      stateId: truckloadFilter.stateId,
      packageId: truckloadFilter.packageId,
      destinationAddressId: truckloadFilter.destinationAddressId,
      orderOfTruckloadSorting: truckloadFilter.sorting,
      sortingMode: truckloadFilter.sortingMode,
    };
    setOrderFilter({
      ...orderFilter,
      filter: filterObject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    truckloadFilter.destinationAddressId,
    truckloadFilter.fractionId,
    truckloadFilter.typeId,
    truckloadFilter.stateId,
    truckloadFilter.packageId,
    truckloadFilter.query,
    truckloadFilter.sorting,
    truckloadFilter.sortingMode,
    setOrderFilter,
  ]);

  const getEmptyText = () => {
    if (!isSelectDataSet) return t("orders.truckload.chooseSelectData");
    return t("orders.truckload.noData");
  };

  const onCheckboxChanged = (orderId: number, checked: boolean) => {
    if (checked) {
      setSelectedOrderIds([...selectedOrderIds, orderId]);
    } else {
      setSelectedOrderIds(selectedOrderIds.filter((id) => id !== orderId));
    }
  };

  const resetSelectedOrderIds = () => setSelectedOrderIds([]);
  const isOrderSelected = (orderId: number) => selectedOrderIds.includes(orderId);

  const onSubmitCancel = () => {
    setIsSubmitDialogOpen(false);
  };

  const onSubmit = async (data: ICfmCreateTruckload) => {
    await createTruckload(CfmTruckloadConverter.createDataToModel(data));
    setIsSubmitDialogOpen(false);
    setOrderFilter({ ...orderFilter, statusFilter: CfmOrderXApiStatus.LogisticTruckLoadAnnounced });
  };

  const getSelectedOrders = () => orders.filter((order) => selectedOrderIds.includes(order.id));

  return (
    <>
      {isSubmitDialogOpen && (
        <LogisticWarehouseDialog
          startAddressId={truckloadFilter.destinationAddressId}
          open={isSubmitDialogOpen}
          onCancel={onSubmitCancel}
          onSubmit={onSubmit}
          weight={selectedWeight}
          selectedOrders={getSelectedOrders()}
          serviceDateEditEnabled
          isLoading={isLoading}
          isDraftEnabled
        />
      )}
      <Grid container direction="column" spacing={1} className={classes.mainContent}>
        <Grid item>
          <LogisticWarehouseSelectFilter />
        </Grid>
        {hasOrders && (
          <>
            <Grid item className={classes.orderFilterContainer}>
              <LogisticWarehouseOrderFilter
                truckloadFilter={truckloadFilter}
                setTruckloadFilter={(filterLean) => {
                  setTruckloadFilter({
                    ...truckloadFilter,
                    sorting: filterLean.sorting,
                    query: filterLean.query,
                    sortingMode: filterLean.sortingMode,
                  });
                }}
                orders={orders}
                onFilterChanged={resetSelectedOrderIds}
              />
            </Grid>
            {orders.map((order, index) => (
              <Grid item key={index}>
                <LogisticWarehouseOrderRow
                  order={order}
                  disableSelection={!orderFilter.filter?.fractionId}
                  disabledSelectionHint={t("orders.truckload.disabledSelectionHint")}
                  onCheckboxChange={onCheckboxChanged}
                  isSelected={isOrderSelected}
                />
              </Grid>
            ))}
          </>
        )}
        {(!orderFilter.filter?.destinationAddressId || !hasOrders) && !isLoading && (
          <Grid item className={classes.emptyTextContainer}>
            <EmptyWarehouse text={getEmptyText()} />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        className={classNames(classes.footerStyle, {
          [classes.footerWidthSidebarOpen]: sidebarOpen,
          [classes.footerWidthSidebarClosed]: !sidebarOpen,
        })}
      >
        <Grid item>
          <OrderScrollToTopButton />
        </Grid>
        <Grid item>
          <LogisticWarehouseFooter
            selectedWeight={selectedWeight}
            totalWarehouseWeight={totalWarehouseWeight}
            submitDisabled={selectedOrderIds.length === 0}
            onCreateTruckloadClick={() => setIsSubmitDialogOpen(true)}
          />
        </Grid>
      </Grid>
    </>
  );
};
