import { makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { Colors } from "../../../style/Colors";

const orangeRgbaColor = "239, 126, 60";

const useStyles = makeStyles((theme: Theme) => ({
  pulse: {
    background: `${Colors.orange}`,
    borderRadius: "50%",
    margin: 10,
    height: 15,
    width: 15,
    boxShadow: `0 0 0 0 rgba(${orangeRgbaColor}, 1)`,
    transform: "scale(1)",
    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: `0 0 0 0 rgba(${orangeRgbaColor}, 0.7)`,
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: `0 0 0 10px rgba(${orangeRgbaColor}, 0)`,
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: `0 0 0 0 rgba(${orangeRgbaColor}, 0)`,
    },
  },
}));

export const PulseAnimation: VFC = () => {
  const classes = useStyles();

  return <div className={classes.pulse}></div>;
};
