export type SortingMode = "ASC" | "DESC";

export interface ICfmTruckloadSortModel {
  sortingMode: SortingMode;
  sortBy?: CfmTruckloadSortByOptionModel;
}

export enum CfmTruckloadSortByOptionModel {
  TruckloadId = "TruckloadId",
  PlannedDeliveryDate = "PlannedDeliveryDate",
}
