import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import { IProductNameInputs } from "../../../../../configurator/pages/product/components/product-overview-row.component";
import getInstance from "../../../../../utils/rest";
import { CfmUpdateProductConverter } from "../../../models/converter/cfm-update-product.converter";
import { CfmQueryKeys } from "../../cfm-query-keys";

const updateCfmProductName = async (productId: number, data: IProductNameInputs): Promise<void> => {
  await getInstance().put(RestEndpoints.UpdateCfmProductName, {
    data: CfmUpdateProductConverter.productNameDataToModel(productId, data),
  });
};

export const useUpdateCfmProductNameQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    CfmQueryKeys.UpdateProductName,
    ({ productId, data }: { productId: number; data: IProductNameInputs }) => updateCfmProductName(productId, data),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
