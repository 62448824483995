import { makeStyles } from "@material-ui/core";
import { Shades } from "../../../../../style/Colors";

const ORDER_CARD_PADDING_TOP = 15;
const ORDER_CARD_PADDING_BOTTOM = 15;
const ORDER_CARD_PADDING_LEFT = 30;
const ORDER_CARD_PADDING_LEFT_SELECTED_MOBILE = 20;
const ORDER_CARD_PADDING_LEFT_MOBILE = 15;
const ORDER_CARD_PADDING_RIGHT_MOBILE = 15;
const ORDER_CARD_PADDING_RIGHT = 30;
const SELECTED_PADDING_LEFT = 58;
const SELECTED_PADDING_RIGHT = 48;

export const useOrderContentStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: ORDER_CARD_PADDING_TOP,
    paddingBottom: ORDER_CARD_PADDING_BOTTOM,
    paddingLeft: ORDER_CARD_PADDING_LEFT,
    paddingRight: ORDER_CARD_PADDING_RIGHT,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: ORDER_CARD_PADDING_LEFT_MOBILE,
      paddingRight: ORDER_CARD_PADDING_RIGHT_MOBILE,
    },
  },
  cardContentSelected: {
    backgroundColor: Shades.gray20,
    paddingLeft: SELECTED_PADDING_LEFT,
    paddingRight: SELECTED_PADDING_RIGHT,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: ORDER_CARD_PADDING_LEFT_SELECTED_MOBILE,
      paddingRight: ORDER_CARD_PADDING_LEFT_SELECTED_MOBILE,
    },
  },
  cardContentNotSelected: {
    backgroundColor: Shades.gray10,
  },
  cardOrderInformationIcon: {
    width: "fit-content",
    minHeight: 40,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: {
      marginRight: 16,
      width: 50,
      height: "auto",
    },
  },
  iconSizeProduct: {
    height: 40,
    width: 54,
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: "auto",
    },
  },
  iconSizeAddon: {
    height: 32,
    width: 40,
  },
  rowCenter: {
    textAlign: "center",
  },
  iconSizeProductMobile: {
    height: 30,
    width: 39,
  },
  container: { height: "100%" },
}));
