import dayjs from "dayjs";
import { IPomManufacturerFormInputs } from "put-on-market/components/manufacturer-form/manufacturer-form.component";
import { IPomManufacturerModel } from "put-on-market/repositories/models/manufacturer/manufacturer.model";
import { ICreatePomManufacturerRequest } from "put-on-market/repositories/requests/manufacturer/manufacturer-create.request";
import { IUpdatePomManufacturerRequest } from "put-on-market/repositories/requests/manufacturer/manufacturer-update.request";
import { AddressConverter } from "shared/domain/converter/address.converter";
import { GroupConverter } from "shared/domain/converter/group.converter";
import { Countries } from "../../../../utils/Countries";
import { IPomManufacturer } from "../../models/manufacturer/manufacturer";

export class ManufacturerConverter {
  public static toDomain(manufacturer: IPomManufacturerModel): IPomManufacturer {
    return {
      id: manufacturer.id,
      uuid: manufacturer.uuid,
      type: manufacturer.type,
      addressId: manufacturer.addressId,
      address: manufacturer.address ? AddressConverter.toDomain(manufacturer.address) : manufacturer.address,
      name: manufacturer.name,
      groupId: manufacturer.groupId,
      group: manufacturer.group ? GroupConverter.toDomain(manufacturer.group) : manufacturer.group,
      contractStartsAt: manufacturer.contractStartsAt,
      contractEndsAt: manufacturer.contractEndsAt,
      battregNumber: manufacturer.battregNumber,
      externalId: manufacturer.externalId,
    };
  }

  public static toModel(manufacturer: IPomManufacturer): IPomManufacturerModel {
    return {
      id: manufacturer.id,
      uuid: manufacturer.uuid,
      type: manufacturer.type,
      addressId: manufacturer.addressId,
      address: manufacturer.address ? AddressConverter.toModel(manufacturer.address) : manufacturer.address,
      name: manufacturer.name,
      groupId: manufacturer.groupId,
      group: manufacturer.group ? GroupConverter.toModel(manufacturer.group) : manufacturer.group,
      contractStartsAt: manufacturer.contractStartsAt,
      contractEndsAt: manufacturer.contractEndsAt,
      battregNumber: manufacturer.battregNumber,
      externalId: manufacturer.externalId,
    };
  }

  static toDomainList(manufacturers?: IPomManufacturerModel[]): IPomManufacturer[] {
    if (!manufacturers) return [];
    return manufacturers.map(this.toDomain);
  }

  static toModelList(manufacturers?: IPomManufacturer[]): IPomManufacturerModel[] {
    if (!manufacturers) return [];
    return manufacturers.map(this.toModel);
  }

  public static toFormCreateRequest(manufacturer: IPomManufacturerFormInputs): ICreatePomManufacturerRequest {
    return {
      name: manufacturer.name,
      groupId: manufacturer.group?.id ?? -1,
      contractStartsAt: dayjs(manufacturer.contractStartsAt).format("YYYY-MM-DD"),
      contractEndsAt: manufacturer.contractEndsAt ? dayjs(manufacturer.contractEndsAt).format("YYYY-MM-DD") : undefined,
      battregNumber: manufacturer.battregNumber,
      externalId: manufacturer.externalId,
      country: manufacturer.country?.code,
      addressLocation: manufacturer.addressLocation,
      buildingNumber: manufacturer.buildingNumber,
      state: manufacturer.state,
      postal: manufacturer.postal,
      city: manufacturer.city,
      addressName2: manufacturer.addressName2,
    };
  }

  public static toFormUpdateRequest(manufacturer: IPomManufacturerFormInputs): IUpdatePomManufacturerRequest {
    return {
      addressId: manufacturer.addressId ?? -1,
      name: manufacturer.name,
      groupId: manufacturer.group?.id ?? -1,
      contractStartsAt: dayjs(manufacturer.contractStartsAt).format("YYYY-MM-DD"),
      contractEndsAt: manufacturer.contractEndsAt ? dayjs(manufacturer.contractEndsAt).format("YYYY-MM-DD") : undefined,
      battregNumber: manufacturer.battregNumber,
      externalId: manufacturer.externalId,
      country: manufacturer.country?.code,
      addressLocation: manufacturer.addressLocation,
      buildingNumber: manufacturer.buildingNumber,
      state: manufacturer.state,
      postal: manufacturer.postal,
      city: manufacturer.city,
      addressName2: manufacturer.addressName2,
    };
  }

  public static toForm(manufacturer: IPomManufacturer): IPomManufacturerFormInputs {
    const country = Countries.find((c) => c.code === manufacturer.address?.country);
    return {
      addressId: manufacturer.addressId,
      addressLocation: manufacturer.address?.address ?? undefined,
      country: country ?? undefined,
      buildingNumber: manufacturer.address?.buildingNumber ?? undefined,
      state: manufacturer.address?.state ?? undefined,
      postal: manufacturer.address?.postal ?? undefined,
      city: manufacturer.address?.city ?? undefined,
      group: manufacturer.group,
      contractStartsAt: manufacturer.contractStartsAt,
      contractEndsAt: manufacturer.contractEndsAt,
      battregNumber: manufacturer.battregNumber ?? undefined,
      externalId: manufacturer.externalId ?? undefined,
      name: manufacturer.name,
      addressName2: manufacturer.address?.name2 ?? undefined,
    };
  }
}
