import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { ChangeEvent, FC, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { Colors, Shades } from "../../../../style/Colors";
import { translateCountryByCode } from "../../../../utils/address.util";
import { IOrdersXPerCustomerAddress } from "./cart-content.component";
import { CartOrderAccordion } from "./cart-order-accordion.component";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  checkboxMin: {
    minWidth: 40,
  },
  selectorMargin: {
    marginRight: 20,
  },
  selectorMobileMargin: {
    marginBottom: 8,
  },
  selector: {
    backgroundColor: Colors.white,
    borderColor: Colors.white,
    border: "1px solid",
    height: "38px",
    width: "38px",
    borderRadius: "100%",
  },
  selectorText: {
    color: Colors.greenLight,
    textAlign: "center",
    lineHeight: "38px",
  },
  detailsContainer: {
    padding: 0,
    backgroundColor: Colors.white,
  },
  summaryTextBold: {
    color: Colors.white,
    fontWeight: "bold",
  },
  summaryText: {
    color: Colors.white,
  },
  item: {
    width: "calc(100% - 40px)",
  },
  itemSpacing: {
    marginTop: 2,
  },
  mobileOrderCountBadge: {
    marginRight: 30,
    marginBottom: 0,
  },
  expandIcon: {
    color: Colors.white,
    height: 30,
    width: 30,
  },
  expandedIcon: {
    transform: "rotate(90deg)",
    color: Colors.white,
    height: 30,
    width: 30,
  },
  accordionSummaryHeightOnExpand: {
    "&.Mui-expanded": {
      minHeight: 72,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "auto",
    },
  },
  rowsContainer: {
    "&>div:nth-child(odd)>div": {
      backgroundColor: Shades.gray10,
    },
    "&>div:nth-child(even)>div": {
      backgroundColor: Shades.gray20,
    },
  },
  mobileAccordionHeader: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 38px)",
    },
  },
}));

const AccordionSummaryNoExpandIconRotate = withStyles((theme) => ({
  root: {
    backgroundColor: Colors.greenLight,
    borderRadius: 5,
    minHeight: 60,
    padding: "15px 30px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
      flexDirection: "column-reverse",
    },
  },
  expandIcon: {
    transform: "none !important",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      top: "40%",
      right: 15,
    },
  },
  expanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}))(AccordionSummary);

interface ICartAddressWithOrdersProps {
  ordersPerCustomerAddress: IOrdersXPerCustomerAddress;
  selectedOrderIds: number[];
  onSelect: (ids: number[]) => void;
}

export const CartAddressWithOrders: FC<ICartAddressWithOrdersProps> = (props) => {
  const { ordersPerCustomerAddress, selectedOrderIds, onSelect } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();
  const [isExpanded, setIsExpanded] = useState(true);

  const address = useMemo(() => {
    return ordersPerCustomerAddress.orders.find((order) => order.customerAddress)?.customerAddress;
  }, [ordersPerCustomerAddress]);

  const handleSelectAllCheckbox = (checked: boolean) => {
    setIsExpanded(isExpanded);
    const currentOrderIds = ordersPerCustomerAddress.orders.map((order) => order.id);
    if (checked) {
      onSelect(selectedOrderIds.concat(currentOrderIds));
    } else {
      onSelect(selectedOrderIds.filter((id) => !currentOrderIds.includes(id)));
    }
  };

  const handleSelectOrder = (checked: boolean, orderId: number) => {
    if (checked) {
      onSelect(selectedOrderIds.concat([orderId]));
    } else {
      onSelect(selectedOrderIds.filter((id) => id !== orderId));
    }
  };

  const areAllOrdersSelected = () => {
    const currentOrderIds = ordersPerCustomerAddress.orders.map((order) => order.id);
    return currentOrderIds.every((id) => selectedOrderIds.includes(id));
  };

  const getAddressMiscText = () => {
    if (!address) return "";
    let text = `${address.contactPersonName}, ${address.contactPersonEmail}, ${address.contactPersonTelephone}`;

    if (address.address && address.buildingNumber) {
      text = `${text}, ${address.address} ${address.buildingNumber}`;
    }

    if (address.postal) {
      text = `${text}, ${address.postal}`;
    }

    if (address.city) {
      text = `${text}, ${address.city}`;
    }

    if (address.country) {
      text = `${text}, ${translateCountryByCode(address.country, t)}`;
    }

    return text;
  };

  // Underlying component exposes this type
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const getOrderCountBadge = (): ReactNode => {
    return (
      <Box
        className={classNames(classes.selector, {
          [classes.selectorMargin]: isDesktop || isLaptop,
          [classes.selectorMobileMargin]: isMobile || isTablet,
          [classes.mobileOrderCountBadge]: isMobile || isTablet,
        })}
      >
        <Typography variant="body1" className={classes.selectorText}>
          {ordersPerCustomerAddress.orders.length}
        </Typography>
      </Box>
    );
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleExpandChange} elevation={0}>
      <AccordionSummaryNoExpandIconRotate
        className={classes.accordionSummaryHeightOnExpand}
        expandIcon={
          <ArrowForwardIosOutlined classes={{ root: isExpanded ? classes.expandedIcon : classes.expandIcon }} />
        }
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.mobileAccordionHeader}
        >
          <Grid item xs={11} container direction="row" spacing={1}>
            <Grid item className={classes.checkboxMin}>
              <Box display={"flex"} alignItems={"center"} className={classes.container}>
                <OrderCheckbox
                  label={""}
                  color="white"
                  onChange={handleSelectAllCheckbox}
                  checked={areAllOrdersSelected()}
                  size="medium"
                />
              </Box>
            </Grid>
            <Grid item xs container direction="column">
              <Grid item>
                <Typography variant="body1" className={classes.summaryText}>
                  {t("cart.customerAddress", { name: `(${address?.id ?? ""}) ${address?.name ?? "-"}` })}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.summaryText}>
                  {getAddressMiscText()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1}>
            {getOrderCountBadge()}
          </Grid>
        </Grid>
      </AccordionSummaryNoExpandIconRotate>
      <AccordionDetails classes={{ root: classes.detailsContainer }}>
        <Grid container direction="column" className={classes.rowsContainer}>
          {ordersPerCustomerAddress.orders.map((order, index) => (
            <Grid item key={index} className={classNames({ [classes.itemSpacing]: index > 0 })}>
              <CartOrderAccordion order={order} selectedOrderIds={selectedOrderIds} onSelect={handleSelectOrder} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
