import { Grid } from "@material-ui/core";
import parse from "html-react-parser";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { IApiMail } from "../../../../domain/mail/mail";
import { AppDialog } from "../../../../components/dialog/app-dialog.component";

interface IMailDetailDialogProps {
  mail: IApiMail | undefined;
  onClose: VoidFunction;
}

export const MailDetailDialog: VFC<IMailDetailDialogProps> = (props) => {
  const { mail, onClose } = props;
  const { t } = useTranslation();

  const htmlStringToReact = (mail: IApiMail): string | JSX.Element | JSX.Element[] => {
    if (mail.html) {
      return parse(mail.html);
    }

    return <></>;
  };

  return (
    <AppDialog title={t("mail.name")} open={mail !== undefined} onCancelClick={onClose}>
      <Grid container direction="column" spacing={2}>
        {mail && <Grid item>{htmlStringToReact(mail)}</Grid>}
      </Grid>
    </AppDialog>
  );
};
