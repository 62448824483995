import { Box, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  ResponseFieldCalendarIcon,
  ResponseFieldFileIcon,
  ResponseFieldFloatingPointIcon,
  ResponseFieldNumberIcon,
  ResponseFieldTextAreaIcon,
  ResponseFieldTextIcon,
} from "../../../../../components/Primitives/Icons";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { PrimaryColors } from "../../../../../style/Colors";
import { CfmResponseFieldType } from "../../../../../collect-from-market/domain/response-values-x/cfm-response-field-type";

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    color: PrimaryColors.base,
    height: 16,
    width: 16,
  },
  iconStyleText: {
    color: PrimaryColors.base,
    height: 16,
    width: 19,
  },
  hoverToolTip: {
    "&:hover": {
      cursor: "help",
    },
  },
}));

interface IResponseFieldTypeIconProps {
  type: CfmResponseFieldType;
}

export const ResponseFieldTypeIcon: VFC<IResponseFieldTypeIconProps> = (props) => {
  const { type } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getTypeIcon = () => {
    switch (type) {
      case CfmResponseFieldType.Text:
        return <ResponseFieldTextIcon className={classes.iconStyleText} />;
      case CfmResponseFieldType.TextArea:
        return <ResponseFieldTextAreaIcon className={classes.iconStyle} />;
      case CfmResponseFieldType.Number:
        return <ResponseFieldNumberIcon className={classes.iconStyle} />;
      case CfmResponseFieldType.FloatingPoint:
        return <ResponseFieldFloatingPointIcon className={classes.iconStyle} />;
      case CfmResponseFieldType.Date:
        return <ResponseFieldCalendarIcon className={classes.iconStyle} />;
      case CfmResponseFieldType.File:
        return <ResponseFieldFileIcon className={classes.iconStyle} />;
    }
  };

  return (
    <NewOrderToolTip
      title={t(`configuration.responseField.type.${type}`)}
      className={classes.hoverToolTip}
      placement="top"
    >
      <Box>{getTypeIcon()}</Box>
    </NewOrderToolTip>
  );
};
