import { IProductNameInputs } from "../../../../configurator/pages/product/components/product-overview-row.component";
import { IUpdateProductNameModel } from "../product/cfm-update-products.model";

export class CfmUpdateProductConverter {
  public static productNameDataToModel(productId: number, data: IProductNameInputs): IUpdateProductNameModel {
    return {
      productId,
      name: data.name,
    };
  }
}
