import { IPomProductModel } from "../products/pom-products.model";
import { PomAnnouncementPeriod } from "./pom-announcement.period";

export interface IPomAnnouncementModel {
  id: number;
  announcementId: number;
  announcementDate: Date;
  products: IPomAnnouncementProductModel[];
  status: PomAnnouncementStatusModel;
  announcementPeriod: PomAnnouncementPeriod;
  purchaseOrderNumber: string;
  createdAt: Date;
}

export interface IPomAnnouncementProductModel {
  productData: IPomProductModel;
  count: number;
  weight: number;
  createdByAdminName: string | null;
  createdByUserName: string;
  history: IPomAnnouncementProductModel[];
  createdAt: Date;
}

export enum PomAnnouncementStatusModel {
  Draft = "draft",
  Published = "published",
}
