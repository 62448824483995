import { IProductsResponseModel } from "../../collect-from-market/repositories/models/product/cfm-product.model";

export const getNextProductPageParam = (lastPage: IProductsResponseModel, pages: IProductsResponseModel[]) => {
  const hasNextPage = pages.length < (lastPage.paginationMeta?.numberOfPages ?? 0);
  if (hasNextPage) {
    return pages.length + 1;
  }

  // Return undefined to indicate there is no next page available.
  return undefined;
};
