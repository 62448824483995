import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAutocompleteStyles } from "../../style/autocomplete.style";
import { IAutocompleteOption } from "../../utils/AutocompleteHelper";

interface IAutocompleteNoFormProps {
  onChange: (option: IAutocompleteOption | IAutocompleteOption[] | null) => void;
  disabled: boolean;
  value?: IAutocompleteOption | IAutocompleteOption[];
  options: IAutocompleteOption[];
  error?: boolean;
  label: string;
  multiple?: boolean;
  disableClearable?: boolean;
  limit?: number;
  required?: boolean;
  startAdornment?: ReactNode;
}

export const AutoCompleteNoForm: FunctionComponent<IAutocompleteNoFormProps> = (props) => {
  const {
    onChange,
    disabled,
    value,
    options,
    error,
    label,
    multiple,
    limit,
    disableClearable = false,
    required = true,
    startAdornment,
  } = props;
  const classes = useAutocompleteStyles();
  const { t } = useTranslation();

  const handleSelected = (o1: IAutocompleteOption, o2: IAutocompleteOption) => {
    const selected = o1.id.toString() === o2.id.toString();
    return selected;
  };

  return (
    <Autocomplete
      getOptionSelected={handleSelected}
      multiple={multiple}
      disabled={disabled}
      noOptionsText={t("general.autocomplete.noOptions")}
      onChange={(e, value) => onChange(value)}
      value={value}
      getOptionLabel={(option: IAutocompleteOption) => {
        return option.name;
      }}
      options={options}
      classes={{
        inputRoot: classes.root,
        endAdornment: classes.adornment,
      }}
      size="small"
      limitTags={limit ?? -1}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          error={error}
          required={required}
          {...params}
          InputLabelProps={{ shrink: true }}
          InputProps={{ ...params.InputProps, startAdornment }}
          label={label}
        />
      )}
    />
  );
};
