import lodash from "lodash";
import { useEffect, useState } from "react";

interface IUseErpNumberFormatterProps {
  erpNumber: string | undefined | null;
}

interface IUseErpNumberFormatter {
  erpNumber: string | undefined;
  formattedErpNumber: string | undefined;
  prefix: string | undefined;
  number: string | undefined;
}

const getNumberOnly = (prefixAndNumber: string | undefined | null): string | undefined => {
  if (prefixAndNumber == null) return undefined;
  const regEx = new RegExp("[0-9]*$");
  const matchValue = prefixAndNumber?.match(regEx);
  const value = matchValue ? matchValue[0] : "";
  return value.trim();
};

const getPrefixOnly = (prefixAndNumber: string | undefined | null): string | undefined => {
  if (prefixAndNumber == null) return undefined;
  const numberOnly = getNumberOnly(prefixAndNumber) ?? "";
  return prefixAndNumber.replaceAll("-", "").replace(numberOnly, "").toUpperCase().trim();
};

const getFormatted = (prefix: string, number: string): string => `${prefix}${number}`;

export const useErpNumberFormatter = ({ erpNumber }: IUseErpNumberFormatterProps): IUseErpNumberFormatter => {
  const [formattedErpNumber, setFormattedErpNumber] = useState<string>();
  const [prefix, setPrefix] = useState<string>();
  const [number, setNumber] = useState<string>();

  useEffect(() => {
    const numberOnly = getNumberOnly(erpNumber);
    const prefixOnly = getPrefixOnly(erpNumber);

    setNumber(numberOnly);
    setPrefix(prefixOnly);

    if (numberOnly && !lodash.isEmpty(numberOnly) && prefixOnly && !lodash.isEmpty(prefixOnly)) {
      setFormattedErpNumber(getFormatted(prefixOnly, numberOnly));
    } else {
      setFormattedErpNumber(erpNumber ?? undefined);
    }
  }, [erpNumber]);

  return {
    erpNumber: erpNumber ?? undefined,
    formattedErpNumber,
    prefix,
    number,
  };
};
