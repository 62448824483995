import isEmail from "validator/lib/isEmail";

export const notNullValue = (value: unknown): boolean => value != null && value !== "";

export const isNullValue = (value: unknown): boolean => !notNullValue(value);

export const nonEmptyString = (value: string): boolean => notNullValue(value) && value.trim().length > 0;

export const mailValidation = (value: string, message: string) => (value && isEmail(value) ? undefined : message);

export const positiveNumberOnly = (value: number, message: string, zeroAllowed?: boolean) => {
  if (zeroAllowed === false) {
    return value > 0 ? undefined : message;
  }
  return value >= 0 ? undefined : message;
};
