import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { ArrowRightBig } from "../../../../components/Primitives";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { PrimaryColors } from "../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  greenIcon: {
    color: PrimaryColors.base,
    height: 30,
    width: 40,
    [theme.breakpoints.down("xs")]: {
      marginTop: 4,
    },
  },
  mobileAlign: {
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  headerContainer: {
    alignItems: "center",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
}));

interface IWizardStepHeaderProps {
  text: string;
}

export const WizardStepHeader: VFC<IWizardStepHeaderProps> = (props) => {
  const { text } = props;
  const classes = useStyles();
  const { isMobile } = useScreenSize();

  return (
    <Grid container direction="row" spacing={2} className={classes.headerContainer}>
      <Grid item>
        <ArrowRightBig className={classes.greenIcon} />
      </Grid>
      <Grid item className={classNames({ [classes.mobileAlign]: isMobile })}>
        <Typography variant="h1">{text}</Typography>
      </Grid>
    </Grid>
  );
};
