import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { GroupTypeFilterValues } from "../../../components/group/group-filter/group-type-filter.component";
import { PaginationMetaConverter } from "../../../domain/converter/pagination-meta.converter";
import { UserConverter } from "../../../domain/converter/user.converter";
import { IPaginationInputMeta } from "../../../domain/pagination/pagination-meta";
import { IUserResult } from "../../../domain/user/user";
import { IUserListModel } from "../../../models/user/user.model";
import { SharedQueryKeys } from "../shared-query-keys";

export interface IUsersFilter {
  active?: boolean;
  searchText?: string;
  groupId?: number;
  groupTypes?: GroupTypeFilterValues[];
}

const getUsersWithFilters = async (filter: IUsersFilter, pagination: IPaginationInputMeta): Promise<IUserResult> => {
  const { active, groupTypes, searchText, groupId } = filter;
  const { page, pageSize } = pagination ?? {};
  const { data } = await getInstance().get<IUserListModel>(RestEndpoints.AllUsers, {
    params: { active, groupTypes, query: searchText, page, groupId, pageSize },
  });
  return {
    users: data.users.map(UserConverter.toDomain),
    meta: data.meta ? PaginationMetaConverter.toDomain(data.meta) : undefined,
  };
};

export const useGetUsersWithFiltersQuery = (
  filter: IUsersFilter,
  enabled: boolean = true,
  pagination: IPaginationInputMeta,
  onSuccess: (data: IUserResult) => void,
) => {
  return useQuery(
    [SharedQueryKeys.GetUsersWithFilter, filter, pagination],
    () => getUsersWithFilters(filter, pagination),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled,
      onSuccess,
    },
  );
};
