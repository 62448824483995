import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { Colors, PrimaryColors, Shades } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    border: "1px solid",
    borderRadius: "4px",
  },
  optionNotDisabled: {
    borderColor: PrimaryColors.base,
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionDisabled: {
    borderColor: Colors.gray,
    "&:hover": {
      cursor: "default",
    },
  },
  optionText: {
    fontWeight: "bold",
    padding: "3px 6px 1px 6px",
  },
  optionTextNotSelected: {
    color: PrimaryColors.base,
  },
  optionTextSelected: {
    color: Shades.white,
  },
  optionTextDisabled: {
    color: Colors.gray,
  },
  optionSelected: {
    backgroundColor: PrimaryColors.base,
  },
  optionNotSelected: {
    backgroundColor: Shades.white,
  },
  label: {
    textAlign: "end",
    marginTop: 2,
  },
  labelContent: {
    minWidth: 130,
  },
}));

export interface IConfigurationOption {
  value: string | number;
  label: string;
}

interface IProductConfigurationProps {
  selectedOptions: IConfigurationOption[] | undefined;
  label: string;
  options: IConfigurationOption[];
  onSelect: (value: IConfigurationOption | IConfigurationOption[] | undefined) => void;
  multiSelect: boolean;
  disabled: boolean;
}

export const ProductConfiguration: VFC<IProductConfigurationProps> = (props) => {
  const { label, options, multiSelect, onSelect, disabled, selectedOptions } = props;
  const classes = useStyles();

  const handleClick = (option: IConfigurationOption) => {
    if (disabled) return;
    onSelect(option);
  };

  const handleClickMultiple = (option: IConfigurationOption) => {
    if (disabled) return;
    const alreadyExisting = ((selectedOptions as IConfigurationOption[]) ?? []).find(
      (selectedOption) => selectedOption.value === option.value,
    );
    let options: IConfigurationOption[] | undefined;
    if (alreadyExisting) {
      options = ((selectedOptions as IConfigurationOption[]) ?? []).filter(
        (selectedOption) => selectedOption.value !== option.value,
      );
    } else {
      options = ((selectedOptions as IConfigurationOption[]) ?? []).concat(option);
    }
    onSelect(options);
  };

  const isSelected = (option: IConfigurationOption) => {
    const options = selectedOptions as IConfigurationOption[] | undefined;
    return (options ?? []).some((selectedOption) => selectedOption.value === option.value);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item className={classes.label}>
        <Typography variant="body1" className={classes.labelContent}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" spacing={1}>
          {options.map((option) => {
            return (
              <Grid item key={option.value}>
                <Box
                  id={option.value.toString()}
                  onClick={() => (multiSelect ? handleClickMultiple(option) : handleClick(option))}
                  className={classNames(classes.option, {
                    [classes.optionSelected]: isSelected(option),
                    [classes.optionNotSelected]: !isSelected(option),
                    [classes.optionNotDisabled]: !disabled,
                    [classes.optionDisabled]: disabled,
                  })}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classNames(classes.optionText, {
                      [classes.optionTextSelected]: isSelected(option),
                      [classes.optionTextNotSelected]: !isSelected(option),
                      [classes.optionTextDisabled]: disabled,
                    })}
                  >
                    {option.label}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
