import { Grid, makeStyles, Theme } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import SearchField from "../../../../../../components/SearchField";
import { PrimaryColors } from "../../../../../../style/Colors";
import { ICfmOrderX } from "../../../../../domain/order-x/cfm-order-x";
import { OrderOfTruckloadSorting } from "../../../order-truckload.provider";
import { ISortComponentOption, SortComponent } from "../../../../../../shared/components/filter/sort.component";
import { SortingMode } from "../../../../../repositories/models/truckload/cfm-truckload-sort.model";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  icon: {
    color: PrimaryColors.base,
  },
  searchFieldContainer: {
    paddingBottom: 24,
  },
}));

export interface ITruckloadLeanFilter {
  sorting?: OrderOfTruckloadSorting;
  sortingMode?: SortingMode;
  query?: string;
}

interface ILogisticWarehouseOrderFilterProps {
  onFilterChanged: VoidFunction;
  truckloadFilter: ITruckloadLeanFilter;
  setTruckloadFilter: (filter: ITruckloadLeanFilter) => void;
  orders: ICfmOrderX[];
  hideFilter?: boolean;
}

export const LogisticWarehouseOrderFilter: VFC<ILogisticWarehouseOrderFilterProps> = (props) => {
  const { onFilterChanged, truckloadFilter, setTruckloadFilter, orders, hideFilter } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const displaySearchField = useMemo(() => orders.length > 5 || truckloadFilter.query, [orders, truckloadFilter]);

  const handleSortingChanged = (checked: boolean, sorting: OrderOfTruckloadSorting) => {
    if (checked) {
      setTruckloadFilter({ ...truckloadFilter, sorting });
    }
    onFilterChanged();
  };

  const handleSortingModeChanged = (sortingMode: SortingMode) => {
    setTruckloadFilter({ ...truckloadFilter, sortingMode });
    onFilterChanged();
  };

  const sortOptions: ISortComponentOption[] = [
    {
      label: t(`orders.truckload.sorting.${OrderOfTruckloadSorting.WarehouseEntryDate}`),
      checked: truckloadFilter.sorting === OrderOfTruckloadSorting.WarehouseEntryDate,
      value: "sortingTruckloadDate",
      onChange: (checked) => handleSortingChanged(checked, OrderOfTruckloadSorting.WarehouseEntryDate),
    },
    {
      label: t(`orders.truckload.sorting.${OrderOfTruckloadSorting.OrderNumber}`),
      checked: truckloadFilter.sorting === OrderOfTruckloadSorting.OrderNumber,
      value: "sortingOrderNumber",
      onChange: (checked) => handleSortingChanged(checked, OrderOfTruckloadSorting.OrderNumber),
    },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      {!hideFilter && (
        <Grid item xs={12}>
          <SortComponent
            initialSortIconState={truckloadFilter.sortingMode}
            options={sortOptions}
            onSortIconClick={() => {
              handleSortingModeChanged(truckloadFilter.sortingMode === "ASC" ? "DESC" : "ASC");
            }}
          />
        </Grid>
      )}
      {displaySearchField && (
        <Grid item xs={12} className={classes.searchFieldContainer}>
          <SearchField
            initialValue={truckloadFilter.query}
            size="large"
            tooltip={t("order_filter_search_tooltip")}
            onSearchSubmit={(value: string) => {
              setTruckloadFilter({ ...truckloadFilter, query: value });
              onFilterChanged();
            }}
            placeholder={t("order_filter_search_placeholder")}
            autoFocus={false}
          />
        </Grid>
      )}
    </Grid>
  );
};
