import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { EditIconButton } from "../../../../components/Primitives";
import { GroupDetailStatusTag } from "./group-detail-status-tag.component";

const useStyles = makeStyles((theme: Theme) => ({
  iconAlign: {
    textAlign: "right",
  },
}));

interface IGroupDetailNameProps {
  name: string;
  active: Date | null;
  grsManagedEar: boolean;
  onEditClicked?: VoidFunction;
}

export const GroupDetailName: FC<IGroupDetailNameProps> = (props) => {
  const { name, active, grsManagedEar, onEditClicked } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const getActiveStatusLabel = () => {
    return active === null ? t("general.inactive") : t("general.active");
  };

  return (
    <Grid container direction="row" justifyContent="space-between" spacing={1}>
      <Grid item xs={10} md="auto">
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="h1">{name}</Typography>
          </Grid>
          <Grid item>
            <GroupDetailStatusTag label={getActiveStatusLabel()} type={active === null ? "error" : "success"} />
          </Grid>
          {grsManagedEar && (
            <Grid item>
              <GroupDetailStatusTag label={t("basedata.groups.edit.grsManagedEar")} type={"success"} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {onEditClicked && (
        <Grid item xs={2} md="auto" className={classes.iconAlign}>
          <EditIconButton onClick={onEditClicked} />
        </Grid>
      )}
    </Grid>
  );
};
