import axios, { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import qs from "qs";
import config from "../config/config";
import { clearLogin } from "./auth";
import { AvailableSharedRoutes } from "./constants";

/**
 * @deprecated use a real typed interface
 */
export interface ILooseObject {
  [key: string]: any;
}

const defaultAPIConfig: ILooseObject = {};
const defaultAuth: ILooseObject = {};

const { api = defaultAPIConfig, auth = defaultAuth } = config;
const { baseURL } = api;
const { tokenKey } = auth;

const getInstance = (headers = {}, props = {}) => {
  const token: string = localStorage.getItem(tokenKey) || "";

  let heads: ILooseObject = {};

  if (token) {
    heads = { Authorization: `Bearer ${token}`, ...headers };
  }

  const axiosInstance = axios.create({
    baseURL,
    headers: heads,
    // required to send cookies
    withCredentials: true,
    paramsSerializer: (params) => qs.stringify(params),
    ...props,
  });

  // logout on 401-Unauthorized response
  // to handle expired sessions
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        clearLogin();
        if (window.location.pathname !== AvailableSharedRoutes.Login) {
          window.location.pathname = AvailableSharedRoutes.Login;
        }
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export const getInstanceMultipartFormData = () =>
  getInstance({
    "Content-Type": "multipart/form-data",
  });

export function instanceOfAxiosError(object: any): object is AxiosError {
  return "isAxiosError" in object;
}

export default getInstance;
