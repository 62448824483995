import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { CfmTruckloadConverter } from "../../../models/converter/truckload/cfm-truckload.converter";
import { IUpdateTruckloadInfoAndOrdersModel } from "../../../models/truckload/cfm-update-truckload.model";
import { ICfmTruckloadModel } from "../../../models/truckload/cfm-truckload.model";
import { ICfmTruckload } from "../../../../domain/truckload/cfm-truckload";

const updateTruckloadInfoAndOrders = async (
  requestData: IUpdateTruckloadInfoAndOrdersModel,
): Promise<ICfmTruckload> => {
  const { data } = await getInstance().patch<ICfmTruckloadModel>(
    RestEndpoints.UpdateCfmTruckload.replace(":id", requestData.truckloadId.toString()),
    {
      type: requestData.type,
      orderIds: requestData.orderIds,
      targetAddressId: requestData.targetAddressId,
      plannedDeliveryDate: requestData.plannedDeliveryDate,
      actualWeight: requestData.actualWeight,
      actualDeliveryDate: requestData.actualDeliveryDate,
      serviceDatesForOrders: requestData.serviceDatesForOrders,
      status: requestData.status,
    },
  );
  return CfmTruckloadConverter.toDomain(data);
};

export const useUpdateTruckloadInfoAndOrdersQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation([CfmQueryKeys.UpdateTruckloadOrders], updateTruckloadInfoAndOrders, {
    onSuccess: () => {
      enqueueSnackbar(t("notifications.order_saved_success.message"), {
        variant: "success",
      });
      queryClient.invalidateQueries([CfmQueryKeys.GetOrdersXWithFilterInfinite]);
      queryClient.invalidateQueries([CfmQueryKeys.Truckloads]);
      queryClient.invalidateQueries([CfmQueryKeys.Truckload]);
    },
  });
};
