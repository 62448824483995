import { ICfmProductType } from "../../../domain/products/cfm-product-type";
import { ICfmProductTypeModel } from "../product/cfm-product-type.model";

export class CfmProductTypeConverter {
  public static toModel(type: ICfmProductType): ICfmProductTypeModel {
    return {
      id: type.id,
      name: type.name,
      active: type.active,
      internalNumber: type.internalNumber,
    };
  }

  public static toDomain(type: ICfmProductTypeModel): ICfmProductType {
    return {
      id: type.id,
      name: type.name,
      active: type.active,
      internalNumber: type.internalNumber,
    };
  }
}
