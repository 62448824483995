import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors } from "../../../style/Colors";

export const useDialogStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      "& >div": {
        width: "100%",
        "& button": {
          width: "100%",
        },
      },
    },
  },
  dialogHeader: {
    marginLeft: 24,
    textAlign: "left",
  },
  dialogContent: {
    backgroundColor: Colors.grayLight,
    overflow: "unset",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  headerBox: {
    backgroundColor: PrimaryColors.base,
    borderBottom: "2px solid",
    borderBottomColor: PrimaryColors.active,
  },
  textContainer: {
    paddingLeft: 33,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 12,
    },
  },
}));
