import { BadgeClassKey, StyleRules } from "@material-ui/core";
import { Colors } from "style/Colors";
import { FONT_BADGE_SIZE, FONT_FAMILY } from "./constant";

export const MuiBadge: Partial<StyleRules<BadgeClassKey, {}>> = {
  root: {
    width: "10px",
    "&.bg-orange": {
      "& .MuiBadge-badge": {
        backgroundColor: Colors.orange,
        fontWeight: "bold",
        fontSize: "12px",
      },
    },

    "&.bg-greenDark": {
      "& .MuiBadge-badge": {
        backgroundColor: Colors.accentGreenLight,
      },
    },
  },
  badge: {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_BADGE_SIZE,
  },
  anchorOriginTopRightRectangle: {
    backgroundColor: Colors.greenDark,
    fontWeight: "bold",
  },
};
