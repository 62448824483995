import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { IResponseAssignmentRowValue } from "../../../../configurator/pages/product-response/product-response-assignment.provider";
import getInstance, { instanceOfAxiosError } from "../../../../utils/rest";
import { CfmResponseAssignmentXMutationConverter } from "../../models/converter/cfm-response-assignment-x-mutation.converter";
import { CfmQueryKeys } from "../cfm-query-keys";
import { StatusCodes } from "http-status-codes";

const createProductPackageConfig = async (
  productIds: number[],
  responseAssignments: IResponseAssignmentRowValue[],
): Promise<void> => {
  const convertedAssignments = responseAssignments.map(CfmResponseAssignmentXMutationConverter.toModel);

  await getInstance().post(RestEndpoints.ResponseAssignmentsX, {
    productIds,
    responseAssignments: convertedAssignments,
  });
};

export const useCreateResponseAssignmentsForProducts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation(
    CfmQueryKeys.CreateProductPackageConfig,
    ({
      productIds,
      responseAssignments,
    }: {
      productIds: number[];
      responseAssignments: IResponseAssignmentRowValue[];
    }) => createProductPackageConfig(productIds, responseAssignments),
    {
      onSuccess: () => {
        enqueueSnackbar(t("configuration.responseAssignments.create.success"), { variant: "success" });
      },
      onError: (error) => {
        if (instanceOfAxiosError(error) && error.response?.status === StatusCodes.BAD_REQUEST) {
          enqueueSnackbar(t("configuration.responseAssignments.create.error.billableResponseFieldNumberOnly"), {
            variant: "error",
          });
        }
      },
    },
  );
};
