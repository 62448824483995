export const base64toBlobPDF = (data: string) => {
  const base64WithoutPrefix = data.substring("data:application/pdf;base64,".length);

  const raw = window.atob(base64WithoutPrefix);
  const rawLength = raw.length;
  const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    blobArray[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([blobArray], { type: "application/pdf" });
  return blob;
};
