import { DatePickerView, KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useShrinkLabel } from "../../../../hooks/useShrinkLabel";

interface IFormDatepickerField {
  hasError?: boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  disablePast?: boolean;
  label: string;
  control: Control<any, object>;
  name: string;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  emptyLabel?: string;
  views?: DatePickerView[];
}

export const FormDatepickerField = React.forwardRef(
  (
    {
      hasError,
      errorMessage,
      label,
      required = true,
      control,
      name,
      disabled = false,
      minDate,
      maxDate,
      disablePast = false,
      format = "DD.MM.YYYY",
      emptyLabel = "TT.MM.JJJJ",
      views = ["date"],
    }: IFormDatepickerField,
    _,
  ) => {
    const { t } = useTranslation();
    const { shrinkStyle } = useShrinkLabel(label);

    const getLabelElement = () => {
      if (!required) return label;

      return <b>{label}</b>;
    };

    const validateDate = (value: Date) => {
      // Empty?
      if (!value && !required) return undefined;
      if (!value && required) return "Field is required, but empty";

      // Valid?
      const date = dayjs(value);
      if (!date.isValid()) return "Invalid date";

      // Range?
      if (disablePast && date.isBefore(dayjs(), "day")) return "Date cannot be in the past";
      if (minDate && date.isBefore(minDate, "day"))
        return `Date cannot be before ${dayjs(minDate).format("YYYY-MM-DD")}`;
      if (maxDate && date.isAfter(maxDate, "day")) return `Date cannot be after ${dayjs(maxDate).format("YYYY-MM-DD")}`;

      // Valid.
      return undefined;
    };

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={{ required: required, validate: validateDate }}
        render={({ field: { onChange, value } }) => (
          <KeyboardDatePicker
            required={required}
            fullWidth
            label={getLabelElement()}
            InputLabelProps={{ shrink: true, style: shrinkStyle }}
            value={value}
            onChange={onChange}
            error={hasError}
            helperText={errorMessage}
            autoOk
            minDate={minDate}
            maxDate={maxDate}
            format={format}
            emptyLabel={emptyLabel}
            disabled={disabled}
            disablePast={disablePast}
            cancelLabel={t("general.cancel.text")}
            okLabel={t("general.ok.text")}
            views={views}
          />
        )}
      />
    );
  },
);
