import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FC } from "react";
import { Colors, PrimaryColors } from "../../../style/Colors";

const rowMarginTop = 8;

const useStyles = makeStyles((theme: Theme) => ({
  rowContainer: {
    minHeight: "50px",
    borderRadius: "4px",
    marginTop: rowMarginTop,
    backgroundColor: PrimaryColors.base,
    color: Colors.white,
  },
}));

export const ResponseFieldRowHeader: FC = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.rowContainer}>
      {children}
    </Grid>
  );
};
