import { Box, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { useProductRowStyle } from "../../../style/product-row.style";

interface IArticleNumberBoxProps {
  articleNumber: string;
  disabled?: boolean;
}

export const ArticleNumberBox: VFC<IArticleNumberBoxProps> = (props) => {
  const { articleNumber, disabled } = props;
  const classes = useProductRowStyle();

  return (
    <Box
      className={classNames(classes.articleNumberContainer, {
        [classes.articleNumberContainerDefault]: !disabled,
        [classes.articleNumberContainerExistingProduct]: disabled,
      })}
    >
      <Typography
        variant="body2"
        className={classNames(classes.articleNumberDefault, {
          [classes.articleNumberText]: !disabled,
          [classes.articleNumberTextExistingProduct]: disabled,
        })}
      >
        {articleNumber}
      </Typography>
    </Box>
  );
};
