import React, { CSSProperties, FunctionComponent } from "react";
import { formatNumber } from "../../../../../utils/number.helper";
import { usePomAnnouncementContext } from "../../../../pages/announcement/pom-announcement.provider";

interface IPomAnnouncementTablePriceColProps {
  id: number;
  style?: CSSProperties;
}

export const PomAnnouncementTablePriceCol: FunctionComponent<IPomAnnouncementTablePriceColProps> = (props) => {
  const { id, style } = props;
  const { changedProducts, products } = usePomAnnouncementContext();

  let price = changedProducts?.find((value) => value.id === id)?.totalPrice;

  if (!price) {
    price = products?.find((value) => value.id === id)?.totalPrice;
  }
  return <span style={{ whiteSpace: "nowrap", ...style }}>{`${formatNumber(price, 2) ?? 0} €`}</span>;
};
