import React, { createContext, useContext, useState } from "react";
import { GroupType } from "../../shared/domain/group/group-type";

interface ILoginAsContextType {
  loginAsTypes: GroupType[] | undefined;
  setLoginAsTypes(type: GroupType[] | undefined): void;
  setLoggedAs(loggedAs: boolean): void;
  loggedAs: boolean;
}

const LoginAsContext = createContext<ILoginAsContextType>({} as ILoginAsContextType);

export const LoginAsProvider = (props: any) => {
  const value = useLoginAsProvider();
  return <LoginAsContext.Provider value={value}>{props.children}</LoginAsContext.Provider>;
};

export const useLoginAs = () => {
  return useContext(LoginAsContext);
};

const useLoginAsProvider = (): ILoginAsContextType => {
  const [loginAsTypes, setLoginAsTypes] = useState<GroupType[]>();
  const [loggedAs, setLoggedAs] = useState<boolean>(false);

  return {
    loginAsTypes,
    setLoginAsTypes,
    setLoggedAs,
    loggedAs,
  };
};
