import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { Colors } from "../../../../../style/Colors";
import { useTypographyPillStyles } from "../../../../../style/typography-pill.style";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { getFormattedCustomerAddress } from "../../../../utils/order-item.util";
import { getStatusXName } from "../status-filter/status-x-util";

const useStyles = makeStyles((theme) => ({
  container: {
    color: Colors.white,
  },
  title: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
  },
  idContainer: {
    paddingRight: 15,
  },
  addressText: {
    lineHeight: "24px",
  },
  idPadding: {
    paddingRight: 8,
  },
  containerHeight: { height: "100%" },
}));

interface IOrderXCardTitleProps {
  order: ICfmOrderX;
  hasStatus: boolean;
  endAdornment?: ReactNode;
}

export const OrderXCardTitle: React.VFC<IOrderXCardTitleProps> = (props) => {
  const classes = useStyles();
  const typographyPillClasses = useTypographyPillStyles();
  const { t } = useTranslation();
  const { order, hasStatus, endAdornment } = props;
  const { internalUser } = useAuthContext();

  return (
    <Grid container className={classes.container} direction="column" alignItems="center">
      <Grid item xs className={classes.grow} container spacing={0}>
        {hasStatus && (
          <Grid item className={classes.idPadding}>
            <Box display={"flex"} alignItems={"center"} className={classes.containerHeight}>
              <Typography variant="body2" className={typographyPillClasses.whitePill}>
                {getStatusXName(
                  order.status,
                  t,
                  internalUser?.group?.type,
                  order.plannedDeliveryDate !== null,
                  order.truckloadId !== null,
                )}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs sm="auto" className={classes.idContainer}>
          <Typography
            variant="body1"
            component="h2"
            className={classNames(classes.title, { [classes.idPadding]: hasStatus })}
          >
            {order.id}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" className={classes.addressText}>
            {getFormattedCustomerAddress(order.customerAddress, t)}
          </Typography>
        </Grid>
      </Grid>
      {endAdornment && (
        <Grid item>
          <div>{endAdornment}</div>
        </Grid>
      )}
    </Grid>
  );
};
