import { Grid } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "../../../../../../shared/components/loading-spinner.component";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { RespondOutgoingWarehouseProductDialog } from "./dialog/respond-outgoing-warehouse-product-dialog.component";
import { ICfmWarehouseProduct } from "../../../../../domain/products/cfm-warehouse-product";
import { useGetWarehouseItems } from "../../../../../repositories/queries/product/query/get-warehouse-items.query";
import { SelectedFilesProvider } from "../../actions/providers/selected-files.provider";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";
import { RespondIncomingWarehouseProductDialog } from "./dialog/respond-incoming-warehouse-product-dialog.component";
import { RespondDestinationReceivedRecyclerWarehouseProductDialog } from "../destination-received-recycler-warehouse/destination-received-recycler-warehouse-product-dialog.component";
import {
  CfmTransferOrderActionType,
  ICfmTransferOrderAvailableAction,
} from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { ListFooterComponent } from "../../../../../../shared/components/list/list-footer.component";
import { WarehouseProductRow } from "../warehouse/warehouse-product-row.component";
import { AddressFilter } from "../../../../../../shared/components/address/address-filter.component";
import { AddressType } from "../../../../../../shared/models/address/address.model";
import { CfmOrderXUiStatus } from "../../../../../domain/order-x/cfm-order-x.status";

export const SorterWarehouseView: VFC = () => {
  const { t } = useTranslation();
  const [selectedWarehouse, setSelectedWarehouse] = useState<IAddressLean | undefined>();
  const [selectedWarehouseProduct, setSelectedWarehouseProduct] = useState<
    { product: ICfmWarehouseProduct; action: ICfmTransferOrderAvailableAction } | undefined
  >();
  const [isOutgoingOpen, setIsOutgoingOpen] = useState(false);
  const [isIncomingOpen, setIsIncomingOpen] = useState(false);
  const [isRecyclingOpen, setIsRecyclingIncomingOpen] = useState(false);

  const handleWarehouseChanged = (warehouse: IAddressLean | null) => {
    setSelectedWarehouse(warehouse ?? undefined);
  };

  const { isLoading: areProductsLoading, data: products } = useGetWarehouseItems(selectedWarehouse?.id, Number.isFinite(selectedWarehouse?.id));

  const hasProducts = useMemo(() => {
    return products && products.length > 0;
  }, [products]);

  const onRespondClicked = (warehouseProduct: ICfmWarehouseProduct, action: ICfmTransferOrderAvailableAction) => {
    setSelectedWarehouseProduct({ product: warehouseProduct, action: action });

    switch (action.type) {
      case CfmTransferOrderActionType.Sorted:
        setIsIncomingOpen(true);
        break;
      case CfmTransferOrderActionType.TruckloadCreated:
        setIsOutgoingOpen(true);
        break;
      case CfmTransferOrderActionType.Recycled:
        setIsRecyclingIncomingOpen(true);
        break;
    }
  };

  const onSubmittedWarehouseProduct = () => {
    closeSubmitDialog();
  };

  const closeSubmitDialog = () => {
    setSelectedWarehouseProduct(undefined);
    setIsOutgoingOpen(false);
    setIsIncomingOpen(false);
    setIsRecyclingIncomingOpen(false);
  };

  return (
    <SelectedFilesProvider>
      {selectedWarehouse && selectedWarehouseProduct && isOutgoingOpen && (
        <RespondOutgoingWarehouseProductDialog
          warehouseProduct={selectedWarehouseProduct.product}
          availableAction={selectedWarehouseProduct.action}
          startAddress={selectedWarehouse}
          isOpen={isOutgoingOpen}
          onSubmitted={onSubmittedWarehouseProduct}
          onCancel={closeSubmitDialog}
        />
      )}
      {selectedWarehouse && selectedWarehouseProduct && isIncomingOpen && (
        <RespondIncomingWarehouseProductDialog
          warehouseProduct={selectedWarehouseProduct.product}
          availableAction={selectedWarehouseProduct.action}
          startAddress={selectedWarehouse}
          isOpen={isIncomingOpen}
          onSubmitted={onSubmittedWarehouseProduct}
          onCancel={closeSubmitDialog}
        />
      )}
      {selectedWarehouse && selectedWarehouseProduct && isRecyclingOpen && (
        <RespondDestinationReceivedRecyclerWarehouseProductDialog
          warehouseProduct={selectedWarehouseProduct.product}
          availableAction={selectedWarehouseProduct.action}
          startAddress={selectedWarehouse}
          isOpen={isRecyclingOpen}
          onSubmitted={onSubmittedWarehouseProduct}
          onCancel={closeSubmitDialog}
        />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12} sm={4}>
          <AddressFilter handleAddressChanged={handleWarehouseChanged} addressTypes={[AddressType.SorterLocation]} />
        </Grid>
        {areProductsLoading && (
          <Grid item>
            <LoadingSpinner isLoading={areProductsLoading} />
          </Grid>
        )}
        {!selectedWarehouse && (
          <Grid item>
            <EmptyWarehouse text={t("orders.recyclerIncomingWarehouse.selectWarehouse")} />
          </Grid>
        )}
        {selectedWarehouse && !hasProducts && (
          <Grid item>
            <EmptyWarehouse text={t("orders.recyclerIncomingWarehouse.noProducts")} />
          </Grid>
        )}

        {selectedWarehouse && (
          <Grid item container direction="column" key={selectedWarehouse.id}>
            {(products ?? []).map((p) => {
              return (
                <Grid item key={`${selectedWarehouse?.id}-${p.product.id}`}>
                  <WarehouseProductRow
                    warehouseProduct={p}
                    selectedWarehouse={selectedWarehouse}
                    onRespondClicked={onRespondClicked}
                    shouldShowNotifiedWeight={true}
                    status={CfmOrderXUiStatus.SorterWarehouse}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
      <ListFooterComponent />
    </SelectedFilesProvider>
  );
};
