import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ICfmProductRoutingAssignment } from "../../../collect-from-market/domain/routing-assignment/cfm-product-routing-assignment";
import { useDeleteProductRoutingAssignmentQuery } from "../../../collect-from-market/repositories/queries/routing-assignment/delete-routing-assignments.query";
import {
  getFormattedPostal,
  getRedirectToEditRoutingUrl,
} from "../../../components/assignment-routing/assignment-products.util";
import { QuestionDialog } from "../../../components/Dialogs/question-dialog";
import { AppDialog } from "../../../shared/components/dialog/app-dialog.component";
import { Colors, Shades } from "../../../style/Colors";
import { ProductRoutingAssignmentSummary } from "./product-routing-assignment-summary.component";
import { ProductRoutingDialogActionButtons } from "./product-routing-dialog-action-buttons.component";

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    border: "2px solid",
    borderColor: Shades.gray40,
    borderRadius: "0px 5px 5px 5px",
    padding: "15px 20px 15px 20px",
  },
  boxHeader: {
    backgroundColor: Shades.gray50,
    width: "fit-content",
    borderRadius: "5px 5px 0px 0px",
    padding: "7px 15px 3px 15px",
  },
  boxHeadingText: {
    color: Colors.white,
  },
}));

interface IProductRoutingDialogProps {
  productName: string;
  productId: number;
  assignments: ICfmProductRoutingAssignment[];
  open: boolean;
  onClose: VoidFunction;
  onEdit: VoidFunction;
}

export const ProductRoutingDialog: VFC<IProductRoutingDialogProps> = (props) => {
  const { open, onClose, onEdit, productId, productName, assignments } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [assignmentToDeleteId, setAssignmentToDeleteId] = useState<number | undefined>();

  const { isLoading: isDeleteProductRoutingAssignmentLoading, mutateAsync: deleteProductRoutingAssignment } =
    useDeleteProductRoutingAssignmentQuery();

  const onDeleteProductRoutingAssignment = async (id: number) => {
    await deleteProductRoutingAssignment({ id });
    onClose();
  };

  const onEditProductRoutingAssignment = (assignmentId: number) => {
    history.push(getRedirectToEditRoutingUrl(productId, assignmentId));
  };

  return (
    <>
      {assignmentToDeleteId && (
        <QuestionDialog
          title={t("configuration.assignments.dialog.title")}
          message={t("configuration.assignments.dialog.content")}
          acceptText={t("dialog.multiselect_order_delete_button_action.text")}
          onAccept={async () => {
            const id = assignmentToDeleteId;
            setAssignmentToDeleteId(undefined);
            onDeleteProductRoutingAssignment(id);
          }}
          onClose={() => setAssignmentToDeleteId(undefined)}
          isOpen={assignmentToDeleteId !== undefined}
          displayStyle={"warning"}
        />
      )}
      <AppDialog
        open={open}
        onCancelClick={onClose}
        onAcceptClick={onEdit}
        title={t("configuration.currentRouting")}
        subtitle={productName}
        acceptTextOverride={t("configuration.newRouting")}
        maxWidth="sm"
        isLoading={isDeleteProductRoutingAssignmentLoading}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.boxHeader}>
                <Typography variant="body1" className={classes.boxHeadingText}>
                  {t("configuration.postalOrRegion")}
                </Typography>
              </Grid>
              <Grid item className={classes.box}>
                <Typography variant="body1">
                  {assignments.map((assignment) => getFormattedPostal(assignment.postal)).join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {assignments.map((assignment) => {
            return (
              <Grid item key={assignment.id}>
                <Grid container direction="column">
                  <Grid item className={classes.boxHeader}>
                    <Typography variant="body1" className={classes.boxHeadingText}>
                      {getFormattedPostal(assignment.postal)}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.box}>
                    <Grid container direction="row">
                      <Grid item xs={11}>
                        <ProductRoutingAssignmentSummary assignment={assignment} />
                      </Grid>
                      <Grid item xs={1}>
                        <ProductRoutingDialogActionButtons
                          onDelete={() => setAssignmentToDeleteId(assignment.id)}
                          onEdit={() => onEditProductRoutingAssignment(assignment.id)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </AppDialog>
    </>
  );
};
