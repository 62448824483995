import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { useState, VFC } from "react";
import { SubmitHandler } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { ForwardButton, MailIconButton } from "../../../../../../components/Primitives";
import { useScreenSize } from "../../../../../../hooks/use-screen-size";
import { ICreateFreshdeskTicketRequest } from "../../../../../../shared/domain/requests/create-freshdesk-ticket.request";
import { useCreateFreshdeskTicketQuery } from "../../../../../../shared/repositories/queries/freshdesk/create-freshdesk-ticket.query";
import { formatDateShort } from "../../../../../../shared/util/date.util";
import { Colors, Shades } from "../../../../../../style/Colors";
import { useTypographyPillStyles } from "../../../../../../style/typography-pill.style";
import { ICfmTransferOrder } from "../../../../../domain/transfer-order/cfm-transfer-order";
import { useOrderXCardStyle } from "../../card/order-x-card.styles";
import { FreshdeskForm, IOrderXFreshdeskFormInputs } from "../../order-freshdesk-form/freshdesk-form.component";
import { useGetTransferOrderActions } from "../../../../../repositories/queries/transfer-order/queries/get-transfer-order-actions.query";
import {
  CfmTransferOrderActionType,
  ICfmTransferOrderAvailableAction,
} from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    backgroundColor: Shades.gray10,
  },
  container: {
    color: Colors.white,
  },
  label: {
    paddingRight: 8,
  },
  content: {
    padding: "24px 24px 0px 24px",
  },
}));

interface IDestinationArrivedRecyclerWarehouseRowProps {
  onRespondClicked: (transferOrder: ICfmTransferOrder, action: ICfmTransferOrderAvailableAction) => void;
  transferOrder: ICfmTransferOrder;
}

export const DestinationArrivedRecyclerWarehouseRow: VFC<IDestinationArrivedRecyclerWarehouseRowProps> = (props) => {
  const { onRespondClicked, transferOrder } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const orderClasses = useOrderXCardStyle();
  const typographyPillClasses = useTypographyPillStyles();
  const [isOrderFreshdeskFormOpen, setIsOrderFreshdeskFormOpen] = useState(false);
  const { mutateAsync: createFreshdeskTicket } = useCreateFreshdeskTicketQuery();
  const { data: availableActions, isLoading: isAvailableActionsLoading } = useGetTransferOrderActions(transferOrder.id);
  const { isMobile } = useScreenSize();

  const getSubjectText = () => {
    return t("orders.destinationArrived.freshdeskSupport", {
      transferOrderId: transferOrder.id,
    });
  };

  const onFreshdeskSupportSubmit: SubmitHandler<IOrderXFreshdeskFormInputs> = async (inputs): Promise<boolean> => {
    const requestData: ICreateFreshdeskTicketRequest = {
      subject: getSubjectText(),
      description: `${inputs.text} \n\n ${t("order_view.contact", { mail: inputs.contactMail })}`,
    };

    await createFreshdeskTicket({ createData: requestData });
    return true;
  };

  const onCancelFreshdeskForm = () => {
    setIsOrderFreshdeskFormOpen(false);
  };

  return (
    <>
      <FreshdeskForm
        isOpen={isOrderFreshdeskFormOpen}
        subject={getSubjectText()}
        onCancel={onCancelFreshdeskForm}
        onSubmit={onFreshdeskSupportSubmit}
        disableSuccessNotification
      />
      <Card elevation={0} className={classes.card}>
        <CardHeader
          title={
            <Grid container className={classes.container} spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="body2" className={typographyPillClasses.whitePill}>
                  {transferOrder.id}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" component="h2">
                  {transferOrder.product.articleNumber} {transferOrder.product.name}
                </Typography>
              </Grid>
            </Grid>
          }
          className={orderClasses.cardHeader}
        />
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Grid item container xs={12} direction="row">
                <Typography>
                  <Trans i18nKey="orders.destinationArrived.deliveryInfo">
                    {{
                      date: transferOrder.plannedDeliveryDate ? formatDateShort(transferOrder.plannedDeliveryDate) : "",
                    }}
                  </Trans>
                </Typography>
              </Grid>
              <Grid item container xs={12} direction="row">
                <Typography>
                  <Trans i18nKey="orders.destinationArrived.weightInfo">
                    {{ weight: transferOrder.weight?.toLocaleString() }}
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item container xs={12} direction="row" spacing={2} justifyContent="flex-end">
                <Grid item className={classNames({ "pr-2": isMobile })}>
                  <MailIconButton variant="outlined" onClick={() => setIsOrderFreshdeskFormOpen(true)}>
                    <Typography>{t("general.contact_grs")}</Typography>
                  </MailIconButton>
                </Grid>
                {isAvailableActionsLoading && (
                  <Grid item className="mx-2">
                    <CircularProgress size={30} />
                  </Grid>
                )}

                {!isAvailableActionsLoading &&
                  availableActions &&
                  availableActions.map((action) => {
                    if (action.type === CfmTransferOrderActionType.TruckloadConfirmed) {
                      return (
                        <Grid item>
                          <ForwardButton
                            variant="outlined"
                            onClick={() => onRespondClicked(transferOrder, action)}
                            className={classNames({ "pr-2": isMobile })}
                          >
                            <Typography variant="body1">{t("orders.destinationArrived.respond")}</Typography>
                          </ForwardButton>
                        </Grid>
                      );
                    }

                    return <></>;
                  })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
