import { Grid, TablePagination, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { replaceUriParameter } from "../../../../api/endpoints";
import { ClearFilterButton } from "../../../../components/Buttons/clear-filter-button";
import { LoginAsDialog } from "../../auth/login-as-dialog.component";
import {
  AddButton,
  ApproveIconButton,
  ArrowRightIconButton,
  CloseIconButton,
  EditIconButton,
  LoginAsIconButton,
} from "../../../../components/Primitives/Buttons";
import SearchField from "../../../../components/SearchField";
import CustomTable from "../../../../components/Tables/custom-table";
import { useUserTableContext } from "../../../../providers/Users/user-table.provider";
import { useBaseDataGridStyles } from "../../../../style/baseDataGrid.style";
import { AvailableSharedRoutes } from "../../../../utils/constants";
import { UserConverter } from "../../../domain/converter/user.converter";
import { getCfmRoleName, getFullName, getGroupName, getPomRoleName, isAdmin, IUser } from "../../../domain/user/user";
import { UsersActiveFilter } from "./filter/users-active-filter";

export const UsersTable: FC = () => {
  const { t } = useTranslation();
  const classes = useBaseDataGridStyles();
  const [loginAsModal, setLoginAsModal] = useState<IUser>();
  const history = useHistory();
  const {
    getErpNumber,
    getGroupType,
    entryCount,
    toggleUserStatus,
    users,
    isLoading,
    setTablePagination,
    setFilters,
    filters,
    tablePagination,
    isFilterDirty,
    resetFilter,
  } = useUserTableContext();

  const actions = [
    (rowData: any) => {
      const user = rowData as IUser;
      return {
        icon: () => (user.active ? <CloseIconButton /> : <ApproveIconButton />),
        tooltip: user.active ? t("basedata.users.table.action.deactivate") : t("basedata.users.table.action.activate"),
        onClick: async () => {
          await toggleUserStatus(user);
        },
      };
    },
    (rowData: any) => {
      const user = rowData as IUser;
      return {
        icon: () => <EditIconButton />,
        tooltip: t("basedata.users.table.action.edit"),
        onClick: () => {
          history.push(replaceUriParameter(AvailableSharedRoutes.UserEdit, "id", user.id));
        },
      };
    },
    (rowData: any) => {
      const user = rowData as IUser;
      return {
        icon: () => <ArrowRightIconButton />,
        tooltip: t("basedata.users.table.action.detail"),
        onClick: () => {
          history.push(replaceUriParameter(AvailableSharedRoutes.UserDetail, "id", user.id));
        },
      };
    },
    (rowData: any) => {
      const user = rowData as IUser;
      const loginAsDisabled = isAdmin(user) || !user.active;
      return {
        disabled: loginAsDisabled,
        icon: () => <LoginAsIconButton disabled={loginAsDisabled} />,
        tooltip: loginAsDisabled
          ? t("basedata.users.table.action.loginNotAvailable")
          : t("basedata.users.table.action.login"),
        onClick: () => setLoginAsModal(user),
      };
    },
  ];

  return (
    <>
      <LoginAsDialog
        user={loginAsModal ? UserConverter.toLoginAsUserDomain(loginAsModal) : loginAsModal}
        open={!!loginAsModal}
        onCancel={() => setLoginAsModal(undefined)}
      />
      <CustomTable
        options={{
          pageSize: entryCount,
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          rowStyle: (data: IUser) => {
            return {
              color: data.active === null ? "red" : undefined,
            };
          },
        }}
        columns={[
          {
            title: t("basedata.users.table.userId"),
            field: "id",
          },
          {
            title: t("basedata.users.table.type"),
            render: (rowData) => getGroupType(rowData as IUser),
            field: "groupType",
            customSort: (data1: any, data2: any, type: "row" | "group") => {
              return getGroupType(data1 as IUser).localeCompare(getGroupType(data2 as IUser));
            },
          },
          {
            title: t("basedata.users.table.role"),
            render: (rowData) => (
              <span>
                {getCfmRoleName(rowData as IUser, t)} <br />
                {getPomRoleName(rowData as IUser, t)}
              </span>
            ),
            field: "role",
          },
          {
            title: t("basedata.users.table.erp_number"),
            render: (rowData) => getErpNumber(rowData as IUser),
            field: "erpNumber",
          },
          {
            title: t("basedata.users.table.groupName"),
            render: (rowData) => getGroupName(rowData as IUser),
            field: "groupName",
            customSort: (data1: any, data2: any, type: "row" | "group") => {
              return (getGroupName(data1 as IUser) ?? "").localeCompare(getGroupName(data2 as IUser) ?? "");
            },
          },
          {
            title: t("basedata.users.table.name"),
            render: (rowData) => getFullName(rowData as IUser),
            field: "name",
            customSort: (data1: any, data2: any, type: "row" | "group") => {
              return getFullName(data1 as IUser).localeCompare(getFullName(data2 as IUser));
            },
          },
          {
            title: t("basedata.users.table.email"),
            field: "email",
          },
        ]}
        actions={actions}
        data={users}
        isLoading={isLoading}
        components={{
          Toolbar: () => (
            <Grid direction="row" container className={classes.search}>
              <Grid className={classes.searchGrid} container spacing={4}>
                <Grid item>
                  <UsersActiveFilter />
                </Grid>
                {isFilterDirty && (
                  <Grid item>
                    <ClearFilterButton onClick={resetFilter} />
                  </Grid>
                )}
                <Grid className={classes.searchGrid} item>
                  <SearchField
                    tooltip={t("basedata.users.table.search")}
                    submitSearch={true}
                    inputTimeout={0}
                    initialValue={filters?.searchText}
                    onSearchSubmit={(value: string) => {
                      setTablePagination({ ...tablePagination, page: 0 });
                      setFilters({
                        ...filters,
                        searchText: value,
                      });
                    }}
                    placeholder={t("basedata.users.table.search")}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.addGrid} item>
                <AddButton
                  onClick={() => {
                    history.push(AvailableSharedRoutes.UserCreate);
                  }}
                >
                  <Typography variant="body1">{t("response_field_config.response.table.add_user")}</Typography>
                </AddButton>
              </Grid>
            </Grid>
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[50, 100]}
              rowsPerPage={tablePagination.pageSize}
              count={tablePagination.numberOfRecords}
              page={tablePagination.page}
              onPageChange={(event: any, newPage: number) => {
                setTablePagination({
                  ...tablePagination,
                  page: newPage,
                });
                props.onPageChange(event, newPage);
              }}
              onRowsPerPageChange={(event: any) => {
                setTablePagination({
                  ...tablePagination,
                  pageSize: event.target.value,
                  page: 0,
                });
                props.onRowsPerPageChange(event);
              }}
            />
          ),
        }}
      />
    </>
  );
};
