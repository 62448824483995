import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import "dayjs/locale/de";
import IsBetween from "dayjs/plugin/isBetween";
import { StatusCodes } from "http-status-codes";
import moment from "moment";
import "moment/locale/de";
import { useSnackbar } from "notistack";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { getErrorCode, getErrorStatusCode } from "./api/api-response";
import "./assets/base.scss";
import { UnsavedDataProvider } from "./providers/App/unsaved-data-context";
import Routes from "./Routes";
import { ScrolledToBottomProvider } from "./shared/domain/scroll/scrolled-to-bottom-context";
import { SidebarProvider } from "./shared/domain/sidebar/sidebar-context";
import { instanceOfAxiosError } from "./utils/rest";
import ScrollToTop from "./shared/util/router/scroll-to-top";
import { AppInsightsErrorBoundary, withAITracking } from "@microsoft/applicationinsights-react-js";
import { getReactPlugin } from "./app-insights";

// https://day.js.org/docs/en/plugin/is-between
dayjs.extend(IsBetween);

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      // global error handling for all useQuery hooks
      onError: (error) => {
        if (instanceOfAxiosError(error) && error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          const errorMsg = getErrorCode(error);
          const errorCode = getErrorStatusCode(error);
          enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
        }
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        if (instanceOfAxiosError(error) && error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          const errorMsg = getErrorCode(error);
          const errorCode = getErrorStatusCode(error);
          enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
        }
      },
    }),
  });

  dayjs.locale("de");
  moment.locale("de"); // components like mui-rff keyboardDatePicker use moment.js

  return (
    <HelmetProvider>
      <AppInsightsErrorBoundary appInsights={getReactPlugin()} onError={() => <h1>{t("general.error_occurred")}</h1>}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SidebarProvider>
            <UnsavedDataProvider>
              <Router
                getUserConfirmation={() => {
                  /* Empty callback to block the default browser prompt */
                }}
              >
                <ScrollToTop>
                  <ScrolledToBottomProvider>
                    <QueryClientProvider client={queryClient}>
                      <Routes />
                    </QueryClientProvider>
                  </ScrolledToBottomProvider>
                </ScrollToTop>
              </Router>
            </UnsavedDataProvider>
          </SidebarProvider>
        </MuiPickersUtilsProvider>
      </AppInsightsErrorBoundary>
    </HelmetProvider>
  );
}

export default withAITracking(getReactPlugin(), React.memo(App));
