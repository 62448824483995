import "core-js/stable";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import { i18n } from "./config/locales/i18n";
import { CustomSnackbarProvider } from "./utils/custom-snackbar-provider";
import { telemetryService } from "./app-insights";

// https://day.js.org/docs/en/plugin/min-max
dayjs.extend(minMax);

telemetryService().initialize();

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <CustomSnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <App />
      </CustomSnackbarProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
