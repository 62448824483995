import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { getTranslationKeyOfPeriod } from "../../../../put-on-market/repositories/models/announcements/pom-announcement.period";
import { ICfmGroupContractField, IGroupContract, IPomGroupContractField } from "../../../domain/group/group-contract";
import { GroupContractType } from "../../../models/group/group-contract.model";
import { formatDateShort } from "../../../util/date.util";
import { DetailValueDisplay } from "../../value-display/detail-value-display.component";

interface IGroupContractFormDetailProps {
  baseContractValues: IGroupContract;
  cfmContractValues?: ICfmGroupContractField;
  pomContractValues?: IPomGroupContractField;
}

export const GroupContractFormDetailComponent: VFC<IGroupContractFormDetailProps> = (props) => {
  const { t } = useTranslation();
  const { baseContractValues, cfmContractValues, pomContractValues } = props;
  return (
    <Grid container>
      <Grid item md={6} sm={6} xs={12}>
        <DetailValueDisplay
          label={t("basedata.groups.contractsTable.type")}
          value={baseContractValues.type ? t(`basedata.groups.contractsTable.${baseContractValues.type}Type`) : "-"}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <DetailValueDisplay
          label={t("basedata.groups.contractsTable.contract_number")}
          value={baseContractValues.contractNumber ?? "-"}
        />
      </Grid>

      <Grid item md={6} sm={6} xs={12}>
        <DetailValueDisplay
          label={t("basedata.groups.groupContracts.startDate")}
          value={baseContractValues.startDate ? formatDateShort(baseContractValues.startDate) : "-"}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <DetailValueDisplay
          label={t("basedata.groups.groupContracts.endDate")}
          value={baseContractValues.endDate ? formatDateShort(baseContractValues.endDate) : "-"}
        />
      </Grid>
      {baseContractValues.type === GroupContractType.Cfm && cfmContractValues && (
        <Grid item md={6} sm={6} xs={12}>
          <DetailValueDisplay
            label={t("basedata.groups.groupContracts.productCategory")}
            value={cfmContractValues.productCategory.name}
          />
        </Grid>
      )}

      {baseContractValues.type === GroupContractType.Pom && pomContractValues && (
        <>
          <Grid item md={6} sm={6} xs={12}>
            <DetailValueDisplay
              label={t("basedata.groups.groupContracts.takeBackSystem")}
              value={pomContractValues.takeBackSystem.name}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <DetailValueDisplay
              label={t("basedata.groups.edit.defaultPriceGroup")}
              value={
                pomContractValues.defaultPriceGroup ? t("basedata.general.yes.text") : t("basedata.general.no.text")
              }
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <DetailValueDisplay
              label={t("basedata.groups.pom_announcement_period")}
              value={
                pomContractValues.pomAnnouncementPeriod
                  ? t(getTranslationKeyOfPeriod(pomContractValues.pomAnnouncementPeriod))
                  : "-"
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
