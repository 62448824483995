import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { CfmOrderXApiStatus } from "../../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { ICfmProduct } from "../../../../collect-from-market/domain/products/cfm-product";
import { useGetResponseAssignmentByIdsXQuery } from "../../../../collect-from-market/repositories/queries/response-assignments/get-response-assignments-x.query";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { Colors } from "../../../../style/Colors";
import { IResponseAssignmentRowValue } from "../../../pages/product-response/product-response-assignment.provider";
import { ArticleNumberBox } from "../../product/article-number-box.component";
import { mapToResponseFieldData } from "../../response-field/response-field.util";
import { ProductResponseAssignmentRow } from "../product-response-assignment-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
}));

interface IProductResponseDialogProps {
  open: boolean;
  onCancel: VoidFunction;
  onEdit: VoidFunction;
  product: ICfmProduct;
}

export const ProductResponseDialog: VFC<IProductResponseDialogProps> = (props) => {
  const { open, onCancel, onEdit, product } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStatus, setActiveStatus] = useState<CfmOrderXApiStatus | undefined>();
  const responseAssignmentIds = (product.responseAssignments ?? []).map((responseAssignment) => responseAssignment.id);

  const { isLoading, data: responseAssignments } = useGetResponseAssignmentByIdsXQuery(
    responseAssignmentIds,
    responseAssignmentIds.length > 0,
  );

  if (!responseAssignmentIds) {
    onCancel();
  }

  const getRows = (): IResponseAssignmentRowValue[] => {
    const mapped = (responseAssignments ?? []).map((assignment) => {
      return {
        status: assignment.status,
        responseFieldData: assignment.responseFields
          ? assignment.responseFields.map(mapToResponseFieldData)
          : undefined,
        serviceDates: assignment.serviceDates,
      };
    });

    return mapped.sort((a1, a2) => a1.status - a2.status);
  };

  const getSubtitleElement = () => {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <ArticleNumberBox articleNumber={product.articleNumber} />
        </Grid>
        <Grid item>
          <Box display={"flex"} alignItems={"center"} className={classes.container}>
            <Typography variant="body2">{product.name}</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <AppDialog
      open={open}
      onCancelClick={onCancel}
      onAcceptClick={onEdit}
      title={t("configuration.currentResponseAssignment")}
      subtitle={getSubtitleElement()}
      isLoading={isLoading}
      acceptTextOverride={t("general.edit.text")}
      maxWidth="md"
      alignButtons="space-between"
    >
      <Grid container direction="column" spacing={2}>
        {getRows().map((values, index) => {
          return (
            <Grid item key={index}>
              <ProductResponseAssignmentRow
                initValues={values}
                index={index}
                backgroundColor={Colors.white}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
              />
            </Grid>
          );
        })}
      </Grid>
    </AppDialog>
  );
};
