import { ICfmCreateResponseValueX } from "../../../../domain/order-x/actions/cfm-create-response-value-x";
import { ICfmCreateTransferOrderResponseValue } from "../../../../domain/transfer-order/create-transfer-order-response-value.ts";
import { ISelectedFilesList } from "../../../../pages/order-x/components/actions/providers/selected-files.provider";
import { ICfmCreateTransferOrderResponseValueModel } from "../../transfer-order/create-transfer-order-response-value.model";

export class CfmTransferOrderResponseFieldConverter {
  public static createToModel(domain: ICfmCreateTransferOrderResponseValue): ICfmCreateTransferOrderResponseValueModel {
    return {
      responseAssignmentResponseFieldId: domain.responseAssignmentResponseFieldId,
      transferOrderId: domain.transferOrderId,
      text: domain.text,
      number: domain.number,
      floatingPoint: domain.floatingPoint,
      date: domain.date,
      files: domain.files,
      warehouseProductId: domain.warehouseProductId,
    };
  }

  public static formToCreateDomain(
    domain: ICfmCreateResponseValueX,
    files?: ISelectedFilesList,
    warehouseProductId?: number,
  ): ICfmCreateTransferOrderResponseValue {
    return {
      text: domain.text,
      transferOrderId: domain.itemId,
      number: domain.number ? Number(domain.number) : null,
      files: files?.s3Paths ?? [],
      date: domain.date,
      floatingPoint: domain.floatingPoint ? Number(domain.floatingPoint) : null,
      responseAssignmentResponseFieldId: domain.responseAssignmentResponseFieldId,
      warehouseProductId: warehouseProductId ?? domain.warehouseProductId,
    };
  }
}
