import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { EditIconButton } from "../../../../components/Primitives";
import { getGroupTypeTranslationForLabel } from "../../../../utils/group.helper";
import { GroupType } from "../../../domain/group/group-type";

const useStyles = makeStyles((theme: Theme) => ({
  iconAlign: {
    textAlign: "right",
  },
}));

interface IGroupDetailTypeNameProps {
  type: GroupType;
  onEditClicked: VoidFunction;
}

export const GroupDetailTypeInfoName: VFC<IGroupDetailTypeNameProps> = (props) => {
  const { type, onEditClicked } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={10} md="auto">
        <Typography variant="h1">{getGroupTypeTranslationForLabel(type, t)}</Typography>
      </Grid>
      <Grid item xs={2} md="auto" className={classes.iconAlign}>
        <EditIconButton onClick={onEditClicked} />
      </Grid>
    </Grid>
  );
};
