import { SharedQueryKeys } from "../shared-query-keys";
import { useQuery } from "react-query";
import { IAddressResultModel } from "shared/models/address/address.model";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { IAddress } from "../../../domain/address/address";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { AddressType } from "../../../models/address/address.model";
import getInstance from "../../../../utils/rest";
import { IPaginationInputMeta } from "../../../domain/pagination/pagination-meta";

const getAllAddressesOfType = async (
  types: AddressType[],
  paginationInputMeta?: IPaginationInputMeta,
  active?: boolean,
): Promise<IAddress[]> => {
  const response = await getInstance().get<IAddressResultModel>(RestEndpoints.Addresses, {
    params: {
      types,
      active,
      ...paginationInputMeta,
    },
  });

  return response.data.addresses.map(AddressConverter.toDomain);
};

export const useGetAllAddressesOfType = (
  types: AddressType[],
  paginationInputMeta?: IPaginationInputMeta,
  enabled?: boolean,
  active?: boolean,
) => {
  return useQuery(
    [SharedQueryKeys.GetAddressesOfType, types],
    () => getAllAddressesOfType(types, paginationInputMeta, active),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled ?? false,
    },
  );
};
