import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { ICfmResponseFieldsCountResponseModel } from "../../models/response-field/cfm-response-field.model";
import { CfmQueryKeys } from "../cfm-query-keys";
import { ICfmResponseField } from "./../../../domain/routing-assignment/cfm-response-field";
import { CfmResponseFieldConverter } from "./../../models/converter/cfm-response-field.converter";

const getResponseFields = async (): Promise<ICfmResponseField[]> => {
  const { data } = await getInstance().get<ICfmResponseFieldsCountResponseModel>(RestEndpoints.ResponseFields);
  return data.data.map(CfmResponseFieldConverter.toDomain);
};

export const useGetResponseFieldsQuery = () => {
  return useQuery([CfmQueryKeys.GetResponseFields], () => getResponseFields(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
