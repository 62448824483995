import { Grid, makeStyles, Theme } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useMemo, FC } from "react";
import { useTranslation } from "react-i18next";
import { OrderCheckbox } from "../../../../../components/checkbox/order-checkbox.component";
import SearchField from "../../../../../components/SearchField";
import useAsyncEffect from "../../../../../hooks/use-async-effect";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { useScrolledToBottomContext } from "../../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { isCfmLogistics } from "../../../../../shared/domain/user/user";
import { CfmOrderXApiStatus, isLogisticBulkStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { OrderBulkActionBar } from "../bulk-actions/order-bulk-action-bar.component";
import { OrderBulkActionButtonGroup } from "../bulk-actions/order-bulk-action-button-group.component";
import { useOrderBulkActionContext } from "../bulk-actions/providers/order-bulk-action.provider";
import { OrderXCard } from "../card/order-x-card.component";
import { OrderOverviewEmptyList } from "../order-overview-empty-list.component";

const useStyles = makeStyles((theme: Theme) => ({
  searchFieldContainer: {
    paddingBottom: 24,
  },
  alignSpinner: {
    textAlign: "center",
    marginBottom: 20,
  },
  checkbox: {
    paddingTop: 8,
    width: 40,
    marginBottom: 24,
    paddingLeft: 0,
    marginLeft: -11,
  },
  orderCardContainer: {
    flexGrow: 1,
    width: `calc(100% - 40px)`,
    paddingLeft: 4,
  },
  circularProgress: {
    marginLeft: 24,
  },
}));

export const OrderListDefaultView: FC = (props) => {
  const {
    orders,
    fetchNextPage,
    setFilter,
    curFilter,
    isLoading: areOrdersLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  } = useOrderXOverviewContext();
  const { hasScrolledToBottom } = useScrolledToBottomContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const { internalUser } = useAuthContext();
  const { selectedOrderIds, setSelectedOrderIds, handleSelectOrder, isSelectAll } = useOrderBulkActionContext();

  const isLoading = useMemo(
    () => areOrdersLoading || isFetching || isFetchingNextPage,
    [areOrdersLoading, isFetching, isFetchingNextPage],
  );

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  // Update selected order ids on finished refetch for bulk actions
  useEffect(() => {
    if (!isLoading && isSelectAll) {
      const orderIds = _.uniq(orders.map((order) => order.id));
      setSelectedOrderIds(orderIds);
    }
  }, [isLoading, isSelectAll, orders]);

  const isBulkPossible = useMemo(() => {
    const status = curFilter.statusFilter;
    return isCfmLogistics(internalUser) && isLogisticBulkStatus(status as CfmOrderXApiStatus) && orders.length > 0;
  }, [curFilter, orders]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.searchFieldContainer}>
        <SearchField
          initialValue={curFilter.filter?.query}
          autoFocus={false}
          size="large"
          onSearchSubmit={(value) => {
            setFilter({
              statusFilter: curFilter.statusFilter,
              filter: {
                query: value,
              },
            });
          }}
          placeholder={t("order_filter_search_placeholder")}
          tooltip={t("order_filter_search_tooltip")}
        />
      </Grid>
      {isBulkPossible && (
        <OrderBulkActionBar>
          <OrderBulkActionButtonGroup />
        </OrderBulkActionBar>
      )}
      <Grid item xs={12}>
        {orders.map((order, index) => {
          return (
            <Grid container direction="row" key={index}>
              {isBulkPossible && (
                <Grid item className={classes.checkbox}>
                  <OrderCheckbox
                    onChange={(checked: boolean) => handleSelectOrder(checked, order.id)}
                    checked={selectedOrderIds.includes(order.id)}
                    size="medium"
                  />
                </Grid>
              )}
              <Grid item xs="auto" className={classes.orderCardContainer}>
                <OrderXCard key={order.id} order={order} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {orders.length === 0 && !isLoading && (
        <Grid item xs={12}>
          <OrderOverviewEmptyList />
        </Grid>
      )}
    </Grid>
  );
};
