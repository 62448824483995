import { ICfmProductCategoryModel } from "../../../collect-from-market/repositories/models/product/cfm-product-category.model";
import { PomAnnouncementPeriod } from "../../../put-on-market/repositories/models/announcements/pom-announcement.period";
import { IPomProductTakeBackSystemModel } from "../../../put-on-market/repositories/models/products/pom-product-take-back-system.model";
import { DateWithoutTime } from "../../util/date.util";
import { IPaginationMetaModel } from "../util/pagination-meta.model";

export enum GroupContractType {
  Cfm = "cfm",
  Pom = "pom",
}

export interface IPomGroupContractFieldModel {
  id: number;
  takeBackSystem: IPomProductTakeBackSystemModel;
  pomAnnouncementPeriod?: PomAnnouncementPeriod;
  defaultPriceGroup: boolean;
}

export interface ICfmGroupContractFieldModel {
  id: number;
  productCategory: ICfmProductCategoryModel;
}

export interface IBaseGroupContractModel {
  id: number;
  groupId: number;
  contractNumber: string;
  startDate: DateWithoutTime;
  endDate: DateWithoutTime | null;
  type: GroupContractType;
  active: boolean;
}

export interface ICfmGroupContractModel extends IBaseGroupContractModel {
  contractField: ICfmGroupContractFieldModel;
}

export interface IPomGroupContractModel extends IBaseGroupContractModel {
  contractField: IPomGroupContractFieldModel;
}

export interface IGroupContractListModel {
  cfmContracts: ICfmGroupContractModel[];
  pomContracts: IPomGroupContractModel[];
  meta: IPaginationMetaModel;
}
