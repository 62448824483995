import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { ProductConfigIcon } from "../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../style/Colors";
import { CreateProduct } from "../../components/product/create-product.component";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 20,
    marginBottom: 30,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
  },
  columnContainer: {
    height: "100%",
  },
  textMargin: {
    marginTop: 3,
  },
}));

export const CreateProductPage: VFC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item className={classes.heading}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Grid container direction="column" justifyContent="center" className={classes.columnContainer}>
              <Grid item>
                <ProductConfigIcon viewBox="0 0 25 25" className={classes.iconStyle} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h4" className={classes.textMargin}>
              {t("configuration.configArticle")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CreateProduct />
      </Grid>
    </Grid>
  );
};
