import { IconButton, InputAdornment, makeStyles, OutlinedInput, Tooltip } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FONT_FAMILY, SEARCH_INPUT_LARGE, SEARCH_INPUT_NORMAL } from "../../config/theme/overrides/constant";
import { Colors } from "../../style/Colors";
import { ENTER_KEY } from "../../utils/constants";
import { SearchIcon } from "../Primitives";

let timerId: any;

type SearchSize = "normal" | "large";

const useStyles = makeStyles({
  // to not use default behaviour here, override only for this component
  root: {
    "&.Mui-focused": {
      border: `none`,
    },
  },
  notchedOutline: {
    border: `none`,
    "&.Mui-focused": {
      border: `none`,
    },
  },
  focused: {
    border: `none`,
    "&.Mui-focused": {
      border: `2px solid ${Colors.greenLight}`,
    },
  },
  normal: {
    border: `2px solid ${Colors.gray} !important`,
  },
  searchField: {
    boxShadow: "none",
    border: `none`,
    fontFamily: FONT_FAMILY,
  },
});

interface ISearchFieldProps {
  initialValue?: string;
  onSearchSubmit?: (value: string) => void;
  inputTimeout?: number;
  placeholder?: string;
  tooltip?: string;
  submitSearch?: boolean;
  size?: SearchSize;
  autoFocus?: boolean;
}

const SearchField = React.forwardRef<HTMLInputElement, ISearchFieldProps>((props, ref) => {
  const { t } = useTranslation();

  const {
    initialValue = "",
    onSearchSubmit = (value: string) => {},
    inputTimeout = 500,
    placeholder,
    tooltip = "",
    submitSearch = false,
    size = "normal",
    autoFocus = true,
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const [focused, setFocused] = useState(false);
  const onChangeDebounced = (value: string) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => onSearchSubmit(value), inputTimeout);
  };

  const handleChange = (value: string, submit: boolean) => {
    setValue(value);
    if (submit) {
      onChangeDebounced(value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <OutlinedInput
      ref={ref}
      name="searchInput"
      classes={{ notchedOutline: classes.notchedOutline, root: classes.root }}
      className={classNames(classes.searchField, {
        [classes.normal]: !focused,
        [classes.focused]: focused,
      })}
      style={{ height: size === "normal" ? SEARCH_INPUT_NORMAL : SEARCH_INPUT_LARGE }}
      fullWidth
      placeholder={placeholder ? placeholder : t("general.search")}
      value={value}
      onChange={(e) => {
        handleChange(e.target.value, !submitSearch);
      }}
      onKeyDown={(e) => {
        if (submitSearch && e.key === ENTER_KEY) {
          handleChange(e.currentTarget.value, submitSearch);
        }
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      id="outlined-adornment-search"
      startAdornment={
        <Tooltip disableHoverListener={!tooltip} title={tooltip ?? t("general.search")}>
          <InputAdornment position="start">
            <SearchIcon svgColor="black" />
          </InputAdornment>
        </Tooltip>
      }
      autoFocus={autoFocus}
      endAdornment={
        value !== "" ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                handleChange("", true);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ) : (
          <div style={{ width: 48 }} />
        )
      }
    />
  );
});

export default SearchField;
