import { Badge, Grid, Tooltip, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StatusItem } from "../../../../../components/Primitives/status-item.component";
import { CfmOrderXApiStatus, CfmOrderXStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { CfmOrderXStatusConverter } from "../../../../repositories/models/converter/order-x/cfm-order-x-status.converter";
import { useGetOrderXStatusCountQuery } from "../../../../repositories/queries/order-x/queries/get-order-x-status-counts.query";
import { ICfmOrderXFilter } from "../../../../domain/order-x/cfm-order-x-filter";

const HIDE_NEW_ORDERS_BADGE: Array<CfmOrderXStatus | "all"> = ["all", CfmOrderXApiStatus.LogisticTruckLoadAnnounced];
const HIDE_TOTAL_ORDER_BADGE: Array<CfmOrderXStatus | "all"> = [
  "all",
  CfmOrderXApiStatus.Confirmed,
  CfmOrderXApiStatus.LogisticTruckLoadAnnounced,
];
const BADGE_MIN_SIZE = 22;
const BADGE_SIZE_INDICATOR = 10;
const BADGE_INCREASE_PADDING = 4;

interface IOrderXFilterButtonProps {
  children: ReactNode;
  isSelected: boolean;
  filter?: ICfmOrderXFilter;
  onClick: () => void;
  orderStatus: CfmOrderXStatus | "all";
}

export const OrderXFilterButton: React.FC<IOrderXFilterButtonProps> = ({
  children,
  isSelected,
  onClick,
  orderStatus,
  filter,
}: IOrderXFilterButtonProps) => {
  const orderStatusModel = CfmOrderXStatusConverter.statusWithAllToModel(filter?.status ?? orderStatus);
  const { t } = useTranslation();
  const { data, isLoading } = useGetOrderXStatusCountQuery(orderStatusModel, true, filter?.hasPlannedDeliveryDate);

  const buttonAction = () => {
    onClick();
  };

  const newOrderCount = data?.newCount ?? 0;
  const totalOrderCount = data?.totalCount ?? 0;

  const renderNewOrderBadge = () => {
    return !HIDE_NEW_ORDERS_BADGE.includes(orderStatus) && data && data.newCount > 0;
  };

  const renderTotalOrderBadge = () => {
    return !HIDE_TOTAL_ORDER_BADGE.includes(orderStatus) && data && data.totalCount > 0;
  };

  return (
    <StatusItem isActive={isSelected} onClick={buttonAction}>
      <Grid container spacing={1} alignItems="center" wrap={"nowrap"}>
        <Grid item id="status-label">
          <Typography component="span">{children}</Typography>
        </Grid>

        {renderNewOrderBadge() && (
          <Grid
            item
            style={{
              marginLeft:
                newOrderCount / BADGE_SIZE_INDICATOR < BADGE_SIZE_INDICATOR
                  ? BADGE_INCREASE_PADDING
                  : BADGE_INCREASE_PADDING * 2,
              width: `calc(${BADGE_MIN_SIZE}px + ${
                newOrderCount / BADGE_SIZE_INDICATOR < 1
                  ? 0
                  : newOrderCount / BADGE_SIZE_INDICATOR < BADGE_SIZE_INDICATOR
                  ? BADGE_INCREASE_PADDING
                  : BADGE_INCREASE_PADDING * 2
              }px)`,
            }}
          >
            <Tooltip title={t("orders.filter.areNew")}>
              <Badge
                overlap="rectangular"
                className="bg-orange"
                color="secondary"
                badgeContent={isLoading ? 0 : newOrderCount}
              />
            </Tooltip>
          </Grid>
        )}
        {renderTotalOrderBadge() && (
          <Grid
            item
            style={{
              marginLeft:
                totalOrderCount / BADGE_SIZE_INDICATOR < BADGE_SIZE_INDICATOR
                  ? BADGE_INCREASE_PADDING
                  : BADGE_INCREASE_PADDING * 2,
              width: `calc(${BADGE_MIN_SIZE}px + ${
                totalOrderCount / BADGE_SIZE_INDICATOR < 1
                  ? 0
                  : totalOrderCount / BADGE_SIZE_INDICATOR < BADGE_SIZE_INDICATOR
                  ? BADGE_INCREASE_PADDING
                  : BADGE_INCREASE_PADDING * 2
              }px)`,
            }}
          >
            <Tooltip title={t("orders.filter.areTotal")}>
              <Badge
                overlap="rectangular"
                className="bg-greenDark"
                color="secondary"
                badgeContent={isLoading ? 0 : totalOrderCount}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </StatusItem>
  );
};
