import { VFC } from "react";
import { useScreenSize } from "../../../../hooks/use-screen-size";
import { useGetAddressQuery } from "../../../../shared/repositories/queries/address/get-address.query";
import { TruckloadInfoBoxMobileView } from "./truckload-info-box-mobile-view.component";
import { TruckloadInfoBoxView } from "./truckload-info-box-view.component";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";

interface ITruckloadInfoBoxProps {
  targetAddressId: number | undefined;
  plannedDeliveryDate: Date | undefined;
  truckload: ICfmTruckloadWithOrders;
  onEditClick?: VoidFunction;
}

export const TruckloadInfoBox: VFC<ITruckloadInfoBoxProps> = (props) => {
  const { truckload, onEditClick, targetAddressId, plannedDeliveryDate } = props;
  const { data: targetAddress } = useGetAddressQuery(targetAddressId);
  const { isMobile } = useScreenSize();

  return (
    <>
      {!isMobile && (
        <TruckloadInfoBoxView
          truckload={truckload}
          targetAddress={targetAddress}
          plannedDeliveryDate={plannedDeliveryDate}
          onEditClick={onEditClick}
        />
      )}
      {isMobile && (
        <TruckloadInfoBoxMobileView
          truckload={truckload}
          targetAddress={targetAddress}
          plannedDeliveryDate={plannedDeliveryDate}
        />
      )}
    </>
  );
};
