import { CfmResponseFieldType } from "../../../collect-from-market/domain/response-values-x/cfm-response-field-type";
import { ICfmResponseFieldAssignment } from "../../../collect-from-market/domain/assignment/cfm-response-field-assignment";
import { CfmServiceDate } from "../../../collect-from-market/domain/order-x/actions/cfm-service-date";
import { TranslateFunc } from "../../../utils/translation.utils";
import { IResponseFieldData } from "../../pages/product-response/product-response-assignment.provider";
import { IConfigurationOption } from "../product/product-configuration.component";

export enum ResponseFieldStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export interface IResponseFieldFilter {
  config: IResponseFieldConfigFilter;
  searchText: string | undefined;
}

export interface IResponseFieldConfigFilter {
  type: CfmResponseFieldType[] | undefined;
  status: ResponseFieldStatus | undefined;
}

export const EMPTY_RESPONSE_FIELD_CONFIG_FILTER: IResponseFieldConfigFilter = {
  type: undefined,
  status: undefined,
};

export interface IResponseFieldRowHeader {
  id: string;
  internalNumber: string;
  type: string;
  name: string;
  description: string;
  unit: string;
  active: string;
}

export const getResponseFieldRowHeader = (t: TranslateFunc) => {
  return {
    id: t("configuration.responseField.rowHeader.id"),
    internalNumber: t("configuration.responseField.rowHeader.internalNumber"),
    type: t("configuration.responseField.rowHeader.type"),
    name: t("configuration.responseField.rowHeader.name"),
    description: t("configuration.responseField.rowHeader.description"),
    unit: t("configuration.responseField.rowHeader.unit"),
    active: t("configuration.responseField.rowHeader.active"),
  };
};

export const getResponseFieldFilterInitialValue = () => {
  return { searchText: undefined, config: EMPTY_RESPONSE_FIELD_CONFIG_FILTER };
};

export const getResponseFieldFieldTypeFilterOptions = (
  types: CfmResponseFieldType[] | undefined,
  t: TranslateFunc,
): IConfigurationOption[] => {
  const options: IConfigurationOption[] = [];

  if (!types) return [];

  types.forEach((type) => {
    options.push({
      value: type.toString(),
      label: t(`configuration.responseField.type.${type}`),
    });
  });

  return options;
};

export const getResponseFieldFieldTypeLabel = (type: CfmResponseFieldType, t: TranslateFunc) => {
  if (!type) return "";
  return t(`configuration.responseField.type.${type}`);
};

export const getResponseFieldStatusFilterOptions = (
  status: ResponseFieldStatus | ResponseFieldStatus[] | undefined,
  t: TranslateFunc,
): IConfigurationOption[] => {
  const options: IConfigurationOption[] = [];

  if (!status) return [];

  if (!Array.isArray(status)) {
    return [{ value: status.toString(), label: t(`configuration.responseField.status.${status}`) }];
  }

  status.forEach((stat) => {
    options.push({
      value: stat.toString(),
      label: t(`configuration.responseField.status.${stat}`),
    });
  });

  return options;
};

export const getResponseFieldServiceDates = (): CfmServiceDate[] => {
  return Object.values(CfmServiceDate);
};

export const mapToResponseFieldData = (field: ICfmResponseFieldAssignment): IResponseFieldData => {
  return {
    id: field.id,
    name: field.name,
    type: field.type,
    serviceDate: field.serviceDate,
    warehouseArticleId: field.warehouseArticle?.id ?? null,
    billingType: field.billingType,
    internalNumber: field.internalNumber,
    unit: field.unit,
  };
};
