import { Grid } from "@material-ui/core";
import { VFC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { isAdmin } from "../../../domain/user/user";
import { FormSwitch } from "../../form/fields/form-switch.field";
import { FormField } from "../../form/form-field.component";

export interface IGroupBaseToggleFormInputs {
  active: boolean;
  grsManagedEar?: boolean;
}

interface IGroupFormRadioButtonsProps {}

export const GroupFormRadioButtons: VFC<IGroupFormRadioButtonsProps> = (props) => {
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const { control } = useFormContext<IGroupBaseToggleFormInputs>();

  return (
    <Grid container spacing={4} className="mt-2">
      <FormField md={12}>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <FormSwitch
              name={"active"}
              control={control}
              label={t("basedata.users.edit.active")}
              disabled={!isAdmin(internalUser)}
            />
          </Grid>
          <Grid item>
            <FormSwitch
              name={"grsManagedEar"}
              control={control}
              label={t("basedata.groups.edit.grsManagedEar")}
              disabled={!isAdmin(internalUser)}
            />
          </Grid>
        </Grid>
      </FormField>
    </Grid>
  );
};
