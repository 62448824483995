import { ISystemCloningStatusModel } from "shared/repositories/models/system-cloning-status/system-cloning-status.model";
import { ISystemCloningStatus } from "../system-cloning-status/system-cloning-status";

export class SystemCloningStatusConverter {
  public static toModel(domain: ISystemCloningStatus): ISystemCloningStatusModel {
    return {
      id: domain.id,
      startedAt: domain.startedAt,
      finishedAt: domain.finishedAt,
      startedBy: domain.startedBy,
      status: domain.status,
    };
  }

  public static toDomain(model: ISystemCloningStatusModel): ISystemCloningStatus {
    return {
      id: model.id,
      startedAt: model.startedAt,
      finishedAt: model.finishedAt,
      startedBy: model.startedBy,
      status: model.status,
    };
  }
}
