import React, { FC, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { WarehouseProductRow } from "../warehouse/warehouse-product-row.component";
import { ICfmWarehouseProduct } from "../../../../../domain/products/cfm-warehouse-product";
import { ICfmTransferOrderAvailableAction } from "../../../../../domain/transfer-order/cfm-transfer-order-available-action";
import { EmptyWarehouse } from "../warehouse/empty-warehouse.component";
import { useTranslation } from "react-i18next";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { CfmOrderXStatus } from "../../../../../domain/order-x/cfm-order-x.status";

interface IWarehouseProductsListComponentProps {
  warehouseProducts: ICfmWarehouseProduct[] | undefined;
  selectedWarehouse: IAddressLean;
  orderStatus: CfmOrderXStatus;
  onRespondClicked(warehouseProduct: ICfmWarehouseProduct, action: ICfmTransferOrderAvailableAction): void;
}

export const WarehouseProductsListComponent: FC<IWarehouseProductsListComponentProps> = (props) => {
  const { warehouseProducts, onRespondClicked, selectedWarehouse, orderStatus } = props;
  const { t } = useTranslation();
  const hasProducts = useMemo(() => {
    return warehouseProducts && warehouseProducts.length > 0;
  }, [warehouseProducts]);
  return (
    <>
      {!hasProducts && (
        <Grid item>
          <EmptyWarehouse text={t("orders.destinationReceivedWarehouse.noProducts")} />
        </Grid>
      )}
      {hasProducts && (
        <Grid item>
          <Grid container direction="column">
            {(warehouseProducts ?? []).map((p) => {
              return (
                <Grid item key={p.product.id}>
                  <WarehouseProductRow
                    warehouseProduct={p}
                    onRespondClicked={onRespondClicked}
                    selectedWarehouse={selectedWarehouse}
                    status={orderStatus}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};
