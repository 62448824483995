import { IAddressModel } from "shared/models/address/address.model";
import { IAddressFormInputs } from "../../components/address/address-form/address-form.component";
import { ICreateAddressModel } from "../../models/address/create-address.model";
import { IUpdateAddressModel } from "../../models/address/update-address.model";
import { PhoneNumberUtil } from "../../util/phone-number.util";
import { IAddress } from "../address/address";
import { IAddressLean } from "../address/address-lean";
import { GroupConverter } from "./group.converter";
import { UserConverter } from "./user.converter";

export class AddressConverter {
  public static toDomain(address: IAddressModel): IAddress {
    return {
      active: address.active ?? null,
      address: address.address ?? null,
      addressText: address.addressText ?? null,
      buildingNumber: address.buildingNumber ?? null,
      city: address.city ?? null,
      contactPersonEmail: address.contactPersonEmail ?? null,
      contactPersonName: address.contactPersonName ?? null,
      contactPersonTelephone: address.contactPersonTelephone
        ? PhoneNumberUtil.transformPhoneNumber(address.contactPersonTelephone)
        : "",
      country: address.country ?? null,
      state: address.state ?? null,
      name2: address.name2 ?? null,
      externalId: address.externalId ?? null,
      formattedAddress: address.formattedAddress ?? null,
      group: address.group ? GroupConverter.toDomain(address.group) : address.group,
      groupId: address.groupId ?? null,
      id: address.id,
      lat: address.lat ?? null,
      long: address.long ?? null,
      name: address.name ?? null,
      postal: address.postal ?? null,
      type: address.type,
      users: address.users ? UserConverter.toDomainList(address.users) : address.users,
      openingHours: address.openingHours,
    };
  }

  public static toLeanAddress(address: IAddressModel): IAddressLean {
    if (!address.groupId) {
      throw Error("Could not convert address to lean address, no group id present");
    }

    if (!address.id) {
      throw Error("Could not convert address to lean address, no address id present");
    }
    return {
      address: address.address ?? "",
      addressText: address.addressText ?? "",
      buildingNumber: address.buildingNumber ?? "",
      city: address.city ?? "",
      contactPersonEmail: address.contactPersonEmail ?? "",
      contactPersonName: address.contactPersonName ?? "",
      contactPersonTelephone: address.contactPersonTelephone
        ? PhoneNumberUtil.transformPhoneNumber(address.contactPersonTelephone)
        : "",
      country: address.country ?? "",
      state: address.state ?? "",
      name2: address.name2 ?? "",
      formattedAddress: address.formattedAddress ?? undefined,
      groupId: address.groupId,
      id: address.id,
      lat: address.lat ?? undefined,
      long: address.long ?? undefined,
      name: address.name ?? "",
      postal: address.postal ?? "",
      type: address.type,
      openingHours: address.openingHours ?? "",
      active: address.active ?? undefined,
    };
  }

  public static toModel(address: IAddress): IAddressModel {
    return {
      id: address.id ?? undefined,
      type: address.type,
      name: address.name,
      name2: address.name2,
      address: address.address,
      formattedAddress: address.formattedAddress,
      addressText: address.addressText,
      buildingNumber: address.buildingNumber,
      city: address.city,
      postal: address.postal,
      state: address.state,
      country: address.country,
      lat: address.lat,
      long: address.long,
      contactPersonEmail: address.contactPersonEmail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonTelephone,
      externalId: address.externalId,
      active: address.active,
      users: address.users ? address.users.map(UserConverter.toModel) : address.users,
      groupId: address.group?.id,
      group: address.group ? GroupConverter.toModel(address.group) : address.group,
      openingHours: address.openingHours,
    };
  }

  public static domainToLeanAddressDomain(address: IAddress): IAddressLean {
    if (!address.groupId) {
      throw Error("Could not convert address to lean address, no group id present");
    }

    if (!address.id) {
      throw Error("Could not convert address to lean address, no address id present");
    }
    return {
      id: address.id ?? undefined,
      groupId: address.groupId,
      type: address.type,
      name: address.name ?? "",
      name2: address.name2 ?? "",
      address: address.address ?? "",
      formattedAddress: address.formattedAddress ?? "",
      addressText: address.addressText ?? "",
      buildingNumber: address.buildingNumber ?? "",
      city: address.city ?? "",
      postal: address.postal ?? "",
      country: address.country ?? "",
      state: address.state ?? "",
      lat: address.lat ?? undefined,
      long: address.long ?? undefined,
      contactPersonEmail: address.contactPersonEmail ?? "",
      contactPersonName: address.contactPersonName ?? "",
      contactPersonTelephone: address.contactPersonTelephone ?? "",
      openingHours: address.openingHours ?? "",
      active: address.active ?? undefined,
    };
  }

  static toDomainList(addresses?: any[]): IAddress[] {
    if (!addresses) return [];
    return addresses.map(this.toDomain);
  }

  public static toFormCreateRequest(address: IAddressFormInputs, groupId: number): ICreateAddressModel {
    return {
      groupId: groupId,
      type: address.type,
      name: address.name,
      address: address.address,
      name2: address.name2,
      buildingNumber: address.buildingNumber,
      postal: address.postal,
      city: address.city,
      country: address.country.code,
      state: address.state,
      lat: address.lat,
      long: address.lng,
      contactPersonEmail: address.contactPersonMail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonPhone,
      addressText: address.addressText,
      active: address.active ? new Date() : null,
      openingHours: address.openingHours,
    };
  }

  public static toFormEditRequest(address: IAddressFormInputs, id: number): IUpdateAddressModel {
    return {
      id: id,
      type: address.type,
      name: address.name,
      address: address.address,
      name2: address.name2,
      buildingNumber: address.buildingNumber,
      postal: address.postal,
      city: address.city,
      country: address.country.code,
      state: address.state,
      lat: address.lat,
      long: address.lng,
      contactPersonEmail: address.contactPersonMail,
      contactPersonName: address.contactPersonName,
      contactPersonTelephone: address.contactPersonPhone,
      addressText: address.addressText,
      active: address.active ? new Date() : null,
      openingHours: address.openingHours,
    };
  }
}
