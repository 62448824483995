import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FunctionComponent, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProductIcon, ProductMobileIcon, SingleOrderIcon } from "../../../../../components/Primitives/Icons";
import { CfmProductArticleType } from "../../../../../configurator/components/product/product.utils";
import { useScreenSize } from "../../../../../hooks/use-screen-size";
import { Shades } from "../../../../../style/Colors";
import { ARTICLE_SEPARATOR } from "../../../../../utils/constants";
import { ICfmProduct } from "../../../../domain/products/cfm-product";
import { useGetPackagesForProduct } from "../../../../repositories/queries/package/get-packages-for-product.query";
import { useOrderNewContext, WizardStep } from "../../order-new.provider";
import { IOrderNewWizardFormInputs, WizardMode } from "../order-new-wizard.component";
import { PackageSummaryRow } from "./package-summary-row.component";
import { SummaryBox } from "./summary-box.component";
import { SummaryLoadingSpinner } from "./summary-loading-spinner.component";
import { OrderNewPackageDialog } from "../dialog/order-new-package-dialog.component";
import { useGetProductQuery } from "../../../../repositories/queries/product/query/get-product.query";

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    minHeight: 70,
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: "5px 5px 5px 5px",
  },
  entryContainer: {
    minHeight: 70,
  },
  iconSize: {
    height: 40,
    width: 29,
  },
  iconSizeProduct: {
    height: 40,
    width: 54,
  },
  iconSizeProductMobile: {
    height: 30,
    width: 39,
  },
  iconSizeAddon: {
    height: 32,
    width: 40,
  },
  labelColor: {
    color: "#3b3e66",
  },
  bold: {
    fontWeight: "bold",
  },
  bottomSpacing: {
    height: 18,
  },
}));

interface IProductSummaryProps {
  productOrderIndex: number;
}

export const ProductSummary: FunctionComponent<IProductSummaryProps> = (props) => {
  const { productOrderIndex } = props;
  const classes = useStyles();
  const { setValue, getValues, watch, control } = useFormContext<IOrderNewWizardFormInputs>();
  const productId = watch("productId");
  const {
    remove: removeProductOrder,
    update: updateProductOrder,
    fields,
  } = useFieldArray({
    name: "productOrders",
    control,
  });
  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);

  const { setActiveStep, resetProduct, isPackageInfoAvailable, wizardMode } = useOrderNewContext();
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();

  const { isLoading: isProductLoading, data: product } = useGetProductQuery(productId);
  const { isLoading: arePackagesLoading, data: packagesForProduct } = useGetPackagesForProduct(productId, undefined);
  const isLoading = isProductLoading || arePackagesLoading;

  const productOrders = watch(`productOrders`);
  const productOrder = productOrders?.[productOrderIndex];

  if (!productOrder) {
    return <></>;
  }

  const getProductIcon = () => {
    return isMobile ? (
      <ProductMobileIcon classes={{ root: classes.iconSizeProductMobile }} />
    ) : (
      <ProductIcon classes={{ root: classes.iconSizeProduct }} />
    );
  };

  const getSingleOrderIcon = () => {
    return <SingleOrderIcon classes={{ root: classes.iconSizeAddon }} />;
  };

  const getProductLabel = (product: ICfmProduct) => {
    return (
      <Typography variant="body1" className={classes.labelColor}>
        {product.articleNumber} {ARTICLE_SEPARATOR} {product.name}
      </Typography>
    );
  };

  const handleDelete = (): void => {
    if (getValues("productOrders").length === 1) {
      resetProduct(setValue);
      setActiveStep(WizardStep.Product);
    } else {
      removeProductOrder(productOrderIndex);
    }
  };

  const handlePackageDelete = (): void => {
    updateProductOrder(productOrderIndex, {
      ...fields[productOrderIndex],
      requestedPickupDate: undefined,
      productNetWeight: undefined,
      productGrossWeight: undefined,
      package: undefined,
    });
    setActiveStep(WizardStep.Package);
  };

  const getPackage = (id: number) => {
    return (packagesForProduct ?? []).find((productPackage) => productPackage.id === id);
  };

  return (
    <Grid container direction="column" className={classes.gridContainer}>
      {isPackageModalOpen && productOrder.package && (
        <OrderNewPackageDialog
          open={isPackageModalOpen}
          productOrderIndex={productOrderIndex}
          productPackage={getPackage(productOrder.package.packageId)}
          onCancel={() => {
            setIsPackageModalOpen(false);
          }}
          onAccept={() => {
            setIsPackageModalOpen(false);
          }}
        />
      )}
      <Grid item>
        <Grid container direction="row" className={classes.entryContainer}>
          {isLoading && <SummaryLoadingSpinner />}
          {!isLoading && (
            <SummaryBox
              iconComponent={
                product?.articleType === CfmProductArticleType.Product ? getProductIcon() : getSingleOrderIcon()
              }
              labelComponent={product ? getProductLabel(product) : <>{t("orders.new.wizard.noData")}</>}
              onDeleteClick={handleDelete}
            >
              <Box flexDirection={"column"}>
                {productOrder.orderNumber && (
                  <div>{`${t("orders.new.wizard.submit.orderNumber")}: ${productOrder.orderNumber}`}</div>
                )}
                {productOrder.orderComment && (
                  <div>{`${t("orders.new.wizard.submit.orderComment")}: ${productOrder.orderComment}`}</div>
                )}
              </Box>
            </SummaryBox>
          )}
        </Grid>
      </Grid>
      {isPackageInfoAvailable && !isLoading && productOrder.package && (
        <PackageSummaryRow
          key={productOrder.package.packageId}
          productPackage={getPackage(productOrder.package.packageId)}
          formPackage={productOrder.package}
          onDeleteClick={wizardMode === WizardMode.Edit || productOrders.length === 1 ? handlePackageDelete : undefined}
          onEditClick={() => {
            setIsPackageModalOpen(true);
            if (productOrders.some((p) => !!p.package)) return;
          }}
        />
      )}
    </Grid>
  );
};
