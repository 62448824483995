import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints, replaceUriParameter } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { IAddress } from "../../../domain/address/address";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { IAddressModel } from "../../../models/address/address.model";
import { IUpdateAddressModel } from "../../../models/address/update-address.model";
import { SharedQueryKeys } from "../shared-query-keys";

const updateAddress = async (id: number, address: IUpdateAddressModel): Promise<IAddress> => {
  const url = replaceUriParameter(RestEndpoints.EditAddress, "id", id);
  const { data } = await getInstance().patch<IAddressModel>(url, address);
  return AddressConverter.toDomain(data);
};

export const useUpdateAddressQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  return useMutation(
    SharedQueryKeys.UpdateAddress,
    ({ id, updateData }: { id: number; updateData: IUpdateAddressModel }) => updateAddress(id, updateData),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
