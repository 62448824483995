import { useEffect, VFC } from "react";
import { useExportTransferOrders } from "../../../../../repositories/queries/transfer-order/queries/export-transfer-orders.query";
import { ICfmTransferOrderFilterModel } from "../../../../../repositories/models/transfer-order/cfm-transfer-order-filter.model";
import { downloadFile } from "../../../../../../shared/util/download.util";
import { formatDateYearMonthDay } from "../../../../../../shared/util/date.util";
import { ExcelButton } from "../../../../../../shared/components/button/excel-button.component";

interface IExportTransferOrderButtonProps {
  transferOrderFilter: ICfmTransferOrderFilterModel;
}

export const ExportTransferOrdersButton: VFC<IExportTransferOrderButtonProps> = (props) => {
  const { transferOrderFilter } = props;

  const {
    isLoading: isExportLoading,
    refetch: refetchExport,
    data: exportData,
    isRefetching: isExportRefetching,
    remove: removeExportData,
  } = useExportTransferOrders(transferOrderFilter, false);

  useEffect(() => {
    if (exportData) {
      const filename = `transfer-order-export-${formatDateYearMonthDay(new Date())}.xlsx`;
      downloadFile(exportData, filename);
    }
    return () => {
      removeExportData();
    };
  }, [exportData, removeExportData]);

  const handleExportOrdersClick = async () => {
    await refetchExport();
  };

  return <ExcelButton onClick={handleExportOrdersClick} disabled={isExportLoading || isExportRefetching} />;
};
