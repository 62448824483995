import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { getErrorCode, getErrorStatusCode } from "../../../../api/api-response";
import { RestEndpoints } from "../../../../api/endpoints";
import { getInstanceMultipartFormData } from "../../../../utils/rest";
import { IPostalsResultModel } from "../../models/routing-assignment/cfm-product-routing-assignment.model";
import { CfmQueryKeys } from "../cfm-query-keys";

const uploadPostalsForCreateAssignments = async (file: File): Promise<string[]> => {
  const form = new FormData();
  form.append("file", file);
  const result = await getInstanceMultipartFormData().post<IPostalsResultModel>(
    RestEndpoints.UploadPostalsForCreateAssignments,
    form,
  );
  return result.data.postals;
};

export const useUploadPostalsForCreateAssignments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation(
    CfmQueryKeys.UploadPostalsForCreateAssignments,
    ({ file }: { file: File }) => uploadPostalsForCreateAssignments(file),
    {
      onError: (e) => {
        const errorMsg = getErrorCode(e);
        const errorCode = getErrorStatusCode(e);
        enqueueSnackbar(t("general.error_occurred", { errorCode, errorMsg }), { variant: "error" });
      },
    },
  );
};
