import dayjs from "dayjs";
import { useState } from "react";
import config from "../config/config";
import { useGetLoggedInUserInfoQuery } from "../shared/repositories/queries/user/get-users-info.query";
import { IAuthConfig, ILoginResult, LoginOperationResult } from "../utils/auth";
import { useLoginQuery } from "../shared/repositories/queries/auth/mutation/login.query";

const { auth } = config;
const { expirationKey, tokenKey, expirationNumber } = auth as IAuthConfig;

export const EMAIL = "email";
export const PASSWORD = "password";

interface IAuthLogin {
  email: string;
  password: string;
}

const initialValue: IAuthLogin = {
  email: "",
  password: "",
};

export const useLogin = () => {
  const [authLogin, setAuthLoginLogin] = useState<IAuthLogin>(initialValue);
  const { refetch: refetchUserInfo } = useGetLoggedInUserInfoQuery(false);
  const { mutateAsync: login } = useLoginQuery();
  const handleLogin = async (): Promise<ILoginResult> => {
    return await handleAuthentication(authLogin.email, authLogin.password);
  };

  const setValue = (value: string, id: keyof IAuthLogin) => {
    const copyAuth = JSON.parse(JSON.stringify(authLogin));
    copyAuth[id] = value;
    setAuthLoginLogin(copyAuth);
  };

  const handleAuthentication = async (email: string, password: string): Promise<ILoginResult> => {
    try {
      const authResponse = await login({ email, password });

      if (authResponse.status === 401) {
        return { result: LoginOperationResult.WrongLoginData };
      }

      if (authResponse.status === 200) {
        const token = authResponse.result.token ?? "";

        setSession(token);
        const userResponse = await refetchUserInfo();

        if (userResponse.data?.status === 401) {
          return { result: LoginOperationResult.WrongLoginData };
        }

        if (userResponse.data?.status === 200) {
          return {
            result: LoginOperationResult.Success,
            token,
            user: userResponse.data.user,
          };
        }
      }

      return { result: LoginOperationResult.UnexpectedError };
    } catch (e) {
      const { response = false } = e as any;

      if (response.status === 423) {
        return { result: LoginOperationResult.UserLocked };
      }

      if (response.status === 403) {
        return { result: LoginOperationResult.NoContracts };
      }

      if (response.status === 401) {
        return { result: LoginOperationResult.WrongLoginData };
      }

      if (response.status === 429) {
        return { result: LoginOperationResult.TooManyRequest };
      }

      return { result: LoginOperationResult.UnexpectedError };
    }
  };

  const setSession = (token = "") => {
    if (typeof Storage !== "undefined") {
      try {
        localStorage.setItem(expirationKey, dayjs().add(expirationNumber, "days").format("x"));
        localStorage.setItem(tokenKey, token);
      } catch (ex) {}
    } else {
      // No web storage Support.
    }
  };

  return [authLogin, setValue, handleLogin] as const;
};
