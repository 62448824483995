import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { SharedQueryKeys } from "../../shared-query-keys";

type QueryErrorHandler = ((error: unknown, variables: void, context: unknown) => void | Promise<unknown>) | undefined;
type QuerySuccessHandler = ((data: unknown, variables: void, context: unknown) => void | Promise<unknown>) | undefined;

const cloneSystem = async (): Promise<void> => {
  await getInstance().post(RestEndpoints.SystemClone);
};

export const useStartCloningSystemQuery = (handleError: QueryErrorHandler, handleSuccess: QuerySuccessHandler) => {
  return useMutation(SharedQueryKeys.CloneSystem, () => cloneSystem(), {
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
