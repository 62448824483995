import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateShort } from "../../../../../shared/util/date.util";
import { translateCountryByCode } from "../../../../../utils/address.util";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderDetailInfoBlock } from "../card/order-details-info-block.component";

export const OrderXDetailsDestination: React.VFC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const destinationAddress = useMemo(() => {
    return order.destinationAddress;
  }, [order.destinationAddress]);

  const addressText = useMemo(() => {
    const textData: string[] = [`(${destinationAddress?.id ?? ""}) ${destinationAddress?.name ?? ""}`];

    if (destinationAddress?.address && destinationAddress.buildingNumber) {
      textData.push(`${destinationAddress.address} ${destinationAddress.buildingNumber}`);
    }

    if (destinationAddress?.postal && destinationAddress.city) {
      textData.push(`${destinationAddress.postal} ${destinationAddress.city}`);
    }

    textData.push(destinationAddress?.country ? translateCountryByCode(destinationAddress.country, t) : "");
    return textData.filter((text) => text.length > 0).join(", ");
  }, [
    destinationAddress?.id,
    destinationAddress?.name,
    destinationAddress?.address,
    destinationAddress?.postal,
    destinationAddress?.city,
    destinationAddress?.buildingNumber,
    destinationAddress?.country,
    t,
  ]);

  const contactDataText = useMemo(() => {
    const data = [
      destinationAddress?.contactPersonName ?? "",
      destinationAddress?.contactPersonEmail ?? "",
      destinationAddress?.contactPersonTelephone ?? "",
    ];

    return data.filter((text) => text.length > 0).join(", ");
  }, [
    destinationAddress?.contactPersonName,
    destinationAddress?.contactPersonEmail,
    destinationAddress?.contactPersonTelephone,
  ]);

  return (
    <OrderDetailInfoBlock title={t("order.details.destination.title")}>
      <Grid container direction="column">
        {destinationAddress && (
          <Grid item>
            <Typography variant="body1">{addressText}</Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">{contactDataText}</Typography>
        </Grid>
        {destinationAddress?.openingHours && (
          <Grid item>
            <Typography variant="body1">
              {t("order.details.openingHours", { openingHours: destinationAddress?.openingHours })}
            </Typography>
          </Grid>
        )}
        {order.requestedDeliveryDate && (
          <Grid item>
            <Typography variant="body1">
              {t("order.details.requestedDeliveryDate", { date: formatDateShort(order.requestedDeliveryDate) })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </OrderDetailInfoBlock>
  );
};
