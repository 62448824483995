import { Action, Column, Options } from "@material-table/core";
import { Box, Button, TablePagination } from "@material-ui/core";
import React, { CSSProperties, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClearFilterButton } from "../../../../../components/Buttons/clear-filter-button";
import { AddIcon, EditIconButton } from "../../../../../components/Primitives";
import CustomTable from "../../../../../components/Tables/custom-table";
import { TranslateFunc } from "../../../../../utils/translation.utils";
import { Shades } from "../../../../../style/Colors";
import { IBattGBrand } from "../../../../models/batt-g/batt-g-brand";
import { BattGBrandFilter } from "./batt-g-brand-filter";
import { BattGBrandContext, useBattGBrandContext } from "./batt-g-brand.provider";
import { BattGBrandUtil } from "./batt-g-brand.util";
import { BattGBrandDialogProvider } from "./brand-dialog/batt-g-brand-dialog.provider";
import { BattGBrandDialog } from "./brand-dialog/batt-g-brand.dialog";

export const BattGBrandTableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading, brands, pageSize, page, setNewPageSize, itemCount, setPage, filters, setFilters } =
    useBattGBrandContext();
  const [brandDialogOpen, setBrandDialogOpen] = useState<boolean>(false);
  const selectedBrand = useRef<IBattGBrand | undefined>(undefined);
  const handleEditBrand = (newBrand: IBattGBrand): void => {
    selectedBrand.current = newBrand;
    setBrandDialogOpen(true);
  };

  const tableOptions: Options<any> = {
    actionsColumnIndex: -1,
    pageSize: pageSize,
    emptyRowsWhenPaging: false,
    rowStyle: (rowData: IBattGBrand) => ({
      color: "black",
      backgroundColor: rowData.endDate !== null ? Shades.gray20 : "",
    }),
    actionsCellStyle: {
      width: "1%",
      paddingRight: "15px",
    },
  };

  const tableActions: ((rowData: object) => Action<object>)[] = [
    (rowData: object) => {
      const brand = rowData as IBattGBrand;
      return {
        icon: () => <EditIconButton />,
        onClick: () => {
          handleEditBrand(brand);
        },
      };
    },
  ];

  return (
    <>
      {brandDialogOpen && (
        <BattGBrandDialogProvider brand={selectedBrand.current} key={selectedBrand.current?.id}>
          <BattGBrandContext.Consumer>
            {({ fetchBrands }) => (
              <BattGBrandDialog
                open={brandDialogOpen}
                onOk={() => {
                  selectedBrand.current = undefined;
                  setBrandDialogOpen(false);
                  fetchBrands();
                }}
                onCancel={() => {
                  selectedBrand.current = undefined;
                  setBrandDialogOpen(false);
                }}
              />
            )}
          </BattGBrandContext.Consumer>
        </BattGBrandDialogProvider>
      )}

      <CustomTable
        isLoading={isLoading}
        options={tableOptions}
        columns={getColumns(t)}
        actions={tableActions}
        data={brands}
        components={{
          Toolbar: () => (
            <Box display="flex" justifyContent="space-between" className="pb-3">
              <Box display="flex" gridGap={20}>
                <BattGBrandFilter />
                {filters.length > 0 && <ClearFilterButton onClick={() => setFilters([])} />}
              </Box>
              <Button
                endIcon={<AddIcon />}
                color="primary"
                variant="contained"
                onClick={() => {
                  setBrandDialogOpen(true);
                }}
              >
                {t("batt_g.brand.add")}
              </Button>
            </Box>
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[50, 100]}
              rowsPerPage={pageSize}
              count={itemCount}
              page={page}
              onPageChange={(event: any, newPage: number) => {
                setPage(newPage);
                props.onPageChange(event, newPage);
              }}
              onRowsPerPageChange={(event: any) => {
                setNewPageSize(event.target.value);
                props.onRowsPerPageChange(event);
              }}
            />
          ),
        }}
      />
    </>
  );
};
const getColumns = (t: TranslateFunc): Column<object>[] => {
  const headerStyle: CSSProperties = {
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "start",
  };

  const cellStyle: CSSProperties = {
    textAlign: "start",
  };

  return [
    {
      title: t("batt_g.brand.table.name"),
      field: "name",
      headerStyle: {
        ...headerStyle,
        paddingLeft: 26,
      },
      cellStyle: {
        ...cellStyle,
        paddingLeft: 26,
      },
    },
    {
      title: t("batt_g.brand.table.trademark"),
      field: "trademarkRegisterNumber",
      headerStyle,
      cellStyle,
    },
    {
      title: t("batt_g.brand.table.battery_type"),
      field: "grsBatteryType.name",
      headerStyle,
      cellStyle,
    },
    {
      title: t("batt_g.brand.table.status"),
      field: "status",
      render: (rowData) => {
        const brand = rowData as IBattGBrand;
        return t(BattGBrandUtil.getBrandStatusTranslation(brand.status, brand.endDate));
      },
      headerStyle,
      cellStyle,
    },
  ];
};
