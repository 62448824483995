import { useQuery } from "react-query";
import { RestEndpoints, replaceUriParameter } from "../../../api/endpoints";
import { ReactQueryConfig } from "../../../config/react-query-config";
import getInstance from "../../../utils/rest";
import { ManufacturerConverter } from "../../domain/converter/manufacturer/manufacturer.converter";
import { IPomManufacturerModel } from "../models/manufacturer/manufacturer.model";
import { PomQueryKeys } from "../pom-query-keys";
import { IPomManufacturer } from "../../domain/models/manufacturer/manufacturer";

const getManufacturer = async (id: number | undefined): Promise<IPomManufacturer | undefined> => {
  if (!id) return undefined;
  const url = replaceUriParameter(RestEndpoints.GetPomManufacturer, "id", id);
  const { data } = await getInstance().get<IPomManufacturerModel>(url);
  return ManufacturerConverter.toDomain(data);
};

export const useGetManufacturerQuery = (id: number | undefined) => {
  return useQuery([PomQueryKeys.GetManufacturer], () => getManufacturer(id), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: Number.isFinite(id),
  });
};
