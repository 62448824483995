import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrderXRepeatList } from "./components/order-x-repeat-list.component";
import { OrderXRepeatOverviewProvider } from "./order-x-repeat-overview.provider";

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: "bold",
  },
  header: {
    marginBottom: 20,
  },
  infoText: {
    marginBottom: 10,
  },
}));

export const OrderXRepeatOverview: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <OrderXRepeatOverviewProvider>
      <Grid container direction="column">
        <Grid item className={classes.header}>
          <Typography variant="h4">{t("orders.repeat.text")}</Typography>
        </Grid>
        <Grid item className={classes.infoText}>
          <Typography variant="h6" className={classes.boldText}>
            {t("orders.repeat.info")}
          </Typography>
        </Grid>
        <Grid item>
          <OrderXRepeatList />
        </Grid>
      </Grid>
    </OrderXRepeatOverviewProvider>
  );
};
