import { base64toBlobPDF } from "../../collect-from-market/pages/order-x/utils/create-blob";

export const downloadFile = (data: string | undefined, fileName: string) => {
  const blob = new Blob([data ?? ""]);
  download(blob, fileName);
};

export const downloadPdf = (pdfBase64string: string, fileName: string) => {
  const pdfBlob = base64toBlobPDF(pdfBase64string);
  download(pdfBlob, fileName);
};

const download = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
