import { Grid, LinearProgress, makeStyles, Theme, Typography } from "@material-ui/core";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchField from "../../../../../components/SearchField";
import { useAuthContext } from "../../../../../providers/Auth/auth.provider";
import { AvailablePlatform } from "../../../../../providers/Auth/platform.provider";
import { AddressFormModal } from "../../../../../shared/components/address/address-form-modal/address-form-modal.component";
import { AddressFormMode } from "../../../../../shared/components/address/address-form/address-form.component";
import { IAddressLean } from "../../../../../shared/domain/address/address-lean";
import { AddressConverter } from "../../../../../shared/domain/converter/address.converter";
import { isAdmin } from "../../../../../shared/domain/user/user";
import { AddressType } from "../../../../../shared/models/address/address.model";
import { useGetGroupAddresses } from "../../../../../shared/repositories/queries/address/get-group-addresses.query";
import { useGetUserAddresses } from "../../../../../shared/repositories/queries/address/get-user-addresses-for-id.query";
import { Colors } from "../../../../../style/Colors";
import { MIN_COUNT_FOR_SEARCH_FIELD } from "../../../../../utils/constants";
import { useOrderNewContext, WizardStep } from "../../order-new.provider";
import { OrderNewAddressDialog } from "../dialog/order-new-address-dialog.component";
import { AddressInfoBox } from "../info-box/address-info-box.component";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { WizardStepHeader } from "../wizard-step-heading.component";

const useStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    marginTop: 25,
  },
  addressBoxFirst: {
    marginTop: 15,
  },
  addressBox: {
    marginTop: 5,
  },
  newAddressBox: {
    marginTop: 8,
  },
  newAddressText: {
    textAlign: "right",
    textDecoration: "underline",
    color: Colors.greenLight,
    "&:hover": {
      cursor: "pointer",
    },
  },
  newAddressTextContainer: {
    marginTop: 15,
  },
}));

export const OrderNewAddressList: FC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [selectedAddress, setSelectedAddress] = useState<IAddressLean | undefined>();
  const [isCreateAddressDialogOpen, setIsCreateAddressDialogOpen] = useState(false);
  // if user creates a new address, it is displayed on top of all other addresses, but only until user refreshes page
  const [newAddressId, setNewAddressId] = useState<number | undefined>();
  const { activeStep, setActiveStep, editInfo, setEditInfo } = useOrderNewContext();
  const { watch, setValue, clearErrors, getValues } = useFormContext<IOrderNewWizardFormInputs>();
  const groupId = watch("customerGroupId");
  const addressId = watch("customerAddressId");
  // if admin creates order, he chooses a group and loads its addresses
  // if a customer creates order, he sees only his routes (depending on userTypeLevel)
  const {
    data: groupAddressesResult,
    isLoading: groupAddressesLoading,
    refetch: refetchGroupAddresses,
  } = useGetGroupAddresses(
    groupId,
    searchText,
    [AddressType.CustomerLocation],
    isAdmin(internalUser),
    {
      pageSize: 30,
      page: 0,
    },
    true,
  );
  const {
    data: userAddressesResult,
    isLoading: userAddressesLoading,
    refetch: refetchUserAddresses,
  } = useGetUserAddresses(internalUser?.id, [AddressType.CustomerLocation], searchText, !isAdmin(internalUser));

  const isLoading = useMemo(() => {
    return groupAddressesLoading || userAddressesLoading;
  }, [groupAddressesLoading, userAddressesLoading]);

  const currentAddresses = useMemo(() => {
    if (isAdmin(internalUser)) {
      return (groupAddressesResult?.addresses ?? []).sort(sortAddresses);
    } else {
      return (userAddressesResult?.addresses ?? []).map(AddressConverter.domainToLeanAddressDomain).sort(sortAddresses);
    }
  }, [internalUser, groupAddressesResult?.addresses, userAddressesResult]);

  const newAddress = useMemo(() => {
    return currentAddresses.find((address) => address.id === newAddressId);
  }, [currentAddresses, newAddressId]);

  const doRenderAddressRows = useMemo(() => {
    return !isLoading && currentAddresses;
  }, [isLoading, currentAddresses]);

  const refetchAddresses = useCallback(() => {
    if (isAdmin(internalUser)) {
      refetchGroupAddresses();
    } else {
      refetchUserAddresses();
    }
  }, [internalUser, refetchGroupAddresses, refetchUserAddresses]);

  useEffect(() => {
    if (!editInfo.editClicked) return;
    const address = currentAddresses.find((address) => address.id === addressId);

    if (!address?.id) return;

    const formValues = getValues();
    setValue("customerAddressId", address.id);
    setValue("contactPersonName", formValues.contactPersonName);
    setValue("contactPersonEmail", formValues.contactPersonEmail);
    setValue("contactPersonPhone", formValues.contactPersonPhone);
    setValue("openingHours", formValues.openingHours);
    clearErrors();
    setSelectedAddress(address);
    setEditInfo({ ...editInfo, editClicked: false });
  }, [editInfo, setEditInfo, addressId, clearErrors, getValues, setValue, currentAddresses]);

  useEffect(() => {
    refetchAddresses();
  }, [searchText, refetchAddresses]);

  const doDisplaySearchField = () => {
    return (currentAddresses.length ?? 0) > MIN_COUNT_FOR_SEARCH_FIELD || searchText;
  };

  const hasNoData = () => {
    return !isLoading && currentAddresses.length === 0;
  };

  const onAddressClicked = (address: IAddressLean) => {
    if (!address.id) return;
    setValue("customerAddressId", address.id);
    setValue("contactPersonName", address.contactPersonName ?? "");
    setValue("contactPersonEmail", address.contactPersonEmail ?? "");
    setValue("contactPersonPhone", address.contactPersonTelephone ?? "");
    setValue("openingHours", address.openingHours ?? "");
    clearErrors();
    setSelectedAddress(address);
  };

  const handleNewAddress = (newAddressId: number | undefined): void => {
    setNewAddressId(newAddressId);
    setIsCreateAddressDialogOpen(false);
    refetchAddresses();
  };

  const sortAddresses = (a: IAddressLean, b: IAddressLean) => {
    return (a.name ?? "").localeCompare(b.name ?? "");
  };

  // Prefill customer address if only one address is available
  useEffect(() => {
    const nextStep = editInfo.stepBeforeEditClicked ?? activeStep + 1;
    if (
      groupId &&
      currentAddresses.length === 1 &&
      editInfo.stepBeforeEditClicked !== WizardStep.CustomerAddress &&
      editInfo.stepBeforeEditClicked !== WizardStep.Product
    ) {
      const address = currentAddresses[0];
      setValue("customerAddressId", address.id);
      setValue("contactPersonName", address.contactPersonName ?? "");
      setValue("contactPersonEmail", address.contactPersonEmail ?? "");
      setValue("contactPersonPhone", address.contactPersonTelephone ?? "");
      setValue("openingHours", address.openingHours ?? "");

      setEditInfo({ ...editInfo, stepBeforeEditClicked: WizardStep.CustomerAddress });
      setSelectedAddress(undefined);
      setActiveStep(nextStep);
    }
  }, [activeStep, setActiveStep, setEditInfo, setValue, groupId, currentAddresses, editInfo]);

  return (
    <>
      <AddressFormModal
        platform={AvailablePlatform.Cfm}
        open={isCreateAddressDialogOpen}
        groupId={groupId}
        addressTypes={[AddressType.CustomerLocation]}
        mode={AddressFormMode.Create}
        onCancel={() => {
          setIsCreateAddressDialogOpen(false);
        }}
        onChangedAddress={(newAddress) => handleNewAddress(newAddress?.address?.id)}
        canDeactivate={false}
        shouldPersistAddress
        headerText={t("orders.new.wizard.wasteProducerAddress.newAddressHeading")}
        hide={["active", "type"]}
      />
      <OrderNewAddressDialog
        open={selectedAddress !== undefined}
        address={selectedAddress}
        onCancel={() => {
          setSelectedAddress(undefined);
        }}
        onAccept={() => {
          setSelectedAddress(undefined);
          const nextStep = editInfo.stepBeforeEditClicked ?? activeStep + 1;
          setEditInfo({ ...editInfo, stepBeforeEditClicked: undefined });
          setActiveStep(nextStep);
        }}
      />
      <Grid container direction="column">
        <Grid item>
          <WizardStepHeader text={t("orders.new.wizard.wasteProducerAddress.select")} />
        </Grid>
        {isLoading && (
          <Grid item>
            <LinearProgress />
          </Grid>
        )}
        {doDisplaySearchField() && (
          <Grid item className={classes.searchContainer}>
            <SearchField
              size="large"
              tooltip={t("orders.new.wizard.search.wasteProducerAddress")}
              onSearchSubmit={(value: string) => {
                setSearchText(value);
              }}
              placeholder={t("orders.new.wizard.search.wasteProducerAddress")}
              autoFocus={false}
            />
          </Grid>
        )}
        <Grid item className={classes.newAddressTextContainer}>
          <Typography
            className={classes.newAddressText}
            onClick={() => {
              setIsCreateAddressDialogOpen(true);
            }}
          >
            {t("orders.new.wizard.wasteProducerAddress.newAddress")}
          </Typography>
        </Grid>
        {doRenderAddressRows && newAddress && (
          <Grid item className={classes.newAddressBox}>
            <AddressInfoBox address={newAddress} onClick={onAddressClicked} isNew />
          </Grid>
        )}
        {doRenderAddressRows &&
          currentAddresses
            .filter((address) => address.id !== newAddressId)
            .map((address, index) => {
              return (
                <Grid key={index} item className={index === 0 ? classes.addressBoxFirst : classes.addressBox}>
                  <AddressInfoBox address={address} onClick={onAddressClicked} isNew={false} />
                </Grid>
              );
            })}
        {hasNoData() && (
          <Grid item>
            <Typography variant="body1">{t("orders.new.wizard.noData")}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
