import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors, Shades } from "./Colors";

export const useProductOverviewRowStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  icons: {
    height: 25,
    width: 25,
    marginTop: 3,
  },
  iconNoData: {
    color: Colors.red,
  },
  iconData: {
    color: PrimaryColors.base,
  },
  editIconColor: {
    color: PrimaryColors.base,
  },
  articleNumberBox: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 3,
  },
  articleNumberIsEditing: {
    marginTop: 27,
  },
  text: {
    marginTop: 5,
    [theme.breakpoints.down("xl")]: {
      maxWidth: 900,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 600,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 400,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 200,
    },
  },
  createdAtText: {
    marginTop: 25,
    color: Shades.gray50,
  },
  editIcon: {
    marginTop: 3,
    marginLeft: 2,
  },
  iconAndButtonContainer: {
    marginRight: 25,
  },
  productIconContainerEditing: {
    marginTop: 25,
  },
  productIconContainer: {
    marginTop: 20,
  },
  buttonContainer: {
    marginTop: 5,
  },
  formContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
