import { Add } from "@material-ui/icons";
import React from "react";
import { useTableActionIconStyle } from "./table-action-icons.style";

export const AddItemTableIcon: React.FC<{ isDisabled: boolean }> = ({ isDisabled }) => {
  const { small, greenFill, animatedBorder, disabled } = useTableActionIconStyle();
  const classNames = [small, animatedBorder];

  if (isDisabled) {
    classNames.push(disabled);
  } else {
    classNames.push(greenFill);
  }

  return (
    <div className={classNames.join(" ")}>
      <Add color="primary" style={{ flex: 1 }} />
    </div>
  );
};
