import { useMutation } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { BattGRegistrationConverter } from "../../../../../shared/domain/converter/batt-g/batt-g-registration.converter";
import { IBattGRegistration } from "../../../../../shared/models/batt-g/batt-g-registration";
import { IBattGRegistrationModel } from "../../../../../shared/repositories/models/batt-g/batt-g-registration.model";
import { IBattGRegistrationRequest } from "../../../../../shared/repositories/requests/batt-g/batt-g-registration.request";
import getInstance from "../../../../../utils/rest";
import { PomQueryKeys } from "../../../pom-query-keys";

const createRegistration = async (request: IBattGRegistrationRequest): Promise<IBattGRegistration> => {
  const { data } = await getInstance().post<IBattGRegistrationModel>(RestEndpoints.BattGRegistrations, request);
  return BattGRegistrationConverter.toDomain(data);
};

export const useCreateRegistrationQuery = () => {
  return useMutation(
    [PomQueryKeys.UpdateRegistrationOfCurrentGroup],
    ({ request }: { request: IBattGRegistrationRequest }) => createRegistration(request),
  );
};
