import React from "react";
import { Switch } from "react-router-dom";
import { AuthenticatedRoute } from "../../../utils/AuthenticatedRoute";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { BattGRegisterPage } from "../../pages/batt-g/registration/batt-g-register.page";

export const BattGRoutes = () => {
  return (
    <Switch>
      <AuthenticatedRoute path={AvailableSharedRoutes.BattGMasterData} exact component={BattGRegisterPage} />
    </Switch>
  );
};
