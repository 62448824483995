import { ICfmOrderFreshdeskRequest } from "../../../domain/order-x/cfm-order-freshdesk-request";
import { ICfmOrderFreshdeskRequestModel } from "../order-x/cfm-order-request-freshdesk.model";

export class CfmOrderFreshdeskRequestConverter {
  public static toModel(data: ICfmOrderFreshdeskRequest): ICfmOrderFreshdeskRequestModel {
    return {
      contact: data.contact,
      text: data.text,
    };
  }
}
