import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { AppDialog } from "../../../../../shared/components/dialog/app-dialog.component";
import { FormPhoneCountryField } from "../../../../../shared/components/form/fields/form-phone-country.field";
import { FormTextField } from "../../../../../shared/components/form/fields/form-text.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { IAddressLean } from "../../../../../shared/domain/address/address-lean";
import { getFormattedDomainAddressForTitle, translateCountryByCode } from "../../../../../utils/address.util";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";

const useStyles = makeStyles((theme: Theme) => ({
  textColor: {
    color: "white",
  },
  formSpacing: {
    marginTop: theme.spacing(4),
  },
}));

interface IOrderNewAddressDialogProps {
  open: boolean;
  address: IAddressLean | undefined;
  onCancel: () => void;
  onAccept: () => void;
}

export const OrderNewAddressDialog: FunctionComponent<IOrderNewAddressDialogProps> = (props) => {
  const { open, address, onCancel, onAccept } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    trigger,
    formState: { errors, isValid },
    control,
  } = useFormContext<IOrderNewWizardFormInputs>();

  const onAcceptClick = async () => {
    const result = await trigger();
    const canContinue = result && isValid;
    if (!canContinue) return;
    onAccept();
  };

  const getTitle = (): string => {
    if (!address) return "";

    return `${getFormattedDomainAddressForTitle(
      address,
      address.country ? translateCountryByCode(address.country, t) : "",
    )}`;
  };

  return (
    <AppDialog open={open} title={getTitle()} onCancelClick={onCancel} onAcceptClick={onAcceptClick} fullWidth={false}>
      <Grid container>
        <FormField md={12}>
          <FormTextField
            hasError={Boolean(errors?.contactPersonName)}
            label={t("orders.new.wizard.wasteProducerAddress.contactPersonName")}
            control={control}
            name={"contactPersonName"}
            required={true}
            rules={{ required: true }}
          />
        </FormField>
        <FormField md={12} className={classes.formSpacing}>
          <FormTextField
            hasError={Boolean(errors?.contactPersonEmail)}
            label={t("orders.new.wizard.wasteProducerAddress.contactPersonEmail")}
            name={"contactPersonEmail"}
            required={true}
            control={control}
            rules={{
              required: true,
              validate: async (value) => {
                return value && isEmail(value);
              },
            }}
          />
        </FormField>
        <FormField md={12} className={classes.formSpacing}>
          <FormPhoneCountryField
            name="contactPersonPhone"
            label={t("orders.new.wizard.wasteProducerAddress.contactPersonPhone")}
            control={control}
            hasError={Boolean(errors?.contactPersonPhone)}
            required={true}
          />
        </FormField>
        <FormField md={12} className={classes.formSpacing}>
          <FormTextField
            hasError={Boolean(errors?.openingHours)}
            label={t("orders.new.wizard.wasteProducerAddress.openingHours")}
            multiline={true}
            minRows={3}
            required={false}
            control={control}
            name={"openingHours"}
          />
        </FormField>
      </Grid>
    </AppDialog>
  );
};
