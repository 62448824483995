import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { useMemo, VFC } from "react";
import { useTranslation } from "react-i18next";
import {
  CfmOrderXApiStatus,
  getOrderStatusKeys,
} from "../../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { OrderCheckbox } from "../../../../components/checkbox/order-checkbox.component";
import { ForwardButton } from "../../../../components/Primitives";
import {
  ProductResponseAssignmentStep,
  useProductResponseAssignmentContext,
} from "../../../pages/product-response/product-response-assignment.provider";
import { ProductResponseOrderStatusConfigRow } from "../product-response-order-status-config-row.component";

const useStyles = makeStyles((theme: Theme) => ({
  checkBox: {
    marginLeft: 22,
  },
  bottomRowContainer: {
    marginBottom: 30,
    marginTop: 20,
  },
  headingContainer: {
    marginTop: 20,
  },
}));

export const ProductResponseOrderStatusFormContent: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { selectedResponseAssignmentRowValues, setSelectedResponseAssignmentRowValues, setActiveStep } =
    useProductResponseAssignmentContext();
  const selectedOrderStatus = useMemo(() => {
    return selectedResponseAssignmentRowValues.map((values) => values.status);
  }, [selectedResponseAssignmentRowValues]);

  const orderStatus = getOrderStatusKeys(false).filter(
    (status) => status !== CfmOrderXApiStatus.Confirmed && status !== CfmOrderXApiStatus.Canceled,
  );

  const areAllSelected = () => {
    return orderStatus.length > 0 && orderStatus.every((status) => selectedOrderStatus.includes(status));
  };

  const onSelectAllClick = (checked: boolean) => {
    const newSelectedStatus =
      checked && orderStatus
        ? orderStatus.map((status) => {
            return { status };
          })
        : [];

    setSelectedResponseAssignmentRowValues(newSelectedStatus);
  };

  const isStatusSelected = (status: CfmOrderXApiStatus) => {
    return selectedResponseAssignmentRowValues.find((values) => values.status === status) !== undefined;
  };

  const onStatusSelect = (status: CfmOrderXApiStatus, checked: boolean) => {
    if (checked) {
      setSelectedResponseAssignmentRowValues([...selectedResponseAssignmentRowValues, { status }]);
    } else {
      setSelectedResponseAssignmentRowValues(
        selectedResponseAssignmentRowValues.filter((values) => values.status !== status),
      );
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="column">
          {orderStatus.map((status, index) => {
            return (
              <Grid item key={index}>
                <ProductResponseOrderStatusConfigRow
                  status={status}
                  isSelected={isStatusSelected(status)}
                  onSelect={onStatusSelect}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item className={classes.bottomRowContainer}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item className={classes.checkBox}>
            <OrderCheckbox
              label={areAllSelected() ? t("general.deselectAll") : t("general.selectAll")}
              onChange={onSelectAllClick}
              checked={areAllSelected()}
              size="large"
            />
          </Grid>
          <Grid item>
            <ForwardButton
              onClick={() => setActiveStep(ProductResponseAssignmentStep.RESPONSE_FIELDS)}
              disabled={selectedResponseAssignmentRowValues.length === 0}
            >
              <Typography variant="body1">{t("configuration.responseAssignments.configResponseFields")}</Typography>
            </ForwardButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
