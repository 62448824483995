import React, { createContext, useContext } from "react";
import { AvailablePlatform, usePlatformContext } from "../Auth/platform.provider";
import { useGetOrderXStatusCountQuery } from "../../collect-from-market/repositories/queries/order-x/queries/get-order-x-status-counts.query";
import { CfmOrderXStatusModel } from "../../collect-from-market/repositories/models/order-x/cfm-order-x.status.model";

interface ICartContextType {
  cartCountOrderX: number;
}

export const CartContext = createContext<ICartContextType>({} as ICartContextType);

export const CartProvider = (props: any) => {
  const value = useCartProvider();
  return <CartContext.Provider value={value}>{props.children}</CartContext.Provider>;
};

export const useCart = () => {
  return useContext(CartContext);
};

const useCartProvider = (): ICartContextType => {
  const { activePlatform } = usePlatformContext();

  const { data } = useGetOrderXStatusCountQuery(
    CfmOrderXStatusModel.ShoppingCart,
    activePlatform === AvailablePlatform.Cfm,
  );

  return { cartCountOrderX: data?.totalCount ?? 0 };
};
