import { ICreateProductRoutingFormInputs } from "../../../../configurator/components/product-routing/create-product-routing-content.component";
import { emptyOption } from "../../../../configurator/components/product-routing/form/product-routing-addresses-form-content.component";
import { ICfmCreateProductRoutingAssignmentModel } from "../routing-assignment/cfm-create-product-routing-assignment.model";

export class CfmCreateProductRoutingAssignmentConverter {
  public static createDataToModel(data: ICreateProductRoutingFormInputs): ICfmCreateProductRoutingAssignmentModel {
    return {
      postals: data.postals,
      primaryStartAddressId: data.primaryStartAddressId,
      primaryTargetAddressId: data.primaryTargetAddressId,
      secondaryStartAddressId: data.secondaryStartAddressId,
      secondaryTargetAddressId: data.secondaryTargetAddressId,
      optionalPrimaryAddressIds: data.optionalPrimaryAddressIds?.filter(
        (address) => address !== Number(emptyOption.id),
      ),
      optionalSecondaryAddressIds: data.optionalSecondaryAddressIds?.filter(
        (address) => address !== Number(emptyOption.id),
      ),
    };
  }
}
