import { CircularProgress, Grid } from "@material-ui/core";
import { VFC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AvailableCfmRoutes } from "../../../utils/constants";
import { EditTruckloadContent } from "../../components/truckload/edit-truckload-content.component";
import { useGetTruckloadQuery } from "../../repositories/queries/truckload/queries/get-truckload.query";
interface ITruckloadDetailParams {
  id: string | undefined;
}

export const EditTruckloadPage: VFC = (props) => {
  const history = useHistory();
  const { id } = useParams<ITruckloadDetailParams>();
  const { data: truckload, isLoading } = useGetTruckloadQuery(id ? Number(id) : undefined);

  if (!id || !Number(id)) {
    history.push(AvailableCfmRoutes.OrderXOverview);
  }

  return (
    <>
      {isLoading && (
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {truckload && !isLoading && <EditTruckloadContent truckload={truckload} />}
    </>
  );
};
