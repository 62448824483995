import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { QuestionDialog } from "../../../../components/Dialogs/question-dialog";

interface ISystemCloningDialogProps {
  isOpen: boolean;
  isLoading?: boolean;
  onAccept: () => Promise<void>;
  onCancel: () => Promise<void>;
}

export const SystemCloningDialog: VFC<ISystemCloningDialogProps> = (props) => {
  const { isOpen, isLoading, onAccept, onCancel } = props;
  const { t } = useTranslation();

  return (
    <QuestionDialog
      isOpen={isOpen}
      isProcessing={isLoading}
      onAccept={onAccept}
      onClose={onCancel}
      title={t("system_cloning.title")}
      message={t("system_cloning.message")}
      acceptText={t("system_cloning.accept")}
      displayStyle="warning"
    />
  );
};
