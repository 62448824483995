import dayjs from "dayjs";
import moment from "moment";
import { DateWithoutTime } from "../../shared/util/date.util";
import {
  IPomAnnouncement,
  IPomAnnouncementTableData,
} from "../components/announcement-table/pom-announcement-table-data";
import { PomProductUnit } from "../components/product-table/pom-product-table-data";
import { PomAnnouncementStatusModel } from "../repositories/models/announcements/pom-announcements.model";

export class AnnouncementUtil {
  /**
   * Checks if an announcement entry can be decreased. (increase is always allowed)
   * If the announcement is older than one year, it is not allowed.
   * The previous year should only be updated til the 18.01. of the current year.
   * Only published announcements should be protected.
   * @param announcement the announcement that should be updated
   */
  public static isAnnouncementDecreaseAllowedForYear(announcement: IPomAnnouncement | undefined | null): boolean {
    if (!announcement) {
      return true;
    }

    const announcementYear = announcement.announcementDate.getFullYear();
    const curDate = new Date();
    const curYear = curDate.getFullYear();

    if (announcement.status === PomAnnouncementStatusModel.Draft) {
      return true;
    }

    // If announcement is in the future or same year, allow updates
    if (curYear - announcementYear <= 0) {
      return true;
    }

    // If announcement is older than one year, do not allow updates
    if (curYear - announcementYear > 1) {
      return false;
    }

    // If the current date is before the 18.01. allow updates
    return moment(curDate).isBefore(new Date(curDate.getFullYear(), 0, 25), "day");
  }

  /**
   * Checks if the provided table data is invalid.
   * Currently only checks when either count or totalWeight is 0, the other value has to be 0 as well
   * @param data the announcement table data
   */
  public static isAnnouncementTableDataInvalid(data: IPomAnnouncementTableData): boolean {
    return (data.count === 0 && data.totalWeight !== 0) || (data.totalWeight === 0 && data.count !== 0);
  }

  /**
   * Check if the given weight is in the range of the lower and upper weight limit
   * @param data the announcement table data
   */
  public static isAnnouncementTableDataWeightInvalid(data: IPomAnnouncementTableData): boolean {
    if (!data.count || !data.totalWeight) {
      return false;
    }

    const lowerLimitPerItemInGram = data.lowerWeightLimitInGram ?? 0;
    const upperLimitPerItemInGram = data.upperWeightLimitInGram ?? Number.MAX_VALUE;

    const totalLowerLimitInKg = (lowerLimitPerItemInGram / 1000) * data.count;
    const totalUpperLimitInKg = (upperLimitPerItemInGram / 1000) * data.count;

    return data.totalWeight < totalLowerLimitInKg || data.totalWeight > totalUpperLimitInKg;
  }

  public static calculatePrice(
    unit: PomProductUnit,
    price: number,
    count: number | undefined,
    weight: number | undefined,
  ): number | undefined {
    if (unit === "STK") {
      return count ? count * price : undefined;
    } else {
      return weight ? weight * price : undefined;
    }
  }
}

export const getYearlyAnnouncementDate = (contractStartDate: DateWithoutTime): Date => {
  const currentYear = dayjs().startOf("year");
  const contractYear = dayjs(contractStartDate).startOf("year");

  // Only allow 3 years into the past if the contract start date is older than 3 years
  if (currentYear.get("year") - contractYear.get("year") >= 3) {
    return currentYear.subtract(3, "year").toDate();
  } else {
    return contractYear.toDate();
  }
};
