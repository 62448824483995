import { TranslateFunc } from "./translation.utils";

export const NO_FILTER_SELECT_OPTION = "0";
export const ENTER_KEY = "Enter";
export const PAGE_NOT_FOUND = "Page Not Found";

export const STRONG_PASSWORD_OPTIONS = {
  minLength: 10,
  minLowerCase: 1,
  minUpperCase: 1,
  minNumbers: 1,
  minSymbols: 1,
};

export const getTitles = (translate: TranslateFunc) => {
  const titles = [
    translate("basedata.users.title.female"),
    translate("basedata.users.title.male"),
    translate("basedata.users.title.diverse"),
  ];

  return titles;
};

// #region Routes
export const AvailableSharedRoutes = {
  Portal: "/portal",
  Login: "/login",
  LockedOut: "/locked",
  Unlock: "/unlock",
  ResetPassword: "/portal/user/resetPassword",
  SecretCloneSystem: "/portal/clone/production",
  Contact: "/portal/contact",
  DataPrivacy: "/portal/data-privacy",
  BattG: "/portal/batt-g",
  BattGMasterData: "/portal/batt-g/master-data",

  // Group
  Group: "/portal/groups",
  GroupList: "/portal/groups",
  GroupNew: "/portal/groups/new",
  GroupDetail: "/portal/groups/:id",

  // Users
  UserList: "/portal/users",
  UserDetail: "/portal/users/:id",
  UserEdit: "/portal/users/:id/edit",
  UserCreate: "/portal/users/add",
};

export const AvailablePomRoutes = {
  Announcement: "/portal/pom/announcement",
  UserNew: "/portal/pom/users/new",
  Agency: "/portal/pom/agency",
  ManufacturerCreate: "/portal/pom/manufacturers/add",
  ManufacturerDetail: "/portal/pom/manufacturers/:id",
  ManufacturerEdit: "/portal/pom/manufacturers/:id/edit",
  Portal: "/portal/pom",
};

export const AvailableCfmRoutes = {
  OrderQrView: "/portal/cfm/qrview/:orderId",
  UserNew: "/portal/cfm/users/new",
  LogisticAssignments: "/portal/cfm/logistics/new",
  RecyclerAssignments: "/portal/cfm/recycler/new",
  BaseOrderUrl: "/portal/cfm/contract",
  Portal: "/portal/cfm",

  OrderXDetail: "/portal/cfm/contract/order/:orderId",
  OrderXOverview: "/portal/cfm/contract/order",
  OrderXNew: "/portal/cfm/order/new",
  OrderXEditPending: "/portal/cfm/order/:id/edit",
  OrderXNewRepeat: "/portal/cfm/order/:id/repeat",
  OrderXAdminNew: "/portal/cfm/contract/order-new",
  OrderXRepeatOverview: "/portal/cfm/repeat",
  CartX: "/portal/cfm/cart",

  TruckloadEdit: "/portal/cfm/truckload/:id/edit",
};

export const AvailableConfiguratorRoutes = {
  Portal: "/portal/config",
  ProductOverview: "/portal/config/overview",
  CreateProductConfig: "/portal/config/product/new",
  PackageOverview: "/portal/config/package/overview",
  CreateProductPackageConfig: "/portal/config/package/new",
  ProductRoutingOverview: "/portal/config/routing/overview",
  ProductsForRouting: "/portal/config/routing/products",
  CreateProductRouting: "/portal/config/routing/new",
  EditProductRouting: "/portal/config/routing/edit",
  CreateResponseConfig: "/portal/config/response/new",
  ProductResponseOverview: "/portal/config/response/overview",
  ProductResponseFieldConfig: "/portal/config/response-field/overview",
};
// #endregion

export const isCfmRoute = (route: string) => route.startsWith("/portal/cfm/");
export const isPomRoute = (route: string) => route.startsWith("/portal/pom/");
export const isConfigRoute = (route: string) => route.startsWith("/portal/config/");

export const MIN_COUNT_FOR_SEARCH_FIELD = 10;
export const SCROLL_TO_TOP_BUTTON_HEIGHT = 60;
export const SCROLL_TO_TOP_BUTTON_MIN_WIDTH = 60;
export const GERMANY_FALLBACK_ZIP = "DE";
export const POSTAL_LENGTH = 5;
export const ARTICLE_SEPARATOR = "|";
export const TEXT_SEPARATOR = ",";
