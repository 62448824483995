import { Button, ButtonGroup, CircularProgress, Grow, makeStyles, Paper, Popper, Theme } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadPdf } from "../../../../../shared/util/download.util";
import { Colors } from "../../../../../style/Colors";
import { isLogisticBulkUpdateStatus } from "../../../../domain/order-x/cfm-order-x.status";
import { useOrderXOverviewContext } from "../../order-x-overview.provider";
import { SelectedFilesProvider } from "../actions/providers/selected-files.provider";
import { OrderBulkActionDialog } from "./oder-bulk-action-component";
import { OrderBulkActionMenuList } from "./order-bulk-action-menu-list";
import { getActionTypeForBulkAction } from "./order-bulk-action.util";
import { LogisticBulkAction, useOrderBulkActionContext } from "./providers/order-bulk-action.provider";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    borderRadius: 48,
  },
  popper: {
    zIndex: 1,
    borderRadius: 8,
    marginTop: 2,
  },
  loading: {
    paddingLeft: 26,
    marginBottom: -6,
    color: Colors.greenDark,
  },
}));

export const OrderBulkActionButtonGroup: React.VFC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    selectedOrderIds,
    resetBulkActions,
    pdfContent,
    cfmOrderBulkActions,
    bulkActions,
    isLoading,
    getDeliveryReport,
    validateAvailableOrderActions,
    setCurrentBulkAction,
  } = useOrderBulkActionContext();
  const { curFilter } = useOrderXOverviewContext();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const classes = useStyles();

  const openDeliveryReport = () => {
    if (!pdfContent) return;
    downloadPdf(pdfContent, "Lieferschein.pdf");
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    switch (bulkActions[index].action) {
      case LogisticBulkAction.DeliveryReport:
        openDeliveryReport();
        break;
      case LogisticBulkAction.LogisticAnnouncedSingleOrder:
      case LogisticBulkAction.LogisticMistrip:
      case LogisticBulkAction.LogisticAccepted:
      case LogisticBulkAction.LogisticPerformed:
      case LogisticBulkAction.ChangedPlannedPickup:
      case LogisticBulkAction.LogisticAnnounced:
        if (cfmOrderBulkActions) {
          const type = getActionTypeForBulkAction(bulkActions[index].action);
          if (!type) return;
          const currentAction = cfmOrderBulkActions.availableOrderBulks[0].availableActions.find(
            (action) => action.type === type,
          );
          setCurrentBulkAction(currentAction);
          setOpenDialog(true);
        }
        break;
      default:
        return;
    }
    setOpen(false);
  };

  const handleToggle = async () => {
    if (open) {
      setOpen(!open);
      return;
    }
    await validateAvailableOrderActions();
    await getDeliveryReport();
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Fragment>
      {isLogisticBulkUpdateStatus(curFilter.statusFilter) && (
        <SelectedFilesProvider>
          {/* Use selectedOrderIds as key for re-rendering of the action bulk dialog */}
          <OrderBulkActionDialog
            key={selectedOrderIds.toString()}
            open={openDialog}
            setOpen={setOpenDialog}
            reset={resetBulkActions}
          />
        </SelectedFilesProvider>
      )}
      <ButtonGroup
        color={"primary"}
        variant="outlined"
        disabled={selectedOrderIds.length === 0}
        ref={anchorRef}
        aria-label="action-selection"
        className={classes.buttonGroup}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading || selectedOrderIds.length === 0}
          onClick={handleToggle}
        >
          {isLoading && (
            <div className={classes.loading}>
              <CircularProgress size={22} />
            </div>
          )}
          {!isLoading && t("order.bulk_update.action_default")}
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={isLoading || selectedOrderIds.length === 0}
          aria-controls={open ? "bulk-actions" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select bulk action"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown fontSize="medium" />
        </Button>
      </ButtonGroup>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <OrderBulkActionMenuList handleMenuItemClick={handleMenuItemClick} handleClose={handleClose} />
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};
