import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { OrderXList } from "./components/order-x-list.component";
import { OrderXStatusFilter } from "./components/status-filter/order-x-status-filter.component";
import { OrderXOverviewProvider } from "./order-x-overview.provider";
import { OrderXOverviewHeaderButtons } from "./components/order-overview-header-buttons/order-overview-header-buttons.component";
import { useParams } from "react-router-dom";
import { OrderBulkActionProvider } from "./components/bulk-actions/providers/order-bulk-action.provider";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: 30,
    marginBottom: 30,
  },
  buttonContainer: {
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
      marginRight: 0,
      marginLeft: "auto",
    },
  },
}));

export const OrderXOverviewPage: VFC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <OrderXOverviewProvider orderIdForPreSelect={Number(orderId)}>
      <Grid container direction="column">
        <Grid item xs={12} className={classes.heading}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{t("orders.overview.text")}</Typography>
            </Grid>
            <OrderXOverviewHeaderButtons />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 24 }}>
          <OrderXStatusFilter />
        </Grid>
        <Grid item xs={12}>
          <OrderBulkActionProvider>
            <OrderXList />
          </OrderBulkActionProvider>
        </Grid>
      </Grid>
    </OrderXOverviewProvider>
  );
};
