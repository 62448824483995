import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../style/Colors";
import { CsvExportIcon } from "../../../components/Primitives";

const useStyles = makeStyles((theme: Theme) => ({
  whiteIcon: {
    color: Colors.white,
  },
}));

interface IExcelButtonProps {
  onClick: VoidFunction;
  disabled?: boolean;
}

export const ExcelButton: VFC<IExcelButtonProps> = (props) => {
  const { onClick, disabled } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      endIcon={<CsvExportIcon className={classes.whiteIcon} />}
      color="primary"
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      <Typography variant="body1">{t("orders.exportExcel")}</Typography>
    </Button>
  );
};
