import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { FormProvider } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { AppDialog } from "../../../../shared/components/dialog/app-dialog.component";
import { FormDatepickerField } from "../../../../shared/components/form/fields/form-datepicker.field";
import { FormTextField } from "../../../../shared/components/form/fields/form-text.field";
import { useCustomForm } from "../../../../shared/util/form.util";
import { Shades } from "../../../../style/Colors";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { OrderServiceDateForm } from "../../../pages/order-x/components/dialog/order-service-dates-form.component";
import { CfmOrderXApiStatus } from "../../../domain/order-x/cfm-order-x.status";
import { OrderActionServiceDate } from "../../../domain/order-x/actions/cfm-order-action";

const useStyles = makeStyles((theme: Theme) => ({
  weightBox: {
    border: "2px solid",
    borderColor: Shades.gray50,
    borderRadius: 5,
    padding: "5px 20px 5px 20px",
  },
}));

export interface ITruckloadPerformedInputs {
  actualDeliveryDate: Date;
  actualWeight: number;
  serviceDates: OrderActionServiceDate;
}

interface ITruckloadPerformedDialog {
  isOpen: boolean;
  isLoading: boolean;
  calculatedWeight: number;
  onCancel: VoidFunction;
  onSubmit: (data: ITruckloadPerformedInputs) => Promise<void>;
  ordersInTruckload: ICfmOrderX[];
}

export const TruckloadPerformedDialog: VFC<ITruckloadPerformedDialog> = (props) => {
  const { isOpen, onCancel, onSubmit, calculatedWeight, ordersInTruckload, isLoading } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const formMethods = useCustomForm<ITruckloadPerformedInputs>({ mode: "all" });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const prepareAndSubmit = async (inputs: ITruckloadPerformedInputs): Promise<void> => {
    onSubmit(inputs);
  };

  return (
    <AppDialog
      open={isOpen}
      title={t("orders.truckload.heading")}
      onCancelClick={onCancel}
      onAcceptClick={() => handleSubmit(prepareAndSubmit)()}
      acceptTextOverride={t("orders.truckload.respond")}
      fullWidth
      maxWidth="sm"
      isLoading={isLoading}
    >
      <FormProvider {...formMethods}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Box className={classes.weightBox}>
              <Trans i18nKey="orders.truckload.truckloadTransportWeight">
                {{ weight: calculatedWeight?.toLocaleString() ?? 0 }}
              </Trans>
            </Box>
          </Grid>
          <Grid item>
            <FormDatepickerField
              label={t("orders.truckload.truckloadPerformed.actualDeliveryDate")}
              hasError={Boolean(errors.actualDeliveryDate)}
              control={control}
              required
              name={"actualDeliveryDate"}
            />
          </Grid>
          <Grid item>
            <FormTextField
              hasError={Boolean(errors?.actualWeight)}
              label={t("orders.truckload.truckloadPerformed.actualWeight")}
              required={true}
              type="number"
              control={control}
              name={"actualWeight"}
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
        {ordersInTruckload.length > 0 && (
          <div className={"mt-3"}>
            <OrderServiceDateForm orders={ordersInTruckload} orderStatus={CfmOrderXApiStatus.Confirmed} />
          </div>
        )}
      </FormProvider>
    </AppDialog>
  );
};
