import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useIconButtonsStyle } from "style/iconButtons.style";

export const ClearFilterButton: FunctionComponent<IconButtonProps> = (props) => {
  const { small, green } = useIconButtonsStyle();
  const { t } = useTranslation();

  return (
    <Tooltip title={t("clear_all_filters")}>
      <IconButton {...props} color="primary" aria-label="clear_all_filters" className={[small, green].join(" ")}>
        <Close color="primary" />
      </IconButton>
    </Tooltip>
  );
};
