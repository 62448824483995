import { Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { PasswordResetDialog } from "../../components/Password/password-reset-dialog";
import { MenuBurgerIcon, MenuDrawerIcon } from "../../components/Primitives";
import { Header } from "../../layout-components/Header/header-component";
import { usePasswordResetDialogContext } from "../../components/Password/password-reset-dialog.provider";
import { DRAWER_OPEN, Sidebar } from "../domain/sidebar/sidebar";
import { useSidebarContext } from "../domain/sidebar/sidebar-context";

const DRAWER_HANDLE = 31;

const useStyles = makeStyles((theme: Theme) => ({
  rootContent: {
    paddingLeft: 40,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
    },
  },
  marginLeftDrawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - (${DRAWER_OPEN}px + ${DRAWER_HANDLE}px))`,
    [theme.breakpoints.down(1190)]: {
      width: `calc(100% - ${DRAWER_HANDLE}px)`,
    },
  },
  marginLeftDrawerClosed: {
    width: `calc(100% - ${DRAWER_HANDLE}px)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${DRAWER_HANDLE - 10}px)`,
    },
  },
  content: {
    width: "100%",
  },
  headerContainer: {
    width: "100%",
  },
  sidebar: {
    width: 0,
  },
  drawerHandle: {
    float: "right",
    width: 31,
  },
  button: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

interface ISidebarLayout {}

export const SidebarLayout: React.FunctionComponent<ISidebarLayout> = (props) => {
  const classes = useStyles();
  const { sidebarOpen, setSidebarOpen } = useSidebarContext();
  const { open } = usePasswordResetDialogContext();

  return (
    <Grid
      container
      className={`${classes.rootContent} ${
        sidebarOpen ? classes.marginLeftDrawerOpen : classes.marginLeftDrawerClosed
      }`}
    >
      <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
        <Grid item className={classes.headerContainer}>
          <Header />
        </Grid>
        <Grid item className={classes.drawerHandle}>
          <IconButton className={classes.button} size="medium" onClick={() => setSidebarOpen(!sidebarOpen)}>
            {sidebarOpen ? <MenuDrawerIcon svgColor="green" /> : <MenuBurgerIcon svgColor="green" />}
          </IconButton>
        </Grid>
        <Grid item className={classes.sidebar}>
          <Sidebar />
        </Grid>
      </Grid>
      <Grid item className={classes.content}>
        <PasswordResetDialog open={open} />
        {props.children}
      </Grid>
    </Grid>
  );
};
