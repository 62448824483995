import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmTransferOrderFilterModel } from "../../../models/transfer-order/cfm-transfer-order-filter.model";

const exportTransferOrders = async (filter?: ICfmTransferOrderFilterModel): Promise<string> => {
  const { data } = await getInstance().get(RestEndpoints.ExportTransferOrders, {
    params: {
      status: filter?.status,
      targetAddressId: filter?.targetAddressId,
      startAddressId: filter?.startAddressId,
      postingType: filter?.postingType,
    },
    responseType: "blob",
  });
  return data;
};

export const useExportTransferOrders = (filter?: ICfmTransferOrderFilterModel, enabled?: boolean) => {
  return useQuery([CfmQueryKeys.ExportTransferOrders, filter], () => exportTransferOrders(filter), {
    staleTime: 0,
    enabled: enabled,
  });
};
