import { Grid } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useOrderNewInfoBoxStyle } from "../../../../../style/order-new-info-box.style";

interface IOrderNewInfoBoxMobileProps {
  iconComponent: JSX.Element;
  labelComponent: JSX.Element;
  onClick: VoidFunction;
  renderNextArrow: () => JSX.Element;
  renderIsNew: () => JSX.Element | null;
}

export const OrderNewInfoBoxMobile: React.FC<IOrderNewInfoBoxMobileProps> = (props) => {
  const { iconComponent, labelComponent, onClick, renderNextArrow, renderIsNew } = props;
  const classes = useOrderNewInfoBoxStyle();

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.gridContainer, classes.gridContainerHover)}
      onClick={onClick}
      spacing={0}
    >
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs="auto" className={classNames(classes.innerContainer, classes.iconPadding)}>
            {iconComponent}
          </Grid>
          <Grid item xs="auto">
            <Grid container direction="row">
              <Grid item>{renderIsNew()}</Grid>
              <Grid item>{renderNextArrow()}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.label}>
        {labelComponent}
      </Grid>
    </Grid>
  );
};
