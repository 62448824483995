import { Grid, GridProps } from "@material-ui/core";
import React, { FC } from "react";

interface IFormFieldProps extends GridProps {
  hide?: boolean;
  container?: boolean;
}

export const FormField: FC<IFormFieldProps> = (props) => {
  const { xs = 12, md = 6, container, hide, children, ...rest } = props;

  if (hide) return null;

  return (
    <Grid item={!container} xs={xs} md={md} {...rest} container={container}>
      {children}
    </Grid>
  );
};
