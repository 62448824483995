import { useQuery } from "react-query";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import { AddressConverter } from "../../../../shared/domain/converter/address.converter";
import getInstance from "../../../../utils/rest";
import { ICfmProductRoutingAddresses } from "../../../domain/routing-assignment/cfm-product-routing-assignment";
import { CfmProductAddressTypeModel } from "../../models/product/cfm-product-address-type.model";
import { ICfmProductRoutingAddressesModel } from "../../models/routing-assignment/cfm-product-routing-assignment.model";
import { CfmQueryKeys } from "../cfm-query-keys";

const getAddressesForProduct = async (
  productId: number | undefined,
  type: CfmProductAddressTypeModel,
  routingPostal: string,
  startAddressId: number,
): Promise<ICfmProductRoutingAddresses | undefined> => {
  if (!productId) return undefined;
  const url = replaceUriParameter(RestEndpoints.ProductAddresses, "productId", productId);
  const { data } = await getInstance().get<ICfmProductRoutingAddressesModel>(url, {
    params: {
      type,
      routingPostal,
      startAddressId,
    },
  });

  return {
    address: data.address ? AddressConverter.toLeanAddress(data.address) : null,
    optionalAddresses: data.optionalAddresses.map(AddressConverter.toLeanAddress),
  };
};

export const useGetAddressesForProductQuery = (
  productId: number | undefined,
  type: CfmProductAddressTypeModel,
  routingPostal: string,
  startAddressId: number,
  enabled?: boolean,
) => {
  return useQuery(
    [CfmQueryKeys.GetProductAddresses, productId, type],
    () => getAddressesForProduct(productId, type, routingPostal, startAddressId),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled ?? false,
    },
  );
};
