import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import Image from "material-ui-image";
import { VFC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
}));

interface IPackageCustomImageProps {
  imageUrl: string;
  packageName: string;
  onMouseEnter: VoidFunction;
}

export const PackageCustomImage: VFC<IPackageCustomImageProps> = (props) => {
  const { imageUrl, packageName, onMouseEnter } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1} justifyContent="space-evenly" onMouseEnter={onMouseEnter}>
      <Grid item>
        <Image src={imageUrl} alt={packageName} style={{ width: 40, height: 40 }} />
      </Grid>
      <Grid item>
        <Box display="flex" alignItems={"center"} className={classes.container}>
          <InfoOutlined color="inherit" fontSize="small" />
        </Box>
      </Grid>
    </Grid>
  );
};
