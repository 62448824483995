import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { IGroupNextContractNumberModel } from "../../../models/group/group-next-contract-number.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getGroupNextContractNumber = async (): Promise<number> => {
  const response = await getInstance().get<IGroupNextContractNumberModel>(RestEndpoints.NextContractNumber);
  return response.data.nextContractNumber;
};

export const useGetGroupNextContractNumberQuery = ({ enabled }: { enabled?: boolean }) => {
  return useQuery([SharedQueryKeys.GetGroupNextContractNumber], () => getGroupNextContractNumber(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled: enabled,
  });
};
