import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import {
  ConfigDoneIcon,
  ConfigMissingIcon,
  PackageConfigIcon,
  RoutingConfigIcon,
} from "../../../components/Primitives/Icons";
import { AppDialog } from "../../../shared/components/dialog/app-dialog.component";
import { Colors, PrimaryColors } from "../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  packageIcon: {
    height: 40,
    width: 40,
  },
  hasPackage: {
    color: PrimaryColors.base,
  },
  noPackages: {
    color: Colors.red,
  },
  routingIcon: {
    height: 40,
    width: 41,
  },
  hasRouting: {
    color: PrimaryColors.base,
  },
  noRouting: {
    color: Colors.red,
  },
  missingConfigIcon: {
    height: 16,
    width: 16,
    color: Colors.red,
  },
  configIcon: {
    height: 16,
    width: 16,
    color: PrimaryColors.base,
  },
}));

interface IConfigurationCreatedDialog {
  open: boolean;
  title: string;
  hasPackages: boolean;
  hasRouting: boolean;
  onAccept: VoidFunction;
  onCancel?: VoidFunction;
  acceptText?: string;
  cancelText?: string;
  displayPackageIcon?: boolean;
  displayRoutingIcon?: boolean;
  fullWidth?: boolean;
  contentText?: string;
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
}

export const ConfigurationCreatedDialog: VFC<IConfigurationCreatedDialog> = (props) => {
  const {
    open,
    title,
    hasPackages,
    hasRouting,
    onAccept,
    onCancel,
    acceptText,
    cancelText,
    displayPackageIcon = true,
    displayRoutingIcon = true,
    fullWidth = true,
    contentText,
    maxWidth,
  } = props;
  const classes = useStyles();

  const getSubIcon = (hasData: boolean) => {
    if (hasData) {
      return <ConfigDoneIcon className={classes.configIcon} viewBox="0 0 16 15.996" />;
    } else {
      return <ConfigMissingIcon className={classes.missingConfigIcon} viewBox="0 0 16 15.996" />;
    }
  };

  return (
    <AppDialog
      open={open}
      title={title}
      onAcceptClick={onAccept}
      onCancelClick={onCancel}
      acceptTextOverride={acceptText}
      cancelTextOverride={cancelText}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" spacing={4} justifyContent="center">
            {displayPackageIcon && (
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" justifyContent="center">
                      <Grid item>
                        <PackageConfigIcon
                          className={classNames(classes.packageIcon, {
                            [classes.hasPackage]: hasPackages,
                            [classes.noPackages]: !hasPackages,
                          })}
                          viewBox="0 0 40 39.999"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>{getSubIcon(hasPackages)}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {displayRoutingIcon && (
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" justifyContent="center">
                      <Grid item>
                        <RoutingConfigIcon
                          className={classNames(classes.routingIcon, {
                            [classes.hasRouting]: hasRouting,
                            [classes.noRouting]: !hasRouting,
                          })}
                          viewBox="0 0 41 40"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="center">
                      <Grid item>{getSubIcon(hasRouting)}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {contentText && (
          <Grid item>
            <Grid container direction="row" spacing={4} justifyContent="center">
              <Grid item>
                <Typography variant="body1">{contentText}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </AppDialog>
  );
};
