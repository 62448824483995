import { Grid, LinearProgress } from "@material-ui/core";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PomPlatformLogo } from "../../../../components/Primitives/Icons";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { usePlatformIconStyle } from "../../../../style/platform-icon.style";
import { IGroup } from "../../../domain/group/group";
import { GroupType } from "../../../domain/group/group-type";
import { isAdmin } from "../../../domain/user/user";
import { useGetAllAgenciesQuery } from "../../../repositories/queries/group/get-all-agencies.query";
import { FormAutocomplete } from "../../form/fields/form-autocomplete.component";
import { FormCheckbox } from "../../form/fields/form-checkbox.field";
import { FormTextField } from "../../form/fields/form-text.field";
import { FormField } from "../../form/form-field.component";
import { CreateGroupIconHeader } from "../create-group/create-group-icon-header.component";
import { isAgencyOrMainContact } from "./group-form.util";

export interface IGroupBaseDataPomFormInputs {
  agencyGroup?: IGroup | null;
  earNumber: string;
  earTemporaryNumber: string;
  isPomAgencyAnnouncer: boolean | null;
}

interface IGroupFormPomSectionProps {
  groupType: GroupType;
}

export const GroupFormPomSection: FC<IGroupFormPomSectionProps> = (props) => {
  const { groupType } = props;
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext<IGroupBaseDataPomFormInputs>();
  const { internalUser } = useAuthContext();
  const platformIconClasses = usePlatformIconStyle();

  const { isLoading, data: agencyGroups } = useGetAllAgenciesQuery(isAdmin(internalUser));
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <CreateGroupIconHeader
          text={t("basedata.group.pom_heading")}
          icon={<PomPlatformLogo className={platformIconClasses.pomPlatformDetailsIcon} />}
        />
        <Grid item className={"mt-4"}>
          <Grid container spacing={4}>
            {isLoading && <LinearProgress />}
            {!isLoading && (
              <>
                <FormField>
                  <FormAutocomplete<IGroup>
                    control={control}
                    required={false}
                    getOptionSelected={(option, value) => option.id === value.id}
                    label={t("basedata.groups.edit.agencyGroup")}
                    options={agencyGroups ?? []}
                    rules={{ required: false }}
                    error={Boolean(errors?.agencyGroup)}
                    getOptionLabel={(group) => group.name}
                    disabled={isAgencyOrMainContact(internalUser)}
                    // @ts-ignore fix circular dependency
                    {...register("agencyGroup")}
                  />
                </FormField>

                <FormField>
                  <FormTextField
                    hasError={Boolean(errors?.earNumber)}
                    label={t("basedata.groups.edit.EARNumber")}
                    required={false}
                    control={control}
                    name={"earNumber"}
                  />
                </FormField>
                <FormField>
                  <FormTextField
                    hasError={Boolean(errors?.earTemporaryNumber)}
                    label={t("basedata.groups.edit.EARTemporaryNumber")}
                    required={false}
                    control={control}
                    name={"earTemporaryNumber"}
                  />
                </FormField>
                {groupType === GroupType.PomAgency && (
                  <FormField>
                    <FormCheckbox
                      name={"isPomAgencyAnnouncer"}
                      control={control}
                      label={t(`basedata.groups.edit.isPomAgencyAnnouncer`)}
                      noMargin
                    />
                  </FormField>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
