import { useQuery } from "react-query";
import { GroupConverter } from "shared/domain/converter/group.converter";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { INextGroupErpNumber } from "../../../domain/group/group";
import { INextGroupErpNumberModel } from "../../models/group/next-group-erp-number.model";
import { SharedQueryKeys } from "../shared-query-keys";

export const getNextErpNumber = async (): Promise<INextGroupErpNumber> => {
  const response = await getInstance().get<INextGroupErpNumberModel>(RestEndpoints.NextErpNumber);
  return GroupConverter.nextErpNumberToDomain(response.data);
};

export const useGetNextErpNumber = (enabled: boolean = true) => {
  return useQuery([SharedQueryKeys.GetNextErpNumber], () => getNextErpNumber(), {
    staleTime: ReactQueryConfig.staleTime,
    enabled,
  });
};
