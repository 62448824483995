import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors, Shades } from "../../../../../style/Colors";
import { useOrderXSelectedContext } from "../../order-x-selected.provider";
import { OrderXHistory } from "./order-x-history";
import { useGetOrderXHistory } from "../../../../repositories/queries/order-x/queries/get-order-x-history.query";
import { useOrderCardStyle } from "../../../../../style/order-card.style";

const accordionMinHeight = 50;
const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    backgroundColor: Colors.white,
  },
}));

export const OrderXCardHistory: FC = () => {
  const { order } = useOrderXSelectedContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const orderCardClasses = useOrderCardStyle();
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: history, isLoading } = useGetOrderXHistory(order.id);

  const AccordionSummaryNoExpandIconRotate = withStyles({
    root: {
      minHeight: accordionMinHeight,
      backgroundColor: Colors.white,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        backgroundColor: Shades.gray50,
      },
    },
    expandIcon: {
      transform: "none !important",
    },
  })(AccordionSummary);

  // Underlying MUI component exposes this type
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  if (!isLoading && (!history || history.length === 0)) {
    return <></>;
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleExpandChange} elevation={0} square>
      <AccordionSummaryNoExpandIconRotate
        className={orderCardClasses.accordionSummaryHeightOnExpand}
        expandIcon={
          <ArrowForwardIosOutlined
            classes={{ root: isExpanded ? orderCardClasses.expandedIcon : orderCardClasses.expandIcon }}
          />
        }
      >
        <Typography variant="body1" className={classNames({ [orderCardClasses.cardHeaderTextExpanded]: isExpanded })}>
          {t("order.history.cardLabel")}
        </Typography>
      </AccordionSummaryNoExpandIconRotate>
      <AccordionDetails classes={{ root: classes.detailsContainer }}>
        {isLoading && <CircularProgress />}
        {!isLoading && history && <OrderXHistory history={history} />}
        {!isLoading && !history && <Typography>{t("order.noData")}</Typography>}
      </AccordionDetails>
    </Accordion>
  );
};
