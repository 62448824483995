import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

interface IValidationContextType {
  requiredValidation: (value: any) => any;
}

const ValidationContext = createContext<IValidationContextType>({} as IValidationContextType);

export const ValidationProvider = (props: any) => {
  const value = useValidationProvider();
  return <ValidationContext.Provider value={value}>{props.children}</ValidationContext.Provider>;
};

export const useValidation = () => {
  return useContext(ValidationContext);
};

const useValidationProvider = (): IValidationContextType => {
  const { t } = useTranslation();
  const requiredMessage = t("general.form.required_field_warning.text");

  const requiredValidation = (value: any) => {
    if (value === undefined || value === null) {
      return requiredMessage;
    }

    return undefined;
  };

  return {
    requiredValidation,
  };
};
