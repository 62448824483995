import { Button, Grid } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { WizardStepHeader } from "../../../../collect-from-market/pages/order-new/components/wizard-step-heading.component";
import {
  IResponseAssignmentRowValue,
  ProductResponseAssignmentStep,
  useProductResponseAssignmentContext,
} from "../../../pages/product-response/product-response-assignment.provider";
import { CopyResponseAssignmentDialog } from "../dialog/copy-response-assignment-dialog.component";
import { ProductResponseOrderStatusFormContent } from "./product-response-order-status-form-content.component";

export const ProductResponseOrderStatusForm: VFC = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { setSelectedResponseAssignmentRowValues, setActiveStep } = useProductResponseAssignmentContext();

  const onDialogCancel = () => {
    setIsDialogOpen(false);
  };

  const onCopyAssignment = (values: IResponseAssignmentRowValue[]) => {
    setSelectedResponseAssignmentRowValues(values);
    setActiveStep(ProductResponseAssignmentStep.RESPONSE_FIELDS);
  };

  return (
    <>
      <CopyResponseAssignmentDialog open={isDialogOpen} onCancel={onDialogCancel} onCopyAssignment={onCopyAssignment} />
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <WizardStepHeader text={t("configuration.responseAssignments.create.orderStatusHeading")} />
            </Grid>
            <Grid item>
              <Button
                endIcon={<FileCopyOutlined />}
                color="primary"
                variant="outlined"
                onClick={() => setIsDialogOpen(true)}
              >
                {t("configuration.responseAssignments.create.copyConfig")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ProductResponseOrderStatusFormContent />
        </Grid>
      </Grid>
    </>
  );
};
