import { DefaultValues } from "react-hook-form";
import { BattGBrandStatus, IBattGBrand } from "../../../../models/batt-g/batt-g-brand";
import { IBattGBrandFormInputs } from "./brand-dialog/batt-g-brand.dialog";

export class BattGBrandUtil {
  public static brandDialogInitialValues(brand: IBattGBrand | undefined): DefaultValues<IBattGBrandFormInputs> {
    if (brand) {
      return {
        takeBackViaGrs: brand.takeBackViaGrs,
        name: brand.name,
        trademarkRegisterNumber: brand.trademarkRegisterNumber,
        startDate: brand.startDate,
        batteryType: brand.grsBatteryType,
        endDate: brand.endDate,
        takeBackCompanyName: brand.takeBackCompanyName,
        takeBackContactPersonEmail: brand.takeBackContactPersonEmail,
        takeBackContactPersonName: brand.takeBackContactPersonName,
        takeBackContactPersonTelephone: brand.takeBackContactPersonTelephone,
        takeBackDescription: brand.takeBackDescription,
      };
    } else {
      return {
        takeBackViaGrs: false,
        // @ts-ignore
        batteryType: null,
      };
    }
  }

  public static getBrandStatusTranslation(status: BattGBrandStatus, endDate?: Date | null): string {
    if (endDate) {
      return "batt_g.brand.status.deactivated";
    }
    switch (status) {
      case BattGBrandStatus.Draft:
        return "batt_g.brand.status.draft";
      case BattGBrandStatus.Published:
        return "batt_g.brand.status.published";
    }
  }
}
