import { useMutation } from "react-query";
import { RestEndpoints } from "../../../api/endpoints";
import getInstance from "../../../utils/rest";
import { IPomAnnouncementExcelExport } from "../../domain/announcements/pom-announcement-export";
import { PomQueryKeys } from "../pom-query-keys";

const exportExcelAnnouncements = async (exportData: IPomAnnouncementExcelExport): Promise<string> => {
  const { data } = await getInstance().post(RestEndpoints.PomAnnouncementExportExcel, exportData);
  return data;
};

export const useExportExcelAnnouncements = () => {
  return useMutation(PomQueryKeys.ExportExcelAnnouncements, (exportData: IPomAnnouncementExcelExport) =>
    exportExcelAnnouncements(exportData),
  );
};
