// Same content as OperationResult from core
export enum ApiErrorCodes {
  Error = "Error",
  NotFound = "NotFound",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",

  PomAnnouncementAlreadyExists = "PomAnnouncementAlreadyExists",
  PomAnnouncementUpdateConflict = "PomAnnouncementUpdateConflict",
  PomAnnouncementItemReductionNotAllowed = "PomAnnouncementItemReductionNotAllowed",
  PomAnnouncementInvalidInputCombination = "PomAnnouncementInvalidInputCombination",
  PomAnnouncementContractMissing = "PomAnnouncementContractMissing",
  PomAnnouncementPeriodMissing = "PomAnnouncementPeriodMissing",
  PomAnnouncementStatusError = "PomAnnouncementStatusError",
  PomAnnouncementExcelFileMissing = "PomAnnouncementExcelFileMissing",
  PomAnnouncementExcelWrongFileFormat = "PomAnnouncementExcelWrongFileFormat",
  PomAnnouncementExcelWrongFileContent = "PomAnnouncementExcelWrongFileContent",

  PomContractAlreadyExists = "PomContractAlreadyExists",
  CfmContractAlreadyExists = "CfmContractAlreadyExists",

  CfmInternalNumberAlreadyExists = "CfmInternalNumberAlreadyExists",
  CfmOrdersNotFound = "CfmOrdersNotFound",
  CfmNoRoutingAssignmentsFound = "CfmNoRoutingAssignmentsFound",

  GroupContractNumberNotAvailable = "GroupContractNumberNotAvailable",
}
