import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  IManufacturerDetailParams,
  ManufacturerDetailContext,
  ManufacturerDetailProvider,
} from "put-on-market/components/manufacturer-form/manufacturer-detail.provider";
import {
  ManufacturerForm,
  ManufacturerFormMode,
} from "put-on-market/components/manufacturer-form/manufacturer-form.component";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { AvailablePomRoutes } from "../../../utils/constants";
import { IPomManufacturer } from "../../domain/models/manufacturer/manufacturer";

interface IPomManufacturerDetailPageProps {
  mode: ManufacturerFormMode;
}

export const PomManufacturerDetailPage: FunctionComponent<IPomManufacturerDetailPageProps> = ({ mode }) => {
  const { id } = useParams<IManufacturerDetailParams>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSuccessCallback = (manufacturer: IPomManufacturer | undefined) => {
    enqueueSnackbar(t("general.successfull_saved"), { variant: "success" });
    if (manufacturer) {
      history.push(AvailablePomRoutes.ManufacturerEdit.replace(":id", manufacturer.id.toString()));
    }
  };

  return (
    <ManufacturerDetailProvider manufacturerId={id ? Number(id) : undefined}>
      <ManufacturerDetailContext.Consumer>
        {({ isLoading, manufacturer }) => (
          <>
            <PageTitle
              id={
                mode === ManufacturerFormMode.Detail
                  ? AvailablePomRoutes.ManufacturerDetail
                  : AvailablePomRoutes.ManufacturerEdit
              }
            />
            {isLoading && <LinearProgress />}
            {!isLoading && manufacturer && (
              <ManufacturerForm mode={mode} manufacturer={manufacturer} onSuccessCallback={onSuccessCallback} />
            )}
          </>
        )}
      </ManufacturerDetailContext.Consumer>
    </ManufacturerDetailProvider>
  );
};
