import { makeStyles } from "@material-ui/core";
import { Colors, PrimaryColors, Shades } from "./Colors";

export const useSidebarStyle = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${Shades.gray30}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listTitle: {
    "& .MuiListItemText-primary": {
      fontSize: 16,
    },
  },
  activeListTitle: {
    "& .MuiListItemText-primary": {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  menuListItemIcon: {
    marginLeft: 6,
    height: 28,
    width: 36,
    minWidth: 36,
    marginTop: 4,
    color: PrimaryColors.base,
  },
  listItemIcon: {
    height: 28,
    width: 28,
    minWidth: 36,
    marginLeft: 8,
    marginTop: 4,
    color: PrimaryColors.base,
  },
  expandUserMenuListItem: {
    borderTop: `1px solid ${Shades.gray30}`,
  },
  expandListItem: {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${Shades.gray30}`,
    borderRadius: 0,
    boxShadow: "none",
    paddingTop: 8,
    paddingBottom: 8,
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
    "&.MuiAccordion-root": {
      borderRadius: 0,
    },
    "&.MuiAccordion-root:before": {
      opacity: 0,
    },
  },
  expandedListItemContent: {
    backgroundColor: "transparent",
    paddingTop: 0,
    margin: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  summary: {
    "&.MuiAccordionSummary-root": {
      paddingTop: 8,
      paddingBottom: 8,
      height: 48,
      minHeight: 48,
    },
  },
  list: {
    width: "100%",
    paddingTop: 8,
    "&.MuiList-padding": {
      paddingTop: 8,
    },
  },
  expandIcon: {
    "&.MuiSvgIcon-colorPrimary": {
      color: Colors.greenLight,
    },
  },
  subListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 60,
    paddingRight: 0,
  },
}));
