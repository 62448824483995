import { useInfiniteQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderXList } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../../models/converter/order-x/cfm-order-x.converter";
import { ICfmOrderXFilterModel } from "../../../models/order-x/cfm-order-x-filter.model";
import { ICfmOrderXListModel } from "../../../models/order-x/cfm-order-x.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getInfiniteOrdersX = async (
  filter: ICfmOrderXFilterModel,
  pageParam: number = 0,
  pageSize: number = 5,
): Promise<ICfmOrderXList> => {
  const { query, status, hasPlannedDeliveryDate } = filter;

  const { data } = await getInstance().get<ICfmOrderXListModel>(RestEndpoints.CfmOrdersX, {
    params: {
      query: query === "" ? undefined : query,
      status,
      hasPlannedDeliveryDate,
      logisticAddressId: filter.logisticAddressId,
      destinationAddressId: filter.destinationAddressId,
      stateId: filter.stateId,
      typeId: filter.typeId,
      fractionId: filter.fractionId,
      packageId: filter.packageId,
      orderOfTruckloadSorting: filter.orderOfTruckloadSorting,
      assignedToTruckload: filter.assignedToTruckload,
      sortingMode: filter.sortingMode,
      page: pageParam,
      pageSize,
    },
  });

  return {
    items: data.items.map(CfmOrderXConverter.toDomain),
    meta: data.meta,
  };
};

export const useGetInfiniteOrdersXQuery = (filter: ICfmOrderXFilterModel, pageSize?: number, enabled?: boolean) => {
  return useInfiniteQuery(
    [CfmQueryKeys.GetOrdersXWithFilterInfinite, filter, pageSize],
    ({ pageParam }) => getInfiniteOrdersX(filter, pageParam, pageSize),
    {
      getNextPageParam: (lastPage) => {
        const hasNextPage = lastPage.meta.page < lastPage.meta.numberOfPages;
        if (hasNextPage) {
          return lastPage.meta.page + 1;
        }

        return undefined;
      },
      staleTime: ReactQueryConfig.staleTime,
      enabled: enabled ?? true,
    },
  );
};
