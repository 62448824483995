import { Paper } from "@material-ui/core";
import { FC } from "react";
import { useBoxStyle } from "../../style/useBox.style";

export const ErrorBox: FC = (props) => {
  const { children } = props;
  const { error } = useBoxStyle();

  return (
    <Paper className={error} elevation={0}>
      {children}
    </Paper>
  );
};
