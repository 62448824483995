import { CircularProgress, Grid, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  alignSpinner: {
    textAlign: "center",
    marginBottom: 20,
  },
}));

interface ILoadingSpinnerProps {
  isLoading: boolean;
}

export const LoadingSpinner: VFC<ILoadingSpinnerProps> = (props) => {
  const { isLoading } = props;
  const classes = useStyles();

  if (!isLoading) return null;

  return (
    <Grid container direction="row">
      <Grid item xs={12} className={classes.alignSpinner}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
