import { useMediaQuery, useTheme } from "@material-ui/core";

export const useScreenSize = () => {
  const theme = useTheme();
  // breakpoints defined by material ui default breakpoints
  // https://mui.com/material-ui/customization/breakpoints/
  // can be changed; https://mui.com/material-ui/customization/breakpoints/#custom-breakpoints
  const isDesktop = useMediaQuery(theme.breakpoints.only("xl"), { noSsr: true }); // > 1536px
  const isLaptop = useMediaQuery(theme.breakpoints.only("lg"), { noSsr: true }); // between 1200 and 1535px
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"), { noSsr: true }); // between 600 and 900px
  const isUpTablet = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true }); // > 900px
  const isDownTablet = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true }); // < 900px
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true }); // < 599px

  return {
    isDesktop,
    isLaptop,
    isTablet,
    isUpTablet,
    isDownTablet,
    isMobile,
  };
};
