import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FunctionComponent, useCallback, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { IPasswordResetData } from "../../../utils/PasswordHelper";
import { ResetPasswordStatus } from "../../domain/user/user";
import DefaultLayout from "../../layout/default.layout";
import { useResetPasswordQuery } from "../../repositories/queries/auth/mutation/reset-password.query";
import { AuthUtil } from "../../util/auth.util";
import { PasswordResetBody } from "./components/password-reset-body.component";
import { IPasswordResetFormInputs, PasswordResetForm } from "./components/password-reset-form.component";
import { PasswordResetHead } from "./components/password-reset-head.component";

export const PasswordResetPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { getUser, setIsPasswordResetRequired, internalUser } = useAuthContext();
  const { mutateAsync: resetPassword } = useResetPasswordQuery();

  const redirectUser = useCallback(() => {
    history.push(AuthUtil.getRedirectUrl());
  }, [history]);

  useEffect(() => {
    if (internalUser && !internalUser.mustResetPassword) {
      redirectUser();
    }
  }, [internalUser, redirectUser]);

  const handlePasswordReset: SubmitHandler<IPasswordResetFormInputs> = async ({ password, verificationPassword }) => {
    const request: IPasswordResetData = {
      normalPassword: password,
      validationPassword: verificationPassword,
      oldPassword: "",
    };
    const response = await resetPassword({ data: request, oldPasswordRequired: false });
    switch (response.status) {
      case ResetPasswordStatus.NoUser:
        enqueueSnackbar(t("user.reset.noUser"), { variant: "error" });
        break;
      case ResetPasswordStatus.Successful:
        enqueueSnackbar(t("user.reset.SuccessfullyReset"), { variant: "success" });
        break;
    }

    const user = await getUser();
    if (!user) return;
    await setIsPasswordResetRequired(user);
  };

  return (
    <DefaultLayout>
      <PasswordResetHead title={t("user.reset.password")} />
      <PasswordResetBody>
        <Typography variant="h1">{t("user.reset.password")}</Typography>
        <Typography variant="body1">{t("user.reset.required")}</Typography>
        <PasswordResetForm onSubmit={handlePasswordReset} />
      </PasswordResetBody>
    </DefaultLayout>
  );
};
