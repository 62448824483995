import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface ISimplePageTitleProps {
  titleMessageKey: string;
}

export const SimplePageTitle: FC<ISimplePageTitleProps> = (props) => {
  const { t } = useTranslation();
  const { titleMessageKey } = props;
  return <Typography variant="h1"> {t(titleMessageKey)} </Typography>;
};
