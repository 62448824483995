import React, { ReactNode, VFC } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LinearProgress } from "@material-ui/core";

interface ILoadingProps {
  size?: number;
  isLoading: boolean;
  children?: ReactNode;
  mode?: "linear" | "circular";
}

export const Loading: VFC<ILoadingProps> = (props) => {
  const { size, children, isLoading, mode = "circular" } = props;

  const getLoadingComponent = (): ReactNode => {
    switch (mode) {
      case "circular":
        return (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={size ?? 50} />
          </div>
        );
      case "linear":
        return <LinearProgress />;
    }
  };
  return (
    <>
      {isLoading && getLoadingComponent()}
      {!isLoading && children}
    </>
  );
};
