import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { FONT_H1, FONT_H5, HEADER_MARGIN_BOTTOM, HEADER_MARGIN_TOP } from "../../../config/theme/overrides/constant";

const useStyles = makeStyles((theme) => ({
  headerStyleMedium: {
    marginTop: HEADER_MARGIN_TOP,
    marginBottom: HEADER_MARGIN_BOTTOM,
  },
  headerStyleSmall: {
    marginTop: 5,
    marginBottom: 5,
  },
  fontSize: {
    fontSize: FONT_H1,
  },
  fontSizeShrink: {
    [theme.breakpoints.down("xs")]: {
      fontSize: FONT_H5,
    },
  },
}));

interface IFormHeaderProps {
  size?: "medium" | "small";
  doShrink?: boolean;
}

export const FormHeader: FC<IFormHeaderProps> = (props) => {
  const { children, size, doShrink } = props;
  const classes = useStyles();
  return (
    <Typography
      variant="h1"
      className={classNames(classes.fontSize, {
        [classes.headerStyleSmall]: size === "small",
        [classes.headerStyleMedium]: size === "medium",
        [classes.fontSizeShrink]: doShrink,
      })}
    >
      {children}
    </Typography>
  );
};
