import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { UserConverter } from "../../../domain/converter/user.converter";
import { IUser } from "../../../domain/user/user";
import { IUserModel } from "../../../models/user/user.model";
import { SharedQueryKeys } from "../shared-query-keys";

const getUser = async (userId: number): Promise<IUser> => {
  const { data } = await getInstance().get<IUserModel>(RestEndpoints.GetUser.replace(":id", String(userId)));
  return UserConverter.toDomain(data);
};

export const useGetUserQuery = (userId: number) => {
  return useQuery([SharedQueryKeys.GetUser, userId], () => getUser(userId), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
