import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderX } from "../../../../domain/order-x/cfm-order-x";
import { CfmOrderXConverter } from "../../../models/converter/order-x/cfm-order-x.converter";
import { ICfmOrderXModel } from "../../../models/order-x/cfm-order-x.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmOrderActionModel } from "../../../models/order-x/action/cfm-order-x-action.model";

interface IUpdateOrderXRequestData {
  action: ICfmOrderActionModel;
  orderId: number;
}

const updateOrderXQuery = async ({ action, orderId }: IUpdateOrderXRequestData): Promise<ICfmOrderX> => {
  const { data } = await getInstance().patch<ICfmOrderXModel>(
    RestEndpoints.CfmOrderX.replace(":id", orderId.toString()),
    action,
  );
  return CfmOrderXConverter.toDomain(data);
};

export const useUpdateOrderXQuery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation([CfmQueryKeys.UpdateOrderX], updateOrderXQuery, {
    onError: () => {
      enqueueSnackbar(t("notifications.order_saved_error.message"), {
        variant: "error",
      });
    },
    onSuccess: ({ id }) => {
      enqueueSnackbar(t("notifications.order_saved_success.message"), {
        variant: "success",
      });
      void queryClient.invalidateQueries(CfmQueryKeys.GetOrdersXWithFilterInfinite);
      void queryClient.invalidateQueries(CfmQueryKeys.GetOrdersXWithFilter);
      void queryClient.invalidateQueries(CfmQueryKeys.GetOrderXStatusCount);
      void queryClient.invalidateQueries([CfmQueryKeys.GetOrderXActions, id]);
      void queryClient.invalidateQueries(CfmQueryKeys.GetOrderXHistory);
      void queryClient.invalidateQueries(CfmQueryKeys.GetCfmOrderResponseReport);
    },
  });
};
