import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { IAddressLean } from "../../../../../shared/domain/address/address-lean";
import {
  getAddressBaseTextParts,
  getOpeningHoursTextForTooltip,
  trimOpeningHoursTextIfRequired,
} from "../../../../../utils/address.util";
import { TEXT_SEPARATOR } from "../../../../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  columnContainer: {
    height: "100%",
  },
}));

interface IAddressInfoBoxTextProps {
  address: IAddressLean;
}

export const AddressInfoBoxText: FunctionComponent<IAddressInfoBoxTextProps> = (props) => {
  const { address } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getMiscText = () => {
    const text = `${address.contactPersonName ?? "-"}${TEXT_SEPARATOR} ${
      address.contactPersonEmail ?? "-"
    }${TEXT_SEPARATOR} ${address.contactPersonTelephone ?? "-"}`;
    if (address.openingHours) {
      return `${text}${TEXT_SEPARATOR} ${trimOpeningHoursTextIfRequired(address.openingHours)}`;
    }

    return text;
  };

  return (
    <Grid container direction="column" justifyContent="center" className={classNames(classes.columnContainer)}>
      <Grid item>
        <Typography variant="body1">
          <b>{`(${address.id}) ${address.name}`}</b>
          {`${TEXT_SEPARATOR} ${getAddressBaseTextParts(address, t)}`}
        </Typography>
      </Grid>
      <Grid item>
        <NewOrderToolTip title={getOpeningHoursTextForTooltip(address.openingHours ?? undefined, t)}>
          <Typography variant="body1">{getMiscText()}</Typography>
        </NewOrderToolTip>
      </Grid>
    </Grid>
  );
};
