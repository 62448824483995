import { createContext, FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrorCodes } from "../../../api/api-error-codes";
import { ApiResponse, isSuccess } from "../../../api/api-response";
import { useDashboardActionContext } from "../../../shared/pages/dashboard/dashboard-action.provider";
import { IPomAnnouncementExcelDataModel } from "../../repositories/models/announcements/pom-announcement-excel-data.model";
import { useGetAnnouncementTemplateQuery } from "../../repositories/queries/announcements/get-announcement-template.query";
import { useExtractAnnouncementTemplateDataQuery } from "../../repositories/queries/mutations/announcements/extract-announcement-template-data.query";

interface IPomAnnouncementExcelUploadProvider {
  dialogState: PomAnnouncementExcelUploadDialogState;
  extractionResult: ApiResponse<IPomAnnouncementExcelDataModel> | undefined;
  resetDialog(): void;
  isExtractionValid(): boolean;
  onFileSelected(file: File | undefined): Promise<void>;
  getErrorTextByErrorCode(errorCode: string | undefined): string;
  downloadAnnouncementTemplate: (lang: string) => void;
}

export enum PomAnnouncementExcelUploadDialogState {
  Initial,
  Loading,
  ShowResults,
}

export const PomAnnouncementExcelUploadContext = createContext<IPomAnnouncementExcelUploadProvider>(
  {} as IPomAnnouncementExcelUploadProvider,
);

export const usePomAnnouncementExcelUploadContext = () => {
  return useContext(PomAnnouncementExcelUploadContext);
};

const usePomAnnouncementExcelUploadProvider = (): IPomAnnouncementExcelUploadProvider => {
  const [extractionResult, setExtractionResult] = useState<ApiResponse<IPomAnnouncementExcelDataModel> | undefined>();
  const [dialogState, setDialogState] = useState<PomAnnouncementExcelUploadDialogState>(
    PomAnnouncementExcelUploadDialogState.Initial,
  );
  const { t } = useTranslation();
  const { contractId } = useDashboardActionContext();
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  const { mutateAsync: extractAnnouncementTemplateData } = useExtractAnnouncementTemplateDataQuery();
  const { data: announcementTemplate, refetch: refetchTemplate } = useGetAnnouncementTemplateQuery(contractId, selectedLanguage);

  const resetDialog = (): void => {
    setExtractionResult(undefined);
    setTimeout(() => setDialogState(PomAnnouncementExcelUploadDialogState.Initial), 500);
  };

  const isExtractionValid = (): boolean => {
    return !!extractionResult && isSuccess(extractionResult) && extractionResult.data.invalidItems.length === 0;
  };

  const onFileSelected = async (file: File | undefined): Promise<void> => {
    if (!file || !contractId) {
      return;
    }
    setDialogState(PomAnnouncementExcelUploadDialogState.Loading);
    const result = await extractAnnouncementTemplateData({ file, contractId });
    setExtractionResult(result);
    setDialogState(PomAnnouncementExcelUploadDialogState.ShowResults);
  };

  const getErrorTextByErrorCode = (errorCode: string | undefined): string => {
    switch (errorCode) {
      case ApiErrorCodes.PomAnnouncementExcelWrongFileFormat:
        return t("pom.announcements.template.upload.dialog.error.wrong_file_type");
      case ApiErrorCodes.PomAnnouncementExcelWrongFileContent:
        return t("pom.announcements.template.upload.dialog.error.wrong_file_content");
      default:
        return t("pom.announcements.template.upload.dialog.error.generic");
    }
  };

  const downloadAnnouncementTemplate = async (lang: string) => {
    setSelectedLanguage(lang);
    if (announcementTemplate && announcementTemplate.data && lang === announcementTemplate.lang) {
      await refetchTemplate();
    }
  };

  return {
    downloadAnnouncementTemplate,
    extractionResult,
    dialogState,
    resetDialog,
    isExtractionValid,
    onFileSelected,
    getErrorTextByErrorCode,
  };
};

export const PomAnnouncementExcelUploadProvider: FunctionComponent = (props) => {
  const value = usePomAnnouncementExcelUploadProvider();
  return (
    <PomAnnouncementExcelUploadContext.Provider value={value}>
      {props.children}
    </PomAnnouncementExcelUploadContext.Provider>
  );
};
