import { useMutation } from "react-query";
import { getErrorStatusCode } from "../../../../../api/api-response";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { IPomAddAnnouncementModel } from "../../../models/announcements/pom-add-announcement.model";
import { IPomAnnouncementModel } from "../../../models/announcements/pom-announcements.model";
import { PomQueryKeys } from "../../../pom-query-keys";

export interface ICreateAnnouncementResult {
  announcement: IPomAnnouncementModel | undefined;
  status: number;
}

const createAnnouncement = async (request: IPomAddAnnouncementModel): Promise<ICreateAnnouncementResult> => {
  try {
    const result = await getInstance().post<IPomAnnouncementModel>(RestEndpoints.PomAnnouncements, request);
    return { announcement: result.data, status: result.status };
  } catch (error: any) {
    const code = getErrorStatusCode(error);
    return { announcement: undefined, status: code };
  }
};

export const useCreateAnnouncementQuery = () => {
  return useMutation([PomQueryKeys.CreateAnnouncement], ({ request }: { request: IPomAddAnnouncementModel }) =>
    createAnnouncement(request),
  );
};
