import { Card, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useMemo, VFC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CancelButton, CancelButtonOutlined, ForwardButton, SaveButton } from "../../../../components/Primitives";
import { useAuthContext } from "../../../../providers/Auth/auth.provider";
import { DRAWER_OPEN } from "../../../../shared/domain/sidebar/sidebar";
import { useSidebarContext } from "../../../../shared/domain/sidebar/sidebar-context";
import { isCfmLogistics, isCfmRecycler } from "../../../../shared/domain/user/user";
import { PrimaryColors } from "../../../../style/Colors";
import { OrderScrollToTopButton } from "../../../pages/order-x/components/order-scroll-to-top-button.component";
import { TruckloadState } from "../../../domain/truckload/truckload-state";

const useStyles = makeStyles((theme: Theme) => ({
  footerStyle: {
    bottom: 0,
    left: 0,
    position: "fixed",
    zIndex: 600,
    minHeight: 100,
  },
  footerWidthSidebarOpen: {
    width: `calc(100% - ${DRAWER_OPEN}px)`,
  },
  footerWidthSidebarClosed: {
    width: "100%",
  },
  containerPadding: {
    padding: "20px 20px 0 20px",
  },
  weightText: {
    color: PrimaryColors.base,
  },
  buttonContainer: {
    "& > .MuiGrid-item": {
      marginRight: 10,
      marginBottom: 24,
    },
    "& > .MuiGrid-item:last-child": {
      marginRight: 0,
    },
  },
}));

interface IEditTruckloadFooterViewProps {
  continueActionsDisabled: boolean;
  onCancel: VoidFunction;
  onCancelTruckload: VoidFunction;
  onSave: VoidFunction;
  onRespondTruckload: VoidFunction;
  weight: number;
  truckloadState: TruckloadState;
}

export const EditTruckloadFooterView: VFC<IEditTruckloadFooterViewProps> = (props) => {
  const { onCancel, onCancelTruckload, onSave, onRespondTruckload, continueActionsDisabled, weight, truckloadState } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { internalUser } = useAuthContext();
  const { sidebarOpen } = useSidebarContext();

  const displayRespondOrSubmitButton = useMemo(() => {
    return (
      (isCfmRecycler(internalUser) && truckloadState !== TruckloadState.Confirmed) ||
      (isCfmLogistics(internalUser) && truckloadState === TruckloadState.Draft)
    );
  }, [internalUser, truckloadState]);

  const isTruckloadCompleted = truckloadState === TruckloadState.Confirmed;
  const showCancelButton = !isTruckloadCompleted && isCfmLogistics(internalUser);
  const weightString = weight.toLocaleString();
  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.footerStyle, {
        [classes.footerWidthSidebarOpen]: sidebarOpen,
        [classes.footerWidthSidebarClosed]: !sidebarOpen,
      })}
    >
      <Grid item>
        <OrderScrollToTopButton />
      </Grid>
      <Grid item>
        <Card raised elevation={20}>
          <Grid container direction="column" className={classes.containerPadding} spacing={1}>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignContent="center">
                <Grid item>
                  <Typography variant="body1" className={classes.weightText}>
                    <Trans i18nKey="orders.truckload.truckloadWeight">{{ weight: weightString }}</Trans>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                {showCancelButton && (
                  <Grid item xs={12} sm={3} justifyContent={"flex-start"}>
                    <CancelButton onClick={onCancelTruckload}>
                      <Typography>{t("cancel")}</Typography>
                    </CancelButton>
                  </Grid>
                )}
                <Grid
                  container
                  sm={showCancelButton ? 9 : 12}
                  justifyContent={"flex-end"}
                  className={classes.buttonContainer}
                >
                  <Grid item>
                    <CancelButtonOutlined onClick={onCancel} variant="outlined">
                      <Typography>{t("general.cancel.text")}</Typography>
                    </CancelButtonOutlined>
                  </Grid>
                  {!isTruckloadCompleted && (
                    <Grid item>
                      <SaveButton onClick={onSave} disabled={continueActionsDisabled}>
                        <Typography>{t("general.change")}</Typography>
                      </SaveButton>
                    </Grid>
                  )}
                  {displayRespondOrSubmitButton && (
                    <Grid item>
                      <ForwardButton onClick={onRespondTruckload} disabled={continueActionsDisabled}>
                        <Typography>
                          {isCfmRecycler(internalUser) ? t("orders.truckload.respond") : t("orders.truckload.submit")}
                        </Typography>
                      </ForwardButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
