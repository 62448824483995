import { useMutation } from "react-query";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import getInstance from "../../../../utils/rest";
import { GroupConverter } from "../../../domain/converter/group.converter";
import { IGroup } from "../../../domain/group/group";
import { IGroupModel } from "../../../models/group/group.model";
import { SharedQueryKeys } from "../shared-query-keys";

const updateGroupActive = async (id: number, isActive: boolean): Promise<IGroup> => {
  const url = replaceUriParameter(RestEndpoints.UpdateGroupActive, "id", id);
  const { data } = await getInstance().put<IGroupModel>(url, {
    active: isActive,
  });
  return GroupConverter.toDomain(data);
};

export const useUpdateGroupActive = () => {
  return useMutation(SharedQueryKeys.UpdateGroupActive, ({ id, isActive }: { id: number; isActive: boolean }) =>
    updateGroupActive(id, isActive),
  );
};
