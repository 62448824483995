import _ from "lodash";
import { ICfmOrderX } from "./../../../collect-from-market/domain/order-x/cfm-order-x";
import { TranslateFunc } from "utils/translation.utils";
import { CfmOrderXApiStatus } from "../../../collect-from-market/domain/order-x/cfm-order-x.status";
import { ICfmProduct } from "../../../collect-from-market/domain/products/cfm-product";
import { ICfmProductCategory } from "../../../collect-from-market/domain/products/cfm-product-category";
import { ICfmProductState } from "../../../collect-from-market/domain/products/cfm-product-state";
import { ICfmProductType } from "../../../collect-from-market/domain/products/cfm-product-type";
import { ARTICLE_SEPARATOR } from "../../../utils/constants";
import { removeNullAndUndefined } from "../../../utils/filter.util";
import { ICfmProductFraction } from "./../../../collect-from-market/domain/products/cfm-product-fraction";
import { IConfigurationOption } from "./product-configuration.component";
import { IProductData } from "./product-list/creatable-products-list.component";
import { AvailablePlatform } from "../../../providers/Auth/platform.provider";

export enum CfmProductArticleType {
  Product = "Product",
  SingleOrderProduct = "SingleOrderProduct",
}

export interface IProductStateWithoutCategory {
  fraction: ICfmProductFraction;
  type: ICfmProductType;
  state: ICfmProductState;
  id: number;
}

export interface IProductConfigFilter {
  platform: AvailablePlatform.Cfm | AvailablePlatform.Pom | undefined;
  articleType: CfmProductArticleType | undefined;
  categories: ICfmProductCategory[] | undefined;
  fractions: ICfmProductFraction[] | undefined;
  states: ICfmProductState[] | undefined;
  types: ICfmProductType[] | undefined;
  orderStatus: CfmOrderXApiStatus[] | undefined;
}

export const EMPTY_PRODUCT_CONFIG_FILTER: IProductConfigFilter = {
  platform: AvailablePlatform.Cfm,
  articleType: undefined,
  categories: undefined,
  fractions: undefined,
  states: undefined,
  types: undefined,
  orderStatus: undefined,
};

export const getProductArticleTypeFilterOptions = (
  types: CfmProductArticleType[],
  t: TranslateFunc,
): IConfigurationOption[] => {
  const options: IConfigurationOption[] = [];

  types.forEach((type) => {
    options.push({
      value: type.toString(),
      label:
        type === CfmProductArticleType.Product ? t("configuration.product") : t("configuration.singleOrderProduct"),
    });
  });

  return options;
};

export const getPlatformFilterOptions = (
  platforms: AvailablePlatform[],
  t: TranslateFunc,
  pomEnabled: boolean,
): IConfigurationOption[] => {
  const options: IConfigurationOption[] = [];

  platforms.forEach((platform) => {
    options.push({
      value: platform.toString(),
      label: platform === AvailablePlatform.Cfm ? t("basedata.group.cfm_heading") : t("basedata.group.pom_heading"),
    });
  });

  return !pomEnabled ? options.filter((option) => option.value !== AvailablePlatform.Pom) : options;
};

export const getProductConfigFilterOptions = (
  productConfigParts:
    | ICfmProductCategory[]
    | ICfmProductType[]
    | ICfmProductState[]
    | ICfmProductFraction[]
    | undefined,
): IConfigurationOption[] => {
  if (!productConfigParts) return [];

  return productConfigParts
    .sort(
      (p1, p2) =>
        (p1.internalNumber ? Number(p1.internalNumber) : 0) - (p2.internalNumber ? Number(p2.internalNumber) : 0),
    )
    .map((part) => {
      const internalNumber = part.internalNumber ? `${part.internalNumber} ` : "";
      return {
        value: part.id.toString(),
        label: `${internalNumber}${part.name}`,
      };
    });
};

function cartesianProduct<T>(...allEntries: T[][]): T[][] {
  return allEntries.reduce<T[][]>(
    (results, entries) =>
      results
        .map((result) => entries.map((entry) => [...result, entry]))
        .reduce((subResults, result) => [...subResults, ...result], []),
    [[]],
  );
}

const getProductDataFromConfig = (
  filter: IProductConfigFilter,
  articleType: CfmProductArticleType,
  categoryId: number,
  fractionId: number,
  typeId: number,
  stateId: number,
): IProductData | undefined => {
  const category = (filter.categories ?? []).find((cat) => cat.id === categoryId);
  const fraction = (filter.fractions ?? []).find((fraction) => fraction.id === fractionId);
  const type = (filter.types ?? []).find((type) => type.id === typeId);
  const state = (filter.states ?? []).find((state) => state.id === stateId);

  if (!category || (filter.articleType === CfmProductArticleType.Product && !fraction) || !type || !state)
    return undefined;

  return {
    platform: AvailablePlatform.Cfm,
    articleType,
    category,
    fraction,
    type,
    state,
    exists: false,
    selected: false,
  };
};

export const generateProductData = (filter: IProductConfigFilter): IProductData[] => {
  if (!filter.articleType) {
    return [];
  }
  const articleType = filter.articleType;
  const categoryIds = (filter.categories ?? []).map((cat) => cat.id);
  const fractionIds =
    articleType === CfmProductArticleType.SingleOrderProduct
      ? [0]
      : (filter.fractions ?? []).map((fraction) => fraction.id);
  const typeIds = (filter.types ?? []).map((type) => type.id);
  const stateIds = (filter.states ?? []).map((state) => state.id);
  const finishedCartesianProduct = cartesianProduct(categoryIds, fractionIds, typeIds, stateIds);

  const productData: (IProductData | undefined)[] = [];
  for (const productConfig of finishedCartesianProduct) {
    productData.push(
      getProductDataFromConfig(
        filter,
        articleType,
        productConfig[0],
        productConfig[1],
        productConfig[2],
        productConfig[3],
      ),
    );
  }

  return productData.filter(removeNullAndUndefined);
};

export const getProductArticleNumberAndName = (product: ICfmProduct) => {
  return `${product.articleNumber} ${ARTICLE_SEPARATOR} ${product.name}`;
};

export const getArticleNumber = (productData: IProductData) => {
  return `${productData.category.internalNumber ?? "00"}${productData.fraction?.internalNumber ?? "00"}${
    productData.type.internalNumber ?? "00"
  }${productData.state.internalNumber ?? "00"}`;
};

export const getProductArticleNumberAndNameFromProductData = (productData: IProductData) => {
  return `${productData.category.name ?? ""} ${ARTICLE_SEPARATOR} ${
    productData.fraction?.name ? productData.fraction.name + ` ${ARTICLE_SEPARATOR} ` : ""
  }  ${productData.type.name ?? ""} ${ARTICLE_SEPARATOR} ${productData.state.name ?? ""}`;
};

export const hasRouting = (product: ICfmProduct) => {
  return (product.assignments?.length ?? 0) > 0;
};

export const hasPackages = (product: ICfmProduct) => {
  return (product.packages?.length ?? 0) > 0;
};

export const hasResponse = (product: ICfmProduct) => {
  return (product.responseAssignments?.length ?? 0) > 0;
};

export const productsToStateWithoutCategory = (products: ICfmProduct[]): IProductStateWithoutCategory[] => {
  const productStates = products
    .map((product) => {
      if (!product.fraction || !product.type || !product.state || !product.id) return undefined;
      return {
        fraction: product.fraction,
        type: product.type,
        state: product.state,
        id: product.id,
      };
    })
    .filter(removeNullAndUndefined)
    .sort((s1, s2) => s1.id - s2.id);

  // get only unique fraction, type and state
  return _.uniqBy(
    productStates,
    (productState) => `${productState.fraction.id}-${productState.state.id}-${productState.type.id}`,
  );
};

export const getProductNameWithoutCategory = (
  fraction: ICfmProductFraction,
  type: ICfmProductType,
  state: ICfmProductState,
): string => {
  return `${fraction.name} ${ARTICLE_SEPARATOR} ${type.name} ${ARTICLE_SEPARATOR} ${state.name}`;
};

export const getProductOfOrder = (order: ICfmOrderX) => {
  return order.items.find((item) => item.product)?.product;
};
