import { replaceUriParameter } from "api/endpoints";
import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import getInstance from "../../../../../utils/rest";
import { ICfmOrderXTicketsModel } from "../../../models/order-x/cfm-order-x-tickets.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getOrderTickets = async (orderId: number): Promise<ICfmOrderXTicketsModel[]> => {
  const url = replaceUriParameter(RestEndpoints.CfmOrderXTickets, "id", orderId);
  const { data } = await getInstance().get<ICfmOrderXTicketsModel[]>(url);

  return data;
};

export const useGetOrderXTickets = (orderId: number) => {
  return useQuery([CfmQueryKeys.GetOrderTickets], () => getOrderTickets(orderId));
};
