import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowForwardIosOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { ChangeEvent, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { IAddress } from "../../../../shared/domain/address/address";
import { PrimaryColors, Shades } from "../../../../style/Colors";
import { TruckloadInfoBoxContent } from "./truckload-info-box-content.component";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";

const accordionMinHeight = 50;
const useStyles = makeStyles((theme: Theme) => ({
  cardHeaderTextExpanded: {
    fontWeight: "bold",
  },
  cardHeaderText: {
    color: PrimaryColors.base,
  },
  expandIcon: {
    color: PrimaryColors.base,
    height: 30,
    width: 30,
  },
  expandedIcon: {
    transform: "rotate(90deg)",
    color: PrimaryColors.base,
    height: 30,
    width: 30,
  },
  detailsContainer: {
    backgroundColor: Shades.gray20,
    padding: "0px 16px 16px 16px",
  },
  accordionSummaryHeightOnExpand: {
    "&.Mui-expanded": {
      minHeight: accordionMinHeight,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "auto",
    },
  },
}));

interface ITruckloadInfoBoxMobileViewProps {
  truckload: ICfmTruckloadWithOrders;
  targetAddress: IAddress | undefined;
  plannedDeliveryDate: Date | undefined;
}

export const TruckloadInfoBoxMobileView: VFC<ITruckloadInfoBoxMobileViewProps> = (props) => {
  const { truckload, targetAddress, plannedDeliveryDate } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const AccordionSummaryNoExpandIconRotate = withStyles({
    root: {
      minHeight: accordionMinHeight,
      backgroundColor: Shades.gray10,
      "&.MuiAccordionSummary-root.Mui-expanded": {
        backgroundColor: Shades.gray20,
      },
    },
    expandIcon: {
      transform: "none !important",
    },
  })(AccordionSummary);

  // Underlying MUI component exposes this type
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleExpandChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleExpandChange} elevation={0} square>
      <AccordionSummaryNoExpandIconRotate
        className={classes.accordionSummaryHeightOnExpand}
        expandIcon={
          <ArrowForwardIosOutlined classes={{ root: isExpanded ? classes.expandedIcon : classes.expandIcon }} />
        }
      >
        <Typography
          variant="body1"
          className={classNames(classes.cardHeaderText, { [classes.cardHeaderTextExpanded]: isExpanded })}
        >
          {t("orders.truckload.details")}
        </Typography>
      </AccordionSummaryNoExpandIconRotate>
      <AccordionDetails classes={{ root: classes.detailsContainer }}>
        <TruckloadInfoBoxContent
          truckload={truckload}
          targetAddress={targetAddress}
          plannedDeliveryDate={plannedDeliveryDate}
          contentPadding={false}
        />
      </AccordionDetails>
    </Accordion>
  );
};
