import React, { FC, useState } from "react";
import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { CustomRadio } from "../radio/custom-radio.component";
import { PrimaryColors } from "../../../style/Colors";
import { TruckloadSortingAscIcon, TruckloadSortingDescIcon } from "../../../components/Primitives";
import { SortingMode } from "../../../collect-from-market/repositories/models/truckload/cfm-truckload-sort.model";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: PrimaryColors.base,
    cursor: "pointer",
  },
}));

export interface ISortComponentOption {
  label: string;
  checked: boolean;
  value: string;
  onChange: (checked: boolean) => void;
}

interface ISortComponentProps {
  onSortIconClick?: VoidFunction;
  options: ISortComponentOption[];
  initialSortIconState?: SortingMode;
}

export const SortComponent: FC<ISortComponentProps> = (props) => {
  const { onSortIconClick, options, initialSortIconState } = props;
  const classes = useStyles();

  const [sortIconState, setSortIconState] = useState<SortingMode>(initialSortIconState ?? "DESC");

  const handleSortIconClicked = (): void => {
    if (!onSortIconClick) return;

    setSortIconState(sortIconState === "ASC" ? "DESC" : "ASC");
    onSortIconClick();
  };

  return (
    <Box display="flex" alignItems={"center"}>
      <Grid container direction="row" alignItems={"center"} spacing={4}>
        {onSortIconClick && (
          <Grid item xs={12} sm={1} md="auto" onClick={handleSortIconClicked}>
            <Box display="flex" alignItems="center" className={classes.icon}>
              {sortIconState === "ASC" && <TruckloadSortingAscIcon />}
              {sortIconState === "DESC" && <TruckloadSortingDescIcon />}
            </Box>
          </Grid>
        )}
        {options.map((option) => {
          return (
            <Grid item xs={12} sm={5} md="auto" key={option.value}>
              <CustomRadio
                label={option.label}
                checked={option.checked}
                value={option.value}
                onChange={option.onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
