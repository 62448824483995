import { createContext, ReactNode, useContext, useState } from "react";
import { GroupTypeFilterValues } from "../../shared/components/group/group-filter/group-type-filter.component";
import { IUsersFilter } from "../../shared/repositories/queries/user/get-users.query";

interface IUsersFilterProps {
  children: ReactNode;
  groupTypes?: GroupTypeFilterValues[];
}

interface IUsersFilterContext {
  filters: IUsersFilter;
  setFilters: (filters: IUsersFilter) => void;
}

export const UsersFilterContext = createContext<IUsersFilterContext>({} as IUsersFilterContext);

export const useUsersFilterContext = () => {
  return useContext(UsersFilterContext);
};

const useUsersFilterProvider = (props: IUsersFilterProps): IUsersFilterContext => {
  const [filters, setFilters] = useState<IUsersFilter>({
    active: undefined,
    searchText: undefined,
    groupTypes: props.groupTypes ?? [],
  });

  return {
    filters,
    setFilters,
  };
};

export const UsersFilterProvider = (props: IUsersFilterProps) => {
  const value = useUsersFilterProvider(props);
  return <UsersFilterContext.Provider value={value}>{props.children}</UsersFilterContext.Provider>;
};
