import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useMemo, useState, VFC } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useGetProductsByIdsQuery } from "../../../collect-from-market/repositories/queries/product/query/get-products-by-ids.query";
import { useCreateProductRoutingAssignmentsQuery } from "../../../collect-from-market/repositories/queries/routing-assignment/create-routing-assignments.query";
import { ForwardButton } from "../../../components/Primitives";
import { useCustomForm } from "../../../shared/util/form.util";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import { ConfigurationCreatedDialog } from "../dialog/configuration-created-dialog.component";
import { ProductDataDisplay } from "../product/product-data-display.component";
import { ProductRoutingAddressesForm } from "./form/product-routing-addresses-form.component";
import { ProductRoutingPostalForm } from "./form/product-routing-postal-form.component";

export interface ICreateProductRoutingFormInputs {
  postals: string[];
  primaryStartAddressId: number;
  primaryTargetAddressId: number;
  secondaryStartAddressId: number;
  secondaryTargetAddressId: number;
  optionalPrimaryAddressIds: number[] | undefined;
  optionalSecondaryAddressIds: number[] | undefined;
}

interface ICreateProductRoutingContentProps {
  productIds: number[];
  initialValues?: Partial<ICreateProductRoutingFormInputs>;
}

export const CreateProductRoutingContent: VFC<ICreateProductRoutingContentProps> = (props) => {
  const { productIds, initialValues } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const hasValidInitialValues = initialValues !== undefined && (initialValues.postals?.length ?? 0) > 0;
  const [hasPostalsSelected, setHasPostalsSelected] = useState(hasValidInitialValues);
  const [hasAddressesSelected, setHasAddressesSelected] = useState(false);
  const [isCreateSuccessDialogOpen, setIsCreateSuccessDialogOpen] = useState(false);

  const formMethods = useCustomForm<ICreateProductRoutingFormInputs>({ defaultValues: initialValues, mode: "all" });
  const {
    handleSubmit,
    formState: { errors },
    trigger,
  } = formMethods;

  const { isLoading: isGetProductsByIdLoading, data: products } = useGetProductsByIdsQuery(productIds);
  const { isLoading: isCreateProductRoutingAssignmentsLoading, mutateAsync: createProductRoutingAssignments } =
    useCreateProductRoutingAssignmentsQuery();

  const isLoading = useMemo(() => {
    return isGetProductsByIdLoading || isCreateProductRoutingAssignmentsLoading;
  }, [isGetProductsByIdLoading, isCreateProductRoutingAssignmentsLoading]);

  const buttonLabel = useMemo(() => {
    if (!hasPostalsSelected && !hasAddressesSelected) {
      return t("configuration.assignments.create.assignRouting");
    } else if (hasPostalsSelected && !hasAddressesSelected) {
      return t("configuration.assignments.create.showRoutingConfig");
    }

    return t("configuration.assignments.create.submit");
  }, [hasPostalsSelected, hasAddressesSelected, t]);

  const onSubmit: SubmitHandler<ICreateProductRoutingFormInputs> = async (inputs): Promise<void> => {
    await createProductRoutingAssignments({ productIds, createData: inputs });
    setIsCreateSuccessDialogOpen(true);
  };

  const onNextButtonClicked = async () => {
    await trigger();
    const hasErrors = errors && Object.keys(errors).length > 0;
    if (hasErrors) return;

    if (!hasPostalsSelected) {
      setHasPostalsSelected(true);
      return;
    }

    if (!hasAddressesSelected) {
      setHasAddressesSelected(true);
      return;
    }

    if (hasPostalsSelected && hasAddressesSelected) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <ConfigurationCreatedDialog
        open={isCreateSuccessDialogOpen}
        onAccept={() => history.push(AvailableConfiguratorRoutes.ProductOverview)}
        title={t("configuration.assignments.create.success")}
        acceptText={t("configuration.assignments.create.toOverview")}
        hasPackages
        hasRouting
        fullWidth={false}
      />
      <Grid container direction="column" spacing={4}>
        {isLoading && (
          <Grid item>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <ProductDataDisplay
            products={products ?? []}
            onEditClick={() => {
              history.push(AvailableConfiguratorRoutes.CreateProductRouting);
            }}
            canEdit={!hasValidInitialValues}
          />
        </Grid>
        <Grid item>
          <ProductRoutingPostalForm
            isSummary={hasPostalsSelected}
            canEdit={!hasValidInitialValues}
            onEditClick={() => {
              setHasAddressesSelected(false);
              setHasPostalsSelected(false);
            }}
          />
        </Grid>
        {hasPostalsSelected && (
          <Grid item>
            <ProductRoutingAddressesForm
              isSummary={hasAddressesSelected}
              onEditClick={() => {
                setHasAddressesSelected(false);
              }}
            />
          </Grid>
        )}
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <ForwardButton onClick={onNextButtonClicked}>
              <Typography variant="body1">{buttonLabel}</Typography>
            </ForwardButton>
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
