import { Backdrop, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import { VFC } from "react";
import { useUpdateCfmProductActiveQuery } from "../../../../collect-from-market/repositories/queries/product/mutation/update-product-active.query";
import { CustomSwitch } from "../../../../shared/components/switch/custom-switch.component";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

interface IProductActiveToggleProps {
  productId: number;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}

export const ProductActiveToggle: VFC<IProductActiveToggleProps> = (props) => {
  const { productId, isActive, setIsActive } = props;
  const classes = useStyles();
  const { isLoading, mutateAsync: updateProductActive } = useUpdateCfmProductActiveQuery();

  const handleChange = async (checked: boolean) => {
    setIsActive(checked);
    await updateProductActive({ productId, isActive: checked });
  };

  return (
    <>
      <Backdrop open={isLoading} className={classes.backdrop} transitionDuration={0}>
        <CircularProgress />
      </Backdrop>
      <CustomSwitch value={isActive} onChange={(e) => handleChange(e.target.checked)} />
    </>
  );
};
