import React from "react";
import { useAuthContext } from "../../../providers/Auth/auth.provider";
import { AvailablePlatform, usePlatformContext } from "../../../providers/Auth/platform.provider";
import getConfigAdminNavItems from "../navigation/config-admin-navigation-items";
import { isAdmin } from "../user/user";
import { SidebarMenuItem } from "./sidebar-menu-item.component";

export const SidebarConfigMenu: React.VFC = () => {
  const { internalUser } = useAuthContext();
  const { activePlatform } = usePlatformContext();

  const isConfigPlatform = () => activePlatform === AvailablePlatform.Config;
  const adminNavItems = getConfigAdminNavItems();

  if (!isConfigPlatform() || !isAdmin(internalUser)) return null;

  return (
    <>
      {adminNavItems
        .filter((item) => item.visible && item.content)
        .map((item, index) => {
          return (
            <SidebarMenuItem key={`config-${item.to}-${index}`} name="admin" navItem={item} menuItem={item.label} />
          );
        })}
    </>
  );
};
