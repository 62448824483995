import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import { AddressConverter } from "../../../../../shared/domain/converter/address.converter";
import getInstance from "../../../../../utils/rest";
import { ICfmProductRoutingAssignmentResponse } from "../../../../domain/routing-assignment/cfm-product-routing-assignment";
import { ICfmProductRoutingAssignmentResponseModel } from "../../../models/routing-assignment/cfm-product-routing-assignment.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

type errorFunction = (err: unknown) => void;

const getOrderLogisticRouting = async (
  orderId: number,
  alternative: boolean,
): Promise<ICfmProductRoutingAssignmentResponse> => {
  const url = RestEndpoints.CfmOrderLogisticRouting.replace(":id", orderId.toString());
  const { data } = await getInstance().get<ICfmProductRoutingAssignmentResponseModel>(url, {
    params: {
      alternative,
    },
  });

  return {
    postal: data.postal,
    address: AddressConverter.toDomain(data.address),
  };
};

export const useGetOrderLogisticRoutingQuery = (orderId: number, alternative: boolean, onError?: errorFunction) => {
  return useQuery(
    [CfmQueryKeys.GetOrderLogisticRouting, orderId, alternative],
    () => getOrderLogisticRouting(orderId, alternative),
    {
      staleTime: ReactQueryConfig.staleTime,
      onError,
    },
  );
};
