import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmWarehouseProduct } from "../../../../domain/products/cfm-warehouse-product";
import { CfmProductConverter } from "../../../models/converter/cfm-product.converter";
import { ICfmWarehouseProductListModel } from "../../../models/product/cfm-warehouse-product.model";
import { CfmQueryKeys } from "../../cfm-query-keys";

const getWarehouseItems = async (warehouseId: number | undefined): Promise<ICfmWarehouseProduct[] | undefined> => {
  if (!warehouseId) return undefined;
  const { data } = await getInstance().get<ICfmWarehouseProductListModel>(
    RestEndpoints.Warehouse.replace(":id", warehouseId.toString()),
  );
  return data.items.map(CfmProductConverter.warehouseToDomain);
};

export const useGetWarehouseItems = (warehouseId: number | undefined, enabled: boolean) => {
  return useQuery([CfmQueryKeys.GetWarehouseItems, warehouseId], () => getWarehouseItems(warehouseId), {
    staleTime: ReactQueryConfig.staleTime,
    enabled
  });
};
