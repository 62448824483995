import _ from "lodash";
import { createContext, useContext, useMemo, useState } from "react";
import { getProductOfOrder } from "../../../../../../configurator/components/product/product.utils";
import { IAddressLean } from "../../../../../../shared/domain/address/address-lean";
import { AddressConverter } from "../../../../../../shared/domain/converter/address.converter";
import { IGroup } from "../../../../../../shared/domain/group/group";
import { GroupType } from "../../../../../../shared/domain/group/group-type";
import { AddressType, recyclerAddressTypes } from "../../../../../../shared/models/address/address.model";
import { useGetInfiniteGroupAddresses } from "../../../../../../shared/repositories/queries/address/get-infinite-group-addresses.query";
import { useGetInfiniteAddressesOfTypeQuery } from "../../../../../../shared/repositories/queries/address/get-infinte-addresses-of-type.query";
import { useGetInfiniteGroupsQuery } from "../../../../../../shared/repositories/queries/group/get-infinte-groups.query";
import { CfmProductAddressTypeModel } from "../../../../../repositories/models/product/cfm-product-address-type.model";
import { useGetAddressesForProductQuery } from "../../../../../repositories/queries/address/get-addresses-for-product.query";
import { useOrderXSelectedContext } from "../../../order-x-selected.provider";
import { useGetOrderLogisticRoutingQuery } from "../../../../../repositories/queries/order-x/queries/get-order-logistic-routing.query";
import { IAddress } from "../../../../../../shared/domain/address/address";
import { getErrorCode, getErrorStatusCode } from "../../../../../../api/api-response";
import { ApiErrorCodes } from "../../../../../../api/api-error-codes";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { StatusCodes } from "http-status-codes";

interface IOrderActionChangeLogisticContextType {
  selectedGroupId: number | undefined;
  setSelectedGroupId: (id: number | undefined) => void;
  filter: IChangeLogisticFilter;
  setFilter: (filter: IChangeLogisticFilter) => void;
  destinationAddresses: IAddressLean[];
  fetchNextDestinationAddressesPage: () => Promise<void>;
  groups: IGroup[];
  fetchNextGroupsPage: () => Promise<void>;
  addresses: IAddressLean[];
  fetchNextAddressesPage: () => Promise<void>;
  areRoutingAddressesLoading: boolean;
  alternativeLogisticAddress: IAddress | undefined;
}

const OrderActionChangeLogisticContext = createContext<IOrderActionChangeLogisticContextType>(
  {} as IOrderActionChangeLogisticContextType,
);

export const OrderActionChangeLogisticProvider = (props: any) => {
  const value = useOrderActionChangeLogisticProvider();
  return (
    <OrderActionChangeLogisticContext.Provider value={value}>
      {props.children}
    </OrderActionChangeLogisticContext.Provider>
  );
};

export const useOrderActionChangeLogisticContext = () => {
  return useContext(OrderActionChangeLogisticContext);
};

interface ISelectFilter {
  search?: string;
  groupId?: number;
}

interface IChangeLogisticFilter {
  groupFilter: ISelectFilter;
  logisticAddressFilter: ISelectFilter;
  destinationAddressFilter: ISelectFilter;
}

const defaultFilterValue: IChangeLogisticFilter = {
  groupFilter: {},
  logisticAddressFilter: {},
  destinationAddressFilter: {},
};

const defaultPageSize = 200;
const useOrderActionChangeLogisticProvider = (): IOrderActionChangeLogisticContextType => {
  const { order } = useOrderXSelectedContext();
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();
  const [filter, setFilter] = useState<IChangeLogisticFilter>(defaultFilterValue);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //#region routing addresses
  const { isLoading: areRoutingAddressesLoading, data: routingAddressData } = useGetAddressesForProductQuery(
    getProductOfOrder(order)?.id,
    CfmProductAddressTypeModel.TargetAddresses,
    order.routingPostal ?? "DE",
    order.logisticAddress?.id ?? 0,
    getProductOfOrder(order) !== undefined,
  );
  const alternativeAddresses = useMemo(() => {
    return routingAddressData?.optionalAddresses ?? [];
  }, [routingAddressData]);
  //#endregion

  //#region destination addresses
  const {
    data: destinationAddressesResult,
    fetchNextPage: fetchNextDestinationAddressPageQuery,
    isFetching: isFetchingDestinationAddress,
    isFetchingNextPage: isFetchingDestinationAddressPage,
    hasNextPage: hasNextDestinationAddressPage,
  } = useGetInfiniteAddressesOfTypeQuery(
    [AddressType.LogisticLocation, ...recyclerAddressTypes],
    filter.destinationAddressFilter.search,
    false,
    defaultPageSize,
    true,
  );

  const fetchNextDestinationAddressesPage = async () => {
    if (isFetchingDestinationAddress || isFetchingDestinationAddressPage || !hasNextDestinationAddressPage) return;
    await fetchNextDestinationAddressPageQuery();
  };

  const destinationAddresses = useMemo(() => {
    const pages = destinationAddressesResult?.pages ?? [];
    return _.flatMap(pages, (page) => page.addresses).map(AddressConverter.domainToLeanAddressDomain);
  }, [destinationAddressesResult?.pages]);
  //#endregion

  //#region groups
  const {
    data: groupsResult,
    fetchNextPage: fetchNextGroupsPageQuery,
    isFetching: isFetchingGroups,
    isFetchingNextPage: isFetchingNextGroupsPage,
    hasNextPage: hasNextGroupsPage,
  } = useGetInfiniteGroupsQuery([GroupType.CfmLogistics], filter.groupFilter.search, defaultPageSize, true);

  const fetchNextGroupsPage = async () => {
    if (isFetchingGroups || isFetchingNextGroupsPage || !hasNextGroupsPage) return;
    await fetchNextGroupsPageQuery();
  };

  const groups = useMemo(() => {
    const pages = groupsResult?.pages ?? [];
    const mappedGroups = _.flatMap(pages, (page) => page.items);
    return mappedGroups.sort((g1, g2) => {
      const g1AddressIds = (g1.addresses ?? []).map((a) => a.id);
      const g2AddressIds = (g2.addresses ?? []).map((a) => a.id);
      const alternativeAddressIds = (alternativeAddresses ?? []).map((a) => a.id);

      if (alternativeAddresses && g1AddressIds.some((id) => alternativeAddressIds.includes(id))) return -1;
      if (alternativeAddresses && g2AddressIds.some((id) => alternativeAddressIds.includes(id))) return 1;

      return g2.id - g1.id;
    });
  }, [alternativeAddresses, groupsResult]);
  //#endregion

  //#region all group addresses
  const {
    data: addressesResult,
    fetchNextPage: fetchNextAddressPageQuery,
    isFetching: isFetchingAddress,
    isFetchingNextPage: isFetchingAddressPage,
    hasNextPage: hasNextDestinationPage,
  } = useGetInfiniteGroupAddresses(
    selectedGroupId,
    filter.logisticAddressFilter.search,
    false,
    [AddressType.LogisticLocation],
    defaultPageSize,
    Number.isFinite(selectedGroupId),
  );

  const fetchNextAddressesPage = async () => {
    if (isFetchingAddress || isFetchingAddressPage || !hasNextDestinationPage) return;
    await fetchNextAddressPageQuery();
  };

  const addresses = useMemo(() => {
    const pages = addressesResult?.pages ?? [];
    return _.flatMap(pages, (page) => page.addresses).map(AddressConverter.domainToLeanAddressDomain);
  }, [addressesResult?.pages]);
  //#endregion

  const onAlternativeError = (err: unknown) => {
    const errorCode = getErrorCode(err);
    const errorStatusCode = getErrorStatusCode(err);
    // do not throw error if no assignments are found or if error code is 500 (to prevent duplicate error message)
    if (
      errorCode === ApiErrorCodes.CfmNoRoutingAssignmentsFound ||
      errorStatusCode === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      return;
    }

    enqueueSnackbar(
      t("general.error_occurred", {
        errorCode: errorStatusCode,
        errorMsg: errorCode,
      }),
      { variant: "error" },
    );
  };

  const { data: alternativeResult } = useGetOrderLogisticRoutingQuery(order.id, true, onAlternativeError);

  return {
    selectedGroupId,
    setSelectedGroupId,
    filter,
    setFilter,
    destinationAddresses,
    fetchNextDestinationAddressesPage: fetchNextDestinationAddressesPage,
    groups,
    fetchNextGroupsPage,
    addresses,
    fetchNextAddressesPage,
    areRoutingAddressesLoading,
    alternativeLogisticAddress: alternativeResult?.address,
  };
};
