import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CfmProductArticleType } from "../../../../../configurator/components/product/product.utils";
import { ARTICLE_SEPARATOR, TEXT_SEPARATOR } from "../../../../../utils/constants";
import { ICfmItemX } from "../../../../domain/order-x/cfm-item-x";
import { getPackagesFromItems } from "../../utils/order-x-card.util";
import {
  IOrderXCardPackageInformation,
  OrderXCardPackageInformation,
} from "../package-information/order-x-card-package-information.component";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  quantityText: {
    fontWeight: "bold",
    paddingRight: 10,
  },
  productContainer: {
    display: "flex",
  },
  packagePadding: {
    paddingTop: 10,
  },
  container: {
    height: "100%",
  },
}));

export enum CfmActions {
  Pickup = 1,
  Delivery = 2,
}

interface IOrderXCardInformationProps {
  items: ICfmItemX[];
  isSelected: boolean;
}

export const OrderXCardInformation: React.FC<IOrderXCardInformationProps> = (props) => {
  const { t } = useTranslation();
  const { items, isSelected } = props;
  const classes = useStyles();

  const productItem = items.find((item) => item.product);
  const isSingleOrderArticle = productItem?.product?.articleType === CfmProductArticleType.SingleOrderProduct;
  const packageItems: IOrderXCardPackageInformation[] = getPackagesFromItems(items);

  const getProductLabel = () => {
    if (!productItem?.product) return <></>;
    const product = productItem.product;
    const productName = `${product.articleNumber} ${ARTICLE_SEPARATOR} ${product.name}`;

    return (
      <Grid container direction="column" justifyContent="center" className={classes.container}>
        <Grid item>
          <Grid container direction="row">
            {product.articleType === CfmProductArticleType.SingleOrderProduct && (
              <Grid item>
                <Typography variant="body1" className={classes.quantityText}>
                  {productItem?.quantity ?? 0} {t("general.unit")}
                </Typography>
              </Grid>
            )}
            <Grid item className={"pr-2"}>
              <Typography variant="body1">{productName} </Typography>
            </Grid>
            {getProductWeights()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getProductWeights = () => {
    if (!productItem?.targetNetWeightKg || !productItem.targetGrossWeightKg) return <></>;

    return (
      <Grid item>
        <Typography variant="body1">
          {t("order.product.grossWeight")}: {productItem.targetGrossWeightKg} kg{TEXT_SEPARATOR}{" "}
          {t("order.product.netWeight")}: {productItem.targetNetWeightKg} kg
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      className={classNames({ [classes.container]: isSingleOrderArticle && !isSelected })}
    >
      <Grid item xs={12} style={{ height: "100%" }}>
        {getProductLabel()}
      </Grid>
      {packageItems.map((item) => {
        return (
          <Grid key={item.id} item xs={12} className={classes.packagePadding}>
            <OrderXCardPackageInformation
              packageName={item.packageName}
              quantityToCollect={item.quantityToCollect}
              quantityToDeliver={item.quantityToDeliver}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
