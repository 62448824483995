import { AddressConverter } from "../../../../shared/domain/converter/address.converter";
import { ICfmProductRoutingAssignment } from "../../../domain/routing-assignment/cfm-product-routing-assignment";
import { ICfmProductRoutingAssignmentModel } from "../routing-assignment/cfm-product-routing-assignment.model";
import { CfmProductConverter } from "./cfm-product.converter";

export class CfmProductRoutingAssignmentConverter {
  public static toModel(domain: ICfmProductRoutingAssignment): ICfmProductRoutingAssignmentModel {
    return {
      id: domain.id,
      postal: domain.postal,
      product: domain.product ? CfmProductConverter.toModel(domain.product) : undefined,
      primaryStartAddress: domain.primaryStartAddress
        ? AddressConverter.toModel(domain.primaryStartAddress)
        : undefined,
      primaryTargetAddress: domain.primaryTargetAddress
        ? AddressConverter.toModel(domain.primaryTargetAddress)
        : undefined,
      secondaryStartAddress: domain.secondaryStartAddress
        ? AddressConverter.toModel(domain.secondaryStartAddress)
        : undefined,
      secondaryTargetAddress: domain.secondaryTargetAddress
        ? AddressConverter.toModel(domain.secondaryTargetAddress)
        : undefined,
      optionalPrimaryAddresses: domain.optionalPrimaryAddresses
        ? domain.optionalPrimaryAddresses.map(AddressConverter.toModel)
        : null,
      optionalSecondaryAddresses: domain.optionalSecondaryAddresses
        ? domain.optionalSecondaryAddresses.map(AddressConverter.toModel)
        : null,
      createdAt: domain.createdAt,
      updatedAt: domain.updatedAt,
      deletedAt: domain.deletedAt,
    };
  }

  public static toDomain(model: ICfmProductRoutingAssignmentModel): ICfmProductRoutingAssignment {
    return {
      id: model.id,
      postal: model.postal,
      product: model.product ? CfmProductConverter.toDomain(model.product) : undefined,
      primaryStartAddress: model.primaryStartAddress ? AddressConverter.toDomain(model.primaryStartAddress) : undefined,
      primaryTargetAddress: model.primaryTargetAddress
        ? AddressConverter.toDomain(model.primaryTargetAddress)
        : undefined,
      secondaryStartAddress: model.secondaryStartAddress
        ? AddressConverter.toDomain(model.secondaryStartAddress)
        : undefined,
      secondaryTargetAddress: model.secondaryTargetAddress
        ? AddressConverter.toDomain(model.secondaryTargetAddress)
        : undefined,
      optionalPrimaryAddresses: model.optionalPrimaryAddresses
        ? model.optionalPrimaryAddresses.map(AddressConverter.toDomain)
        : null,
      optionalSecondaryAddresses: model.optionalSecondaryAddresses
        ? model.optionalSecondaryAddresses.map(AddressConverter.toDomain)
        : null,
      createdAt: model.createdAt,
      updatedAt: model.updatedAt,
      deletedAt: model.deletedAt,
    };
  }
}
