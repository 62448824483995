import { isNumber } from "lodash";
import { useQuery } from "react-query";
import { IPaginationInputMeta } from "shared/domain/pagination/pagination-meta";
import { replaceUriParameter, RestEndpoints } from "../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../config/react-query-config";
import getInstance from "../../../../utils/rest";
import { AddressConverter } from "../../../domain/converter/address.converter";
import { IAddressResultModel } from "../../../models/address/address.model";
import { SharedQueryKeys } from "../shared-query-keys";
import { IAddressLeanResult } from "./../../../domain/address/address-lean";
import { PaginationMetaConverter } from "./../../../domain/converter/pagination-meta.converter";
import { AddressType } from "./../../../models/address/address.model";

export const getGroupAddresses = async (
  groupId: number | undefined,
  searchText: string | undefined,
  addressTypes: AddressType[],
  paginationInputMeta?: IPaginationInputMeta,
  active?: boolean,
): Promise<IAddressLeanResult> => {
  const url = replaceUriParameter(RestEndpoints.AddressesForGroup, "groupId", groupId ?? "");
  const response = await getInstance().get<IAddressResultModel>(url, {
    params: {
      query: searchText,
      addressTypes,
      active,
      ...paginationInputMeta,
    },
  });

  return {
    addresses: response.data.addresses.map(AddressConverter.toLeanAddress),
    meta: response.data.meta ? PaginationMetaConverter.toDomain(response.data.meta) : response.data.meta,
  };
};

export const useGetGroupAddresses = (
  groupId: number | undefined,
  searchText: string | undefined,
  addressTypes: AddressType[],
  enabled: boolean,
  paginationInputMeta?: IPaginationInputMeta,
  active?: boolean,
) => {
  return useQuery(
    [SharedQueryKeys.GetGroupAddressesForUuid, groupId, searchText, addressTypes],
    () => getGroupAddresses(groupId, searchText, addressTypes, paginationInputMeta, active),
    {
      staleTime: ReactQueryConfig.staleTime,
      enabled: isNumber(groupId) && enabled,
    },
  );
};
