import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CfmProductArticleType } from "../../../../../configurator/components/product/product.utils";
import { FormDatepickerField } from "../../../../../shared/components/form/fields/form-datepicker.field";
import { FormTextField } from "../../../../../shared/components/form/fields/form-text.field";
import { FormField } from "../../../../../shared/components/form/form-field.component";
import { Loading } from "../../../../../shared/components/loading/loading.component";
import { positiveNumberOnly } from "../../../../../utils/validationHelper";
import { useGetProductQuery } from "../../../../repositories/queries/product/query/get-product.query";
import { ErrorInfoBox } from "../dialog/error-info-box.component";
import { IOrderNewWizardFormInputs, IOrderNewWizardFormProductInput } from "../order-new-wizard.component";

const useStyles = makeStyles((theme: Theme) => ({
  mobileAlign: {
    textAlign: "center",
  },
  measurements: {
    paddingTop: 3.5,
  },
  formSpacing: {
    marginTop: theme.spacing(4),
  },
  textColor: {
    color: "white",
  },
  fullContainer: {
    width: "100%",
  },
}));

interface IOrderNewSubmitFormProps {
  productOrderIndex: number;
}

export const OrderNewSubmitFormComponent: FC<IOrderNewSubmitFormProps> = (props) => {
  const { productOrderIndex } = props;
  const { t } = useTranslation();
  const {
    trigger,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useFormContext<IOrderNewWizardFormInputs>();
  const productId: number | undefined = watch("productId");
  const productPackage = watch(`productOrders.${productOrderIndex}.package`);
  const productOrder: IOrderNewWizardFormProductInput = getValues(`productOrders.${productOrderIndex}`);

  const { isLoading, data: product } = useGetProductQuery(productId);

  const classes = useStyles();

  const isSingleOrderProduct = useMemo(() => {
    return product?.articleType === CfmProductArticleType.SingleOrderProduct;
  }, [product]);

  const positiveNumberOnlyValidation = (value: number | undefined, errorMessage: string) => {
    if (!value) return errorMessage;
    return positiveNumberOnly(value, errorMessage, true);
  };

  const netWeightValidation = (value: number | undefined) => {
    const errorMessage = t("orders.new.wizard.submit.error.netWeight");
    if (!value) return errorMessage;
    const result = positiveNumberOnly(value, errorMessage, true);
    if (result) return result;
    const grossWeight = productOrder.productGrossWeight;
    if (!grossWeight) return undefined;

    if (Number(grossWeight) === 0 && Number(value) === 0) return undefined;

    return Number(value) < Number(grossWeight)
      ? undefined
      : t("orders.new.wizard.submit.error.netWeightGreaterThenGross");
  };

  const getErrors = useCallback(() => {
    const errorMessages: string[] = [];

    if (errors.productOrders?.[productOrderIndex]?.productGrossWeight) {
      errorMessages.push(errors.productOrders?.[productOrderIndex]?.productGrossWeight?.message ?? "");
    }
    if (errors.productOrders?.[productOrderIndex]?.productNetWeight) {
      errorMessages.push(errors.productOrders?.[productOrderIndex]?.productNetWeight?.message ?? "");
    }
    if (errors.productOrders?.[productOrderIndex]?.requestedPickupDate) {
      errorMessages.push(errors.productOrders?.[productOrderIndex]?.requestedPickupDate?.message ?? "");
    }
    if (errors?.productOrders?.[productOrderIndex]?.package?.containersToBeCollected) {
      errorMessages.push(errors?.productOrders?.[productOrderIndex]?.package?.containersToBeCollected?.message ?? "");
    }

    return errorMessages.filter((message) => message);
  }, [errors, productOrderIndex]);

  return (
    <Loading isLoading={isLoading} mode={"linear"}>
      <Grid container key={productPackage?.containersToBeCollected}>
        {!isSingleOrderProduct && (
          <>
            <FormField md={12}>
              <FormTextField
                toolTipText={t("orders.new.wizard.submit.grossWeight")}
                hasError={Boolean(errors.productOrders?.[productOrderIndex]?.productGrossWeight)}
                label={t("orders.new.wizard.submit.grossWeight")}
                type="number"
                inputProps={{
                  endAdornment: <>kg</>,
                }}
                required
                name={`productOrders.${productOrderIndex}.productGrossWeight`}
                control={control}
                rules={{
                  validate: (value) =>
                    positiveNumberOnlyValidation(value, t("orders.new.wizard.submit.error.grossWeight")),
                  onChange: () => {
                    if (getValues(`productOrders.${productOrderIndex}.productNetWeight`)) {
                      void trigger(`productOrders.${productOrderIndex}.productNetWeight`);
                    }
                  },
                }}
              />
            </FormField>
            <FormField md={12} className={classes.formSpacing}>
              <FormTextField
                toolTipText={t("orders.new.wizard.submit.netWeight")}
                hasError={Boolean(errors.productOrders?.[productOrderIndex]?.productNetWeight)}
                label={t("orders.new.wizard.submit.netWeight")}
                type="number"
                inputProps={{
                  endAdornment: <>kg</>,
                }}
                required
                name={`productOrders.${productOrderIndex}.productNetWeight`}
                control={control}
                rules={{
                  validate: (value) => netWeightValidation(value),
                }}
              />
            </FormField>
          </>
        )}
        <FormField md={12} className={classes.formSpacing}>
          <FormDatepickerField
            label={t("orders.new.wizard.submit.requestedPickupDate")}
            hasError={Boolean(errors.productOrders?.[productOrderIndex]?.requestedPickupDate)}
            control={control}
            required={true}
            name={`productOrders.${productOrderIndex}.requestedPickupDate`}
          />
        </FormField>
        <FormField md={12} className={classes.formSpacing}>
          <FormTextField
            required={false}
            hasError={Boolean(errors.productOrders?.[productOrderIndex]?.orderNumber)}
            maxLength={35}
            control={control}
            label={t("orders.new.wizard.submit.orderNumber")}
            name={`productOrders.${productOrderIndex}.orderNumber`}
          />
        </FormField>
        <FormField md={12} className={classes.formSpacing}>
          <FormTextField
            required={false}
            hasError={Boolean(errors.productOrders?.[productOrderIndex]?.orderComment)}
            multiline
            control={control}
            minRows={2}
            maxLength={260}
            label={t("orders.new.wizard.submit.orderComment")}
            name={`productOrders.${productOrderIndex}.orderComment`}
          />
        </FormField>
        {getErrors().length > 0 && (
          <Grid item lg={12} className={classes.fullContainer}>
            <ErrorInfoBox errors={getErrors()} />
          </Grid>
        )}
      </Grid>
    </Loading>
  );
};
