import { useQuery } from "react-query";
import { RestEndpoints } from "../../../../../api/endpoints";
import { ReactQueryConfig } from "../../../../../config/react-query-config";
import getInstance from "../../../../../utils/rest";
import { ICfmProductTypeModel } from "../../../models/product/cfm-product-type.model";
import { CfmQueryKeys } from "../../cfm-query-keys";
import { ICfmProductType } from "../../../../domain/products/cfm-product-type";
import { CfmProductTypeConverter } from "../../../models/converter/cfm-product-type.converter";

const getProductTypes = async (): Promise<ICfmProductType[]> => {
  const { data } = await getInstance().get<ICfmProductTypeModel[]>(RestEndpoints.AllProductTypes);
  return data.map(CfmProductTypeConverter.toDomain);
};

export const useGetProductTypesQuery = () => {
  return useQuery([CfmQueryKeys.GetProductTypes], () => getProductTypes(), {
    staleTime: ReactQueryConfig.staleTime,
  });
};
