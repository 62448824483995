import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { EditIconNoBorderButton } from "../../../../components/Primitives";
import { getProductNameWithoutCategory } from "../../../../configurator/components/product/product.utils";
import { DetailValueDisplay } from "../../../../shared/components/value-display/detail-value-display.component";
import { IAddress } from "../../../../shared/domain/address/address";
import { formatDateShort } from "../../../../shared/util/date.util";
import { useProductPackageDataDisplayStyle } from "../../../../style/product-package-data-display.style";
import { getFormattedDomainAddress } from "../../../../utils/address.util";
import { ICfmTruckloadWithOrders } from "../../../domain/truckload/cfm-truckload-with-orders";
import { TruckloadState } from "../../../domain/truckload/truckload-state";

interface ITruckloadInfoBoxContentProps {
  truckload: ICfmTruckloadWithOrders;
  plannedDeliveryDate: Date | undefined;
  targetAddress: IAddress | undefined;
  onEditClick?: VoidFunction;
  contentPadding?: boolean;
}

export const TruckloadInfoBoxContent: VFC<ITruckloadInfoBoxContentProps> = (props) => {
  const { truckload, targetAddress, onEditClick, contentPadding, plannedDeliveryDate } = props;
  const classes = useProductPackageDataDisplayStyle();
  const { t } = useTranslation();

  const getProductLabel = () => {
    // fraction, type, state of truckload orders has to be the same
    const productItem = truckload.orders[0].items.find((item) => item.product);
    const product = productItem?.product;
    if (!product || !product.fraction || !product.type || !product.state) return "-";
    return getProductNameWithoutCategory(product.fraction, product.type, product.state);
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={onEditClick ? 11 : 12}>
        <Grid
          container
          direction="column"
          className={classNames({ [classes.productOrPackageContainer]: contentPadding })}
          spacing={1}
        >
          <Grid item>
            <DetailValueDisplay
              label={t("orders.truckload.warehouse")}
              value={getFormattedDomainAddress(truckload.startAddress, t)}
            />
          </Grid>
          <Grid item>
            <DetailValueDisplay label={t("orders.truckloadAnnounced.filter.product")} value={getProductLabel()} />
          </Grid>
          <Grid item>
            <DetailValueDisplay
              label={t("general.target")}
              value={targetAddress ? getFormattedDomainAddress(targetAddress, t) : "-"}
            />
          </Grid>
          <Grid item>
            <DetailValueDisplay
              label={t(`orders.truckloadAnnounced.${TruckloadState.Submitted}`)}
              value={plannedDeliveryDate ? formatDateShort(plannedDeliveryDate) : "-"}
            />
          </Grid>
        </Grid>
      </Grid>
      {onEditClick && (
        <Grid item xs={1}>
          <Grid container direction="column" className={classes.container} justifyContent="center">
            <Grid item>
              <EditIconNoBorderButton className={classes.iconDimensions} onClick={onEditClick} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
