import { CircularProgress, Grid } from "@material-ui/core";
import { useEffect, useMemo, VFC } from "react";
import { useHistory } from "react-router";
import { useGetProductsByIdsQuery } from "../../../collect-from-market/repositories/queries/product/query/get-products-by-ids.query";
import { useGetResponseAssignmentByIdsXQuery } from "../../../collect-from-market/repositories/queries/response-assignments/get-response-assignments-x.query";
import { AvailableConfiguratorRoutes } from "../../../utils/constants";
import {
  IResponseAssignmentRowValue,
  ProductResponseAssignmentStep,
  useProductResponseAssignmentContext,
} from "../../pages/product-response/product-response-assignment.provider";
import { ProductDataDisplay } from "../product/product-data-display.component";
import { mapToResponseFieldData } from "../response-field/response-field.util";
import { ProductResponseAssignmentForm } from "./form/product-response-assignment-form.component";
import { ProductResponseOrderStatusForm } from "./form/product-response-order-status-form.component";

interface ICreateProductResponseContentProps {
  productIds: number[];
  assignmentIds: number[] | undefined;
}

export const CreateProductResponseContent: VFC<ICreateProductResponseContentProps> = (props) => {
  const { productIds, assignmentIds } = props;
  const history = useHistory();
  const { activeStep, setActiveStep, setSelectedResponseAssignmentRowValues } = useProductResponseAssignmentContext();
  const { isLoading: isGetProductsByIdLoading, data: products } = useGetProductsByIdsQuery(productIds);
  const { isLoading: areAssignmentsLoading, data: responseAssignments } = useGetResponseAssignmentByIdsXQuery(
    assignmentIds,
    (assignmentIds?.length || 0) > 0,
  );

  useEffect(() => {
    if (!responseAssignments) return;
    const mapped: IResponseAssignmentRowValue[] = responseAssignments.map((assignment) => {
      return {
        status: assignment.status,
        responseFieldData: assignment.responseFields.map(mapToResponseFieldData),
        serviceDates: assignment.serviceDates,
      };
    });
    setSelectedResponseAssignmentRowValues(mapped);
  }, [responseAssignments, setSelectedResponseAssignmentRowValues]);

  const isLoading = useMemo(() => {
    return isGetProductsByIdLoading || areAssignmentsLoading;
  }, [areAssignmentsLoading, isGetProductsByIdLoading]);

  const isOrderStatusAssignment = useMemo(() => {
    return activeStep === ProductResponseAssignmentStep.ORDER_STATUS;
  }, [activeStep]);

  return (
    <Grid container direction="column" spacing={4}>
      {isLoading && (
        <Grid item>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <ProductDataDisplay
          products={products ?? []}
          onEditClick={() => {
            setActiveStep(ProductResponseAssignmentStep.ORDER_STATUS);
            history.push(AvailableConfiguratorRoutes.CreateResponseConfig);
          }}
          showActiveBadge={true}
        />
      </Grid>
      {isOrderStatusAssignment ? (
        <Grid item>
          <ProductResponseOrderStatusForm />
        </Grid>
      ) : (
        <Grid item>
          <ProductResponseAssignmentForm productIds={productIds} />
        </Grid>
      )}
    </Grid>
  );
};
