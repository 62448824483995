import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { VFC } from "react";
import { useTranslation } from "react-i18next";
import { CreatePackageConfigIcon } from "../../../components/Primitives/Icons";
import { PrimaryColors } from "../../../style/Colors";
import { PackageConfigProductList } from "../../components/package/package-config-product-list.component";
import { ProductOverviewGeneralFilter } from "../product/components/product-overview.utils";
import { ProductListFilter, ProductListFilterKey } from "../../components/product/product-list-filter.component";
import { ProductOverviewProvider } from "../product/product-overview.provider";
import { PackageOverviewProvider } from "./package-overview.provider";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginTop: 20,
    marginBottom: 30,
  },
  iconStyle: {
    color: PrimaryColors.base,
    height: 25,
    width: 25,
    marginTop: 5,
  },
  columnContainer: {
    height: "100%",
  },
  filter: {
    marginBottom: 20,
  },
}));

export const PackageConfigurationOverviewPage: VFC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PackageOverviewProvider>
      <ProductOverviewProvider>
        <Grid container direction="column">
          <Grid item className={classes.heading}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <CreatePackageConfigIcon viewBox="0 0 25 25" className={classes.iconStyle} />
              </Grid>
              <Grid item>
                <Typography variant="h4">{t("configuration.configPackage")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.filter}>
            <ProductListFilter
              hidden={[
                ProductListFilterKey.SortingArticleNumber,
                ProductListFilterKey.SortingDate,
                ProductListFilterKey.SortingRegion,
                ProductListFilterKey.TagWithoutRouting,
                ProductListFilterKey.TagWithoutResponseAssignments,
                ProductListFilterKey.TagInactiveProducts,
              ]}
              withoutPackageTextOverride={t(
                `configuration.overview.filter.${ProductOverviewGeneralFilter.WithoutPackages}.packageOverview`,
              )}
            />
          </Grid>
          <Grid item>
            <PackageConfigProductList />
          </Grid>
        </Grid>
      </ProductOverviewProvider>
    </PackageOverviewProvider>
  );
};
