import { Typography } from "@material-ui/core";
import { HEADER_MARGIN_BOTTOM, HEADER_MARGIN_TOP } from "config/theme/overrides/constant";
import React, { VFC } from "react";

interface IBaseDataPageHeaderProps {
  title: string;
}

export const BaseDataPageHeader: VFC<IBaseDataPageHeaderProps> = ({ title }) => {
  return (
    <Typography
      variant="h1"
      style={{
        marginTop: HEADER_MARGIN_TOP,
        marginBottom: HEADER_MARGIN_BOTTOM,
      }}
    >
      {title}
    </Typography>
  );
};
