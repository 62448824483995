import { makeStyles } from "@material-ui/core";

export const useBaseDataGridStyles = makeStyles((theme) => ({
  search: {
    padding: theme.spacing(1),
  },
  searchGrid: {
    maxWidth: "80%",
  },
  addGrid: {
    marginLeft: "auto",
    marginRight: theme.spacing(0.5),
  },
}));
