import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { VFC } from "react";
import { Colors, PrimaryColors } from "../../../../style/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.7,
  },
  success: {
    color: PrimaryColors.base,
  },
  error: {
    color: Colors.red,
  },
  container: {
    height: 25,
    border: "2px solid",
    borderRadius: "4px",
  },
  borderSuccess: {
    borderColor: PrimaryColors.base,
  },
  borderError: {
    borderColor: Colors.red,
  },
  textContainer: {
    height: "100%",
  },
}));

interface IGroupDetailStatusTagProps {
  label: string;
  type: "success" | "error";
}

export const GroupDetailStatusTag: VFC<IGroupDetailStatusTagProps> = (props) => {
  const { label, type } = props;
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.textContainer}>
      <Grid item>
        <Grid
          container
          className={classNames(classes.container, {
            [classes.borderError]: type === "error",
            [classes.borderSuccess]: type === "success",
          })}
          direction="column"
          justifyContent="center"
        >
          <Grid item className={classes.textContainer}>
            <Typography
              variant="body2"
              className={classNames(classes.text, {
                [classes.error]: type === "error",
                [classes.success]: type === "success",
              })}
            >
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
