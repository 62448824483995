import { ChipFilter, IOption } from "components/Filters/ChipFilter";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IActiveFilterProps {
  isActive: boolean | undefined;
  setIsActive: (isActive: boolean | undefined) => void;
}

export const ActiveFilter: FunctionComponent<IActiveFilterProps> = (props) => {
  const { isActive, setIsActive } = props;
  const { t } = useTranslation();
  const options: IOption[] = [
    {
      value: "true",
      displayValue: t("basedata.users.table.filter.status.active"),
    },
    {
      value: "false",
      displayValue: t("basedata.users.table.filter.status.inactive"),
    },
  ];

  return (
    <ChipFilter
      possibleOptions={options}
      label={t("basedata.users.table.filter.status.text")}
      filter={String(isActive)}
      returnFullOption
      setFilter={(option: IOption | undefined) => {
        if (!option) {
          setIsActive(undefined);
          return;
        }
        const newIsActive = option.value === "true";
        setIsActive(newIsActive);
      }}
    />
  );
};
