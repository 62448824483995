import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { FC } from "react";
import { Colors } from "../style/Colors";

const useStyles = makeStyles({
  variantWarning: {
    backgroundColor: Colors.orange,
  },
});

export const CustomSnackbarProvider: FC<SnackbarProviderProps> = ({ children, ...props }) => {
  const classes = useStyles();
  return <SnackbarProvider classes={classes} children={children} {...props} />;
};
