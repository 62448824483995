import { Card, CardHeader, IconButton, makeStyles, Theme } from "@material-ui/core";
import React, { ReactNode, useCallback } from "react";
import { RepeatOrderIcon } from "../../../../components/Primitives";
import { PrimaryColors } from "../../../../style/Colors";
import { ICfmOrderX } from "../../../domain/order-x/cfm-order-x";
import { OrderXOverviewCardContent } from "../../order-x/components/card-content/order-x-overview-card-content.component";
import { OrderXCardTitle } from "../../order-x/components/card/order-x-card-title.component";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: 20,
  },
  cardHeader: {
    backgroundColor: PrimaryColors.base,
  },
  cardHeaderPadding: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down("xs")]: {
      padding: 15,
    },
  },
}));

interface IOrderXRepeatInfoProps {
  onRepeatClick: (order: ICfmOrderX) => void;
  order: ICfmOrderX;
}

export const OrderXRepeatInfo: React.FC<IOrderXRepeatInfoProps> = (props) => {
  const { order, onRepeatClick } = props;
  const classes = useStyles();

  const getActionButton = useCallback((): ReactNode => {
    return (
      <IconButton>
        <RepeatOrderIcon
          onClick={() => {
            onRepeatClick(order);
          }}
          svgColor="white"
        />
      </IconButton>
    );
  }, [onRepeatClick, order]);

  return (
    <Card elevation={0} className={classes.card}>
      <CardHeader
        title={<OrderXCardTitle order={order} hasStatus={false} endAdornment={getActionButton()} />}
        className={classes.cardHeader}
        classes={{ root: classes.cardHeaderPadding }}
      />
      <OrderXOverviewCardContent order={order} isSelected={false} isLoading={false} />
    </Card>
  );
};
