import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { PrimaryColors } from "../../../style/Colors";

const useStyles = makeStyles({
  headerText: {
    color: PrimaryColors.base,
    lineHeight: 1.5,
  },
});

interface IIconFormHeaderProps {
  text: string;
  icon: JSX.Element;
}

export const IconFormHeader: FC<IIconFormHeaderProps> = (props) => {
  const { text, icon } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography variant="h5" className={classes.headerText}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
