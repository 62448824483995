import { usePlatformContext } from "providers/Auth/platform.provider";
import { VFC } from "react";
import { PageTitle } from "../../../layout-components/PageTitle/page-title";
import { AvailableSharedRoutes } from "../../../utils/constants";
import { CreateGroupForm } from "../../components/group/create-group/create-group-form.component";
import { CreateGroupFormProvider } from "../../components/group/create-group/create-group-form.provider";

export const GroupAddPage: VFC = () => {
  const { activePlatform } = usePlatformContext();

  return (
    <CreateGroupFormProvider>
      <PageTitle id={AvailableSharedRoutes.GroupNew} />
      <CreateGroupForm platform={activePlatform} />
    </CreateGroupFormProvider>
  );
};
