export enum CfmTruckloadActionType {
  TruckloadCanceled = "TruckloadCanceled",
  TruckloadSubmitted = "TruckloadSubmitted",
  TruckloadDone = "TruckloadDone",
  TruckloadUpdate = "TruckloadUpdate",
}

export interface ICfmTruckloadAction {
  type: CfmTruckloadActionType;
}
